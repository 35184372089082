import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'You and Your Worldview',
    'app.individualreport.a_personal_report_from': 'A personal report from the',
    'app.individualreport.bevi_description': 'Beliefs, Events, and Values Inventory (BEVI)',
    'app.individualreport.user': 'User',
    'app.individualreport.date_of_test': 'Date of Completion',
    'app.individualreport.static_text_1': 'Pick up a newspaper or turn on a radio or television ' +
        'anywhere in the world and one fact becomes immediately clear: ' +
        'beliefs and values are integral to the human experience.  People have strong opinions about topics ' +
        'from politics, religion, education and the arts, to marriage, family, gender, and sexuality.  ' +
        'However, whether a specific belief is "right" or "wrong" is arguably less important than ' +
        'understanding the complex interaction among thoughts, feelings, behavior, life history, ' +
        'and context that results in a unique version of reality for each human being. ' +
        'Such understanding is important because beliefs and values influence the actions, ' +
        'policies, and practices of individuals, groups, organizations, governments, and societies all over the world. ' +
        'The Beliefs, Events, and Values Inventory or BEVI provides a way for us to explore these complex ' +
        'issues at the individual and group level, by helping each of us to make sense of why we hold certain beliefs and values, ' +
        'while also examining why other people may see the world in similar and different ways. ',
    'app.individualreport.static_text_2': 'This report is designed to help you take a closer look at what you ' +
        'seem to believe and value, while also offering some possibilities about why you believe what you believe. ' +
        'Admittedly, these "what" and "why" questions are hard to answer in any absolute way. ' +
        'Moreover, it should be emphasized that the world is in a constant state of change. ' +
        'What may be perceived as "true" today might have been seen as "false" not too long ago. ' +
        'Likewise, what is seen as "bad" today might be seen as "good" in years to come. ' +
        'Thus, it is very important that you look at the following perspective simply as issues to ' +
        'think about at this point in your life, rather than a final determination of these matters for you. ' +
        'As human beings, we all are changing, unique, and complex.  No measure can possibly capture all ' +
        'that you have been, all that you are now, or all that will become; nor does the BEVI seek to do so. ' +
        'Instead, this instrument is designed to help you reflect upon yourself, others, and the world at large ' +
        'across a number of substantive areas that often are central to the experience of being human.',
    'app.individualreport.static_text_3': 'One final point should be emphasized.  Although this report focuses on the ' +
        'nature and implications of beliefs and values for one\'s life, work, and relationships - seeking to facilitate ' +
        'greater understanding of self, others, and the larger world - the BEVI takes no position on whether one set of ' +
        'beliefs and values is "right," "wrong," "better," or "worse" than any other set of beliefs and values.',
    'app.individualreport.static_text_4': 'So, against this backdrop, let\'s review your report. ' +
        'In addition to explanatory information about beliefs and values throughout, ' +
        'your unique responses to the BEVI are highlighted below in bold.',
    'app.individualreport.static_text_5': 'The experiences we have while growing up and throughout life often affect how we ' +
        'make sense of ourselves, others, and the world at large.  For example, people who have a lot of positive events in ' +
        'their life, and feel that their core needs were met much of the time, often have a sense that other human beings are ' +
        'basically trustworthy and that good things can be expected from life.  Sometimes - but by no means always - when ' +
        'people experience a lot of negative life events (e.g., conflict with their caregivers or in their home), or don\'t ' +
        'feel like their basic core needs were sufficiently met (e.g., for love or nurturance), their whole way of seeing ' +
        'the world and experiencing others (e.g., in school, work, and relationships) can be affected, mainly because ' +
        '"the world" wasn\'t always experienced as a very pleasant place to be, especially if such experiences happened ' +
        'at a very early age, over a long period of time, or were especially difficult or traumatic. ' +
        'And in fact, people who experienced a great many negative life events, or a lack of care for their basic core needs, ' +
        'may find it helpful to talk with someone who is trained in or knowledgeable about these sorts of issues. ' +
        'That said, it also is very important to recognize that life is challenging for all of us to one degree or another, ' +
        'and even if we experience many difficulties, most people are able to cope with such adversity and go on to have meaningful ' +
        'relationships in school, work, and life, sometimes with considerable help from others (e.g., partners, friends, teachers, ' +
        'employers, spiritual leaders, mentors, counselors).  In any case, when the beliefs and values of others don\'t make sense ' +
        'to us, it may be helpful to remember that different life experiences and backgrounds influence how we see ourselves, ' +
        'others, and the larger world.  Such a realization may help us have a bit more understanding if not empathy for why we and ' +
        'others think, feel, and do what we do across a range of situations and contexts.',
    'app.individualreport.static_text_6': 'If you tend to be the "inquisitive" type, it may be helpful to remember that ' +
        'other people around you - even the "very confident" types - may not be as sure as they appear to be in terms ' +
        'of the new or unfamiliar, and may not in fact know any better than you what is the "right thing" to do. ' +
        'Reasonable confidence is great, when you are in a situation where you have good reason to believe that you know what is happening. ' +
        'However, when you are in a situation or place that you have never encountered before, it may be helpful to adopt a more inquisitive ' +
        'and open stance in general, while paying particular attention to the sorts of issues, questions, practices, and processes that ' +
        'seem most intriguing to you.  Such information about yourself can help you understand better who you are, who you are becoming, and who you wish to be. ',
    'app.individualreport.static_text_7': 'At the same time, as we encounter new people or situations and change over time, ' +
        'it is equally important that we strive to "know what we do not know" so that we may appraise the limits of our own knowledge, ' +
        'and admit that we may not have "the right answers" or may need to learn more about a particular subject before making up our mind. ' +
        'In short, the degree to which we are able to make sense of why we humans do what we do often is associated with the ability to think, ' +
        'feel, and act with greater understanding, wisdom, and compassion.',
    'app.individualreport.static_text_8': 'In the context of the above findings and observations, it is worth reflecting more generally upon ' +
        'the role of "needs" and "feelings" in our daily lives. You\'ll notice, for example, that we often seek out and even long for ' +
        'experiences where we "feel deeply" (e.g., through relationships, music, theater, reading, travel, being in nature, etc.). ' +
        'Because our emotions reveal so much about our "true self," it often is difficult for us to feel what we feel, much less to ' +
        'express these feelings out loud, since doing so may say a lot about who we really are. ' +
        'And yet, letting others know "who we are" is crucial to building relationships, getting needs met, and the pursuit of work ' +
        'and life goals.  Thus, while it may not be advisable for us to reveal every emotion or need that we have, not being able to ' +
        'experience or express them can create real difficulties for us and others.',
    'app.individualreport.static_text_9_1': 'In short, the inability or unwillingness to feel feelings and acknowledge needs can be a problem ' +
        'for us and others for at least two reasons: 1) we lose important information about what is really "going on" within us, which can ' +
        'further impair our decision making and overall well-being; and 2) others may find it difficult to relate with us or know who we really are. ' +
        'Fortunately, whether we are able to acknowledge it or not, all of us come into the world built to feel and need, which is perhaps the most ' +
        'basic way that we communicate with other beings, even before we speak our first words. ' +
        'Throughout life, we have the chance to encounter the full range of needs and feelings in ourselves and others, becoming more deeply human along the way.',
    'app.individualreport.static_text_9_2': '',
    'app.individualreport.static_text_9_3': '',
    'app.individualreport.static_text_9_4': '',
    'app.individualreport.static_text_10_1': 'Finally, our backgrounds and experiences can affect not only how we see ourselves and others, ' +
        'but how we see the "larger world" and how we think it is or should be. Here, we\'re going to focus on two final global themes ' +
        'that have relevance for us all: 1) our stance toward the environment and natural world; and 2) the degree to which we are or ' +
        'are not inclined to be globally engaged.',
    'app.individualreport.static_text_10_2': '',
    'app.individualreport.static_text_10_3': '',
    'app.individualreport.static_text_11': 'As we conclude this report, it\'s important to remember that the above statements apply ' +
        'to where you are now; we all change over the course of our lives, and your current beliefs and values may change in time as well. ' +
        'In the final analysis, it\'s good that we cultivate the courage and capacity to reflect as deeply as possible upon what we believe ' +
        'and value in order to have the best chance of ensuring that the decisions we make in our own lives are those that we understand, ' +
        'can defend, and would want to embrace. Hopefully, the information and perspective in this report is helpful and relevant to you ' +
        'in your own life as you continue to reflect upon who you are now, who you are becoming, and who you want to be.',
    'app.individualreport.heading_text_1_1': 'The Beliefs, Events, and Values Inventory (BEVI) is designed to help people gain ' +
        'greater awareness of their own beliefs and values. The BEVI also examines how beliefs and values may influence learning, ' +
        'personal growth, relationships, group or organizational processes, and the pursuit of life goals. ' +
        'In addressing these issues, this report contains information about how you see yourself, others, and the larger world. ' +
        'However, because the BEVI takes no position at all on whether the answers you provided are right, wrong, true, or false, ' +
        'neither does this report make any such claims. Instead, this report is designed to promote reflection about issues in life ' +
        'that often are relevant to us all.  If you have any questions about the BEVI, please contact the BEVI administrator at your organization or institution. ' +
        'You also may contact us directly at info@ibavi.org. ' +
        'Before reading the following report, you must agree to the three conditions below either by clicking the Agree button or by signing a ' +
        'separate agreement form that will be provided to you prior to reading the report:',
    'app.individualreport.heading_text_1_2': '',
    'app.individualreport.heading_text_1_3': '',
    'app.individualreport.heading_text_2': '1. You ({UserName}) voluntarily completed the BEVI and subsequently requested this follow up report, ' +
        'which you are reading voluntarily (i.e., you agree that no person, group, organization, or entity is requiring you to read this report).',
    'app.individualreport.heading_text_3': '2. Every effort has been made to communicate information from the BEVI in a way that is helpful ' +
        'and constructive.  However, if you experience distress upon reading this report, you agree to contact the BEVI administrator in your ' +
        'organization, the coordinator of a BEVI feedback session, and/or a health or mental health care provider.',
    'app.individualreport.heading_text_4': '3. You are free to keep a copy of this report for yourself. ' +
        'However, because the BEVI and this report are copyrighted, you agree not to photocopy this report or distribute it ' +
        '(electronically or otherwise) to anyone else.',
    'app.individualreport.thankyou_static_1_1': 'The Beliefs, Events, and Values Inventory or BEVI and this BEVI report are copyrighted. ' +
        'The BEVI and BEVI reports, items, item content, or scales may not be modified, copied, disseminated, or published, in whole ' +
        'or part, without the written and express permission of Craig N. Shealy, Ph.D.',
    'app.individualreport.thankyou_static_1_2': '',
    'app.individualreport.thankyou_static_2': 'As with the BEVI as a whole, the structure and design of these reports are subject ' +
        'to ongoing processes of review and development. If you have suggestions or comments about any aspect of the BEVI or this ' +
        'report, please feel free to contact us directly at info@ibavi.org.',
    'app.individualreport.thankyou_static_3': 'To learn more about beliefs and values, please visit the International Beliefs ' +
        'and Values Institute (IBAVI) at www.ibavi.org and www.thebevi.com.',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': 'We all differ a little, and sometimes a lot, in what we believe and value. ' +
        'Why is that?  Although there are many ways to answer this question, the BEVI is built upon the basic recognition ' +
        'that the places, cultures, and contexts in which we are raised - and the positive and negative life experiences that we ' +
        'have while we are growing up and throughout our lives  - all have a great impact on how we see the world.',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': 'From the perspective of the BEVI, we acquire ways of thinking and ' +
        'feeling about the world (e.g., politics and religion) as a result of a whole range of factors, including but ' +
        'by no means limited to one\'s family, upbringing, culture, and context. Thus, to understand what people ' +
        'believe and value, it helps to know some of the positive and negative life experiences that people have had ' +
        'as well as the degree to which they report that their core needs have been met. <b>In your case, you ' +
        'report that you have experienced {NegativeLifeEvents} number of negative experiences in your life. ' +
        'You also report that your own core needs were {NeedsClosure} during childhood and adolescence.</b>'
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': 'Our background and experiences affect us in other ways too, ' +
        'by shaping the degree to which we are able and willing to deal with people and events that are very different from what we are ' +
        'accustomed. From the standpoint of the BEVI, there are two types of people in this regard.  The first type - let\'s call ' +
        '"confident" - may feel sure of themselves as well as their understanding of who others are, how the world works, and their ' +
        'experience of life, work, and relationships.  The second type - let\'s call "inquisitive" - may be less clear about such ' +
        'matters, feel doubts and regrets at times, as well as confusion about how they may react when exposed to situations that ' +
        'are different or unfamiliar. <b>From your responses on the BEVI, you tend to be more confident than inquisitive.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': 'Of course, there are potential advantages and disadvantages ' +
        'to a confident or inquisitive approach, and all of us may feel both ways from time to time, depending upon different ' +
        'circumstances or situations.  In general, though, if you are the "confident" type, it may be helpful to become more aware ' +
        'of times when you may not necessarily know what to do, how to think, or what to feel, especially when you experience ' +
        'something that is different from what you are accustomed. <b>At a related level, you also seem to have a very strong ' +
        'sense of will and responsibility, and to believe that the world is "what you make of it" and to dislike "negative thinking" ' +
        'in general.  Such a stance can help you accomplish many things in your life, as you may not be easily dissuaded ' +
        'from your goals. This same attitude may also make it difficult at times for you to empathize with others who may not feel ' +
        'such a strong sense of purpose or optimism.  It\'s important again to remember that people have different backgrounds and ' +
        'experiences, and what you may experience in them as excessive "neediness" or "weakness," may be more a reflection of the ' +
        'real difficulties and obstacles that they have experienced in their lives, which all of the positive thinking in the ' +
        'world simply can\'t overcome, at least not yet.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': '',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': 'Our background and experiences affect us in other ways too, ' +
        'by shaping the degree to which we are able and willing to deal with people and events that are very different from what we ' +
        'are accustomed.  From the standpoint of the BEVI, there are two types of people in this regard.  The first type - let\'s ' +
        'call "confident" - may feel sure of themselves as well as their understanding of who others are, how the world works, ' +
        'and their experience of life.  The second type - let\'s call "inquisitive" - may be less clear about such matters, ' +
        'feel doubts and regrets at times, as well as confusion about how they may react when exposed to situations that ' +
        'are different or unfamiliar. <b>From your responses on the BEVI, you tend to be in the middle between confident and inquisitive.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': 'Of course, there are potential advantages and disadvantages ' +
        'to a confident or inquisitive approach, and all of us may feel both ways from time to time, depending upon different ' +
        'circumstances or situations.  In general, though, if you are the "confident" type, it may be helpful to become more ' +
        'aware of times when you may not necessarily know what to do, how to think, or what to feel, especially when you ' +
        'experience something that is different from what you are accustomed.',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '<b>At a related level, you seem to wonder if life ' +
        'is "what you make of it" or if the best strategy for dealing with problems really is simply to "think ' +
        'positive thoughts."</b> In some cases, these methods may work for you, but you\'re likely to be just as ' +
        'inclined to question this "positive" approach to life and living. Perhaps one advantage of thinking that "only ' +
        'you are responsible for your problems" is that such people do feel a sense of empowerment to tackle any issue they face. ' +
        'However, one disadvantage of this worldview may be that such people may not always feel particularly empathetic towards ' +
        'those who don\'t seem to have the ability to "overcome any obstacle." Among other questions worth asking in this ' +
        'regard is what are the consequences of the belief - or the disbelief - that we are "all responsible for the kind of life we lead"?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': 'Our background and experiences affect us in other ways too, ' +
        'by shaping the degree to which we are able and willing to deal with people and events that are very different from what ' +
        'we are accustomed. From the standpoint of the BEVI, there are two types of people in this regard.  The first type - ' +
        'let\'s call "confident" - may feel sure of themselves as well as their understanding of who others are, how the world ' +
        'works, and their experience of life. The second type - let\'s call "inquisitive" - may be less clear about such matters, ' +
        'feel doubts and regrets at times, as well as confusion about how they may react when exposed to situations that are ' +
        'different or unfamiliar. <b>From your responses on the BEVI, you tend to be more inquisitive than confident.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': 'Of course, there are potential advantages and disadvantages ' +
        'to a confident or inquisitive approach, and all of us may feel both ways from time to time, depending upon different ' +
        'circumstances or situations.  In general, though, if you are the "confident" type, it may be helpful to become more ' +
        'aware of times when you may not necessarily know what to do, how to think, or what to feel, especially when you ' +
        'experience something that is different from what you are accustomed. <b>At a related level, you seem to be a bit ' +
        'skeptical in general that the world is "what you make of it" or that we should "just think positively" to get ' +
        'through life.</b> In fact, people who seem to think in this way may seem na&iuml;ve or even irritating at times, ' +
        'mainly because they seem to be impatient with those who struggle just to get through each day. Perhaps the advantage ' +
        'of thinking that "only you are responsible for your problems" is that such people do feel a sense of empowerment to ' +
        'tackle any issue they face.  However, the disadvantage of this worldview is that such people may not always feel ' +
        'particularly empathetic towards those who don\'t seem to have the ability to "overcome any obstacle."' +
        ' In your case, it might be useful to reflect upon the times when you do and you don\'t feel a sense of empowerment, ' +
        'and why that may be the case for you. For example, what have you come to believe about "destiny," "human nature," ' +
        'or "why things are the way they are"? Which of these beliefs are the most - and the least - helpful to you in ' +
        'navigating the challenges and possibilities that are before you each day?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': 'We humans are meaning making creatures. ' +
        'We may not even realize we are doing it, but we all seek explanations for "why things are the way they are" ' +
        'and draw causal conclusions even if we do not always have enough information to do so. These explanations often ' +
        'range widely from why we humans do what we do, to why events happen as they do in the larger world, to the overall ' +
        'purpose of life or existence. Sometimes, we may not even be aware that we are drawing conclusions about "why things ' +
        'are the way they are" or how very common, automatic, non-conscious, or even unavoidable these meaning-making ' +
        'processes are in everyday life. <b>From your responses on the BEVI, it appears that you have a very well ' +
        'developed capacity to draw nuanced and sophisticated causal explanations for phenomena, and to reflect deeply ' +
        'and accurately on "why things are the way they are."</b>',
    'app.individualreport.whywedo.lessthan69.text': 'We humans are meaning making creatures. ' +
        'We may not even realize we are doing it, but we all seek explanations for "why things are the way they are" ' +
        'and draw causal conclusions even if we do not always have enough information to do so. These explanations often ' +
        'range widely from why we humans do what we do, to why events happen as they do in the larger world, to the overall ' +
        'purpose of life or existence. Sometimes, we may not even be aware that we are drawing conclusions about "why things ' +
        'are the way they are" or how very common, automatic, non-conscious, or even unavoidable these meaning-making ' +
        'processes are in everyday life. <b>From your responses on the BEVI, it appears that you are thinking more ' +
        'and more about "why things are the way they are."</b>',
    'app.individualreport.whywedo.lessthan30.text': 'We humans are meaning making creatures. ' +
        'We may not even realize we are doing it, but we all seek explanations for "why things are the way they are" ' +
        'and draw causal conclusions even if we do not always have enough information to do so. These explanations often ' +
        'range widely from why we humans do what we do, to why events happen as they do in the larger world, to the overall ' +
        'purpose of life or existence. Sometimes, we may not even be aware that we are drawing conclusions about "why things ' +
        'are the way they are" or how very common, automatic, non-conscious, or even unavoidable these meaning-making ' +
        'processes are in everyday life. <b>From your responses on the BEVI, it appears that you may benefit from ' +
        'exploring more about why people do what they do or why events in the world happen as they do, before, during, ' +
        'and after any new or especially challenging experiences that you may have.</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': 'Introduction',
    'app.individualreport.title.foundation': 'The Foundation: "Formative Variables" and "Core Needs"',
    'app.individualreport.title.tolerance_of_desequilibrium': 'Tolerance of Disequilibrium',
    'app.individualreport.title.making_sense': 'Making Sense of Why We Do What We Do',
    'app.individualreport.title.access_to_yourself': 'Access to Yourself and Your Thoughts, Feelings, and Needs',
    'app.individualreport.title.access_to_the_thoughts': 'Access to the Thoughts and Feelings of Others',
    'app.individualreport.title.access_to_the_larger_world': 'Access to the Larger World',
    'app.individualreport.title.conclusion': 'In Conclusion',
    'app.individualreport.title.thankyou': 'Thank you for completing the BEVI.',
    'app.individualreport.title.profile': 'Profile',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '<b>You probably have ' +
        'noticed that some people tend to be more emotional and sensitive while also valuing ' +
        'the expression of needs or feelings more deeply than others. Such a description seems ' +
        'to be a very good fit for you.</b> Think for a moment about how central emotions are ' +
        'to human existence.  The ability to feel what you feel, while accurately interpreting and ' +
        'understanding the feelings of others - what some have referred to as "emotional intelligence" ' +
        '- is key to navigating every aspect of life, from your personal relationships to the world of ' +
        'work.  Without emotions, it would be very difficult to understand what we like and don\'t like, ' +
        'who we and others are, and who we want to be.',
    'app.individualreport.accessself.textB': '<b>You probably have ' +
        'noticed that some people tend to be more emotional and sensitive than others. Such a description ' +
        'seems to fit you in many circumstances.</b> Think for a moment about how central emotions ' +
        'are to human existence.  The ability to feel what you feel, while accurately interpreting and ' +
        'understanding the feelings of others - what some have referred to as "emotional intelligence" ' +
        '- is key to navigating every aspect of life, from your personal relationships to the world of ' +
        'work.  Without emotions, it would be very difficult to understand what we like and don\'t like, ' +
        'who we and others are, and who we want to be.',
    'app.individualreport.accessself.textC': '<b>You probably have noticed ' +
        'that some people tend to be more emotional and sensitive while also valuing the expression of ' +
        'needs or feelings more deeply than others. Such a description seems less descriptive of how you ' +
        'approach your own feelings and those of others, in that you may tend to be puzzled and even ' +
        'irritated at times by what you experience as excessive displays of emotion or vulnerability in ' +
        'other people.</b> If that is the case for you, it may be helpful to reflect again on the ' +
        'fact that our backgrounds and life experiences may make us much more likely - or much less ' +
        'likely - to be able and willing to "access" deep needs and feelings.  Only you can be the judge ' +
        'of whether your background and experiences were such that you were discouraged in general from ' +
        'feeling too deeply or needing too much from others.  If that was your experience, it\'s important ' +
        'to think about the possible impact of such processes for you in your life, in work, in your ' +
        'relationships with others, and in how you experience people, situations, and relationships, ' +
        'which may be difficult to handle, at least at first, particularly when they are new.  Think ' +
        'for a moment about how central emotions are to human existence.  The ability to feel what you ' +
        'feel, while accurately interpreting and understanding the feelings of others - what some have ' +
        'referred to as "emotional intelligence" - is key to navigating every aspect of life, from your ' +
        'personal relationships to the world of work.  Without emotions, it would be very difficult to ' +
        'understand what we like and don\'t like, who we and others are, and who we want to be.',
    'app.individualreport.accessself.textI': '<b>You also may struggle at times with difficult thoughts and feelings, and avoid excessive "introspection." In general, it may puzzle or even irritate you when others seem to focus so much on "why they are the way they are."</b> ' +
        'It is important again to remember that everyone has different experiences and backgrounds, ' +
        'which shape the way they see themselves, and that people who spend a lot of time thinking about ' +
        '"why we are the way we are" often can help the rest of us make better sense of who we are and ' +
        'why we do what we do.  At the same time, it also is possible to become preoccupied with thoughts ' +
        'and feelings that are very difficult to experience, understand, or manage; in such cases, it may ' +
        'be helpful to talk with someone who is trained in or knowledgeable about these sorts of issues.',
    'app.individualreport.accessself.textH': '<b>You may also be open ' +
        'to the world of thoughts and feelings, but find that you occupy the "middle ground" compared ' +
        'to those who are around you.  Some people will seem to spend a great deal of time and energy ' +
        'thinking about "why we are the way we are" whereas others seem not to think much at all in these ' +
        'terms, and may find those who do to be a bit irritating.  In many ways, you are well positioned ' +
        'to help both sides of the aisle better understand each other\'s tendencies in this regard (e.g., ' +
        'those who seem to feel "a great deal" versus those who seem to avoid such feelings altogether).</b>',
    'app.individualreport.accessself.textG': '<b>You also may be very open ' +
        'to difficult thoughts and feelings, to be introspective in general, and to be aware of how you ' +
        'think and feel.  It may puzzle you, at times, when others don\'t seem to have this same capacity, ' +
        'which can feel lonely or isolating for you at times.  For people like you, it\'s important to ' +
        'seek out other "kindred spirits" who understand and value the expression of needs and feelings, ' +
        'and like to think about "why we are the way we are."  We certainly need people like you in the ' +
        'world who have the capacity to think and feel so deeply.</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {

    'app.individualreport.accessothers.textA': 'From a young age, we often learn to avoid talking about ' +
        'certain topics such as religion and politics, the roles of men and women, or matters of race or ' +
        'ethnicity because these issues can become so heated when we encounter someone who believes very ' +
        'differently from us.  But from the standpoint of the BEVI, it\'s not so much that we cannot or ' +
        'should not discuss these topics; in fact, it\'s essential to healthy discourse in a civil society ' +
        'that we do so.  What\'s most important is first understanding why some issues are "hot button" ' +
        'issues for us and others.  So here, we explore how you appear to see matters of religion, gender, ' +
        'politics, and race as well as the potential implications of such beliefs and values for your ' +
        'relationships with others who may think and feel similarly - or differently - from you. <br /><br />' +
        '<b>First, it appears that you tend to have strong ' +
        'religious beliefs or convictions, and may experience your faith as a key part of how you make ' +
        'meaning and find purpose.  It can be wonderful to have a deep sense of faith.  It\'s also ' +
        'important to understand that some individuals, groups, religions, and cultures may believe very ' +
        'differently from you about these fundamental matters.</b> Sometimes we are very clear about ' +
        'why we believe that which we do about religious matters.  At other times, we simply have ' +
        'accepted what we were taught, whatever it was, without really reflecting upon why we believe ' +
        'what we believe.  The point here is not that one set of beliefs is right and another is wrong, ' +
        'but rather that we all come to believe something about these fundamental matters that affect us ' +
        'all (i.e., even non-belief is a belief that there is no point in - or reality to - a particular ' +
        'belief or belief system).   Because matters of religious belief are so core to how we experience ' +
        'ourselves, others, and the larger world, it is very important that we strive to understand the ' +
        'nature and origins of our beliefs and values about religion.  As if not more important, since ' +
        'our beliefs about religion often result from our unique upbringing, culture, and context, it ' +
        'may be possible for us to become more understanding about how and why other people may believe ' +
        'similarly or differently from us.  By approaching beliefs and values from the standpoint of ' +
        'genuine curiosity and care, we may come to learn more about why we are who we are, while ' +
        'helping others to do the same.',
    'app.individualreport.accessothers.textB': 'From a young age, we often learn to avoid talking about ' +
        'certain topics such as religion and politics, the roles of men and women, or matters of race or ' +
        'ethnicity because these issues can become so heated when we encounter someone who believes very ' +
        'differently from us.  But from the standpoint of the BEVI, it\'s not so much that we cannot or ' +
        'should not discuss these topics; in fact, it\'s essential to healthy discourse in a civil society ' +
        'that we do so.  What\'s most important is first understanding why some issues are "hot button" ' +
        'issues for us and others.  So here, we explore how you appear to see matters of religion, ' +
        'gender, politics, and race as well as the potential implications of such beliefs and values ' +
        'for your relationships with others who may think and feel similarly - or differently - from you. ' +
        '<br /><br />' +
        '<b>First, it appears that you tend to have moderate ' +
        'religious beliefs.  It is possible that you are completely fine with your current beliefs about ' +
        '"matters of faith." However, it also is possible that you have questions about the depth and ' +
        'intensity of your own religious beliefs, whatever they are.  You also may wonder about others ' +
        'who seem to feel very strongly one way or another (e.g., they describe themselves as very ' +
        'strongly religious or very strongly anti-religious).</b> Sometimes we are very clear about ' +
        'why we believe that which we do about religious matters. At other times, we simply have ' +
        'accepted what we were taught, whatever it was, without really reflecting upon why we believe ' +
        'what we believe.  The point here is not that one set of beliefs is right and another is wrong, ' +
        'but rather that we all come to believe something about these fundamental matters that affect us ' +
        'all (i.e., even non-belief is a belief that there is no point in - or reality to - a particular ' +
        'belief or belief system).   Because matters of religious belief are so core to how we experience ' +
        'ourselves, others, and the larger world, it is very important that we strive to understand the ' +
        'nature and origins of our beliefs and values about religion. As if not more important, since ' +
        'our beliefs about religion often result from our unique upbringing, culture, and context, it ' +
        'may be possible for us to become more understanding about how and why other people may believe ' +
        'similarly or differently from us.  By approaching beliefs and values from the standpoint of ' +
        'genuine curiosity and care, we may come to learn more about why we are who we are, while ' +
        'helping others to do the same.',
    'app.individualreport.accessothers.textC': 'From a young age, we often learn to avoid talking about ' +
        'certain topics such as religion and politics, the roles of men and women, or matters of race ' +
        'or ethnicity because these issues can become so heated when we encounter someone who believes ' +
        'very differently from us.  But from the standpoint of the BEVI, it\'s not so much that we cannot ' +
        'or should not discuss these topics; in fact, it\'s essential to healthy discourse in a civil ' +
        'society that we do so.  What\'s most important is first understanding why some issues are ' +
        '"hot button" issues for us and others.  So here, we explore how you appear to see matters of ' +
        'religion, gender, politics, and race as well as the potential implications of such beliefs and ' +
        'values for your relationships with others who may think and feel similarly - or differently - ' +
        'from you. <br /><br />' +
        '<b>First, it appears that you tend not to ' +
        'have strong religious beliefs or convictions, and may even be skeptical of religious systems ' +
        'or religious believers. Thinking carefully about matters of religious belief, or not accepting ' +
        'such matters on "faith" alone, can result from critical thinking about beliefs and values that ' +
        'too often are accepted without sufficient scrutiny.  Whatever the outcome of this process for ' +
        'you, it may be helpful to reflect upon the possibility that one strongly held set of convictions ' +
        '(e.g., deep beliefs about the validity of one\'s beliefs) may simply be substituted for another ' +
        'strongly held set of convictions (e.g., deep beliefs about the validity of one\'s non-beliefs) ' +
        'without a corresponding increase in our understanding about the origins and purpose of beliefs ' +
        'and values for all of us.</b> Sometimes we are very clear about why we believe that which ' +
        'we do about religious matters.  At other times, we simply have accepted what we were taught, ' +
        'whatever it was, without really reflecting upon why we believe what we believe.  The point here ' +
        'is not that one set of beliefs is right and another is wrong, but rather that we all come to ' +
        'believe something about these fundamental matters that affect us all (i.e., even non-belief is ' +
        'a belief that there is no point in - or reality to - a particular belief or belief system).  ' +
        'Because matters of religious belief are so core to how we experience ourselves, others, and ' +
        'the larger world, it is very important that we strive to understand the nature and origins of ' +
        'our beliefs and values about religion.  As if not more important, since our beliefs about ' +
        'religion often result from our unique upbringing, culture, and context, it may be possible ' +
        'for us to become more understanding about how and why other people may believe similarly or ' +
        'differently from us.  By approaching beliefs and values from the standpoint of genuine curiosity ' +
        'and care, we may come to learn more about why we are who we are, while helping others to do the ' +
        'same. ',
}

const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '<b>Second, in terms of gender, ' +
        'you tend to have more traditional or conventional beliefs and values regarding who males and ' +
        'females are, and how they should be; you may also feel that men and women simply are "built to ' +
        'be a certain way," and that we would all do better if we simply understood and accepted that ' +
        'fact. We all know that there are differences between men and women. But the question ' +
        'is, why are there differences in the first place?  From your responses, you tend to believe that ' +
        'gender differences are largely due to underlying biological factors.</b> Although scholarship on ' +
        'this topic is highly complex, it is important to know that real or assumed "differences" between ' +
        'men and women have been used as a way to deny girls and women many rights over the years; in ' +
        'short, "biological arguments" often have been employed to justify the denial of rights to females ' +
        'because they are not "meant" to live, work, or be in the world of men, as equals.  Stereotypes ' +
        'can cut both ways, however, as boys and men may be seen as "less capable" of nurturance or ' +
        'emotion, which means they may be denied not only the "right" to have feelings and experience ' +
        'needs, in themselves and others; such "lessons" may impact all of us if males learn that they ' +
        '"must be tough," and then come to act in ways that are aggressive or uncaring towards others. ' +
        'Men may also be denied equality when it comes to childrearing, for example, or with other roles ' +
        'that have historically been assigned to girls and women.  In reflecting upon such issues, it is ' +
        'important to understand how arguments made on the basis of assumed biological differences may be ' +
        'used to limit opportunities and ways of being for females and males, with consequences for us ' +
        'all.',
    'app.individualreport.gendertradition.textB': '<b>Second, in terms of gender, ' +
        'you tend to have balanced beliefs and values regarding who males and females are, and how they ' +
        'should be; you are unconvinced that men and women are simply "built to be a certain way."  You ' +
        'may acknowledge that there are differences between men and women, but recognize that the most ' +
        'important question is why such differences are there in the first place?</b> For example, ' +
        'are these differences due to the "environment" or "genetics" or some combination of both? ' +
        'There is evidence on both sides, which suggests that differences between men and women are due ' +
        'to a complex interaction among cultural and upbringing processes as well as underlying biological ' +
        'differences.  It is important to recognize that biological explanations for these differences ' +
        'have been used as a way to deny girls and women many rights over the years, from education to ' +
        'voting to control over their own sexuality.  However, stereotypes can cut both ways.  For ' +
        'example, males may be seen as less able to nurture or express feelings, and thus less capable ' +
        'of childrearing.  In reflecting upon such issues, it\'s important to read and learn more about ' +
        'what we do - and do not - know about gender differences, especially because many scholars ' +
        'contend that females and males are more similar than different as human beings.',
    'app.individualreport.gendertradition.textC': '<b>Second, in terms of gender, ' +
        'you tend to have more progressive or liberal beliefs and values regarding who males and females ' +
        'are, and how they should be; you likely disagree that men and women simply are "built to be a ' +
        'certain way."  You recognize that there are differences between men and women; we all know and ' +
        'see the evidence for that fact on a daily basis.  But the question is why such differences are ' +
        'there in the first place? For example, are these differences due to the "environment" ' +
        'or "genetics" or some combination of both?  From your responses, you tend to believe that gender ' +
        'differences are probably more likely due to underlying cultural or environmental factors.</b> ' +
        'Although scholarship on this topic is highly complex, there is evidence on both sides of the ' +
        'aisle, which suggests that differences between men and women are due to a complex interaction ' +
        'among cultural and upbringing processes as well as underlying biological differences.  At the ' +
        'same time, however, these differences have been used as a way to deny girls and women many ' +
        'rights over the years, from education to voting to control over their own sexuality, and that ' +
        '"biological arguments" have often been employed to justify denial of such rights to females. ' +
        'Stereotypes can cut both ways, however, as boys and men may be seen as "less capable" of ' +
        'nurturance or emotion, which means they may be denied not only the "right" to have feelings ' +
        'and experience needs, in themselves and others, but may be denied equality when it comes to ' +
        'childrearing, for example, or other roles that have historically been assigned to girls and ' +
        'women.  In reflecting upon such issues, it is important to understand that arguments made on ' +
        'the basis of assumed biological differences have been used to limit opportunities and ways of ' +
        'being for females and males, with consequences for us all, at an individual and societal level. ' +
        'Thus, it is important to read and learn more about what we do - and do not - know about gender ' +
        'differences, as many scholars believe that females and males are more similar than different as ' +
        'human beings.',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '<b>Third, in terms of cultural and ' +
        'political matters, you are likely to be described as accepting, open, liberal, and progressive. ' +
        'Such ways of thinking and being can be wonderful, as you likely anticipate eagerly an encounter ' +
        'with ideas, approaches, circumstances, people, or societies that are new or different, and to ' +
        'feel a sense of exhilaration when doing so.  You may also seek to promote change in your life ' +
        'and work, and society at large, and feel irritation with others who do not share these same ' +
        'convictions.</b> Again, it is important to understand why others may not think or feel the ' +
        'same way that you do. For example, different systems, cultures, or ways of being may be a ' +
        'source of anxiety for some people, as the beliefs and values of these systems, cultures, or ' +
        'ways of being may be experienced as challenging to their worldview.  If you believe differently ' +
        'in this regard, it may be tempting to dismiss individuals who are uncomfortable in such ' +
        'situations as closed-minded or simplistic.  Instead, seek to learn more about the backgrounds ' +
        'and experiences of individuals who think and feel so differently from you.  They may be more ' +
        'open than they appear to be, but are struggling to figure out how to make sense of beliefs and ' +
        'values that are different from everything to which they were accustomed.  In such an encounter, ' +
        'it may be possible to understand better why they, and all of us, come to believe and value that ' +
        'which we do in the first place. ',
    'app.individualreport.variables.textE_2': '',
    'app.individualreport.variables.textF': '<b>Third, in terms of cultural and ' +
        'political matters, you are likely to be described as more "moderate" or "middle of the road" than ' +
        'some of your peers, who may seem a little - or even a lot - more liberal or conservative than you. ' +
        'There are benefits of this stance, since you may be inclined toward compromise, or to see the ' +
        'value in perspectives or ideas that seem to be odds.  On the other hand, such a stance also may ' +
        'mean that you haven\'t yet examined such issues in depth, feel relatively indifferent, or simply ' +
        'don\'t know yet what you believe in this regard.</b> To the degree to which this perspective ' +
        'fits you in terms of where you are now, it may be helpful to reflect deeply and frequently about ' +
        'why you believe what you believe about issues of culture, politics, race, or gender.  Finding ' +
        'ways to talk with friends and colleagues who seem more strident than you about why they believe ' +
        'what they believe may not only be interesting, but help you clarify more deeply the basis for ' +
        'your own worldviews.  Moreover, you may find that your own stance represents an interesting ' +
        'opportunity for those who are more "convinced" than you to reflect upon their own reasons for ' +
        'such strongly held convictions.  Either way, such encounters with self and others are likely to ' +
        'clarify what you believe and value about these crucial matters that affect us all.',
    'app.individualreport.variables.textG_1': '<b>Third, in terms of cultural and ' +
        'political matters, you may tend to be described as more traditional, careful, or cautious than ' +
        'your peers regarding the need for, or value of, rapid change, either locally or globally.  You ' +
        'may feel like time-tested ways of working are being questioned or even undermined, without ' +
        'sufficient consideration of the consequences of such change (e.g., for the family, workplace, ' +
        'society), or the financial costs of corresponding social programs.  It is possible that you may ' +
        'feel irritation with others who do not share these same convictions, and may seem na&iuml;ve or ' +
        'irresponsible in their advocacy efforts.</b> Again, it is important to understand why others ' +
        'may not think or feel the same way that you do.  For example, others may believe that ' +
        '"traditional" ways of believing and being are simply ways to control those who have less power, ' +
        'resources, or rights.  Such beliefs may, for them, have a basis in their own life history or ' +
        'circumstances that they, or others they know, have experienced.  If you believe differently in ' +
        'this regard, it may be tempting to dismiss such individuals as complaining or simplistic.  ' +
        'Instead, seek to learn more about the backgrounds and experiences of individuals who think and ' +
        'feel so differently from you.  They may be more open to your beliefs than you think they will be, ' +
        'and more likely to engage with you in deep and honest debate - if conducted in a respectful and ' +
        'good faith manner - than you might imagine.  Perhaps they even will be compelled by the wisdom of ' +
        'your perspective, just as you might be persuaded by the relevance of theirs.  Ultimately, such ' +
        'encounters with "the other" can promote good will and mutual respect, while helping us all ' +
        'understand better why they, and all of us, come to believe and value that which we do in the ' +
        'first place.',
    'app.individualreport.variables.textG_2': '',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>In terms of environmental ' +
        'attitudes, you appear to have a deep affinity for and connection to the Earth, tending to see our ' +
        'planet as our only home, which must be protected and preserved. Thus, it may be frustrating to ' +
        'you that so many others don\'t seem to share these convictions, particularly when the evidence ' +
        'seems so clear that we must do a better job than we are of caring for the living  world.</b> ' +
        'In reflecting upon these issues, it is important to remember that different groups, cultures, and ' +
        'religions may view the relationship between human beings, nature, and the Earth in different ' +
        'terms for different reasons that seem entirely legitimate to them. It is important to consider ' +
        'the basis of such differences among us and why we tend to feel so deeply about these issues, one ' +
        'way or another. That is because our ecological beliefs and values - and the way individuals, ' +
        'organizations, governments, and societies experience, understand, and relate to naturethe natural ' +
        'world - have deep implications for environmental actions, policies, and practices around the ' +
        'globe.  To the degree that you can, try to engage others in respectful and open conversation ' +
        'about such matters, and not only by appealing to "the facts" as you understand them.  In other ' +
        'words, share your own convictions, not just from an intellectual standpoint - which is crucial of ' +
        'course - but also in terms of why you feel as you do.  Such an approach is more likely to promote ' +
        'genuine reflection about environmental issues, which may seem abstract, but actually are intensely ' +
        'value-laden, residing as they do at the "heart" of why we believe we are here, and what our ' +
        'obligations are - or are not - to the natural world.',
    'app.individualreport.ecologicalresonance.textB': '<b>In terms of environmental ' +
        'attitudes, you appear to be open to arguments regarding the need to protect the Earth\'s natural ' +
        'resources and would likely describe yourself as  a "moderate" regarding environmental issues more ' +
        'generally. Thus, you may wonder at the intensity of people who believe strongly - one way or ' +
        'another - on such matters.</b> Such differences offer an opportunity for you to learn more ' +
        'about your own beliefs in this regard, and perhaps clarify the basis for your convictions (or ' +
        'lack thereof) about these important issues.  In reflecting upon these issues, it is important to ' +
        'remember that different groups, cultures, and religions may view the relationship between human ' +
        'beings, nature, and the Earth in different terms for different reasons that seem entirely ' +
        'legitimate to them. It also may be helpful to consider the basis of such differences among us ' +
        'and why we tend to feel so deeply about these issues, one way or another.  That is because our ' +
        'ecological beliefs and values - and the way individuals, organizations, governments, and societies ' +
        'experience, understand, and relate to nature - have deep implications for environmental actions, ' +
        'policies, and practices around the globe.  To the degree that you can, try to engage others in ' +
        'respectful and open conversation about such matters, and not only by appealing to "the facts" as ' +
        'you understand them. In other words, share your own convictions, not just from an intellectual ' +
        'standpoint - which is crucial of course - but also in terms of why you feel as you do.  Such an ' +
        'approach is more likely to promote genuine reflection about environmental issues, which may seem ' +
        'abstract, but actually are intensely value-laden, residing as they do at the "heart" of why we ' +
        'believe we are here, and what our obligations are - or are not - to the natural world.',
    'app.individualreport.ecologicalresonance.textC': '<b>In terms of environmental ' +
        'attitudes, you appear to be skeptical in general of those who claim a deep affinity for and ' +
        'connection to the Earth as well as those who express concerns about the state of our natural ' +
        'world. If you tend to be skeptical of environmental claims, it may be a good idea to ' +
        'encounter perspectives that strive to be impartial, and to see what science is telling us now ' +
        'about these vastly important - albeit highly complex - issues. It may also be worth noting ' +
        'that many different groups around the world - including religious or faith-based organizations ' +
        '- are increasingly recognizing the importance of stewardship for the Earth and the natural world.</b> ' +
        'In reflecting upon these issues, it is important to remember that different groups, cultures, ' +
        'and religions may view the relationship between human beings, nature, and the Earth in different ' +
        'terms for different reasons that seem entirely legitimate to them. It is important to consider ' +
        'the basis of such differences among us and why we tend to feel so deeply about these issues, one ' +
        'way or another.  That is because our ecological beliefs and values - and the way individuals, ' +
        'organizations, governments, and societies experience, understand, and relate to the natural ' +
        'world - have deep implications for environmental actions, policies, and practices around the ' +
        'globe.  To the degree that you can, try to engage others in respectful and open conversation ' +
        'about such matters, and not only by appealing to "the facts" as you understand them.  In other ' +
        'words, share your own convictions, not just from an intellectual standpoint - which is crucial ' +
        'of course - but also in terms of why you feel as you do.  Such an approach is more likely to ' +
        'promote genuine reflection about environmental issues, which may seem abstract, but actually are ' +
        'intensely value-laden, residing as they do at the "heart" of why we believe we are here, and ' +
        'what our obligations are - or are not - to the natural world.',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': 'Our final "big picture" issue is your tendency towards ' +
        'global engagement or resonance in the broadest sense. <b>Overall, ' +
        'you appear to endorse strongly the need for understanding of, and respectful relations toward, ' +
        'cultures, societies, and traditions than are different from our own.  Such a worldview suggests ' +
        'that you are likely to embrace intercultural and global opportunities and experiences whenever ' +
        'possible.</b> Whatever your stance in this regard, it is important to reflect on the fact ' +
        'that the world is increasingly interconnected. For example, events in one country (e.g., ' +
        'economic, political, environmental) may impact circumstances in another country even if the vast ' +
        'majority of people within each country have little direct contact with one another.  Moreover, ' +
        'through technology, we are able to encounter and influence world events right as they happen.  ' +
        'Thus, our beliefs and values about others (e.g., different cultures, religions) are inevitably ' +
        'linked to how we experience and contend with ourselves and one another - as well as how we ' +
        'understand and respond to various challenges and opportunities around the globe - with ' +
        'corresponding implications for us all.',
    'app.individualreport.globalengagement.textB': 'Our final "big picture" issue is your tendency towards ' +
        'global engagement or resonance in the broadest sense. <b>Overall, ' +
        'you appear to appreciate the need for understanding of, and respectful relations toward, cultures, ' +
        'societies, and traditions than are different from our own. Such a worldview suggests that you ' +
        'also would be curious about intercultural and global opportunities if and when they arise.</b>' +
        ' Whatever your stance in this regard, it is important to reflect on the fact that the ' +
        'world is increasingly interconnected. For example, events in one country (e.g., economic, ' +
        'political, environmental) may impact circumstances in another country even if the vast majority ' +
        'of people within each country have little direct contact with one another.  Moreover, through ' +
        'technology, we are able to encounter and influence world events right as they happen.  Thus, ' +
        'our beliefs and values about others (e.g., different cultures, religions) are inevitably linked ' +
        'to how we experience and contend with ourselves and one another - as well as how we understand ' +
        'and respond to various challenges and opportunities around the globe - with corresponding ' +
        'implications for us all.',
    'app.individualreport.globalengagement.textC': 'Our final "big picture" issue is your tendency towards ' +
        'global engagement or resonance in the broadest sense. <b>Overall, you ' +
        'appear to question whether we sometimes (or even frequently) go too far in our attempt to engage, ' +
        'tolerate, or appease cultures, societies, and traditions than are different from our own.</b> ' +
        'Whatever your stance in this regard, it is important to reflect on the fact that the world is ' +
        'increasingly interconnected.   For example, events in one country (e.g., economic, political, ' +
        'environmental) may impact circumstances in another country even if the vast majority of people ' +
        'within each country have little direct contact with one another.  Moreover, through technology, ' +
        'we are able to encounter and influence world events right as they happen.  Thus, our beliefs and ' +
        'values about others (e.g., different cultures, religions) are inevitably linked to how we ' +
        'experience and contend with ourselves and one another - as well as how we understand and respond ' +
        'to various challenges and opportunities around the globe - with corresponding implications for ' +
        'us all.',
}

const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': 'a low',
    'app.individualreport.negativelifeevents.moderate': 'a moderate',
    'app.individualreport.negativelifeevents.high': 'a high',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': 'met well',
    'app.individualreport.needsclosure.met_at_times': 'met at times but not consistently',
    'app.individualreport.needsclosure.not_met_well': 'not met well',
}

export const individualReportPageEN: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle, ...individualReportVariables,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};