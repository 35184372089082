import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': 'Alter',
    'app.demographic.gender': 'Geschlecht',
    'app.demographic.marital_status': 'Familienstand',
    'app.demographic.level_of_fluency': 'Bitte geben Sie an, wie gut Sie eine andere Sprache als Ihre Muttersprache (die in Ihrem Zuhause und/oder Ihrer Gemeinde gesprochen wird und/oder die Unterrichtssprache in der/den von Ihnen besuchten Schule(n)) beherrschen.',
    'app.demographic.education_years': `Bitte geben Sie an, wie viele Jahre formaler Bildung Sie abgeschlossen haben.`,
    'app.demographic.raised_in_country_where_currently_in': 'Wie viele Jahre formaler Bildung haben Sie absolviert, beginnend mit der Grundschulbildung (z. B. nach Kindergarten oder Vorschule):',
    'app.demographic.ethnic': 'Was ist Ihr ethnischer Hintergrund?',
    'app.demographic.ethnic_other': 'Bitte im dafür vorgesehenen Feld beschreiben:',
    'app.demographic.ethnic_asian': `Asiatisch. Eine Person, die aus Ländern oder Regionen des Fernen Ostens, des indischen Subkontinents oder Südostasiens stammt, z. B. aus Kambodscha, China, Indien, Japan, Korea, Malaysia, Pakistan, den Philippinen, Thailand und Vietnam.`,
    'app.demographic.ethnic_african': 'Schwarz oder Afrikanisch. Eine Person, die von einer der schwarzen ethnischen Volksgruppen Afrikas abstammt.',
    'app.demographic.ethnic_latino': `Hispanic oder Latino. Eine Person, die aus Ländern oder Regionen wie z. B. Kuba, Mexiko, Puerto Rico, Süd- oder Mittelamerika oder einer anderen spanischen Kultur oder Herkunft stammt, unabhängig von ihrer ethnischen Volksgruppe.`,
    'app.demographic.ethnic_indigenous': `Eingeborene:r. Eine Person, die aus einem der ursprünglichen Völker eines bestimmten Landes oder einer bestimmten Region stammt und eine Zugehörigkeit oder Bindung zu einem indigenen Stamm oder einer indigenen Gemeinschaft hat.`,
    'app.demographic.ethnic_middle_eastern': `Personen aus Nahost. Eine Person, die aus einem der Länder oder Regionen von Afghanistan bis Libyen stammt, einschließlich z. B. Ägypten, Iran, Irak, Israel, Jordanien, Libanon, Saudi-Arabien, Sudan, Syrien, Türkei oder Länder der arabischen Halbinsel.`,
    'app.demographic.ethnic_white': 'Kaukasisch. Eine Person, die von einer der kaukasischen Volksgruppen Europas abstammt.',
    'app.demographic.completing_bevi': 'Bitte geben Sie an, wann Sie das BEVI ausfüllen.',
    'app.demographic.completing_bevi_other': 'Bitte beschreiben Sie im Folgenden die Umstände, unter denen Sie das BEVI ausfüllen.',
    'app.demographic.previously_completed': 'Haben Sie das BEVI schon einmal ausgefüllt?',
    'app.demographic.disadvantaged_or_minority_group': 'Gehören Sie Ihrer Erfahrung nach einer marginalisierten oder benachteiligten Gruppe an?',
    'app.demographic.disadvantaged_or_minority_group_yes':'Bitte geben Sie in dem dafür vorgesehenen Feld zusätzliche Informationen an.',
    'app.demographic.primary_area_of_work': 'Welche der nachstehenden Optionen entspricht am ehesten Ihrer Studienrichtung oder Ihrem primären Arbeitsbereich/beruflichen Interesse?',
    'app.demographic.current_area': 'Bitte geben Sie in dem dafür vorgesehenen Feld Ihre derzeitige(n) Studienrichtung(en) oder Beruf(e) an:',
    'app.demographic.political_orientation':'Benutzen Sie den nachstehenden Schieberegler, um Ihre politische Orientierung zwischen "Konservativ/Traditionell anzugeben (z.B. Befürwortung der Beibehaltung oder Bewahrung bestehender Gesetze, Systeme und Lebensweisen)" und "Liberal/Progressiv (z.B. Befürwortung der Änderung oder Reform bestehender Gesetze, Systeme und Lebensweisen)"',
    'app.demographic.political_commitment':'Bitte geben Sie an, inwieweit Sie sich persönlich für Ihre politische Ausrichtung engagieren.',
    'app.demographic.political_orientation_conservative_label':'Konservativ/Traditionell',
    'app.demographic.political_orientation_liberal_label': 'Liberal/Progressiv',
    'app.demographic.religion': 'Welche der folgenden Optionen entspricht am ehesten Ihrer derzeitigen Überzeugungen oder Verpflichtungen in Bezug auf Religion oder Spiritualität?',
    'app.demographic.religion_other': 'Bitte im dafür vorgesehenen Feld beschreiben:',
    'app.demographic.religion_scale': 'Bitte geben Sie an, inwieweit Sie sich persönlich für Religion oder Spiritualität engagieren.',
    'app.demographic.religion_scale_specified':'Bitte geben Sie an, inwieweit Sie sich persönlich für {0} engagieren',
    'app.demographic.income_level': 'Benutzen Sie bitte den Schieberegler unten, um Ihr Einkommen oder Ihren Zugang zu Ressourcen im Vergleich zu anderen in Ihrer Gesellschaft anzugeben (basierend auf einer Skala von "niedrigste Stufe" bis "höchste Stufe").',
    'app.demographic.income_less_label':'Niedrigste Stufe',
    'app.demographic.income_more_label':'Höchste Stufe',
    'app.demographic.academic_rank_lowest_label':'Niedrigste Stufe',
    'app.demographic.academic_rank_highest_label':'Höchste Stufe',
    'app.demographic.academic_rank':'Schätzen Sie bitte so gut wie möglich Ihre akademische Stellung oder Ihren Stand im Vergleich zu anderen Studierenden ein, die in Ihrer jüngsten Studienzeit mit Ihnen in einer Lehrveranstaltung waren (basierend auf einer Skala von "niedrigste Stufe" bis "höchste Stufe").',
    'app.demographic.primary_country':'Aus welchem Land kommen Sie ursprünglich?',
    'app.demographic.time_in_other_countries':'Bitte geben Sie an, wie viel Zeit Sie in anderen Ländern/Regionen außer Ihrem Herkunftsland verbracht haben.',
    'app.demographic.time_in_other_countries_other':'Bitte spezifizieren',
    'app.demographic.visit_countries':'In wie vielen anderen Ländern/Regionen außer Ihrem Herkunftsland haben Sie bereits gelebt bzw. wie viele andere Ländern/Regionen außer Ihrem Herkunftsland haben Sie bereits besucht?',
    'app.demographic.country_explanation_field':'Um Ihr Land zu finden, können Sie entweder das Land aus dem Dropdown-Menü auswählen oder es einfach eintippen und die Sprache auswählen.',
    'app.demographic.country_mother_born':'Bitte geben Sie das Land/die Region an, in dem/der Ihre Mutter geboren wurde:',
    'app.demographic.country_father_born':'Bitte geben Sie das Land/die Region an, in dem/der Ihr Vater geboren wurde:',
    'app.demographic.personal_interest':'Bitte geben Sie an,inwieweit Sie persönlich an internationalen, multikulturellen oder interkulturellen Erfahrungen interessiert sind (z.B. Arbeit, Studium oder Dienst im Ausland; virtueller Austausch (VE) oder Kollaboratives Internationales Online-Lernen (COIL); multikulturelle Kursarbeit; Wirken in diversen Teams).',
    'app.demographic.participated_experiences':'Haben Sie bereits eine der folgenden Erfahrungen gemacht oder machen Sie diese gerade? (Klicken Sie alle zutreffenden Angaben an)',
    'app.demographic.participated_learning_courses':'Haben Sie bereits einen der folgenden Kurse/Programme zum Thema Globales Lernen und Vielfalt absolviert oder absolvieren Sie derzeit einen solchen? (Klicken Sie alle zutreffenden Angaben an)',
    'app.demographic.plan_participate_experiences':'Haben Sie vor, eine der folgenden Erfahrungen zu machen? (Klicken Sie alle zutreffenden Punkte an)',
    'app.demographic.plan_participate_learning_courses':'Haben Sie vor, einen der folgenden Kurse/Programme zum Thema Globales Lernen und Vielfalt zu absolvieren? (Klicken Sie alle zutreffenden Angaben an)',
    'app.demographic.experience_satisfaction':'Wenn Sie an internationalen, multikulturellen oder interkulturellen Erfahrungen teilgenommen haben oder derzeit teilnehmen (z.B. Arbeit, Studium oder Dienst im Ausland; virtueller Austausch (VE) oder kollaboratives internationales Online-Lernen (COIL); multikulturelle Kursarbeit; Teilnahme an verschiedenen Teams), geben Sie bitte an, wie zufrieden Sie insgesamt mit diesen Erfahrungen sind.',
    'app.demographic.languages_speak':'Wie viele Sprachen sprechen Sie als Muttersprache (in Ihrem Zuhause und/oder in Ihrer Gemeinde und/oder als Unterrichtssprache in der/den von Ihnen besuchten Schule(n))?',
    'app.demographic.years_studied_foreign_language':'Wie viele Jahre haben Sie insgesamt eine oder mehrere Fremdsprachen gelernt?',
    'app.demographic.primary_language':'Was ist Ihre Hauptsprache (d.h. die erste oder Hauptsprache, die Sie sprechen)? Sie können mehr als eine Sprache auswählen, wenn Sie mehr als eine Hauptsprache haben. Um Ihre Sprache zu finden, können Sie entweder die Sprache(n) aus dem Dropdown-Menü auswählen oder einfach die Sprache(n) eintippen und auswählen.',
    'app.demographic.primary_language_other': 'Sprechen Sie eine andere Sprache als Ihre Muttersprache (d. h. Ihre erste oder primäre Sprache)?',
    'app.demographic.language_level_of_fluency': 'Bitte wählen Sie aus den nachstehenden Optionen die Sprache(n) aus, die Sie sprechen und geben Sie an, wie gut Sie diese Sprachen beherrschen (Flüssigkeit).',
    'app.demographic.language_level_of_fluency_add_anwser': 'Hinzufügen',
    'app.demographic.table_level_of_fluency': 'Sprachniveau/Flüssigkeit',
    'app.demographic.table_language': 'Sprache',
    'app.demographic.table_action': 'Aktion',
    'app.demographic.remove_language_tooltip': 'Sprache entfernen',
    'app.demographic.father_formal_education': 'Bitte geben Sie den höchsten formalen Bildungsabschluss Ihres Vaters an:',
    'app.demographic.mother_formal_education': 'Bitte geben Sie den höchsten formalen Bildungsabschluss Ihrer Mutter an:',
    'app.demographic.comparative': 'Vergleichender Einzelbericht',
    'app.demographic.compare': 'Vergleichen',
    'app.demographic.select_multiple_reports': 'Wählen Sie mehrere Berichte aus',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanisch',
    'app.demographic.language_options.Amharic': 'Amharisch',
    'app.demographic.language_options.Arabic': 'Arabisch',
    'app.demographic.language_options.Armenian': 'Armenisch',
    'app.demographic.language_options.Basque': 'Baskisch',
    'app.demographic.language_options.Bengali': 'Bengali',
    'app.demographic.language_options.Byelorussian': 'Belorussisch',
    'app.demographic.language_options.Burmese': 'Birmanisch',
    'app.demographic.language_options.Bulgarian': 'Bulgarisch',
    'app.demographic.language_options.Catalan': 'Katalanisch',
    'app.demographic.language_options.Czech': 'Tschechisch',
    'app.demographic.language_options.Chinese': 'Chinesisch',
    'app.demographic.language_options.Croatian': 'Kroatisch',
    'app.demographic.language_options.Danish': 'Dänisch',
    'app.demographic.language_options.Dari': 'Dari',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Niederländisch',
    'app.demographic.language_options.English': 'Englisch',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estnisch',
    'app.demographic.language_options.Faroese': 'Färöisch',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finnisch',
    'app.demographic.language_options.French': 'Französisch',
    'app.demographic.language_options.Gaelic': 'Gälisch',
    'app.demographic.language_options.Galician': 'Galicisch',
    'app.demographic.language_options.German': 'Deutsch',
    'app.demographic.language_options.Greek': 'Griechisch',
    'app.demographic.language_options.Hebrew': 'Hebräisch',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Ungarisch',
    'app.demographic.language_options.Icelandic': 'Isländisch',
    'app.demographic.language_options.Indonesian': 'Indonesisch',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Eskimo)',
    'app.demographic.language_options.Italian': 'Italiänisch',
    'app.demographic.language_options.Japanese': 'Japanisch',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Koreanisch',
    'app.demographic.language_options.Kurdish': 'Kurdisch',
    'app.demographic.language_options.Laotian': 'Laotisch',
    'app.demographic.language_options.Latvian': 'Lettisch',
    'app.demographic.language_options.Lappish': 'Lappländisch',
    'app.demographic.language_options.Lithuanian': 'Litauisch',
    'app.demographic.language_options.Macedonian': 'Mazedonisch',
    'app.demographic.language_options.Malay': 'Malaiisch',
    'app.demographic.language_options.Maltese': 'Maltesisch',
    'app.demographic.language_options.Nepali': 'Nepali',
    'app.demographic.language_options.Norwegian': 'Norwegisch',
    'app.demographic.language_options.Pashto': 'Paschtunisch',
    'app.demographic.language_options.Polish': 'Polnisch',
    'app.demographic.language_options.Portuguese': 'Portugiesisch',
    'app.demographic.language_options.Romanian': 'Rumänisch',
    'app.demographic.language_options.Russian': 'Russisch',
    'app.demographic.language_options.Scots': 'Scots',
    'app.demographic.language_options.Serbian': 'Serbisch',
    'app.demographic.language_options.Slovak': 'Slovakisch',
    'app.demographic.language_options.Slovenian': 'Slovenisch',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Spanisch',
    'app.demographic.language_options.Swedish': 'Schwedisch',
    'app.demographic.language_options.Swahili': 'Swahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalog',
    'app.demographic.language_options.Tajik': 'Tadschikisch',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Thailändisch',
    'app.demographic.language_options.Tibetan': 'Tibetisch',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tongaisch',
    'app.demographic.language_options.Turkish': 'Türkisch',
    'app.demographic.language_options.Turkmen': 'Turkmenisch',
    'app.demographic.language_options.Ucrainian': 'Ukrainisch',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Usbekisch',
    'app.demographic.language_options.Vietnamese': 'Vietnamesisch',
    'app.demographic.language_options.Welsh': 'Walisisch',
    'app.demographic.language_options.Other': 'Andere',
    'app.demographic.gender.male': 'Männlich',
    'app.demographic.gender.female': 'Weiblich',
    'app.demographic.gender.self_identify': 'Selbstgewählte Beschreibung',
    'app.demographic.marital.response_single': 'Single',
    'app.demographic.marital.response_married': 'Verheiratet',
    'app.demographic.marital.response_divorced': 'Geschieden',
    'app.demographic.marital.response_widowed': 'Verwitwet',
    'app.demographic.marital.response_other': 'Andere',
    'app.demographic.education_years.response_1': 'Keine formale Ausbildung.',
    'app.demographic.education_years.response_2': '1 Jahr',
    'app.demographic.education_years.response_3': '2 Jahre',
    'app.demographic.education_years.response_4': '3 Jahre',
    'app.demographic.education_years.response_5': '4 Jahre',
    'app.demographic.education_years.response_6': '5 Jahre',
    'app.demographic.education_years.response_7': '6 Jahre',
    'app.demographic.education_years.response_8': '7 Jahre',
    'app.demographic.education_years.response_9': '8 Jahre',
    'app.demographic.education_years.response_10': '9 Jahre',
    'app.demographic.education_years.response_11': '10 Jahre',
    'app.demographic.education_years.response_12': '11 Jahre',
    'app.demographic.education_years.response_13': '12 Jahre',
    'app.demographic.education_years.response_14': '13 Jahre',
    'app.demographic.education_years.response_15': '14 Jahre',
    'app.demographic.education_years.response_16': '15 Jahre',
    'app.demographic.education_years.response_17': '16 Jahre',
    'app.demographic.education_years.response_18': '17 Jahre',
    'app.demographic.education_years.response_19': '18 Jahre',
    'app.demographic.education_years.response_20': '19 Jahre',
    'app.demographic.education_years.response_21': '20 Jahre',
    'app.demographic.education_years.response_22': '21 Jahre',
    'app.demographic.education_years.response_23': '22 Jahre',
    'app.demographic.education_years.response_24': '23 Jahre',
    'app.demographic.education_years.response_25': '24 Jahre',
    'app.demographic.education_years.response_26': '25 Jahre',
    'app.demographic.education_years.response_27': '26 Jahre',
    'app.demographic.education_years.response_28': '27 Jahre',
    'app.demographic.education_years.response_29': '28 Jahre',
    'app.demographic.education_years.response_30': '29 Jahre',
    'app.demographic.education_years.response_31': '30 Jahre',
    'app.demographic.education_years.response_32': 'Über 30 Jahre',
    'app.demographic.ethnic.respose_asian': 'Asiate/in',
    'app.demographic.ethnic.respose_african': 'Schwarz oder Afrikaner/in',
    'app.demographic.ethnic.respose_latino': 'Hispanoamerikanisch oder Latino/a',
    'app.demographic.ethnic.respose_indigenous': 'Indigen',
    'app.demographic.ethnic.respose_middle_eastern': 'Nahöstlich',
    'app.demographic.ethnic.respose_white': 'Weiß oder Kaukasier/in',
    'app.demographic.ethnic.respose_mixed': 'Gemischt',
    'app.demographic.ethnic.respose_other': 'Andere',
    'app.demographic.completing_bevi.response_before': 'Vor (am Anfang) einer internationalen/interkulturellen Lernerfahrung.',
    'app.demographic.completing_bevi.response_during': 'Während (oder mitten in) einer internationalen/interkulturellen Lernerfahrung.',
    'app.demographic.completing_bevi.response_after': 'Nach (oder am Ende) einer internationalen/interkulturellen Lernerfahrung.',
    'app.demographic.completing_bevi.response_during_academic': 'Während der akademischen Orientierung und nicht bei einer der oben genannten Optionen.',
    'app.demographic.completing_bevi.response_end_graduation': 'Am Ende des Studiums an der Universität (noch nicht Absolvent:in).',
    'app.demographic.completing_bevi.response_other': 'Andere - bitte spezifizieren.',
    'app.demographic.primary_area_of_work.response_1': 'Kunst, Kommunikation, Geisteswissenschaften',
    'app.demographic.primary_area_of_work.response_2': 'Verhalten, Kultur, Gesellschaft',
    'app.demographic.primary_area_of_work.response_3': 'Wirtschaft',
    'app.demographic.primary_area_of_work.response_4': 'Erziehungswissenschaften',
    'app.demographic.primary_area_of_work.response_5': 'Gesundheits- und Sozialwesen',
    'app.demographic.primary_area_of_work.response_6': 'Wissenschaft und Technologie',
    'app.demographic.primary_area_of_work.response_7': 'Andere',
    'app.demographic.political_orientation.response_1': 'konservativ/traditionell (z. B. Befürwortung der Beibehaltung oder Bewahrung bestehender Gesetze, Systeme und Lebensweisen)',
    'app.demographic.political_orientation.response_2': 'Eher konservativ als liberal',
    'app.demographic.political_orientation.response_3': 'Gemäßigt/neutral/ weder konservativ noch liberal',
    'app.demographic.political_orientation.response_4': 'Eher liberal als konservativ',
    'app.demographic.political_orientation.response_5': 'Liberal/progressiv (z. B. Unterstützung von Änderungen oder Reformen der bestehenden Gesetze, Systeme und Lebensweisen)',
    'app.demographic.political_orientation.response_6': 'Andere',
    'app.demographic.religion.response_1': 'Traditionelle afrikanische Religion',
    'app.demographic.religion.response_2': 'Agnostizismus',
    'app.demographic.religion.response_3': 'Atheismus',
    'app.demographic.religion.response_4': 'Bahaitum',
    'app.demographic.religion.response_5': 'Buddhismus',
    'app.demographic.religion.response_6': 'Caodaismus',
    'app.demographic.religion.response_7': 'Chinesischer Volksglaube',
    'app.demographic.religion.response_8': 'Christentum',
    'app.demographic.religion.response_9': 'Hinduismus',
    'app.demographic.religion.response_10': 'Indigen',
    'app.demographic.religion.response_11': 'Islam',
    'app.demographic.religion.response_12': 'Jainismus',
    'app.demographic.religion.response_13': 'Chuch’e',
    'app.demographic.religion.response_14': 'Judentum',
    'app.demographic.religion.response_15': 'Heidentum',
    'app.demographic.religion.response_16': 'Shintō',
    'app.demographic.religion.response_17': 'Sikhismus',
    'app.demographic.religion.response_18': 'Spiritismus',
    'app.demographic.religion.response_19': 'Spirituell, aber keine spezifische religiöse Zugehörigkeit',
    'app.demographic.religion.response_20': 'Tenrikyō',
    'app.demographic.religion.response_21': 'Unitarismus',
    'app.demographic.religion.response_22': 'Zoroastrismus',
    'app.demographic.religion.response_other': 'Andere',
    'app.demographic.religion.no_response': 'Ich möchte diese Frage lieber nicht beantworten',
    'app.demographic.income_level.response_1': 'Oberes Drittel',
    'app.demographic.income_level.response_2': 'Mittleres Drittel',
    'app.demographic.income_level.response_3': 'Unteres Drittel',
    'app.demographic.academic_rank.response_1': 'Oberes Drittel',
    'app.demographic.academic_rank.response_2': 'Mittlere Drittel',
    'app.demographic.academic_rank.response_3': 'Unteres Drittel',
    'app.demographic.participated_experiences.response_1': 'Seminarkurs speziell für Studierende im 1. Studienjahr / Studienanfänger:innen',
    'app.demographic.participated_experiences.response_2': 'Mitglied einer von der Universität organisierten Lerngemeinschaft',
    'app.demographic.participated_experiences.response_3': 'Schreibintensiver Kurs',
    'app.demographic.participated_experiences.response_4': 'Forschungsprojekt mit einem Fakultäts- oder Fachmentor:in',
    'app.demographic.participated_experiences.response_5': 'Von der Universität gesponsertes/genehmigtes Service-Learning- oder gemeinschaftsbasiertes Projekt',
    'app.demographic.participated_experiences.response_6': 'Von der Universität gefördertes/genehmigtes Praktikum',
    'app.demographic.participated_experiences.response_7': 'Abschlussprojekt',
    'app.demographic.participated_experiences.response_8': 'Gemeinsame oder gemeinsam genutzte erforderliche Kurse',
    'app.demographic.participated_experiences.response_9': 'Gemeinsame Aufgaben oder Projekte mit anderen',
    'app.demographic.participated_experiences.response_10': 'Kurse zum Thema Diversity/Globales Lernen oder Auslandsstudium',
    'app.demographic.participated_experiences.response_11': 'Elektronisches Portfolio (E-Portfolio) Ihrer Bildungsarbeit oder Erfahrungen',
    'app.demographic.participated_experiences.response_12': 'Online-Lernen (z. B. virtuell oder webbasiert) <b>mit</b> internationalem, multikulturellem oder interkulturellem Schwerpunkt.',
    'app.demographic.participated_experiences.response_13': 'Online-Lernen (z. B. virtuell oder webbasiert), das <b>keinen</b> internationalen, multikulturellen oder interkulturellen Schwerpunkt hat.',
    'app.demographic.participated_learning_courses.response_1': 'Belegung eines Kurses mit einem multikulturellen oder globalen Schwerpunkt',
    'app.demographic.participated_learning_courses.response_2': 'Teilnahme an extracurricularen multikulturellen, internationalen und kulturellen Veranstaltungen an der Universität',
    'app.demographic.participated_learning_courses.response_3': 'Abgeschlossenes Service-Learning im Ausland',
    'app.demographic.participated_learning_courses.response_4': 'Ein Praktikum im Ausland absolviert',
    'app.demographic.participated_learning_courses.response_5': 'Im Ausland studiert',
    'app.demographic.participated_learning_courses.response_6': 'In einer Wohn- und Lerngemeinschaft mit Schwerpunkt auf internationalem, multikulturellem, globalem oder sprachlichem Lernen gelebt',
    'app.demographic.participated_learning_courses.response_7': 'Eine Sprache studiert, die nicht meine Muttersprache ist.',
    'app.demographic.participated_learning_courses.response_8': 'Mit einer/m Mitbewohner:in gelebt, die/der eine andere kulturelle Tradition oder einen anderen Hintergrund hatte als ich selbst',
    'app.demographic.participated_learning_courses.response_9': 'Einen Virtuellen Austausch (VE), Collaborative Online International Learning (COIL) oder eine andere Online-/Web-basierte Lernerfahrung mit internationalem, multikulturellem oder interkulturellem Schwerpunkt absolviert oder belege eine solche gerade.',
    'app.demographic.plan_participate_experiences.response_1': 'Seminarkurs speziell für Student:innen im 1. Studienjahr / Studienanfänger:innen',
    'app.demographic.plan_participate_experiences.response_2': 'Mitglied einer von der Universität organisierten Lerngemeinschaft',
    'app.demographic.plan_participate_experiences.response_3': 'Schreibintensiver Kurs',
    'app.demographic.plan_participate_experiences.response_4': 'Forschungsprojekt mit einem Fakultäts- oder Fachmentor:in',
    'app.demographic.plan_participate_experiences.response_5': 'Von der Universität gefördertes/genehmigtes Service-Learning- oder gemeinschaftsbasiertes Projekt',
    'app.demographic.plan_participate_experiences.response_6': 'Von der Universität gefördertes/genehmigtes Praktikum',
    'app.demographic.plan_participate_experiences.response_7': 'Abschlussprojekt',
    'app.demographic.plan_participate_experiences.response_8': 'Gemeinsame oder gemeinsam genutzte erforderliche Kurse',
    'app.demographic.plan_participate_experiences.response_9': 'Gemeinsame Aufgaben oder Projekte mit anderen',
    'app.demographic.plan_participate_experiences.response_10': 'Kurse zum Thema Diversity/Globales Lernen oder Auslandsstudium',
    'app.demographic.plan_participate_experiences.response_11': 'Elektronisches Portfolio (E-Portfolio) deiner Bildungsarbeit oder Erfahrungen',
    'app.demographic.plan_participate_experiences.response_12': 'Online-Lernen (z. B. virtuell oder webbasiert) <b>mit</b> internationalem, multikulturellem oder interkulturellem Schwerpunkt.',
    'app.demographic.plan_participate_experiences.response_13': 'Online-Lernen (z. B. virtuell oder webbasiert), das <b>keinen</b> internationalen, multikulturellen oder interkulturellen Schwerpunkt hat.',
    'app.demographic.plan_participate_learning_courses.response_1': 'Belegung eines Kurses mit einem multikulturellen oder globalen Schwerpunkt',
    'app.demographic.plan_participate_learning_courses.response_2': 'Teilnahme an extracurricularen multikulturellen, internationalen und kulturellen Veranstaltungen an der Universität',
    'app.demographic.plan_participate_learning_courses.response_3': 'Abgeschlossenes Service-Learning im Ausland',
    'app.demographic.plan_participate_learning_courses.response_4': 'Ein Praktikum im Ausland absolviert',
    'app.demographic.plan_participate_learning_courses.response_5': 'Im Ausland studiert',
    'app.demographic.plan_participate_learning_courses.response_6': 'In einer Wohn- und Lerngemeinschaft mit Schwerpunkt auf internationalem, multikulturellem, globalem oder sprachlichem Lernen gelebt',
    'app.demographic.plan_participate_learning_courses.response_7': 'Eine Sprache studiert, die nicht meine Muttersprache ist.',
    'app.demographic.plan_participate_learning_courses.response_8': 'Mit einer/m Mitbewohner:in gelebt, die/der eine andere kulturelle Tradition oder einen anderen Hintergrund hatte als ich selbst',
    'app.demographic.plan_participate_learning_courses.response_9': 'Einen Virtuellen Austausch (VE), Collaborative Online International Learning (COIL) oder eine andere Online-/Web-basierte Lernerfahrung mit internationalem, multikulturellem oder interkulturellem Schwerpunkt absolviert oder belege eine solche gerade.',
    'app.demographic.time_in_other_country.response_not_spent': 'Keine nennenswerte Zeit im Ausland',
    'app.demographic.time_in_other_country.response_less_2_weeks': 'Mindestens 1 Woche, aber weniger als 2 Wochen',
    'app.demographic.time_in_other_country.response_less_1_month': 'Mindestens 2 Wochen, aber weniger als 1 Monat',
    'app.demographic.time_in_other_country.response_less_3_months': 'Mindestens 1 Monat, aber weniger als 3 Monate',
    'app.demographic.time_in_other_country.response_less_6_months': 'Mindestens 3 Monate, aber weniger als 6 Monate',
    'app.demographic.time_in_other_country.response_less_1_year': 'Mindestens 6 Monate, aber weniger als 1 Jahr',
    'app.demographic.time_in_other_country.response_less_2_years': 'Mindestens 1 Jahr, aber weniger als 2 Jahre',
    'app.demographic.time_in_other_country.response_less_5_years': 'Mindestens 2 Jahre, aber weniger als 5 Jahre',
    'app.demographic.time_in_other_country.response_less_10_years': 'Mindestens 5 Jahre, aber weniger als 10 Jahre',
    'app.demographic.time_in_other_country.response_10_or_more': '10 Jahre oder mehr',
    'app.demographic.country.response_eua': 'Vereinigte Staaten',
    'app.demographic.country.response_afganistan': 'Afghanistan',
    'app.demographic.country.response_albania': 'Albanien',
    'app.demographic.country.response_algeria': 'Algerien',
    'app.demographic.country.response_american_samoa': 'Amerikanisch-Samoa',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
    'app.demographic.country.response_argentia': 'Argentinien',
    'app.demographic.country.response_armenia': 'Armenien',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australien',
    'app.demographic.country.response_austria': 'Österreich',
    'app.demographic.country.response_azerbaijan': 'Aserbaidschan',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarus',
    'app.demographic.country.response_belgium': 'Belgien',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benin',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolivien',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnien und Herzegowina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brazilien',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgarien',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Kambodscha',
    'app.demographic.country.response_cameroon': 'Kamerun',
    'app.demographic.country.response_canada': 'Kanada',
    'app.demographic.country.response_cape_verde': 'Kap Verde',
    'app.demographic.country.response_cayman_islands': 'Kaimaninseln ',
    'app.demographic.country.response_central_african_republic': 'Zentralafrikanische Republik',
    'app.demographic.country.response_chad': 'Tschad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Kolumbien',
    'app.demographic.country.response_comoros': 'Komoren',
    'app.demographic.country.response_congo': 'Kongo',
    'app.demographic.country.response_congo,_democratic_republic': 'Demokratische Republik Kongo',
    'app.demographic.country.response_cook_islands': 'Cookinseln ',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Elfenbeinküste ',
    'app.demographic.country.response_croatia': 'Kroatien',
    'app.demographic.country.response_cuba': 'Kuba',
    'app.demographic.country.response_cyprus': 'Zypern',
    'app.demographic.country.response_czech_republic': 'Tschechien',
    'app.demographic.country.response_denmark': 'Dänemark',
    'app.demographic.country.response_djibouti': 'Dschibuti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'Dominikanische Republik',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Ägypten',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Äquatorialguinea ',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estland',
    'app.demographic.country.response_ethiopia': 'Äthiopien',
    'app.demographic.country.response_euro_area': 'Eurozone',
    'app.demographic.country.response_falkland_islands': 'Falklandinseln',
    'app.demographic.country.response_fiji': 'Fidschi',
    'app.demographic.country.response_finland': 'Finland',
    'app.demographic.country.response_france': 'Frankreich',
    'app.demographic.country.response_french_guiana': 'Französisch-Guyana',
    'app.demographic.country.response_french_polynesia': 'Französisch-Polynesien',
    'app.demographic.country.response_gabon': 'Gabun',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgien',
    'app.demographic.country.response_germany': 'Deutschland',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Griechenland',
    'app.demographic.country.response_grenada': 'Grenada',
    'app.demographic.country.response_guadeloupe': 'Guadeloupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haiti',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Ungarn',
    'app.demographic.country.response_iceland': 'Island',
    'app.demographic.country.response_india': 'Indien',
    'app.demographic.country.response_indonesia': 'Indonesien',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Irland',
    'app.demographic.country.response_isle_of_man': 'Isle of Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italien',
    'app.demographic.country.response_jamaica': 'Jamaika',
    'app.demographic.country.response_japan': 'Japan',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordanien',
    'app.demographic.country.response_kazakhstan': 'Kasachstan',
    'app.demographic.country.response_kenya': 'Kenia',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kirgisistan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Lettland',
    'app.demographic.country.response_lebanon': 'Libanon',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libyen',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Litauen',
    'app.demographic.country.response_luxembourg': 'Luxembourg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Mazedonien',
    'app.demographic.country.response_madagascar': 'Madagaskar',
    'app.demographic.country.response_malaysia': 'Malaysia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Malediven',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Mariana',
    'app.demographic.country.response_marshall_islands': 'Marshallinseln',
    'app.demographic.country.response_martinique': 'Martinique',
    'app.demographic.country.response_mauritania': 'Mauretanien',
    'app.demographic.country.response_mauritius': 'Mauritius',
    'app.demographic.country.response_mexico': 'Mexiko',
    'app.demographic.country.response_micronesia': 'Föderierte Staaten von Mikronesien',
    'app.demographic.country.response_moldova': 'Moldavien',
    'app.demographic.country.response_monaco': 'Monaco',
    'app.demographic.country.response_mongolia': 'Mongolei',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Morokko',
    'app.demographic.country.response_mozambique': 'Mosambik',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Niederländischen Antillen',
    'app.demographic.country.response_netherlands': 'Niederlande',
    'app.demographic.country.response_new_caledonia': 'Neukaledonien',
    'app.demographic.country.response_new_zealand': 'Neuseeland',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'Nordkorea',
    'app.demographic.country.response_norway': 'Norwegen',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panama',
    'app.demographic.country.response_papua_new_guinea': 'Papua-Neuguinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Peru',
    'app.demographic.country.response_phillipines': 'Philippinen',
    'app.demographic.country.response_poland': 'Polen',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Reunion',
    'app.demographic.country.response_romania': 'Rumänien',
    'app.demographic.country.response_russian_federation': 'Russland',
    'app.demographic.country.response_rwanda': 'Rwanda',
    'app.demographic.country.response_st_helena': 'St Helena',
    'app.demographic.country.response_st_kitts-nevis': 'Sankt Kitts und Nevis',
    'app.demographic.country.response_st_lucia': 'Sankt Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'Sankt Vincent und die Grenadinen',
    'app.demographic.country.response_stateless': 'Staatenlos',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'São Tomé und Príncipe',
    'app.demographic.country.response_saudi_arabia': 'Saudi-Arabien',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychellen',
    'app.demographic.country.response_serbia': 'Serbien',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapur',
    'app.demographic.country.response_slovak_republic': 'Slowakei',
    'app.demographic.country.response_slovenia': 'Slovenien',
    'app.demographic.country.response_solomon_islands': 'Salomonen',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'Südafrika',
    'app.demographic.country.response_south_korea': 'Südkorea',
    'app.demographic.country.response_spain': 'Spanien',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Suriname',
    'app.demographic.country.response_swaziland': 'Swasiland',
    'app.demographic.country.response_sweden': 'Schweden',
    'app.demographic.country.response_switzerland': 'Schweiz',
    'app.demographic.country.response_syria': 'Syrien',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tadschikistan',
    'app.demographic.country.response_tanzania': 'Tansania',
    'app.demographic.country.response_thailand': 'Thailand',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Osttimor',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad und Tobago',
    'app.demographic.country.response_tunisia': 'Tunesien',
    'app.demographic.country.response_turkey': 'Türkei',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Turks- und Caicosinseln',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ukraine',
    'app.demographic.country.response_united_arab_erimates': 'Vereinigte Arabische Emirate',
    'app.demographic.country.response_united_kingdom': 'Vereinigtes Königreich',
    'app.demographic.country.response_uraguay': 'Uraguay',
    'app.demographic.country.response_uzbekistan': 'Usbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Virgin Islands',
    'app.demographic.country.response_west_bank_and_gaza': 'Westjordanland',
    'app.demographic.country.response_yemen': 'Jemen',
    'app.demographic.country.response_zambia': 'Sambia',
    'app.demographic.country.response_zimbabwe': 'Simbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak': 'Ich spreche keine andere Sprache als meine Muttersprache(n)',
    'app.demographic.level_of_fluency.minimally_fluent': 'Geringfügig/kaum fließend',
    'app.demographic.level_of_fluency.somewhat_fluent': 'Einigermaßen fließend',
    'app.demographic.level_of_fluency.moderately_fluent': 'Mäßig fließend',
    'app.demographic.level_of_fluency.mostly_fluent': 'Weitgehend fließend',
    'app.demographic.level_of_fluency.fluent': 'Fließend',
    'app.demographic.level_of_interest.not_applicable': 'Nicht zutreffend',
    'app.demographic.level_of_interest.extremely_low': 'Extrem niedrig',
    'app.demographic.level_of_interest.very_low': 'Sehr niedrig',
    'app.demographic.level_of_interest.low': 'Niedrig',
    'app.demographic.level_of_interest.neutral': 'Neutral',
    'app.demographic.level_of_interest.high': 'Hoch',
    'app.demographic.level_of_interest.very_high': 'Sehr hoch',
    'app.demographic.level_of_interest.extremely_high': 'Extrem Hoch',
    'app.demographic_slider_default_option': 'Bitte Cursor bewegen, um Antwort auszuwählen.',
    'app.demographic.formal_education.some_high_school_or_less': 'Abgebrochene Oberstufe oder weniger',
    'app.demographic.formal_education.high_school_graduate': 'Abgeschlossene Oberstufe',
    'app.demographic.formal_education.some_college': 'Abgebrochenes Studium',
    'app.demographic.formal_education.college_degree': 'Hochschulabschluss (z.B. Bachelorstudium)',
    'app.demographic.formal_education.some_graduate_school': 'Abgebrochenes Masterstudium (nach dem Bachelorstudium)',
    'app.demographic.formal_education.graduate_degree': 'Absolvent:in (nach Bachelor, z. B. Masterstudium, Diplomstudium)',
    'app.demographic.formal_education.professional_degree': 'Berufsqualifizierender Hochschulabschluss',
    'app.demographic.formal_education.doctoral_degree': 'Doktorat',
}

export const demographicQuestionsDE: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
