import React, { ReactNode } from 'react';

import { LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';
import { ExternalTemplate } from './ExternalTemplate';

type Props = {
    component: ReactNode;
}

export const ExternalTemplateContainer = ({ component }: Props): JSX.Element => {
    const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    return (<ExternalTemplate component={component} isJapanese={isJapanese} />);
}
