import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsCA: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': 'Reflexionant sobre l’experiència per la qual estàs contestant el BEVI, si us plau, respon a aquestes tres qüestions..',
    'app.discursive_questions.question1': '1. Quin esdeveniment o aspecte de la teva vida ha tingut  més impacte sobre tu i per què? ',
    'app.discursive_questions.question2': '2. Hi ha hagut algun aspecte de tu mateix, el teu “jo” o la teva "identitat" (per exemple, gènere, ètnia, orientació sexual, religiosa o antecedents polítics, etc.) que hagi esdevingut especialment clar o rellevant per a tu o altres arran d’aquesta experiència?',
    'app.discursive_questions.question3': '3. Què has après o com has canviat arran d’aquesta experiència?',
    'app.discursive_questions.thank_you': 'Gràcies per completar el BEVI!',
    'app.discursive_questions.please_note': 'Tingues en compte:',
    'app.discursive_questions.go_to_report': 'Si vols veure el teu informe ara, si us plau fes clic al botó a continuació. Pot ser que també vulguis revisar l’informe més tard al teu taulell.',
    'app.discursive_questions.do_not_show_report': 'Malauradament, per diversos motius (per exemple, falten respostes a massa preguntes), no podem generar el teu informe. Si vols, pots provar de tornar a completar el BEVI.',
    'app.discursive_questions.visualize_report': 'Llegir informe',
}
