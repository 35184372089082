import { useActions, useValues } from 'kea';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { formLogic, FormsActions, FormsProps } from '../../redux/formsLogic';
import {
    DEMOGRAPHIC_QUESTIONS_PAGE, EXTERNAL_CONSENT_PAGE, EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE
} from '../../router/pages';
import { Consent } from './Consent';

type Props = RouteComponentProps;

export const ConsentContainer = withRouter((props: Props): JSX.Element => {

    const { initializeForm, setFormStatus }: FormsActions = useActions(formLogic);
    const { loading }: FormsProps = useValues(formLogic);

    const handleClick = (): void => {
        // TODO(rubens.sotero): I need to check if the result property was accepted to move to another page.
        initializeForm();
        setFormStatus(2); // On Demographics
        if (props.location.pathname === EXTERNAL_CONSENT_PAGE) {
            props.history.push(EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE);
        } else {
            props.history.push(DEMOGRAPHIC_QUESTIONS_PAGE);
        }
    }

    return (
        <Consent
            onHandleClick={handleClick}
            loading={loading}
        />
    )
});
