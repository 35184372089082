import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsES: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': 'Teniendo en cuenta la experiencia en relación con la que estás haciendo el BEVI, por favor, responde a las siguientes tres preguntas.',
    'app.discursive_questions.question1': '1. ¿Qué vivencia o aspecto de tu experiencia tuvo mayor impacto en ti y por qué?',
    'app.discursive_questions.question2': '2. ¿Ha habido algún aspecto de tu propio "yo" o "identidad" (i. e., género, etnicidad, orientación sexual, antecedentes religiosos o políticos, etc.) que se te haya revelado con especial claridad a raíz de esta experiencia?',
    'app.discursive_questions.question3': '3. ¿Qué has aprendido o en qué has cambiado a raíz de esta experiencia?',
    'app.discursive_questions.thank_you': '¡Gracias por completar el BEVI!',
    'app.discursive_questions.please_note': 'Por favor, ten en cuenta:',
    'app.discursive_questions.go_to_report': 'Si quieres ver tu informe ahora, por favor, haz click en el botón que aparece a continuación. También podrás revisar el informe más tarde desde tu panel.',
    'app.discursive_questions.do_not_show_report': 'Por desgracia, por diversas razones (i. e., no se proporcionaron respuestas a muchas de las preguntas), no podemos generar un informe. Si lo deseas, puedes intentar hacer el BEVI de nuevo.',
    'app.discursive_questions.visualize_report': 'Leer informe',
}
