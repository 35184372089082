import { useActions, useValues } from 'kea';
import React, { Fragment, useContext, useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { IntlContext } from '../../IntlProviderWrapper';
import {
    ComparativeByIndividualReportActions,
    comparativeByIndividualReportLogic, ComparativeByIndividualReportProps
} from '../../redux/comparativeByIndividualReportLogic';
import { ReportLanguage } from '../../services/reportService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ComparativeReportByIndividual } from './ComparativeReportByIndividual';
import { BeingBevi } from '../../components/BeingBevi';

type Props = RouteComponentProps;

export const ComparativeReportByIndividualContainer = withRouter((props: Props): JSX.Element => {
    const { loadComparativeByIndividualReport, downloadWithinGroupReport }: ComparativeByIndividualReportActions = useActions(comparativeByIndividualReportLogic);
    const { loading, report, loadingDataDownload }: ComparativeByIndividualReportProps = useValues(comparativeByIndividualReportLogic);
    const { getReportLanguage } = useContext(IntlContext);

    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        loadComparativeByIndividualReport(id)
    }, [loadComparativeByIndividualReport, id]);

    const download = () => {
        const reportLanguage = getReportLanguage && getReportLanguage();
        downloadWithinGroupReport(reportLanguage ?? ReportLanguage.English, id)
    }

    if (loading) return <Loading />

    return (
        <Fragment>
            <ComparativeReportByIndividual
                report={report ? report : {}}
                download={download}
                loadingDataDownload={loadingDataDownload}
                scalesTitle={{
                    negative_life_events: GetFormattedMessage('app.report.scales.negative_life_events'),
                    needs_closure: GetFormattedMessage('app.report.scales.needs_closure'),
                    needs_fulfillment: GetFormattedMessage('app.report.scales.needs_fulfillment'),
                    identity_diffusion: GetFormattedMessage('app.report.scales.identity_diffusion'),
                    basic_openness: GetFormattedMessage('app.report.scales.basic_openness'),
                    self_certitude: GetFormattedMessage('app.report.scales.self_certitude'),
                    basic_determinism: GetFormattedMessage('app.report.scales.basic_determinism'),
                    socioemotional_convergence: GetFormattedMessage('app.report.scales.socioemotional_convergence'),
                    physical_resonance: GetFormattedMessage('app.report.scales.physical_resonance'),
                    emotional_attunement: GetFormattedMessage('app.report.scales.emotional_attunement'),
                    self_awareness: GetFormattedMessage('app.report.scales.self_awareness'),
                    meaning_quest: GetFormattedMessage('app.report.scales.meaning_quest'),
                    religious_traditionalism: GetFormattedMessage('app.report.scales.religious_traditionalism'),
                    gender_traditionalism: GetFormattedMessage('app.report.scales.gender_traditionalism'),
                    sociocultural_openness: GetFormattedMessage('app.report.scales.sociocultural_openness'),
                    ecological_resonance: GetFormattedMessage('app.report.scales.ecological_resonance'),
                    global_resonance: GetFormattedMessage('app.report.scales.global_resonance'),
                    consistency: GetFormattedMessage('app.report.scales.consistency'),
                    congruency: GetFormattedMessage('app.report.scales.congruency'),
                }}
            />
            <BeingBevi type='ComparativeByIndividualReport' id={id} />
        </Fragment>
    )
});
