import { ListFormPageMessages } from '../types';

export const listFormsPageJA: ListFormPageMessages = {
    'app.listforms.title': 'BEVI Dashboard',
    'app.listforms.subtitle': 'BEVIダッシュボードで、BEVIの受検、個人レポートの閲覧・管理を行うことができます。',
    'app.listforms.organization': '大学/機関',
    'app.listforms.participant': '受検者',
    'app.listforms.program': 'プログラム',
    'app.listforms.take_by': '期限',
    'app.listforms.completed_on': '回答日',
    'app.listforms.progress': '進捗',
    'app.listforms.status': '状態',
    'app.listforms.custom_agreement_status': 'カスタム契約ステータス',
    'app.listforms.action': '選択',
    'app.listforms.organization_tooltip': 'このダッシュボードで、BEVIの受検・レポート閲覧・管理を行うことができます。',
    'app.listforms.program_tooltip': 'BEVI を受験するレポート、プログラム、またはエクスペリエンスの名前。',
    'app.listforms.take_by_tooltip': 'この期日までにBEVIを完了してください。',
    'app.listforms.completed_on_tooltip': 'BEVIを完了した日',
    'app.listforms.progress_tooltip': 'BEVIの進捗状況（100％の場合はBEVIを完了したことになります。）',
    'app.listforms.status_tooltip': 'BEVIテストの状態',
    'app.listforms.action_tooltip': 'BEVIの受検、継続、再受検また個人レポートの閲覧を行うことができます。表示されているボックスが、現在可能な選択肢です。',
    'app.listforms.all': 'All',
    'app.listforms.available': '未回答',
    'app.listforms.onDemographics': '背景情報記入中',
    'app.listforms.onScales': 'BEVI質問項目回答中',
    'app.listforms.onSubjective': '記述項目回答中',
    'app.listforms.finished': '完了',
    'app.listforms.cancelled': 'キャンセル',
    'app.listforms.expired': '期限切れ',
    'app.listforms.archived': 'Archived',
    'app.listforms.invalid': '無効',
    'app.listforms.start': '開始',
    'app.listforms.continue': '継続',
    'app.listforms.report': 'レポート閲覧',
    'app.listforms.partner_report': 'パートナーレポートを確認する',
    'app.listforms.partner_change_report': 'パートナー変更レポートの確認',
    'app.listforms.restart': '再受検',
    'app.listforms.confirmation_title': 'ご案内',
    'app.listforms.retart_confirmation_title': '再受検',
    'app.listforms.restart.instruction.part1': '下のボタンをクリックしてBEVIを再受検してください。',
    'app.listforms.instruction.part1': '以下の調査は、背景情報とBEVI本体（185項目）から構成されています。BEVIについての質問は1ページあたり20項目あり、これらの質問に対して、あなたがどのように感じるのかを最もよく表す4つの選択肢（強くそう思う、そう思う、そうは思わない、全くそうは思わない）から1つを選択してください。',
    'app.listforms.instruction.part2': '20項目の質問について回答が終わった後、「次へ」をクリックして先へ進んでください。「次へ」がクリックされると、もとのページに戻って回答を変更することはできません。',
    'app.listforms.instruction.part3': 'また、BEVIを開始する前に、概要及び同意書を読む必要があります。',
    'app.listforms.instruction.press.start': 'この度はBEVIに参加いただき、ありがとうございます。よろしければ「開始」をクリックしてください。',
    'app.listforms.comparative_individual_report': '個別レポートの比較',
    'app.form.status_title': '受検可能なBEVIテストがありません。',
    'app.form.status_finished_subtitle': 'このBEVIテストは既に回答済みです。',
    'app.form.status_finished_body': 'このBEVIテストについて不明な点があれば、BEVI実施責任者に問い合わせてください。',
    'app.form.status_cancelled_subtitle': 'このBEVIテストはキャンセルされました。',
    'app.form.status_cancelled_body': 'このBEVIテストについて不明な点があれば、BEVI実施責任者に問い合わせてください。',
    'app.form.status_expired_subtitle': 'このBEVIテストは回答期限を過ぎています。',
    'app.form.status_expired_body': 'このBEVIテストについて不明な点があれば、BEVI実施責任者に問い合わせてください。',
    'app.form.invalid_status.by_time': '回答時間',
    'app.form.invalid_status.by_number_of_questions': '回答数不足',
    'app.form.invalid_status.by_consecutive_answers': '連続回答',
    'app.form.invalid_status.by_common_answers': '同一パターン',
    'app.form.invalid_status.by_congruency': '適合性不足',
    'app.form.invalid_status.by_consistency': '一貫性不足',
    'app.form.invalid_status.by_wrong_scale_value': 'Invalid by Wrong Scale Value',
    'app.form.custom_agreement.not_requested': 'Not requested',
    'app.form.custom_agreement.pending': 'Pending',
    'app.form.custom_agreement.accepted': 'Accepted',
    'app.form.custom_agreement.rejected': 'Rejected',
    'app.form.actions.tooltip.send_custom_agreement': 'Send custom agreement',
    'app.form.actions.tooltip.resend_custom_agreement': 'Resend custom agreement',
    'app.form.actions.tooltip.show_report': 'Show report',
    'app.listforms.ai_interactions': 'AIインタラクション'
}
