import { ListReportMessages } from '../types';

export const listReportES: ListReportMessages = {
    'app.listreport.report_area': 'Gestión de informes',
    'app.listreport.new': 'Nuevo Informe',
    'app.listreport.new_group': 'Nuevo grupo de informes',
    'app.listreport.add_group': 'Agregar otro grupo',
    'app.listreport.please_add_group_name': 'Por favor agregue el nombre del grupo',
    'app.listreport.search': 'Buscar',
    'app.listreport.name': 'Nombrar',
    'app.listreport.report_type': 'Escribir',
    'app.listreport.created_on': 'Creado el',
    'app.listreport.confirm_deletion_header': 'Confirmar acción',
    'app.listreport.confirm_deletion_body': ' ¿Estás seguro de que quieres continuar con el proceso de borrado de informe? Si lo haces, este informe se eliminará de nuestra base de datos permanentemente.',
    'app.listorganizations.view': 'Ver',
    'app.listorganizations.edit': 'Editar',
    'app.listorganizations.delete': 'Borrar',
    'app.listreport.report_status': 'Estado del informe',
    'app.listreport.report_status_pending_approval': 'Pending Participant Approval',
    'app.listreport.report_status_completed': 'Available',
}
