import { NoInfoMessages } from '../types';

export const noInfoZHTW: NoInfoMessages = {
    'app.no.info.title.not_found': 'PAGE NOT FOUND',
    'app.no.info.title.no_data': 'NO DATA',
    'app.no.info.subtitle.not_found': '您查找的网页不存在。',
    'app.no.info.subtitle.no_data': '未搜索到符合条件的数据。',
    'app.no.info.body.not_found': '您要查找的页面不存在或已被删除。',
    'app.no.info.body.no_data': '请检查搜索条件。若仍未搜索到任何数据，请联系BEVI管理者。',
    'app.no.info.title.not_authorized': 'PAGE NOT AUTHORIZED',
    'app.no.info.subtitle.not_authorized': '您无权查看此数据。',
    'app.no.info.body.not_authorized': '请咨询BEVI管理者。',
    'app.no.info.title.maintenance': '维护中',
    'app.no.info.subtitle.maintenance': '系统维护中，暂停服务。',
    'app.no.info.body.maintenance': '请稍后重新连接或稍等片刻。',
}
