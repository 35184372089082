import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import { deleteReport, DeleteReportResponse, ListReportModel, listReports } from '../services/reportService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'getReports', 'deleteReport'
]);
export type ListReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const listReportActionsConstants: ListReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setReports', 'setReportsError', 'setSuccessfullyDeleted', 'setDeleteError'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading',
    'reports',
    'error',
    'deleting',
    'deleteError',
]);
export type ListReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const listReportPropsConstants: ListReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ListReportActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchListReportRequest: () => void,
        deleteReportRequest: () => void,
    }
};

// Define payloads type.
type ErrorPayload = { errorKey: string; };
type ListReportPayload = { reports: ListReportModel[] };
type ListReportRequestPayload = {
    startDate: Date,
    endDate: Date,
    organizationId: number;
};
type ListReportRequest = { payload: ListReportRequestPayload };
type DeleteReportRequestPayload = {
    reportId: number;
};
type DeleteReportRequest = { payload: DeleteReportRequestPayload };

type DeleteReportRaceResponse = {
    deleteReportResponse?: DeleteReportResponse;
}

const ListReportItemPropType = PropTypes.shape({
    id: PropTypes.number,
    reportType: PropTypes.number,
    name: PropTypes.string,
    createdTimeOnUtc: PropTypes.string,
});

const emptyListReportItem: ListReportModel[] = [];

// persist forms reducer
export const listReportKeaPath = 'listReport';

export const listReportReducer = keaReducer(listReportKeaPath);

export const listReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [listReportKeaPath],

    actions: () => ({
        [listReportActionsConstants.getReports]:
            (startDate: Date, endDate: Date, organizationId: number): ListReportRequestPayload =>
                ({ startDate, endDate, organizationId }),
        [listReportActionsConstants.deleteReport]:
            (reportId: number): DeleteReportRequestPayload => ({ reportId }),
        [privateActionsConstants.setReportsError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setDeleteError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setReports]:
            (reports: ListReportModel[]): ListReportPayload => ({ reports }),
        [privateActionsConstants.setSuccessfullyDeleted]:
            (reportId: number): DeleteReportRequestPayload => ({ reportId }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [listReportPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.getReports]: () => true,
            [actions.setReports]: () => false,
            [actions.setReportsError]: () => false,
            [logout]: () => false,
        }],
        [listReportPropsConstants.reports]: [emptyListReportItem, PropTypes.arrayOf(ListReportItemPropType), {
            [actions.setReports]: (_: ListReportModel[], payload: ListReportPayload) => payload.reports,
            [actions.setSuccessfullyDeleted]: (currentList: ListReportModel[], payload: DeleteReportRequestPayload) => {
                const newList = currentList.filter((value: ListReportModel) => value.id !== payload.reportId);
                return newList;
            },
            [actions.setReportsError]: () => emptyListReportItem,
            [logout]: () => emptyListReportItem
        }],
        [listReportPropsConstants.error]: [false, PropTypes.bool, {
            [actions.setReportsError]: () => true,
            [actions.getReports]: () => false,
            [logout]: () => false,
        }],
        [listReportPropsConstants.deleting]: [false, PropTypes.bool, {
            [actions.deleteReport]: () => true,
            [actions.setSuccessfullyDeleted]: () => false,
            [actions.setDeleteError]: () => false,
            [logout]: () => false,
        }],
        [listReportPropsConstants.deleteError]: [null, PropTypes.string, {
            [actions.setDeleteError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.deleteReport]: () => null,
            [logout]: () => null,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.getReports]: workers.fetchListReportRequest,
        [actions.deleteReport]: workers.deleteReportRequest,
    }),

    workers: {
        * fetchListReportRequest(action: ListReportRequest): any {
            //@ts-ignore
            const { setReportsError, setReports } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const payload = action.payload;

            const listReportResponse = yield call(listReports, token, payload);

            if (listReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (listReportResponse?.status === successfulCode) {
                if (listReportResponse?.body) {
                    yield put(setReports(listReportResponse?.body));
                } else {
                    yield put(setReportsError(generalExceptionKey));
                }
            } else if (listReportResponse?.keyErrorMessage) {
                yield put(setReportsError(listReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * deleteReportRequest(action: DeleteReportRequest): any {
            //@ts-ignore
            const { setSuccessfullyDeleted, setDeleteError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const payload = action.payload;

            const { deleteReportResponse }: DeleteReportRaceResponse = yield race({
                deleteReportResponse: call(deleteReport, token, payload),
                timeout: delay(requestTimeout)
            });

            if (deleteReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (deleteReportResponse?.status === successfulCode) {
                yield put(setSuccessfullyDeleted(payload.reportId));
            } else if (deleteReportResponse?.keyErrorMessage) {
                yield put(setDeleteError(deleteReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
