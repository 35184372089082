import React from 'react';
import { FormattedMessage } from 'react-intl';

import { InvalidComponentDefinitionError } from '../exceptions/InvalidComponentDefinitionError';
import { PageMessages } from '../locales/types';
import { GetFormattedMessage } from '../utils/htmlHelper';

export interface BeviFormattedTextProps {
  messageId?: keyof (PageMessages) | "";
  formattedMessage?: typeof FormattedMessage;
  formattedHTMLMessage?: typeof FormattedMessage;
  style?: React.CSSProperties;
  isHtml?: boolean;
}

export const BeviFormattedLabel = (props: BeviFormattedTextProps) => {

  if (!!props.messageId && (!!props.formattedMessage || !!props.formattedHTMLMessage)) {
    throw new InvalidComponentDefinitionError(
      "BeviFormattedText: The formattedMessageId or formattedMessage/formatterHtmlMessage should be filled");
  }

  if(!!!props.isHtml) {
    const formattedMessage = props.formattedMessage ?? GetFormattedMessage(props.messageId);
    return (
      <p style={props.style}>
        <>
          {formattedMessage}
        </>
      </p>  
    )
  }

  if (props.isHtml) {
    if (typeof props.formattedHTMLMessage === 'string') {
      return (
        <p style={props.style} dangerouslySetInnerHTML={{ __html: props.formattedHTMLMessage }} />
      )
    } else if (props.messageId != null) {
      return (
        <p style={props.style} dangerouslySetInnerHTML={{ __html: GetFormattedMessage(props.messageId) }} />
      )
    }
  }

  return (
    <p style={props.style}>
      <>
      {props.formattedHTMLMessage ?? <FormattedMessage id={props.messageId} />}
      </>
    </p>
  );
};
