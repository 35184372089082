import React, { useMemo } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { FaDownload, FaFileDownload, FaUndo, FaUpload } from 'react-icons/fa';

import { SelectItem } from '../../../components/FormDropdown';
import { FormDropdownAntd } from '../../../components/FormDropdownAntd';
import { Loading } from '../../../components/Loading';
import { colors } from '../../../config/colors';
import { listOrganizationLogic, ListOrganizationProps } from '../../../redux/listOrganizationLogic';
import { SendProgram } from '../../../services/programService';
import { OrganizationUser } from '../../../services/usersService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { useValues } from 'kea';

const ReactExport: any = require('react-export-excel-xlsx-fix');

const ExcelFile = ReactExport.modules.ExcelFile;
const ExcelSheet = ReactExport.modules.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.modules.ExcelFile.ExcelColumn;

type Props = {
    program: SendProgram;
    availableUsers: SelectItem[];
    selectedUsers: OrganizationUser[];
    organizationName: string;
    allOrganizationUsers: OrganizationUser[];
    uploadingUsers: boolean;
    onUserSelected: (userId: number) => void;
    onClearAllUserClicked: () => void;
    onUseUsersFromLastSubProgramClicked: () => void;
    dropdownKey: string;
    openDropzone(): void;
    getRootProps(props?: DropzoneRootProps): DropzoneRootProps;
    getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
};

export const AssociateUsersHeader = (
    {
        program, availableUsers, onUserSelected, dropdownKey, allOrganizationUsers, selectedUsers,
        organizationName, uploadingUsers, onClearAllUserClicked, onUseUsersFromLastSubProgramClicked,
        openDropzone, getRootProps, getInputProps,
    }: Props
): JSX.Element => {

    const getLastUsersTooltip = GetFormattedMessage('app.create_program.tooltip.btn_get_last_users');
    const downloadUsersTooltip = GetFormattedMessage('app.create_program.tooltip.btn_download_users');
    const downloadUsersEmptyTooltip = GetFormattedMessage('app.create_program.tooltip.btn_download_users_empty');
    const uploadUsersTooltip = GetFormattedMessage('app.create_program.tooltip.btn_upload_users');
    const excelNameColumn = GetFormattedMessage('app.create_program.excel.name_column');
    const excelLoginColumn = GetFormattedMessage('app.create_program.excel.login_column');
    const excelActiveColumn = GetFormattedMessage('app.create_program.excel.active_column');

    const {
        selectedProgram,
    }: ListOrganizationProps = useValues(listOrganizationLogic);

    const disabledPreviousAdm = useMemo(() => {
        if (program?.subProgramId != null) {
            if (selectedProgram?.subPrograms != null && selectedProgram.subPrograms.length > 1)
                return false;
            return true;
        } else {
            if (selectedProgram?.subPrograms != null && selectedProgram.subPrograms.length > 0)
                return false;
            return true;
        }
    }, [program, selectedProgram])

    function getSheetName(input: string): string {
        if (input == null)
            return '';

        const reg: RegExp = /[/\\*'?[\]:]+/;
        const newInput = input.replace(reg, '');
        if (newInput.length <= 31) {
            return newInput;
        } else {
            return newInput.substring(0, 31);
        }
    }

    return (
        <React.Fragment>
            <header style={styles.associateUserHeader}>
                <h3 style={styles.associateUserTitle}>
                    {GetFormattedMessage('app.create_program.select_individuals_title')}<br /><br />
                </h3>
            </header>
            <header style={styles.associateUserHeader}>
                <div style={styles.associateUserTitle}>
                    <p>{GetFormattedMessage('app.create_program.import_individuals_from_previous_administration')} <Button className=' bg-transparent'><FaUndo color={colors.darkSlateBlue} /></Button><br /></p>
                    <p>{GetFormattedMessage('app.create_program.download_manage_who_will_take_the_bevi')} <Button className=' bg-transparent'><FaDownload color={colors.darkSlateBlue} /></Button>{GetFormattedMessage('app.create_program.upload_who_will_take_the_bevi')} <Button className=' bg-transparent'><FaUpload color={colors.darkSlateBlue} /></Button><br /></p>
                    <p>{GetFormattedMessage('app.create_program.add_individuals_manually')}<br /><br /></p>
                </div>
                <br /><br />
            </header>
            {uploadingUsers
                ? <Loading />
                : (
                    <header style={styles.associateUserHeader}>
                        <Row style={{ flex: 1 }}>
                            <Form.Group className='mb-3' as={Col} sm='1'>
                                <OverlayTrigger
                                    placement={'auto'}
                                    trigger={["hover", "hover"]}
                                    overlay={<Tooltip id={`undoTooltip`}> {getLastUsersTooltip} </Tooltip>}
                                >
                                    <Button
                                        className=' bg-transparent'
                                        disabled={disabledPreviousAdm}
                                        onClick={() => {
                                            onUseUsersFromLastSubProgramClicked();
                                        }}
                                    >
                                        <FaUndo color={colors.darkSlateBlue} />
                                    </Button>
                                </OverlayTrigger>
                            </Form.Group>
                            <Form.Group className='mb-3' as={Col} sm='1'>
                                <ExcelFile
                                    filename={`${organizationName}_users`}
                                    element={
                                        <OverlayTrigger
                                            placement={'auto'}
                                            trigger={["hover", "hover"]}
                                            overlay={<Tooltip id={`downloadTooltip`}> {downloadUsersTooltip} </Tooltip>}
                                        >
                                            <Button className=' bg-transparent'>
                                                <FaDownload color={colors.darkSlateBlue} />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                >
                                    <ExcelSheet
                                        data={allOrganizationUsers}
                                        name={getSheetName(organizationName)}
                                    >
                                        <ExcelColumn label={excelLoginColumn} value='login' />
                                        <ExcelColumn label={excelNameColumn} value='name' />
                                        <ExcelColumn label={excelActiveColumn} value='isActive' />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Form.Group>
                            <Form.Group className='mb-3' as={Col} sm='1'>
                                <ExcelFile
                                    filename={`${organizationName}_users`}
                                    element={
                                        <OverlayTrigger
                                            placement={'auto'}
                                            trigger={["hover", "hover"]}
                                            overlay={<Tooltip id={`downloadTooltip`}> {downloadUsersEmptyTooltip} </Tooltip>}
                                        >
                                            <Button className=' bg-transparent'>
                                                <FaFileDownload color={colors.darkSlateBlue} />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                >
                                    <ExcelSheet
                                        data={[]}
                                        name={getSheetName(organizationName)}
                                    >
                                        <ExcelColumn label={excelLoginColumn} value='login' />
                                        <ExcelColumn label={excelNameColumn} value='name' />
                                        <ExcelColumn label={excelActiveColumn} value='isActive' />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Form.Group>
                            <Form.Group className='mb-3' as={Col} sm='1'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={["hover", "hover"]}
                                        overlay={<Tooltip id={`uploadUsersTooltip`}> {uploadUsersTooltip} </Tooltip>}
                                    >
                                        <Button className=' bg-transparent'>
                                            <FaUpload color={colors.darkSlateBlue} onClick={openDropzone} />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </Form.Group>
                            <Form.Group className='mb-3' as={Col} sm='6'>
                                <FormDropdownAntd
                                    optionItens={availableUsers}
                                    defaultOptionId={'app.create_program.select_individual_manually'}
                                    allowClear={true}
                                    onChange={onUserSelected}
                                    ignoreValue={true}
                                    showSearch={true}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' as={Col} sm='3'>
                                <Button
                                    type='reset'
                                    variant={selectedUsers.length === 0 ? 'secondary' : 'danger'}
                                    disabled={selectedUsers.length === 0}
                                    onClick={() => onClearAllUserClicked()}
                                    className='w-100'
                                >
                                    {GetFormattedMessage('app.create_program.btn_unselect_all_users')}
                                </Button>
                            </Form.Group>
                        </Row>
                    </header>
                )}
        </React.Fragment>
    );
}


const styles = {
    associateUserHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    } as React.CSSProperties,
    associateUserTitle: {
        marginRight: 'auto',
        width: '100%'
    } as React.CSSProperties,
};
