import '../../../style/scale-questions.css';

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import { ScaleQuestion } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    question: ScaleQuestion;
    rowStyle: React.CSSProperties;
    onAnswer: (value: number) => void;
}

export const Question = ({ question, rowStyle, onAnswer }: Props): JSX.Element => {
    return (
        <Row key={question.id} style={rowStyle}>
            <Form.Label column={true} md={isMobile ? 12 : undefined} className='question-label'>
                {`${question.order}.  ${GetFormattedMessage(question.localeKey)}`}
            </Form.Label>
            <Col
                className={isMobile ? 'check-column-mobile-div' : 'check-column-div'}
                xs={isMobile ? '3' : '2'}
                md='1'
            >
                <Form.Check
                    className='response-label'
                    type='radio'
                    label={isMobile ? GetFormattedMessage('app.question.strongly_agree') : ''}
                    name={question.localeKey}
                    id={`${question.localeKey}-1`}
                    onClick={() => onAnswer(1)}
                />
            </Col>
            <Col
                className={isMobile ? 'check-column-mobile-div' : 'check-column-div'}
                xs={isMobile ? '3' : '2'}
                md='1'
            >
                <Form.Check
                    className='response-label'
                    type='radio'
                    label={isMobile ? GetFormattedMessage('app.question.agree') : ''}
                    name={question.localeKey}
                    id={`${question.localeKey}-2`}
                    onClick={() => onAnswer(2)}
                />
            </Col>
            <Col
                className={isMobile ? 'check-column-mobile-div' : 'check-column-div'}
                xs={isMobile ? '3' : '2'}
                md='1'
            >
                <Form.Check
                    className='response-label'
                    type='radio'
                    label={isMobile ? GetFormattedMessage('app.question.disagree') : ''}
                    name={question.localeKey}
                    id={`${question.localeKey}-3`}
                    onClick={() => onAnswer(3)}
                />
            </Col>
            <Col
                className={isMobile ? 'check-column-mobile-div' : 'check-column-div'}
                xs={isMobile ? '3' : '2'}
                md='1'
            >
                <Form.Check
                    className='response-label'
                    type='radio'
                    label={isMobile ? GetFormattedMessage('app.question.strongly_disagree') : ''}
                    name={question.localeKey}
                    id={`${question.localeKey}-4`}
                    onClick={() => onAnswer(4)}
                />
            </Col>
        </Row>
    );
}
