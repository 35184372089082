import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SampleDataReport } from './SampleDataReport';
import useSampleData from '../../hooks/sampleData';
import { AppProps, appLogic } from '../../redux/appLogic';
import { useValues } from 'kea';

type Props = RouteComponentProps;

export const SampleDataReportContainer = withRouter((props: Props): JSX.Element => {
  const { token }: AppProps = useValues(appLogic);

  const { getSampleData, loading, list } = useSampleData();

  useEffect(() => {
    if(token)
      getSampleData(token);
  }, [getSampleData, token])
  
  return (
    <React.Fragment>
      <SampleDataReport
        reports={list ?? []}
        loading={loading}
      />
    </React.Fragment>
  )
});

