import { InternalTemplateMessages } from '../types';

export const internalTemplatePageZHCN: InternalTemplateMessages = {
    'app.internaltemplate.welcome': '歡迎',
    'app.internaltemplate.menu.dashboard': '儀表板(Dashboard)',
    'app.internaltemplate.menu.users': '用戶',
    'app.internaltemplate.menu.organization': '大學或機構',
    'app.internaltemplate.menu.report': '結果',
    'app.internaltemplate.menu.sample_report': '报告样本',
    'app.internaltemplate.menu.permission_profile': 'Permission Profile',
    'app.internaltemplate.menu.financial_report': 'Usage Report',
    'app.internaltemplate.menu.logout': '退出',
    'app.internaltemplate.menu.virtualAccount': 'Virtual account',
    'app.internaltemplate.menu.institutions': 'Organizations',
    'app.internaltemplate.system.maintenance_time': 'The system will be under maintenance from {0} to {1}.',
    'app.internaltemplate.menu.report_permission': 'Report Permission',
}
