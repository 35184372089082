import { ProgramsMessages } from '../types';

export const programsCA: ProgramsMessages = {
  'app.programs.add.title': 'Cercar Programa',
  'app.programs.rename': 'Canvia el nom del programa',
  'app.programs.edit.title': 'Editar Programa',
  'app.programs.add.successful_message': 'Programa creat amb èxit.',
  'app.programs.add.error_message': 'Alguna cosa ha fallat. Si us plau, torna a provar després d’uns minuts.',
  'app.programs.add.new_name': 'Nou nom',
  'app.programs.add.button_save': 'Guardar',
  'app.programs.chat.successful_message': "En pocs minuts, tots els usuaris podran utilitzar el xat d'IA.",
  'app.programs.chat.add_chat_all': 'Afegeix un xat per a tots els usuaris',
  'app.programs.chat.question_add_chat_all': "Confirmes que vols incloure el xat per a tots els usuaris d'aquest programa?"
}