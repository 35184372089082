import { ReportsMessages } from '../types';

export const reportsEN: ReportsMessages = {
    'app.reports.insuficient_data': 'There aren\'t sufficient data to generate the report.',
    'app.reports.decile': 'Decile',
    'app.reports.group_report.title': 'Group Report',
    'app.reports.longitudinal.title': 'Longitudinal Report',
    'app.reports.comparative_by_group.title': 'Between Group Report',
    'app.reports.comparative_by_individual.title': 'Within Group Report',
    'app.report.menu.introduction': 'Report Information',
    'app.report.menu.basic_descriptive_statistics': 'Background Domain',
    'app.report.menu.aggregate_profile': 'Aggregate Profile',
    'app.report.menu.decile_profile': 'Decile Profile',
    'app.report.menu.aggregate_by_demographics': 'Aggregate by Demographics',
    'app.report.menu.background_domain_contrast': 'Background Domain Contrast',
    'app.report.menu.profile_background_contrast': 'Background Domain Contrast',
    'app.report.menu.profile_contrast': 'Profile Contrast',
    'app.report.menu.experiential_reflection_items': 'Experiential Reflection Items',
    'app.report.menu.country_of_origin': 'Country of Origin',
    'app.report.menu.gender': 'Gender',
    'app.report.menu.education': 'Education',
    'app.report.menu.ethnicity': 'Ethnicity',
    'app.report.menu.income': 'Income',
    'app.report.menu.interest': 'Interest',
    'app.report.menu.politics': 'Politics',
    'app.report.menu.religion': 'Religion',
    'app.report.menu.satisfaction': 'Satisfaction',
    'app.report.menu.question_1': 'Question 1',
    'app.report.menu.question_2': 'Question 2',
    'app.report.menu.question_3': 'Question 3',
    'app.report.menu.narrative_report': 'Narrative Report',
    'app.report.introduction.create_date': 'Created on:',
    'app.report.introduction.created_by': 'User:',
    'app.report.introduction.program_name': 'Program or experience:',
    'app.report.introduction.group_name': 'BEVI administration(s):',
    'app.report.introduction.number_of_participants': 'Number of participants:',
    'app.report.introduction.visualized_by': 'Viewed by:',
    'app.report.scales.consistency': 'Consistency',
    'app.report.scales.congruency': 'Congruency',
    'app.report.scales.negative_life_events': 'Negative Life Events',
    'app.report.scales.needs_closure': 'Needs Closure',
    'app.report.scales.needs_fulfillment': 'Needs Fulfillment',
    'app.report.scales.identity_diffusion': 'Identity Diffusion',
    'app.report.scales.basic_openness': 'Basic Openness',
    'app.report.scales.self_certitude': 'Self Certitude',
    'app.report.scales.basic_determinism': 'Basic Determinism',
    'app.report.scales.socioemotional_convergence': 'Socioemotional Convergence',
    'app.report.scales.physical_resonance': 'Physical Resonance',
    'app.report.scales.emotional_attunement': 'Emotional Attunement',
    'app.report.scales.self_awareness': 'Self Awareness',
    'app.report.scales.meaning_quest': 'Meaning Quest',
    'app.report.scales.religious_traditionalism': 'Religious Traditionalism',
    'app.report.scales.gender_traditionalism': 'Gender Traditionalism',
    'app.report.scales.sociocultural_openness': 'Sociocultural Openness',
    'app.report.scales.ecological_resonance': 'Ecological Resonance',
    'app.report.scales.global_resonance': 'Global Resonance',
    'app.report.aggregate_profile.high': 'High',
    'app.report.aggregate_profile.low': 'Low',
    'app.report.aggregate_profile.average': 'Average',
    'app.report.background_statistic_item.income_participants': '{0} of {1} participants - {2} %',
    'app.report.background_statistic_item.background_information': 'Background Information',
    'app.report.background_statistic_item.lowest': 'Lowest',
    'app.report.background_statistic_item.middle': 'Middle',
    'app.report.background_statistic_item.highest': 'Highest',
    'app.report.background_statistic_item.age': 'Age',
    'app.report.background_statistic_item.average_age': 'Average Age:',
    'app.report.background_statistic_item.answer': 'Answer:',
    'app.report.background_statistic_item.not_answer': 'Not answer:',
    'app.report.background_statistic_item.range': 'Range:',
    'app.report.background_statistic_item.gender': 'Gender',
    'app.report.background_statistic_item.ethnic_background': 'Ethnic Background',
    'app.report.background_statistic_item.country_of_origin': 'Country of Origin',
    'app.report.background_statistic_item.raised_in': 'Raised in',
    'app.report.background_statistic_item.not_raised_in': 'Not Raised in',
    'app.report.background_statistic_item.details': 'Details',
    'app.report.background_statistic_item.years_of_education': 'Years of education',
    'app.report.background_statistic_item.income': 'Income',
    'app.report.background_statistic_item.average': 'Average:',
    'app.report.background_statistic_item.marital_status': 'Relationship Status',
    'app.report.background_statistic_item.religion': 'Religion',
    'app.report.background_statistic_item.interest': 'Interest',
    'app.report.background_statistic_item.satisfaction': 'Satisfaction',
    'app.report.background_statistic_item.domain_scores': 'Domain Scores',
    'app.report.background_statistic_item.validity': 'Validity',
    'app.report.background_statistic_item.average_missing_items': 'Average Missing Items',
    'app.report.background_statistic_item.average_quantitative_completion_time': 'Average Quantitative Completion Time',
    'app.report.background_statistic_item.full_scale': 'Full Scale',
    'app.report.background_statistic_item.formative_variables': 'I. Formative Variables',
    'app.report.background_statistic_item.fulfillment_of_core_needs': 'II. Fulfillment of Core Needs',
    'app.report.background_statistic_item.tolerance_of_disequilibrium': 'III. Tolerance of Disequilibrium',
    'app.report.background_statistic_item.critical_thinking': 'IV. Critical Thinking',
    'app.report.background_statistic_item.self_access': 'V. Self Access',
    'app.report.background_statistic_item.other_access': 'VI. Other Access',
    'app.report.background_statistic_item.global_access': 'VII. Global Access',
    'app.report.decile_profile.high': 'High',
    'app.report.decile_profile.low': 'Low',
    'app.report.decile_profile.average': 'Average',
    'app.report.background_domain_contrast.title': 'Background Domain Contrast',
    'app.report.background_domain_contrast.lowest_full_scale': 'Lowest Full Scale',
    'app.report.background_domain_contrast.middle_full_scale': 'Middle Full Scale',
    'app.report.background_domain_contrast.highest_full_scale': 'Highest Full Scale',
    'app.report.background_domain_contrast.mean': 'Mean',
    'app.report.background_domain_contrast.range': 'Range',
    'app.report.background_domain_contrast.background_information': 'Background Information',
    'app.report.profile_contrast.lowest': 'Lowest',
    'app.report.profile_contrast.middle': 'Middle',
    'app.report.profile_contrast.highest': 'Highest',
    'app.report.profile_contrast.lowest_optimal_profiles': 'Lowest Optimal Profiles',
    'app.report.profile_contrast.middle_optimal_profiles': 'Middle Optimal Profiles',
    'app.report.profile_contrast.highest_optimal_profiles': 'Highest Optimal Profiles',
    'app.report.aggregate_profile.country_of_origin.selected_country': 'Selected Country',
    'app.report.aggregate_profile.country_of_origin.selected_countries': 'Selected Countries',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Selected Ethnic',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Selected Ethnics',
    'app.report.aggregate_profile.country_of_origin.other': 'Other',
    'app.report.aggregate_profile.religious_orientation.other': 'Other',
    'app.report.aggregate_profile.parents_country.same': 'Same',
    'app.report.aggregate_profile.parents_country.different': 'Different',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Selected Relationship Status',
    'app.report.aggregate_profile.religion.selected_religion': 'Selected Religion',
    'app.report.aggregate_profile.relationship_status.other': 'Other',
    'app.report.aggregate_profile.gender.male': 'Male',
    'app.report.aggregate_profile.gender.female': 'Female',
    'app.report.aggregate_profile.gender.self_identify': 'Self Identify',
    'app.report.aggregate_profile.education.lowest_30': 'Lowest 30%',
    'app.report.aggregate_profile.education.highest_30': 'Highest 30%',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'Selected ethnicity',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Selected ethnicities',
    'app.report.aggregate_profile.ethnicity.other': 'Other',
    'app.report.aggregate_profile.income.bottom_third': 'Bottom third',
    'app.report.aggregate_profile.income.middle_third': 'Middle third',
    'app.report.aggregate_profile.income.top_third': 'Top third',
    'app.report.aggregate_profile.interest.low': 'Low',
    'app.report.aggregate_profile.interest.middle': 'Middle',
    'app.report.aggregate_profile.interest.high': 'High',
    'app.report.aggregate_profile.politics.low': 'More Conservative/Traditional',
    'app.report.aggregate_profile.politics.middle': 'Moderate/Neither',
    'app.report.aggregate_profile.politics.high': 'More Liberal/Progressive',
    'app.report.aggregate_profile.age.low': 'Low',
    'app.report.aggregate_profile.age.middle': 'Middle',
    'app.report.aggregate_profile.age.high': 'High',
    'app.report.aggregate_profile.religion.low': 'Low',
    'app.report.aggregate_profile.religion.middle': 'Middle',
    'app.report.aggregate_profile.religion.high': 'High',
    'app.report.aggregate_profile.satisfaction.low': 'Low',
    'app.report.aggregate_profile.satisfaction.middle': 'Middle',
    'app.report.aggregate_profile.satisfaction.high': 'High',
    'app.report.aggregate_profile.mother_father_education.low': 'Low',
    'app.report.aggregate_profile.mother_father_education.middle': 'Middle',
    'app.report.aggregate_profile.mother_father_education.high': 'High',
    'app.report.aggregate_profile.time_spent_other_countries.low': 'Low',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Middle',
    'app.report.aggregate_profile.time_spent_other_countries.high': 'High',
    'app.report.aggregate_profile.minority_group.minority': 'Minority',
    'app.report.aggregate_profile.minority_group.majority': 'Majority',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'Selected Primary Area of Work',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'Selected Primary Areas of Work',
    'app.report.aggregate_profile.primary_area_of_work.other': 'Other',
    'app.report.aggregate_profile.years_of_language_study.low': 'Low',
    'app.report.aggregate_profile.years_of_language_study.middle': 'Middle',
    'app.report.aggregate_profile.years_of_language_study.high': 'High',
    'app.report.aggregate_profile.academic_rank.low': 'Low',
    'app.report.aggregate_profile.academic_rank.middle': 'Middle',
    'app.report.aggregate_profile.academic_rank.high': 'High',
    'app.report.aggregate_profile.visited_countries.low': 'Low',
    'app.report.aggregate_profile.visited_countries.middle': 'Middle',
    'app.report.aggregate_profile.visited_countries.high': 'High',
    'app.report.aggregate_profile.fluency.low': 'Don\'t or Minimum',
    'app.report.aggregate_profile.fluency.middle': 'Somewhat or Modestly',
    'app.report.aggregate_profile.fluency.high': 'Mostly Fluent or Fluent',
    'app.report.aggregate_demographic.core_demographics': 'Core Demographics',
    'app.report.aggregate_demographic.parental_background': 'Parental Background',
    'app.report.aggregate_demographic.political_religious_inclinations': 'Political and Religious Inclinations',
    'app.report.aggregate_demographic.education_background': 'Education Background',
    'app.report.aggregate_demographic.international_cultural_experiences': 'International and Cultural Experiences',
    'app.report.aggregate_demographic.age': 'Age',
    'app.report.aggregate_demographic.gender': 'Gender',
    'app.report.aggregate_demographic.relationship_status': 'Relationship Status',
    'app.report.aggregate_demographic.country_origin': 'Country of Origin',
    'app.report.aggregate_demographic.parent_country': 'Parent\'s Country',
    'app.report.aggregate_demographic.mother_education': 'Mother\'s Education',
    'app.report.aggregate_demographic.father_education': 'Father\'s Education',
    'app.report.aggregate_demographic.ethnic_background': 'Ethnic Background',
    'app.report.aggregate_demographic.minority_group': 'Minority Group',
    'app.report.aggregate_demographic.political_orientation': 'Political Orientation',
    'app.report.aggregate_demographic.religious_orientation': 'Religious Orientation',
    'app.report.aggregate_demographic.religious_commitment': 'Religious Commitment',
    'app.report.aggregate_demographic.income_level': 'Income Level',
    'app.report.aggregate_demographic.years_education': 'Years of Education',
    'app.report.aggregate_demographic.primary_area_work': 'Primary area or work',
    'app.report.aggregate_demographic.academic_rank': 'Academic Rank',
    'app.report.aggregate_demographic.time_spent_countries': 'Time spent in other countries',
    'app.report.aggregate_demographic.visited_countries': 'Visited Countries',
    'app.report.aggregate_demographic.personal_interest': 'Personal interest',
    'app.report.aggregate_demographic.study_abroad_satisfaction': 'Study abroad satisfaction',
    'app.report.aggregate_demographic.fluency': 'Fluency',
    'app.report.aggregate_demographic.years_language_study': 'Years of Language Study',
    'app.report.comparative_individual_report_result_title': 'BEVI Individual Change Report Results',
    'app.report.comparative_individual_report_result_description': 'From one administration to the next, you are moving between different "bands" of change on the BEVI (i.e., you may be showing substantial change from one administration to the next). "Being Bevi" can tell you more about the changes that are actually occurring for you between different BEVI administrations (i.e., Being Bevi is the AI entity that lives within the Beviverse -- click on the circle in the lower right hand corner). If there is colored text below, that typically means that your scores have changed substantially from one administration of the BEVI to the next.  The colored text references the direction(s) in which this change has occurred across different administrations of the BEVI (e.g., typically that would be from the first time you took the BEVI to the second time, but that really depends upon how many times you have taken the BEVI and which comparisons you are making). If you want to go more in depth, to see specific changes for you on the 17 scales of the BEVI, please either contact your BEVI Administrator or get in touch with the BEVI Team at',
    'app.report.comparative_individual_report_result_description_no_change': 'From one administration to the next, you are staying within the same "band" of change. "Being Bevi" can tell you more about the changes that are actually occurring for you between one BEVI administration to the next (i.e., Being Bevi is the AI entity that lives within the Beviverse -- click on the circle in the lower right hand corner).  If you want to go more in depth, to see specific changes for you on the 17 scales of the BEVI, please either contact your BEVI Administrator or get in touch with the BEVI Team at',
    'app.report.partner_report_result_title': 'BEVI Partner Report Results',
    'app.report.partner_change_report_result_title': 'BEVI Partner Change Report Results',
    'app.report.permission.modal_title': 'Dear BEVI Participant',
    'app.report.permission.modal_description': `
    <h5>You already provided consent to take the BEVI, but before we can work with you on your BEVI report, we are asking you to agree:</h5>
    <br />
    <p>
    1) that you want to participate in one or more sessions where your individual report will be discussed with you and
    </p>
    <p>
    2) that you are authorizing <b>[#USERS#]</b> to review your individual report (e.g., your narrative, scores) and discuss the meaning or implications of your BEVI report with you. 
    Of course, as indicated in the consent form for the BEVI, your individual results will remain completely confidential. If you have any questions, or need further information, please contact the BEVI administrator who is working with you.
    </p>
    <p>
    If you understand and agree to the two above provisions, please click the "I Agree" button below. 
    </p>
    <center>Thank you for your interest in the BEVI!</center>
    `,
    'app.report.permission.pending': 'Pending',
    'app.report.permission.approved': 'Approved',
    'app.report.permission.rejected': 'Rejected',
    'app.report.permission.approve': 'I AGREE',
    'app.report.permission.reject': 'I DO NOT AGREE',
}
