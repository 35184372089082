import { Slider } from 'antd';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { PageMessages } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { SelectQuestion } from '../library/questionDefinitions';
import { colors } from '../../../config/colors';

type Props = Partial<typeof Form.Group> & { groupAs?: typeof Col, groupMd?: string } & {
    question: SelectQuestion;
    questionNumber: number;
    minLabel: keyof (PageMessages);
    min: number;
    maxLabel: keyof (PageMessages);
    max: number;
    style?: React.CSSProperties;
    answerChange: (key: keyof (DemographicQuestionAnswers), value: number | string | undefined) => void;
    mdLabel?: number;
};

export const SliderOptions = (
    { mdLabel, minLabel, min, maxLabel, max, question, questionNumber, answerChange, groupAs, groupMd, style, ...groupProps }: Props
): JSX.Element => {
    const [currentValue, setCurrentValue] = useState<number>();

    return (
        <Form.Group className='mb-3' as={groupAs} md={groupMd} style={style} {...groupProps}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={question.GetQuestionLabelId()} />
                </div>
            </Form.Label>
            <br />
            <Form.Group className='mb-3' as={Row}>
                <Form.Label column={true} md={mdLabel ?? 2} xs={3} style={styles.sliderLeftLabel}>
                    {GetFormattedMessage(minLabel)}
                </Form.Label>
                <Form.Group className='mb-3' as={Col}>
                    <Slider
                        id={question.GetQuestionLabelId()}
                        //tooltipVisible={currentValue !== undefined && currentValue !== 0}
                        tooltipVisible={false}
                        tooltipPlacement={'bottom'}
                        defaultValue={0}
                        min={min}
                        max={max}
                        onChange={(value: number | [number, number]) => {
                            if (typeof value === 'number') {
                                setCurrentValue(value);
                                answerChange(question.GetQuestionKeyName(), value);
                            }
                        }}
                    />
                    {(currentValue === undefined || currentValue === 0) &&
                        <Form.Label style={styles.tooltip}>
                            {GetFormattedMessage('app.demographic_slider_default_option')}
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Label column={true} md={mdLabel ?? 2} xs={3} style={styles.sliderRightLabel}>
                    {GetFormattedMessage(maxLabel)}
                </Form.Label>
            </Form.Group>
        </Form.Group>
    )
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
    sliderLeftLabel: {
        textAlign: 'right'
    } as React.CSSProperties,
    sliderRightLabel: {
        textAlign: 'left'
    } as React.CSSProperties,
    tooltip: {
        backgroundColor: colors.darkGray,
        padding: '2px 10px',
        color: 'white',
        borderRadius: '3px',
    } as React.CSSProperties,
};
