import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    loadScaleQuestions, LoadScaleQuestionsResponse, SaveScaleAnswersResponse, saveScaleQuestions
} from '../services/questionsService';
import { ScaleQuestion, ScaleQuestionAnswers } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, loadQuestionsTimeout } from './constants';
import { formLogic, formsActionsConstants } from './formsLogic';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';
import { ValidateExternalToken, ValidateToken } from '../utils/validateHelper';

// actions
const actionsLiterals = asLiterals(['loadScaleQuestions', 'sendAnswers']);
export type ScaleQuestionsActions = { [K in (typeof actionsLiterals)[number]]: any };
export const scaleActionsConstants: ScaleQuestionsActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setScaleQuestions', 'setError', 'successfulSend'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'currentQuestions', 'isLastPage', 'finishQuestions']);
export type ScaleQuestionsProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const scaleQuestionsPropsConstants: ScaleQuestionsProps = { ...arrayToObject(reducersLiterals) };

const privatePropsLiterals = asLiterals([
    'paginatedQuestions', 'page', 'fetching', 'sending'
]);
type PrivateProps = { [K in (typeof privatePropsLiterals)[number]]: any };
const privatePropsConstants: PrivateProps = { ...arrayToObject(privatePropsLiterals) };

// type reducers parameter
type ReducersParam = { actions: ScaleQuestionsActions & PrivateActions; };
type SelectorsParam = { selectors: ScaleQuestionsProps & PrivateProps };

type WorkersParam = {
    workers: {
        fetchScaleQuestionsRequest: () => void;
        saveAnswers: () => void;
    }
};

// Define payloads type.
type ScaleQuestionsPayload = { questions: Array<Array<ScaleQuestion>> };
type ErrorPayload = { error: string; };

type AnswersPayload = { answers: ScaleQuestionAnswers };
type SendAnswersRequest = { payload: AnswersPayload };

type NextPagePayload = { page: number };

type LoadScaleQuestionsPayload = { formId: number };
type LoadScaleQuestionsRequest = { payload: LoadScaleQuestionsPayload };

type LoadScaleQuestionsRaceResponse = {
    loadQuestionsResponse?: LoadScaleQuestionsResponse;
}
type SaveScaleAnswersRaceResponse = {
    saveAnswersResponse?: SaveScaleAnswersResponse;
}

// persist app reducer
export const scaleQuestionsPath = 'scaleQuestions';

export const scaleQuestionsReducer = keaReducer(scaleQuestionsPath);

export const scaleQuestionsLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
        actions: [formLogic, [formsActionsConstants.updateFormPercentage]],
    },

    path: () => [scaleQuestionsPath],

    actions: () => ({
        [scaleActionsConstants.loadScaleQuestions]:
            (formId: number): LoadScaleQuestionsPayload => ({ formId }),
        [scaleActionsConstants.sendAnswers]:
            (answers: ScaleQuestionAnswers ): AnswersPayload => ({ answers }),

        [privateActionsConstants.successfulSend]:
            (page: number): NextPagePayload => ({ page }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.setScaleQuestions]:
            (questions: Array<Array<ScaleQuestion>>): ScaleQuestionsPayload => ({ questions }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [privatePropsConstants.fetching]: [false, PropTypes.bool, {
            [actions.loadScaleQuestions]: () => true,
            [actions.setError]: () => false,
            [actions.setScaleQuestions]: () => false,
            [logout]: () => false
        }],
        [privatePropsConstants.sending]: [false, PropTypes.bool, {
            [actions.sendAnswers]: () => true,
            [actions.setError]: () => false,
            [actions.successfulSend]: () => false,
            [logout]: () => false
        }],
        [scaleQuestionsPropsConstants.error]: [null, PropTypes.string, {
            [actions.loadScaleQuestions]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [privatePropsConstants.paginatedQuestions]: [null, PropTypes.any, {
            [actions.loadScaleQuestions]: () => null,
            [actions.setScaleQuestions]: (_: string, payload: ScaleQuestionsPayload) => payload.questions,
            [logout]: () => null
        }],
        [privatePropsConstants.page]: [0, PropTypes.number, {
            [actions.loadScaleQuestions]: () => 0,
            [actions.successfulSend]: (_: number, payload: NextPagePayload) => payload.page,
            [logout]: () => 0
        }],
    }),

    selectors: ({ selectors }: SelectorsParam) => ({
        [scaleQuestionsPropsConstants.loading]: [
            () => [selectors.fetching, selectors.sending],
            (fetching: boolean, sending: boolean) => fetching || sending,
            PropTypes.bool
        ],
        [scaleQuestionsPropsConstants.currentQuestions]: [
            () => [selectors.paginatedQuestions, selectors.page],
            (paginatedQuestions: Array<Array<ScaleQuestion>>, page: number) => {
                if (!paginatedQuestions) return null;
                if (page < paginatedQuestions.length) return paginatedQuestions[page];
                return [];
            }
        ],
        [scaleQuestionsPropsConstants.finishQuestions]: [
            () => [selectors.paginatedQuestions, selectors.page],
            (paginatedQuestions: Array<Array<ScaleQuestion>>, page: number) => {
                if (!paginatedQuestions) return false;
                if (page < paginatedQuestions.length) return false;
                return true;
            }
        ],
        [scaleQuestionsPropsConstants.isLastPage]: [
            () => [selectors.paginatedQuestions, selectors.page],
            (paginatedQuestions: Array<Array<ScaleQuestion>>, page: number) => {
                if (!paginatedQuestions) return false;
                if (page !== (paginatedQuestions.length - 1)) return false;
                return true;
            }
        ],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadScaleQuestions]: workers.fetchScaleQuestionsRequest,
        [actions.sendAnswers]: workers.saveAnswers
    }),

    workers: {
        * fetchScaleQuestionsRequest(action: LoadScaleQuestionsRequest): any {
            //@ts-ignore
            const { setError, setScaleQuestions } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }
            
            const { formId } = action.payload;

            const { loadQuestionsResponse }: LoadScaleQuestionsRaceResponse = yield race({
                loadQuestionsResponse: call(loadScaleQuestions, token, formId),
                timeout: delay(loadQuestionsTimeout)
            });

            if (loadQuestionsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (loadQuestionsResponse?.status === successfulCode) {
                if (!!loadQuestionsResponse?.body?.questions) {
                    yield put(setScaleQuestions(loadQuestionsResponse.body.questions));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (loadQuestionsResponse?.keyErrorMessage) {
                yield put(setError(loadQuestionsResponse.keyErrorMessage));
            } else {
                yield put(setError(generalExceptionKey));
            }
        },

        * saveAnswers(action: SendAnswersRequest): any {
            const { answers } = action.payload;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }
            
            //@ts-ignore
            const { setError, successfulSend, updateFormPercentage } = this.actionCreators;

            const { saveAnswersResponse }: SaveScaleAnswersRaceResponse = yield race({
                saveAnswersResponse: call(saveScaleQuestions, token, answers),
                timeout: delay(loadQuestionsTimeout)
            });

            if (saveAnswersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (saveAnswersResponse?.status === successfulCode) {
                if (saveAnswersResponse?.body) {
                    yield put(updateFormPercentage(saveAnswersResponse?.body));
                }
                //@ts-ignore
                const currentPage = yield this.get(privatePropsConstants.page);
                yield put(successfulSend(currentPage + 1));
            } else if (saveAnswersResponse?.keyErrorMessage) {
                yield put(setError(saveAnswersResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
