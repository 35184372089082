// TODO(rodrigo.santos): Remove this test toy file.

import { kea, keaReducer, useActions, useValues } from 'kea';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LanguageSwitch } from '../components/LanguageSwitch';
import { arrayToObject, asLiterals } from '../utils/helpers';

// actions
const actionsLiterals = asLiterals(['increment', 'decrement']);
export type CounterActions = { [K in (typeof actionsLiterals)[number]]: any };
export const counterActionsConstants: CounterActions = { ...arrayToObject(actionsLiterals) };

// props
const reducersLiterals = asLiterals(['counter', 'doubleCounter']);
export type CounterProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const counterPropsConstants: CounterProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: CounterActions; };
type SelectorsParam = { selectors: CounterProps };

// Define payloads type.
type AmountPayload = { amount: number; };

// persist app reducer
export const counterKeaPath = 'counter';

export const counterReducer = keaReducer(counterKeaPath);

const logic = kea({
    path: () => [counterKeaPath],

    actions: () => ({
        increment: (amount: number) => ({ amount }),
        decrement: (amount: number) => ({ amount })
    }),

    reducers: ({ actions }: ReducersParam) => ({
        counter: [0, {
        [actions.increment]: (state: number, payload: AmountPayload) => state + payload.amount,
        [actions.decrement]: (state: number, payload: AmountPayload) => state - payload.amount
        }]
    }),

    selectors: ({ selectors }: SelectorsParam) => ({
        doubleCounter: [
        () => [selectors.counter],
        (counter: number) => counter * 2
        ]
    })
})


export default function Counter () {
    const { increment, decrement } = useActions(logic)
    const { counter, doubleCounter } = useValues(logic)

    return (
        <div>
        <p><FormattedMessage id="app.counter.counter" />: {counter}</p>
        <p><FormattedMessage id="app.counter.double.counter" />: {doubleCounter}</p>
        <p>
            <button onClick={() => increment(1)}>+</button>
            <button onClick={() => decrement(1)}>-</button>
        </p>
        <LanguageSwitch />
        </div>
    )
}

