import { InvitationPageMessages } from '../types';

export const invitationCA: InvitationPageMessages = {
    'app.invitation.invite_label_template': '(TODO) You were invited to participate in the BEVI by {0}. You will be registered for program {1} and group {2}.',
    'app.invitation.email': '(TODO) Email',
    'app.invitation.password': '(TODO) Password',
    'app.invitation.confirm_password': '(TODO) Repeat password',
    'app.invitation.name': '(TODO) Name',
    'app.invitation.language': '(TODO) Language',
    'app.invitation.blank_feedback': '(TODO) This field cannot be blank!',
    'app.invitation.invalid_email_feedback': '(TODO) This email is invalid!',
    'app.invitation.password_length_feedback_template': '(TODO) This field must have at least {0} caracters!',
    'app.invitation.confirm_password_not_equal_feedback': '(TODO) This field must be equals to the password!',
    'app.invitation.success_notification_header': '(TODO) Welcome to BEVI!',
    'app.invitation.success_notification_body': '(TODO) Your registered email and password will be used to log into the system in the next few times.',
    'app.invitation.error_notification_header': '(TODO) An error occurred.',
    'app.invitation.error_notification_body': '(TODO) Please check the data sent and try again.',
}
