import { NoInfoMessages } from '../types';

export const noInfoES: NoInfoMessages = {
    'app.no.info.title.not_found': 'NO SE HA ENCONTRADO LA PÁGINA',
    'app.no.info.title.no_data': 'NO SE HAN ENCONTRADO DATOS',
    'app.no.info.subtitle.not_found': '¡Parece que te has perdido!',
    'app.no.info.subtitle.no_data': 'Asegúrate de que tus parámetros de búsqueda son correctos y si se produce un error ponte en contacto con info@thebevi.com.',
    'app.no.info.body.not_found': 'La página que estás buscando no existe o se ha trasladado.',
    'app.no.info.body.no_data': 'Echa un vistazo a los campos que elijas y si todo es correcto, ponte en contacto con el administrador de la cuenta',
    'app.no.info.title.not_authorized': 'PÁGINA NO AUTORIZADA',
    'app.no.info.subtitle.not_authorized': 'Parece que estás intentando acceder a una página para la que no tienes permiso',
    'app.no.info.body.not_authorized': 'Si quieres acceder a ella, por favor, ponte en contacto con el administrador de la cuenta.',
    'app.no.info.title.maintenance': 'SISTEMA EN MANTENIMIENTO',
    'app.no.info.subtitle.maintenance': 'Parece que el sistema está en mantenimiento',
    'app.no.info.body.maintenance': 'Por favor, espera unos minutos y vuélvelo a intentar.',
}
