import { TimeMessages } from '../types';

export const timeCA: TimeMessages = {
  'app.time.day': 'dia',
  'app.time.days': 'dies',
  'app.time.hour': 'hora',
  'app.time.hours': 'hores',
  'app.time.minute': 'minut',
  'app.time.minutes': 'minuts',
  'app.time.second': 'segon',
  'app.time.seconds': 'segons',
  'app.time.and': 'i',
}
