import React from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';

import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
const ReactExport: any = require('react-export-excel-xlsx-fix');

const ExcelFile = ReactExport.modules.ExcelFile;
const ExcelSheet = ReactExport.modules.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.modules.ExcelFile.ExcelColumn;

const dataSetExample = [
    {
        name: "Example Name",
        login: "bevi@bevi.com",
        language: 1,
    },
];

export const ExcelTemplate = (): JSX.Element => {
    return (
        <ExcelFile
            element={
                <ButtonWithIcon
                    key={'btn'}
                    textId={'app.create.user.download.data.example'}
                    icon={<FaCloudDownloadAlt />}
                />}>
            <ExcelSheet data={dataSetExample} name="Sheet1">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Login" value="login" />
                <ExcelColumn label="Language" value="language" />
            </ExcelSheet>
        </ExcelFile>
    );
}
