import React, { useEffect, useState } from 'react';
import { useActions, useValues } from 'kea';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserProfilePermission } from './UserProfilePermission';
import { ReportActions, reportLogic, ReportProps } from '../../redux/reportLogic';
import { SelectItem } from '../../components/FormDropdown';
import { ProgramModel, OrganizationModel } from '../../services/reportService';
import { Loading } from '../../components/Loading';
import { usersLogic, UsersActions, UsersProps } from '../../redux/usersLogic';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { UserPermissionModel } from '../../services/usersService';
import { notification } from 'antd';

type Props = RouteComponentProps;

export const UserProfilePermissionContainer = withRouter((props: Props): JSX.Element => {

  const {
    loadOrganizations
  }: ReportActions = useActions(reportLogic);
  const {
    loading, organizations,
  }: ReportProps = useValues(reportLogic);

  const {
    searchUserPermission, saveUserPermission, addUserPermissionExcelFile
  }: UsersActions = useActions(usersLogic);
  const {
    loadingSearchUsersPermission, usersPermission, loadingSaveUsersPermissionStatus, saveUsersPermissionStatus, userAddPermissionError,
    loadingAddUserPermission, hasAddUserPermission
  }: UsersProps = useValues(usersLogic);

  const [feedbackMessage, setFeedbackMessage] = useState<boolean>(false);
  const [loadFirstTime, setLoadFirstTime] = useState<boolean>(false);

  const selectProgramText = GetFormattedMessage('app.create_report.select_program');
  const invalidEmail = GetFormattedMessage('app.create_batch_user.error_wrong_email');

  const OnOrganizationChange = (organizationId: number | undefined): SelectItem[] => {

    if (organizationId === undefined) return [];

    const selectedOrganizationIndex = organizations.findIndex((t: OrganizationModel) => t.id === organizationId);
    if (selectedOrganizationIndex < 0) return [];

    const selectedOrganization = organizations[selectedOrganizationIndex];
    const programOptions: SelectItem[] = selectedOrganization?.programs?.map(
      (prog: ProgramModel) => ({ value: prog.id, label: prog.name })
    ) ?? [];

    programOptions.unshift({ value: undefined, label: selectProgramText })

    return programOptions;
  }

  useEffect(() => {
    if (userAddPermissionError != null && userAddPermissionError.length > 0) {
      notification.error({
        key: 'wrong-email',
        duration: 0,
        message: <>
          <b>{invalidEmail}</b>
          {userAddPermissionError.map((x: any) => (<div>{x.email}</div>))}
        </>
      })
    }
  }, [invalidEmail, userAddPermissionError])

  useEffect(() => {
    console.log(hasAddUserPermission)
  }, [hasAddUserPermission])

  useEffect(() => {
    if (!organizations) loadOrganizations();
  }, [organizations, loadOrganizations]);

  useEffect(() => {
    setFeedbackMessage(saveUsersPermissionStatus);
  }, [saveUsersPermissionStatus]);

  const searchHandleClick = (organizationId: number, program: number | undefined) => {
    searchUserPermission(organizationId, program);
    setLoadFirstTime(true);
  }

  const updateIndividualReportUserPermissionClick = (users: UserPermissionModel[]) => {
    saveUserPermission(users);
  }

  const clearFeedbackHandle = () => {
    setFeedbackMessage(false);
  }

  const addUserPermission = (permissions: number[], file: File) => {
    addUserPermissionExcelFile({ permissions, file });
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <UserProfilePermission
      organizations={organizations}
      onOrganizationChange={OnOrganizationChange}
      loading={loadingSearchUsersPermission || loadingSaveUsersPermissionStatus}
      searchHandleClick={searchHandleClick}
      users={usersPermission}
      updatedUsers={feedbackMessage}
      updateIndividualReportUserPermissionClick={updateIndividualReportUserPermissionClick}
      clearFeedbackHandle={clearFeedbackHandle}
      loadFirstTime={loadFirstTime}
      addUserPermission={addUserPermission}
      loadingAddUserPermission={loadingAddUserPermission}
      hasAddUserPermission={hasAddUserPermission}
    />
  );
});
