import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, put } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import { downloadBetweenGroupReports, getComparativeByGroupReport, ReportLanguage } from '../services/reportService';
import { ComparativeByGroupReportItem, LongitudinalReportItem } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['loadComparativeByGroupReport', 'downloadBetweenGroupReport']);
export type ComparativeByGroupReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const comparativeByGroupReportConstants: ComparativeByGroupReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReport', 'setSuccessfullDownload', 'setDataDownloadError'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'report', 'loadingDataDownload']);
export type ComparativeByGroupReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const comparativeByGroupReportPropsConstants: ComparativeByGroupReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ComparativeByGroupReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        fetchComparativeByGroupReport: () => void;
        fetchDownloadBetweenGroupReport: () => void;
    },
};

// Define payloads type.
type ErrorPayload = { error: string; };
type ComparativeByGroupReportPayload = { reportId: number };

type ReportPayload = { data: ComparativeByGroupReportItem };
type ComparativeByGroupReportRequest = { payload: ComparativeByGroupReportPayload };

type DownloadBetweenGroupReportRequestPayload = { language: ReportLanguage, reportId: number };
type DownloadBetweenGroupReportRequestPayloadRequest = { payload: DownloadBetweenGroupReportRequestPayload };

// persist app reducer
export const comparativeByGroupReportPath = 'comparativeByGroupReport';
export const comparativeByGroupReportReducer = keaReducer(comparativeByGroupReportPath);

export const comparativeByGroupReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [comparativeByGroupReportPath],

    actions: () => ({
        [comparativeByGroupReportConstants.loadComparativeByGroupReport]:
            (reportId: number): ComparativeByGroupReportPayload => ({ reportId }),
        [privateActionsConstants.setReport]:
            (data: LongitudinalReportItem): ReportPayload => ({ data }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [comparativeByGroupReportConstants.downloadBetweenGroupReport]:
            (language: ReportLanguage, reportId: number): DownloadBetweenGroupReportRequestPayload => ({ language, reportId }),
        [privateActionsConstants.setSuccessfullDownload]: () => true,
        [privateActionsConstants.setDataDownloadError]:
                (error: string): ErrorPayload => ({ error }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [comparativeByGroupReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.loadComparativeByGroupReport]: () => true,
            [actions.setError]: () => false,
            [actions.setReport]: () => false,
            [logout]: () => false
        }],
        [comparativeByGroupReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.loadComparativeByGroupReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [comparativeByGroupReportPropsConstants.report]: [null, PropTypes.any, {
            [actions.setReport]: (_: ComparativeByGroupReportItem, payload: ReportPayload) => payload.data,
            [actions.loadComparativeByGroupReport]: () => null,
            [logout]: () => null
        }],
        [comparativeByGroupReportPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.downloadBetweenGroupReport]: () => true,
            [actions.setSuccessfullDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadComparativeByGroupReport]: workers.fetchComparativeByGroupReport,
        [actions.downloadBetweenGroupReport]: workers.fetchDownloadBetweenGroupReport,
    }),

    workers: {
        * fetchComparativeByGroupReport(action: ComparativeByGroupReportRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { reportId } = action.payload;
            //@ts-ignore
            const { setError, setReport } = this.actionCreators;
            const comparativeByGroupReportResponse = yield call(getComparativeByGroupReport, token, reportId);

            if (comparativeByGroupReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (comparativeByGroupReportResponse?.status === successfulCode) {
                yield put(setReport(comparativeByGroupReportResponse?.body))
            } else if (!!comparativeByGroupReportResponse?.keyErrorMessage) {
                yield put(setError(comparativeByGroupReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchDownloadBetweenGroupReport(action: DownloadBetweenGroupReportRequestPayloadRequest): any {
            //@ts-ignore
            const { setDataDownloadError, setSuccessfullDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { language, reportId } = action.payload;
            const downloadWithinGroupReportResponse = yield call(
                downloadBetweenGroupReports,
                token,
                { reportId, language }
            );

            if (downloadWithinGroupReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (downloadWithinGroupReportResponse?.status === successfulCode) {
                yield put(setSuccessfullDownload());
            } else if (!!downloadWithinGroupReportResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(downloadWithinGroupReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        }
    }
});
