import { Alert } from 'antd';
import React, { useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
  changePassword: (password: string, confirmationPassword: string) => void;
  loading: boolean;
  errorKey: string;
};

export const ChangePassword = ({ changePassword, loading, errorKey }: Props): JSX.Element => {

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  return (
    <React.Fragment>
      {errorKey &&
        <div className={"pb-3"}>
          <Alert message={<FormattedMessage id={errorKey} />} type='error' showIcon />
        </div>
      }
      <Form.Group className='mb-3' as={Row} controlId='formField1'>
        <FormattedMessage id='app.login.lbl_new_password' />
        <Form.Control
          type='password'
          placeholder={GetFormattedMessage('app.login.placeholder.password')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className='mb-3' as={Row} controlId="formField2">
        <FormattedMessage id='app.login.lbl_new_password_confirmation' />
        <Form.Control
          className="form-control mb-3"
          type='password'
          placeholder={GetFormattedMessage('app.login.placeholder.password')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(e.target.value)}
        />
      </Form.Group>
      <div className="d-flex align-items-center mb-4">
        <Button className="btn px-4 shadow-lg btn-primary ml-auto rounded-pill"
          disabled={loading}
          style={styles.button}
          type='submit'
          onClick={() => { changePassword(password, passwordConfirmation) }}
        >
          {loading ?
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            :
            <FormattedMessage id='app.login.btn_submit_new_password' />
          }
        </Button>
      </div>
    </React.Fragment>
  );
}

const styles = {
  button: {
    width: 200,
  } as React.CSSProperties,
}

