import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { VirtualAccount } from './VirtualAccount';
import { VirtualAccountActions, VirtualAccountProps, virtualAccountLogic } from '../../redux/virtualAccountLogic';
import { useActions, useValues } from 'kea';
import { ReportActions, reportLogic, ReportProps } from '../../redux/reportLogic';
import { RequestVirtualAccount, RequestVirtualAccountCreate } from '../../services/virtualAccountService';
import { FilterVirtualAccount } from './components/Filter';
import { Dayjs } from 'dayjs';
import { AddInteractionsModal } from './components/Add';
import { Sleep } from '../../utils/sleeptHelper';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import dayjs from 'dayjs';
import { Alert } from 'antd';
import { AppProps, appLogic } from '../../redux/appLogic';

type Props = RouteComponentProps;

export const VirtalAccountContainer = withRouter((props: Props): JSX.Element => {

  const { tokenInfo }: AppProps = useValues(appLogic);
  
  const {
    loadVirtualAccount, addInteractions
  }: VirtualAccountActions = useActions(virtualAccountLogic);

  const {
    loadOrganizations
  }: ReportActions = useActions(reportLogic);

  const {
    loading, organizations,
  }: ReportProps = useValues(reportLogic);

  const {
    virtualAccount, hasCreatedVirtualAccount, loadingVirtualAccount
  }: VirtualAccountProps = useValues(virtualAccountLogic);

  const [organizationId, setOrganizationId] = useState<number>();
  const [organizationName, setOrganizationName] = useState<string>('');
  const [date, setDate] = useState<Dayjs | null>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [lastOrganizationAdd, setLastOrganizationAdd] = useState<number>();

  useEffect(() => {
    async function clearSuccessAfterDelay() {
      await Sleep(10000);
      setSuccess(false);
    }

    if (success === true) {
      clearSuccessAfterDelay();
    }
  }, [success])

  useEffect(() => {
    if (organizationId) {
      setOrganizationName(organizations.find((x: any) => x.id === organizationId).name);
    } else {
      setOrganizationName('');
    }
  }, [organizationId, organizations])

  useEffect(() => {
    if (!organizations) loadOrganizations();
  }, [organizations, loadOrganizations]);

  const onSearch = () => {
    if (organizationId && date) {
      loadVirtualAccount({
        organizationId: organizationId,
        month: date.month() + 1,
        year: date.year()
      } as RequestVirtualAccount);
    }
  }

  const handlerAddInteraction = (interactions: number) => {
    setLastOrganizationAdd(organizationId);
    addInteractions({ interactions: interactions, organizationId: organizationId } as RequestVirtualAccountCreate);
  }

  useEffect(() => {
    if ((hasCreatedVirtualAccount === true)) {
      setShowModal(false);
      if (lastOrganizationAdd) {
        loadVirtualAccount({
          organizationId: lastOrganizationAdd,
          month: dayjs().month() + 1,
          year: dayjs().year()
        } as RequestVirtualAccount);
        setDate(dayjs());
        setOrganizationId(lastOrganizationAdd);
        setLastOrganizationAdd(undefined);
      }
    }
    setSuccess(hasCreatedVirtualAccount);
  }, [hasCreatedVirtualAccount, lastOrganizationAdd, loadVirtualAccount]);

  return (<>
    <FilterVirtualAccount userInfo={tokenInfo} organizations={organizations?.map((data: any) => ({
      value: data.id,
      label: data.name
    }))} setSelectOrganizationId={setOrganizationId} loadingOrganization={loading} setSelectDate={setDate} onSearch={onSearch}
      selectDate={date} selectOrganization={organizationId} setShowModal={setShowModal} />
    <AddInteractionsModal onModalHide={() => setShowModal(false)} showModal={showModal} addInteraction={handlerAddInteraction} organizationName={organizationName} />
    {success &&
      <Alert message={GetFormattedMessage('app.virtual_account.successful_message')} type="info" closable onClose={() => setSuccess(false)} />
      // <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.virtual_account.successful_message') }} />
    }
    <VirtualAccount data={virtualAccount} loading={loadingVirtualAccount} />
  </>
  );
});
