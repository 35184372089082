import { LoginPageMessages } from '../types';

export const loginPageZHTW: LoginPageMessages = {
    'app.login.text_welcome': '欢迎访问 the Beliefs, Events, and Values Inventory (BEVI)',
    'app.login.text_information': '若想访问BEVI网站，您必须受到有BEVI使用权的大学、机构或管理者的邀请',
    'app.login.text_login_requirement': '请输入您的用户名和密码。',
    'app.login.lbl_username': '用户名:',
    'app.login.lbl_password': '密码:',
    'app.login.btn_submit': '登录',
    'app.login.lbl_new_password': '新密码',
    'app.login.lbl_new_password_confirmation': '再次输入新密码',
    'app.login.btn_submit_new_password': '重设密码',
    'app.login.placeholder.login': '电子邮箱',
    'app.login.placeholder.password': '您的密码',
    'app.login.invalid_credentials': '电子邮箱或密码错误。请重试。或点击以下“密码设定或重设”按钮，重新设置您的账号。',
    'app.login.forgot_password.invalid_credentials': '电子邮箱错误。请重试。',
    'app.login.forgot_password': '密码设定或重设',
    'app.login.forgot_password_confirmation_title': '重设密码',
    'app.login.forgot_password_confirmation_body': '系统已给您刚才登录的电子邮箱发了邮件。请查收邮件并按其指示重设密码。',
    'app.login.btn_submit_forgot_password': '密码重设（输入电子邮箱并点击）',
    'app.login.passwords_dont_match': '密码不一致。 ',
    'app.login.change_password.expired_confirmation_title': '无法显示该页面。',
    'app.login.change_password.expired_confirmation_body': '页面权限已过期。再次请求访问或重新登录BEVI。',
    'app.login.change_password.success_confirmation_title': '成功完成了再设定。',
    'app.login.change_password.success_confirmation_body': '成功设置了新密码。下次请使用新密码登录。',
    'app.login.first_access.title': '请在密码变更后使用。',
}
