import React from 'react';
import { AggregateProfileByDemographics } from './AggregateProfileByDemographics';
import { GetPrimaryAreaOfWorkByCode } from '../../../utils/demographHelper';
import { GroupReportAggregateByDemographics_PrimaryAreaOfWork } from '../../../services/types';
import { ScaleGroup } from '../../../services/reportService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { InsufficientDataComponent } from './InsufficientDataComponent';
import { getScaleTitle } from '../../../utils/reportHelper';

type Props = {
  selectedPrimaryAreaOfWork: number[];
  report: GroupReportAggregateByDemographics_PrimaryAreaOfWork[];
  subProgramName?: string[];
}

type ScaleProps = {
  title: string;
  value: number;
  hasSpace: boolean;
}

const hasEnoughData = (report: GroupReportAggregateByDemographics_PrimaryAreaOfWork[]) => {
  let invalidReportCount = 0;
  for (let i = 0; i < report.length; i++) {
    if (!report[i].hasEnoughData) {
      invalidReportCount += 1;
    }
  }

  if (report.length === 1) {
    if (invalidReportCount === 0) return true;
    return false;
  }
  else {
    return report.length - invalidReportCount > 1;
  }
}

const buildSubTitle = (report: GroupReportAggregateByDemographics_PrimaryAreaOfWork[],
  subProgramName?: string[]) => {

  if (report.length === 1) return undefined;
  const subTitle: string[][] = [];
  for (let i = 0; i < report.length; i++) {
    if (!report[i].hasEnoughData) continue;

    subTitle.push(
      [
        `${subProgramName && subProgramName[i]} - (N = ${report[i].selectedAreaOfWorkNumberOfUsers})`,
        `${subProgramName && subProgramName[i]} - (N = ${report[i].otherNumberOfUsers})`,
      ]
    )

  }
  return subTitle;
}

const buildScales = (selectedPrimaryAreaOfWork: number[], report: GroupReportAggregateByDemographics_PrimaryAreaOfWork[],
  scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {
  const scalePropos: ScaleProps[] = [];
  const scaleTitle = getScaleTitle(scale);

  const selectedCountryText = selectedPrimaryAreaOfWork.length > 1 ?
    GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work')
    :
    GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work');
  const other = GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.other');
  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const countryOfOrigin = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${selectedCountryText})`,
      value: countryOfOrigin.scales[scale][0],
      hasSpace: i === 0 && name !== ''
    });
  }

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const countryOfOrigin = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${other})`,
      value: countryOfOrigin.scales[scale][1],
      hasSpace: i === 0 && name !== ''
    });
  }

  return scalePropos;
}

export const AggregateProfileByDemographics_PrimaryAreaOfWork = ({ selectedPrimaryAreaOfWork, report, subProgramName }: Props) => {

  if (!hasEnoughData(report)) {
    return <InsufficientDataComponent />
  }

  const selectedCountryText = selectedPrimaryAreaOfWork.length > 1 ?
    GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work')
    :
    GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work');

  const other = GetFormattedMessage('app.report.aggregate_profile.primary_area_of_work.other');
  return (
    <AggregateProfileByDemographics
      title={[
        `${selectedCountryText}: ${selectedPrimaryAreaOfWork.map((item) => GetPrimaryAreaOfWorkByCode(item)).join(', ')} (N = ${report.map(item => item.selectedAreaOfWorkNumberOfUsers).reduce((prev, next) => prev + next)})`,
        `${other} (N = ${report.map(item => item.otherNumberOfUsers).reduce((prev, next) => prev + next)})`,
      ]}
      subTitle={buildSubTitle(report, subProgramName)}
      negativeLifeEvents={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.NegativeLifeEvents, 1, subProgramName)}
      needsClosure={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.NeedsClosure, 2, subProgramName)}
      needsFulfillment={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.NeedsFulfillment, 3, subProgramName)}
      identityDiffusion={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.IdentityDiffusion, 4, subProgramName)}
      basicOpenness={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.BasicOpenness, 5, subProgramName)}
      selfCertitude={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.SelfCertitude, 6, subProgramName)}
      basicDeterminism={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.BasicDeterminism, 7, subProgramName)}
      socioemotionalConvergence={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.SocioemotionalConvergence, 8, subProgramName)}
      physicalResonance={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.PhysicalResonance, 9, subProgramName)}
      emotionalAttunument={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.EmotionalAttunement, 10, subProgramName)}
      selfAwereness={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.SelfAwereness, 11, subProgramName)}
      meaningQuest={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.MeaningQuest, 12, subProgramName)}
      religiousTraditionalism={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.ReligiousTraditionalism, 13, subProgramName)}
      genderTraditionalism={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.GenderTraditionalism, 14, subProgramName)}
      socioCulturalOpenness={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.SocioculturalOpenness, 15, subProgramName)}
      ecologicalResonance={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.EcologicalResonance, 16, subProgramName)}
      globalResonance={buildScales(selectedPrimaryAreaOfWork, report, ScaleGroup.GlobalResonance, 17, subProgramName)}
    />
  )
}