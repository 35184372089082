import React from 'react';

import { GraphBar } from '../../../components/GraphBar';
import { ScaleGroup } from '../../../services/reportService';
import { GroupReportAggregateProfile } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { GetReportItemColor } from '../../../utils/reportHelper';

type Props = {
    title?: string;
    aggregateProfile: GroupReportAggregateProfile;
}

type ProfileSummary = {
    label: string;
    group: ScaleGroup;
}

export const AggregateProfile = ({ aggregateProfile, title }: Props): JSX.Element => {
    const profileSummaries: ProfileSummary[] = [
        { label: GetFormattedMessage('app.report.scales.negative_life_events'), group: ScaleGroup.NegativeLifeEvents },
        { label: GetFormattedMessage('app.report.scales.needs_closure'), group: ScaleGroup.NeedsClosure },
        { label: GetFormattedMessage('app.report.scales.needs_fulfillment'), group: ScaleGroup.NeedsFulfillment },
        { label: GetFormattedMessage('app.report.scales.identity_diffusion'), group: ScaleGroup.IdentityDiffusion },
        { label: GetFormattedMessage('app.report.scales.basic_openness'), group: ScaleGroup.BasicOpenness },
        { label: GetFormattedMessage('app.report.scales.self_certitude'), group: ScaleGroup.SelfCertitude },
        { label: GetFormattedMessage('app.report.scales.basic_determinism'), group: ScaleGroup.BasicDeterminism },
        { label: GetFormattedMessage('app.report.scales.socioemotional_convergence'), group: ScaleGroup.SocioemotionalConvergence },
        { label: GetFormattedMessage('app.report.scales.physical_resonance'), group: ScaleGroup.PhysicalResonance },
        { label: GetFormattedMessage('app.report.scales.emotional_attunement'), group: ScaleGroup.EmotionalAttunement },
        { label: GetFormattedMessage('app.report.scales.self_awareness'), group: ScaleGroup.SelfAwereness },
        { label: GetFormattedMessage('app.report.scales.meaning_quest'), group: ScaleGroup.MeaningQuest },
        { label: GetFormattedMessage('app.report.scales.religious_traditionalism'), group: ScaleGroup.ReligiousTraditionalism },
        { label: GetFormattedMessage('app.report.scales.gender_traditionalism'), group: ScaleGroup.GenderTraditionalism },
        { label: GetFormattedMessage('app.report.scales.sociocultural_openness'), group: ScaleGroup.SocioculturalOpenness },
        { label: GetFormattedMessage('app.report.scales.ecological_resonance'), group: ScaleGroup.EcologicalResonance },
        { label: GetFormattedMessage('app.report.scales.global_resonance'), group: ScaleGroup.GlobalResonance },
    ];

    return (
        <div className='report-texts'>
            <GraphBar
                title={GetFormattedMessage('app.report.scales.consistency')}
                value={aggregateProfile.scales[ScaleGroup.Consistency]}
                personalizedBarColor={GetReportItemColor(ScaleGroup.Consistency)}
            />
            <GraphBar
                title={GetFormattedMessage('app.report.scales.congruency')}
                value={aggregateProfile.scales[ScaleGroup.Congruency]}
                personalizedBarColor={GetReportItemColor(ScaleGroup.Congruency)}
            />
            <hr />
            {profileSummaries.map((summary: ProfileSummary, index: number) => (
                <GraphBar
                    key={`${index + 1}. ${summary.label}`}
                    title={`${index + 1}. ${summary.label}`}
                    value={aggregateProfile.scales[summary.group]}
                    personalizedBarColor={GetReportItemColor(summary.group)}
                />
            ))}
        </div>
    )
}
