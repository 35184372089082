import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    getVirtualAccount,
    AiChatVirtualAccount,
    RequestVirtualAccount,
    RequestVirtualAccountCreate,
    addInteractions
} from '../services/virtualAccountService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'loadVirtualAccount', 'clearVirtualAccount', 'clearSuccessfulCreateVirtualAccount', 'addInteractions'
]);
export type VirtualAccountActions = { [K in (typeof actionsLiterals)[number]]: any };
export const virtualAccountActionsConstants: VirtualAccountActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setVirtualAccount', 'setVirtualAccountError', 'successfulCreateVirtualAccount'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'error', 'loadingVirtualAccount', 'virtualAccount', 'hasCreatedVirtualAccount'
]);
export type VirtualAccountProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const virtualAccountPropsConstants: VirtualAccountProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: VirtualAccountActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchGetVirtualAccountRequest: () => void,
        fetchAddInteractionsRequest: () => void,
    }
};

// Define payloads type.
type ErrorPayload = { errorKey: string; };

type GetVirtualAccountRequest = { payload: { virtualAccount: RequestVirtualAccount } };
type PostVirtualAccountRequest = { payload: { virtualAccount: RequestVirtualAccountCreate } };

type StatusSaveVirtualAccountPayload = { success: boolean; }

type VirtualAccountPayload = { Prograns: AiChatVirtualAccount[] };
const VirtualAccountsPropType = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
});

// persist forms reducer
export const virtualAccountKeaPath = 'virtualAccount';

export const virtualAccountReducer = keaReducer(virtualAccountKeaPath);

export const virtualAccountLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [virtualAccountKeaPath],

    actions: () => ({
        [privateActionsConstants.setVirtualAccountError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [virtualAccountActionsConstants.loadVirtualAccount]:
            (virtualAccount: RequestVirtualAccount): any => ({ virtualAccount }),
        [privateActionsConstants.setVirtualAccount]:
            (Prograns: AiChatVirtualAccount[]): VirtualAccountPayload => ({ Prograns }),
        [virtualAccountActionsConstants.clearVirtualAccount]: true,
        [virtualAccountActionsConstants.clearSuccessfulCreateVirtualAccount]: true,
        [virtualAccountActionsConstants.addInteractions]:
            (virtualAccount: RequestVirtualAccountCreate): any => ({ virtualAccount }),
        [privateActionsConstants.successfulCreateVirtualAccount]: (): StatusSaveVirtualAccountPayload => ({ success: true }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [virtualAccountPropsConstants.error]: [null, PropTypes.string, {
            [actions.setVirtualAccountError]: () => (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.clearSuccessfulCreateVirtualAccount]: () => null,
            [logout]: () => null,
        }],
        [virtualAccountPropsConstants.loadingVirtualAccount]: [false, PropTypes.bool, {
            [actions.loadVirtualAccount]: () => true,
            [actions.setVirtualAccount]: () => false,
            [actions.setVirtualAccountError]: () => false,
        }],
        [virtualAccountPropsConstants.virtualAccount]: [null, PropTypes.objectOf(VirtualAccountsPropType), {
            [actions.setVirtualAccount]:
                (_: AiChatVirtualAccount, payload: VirtualAccountPayload) => payload.Prograns,
            [actions.clearVirtualAccount]: () => null
        }],
        [virtualAccountPropsConstants.hasCreatedVirtualAccount]: [false, PropTypes.bool, {
            [actions.successfulCreateVirtualAccount]: () => true,
            [actions.clearSuccessfulCreateVirtualAccount]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadVirtualAccount]: workers.fetchGetVirtualAccountRequest,
        [actions.addInteractions]: workers.fetchAddInteractionsRequest,
    }),

    workers: {
        * fetchGetVirtualAccountRequest(action: GetVirtualAccountRequest): any {
            //@ts-ignore
            const { setVirtualAccount, setVirtualAccountError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { virtualAccount } = action.payload;

            const VirtualAccountResponse = yield call(getVirtualAccount, token, virtualAccount);

            if (VirtualAccountResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (VirtualAccountResponse?.status === successfulCode) {
                if (VirtualAccountResponse?.body) {
                    yield put(setVirtualAccount(VirtualAccountResponse?.body));
                } else {
                    yield put(setVirtualAccountError(generalExceptionKey));
                }
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchAddInteractionsRequest(action: PostVirtualAccountRequest): any {

            //@ts-ignore
            const { successfulCreateVirtualAccount, setVirtualAccountError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { virtualAccount } = action.payload;

            const { virtualAccountResponse } = yield race({
                virtualAccountResponse: call(addInteractions, token, virtualAccount),
                timeout: delay(requestTimeout)
            });

            if (virtualAccountResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (virtualAccountResponse?.status === successfulCode) {
                yield put(successfulCreateVirtualAccount(virtualAccountResponse?.body));
            } else if (virtualAccountResponse?.keyErrorMessage) {
                yield put(setVirtualAccountError(virtualAccountResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    },

})
