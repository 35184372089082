import React, { useState } from 'react';
import { Col, Form, FormCheck, Row, Spinner } from 'react-bootstrap';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { ButtonWithIcon } from '../../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../../components/FormDropdown';
import { H2 } from '../../../../components/styled/h2';
import { GetFormattedMessage, getLanguages } from '../../../../utils/htmlHelper';
import { ExcelDropzone } from '../ExcelDropzone';
import { ExcelTemplate } from '../ExcelTemplate';

type Props = {
    acceptedFiles: File[];
    loading: boolean;
    getRootProps(props?: DropzoneRootProps): DropzoneRootProps;
    getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
    onClearSelectedFile: () => void;
    onClickSubmit: (organizationId: number | undefined, sendEmail?: boolean) => void;
    organizations: SelectItem[];
}

export const MultipleUser = (props: Props): JSX.Element => {

    const [organizationId, setOrganization] = useState<number | undefined>(undefined);
    const [sendEmail, setSendEmail] = useState<boolean>(true);

    return (
        <div style={styles.container}>
            <H2 textAlign='left'>{GetFormattedMessage('app.create.user.create.multiple.user.title')}</H2>
            <hr />
            <br />
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <p>{GetFormattedMessage('app.create.user.download.template.file')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <ExcelTemplate />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col>
                            <ExcelDropzone {...props} />
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={{ span: 10, offset: 2 }}>
                            <h5>{GetFormattedMessage('app.create.user.language_codes')}</h5>
                            <ul>
                                {
                                    getLanguages().map((item) =>
                                        <li key={`Language-${item.value}`}>{item.label}: {item.value}</li>)
                                }
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={{ span: 6 }}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.user.organization')}</b></p></div>
                        </Form.Label>
                        <FormDropdown
                            isSearchable={true}
                            defaultOptionId={'app.listorganizations.select_organization'}
                            onValueSelect={(value: number | undefined) => {
                                setOrganization(value);
                            }}
                            optionItens={props.organizations}
                        />
                    </Form.Group>
                </Col>
                <Col md={{ span: 6 }}>
                    <Form.Group className='mb-3' controlId='sendEmail'>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <div><p><b>{GetFormattedMessage('app.create.user.send_welcome_email')}</b></p></div>
                            </Form.Label>
                            <FormCheck
                                type='switch'
                                checked={sendEmail}
                                label={''}
                                onChange={(event) => {
                                    setSendEmail(event.target.checked);
                                }}
                            />
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
            <div style={styles.button}>
                <ButtonWithIcon
                    disabled={props.loading || props.acceptedFiles === undefined ||
                        props.acceptedFiles.length === 0 || organizationId === undefined}
                    textId={'app.create.user.upload.file'}
                    icon={props.loading ?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        :
                        <IoMdAddCircleOutline />
                    }
                    onClick={() => props.onClickSubmit(organizationId, sendEmail)}
                />
            </div>
        </div>
    );
}

const styles = {
    container: {
        marginTop: 40,
        // textAlign: 'left',
    } as React.CSSProperties,
    button: {
        marginTop: 40,
    } as React.CSSProperties,
};
