import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { colors } from '../../../config/colors';
import { BackgroundItem } from '../../../services/types';
import { GetCountryByCode, GetEthnicOrientationByCode, GetGenderByCode, GetMaritalStatusByCode, GetReligionByCode, GetLevelOfInterestByCode } from '../../../utils/demographHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { GetIncomeGroup } from '../../../utils/reportHelper';
type Props = {
    title: string[];
    background: BackgroundItem;
    selectedCountryCodes: number[];
}

export const BackgroundStatisticItem = ({ title, background, selectedCountryCodes }: Props) => {

    const [collapseCountryOfOrigin, setCollapseCountryOfOrigin] = useState(false);

    return (
        <React.Fragment>
            {title.map((item, index) =>
                <Row key={`BackgroundDomainContrastItemTitle-${index}`}>
                    <Col>
                        <h2 style={index === 0 ? styles.title : styles.subTitle}>{item}</h2>
                        <hr />
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.age')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.age_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.age_NotAnswer}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.range')} {background.age_Range}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.average_age')} {background.age_Average}</li>
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.gender')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.gender_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.gender_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.gender).map((item, index) => {
                                return <li key={`Gender-${index}`}>{GetGenderByCode(parseInt(item))}: {background.gender[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.ethnic_background')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.ethnicBackground_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.ethnicBackground_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.ethnicBackground).map((item, index) => {
                                return <li key={`EthnicOrientation-${index}`}>{GetEthnicOrientationByCode(parseInt(item))}: {background.ethnicBackground[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.country_of_origin')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.countryOfOrigin_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')}: {background.countryOfOrigin_NotAnswer}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.raised_in')} {selectedCountryCodes.map((item) => GetCountryByCode(item)).join(', ')}: {background.countryOfOrigin_RaisedInSelectedCountry}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_raised_in')} {selectedCountryCodes.map((item) => GetCountryByCode(item)).join(', ')}: {background.countryOfOrigin_NotRaisedInSelectedCountry}</li>
                    </ul>

                    <Button
                        onClick={() => setCollapseCountryOfOrigin(!collapseCountryOfOrigin)}
                        aria-controls="country-of-origin-collapse-text"
                        aria-expanded={collapseCountryOfOrigin}
                    >
                        {GetFormattedMessage('app.report.background_statistic_item.details')}
                    </Button>

                    <Collapse in={collapseCountryOfOrigin}>
                        <div id="country-of-origin-collapse-text" style={{ marginTop: 20 }}>
                            <ul>
                                {
                                    Object.keys(background.countryOfOriginDetails).map((item, index) => {
                                        return <li key={`Contry-${index}`}>{GetCountryByCode(parseInt(item))}: {background.countryOfOriginDetails[parseInt(item)]}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </Collapse>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.years_of_education')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.yearsOfEducation_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')}: {background.yearsOfEducation_NotAnswer}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.range')} {background.yearsOfEducation_RangeCodes.length > 0 ? background.yearsOfEducation_RangeCodes[0] : ''} - {background.yearsOfEducation_RangeCodes.length > 1 ? background.yearsOfEducation_RangeCodes[1] : ''}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.average')} {background.yearsOfEducation_Average}</li>
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.income')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.income_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.income_NotAnswer}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.average')} {GetIncomeGroup(background.income_Group)}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.average')} {background.income_Average}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.lowest')} {GetFormattedMessage('app.report.background_statistic_item.income_participants')
                            .replace('{0}', background.income_Lowest_NumberOfUsers.toString())
                            .replace('{1}', background.income_Answered.toString())
                            .replace('{2}', ((background.income_Lowest_NumberOfUsers / background.income_Answered) * 100).toFixed(2).toString())
                        }
                        </li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.range')} {background.income_Lowest_Range.length > 0 ? background.income_Lowest_Range[0] : ''} - {background.income_Lowest_Range.length > 1 ? background.income_Lowest_Range[1] : ''}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.middle')} {GetFormattedMessage('app.report.background_statistic_item.income_participants')
                            .replace('{0}', background.income_Middle_NumberOfUsers.toString())
                            .replace('{1}', background.income_Answered.toString())
                            .replace('{2}', ((background.income_Middle_NumberOfUsers / background.income_Answered) * 100).toFixed(2).toString())
                        }
                        </li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.range')} {background.income_Middle_Range.length > 0 ? background.income_Middle_Range[0] : ''} - {background.income_Middle_Range.length > 1 ? background.income_Middle_Range[1] : ''}</li>
                    </ul>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.highest')} {GetFormattedMessage('app.report.background_statistic_item.income_participants')
                            .replace('{0}', background.income_Highest_NumberOfUsers.toString())
                            .replace('{1}', background.income_Answered.toString())
                            .replace('{2}', ((background.income_Highest_NumberOfUsers / background.income_Answered) * 100).toFixed(2).toString())
                        }
                        </li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.range')} {background.income_Highest_Range.length > 0 ? background.income_Highest_Range[0] : ''} - {background.income_Highest_Range.length > 1 ? background.income_Highest_Range[1] : ''}</li>
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.marital_status')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.maritalStatus_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.maritalStatus_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.maritalStatus).map((item, index) => {
                                return <li key={`Maritalstatus-${index}`}>{GetMaritalStatusByCode(parseInt(item))}: {background.maritalStatus[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.religion')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.religions_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.religions_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.religions).map((item, index) => {
                                return <li key={`Religion-${index}`}>{GetReligionByCode(parseInt(item))}: {background.religions[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.interest')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.interest_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.interest_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.interest).map((item, index) => {
                                return <li key={`LevelOfInterest-${index}`}>{GetLevelOfInterestByCode(parseInt(item))}: {background.interest[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col sm={{ span: 11, offset: 1 }}>
                    <h4>{GetFormattedMessage('app.report.background_statistic_item.satisfaction')}</h4>
                    <ul>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.answer')} {background.satisfaction_Answered}</li>
                        <li>{GetFormattedMessage('app.report.background_statistic_item.not_answer')} {background.satisfaction_NotAnswer}</li>
                    </ul>
                    <ul>
                        {
                            Object.keys(background.satisfaction).map((item, index) => {
                                return <li key={`Satisfaction-${index}`}>{GetLevelOfInterestByCode(parseInt(item))}: {background.satisfaction[parseInt(item)]}</li>
                            })
                        }
                    </ul>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const styles = {
    title: {
        textAlign: 'center',
        color: colors.darkSlateBlue,
        fontSize: '20px'
    } as React.CSSProperties,
    subTitle: {
        textAlign: 'center',
        color: colors.darkSlateBlue,
        fontSize: '14px'
    } as React.CSSProperties,
};
