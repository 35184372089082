import { AppMessages } from '../types';

export const appCA: AppMessages = {
    'app.unknown_error': 'S’ha produït un error desconegut, si us plau posa’t en contacte amb els responsables.',
    'app.btn_start': 'Iniciar',
    'app.btn_restart': 'Reiniciar',
    'app.btn_cancel': 'Cancel·lar',
    'app.btn_next': 'Següent',
    'app.btn_ok': 'D\'acord',
    'app.btn_finish': 'Acabar',
    'app.btn_back': 'Tornar',
    'app.btn_confirm' : 'Confirmar',
    'app.dropdown_select': 'Selecciona...',
    'app.loading': 'Carregant',
    'app.response_yes': 'Sí',
    'app.response_no': 'No',
    'app.bevi_copyright_text_1': 'L’Inventari de creences, esdeveniments i valors (BEVI) és un inventari amb drets d\'autor. El BEVI i els informes del BEVI, ítems, contingut d\'ítems i escales no es poden modificar, copiar, difondre o publicar, totalment ni parcialment sense el permís exprés i per escrit de Craig N. Shealy, Ph.D.',
    'app.bevi_copyright_text_2': '',
    'app.empty.data': 'No hi ha dades',
    'app.systemerro': 'Llàstima! El sistema mostra inestabilitat; torna a provar d\'aquí a uns minuts.',
    'app.loading_document': 'S\'està carregant el document...',
    'app.download_document': 'Descarregar PDF',
    'app.download_excel': 'Descarregar XLSX',
    'app.btn_search': 'Cercar',
    'app.english': 'Anglès',
    'app.catalan': 'Català',
    'app.spanish': 'Espanyol',
    'app.japanese': 'Japonès',
    'app.chinese-t': 'Xinès tradicional',
    'app.chinese-s': 'Xinès simplificat',
    'app.true': 'Verdader',
    'app.false': 'Fals',
    'app.save': 'Desar',
    'app.btn_reset': 'Restablir',
    'app.btn_submit': 'Enviar',
    'app.search_placeholder': 'Escriu text de cerca',
    'app.notification.error_title': 'Error',
    'app.error.user_not_found': 'Usuari no trobat',
    'app.save_with_success': 'Desa amb èxit'
}
