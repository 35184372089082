import { appJA } from './ja/app';
import { consentPageJA } from './ja/consent';
import { counterPageJA } from './ja/counter';
import { createReportJA } from './ja/create_report';
import { createProgramJA } from './ja/createProgram';
import { dataDownloadJA } from './ja/dataDownload';
import { demographicQuestionsJA } from './ja/demographicQuestions';
import { discursiveQuestionsJA } from './ja/discursiveQuestions';
import { externalJA } from './ja/external';
import { individualReportPageJA } from './ja/individualReport';
import { internalTemplatePageJA } from './ja/internalTemplate';
import { listFormsPageJA } from './ja/listforms';
import { listOrganizationsJA } from './ja/listOrganizations';
import { listReportJA } from './ja/listReport';
import { loginPageJA } from './ja/login';
import { noInfoJA } from './ja/noInfo';
import { permissionJA } from './ja/permission';
import { reportsJA } from './ja/reports';
import { scaleQuestionsPageJA } from './ja/scaleQuestions';
import { timeJA } from './ja/time';
import { usersJA } from './ja/users';
import { PageMessages } from './types';
import { financialReportJA } from './ja/financialReport';
import { invitationJA } from './ja/invitation';
import { institutionJA } from './ja/institutions';
import { programsJA } from './ja/programs';
import { virtualAccountJA } from './ja/virtualAccount';

export const ja: PageMessages = {
    ...appJA,
    ...counterPageJA,
    ...loginPageJA,
    ...scaleQuestionsPageJA,
    ...consentPageJA,
    ...listFormsPageJA,
    ...demographicQuestionsJA,
    ...listOrganizationsJA,
    ...discursiveQuestionsJA,
    ...individualReportPageJA,
    ...internalTemplatePageJA,
    ...createProgramJA,
    ...usersJA,
    ...dataDownloadJA,
    ...noInfoJA,
    ...createReportJA,
    ...reportsJA,
    ...timeJA,
    ...listReportJA,
    ...permissionJA,
    ...externalJA,
    ...financialReportJA,
    ...invitationJA,
    ...institutionJA,
    ...programsJA,
    ...virtualAccountJA
}
