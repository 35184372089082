import { NoInfoMessages } from '../types';

export const noInfoCA: NoInfoMessages = {
    'app.no.info.title.not_found': 'PÀGINA NO TROBADA',
    'app.no.info.title.no_data': 'NO S’HAN TROBAT DADES',
    'app.no.info.subtitle.not_found': 'Sembla que t’has perdut!',
    'app.no.info.subtitle.no_data': 'Assegura’t que els paràmetres de cerca són correctes i, si hi ha un error, posa’t en contacte amb  info@thebevi.com.',
    'app.no.info.body.not_found': 'La pàgina que cerques no existeix o s’ha mogut.',
    'app.no.info.body.no_data': 'Mira els camps que tries i, si tot és correcte, posa’t en contacte amb el gestor del compte.',
    'app.no.info.title.not_authorized': 'PÀGINA NO AUTORITZADA',
    'app.no.info.subtitle.not_authorized': 'Sembla que proves d’accedir a una pàgina per a la qual no tens permís.',
    'app.no.info.body.not_authorized': 'Si vols accedir-hi, posa’t en contacte amb el gestor del compte.',
    'app.no.info.title.maintenance': 'SISTEMA EN MANTENIMENT',
    'app.no.info.subtitle.maintenance': 'Sembla que el sistema està en manteniment.',
    'app.no.info.body.maintenance': 'Si us plau, espera uns minuts i torna a provar-ho.',
}
