import { Popconfirm, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { FaEdit, FaRocketchat } from 'react-icons/fa';
import { IntlContext } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { Loading } from '../../components/Loading';
import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { colors } from '../../config/colors';
import { paginationDefaultPageSize } from '../../config/constants';
import { TokenInfo } from '../../services/loginService';
import { OrganizationDetails } from '../../services/organizationService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { IsAccountManager, IsSuperAdmin } from '../../utils/userHelper';

type Props = {
    data: OrganizationDetails[];
    loading: boolean;
    userInfo: TokenInfo;
    onEditClick: (oraganization: any) => void;
    onHideDemographicsQuestionsClicked: (institutionId: number) => void;
    onDemographicsExtraQuestionsHandleClick: (institutionId: number) => void;
    onChatAllClick: (institutionId: number) => void;
};

export const ListInstitutions = ({ data, loading, userInfo, onEditClick, onHideDemographicsQuestionsClicked,
    onDemographicsExtraQuestionsHandleClick, onChatAllClick }: Props): JSX.Element => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const { messages } = React.useContext(IntlContext);

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <Table
                    dataSource={data}
                    rowKey={(record: OrganizationDetails) => record.id.toString()}
                    locale={{ emptyText: messages['app.empty.data'].toString() }}
                    pagination={data.length > paginationDefaultPageSize ? defaultPagination : false}
                >
                    <Column
                        title={messages['app.institutions.list.name'].toString()}
                        dataIndex='name'
                        key='name'
                        width='75%'
                        sorter={(a: OrganizationDetails, b: OrganizationDetails) => a.name.localeCompare(b.name)}
                        {...filterOptions}
                        onFilter={(value, record: OrganizationDetails) =>
                            record.name.toLowerCase().includes(value.toString().toLowerCase())}
                    />
                    <Column
                        title={messages['app.institutions.list.action'].toString()}
                        key='action'
                        width='25%'
                        render={(_, record: OrganizationDetails) => (
                            <Row>
                                <div style={styles.marginRight8}>
                                    <ButtonWithIcon
                                        icon={<FaEdit />}
                                        onClick={() => onEditClick(record)}
                                        textId={'app.institutions.list.edit'}
                                    />
                                </div>
                                <div style={styles.marginRight8}>
                                    <ButtonWithIcon
                                        icon={<FaEdit />}
                                        onClick={() => onHideDemographicsQuestionsClicked(record.id)}
                                        textId={'app.institutions.add.hide_demographics_questions'}
                                    />
                                </div>
                                {(IsAccountManager(userInfo) || IsSuperAdmin(userInfo)) &&
                                    <div style={styles.marginRight8}>
                                        <ButtonWithIcon
                                            icon={<FaEdit />}
                                            onClick={() => onDemographicsExtraQuestionsHandleClick(record.id)}
                                            textId={'app.institutions.add.demographics_extra_questions'}
                                        />
                                    </div>}
                                {IsSuperAdmin(userInfo) &&
                                    <div style={styles.marginRight8}>
                                        <Popconfirm onConfirm={() => onChatAllClick(record.id)}
                                            title={GetFormattedMessage('app.institutions.chat.question_add_chat_all')} placement='bottom'>
                                            <ButtonWithIcon
                                                icon={<FaRocketchat />}
                                                textId={'app.institutions.chat.add_chat_all'}
                                            />
                                        </Popconfirm>
                                    </div>}
                            </Row>
                        )}
                    />
                </Table>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    marginRight8: {
        marginRight: '8px',
    } as React.CSSProperties,
};
