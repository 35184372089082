import { useActions, useValues } from 'kea';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { Loading } from '../../components/Loading';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import {
  GetAccessLargeWorld, GetAccessSelf, GetAccessToThoughtsAndFeelingsOfOthers, GetGenderTradition,
  GetNeedsClosure, GetNegativeLifeEvents, GetToleranceOfDisequilibruim, GetVariables,
  GetWhyWeDoText
} from '../../utils/reportHelper';
import { ComparativeIndividualReport } from './ComparativeIndividualReport';
import { DemographicsSection, TitleReportProps } from './ReportTypes';
import {
  FormalEducationOptionsByCode, GetCountryByCode, GetEthnicOrientationByCode, GetGenderByCode, GetLanguageOptionsByCode,
  GetLevelOfFluencyOptionsByCode, GetLevelOfInterestByCode, GetMaritalStatusByCode, GetParticipatedExperiencesOptionsByCode,
  GetParticipatedLearningCoursesOptionsByCode, GetPlanParticipateExperiencesOptionsByCode,
  GetPlanParticipateLearningCoursesOptionsByCode, GetPrimaryAreaOfWorkByCode, GetReligionByCode,
  GetTimeSpentInOtherCountriesOptionsByCode, GetYearOfEducationOptionsByCode
} from '../../utils/demographHelper';
import { ComparativeIndividualReportActions, comparativeIndividualReportLogic, ComparativeIndividualReportProps } from '../../redux/comparativeIndividualReportLogic';
import { Tab } from 'semantic-ui-react';
import { BackgroundStatistic, GroupReportAggregateProfile } from '../../services/types';
import { BeingBevi } from '../../components/BeingBevi';
import { Reports } from '../../services/reportService';
import { isNil } from 'lodash';
import { IsParticipant } from '../../utils/userHelper';
import { AppProps, appLogic } from '../../redux/appLogic';

type Props = RouteComponentProps;

enum QuestionType {
  StronglyAgree,
  StronglyDisagree,
}

enum DiscusiveQuestionType {
  Response1,
  Response2,
  Response3,
}

const BACKGROUND_COLORS = [
  '#FF000030', // Red 
  '#0000FF30', // Blue 
  '#FFA50030', // Orange
  '#80008030', // Purple
  '#FFFF0030', // Yellow 
  '#FFC0CB30', // Pink
  '#00FFFF30', // Cyan
  '#A9A9A930', // Dark gray
  '#A52A2A30', // Brown
  '#80800030', // Olive
];

export const ComparativeIndividualReportContainer = withRouter((props: Props): JSX.Element => {
  const { loading, reports, error }: ComparativeIndividualReportProps = useValues(comparativeIndividualReportLogic);
  const { tokenInfo }: AppProps = useValues(appLogic);

  const { id } = useParams<{ id: string }>();

  const [reportType, setReportType] = useState<string>();

  const {
    loadComparativeIndividualReport
  }: ComparativeIndividualReportActions = useActions(comparativeIndividualReportLogic);

  useEffect(() => {
    if (id) {
      loadComparativeIndividualReport({
        reportId: id,
        generateArea: IsParticipant(tokenInfo) ? 'Participant' : 'Admin'
      });
    }
  }, [id, loadComparativeIndividualReport, tokenInfo])

  const comparativeTabMessage = useMemo(() => {
    if (isNil(reports)) return '';
    if (id == null) {
      // COMPARATIVE INDIVIDUAL REPORT GENERATED ON THE PARTICIPANT AREA.
      return GetFormattedMessage('app.demographic.comparative');
    } else if (reports.length === 0) {
      return ''
    } else if (reports[0].reportType === Reports.individual_report) {
      // SAVED INDIVIDUAL REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.create_report.individual_report');
    } else if (reports[0].reportType === Reports.longitudinal_individual_report) {
      // SAVED COMPARATIVE INDIVIDUAL REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.demographic.comparative');
    } else if (reports[0].reportType === Reports.partner_report) {
      // SAVED PARTNERS REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.create_report.combined_report');
    } else if (reports[0].reportType === Reports.partner_change_report) {
      // SAVED PARTNERS CHANGE REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.create_report.partner_change_report');
    }
  }, [reports, id]);

  const comparativeReportTitle = useMemo(() => {
    if (isNil(reports)) return '';
    if (id == null) {
      // COMPARATIVE INDIVIDUAL REPORT GENERATED ON THE PARTICIPANT AREA.
      return GetFormattedMessage('app.report.comparative_individual_report_result_title');
    } else if (reports.length === 0) {
      return ''
    } else if (reports[0].reportType === Reports.individual_report) {
      // SAVED INDIVIDUAL REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.create_report.individual_report');
    } else if (reports[0].reportType === Reports.longitudinal_individual_report) {
      // SAVED COMPARATIVE INDIVIDUAL REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.report.comparative_individual_report_result_title');
    } else if (reports[0].reportType === Reports.partner_report) {
      // SAVED PARTNERS REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.report.partner_report_result_title');
    } else if (reports[0].reportType === Reports.partner_change_report) {
      // SAVED PARTNERS CHANGE REPORT GENERATED ON THE ADMIN AREA.
      return GetFormattedMessage('app.report.partner_change_report_result_title');
    }
    return '';
  }, [reports, id]);

  const panes = useMemo(() => {
    if (reports) {
      if (reports.length > 0) {
        if (id == null) {
          setReportType('ComparativeIndividualReportParticipant');
        } else if (reports[0].reportType === Reports.individual_report) {
          setReportType('ComparativeSingleIndividualReport');
        } else if (reports[0].reportType === Reports.longitudinal_individual_report) {
          setReportType('ComparativeIndividualReportAdmin');
        } else if (reports[0].reportType === Reports.partner_report) {
          setReportType('PartnerReport');
        } else if (reports[0].reportType === Reports.partner_change_report) {
          setReportType('PartnerChangeReport');
        }
      }

      var newTabs = [];
      if (reports.length > 1) {
        newTabs.push({
          menuItem: comparativeTabMessage,
          render: () =>
            <Tab.Pane>
              <ComparativeIndividualReportItem canViewExtraDetails={!IsParticipant(tokenInfo)} key={"Comparative"} loading={loading} individualReports={reports} comparativeTitle={comparativeReportTitle} />
            </Tab.Pane>
        })
      } else {
        newTabs = [...reports.map((individualReport: any, i: number) => {
          return {
            menuItem: individualReport.reportTitle,
            render: () =>
              <Tab.Pane>
                <ComparativeIndividualReportItem canViewExtraDetails={true} key={i} loading={loading} individualReports={[individualReport]} comparativeTitle={comparativeReportTitle} />
              </Tab.Pane>
          }
        })]
      }

      return newTabs;
    }

    return [];
  }, [comparativeTabMessage, loading, reports, id])

  if (loading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      {error ?
        <p>Error</p>
        :
        <>
          <Tab menu={{ pointing: true }} panes={panes} />
          {reportType && <BeingBevi type={reportType} formIds={reports.map((t: any) => t.formId)} id={id ?? ''} />}
        </>
      }
    </React.Fragment>
  )
});

type ComparativeIndividualReportItemProps = {
  loading: boolean,
  canViewExtraDetails: boolean,
  comparativeTitle: string,
  individualReports: any[]
}

const ComparativeIndividualReportItem = ({ loading, canViewExtraDetails, individualReports, comparativeTitle }: ComparativeIndividualReportItemProps) => {
  const question1Message = GetFormattedMessage('app.discursive_questions.question1');
  const question2Message = GetFormattedMessage('app.discursive_questions.question2');
  const question3Message = GetFormattedMessage('app.discursive_questions.question3');
  const demographicAgeMessage = GetFormattedMessage('app.demographic.age');
  const demographicGenderMessage = GetFormattedMessage('app.demographic.gender');
  const demographicEducationYearsMessage = GetFormattedMessage('app.demographic.education_years');
  const demographicMaritalStatusMessage = GetFormattedMessage('app.demographic.marital_status');
  const demographicEthnicMessage = GetFormattedMessage('app.demographic.ethnic');
  const demographicCountryMotherBornMessage = GetFormattedMessage('app.demographic.country_mother_born');
  const demographicCountryFatherBornMessage = GetFormattedMessage('app.demographic.country_father_born');
  const demographicDisadvantagedOrMinorityGroupMessage = GetFormattedMessage('app.demographic.disadvantaged_or_minority_group');
  const demographicPrimaryCountryMessage = GetFormattedMessage('app.demographic.primary_country');
  const demographicTimeInOtherCountriesMessage = GetFormattedMessage('app.demographic.time_in_other_countries');
  const demographicVisitCountriesMessage = GetFormattedMessage('app.demographic.visit_countries');
  const demographicPoliticalOrientationMessage = GetFormattedMessage('app.demographic.political_orientation');
  const demographicPoliticalCommitmentMessage = GetFormattedMessage('app.demographic.political_commitment');
  const demographicMotherFormalEducationMessage = GetFormattedMessage('app.demographic.mother_formal_education');
  const demographicFatherFormalEducationMessage = GetFormattedMessage('app.demographic.father_formal_education');
  const demographicPrimaryAreaOfWorkMessage = GetFormattedMessage('app.demographic.primary_area_of_work');
  const demographicPrimaryLanguageMessage = GetFormattedMessage('app.demographic.primary_language');
  const demographicLanguageLevelOfFluencyMessage = GetFormattedMessage('app.demographic.language_level_of_fluency');
  const demographicYearsStudiedForeignLanguageMessage = GetFormattedMessage('app.demographic.years_studied_foreign_language');
  const demographicIncomeLevelMessage = GetFormattedMessage('app.demographic.income_level');
  const demographicAcademicRankMessage = GetFormattedMessage('app.demographic.academic_rank');
  const demographicReligionMessage = GetFormattedMessage('app.demographic.religion');
  const demographicReligionScaleMessage = GetFormattedMessage('app.demographic.religion_scale');
  const demographicPersonalInterestMessage = GetFormattedMessage('app.demographic.personal_interest');
  const demographicExperienceSatisfactionMessage = GetFormattedMessage('app.demographic.experience_satisfaction');
  const demographicParticipatedExperiencesMessage = GetFormattedMessage('app.demographic.participated_experiences');
  const demographicPlanParticipateExperiencesMessage = GetFormattedMessage('app.demographic.plan_participate_experiences');
  const demographicParticipatedLearningCoursesMessage = GetFormattedMessage('app.demographic.participated_learning_courses');
  const demographicPlanParticipateLearningCoursesMessage = GetFormattedMessage('app.demographic.plan_participate_learning_courses');
  const foundationsTextMessage = GetFormattedMessage('app.individualreport.demographics.text');
  const formativeCoreTextMessage = GetFormattedMessage('app.individualreport.formativecore.text');
  const staticText5Message = GetFormattedMessage('app.individualreport.static_text_5');

  const buildDiscursiveQuestions = (type: DiscusiveQuestionType, individualReport: any) => {
    if (type === DiscusiveQuestionType.Response1) {
      let answer = individualReport.discusiveAnswer1 ?? 'Did not answer';
      return {
        title: `<b>Question - ${question1Message}</b>`,
        value: `Answer - ${answer}`
      }
    }
    else if (type === DiscusiveQuestionType.Response2) {
      let answer = individualReport.discusiveAnswer2 ?? 'Did not answer';
      return {
        title: `<b>Question - ${question2Message}</b>`,
        value: `Answer - ${answer}`
      }
    }
    else {
      let answer = individualReport.discusiveAnswer2 ?? 'Did not answer';
      return {
        title: `<b>Question - ${question3Message}</b>`,
        value: `Answer - ${answer}`
      }
    }
  }

  const buildScaleQuestions = (type: QuestionType, individualReport: any) => {
    let questions = [];
    if (type === QuestionType.StronglyAgree) {
      if (individualReport.scalesStronglyAgree === undefined || individualReport.scalesStronglyAgree === null ||
        individualReport.scalesStronglyAgree.questions === undefined || individualReport.scalesStronglyAgree.questions === null) {
        return undefined;
      }
      questions = individualReport.scalesStronglyAgree.questions;
    } else {
      if (individualReport.scalesStronglyDisagree === undefined || individualReport.scalesStronglyDisagree === null ||
        individualReport.scalesStronglyDisagree.questions === undefined || individualReport.scalesStronglyDisagree.questions === null) {
        return undefined;
      }
      questions = individualReport.scalesStronglyDisagree.questions;
    }

    questions.sort((a: any, b: any) => parseFloat(a.order) - parseFloat(b.order));
    return questions.map((item: any) => `${item.order} - ${GetFormattedMessage(item.localeKey)}`);
  }

  const buildDemographicsSection = (individualReport: any) => {
    if (individualReport.demographicsQuestions === undefined || individualReport.demographicsQuestions === null) return undefined;

    const result: DemographicsSection = {
      age: {
        title: `<b>Question - ${demographicAgeMessage}</b>`,
        value: `Answer - ${individualReport.demographicsQuestions.age !== undefined && individualReport.demographicsQuestions.age !== null ? individualReport.demographicsQuestions.age : 'Did not answer'}`
      },
      gender: {
        title: `<b>Question - ${demographicGenderMessage}</b>`,
        value: `Answer - ${individualReport.demographicsQuestions.gender !== undefined && individualReport.demographicsQuestions.gender !== null ? GetGenderByCode(individualReport.demographicsQuestions.gender) : 'Did not answer'}`
      },
      yearsOfFormalEducation: {
        title: `<b>Question - ${demographicEducationYearsMessage}</b>`,
        value: `Answer - ${individualReport.demographicsQuestions.completedYearOfEducation !== undefined && individualReport.demographicsQuestions.completedYearOfEducation !== null ? GetYearOfEducationOptionsByCode(individualReport.demographicsQuestions.completedYearOfEducation) : 'Did not answer'}`
      },
      relationshipStatus: {
        title: `<b>Question - ${demographicMaritalStatusMessage}</b>`,
        value: `Answer - ${individualReport.demographicsQuestions.maritalStatus !== undefined && individualReport.demographicsQuestions.maritalStatus !== null ? GetMaritalStatusByCode(individualReport.demographicsQuestions.maritalStatus) : 'Did not answer'}`
      },
      ethnicBackground: {
        title: `<b>Question - ${demographicEthnicMessage}</b>`,
        value: `Answer - ${individualReport.demographicsQuestions.ethnicBackground !== undefined && individualReport.demographicsQuestions.ethnicBackground !== null ? GetEthnicOrientationByCode(individualReport.demographicsQuestions.ethnicBackground) : 'Did not answer'}`,
        other: `Answer - (Other) - ${individualReport.demographicsQuestions.ethnicBackgroundOther !== undefined && individualReport.demographicsQuestions.ethnicBackgroundOther !== null ? individualReport.demographicsQuestions.ethnicBackgroundOther : 'Did not answer'}`
      },
      motherCountry: {
        title: `<b> Question - ${demographicCountryMotherBornMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.motherCountry !== undefined && individualReport.demographicsQuestions.motherCountry !== null ? GetCountryByCode(individualReport.demographicsQuestions.motherCountry) : 'Did not answer'} `
      },
      fatherCountry: {
        title: `<b> Question - ${demographicCountryFatherBornMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.fatherCountry !== undefined && individualReport.demographicsQuestions.fatherCountry !== null ? GetCountryByCode(individualReport.demographicsQuestions.fatherCountry) : 'Did not answer'} `
      },
      minorityGroup: {
        title: `<b> Question - ${demographicDisadvantagedOrMinorityGroupMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup !== undefined && individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup !== null ? (individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup === 1 ? GetFormattedMessage('app.response_yes') : GetFormattedMessage('app.response_no')) : 'Did not answer'} `,
        other: `Answer - (Other) - ${individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription !== undefined && individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription !== null ? individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription : 'Did not answer'} `
      },
      primaryCountry: {
        title: `<b> Question - ${demographicPrimaryCountryMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.primaryCountryOfOrigin !== undefined && individualReport.demographicsQuestions.primaryCountryOfOrigin !== null ? GetCountryByCode(individualReport.demographicsQuestions.primaryCountryOfOrigin) : 'Did not answer'} `
      },
      timeSpentInAnotherCountry: {
        title: `<b> Question - ${demographicTimeInOtherCountriesMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.timeInOtherCountry !== undefined && individualReport.demographicsQuestions.timeInOtherCountry !== null ? GetTimeSpentInOtherCountriesOptionsByCode(individualReport.demographicsQuestions.timeInOtherCountry) : 'Did not answer'} `
      },
      visitedCountries: {
        title: `<b> Question - ${demographicVisitCountriesMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.visitedOrLivedCountries !== undefined && individualReport.demographicsQuestions.visitedOrLivedCountries !== null ? individualReport.demographicsQuestions.visitedOrLivedCountries : 'Did not answer'} `
      },
      politicalOrientation: {
        title: `<b> Question - ${demographicPoliticalOrientationMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.politicalOrientation !== undefined && individualReport.demographicsQuestions.politicalOrientation !== null ? individualReport.demographicsQuestions.politicalOrientation : 'Did not answer'} `
      },
      levelPoliticalOrientation: {
        title: `<b> Question - ${demographicPoliticalCommitmentMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.politicalCommitment !== undefined && individualReport.demographicsQuestions.politicalCommitment !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.politicalCommitment) : 'Did not answer'} `
      },
      motherEducation: {
        title: `<b> Question - ${demographicMotherFormalEducationMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.motherFormalEducation !== undefined && individualReport.demographicsQuestions.motherFormalEducation !== null ? FormalEducationOptionsByCode(individualReport.demographicsQuestions.motherFormalEducation) : 'Did not answer'} `
      },
      fatherEducation: {
        title: `<b> Question - ${demographicFatherFormalEducationMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.fatherFormalEducation !== undefined && individualReport.demographicsQuestions.fatherFormalEducation !== null ? FormalEducationOptionsByCode(individualReport.demographicsQuestions.fatherFormalEducation) : 'Did not answer'} `
      },
      primaryAreaOfWork: {
        title: `<b> Question - ${demographicPrimaryAreaOfWorkMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest !== undefined && individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest !== null ? GetPrimaryAreaOfWorkByCode(individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest) : 'Did not answer'} `,
        others: [
          `Descripive Item #1 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 : 'Did not answer'}`,
          `Descripive Item #2 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 : 'Did not answer'}`,
          `Descripive Item #3 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 : 'Did not answer'}`,
        ]
      },
      primaryLanguage: {
        title: `<b> Question - ${demographicPrimaryLanguageMessage}</b> `,
        others: individualReport.demographicsQuestions.primaryLanguages !== null && individualReport.demographicsQuestions.primaryLanguages.map((item: any) => {
          return `Language: ${GetLanguageOptionsByCode(item.language)} `
        }),
        value: '',
      },
      otherFluentLanguages: {
        title: `<b> Question - ${demographicLanguageLevelOfFluencyMessage}</b> `,
        value: '',
        others: individualReport.demographicsQuestions.fluencyInLanguages !== null && individualReport.demographicsQuestions.fluencyInLanguages.map((item: any) => {
          return `Language: ${GetLanguageOptionsByCode(item.language)} - Fluency: ${GetLevelOfFluencyOptionsByCode(item.fluency)} `
        })
      },
      otherLanguages: {
        title: `<b> Question - (Other Option) - ${demographicLanguageLevelOfFluencyMessage}</b>`,
        value: '',
        others: individualReport.demographicsQuestions.fluencyInLanguagesOthers !== null && individualReport.demographicsQuestions.fluencyInLanguagesOthers.map((item: any) => {
          return `Language: ${item.language} - Fluency: ${GetLevelOfFluencyOptionsByCode(item.fluency)} `
        })
      },
      yearOfForeignLanguages: {
        title: `<b> Question - ${demographicYearsStudiedForeignLanguageMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage !== undefined && individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage !== null ? individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage : 'Did not answer'} `
      },
      income: {
        title: `<b> Question - ${demographicIncomeLevelMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.incomeLevelOfHomeCountry !== undefined && individualReport.demographicsQuestions.incomeLevelOfHomeCountry !== null ? individualReport.demographicsQuestions.incomeLevelOfHomeCountry : 'Did not answer'} `
      },
      academicRank: {
        title: `<b> Question - ${demographicAcademicRankMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.academicStandingOrRank !== undefined && individualReport.demographicsQuestions.academicStandingOrRank !== null ? individualReport.demographicsQuestions.academicStandingOrRank : 'Did not answer'} `
      },
      commitmentsReligion: {
        title: `<b> Question - ${demographicReligionMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion !== undefined && individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion !== null ? GetReligionByCode(individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion) : 'Did not answer'} `,
        other: `Answer - (Other) - ${individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligionOther ?? 'Did not answer'} `
      },
      levelCommitmentsReligion: {
        title: `<b> Question - ${demographicReligionScaleMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.commitmentTowardFaithReligions !== undefined && individualReport.demographicsQuestions.commitmentTowardFaithReligions !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.commitmentTowardFaithReligions) : 'Did not answer'} `,
      },
      personalInterest: {
        title: `<b> Question - ${demographicPersonalInterestMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning !== undefined && individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning) : 'Did not answer'} `,
      },
      currentlyParticipating: {
        title: `<b> Question - ${demographicExperienceSatisfactionMessage}</b> `,
        value: `Answer - ${individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram !== undefined && individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram) : 'Did not answer'} `,
      },
      previouslyCompletedExperiences: {
        title: `<b> Question - ${demographicParticipatedExperiencesMessage}</b> `,
        value: individualReport.demographicsQuestions.participatedExperiencesCapabilities !== undefined &&
          individualReport.demographicsQuestions.participatedExperiencesCapabilities !== null ?
          GetParticipatedExperiencesOptionsByCode(individualReport.demographicsQuestions.participatedExperiencesCapabilities) : ['Did not answer'],
      },
      completedExperiences: {
        title: `<b> Question - ${demographicPlanParticipateExperiencesMessage}</b> `,
        value: individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities !== undefined &&
          individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities !== null ?
          GetParticipatedLearningCoursesOptionsByCode(individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities) : ['Did not answer'],
      },
      previouslyCompletedLearningCoursesPrograms: {
        title: `<b> Question - ${demographicParticipatedLearningCoursesMessage}</b> `,
        value: individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities !== undefined &&
          individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities !== null ?
          GetPlanParticipateExperiencesOptionsByCode(individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities) : ['Did not answer'],
      },
      completedLearningCoursesPrograms: {
        title: `<b> Question - ${demographicPlanParticipateLearningCoursesMessage}</b> `,
        value: individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities !== undefined &&
          individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities !== null ?
          GetPlanParticipateLearningCoursesOptionsByCode(individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities) : ['Did not answer'],
      }
    }

    return result;
  }

  const relInfo = useMemo(() => {
    let foundations: string[] = [];
    let toleranceOfDisequilibrium: string[] = [];
    let whyWeDo: string[] = [];
    let accessSelf: string[] = [];
    let accessToTheThoughts: string[] = [];
    let accessToTheLargerWorld: string[] = [];
    let profiles: {
      reportTitle?: string,
      value: GroupReportAggregateProfile
    }[] = [];
    let backgroundStatistics: {
      reportTitle?: string,
      value: BackgroundStatistic
    }[] = [];
    let demographicsSections: {
      reportTitle?: string,
      value: DemographicsSection
    }[] = [];
    let scalesStronglyAgree: {
      reportTitle?: string,
      value: string[] | undefined
    }[] = [];
    let scalesStronglyDisagree: {
      reportTitle?: string,
      value: string[] | undefined
    }[] = [];
    let discusiveAnswers: {
      reportTitle?: string,
      value: {
        title: string,
        value: string
      }[]
    }[] = [];

    let hasFoundationChanged = false;
    let hasToleranceOfDisequilibriumChanged = false;
    let hasWhyWeDoChanged = false;
    let hasAccessSelfChanged = false;
    let hasAccessToTheThoughtsChanged = false;
    let hasAccessToTheLargerWorldChanged = false;

    const firstFoundationValue = `${individualReports[0].negativeLifeEventsIndicator}|${individualReports[0].needsClosureIndicator};`;
    const firstToleranceOfDisequilibriumValue = `${individualReports[0].toleranceDisequilibriumIndicator}`;
    const firstWhyWeDoValue = `${individualReports[0].whyWeDoIndicator}`;
    const firstAccessSelfValue = `${individualReports[0].accessSelfIndicator}|${individualReports[0].selfAwarenessIndicator}`;
    const firstAccessToTheThoughtsValue = `${individualReports[0].religiousTraditionalimsIndicator}|${individualReports[0].genderTraditionIndicator}|${individualReports[0].socioCulturalIndicator}`;
    const firstAccessToTheLargerWorldValue = `${individualReports[0].ecologicalResonanceIndicator}|${individualReports[0].globalEngagementIndicador}`;

    for (let index = 1; index < individualReports.length; index++) {
      const individualReport = individualReports[index];

      const reportFoundationValue = `${individualReport.negativeLifeEventsIndicator}|${individualReport.needsClosureIndicator};`;
      if (firstFoundationValue !== reportFoundationValue) hasFoundationChanged = true;

      const reportToleranceOfDisequilibuiumValue = `${individualReport.toleranceDisequilibriumIndicator}`;
      if (firstToleranceOfDisequilibriumValue !== reportToleranceOfDisequilibuiumValue) hasToleranceOfDisequilibriumChanged = true;

      const reportWhyWeDoValue = `${individualReport.whyWeDoIndicator}`;
      if (firstWhyWeDoValue !== reportWhyWeDoValue) hasWhyWeDoChanged = true;

      const reportAccessSelfValue = `${individualReport.accessSelfIndicator}|${individualReport.selfAwarenessIndicator}`;
      if (firstAccessSelfValue !== reportAccessSelfValue) hasAccessSelfChanged = true;

      const reportAccessToTheThoughtsValue = `${individualReport.religiousTraditionalimsIndicator}|${individualReport.genderTraditionIndicator}|${individualReport.socioCulturalIndicator}`;
      if (firstAccessToTheThoughtsValue !== reportAccessToTheThoughtsValue) hasAccessToTheThoughtsChanged = true;

      const reportAccessToTheLargerWorldValue = `${individualReport.ecologicalResonanceIndicator}|${individualReport.globalEngagementIndicador}`;
      if (firstAccessToTheLargerWorldValue !== reportAccessToTheLargerWorldValue) hasAccessToTheLargerWorldChanged = true;
    }
    console.log('individual reports', canViewExtraDetails)
    individualReports.forEach((individualReport, index) => {
      let reportTitleH2;
      let reportTitle;
      if (individualReports.length > 1) {
        reportTitle = individualReport.reportTitle;
        reportTitleH2 = `<h2> -> ${individualReport.reportTitle}</h2>`;
        if (hasFoundationChanged) foundations.push(reportTitleH2);
        if (hasToleranceOfDisequilibriumChanged) toleranceOfDisequilibrium.push(reportTitleH2);
        if (hasWhyWeDoChanged) whyWeDo.push(reportTitleH2);
        if (hasAccessSelfChanged) accessSelf.push(reportTitleH2);
        if (hasAccessToTheThoughtsChanged) accessToTheThoughts.push(reportTitleH2);
        if (hasAccessToTheLargerWorldChanged) accessToTheLargerWorld.push(reportTitleH2);
      }

      if (individualReport.hasPermissionToViewProfile && canViewExtraDetails) {
        profiles.push({
          reportTitle: reportTitle,
          value: individualReport.aggregateProfile
        })
      }

      if (individualReport.backgroundStatistic && canViewExtraDetails) {
        backgroundStatistics.push({
          reportTitle: reportTitle,
          value: individualReport.backgroundStatistic
        })
      }

      const demographicsSection = buildDemographicsSection(individualReport);
      if (demographicsSection && canViewExtraDetails) {
        demographicsSections.push({
          reportTitle: reportTitle,
          value: demographicsSection
        })
      }

      const stronglyAgree = buildScaleQuestions(QuestionType.StronglyAgree, individualReport);
      if (stronglyAgree && canViewExtraDetails) {
        scalesStronglyAgree.push({
          reportTitle: reportTitle,
          value: stronglyAgree
        })
      }

      const stronglyDisagree = buildScaleQuestions(QuestionType.StronglyDisagree, individualReport);
      if (stronglyDisagree && canViewExtraDetails) {
        scalesStronglyDisagree.push({
          reportTitle: reportTitle,
          value: stronglyDisagree
        })
      }

      if (individualReport.showExtraData && canViewExtraDetails) {
        const response1 = buildDiscursiveQuestions(DiscusiveQuestionType.Response1, individualReport);
        const response2 = buildDiscursiveQuestions(DiscusiveQuestionType.Response2, individualReport);
        const response3 = buildDiscursiveQuestions(DiscusiveQuestionType.Response3, individualReport);
        const responses: {
          value: string,
          title: string
        }[] = [];
        if (response1) responses.push(response1);
        if (response2) responses.push(response2);
        if (response3) responses.push(response3);

        if (responses.length > 0) {
          discusiveAnswers.push({
            reportTitle: reportTitle,
            value: responses
          })
        }
      }

      if (hasFoundationChanged && index > 0) {
        foundations = [
          ...foundations,
          `<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${formativeCoreTextMessage
            .replace('{NegativeLifeEvents}',
              GetNegativeLifeEvents(individualReport.negativeLifeEventsIndicator))
            .replace('{NeedsClosure}', GetNeedsClosure(individualReport.needsClosureIndicator))}</span>`,
        ];
      } else if (hasFoundationChanged) {
        foundations = [
          ...foundations,
          foundationsTextMessage,
          `<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${formativeCoreTextMessage
            .replace('{NegativeLifeEvents}',
              GetNegativeLifeEvents(individualReport.negativeLifeEventsIndicator))
            .replace('{NeedsClosure}', GetNeedsClosure(individualReport.needsClosureIndicator))}</span>`,
          staticText5Message];
      }

      if (hasToleranceOfDisequilibriumChanged && index === 0) {
        const toleranceArrTexts = GetToleranceOfDisequilibruim(individualReport.toleranceDisequilibriumIndicator);
        toleranceOfDisequilibrium = [
          ...toleranceOfDisequilibrium,
        ];
        toleranceArrTexts.forEach((text) => {
          toleranceOfDisequilibrium.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
        });
      } else if (hasToleranceOfDisequilibriumChanged) {
        // TODO: NEED TO CHANGE IT HERE!
        const toleranceArrTexts = GetToleranceOfDisequilibruim(individualReport.toleranceDisequilibriumIndicator);
        toleranceOfDisequilibrium = [
          ...toleranceOfDisequilibrium,
        ];
        toleranceArrTexts.forEach((text) => {
          toleranceOfDisequilibrium.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
        });
      }

      if (hasWhyWeDoChanged && index === 0) {
        const whyWeDoArrTexts = GetWhyWeDoText(individualReport.whyWeDoIndicator);
        whyWeDo = [...whyWeDo];
        whyWeDoArrTexts.forEach((text) => {
          whyWeDo.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
        });
      } else if (hasWhyWeDoChanged) {
        const whyWeDoArrTexts = GetWhyWeDoText(individualReport.whyWeDoIndicator);
        whyWeDo = [...whyWeDo,];
        whyWeDo.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${whyWeDoArrTexts[0]}</span>`)
      }

      if (hasAccessSelfChanged && index === 0) {
        accessSelf = [...accessSelf];

        const accessSelfArrText = GetAccessSelf(individualReport.accessSelfIndicator, individualReport.selfAwarenessIndicator);
        accessSelfArrText.forEach((text, textIndex) => {
          accessSelf.push(text);
        });
      } else if (hasAccessSelfChanged) {
        accessSelf = [...accessSelf];

        const accessSelfArrText = GetAccessSelf(individualReport.accessSelfIndicator, individualReport.selfAwarenessIndicator);
        accessSelfArrText.forEach((text, textIndex) => {
          individualReports.forEach((comparableReport, comparableReportIndex) => {
            if (comparableReportIndex !== index) {
              const accessSelfArrText = GetAccessSelf(comparableReport.accessSelfIndicator, comparableReport.selfAwarenessIndicator);
              if (accessSelfArrText[textIndex] !== text) {
                accessSelf.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
              }
            }
          })
        });
      }

      if (hasAccessToTheThoughtsChanged && index === 0) {
        accessToTheThoughts = [...accessToTheThoughts];

        const accessToThoughtsAndFeelingsOfOthersText = GetAccessToThoughtsAndFeelingsOfOthers(individualReport.religiousTraditionalimsIndicator);
        accessToTheThoughts.push(accessToThoughtsAndFeelingsOfOthersText);

        const genderTraditionText = GetGenderTradition(individualReport.genderTraditionIndicator);
        accessToTheThoughts.push(genderTraditionText);

        const variablesArrText = GetVariables(individualReport.socioCulturalIndicator);
        variablesArrText.forEach((text, textIndex) => {
          accessToTheThoughts.push(text);
        });
      } else if (hasAccessToTheThoughtsChanged) {
        accessToTheThoughts = [...accessToTheThoughts];

        const accessToThoughtsAndFeelingsOfOthersText = GetAccessToThoughtsAndFeelingsOfOthers(individualReport.religiousTraditionalimsIndicator);
        individualReports.forEach((comparableReport, comparableReportIndex) => {
          if (comparableReportIndex !== index) {
            const comparableAccessToThoughtsAndFeelingsOfOthersText = GetAccessToThoughtsAndFeelingsOfOthers(comparableReport.religiousTraditionalimsIndicator);
            if (comparableAccessToThoughtsAndFeelingsOfOthersText !== accessToThoughtsAndFeelingsOfOthersText) {
              accessToTheThoughts.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${accessToThoughtsAndFeelingsOfOthersText}</span>`)
            }
          }
        })

        const genderTraditionText = GetGenderTradition(individualReport.genderTraditionIndicator);
        individualReports.forEach((comparableReport, comparableReportIndex) => {
          if (comparableReportIndex !== index) {
            const comparableGenderTraditionalText = GetGenderTradition(comparableReport.genderTraditionIndicator);
            if (comparableGenderTraditionalText !== genderTraditionText) {
              accessToTheThoughts.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${genderTraditionText}</span>`)
            }
          }
        });

        const variablesArrText = GetVariables(individualReport.socioCulturalIndicator);
        variablesArrText.forEach((text, textIndex) => {
          individualReports.forEach((comparableReport, comparableReportIndex) => {
            if (comparableReportIndex !== index) {
              const comparableVariablesArrText = GetVariables(comparableReport.socioCulturalIndicator);
              if (comparableVariablesArrText[textIndex] !== text) {
                accessToTheThoughts.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
              }
            }
          });
        });
      }

      if (hasAccessToTheLargerWorldChanged && index === 0) {
        accessToTheLargerWorld = [...accessToTheLargerWorld,
        ...GetAccessLargeWorld(individualReport.ecologicalResonanceIndicator,
          individualReport.globalEngagementIndicador)];

        const accessToTheLargerWorldArrText = GetAccessLargeWorld(individualReport.ecologicalResonanceIndicator, individualReport.globalEngagementIndicador);
        accessToTheLargerWorldArrText.forEach((text, textIndex) => {
          accessToTheLargerWorld.push(text);
        });
      } else if (hasAccessToTheLargerWorldChanged) {
        accessToTheLargerWorld = [...accessToTheLargerWorld];

        const accessToTheLargerWorldArrText = GetAccessLargeWorld(individualReport.ecologicalResonanceIndicator, individualReport.globalEngagementIndicador);
        accessToTheLargerWorldArrText.forEach((text, textIndex) => {
          individualReports.forEach((comparableReport, comparableReportIndex) => {
            if (comparableReportIndex !== index) {
              const comparableAccessToTheLargerWorldArrText = GetVariables(comparableReport.socioCulturalIndicator);
              if (comparableAccessToTheLargerWorldArrText[textIndex] !== text) {
                accessToTheLargerWorld.push(`<span style='background-color: ${index === 0 ? '#fff' : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}'>${text}</span>`)
              }
            }
          });
        });
      }

    })

    if (!hasFoundationChanged) {
      foundations = [
        ...foundations,
        foundationsTextMessage,
        formativeCoreTextMessage
          .replace('{NegativeLifeEvents}',
            GetNegativeLifeEvents(individualReports[0].negativeLifeEventsIndicator))
          .replace('{NeedsClosure}', GetNeedsClosure(individualReports[0].needsClosureIndicator)),
        staticText5Message];
    }

    if (!hasToleranceOfDisequilibriumChanged) {
      toleranceOfDisequilibrium = [...toleranceOfDisequilibrium,
      ...GetToleranceOfDisequilibruim(individualReports[0].toleranceDisequilibriumIndicator)];
    }

    if (!hasWhyWeDoChanged) {
      whyWeDo = [...whyWeDo, ...GetWhyWeDoText(individualReports[0].whyWeDoIndicator)];
    }

    if (!hasAccessSelfChanged) {
      accessSelf = [...accessSelf, ...GetAccessSelf(individualReports[0].accessSelfIndicator, individualReports[0].selfAwarenessIndicator)];
    }

    if (!hasAccessToTheThoughtsChanged) {
      accessToTheThoughts = [...accessToTheThoughts,
      GetAccessToThoughtsAndFeelingsOfOthers(individualReports[0].religiousTraditionalimsIndicator),
      GetGenderTradition(individualReports[0].genderTraditionIndicator),
      ...GetVariables(individualReports[0].socioCulturalIndicator)]
    }

    if (!hasAccessToTheLargerWorldChanged) {
      accessToTheLargerWorld = [...accessToTheLargerWorld,
      ...GetAccessLargeWorld(individualReports[0].ecologicalResonanceIndicator,
        individualReports[0].globalEngagementIndicador)]
    }

    return {
      numberOfReports: individualReports.length,
      foundations,
      toleranceOfDisequilibrium,
      whyWeDo,
      accessSelf,
      accessToTheThoughts,
      accessToTheLargerWorld,
      profiles,
      backgroundStatistics,
      demographicsSections,
      scalesStronglyAgree,
      scalesStronglyDisagree,
      discusiveAnswers,
      introduction: [
        GetFormattedMessage('app.individualreport.static_text_1'),
        GetFormattedMessage('app.individualreport.static_text_2'),
        GetFormattedMessage('app.individualreport.static_text_3'),
        GetFormattedMessage('app.individualreport.static_text_4'),
      ],
      hasAnyModification: (hasFoundationChanged ||
        hasToleranceOfDisequilibriumChanged ||
        hasWhyWeDoChanged ||
        hasAccessSelfChanged ||
        hasAccessToTheThoughtsChanged ||
        hasAccessToTheLargerWorldChanged)
    }
  }, [individualReports])

  return (
    <>
      <ComparativeIndividualReport
        title={{
          reportResultDescription: GetFormattedMessage('app.report.comparative_individual_report_result_description'),
          reportResultTitle: comparativeTitle,
          reportResultDescriptionNoChange: GetFormattedMessage('app.report.comparative_individual_report_result_description_no_change'),
          introduction: GetFormattedMessage('app.individualreport.title.introduction'),
          foundation: GetFormattedMessage('app.individualreport.title.foundation'),
          tolerance_of_desequilibrium: GetFormattedMessage('app.individualreport.title.tolerance_of_desequilibrium'),
          making_sense: GetFormattedMessage('app.individualreport.title.making_sense'),
          access_to_yourself: GetFormattedMessage('app.individualreport.title.access_to_yourself'),
          access_to_the_thoughts: GetFormattedMessage('app.individualreport.title.access_to_the_thoughts'),
          access_to_the_larger_world: GetFormattedMessage('app.individualreport.title.access_to_the_larger_world'),
          conclusion: GetFormattedMessage('app.individualreport.title.conclusion'),
          thankYou: GetFormattedMessage('app.individualreport.title.thankyou'),
          profile: GetFormattedMessage('app.individualreport.title.profile'),
          domain_scores: GetFormattedMessage('app.report.background_statistic_item.domain_scores'),
          scalesTitle: {
            negative_life_events: GetFormattedMessage('app.report.scales.negative_life_events'),
            needs_closure: GetFormattedMessage('app.report.scales.needs_closure'),
            needs_fulfillment: GetFormattedMessage('app.report.scales.needs_fulfillment'),
            identity_diffusion: GetFormattedMessage('app.report.scales.identity_diffusion'),
            basic_openness: GetFormattedMessage('app.report.scales.basic_openness'),
            self_certitude: GetFormattedMessage('app.report.scales.self_certitude'),
            basic_determinism: GetFormattedMessage('app.report.scales.basic_determinism'),
            socioemotional_convergence: GetFormattedMessage('app.report.scales.socioemotional_convergence'),
            physical_resonance: GetFormattedMessage('app.report.scales.physical_resonance'),
            emotional_attunement: GetFormattedMessage('app.report.scales.emotional_attunement'),
            self_awareness: GetFormattedMessage('app.report.scales.self_awareness'),
            meaning_quest: GetFormattedMessage('app.report.scales.meaning_quest'),
            religious_traditionalism: GetFormattedMessage('app.report.scales.religious_traditionalism'),
            gender_traditionalism: GetFormattedMessage('app.report.scales.gender_traditionalism'),
            sociocultural_openness: GetFormattedMessage('app.report.scales.sociocultural_openness'),
            ecological_resonance: GetFormattedMessage('app.report.scales.ecological_resonance'),
            global_resonance: GetFormattedMessage('app.report.scales.global_resonance'),
            consistency: GetFormattedMessage('app.report.scales.consistency'),
            congruency: GetFormattedMessage('app.report.scales.congruency'),
          }
        } as TitleReportProps}
        foundation={relInfo.foundations}
        introduction={relInfo.introduction}
        toleranceOfDisequilibrium={relInfo.toleranceOfDisequilibrium}
        whyWeDo={relInfo.whyWeDo}
        accessSelf={relInfo.accessSelf}
        accessToTheThoughts={relInfo.accessToTheThoughts}
        accessToTheLargerWorld={relInfo.accessToTheLargerWorld}
        profiles={relInfo.profiles}
        backgroundStatistics={relInfo.backgroundStatistics}
        demographicsSections={relInfo.demographicsSections}
        scalesStronglyAgree={relInfo.scalesStronglyAgree}
        scalesStronglyDisagree={relInfo.scalesStronglyDisagree}
        discusiveAnswers={relInfo.discusiveAnswers}
        hasAnyModification={relInfo.hasAnyModification}
        numberOfReports={relInfo.numberOfReports}
        conclusion={GetFormattedMessage('app.individualreport.static_text_11')}
        thankYou={[
          GetFormattedMessage('app.individualreport.thankyou_static_1_1'),
          GetFormattedMessage('app.individualreport.thankyou_static_1_2'),
          GetFormattedMessage('app.individualreport.thankyou_static_2'),
          GetFormattedMessage('app.individualreport.thankyou_static_3'),
        ]}
      />
    </>
  );
}