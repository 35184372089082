import React from 'react';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import cancelled from '../../assets/images/canceled.png';
import completed from '../../assets/images/completed.png';
import expired from '../../assets/images/expired.png';
import { colors } from '../../config/colors';
import { PageMessages } from '../../locales/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';

export const NotAvailableForm = (): JSX.Element => {
    const location = useLocation<{status?: string}>();
    const statusNumber = Number(location?.state?.status);
    return (
        <div style={styles.container}>
            <div style={styles.textContainer}>
                <h1>{GetFormattedMessage('app.form.status_title')}</h1>
                <h3 style={styles.subTitle}>{GetFormattedMessage(GetSubTitle(statusNumber))}</h3>
                <p>{GetFormattedMessage(GetBody(statusNumber))}</p>
            </div>
            <div style={styles.image}>
                <Image src={GetImage(statusNumber)} fluid />
            </div>
        </div>
    );
}

function GetImage(status: number): string {
    if (status === 5 /*Finished*/) return completed;
    if (status === 7 /*Expired*/) return expired;
    if (status === 8 /*Archived*/) return completed;
    return cancelled;
}

function GetSubTitle(status: number): keyof PageMessages {
    if (status === 5) return 'app.form.status_finished_subtitle';
    if (status === 7) return 'app.form.status_expired_subtitle';
    if (status === 8 /*Archived*/) return 'app.form.status_finished_subtitle';
    return 'app.form.status_cancelled_subtitle';
}

function GetBody(status: number): keyof PageMessages {
    if (status === 5) return 'app.form.status_finished_body';
    if (status === 7) return 'app.form.status_expired_body';
    if (status === 8 /*Archived*/) return 'app.form.status_finished_body';
    return 'app.form.status_cancelled_body';
}

const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%'
    } as React.CSSProperties,
    subTitle: {
        textAlign: 'right',
    } as React.CSSProperties,
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
    } as React.CSSProperties,
    text: {
        color: colors.black,
    } as React.CSSProperties,
    image: {
        width: '30%',
        maxHeight: 400,
        maxWidth: 400,
        marginLeft: 20
    } as React.CSSProperties,
}
