import { Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { FaEdit, FaFileAlt, FaKey } from 'react-icons/fa';
import { IntlContext } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { Loading } from '../../components/Loading';
import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { TableFilterWithRadioButton } from '../../components/TableFilterWithRadioButton';
import { colors } from '../../config/colors';
import { paginationDefaultPageSize } from '../../config/constants';
import { PageMessages } from '../../locales/types';
import { TokenInfo } from '../../services/loginService';
import { SearchUsersDetails, UserPermissions, UserType } from '../../services/usersService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { HasPermissionCapabilityProgramAdmin, IsAccountManager, IsInstitutional, IsProgramAdmin, IsSuperAdmin } from '../../utils/userHelper';

const getStatusValue = (isActive: boolean): keyof (PageMessages) => {
    if (isActive) return 'app.users.status.active';
    return 'app.users.status.inactive';
}

type Props = {
    data: SearchUsersDetails[];
    loading: boolean;
    userInfo: TokenInfo;
    onEditUserClicked: (userId: number) => void;
    onUserProgramClicked: (userId: number) => void;
    onResetClick: (user: any) => void;
};

export const ListUsers = ({ data, loading, userInfo, onEditUserClicked, onUserProgramClicked, onResetClick }: Props): JSX.Element => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const { messages } = React.useContext(IntlContext);

    const userTypes = [{
        label: GetFormattedMessage('app.users_type.all'),
        value: 0
    }, {
        label: GetFormattedMessage('app.users_type.participant'),
        value: UserType.participant
    }, {
        label: GetFormattedMessage('app.users_type.program_admin'),
        value: UserType.programAdmin
    }, {
        label: GetFormattedMessage('app.users_type.institutional'),
        value: UserType.institutional
    }, {
        label: GetFormattedMessage('app.users_type.account_manager'),
        value: UserType.accountManager
    }, {
        label: GetFormattedMessage('app.users_type.super_admin'),
        value: UserType.superAdmin
    }, {
        label: GetFormattedMessage('app.users_type.external_partner'),
        value: UserType.externalPartner
    }];

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <Table
                    dataSource={data}
                    rowKey={(record: SearchUsersDetails) => record.id.toString()}
                    locale={{ emptyText: messages['app.empty.data'].toString() }}
                    pagination={data.length > paginationDefaultPageSize ? defaultPagination : false}
                >
                    <Column
                        title={messages['app.users.name'].toString()}
                        dataIndex='name'
                        key='name'
                        width='25%'
                        sorter={(a: SearchUsersDetails, b: SearchUsersDetails) => a.name.localeCompare(b.name)}
                        {...filterOptions}
                        onFilter={(value, record: SearchUsersDetails) =>
                            record.name.toLowerCase().includes(value.toString().toLowerCase())}
                    />
                    <Column
                        title={messages['app.users.login'].toString()}
                        dataIndex='login'
                        key='login'
                        width='25%'
                        sorter={(a: SearchUsersDetails, b: SearchUsersDetails) => a.login.localeCompare(b.login)}
                        {...filterOptions}
                        onFilter={(value, record: SearchUsersDetails) =>
                            record.login.toLowerCase().includes(value.toString().toLowerCase())}
                    />
                    <Column
                        title={messages['app.create.user.user.type'].toString()}
                        dataIndex='userType'
                        key='userType'
                        width='10%'
                        render={userType => {
                            const type = userTypes.find(x => x.value === userType)?.label;
                            return type;
                        }}
                        sorter={(a: SearchUsersDetails, b: SearchUsersDetails) => a.userType - b.userType}
                        filters={userTypes.filter(x => x.value !== 0).map(x => ({ text: x.label, value: x.value }))}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <TableFilterWithRadioButton
                                {...props}
                                confirmButtonText={searchConfirmButtonText}
                                resetButtonText={searchResetButtonText}
                            />
                        )}
                        onFilter={(value, record: SearchUsersDetails) => {
                            return record.userType === value;
                        }}
                    />
                    <Column
                        title={messages['app.users.status'].toString()}
                        dataIndex='isActive'
                        key='isActive'
                        width='15%'
                        render={isActive => (
                            <Tag color={isActive ? "green" : "red"} key={isActive}>
                                {messages[getStatusValue(isActive)].toString()}
                            </Tag>
                        )}
                        filters={[
                            { text: messages[getStatusValue(true)].toString(), value: 'Active' },
                            { text: messages[getStatusValue(false)].toString(), value: 'Inactive' },
                        ]}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <TableFilterWithRadioButton
                                {...props}
                                confirmButtonText={searchConfirmButtonText}
                                resetButtonText={searchResetButtonText}
                            />
                        )}
                        onFilter={(value, record: SearchUsersDetails) => {
                            return value === 'Active' ? record.isActive : !record.isActive
                        }}
                    />
                    <Column
                        title={messages['app.users.listuser.action'].toString()}
                        key='action'
                        width='25%'
                        render={(_, record: SearchUsersDetails) => (
                            <Row>
                                {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canResetUserPassword) &&
                                    (IsSuperAdmin(userInfo) || IsAccountManager(userInfo) || IsInstitutional(userInfo) || IsProgramAdmin(userInfo)) && (
                                        <div style={styles.marginRight8}>
                                            <ButtonWithIcon
                                                icon={<FaKey />}
                                                onClick={() => onResetClick(record)}
                                                textId={'app.users.listuser.reset_password'}
                                            />
                                        </div>
                                    )}
                                {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canManageUser) && <div style={styles.marginRight8}>
                                    <ButtonWithIcon
                                        icon={<FaEdit />}
                                        onClick={() => onEditUserClicked(record.id)}
                                        textId={'app.users.listuser.details'}
                                    />
                                </div>}
                                {(HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canVisualizeUserPrograms) && (record.userType === UserType.participant)) &&
                                    <ButtonWithIcon
                                        icon={<FaFileAlt />}
                                        onClick={() => onUserProgramClicked(record.id)}
                                        textId={'app.users.listuser.programs_action'}
                                    />
                                }
                            </Row>
                        )}
                    />
                </Table>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    marginRight8: {
        marginRight: '8px',
    } as React.CSSProperties,
};
