import React from 'react';
import { AggregateProfileByDemographics } from './AggregateProfileByDemographics';
import { GetCountryByCode } from '../../../utils/demographHelper';
import { GroupReportAggregateByDemographics_CountryOfOrigin } from '../../../services/types';
import { ScaleGroup } from '../../../services/reportService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { InsufficientDataComponent } from './InsufficientDataComponent';
import { getScaleTitle } from '../../../utils/reportHelper';

type Props = {
    selectedCountries: number[];
    reportCountryOfOrigin: GroupReportAggregateByDemographics_CountryOfOrigin[];
    subProgramName?: string[];
}

type ScaleProps = {
    title: string;
    value: number;
    hasSpace: boolean;
}

const hasEnoughData = (reportCountryOfOrigin: GroupReportAggregateByDemographics_CountryOfOrigin[]) => {
    let invalidReportCount = 0;
    for (let i = 0; i < reportCountryOfOrigin.length; i++) {
        if (!reportCountryOfOrigin[i].hasEnoughData) {
            invalidReportCount += 1;
        }
    }

    if (reportCountryOfOrigin.length === 1) {
        if (invalidReportCount === 0) return true;
        return false;
    }
    else {
        return reportCountryOfOrigin.length - invalidReportCount > 1;
    }
}

const buildSubTitle = (reportCountryOfOrigin: GroupReportAggregateByDemographics_CountryOfOrigin[],
    subProgramName?: string[]) => {

    if (reportCountryOfOrigin.length === 1) return undefined;
    const subTitle: string[][] = [];
    for (let i = 0; i < reportCountryOfOrigin.length; i++) {
        if (!reportCountryOfOrigin[i].hasEnoughData) continue;

        subTitle.push(
            [
                `${subProgramName && subProgramName[i]} - (N = ${reportCountryOfOrigin[i].selectedCountryNumberOfUsers})`,
                `${subProgramName && subProgramName[i]} - (N = ${reportCountryOfOrigin[i].otherNumberOfUsers})`,
            ]
        )

    }
    return subTitle;
}

const buildScales = (selectedCountries: number[], reportCountryOfOrigin: GroupReportAggregateByDemographics_CountryOfOrigin[],
    scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {
    const scalePropos: ScaleProps[] = [];
    const scaleTitle = getScaleTitle(scale);

    const selectedCountryText = selectedCountries.length > 1 ?
        GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_countries')
        :
        GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_country');
    const other = GetFormattedMessage('app.report.aggregate_profile.country_of_origin.other');
    for (let i = 0; i < reportCountryOfOrigin.length; i++) {

        if (!reportCountryOfOrigin[i].hasEnoughData) continue;

        const countryOfOrigin = reportCountryOfOrigin[i];
        const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

        scalePropos.push({
            //@ts-ignore
            title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${selectedCountryText})`,
            value: countryOfOrigin.scales[scale][0],
            hasSpace: i === 0 && name !== ''
        });
    }

    for (let i = 0; i < reportCountryOfOrigin.length; i++) {

        if (!reportCountryOfOrigin[i].hasEnoughData) continue;

        const countryOfOrigin = reportCountryOfOrigin[i];
        const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

        scalePropos.push({
            //@ts-ignore
            title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${other})`,
            value: countryOfOrigin.scales[scale][1],
            hasSpace: i === 0 && name !== ''
        });
    }

    return scalePropos;
}

export const AggregateProfileByDemographics_CountryOfOrigin = ({ selectedCountries, reportCountryOfOrigin, subProgramName }: Props) => {

    if (!hasEnoughData(reportCountryOfOrigin)) {
        return <InsufficientDataComponent />
    }

    const selectedCountryText = selectedCountries.length > 1 ?
        GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_countries')
        :
        GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_country');

    const other = GetFormattedMessage('app.report.aggregate_profile.country_of_origin.other');
    return (
        <AggregateProfileByDemographics
            title={[
                `${selectedCountryText}: ${selectedCountries.map((item) => GetCountryByCode(item)).join(', ')} (N = ${reportCountryOfOrigin.map(item => item.selectedCountryNumberOfUsers).reduce((prev, next) => prev + next)})`,
                `${other} (N = ${reportCountryOfOrigin.map(item => item.otherNumberOfUsers).reduce((prev, next) => prev + next)})`,
            ]}
            subTitle={buildSubTitle(reportCountryOfOrigin, subProgramName)}
            negativeLifeEvents={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.NegativeLifeEvents, 1, subProgramName)}
            needsClosure={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.NeedsClosure, 2, subProgramName)}
            needsFulfillment={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.NeedsFulfillment, 3, subProgramName)}
            identityDiffusion={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.IdentityDiffusion, 4, subProgramName)}
            basicOpenness={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.BasicOpenness, 5, subProgramName)}
            selfCertitude={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.SelfCertitude, 6, subProgramName)}
            basicDeterminism={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.BasicDeterminism, 7, subProgramName)}
            socioemotionalConvergence={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.SocioemotionalConvergence, 8, subProgramName)}
            physicalResonance={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.PhysicalResonance, 9, subProgramName)}
            emotionalAttunument={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.EmotionalAttunement, 10, subProgramName)}
            selfAwereness={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.SelfAwereness, 11, subProgramName)}
            meaningQuest={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.MeaningQuest, 12, subProgramName)}
            religiousTraditionalism={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.ReligiousTraditionalism, 13, subProgramName)}
            genderTraditionalism={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.GenderTraditionalism, 14, subProgramName)}
            socioCulturalOpenness={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.SocioculturalOpenness, 15, subProgramName)}
            ecologicalResonance={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.EcologicalResonance, 16, subProgramName)}
            globalResonance={buildScales(selectedCountries, reportCountryOfOrigin, ScaleGroup.GlobalResonance, 17, subProgramName)}
        />
    )
}