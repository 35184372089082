import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import { BaseResponse } from '../rpc/api';
import {
    DataDownloadParams,
    downloadDataReport,
    downloadIndividualReport,
    DownloadReportResponse,
    GetIndividualReportResponse,
    loadIndividualReport,
    ReportLanguage,
    sendIndividualReportAgreement,
    signCustomConsent
} from '../services/reportService';
import { IndividualReport } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateExternalToken, ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { formLogic, formsPropsConstants } from './formsLogic';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';


// actions
const actionsLiterals = asLiterals([
    'getIndividualReport', 'getDataDownload', 'setIndividualReportAgreement',
    'setCustomAgreementConsentResponse', 'downloadIndividualReport'
]);
export type IndividualReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const individualReportActionsConstants: IndividualReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReportData', 'setDataDownloadError', 'setSuccessfulDownload',
    'setIndividualReportAgreementSuccess', 'setCustomAgreementConsentResponseSuccess'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading', 'error', 'individualReport', 'dataDownloadError', 'loadingDataDownload',
]);
export type IndividualReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const individualReportPropsConstants: IndividualReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: IndividualReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        fetchLoadReport: () => void;
        fetchLoadDataDownload: () => void;
        sendIndividualReportAgreement: () => void;
        sendCustomAgreementConsent: () => void;
        fetchDownloadIndividualReport: () => void;
    }
};

// Define payloads type.
type ErrorPayload = { error: string; };
type ReportPayload = { data: IndividualReport };

type SignCustomConsentPayload = { accepted: boolean };
type SignCustomConsentPayloadRequest = { payload: SignCustomConsentPayload };
type DataDownloadRequestPayload = { data: DataDownloadParams };
type DataDownloadRequestPayloadRequest = { payload: DataDownloadRequestPayload };
type DownloadInduvidualReportRequestPayload = { language: ReportLanguage };
type DownloadInduvidualReportRequestPayloadRequest = { payload: DownloadInduvidualReportRequestPayload };

const IndividualReportPropType = PropTypes.shape({
    userName: PropTypes.string,
    formStartDateOnUtc: PropTypes.string,
    personalInterested: PropTypes.number,
    negativeLifeEventsIndicator: PropTypes.number,
    needsClosureIndicator: PropTypes.number,
    toleranceDisequilibriumIndicator: PropTypes.number,
    whyWeDoIndicator: PropTypes.number,
    accessSelfIndicator: PropTypes.number,
    selfAwarenessIndicator: PropTypes.number,
    religiousTraditionalimsIndicator: PropTypes.number,
    genderTraditionIndicator: PropTypes.number,
    socioCulturalIndicator: PropTypes.number,
    ecologicalResonanceIndicator: PropTypes.number,
    globalEngagementIndicador: PropTypes.number,
});

type SendIndividualReportAgreementRaceResponse = {
    sendIndividualReportAgreementResponse?: BaseResponse<{}>;
}

type SignCustomConsentRaceResponse = {
    signCustomConsentResponse?: BaseResponse<{}>;
}

type GetIndividualReportRaceResponse = {
    getIndividualReportResponse?: GetIndividualReportResponse;
}

type DownloadIndividualReportRaceResponse = {
    downloadIndividualReportResponse?: DownloadReportResponse;
}

// persist app reducer
export const individualReportPath = 'individualReport';

export const individualReportReducer = keaReducer(individualReportPath);

export const individualReportLogic = kea({
    connect: {
        props: [
            appLogic, [appPropsConstants.token],
            formLogic, [formsPropsConstants.formId]
        ],
    },

    path: () => [individualReportPath],

    actions: () => ({
        [individualReportActionsConstants.getIndividualReport]: () => true,
        [individualReportActionsConstants.setIndividualReportAgreement]: () => true,
        [individualReportActionsConstants.getDataDownload]:
            (data: DataDownloadParams): DataDownloadRequestPayload => ({ data }),
        [individualReportActionsConstants.downloadIndividualReport]:
            (language: ReportLanguage): DownloadInduvidualReportRequestPayload => ({ language }),
        [individualReportActionsConstants.setCustomAgreementConsentResponse]:
            (accepted: boolean): SignCustomConsentPayload => ({ accepted }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.setReportData]:
            (data: IndividualReport): ReportPayload => ({ data }),
        [privateActionsConstants.setDataDownloadError]:
            (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.setSuccessfulDownload]: () => true,
        [privateActionsConstants.setIndividualReportAgreementSuccess]: () => true,
        [privateActionsConstants.setCustomAgreementConsentResponseSuccess]: () => true,
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [individualReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.setIndividualReportAgreement]: () => true,
            [actions.setIndividualReportAgreementSuccess]: () => false,
            [actions.setCustomAgreementConsentResponse]: () => true,
            [actions.setCustomAgreementConsentResponseSuccess]: () => false,
            [actions.setReportData]: () => false,
            [actions.setError]: () => false,
            [logout]: () => true
        }],
        [individualReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.setReportData]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [individualReportPropsConstants.individualReport]: [null, PropTypes.objectOf(IndividualReportPropType), {
            [actions.setReportData]: (_: IndividualReport, payload: ReportPayload) => payload.data,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [individualReportPropsConstants.dataDownloadError]: [null, PropTypes.string, {
            [actions.downloadIndividualReport]: () => null,
            [actions.setSuccessfulDownload]: () => null,
            [actions.setDataDownloadError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [individualReportPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.downloadIndividualReport]: () => true,
            [actions.getDataDownload]: () => true,
            [actions.setSuccessfulDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [logout]: () => true
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.getIndividualReport]: workers.fetchLoadReport,
        [actions.getDataDownload]: workers.fetchLoadDataDownload,
        [actions.setIndividualReportAgreement]: workers.sendIndividualReportAgreement,
        [actions.setCustomAgreementConsentResponse]: workers.sendCustomAgreementConsent,
        [actions.downloadIndividualReport]: workers.fetchDownloadIndividualReport,
    }),

    workers: {
        * fetchLoadReport(): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const formId = yield this.get(formsPropsConstants.formId);
            //@ts-ignore
            const { setError, setReportData } = this.actionCreators;
            const { getIndividualReportResponse }: GetIndividualReportRaceResponse = yield race({
                getIndividualReportResponse: call(loadIndividualReport, token, formId),
                timeout: delay(requestTimeout)
            });

            if (getIndividualReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (getIndividualReportResponse?.status === successfulCode) {
                yield put(setReportData(getIndividualReportResponse.body));
            } else if (!!getIndividualReportResponse?.keyErrorMessage) {
                yield put(setError(getIndividualReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchLoadDataDownload(action: DataDownloadRequestPayloadRequest): any {

            //@ts-ignore
            const { setSuccessfulDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { data } = action.payload;
            const result = yield call(downloadDataReport, token, data);
            console.log('getDataDownloadReportResponse', result);

            if (result.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (result.status === successfulCode) {
                yield put(setSuccessfulDownload());
                // } 
                // else if (!!getDataDownloadReportResponse?.keyErrorMessage) {
                //     yield put(setDataDownloadError(getDataDownloadReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }

        },

        * sendIndividualReportAgreement(): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const formId = yield this.get(formsPropsConstants.formId);
            //@ts-ignore
            const { setError, setIndividualReportAgreementSuccess } = this.actionCreators;
            const { sendIndividualReportAgreementResponse }: SendIndividualReportAgreementRaceResponse = yield race({
                sendIndividualReportAgreementResponse: call(sendIndividualReportAgreement, token, formId),
                timeout: delay(requestTimeout)
            });

            if (sendIndividualReportAgreementResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (sendIndividualReportAgreementResponse?.status === successfulCode) {
                yield put(setIndividualReportAgreementSuccess());
            } else if (!!sendIndividualReportAgreementResponse?.keyErrorMessage) {
                yield put(setError(sendIndividualReportAgreementResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * sendCustomAgreementConsent(action: SignCustomConsentPayloadRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const formId = yield this.get(formsPropsConstants.formId);
            //@ts-ignore
            const { setError, setCustomAgreementConsentResponseSuccess } = this.actionCreators;
            const { accepted } = action.payload;

            const { signCustomConsentResponse }: SignCustomConsentRaceResponse = yield race({
                signCustomConsentResponse: call(signCustomConsent, token, formId, accepted),
                timeout: delay(requestTimeout)
            });

            if (signCustomConsentResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (signCustomConsentResponse?.status === successfulCode) {
                yield put(setCustomAgreementConsentResponseSuccess());
            } else if (!!signCustomConsentResponse?.keyErrorMessage) {
                yield put(setError(signCustomConsentResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchDownloadIndividualReport(action: DownloadInduvidualReportRequestPayloadRequest): any {

            //@ts-ignore
            const { setDataDownloadError, setSuccessfulDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const formId = yield this.get(formsPropsConstants.formId);
            const { language } = action.payload;
            const { downloadIndividualReportResponse }: DownloadIndividualReportRaceResponse = yield race({
                downloadIndividualReportResponse: call(
                    downloadIndividualReport,
                    token,
                    { formId, language }
                ),
                timeout: delay(requestTimeout)
            });

            if (downloadIndividualReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (downloadIndividualReportResponse?.status === successfulCode) {
                yield put(setSuccessfulDownload());
            } else if (!!downloadIndividualReportResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(downloadIndividualReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
});
