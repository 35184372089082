import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import { colors } from '../../config/colors';
import { FormDetails } from '../../services/listFormsService';

type Props = {
    formListDetails?: FormDetails;
    percentageCompleted: number;
};

export const QuestionPageHeader = ({ formListDetails, percentageCompleted }: Props): JSX.Element | null => {
    if (!formListDetails) return null;

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <h2 style={styles.h2ProgramTitle}>
                    {formListDetails.programName} - {formListDetails.subProgramName}
                </h2>
                <div style={styles.divProgressBarAndLabels}>
                    <ProgressBar now={percentageCompleted * 100} />
                    <div style={styles.divProgressLabels}>
                        <label style={styles.textLabel}>{'0%  '}</label>
                        <label style={styles.textLabel}>{'25% '}</label>
                        <label style={styles.textLabel}>{'50% '}</label>
                        <label style={styles.textLabel}>{'75% '}</label>
                        <label style={styles.textLabel}>{'100%'}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '80%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    divProgressBarAndLabels: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
    divProgressLabels: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    } as React.CSSProperties,
    textLabel: {
        fontSize: '10px',
    } as React.CSSProperties,
};
