import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import { colors } from '../../../config/colors';
import { RenameProgramPayloadRequest } from '../../../services/programService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
  showModal: boolean;
  program: any;
  loadingButton: boolean;
  success: boolean;
  error: boolean;
  onModalHide: () => void;
  onRenameProgramHandleClick(program: RenameProgramPayloadRequest): void;
  onSuccessfullRenameProgramModalHide: () => void;
}

export const RenameProgramModal = (
  { loadingButton, showModal, program, success, error, onModalHide, onRenameProgramHandleClick, onSuccessfullRenameProgramModalHide }: Props
): JSX.Element => {

  useEffect(() => {
    if (program && program.programId && program.programId > 0 && program.name) {
      setName(program.name)
    } else {
      setName('')
    }
  }, [program])

  useEffect(() => {
    if(success) {
      setName('')
      onSuccessfullRenameProgramModalHide()
    }
    // eslint-disable-next-line
  }, [success])

  useEffect(() => {
    if(error) {
      setAlert(error)
    }
  }, [error])

  const [name, setName] = useState<string>('');
  const [alert, setAlert] = useState<boolean>(false);

  const onSubmitButtonClicked = () => {
    let data: RenameProgramPayloadRequest = { programId: 0, name: '' };
    if (program && program.programId && program.programId > 0) {
      data.programId = program.programId
      
    }
    if(name !== '') {
      data.name = name;
      onRenameProgramHandleClick(data);
    }
  }

  return (
    <Modal show={showModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          { (program && program.programId && program.programId > 0) ? GetFormattedMessage('app.programs.rename').toUpperCase() : GetFormattedMessage('app.programs.add.title').toUpperCase() }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {alert === true &&
            <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
              <p dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.programs.add.error_message') }} />
            </Alert>
          }
          <Form.Group className='mb-3' as={Col}>
            <Form.Group className='mb-3' as={Row} controlId="formField2">
              <Form.Label><FormattedMessage id='app.programs.add.new_name' /></Form.Label>
              <Form.Control
                className="form-control mb-3"
                type='text'
                placeholder={GetFormattedMessage('app.programs.add.new_name')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>
          </Form.Group>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: colors.darkSlateBlue }}
          variant='primary'
          onClick={onSubmitButtonClicked}
          disabled={loadingButton || name === ''}
        >
          {loadingButton &&
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: 10 }}
            />
          }
          {GetFormattedMessage('app.programs.add.button_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};