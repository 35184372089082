import { CreateProgramMessages } from '../types';

export const createProgramJA: CreateProgramMessages = {
    'app.create_program.btn_submit': '保存',
    'app.create_program.btn_submit_and_send_email': '保存/送信メール',
    'app.create_program.btn_unselect_all_users': '全選択解除',
    'app.create_program.select_individual_manually': '受検者を個人ごとに登録',
    'app.create_program.table.column_name': 'Name',
    'app.create_program.table.column_email': 'Email',
    'app.create_program.table.column_actions': '除外',
    'app.create_program.table.column_get_last_users': '前の管理からの個人。',
    'app.create_program.tooltip.action_remove': '削除',
    'app.create_program.tooltip.btn_get_last_users': '前回受験者を再指定',
    'app.create_program.tooltip.btn_download_users': '大学/機関の受験者のダウンロード/管理',
    'app.create_program.tooltip.btn_download_users_empty': 'Download / manage who will take the BEVI(Empty Template).',
    'app.create_program.excel.name_column': 'Name',
    'app.create_program.excel.login_column': 'Email',
    'app.create_program.excel.active_column': 'Active',
    'app.create_program.confirmation_title_success': '作成',
    'app.create_program.confirmation_body_success_program': 'プログラムが作成されました。OKをクリックして確認・管理することができます。',
    'app.create_program.confirmation_body_success_subprogram': 'サブグループが作成されました。OKをクリックして確認・管理することができます。',
    'app.create_program.confirmation_title_fail': '作成に失敗',
    'app.create_program.confirmation_body_fail_program': 'プログラムを作成できませんでした。入力データ、インターネット接続を確認し、再度試してみてください。問題が解決しない場合は、アカウント･マネージャーに相談してください。',
    'app.create_program.confirmation_body_fail_subprogram': 'サブグループを更新できませんでした。入力データ、インターネット接続を確認し、再度試してみてください。問題が解決しない場合は、アカウントマネージャーに相談してください。',
    'app.edit_sub_program.confirmation_title_success': '更新',
    'app.edit_sub_program.confirmation_body_success': 'サブグループが更新され、レポート作成ができるようになりました。',
    'app.edit_sub_program.confirmation_title_fail': '更新に失敗',
    'app.edit_sub_program.confirmation_body_fail': 'サブグループが更新できませんでした。入力データ、インターネット接続を確認し、再度試してみてください。問題が解決しない場合は、アカウント･マネージャーに相談してください。',
    'app.create_program.lbl_associate_users': '受検者を個人ごとに登録',
    'app.create_program.lbl_organization_name': '大学/機関名',
    'app.create_program.lbl_program_name': 'プログラム名',
    'app.create_program.lbl_sub_program_name': 'サブグループ名',
    'app.create_program.lbl_year': 'Year',
    'app.create_program.lbl_due_date': '終了期限',
    'app.create_program.lbl_alert_all_users': '全受検者にメール送信',
    'app.details_sub_program.progress': 'サブグループの進捗状況',
    'app.details_sub_program.participant_details': '受検者の詳細情報',
    'app.details_sub_program.remind_alert': 'Remind Alert',
    'app.details_sub_program.remind_alert_text': 'A reminder alert was scheduled and it will be sent in a few minutes.',
    'app.create_program.lbl_email_message': '送信するメッセージを以下のボックスに記入してください。',
    'app.create_program.tooltip.btn_upload_users': 'BEVIの受検者リストをアップロード',
    'app.create_program.upload_files_error_title': 'アップロード･エラー',
    'app.create_program.upload_rejected_files_error_body_prefix': '以下のファイルはフォーマットにエラーがあるためアップロードできませんでした。',
    'app.create_program.upload_parsed_files_error_body_prefix': 'ファイルは{0}, {1} and {2}の欄が必要です。以下のファイルを読み込むことができませんでした。',
    'app.create_program.upload_parsed_files_error_body': '{0} は {1} 個のエラーがあります。',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': '以下のファイルを分析中エラーが発生しました:',
    'app.create_program.select_individuals_title': '以下のいずれかの方法でBEVI受検者を指定してください。',
    'app.create_program.import_individuals_from_previous_administration': '1. 前回プログラムBEVI受験者を再指定',
    'app.create_program.download_manage_who_will_take_the_bevi': '2. 大学/機関の全BEVI受検者リストをダウンロード',
    'app.create_program.upload_who_will_take_the_bevi': '。本プログラム受検者をアップロード',
    'app.create_program.add_individuals_manually': '3. 以下のドロップダウン･リストから、受検者を個人ごとに手動で指定',
}
