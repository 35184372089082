import { ListOrganizationsMessages } from '../types';

export const listOrganizationsZHCN: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Created on',
    'app.listorganizations.active': 'Active',
    'app.listorganizations.inactive': 'Inactive',
    'app.listorganizations.new.program': 'New Program or Experience',
    'app.listorganizations.new.group': 'Create and name a new BEVI administration.',
    'app.listorganizations.view': 'View',
    'app.listorganizations.report': 'Report',
    'app.listorganizations.details': 'Review and manage the BEVI administration.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Invite new users to join on this group by QR Code.',
    'app.listorganizations.tooltip.sub_program_edit': 'Edit BEVI administration parameters.',
    'app.listorganizations.table.beviname': 'BEVI Administration Name',
    'app.listorganizations.table.created_on': 'Created On',
    'app.listorganizations.table.due_date': 'Due Date',
    'app.listorganizations.table.completed_percentage': 'Completed %',
    'app.listorganizations.table.status': 'Status',
    'app.listorganizations.table.actions': 'Actions',
    'app.listorganizations.select_organization': 'Select an organization.',
    'app.listorganizations.search': 'Search',
    'app.listorganizations.new_organization': 'New Organization',
    'app.listorganizations.input_program_placeholder': 'Type the name of the program or experience.',
    'app.listorganizations.input_subprogram_placeholder': 'Type the name of the BEVI administration.',
    'app.listorganizations.organization_area': 'Management of Programs and Experiences',
    'app.listorganizations.download.data_report': 'Download data as an Excel file.',
    "app.listorganizations.sort_name_date": "Sort by name or date",
    "app.listorganizations.sort_by": "Sort by",
    'app.sample.data.footer.message_line1': '<b><i>笔记。</i> BEVI 报告系统允许开发八种不同的报告类型：1) 个人报告，2) 个人变更报告，3) 合作伙伴报告，4) 合作伙伴变更报告，5) 团体报告，6) 团体变更报告，7) 内部 小组报告，以及 8) 小组间报告。 为了让您了解这些报告的工作原理以及可以访问的信息类型，提供了每种报告类型的两个示例（A 和 B）。</b>',
    'app.sample.data.footer.message_line2': '<b>尽管示例报告与 BEVI 系统中的实际报告一致，但提供这些示例仅用于说明目的。 因此，这些样本报告中的一些信息可能已被修改（例如，量表分数）或删除（例如，定性反应），以确保结果不被识别和/或与特定个人脱钩。</b>',
    'app.sample.data.footer.message_line3': '<b>如果您对这些样本报告或 BEVI 的任何其他方面有任何疑问，请联系为您授权 BEVI 管理访问权限的个人，或联系 BEVI 团队：<a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
