import { UsersMessages } from '../types';

const users: UsersMessages = {
    'app.users.listuser.title': 'Manage Users',
    'app.users.name': 'User Name',
    'app.users.login': 'E-mail',
    'app.users.status.inactive': 'Inactive',
    'app.users.status.active': 'Active',
    'app.users.status': 'Status',
    'app.create_batch_user.excel.email_column': 'Email',
    'app.create_batch_user.excel.line_number_column': 'Line number',
    'app.create_batch_user.excel.user_error_column': 'Error',
    'app.create_batch_user.excel.download_error_details': 'Download details',
    'app.create_batch_user.error_already_exists': 'User already exists',
    'app.create_batch_user.error_invalid_line': 'Invalid line',
    'app.create_batch_user.error_wrong_email': 'User with wrong email',
    'app.create_batch_user.error_missing_name': 'Missing user name',
    'app.create_batch_user.error_details_filename': 'Error details',
    'app.user.organization': 'Organization',
    'app.user.select_organization': 'Select an organization.',
    'app.user.select_program': 'Select a program or experience',
    'app.user.program': 'Program',
    'app.users.listuser.action': 'Action',
    'app.users.listuser.reset_password': 'Reset Password',
    'app.users.listuser.details': 'User Management',
    'app.users.listuser.programs_action': 'Programs and Experiences',
    'app.users.add_new_user': 'New User',
    'app.user_programs.programName': 'Name of Program or Experience',
    'app.user_programs.subprogramName': 'Name of BEVI Administration',
    'app.user_programs.status': 'Status',
    'app.user_programs.completedOn': 'Completed on',
    'app.user_programs.invalid_reason': 'Invalid reason',
    'app.listorganizations.input_username_placeholder': 'Type a User Name',
    'app.listorganizations.input_useremail_placeholder': 'Type a User E-mail',
    'app.create.batch_user_error': 'One or more errors were encountered when trying to create multiple users for the organization. For more details download the error information file.',
    'app.create.user.title': 'Create a New User',
    'app.create.user.single.user': 'Provide a single email address for someone who is authorized to complete and/or administer the BEVI.',
    'app.create.user.send_welcome_email': 'Send welcome email',
    'app.create.user.multiple.users': 'Provide email addresses for individuals who may be authorized to complete the BEVI.',
    'app.create.user.choose.option.new.user': 'Select the correct option from the two choices below.',
    'app.create.user.language_codes': 'Language codes',
    'app.create.user.download.template.file': 'Download the template file.',
    'app.create.user.download.data.example': 'Download sample file',
    'app.create.user.upload.file': 'Upload',
    'app.create.user.drag.n.drop.text': 'Drag \'n\' drop some files here, or click to select files',
    'app.create.user.dran.n.drop.available.files': '(Only *.xlsx and *.xls files will be accepted)',
    'app.create.user.remove': 'Remove',
    'app.create.user.selected.file': 'Selected: ',
    'app.create.user.create.multiple.user.title': 'Provide email addresses for individuals who may be authorized to complete the BEVI.',
    'app.create.user.create.single.user.title': 'Create a User',
    'app.create.user.table.column_actions': 'Remove Organization',
    'app.create.user.edit.single.user.title': 'User Management',
    'app.create.user.is.anonymous': 'Is Anonymous',
    'app.create.user.user.type': 'User Type',
    'app.create.user.language': 'E-mail Language',
    "app.create.user.capability_visualize_user": "查看用户",
    "app.create.user.capability_add_user": "添加用户",
    "app.create.user.capability_reset_password_user": "重置密码 - 用户",
    "app.create.user.capability_manage_user": "管理用户",
    "app.create.user.capability_visualize_user_programs": "查看用户程序",
    "app.create.user.capability_visualize_program_and_experience": "查看程序和体验",
    "app.create.user.capability_add_program_and_experience": "添加程序和体验",
    "app.create.user.capability_rename_program_and_experience": "重命名程序和体验",
    "app.create.user.capability_add_subprogram": "添加子程序",
    "app.create.user.capability_edit_subprogram": "编辑子程序",
    "app.create.user.capability_manage_subprogram": "管理子程序",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "显示邀请新用户的二维码 - 子程序",
    "app.create.user.capability_visualize_report": "查看报告",
    "app.create.user.capability_add_report": "添加报告",
    'app.user.profile.participant': 'Participant',
    'app.user.profile.programAdmin': 'Program Admin',
    'app.user.profile.institutional': 'Institutional Admin',
    'app.user.profile.accountManager': 'Account Manager',
    'app.user.profile.superAdmin': 'Super Admin',
    'app.user.language.message': '(The language that the user will receive email)',
    'app.user.successfulMessage': 'User was successful created',
    'app.edit.user.confirmation_title_fail': 'Oh no! Unfortunately, we couldn’t update it',
    'app.edit.user.confirmation_body_fail': 'The user couldn’t be updated. Please, review the data provided, the internet connection and try again. If the problem persists, talk to the account manager.',
    'app.edit.user.confirmation_body_fail_already_exists': 'The user couldn’t be updated because another user already has the email provided.',
    'app.edit.user.confirmation_body_fail_wrong_email': 'The user couldn’t be updated because the provided email is invalid.',
    'app.edit.user.confirmation_title_success': 'Successfully updated',
    'app.edit.user.confirmation_body_success': 'The user was updated and you can now visualize it in the user listing, where you are being redirected.',
    'app.create.user.confirmation_title_fail': 'Oh no! Unfortunately, we couldn’t create it',
    'app.create.user.confirmation_body_fail': 'The user couldn’t be created. Please, review the data provided, the internet connection and try again. If the problem persists, talk to the account manager.',
    'app.create.user.confirmation_title_success': 'Successfully created',
    'app.create.user.confirmation_body_success': 'The user was created successfully.',
    'app.create.user.confirmation_body_fail_already_exists': 'The user couldn’t be created because another user already has the email provided.',
    'app.create.user.confirmation_body_fail_wrong_email': 'The user couldn’t be created because the provided email is invalid.',
    'app.reset_password.title': 'RESET PASSWORD',
    'app.reset_password.body': 'Clicking by Reset Password, you will reset temporary the password for <b>{username}</b>.<br/><br/>Once they logged on to the system, they will be able to change their password.',
    'app.reset_password.successful_message': '<b>{username}</b> was successful updated.',
    'app.reset_password.error_message': 'Something went wrong, please, try again in a few minutes.',
    'app.reset_password.new_temporary_password': 'Type below the new temporary password',
    'app.reset_password.new_password': 'New Password',
    'app.reset_password.button_reset': 'Reset Password',
    'app.users_type.select': 'Select a User Type',
    "app.users_type.all": "All",
    "app.users_type.participant": "Participant",
    "app.users_type.program_admin": "Program Administrator",
    "app.users_type.institutional": "Institutional",
    "app.users_type.account_manager": "Account Manager",
    "app.users_type.super_admin": "Super Administrator",
    "app.users_type.external_partner": "External Partner"
}

export const usersZHCN: UsersMessages =
    { ...users }
