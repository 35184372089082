import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsZHTW: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': '关于您这次参加BEVI考评的课程项目，请回答以下三个问题。',
    'app.discursive_questions.question1': '1.什么事情或哪方面对您影响最大？为什么？',
    'app.discursive_questions.question2': '2.这次经历有没有让您在与自己或与他人的关系中更明确了“自我”或“自己的身份”（例如：性别、民族、对宗教、政治或不同文化的看法等）？那么，在你看来，其原因何在？',
    'app.discursive_questions.question3': '3.作为这次您参加BEVI考评的课程项目的结果，您期望能学到什么？或是您已经学到了什么？',
    'app.discursive_questions.thank_you': '非常感谢您参加BEVI考评。',
    'app.discursive_questions.please_note': '注意:',
    'app.discursive_questions.go_to_report': '如果您想阅览这次BEVI考评的个人结果，请单击下键。并且，您也可以稍后在仪表板（Dashboard）上直接阅览。',
    'app.discursive_questions.do_not_show_report': '很遗憾因某些原因（例如：您回答的问题不够等）无法生成结果。若可以的话，请您再次参加BEVI考评。',
    'app.discursive_questions.visualize_report': '结果阅览',
}
