import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsEN: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': 'In reflecting upon the experience for which you are completing the BEVI, please answer the following three questions.',
    'app.discursive_questions.question1': '1. Which event or aspect of your experience had the greatest impact upon you and why?',
    'app.discursive_questions.question2': '2. Was there some aspect of your own "self" or "identity" (e.g., gender, ethnicity, sexual orientation, religious or political background, etc.) that became especially clear or relevant to you or others as a result of this experience?',
    'app.discursive_questions.question3': '3. What have you learned or how are you different as a result of this experience?',
    'app.discursive_questions.thank_you': 'Thank you for completing the BEVI!',
    'app.discursive_questions.please_note': 'Please note:',
    'app.discursive_questions.go_to_report': 'If you want to see your report now, please click on the below button. You also may review your report later in your dashboard.',
    'app.discursive_questions.do_not_show_report': 'Unfortunately, for several possible reasons (e.g., answers were missing for too many questions), we are not able to generate your report. If you wish, you may try taking the BEVI again.',
    'app.discursive_questions.visualize_report': 'Read Report',
}
