import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Collapse, notification } from 'antd';
import { useActions, useValues } from 'kea';
import { Loading } from '../../components/Loading';
import { appLogic, AppProps } from '../../redux/appLogic';
import { ReportActions, reportLogic, ReportProps } from '../../redux/reportLogic';
import {
    COMPARATIVE_BY_GROUP_REPORT_PAGE, COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE, EDIT_REPORT_PAGE,
    GROUP_REPORT_PAGE, LONGITUDINAL_REPORT_PAGE, PROGRAMS_PAGE, REPORT_PAGE
} from '../../router/pages';
import {
    FormModel,
    GetReportModel, OrganizationModel, ParticipantModel, ProgramModel, ReportFormsModel, Reports,
    SaveReportRequest, SearchFormsRequest, SubProgramModel, UserFormModel
} from '../../services/reportService';
import { Step3 } from './components';
import { Step1Container } from './components/Step1Container';
import { Step2Container } from './components/Step2Container';
import { Step4Container } from './components/Step4Container';
import { StepsAddGroup, StepsBackNext } from './components/StepsBtns';
import { CreateReport } from './CreateReport';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { colors } from '../../config/colors';
import { TreeOrganization } from './components/TreeOrganizations';
import { concat, every, flatMap, indexOf, sortBy } from 'lodash';
import { ComparativeIndividualReportActions, ComparativeIndividualReportProps, comparativeIndividualReportLogic } from '../../redux/comparativeIndividualReportLogic';
import { UsersActions, UsersProps, usersLogic } from '../../redux/usersLogic';
import { SearchUsersDetails, UserType } from '../../services/usersService';

export type SelectedSubProgram = {
    organizationId: number;
    programId: number;
    subProgramId: number;
}

type Group = {

    name?: string,
    selectedSubPrograms: SelectedSubProgram[],
    forms: FormModel[],
    lastEmail?: string
}

type Props = RouteComponentProps;

const { Panel } = Collapse;

// TODO(rodrigo.santos): use keyErrorMessage props from report logic.
export const CreateReportContainer = withRouter((props: Props): JSX.Element => {

    //#region Redux properties and actions
    const {
        loadOrganizations, searchForReportForms, searchForReportFormsGroup, generateReport, clearReportData,
        loadSelectedReport, clearSelectedReportId
    }: ReportActions = useActions(reportLogic);

    const {
        loading, organizations, searching, reportForms, generating, reportResult,
        selectedReport, selectedReportId
    }: ReportProps = useValues(reportLogic);

    const {
        findValidIndividualForms, saveIndividualReport, clearReportResponse, clearErrorValidForm
    }: ComparativeIndividualReportActions = useActions(comparativeIndividualReportLogic);

    const {
        validForms, loadingValidForm, saveReportResponse, loadingSave, errorValidForm
    }: ComparativeIndividualReportProps = useValues(comparativeIndividualReportLogic);

    const { searchUsers }: UsersActions = useActions(usersLogic);
    const { users, loadingSearchUsers }: UsersProps = useValues(usersLogic);

    const [usersFiltered, setUsersFiltered] = useState<SearchUsersDetails[]>([]);

    const timer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        setUsersFiltered(users.filter((x: any) => [UserType.programAdmin, UserType.superAdmin, UserType.accountManager, UserType.institutional]
            .includes(x.userType)));
    }, [users])

    useEffect(() => {
        searchUsers(0, '', '');
    }, [searchUsers])

    useEffect(() => {
        if (errorValidForm != null) {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                clearErrorValidForm();
            }, 5000)
        }
    }, [clearErrorValidForm, errorValidForm])

    useEffect(() => {
        if (!organizations) loadOrganizations();
    }, [organizations, loadOrganizations]);

    useEffect(() => {
        if (props.location.pathname === EDIT_REPORT_PAGE) loadSelectedReport(selectedReportId);
    }, [props.location, loadSelectedReport, selectedReportId]);

    // Effect validate the selected organizations, reportForms, reportResult.
    useEffect(() => {
        const redirectPath =
            (props.location.pathname === EDIT_REPORT_PAGE && selectedReportId === null);

        if (redirectPath) props.history.replace(PROGRAMS_PAGE);
    }, [selectedReportId, props.history, props.location]);

    //#endregion
    const { tokenInfo }: AppProps = useValues(appLogic);

    // Step1 properties (Configuring report).
    const [reportName, setReportName] = useState<string>("");
    const [reportType, setReportType] = useState<Reports>();
    const [currentSelectedSubProgram, setCurrentSelectedSubProgram] = useState<Partial<SelectedSubProgram>>({});
    const [selectedSubPrograms, setSelectedSubProgram] = useState<SelectedSubProgram[]>([]);
    const [groups, setGroups] = useState<Group[]>([{
        name: undefined,
        selectedSubPrograms: [],
        forms: []
    }]);

    //Step2 properties - Personalizing report.
    const [reportFilter, setReportFilter] = useState<Partial<SearchFormsRequest>>({});
    const [showCriteriaFilter, setShowCriteriaFilter] = useState<boolean>(false);

    //#region Step3 - Reviewing participants.
    const [selectedParticipants, setSelectedParticipants] = useState<ParticipantModel[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<number[]>();
    const [selectedEthnics, setSelectedEthnics] = useState<number[]>();
    const [maxNumberForms, setMaxNumberForms] = useState<number>();
    const [selectedRelationships, setSelectedRelationships] = useState<number[]>();
    const [selectedReligions, setSelectedReligions] = useState<number[]>();
    const [selectedPrimaryAreaOfWorkCodes, setSelectedPrimaryAreaOfWorkCodes] = useState<number[]>();
    const [selectedSubprograms, setSelectedSubprograms] = useState<number[]>();
    const [selectedGroup, setSelectedGroup] = useState<number>();

    const [shouldGenerateReport, setShouldGenerateReport] = useState<boolean>(false);
    const [loadedUserForms, setLoadedUserForms] = useState<ReportFormsModel[]>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [collapseOpen, setCollapseOpen] = useState<string[]>(['0']);
    const [searchOn, setSearchOn] = useState<boolean>(false);
    const [isGroup, setIsGroup] = useState<boolean>(false);
    const [groupParticipants, setGroupParticipants] = useState<ParticipantModel[]>([]);
    const [allForms, setAllForms] = useState<FormModel[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    const noUserTitle = GetFormattedMessage('app.create_report.step3_no_user_title');
    const noUserSubtitle = GetFormattedMessage('app.create_report.step3_no_user_subtitle');
    const removeTooltip = GetFormattedMessage('app.create_report.action_remove');

    const findForms = (email: string, groupIndex: number) => {
        if (allForms?.find(x => x.email === email) == null) {
            findValidIndividualForms(email);
        }
        if (reportType != null && [Reports.individual_report, Reports.longitudinal_individual_report,
        Reports.partner_report, Reports.partner_change_report].includes(reportType)) {
            const groupsTemp = [...groups];
            groupsTemp[groupIndex].forms = [];
            groupsTemp[groupIndex].lastEmail = email;
            setGroups(groupsTemp);
        }
    }

    const setSelectForms = (forms: FormModel[], groupIndex: number) => {
        const groupsTemp = [...groups];
        groupsTemp[groupIndex].forms = forms;
        setGroups(groupsTemp);
    }

    // Clear Step 3 information when Selected sub program (Step 1) changes
    const clearStep3Properties = () => {
        setSelectedParticipants([]);
        setSelectedCountries([]);
        setSelectedEthnics([]);
        setSelectedRelationships([]);
        setSelectedReligions([]);
        setSelectedPrimaryAreaOfWorkCodes([]);
        setMaxNumberForms(undefined);
        setLoadedUserForms([]);
        clearReportData();
    };

    const isIndividualReport = useMemo(() => {
        return reportType != null && [Reports.individual_report, Reports.longitudinal_individual_report, Reports.partner_report,
        Reports.partner_change_report].includes(reportType);
    }, [reportType])

    const saveDisabled = useMemo(() => {
        if (reportType !== Reports.comparative_by_individual) {
            if (selectedCountries != null && selectedCountries.length > 0 &&
                selectedEthnics != null && selectedEthnics.length > 0 &&
                selectedRelationships != null && selectedRelationships.length > 0 &&
                selectedReligions != null && selectedReligions.length > 0 &&
                selectedPrimaryAreaOfWorkCodes != null && selectedPrimaryAreaOfWorkCodes.length > 0 &&
                maxNumberForms != null && maxNumberForms > 0)
                return false;
            else
                return true;
        } else {
            return false;
        }
    }, [reportType, selectedCountries, selectedEthnics, selectedRelationships, selectedReligions, selectedPrimaryAreaOfWorkCodes, maxNumberForms])

    useEffect(() => {
        if (validForms) {
            setAllForms(x => [...x, ...validForms]);
        }
    }, [validForms])

    useEffect(() => {
        if (saveReportResponse != null) {
            props.history.push(REPORT_PAGE);
            notification.info({ message: 'This report will only be displayed when users approve the request.' })
            clearReportResponse();
        }
    }, [saveReportResponse, clearReportResponse, props.history, reportType])

    useEffect(() => {
        setIsGroup(reportType != null && [Reports.longitudinal_report, Reports.comparative_by_group_report,
        Reports.partner_report, Reports.partner_change_report].includes(reportType));
        setGroups([{
            name: undefined,
            selectedSubPrograms: [],
            forms: []
        }]);
    }, [reportType])

    // Init selected participants.
    useEffect(() => {
        let initialReportForms: ReportFormsModel[] = reportForms;
        if (props.location.pathname === EDIT_REPORT_PAGE && loadedUserForms.length > 0) {
            initialReportForms = loadedUserForms;
        }

        let newSelectedSubPrograms: ParticipantModel[] = [];
        initialReportForms?.forEach((groupForms: ReportFormsModel) => {
            const subprograms: ParticipantModel[] = (groupForms.forms.map((user: UserFormModel) => ({
                id: user.formId,
                alias: user.alias,
                groupName: groupForms.groupName
            }))) ?? [];
            newSelectedSubPrograms = [...newSelectedSubPrograms, ...subprograms];
        });
        setGroupParticipants([...newSelectedSubPrograms]);
        setSelectedParticipants(newSelectedSubPrograms);
    }, [reportForms, loadedUserForms, props.location, setSelectedParticipants]);

    useEffect(() => {
        if (shouldGenerateReport && reportResult && reportResult.result) {
            setShouldGenerateReport(false);
            let report = ''
            if (reportResult.result.reportType === 1) {
                report = GROUP_REPORT_PAGE.replace(':id', reportResult.result.reportId.toString());
            } else if (reportResult.result.reportType === 2) {
                report = LONGITUDINAL_REPORT_PAGE.replace(':id', reportResult.result.reportId.toString());
            } else if (reportResult.result.reportType === 3) {
                report = COMPARATIVE_BY_GROUP_REPORT_PAGE.replace(':id', reportResult.result.reportId.toString());
            } else if (reportResult.result.reportType === 4) {
                report = COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE.replace(':id', reportResult.result.reportId.toString());
            }
            clearReportData();
            clearSelectedReportId();
            props.history.replace(report);
        }
    }, [shouldGenerateReport, reportResult, clearReportData, props.history, clearSelectedReportId]);

    useEffect(() => {
        if (searchOn === true && searching === false && reportForms != null && reportForms.length > 0) {
            setSearchOn(false);
            setCurrentStep(x => x + 1);
        } else if (searchOn === true && searching === false && (reportForms == null || reportForms.length === 0)) {
            notification.warning({
                message: noUserTitle,
                description: noUserSubtitle,
                duration: 2000,
            });
        } else {
            setSearchOn(searching);
        }
        // eslint-disable-next-line
    }, [searching])

    //#endregion

    //#region Edit Report Logic
    const [loadingEditReport, setLoadingEditReport] = useState<boolean>(
        props.location.pathname === EDIT_REPORT_PAGE
    );

    useEffect(() => {
        const { pathname } = props.location
        if (!!reportForms && loadingEditReport && pathname === EDIT_REPORT_PAGE) {
            setLoadingEditReport(false);
        }
        // eslint-disable-next-line
    }, [reportForms]);

    // Only for Edit Report Page
    useEffect(() => {
        if (props.location.pathname !== EDIT_REPORT_PAGE) return;

        const selectedReportModel = selectedReport as GetReportModel;
        if (selectedReportModel && organizations) {
            setReportName(selectedReportModel.name);
            setReportType(selectedReportModel.reportType);

            let reportSelectedSubPrograms: SelectedSubProgram[] = [];
            selectedReportModel.subPrograms.forEach((subProgramId: number) => {
                organizations.forEach((o: OrganizationModel) => {
                    const searchedProgram = o.programs.find((p: ProgramModel) =>
                        p.subPrograms.find((sp: SubProgramModel) => sp.id === subProgramId) !== undefined);

                    if (searchedProgram !== undefined) {
                        reportSelectedSubPrograms.push({
                            organizationId: o.id,
                            programId: searchedProgram.id,
                            subProgramId: subProgramId
                        })
                    }
                })
            })
            setSelectedSubProgram(reportSelectedSubPrograms);
            if (reportSelectedSubPrograms.length > 0 && selectedReportModel.reportType === Reports.longitudinal_report) {
                setCurrentSelectedSubProgram({
                    organizationId: reportSelectedSubPrograms[0].organizationId,
                    programId: reportSelectedSubPrograms[0].programId,
                })
            }
            if (selectedReportModel.searchFilters) {
                setReportFilter({ ...selectedReportModel.searchFilters, subProgramsIds: [] });
            }

            setLoadedUserForms(selectedReportModel.participants);
            setMaxNumberForms(selectedReportModel.maxNumberForms);
            setSelectedCountries(selectedReportModel.selectedCountries);
            setSelectedEthnics(selectedReportModel.selectedEthnics);
            setSelectedRelationships(selectedReportModel.selectedRelationships);
            setSelectedReligions(selectedReportModel.selectedReligions);
            setSelectedPrimaryAreaOfWorkCodes(selectedReportModel.selectedPrimaryAreaOfWorkCodes);

            searchForReportFormsGroup({
                ...selectedReportModel.searchFilters,
                groups: groups.map(group => {
                    return {
                        groupName: group.name,
                        subProgramsIds: group.selectedSubPrograms.map((current: SelectedSubProgram) => current.subProgramId)
                    }
                })
            }, selectedReportModel.reportType);

            setShowCriteriaFilter(true);
        }
        // eslint-disable-next-line
    }, [
        selectedReport, searchForReportForms, searchForReportFormsGroup, organizations, setReportName, setReportType,
        setSelectedSubProgram, setReportFilter, setLoadedUserForms, setMaxNumberForms, setSelectedCountries,
        setSelectedEthnics, setShowCriteriaFilter
    ]);

    //#endregion

    //#region Handle functions

    const changeGroupName = (value: string, index: number): void => {
        let groupTemp = [...groups];
        groupTemp[index].name = value;
        setGroups(groupTemp);
    }

    const openTreeModal = (groupIndex: number) => {
        setSelectedGroup(groupIndex);
        setSelectedSubprograms(groups[0].selectedSubPrograms.map(x => x.subProgramId));
    }

    const addMultiplesSubPrograms = (subPrograms: SelectedSubProgram[]) => {
        if (selectedGroup != null && subPrograms.length > 0) {
            let groupTemp = [...groups];
            const subProgramsTemp = groupTemp[selectedGroup].selectedSubPrograms;
            groupTemp[selectedGroup].selectedSubPrograms = concat(subProgramsTemp, subPrograms);
            setGroups(groupTemp);
        }
        setSelectedGroup(undefined);
        setSelectedSubprograms(undefined);
    }

    const handleAddSubProgramClick = (index?: number): void => {
        if (currentSelectedSubProgram.organizationId !== undefined &&
            currentSelectedSubProgram.programId !== undefined &&
            currentSelectedSubProgram.subProgramId !== undefined
        ) {
            const newSelected = (currentSelectedSubProgram as SelectedSubProgram);

            if (index == null)
                setSelectedSubProgram(selectedSubPrograms ? [...selectedSubPrograms, newSelected] : [newSelected]);
            else
                groups[index].selectedSubPrograms = groups[index].selectedSubPrograms != null ? [...groups[index].selectedSubPrograms!, newSelected] : [newSelected];

            if (reportType === Reports.longitudinal_report) {
                setCurrentSelectedSubProgram({
                    organizationId: currentSelectedSubProgram.organizationId,
                    programId: currentSelectedSubProgram.programId,
                });
            } else {
                setCurrentSelectedSubProgram({});
            }
        }
        clearStep3Properties();
    }

    const handleRemoveSubProgramClick = (subProgramId: number, index?: number): void => {
        if (index == null) {
            const newSelectedSubPrograms = selectedSubPrograms.filter((s) => s.subProgramId !== subProgramId);
            setSelectedSubProgram([...newSelectedSubPrograms]);
        } else {
            let groupsTemp = [...groups];
            groupsTemp[index].selectedSubPrograms = groupsTemp[index].selectedSubPrograms.filter((s) => s.subProgramId !== subProgramId);
            setGroups(groupsTemp);
        }
        clearStep3Properties();
    }

    const handleSelectedParticipantsChanged = (participants: ParticipantModel[]): void => {
        if (groups.length === 0)
            setSelectedParticipants([...participants]);
        else {
            const newParticipants: ParticipantModel[] = [];
            participants.forEach((participant) => {
                participant.groupName = groupParticipants.find(x => x.id === participant.id)?.groupName;
                newParticipants.push(participant);
            })
            setSelectedParticipants([...newParticipants]);
        }
    }

    const handleReportFiltervaluesChanged = (newFilterValues: Partial<SearchFormsRequest>) => {
        setReportFilter(newFilterValues);
        clearStep3Properties();
    }

    const handleCurrentSelectedSubProgramChanged = (change: Partial<SelectedSubProgram>) => {
        setCurrentSelectedSubProgram({ ...currentSelectedSubProgram, ...change });
    }

    const handleSearchButtonClicked = () => {
        searchForReportFormsGroup({
            ...reportFilter, groups: groups.map(group => {
                return {
                    groupName: group.name,
                    subProgramsIds: group.selectedSubPrograms.map((current: SelectedSubProgram) => current.subProgramId)
                }
            })
        }, reportType);
    }

    const handleGenerateReportButtonClicked = () => {
        if (!reportName || !reportType) return;

        let subprograms: number[] = [];
        const groupsNames: string[] = [];

        groups.forEach(group => {
            subprograms = [...subprograms, ...group.selectedSubPrograms.map(x => x.subProgramId)];
            if(group.name && [Reports.longitudinal_report, 
                Reports.comparative_by_group_report, 
                Reports.comparative_by_individual].includes(reportType)) {
                groupsNames.push(group.name);
            }
        });

        const report: SaveReportRequest = {
            id: selectedReportId,
            name: reportName,
            reportType: reportType,
            subPrograms: subprograms,
            participants: selectedParticipants,
            groupOrder: groupsNames,
            searchFilters: reportFilter,
            maxNumberForms: maxNumberForms ?? 0,
            selectedCountries: selectedCountries ?? [],
            selectedEthnics: selectedEthnics ?? [],
            selectedRelationship: selectedRelationships ?? [],
            selectedReligions: selectedReligions ?? [],
            selectedPrimaryAreasOfWork: selectedPrimaryAreaOfWorkCodes ?? [],
        };

        generateReport(report);
        setShouldGenerateReport(true);
    }

    const handleSelectCountriesChanged = (values: number[] | undefined) => {
        setSelectedCountries(values);
    }

    const handleSelectEthnicsChanged = (values: number[] | undefined) => {
        setSelectedEthnics(values);
    }

    const handleMaxNumberFormsChanged = (value: number | undefined) => {
        setMaxNumberForms(value)
    };

    const handleSelectRelationshipsChanged = (values: number[] | undefined) => {
        setSelectedRelationships(values);
    }

    const handleSelectReligionsChanged = (values: number[] | undefined) => {
        setSelectedReligions(values);
    }

    const handleSelectPrimaryAreaOfWorkCodesChanged = (values: number[] | undefined) => {
        setSelectedPrimaryAreaOfWorkCodes(values);
    }

    const handleUserChangeReportType = (reportType?: Reports) => {
        setReportType(reportType);
        // Clear selected subprograms when report type change.
        setCurrentSelectedSubProgram({});
        setSelectedSubProgram([]);
        clearStep3Properties();
    }

    const disableIndividual = useMemo(() => {
        if (reportType === Reports.individual_report) {
            return groups[0].forms.length === 0;
        } else if (reportType === Reports.longitudinal_individual_report) {
            return groups[0].forms.length < 2;
        } else if (reportType === Reports.partner_report) {
            return flatMap(groups, x => x.forms).length < 2;
        } else if (reportType === Reports.partner_change_report) {
            if (groups.length > 1 && groups[0].forms != null && groups[0].forms.length > 0) {
                return !every(groups, (group, index, groups) => {
                    return group.forms.length === groups[0].forms.length;
                });
            }
        }
        return true;
    }, [reportType, groups])

    const saveIndividual = () => {
        saveIndividualReport({
            name: reportName,
            reportType,
            selectedForms: flatMap(groups, x => x.forms).map(x => x.id),
            selectedUsers: selectedUsers
        });
    }

    const getOrderedParticipants = useCallback((forms: any) => {
        if (reportType) {
            if ([Reports.longitudinal_report, 
                Reports.comparative_by_group_report, 
                Reports.comparative_by_individual].includes(reportType)) {
                    console.log('forms', forms, groups);
                const orderNames = groups.map(x => x.name);
                return sortBy(forms, (obj) => indexOf(orderNames, obj.groupName));
            } else {
                return forms;
            }
        } else {
            return [];
        }
    }, [groups, reportType])

    //#endregion

    //#region Render

    if (loading || loadingEditReport) return (<Loading />);

    const ComponentStep1: ReactNode = <Fragment>
        <Step1Container
            key={1}
            userInfo={tokenInfo}
            reportName={reportName}
            reportType={reportType}
            onReportNameChanged={(reportName) => setReportName(reportName)}
            onReportTypeChanged={handleUserChangeReportType}
        />
        <StepsBackNext key={2} disabled={reportName == null || reportType == null}
            hasBack={false} setCurrentStep={setCurrentStep} />
    </Fragment>
    const ComponentStep2: ReactNode = <div>
        <Collapse activeKey={collapseOpen} onChange={(value) => {
            if (value.length > 0) {
                setCollapseOpen((value as any)[value.length - 1])
            } else {
                setCollapseOpen([]);
            }
            setTimeout(() => {
                setCurrentSelectedSubProgram({});
            }, 200)
        }}>
            {groups.map((group, i) => <Panel extra={groups.length > 1 && <OverlayTrigger
                key={i}
                placement={'auto'}
                trigger={["hover", "hover"]}
                overlay={
                    <Tooltip id={`action_remove`} key={`actionRemoveTooltip-${i}`}>
                        {removeTooltip}
                    </Tooltip>
                }
            >
                <FaTimes
                    size={20}
                    color={colors.red}
                    onClick={() => setGroups((x) => x.filter((value: any, index: number) => index !== i))}
                />
            </OverlayTrigger>}
                header={(group.name && group.name.trim() !== '') ? group.name :
                    !isIndividualReport ?
                        <FormattedMessage id={'app.listreport.please_add_group_name'} /> : <>
                            <FormattedMessage id={'app.listorganizations.report'} /> {i + 1}
                        </>}
                key={i.toString()}>
                <Step2Container
                    openTreeModal={openTreeModal}
                    isGroup={isGroup}
                    reportType={reportType}
                    organizations={organizations}
                    selectedSubPrograms={group.selectedSubPrograms}
                    currentSelectedSubProgram={currentSelectedSubProgram}
                    groupName={group.name}
                    groupIndex={i}
                    onOrganizationSelected={(organizationId) => handleCurrentSelectedSubProgramChanged({ organizationId: organizationId })}
                    onProgramSelected={(programId) => handleCurrentSelectedSubProgramChanged({ programId: programId })}
                    onSubProgramSelected={(subProgramId) => handleCurrentSelectedSubProgramChanged({ subProgramId: subProgramId })}
                    onAddSubProgramClick={handleAddSubProgramClick}
                    onRemoveSubProgramClick={handleRemoveSubProgramClick}
                    changeGroupName={changeGroupName}
                    findForms={findForms}
                    forms={allForms}
                    loadingForms={loadingValidForm}
                    errorValidForm={errorValidForm}
                    selectForms={group.forms}
                    setSelectForms={setSelectForms}
                    lastEmailGroup={group.lastEmail}
                    ignoreEmails={groups.filter(x => x !== group).map(x => x.name ?? '')}
                />
            </Panel>)}
        </Collapse>
        {isGroup && <StepsAddGroup reportType={reportType} onClick={() => {
            setCollapseOpen([groups.length.toString()]);
            setGroups(x => {
                return [...x, {
                    name: undefined,
                    selectedSubPrograms: [],
                    forms: [],
                    selectedSubProgramsLongitudinal: {}
                }]
            })
        }} />}
        {isIndividualReport
            ? <StepsBackNext loadingNext={loadingSave}
                disabled={disableIndividual}
                hasBack={true}
                nextClick={() => setCurrentStep(x => x + 1)}
                setCurrentStep={(v) => {
                    setGroups([{
                        name: undefined,
                        selectedSubPrograms: [],
                        forms: []
                    }]);
                    setCurrentStep(v);
                }} />
            :
            <StepsBackNext disabled={(isGroup && (groups.length < 2 || groups.filter(group => group.selectedSubPrograms.length > 0 &&
                group.name != null && group.name.trim() !== '').length !== groups.length)) ||
                (!isGroup && (groups[0].name == null || groups[0].name.trim() === '' ||
                    groups[0].selectedSubPrograms.length === 0))}
                hasBack={true} setCurrentStep={setCurrentStep} />}
    </div>
    const ComponentStep3: ReactNode = currentStep === 2 &&
        <Fragment>
            <Step3
                showCriteriaFilter={showCriteriaFilter}
                filterValues={reportFilter}
                reportType={reportType}
                onFilterChange={handleReportFiltervaluesChanged}
                handleFilterVisibilityToggleButton={() => setShowCriteriaFilter(!showCriteriaFilter)}
                setCurrentStep={setCurrentStep}
                loadingUsers={loadingSearchUsers}
                users={usersFiltered}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
            />
            {isIndividualReport
                ? <StepsBackNext loadingNext={loadingSave} nextName='app.save' nextClick={saveIndividual}
                    disabled={disableIndividual || selectedUsers.length === 0}
                    hasBack={true} setCurrentStep={(v) => {
                        setCurrentStep(v);
                    }} />
                :
                <StepsBackNext disabled={false} nextClick={() => handleSearchButtonClicked()} loadingNext={searching}
                    hasBack={true} setCurrentStep={setCurrentStep} />}
        </Fragment>
        ;
    const ComponentStep4: ReactNode = reportType && currentStep === 3 &&
        <Fragment>
            <Step4Container
                userInfo={tokenInfo}
                reportType={reportType}
                allParticipants={getOrderedParticipants(reportForms)}
                selectedParticipants={selectedParticipants}
                selectedCountries={selectedCountries}
                selectedEthnics={selectedEthnics}
                selectedRelationships={selectedRelationships}
                selectedReligions={selectedReligions}
                selectedPrimaryAreaOfWorkCodes={selectedPrimaryAreaOfWorkCodes}
                maxNumberForms={maxNumberForms}
                onSelectCountriesChanged={handleSelectCountriesChanged}
                onSelectEthnicsChanged={handleSelectEthnicsChanged}
                onSelectRelationshipsChanged={handleSelectRelationshipsChanged}
                onSelectReligionsChanged={handleSelectReligionsChanged}
                onSelectPrimaryAreaOfWorkCodesChanged={handleSelectPrimaryAreaOfWorkCodesChanged}
                onMaxNumberFormsChanged={handleMaxNumberFormsChanged}
                onSelectedParticipantsChanged={handleSelectedParticipantsChanged}
                setCurrentStep={setCurrentStep}
            />
            <StepsBackNext disabled={saveDisabled} loadingNext={generating} nextName='app.save' nextClick={handleGenerateReportButtonClicked}
                hasBack={true} setCurrentStep={setCurrentStep} />
        </Fragment>
    return (
        <>
            <CreateReport
                isIndividualReport={isIndividualReport}
                currentStep={currentStep}
                shouldEnableGenerateButton={reportForms !== null && reportForms.length > 0}
                generating={generating}
                searching={searching}
                onSearchClicked={handleSearchButtonClicked}
                onGenerateReportButtonClick={handleGenerateReportButtonClicked}
            >
                {{
                    Step1: ComponentStep1,
                    Step2: ComponentStep2,
                    Step3: ComponentStep3,
                    Step4: ComponentStep4,
                }}
            </CreateReport>
            <TreeOrganization onCancel={() => setSelectedSubprograms(undefined)} addMultiplesSubPrograms={addMultiplesSubPrograms}
                selectedOrganizations={selectedSubprograms} organizations={organizations} />
        </>
    )

    //#endregion
});
