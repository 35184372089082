import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { LOGIN_PAGE, NOT_FOUND } from '../../router/pages';
import { ValidateToken } from '../../utils/validateHelper';
import { ChangePassword } from './ChangePassword';

type Props = RouteComponentProps;

export const ChangePasswordContainer = withRouter((props: Props): JSX.Element => {
    const { token } = useParams<{ token: string }>();

    const [passwordChandedCorrectly, setPasswordChandedCorrectly] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const handleModalConfirmationExit = (): void => {
        setShowConfirmation(false);
        props.history.replace(LOGIN_PAGE);
    }

    useEffect(() => {
        const validate = ValidateToken(token);
        if (validate === undefined) {
            props.history.replace(NOT_FOUND);
        } else if (validate === false) {
            setShowConfirmation(true);
        }
    }, [token, setShowConfirmation, props.history])

    const { changePassword }: AppActions = useActions(appLogic);
    const { loading, hasPasswordUpdateError }: AppProps = useValues(appLogic);

    const [error, setError] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);

    const handleChangePasswordSubmit = (password: string, confirmationPassword: string): void => {
        if (password === confirmationPassword) {
            changePassword(token, password);
            setSending(true);
        } else {
            setError('app.login.passwords_dont_match')
        }
    }

    useEffect(() => {
        if (hasPasswordUpdateError) {
            setError('app.systemerro');
            setSending(false);
        }
    }, [hasPasswordUpdateError]);

    useEffect(() => {
        if (sending === true && loading === false && hasPasswordUpdateError === false) {
            setPasswordChandedCorrectly(true);
            setShowConfirmation(true);
        }
    }, [loading, hasPasswordUpdateError, sending, props.history]);

    return (
        <React.Fragment>
            <ChangePassword
                changePassword={handleChangePasswordSubmit}
                loading={loading}
                errorKey={error}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={passwordChandedCorrectly ?
                    'app.login.change_password.success_confirmation_title'
                    :
                    'app.login.change_password.expired_confirmation_title'
                }
                bodyFormattedListMessageId={passwordChandedCorrectly ?
                    ['app.login.change_password.success_confirmation_body']
                    :
                    ['app.login.change_password.expired_confirmation_body']
                }
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={handleModalConfirmationExit}
                onPrimaryButtonClicked={handleModalConfirmationExit}
            />
        </React.Fragment>
    );
});
