import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCloudDownloadAlt } from 'react-icons/fa';

import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ReportProps } from './ReportTypes';
import { ReportView } from './ReportView';

export const IndividualReport = (props: ReportProps): JSX.Element => {

    return (
        <React.Fragment>
            { props.agreed &&
                <div style={styles.divButtonContainer}>
                    <ButtonPDF
                        loading={props.downloading}
                        onClick={props.onDownloadButtonClicked}
                    />
                </div>
            }
            <ReportView {...props}/>
        </React.Fragment>
    );
}

type ButtonProps = {
    onClick: () => void;
    loading: boolean;
}

const ButtonPDF = (props: ButtonProps): JSX.Element => (
    <Button style={styles.button} onClick={props.onClick}>
        <FaCloudDownloadAlt size={22} style={styles.marginRight} />
        <span>
            { props.loading
                ? GetFormattedMessage('app.loading_document')
                : GetFormattedMessage('app.download_document')
            }
        </span>
    </Button>
)

const styles = {
    divButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        paddingBottom: 10,
        marginRight: 16,
    } as React.CSSProperties,
    button: {
        display: 'flex',
        height: 'auto',
        width: 'auto',
    } as React.CSSProperties,
    marginRight: {
        marginRight: 10,
    } as React.CSSProperties,
}
