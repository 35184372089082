import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'Tu i la teva visió del món',
    'app.individualreport.a_personal_report_from': 'Un informe personal del',
    'app.individualreport.bevi_description': 'L’Inventari de creences, esdeveniments i valors (BEVI)',
    'app.individualreport.user': 'Usuari',
    'app.individualreport.date_of_test': 'Data de finalització',
    'app.individualreport.static_text_1': 'Si agafes un diari o engegues la ràdio o la televisió ' +
        'a qualsevol lloc del món, un fet es torna immediatament evident: ' +
        'les creences i els valors són integrals a l’experiència humana. La gent té opinions fermes sobre temes ' +
        'que van des de la política, la religió, l’educació i les arts fins al matrimoni, la família, el gènere i la sexualitat.  ' +
        'Tanmateix, si una creença específica és "correcta" o "incorrecta", és sens dubte menys important que ' +
        'comprendre la interacció complexa entre pensaments, sentiments, comportaments, vivències ' +
        'i context que dona lloc a una versió única de la realitat per a cada ésser humà. ' +
        'Comprendre això és important perquè les creences i els valors influeixen en les accions, ' +
        'les polítiques i les pràctiques que duen a terme individus, grups, organitzacions, governs i societats de tot el món. ' +
        'L’Inventari de creences, esdeveniments i valors (BEVI) ens proporciona una manera d’explorar aquestes ' +
        'qüestions complexes a escala individual i grupal, i ens ajuda a cadascun de nosaltres a entendre per què tenim certes creences i valors, ' +
        'alhora que examina per què altres persones poden veure el món de maneres similars o diferents. ',
    'app.individualreport.static_text_2': 'Aquest informe està dissenyat per ajudar-te a conèixer de prop el que ' +
        'sembla que creus i valores, alhora que ofereix algunes possibilitats sobre per què creus el que creus. ' +
        'És cert que aquestes preguntes sobre "què" i "per què" són difícils de respondre de manera absoluta. ' +
        'A més, cal remarcar que el món es troba en un estat de canvi constant. ' +
        'El que avui es pot percebre com a "veritable" podria haver estat vist com a "fals" poc temps enrere. ' +
        'De la mateixa manera, el que avui es veu com a "dolent" es pot considerar "bo" en els propers anys. ' +
        'Per tant, és molt important que consideris les perspectives següents simplement com qüestions ' +
        ' en què pensar en aquest moment de la teva vida, més que una determinació final sobre aquestes qüestions. ' +
        'Com a éssers humans, tots som canviants, únics i complexos. Cap mesura pot mostrar tot ' +
        'el que has estat, el que ets ara o el que seràs; ni el BEVI pretén fer-ho. ' +
        'En canvi, aquest instrument està dissenyat per ajudar-te a reflexionar sobre tu mateix, els altres i el món en general' +
        'a través d’una sèrie d’àrees essencials que sovint són centrals en l’experiència de l’ésser humà.',
    'app.individualreport.static_text_3': 'Cal remarcar un últim punt. Tot i que aquest informe se centra en ' +
        'la naturalesa i les implicacions de les creences i valors en la vida de cadascú, la feina i les relacions interpersonals per tal de tenir ' +
        'millor comprensió d’un mateix, dels altres i del món en general, el BEVI no adopta cap posició concreta sobre si un conjunt de ' +
        'creences i valors són "correctes," "incorrectes," "millors," o "pitjors" que qualsevol altre conjunt de creences i valors.',
    'app.individualreport.static_text_4': 'Per tant, en aquest context, mirarem el teu informe. ' +
        'A més d’informació explicativa sobre les creences i els valors, ' +
        'les teves respostes individuals es ressalten a continuació en negreta.',
    'app.individualreport.static_text_5': 'Les experiències que tenim mentre creixem i al llarg de la vida sovint afecten la nostra manera ' +
        'de donar sentit a nosaltres mateixos, als altres i al món en general. Per exemple, persones amb moltes vivències positives en ' +
        'la seva vida i que senten que les seves necessitats bàsiques han estat satisfetes la major part del temps, sovint tenen la sensació que altres éssers humans són ' +
        'bàsicament persones en qui es pot confiar i de qui es poden esperar coses bones de la vida. De vegades, però no sempre, quan ' +
        'la gent experimenta moltes vivències negatives en la vida (per exemple, conflictes amb els cuidadors o a casa seva), o bé no ' +
        'senten que les seves necessitats bàsiques s’han satisfet prou (per exemple, d’amor o criança), tota la seva manera de veure ' +
        'el món i l\'experiència d\'altres (per exemple, a l\'escola, la feina i les relacions) es poden veure afectats, principalment perquè ' +
        '"el món" no sempre s\'ha percebut com un lloc gaire agradable, sobretot si aquestes experiències tenen lloc ' +
        'a una edat molt primerenca, durant un llarg període de temps o eren especialment difícils o traumàtiques. ' +
        'I, de fet, les persones que han experimentat moltes vivències negatives en la vida o una manca de cura de les seves necessitats bàsiques, ' +
        'poden trobar útil parlar amb algú amb la formació adequada o que tingui els coneixements necessaris sobre aquestes qüestions. ' +
        'Dit això, també és molt important reconèixer que la vida és difícil per a tots nosaltres d’una manera o altra, ' +
        'i fins i tot si experimentem moltes dificultats, la majoria de la gent és capaç de fer-hi front i és capaç de mantenir bones' +
        'relacions a l’escola, a la feina i a la vida, de vegades amb una ajuda considerable d’altres persones (per exemple, parelles, amics, professors, ' +
        'directors, líders espirituals, mentors i orientadors). En qualsevol cas, quan les creences i els valors dels altres no tenen sentit ' +
        ' per a nosaltres, pot ser útil recordar que les diferents experiències i contextos de la vida influeixen en la manera com ens veiem a nosaltres mateixos, ' +
        ' els altres i el món en general. Aquesta percepció pot ajudar-nos a comprendre una mica més (o com a mínim generar empatia per entendre) per què nosaltres i' +
        'els altres pensem, sentim i fem el que fem en diferents situacions i contextos.',
    'app.individualreport.static_text_6': 'Si tendeixes a ser una persona inquisitiva, pot ser útil recordar que ' +
        'altres persones que t’envolten, fins i tot persones aparentment "molt segures", poden no estar tan segures com semblen en relació amb' +
        'coses noves o desconegudes i, de fet, poden no saber res més que tu sobre què és el "correcte" que cal fer. ' +
        'La confiança basada en raons és fantàstica quan ens trobem en una situació en què tenim bones raons per creure que entenem el que està passant. ' +
        'No obstant això, quan ens trobem en una situació o lloc que mai no hem vist abans, pot ser útil adoptar una postura més inquisitiva' +
        'i oberta en general, prestant especial atenció al tipus de qüestions, preguntes, pràctiques i processos que ' +
        'ens semblin més intrigants. Aquesta informació sobre nosaltres mateixos ens pot ajudar a entendre millor qui som, en qui ens estem convertint i qui volem ser. ',
    'app.individualreport.static_text_7': 'Tanmateix, quan ens trobem amb persones o situacions noves i anem canviant amb el pas del temps, ' +
        'és igualment important que ens esforcem per "saber el que no sabem" per poder avaluar els límits del nostre coneixement, ' +
        'i admetre que és possible que no tinguem "les respostes adequades" o que necessitem aprendre més sobre un tema concret abans de prendre una decisió. ' +
        'En resum, el grau en què som capaços de donar sentit a per què els humans fem el que fem sovint s\'associa amb la capacitat de pensar, ' +
        'sentir i actuar amb més comprensió, saviesa i compassió.',
    'app.individualreport.static_text_8': ' En el context de les troballes i observacions anteriors, val la pena reflexionar de manera més general sobre ' +
        'el paper de les "necessitats" i els "sentiments" en la nostra vida quotidiana. Notarem, per exemple, que sovint busquem i fins i tot anhelem ' +
        'experiències en què "sentim profundament" (per exemple, a través de les relacions, la música, el teatre, la lectura, els viatges, la natura, etc.). ' +
        'Com que les nostres emocions revelen molt sobre el nostre "veritable jo", sovint ens costa sentir el que sentim, i menys encara' +
        'expressar aquests sentiments en veu alta, ja que fer-ho pot revelar molt sobre qui som realment. ' +
        'I, tanmateix, fer saber als altres "qui som" és crucial per construir relacions, satisfer les necessitats,   la recerca de feina ' +
        'i els objectius de la vida. Per tant, tot i que potser no és recomanable que revelem totes les emocions o necessitats que tenim, no ser capaç d’' +
        'experimentar-les o expressar-les pot crear dificultats notables per a nosaltres i per als altres.',
    'app.individualreport.static_text_9_1': 'En resum, la incapacitat o la falta de voluntat per sentir sentiments i reconèixer necessitats poden ser un problema ' +
        'per a nosaltres i els altres per almenys dues raons: 1) perdem informació important sobre el que realment està passant al nostre interior, que pot ' +
        ' deteriorar encara més la nostra presa de decisions i el nostre benestar general; i 2) als altres els pot costar relacionar-se amb nosaltres o saber qui som realment. ' +
        'Afortunadament, siguem capaços de reconèixer-ho o no, tots venim al món construïts per sentir i necessitar, que potser és la manera més ' +
        'bàsica de comunicar-nos amb altres éssers, fins i tot abans de dir les primeres paraules. ' +
        ' Al llarg de la vida, tenim l\'oportunitat de trobar-nos amb tota la gamma de necessitats i sentiments en nosaltres mateixos i en els altres, i ens anem tornant cada vegada més profundament humans en el camí.',
    'app.individualreport.static_text_9_2': '',
    'app.individualreport.static_text_9_3': '',
    'app.individualreport.static_text_9_4': '',
    'app.individualreport.static_text_10_1': 'Finalment, els nostres contextos individuals i les experiències poden afectar no només la manera com ens veiem a nosaltres mateixos i els altres, ' +
        'sinó com veiem el "món en general" i com creiem que és o que hauria de ser. Ens centrarem en dos temes globals finals ' +
        'que tenen rellevància per a tothom: 1) la nostra postura sobre el medi ambient i el món natural; i 2) el grau en què estem o ' +
        'no estem disposats a participar-hi a escala mundial',
    'app.individualreport.static_text_10_2': '',
    'app.individualreport.static_text_10_3': '',
    'app.individualreport.static_text_11': 'En finalitzar aquest informe, és important recordar que les afirmacions anteriors són aplicables ' +
        'a qui som i on som ara; tots canviem al llarg de la vida, i les nostres creences i valors també poden canviar en el temps. ' +
        'En definitiva, és bo que cultivem el coratge i la capacitat per reflexionar al més profundament possible sobre allò que creiem ' +
        'i valorem per tenir la millor possibilitat d\'assegurar-nos que les decisions que prenem a la nostra vida són aquelles que entenem, ' +
        'podem defensar i voldríem assumir. Amb sort, la informació i la perspectiva d’aquest informe ens són útils i rellevants ' +
        'a la nostra vida mentre continuem reflexionant sobre qui som ara, en qui ens estem convertint i qui volem ser.',
    'app.individualreport.heading_text_1_1': 'L’Inventari de creences, esdeveniments i valors (BEVI) està dissenyat per ajudar les persones a prendre ' +
        'més consciència de les seves creences i valors. El BEVI també examina com les creences i els valors poden influir en l’aprenentatge, ' +
        ' el creixement personal, les relacions, els processos grupals o organitzatius i la consecució d’objectius vitals. ' +
        'En tractar aquestes qüestions, l’informe conté informació sobre com et veus a tu mateix, els altres i el món en general. ' +
        'Tanmateix, el BEVI no adopta cap posició sobre si les respostes que has proporcionat són correctes, incorrectes, certes o falses, ' +
        'ni tampoc no fa cap reclamació d’aquest tipus. En canvi, està dissenyat per promoure la reflexió sobre qüestions de la vida ' +
        'que sovint són rellevants per a tots nosaltres. Si tens cap pregunta sobre el BEVI, posa’t en contacte amb l’administrador del BEVI de la teva organització o institució. ' +
        'També pots contactar-nos directament a info@ibavi.org. ' +
        'Abans de llegir l\'informe següent, has d\'acceptar les tres condicions següents, fent clic al botó D\'acord o signant un ' +
        'formulari de consentiment a banda que se’t proporcionarà abans de llegir l’informe:',
    'app.individualreport.heading_text_1_2': '',
    'app.individualreport.heading_text_1_3': '',
    'app.individualreport.heading_text_2': '1. ({UserName}), vas completar voluntàriament el BEVI i, posteriorment, vas sol·licitar aquest informe de seguiment, ' +
        'que llegeixes voluntàriament (és a dir, acceptes que cap persona, grup, organització o entitat no et demani que llegeixis aquest informe).',
    'app.individualreport.heading_text_3': '2. S\'ha fet tot el possible per comunicar la informació del BEVI d\'una manera útil ' +
        'i constructiva. Tanmateix, si tens problemes per llegir aquest informe, acceptes contactar amb l\'administrador/a del BEVI a la teva ' +
        'organització, el coordinador/a d’una sessió de retroalimentació de BEVI i / o un professional d’atenció mèdica o de salut.',
    'app.individualreport.heading_text_4': '3. Pots desar una còpia d’aquest informe. ' +
        ' Tanmateix, atès que el BEVI i aquest informe tenen drets d’autor, acceptes no fotocopiar-lo ni distribuir-lo ' +
        '(electrònicament o no) a qualsevol altra persona.',
    'app.individualreport.thankyou_static_1_1': 'L’Inventari de creences, esdeveniment i valors  (BEVI) i aquest informe de BEVI tenen drets d’autor. ' +
        'Els informes, els ítems, el contingut dels ítems o les escales de BEVI i el BEVI no es poden modificar, copiar, difondre ni publicar totalment' +
        ' o parcialment, sense el permís escrit i exprés de Craig N. Shealy, Ph.D.',
    'app.individualreport.thankyou_static_1_2': '',
    'app.individualreport.thankyou_static_2': 'Igual que amb el BEVI en el seu conjunt, l\'estructura i el disseny d\'aquests informes estan subjectes' +
        ' a processos de revisió i desenvolupament constants. Si tens suggeriments o comentaris sobre algun aspecte del BEVI o aquest ' +
        'informe, no dubtis a contactar-nos directament a info@ibavi.org.',
    'app.individualreport.thankyou_static_3': ' Per obtenir més informació sobre les creences i els valors, visita International Beliefs ' +
        'and Values Institute (IBAVI) a www.ibavi.org i www.thebevi.com.',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': 'Tots diferim una mica, i de vegades molt, en el que creiem i valorem. ' +
        ' Per què passa això? Tot i que hi ha moltes maneres de respondre a aquesta pregunta, el BEVI es basa en el reconeixement bàsic ' +
        'que els llocs, les cultures i els contextos en què creixem, així com les vivències positives i negatives que experimentem ' +
        ' mentre creixem i al llarg de la nostra vida, tenen un gran impacte en la forma en què veiem el món.',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': 'Des de la perspectiva del BEVI, adquirim formes de pensar i ' +
        ' percebre el món (per exemple, la política i la religió) com a resultat de tota una sèrie de factors, influïts però ' +
        'en cap cas limitats per la família, la criança, la cultura i el context. Així, per entendre què és el que la gent ' +
        'creu i valora, ajuda a conèixer algunes de les vivències positives i negatives que han tingut les persones ' +
        'així com el grau en què informen que s\'han satisfet les seves necessitats bàsiques. <b>En el teu cas,' +
        'has escrit que has experimentat {NegativeLifeEvents} nombre d\'experiències negatives a la teva vida. ' +
        'També informes que les teves necessitats bàsiques van ser {NeedsClosure} durant la infància i l\'adolescència.</b>'
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': ' Els nostres contextos i experiències també ens afecten d’altres maneres,' +
        ' i configuren el grau en què som capaços i estem disposats a tractar amb persones i esdeveniments molt diferents d’allò a què estem ' +
        ' acostumats. Des del punt de vista del BEVI, hi ha dos tipus de persones en aquest sentit. El primer tipus (diguem-ne ' +
        '"confident")  poden estar segurs d\'ells mateixos i de comprendre qui són els altres, com funciona el món i les seves ' +
        'experiències de la vida, la feina i les relacions. El segon tipus (l’anomenarem "inquisitiu")  pot no tenir tan clar aquestes qüestions, pot dubtar i penedir-se de les coses a vegades, i també sentir-se confós amb les seves possibles reaccions quan es troba davant de situacions noves,' +
        'diferents o desconegudes. <b>A partir de les teves respostes al BEVI, tens tendència a ser més confident que inquisitiu.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': ' Per descomptat, hi ha potencials avantatges i desavantatges ' +
        ' en un enfocament confiat o inquisitiu, i tothom podem sentir-nos de totes dues maneres de tant en tant, depenent de diferents ' +
        ' circumstàncies o situacions. En general, però, si ets del tipus "segur", pot ser útil prendre més consciència ' +
        'de les vegades que potser no saps què fer necessàriament, com pensar o què sentir, sobretot quan experimentes ' +
        ' una cosa que és diferent d’allò a què estàs acostumat. <b>En relació amb això, també sembla que tens una gran ' +
        'força de voluntat i sentit de responsabilitat, i creus que el món és "el que  fas" i et desagraden els "pensaments negatius" ' +
        'en general. Aquesta postura et pot ajudar a dur a terme moltes coses a la vida, ja que és possible que no se’t dissuadeixi fàcilment ' +
        'dels teus objectius. Aquesta mateixa actitud també pot dificultar de vegades l’empatia amb altres persones que potser no senten ' +
        'una convicció tan forta o tant d’optimisme. És important tornar a recordar que la gent té diferents contextos i ' +
        'vivències. Així doncs,  el que puguis interpretar com a excessiva "necessitat" o "debilitat" per part seva pot ser més aviat un reflex de les ' +
        'dificultats i obstacles que han experimentat a la seva vida, i tot el pensament positiu en el ' +
        'món simplement no pot contribuir a la seva superació, almenys encara no.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': '',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': 'Els nostres contextos i experiències també ens afecten d\'altres maneres, ' +
        'i configuren el grau en què som capaços de tractar amb persones i esdeveniments que són molt diferents d’allò a què nosaltres ' +
        'estem acostumats i estem disposats a fer-ho. Des del punt de vista del BEVI, hi ha dos tipus de persones en aquest sentit. El primer tipus – l’anomenarem' +
        ' "confiat" – se senten segurs d’ells mateixos, de la seva percepció dels altres,  del funcionament del món, ' +
        ' i de les seves experiències vivencials. El segon tipus (l’anomenarem "inquisitiu") poden tenir menys clar aquestes qüestions, ' +
        'tenir dubtes i penediment ocasionalment, així com confusió sobre com poden reaccionar quan s’exposen a situacions que ' +
        'són diferents o desconegudes. <b>A partir de les teves respostes al BEVI, tendeixes a estar al mig, entre confiat i inquisitiu.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': 'Per descomptat, hi ha possibles avantatges i desavantatges ' +
        'en una posició de confiat o inquisitiu, i tots podem sentir-nos de totes dues maneres de tant en tant, depenent de diferents ' +
        'circumstàncies o situacions. En general, però, si ets del tipus "confiat" o segur, pot ser útil prendre més ' +
        'consciència dels moments en què potser no necessàriament saps què has de fer, pensar o sentir, sobretot quan ' +
        'experimentes alguna cosa que sigui diferent d’allò a què estàs acostumat.',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '<b>En relació amb això, sembla que et preguntes si la vida' +
        'és "el que fas" o si la millor estratègia per tractar els problemes és simplement "tenir ' +
        'pensaments positius."</b> En alguns casos, aquests mètodes poden funcionar bé, però és probable que ' +
        'hi hagi una tendència a qüestionar aquest enfocament "positiu" de la vida i la vida en si. Potser un avantatge de pensar que "només ' +
        'tu ets responsable dels teus problemes" és que aquestes persones tenen un sentiment d\'empoderament per afrontar qualsevol problema que se’ls presenti. ' +
        'Tanmateix, un desavantatge d’aquesta visió del món pot ser que aquestes persones no sempre se sentin especialment empàtiques ' +
        'cap a aquelles persones que no semblen tenir la capacitat "de superar cap obstacle". Entre altres preguntes que val la pena fer-se ' +
        '—en relació amb això— és quines són les conseqüències de la creença -o la falta de creença- que "tots som responsables del tipus de vida que portem"?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': 'Els nostres contextos i experiències també ens afecten d\'altres maneres,' +
        'i configuren el grau en què som capaços i estem disposats a tractar amb persones i esdeveniments que són molt diferents d’allò a què ' +
        'estem acostumats i estem disposats a fer-ho. Des del punt de vista del BEVI, hi ha dos tipus de persones en aquest sentit. El primer tipus - ' +
        'l’anomenerem "confident"- se senten segurs d’ells mateixos, de la seva percepció dels altres,  del funcionament del món ' +
        ' i de les seves experiències vivencials. El segon tipus (l’anomenarem "inquisitiu") poden tenir menys clar aquestes qüestions, ' +
        'tenir dubtes i penediment ocasionalment, així com confusió sobre com poden reaccionar quan s’exposen a situacions que ' +
        'són diferents o desconegudes. <b>A partir de les teves respostes al BEVI, tendeixes a ser més inquisitiu que confiat.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': ' Per descomptat, hi ha possibles avantatges i desavantatges ' +
        'en una posició confiat o inquisidor, i tots podem sentir-nos de totes dues formes de tant en tant, depenent de diferents ' +
        ' circumstàncies o situacions. En general, però, si ets del tipus "segur", pot ser útil prendre més ' +
        'consciència dels moments en què potser no necessàriament saps què has de fer, pensar o sentir, sobretot quan ' +
        'experimentes alguna cosa que sigui diferent d’allò a què estàs acostumat <b>En relació amb això, sembla que ets una mica ' +
        'escèptic en general que el món sigui "el que tú fas" o que hauries de "simplement pensar positivament" per superar ' +
        'allò que la vida et presenta.</b> De fet, les persones que sembla que pensen d’aquesta manera poden semblar desagradables o fins i tot irritants de vegades,' +
        'principalment perquè solen ser impacients respecte d’aquells que lluiten per superar-se cada dia. Potser l’avantatge ' +
        'de pensar que "només tu ets el responsable dels teus problemes" és que aquestes persones tenen una sensació d\'empoderament ' +
        'per afrontar qualsevol problema que se’ls presenti. No obstant això, l’inconvenient d’aquesta visió del món és que aquestes persones no sempre tenen ' +
        'empatia cap a aquelles persones que no semblen tenir la capacitat de "superar qualsevol obstacle".' +
        'En el teu cas, pot ser útil reflexionar sobre els moments en què sents o no sents cap empoderament, ' +
        'i per què és així per a tu. Per exemple, què has arribat a creure sobre el “destí” o “la naturalesa humana” ' +
        'o "per què les coses son com son"? Quines d\'aquestes creences et són més útils i menys útils per ' +
        'gestionar els reptes i les possibilitats que se’t plantegen cada dia?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': 'Nosaltres, els humans, som éssers que creem sentit. ' +
        'Pot ser que ni tan sols ens adonem que ho estem fent, però tots busquem explicacions per respondre "per què les coses són com són" ' +
        'i treure’n conclusions causals, encara que no sempre tinguem prou informació per fer-ho. Aquestes explicacions sovint ' +
        'van des de preguntar-nos per què els humans fem el que fem, passant per com els esdeveniments es produeixen com es produeixen en el món en general fins al ' +
        'sentit de la vida o de l’existència. De vegades, ni tan sols som conscients que estem traient conclusions sobre "per què les coses ' +
        'són com són" o com de comuns, automàtics, inconscients i també inevitables són aquests processos ' +
        'de creació de significat en la vida diària. <b>A partir de les teves respostes al BEVI, sembla que  tens una ' +
        'capacitat molt ben desenvolupada per extreure explicacions causals matisades i sofisticades de fenòmens i reflexionar-hi profundament ' +
        'i amb precisió sobre "per què les coses són com són".</b>',
    'app.individualreport.whywedo.lessthan69.text': 'Nosaltres, els humans, som éssers que creem sentit.. ' +
        'Pot ser que ni tan sols ens adonem que ho estem fent, però tots busquem explicacions per respondre "per què les coses són com són" ' +
        'i treure’n conclusions causals, encara que no sempre tinguem prou informació per fer-ho. Aquestes explicacions sovint ' +
        'van des de preguntar-nos per què els humans fem el que fem, passant per com els esdeveniments es produeixen com es produeixen en el món en general fins al ' +
        'sentit de la vida o de l’existència. De vegades, ni tan sols som conscients que estem traient conclusions sobre "per què les coses ' +
        'són com són" o com de comuns, automàtics, inconscients i també inevitables són aquests processos ' +
        'de creació de significat en la vida diària. <b>A partir de les teves respostes al BEVI, sembla que estàs pensant cada vegada més ' +
        ' sobre “per què les coses són com són." </b>',
    'app.individualreport.whywedo.lessthan30.text': 'Nosaltres, els humans, som éssers que creem sentit. ' +
        'Pot ser que ni tan sols ens adonem que ho estem fent, però tots busquem explicacions per respondre "per què les coses són com són" ' +
        'i treure’n conclusions causals, encara que no sempre tinguem prou informació per fer-ho. Aquestes explicacions sovint ' +
        'van des de per què els humans fem el que fem, passant per com els esdeveniments es produeixen com es produeixen en el món en general fins al ' +
        'sentit de la vida o de l’existència. De vegades, ni tan sols som conscients que estem traient conclusions sobre "per què les coses ' +
        'són com són" o com de comuns, automàtics, inconscients i també inevitables són aquests processos ' +
        'de creació de significat en la vida diària. <b>A partir de les teves respostes al BEVI, sembla que et podria beneficiar ' +
        'd’explorar més sobre per què la gent fa el que fa o per què els esdeveniments del món es produeixen d’una manera o altra, abans, durant, ' +
        'i després de qualsevol nova experiència que tinguis, que et plantegi un repte especial.</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': 'Introducció',
    'app.individualreport.title.foundation': 'La base: "Variables formatives" i "Necessitats bàsiques"',
    'app.individualreport.title.tolerance_of_desequilibrium': 'Tolerància al Desequilibri',
    'app.individualreport.title.making_sense': 'Comprenent per què fem allò que fem',
    'app.individualreport.title.access_to_yourself': 'Accés a tu mateix i els teus pensaments, sentiments i necessitats',
    'app.individualreport.title.access_to_the_thoughts': 'Accés als pensaments i sentiments dels altres',
    'app.individualreport.title.access_to_the_larger_world': 'Accés al món global',
    'app.individualreport.title.conclusion': 'En resum',
    'app.individualreport.title.thankyou': 'Moltes gràcies per completar el BEVI.',
    'app.individualreport.title.profile': 'Perfil',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '<b>Probablement has notat que ' +
        'algunes persones solen ser més emocionals i sensibles perquè ' +
        'valoren l’expressió de necessitats i sentiments més profundament que d’altres. Això sembla que és' +
        'una descripció molt bona de tu mateix.</b> Pensa durant un moment com d’importants i centrals són les emocions en ' +
        'l’existència humana. La capacitat de sentir allò que sents, mentre interpretes amb precisió ' +
        'i comprensió els sentiments dels altres –allò que alguns anomenen “intel·ligència emocional” ' +
        '- és clau per navegar per tots els aspectes de la vida, des de les relacions personals fins al món de la feina.' +
        ' Sense emocions, seria molt difícil entendre què ens agrada,  què no, ' +
        'qui som,  qui són els altres i qui volem ser.',
    'app.individualreport.accessself.textB': '<b> Probablement has notat que ' +
        'algunes persones solen ser més emocionals i sensibles perquè ' +
        'valoren l’expressió de necessitats i sentiments més profundament que d’altres. Això sembla que és ' +
        'una descripció molt bona de tu mateix.</b> Pensa durant un moment com d’importants i centrals són les emocions en ' +
        'l’existència humana. La capacitat de sentir allò que sents, mentre interpretes amb precisió ' +
        'i comprensió els sentiments dels altres –allò que alguns anomenen “intel·ligència emocional” ' +
        '- és clau per navegar per tots els aspectes de la vida, des de les relacions personals fins al món de la feina.' +
        ' Sense emocions, seria molt difícil entendre què ens agrada,  què no, ' +
        'qui som,  qui són els altres i qui volem ser.',
    'app.individualreport.accessself.textC': '<b> Probablement has notat que ' +
        'algunes persones solen ser més emocionals i sensibles perquè ' +
        'valoren l’expressió de necessitats i sentiments més profundament que d’altres. Aquesta descripció sembla que és ' +
        'menys descriptiva de tu i la manera en què t’apropes als teus sentiments i als sentiments dels altres, en el sentit que potser tendeixes a desconcertar-te i, fins i tot,' +
        'enrabiar-te de vegades pel que consideres com a manifestacions excessives d’emoció o vulnerabilitat dels ' +
        'altres.</b> Si és el teu cas, et podria resultar útil reflexionar de nou ' +
        'sobre el fet que les nostres experiències a la vida, el bagatge, ens poden fer molt més o molt menys ' +
        'capaços i disposats a “accedir” a pensaments o sentiments profunds. Només tu pots jutjar ' +
        'si les teves experiències i bagatge van ser d’un caràcter que et van desanimar en general a ' +
        'sentir massa profundament o necessitar massa dels altres. Si aquesta va ser la teva experiència, és important ' +
        'considerar l’impacte possible d’aquests processos sobre tu a la teva vida, a la feina, ' +
        'a les relacions amb els altres, i sobre com experimentes la gent, les situacions i les relacions, ' +
        'que poden ser difícils, almenys al principi,  sobretot quan són noves.' +
        ' Pensa durant un moment com d’importants i centrals són les emocions en ' +
        'l’existència humana. La capacitat de sentir allò que sents, mentre interpretes amb precisió ' +
        'i comprensió els sentiments dels altres –allò que alguns anomenen “intel·ligència emocional” ' +
        '- és clau per navegar per tots els aspectes de la vida, des de les relacions personals fins al món de la feina.' +
        ' Sense emocions, seria molt difícil entendre què ens agrada,  què no, ' +
        'qui som, qui són els altres i qui volem ser.',
    'app.individualreport.accessself.textI': '<b>També és possible que hi hagi moments que lluites amb pensaments i sentiments difícils, i evites una “introspecció” excessiva. En general, és possible que et sentis desconcertat o, fins i tot, enrabiat quan altres persones semblen pensar tant sobre “per què són com són”."</b> ' +
        'De nou, és important recordar que tothom ha tingut experiències molt variades i antecedents molt diferents. ' +
        ' Això conforma la manera en què es veuen a ells mateixos, i que la gent que passa molt de temps pensant sobre ' +
        '"per què som com som" sovint ens poden ajudar els altres a entendre’ns millor, a entendre qui som i ' +
        'per què fem el que fem. De la mateixa manera, també és possible preocupar-se per pensaments ' +
        'i sentiments que són molt difícils d’experimentar, comprendre o gestionar; en aquests casos, podria ' +
        'resultar útil parlar amb una persona professional o amb coneixements d’aquesta mena de problemes.',
    'app.individualreport.accessself.textH': '<b> Sembles obert' +
        'al món de pensaments i sentiments, però sembla que et trobes en  “terra de ningú” en comparació de ' +
        'la gent del teu entorn.  Algunes persones sembla que dediquen molt de temps i energia ' +
        'a pensar sobre "per què som de la manera que som" mentre que d’altres sembla que dediquen molt poc a aquestes qüestions, ' +
        ' i poden considerar una mica irritants aquells que s’hi dediquen. En molts aspectes, estàs ben posicionat ' +
        'per ajudar ambdues bandes a entendre les tendències de l’altre, és dir, ' +
        'aquells que sembla que senten “moltíssim” versus aquells que sembla que eviten els sentiments completament.</b>',
    'app.individualreport.accessself.textG': '<b> També pots ser molt obert ' +
        'als pensaments i sentiments difícils, ser introspectiu en general i ser conscient de com ' +
        'penses i sents. A vegades, et pot desconcertar que altres persones no sembla que tinguin aquesta mateixa capacitat, ' +
        'la qual cosa et pot deixar sentint sol o aïllat. Per a les persones com tu, és important ' +
        'cercar altres “esperits afins” que poden comprendre i donar valor a l’expressió de necessitats i sentiments, ' +
        'i a qui els agrada pensar sobre “per què som com som”. És clar que necessitem persones com tu al món, amb la capacitat de pensar i sentir tan profundament.</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {

    'app.individualreport.accessothers.textA': 'Des de petit, sovint aprenem a evitar parlar de ' +
        'qüestions concretes com són la religió, la política, els papers dels homes i dones, o qüestions de raça o ' +
        'etnicitat perquè són temes que es poden tornar calents quan ens trobem amb algú que pensa d’una manera molt diferent ' +
        'de la nostra. Però, des del punt de vista del BEVI, no és tant que no podem o ' +
        'no hem de discutir sobre aquestes qüestions; de fet, és imprescindible per al discurs saludable en una societat civil ' +
        'que ho fem. El més important de tot ésentendre per què algunes qüestions són “calentes” ' +
        'per nosaltres i els altres. Així que aquí explorem com sembla que veus qüestions de religió, gènere, ' +
        'política i raça, a més de les implicacions potencials que aquestes creences i valors poden tenir per a les teves' +
        'relacions amb altres persones que poden pensar d’una manera semblant o diferent de la teva. <br /><br />' +
        '<b>En primer lloc, sembla que tendeixes a tenir fortes creences o ' +
        'conviccions religioses i pots experimentar la teva fe com una part fonamental de la manera que crees ' +
        ' el sentit i propòsit de les coses. Pot ser meravellós tenir un sentiment de fe tan profund. També és important ' +
        'entendre que alguns individus, grups, religions o cultures poden tenir creences molt ' +
        'diferents de les teves sobre aquestes qüestions tan fonamentals.</b> A vegades, tenim molt clar ' +
        'per què creiem el que creiem sobre temes religiosos. En altres moments, simplement hem ' +
        'acceptat el que ens han ensenyat, sigui el que sigui, sense reflexionar realment sobre per què creiem  ' +
        'el que creiem. El que volem suggerir aquí no és que unes creences són correctes i unes altres errònies,  ' +
        'sinó més aviat que tots arribem a creure alguna cosa sobre aquestes qüestions tan fonamentals que ens afecten a ' +
        'tots (p.e., fins i tot el fet de no creure és una creença que no hi ha cap sentit –o realitat– en cap creença o ' +
        ' sistema de creences específic). Des que qüestions de creença religiosa són tan bàsiques per com les experimentem ' +
        '  nosaltres mateixos, els altres, el món global, és molt important que ens esforcem per entendre ' +
        'la naturalesa i els orígens de les nostres creences i valors sobre la religió. No menys important és això: ja que ' +
        'les creences religioses sovint són resultat de la nostra educació, cultura i context únics, pot ser ' +
        'possible que siguem més comprensius amb el com i el perquè altres persones poden ' +
        'creure d’una manera similar o diferent de nosaltres. Acostant-nos a creences i valors des de la perspectiva ' +
        ' d’una curiositat i atenció genuïnes, podem arribar a aprendre més sobre per què som com som,' +
        'mentre ajudem  altres fer el mateix.',

    'app.individualreport.accessothers.textB': ' Des de petit, sovint aprenem a evitar parlar de ' +
        'qüestions concretes com són la religió, la política, els papers dels homes i dones, o qüestions de raça o ' +
        'etnicitat perquè són temes que es poden tornar calents quan ens trobem amb algú que pensa d’una manera molt diferent ' +
        'de la nostra.Però, des del punt de vista del BEVI, no és tant que no podem o ' +
        'no hem de discutir sobre aquestes qüestions; de fet, és imprescindible per al discurs saludable en una societat civil ' +
        'que ho fem. El més important de tot ésentendre per què algunes qüestions són “calentes” ' +
        'per nosaltres i els altres. Així que aquí explorem com sembla que veus qüestions de religió, gènere, ' +
        'política i raça, a més de les implicacions potencials que aquestes creences i valors poden tenir per a les teves' +
        'relacions amb altres persones que poden pensar d’una manera semblant o diferent de la teva. ' +
        '<br /><br />' +
        '<b>En primer lloc, sembla que tendeixes a tenir creences ' +
        ' religioses moderades. És possible que et sentis molt còmode en aquests moments amb les teves creences en ' +
        '"qüestions de fe." No obstant això, també és possible que qüestionis la profunditat i ' +
        'intensitat de les teves creences religioses, siguin les que siguin. També pots preguntar-te pels altres ' +
        'que sembla que senten d’una manera molt forta en un sentit o en un altre (p.e., es descriuen com molt  ' +
        'forts en les seves creences o molt forts en contra de la religió).</b> A vegades tenim molt clar ' +
        'per què tenim les creences que tenim sobre qüestions religioses. En altres moments, simplement hem ' +
        'acceptat allò que ens han ensenyat, sigui el que sigui, sense realment reflexionar sobre per què creiem ' +
        'el que creiem. El punt important aquí no és que un conjunt de creences sigui correcte i un altre incorrecte, ' +
        'sinó, més aviat, que tots hem arribat a creure alguna cosa sobre aquestes qüestions fonamentals que ens afecten ' +
        'a tots (p.e., fins i tot no creure és una creença que no hi ha cap sentit –ni realitat– en una creença ' +
        ' o sistema de creences concretes). Com que les qüestions de creença religiosa són tan bàsiques per com les experimentem,  nosaltres mateixos, els altres i el món global, és molt important que ens esforcem per entendre ' +
        'la naturalesa i els orígens de les nostres creences i valors sobre la religió. No menys important és això: ja que ' +
        'les creences religioses sovint són resultat de la nostra educació, cultura i context únics, pot ser ' +
        'possible que siguem més comprensius amb el com i el perquè altres persones poden ' +
        'creure d’una manera similar o diferent de nosaltres. Acostant-nos a creences i valors des de la perspectiva ' +
        ' d’una curiositat i atenció genuïna, podem arribar a aprendre més sobre per què som com som,' +
        'mentre ajudem  altres fer el mateix .',
    'app.individualreport.accessothers.textC': ' Des de petit, sovint aprenem a evitar parlar de ' +
        'qüestions concretes com són la religió, la política, els papers dels homes i dones, o qüestions de raça o ' +
        'etnicitat perquè són temes que es poden tornar calents quan ens trobem amb algú que creu d’una manera molt diferent ' +
        'de la nostra.Però, des del punt de vista del BEVI, no és tant un tema que no podem o ' +
        'no hem discutir; de fet, és imprescindible per al discurs saludable en una societat civil ' +
        'que ho fem. El més important de tot ésentendre per què algunes qüestions són “calentes” ' +
        'per nosaltres i els altres. Així que aquí explorem com sembla que veus qüestions de religió, gènere, ' +
        'política i raça, a més de les implicacions potencials que aquestes creences i valors poden tenir per a les teves' +
        'relacions amb altres persones que poden pensar d’una manera semblant o diferent de la teva. <br /><br />' +
        '<b>En primer lloc, sembla que no tendeixes a tenir ' +
        'creences o conviccions religioses molt fortes, i fins i tot pots ser escèptic quant als sistemes religiosos ' +
        'o els creients religiosos. Pensar bé sobre qüestions de creença religiosa, o no acceptar ' +
        'aquestes qüestions només a base de “fe”, pot resultar ser una manera de pensar crítica sobre creences i valors que' +
        'massa sovint s’accepten sense un escrutini suficient. Sigui quin sigui el resultat d’aquest procés,  ' +
        'et podria resultar útil reflexionar sobre el fet que un conjunt de conviccions fortes' +
        '(p.e., conviccions profundes sobre la validesa de les teves pròpies creences) es pot substituir ' +
        ' per un altre conjunt de conviccions (p.e., conviccions profundes sobre la validesa de l’absència o negació de creences en un mateix) ' +
        'sense un augment corresponent de la comprensió dels orígens i finalitat que tenen les creences ' +
        ' i valors per a tots nosaltres.</b> A vegades tenim molt clar ' +
        'per què tenim les creences que tenim sobre qüestions religioses. En altres moments, simplement hem ' +
        'acceptat allò que ens han ensenyat, sigui el que sigui, sense realment reflexionar sobre per què creiem ' +
        'el que creiem. El punt important aquí no és que un conjunt de creences sigui correcte i un altre incorrecte, ' +
        'sinó, més aviat, que tots hem arribat a creure alguna cosa sobre aquestes qüestions fonamentals que ens afecten ' +
        'a tots (p.e., fins i tot no creure és una creença que no hi ha cap sentit –ni realita – en una creença ' +
        ' o sistema de creences concretes). Com que les qüestions de creença religiosa són tan bàsiques per com les experimentem nosaltres mateixos, els altre, i el món global, és molt important que ens esforcem per entendre ' +
        'la naturalesa i orígens de les nostres creences i valors sobre la religió. No menys important és això: ja que ' +
        'les creences religioses sovint són resultat de la nostra educació, cultura i context únics, pot ser ' +
        'possible que siguem més comprensius amb el com i el perquè altres persones poden ' +
        'creure d’una manera similar o diferent de nosaltres. Acostant-nos a creences i valors des de la perspectiva ' +
        ' d’una curiositat i atenció genuïnes, podem arribar a aprendre més sobre per què som com som,' +
        'mentre ajudem  altres fer el mateix. ',
}


const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '<b>En segon lloc, en relació amb la qüestió de gènere, ' +
        'tendeixes a tenir creences i valors més tradicionals pel que fa als homes i dones, qui son i com haurien de ser; ' +
        ' potser també penses que els homes i les dones simplement “estan construïts ' +
        'per ser d’una certa manera," i seria millor per a tots si entenguéssim i acceptéssim aquest simple fet. ' +
        'Tots sabem que hi ha diferències entre els homes i les dones. Però, per començar, la qüestió és  ' +
        'per què existeixen diferències? De les teves respostes, veiem que tendeixes a creure que ' +
        'les diferències de gènere es deuen en gran manera a factors biològics subjacents.</b> Encara que estudis acadèmics ' +
        'sobre aquest tema revelen una gran complexitat, és important saber que s’han utilitzat “diferències” reals i falses ' +
        ' entre homes i dones com a manera de negar a les nenes i dones molts drets al llarg dels anys; en resum, ' +
        's’han emprat ”arguments biològics” per  justificar la negació dels drets de les dones' +
        'perquè no estan “destinades” a viure, treballar o a ser al món dels homes, com a iguals. Els estereotips ' +
        'poden funcionar en ambdós sentits, però, ja que els nens i els homes es poden veure com a “menys capacitats” per ' +
        'donar una atenció afectuosa o expressar emocions, això significa que potser a ells se’ls nega no només el “dret” de tenir sentiments i experimentar ' +
        ' necessitats, en ells mateixos i en els altres. Aquestes “lliçons” poden impactar en tots nosaltres:  els homes aprenen que ' +
        '"han de ser durs" i després arriben a actuar d’una manera agressiva i indiferent als altres. ' +
        'Als homes també se’ls pot negar igualtat quant a la criança dels fills, per exemple, o en altres papers ' +
        'que s’han assignat històricament a les nenes i les dones. En reflexionar sobre aquests temes, és' +
        'important comprendre com els arguments que es basen en diferències biològiques suposades poden servir per ' +
        'limitar les oportunitats i maneres de ser tant per a les dones com per als homes, amb conseqüències ' +
        'per a tots nosaltres.',
    'app.individualreport.gendertradition.textB': '<b>En segon lloc , en termes de gènere,' +
        'tendeixes a tenir creences i valors equilibrats en relació amb el tema d’homes i dones, qui són i com ' +
        'haurien de ser; no et convenç l’argument que els homes i les dones “simplement estan construïts de certa manera”.' +
        'Potser reconeixes que hi ha diferències entre homes i dones, però també veus que la qüestió més ' +
        'important és per què hi ha aquestes diferències?</b> Per exemple, aquestes diferències ' +
        'es deuen a “l’entorn” o a “la genètica” o un combinat de totes dues coses? ' +
        'Hi ha mostres per als dos arguments, els quals suggereixen que la diferència entre homes i dones es deu' +
        'a una interacció complexa entre processos culturals i educatius a més de diferències biològiques subjacents ' +
        ' És important reconèixer que s’han utilitzat les explicacions ' +
        ' per a aquestes diferències per negar a les nenes i les dones molts drets al llarg dels anys, des de l’educació fins al ' +
        'vot, per tenir el control sobre la seva sexualitat. No obstant això, els estereotips poden funcionar en ambdós sentits. Per  ' +
        'exemple, els homes poden semblar menys capacitats per donar una atenció afectiva i expressar els sentiments, i així menys capaços d’assumir ' +
        ' la criança. En reflexionar sobre aquestes qüestions, és important llegir i aprendre més sobre  ' +
        'el que sabem –i no sabem– de les diferències entre gèneres, sobretot perquè molts estudiosos ' +
        'sostenen que dones i homes són molt més similars que diferents com a éssers humans.',
    'app.individualreport.gendertradition.textC': '<b>En segon lloc, en termes de gènere, ' +
        'tendeixes a tenir creences i valors més progressistes o liberals sobre qüestions com qui són homes i dones, ' +
        'i com haurien de ser; probablement, no estàs d’acord que homes i dones simplement “estan construïts per ser d’una ' +
        'certa manera."  Reconeixes que hi ha diferències entre homes i dones; tots sabem i Y ' +
        'veiem l’evidència d’aquest fet en la vida diària. Però la qüestió és per què existeixen aquestes diferències? ' +
        ' Per exemple, aquestes diferències es deuen a ”l’entorn” " ' +
        ' o a “la genètica” o un combinat de totes dues?  Basant-nos en les teves respostes, tendeixes a creure que ' +
        'diferències de gènere probablement es deuen més a factors subjacents de la cultura o de l’entorn.</b> ' +
        'Encara que els estudis acadèmics sobre aquest tema revelen una gran complexitat, hi ha proves en totes dues bandes, ' +
        'la qual cosa suggereix que les diferències entre homes i dones es deuen a una interacció complexa ' +
        ' entre processos culturals i educatius, a més de diferències biològiques subjacents. Al mateix ' +
        'temps, però, s’han utilitzat aquestes diferències com a via per negar a les nenes i les dones molts ' +
        'drets al llarg dels anys, des de l’educació fins al vot, per controlar la seva sexualitat, i ' +
        'sovint s’han emprat "arguments biològics” per justificar la negació d’aquests drets a les dones. ' +
        ' Els estereotips poden funcionar en ambdós sentits, ja que els nens i homes ' +
        ' poden semblar “menys capacitats” per donar una atenció afectiva i expressar emocions, la qual cosa pot significar la negació no només del “dret” de tenir sentiments ' +
        'i experimentar necessitats, en ells mateixos i en altres, sinó també de la igualtat quan considerem ' +
        'la criança, per exemple, i altres papers històricament assignats a les nenes i ' +
        'les dones. Reflexionant sobre aquestes qüestions, és important comprendre que s’han utilitzat arguments ' +
        ' basats en una suposada diferència biològica per  limitar les oportunitats i maneres de ser ' +
        ' per a dones i homes, amb conseqüències per a tots nosaltres, tant per a l’individu com per a la societat. ' +
        'Per tant, és important llegir i aprendre més sobre allò que sabem –i no sabem– sobre diferències de ' +
        'gènere, ja que molts acadèmics pensen que els éssers humans, homes i dones, són més similars ' +
        ' que diferents.',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '<b>En tercer lloc, en relació amb qüestions de ' +
        ' cultura i política, és molt més probable que se’t descrigui com a acceptador, obert, liberal i progressista. ' +
        'Aquestes maneres de pensar i de ser poden ser meravelloses, ja que trobar-te ' +
        'amb idees, perspectives, circumstàncies, gent o societats que són noves o diferents és una experiència que probablement reps amb molt d’entusiasme, ' +
        'i sents una il·lusió especial quan ho fas. També pots intentar promoure un canvi en la teva vida ' +
        'i en la feina, o en la societat en general, i sents una certa irritació amb altres persones que no comparteixen aquestes  ' +
        'conviccions. </b> A més, és important comprendre per què els altres poden no pensar o sentir de la ' +
        'mateixa manera com tu. Per exemple, diferents sistemes, cultures o maneres de ser poden ser una ' +
        'font d’ansietat per a algunes persones, ja que poden experimentar les creences i valors ' +
        ' d’aquests sistemes, cultures o maneres de ser com un desafiament a la seva visió del món. Si creus de forma diferent,  ' +
        ' hi pot haver una temptació de no fer cas d’individus que se senten incòmodes en aquestes ' +
        'situacions, i considerar-los de mentalitat tancada o ximples.  En lloc d’això, intenta aprendre més dels antecedents  ' +
        'i experiències dels individus que pensen i senten d’una manera tan diferent de tu. Potser són més ' +
        'oberts del que semblen, però estan lluitant per trobar la manera de treure el sentit d’unes creences i ' +
        'valors tan diferents de tot allò a què estan acostumats. En una trobada com aquesta,' +
        'hi pot haver la possibilitat de comprendre millor per què ells, i tots nosaltres, arribem a creure i valorar allò ' +
        'que creiem i valorem en primer lloc. ',
    'app.individualreport.variables.textE_2': '',
    'app.individualreport.variables.textF': '<b>En tercer lloc, en relació amb qüestions de cultura ' +
        ' i política, és molt probable que se’t descrigui com més “moderat” o “al mig del camí” que ' +
        'alguns dels teus companys, que poden semblar una mica –o fins i tot molt– més progressistes o conservadors que tu. ' +
        'Aquesta postura té avantatges, ja que pots inclinar-te al compromís, o  veure' +
        'valor en perspectives que, entre si, estan en desacord. D’altra banda, aquesta postura també pot indicar  ' +
        'que no has examinat aquestes qüestions en profunditat encara,  que sents una indiferència relativa o simplement ' +
        'que encara no saps què és el que creus en aquest sentit.</b> Fins al grau en què aquesta perspectiva ' +
        'correspon a on et trobes en aquests moments, podria ser útil reflexionar a fons i sovint sobre ' +
        'per què creus el que creus sobre temes de cultura, política, raça, o gènere. Trobar ' +
        'les maneres de parlar-ne amb els amics i companys que semblen més abrandats que tu quan expliquen ' +
        ' per què creuen el que creuen no només pot resultar interessant, sinó també et pot ajudar a aclarir més a fons la base  ' +
        ' de la teva visió del món. A més a més, pots trobar que la teva perspectiva representa una oportunitat  ' +
        'interessant per a aquells que estan més “convençuts” que tu, per reflexionar sobre les seves raons ' +
        ' per unes conviccions tan fortes. De qualsevol manera, probablement aquestes trobades entre tu mateix i altres persones podran aclarir ' +
        'allò que creus i valores en relació amb aquestes qüestions tan crucials que ens afecten a tots. ',
    'app.individualreport.variables.textG_1': '<b>En tercer lloc, en relació amb qüestions ' +
        'cultura i política, és possible que la gent et descriu com a més tradicional, curós, prudent ' +
        ' que els teus companys, pel que fa a la necessitat o valor del canvi ràpid, tant localment com globalment. Pots ' +
        'tenir la sensació que maneres de funcionar que s’han mostrat provades en el temps ja es qüestionen o fins i tot reculen, sense' +
        'consideració suficient de les conseqüències d’aquests canvis (p.e., per a la família, a la feina, ' +
        ' o a la societat en general) del cost econòmic dels plans socials corresponents. És possible que sentis ' +
        ' irritació quan altres persones no comparteixen les mateixes conviccions i poden semblar ingènues o ' +
        'irresponsables en els seus esforços per promoure les seves idees.</b> De nou, és important comprendre per què els altres ' +
        'poden no pensar o sentir de la mateixa manera que tu. Per exemple, altres poden considerar que ' +
        'les maneres de creure i de ser "tradicionals" no són res més que maneres de controlar aquells que tenen menys poder, ' +
        'recursos o drets. Pensar així, per a ells, es basa en la seva història vital o en ' +
        'circumstàncies que ells, o altres que coneguin, han viscut. Si penses d’una altra manera, ' +
        ' hi pot haver una temptació de no fer cas d’aquests individus i considerar-los “queixosos” o “ximples”. ' +
        'En lloc d’això, intenta aprendre d’individus que pensen i ' +
        'senten les coses d’una manera diferent de tu. Poden estar més oberts a les teves creences del que et penses ' +
        'i disposats a obrir un debat amb tu, sempre que sigui profund i honest –i dut a terme d’una manera respectuosa i ' +
        'de bona fe. Hi poden estar molt més disposats del que imagines. Potser, fins i tot, poden sentir-se força convençuts per la saviesa del ' +
        'teu punt de vista i que la rellevància de la seva perspectiva et pugui resultar persuasiva. Al final, aquestes ' +
        'trobades amb “l’altre” poden fomentar la bona voluntat i un respecte mutu, mentre que ens ajuden a tots a ' +
        ' comprendre millor per què ells, i tots nosaltres, arribem a tenir les creences i valors que tenim ' +
        '.',
    'app.individualreport.variables.textG_2': '',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>Pel que fa a actituds ' +
        'relacionades amb el medi ambient, sembla que tens una profunda afinitat i connexió amb la Terra, amb una tendència de veure ' +
        'el nostre planeta com la nostra única casa, que s’ha de protegir i conservar. Així que t’ha de causar frustració ' +
        'veure que hi ha tants altres que no semblen compartir aquestes conviccions, sobretot quan l’evidència ' +
        'sembla tan clara que hem millorar els nostres esforços per  cuidar el món. b> ' +
        'Reflexionant sobre aquests temes, és important recordar que grups, cultures i  ' +
        'religions diferents poden veure la relació entre els éssers humans, la natura i la Terra ' +
        'de maneres diferents per raons diverses que els semblen completament legítimes. És important considerar ' +
        'la base d’aquestes diferències entre nosaltres i per què tenim tendència de sentir aquestes qüestions tan profundament, ' +
        ' d’una manera o d’una altra. És perquè les nostres creences i valors ecològics –i la manera en què individus, ' +
        'organitzacions, governs i societats experimenten, comprenen i es relacionen amb la natura i el món natural- ' +
        'tenen profundes implicacions per a les accions, polítiques i pràctiques mediambientals arreu del món. ' +
        ' En la mesura que puguis, intenta iniciar un debat respectuós i obert amb els altres ' +
        'sobre aquestes qüestions, no només apel·lant als fets tal com els entens. Dit d’una altra manera, ' +
        'comparteix les teves conviccions no només des d’un punt de vist intel·lectual –que és importantíssim, és clar-  ' +
        ' sinó també en termes de per què ho sents així. Aquesta manera d’ acostar-t’hi té més probabilitat de promoure' +
        'una reflexió genuïna sobre qüestions del medi ambient, les quals poden semblar abstractes, però en realitat estan intensament ' +
        'carregades de valors, ja que resideixen al “cor” de per què creiem que som aquí i quines són –o no- les ' +
        'nostres obligacions al medi.',
    'app.individualreport.ecologicalresonance.textB': '<b> Pel que fa a actituds ' +
        ' relacionades amb el medi ambient, sembla que estàs obert als arguments sobre la necessitat de protegir els recursos naturals de ' +
        'la Terra, i probablement et descriuries com una persona “moderada” quant a les qüestions de l’entorn natural en general. ' +
        'Així que és possible que et sorprengui la intensitat de les persones amb fortes creences –en un sentit i en l’altre–' +
        ' sobre aquestes qüestions.</b> Aquestes diferències t’ofereixen l’oportunitat d’aprendre ' +
        ' sobre les teves creences en relació amb aquest tema i, potser, d’aclarir quina és la base de les teves conviccions ' +
        '(o de la falta de conviccions) sobre aquestes qüestions importants. Reflexionant-hi, és important recordar ' +
        'que diferents grups, cultures i religions poden veure la relació entre els éssers  ' +
        'humans, la natura i la Terra en termes diferents i per raons diferents que els semblen completament' +
        'legítims. Pot resultar útil considerar la base d’aquestes diferències entre nosaltres' +
        'i per què tendim a sentir tan profundament aquestes qüestions, en un sentit o un altre. Això és perquè ' +
        'les nostres creences i valors ecològics –i la manera en què individus, ' +
        'organitzacions, governs i societats experimenten, comprenen i es relacionen amb la natura i el món natural- ' +
        'tenen profundes implicacions per a les accions, polítique, i pràctiques mediambientals arreu del món. En la mesura que puguis, intenta iniciar un debat respectuós i obert amb els altres ' +
        'sobre aquestes qüestions, no només apel·lant als “fets” tal com els entens. Dit d’una altra manera, ' +
        'comparteix les teves conviccions no només d’un punt de vist intel·lectual –que és importantíssim, és clar -  ' +
        ' sinó també en termes de per què ho sents així. Aquesta manera d’acostar-t’hi té més probabilitat de promoure' +
        'una reflexió genuïna sobre qüestions del medi ambient, les quals poden semblar abstractes, però en realitat estan intensament ' +
        'carregades de valors, ja que resideixen al “cor” de per què creiem que som aquí i quines són –o no- les ' +
        'nostres obligacions al medi.',
    'app.individualreport.ecologicalresonance.textC': '<b> Pel que fa a actituds ' +
        'relacionades amb el medi ambient, sembla que ets escèptic en general respecte a aquells que diuen que tenen una profunda afinitat per la ' +
        'connexió amb la Terra i per aquells que expressen preocupació per l’estat del medi ' +
        '. Si tens tendència a ser escèptic sobre reclamacions mediambientals, podria ser una bona idea trobar ' +
        'punts de vista que s’esforcen per ser imparcials i veure què és el que la ciència diu ara ' +
        'sobre aquestes qüestions de summa importància, i a la vegada, tan extraordinàriament complexes. També val la pena notar que  ' +
        'molts grups diferents arreu del món –incloent-hi organitzacions religioses o basades en una fe' +
        '- reconeixen cada vegada més la importància de protegir la Terra i el medi ambient.</b> ' +
        ' Reflexionant sobre aquests temes, és important recordar que grups, cultures i  ' +
        'religions diferents poden veure la relació entre els éssers humans, la natura i la Terra ' +
        'de maneres diferents per raons diverses que els semblen completament legítimes. És important considerar ' +
        'la base d’aquestes diferències entre nosaltres i per què tenim la tendència de sentir aquestes qüestions tan profundament, ' +
        ' d’una manera o d’una altra. És perquè les nostres creences i valors ecològics –i la manera en què individus, ' +
        'organitzacions, governs i societats experimenten, comprenen i es relacionen amb la natura i el medi' +
        ' – tenen profundes implicacions per a les accions, polítiques i pràctiques mediambientals arreu del món. ' +
        ' En la mesura que puguis, intenta iniciar un debat respectuós i obert amb els altres ' +
        'sobre aquestes qüestions, no només apel·lant als fets tal com els entens. Dit d’una altra manera, ' +
        'comparteix les teves conviccions no només d’un punt de vist intel·lectual –que és importantíssim, és clar-  ' +
        ' sinó també en termes de per què ho sents així. Aquesta manera d’acostar-t’hi té més probabilitat de promoure' +
        'una reflexió genuïna sobre qüestions del medi ambient, que poden semblar abstractes, però en realitat estan intensament ' +
        'carregades de valors, ja que resideixen al “cor” de per què creiem que som aquí i quines són –o no- les ' +
        'nostres obligacions amb el medi.',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': 'L’última qüestió, de “panorama general”, diguem-ne, està relacionada amb la teva manera d’enfocar ' +
        'la implicació o ressonància global, en el sentit més ampli. <b>En general, ' +
        'sembla que avales amb força la necessitat de la comprensió i relacions respectuoses cap a ' +
        'cultures, societats i tradicions que són diferents de les nostres. Aquesta visió del món ens indica' +
        'que és probable que aprofitaràs oportunitats i experiències interculturals i globals quan sigui ' +
        'possible.</b> Sigui quina sigui la teva postura sobre aquest tema, és important reflexionar sobre el fet que ' +
        'el món té cada vegada més interconnectivitat. Per exemple, esdeveniments en un país (p.e., ' +
        'econòmics, polítics, mediambientals) poden impactar en les circumstàncies d’un altre, encara que la gran majoria ' +
        'de la gent tinguin poc o cap contacte directe els uns amb els altres. A més, ' +
        'a través de la tecnologia, podem assabentar-nos dels esdeveniments mundials el moment que estan passant i influir-hi. ' +
        'D’aquesta manera, les nostres creences i valors sobre els altres (p.e., cultures o religions diferents) estan lligats ' +
        'inevitablement a com experimentem i lluitem amb nosaltres mateixos i els uns amb els altres –i també com ' +
        'comprenem i responem als diversos reptes i oportunitats arreu del món-, la qual cosa té ' +
        'implicacions per a tots nosaltres.',
    'app.individualreport.globalengagement.textB': 'L’última qüestió, de “panorama general”, diguem-ne, està relacionada amb la teva manera d’enfocar  ' +
        ' la implicació o ressonància global, en el sentit més ampli. <b>En general, ' +
        'sembla que aprecies la necessitat de la comprensió i les relacions respectuoses amb cultures, ' +
        'societats i tradicions que són diferents de les nostres. Aquesta visió del món ens indica que ' +
        'tindràs curiositat per les oportunitats interculturals i globals, quan es presentin.</b>' +
        ' Sigui quina sigui la teva postura sobre aquest tema, és important reflexionar sobre el fet que ' +
        'el món té cada vegada més interconnectivitat. Per exemple, esdeveniments en un país (p.e., ' +
        'econòmics, polítics, mediambientals) poden impactar en les circumstàncies d’un altre, encara que la gran majoria ' +
        'de la gent tinguin poc o cap contacte directe els uns amb els altres. A més, ' +
        'a través de la tecnologia, podem assabentar-nos dels esdeveniments mundials el moment que estan passant i influir-hi. ' +
        'D’aquesta manera, les nostres creences i valors sobre els altres (p.e., cultures o religions diferents) estan lligats ' +
        'inevitablement a com experimentem i lluitem amb nosaltres mateixos i els uns amb els altres –i també com ' +
        'comprenem i responem als diversos reptes i oportunitats arreu del món-, la qual cosa té' +
        'implicacions per a tots nosaltres.',
    'app.individualreport.globalengagement.textC': ' L’última qüestió, de “panorama general”, diguem-ne, està relacionada amb la teva manera d’enfocar  ' +
        ' la implicació o ressonància global, en el sentit més ampli.' +
        '<b>En general, sembla que ' +
        'qüestiones si, a vegades (o, fins i tot, sovint), si anem massa lluny en els nostres intents d’interaccionar, , ' +
        ' tolerar o apaivagar cultures, societats i tradicions que són diferents de les nostres.</b> ' +
        'Sigui quina sigui la teva postura sobre aquest tema, és important reflexionar sobre el fet que ' +
        'el món té cada vegada més interconnectivitat. Per exemple, esdeveniments en un país (p.e., ' +
        'econòmics, polítics, mediambientals) poden impactar en les circumstàncies d’un altre país, encara que la gran majoria ' +
        'de la gent tinguin poc o cap contacte directe els uns amb els altres. A més, ' +
        'a través de la tecnologia, podem assabentar-nos dels esdeveniments mundials al moment que estan passant i influir-hi. ' +
        'D’aquesta manera, les nostres creences i valors sobre els altres (p.e., cultures o religions diferents) estan lligats ' +
        'inevitablement a com experimentem i lluitem amb nosaltres mateixos i els uns amb els altres –i també com ' +
        'comprenem i responem als diversos reptes i oportunitats arreu del món-, la qual cosa té ' +
        'implicacions per a tots nosaltres.',
}
const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': 'baix',
    'app.individualreport.negativelifeevents.moderate': 'moderat',
    'app.individualreport.negativelifeevents.high': 'alt',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': 'ben satisfets',
    'app.individualreport.needsclosure.met_at_times': 'satisfets a vegades però no de manera consistent',
    'app.individualreport.needsclosure.not_met_well': 'no ben satisfets',
}

export const individualReportPageCA: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle, ...individualReportVariables,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};

