export const colors = {
  black: '#000000',
  darkSlateBlue: '#1a4f7b',
  white: '#FFFFFF',
  orange: '#ffa500',
  green: '#30C276',
  gray: 'rgb(235, 237, 240)',
  lightGray: '#fafafa',
  strongGray: '#bdbdbd',
  darkGray: 'rgb(64,64,64)',
  light: '#f8f9fa',
  red: '#f42941',
  lightBlue: 'rgb(112,161,209)',
  decileLess5: '#F8F8FC',
  decileLess10: '#EDEDF7',
  decileLess15: '#DFDFF1',
  decileLess25: '#AFAFBF',
  decileRest: '#83838F',
};
