import styled from 'styled-components'

export const DropdownAntdStyled = styled.div`
    .form-dropdown-antd.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #2684ff;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        display: flex;
        align-items: center;
    }

    input {
        height: 22px;
    }

    .ant-select-arrow {
        right: 12px
        font-size: 14px;
        top: 48%;
    }

    .ant-select-clear {
        right: 12px
        font-size: 14px;
        top: 39%;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 0px;
        height: 22px;
        margin-right: 22px;
        display: flex;
        align-items: center;
        border-right: 1px solid hsl(0deg 0% 80%);
    }

    .form-dropdown-antd.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #d9d9d9;
    }

    .form-dropdown-antd {
        height: 38px;
        width: 100%;
        border-radius: 4px;

        

        .ant-select-selector {
            height: 38px;
            display: flex;
            align-items: center;
            border-radius: 4px;
        }

        .ant-select-selection-placeholder {
            color: hsl(0,0%,50%);
        }
    }
`
