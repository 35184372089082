import { LoginPageMessages } from '../types';

export const loginPageEN: LoginPageMessages = {
    'app.login.text_welcome': 'Welcome to the Beliefs, Events, and Values Inventory (BEVI)',
    'app.login.text_information': `To access this site, you must have been invited to do so by a BEVI-licensed institution, organization, or administrator.`,
    'app.login.text_login_requirement': `Please provide the required username and password (supplied to you).`,
    'app.login.lbl_username': 'Username:',
    'app.login.lbl_password': 'Password:',
    'app.login.btn_submit': 'Logon',
    'app.login.lbl_new_password': 'New Password',
    'app.login.lbl_new_password_confirmation': 'Re-enter Password',
    'app.login.btn_submit_new_password': 'Reset Password',
    'app.login.placeholder.login': 'email@example.com',
    'app.login.placeholder.password': 'your password',
    'app.login.invalid_credentials': 'Oh no! Your credentials don\'t seem to match our records. Please, try again or reset your account by clicking on Forgot Password below.',
    'app.login.forgot_password.invalid_credentials': 'Email is not valid. Please, try again.',
    'app.login.forgot_password': 'Forgot Password?',
    'app.login.forgot_password_confirmation_title': 'Password reset',
    'app.login.forgot_password_confirmation_body': 'An email has been sent to the supplied email address. Follow the instructions in the email to reset your password.',
    'app.login.btn_submit_forgot_password': 'Recover password',
    'app.login.passwords_dont_match': 'Passwords don\'t match.',
    'app.login.change_password.expired_confirmation_title': 'Expired page',
    'app.login.change_password.expired_confirmation_body': 'Your permission to access the page has expired. Please request access again or log in to access BEVI.',
    'app.login.change_password.success_confirmation_title': 'Password updated',
    'app.login.change_password.success_confirmation_body': 'Your password has been changed successfully. Use your new password to log in on the home page, where you will be redirected.',
    'app.login.first_access.title': 'Please, change your password before using the system.',
}
