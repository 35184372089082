import { ListReportMessages } from '../types';

export const listReportEN: ListReportMessages = {
    'app.listreport.report_area': 'Report Management',
    'app.listreport.new': 'New Report',
    'app.listreport.new_group': 'New Report Group',
    'app.listreport.add_group': 'Add Another Group',
    'app.listreport.please_add_group_name': 'Please add group name',
    'app.listreport.search': 'Search',
    'app.listreport.name': 'Name',
    'app.listreport.report_type': 'Type',
    'app.listreport.created_on': 'Created on',
    'app.listreport.confirm_deletion_header': 'Confirm deletion',
    'app.listreport.confirm_deletion_body': 'Are you sure you want to proceed with the report deletion process. If you proceed, this report will be removed from our database permanently.',
    'app.listorganizations.view': 'View',
    'app.listorganizations.edit': 'Edit',
    'app.listorganizations.delete': 'Delete',
    'app.listreport.report_status': 'Report Status',
    'app.listreport.report_status_pending_approval': 'Pending Participant Approval',
    'app.listreport.report_status_completed': 'Available',
}
