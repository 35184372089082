import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsZHCN: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': '關於您這次參加BEVI考評的課程項目，請回答以下三個問題。',
    'app.discursive_questions.question1': '1.什麼事情或哪方面對您影響最大？為什麼？',
    'app.discursive_questions.question2': '2.這次經歷有沒有讓您在與自己或與他人的關係中更明確了“自我”或“自己的身份”（例如：性別、民族、對宗教、政治或不同文化的看法等）？那麼，在你看來，其原因何在？',
    'app.discursive_questions.question3': '3.作為這次您參加BEVI考評的課程項目的結果，您期望能學到什麼？或是您已經學到了什麼？',
    'app.discursive_questions.thank_you': '非常感謝您參加BEVI考評。',
    'app.discursive_questions.please_note': '注意:',
    'app.discursive_questions.go_to_report': '如果您想閱覽這次BEVI考評的個人結果，請點擊下鍵。並且，您也可以稍後在儀表板（Dashboard）上直接閱覽。',
    'app.discursive_questions.do_not_show_report': '很遺憾因某些原因（例如：您回答的問題不夠等）無法生成結果。若可以的話，請您再次參加BEVI考評。',
    'app.discursive_questions.visualize_report': '結果閱覽',
}
