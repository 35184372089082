import styled from 'styled-components';
import { Radio } from 'antd';

export const RadioGroupStyled = styled(Radio.Group)`
  .ant-radio-button-wrapper > span {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
