import { ConsentPageMessages } from '../types';

export const consentPageCA: ConsentPageMessages = {
    'app.consent.title': 'Formulari de consentiment',
    'app.consent.form_title': 'Formulari de Consentiment',
    'app.consent.greeting': 'Benvolgut/Benvolguda participant:',
    'app.consent.introduction': 'Gràcies per la disposició a completar l’Inventari de creences, esdeveniments i valors (BEVI).',
    'app.consent.general.observation': 'Aquest formulari general de consentiment s’ha desenvolupat a partir de l’aportació de molts processos diversos de la Junta de Revisió Institucional (IRB). Abans de completar el BEVI, és possible que se’t demani que  donis el teu consentiment mitjançant un sistema o formulari independent que hagi aprovat una institució o organització determinades. Si tens cap pregunta sobre aquest procés, consulta l’administrador o administradora de BEVI de la teva institució o organització.',
    'app.consent.explanation_1': 'Com suggereix el nom d’aquest inventari, el BEVI està dissenyat per avaluar les diverses maneres en què les persones es veuen a elles mateixes, els altres i el món en general. Després de completar una sèrie de preguntes personals, se’t demanarà que responguis a ítems que cobreixen una àmplia gamma de temes i qüestions. És possible que tinguis reaccions diferents a diversos ítems. Alguns d’aquests ítems et semblaran directament relacionats amb l’aprenentatge, el creixement personal i el desenvolupament, però d’altres no; alguns elements et poden semblar senzills, mentre que d’altres poden semblar ambigus. Com que aquesta mesura avalua molts factors complexos i interrelacionats, tots aquests ítems, així com les preguntes personals, són rellevants per ajudar-nos a comprendre els processos relacionats amb l’aprenentatge, el creixement personal i el desenvolupament, i també la forma en què les diferents persones es veuen a elles mateixes, els altres i  el món en general.',
    'app.consent.explanation_2': 'Malgrat que persones diferents poden respondre de maneres diferents a aquests ítems, has de saber que no hi ha respostes “correctes” o “incorrectes” al BEVI. Com que sovint ens diferenciem en les nostres creences, valors i experiències vitals, és habitual i previsible que una persona estigui molt d’acord amb un ítem, mentre que una altra pugui estar-hi en desacord. Amb respostes com ara “Totalment d’acord”, “D’acord”, “En desacord” i “Totalment en desacord”, el BEVI reconeix explícitament que diverses persones poden tenir creences, valors i experiències vitals molt diferents.',
    'app.consent.participant.rights.1': 'La teva participació en aquest estudi és voluntària i pots optar per deixar de participar-hi en qualsevol moment. La teva privacitat estarà totalment protegida per la llei. Totes les teves respostes es tractaran amb <b>estricta confidencialitat</b>. Les respostes individuals que proporciones a les preguntes <b>mai no es relacionaran amb tu, tret que autoritzis específicament aquesta vinculació per escrit.</b> Tanmateix, en alguns casos, és possible que participis en una experiència en què les teves respostes es puguin revisar directament amb tu. Si això passa, seran tractades amb confidencialitat; la teva privacitat estaria totalment protegida per la llei i de manera coherent amb la informació privilegiada. Només els coordinadors del projecte tindran accés a les dades en brut. A efectes acadèmics, docents, professionals, organitzatius, informatius o il·lustratius, és possible que es presentin o publiquin escales o ítems individuals, dades de perfil de grup o informes organitzatius. Si és així, mai no es presentarà ni publicarà cap informació identificadora que et pugui vincular directament a un perfil, escales, elements o respostes específiques (per exemple, se suprimirà qualsevol identificació que hagis proporcionat), tret que l’autoritzis específicament i per escrit.',
    'app.consent.participant.rights.2': 'Segons quan i en quin context completis el BEVI, és possible que se’t doni l’oportunitat de revisar un informe sobre les teves respostes. Si és així, és possible que se’t demani que acceptis condicions presentades per separat abans de poder llegir aquest text. També és possible que se’t demani que participis en una sessió en la qual es comentarà un informe grupal, que pot incloure dades BEVI d’un grup més gran del qual potser ’has estat membre. Com a part d’un projecte d’avaluació BEVI, també és possible que se’t demani que completis altres mesures quantitatives (per exemple, d’elecció múltiple) o qualitatives (per exemple, respostes escrites) i/o que proporcionis informació addicional mitjançant una entrevista escrita o oral. Aquest procés pot implicar discussions de seguiment amb l’administrador o l’intèrpret d’una mesura concreta. La participació en tots aquests processos és voluntària i pots deixar de participar-hi en qualsevol moment. Com s’ha assenyalat anteriorment, les teves respostes individuals en qualsevol informe de grup o qualsevol altra mesura no estaran vinculades amb tu. Finalment, també que és possible que tinguis l’oportunitat de participar en una sessió de retroalimentació després de completar el BEVI o altres mesures quantitatives o qualitatives, tal com s’indica més amunt.',
    'app.consent.understand.agree': 'Si entens la informació i les directrius anteriors, hi estàs d’acord i acceptes voluntàriament completar el BEVI i participar en una o més experiències en què s’utilitzi el BEVI (per exemple, les descrites anteriorment), fes clic al botó “D’acord”.',
    'app.consent.have.questions': 'Si tens cap pregunta, no dubtis a posar-te en contacte amb nosaltres a <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>',
    'app.consent.thanks': 'Gràcies per la teva participació.',
    'app.consent.giving.consent': 'Donar consentiment',
    'app.consent.read.and.agree': 'He llegit sobre el BEVI i aquest formulari de consentiment i entenc què em demanen com a participant. Accepto lliurement participar-hi. He llegit la descripció d’aquest projecte i dels meus drets com a participant. En fer clic al botó “D’acord” i completar i enviar aquesta enquesta confidencial en línia, estic consentint a participar-hi.',
    'app.consent.agree': 'D’acord',
    'app.consent.disagree': 'En desacord',
    'app.custom_agreement.modal_title': 'Acord sobre revisió d’informes',
    'app.custom_agreement.modal_explanation_text': 'Si us plau, edita el text de l’acord personalitzat abans d’enviar-lo.',
    'app.custom_agreement.default_custom_agreement': 'Benvolgut/Benvolguda Participant BEVI:<br/><br/>Ja has donat el consentiment per completar el BEVI, però abans que podem treballar junts sobre el teu informe BEVI, et demanem que confirmis que (1) vols participar en una o més sessions, en les quals es comentarà el teu informe BEVI, i 2) que autoritzes  <strong>[aquí hem de posar el nom o noms d’una persona o persones]</strong> perquè revisi el teu informe BEVI (per exemple, la narrativa i les puntuacions) amb tu, i comenteu el significat i les implicacions del mateix.<br/>Per descomptat, i tal com s’indica en el formulari de consentiment del BEVI, els teus resultats seran completament confidencials. Si tens alguna pregunta, o necessites més informació, posa’t en contacte amb el teu administrador BEVI.<br/><br/>Si has entès i estàs d’acord amb les dues disposicions anteriors, fes clic al botó “D’acord” següent. Gràcies per l’interès en el BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>Ja vau donar el vostre consentiment per prendre el BEVI, però abans que puguem treballar amb vosaltres en un o més dels vostres informes BEVI (p. ex., amb finalitats d'entrenament, consultoria, assessorament, etc.), us preguntem si esteu d'acord amb els punts següents :</h5>
    <br />

    <p>
    1. Entenc que hi ha diversos tipus d'informes BEVI on algú que estigui certificat per BEVI pot discutir els meus resultats amb mi: 1) Informe individual, 2) Informe de canvi individual, 3) Informe de soci i 4) Informe de canvi de soci. També entenc que he de donar permís perquè qualsevol altra persona pugui revisar i discutir un o més dels meus informes amb mi.
    </p>
    <p>
    2. En fer clic al botó D'acord que hi ha a continuació, autorizo a la següent persona o persones amb certificació BEVI, <b>[#USERS#]</b>, a revisar i discutir un o més dels meus informes amb mi.
    </p>
    <p>
    3.	També entenc i accepto que:
        <p style="padding-left: 20px;">
        a) Estic autoritzant el soci o socis següents, <b>[#PARTNERS#]</b>, a revisar i discutir el meu informe/resultats, i el seu informe/resultats, amb mi i amb la persona o persones amb certificació BEVI que he autoritzat anteriorment;
        </p>
        <p style="padding-left: 20px;">
        b) No compartiré ni discutiré cap informe de soci o informe de canvi de soci en la seva totalitat o en part, ni en cap forma (per exemple, mitjançant correu electrònic, publicació, distribució, mostra, còpia, etc.) amb ningú que no sigui el meu soci ( s) i les persones amb certificació BEVI que s'enumeren més amunt; Qualsevol excepció a aquesta condició serà acceptada per escrit per mi i totes les persones que s'indiquen aquí: soci o socis i la persona o persones amb certificació BEVI;
        </p>
        <p style="padding-left: 20px;">
        c) el soci o els socis que he autoritzat anteriorment poden suprimir els seus informes de socis o informes de canvis de socis en qualsevol moment canviant el perfil de permís al seu tauler de control BEVI, que eliminarà els informes del seu BEVI. quadre de comandament i el meu.
        </p>
    </p>
    <p>
    4. A més de les condicions anteriors, totes les altres disposicions enumerades al Formulari de consentiment de BEVI continuaran vigents (per exemple, el caràcter voluntari de la meva participació, etc.). Si teniu cap pregunta o necessiteu més informació, poseu-vos en contacte amb la persona certificada BEVI o persones que treballin amb vosaltres.
    </p>
    <p>
    5. Si esteu d'acord amb tots els punts anteriors, feu clic a "Estic d'acord" a continuació. Si no esteu d'acord amb tots els punts anteriors, feu clic a "No estic d'acord" a continuació.
    </p>
    <center>Gràcies pel vostre interès en el BEVI!</center>
    `,
}
