import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'unbeantwortete Fragen',
    'app.questions.confirmation':
        'Sie haben nicht alle Fragen beantwortet. Wollen Sie wirklich fortfahren? Sie können später nicht mehr zu diesen Fragen zurückkehren.',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': 'Stimme voll und ganz zu',
    'app.question.agree': 'Stimme zu',
    'app.question.disagree': 'Stimme nicht zu',
    'app.question.strongly_disagree': 'Stimme überhaupt nicht zu',
    'app.question.q1': 'Manchmal weiß ich nicht, welchen Weg ich einschlagen soll.',
    'app.question.q2': 'Im Heranwachsendenalter haben sich die Leute über mich lustig gemacht.',
    'app.question.q3': 'Manchmal vergleiche ich mich mit anderen.',
    'app.question.q4': 'Es ist gesund für junge Menschen, sexuell zu experimentieren.',
    'app.question.q5': 'Ich hatte viele Konflikte mit einem oder mehreren Mitgliedern meiner Familie.',
    'app.question.q6': 'Ich hätte Angst davor, ganz allein auf der Welt zu sein.',
    'app.question.q7': 'Ich wünschte, meine Mutter und/oder mein Vater hätten die Dinge anders gemacht.',
    'app.question.q8': 'In der Zeit um ihre Periode sind Frauen fast immer emotionaler.',
    'app.question.q9': 'Wir sollten versuchen, Kulturen die anders sind als unsere eigene, zu verstehen.',
    'app.question.q10': 'Meine Mutter ist/war meine beste Freundin.',
    'app.question.q11': 'Wir werden letztendlich akzeptieren, dass Männer und Frauen einfach anders sind.',
    'app.question.q12': 'Frauen gehören an den Herd.',
    'app.question.q13': 'Ich bin oft überwältigt von der Schönheit dieser Welt.',
    'app.question.q14': 'Manche Menschen haben stärkere Gene.',
    'app.question.q15': 'Vorurteile gibt es überall und wir müssen sie überwinden.',
    'app.question.q16': 'Ich habe eine schmerzhafte Identitätskrise durchgemacht.',
    'app.question.q17': 'Ich weine mehrere Male im Jahr.',
    'app.question.q18': 'Ich kann/konnte mich immer auf meine Mutter oder meinen Vater verlassen, wenn ich einen guten Rat brauche/brauchte.',
    'app.question.q19': 'Unser Unterbewusstsein kann unsere Gefühle und unser Verhalten beeinflussen.',
    'app.question.q20': 'Es ist wichtig, über das Weltgeschehen gut informiert zu sein.',
    'app.question.q21': 'Ein Mann sollte sich auch wie ein Mann verhalten.',
    'app.question.q22': 'Gott hat mich so gemacht, wie ich bin.',
    'app.question.q23': 'Letztendlich entscheiden die Menschen, ob sie glücklich oder unglücklich sind.',
    'app.question.q24': 'Ich fühle eine tiefe spirituelle Verbindung zur Erde.',
    'app.question.q25': 'Ich hatte eine wundervolle Kindheit.',
    'app.question.q26': 'Ich fühle mich nicht immer wohl mit dem, was ich bin.',
    'app.question.q27': 'Es ist falsch, an der Existenz Gottes zu zweifeln.',
    'app.question.q28': 'Du kannst niemandem außer dir selbst die Schuld für deine Probleme geben.',
    'app.question.q29': 'Frauen sind emotional abhängiger als Männer.',
    'app.question.q30': 'Ich führe gerne stundenlag tiefgründige Gespräche.',
    'app.question.q31': 'Wir sollten den Mindestlohn erhöhen.',
    'app.question.q32': 'Im Heranwachsendenalter hatte ich eine gute und gesunde Beziehung zu meinem Vater.',
    'app.question.q33': 'AIDS könnte durchaus ein Zeichen für Gottes Zorn sein.',
    'app.question.q34': 'Zu viele Menschen sind nur auf gratis Unterstützungsleistungen aus.',
    'app.question.q35': 'Sexualkunde sollte in unseren Schulen unterrichtet werden.',
    'app.question.q36': 'Im Heranwachsendenalter habe ich viel Wut und Aggression gesehen.',
    'app.question.q37': 'Ich möchte ein besseres Gleichgewicht in meinem Leben finden.',
    'app.question.q38': 'Ich nehme meine eigenen Gefühle sehr ernst.',
    'app.question.q39': 'Frauen können erbitterte Konkurrentinnen sein.',
    'app.question.q40': 'Wir sollten unsere animalische Natur nicht verleugnen.',
    'app.question.q41': 'Manche Ereignisse aus meiner Vergangenheit sind mir schon peinlich, wenn ich nur daran denke.',
    'app.question.q42': 'Ich habe in meinen Beziehungen viele Konflikte erlebt.',
    'app.question.q43': 'Ich bin in sozialen Fragen eher liberal als konservativ.',
    'app.question.q44': 'Ich denke viel über den Sinn des Lebens nach.',
    'app.question.q45': 'Ich fühle mich manchmal gelangweilt.',
    'app.question.q46': 'Es ist normal, dass junge Menschen mit Alkohol und Freizeitdrogen experimentieren.',
    'app.question.q47': 'Frauen haben große Schwierigkeiten, sich zu entscheiden.',
    'app.question.q48': 'Das Wissen über andere Kulturen hilft mir, meine eigene Kultur zu verstehen.',
    'app.question.q49': 'Es gibt nur einen Weg in den Himmel.',
    'app.question.q50': 'Menschen scheitern in der Regel, weil sie sich nicht genug anstrengen.',
    'app.question.q51': 'Ich musste Abstand zu meinen Eltern gewinnen.',
    'app.question.q52': 'Ob es einem gefällt oder nicht, Jungs sind nun mal so.',
    'app.question.q53': 'Der Mensch hat sich über Millionen von Jahren entwickelt.',
    'app.question.q54': 'Eine oder mehrere Personen in meiner Familie wurden körperlich, sexuell oder emotional missbraucht.',
    'app.question.q55': 'Ich habe ein echtes Bedürfnis nach Wärme und Zuneigung.',
    'app.question.q56': 'Es sollte mehr Geld in Förderprogramme im frühen Kindesalter investiert werden.',
    'app.question.q57': 'Ich bin ein sehr einfühlsamer Mensch.',
    'app.question.q58': 'Ich unterstütze die Arbeit der Gewerkschaften.',
    'app.question.q59': 'Ich mache mir Sorgen um unsere Umwelt.',
    'app.question.q60': 'Es tut weh, zurückgewiesen zu werden.',
    'app.question.q61': 'Ich kann nichts Negatives über meine Eltern sagen.',
    'app.question.q62': 'Ich habe mich schon gefragt, wer ich bin und wohin mein Weg mich führt.',
    'app.question.q63': 'Meine Emotionen können manchmal die Oberhand über mich gewinnen.',
    'app.question.q64': 'Frauen tratschen viel mehr als Männer.',
    'app.question.q65': 'Ohne Religion kann es keinen Frieden geben.',
    'app.question.q66': 'Es ist unbestreitbar, dass Männer stärker sind als Frauen.',
    'app.question.q67': 'Als Gesellschaft sind wir viel zu konsumorientiert.',
    'app.question.q68': 'Meine Familie hatte viele Geldprobleme.',
    'app.question.q69': 'Ich versuche ständig, mich selbst besser zu verstehen.',
    'app.question.q70': 'Es ist nur natürlich, dass die Starken überleben werden.',
    'app.question.q71': 'Männer sind vernünftiger als Frauen',
    'app.question.q72': 'Es hilft, schmerzhafte Gefühle aus der Vergangenheit zu verarbeiten.',
    'app.question.q73': 'Manchmal fühlen sich Männer genauso schwach wie Frauen.',
    'app.question.q74': 'Es wäre falsch, einem Jungen rosa Kleidung anzuziehen.',
    'app.question.q75': 'Ich schaue mir gerne Filme über Beziehungen an.',
    'app.question.q76': 'Im Heranwachsendenalter hatte ich eine gute und gesunde Beziehung zu meiner Mutter.',
    'app.question.q77': 'Ich mache mir Sorgen um die Gesundheit unseres Planeten.',
    'app.question.q78': 'Mein Körper reagiert sehr sensibel auf das, was ich fühle.',
    'app.question.q79': 'Ich muss zugeben, dass ich genau wie meine Eltern bin.',
    'app.question.q80': 'Frauen sind emotionaler als Männer',
    'app.question.q81': 'Ich habe starke religiöse Überzeugungen.',
    'app.question.q82': 'Ich bin so, wie ich bin, weil ich so geboren wurde.',
    'app.question.q83': 'In der Beziehung meiner Eltern gab es viele Konflikte.',
    'app.question.q84': 'Ich denke gerne darüber nach, wer ich bin.',
    'app.question.q85': 'Frauen haben in den letzten Jahrzehnten große Fortschritte gemacht.',
    'app.question.q86': 'Mein Vater ist mein Held.',
    'app.question.q87': 'Ich habe mich schwer getan herauszufinden, was ich mit meinem Leben anfangen soll.',
    'app.question.q88': 'Manchmal sind meine Gefühle zu stark.',
    'app.question.q89': 'Wir sollten die Erde als unsere Mutter betrachten.',
    'app.question.q90': 'Ich habe mich in meinem Leben einsam gefühlt.',
    'app.question.q91': 'Viele Professor:innen sind zu liberal in ihren Ansichten.',
    'app.question.q92': 'Die einzige wirkliche Hoffnung auf Heilung kommt von einer höheren Macht.',
    'app.question.q93': 'Manchmal fühle ich mich hilfsbedürftig und verletzlich.',
    'app.question.q94': 'Wir sollten uns mehr für die Minderheitengruppen in unserer Gesellschaft einsetzen.',
    'app.question.q95': 'Jeder kann davon profitieren, mehr als eine Sprache zu lernen.',
    'app.question.q96': 'Auch wenn wir das von ihnen erwarten, sind Männer nicht wirklich dafür geschaffen, in der Ehe treu zu sein.',
    'app.question.q97': 'Ich verstehe nicht, warum manche Dinge passieren.',
    'app.question.q98': 'Mein Vater ist/war mir gegenüber emotional distanziert.',
    'app.question.q99': 'Wir sollten eine Frau an die Spitze unseres Landes wählen.',
    'app.question.q100': 'Letztendlich entscheiden wir uns alle für die Art von Leben, die wir führen.',
    'app.question.q101': 'Die Justiz unserer Regierung ist zu liberal.',
    'app.question.q102': 'Ich möchte ernst genommen werden.',
    'app.question.q103': 'Wir sollten die, die weniger Glück haben, mehr unterstützen.',
    'app.question.q104': 'Ich habe nichts dagegen, wenn Emotionen in der Öffentlichkeit gezeigt werden.',
    'app.question.q105': 'Ich wurde im Heranwachsendenalter emotional verletzt.',
    'app.question.q106': 'Pornographie erniedrigt Frauen.',
    'app.question.q107': 'Ich bin wütend über all die Ungerechtigkeit auf der Welt.',
    'app.question.q108': 'Große Regierungsprogramme schaden mehr als sie nützen.',
    'app.question.q109': 'Meine Gefühle wurden in der Vergangenheit verletzt.',
    'app.question.q110': 'Ich lese gerne Selbsthilfebücher.',
    'app.question.q111': 'Meine Mutter und/oder mein Vater hat/hatten emotionale Probleme.',
    'app.question.q112': 'Ich lerne gerne andere Kulturen kennen.',
    'app.question.q113': 'Wir müssen unsere bewährten Traditionen bewahren.',
    'app.question.q114': 'Frauen sind nicht ehrgeizig genug, um erfolgreich zu sein.',
    'app.question.q115': 'Manchmal bin ich wütend auf andere Menschen.',
    'app.question.q116': 'Wenn du es nicht zulässt, kann dir niemand wirklich wehtun.',
    'app.question.q117': 'Ich brauche jemanden in meinem Leben, der mir zuhört, wie ich mich fühle.',
    'app.question.q118': 'Man kann fast jedes Problem überwinden, wenn man sich nur mehr anstrengt.',
    'app.question.q119': 'Gebete bewirken nichts.',
    'app.question.q120': 'Ich bin/stand oft mitten in den Problemen oder Konflikten meiner Eltern.',
    'app.question.q121': 'In unserem Land ist die Kluft zwischen Arm und Reich zu groß.',
    'app.question.q122': 'Ich verstehe nicht alle meine Gefühle.',
    'app.question.q123': 'Menschen ändern sich nicht wirklich.',
    'app.question.q124': 'Ich hatte nie das Gefühl, dass ich gut genug für meine Familie war.',
    'app.question.q125': 'Männer sind von Natur aus wettbewerbsfähiger als Frauen.',
    'app.question.q126': 'Wir müssen die natürlichen Ressourcen der Erde mehr schützen.',
    'app.question.q127': 'Ich hatte in meinem Leben ernste Geldprobleme.',
    'app.question.q128': 'Ich mag es nicht, wenn man mich kritisiert.',
    'app.question.q129': 'Meine Mutter oder mein Vater und ich stehen/standen uns nahe.',
    'app.question.q130': 'Ich habe Probleme, an denen ich arbeiten muss.',
    'app.question.q131': 'Gebete sind heilsamer als traditionelle Medizin.',
    'app.question.q132': 'Ich wurde im Heranwachsendenalter missbraucht/vernachlässigt.',
    'app.question.q133': 'Wir müssen im Kampf gegen Drogen härter vorgehen.',
    'app.question.q134': 'Ich setze mich sehr für gleiche Rechte für Frauen ein.',
    'app.question.q135': 'Mein Vater verstand und akzeptierte mich.',
    'app.question.q136': 'Bildung ist der Schlüssel zu einer aufgeklärten Gesellschaft.',
    'app.question.q137': 'Männer müssen stark sein.',
    'app.question.q138': 'Wir sollten das Land schützen, unabhängig davon, wem es gehört.',
    'app.question.q139': 'Meine Eltern haben zu viel von mir verlangt.',
    'app.question.q140': 'Wir alle würden davon profitieren, wenn Frauen mehr wirtschaftliche und politische Macht hätten.',
    'app.question.q141': 'Manchmal weine ich, wenn andere Menschen weinen.',
    'app.question.q142': 'Manche Frauen fordern es mit ihrer Kleidung heraus, belästigt zu werden.',
    'app.question.q143': 'Wenn du nichts nettes zu sagen hast, sag besser gar nichts.',
    'app.question.q144': 'Du kannst die Vergangenheit nicht ändern, also macht es keinen Sinn, ihr nachzuhängen.',
    'app.question.q145': 'Wenn das Leben dir einen Schlag versetzt, stehe auf und geh weiter',
    'app.question.q146': 'Zurückweisung macht einen oft stärker.',
    'app.question.q147': 'Manche Menschen denken zu viel über die Welt nach.',
    'app.question.q148': 'Wann immer ich mich schlecht fühle, versuche ich, an etwas Positives zu denken.',
    'app.question.q149': 'Es ist immer besser, sich auf die Stärken zu konzentrieren als auf die Schwächen.',
    'app.question.q150': 'Die beste Art und Weise, das Leben zu meistern, ist, eine glückliche Einstellung zu bewahren.',
    'app.question.q151': 'Was geschehen ist, ist geschehen, also vergib und vergiss. ',
    'app.question.q152': 'Man kann wirklich nicht sagen, dass eine Meinung besser ist als die andere.',
    'app.question.q153': 'Ich finde, die Menschen sollten dankbar sein für das, was sie haben, und aufhören, sich zu beschweren.',
    'app.question.q154': 'Manche Zahlen bringen mehr Unglück als andere.',
    'app.question.q155': 'Es gibt keinen Grund für Obszönitäten.',
    'app.question.q156': 'Zu viele Menschen kommen ihrer Verantwortung nicht nach.',
    'app.question.q157': 'Hellseher:innen können die Zukunft vorhersagen.',
    'app.question.q158': 'Es ist immer am besten, wenn man versucht, die Dinge zu vereinfachen.',
    'app.question.q159': 'Es gibt keine zwei Expert:innen, die sich über die gleiche Sache einig sind.',
    'app.question.q160': 'Wenn man sich an die Regeln hält, kommt man gut durchs Leben.',
    'app.question.q161': 'Es wäre schön, wenn mich alle mögen oder zumindest respektieren würden.',
    'app.question.q162': 'Wir sollten gegenüber anderen kulturellen Praktiken toleranter sein.',
    'app.question.q163': 'Ich bin ein Freigeist.',
    'app.question.q164': 'Ich glaube an die Macht der Astrologie.',
    'app.question.q165': 'Menschen, die in Armut leben, tun mir leid.',
    'app.question.q166': 'Ich schätze klare Logik mehr als alles andere.',
    'app.question.q167': 'Ich fühle mich wohl in Gruppen von Menschen, die ganz anders sind als ich.',
    'app.question.q168': 'Eltern sollten um ihrer Kinder willen zusammenbleiben.',
    'app.question.q169': 'Es ist gesund, wenn Menschen sexuelle Fantasien haben.',
    'app.question.q170': 'Diagnostische Label sind nicht gerade hilfreich.',
    'app.question.q171': 'Ich betrachte die Dinge immer von der positiven Seite.',
    'app.question.q172': 'Lebewesen von anderen Planeten haben unserer Spezies geholfen.',
    'app.question.q173': 'Die Erde ist Milliarden Jahre alt.',
    'app.question.q174': 'Schwäche kann eine Tugend sein.',
    'app.question.q175': 'Niemand mag Pessimist:innen',
    'app.question.q176': 'Es stört mich, wenn Emotionen gezeigt werden.',
    'app.question.q177': 'Meine Eltern hatten eine sehr gute Beziehung, als ich im Heranwachsendenalter war.',
    'app.question.q178': 'Wir sind zu tolerant gegenüber fremden kulturellen Praktiken.',
    'app.question.q179': 'Wir tun genug, um den Armen zu helfen.',
    'app.question.q180': 'Die Menschen machen sich zu viele Sorgen um die Umwelt.',
    'app.question.q181': 'Vorurteile sind wirklich kein großes Problem mehr.',
    'app.question.q182': 'Menschen verändern sich dauernd.',
    'app.question.q183': 'Es gibt viele Wege, die in den Himmel führen.',
    'app.question.q184': 'Männer und Frauen sind sich mehr ähnlich als verschieden.',
    'app.question.q185': 'Wir suchen uns das Leben, das wir haben, nicht wirklich aus.',
};

export const scaleQuestionsPageDE: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
