import { ConsentPageMessages } from '../types';

export const consentPageJA: ConsentPageMessages = {
    'app.consent.title': '同意',
    'app.consent.form_title': '同意書',
    'app.consent.greeting': '参加者の皆様:',
    'app.consent.introduction': '  Beliefs, Events, and Values Inventory（BEVI）による調査に参加いただき、ありがとうございます。',
    'app.consent.general.observation': '  この一般同意書は、多くの大学における研究倫理審査委員会（Institutional Review Board: IRB）での審査手続を参考にして作成されています。BEVIを受検する前に、本同意書とは別に、特定の大学又は機関による別のシステム又は書式により同意を求められる場合もあります。後者の手続についてご質問がある場合は、所属する大学又は機関のBEVI管理者まで御相談ください。',
    'app.consent.explanation_1': '  BEVIは、この調査の名称（Beliefs, Events, and Values Inventory）が示す通り、人々が自分自身、他者、そしてより大きな世界を理解する際の、多種多様な見方を測定する目的で作成されています。一連の背景情報に関する質問の後、非常に幅広い問題またトピックについての質問が行われます。個人の反応は、質問ごとに異なるでしょう。質問には、学習、成長及び発達に直接関連しているように思える質問もあれば、そうでないように見える質問もあるかもしれません。直接的な質問がある一方、曖昧な質問もあります。この調査は、人々の信条や価値観に関連する、複雑かつ相互に関連する多くの要素を測定しており、これら全ての質問項目及び背景情報は、学習、成長及び発達に関連するプロセス、また異なる人々が自分自身、他者、そしてより大きな世界をどうように見ているのかを理解するのに役立ちます。〔また、上記の目的を達成するために必要な最小限度の質問項目のみが含まれています。〕',
    'app.consent.explanation_2': '  これらの質問に対して、人それぞれ異なった回答をすることと思いますが、まず、BEVIの質問には「正解」も「間違った回答」もないことを知っておいてください。私たちの信条、価値観また人生経験は当然のことながら異なっているため、ある人が特定の質問について「強くそう思う」と回答しても、別の人は同じ質問に対して「全くそうは思わない」と回答することがあるのは、当然でありかつ十分予想されることです。「強くそう思う」、「そう思う」、「そうは思わない」、「全くそうは思わない」という選択肢があるのは、BEVIが、人々がそれぞれ多様な信条、価値観また人生経験を有しており、それらを「正しい」また「間違っている」という判断をすることはできない、ということ前提としているからです。',
    'app.consent.participant.rights.1': '  この調査への参加は任意であり、いつでも中断することができます。個人のプライバシーは法のもと最大限保護されます。回答はすべて、<b>厳秘扱い</b>されます。個々の質問に対する回答は、<b>文書による回答者の明示的な同意がある場合を除き、いかなる形でも個人と関連づけられることはありません。</b>場合によっては、自分自身の回答を直接確認することができる場合があります。その場合でも、あなたの回答は厳秘扱いされます。個人のプライバシーは法のもと最大限保護され、かつ適切な方法で部外秘情報として保護されます。この調査全体のプロジェクト･コーディネーターのみが生データにアクセスできます。研究、教育、専門職トレーニング、大学/組織運営、情報提供又は解説のため、グループ･レポートの個々の尺度、グループ又は大学/組織レポートの一部が発表又は出版されることがあります。ただし、この場合でも、特定の統計データ、集計データ、質問又は回答を、個人と直接関連づけるような識別情報は、文書による明示的な同意が与えられない限り、一切発表又は出版されることはありません（この場合でも、記入されたID情報はすべて削除されます）。',
    'app.consent.participant.rights.2': '  BEVIを終了した後、あなたの回答に基づく個人レポートを見ることができる場合があります。この場合、個人レポートを見るに先立ち、別途、条件への同意が求められます。また、グループ･レポートを用いたディスカッションに参加するよう求められる場合もあります。このグループ･レポートには、あなたも参加したかもしれない、より大きなグループのBEVIデータが含まれている場合があります。BEVIを用いた測定プロジェクトの一環として、他の定量的（例：多肢選択式質問）または定性的（例：自由記述による質問）な調査への参加、書面または口頭での質問により追加の情報を求められる場合もあります。このようなプロセスには、調査担当者または研究者とのフォローアップのための話し合いが含まれる場合もあります。このようなプロセスへの参加はすべて任意であり、いつでも参加を中止することができます。上述したように、グループレポートやその他の尺度におけるあなたの個人的な回答が、あなた個人と関連づけられることはありません。最後に、BEVI又は他の定量的・定性的調査の終了後に、フィードバック･セッションに参加する機会があるかもしれません。',
    'app.consent.understand.agree': '  上記の情報とガイドラインを理解し、同意した上で、BEVIまた（上述のような）BEVIを用いたセッションに参加することを自発的に同意される場合は、「同意」をクリックしてください。',
    'app.consent.have.questions': '    ご不明な点がございましたら、<a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>までお気軽にお問い合わせください。',
    'app.consent.thanks': 'ご参加ありがとうございます。',
    'app.consent.giving.consent': '同意書',
    'app.consent.read.and.agree': '  私はBEVIの概要と本同意書を読み、参加者として求められていることを理解しています。私は、上記のこのプロジェクト及び参加者としての私の権利についての説明を読みました。私は、下の「同意」をクリックすることにより、この調査に自由意思で参加することに同意します',
    'app.consent.agree': '同意',
    'app.consent.disagree': 'Disagree',
    'app.custom_agreement.modal_title': 'Report review agreement',
    'app.custom_agreement.modal_explanation_text': 'Please edit the custom agreement text before submitting it.',
    'app.custom_agreement.default_custom_agreement': 'Dear BEVI Participant:<br/><br/>You already provided consent to take the BEVI, but before we can work with you on your BEVI report, we are asking you to agree 1) that you want to participate in one or more sessions where your individual report will be discussed with you and 2) that you are authorizing <strong>[here we need to be able to fill in one or more names of individual people]</strong> to review your individual report (e.g., your narrative, scores) and discuss the  meaning or implications of your BEVI report with you.<br/>Of course, as indicated in the consent form for the BEVI, your individual results will remain completely confidential.  If you have any questions, or need further information, please contact the BEVI administrator who is working with you.<br/><br/>If you understand and agree to the two above provisions, please click the "agree" button below. Thank you for your interest in the BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>あなたはすでに BEVI を受けることに同意していますが、1 つまたは複数の BEVI レポートを作成する前に (コーチング、コンサルティング、カウンセリングなどの目的で)、次の点に同意するかどうかを尋ねています。 :</h5>
    <br />

    <p>
    1. BEVI レポートには、BEVI 認定者によって私の結果について話し合われる可能性があるいくつかの種類があることを理解しています。1) 個人レポート、2) 個人変更レポート、3) パートナー レポート、4) パートナー変更レポート。 また、他の人が私の 1 つ以上のレポートを確認し、私と議論する許可を与える必要があることも理解しています。
    </p>
    <p>
    2. 下の [同意する] ボタンをクリックすると、次の BEVI 認定個人、<b>[#USERS#]</b> が私の 1 つ以上のレポートを確認し、議論することを承認します。
    </p>
    <p>
    3.	私はまた、次のことを理解し、同意します。
        <p style="padding-left: 20px;">
        a) 私は、次のパートナー <b>[#PARTNERS#]</b> に、私のレポート/結果、およびそのレポート/結果を私および BEVI 認定個人と確認し、話し合うことを許可します。 私が上記で承認したこと。
        </p>
        <p style="padding-left: 20px;">
        b) 私は、パートナー レポートまたはパートナー変更レポートの全部または一部を、またはいかなる形式 (電子メール、投稿、配布、表示、コピーなど) で、私のパートナー以外の者と共有したり議論したりしません( s) および上記の BEVI 認定個人。 この条件の例外については、私とここにリストされているすべての個人 (パートナーまたはパートナーおよび BEVI 認定個人または個人) が書面で同意するものとします。
        </p>
        <p style="padding-left: 20px;">
        c) 上記で私が承認したパートナーは、BEVI ダッシュボードで権限プロファイルを変更することで、いつでもパートナー レポートまたはパートナー変更レポートを削除できます。これにより、BEVI からレポートが削除されます。 ダッシュボードと私のもの。
        </p>
    </p>
    <p>
    4. 上記の条件に加え、BEVI 同意書に記載されている他のすべての条項は引き続き有効となります (例: 私の参加の自発的な性質など)。 ご質問がある場合、またはさらに詳しい情報が必要な場合は、BEVI 認定担当者またはお客様と協力している担当者にお問い合わせください。
    </p>
    <p>
    5. 上記すべての事項に同意される場合は、下の「同意する」をクリックしてください。 上記のすべての点に同意しない場合は、下の「同意しない」をクリックしてください。
    </p>
    <center>BEVIにご興味をお持ちいただきありがとうございます。</center>
    `,
}



