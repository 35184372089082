import { CreateProgramMessages } from '../types';

export const createProgramES: CreateProgramMessages = {
    'app.create_program.btn_submit': 'Guardar',
    'app.create_program.btn_submit_and_send_email': 'Guardar/Enviar correo electrónico',
    'app.create_program.btn_unselect_all_users': 'Quitar todos',
    'app.create_program.select_individual_manually': 'Seleccionar Individuos Manualmente',
    'app.create_program.table.column_name': 'Nombre',
    'app.create_program.table.column_email': 'Correo electrónico',
    'app.create_program.table.column_actions': 'Quitar Individuos',
    'app.create_program.table.column_get_last_users': 'Individuos de periodo de administración previo.',
    'app.create_program.tooltip.action_remove': 'Quitar',
    'app.create_program.tooltip.btn_get_last_users': 'Importar individuos de periodo de administración previo.',
    'app.create_program.tooltip.btn_download_users': 'Descargar / gestionar quién hará el BEVI.',
    'app.create_program.tooltip.btn_download_users_empty': 'Descargar / gestionar quién hará el BEVI(Plantilla vacía).',
    'app.create_program.excel.name_column': 'Nombre',
    'app.create_program.excel.login_column': 'Correo electrónico',
    'app.create_program.excel.active_column': 'Activo',
    'app.create_program.confirmation_title_success': 'Creado con éxito',
    'app.create_program.confirmation_body_success_program': 'Se ha creado el programa o experiencia. Puedes revisarlo o gestionarlo haciendo click en "De acuerdo" a continuación.',
    'app.create_program.confirmation_body_success_subprogram': 'Has creado y nombrado un periodo de administración del BEVI.  Puedes revisarlo o gestionarlo haciendo click en "De acuerdo" a continuación.',
    'app.create_program.confirmation_title_fail': '¡Vaya! Por desgracia, no hemos podido crearlo',
    'app.create_program.confirmation_body_fail_program': 'El programa no se ha podido crear. Por favor, revisa los datos proporcionados, la conexión a Internet y vuelve a intentarlo. Si el problema persiste, dirígete al administrador de la cuenta.',
    'app.create_program.confirmation_body_fail_subprogram': 'El Sub Programa no se ha podido crear. Por favor, revisa los datos proporcionados, la conexión a Internet y vuelve a intentarlo. Si el problema persiste, dirígete al administrador de la cuenta.',
    'app.edit_sub_program.confirmation_title_success': 'Actualizado con éxito',
    'app.edit_sub_program.confirmation_body_success': 'El grupo se ha actualizado y ahora puedes visualizarlo en el listado de programas de la organización, al que te estamos redirigiendo.',
    'app.edit_sub_program.confirmation_title_fail': '¡Vaya! Por desgracia, no hemos podido actualizarlo',
    'app.edit_sub_program.confirmation_body_fail': 'El Sub Programa no se ha podido actualizar. Por favor, revisa los datos proporcionados, la conexión a Internet y vuelve a intentarlo. Si el problema persiste, dirígete al administrador de la cuenta.',
    'app.create_program.lbl_associate_users': 'Selecciona los individuos que harán el BEVI.',
    'app.create_program.lbl_organization_name': 'Nombre de Organización',
    'app.create_program.lbl_program_name': 'Nombre de Programa o Experiencia',
    'app.create_program.lbl_sub_program_name': 'Nombre de una Administración del BEVI',
    'app.create_program.lbl_year': 'Año',
    'app.create_program.lbl_due_date': 'Fecha final de una Administración del BEVI',
    'app.create_program.lbl_alert_all_users': 'Avisar a todos los individuos por correo electrónico',
    'app.details_sub_program.progress': 'Progreso de Grupo',
    'app.details_sub_program.participant_details': 'Datos del Participante',
    'app.details_sub_program.remind_alert': 'Remind Alert',
    'app.details_sub_program.remind_alert_text': 'A reminder alert was scheduled and it will be sent in a few minutes.',
    'app.create_program.lbl_email_message': 'Introducir mensaje a continuación para su envío a las direcciones de correo electrónico de cada uno de los participantes.',
    'app.create_program.tooltip.btn_upload_users': 'Colgar quién hará el BEVI.',
    'app.create_program.upload_files_error_title': 'Error al colgar archivo',
    'app.create_program.upload_rejected_files_error_body_prefix': 'Los siguientes archivos han sido rechazados porque no están en el formato apropiado:',
    'app.create_program.upload_parsed_files_error_body_prefix': 'El archivo debe contener las columnas {0}, {1} y {2}. Los siguientes archivos no se han leído correctamente:',
    'app.create_program.upload_parsed_files_error_body': '{0} tiene {1} errores.',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'Error al intentar analizar los siguientes archivos:',
    'app.create_program.select_individuals_title': 'Seleccionar los individuos que harán el BEVI a través de uno de los tres métodos:',
    'app.create_program.import_individuals_from_previous_administration': '1.  Importar individuos de una Administración previa del BEVI.',
    'app.create_program.download_manage_who_will_take_the_bevi': '2.  Descargar / gestionar quién hará el BEVI',
    'app.create_program.upload_who_will_take_the_bevi': ', y por ultimo colgar quién hará el BEVI',
    'app.create_program.add_individuals_manually': '3.  Añadir individuos manualmente / uno a uno haciendo click en el desplegable que aparece a continuación',
}
