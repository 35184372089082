import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': '年齢',
    'app.demographic.gender': '性別',
    'app.demographic.marital_status': '結婚歴',
    'app.demographic.level_of_fluency': '＝＝＝Not in the Demographics母国語以外の言語の流暢さを記入してください。ここでは、母国語とは自宅や地域社会で話されている言語、または通っていた小学校で使われている言語を意味します。',
    'app.demographic.education_years': `正規教育を何年間修了したか選択してください。`,
    'app.demographic.raised_in_country_where_currently_in': '小学校以降（保育園また幼稚園を除く）、何年間の正規の学校教育を受けたか選択してください。',
    'app.demographic.ethnic': '民族的背景を教えてください。',
    'app.demographic.ethnic_other': '以下のボックスに、もしよろしければ、記入してください。',
    'app.demographic.ethnic_asian': `アジア系：日本、中国、韓国、フィリピン、タイ、ベトナム、カンボジア、マレーシア、インド、パキスタン、など、極東、東南アジアまたはインド亜大陸の国または地域に起源を持つ`,
    'app.demographic.ethnic_african': '黒人/アフリカ系：アフリカの黒人種族のいずれかに起源を持つ',
    'app.demographic.ethnic_latino': `ヒスパニック/ラテン：人種に関係なく、キューバ、メキシコ、プエルトリコ、南アメリカ、中央アメリカ、またその他のスペイン・ポルトガル文化の影響下にある国または地域に起源を持つ`,
    'app.demographic.ethnic_indigenous': `先住民族：特定の国/地域の先住民のいずれかを起源とし、先住民族またはコミュニティとの関係/帰属を維持している`,
    'app.demographic.ethnic_middle_eastern': `中東：エジプト、イラン、イラク、イスラエル、ヨルダン、レバノン、サウジアラビア、スーダン、シリア、トルコ、またはアラビア半島の国を含む、アフガニスタンからリビアまでの国または地域に起源を持つ`,
    'app.demographic.ethnic_white': '白人：ヨーロッパのいずれかの人種グループに起源を持つ',
    'app.demographic.completing_bevi': '＝＝＝Not in the Demographicsどの段階でBEVIを受けようとしているのか記入してください。Please indicate when you are completing the BEVI.',
    'app.demographic.completing_bevi_other': '＝＝＝Not in the Demographicsどのような状況でBEVIを受けようとしているのか記入してください。Please describe the circumstances under which you are completing the BEVI below.',
    'app.demographic.previously_completed': '＝＝＝Not in the Demographics以前にBEVIを受けたことがありますかHave you previously completed the BEVI?',
    'app.demographic.disadvantaged_or_minority_group': 'あなたは自分が疎外されたグループ/マイノリティ･グループに属していると思いますか。',
    'app.demographic.disadvantaged_or_minority_group_yes': 'もしよければ、追加情報を以下のボックスに記入してください。',
    'app.demographic.primary_area_of_work': '以下の選択肢の中から、あなたの専攻分野または職業/専門分野に最も近いものを選んでください。',
    'app.demographic.current_area': 'あなたの現在の学部学科/専攻または職業を以下のボックスに記入してください。',
    'app.demographic.political_orientation': '「保守的/伝統的（たとえば、既存の法、制度、あり方を維持、保存することに賛成）」から「リベラル/進歩的（たとえば、既存の法、制度、あり方を変更、改革することに賛成）」の間で、あなたの政治的志向を下のスライドバーを利用して教えてください。',
    'app.demographic.political_orientation_conservative_label': '保守的/伝統的',
    'app.demographic.political_orientation_liberal_label': 'リベラル/進歩的',
    'app.demographic.religion': '以下の選択肢の中で、あなたの宗教/スピリチュアリティに関する現在の信念や関与に最も近いのはどれでしょうか。',
    'app.demographic.religion_other': '以下のボックスに、もしよろしければ、記入してください:',
    'app.demographic.religion_scale': '宗教/スピリチュアリティに対するあなた個人の関与レベルを教えてください。',
    'app.demographic.religion_scale_specified': '{0}への関与レベルを教えてください。',
    'app.demographic.income_level': 'あなたまた家族の収入/資産の相対的な程度（例：「最小」から「最高」まで）を以下スライドバーで教えてください。',
    'app.demographic.income_less_label': '最小',
    'app.demographic.income_more_label': '最高',
    'app.demographic.academic_rank_lowest_label': '最低',
    'app.demographic.academic_rank_highest_label': '最高',
    'app.demographic.academic_rank': '直近の大学/学校で一緒に授業を受けた他の学生と比較した、成績の推定ランク（例：「最低」から「最高」まで）を以下スライドバーで教えてください。',
    'app.demographic.primary_country': 'あなたの出身国を教えて下さい。日本以外を選択する場合は、単語の一部を入力してください。',
    'app.demographic.time_in_other_countries': '以下の選択肢の中から、出身国以外の国/地域で過ごした期間を教えてください。',
    'app.demographic.time_in_other_countries_other': '指定してください',
    'app.demographic.visit_countries': '出身国以外の国/地域の何カ国を訪問/住んだことがありますか(半角入力)? ',
    'app.demographic.country_explanation_field': 'To find your country, you may either select the country from the dropdown menu or simply type in and select the language.',
    'app.demographic.country_mother_born': 'お母様の生まれた国を教えてください。日本以外を選択する場合は、単語の一部を入力してください。',
    'app.demographic.country_father_born': 'お父様の生まれた国を教えてください。日本以外を選択する場合は、単語の一部を入力してください。',
    'app.demographic.personal_interest': '国際的/多文化/異文化体験（例：留学、海外での仕事；バーチャル･エクスチェンジ（VE）、COIL；多文化授業科目；多文化チームへの参加など）への<b>個人的な関心の程度</b>を教えてください。',
    'app.demographic.participated_experiences': 'これまで以下のプログラムに参加したことがありますか、または現在参加していますか（該当する項目すべてを選択してください）。',
    'app.demographic.participated_learning_courses': 'これまで異文化/多様性に関連する以下のコース/プログラムに参加したことがありますか。または現在参加していますか（該当するものをすべて選択してください）。',
    'app.demographic.plan_participate_experiences': '以下のプログラムに参加する予定はありますか（該当するものをすべて選択してください）。',
    'app.demographic.plan_participate_learning_courses': '異文化/多様性に関連する以下のコース/プログラムに参加する予定はありますか（該当するものをすべて選択してください）。',
    'app.demographic.experience_satisfaction': '国際的/多文化/異文化体験（例：留学、海外での仕事；バーチャル･エクスチェンジ（VE）、COIL；多文化授業科目；多文化チームへの参加など）に参加したことがある/現在参加している場合、あなたの<b>全体的な満足度</b>を教えてください。',
    'app.demographic.languages_speak': 'あなたは、母語としていくつの言語を話しますか。　話すことができますか。ここでは、母語とは自宅や地域社会で話されている言語、または通っていた小学校で使われていた言語を意味します。',
    'app.demographic.years_studied_foreign_language': 'これまで何年間、外国語を勉強してきましたか(半角入力)。',
    'app.demographic.primary_language': 'あなたの母語（第一言語、主要言語）は何ですか？母語が複数ある場合は、複数の言語を選択することができます。言語を選択するには、ドロップダウンメニューから選ぶか、単語の一部を入力してください。',
    'app.demographic.primary_language_other': '母語（第一言語、主要言語）以外の言語を話しますか?',
    'app.demographic.language_level_of_fluency': '以下からあなたが話すことのできる母国語以外の言語とその流暢さを選択してください。言語を選択する場合は、単語の一部を入力してください。',
    'app.demographic.language_level_of_fluency_add_anwser': '追加',
    'app.demographic.table_level_of_fluency': '流暢さのレベル',
    'app.demographic.table_language': '言語',
    'app.demographic.table_action': 'アクション',
    'app.demographic.remove_language_tooltip': '言語を削除する',
    'app.demographic.mother_formal_education': 'お母様の最終学歴を教えてください。',
    'app.demographic.father_formal_education': 'お父様の最終学歴を教えてください。',
    'app.demographic.political_commitment': '政治に対するあなた個人の関与レベルを教えてください。',
    'app.demographic.comparative': '個別比較レポート',
    'app.demographic.compare': 'Compare',
    'app.demographic.select_multiple_reports': '複数のレポートを選択する',
}
const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanian',
    'app.demographic.language_options.Amharic': 'Amharic',
    'app.demographic.language_options.Arabic': 'Arabic',
    'app.demographic.language_options.Armenian': 'Armenian',
    'app.demographic.language_options.Basque': 'Basque',
    'app.demographic.language_options.Bengali': 'Bengali',
    'app.demographic.language_options.Byelorussian': 'Byelorussian',
    'app.demographic.language_options.Burmese': 'Burmese',
    'app.demographic.language_options.Bulgarian': 'Bulgarian',
    'app.demographic.language_options.Catalan': 'Catalan',
    'app.demographic.language_options.Czech': 'Czech',
    'app.demographic.language_options.Chinese': 'Chinese',
    'app.demographic.language_options.Croatian': 'Croatian',
    'app.demographic.language_options.Danish': 'Danish',
    'app.demographic.language_options.Dari': 'Dari',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Dutch',
    'app.demographic.language_options.English': 'English',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonian',
    'app.demographic.language_options.Faroese': 'Faroese',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finnish',
    'app.demographic.language_options.French': 'French',
    'app.demographic.language_options.Gaelic': 'Gaelic',
    'app.demographic.language_options.Galician': 'Galician',
    'app.demographic.language_options.German': 'German',
    'app.demographic.language_options.Greek': 'Greek',
    'app.demographic.language_options.Hebrew': 'Hebrew',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Hungarian',
    'app.demographic.language_options.Icelandic': 'Icelandic',
    'app.demographic.language_options.Indonesian': 'Indonesian',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Eskimo)',
    'app.demographic.language_options.Italian': 'Italian',
    'app.demographic.language_options.Japanese': 'Japanese',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Korean',
    'app.demographic.language_options.Kurdish': 'Kurdish',
    'app.demographic.language_options.Laotian': 'Laotian',
    'app.demographic.language_options.Latvian': 'Latvian',
    'app.demographic.language_options.Lappish': 'Lappish',
    'app.demographic.language_options.Lithuanian': 'Lithuanian',
    'app.demographic.language_options.Macedonian': 'Macedonian',
    'app.demographic.language_options.Malay': 'Malay',
    'app.demographic.language_options.Maltese': 'Maltese',
    'app.demographic.language_options.Nepali': 'Nepali',
    'app.demographic.language_options.Norwegian': 'Norwegian',
    'app.demographic.language_options.Pashto': 'Pashto',
    'app.demographic.language_options.Polish': 'Polish',
    'app.demographic.language_options.Portuguese': 'Portuguese',
    'app.demographic.language_options.Romanian': 'Romanian',
    'app.demographic.language_options.Russian': 'Russian',
    'app.demographic.language_options.Scots': 'Scots',
    'app.demographic.language_options.Serbian': 'Serbian',
    'app.demographic.language_options.Slovak': 'Slovak',
    'app.demographic.language_options.Slovenian': 'Slovenian',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Spanish',
    'app.demographic.language_options.Swedish': 'Swedish',
    'app.demographic.language_options.Swahili': 'Swahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalog-Filipino',
    'app.demographic.language_options.Tajik': 'Tajik',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Thai',
    'app.demographic.language_options.Tibetan': 'Tibetan',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tongan',
    'app.demographic.language_options.Turkish': 'Turkish',
    'app.demographic.language_options.Turkmen': 'Turkmen',
    'app.demographic.language_options.Ucrainian': 'Ucrainian',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Uzbek',
    'app.demographic.language_options.Vietnamese': 'Vietnamese',
    'app.demographic.language_options.Welsh': 'Welsh',
    'app.demographic.language_options.Other': 'Other',
    'app.demographic.gender.male': '男性',
    'app.demographic.gender.female': '女性',
    'app.demographic.gender.self_identify': '自分で記述',
    'app.demographic.marital.response_single': '独身',
    'app.demographic.marital.response_married': '既婚',
    'app.demographic.marital.response_divorced': '離婚',
    'app.demographic.marital.response_widowed': '死別',
    'app.demographic.marital.response_other': 'その他',
    'app.demographic.education_years.response_1': '正規教育を受けていない',
    'app.demographic.education_years.response_2': '1 年',
    'app.demographic.education_years.response_3': '2 年',
    'app.demographic.education_years.response_4': '3 年',
    'app.demographic.education_years.response_5': '4 年',
    'app.demographic.education_years.response_6': '5 年',
    'app.demographic.education_years.response_7': '6 年',
    'app.demographic.education_years.response_8': '7 年',
    'app.demographic.education_years.response_9': '8 年',
    'app.demographic.education_years.response_10': '9 年',
    'app.demographic.education_years.response_11': '10 年',
    'app.demographic.education_years.response_12': '11 年',
    'app.demographic.education_years.response_13': '12 年',
    'app.demographic.education_years.response_14': '13 年',
    'app.demographic.education_years.response_15': '14 年',
    'app.demographic.education_years.response_16': '15 年',
    'app.demographic.education_years.response_17': '16 年',
    'app.demographic.education_years.response_18': '17 年',
    'app.demographic.education_years.response_19': '18 年',
    'app.demographic.education_years.response_20': '19 年',
    'app.demographic.education_years.response_21': '20 年',
    'app.demographic.education_years.response_22': '21 年',
    'app.demographic.education_years.response_23': '22 年',
    'app.demographic.education_years.response_24': '23 年',
    'app.demographic.education_years.response_25': '24 年',
    'app.demographic.education_years.response_26': '25 年',
    'app.demographic.education_years.response_27': '26 年',
    'app.demographic.education_years.response_28': '27 年',
    'app.demographic.education_years.response_29': '28 年',
    'app.demographic.education_years.response_30': '29 年',
    'app.demographic.education_years.response_31': '30 年',
    'app.demographic.education_years.response_32': '31年以上',
    'app.demographic.ethnic.respose_asian': 'アジア系',
    'app.demographic.ethnic.respose_african': '黒人またはアフリカ系',
    'app.demographic.ethnic.respose_latino': 'ヒスパニックまたはラテン',
    'app.demographic.ethnic.respose_indigenous': '先住民族',
    'app.demographic.ethnic.respose_middle_eastern': '中東',
    'app.demographic.ethnic.respose_white': '白人',
    'app.demographic.ethnic.respose_mixed': 'Mixed',
    'app.demographic.ethnic.respose_other': 'その他',
    'app.demographic.completing_bevi.response_before': '国際的/異文化体験学習の前/開始直後で',
    'app.demographic.completing_bevi.response_during': '国際的/異文化体験学習の中で/最中に',
    'app.demographic.completing_bevi.response_after': '国際/異文化体験学習の終了直後/後に',
    'app.demographic.completing_bevi.response_during_academic': 'アカデミック・オリエンテーションで、また上記の選択肢のいずれかに該当しない場合',
    'app.demographic.completing_bevi.response_end_graduation': '大学の学部卒業時',
    'app.demographic.completing_bevi.response_other': 'その他 - 具体的に記入してください',
    'app.demographic.primary_area_of_work.response_1': '芸術/メディア/文学',
    'app.demographic.primary_area_of_work.response_2': '心理/社会/法',
    'app.demographic.primary_area_of_work.response_3': '経済/経営',
    'app.demographic.primary_area_of_work.response_4': '教育',
    'app.demographic.primary_area_of_work.response_5': '医療/看護/福祉',
    'app.demographic.primary_area_of_work.response_6': '物理/化学/工学/情報',
    'app.demographic.primary_area_of_work.response_7': 'その他',
    'app.demographic.political_orientation.response_1': '保守的/伝統的（たとえば、既存の法、制度、あり方を維持、保存することに賛成）',
    'app.demographic.political_orientation.response_2': 'より保守的',
    'app.demographic.political_orientation.response_3': '中立：保守的でもリベラルでもない',
    'app.demographic.political_orientation.response_4': 'よりリベラル',
    'app.demographic.political_orientation.response_5': 'リベラル/進歩的（たとえば、既存の法、制度、あり方を変更、改革することに賛成）',
    'app.demographic.political_orientation.response_6': 'その他',
    'app.demographic.religion.response_1': 'アフリカ起源の伝統的宗教',
    'app.demographic.religion.response_2': '不可知論',
    'app.demographic.religion.response_3': '無神論',
    'app.demographic.religion.response_4': 'バハーイー/バハイ教',
    'app.demographic.religion.response_5': '仏教',
    'app.demographic.religion.response_6': 'カオダイ教',
    'app.demographic.religion.response_7': '中国起源の伝統的宗教',
    'app.demographic.religion.response_8': 'キリスト教',
    'app.demographic.religion.response_9': 'ヒンドゥー教',
    'app.demographic.religion.response_10': '先住民起源の伝統的宗教',
    'app.demographic.religion.response_11': 'イスラーム教',
    'app.demographic.religion.response_12': 'ジャイナ教/ジナ教',
    'app.demographic.religion.response_13': '三大主体思想',
    'app.demographic.religion.response_14': 'ユダヤ教',
    'app.demographic.religion.response_15': '自然崇拝/多神教',
    'app.demographic.religion.response_16': '神道',
    'app.demographic.religion.response_17': 'シク教/シーク教',
    'app.demographic.religion.response_18': 'スピリチュアリズム/精霊崇拝/心霊主義',
    'app.demographic.religion.response_19': '信仰を有するが特定の宗教的帰属はない',
    'app.demographic.religion.response_20': '',
    'app.demographic.religion.response_21': 'ユニテリアン・ユニヴァーサリズム',
    'app.demographic.religion.response_22': 'ゾロアスター教',
    'app.demographic.religion.response_other': 'その他',
    'app.demographic.religion.no_response': 'この質問に回答したくない',
    'app.demographic.income_level.response_1': '上位1/3',
    'app.demographic.income_level.response_2': '中位1/3',
    'app.demographic.income_level.response_3': '下位1/3',
    'app.demographic.academic_rank.response_1': '上位1/3',
    'app.demographic.academic_rank.response_2': '中位1/3',
    'app.demographic.academic_rank.response_3': '下位1/3',
    'app.demographic.participated_experiences.response_1': '1年生・新入生用ゼミ',
    'app.demographic.participated_experiences.response_2': '大学/学校が実施する課外学習プログラムへの参加',
    'app.demographic.participated_experiences.response_3': 'レポート/論文作成に重点をおいた科目',
    'app.demographic.participated_experiences.response_4': '教員/専門家の指導による研究プロジェクト',
    'app.demographic.participated_experiences.response_5': '大学/学校が実施/承認した社会貢献型の体験学習（サービスラーニング）/コミュニティを対象とするプロジェクト',
    'app.demographic.participated_experiences.response_6': '大学/学校が実施/承認したインターンシップ',
    'app.demographic.participated_experiences.response_7': 'キャップストーン/卒業･修了プロジェクト',
    'app.demographic.participated_experiences.response_8': '必修科目',
    'app.demographic.participated_experiences.response_9': '他の学生との共同学修/プロジェクト',
    'app.demographic.participated_experiences.response_10': 'ダイバーシティ/グローバル教育科目または留学',
    'app.demographic.participated_experiences.response_11': '電子ポートフォリオ',
    'app.demographic.participated_experiences.response_12': 'オンライン(同期または非同期)の教育で、国際性/多文化/異文化を<b>含む</b>もの',
    'app.demographic.participated_experiences.response_13': 'オンライン(同期または非同期)の教育で、国際性/多文化/異文化を<b>含まない</b>もの', 'app.demographic.participated_learning_courses.response_1': '多文化/グローバルな問題に重点を置いた科目',
    'app.demographic.participated_learning_courses.response_2': '学内での多文化的/国際的側面に焦点を合わせた課外イベント',
    'app.demographic.participated_learning_courses.response_3': '海外での社会貢献型の体験学習（サービスラーニング）',
    'app.demographic.participated_learning_courses.response_4': '海外でのインターンシップ',
    'app.demographic.participated_learning_courses.response_5': '海外留学',
    'app.demographic.participated_learning_courses.response_6': '国際性/多文化/グローバル、また言語学習に重点を置いた教育学生寮またはコミュニティに居住',
    'app.demographic.participated_learning_courses.response_7': '母語以外の言語の学習',
    'app.demographic.participated_learning_courses.response_8': '外国のまたは異なる文化的/民族的背景を持つ人/家族との同居',
    'app.demographic.participated_learning_courses.response_9': 'ヴァーチャル･エクスチェンジ/COIL、または国際性/多文化/異文化に焦点を当てた他のオンライン/ウェブベースの学習体験',
    'app.demographic.plan_participate_experiences.response_1': '1年生・新入生用ゼミ',
    'app.demographic.plan_participate_experiences.response_2': '大学/学校が実施する課外学習プログラムへの参加',
    'app.demographic.plan_participate_experiences.response_3': 'レポート/論文作成に重点をおいた科目',
    'app.demographic.plan_participate_experiences.response_4': '教員/専門家の指導による研究プロジェクト',
    'app.demographic.plan_participate_experiences.response_5': '大学/学校が実施/承認した社会貢献型の体験学習（サービスラーニング）/コミュニティを対象とするプロジェクト',
    'app.demographic.plan_participate_experiences.response_6': '大学/学校が実施/承認したインターンシップ',
    'app.demographic.plan_participate_experiences.response_7': 'キャップストーン/卒業･修了プロジェクト',
    'app.demographic.plan_participate_experiences.response_8': '必修科目',
    'app.demographic.plan_participate_experiences.response_9': '他の学生との共同学修/プロジェクト',
    'app.demographic.plan_participate_experiences.response_10': 'ダイバーシティ/グローバル教育科目または留学',
    'app.demographic.plan_participate_experiences.response_11': '電子ポートフォリオ',
    'app.demographic.plan_participate_experiences.response_12': 'オンライン(同期または非同期)の教育で、国際性/多文化/異文化を<b>含む</b>もの',
    'app.demographic.plan_participate_experiences.response_13': 'オンライン(同期または非同期)の教育で、国際性/多文化/異文化を<b>含まない</b>もの',
    'app.demographic.plan_participate_learning_courses.response_1': '多文化/グローバルな問題に重点を置いた科目',
    'app.demographic.plan_participate_learning_courses.response_2': '学内での多文化的/国際的側面に焦点を合わせた課外イベント',
    'app.demographic.plan_participate_learning_courses.response_3': '海外での社会貢献型の体験学習（サービスラーニング）',
    'app.demographic.plan_participate_learning_courses.response_4': '海外でのインターンシップ',
    'app.demographic.plan_participate_learning_courses.response_5': '海外留学',
    'app.demographic.plan_participate_learning_courses.response_6': '国際性/多文化/グローバル、また言語学習に重点を置いた教育学生寮またはコミュニティに居住',
    'app.demographic.plan_participate_learning_courses.response_7': '母国語以外の言語の学習',
    'app.demographic.plan_participate_learning_courses.response_8': '外国のまたは異なる文化的/民族的背景を持つ人/家族との同居',
    'app.demographic.plan_participate_learning_courses.response_9': 'ヴァーチャル･エクスチェンジ/COIL、または国際性/多文化/異文化に焦点を当てた他のオンライン/ウェブベースの学習体験',
    'app.demographic.time_in_other_country.response_not_spent': '1週間未満',
    'app.demographic.time_in_other_country.response_less_2_weeks': '1週間以上2週間未満',
    'app.demographic.time_in_other_country.response_less_1_month': '2週間以上1月未満',
    'app.demographic.time_in_other_country.response_less_3_months': '1か月以上3か月未満',
    'app.demographic.time_in_other_country.response_less_6_months': '3か月以上6か月未満',
    'app.demographic.time_in_other_country.response_less_1_year': '6か月以上1年未満',
    'app.demographic.time_in_other_country.response_less_2_years': '1年以上2年未満',
    'app.demographic.time_in_other_country.response_less_5_years': '2年以上5年未満',
    'app.demographic.time_in_other_country.response_less_10_years': '5年以上10年未満',
    'app.demographic.time_in_other_country.response_10_or_more': '10年以上',
    'app.demographic.country.response_eua': 'United States of America',
    'app.demographic.country.response_afganistan': 'Afghanistan',
    'app.demographic.country.response_albania': 'Albania',
    'app.demographic.country.response_algeria': 'Algeria',
    'app.demographic.country.response_american_samoa': 'American Samoa',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armenia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australia',
    'app.demographic.country.response_austria': 'Austria',
    'app.demographic.country.response_azerbaijan': 'Azerbaijan',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarus',
    'app.demographic.country.response_belgium': 'Belgium',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benin',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolivia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnia & Herzegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brazil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgaria',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodia',
    'app.demographic.country.response_cameroon': 'Cameroon',
    'app.demographic.country.response_canada': 'Canada',
    'app.demographic.country.response_cape_verde': 'Cape Verde',
    'app.demographic.country.response_cayman_islands': 'Cayman Islands',
    'app.demographic.country.response_central_african_republic': 'Central African Republic',
    'app.demographic.country.response_chad': 'Chad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colombia',
    'app.demographic.country.response_comoros': 'Comoros',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'Congo, Democratic Republic',
    'app.demographic.country.response_cook_islands': 'Cook Islands',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Cote DIvoire',
    'app.demographic.country.response_croatia': 'Croatia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Cyprus',
    'app.demographic.country.response_czech_republic': 'Czech Republic',
    'app.demographic.country.response_denmark': 'Denmark',
    'app.demographic.country.response_djibouti': 'Djibouti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'Dominican Republic',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Egypt',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Equatorial Guinea',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estonia',
    'app.demographic.country.response_ethiopia': 'Ethiopia',
    'app.demographic.country.response_euro_area': 'Euro Area',
    'app.demographic.country.response_falkland_islands': 'Falkland Islands',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finland',
    'app.demographic.country.response_france': 'France',
    'app.demographic.country.response_french_guiana': 'French Guiana',
    'app.demographic.country.response_french_polynesia': 'French Polynesia',
    'app.demographic.country.response_gabon': 'Gabon',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgia',
    'app.demographic.country.response_germany': 'Germany',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Greece',
    'app.demographic.country.response_grenada': 'Grenada',
    'app.demographic.country.response_guadeloupe': 'Guadeloupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haiti',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Hungary',
    'app.demographic.country.response_iceland': 'Iceland',
    'app.demographic.country.response_india': 'India',
    'app.demographic.country.response_indonesia': 'Indonesia',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Ireland',
    'app.demographic.country.response_isle_of_man': 'Isle of Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italy',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': '日本',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordan',
    'app.demographic.country.response_kazakhstan': 'Kazakhstan',
    'app.demographic.country.response_kenya': 'Kenya',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kyrgyzstan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Latvia',
    'app.demographic.country.response_lebanon': 'Lebanon',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libya',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Lithuania',
    'app.demographic.country.response_luxembourg': 'Luxembourg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Macedonia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malaysia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Maldives',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Mariana',
    'app.demographic.country.response_marshall_islands': 'Marshall Islands',
    'app.demographic.country.response_martinique': 'Martinique',
    'app.demographic.country.response_mauritania': 'Mauritania',
    'app.demographic.country.response_mauritius': 'Mauritius',
    'app.demographic.country.response_mexico': 'Mexico',
    'app.demographic.country.response_micronesia': 'Micronesia',
    'app.demographic.country.response_moldova': 'Moldova',
    'app.demographic.country.response_monaco': 'Monaco',
    'app.demographic.country.response_mongolia': 'Mongolia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Morocco',
    'app.demographic.country.response_mozambique': 'Mozambique',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Netherland Antilles',
    'app.demographic.country.response_netherlands': 'Netherlands',
    'app.demographic.country.response_new_caledonia': 'New Caledonia',
    'app.demographic.country.response_new_zealand': 'New Zealand',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'North Korea',
    'app.demographic.country.response_norway': 'Norway',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panama',
    'app.demographic.country.response_papua_new_guinea': 'Papua New Guinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Peru',
    'app.demographic.country.response_phillipines': 'Philippines',
    'app.demographic.country.response_poland': 'Poland',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Reunion',
    'app.demographic.country.response_romania': 'Romania',
    'app.demographic.country.response_russian_federation': 'Russian Federation',
    'app.demographic.country.response_rwanda': 'Rwanda',
    'app.demographic.country.response_st_helena': 'St Helena',
    'app.demographic.country.response_st_kitts-nevis': 'St Kitts-Nevis',
    'app.demographic.country.response_st_lucia': 'St Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'St Vincent & Grenadines',
    'app.demographic.country.response_stateless': 'Stateless',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'Sao Tome & Principe',
    'app.demographic.country.response_saudi_arabia': 'Saudi Arabia',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Serbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapore',
    'app.demographic.country.response_slovak_republic': 'Slovak Republic',
    'app.demographic.country.response_slovenia': 'Slovenia',
    'app.demographic.country.response_solomon_islands': 'Solomon Islands',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'South Africa',
    'app.demographic.country.response_south_korea': 'South Korea',
    'app.demographic.country.response_spain': 'Spain',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Suriname',
    'app.demographic.country.response_swaziland': 'Swaziland',
    'app.demographic.country.response_sweden': 'Sweden',
    'app.demographic.country.response_switzerland': 'Switzerland',
    'app.demographic.country.response_syria': 'Syria',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tajikistan',
    'app.demographic.country.response_tanzania': 'Tanzania',
    'app.demographic.country.response_thailand': 'Thailand',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor-Leste',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad & Tobago',
    'app.demographic.country.response_tunisia': 'Tunisia',
    'app.demographic.country.response_turkey': 'Turkey',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Turks & Caicos Is',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ukraine',
    'app.demographic.country.response_united_arab_erimates': 'United Arab Erimates',
    'app.demographic.country.response_united_kingdom': 'United Kingdom',
    'app.demographic.country.response_uraguay': 'Uraguay',
    'app.demographic.country.response_uzbekistan': 'Uzbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Virgin Islands',
    'app.demographic.country.response_west_bank_and_gaza': 'West Bank and Gaza',
    'app.demographic.country.response_yemen': 'Yemen',
    'app.demographic.country.response_zambia': 'Zambia',
    'app.demographic.country.response_zimbabwe': 'Zimbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak': '母語以外の言語は話すことができない',
    'app.demographic.level_of_fluency.minimally_fluent': '最低限話せる',
    'app.demographic.level_of_fluency.somewhat_fluent': 'いくらか話せる',
    'app.demographic.level_of_fluency.moderately_fluent': '中級レベル',
    'app.demographic.level_of_fluency.mostly_fluent': 'かなり流暢',
    'app.demographic.level_of_fluency.fluent': '流暢',
    'app.demographic.level_of_interest.not_applicable': '該当なし',
    'app.demographic.level_of_interest.extremely_low': '非常に低い',
    'app.demographic.level_of_interest.very_low': '低い',
    'app.demographic.level_of_interest.low': 'やや低い',
    'app.demographic.level_of_interest.neutral': '中立',
    'app.demographic.level_of_interest.high': 'やや高い',
    'app.demographic.level_of_interest.very_high': '高い',
    'app.demographic.level_of_interest.extremely_high': '非常に高い',
    'app.demographic_slider_default_option': 'カーソルをスライドさせ回答してください',
    'app.demographic.formal_education.some_high_school_or_less': '中卒/高校中退',
    'app.demographic.formal_education.high_school_graduate': '高卒',
    'app.demographic.formal_education.some_college': '大学中退',
    'app.demographic.formal_education.college_degree': '学士号　大卒',
    'app.demographic.formal_education.some_graduate_school': '大学院中退',
    'app.demographic.formal_education.graduate_degree': '修士号',
    'app.demographic.formal_education.professional_degree': '専門職修士号',
    'app.demographic.formal_education.doctoral_degree': '博士号',
}

export const demographicQuestionsJA: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
