import React from 'react';
import { Image } from 'react-bootstrap';

import okImage from '../../assets/images/ok.png';
import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';

export const ThanksForFinishingForm = (): JSX.Element => {
    return (
        <div style={styles.container}>
            <Image src={okImage} width={150} height={150} />
            <div style={styles.titleContainer}>
                <h1>{GetFormattedMessage('app.external.title.thankyou')}</h1>
            </div>
            <div style={styles.textContainer}>
                <p style={styles.text}>{GetFormattedMessage('app.external.thankyou_static_1_1')}</p>
                {GetFormattedMessage('app.external.thankyou_static_1_2') !== '' && <p style={styles.text}>{GetFormattedMessage('app.external.thankyou_static_1_2')}</p>}
                <p style={styles.text}>{GetFormattedMessage('app.external.thankyou_static_2')}</p>
                <p style={styles.text}>{GetFormattedMessage('app.external.thankyou_static_3')}</p>
            </div>
        </div>
    );
}

const styles = {
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '10%',
        marginBottom: '10%',
    } as React.CSSProperties,
    titleContainer: {
        marginTop: 30,
        textAlign: 'center'
    } as React.CSSProperties,
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: 40,
    } as React.CSSProperties,
    text: {
        color: colors.black,
        textAlign: 'left',
    } as React.CSSProperties,
}
