import React from 'react';

import { SelectItem } from '../../../components/FormDropdown';
import {
    FormModel,
    OrganizationModel, ProgramModel, Reports, SubProgramModel
} from '../../../services/reportService';
import { SelectedSubProgramsData } from './Step1';
import { Step2 } from './Step2';
import { Step2IndividualReport } from './Step2IndividualReport';

type SelectedSubProgram = {
    organizationId: number;
    programId: number;
    subProgramId: number;
}

type Props = {
    reportType?: Reports;
    organizations: OrganizationModel[];
    selectedSubPrograms: SelectedSubProgram[];
    currentSelectedSubProgram: Partial<SelectedSubProgram>;
    isGroup: boolean;
    groupName?: string;
    groupIndex?: number;
    forms?: FormModel[];
    loadingForms?: boolean;
    selectForms?: FormModel[],
    errorValidForm?: string,
    ignoreEmails?: string[],
    lastEmailGroup?: string,
    onOrganizationSelected: (organizationId: number | undefined) => void;
    onProgramSelected: (programId: number | undefined) => void;
    onSubProgramSelected: (subProgramId: number | undefined) => void;
    onAddSubProgramClick: (index?: number) => void;
    onRemoveSubProgramClick: (subProgramId: number, index?: number) => void;
    changeGroupName?: (value: string, index: number) => void;
    openTreeModal: (index: number) => void;
    findForms: (email: string, groupIndex: number) => void;
    setSelectForms: (forms: FormModel[], groupIndex: number) => void,
};
export const Step2Container = (
    {
        reportType, organizations, selectedSubPrograms, currentSelectedSubProgram, groupName, groupIndex,
        forms, loadingForms, selectForms, errorValidForm, ignoreEmails, lastEmailGroup,
        onOrganizationSelected, onProgramSelected, onSubProgramSelected,
        onAddSubProgramClick, onRemoveSubProgramClick, changeGroupName, openTreeModal,
        findForms, setSelectForms
    }: Props
): JSX.Element => {

    const displaySelectedSubProgramData: SelectedSubProgramsData[] = selectedSubPrograms?.map(
        (s: SelectedSubProgram) => {
            const org = organizations?.find((o: OrganizationModel) => o.id === s.organizationId);
            const prog = org?.programs.find((p: ProgramModel) => p.id === s.programId);
            const subProg = prog?.subPrograms.find((sp: SubProgramModel) => sp.id === s.subProgramId);

            return {
                organizationName: org?.name ?? "",
                programName: prog?.name ?? "",
                subProgramId: subProg?.id ?? 0,
                subProgramName: subProg?.description ?? "",
            };
        }
    ) ?? [];

    const availableOrganizations: OrganizationModel[] = (selectedSubPrograms && selectedSubPrograms.length > 0 &&
        (organizations as OrganizationModel[])?.reduce<OrganizationModel[]>((orgAcc: OrganizationModel[], org: OrganizationModel) => {
            const filteredPrograms = org.programs.reduce<ProgramModel[]>((progAcc: ProgramModel[], prog: ProgramModel) => {
                const filteredSubPrograms = prog.subPrograms.reduce<SubProgramModel[]>(
                    (subProgAcc: SubProgramModel[], subProg: SubProgramModel) => {
                        if (!selectedSubPrograms.find(({ subProgramId }) => subProgramId === subProg.id)) {
                            subProgAcc.push(subProg);
                        }
                        return subProgAcc;
                    }, []);
                if (filteredSubPrograms.length > 0) progAcc.push({ ...prog, subPrograms: filteredSubPrograms });
                return progAcc;
            }, []);
            if (filteredPrograms.length > 0) orgAcc.push({ ...org, programs: filteredPrograms });
            return orgAcc;
        }, [])) || organizations;

    const selectedOrganization: OrganizationModel | null = availableOrganizations?.find(
        (org: OrganizationModel) => org.id === currentSelectedSubProgram?.organizationId
    ) ?? null;

    const organizationOptions: SelectItem[] = availableOrganizations?.map(
        (org: OrganizationModel) => ({ value: org.id, label: org.name })
    ) ?? [];
    const programOptions: SelectItem[] = selectedOrganization?.programs?.map(
        (prog: ProgramModel) => ({ value: prog.id, label: prog.name })
    ) ?? [];
    const subProgramOptions: SelectItem[] = selectedOrganization?.programs?.find(
        (prog: ProgramModel) => prog.id === currentSelectedSubProgram?.programId
    )?.subPrograms.map(
        (subProg: SubProgramModel) => ({ value: subProg.id, label: subProg.description })
    ) ?? [];

    // const isLongitudinalReport = reportType === Reports.longitudinal_report;
    // const shouldShowOrganizationAndProgramOptions = isLongitudinalReport && displaySelectedSubProgramData.length > 0;

    const selectedOrganizationOption = organizationOptions.find(
        (item: SelectItem) => item.value === currentSelectedSubProgram.organizationId) ?? null;
    const selectedProgramOption = programOptions.find(
        (item: SelectItem) => item.value === currentSelectedSubProgram.programId) ?? null;
    const selectedSubProgramOption = subProgramOptions.find(
        (item: SelectItem) => item.value === currentSelectedSubProgram.subProgramId) ?? null;

    if (reportType != null && [Reports.individual_report, Reports.longitudinal_individual_report, Reports.partner_report, Reports.partner_change_report].includes(reportType)) {
        return <Step2IndividualReport
            forms={forms}
            loadingForms={loadingForms}
            reportType={reportType}
            findForms={findForms}
            selectForms={selectForms}
            setSelectForms={setSelectForms}
            errorValidForm={errorValidForm}
            groupIndex={groupIndex}
            changeGroupName={changeGroupName}
            ignoreEmails={ignoreEmails}
            lastEmailGroup={lastEmailGroup}
        />;
    }

    return (
        <Step2
            selectedOrganizationOption={selectedOrganizationOption}
            selectedProgramOption={selectedProgramOption}
            selectedSubProgramOption={selectedSubProgramOption}
            organizationOptions={organizationOptions}
            programOptions={programOptions}
            subProgramOptions={subProgramOptions}
            selectedSubPrograms={displaySelectedSubProgramData}
            groupIndex={groupIndex}
            groupName={groupName}
            onOrganizationSelected={onOrganizationSelected}
            onProgramSelected={onProgramSelected}
            onSubProgramSelected={onSubProgramSelected}
            onAddSubProgramClick={onAddSubProgramClick}
            onRemoveSubProgramClick={onRemoveSubProgramClick}
            changeGroupName={changeGroupName}
            openTreeModal={openTreeModal}
        />
    );
}
