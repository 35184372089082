import { Input, InputNumber, Space, Table, Tag, Button as ButtonAntd } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Button, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { IntlContext } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { TableFilterWithInput } from '../../../components/TableFilterWithInput';
import { TableFilterWithRadioButton } from '../../../components/TableFilterWithRadioButton';
import { paginationDefaultPageSize } from '../../../config/constants';
import { TokenInfo } from '../../../services/loginService';
import { CustomAgreementStatus, UserFormDetailsSubProgram } from '../../../services/programService';
import { getCustomAgreementStatusKey, getInvalidStatusReason, getStatusValue } from '../../../utils/formHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { HasPermissionCapability, IsAccountManager, IsInstitutional, IsSuperAdmin } from '../../../utils/userHelper';
import { FaRedo } from 'react-icons/fa';
import { MdSend } from 'react-icons/md';
import { UserPermissions } from '../../../services/usersService';
import { UserAiInteractionsInput } from './UserAiInteractionsInput';

type Props = {
  users: UserFormDetailsSubProgram[];
  showReportClick?: (formId: number) => void;
  sendCustomAgreementButtonClick: (formId: number) => void;
  userInfo: TokenInfo;
};

export const UserListForms = ({
  users, userInfo, showReportClick, sendCustomAgreementButtonClick
}: Props): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

  const defaultPagination: TablePaginationConfig = {
    position: ['topCenter', 'bottomCenter'],
    pageSize: pageSize,
    onChange: (_: number, newPageSize?: number) => {
      if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
    }
  }

  const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder')
  const searchConfirmButtonText = GetFormattedMessage('app.btn_search')
  const searchResetButtonText = GetFormattedMessage('app.btn_reset')

  const { messages } = React.useContext(IntlContext);

  return (
    <Table
      dataSource={users}
      rowKey={(record: UserFormDetailsSubProgram) => record.userId.toString()}
      locale={{ emptyText: GetFormattedMessage('app.empty.data') }}
      pagination={users.length > paginationDefaultPageSize ? defaultPagination : false}
    >
      <Column
        key='userName'
        title={GetFormattedMessage('app.listforms.participant')}
        dataIndex='name'
        width='25%'
        sorter={(a: UserFormDetailsSubProgram, b: UserFormDetailsSubProgram) => (
          a.name.localeCompare(b.name)
        )}
        filterDropdown={(props: FilterDropdownProps) => (
          <TableFilterWithInput
            inputPlaceholder={searchInputPlaceholder}
            confirmButtonText={searchConfirmButtonText}
            resetButtonText={searchResetButtonText}
            {...props}
          />
        )}
        filterIcon={(filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        )}
        onFilter={(value, record: UserFormDetailsSubProgram) =>
          record.name.toLowerCase().includes(value.toString().toLowerCase()
          )}
      />
      <Column
        title={GetFormattedMessage('app.listforms.progress')}
        dataIndex='completedPercentage'
        key='completedPercentage'
        width='20%'
        sorter={(a: UserFormDetailsSubProgram, b: UserFormDetailsSubProgram) => (
          a.completedPercentage - b.completedPercentage
        )}
        render={percentage => (
          <ProgressBar now={percentage * 100} label={`${percentage * 100} %`} />
        )}
      />
      <Column
        title={GetFormattedMessage('app.listforms.status')}
        dataIndex='formStatus'
        key='formStatus'
        align='center'
        width='15%'
        render={formStatus => (
          <Tag
            color={formStatus === 5 ? 'green' : (formStatus === 6 || formStatus === 7 ? 'red' : 'blue')}
            key={formStatus}>
            {messages[getStatusValue(formStatus)].toString()}
          </Tag>
        )}
        sorter={(a: UserFormDetailsSubProgram, b: UserFormDetailsSubProgram) => a.formStatus - b.formStatus}
        filters={[
          { text: messages[getStatusValue(1)].toString(), value: '1' },
          { text: messages[getStatusValue(2)].toString(), value: '2' },
          { text: messages[getStatusValue(3)].toString(), value: '3' },
          { text: messages[getStatusValue(4)].toString(), value: '4' },
          { text: messages[getStatusValue(5)].toString(), value: '5' },
          { text: messages[getStatusValue(6)].toString(), value: '6' },
          { text: messages[getStatusValue(7)].toString(), value: '7' },
        ]}
        filterDropdown={(props: FilterDropdownProps) => (
          <TableFilterWithRadioButton
            {...props}
            confirmButtonText={searchConfirmButtonText}
            resetButtonText={searchResetButtonText}
          />
        )}
        onFilter={(value, record: UserFormDetailsSubProgram) => value === record.formStatus.toString()}
      />
      {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) &&
        <Column
          title={GetFormattedMessage('app.user_programs.invalid_reason')}
          dataIndex={'validationFormStatus'}
          key={'validationFormStatus'}
          width={'15%'}
          render={(_: string, record: any) => {
            if (record.formStatus !== 9) return null;

            const invalidStatusReason = getInvalidStatusReason(record.validationFormStatus) ?? '';
            return (
              <span key={'ValidationFormStatus' + record.formId}>
                {messages[invalidStatusReason].toString()}
              </span>
            );
          }}
        />
      }
      {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) &&
        <Column
          title={GetFormattedMessage('app.listforms.ai_interactions')}
          dataIndex={'validationFormStatus'}
          key={'validationFormStatus'}
          width={'15%'}
          render={(_: string, record: any) => {
            return (
              <UserAiInteractionsInput record={record} />
            );
          }}
        />
      }
      {(IsSuperAdmin(userInfo) || HasPermissionCapability(userInfo, UserPermissions.canVisualizeFullIndividualReport)) && (
        <React.Fragment>
          <Column
            title={GetFormattedMessage('app.listforms.custom_agreement_status')}
            key="action"
            render={(_, record: UserFormDetailsSubProgram) => {
              const customAgreementStatusText = getCustomAgreementStatusKey(record.customAgreementStatus) ?? '';
              return (
                <span key={'CustomAgreementStatusText' + record.formId}>
                  {messages[customAgreementStatusText].toString()}
                </span>
              );
            }}
          />
          <Column
            title={GetFormattedMessage('app.listforms.action')}
            key="action"
            render={(_, record: UserFormDetailsSubProgram) => (
              <React.Fragment>
                {record.customAgreementStatus === CustomAgreementStatus.NotRequested && (
                  <OverlayTrigger
                    key={`SendTooltipTrigger-${record.formId}`}
                    placement={'auto'}
                    trigger={["hover", "hover"]}
                    overlay={
                      <Tooltip id={`SendTooltip`} key={`SendTooltip-${record.formId}`}>
                        {messages['app.form.actions.tooltip.send_custom_agreement'].toString()}
                      </Tooltip>
                    }
                  >
                    <Button
                      onClick={() => sendCustomAgreementButtonClick(record.formId)}
                      style={{ color: '#FFFFFF' }}
                      variant={"info"}
                    >
                      <MdSend size={16} />
                    </Button>
                  </OverlayTrigger>
                )}
                {record.customAgreementStatus === CustomAgreementStatus.Rejected && (
                  <OverlayTrigger
                    key={`ResendTooltipTrigger-${record.formId}`}
                    placement={'auto'}
                    trigger={["hover", "hover"]}
                    overlay={
                      <Tooltip id={`SendTooltip`} key={`ResendTooltip-${record.formId}`}>
                        {messages['app.form.actions.tooltip.resend_custom_agreement'].toString()}
                      </Tooltip>
                    }
                  >
                    <Button
                      onClick={() => sendCustomAgreementButtonClick(record.formId)}
                      variant={"info"}
                    >
                      <FaRedo size={16} />
                    </Button>
                  </OverlayTrigger>
                )}
                {record.isValidReport === true && record.formStatus === 5 && (
                  <OverlayTrigger
                    key={`ShowReportTooltipTrigger-${record.formId}`}
                    placement={'auto'}
                    trigger={["hover", "hover"]}
                    overlay={
                      <Tooltip id={`ShowReportTooltip`} key={`ResendTooltip-${record.formId}`}>
                        {messages['app.form.actions.tooltip.show_report'].toString()}
                      </Tooltip>
                    }
                  >
                    <Button
                      onClick={() => showReportClick && showReportClick(record.formId)}
                      variant={"success"}
                    >
                      <AiOutlineAreaChart size={16} />
                    </Button>
                  </OverlayTrigger>
                )}
              </React.Fragment>
            )}
          />
        </React.Fragment>
      )}
    </Table>
  );
}
