import { TimeMessages } from '../types';

export const timeDE: TimeMessages = {
  'app.time.day': 'Tag',
  'app.time.days': 'Tage',
  'app.time.hour': 'Stunde',
  'app.time.hours': 'Stunden',
  'app.time.minute': 'Minute',
  'app.time.minutes': 'Minuten',
  'app.time.second': 'Sekunde',
  'app.time.seconds': 'Sekunden',
  'app.time.and': 'und',
}
