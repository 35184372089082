import React from 'react';
import { ScaleGroup } from '../../../services/reportService';
import { GroupReportAggregateByDemographics_Education } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { AggregateProfileByDemographics } from './AggregateProfileByDemographics';
import { InsufficientDataComponent } from './InsufficientDataComponent';
import { getScaleTitle } from '../../../utils/reportHelper';

type Props = {
    reportEducation: GroupReportAggregateByDemographics_Education[];
    subProgramName?: string[]
}

type ScaleProps = {
    title: string;
    value: number;
    hasSpace: boolean;
}

const hasEnoughData = (report: GroupReportAggregateByDemographics_Education[]) => {
    let invalidReportCount = 0;
    for (let i = 0; i < report.length; i++) {
        if (!report[i].hasEnoughData) {
            invalidReportCount += 1;
        }
    }

    if (report.length === 1) {
        if (invalidReportCount === 0) return true;
        return false;
    }
    else {
        return report.length - invalidReportCount > 1;
    }
}

const buildSubTitle = (report: GroupReportAggregateByDemographics_Education[],
    subProgramName?: string[]) => {

    if (report.length === 1) return undefined;
    const subTitle: string[][] = [];
    for (let i = 0; i < report.length; i++) {
        if (!report[i].hasEnoughData) continue;

        subTitle.push(
            [
                `${subProgramName && subProgramName[i] + ' - '}${report[i].lowestRange.length > 0 ? report[i].lowestRange[0] : ''} - ${report[i].lowestRange.length > 1 ? report[i].lowestRange[1] : ''}`,
                `${subProgramName && subProgramName[i] + ' - '}${report[i].highestRange.length > 0 ? report[i].highestRange[0] : ''} - ${report[i].highestRange.length > 1 ? report[i].highestRange[1] : ''}`,
            ]
        )

    }
    return subTitle;
}

const buildScales = (report: GroupReportAggregateByDemographics_Education[],
    scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {

    const scalePropos: ScaleProps[] = [];
    const scaleTitle = getScaleTitle(scale);

    const lowest = GetFormattedMessage('app.report.aggregate_profile.education.lowest_30');
    const highest = GetFormattedMessage('app.report.aggregate_profile.education.highest_30');

    for (let i = 0; i < report.length; i++) {

        if (!report[i].hasEnoughData) continue;

        const reportItem = report[i];
        const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

        scalePropos.push({
            //@ts-ignore
            title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${lowest})`,
            value: reportItem.scales[scale][0],
            hasSpace: i === 0 && name !== ''
        });
    }

    for (let i = 0; i < report.length; i++) {

        if (!report[i].hasEnoughData) continue;

        const reportItem = report[i];
        const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

        scalePropos.push({
            //@ts-ignore
            title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${highest})`,
            value: reportItem.scales[scale][1],
            hasSpace: i === 0 && name !== ''
        });
    }

    return scalePropos;
}

export const AggregateProfileByDemographics_Education = ({ reportEducation, subProgramName }: Props) => {

    if (!hasEnoughData(reportEducation)) {
        return <InsufficientDataComponent />
    }

    const lowest = GetFormattedMessage('app.report.aggregate_profile.education.lowest_30');
    const highest = GetFormattedMessage('app.report.aggregate_profile.education.highest_30');
    return (
        <AggregateProfileByDemographics
            title={[
                `${lowest} (N = ${reportEducation.map(item => item.lowestNumberOfUsers).reduce((prev, next) => prev + next)})`,
                `${highest} (N = ${reportEducation.map(item => item.highestNumberOfUsers).reduce((prev, next) => prev + next)})`,
            ]}
            subTitle={buildSubTitle(reportEducation, subProgramName)}
            negativeLifeEvents={buildScales(reportEducation, ScaleGroup.NegativeLifeEvents, 1, subProgramName)}
            needsClosure={buildScales(reportEducation, ScaleGroup.NeedsClosure, 2, subProgramName)}
            needsFulfillment={buildScales(reportEducation, ScaleGroup.NeedsFulfillment, 3, subProgramName)}
            identityDiffusion={buildScales(reportEducation, ScaleGroup.IdentityDiffusion, 4, subProgramName)}
            basicOpenness={buildScales(reportEducation, ScaleGroup.BasicOpenness, 5, subProgramName)}
            selfCertitude={buildScales(reportEducation, ScaleGroup.SelfCertitude, 6, subProgramName)}
            basicDeterminism={buildScales(reportEducation, ScaleGroup.BasicDeterminism, 7, subProgramName)}
            socioemotionalConvergence={buildScales(reportEducation, ScaleGroup.SocioemotionalConvergence, 8, subProgramName)}
            physicalResonance={buildScales(reportEducation, ScaleGroup.PhysicalResonance, 9, subProgramName)}
            emotionalAttunument={buildScales(reportEducation, ScaleGroup.EmotionalAttunement, 10, subProgramName)}
            selfAwereness={buildScales(reportEducation, ScaleGroup.SelfAwereness, 11, subProgramName)}
            meaningQuest={buildScales(reportEducation, ScaleGroup.MeaningQuest, 12, subProgramName)}
            religiousTraditionalism={buildScales(reportEducation, ScaleGroup.ReligiousTraditionalism, 13, subProgramName)}
            genderTraditionalism={buildScales(reportEducation, ScaleGroup.GenderTraditionalism, 14, subProgramName)}
            socioCulturalOpenness={buildScales(reportEducation, ScaleGroup.SocioculturalOpenness, 15, subProgramName)}
            ecologicalResonance={buildScales(reportEducation, ScaleGroup.EcologicalResonance, 16, subProgramName)}
            globalResonance={buildScales(reportEducation, ScaleGroup.GlobalResonance, 17, subProgramName)}
        />
    )
}