import React from 'react';
import { ReportProps } from './ReportTypes';
import { ReportView } from './ReportView';

export const ComparativeIndividualReport = (props: ReportProps): JSX.Element => {

    return (
        <React.Fragment>
            <ReportView {...props}/>
        </React.Fragment>
    );
}
