import { CreateReportMessages } from '../types';

export const createReportJA: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'Fake name to all participants',
    'app.create_report.btn_real_name_all': 'Real name to all participants',
    'app.create_report.btn_fake_name': 'Use fake name',
    'app.create_report.btn_real_name': 'Use real name',
    'app.create_report.title': 'レポートの管理',
    'app.create_report.step1': 'STEP 1 -レポートの構成・作成',
    'app.create_report.step2': '(JA) STEP 2 - ADD REPORT GROUP',
    'app.create_report.step3': 'STEP 3 -レポートのオプション設定',
    'app.create_report.step3Individual': 'STEP 3 - WHO CAN SEE THE REPORT?',
    'app.create_report.step4': 'STEP 4 -受検者の管理',
    'app.create_report.step3_username_column': 'ユーザー名',
    'app.create_report.step3_aliasname_column': '匿名',
    'app.create_report.step3_action_column': '選択',
    'app.create_report.step3_no_user_title': '受検者は見つかりませんでした',
    'app.create_report.step3_no_user_subtitle': '検索オプションを変更し、再度受検者を検索をしてください',
    'app.create_report.step3_user_name_column_title': '受検者',
    'app.create_report.step3_user_exporting_column_title': '反映',
    'app.create_report.name': 'レポート名',
    'app.create_report.requester': '依頼者',
    'app.create_report.report_type': 'レポートの種類',
    'app.create_report.select_subprogram': 'レポートに使用するデータの選択',
    'app.create_report.select_subprogram_warning': '上記のフィールドを記入してください',
    'app.create_report.group_report': 'グループ･レポート',
    'app.create_report.longitudinal_report': 'グループ変更レポート',
    'app.create_report.comparative_by_group_report': 'グループ比較レポート',
    'app.create_report.comparative_by_individual_report': '個人比較レポート',
    'app.create_report.select_organization': '大学/機関を選択',
    'app.create_report.select_program': 'プログラムを選択',
    'app.create_report.select_sub_program': 'サブグループを選択',
    'app.create_report.organization_name': '大学/機関',
    'app.create_report.program_name': 'プログラム',
    'app.create_report.sub_program_name': 'サブグループ',
    'app.create_report.actions': '選択',
    'app.create_report.action_remove': '削除',
    'app.create_report.show_filters': '検索条件の表示',
    'app.create_report.hide_filters': '検索条件の非表示',
    'app.create_report.filter_select_default_option': '選択...',
    'app.create_report.filter_select_with_values': 'Filtering',
    'app.create_report.filter_age': '年齢',
    'app.create_report.filter_gender': '性別',
    'app.create_report.filter_years_of_education': '正規教育の期間',
    'app.create_report.filter_primary_country_of_origin': '出身国',
    'app.create_report.filter_marital_status': '配偶者',
    'app.create_report.filter_political_orientation': '政治的志向（保守的－ 進歩的）',
    'app.create_report.filter_ethnic_background': '民族的背景',
    'app.create_report.filter_religious_orientation': '宗教的志向',
    'app.create_report.filter_religious_commitment': '宗教的志向のレベル',
    'app.create_report.filter_academic_rank': '成績のランク',
    'app.create_report.filter_time_spent_in_other_countries': '出身国以外での滞在期間',
    'app.create_report.filter_nro_visited_countries': '訪問国数',
    'app.create_report.filter_fluency': '言語の流暢さ',
    'app.create_report.filter_years_of_language_study': '言語学習の年数',
    'app.create_report.filter_mother_country': '母親の出身国',
    'app.create_report.filter_father_country': '父親の出身国',
    'app.create_report.filter_mother_education': '母親の学歴',
    'app.create_report.filter_father_education': '父親の学歴',
    'app.create_report.filter_minority_group': 'マイノリティ',
    'app.create_report.filter_personal_interest': '国際的経験への関心度',
    'app.create_report.filter_study_abroad_satisfaction': '国際的経験の満足度',
    'app.create_report.filter_primary_area_of_work': '専攻分野/専門分野',
    'app.create_report.filter_area_of_occupations': '専攻分野/専門分野の詳細',
    'app.create_report.filter_income_level_of_your_home_country': '収入･資産レベル',
    'app.create_report.btn_search': '検索',
    'app.create_report.btn_save': '作成',
    'app.create_report.placeholder.name': 'レポート名を記入しないと、他のオプションの選択は出来ません。',
    'app.create_report.select.report_type': 'レポートタイプを選択',
    'app.create_report.select_countries_options_lbl': '比較対象国を選択（複数可）:',
    'app.create_report.selected_countries_lbl': '選択された国',
    'app.create_report.select_ethnics_options_lbl': '比較対象の民族的背景を選択（複数可）:',
    'app.create_report.selected_ethnics_lbl': '選択された民族的背景',
    'app.create_report.select_number_of_forms_lbl': '定性的コメントの最大数',
    'app.create_report.summary_line1': 'BEVI Management Systemでは、対象とするサブグループの全のデータに基づいたレポートを作成することができます。レポートには4つの種類があります。<br />' +
        '&nbsp;&nbsp;1) グループ・レポート（ある特定時点でのレポート）<br />' +
        '&nbsp;&nbsp;2) T1/T2/Txレポート（T1、T2、T3などの時間軸を用いた比較レポート）<br />' +
        '&nbsp;&nbsp;3) グループ比較レポート（大学/機関内の異なるグループを比較するレポート）<br />' +
        '&nbsp;&nbsp;4) 個人比較レポート（グループ内の匿名化された個人をBEVI尺度間で比較するレポート）',
    'app.create_report.summary_line2': 'これらのレポートは、デフォルトでは、サブグループに属する全ての受検者の尺度及び背景情報に基づく結果を表示します。これらの4種類のレポートは、また「STEP 2 -レポートのオプション設定」で条件を組み合わせたデータセットに基づき作成することも可能です（例えば、2つの国からの受検者のみを選択し、これらに属する女性と男性を対象としたレポートなど）。このオプション設定機能を利用することにより、より詳細な分析を行うことが可能となります。',
    'app.create_report.select_relationship_status_options_lbl': '他のすべてと対比するために、少なくとも1つの関係ステータスを選択します。',
    'app.create_report.select_religion_options_lbl': '他のすべてと対比するために、少なくとも1つの宗教を選択してください。',
    'app.create_report.select_primary_area_work_options_lbl': '他のすべてと対比するために、少なくとも1つの主要な作業領域を選択してください。',
    'app.create_report.selected_relationship_status_lbl': '選択された関係ステータス',
    'app.create_report.selected_religion_lbl': '選択された宗教的オリエンテーション',
    'app.create_report.selected_primary_area_work_lbl': '選択された主要な作業領域',
    'app.create_report.individual_report': '個別報告書',
    'app.create_report.longitudinal_individual_report': '個人変更報告書',
    'app.create_report.combined_report': 'パートナーレポート',
    'app.create_report.individual': '個人',
    'app.create_report.partner': '相棒',
    'app.create_report.group': 'グループ',
    'app.create_report.select.group': 'グループを選択してください',
    'app.create_report.partner_change_report': 'パートナー変更レポート',
    'app.create_report.email_in_use': '使用中の電子メール',
    'app.create_report.add_new_participant': '新しい参加者を追加'
}
