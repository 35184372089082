import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FaPlus, FaTimes, } from 'react-icons/fa';
import { AppstoreAddOutlined } from '@ant-design/icons';

import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

export type SelectedSubProgramsData = {
    organizationName: string;
    programName: string;
    subProgramId: number;
    subProgramName: string;
};

type Props = {
    selectedOrganizationOption: SelectItem | null;
    selectedProgramOption: SelectItem | null;
    selectedSubProgramOption: SelectItem | null;
    organizationOptions: SelectItem[];
    programOptions: SelectItem[];
    subProgramOptions: SelectItem[];
    selectedSubPrograms: SelectedSubProgramsData[];
    groupName?: string;
    groupIndex?: number;
    onOrganizationSelected: (organizationId: number | undefined) => void;
    onProgramSelected: (programId: number | undefined) => void;
    onSubProgramSelected: (subProgramId: number | undefined) => void;
    onAddSubProgramClick: (index?: number) => void;
    onRemoveSubProgramClick: (subProgramId: number, index?: number) => void;
    changeGroupName?: (value: string, index: number) => void;
    openTreeModal: (index: number) => void;
};

export const Step2 = (
    {
        selectedOrganizationOption, selectedProgramOption, selectedSubProgramOption, groupIndex,
        organizationOptions, programOptions, subProgramOptions, selectedSubPrograms, groupName,
        onOrganizationSelected, onProgramSelected, onSubProgramSelected, onAddSubProgramClick,
        onRemoveSubProgramClick, changeGroupName, openTreeModal
    }: Props
): JSX.Element => {

    const removeTooltip = GetFormattedMessage('app.create_report.action_remove');
    const columns: ColumnProps<SelectedSubProgramsData>[] = [
        {
            title: GetFormattedMessage('app.create_report.organization_name'),
            dataIndex: 'organizationName',
        },
        {
            title: GetFormattedMessage('app.create_report.program_name'),
            dataIndex: 'programName',
        },
        {
            title: GetFormattedMessage('app.create_report.sub_program_name'),
            dataIndex: 'subProgramName',
        },
        {
            title: GetFormattedMessage('app.create_report.actions'),
            dataIndex: 'actions',
            align: 'center',
            render: (_: string, record: SelectedSubProgramsData) => {
                const id = record.organizationName + record.programName + record.subProgramName;
                return (
                    <div style={styles.alignCenter} key={'BTN' + id}>
                        <OverlayTrigger
                            key={`action_remove-${id}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`action_remove`} key={`actionRemoveTooltip-${id}`}>
                                    {removeTooltip}
                                </Tooltip>
                            }
                        >
                            <FaTimes
                                size={20}
                                color={colors.red}
                                onClick={() => onRemoveSubProgramClick(record.subProgramId, groupIndex)}
                            />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    return (
        // @ts-ignore
        <Form style={styles.formDiv}>
            <Form.Group className='mb-3'>
                {groupIndex != null && <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label><b>Group Name</b></Form.Label>
                            <Form.Control
                                value={groupName}
                                placeholder={GetFormattedMessage('app.create_report.placeholder.name')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    changeGroupName!(e.target.value, groupIndex)
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <b>Organization</b>
                        <FormDropdown
                            value={selectedOrganizationOption}
                            isSearchable={true}
                            defaultOptionId={'app.create_report.select_organization'}
                            onValueSelect={(value: number | undefined, _: string) => onOrganizationSelected(value)}
                            optionItens={organizationOptions}
                        />
                    </Col>
                    <Col>
                        <b>Program or Experience</b>
                        <FormDropdown
                            value={selectedProgramOption}
                            isSearchable={true}
                            defaultOptionId={'app.create_report.select_program'}
                            onValueSelect={(value: number | undefined, _: string) => onProgramSelected(value)}
                            optionItens={programOptions}
                            isDisabled={programOptions.length <= 0}
                        />
                    </Col>
                    <Col>
                        <b>BEVI Administration</b>
                        <FormDropdown
                            value={selectedSubProgramOption}
                            isSearchable={true}
                            defaultOptionId={'app.create_report.select_sub_program'}
                            onValueSelect={(value: number | undefined, _: string) => onSubProgramSelected(value)}
                            optionItens={subProgramOptions}
                            isDisabled={subProgramOptions.length <= 0}
                        />
                    </Col>
                    <Col md={1}>
                        <div style={{ height: '100%', position: 'relative', display: 'flex', gap: 2, alignItems: 'flex-end' }}>
                            <Button
                                style={{ height: 38 }}
                                disabled={selectedSubProgramOption === undefined}
                                className='w-100'
                                onClick={() => onAddSubProgramClick(groupIndex)}
                            >
                                <FaPlus color={colors.white} />
                            </Button>
                            <Button
                                style={{ height: 38 }}
                                disabled={selectedSubProgramOption === undefined}
                                className='w-100'
                                onClick={() => openTreeModal(groupIndex!)}
                            >
                                <AppstoreAddOutlined style={{ fontSize: 18 }} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
            {selectedSubPrograms?.length > 0 &&
                <Table
                    columns={columns}
                    dataSource={selectedSubPrograms}
                    pagination={false}
                    rowKey={(record: SelectedSubProgramsData) => record.subProgramId.toString()}
                />
            }
        </Form>
    )
}

const styles = {
    formDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
}
