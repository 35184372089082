import { ListOrganizationsMessages } from '../types';

export const listOrganizationsJA: ListOrganizationsMessages = {
    'app.listorganizations.created.on': '作成日',
    'app.listorganizations.active': 'Active',
    'app.listorganizations.inactive': 'Inactive',
    'app.listorganizations.new.program': '新規プログラム/サブグループの作成',
    'app.listorganizations.new.group': '新規サブグループの作成',
    'app.listorganizations.view': 'View',
    'app.listorganizations.report': 'Report',
    'app.listorganizations.details': '詳細',
    'app.listorganizations.tooltip.invite_by_qr_code': 'QRコードを用いて、新規ユーザーを登録し、BEVIの受検を可能にします。',
    'app.listorganizations.tooltip.sub_program_edit': 'サブグループの編集',
    'app.listorganizations.table.beviname': 'サブグループ',
    'app.listorganizations.table.created_on': '作成日',
    'app.listorganizations.table.due_date': '期限',
    'app.listorganizations.table.completed_percentage': '進捗率',
    'app.listorganizations.table.status': '状態',
    'app.listorganizations.table.actions': '選択',
    'app.listorganizations.select_organization': '大学/機関を選択',
    'app.listorganizations.search': '検索',
    'app.listorganizations.new_organization': '新規大学/機関',
    'app.listorganizations.input_program_placeholder': 'プログラム名...',
    'app.listorganizations.input_subprogram_placeholder': 'サブグループ名...',
    'app.listorganizations.organization_area': 'プログラム/サブグループ管理',
    'app.listorganizations.download.data_report': 'データのダウンロード',
    "app.listorganizations.sort_name_date": "名前または日付で並べ替える",
    "app.listorganizations.sort_by": "並び替え",
    'app.sample.data.footer.message_line1': '<b><i>注記。</i> BEVI レポート システムでは、8 つの異なるレポート タイプの開発が可能です。1) 個人レポート、2) 個人変更レポート、3) パートナー レポート、4) パートナー変更レポート、5) グループ レポート、6) グループ変更レポート、7) 内 グループレポート、および 8) グループレポート間。 これらのレポートがどのように機能するか、およびアクセスできる情報の種類を理解するために、各レポート タイプの 2 つの例 (A と B) が提供されています。</b>',
    'app.sample.data.footer.message_line2': '<b>サンプル レポートは BEVI システムの実際のレポートと一致していますが、これらの例は説明のみを目的として提供されています。 そのため、これらのサンプルレポートの一部の情報は、結果の匿名化および/または特定の個人への関連付けの解除を確実にするために、変更（スケールスコアなど）または削除（定性的回答など）されている場合があります。</b>',
    'app.sample.data.footer.message_line3': '<b>これらのサンプル レポート、または BEVI のその他の側面についてご質問がある場合は、BEVI 管理アクセスを許可した個人にお問い合わせいただくか、次の BEVI チームにお問い合わせください。<a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
