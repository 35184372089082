import { Input, Table } from 'antd';
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Accordion, Card, FormCheck, useAccordionButton } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { SearchOutlined } from '@ant-design/icons';

import { BootstrapButtonWithIcon } from '../../../components/ButtonWithIcon';
import { TableFilterWithInput } from '../../../components/TableFilterWithInput';
import { colors } from '../../../config/colors';
import { paginationDefaultPageSize } from '../../../config/constants';
import { TokenInfo } from '../../../services/loginService';
import { ParticipantModel } from '../../../services/reportService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { IsSuperAdmin } from '../../../utils/userHelper';
import { UserFormForView, UserFormsPerOrganizationForView } from './Step4Helper';

type Props = {
    userInfo: TokenInfo;
    userForms: UserFormsPerOrganizationForView[];
    onUserFormReportChanged: (participants: ParticipantModel[], changeAlias: boolean) => void;
};

export const Step4ComparativeByIndividual = ({ userInfo, userForms, onUserFormReportChanged }: Props): JSX.Element => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const defaultActiveKey = userForms?.length > 0 ? `${userForms[0].name}-0` : '';

    const disableFakeAll = userForms.find((org: UserFormsPerOrganizationForView) =>
        org.users.find((userForm: UserFormForView) =>
            userForm.alias !== userForm.fakeAlias && userForm.exporting === true
        )) === undefined;
    const disableRealAll = userForms.find((org: UserFormsPerOrganizationForView) =>
        org.users.find((userForm: UserFormForView) =>
            userForm.alias !== userForm.userName && userForm.exporting === true
        )) === undefined;

    const ChangeAllAliasName = (isFake: boolean) => {
        let participants: ParticipantModel[] = [];
        userForms.forEach((org: UserFormsPerOrganizationForView) => {
            org.users.forEach((userForm: UserFormForView) => {
                participants.push({
                    id: userForm.formId,
                    alias: isFake ? userForm.fakeAlias : userForm.userName,
                })
            })
        })
        onUserFormReportChanged(participants, /*changeAlias*/ true);
    }

    const isSuperAdmin = IsSuperAdmin(userInfo);

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: any, record: UserFormForView) =>
            record.userName.toLowerCase().includes(value.toString().toLowerCase()),
    }
    const columns: ColumnType<UserFormForView>[] = [
        {
            title: GetFormattedMessage('app.create_report.step3_user_exporting_column_title'),
            key: 'user-exporting-column',
            width: '15%',
            align: 'center',
            sorter: (a: UserFormForView, b: UserFormForView) => Number(a.exporting) - Number(b.exporting),
            render: (_: string, record: UserFormForView) => {
                const userKey = record.userName + record.formId + record.fakeAlias;
                return (
                    <FormCheck
                        id={userKey}
                        key={userKey}
                        type='switch'
                        checked={record.exporting}
                        label={''}
                        onChange={() => {
                            const participants: ParticipantModel[] = [
                                { id: record.formId, alias: record.alias }
                            ];
                            onUserFormReportChanged(participants, /*changeAlias*/ false);
                        }}
                    />
                );
            },
        },
        {
            title: GetFormattedMessage('app.create_report.step3_aliasname_column'),
            dataIndex: 'alias',
            align: 'center',
            key: 'alias-key',
            width: isSuperAdmin === true ? '30%' : '80%',
            render: (_: string, record: UserFormForView) => (
                <Input
                    disabled={!record.exporting}
                    value={record.alias}
                    onChange={(e) => {
                        const participants: ParticipantModel[] = [
                            { id: record.formId, alias: e.currentTarget.value }
                        ];
                        onUserFormReportChanged(participants, /*changeAlias*/ true)
                    }}
                />
            ),
            sorter: (a: UserFormForView, b: UserFormForView) => a.userName.localeCompare(b.userName),
            ...filterOptions
        },
        isSuperAdmin === true ? {
            title: GetFormattedMessage('app.create_report.step3_action_column'),
            key: 'action-key',
            width: '25%',
            align: 'center',
            render: (_: string, record: UserFormForView) => {
                return (
                    isSuperAdmin &&
                    <BootstrapButtonWithIcon
                        variant='primary'
                        // @ts-ignore
                        style={styles.buttonRow}
                        textColor={colors.white}
                        textId={record.alias !== record.userName ?
                            'app.create_report.btn_real_name' : 'app.create_report.btn_fake_name'
                        }
                        onClick={() => {
                            const participants: ParticipantModel[] = [{
                                id: record.formId,
                                alias: record.alias !== record.userName ? record.userName : record.fakeAlias
                            }];
                            onUserFormReportChanged(participants, /*changeAlias*/ true)
                        }}
                        icon={record.alias !== record.userName ?
                            <FaEye style={styles.iconSpace} size={15} />
                            :
                            <FaEyeSlash style={styles.iconSpace} size={15} />
                        }
                    />
                )
            }
        } : {},
    ]

    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <div onClick={decoratedOnClick} style={styles.accordionToogle}>
                {children}
            </div>
        );
    }

    return (
        <React.Fragment>
            {isSuperAdmin &&
                <div style={styles.button}>
                    <BootstrapButtonWithIcon
                        variant={disableRealAll ? 'secondary' : 'info'}
                        // @ts-ignore
                        style={styles.buttonWithText}
                        disabled={disableRealAll}
                        onClick={() => ChangeAllAliasName(/*isFake*/ false)}
                        icon={<FaEye style={styles.iconSpace} size={15} />}
                        textId='app.create_report.btn_real_name_all'
                        textColor={colors.white}
                    />
                    <BootstrapButtonWithIcon
                        variant={disableFakeAll ? 'secondary' : 'info'}
                        // @ts-ignore
                        style={styles.buttonWithText}
                        disabled={disableFakeAll}
                        onClick={() => ChangeAllAliasName(/*isFake*/ true)}
                        icon={<FaEyeSlash style={styles.iconSpace} size={15} />}
                        textId='app.create_report.btn_fake_name_all'
                        textColor={colors.white}
                    />
                </div>
            }
            <Accordion
                // @ts-ignore
                style={styles.accordionDiv}
                defaultActiveKey={defaultActiveKey}
            >
                {userForms.map((org: UserFormsPerOrganizationForView, index: number) => {
                    const eventKeyOrganization = `${org.name}-${index.toString()}`;
                    return (
                        <Card key={eventKeyOrganization}>
                            <CustomToggle as={Card.Header} eventKey={eventKeyOrganization}>
                                {org.name}
                            </CustomToggle>
                            <Accordion.Collapse eventKey={eventKeyOrganization}>
                                <Card.Body id={org.name}>
                                    <Table
                                        key={'step3-comparative-individual-table'}
                                        dataSource={org.users}
                                        columns={columns}
                                        pagination={org.users.length > paginationDefaultPageSize ? defaultPagination : false}
                                        rowKey={(record: UserFormForView, index?: number) => record.fakeAlias + index}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
        </React.Fragment>
    )
}

const styles = {
    accordionDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
    button: {
        display: 'flex',
        height: 'auto',
        width: 'auto',
    } as React.CSSProperties,
    buttonRow: {
        display: 'flex',
        height: 'auto',
        width: 'auto',
    } as React.CSSProperties,
    iconSpace: {
        fontSize: '30px',
        color: colors.white,
    } as React.CSSProperties,
    buttonWithText: {
        marginRight: '15px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as React.CSSProperties,
    accordionToogle: {
        padding: '0.75rem 1.25rem',
        backgroundColor: 'rgba(0,0,0,.03)',
        borderBottom: '1px solid rgba(0,0,0,.125)'
    } as React.CSSProperties,
}
