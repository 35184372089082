import React from 'react';
import { GraphBar } from '../GraphBar';
import { ScaleGroup } from '../../services/reportService';
import { GroupReportAggregateProfile } from '../../services/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { GetReportItemColor } from '../../utils/reportHelper';

type Props = {
  aggregateProfile: GroupReportAggregateProfile[];
  subProgramName: string[];
}

const BuildGraphBar = (aggregateProfile: GroupReportAggregateProfile[], subProgramName: string[], currentTitle: string, scaleGroupItem: ScaleGroup) => {
  const result = subProgramName.map((groupName, index) => {
    // There is only one item per group name
    const item = aggregateProfile.filter(t => t.groupName === groupName)[0];
    const title = `${currentTitle} - (${groupName})`
    return (
      // @ts-ignore
    <GraphBar key={`${index}-${groupName}`} title={title} value={item.scales[scaleGroupItem]} personalizedBarColor={GetReportItemColor(scaleGroupItem)} />  
    );
  });
  return result;
}

export const LongitudinalAggregateProfile = ({ aggregateProfile, subProgramName }: Props): JSX.Element => {
  return (
    <React.Fragment>
      {BuildGraphBar(aggregateProfile, subProgramName, `${GetFormattedMessage('app.report.scales.consistency')}`, ScaleGroup.Consistency)}
      <hr />
      {BuildGraphBar(aggregateProfile, subProgramName, `${GetFormattedMessage('app.report.scales.congruency')}`, ScaleGroup.Congruency)}
      <hr />
      {BuildGraphBar(aggregateProfile, subProgramName, `1. ${GetFormattedMessage('app.report.scales.negative_life_events')}`, ScaleGroup.NegativeLifeEvents)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `2. ${GetFormattedMessage('app.report.scales.needs_closure')}`, ScaleGroup.NeedsClosure)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `3. ${GetFormattedMessage('app.report.scales.needs_fulfillment')}`, ScaleGroup.NeedsFulfillment)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `4. ${GetFormattedMessage('app.report.scales.identity_diffusion')}`, ScaleGroup.IdentityDiffusion)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `5. ${GetFormattedMessage('app.report.scales.basic_openness')}`, ScaleGroup.BasicOpenness)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `6. ${GetFormattedMessage('app.report.scales.self_certitude')}`, ScaleGroup.SelfCertitude)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `7. ${GetFormattedMessage('app.report.scales.basic_determinism')}`, ScaleGroup.BasicDeterminism)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `8. ${GetFormattedMessage('app.report.scales.socioemotional_convergence')}`, ScaleGroup.SocioemotionalConvergence)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `9. ${GetFormattedMessage('app.report.scales.physical_resonance')}`, ScaleGroup.PhysicalResonance)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `10. ${GetFormattedMessage('app.report.scales.emotional_attunement')}`, ScaleGroup.EmotionalAttunement)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `11. ${GetFormattedMessage('app.report.scales.self_awareness')}`, ScaleGroup.SelfAwereness)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `12. ${GetFormattedMessage('app.report.scales.meaning_quest')}`, ScaleGroup.MeaningQuest)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `13. ${GetFormattedMessage('app.report.scales.religious_traditionalism')}`, ScaleGroup.ReligiousTraditionalism)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `14. ${GetFormattedMessage('app.report.scales.gender_traditionalism')}`, ScaleGroup.GenderTraditionalism)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `15. ${GetFormattedMessage('app.report.scales.sociocultural_openness')}`, ScaleGroup.SocioculturalOpenness)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `16. ${GetFormattedMessage('app.report.scales.ecological_resonance')}`, ScaleGroup.EcologicalResonance)}
      <br />
      {BuildGraphBar(aggregateProfile, subProgramName, `17. ${GetFormattedMessage('app.report.scales.global_resonance')}`, ScaleGroup.GlobalResonance)}
      <br />
    </React.Fragment>
  )
}