import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'Unanswered questions',
    'app.questions.confirmation':
        'Do you really want to continue without answering all questions? You will not be able to return to these questions later.',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': 'Strongly Agree',
    'app.question.agree': 'Agree',
    'app.question.disagree': 'Disagree',
    'app.question.strongly_disagree': 'Strongly Disagree',
    'app.question.q1': 'Sometimes I don\'t know which way to go.',
    'app.question.q2': 'People made fun of me when I was growing up.',
    'app.question.q3': 'Sometimes I compare myself to others.',
    'app.question.q4': 'It is healthy for young people to experiment sexually.',
    'app.question.q5': 'I have had a lot of conflict with one or more members of my family.',
    'app.question.q6': 'I would be scared to be all alone in the world.',
    'app.question.q7': 'I wish my mother and/or father had done things differently.',
    'app.question.q8': 'Women are almost always more emotional around the time of their period.',
    'app.question.q9': 'We should try to understand cultures that are different from our own.',
    'app.question.q10': 'My mother is/was my best friend.',
    'app.question.q11': 'We will eventually accept that men and women are simply different.',
    'app.question.q12': 'A woman\'s place is in the home.',
    'app.question.q13': 'I am often overcome by the beauty of this world.',
    'app.question.q14': 'Some people have stronger genes.',
    'app.question.q15': 'Prejudice is everywhere and we must overcome.',
    'app.question.q16': 'I have gone through a painful identity crisis.',
    'app.question.q17': 'I cry several times a year.',
    'app.question.q18': 'I can/could always count on my mother or father for good advice.',
    'app.question.q19': 'The unconscious part of our mind can influence our feelings and behavior.',
    'app.question.q20': 'It is important to be well informed about world events.',
    'app.question.q21': 'A man should act like a man.',
    'app.question.q22': 'God made me the way I am.',
    'app.question.q23': 'In the end, people decide to be happy or unhappy.',
    'app.question.q24': 'I feel a deep spiritual connection to the Earth.',
    'app.question.q25': 'I had a wonderful childhood.',
    'app.question.q26': 'I don\'t always feel good about who I am.',
    'app.question.q27': 'It is wrong to doubt the existence of God.',
    'app.question.q28': 'You can\'t blame anyone but yourself for your problems.',
    'app.question.q29': 'Women are more emotionally dependent than men.',
    'app.question.q30': 'I like to have deep conversations that go on for hours.',
    'app.question.q31': 'We should raise the minimum wage.',
    'app.question.q32': 'I had a good and healthy relationship with my father when I was growing up.',
    'app.question.q33': 'AIDS may well be a sign of God\'s anger.',
    'app.question.q34': 'Too many people are looking for a free handout.',
    'app.question.q35': 'Sex education should be taught in our schools.',
    'app.question.q36': 'I witnessed a lot of anger or aggression when I was growing up.',
    'app.question.q37': 'I want to find a better sense of balance in my life.',
    'app.question.q38': 'I take my own feelings very seriously.',
    'app.question.q39': 'Women can be fierce competitors.',
    'app.question.q40': 'We shouldn\'t deny our animal nature.',
    'app.question.q41': 'Some events in my past are embarrassing to think about.',
    'app.question.q42': 'I have had a lot of conflict in my relationships.',
    'app.question.q43': 'I am more liberal than conservative on social issues.',
    'app.question.q44': 'I think a lot about the meaning of life.',
    'app.question.q45': 'I feel bored at times.',
    'app.question.q46': 'It is normal for young people to experiment with alcohol and recreational drugs.',
    'app.question.q47': 'Women have a lot of trouble making up their minds.',
    'app.question.q48': 'Knowledge of other cultures helps me understand my own culture.',
    'app.question.q49': 'There is one way to heaven.',
    'app.question.q50': 'People usually fail because they don\'t try hard enough.',
    'app.question.q51': 'I have had to create distance from my parents.',
    'app.question.q52': 'Like it or not, boys will be boys.',
    'app.question.q53': 'Humans have evolved over millions of years.',
    'app.question.q54': 'One or more persons in my family was physicaly, sexually, or emotionally abused.',
    'app.question.q55': 'I have real needs for warmth and affection.',
    'app.question.q56': 'We should spend more money on early education programs for children.',
    'app.question.q57': 'I am very feeling person.',
    'app.question.q58': 'I support the work of trade unions.',
    'app.question.q59': 'I worry about our environment.',
    'app.question.q60': 'It hurts to be rejected.',
    'app.question.q61': 'I have nothing negative to say about my parents.',
    'app.question.q62': 'I have wondered about who I am and where I am going.',
    'app.question.q63': 'My emotions can sometimes get the better of me.',
    'app.question.q64': 'Women gossip a lot more than men.',
    'app.question.q65': 'Without religion there can be no peace.',
    'app.question.q66': 'There\'s no denying that men are stronger than women.',
    'app.question.q67': 'As a society, we are far too consumer oriented.',
    'app.question.q68': 'My family had a lot of problems with money.',
    'app.question.q69': 'I am always trying to understand myself better.',
    'app.question.q70': 'It\'s only natural that the strong will survive.',
    'app.question.q71': 'Men are more logical than women.',
    'app.question.q72': 'It helps to work through painful feelings from the past.',
    'app.question.q73': 'Sometimes men feel weak just like women.',
    'app.question.q74': 'It would be wrong to dress a boy in pink.',
    'app.question.q75': 'I like to watch movies about relationships.',
    'app.question.q76': 'I had a good and healthy relationship with my mother when I was growing up.',
    'app.question.q77': 'I worry about the health of our planet.',
    'app.question.q78': 'My body is very sensitive to what I feel.',
    'app.question.q79': 'I have to admit that I am just like my parents.',
    'app.question.q80': 'Women are more emotional than men.',
    'app.question.q81': 'I have strong religious beliefs.',
    'app.question.q82': 'I am the way I am because I was born that way.',
    'app.question.q83': 'My parents had a lot of conflict in their relationship.',
    'app.question.q84': 'I like to think about who I am.',
    'app.question.q85': 'Women have made wonderful strides in the last few decades.',
    'app.question.q86': 'My father is my hero.',
    'app.question.q87': 'I have had a hard time figuring out what to do with my life.',
    'app.question.q88': 'Sometimes I feel things too deeply.',
    'app.question.q89': 'We should think of the earth as our mother.',
    'app.question.q90': 'I have felt lonely in my life.',
    'app.question.q91': 'Many professors are too liberal in their views.',
    'app.question.q92': 'The only real hope for healing comes from a higher power.',
    'app.question.q93': 'Sometimes I feel needy and vulnerable.',
    'app.question.q94': 'We should do more to help minority groups in our society.',
    'app.question.q95': 'Everyone could benefit from learning more than one language.',
    'app.question.q96': 'Even though we expect them to be, men are not really built to be faithful in marriage.',
    'app.question.q97': 'I don\'t understand why some things happen.',
    'app.question.q98': 'My father is/was emotionally distant from me.',
    'app.question.q99': 'We should elect a woman to lead our nation.',
    'app.question.q100': 'In the end, we all choose the kind of life we have.',
    'app.question.q101': 'The judicial branch of our government is too liberal.',
    'app.question.q102': 'I want to be taken seriously.',
    'app.question.q103': 'We should do more to help those who are less fortunate.',
    'app.question.q104': 'I don\'t mind displays of emotion.',
    'app.question.q105': 'I was emotionally harmed while growing up.',
    'app.question.q106': 'Pornography degrades women.',
    'app.question.q107': 'I am angry about all of the injustice in the world.',
    'app.question.q108': 'Big government programs do more harm than good.',
    'app.question.q109': 'My feelings have been hurt in the past.',
    'app.question.q110': 'I like to read self-help books.',
    'app.question.q111': 'My mother and/or father has/had emotional problems.',
    'app.question.q112': 'I enjoy learning about other cultures.',
    'app.question.q113': 'We must preserve our trusted traditions.',
    'app.question.q114': 'Women aren\'t ambitious enough to succeed.',
    'app.question.q115': 'Sometimes I am angry at other people.',
    'app.question.q116': 'No one can really hurt you unless you let them.',
    'app.question.q117': 'I need someone in my life to listen to how I feel.',
    'app.question.q118': 'You can overcome almost any problem if you just try harder.',
    'app.question.q119': 'Prayer doesn\'t accomplish anything.',
    'app.question.q120': 'I am/was often in the middle of my parents\' issues or conflicts.',
    'app.question.q121': 'There is too big a gap between the rich and the poor in our country.',
    'app.question.q122': 'I don\'t understand everything I feel.',
    'app.question.q123': 'People don\'t really change.',
    'app.question.q124': 'I never felt like I was good enough for my family.',
    'app.question.q125': 'Men are naturally more competitive than women.',
    'app.question.q126': 'We must do more to protect the earth\'s natural resources.',
    'app.question.q127': 'I have had serious money problems in my life.',
    'app.question.q128': 'I don\'t like to be criticized.',
    'app.question.q129': 'My mother or father and I are/were close.',
    'app.question.q130': 'I have problems that I need to work on.',
    'app.question.q131': 'Prayer is more healing than traditional medicine.',
    'app.question.q132': 'I was abused/neglected while growing up.',
    'app.question.q133': 'We must fight harder in the war on drugs.',
    'app.question.q134': 'I strongly support equal rights for women.',
    'app.question.q135': 'My father understood and accepted me.',
    'app.question.q136': 'Education is the key to a more enlightened society.',
    'app.question.q137': 'A man\'s role is to be strong.',
    'app.question.q138': 'We should protect the land no matter who owns it.',
    'app.question.q139': 'My parents demanded too much from me.',
    'app.question.q140': 'All of us would benefit if women had more economic and political power.',
    'app.question.q141': 'I sometimes cry when other people cry.',
    'app.question.q142': 'Some women ask to be harassed because of the way they dress.',
    'app.question.q143': 'If you can\'t say something nice, don\'t say anything at all.',
    'app.question.q144': 'You can\'t change the past so there\'s no point dwelling on it.',
    'app.question.q145': 'When life knocks you down you should get up and keep moving.',
    'app.question.q146': 'Rejection often makes you stronger.',
    'app.question.q147': 'Some people analyze the world too much.',
    'app.question.q148': 'Whenever I feel bad I try to think positive thoughts.',
    'app.question.q149': 'It is always better to focus on strengths than weaknesses.',
    'app.question.q150': 'The best way to get through life is to keep a happy attitude.',
    'app.question.q151': 'What\'s done is done so forgive and forget.',
    'app.question.q152': 'You really can\'t say that one viewpoint is better than any other.',
    'app.question.q153': 'I think that people should be grateful for what they have and stop complaining.',
    'app.question.q154': 'Some numbers are more unlucky than others.',
    'app.question.q155': 'There is no reason to use profanity.',
    'app.question.q156': 'Too many people don\'t meet their responsibilities.',
    'app.question.q157': 'Psychics can predict the future.',
    'app.question.q158': 'It is always best to try and simplify things.',
    'app.question.q159': 'You can\'t get two experts to agree on anything.',
    'app.question.q160': 'If you play by the rules you get along fine.',
    'app.question.q161': 'It would be nice if everyone liked or at least respected me.',
    'app.question.q162': 'We should be more tolerant of different cultural practices.',
    'app.question.q163': 'I am a free spirit.',
    'app.question.q164': 'I believe in the power of astrology.',
    'app.question.q165': 'I feel sad for people who live in poverty.',
    'app.question.q166': 'I value clear logic above most other things.',
    'app.question.q167': 'I am comfortable around groups of people who are very different from me.',
    'app.question.q168': 'Parents should stay together for the sake of their children.',
    'app.question.q169': 'It is healthy for people to have sexual fantasies.',
    'app.question.q170': 'Diagnostic labels are not all that helpful.',
    'app.question.q171': 'I always look on the bright side of things.',
    'app.question.q172': 'Beings from other planets have helped our species.',
    'app.question.q173': 'The earth is billions of years old.',
    'app.question.q174': 'Weakness can be a virtue.',
    'app.question.q175': 'No one likes a pessimist.',
    'app.question.q176': 'I am bothered by displays of emotion.',
    'app.question.q177': 'My parents had a very good relationship while I was growing up.',
    'app.question.q178': 'We are too tolerant of different cultural practices.',
    'app.question.q179': 'We do enough to help the poor.',
    'app.question.q180': 'People worry too much about the environment.',
    'app.question.q181': 'Prejudice really isn\'t much of a problem anymore.',
    'app.question.q182': 'People change all the time.',
    'app.question.q183': 'There are many paths to heaven.',
    'app.question.q184': 'Men and women are more similar than different.',
    'app.question.q185': 'We don\'t really choose the life we have.',
};

export const scaleQuestionsPageEN: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
