import './LanguagesSpeakQuestion.css';

import React, { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import {
    DemographicQuestionAnswers, DemographicQuestionAnswersValues, LanguageFluency,
    LanguageFluencyOther, PrimaryLanguage
} from '../../../services/types';
import { GetLanguageOptions, GetLevelOfFluencyOptions } from '../../../utils/demographHelper';
import { ColSpec } from '../../../utils/formHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { LanguagesSpeak } from '../library/questionDefinitions';

type AnswerLabel = {
    languageId: number;
    languageText?: string;
    levelOfFluencyId: number;
    levelOfFluencyText?: string;
}

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    question: LanguagesSpeak;
    answers?: LanguageFluency[];
    primaryLanguageAnswers?: PrimaryLanguage[];
    otherAnswers?: LanguageFluencyOther[];
    answerChange:
        (key: keyof(DemographicQuestionAnswers), value: DemographicQuestionAnswersValues) => void;
    questionNumber: number;
    style?: React.CSSProperties;
};

export const LanguagesSpeakQuestion = ({
    question, primaryLanguageAnswers, answers, otherAnswers, questionNumber, style, optionsMd,
    answerChange, ...groupProps
}: Props): JSX.Element => {
    const [currentLanguage, setCurrentLanguage] = useState<number>();
    const [currentOtherLanguage, setCurrentOtherLanguage] = useState<string>();
    const [currentLevelFluency, setCurrentLevelFluency] = useState<number>();
    const [haveOtherLanguage, setHaveOtherLanguage] = useState<boolean>(false);

    const languageOptions = GetLanguageOptions();
    const primaryLanguageOptions = languageOptions.filter((l: SelectItem) => l.value !== 999)
    const levelOfFluencyOptions = GetLevelOfFluencyOptions();
    const availableLanguageOptions = languageOptions.filter((item: SelectItem) => {
        if (answers === undefined) return true;
        return !answers.find((answer: LanguageFluency) => answer.language === item.value);
    });

    const conditionalProps = question.GetConditonalProps();
    const currentLanguageOption =
        languageOptions.find((item: SelectItem) => currentLanguage === item.value) ?? [];
    const currentLevelOfFluencyOption =
        levelOfFluencyOptions.find((item: SelectItem) => currentLevelFluency === item.value) ?? [];

    const actionRemoveTooltip =  GetFormattedMessage('app.demographic.remove_language_tooltip');

    let answersText: AnswerLabel[] = answers?.map((value: LanguageFluency) => {
        return ({
            languageId: value.language,
            languageText:
                languageOptions.find((item: SelectItem) => item.value === value.language)?.label,
            levelOfFluencyId: value.fluency,
            levelOfFluencyText:
                levelOfFluencyOptions.find((item: SelectItem) => item.value === value.fluency)?.label
        });
    }) ?? [];
    otherAnswers?.forEach((value: LanguageFluencyOther) => {
        answersText.push({
            languageId: question.GetOtherIdValue(), // Other
            languageText: value.language,
            levelOfFluencyId: value.fluency,
            levelOfFluencyText:
                levelOfFluencyOptions.find((item: SelectItem) => item.value === value.fluency)?.label
        })
    })

    const primaryLanguagueSelectedValues = primaryLanguageAnswers?.map(answer =>
        primaryLanguageOptions.find(item => item.value === answer.language)!!);

    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={question.GetPrimaryLanguageLabelId()} />
                </div>
             </Form.Label>
            <br />
            <Col className='question-language-response' md='8'>
                <FormDropdown
                    value={primaryLanguagueSelectedValues}
                    optionItens={primaryLanguageOptions}
                    isMulti={true}
                    onMultiValueSelect={(selectedValue: number[]) => {
                        const languages = selectedValue.map(value => ({
                            language: value
                        }))
                        answerChange(question.GetQuestionPrimaryLanguageKeyName(), languages);
                    }}
                />
            </Col>
            <br />
             <Form.Group className='mb-3' {...groupProps} style={style}>
                <Form.Label>
                    <div style={styles.divQuestionWithNumber}>
                        <FormattedMessage id={question.GetPrimaryLanguageOtherLabelId()} />
                    </div>
                </Form.Label>
                <Row className="mx-0">
                    <Form.Check
                        type='radio'
                        inline={true}
                        value={haveOtherLanguage ? 1 : 0}
                        label={GetFormattedMessage('app.response_yes')}
                        name={'another-language'}
                        id={`have-another-language`}
                        onClick={() => setHaveOtherLanguage(true)}
                    />
                    <Form.Check
                        type='radio'
                        inline={true}
                        value={!haveOtherLanguage ? 1 : 0}
                        label={GetFormattedMessage('app.response_no')}
                        name={'another-language'}
                        id={`no-other-language`}
                        onClick={() => {
                            setHaveOtherLanguage(false);

                            setCurrentLanguage(undefined);
                            setCurrentLevelFluency(undefined);
                            setCurrentOtherLanguage(undefined);

                            answerChange(question.GetQuestionKeyName(), undefined);
                            answerChange(conditionalProps.questionKeyName, undefined);
                        }}
                    />
                </Row>
            </Form.Group>
            {haveOtherLanguage && (
                <Form.Group className='mb-3' {...groupProps} style={style}>
                    <Form.Label>
                        <div style={styles.divQuestionWithNumber}>
                            <FormattedMessage id={question.GetQuestionLabelId()} />
                        </div>
                    </Form.Label>
                    <br />
                    {/* Question response */}
                    <Form.Group className='mb-3' as={Row}>
                        <Col className='question-language-response' md={{ offset: 1 }}>
                            <FormDropdown
                                value={currentLanguageOption}
                                optionItens={availableLanguageOptions}
                                onValueSelect={(value: number | undefined, _: string) => {
                                    setCurrentLanguage(value)
                                }}
                            />
                        </Col>
                        {conditionalProps !== null && currentLanguage !== undefined &&
                            conditionalProps.coditional.indexOf(currentLanguage) >= 0 && (
                            <Col>
                                <Form.Control
                                    as='input'
                                    onChange={(e) => {
                                        setCurrentOtherLanguage(e.currentTarget.value)
                                    }}
                                />
                            </Col>
                        )}
                        <Col className='question-language-response'>
                            <FormDropdown
                                value={currentLevelOfFluencyOption}
                                optionItens={levelOfFluencyOptions}
                                onValueSelect={(value: number | undefined, _: string) => {
                                    setCurrentLevelFluency(value)
                                }}
                            />
                        </Col>
                        <Col className='question-language-response' md='1' style={{ paddingLeft: 10 }}>
                            <Button
                                className='w-100'
                                disabled={
                                    currentLanguage === undefined || currentLevelFluency === undefined ||
                                    (conditionalProps !== null && conditionalProps.coditional.indexOf(currentLanguage) >= 0 && !currentOtherLanguage)
                                }
                                onClick={() => {
                                    if (currentLanguage === undefined || currentLevelFluency === undefined) return;

                                    if (conditionalProps === null || conditionalProps.coditional.indexOf(currentLanguage) < 0) {
                                        let newAnswers: LanguageFluency[] = answers ? [...answers] : [];
                                        newAnswers.push({ language: currentLanguage, fluency: currentLevelFluency });
                                        setCurrentLanguage(undefined);
                                        setCurrentLevelFluency(undefined);
                                        answerChange(question.GetQuestionKeyName(), newAnswers);
                                    } else {
                                        let newAnswers: LanguageFluencyOther[] = otherAnswers ? [...otherAnswers] : [];
                                        newAnswers.push({ language: currentOtherLanguage ?? "", fluency: currentLevelFluency });
                                        setCurrentLanguage(undefined);
                                        setCurrentLevelFluency(undefined);
                                        setCurrentOtherLanguage(undefined);
                                        answerChange(conditionalProps.questionKeyName, newAnswers);
                                    }
                                }}
                            >
                                { GetFormattedMessage('app.demographic.language_level_of_fluency_add_anwser') }
                            </Button>
                        </Col>
                        <Col className='question-language-offset' md='1' />
                    </Form.Group>
                    {/* Answers */}
                    <Form.Group className='mb-3' as={Row}>
                        <Form.Group className='mb-3' as={Col} md={{ offset: 1, span: 10 }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th> { GetFormattedMessage('app.demographic.table_language') } </th>
                                        <th> { GetFormattedMessage('app.demographic.table_level_of_fluency') } </th>
                                        <th> { GetFormattedMessage('app.demographic.table_action') } </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { answersText === undefined ?
                                        <tr> <th /> <th /><th /></tr>
                                        :
                                        answersText.map((answer: AnswerLabel) =>
                                            <tr key={`row-${answer.languageId}-${answer.levelOfFluencyId}`}>
                                                <th> { answer.languageText } </th>
                                                <th> { answer.levelOfFluencyText } </th>
                                                <th> 
                                                    <OverlayTrigger
                                                        key={`trigger-${answer.languageId}-${answer.levelOfFluencyId}`}
                                                        placement={'right'}
                                                        trigger={["hover", "hover"]}
                                                        overlay={
                                                            <Tooltip id={`tooltip-${answer.languageId}-${answer.levelOfFluencyId}`}>
                                                                {`${actionRemoveTooltip} ${answer.languageText}`}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            key={`btn-${answer.languageText}-${answer.levelOfFluencyText}`}
                                                            // @ts-ignore
                                                            style={styles.associateUserButtonAction}
                                                            className='btn bg-transparent shadow-none border-0'
                                                            onClick={() => {
                                                                if (answer.languageId === question.GetOtherIdValue()) {
                                                                    const newAnswers = otherAnswers?.filter((value: LanguageFluencyOther) =>
                                                                        !(value.fluency === answer.levelOfFluencyId && value.language === answer.languageText)
                                                                    );
                                                                    answerChange(conditionalProps.questionKeyName, newAnswers);
                                                                } else {
                                                                    const newAnswers = answers?.filter((value: LanguageFluency) => 
                                                                        !(value.fluency === answer.levelOfFluencyId && value.language === answer.languageId)
                                                                    );
                                                                    answerChange(question.GetQuestionKeyName(), newAnswers);
                                                                }
                                                            }}
                                                        >
                                                            <FaTimes color='red' />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </th>
                                            </tr>
                                        )}
                                </tbody>
                            </Table>
                        </Form.Group>
                    </Form.Group>
                </Form.Group>
            )}
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
