import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'You and Your Worldview',
    'app.individualreport.a_personal_report_from': 'A personal report from the',
    'app.individualreport.bevi_description': 'Beliefs, Events, and Values Inventory (BEVI)',
    'app.individualreport.user': '被考評者',
    'app.individualreport.date_of_test': '完成BEVI考評的日期',
    'app.individualreport.static_text_1': ' 無論您身在何處，拿起智慧型手機或報紙，打開電視或收音機都會立即發現一個事實。那就是，信仰和價值觀在人的一生中是不可或缺的。人們從政治、宗教、教育、藝術到婚姻、家人、性別、性欲都各持己見。但是，某一信仰和價值觀是“正確的”還是“錯誤的”(正如同之後所述，BEVI不會對其進行評判），這並不重要。重要的是要去理解“思考、感情、行為、人生經歷、背景如何在複雜地相互作用下造就了不同的人”。為什麼理解這些會變得如此重要？那是因為信仰和價值觀會影響到世上的每個人、團體、組織、政府以及社會的行動、方針與實踐。Beliefs, Events, and Values Inventory（BEVI）為我們提供了一種方法去測量個人及小組的這些複雜因素。同時，它還有助於我們更好地瞭解“為什麼我們會形成某種特定的信仰和價值觀？”“為什麼他人會以相同或不同的方式看世界？”',
    'app.individualreport.static_text_2': ' 此結果旨在幫助您更好地理解自己的信仰和價值觀。同時，還會提出一些理由來說明您為什麼會形成那樣的信仰與價值觀。誠然，關於這些“什麼？”“為什麼？”的問題，要用絕對的方式去回答是困難的。並且，有必要強調世界是在不斷變化的。今天認為是“正確的”事情，在不久前可能被認為是“錯誤的”。同樣，現在認為是“不好”的事情，可能幾年後就會被認為是“好的”。因此，此結果並不是對這些相關事項的最終考評，而是反映了您現在，人生此刻的問題。每個人都是在變化、獨一無二的、複雜的。無論哪種測量，都不可能測量出以前的您、現在的您或將來的您的全部，而且BEVI的目的也不在於此。這一考評的設製目的在於幫助您就人生的本質，更好地反省自己、認識他人與世界。',
    'app.individualreport.static_text_3': ' 最後再次強調：此結果關注的是您對人生、工作、人際關係的信念和價值觀的基本特徵及其影響，旨在加深您對自己、他人、以及更大的世界的理解。在此再次強調：BEVI不贊同通過比較某種信仰和價值觀與另一種信仰和價值觀，而得出“正確的”或“錯誤的”、“更好的”或“更壞的”。',
    'app.individualreport.static_text_4': ' 因此，請基於以上背景來閱讀您的結果。在解釋信仰和價值觀的同時，您的個人結果將以粗體字顯示。',
    'app.individualreport.static_text_5': ' 我們在成長中或人生中的經歷經常會影響到我們對自己、他人以及世界的理解。例如，那些人生中有許多積極事情發生的，感到自己的核心需求（愛、關懷等）已得到充分滿足的人，基本上都會信任他人，也會相信人生應該會有好事情發生吧。有時（並不是總是），那些人生中有很多消極事情發生的人（與父母或家裡的衝突等），感到自己的核心需求沒有得到充分滿足的人，尤其是在幼年或長時間經歷，或非常痛苦或留下創傷的情況下，就會感到世界不是個適合居住的地方，因此有可能會影響到其對世界的看法及與他人的相處方式（學校、職場、人際關係等）。並且，實際上，要是人生經歷了非常多的負面事情，或是核心需求得不到滿足時，與針對這些問題接受過培訓或具有專門知識的人交談可能會有所幫助。雖說如此，但還是會有差別，人生對每個人而言都會有一定的難度，認識到這一點至關重要。即使經歷了很多困難，大多數人都能克服那樣的逆境，有時會得到他人（伴侶、朋友、老師、上司、教父、輔導員、心理諮詢等）各式各樣的幫助，繼續其在學校、職場及人生中有意義的人際關係。無論如何，當您理解不了別人的信念和價值觀時，請回想起“各式各樣的人生經歷和背景，對我們理解自己、他人、以及更大的世界都會產生影響”。這一認識，讓我們在各式各樣的情況下，即使對自己或他人的想法、感受、行為產生不了共鳴，也會加深對其的理解。',
    'app.individualreport.static_text_6': ' 如果您是傾向於“懷疑”型的，那您周圍的人也是（即使是那些“非常有自信”型的人）對新的或不習慣的事物不像看起來那麼有自信，即使與您相比較實際上也並不知道什麼是“正確”的行為，瞭解這些可能會對您有所幫助。如果您有充分的理由相信自己知道正發生著什麼，合理的自信是非常了不起的。但是，處於未經歷過的狀況或環境下，一般認為採取更懷疑且更開放的態度為妙。另一方面，特別留意最感興趣的問題、疑問、實習及過程，可能會有效。關於您自身的這些資訊，對理解“您是誰？”“將成為誰？”或“想成為誰？”都會有幫助吧。',
    'app.individualreport.static_text_7': ' 同時，我們會遇見新的人或新的狀況，也會隨著時間的流逝在變化過程中認識到自己知識的有限，沒有“正確答案”，或在下決心之前發現就這一主題需要更進一步的學習。為了能接受諸如此類的現實，努力去“瞭解不知道的事情”也同樣重要。總之，我們人類之所以能夠理解做這件事的程度，是因為它與以更深的理解、智慧和慈悲去思考、感受和行動的能力有關',
    'app.individualreport.static_text_8': ' 參照上述的觀察和分析，嘗試思考我們日常生活中的“欲望”和“情緒”所起的作用至關重要。我們常常會尋找“深切感受”的經歷（例如，人際關係或音樂、戲劇、讀書、旅行、大自然的體驗等），或渴望能有“深切感受”的經歷。正因為我們的情緒大部分表露了“真實的自我”，所以感受自我或將情緒表達給他人並非易事。但在構築和諧的人際關係、滿足欲望、追求事業和人生目標的基礎上讓他人瞭解“真正的自己”至關重要。因此，表露我們所有的情緒與欲望是不明智的，同時，若不能感受或表露情緒或欲望，可能會給我們自己或他人帶來巨大的困難。',
    'app.individualreport.static_text_9_1': '也就是說無法感受情緒，認知欲望或不想那樣做的主要原因有以下兩個，它們會成為自己或他人的問題。',
    'app.individualreport.static_text_9_2': '    1）將無法獲得有關我們內心真正“正在發生的”重要資訊，而導致我們的決定及全體的幸福受損。',
    'app.individualreport.static_text_9_3': '    2）他人可能會覺得與我們很難相處，也可能很難瞭解真實的我們。',
    'app.individualreport.static_text_9_4': '慶幸的是，無論我們承認與否，我們都出生在一個充滿情緒與欲望的世界，甚至在我們會說話之前，這已成為我們與他人交流的最基本方式。一生中，我們會感受到自己和他人的各種欲望與情緒，在此過程中，我們會變得更人性。',
    'app.individualreport.static_text_10_1': '最後，我們的背景與經歷不僅會影響到我們如何看待自己與他人，也會影響到我們如何看待“更大的世界”或思考“世界（應）如何”。這裡，我想關注與我們人類息息相關的兩個全球性問題。',
    'app.individualreport.static_text_10_2': '   1）對待環境與自然界我們的姿態',
    'app.individualreport.static_text_10_3': '   2）想走向更大世界的程度或傾向',
    'app.individualreport.static_text_11': '  最後再次強調：以上的結果與陳述是關於現在的您。我們每個人都是變化的，您現在的信仰與價值觀也會隨著時間的推移而變化。最後，為了獲得更好的機會去理解、捍衛和接受我們在生活中所做出的決定，培養去深思“我們究竟信什麼？”或“什麼對我們才重要”的勇氣與能力是值得讚賞的。在此衷心希望：此結果所提供的資訊與觀點將有助或有利於您持續思考“自己是誰？”“將成為誰？”“想成為誰？”。',
    'app.individualreport.heading_text_1_1': '  Beliefs, Events, and Values Inventory (BEVI)是為了幫助大家更好地理解自己的信仰與價值觀而設製的。BEVI也考察信仰和價值觀是如何影響學習、個人成長、人際關係、以及團體或組織的發展，人生目標的追求。為了解決這些問題，這個結果包含了您是如何看待自己、他人、以及更大世界的資訊。但BEVI並不是判斷您的回答的對錯或好壞的考評，也不認為那樣的考評能夠實現。相反，這個結果是為了促進大家能去反思我們共同面臨的人生問題而設製。',
    'app.individualreport.heading_text_1_2': '  關於BEVI，若有疑問，請諮詢所在學校、大學、組織、留學項目的BEVI負責人。您也可以直接諮詢我們：<a href="mailto: info@thebevi.com " > info@thebevi.com</a>',
    'app.individualreport.heading_text_1_3': '  注意：在閱讀以下結果之前，請務必同意以下3個條件。您可以通過在網上點擊“同意”按鍵，或在閱讀以下結果前在另外提供的同意書上簽字同意。',
    'app.individualreport.heading_text_2': '  1.您({UserName})自願完成BEVI考評，隨後，想閱讀您個人的結果（即您已同意閱讀您個人的結果不受任何第三者、團體、組織和社團的要求）。',
    'app.individualreport.heading_text_3': '  2.我們將竭盡全力以有益且富於建設性的方式傳達有關BEVI的資訊。但是，您在閱讀此結果後若感到苦惱的話，將同意我們與您所在單位的BEVI負責人、BEVI結果反饋機構協調員、和/或醫療、心理健康諮詢機構聯繫。',
    'app.individualreport.heading_text_4': '  3.您可以為自己保留一份該結果的複本。但是，因為BEVI及其結果受版權保護，您同意不複製，也不向他人（以電子或其他形式）散佈該結果。',
    'app.individualreport.thankyou_static_1_1': 'BEVI及BEVI的結果、每道問題及其內容、還有各項比例標準，除非得到Craig N. Shealy博士的明確書面許可，否則無權對其全部或一部分進行分發、複製、變更、應用、公開或出版。',
    'app.individualreport.thankyou_static_1_2': '以研究或教育為目的的BEVI中文版的使用權已由Craig N. Shealy博士授予西谷 元 教授（日本廣島大學）。',
    'app.individualreport.thankyou_static_2': '  與BEVI考評一樣，結果的構成及設計將會不斷修改完善。若您對BEVI及其結果有意見或建議，請直接聯繫info@thebevi.com。',
    'app.individualreport.thankyou_static_3': '  若想瞭解有關BEVI的更多詳情，請查看International Beliefs and Values Institute（IBAVI）的主頁（www.ibavi.org）及BEVI主頁（thebevi.com）。',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': '  我們相信或重視的東西會有一些不同，或有時會截然不同。為什麼會有這樣的事情發生呢？對此問題可能會有各式各樣的回答，但BEVI卻是建立在我們成長的地方、文化、背景、以及在我們的成長過程中或人生中積極的、消極的經驗會對我們認知世界有重大影響這一基本認識的基礎上。',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': '  從BEVI的觀點來看，我們學會思考和感受世界的方式（如政治和宗教）是包括（但不限於）家庭、成長、文化和背景等各種因素共同作用的結果。因此，為了理解人們的信仰和價值觀，在瞭解人們經歷的積極的或消極的人生經歷的一部分的同時，也有必要瞭解本人回答的核心需求的滿足度。<b>您回答的人生的消極經歷是{NegativeLifeEvents} 。並且，您回答的幼年期和青春期的核心需求是{NeedsClosure}</b>',
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': ' 我們的背景和經歷也會以其他方式來影響我們。比如，影響了我們處理與自己截然不同的人共事的能力和意願的發展。從BEVI的立場來看，就這一點會有兩種人。一種可稱為“自信型”。這種類型的人，在理解人生、工作及人際關係的經歷時，無論對“自己或對方是誰？”“世界如何運轉？”都充滿了自信。另一種可稱為“懷疑型”。這種類型的人，對這樣的問題幾乎沒有明確的回答，不僅有時會感到懷疑和後悔，在面對與以往不同或自己不熟悉的狀況時，有不知如何應對的傾向。<b>從您的BEVI回答可看出您比起“懷疑型”更傾向於“自信型”。</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': ' 當然，無論是自信型還是懷疑型的方式，都潛伏著利弊。並且，根據事情和狀況的不同，我們時常會同時感受到這兩種方式。但是，一般來說，如果您是“自信型”，尤其是在經歷您不熟悉的狀況時，您未必會知道該做什麼，如何思考，應感受到什麼，所以認識到這一點會更好。',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': ' 　<b>您在某種意義上有著強烈的自我意識和責任感，相信“人生取決於自我”，通常厭惡“消極的想法”。</b>因為您不輕易放棄目標，這一姿態可能有利於您達成了人生中的眾多目標。但這一姿態，可能會讓您很難與那些沒有強烈奮鬥目標和積極想法的人產生共鳴。每個人都有著不同的背景和經歷，對您而言或許是過度的“貧困”或“軟弱”的東西，卻有可能反應了他們在生活中所經歷的實際的困難和障礙，記住這一點很重要。因為至少現在，即使匯集世上所有積極的想法，現實中也存在著解決不了的困難和障礙。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': ' 　　</b>我們的背景和經歷也會以其他方式來影響我們。比如，影響了我們處理與自己截然不同的人共事的能力和意願的發展。從BEVI的立場來看，就這一點會有兩種人。一種可稱為“自信型”。這種類型的人，在理解人生、工作及人際關係的經歷時，無論對“自己或對方是誰？”“世界如何運轉？”都充滿了自信。另一種可稱為“懷疑型”。這種類型的人，對這樣的問題幾乎沒有明確的回答，不僅有時會感到懷疑和後悔，在面對與以往不同或自己不熟悉的狀況時，有不知如何應對的傾向。<b>從您的BEVI回答可看出您處於“懷疑型”和“自信型”之間。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': ' 　當然，無論是自信型還是懷疑型的方式，都潛伏著利弊。並且，根據事情和狀況的不同，我們時常會同時感受到這兩種方式。但是，一般來說，如果您是“自信型”，尤其是在經歷您不熟悉的狀況時，您未必會知道該做什麼，如何思考，應感受到什麼，所以認識到這一點會更好。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '　 <b>您似乎會對“人生取決於自我”或“應以積極的想法度過人生”而感到困惑。</b>有時候，這種思維方式非常有效，但您卻傾向於質疑對待人生和生活的積極方式。“自己的問題責任在於自己”這一想法的好處在於您會湧出解決眼前問題的力量。但這一世界觀的一個缺點在於，持這種思維方式的人，可能不會總能與看起來沒有能力“克服困難”的人產生共鳴。關於這一點，值得深思的一個問題是“相信（或不相信）我們對自己的生活完全負責，會有什麼影響？” ',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': ' 　我們的背景和經歷也會以其他方式來影響我們。比如，影響了我們處理與自己截然不同的人共事的能力和意願的發展。從BEVI的立場來看，就這一點會有兩種人。一種可稱為“自信型”。這種類型的人，在理解人生、工作及人際關係的經歷時，無論對“自己或對方是誰？”“世界如何運轉？”都充滿了自信。另一種可稱為“懷疑型”。這種類型的人，對這樣的問題幾乎沒有明確的回答，不僅有時會感到懷疑和後悔，在面對與以往不同或自己不熟悉的狀況時，有不知如何應對的傾向。<b>從您的BEVI回答可看出您比起“自信型”更傾向於“懷疑型”。</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': ' 當然，無論是自信型還是懷疑型的方式，都潛伏著利弊。並且，根據事情和狀況的不同，我們時常會同時感受到這兩種方式。但是，一般來說，如果您是“自信型”，尤其是在經歷您不熟悉的狀況時，您未必會知道該做什麼，如何思考，應感受到什麼，所以認識到這一點會更好。',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '  <b>您似乎會對“人生取決於自我”或“應以積極的想法度過人生”而感到困惑。</b>有時候，這種思維方式非常有效，但您卻傾向於質疑對待人生和生活的積極方式。“自己的問題責任在於自己”這一想法的好處在於您會湧出解決眼前問題的力量。但這一世界觀的一個缺點在於，持這種思維方式的人，可能不會總能與看起來沒有能力“克服困難”的人產生共鳴。您回顧一下什麼時候有幹勁，什麼時候沒有，並分別思考其原因可能會有助於您。例如，關於“命運”“人類的本質”“事情為何那樣”，您最後相信什麼？這些信念中的哪一個最有利（或不利）於您日常的挑戰和發揮？',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': '  我們人類是一個追求生命意義的生物。即使連這一點也未注意到，去獲得“事物為什麼會這樣”的說明，在沒有充足資訊的情況下，基於因果關係也能導出結論。這些說明，從我們人類為什麼會這樣行動，在更大的世界中為什麼會發生這樣的事情，到人生、活著的目的，包羅萬象。有時候，並未注意到已經得出關於“事物為什麼會這樣”的結論，在日常生活中，這些意義形成的過程，確實是在一般的、自動的或者是無意識的情況下產生的，您甚至可能沒有意識到這是不可避免的。<b>從您的BEVI回答中，可看出您已具備充分的能力去對事物細微且複雜的因果關係進行說明，並能深入、正確地思考“事物為什麼會這樣”。</b>',
    'app.individualreport.whywedo.lessthan69.text': ' 我們人類是一個追求生命意義的生物。即使連這一點也未注意到，去獲得“事物為什麼會這樣”的說明，在沒有充足資訊的情況下，基於因果關係也能導出結論。這些說明，從我們人類為什麼會這樣行動，在更大的世界中為什麼會發生這樣的事情，到人生、活著的目的，包羅萬象。有時候，並未注意到已經得出關於“事物為什麼會這樣”的結論，在日常生活中，這些意義形成的過程，確實是在一般的、自動的或者是無意識的情況下產生的，您甚至可能沒有意識到這是不可避免的。<b>從您的BEVI回答中，可看出您對“事物為什麼會這樣”，能更深入思考了。</b>',
    'app.individualreport.whywedo.lessthan30.text': ' 我們人類是一個追求生命意義的生物。即使連這一點也未注意到，去獲得“事物為什麼會這樣”的說明，在沒有充足資訊的情況下，基於因果關係也能導出結論。這些說明，從我們人類為什麼會這樣行動，在更大的世界中為什麼會發生這樣的事情，到人生、活著的目的，包羅萬象。有時候，並未注意到已經得出關於“事物為什麼會這樣”的結論，在日常生活中，這些意義形成的過程，確實是在一般的、自動的或者是無意識的情況下產生的，您甚至可能沒有意識到這是不可避免的。<b>從您的BEVI回答中，可看出您或許需要在開始新挑戰之前，或在新挑戰時或之後，更深入思考“人們為什麼會這樣做”或“事情為什麼會這樣”。</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': ' 首先, ',
    'app.individualreport.title.foundation': ' 前提: “生成變量”和“核心欲望”',
    'app.individualreport.title.tolerance_of_desequilibrium': '不均衡的寬容',
    'app.individualreport.title.making_sense': ' 理解行為的動機',
    'app.individualreport.title.access_to_yourself': ' 瞭解自身及自己的想法、感情、欲望',
    'app.individualreport.title.access_to_the_thoughts': ' 瞭解他人的想法和感情',
    'app.individualreport.title.access_to_the_larger_world': ' 瞭解更廣闊的世界',
    'app.individualreport.title.conclusion': ' 最後',
    'app.individualreport.title.thankyou': ' 感謝參加BEVI考評。',
    'app.individualreport.title.profile': ' 個人資訊',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '  <b>既情緒化又敏感，您可能已注意到，與其他人相比，有些人更看重自己的欲望和情感表達。這樣的解釋，似乎非常適合您。</b>請思考情緒對人類生存的重要性。把握自己情緒的同時正確解釋或理解他人情緒的能力（有人稱之為“情商EQ”），是從個人的人際關係到工作環境，開闢人生道路的關鍵。如果沒有情緒，理解我們喜歡的東西和不喜歡的東西，我們自己和他人，以及我們想成為的自己應該非常困難吧。',
    'app.individualreport.accessself.textB': '　<b>既情緒化又敏感，您可能已注意到，與其他人相比，有些人更看重自己的欲望和情感表達。這樣的解釋，似乎非常適合您。</b>請思考情緒對人類生存的重要性。把握自己情緒的同時正確解釋或理解他人情緒的能力（有人稱之為“情商EQ”），是從個人的人際關係到工作環境，開闢人生道路的關鍵。如果沒有情緒，理解我們喜歡的東西和不喜歡的東西，我們自己和他人，以及我們想成為的自己應該非常困難吧。',
    'app.individualreport.accessself.textC': ' <b>既情緒化又敏感，您可能已注意到，與其他人相比，有些人更看重自己的欲望和情感表達。這樣的解釋，似乎不適合您對自己或他人情緒把握的方法。他人表露情緒與弱點似乎會讓您感到困惑，有時甚至會讓您煩惱。如果這個適合您的話，根據我們背景和人生經歷的不同，感受深層欲望和情緒會變得很容易，或相反，會變得很難。面對這一現實，可能重新考慮一下比較好。因為背景和人生經歷的不同，一般而言，只有您自己才能判斷出您是否能深刻感受到，或他人對您的要求是否已變少。如果您在人生，工作或人際關係上經歷了這樣的事，特別是這些經歷是新的時候，至少在最初處理難以應付的人、狀況、人際關係的時候，考慮它們的影響至關重要。</b>請思考情緒對人類生存的重要性。把握自己情緒的同時正確解釋或理解他人情緒的能力（有人稱之為“情商EQ”），是從個人的人際關係到工作環境，開闢人生道路的關鍵。如果沒有情緒，理解我們喜歡的東西和不喜歡的東西，我們自己和他人，以及我們想成為的自己應該非常困難吧。',
    'app.individualreport.accessself.textI': '  <b>您備受艱難抉擇或複雜情緒的煎熬，通常會盡量避免過度的“自我反省”。當您看到一個人過於關注自己的欲望和情緒時，您或許會感到困惑甚至焦躁。每個人都擁有塑造他們自己觀點的不同的生活經歷和背景，並且對“為什麼我們是我們”這一問題花過多時間思考的人，經常能夠幫助他人理解自己的真實姿態和行為的理由，記住這一點至關重要。而且，有時他們的經歷、理解和控制會受艱難抉擇或複雜情緒的影響。這時，最好與就此類問題接受過培訓或具有這方面知識的人交談。</b>',
    'app.individualreport.accessself.textH': ' <b>您似乎對自己的想法與情緒持開放態度，但您或許會意識到與周圍人相比，您會持“中立態度”。一些人或許會在思考“我們為什麼會變成這樣”這一事情上花費大量的時間和精力，但也有些人會完全不考慮，反而會對思考的人感到不安。就這一點，您從各種意義上，是能夠幫助持完全不同看法的兩種人（例如，“非常喜歡”情緒外露的人和完全不情緒外露的人）增進相互理解的人。</b>',
    'app.individualreport.accessself.textG': '<b>您對艱難抉擇或複雜情緒持非常開放的態度，通常會自我反省，或許會意識到自己的想法與情緒。有時，當看起來和他人沒有同等能力時，會感到寂寞與孤獨。對您這樣的人來說，理解並尊重欲望及情緒的外露，去發現喜歡思考“為什麼我們會這樣”的“志同道合的人”至關重要。世界需要像您這樣具有深度思考與感悟能力的人。</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {
    'app.individualreport.accessothers.textA': '宗教和政治、男人和女人的作用、人種和民族的問題，諸如此類的話題，要是遇到和我們持完全不同意見的人，或許會變成誘發一場非常激烈辯論的導火線，因此，我們從年輕時就會避開此類話題。但從BEVI的觀點來看，這些並不屬於不能說的、或不該說的話題。實際上，討論此類話題，是實現公民社會的健全議論必不可缺的。最重要的是，要去理解為什麼某一話題對我們而言會成為“誘發一場非常激烈辯論的導火線”。因此，您需要去審視自己是如何看待宗教、性別、政治和種族問題，您的這些信仰和價值觀或許會潛在影響您與那些持相同或不同看法和感受的人的關係。<br /><br />' +
        '<b>首先第一，您看起來有強烈的宗教信仰或信仰心。到目前為止您或許有過這一信仰心幫您找出意義及目的的經歷。有強烈的信仰心是非常了不起的事情。但同時，您也需要去理解他人、團體、宗教或文化就這些基本問題，可能會與您持迥然不同的看法。</b>有時，對於宗教問題，為什麼我們相信那些，對此我們立場明確。另一方面，也有這種情況，那就是沒有很好地思考我們為什麼信仰這些，不管是什麼，只是單純的接受被教的東西。在這裡重要的是，不是說這個信仰是正確的，其他信仰是錯誤的，相反，對我們所有人都會產生影響的是，我們大家相信什麼（例如，沒有信仰，其實是認為某一特定的信仰或信仰系統“信仰”沒有重要性和現實性的一種信仰）。宗教信仰問題，會對我們自身、與他人及更大的世界的經歷產生重大影響，所以，努力去理解我們對宗教自己所持的信仰和價值觀的性質及起源至關重要。同樣重要的是，由於我們的宗教信仰，通常源於我們的成長、文化及背景，因此理解這一點或許能加深我們理解他人為什麼會與自己持有相同或不同的想法。懷著真摯的好奇心和誠意去探究信仰和價值觀，不僅可以加深我們去理解為什麼自己會是現在的自己，也可以幫助他人以同樣的方式去理解自己。',
    'app.individualreport.accessothers.textB': '宗教和政治、男人和女人的作用、人種和民族的問題，諸如此類的話題，要是遇到和我們持完全不同意見的人，或許會變成誘發一場非常激烈辯論的導火線，因此，我們從年輕時就會避開此類話題。但從BEVI的觀點來看，這些並不屬於不能說的、或不該說的話題。實際上，討論此類話題，是實現公民社會的健全議論必不可缺的。最重要的是，要去理解為什麼某一話題對我們而言會成為“誘發一場非常激烈辯論的導火線”。因此，您需要去審視自己是如何看待宗教、性別、政治和種族問題，您的這些信仰和價值觀或許會潛在影響您與那些持相同或不同看法和感受的人的關係。<br /><br />' +
        '<b>首先第一，您看起來有穩定的宗教信仰。對於“信仰問題”，或許您會認為自己現在的信仰完全沒問題。但同時，對於自己信仰的深度和強度又可能會持有疑問。並且，或許會認為那些看起來有強烈信仰的人（例如，將自己描述為非常虔誠或非常反宗教的人）是不可思議的。</b>有時，對於宗教問題，為什麼我們相信那些，對此我們立場明確。另一方面，也有這種情況，那就是沒有很好地思考我們為什麼信仰這些，不管是什麼，只是單純的接受被教的東西。在這裡重要的是，不是說這個信仰是正確的，其他信仰是錯誤的，相反，對我們所有人都會產生影響的是，我們大家相信什麼（例如，沒有信仰，其實是認為某一特定的信仰或信仰系統“信仰”沒有重要性和現實性的一種信仰）。宗教信仰問題，會對我們自身、與他人及更大的世界的經歷產生重大影響，所以，努力去理解我們對宗教自己所持的信仰和價值觀的性質及起源至關重要。同樣重要的是，由於我們的宗教信仰，通常源於我們的成長、文化及背景，因此理解這一點或許能加深我們理解他人為什麼會與自己持有相同或不同的想法。懷著真摯的好奇心和誠意去探究信仰和價值觀，不僅可以加深我們去理解為什麼自己會是現在的自己，也可以幫助他人以同樣的方式去理解自己。',
    'app.individualreport.accessothers.textC': '宗教和政治、男人和女人的作用、人種和民族的問題，諸如此類的話題，要是遇到和我們持完全不同意見的人，或許會變成誘發一場非常激烈辯論的導火線，因此，我們從年輕時就會避開此類話題。但從BEVI的觀點來看，這些並不屬於不能說的、或不該說的話題。實際上，討論此類話題，是實現公民社會的健全議論必不可缺的。最重要的是，要去理解為什麼某一話題對我們而言會成為“誘發一場非常激烈辯論的導火線”。因此，您需要去審視自己是如何看待宗教、性別、政治和種族問題，您的這些信仰和價值觀或許會潛在影響您與那些持相同或不同看法和感受的人的關係。<br /><br />' +
        '<b>首先第一，您看起來沒有強烈的宗教信仰或信仰心，對宗教信仰和信者持懷疑的態度。您之所以會對宗教信仰持謹慎的態度，或以“信仰”為由拒絕接受此類問題的態度，是因為您認為持此類信仰和價值觀的人大都是沒有經過認真思考就盲目信奉的。對您而言，或許去思考這些事情會比較好：您目前的接納方式會產生怎樣的後果？或要是將某一強烈的信念（例如，完全肯定自身信仰的正當性）簡單地換成其他某一強烈的信念（例如，完全否定自身信仰的正當性），那或許會導致讓您無法加深對持久不變的信仰和價值觀的起源及目的的理解。</b>有時，對於宗教問題，為什麼我們相信那些，對此我們立場明確。另一方面，也有這種情況，那就是沒有很好地思考我們為什麼信仰這些，不管是什麼，只是單純的接受被教的東西。在這裡重要的是，不是說這個信仰是正確的，其他信仰是錯誤的，相反，對我們所有人都會產生影響的是，我們大家相信什麼（例如，沒有信仰，其實是認為某一特定的信仰或信仰系統“信仰”沒有重要性和現實性的一種信仰）。宗教信仰問題，會對我們自身、與他人及更大的世界的經歷產生重大影響，所以，努力去理解我們對宗教自己所持的信仰和價值觀的性質及起源至關重要。同樣重要的是，由於我們的宗教信仰，通常源於我們的成長、文化及背景，因此理解這一點或許能加深我們理解他人為什麼會與自己持有相同或不同的想法。懷著真摯的好奇心和誠意去探究信仰和價值觀，不僅可以加深我們去理解為什麼自己會是現在的自己，也可以幫助他人以同樣的方式去理解自己。',
}

const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '  <b>第二，關於性別問題，您是男性還是女性？您應如何？您傾向於持有傳統的或保守的信仰和價值觀。而且，您或許會感到男人和女人都是“按其應有的姿態被創造出來的”，只要單純地理解並接受這個事實，可能就會給所有的人帶來好結果。我們所有人都知道男女有別。但問題就出在為什麼會有差別上。</b>從您的回答可看出您傾向於性別差異主要歸因於其根本的生物學因素。關於這一話題的學說非常複雜，男女間實際上或是假定的“差異”，長年被當作否定少女或女性眾多權力的依據，認識到這一點至關重要。總之，“生物學上的議論”會因女人在男人的世界裡不能對等生存、工作，或原本就沒有女人在男人世界裡生存的計劃，經常會以此去否定女性權力的正當化。但固有觀念就像把雙面刃，男孩和男人被認為情感及其表達的“能力低下”，這不僅剝奪了他們擁有情感的“權利”，也意味著否定了他們去體會自己和他人欲望的機會。就像這樣，男人要學會“變得強大”，對他人進行攻擊，或不考慮對方莽撞行動時，或許這樣的“教訓”對我們所有人都會產生重大影響。而且，歷史賦予了女孩和女人教育孩子等的職責，這導致了她們平等參加的權利被否定。在思考這一問題時重要的是要去瞭解生物差異的論點是如何被用來限制女性和男性的機會和生存方式及其對我們所有人的影響。',
    'app.individualreport.gendertradition.textB': '  <b>第二，關於性別問題，您是男性還是女性？您應如何？您傾向於擁有平衡的信仰和價值觀。而且，您不相信男人和女人都是單純地“按其應有的姿態被創造出來的”。您或許會承認男女有別，但最重要的是您意識到為什麼會有差別這一問題上了嗎？</b>例如，男女的差別是起因於“環境”？“遺傳”？還是其兩者的結合？男女雙方的立場都有跡可尋，男女間的差異不僅歸因於其根本的生物學因素，也是文化、教育方法複雜相互作用的結果。重要的是還要意識到：對男女差異的生物學解釋，從教育到投票乃至控制自己的性行為，其實長期以來一直被用作剝奪女孩和婦女眾多權利的依據。但固有觀念就像把雙面刃。例如，男孩和男人情感及其表達能力低下，或許以此為由而被認為沒有能力教育孩子。在面對這一問題，大多數學者主張男人和女人作為人，與差異相比，有更多相似的地方。因此，對於性別差異，通過閱讀文獻學習我們知道什麼，不知道什麼至關重要。',
    'app.individualreport.gendertradition.textC': '  <b>第二，關於性別問題，您是男性還是女性？您應如何？您傾向於支持更進步或更變革性的信仰和價值觀。恐怕您對男人和女人都是單純地“按其應有的姿態被創造出來的”這一觀點不同意的可能性很大。您意識到男女有別，對此事實的憑證我們大家每天都能目睹。但問題就出在為什麼會有差別上。</b>例如，男女的差別是起因於“環境”？“遺傳”？還是其兩者的結合？看了您的答案，您更傾向於認為男女間的差異是由其根本的文化或環境因素所造成的。關於這一話題的學說非常複雜，男女雙方的立場都有跡可尋，男女間的差異不僅歸因於其根本的生物學因素，也是文化、教育方法複雜相互作用的結果。但同時重要的是還要意識到：對男女差異的生物學解釋，從教育到投票乃至控制自己的性行為，其實長期以來一直被用作剝奪女孩和婦女眾多權利的依據。但固有觀念就像把雙面刃。例如，男孩和男人被認為情感及其表達能力低下，這不僅剝奪了他們去感受自己和他人情緒或去體驗欲望的“權利”。而且，歷史賦予了女孩和女人教育孩子等的職責，這導致了她們平等參加的權利被否定。在思考這一問題時重要的是要去瞭解假設的生物差異論是如何被用來限制女性和男性的機會和生存方式及其對我們個人乃至社會所有人的影響。大多數學者主張男人和女人作為人，與差異相比，有更多相似的地方。因此，對於性別差異，通過閱讀文獻學習我們知道什麼，不知道什麼至關重要。',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '  <b>第三，關於涉及到文化、政治問題，您傾向於認為它們是容易接受的、開放的、自由的、進步的。這樣的思維方式和生存方式，意味著您會衷心期待和新的或與自己不同的想法、方式、狀況、人、社會的相遇，而且能夠享受這樣的機會是件非常了不起的事情。您同時認為這可以給自己的生活、工作、以及社會整體帶來變化，所以或許會對沒有這些共同信念的人您可能會感到不安。</b>再次強調，理解他人為什麼和自己有相同的想法或不同的感受至關重要。例如，不同的制度、文化、或生存方式，所有這些都可能會讓我們體驗到是對自己世界觀的挑戰，因此，其或許會成為一些人不安的因素。您如果不同意這一點的話，對這種情況感到不舒服的人，您或許會將他們作為視野狹隘或單純的人而排除他們。',
    'app.individualreport.variables.textE_2': ' 相反，嘗試去瞭解那些以與您有不同想法或感受的人的背景和經歷。他們或許比您想象的更開放，但卻為如何才能更好地理解與自己截然不同的信仰和價值觀而奮鬥。在與他們的相遇中，他們以及我們所有人，或許會理解我們大家為什麼會相信並重視這樣的事情。',
    'app.individualreport.variables.textF': '  <b>第三，關於涉及到文化、政治問題，您和被認為是進步的或保守的朋友和同事相比，可能會被說成“穩健”或“中規中矩”。您還可以從看似妥協或對立的觀點和想法中找到價值，您的這一姿態對您有利。但這一姿態，可能也表現出您對這些問題沒有深入思考，比較不關心或只不過還不知道針對這一點自己相信什麼。</b>如果這些敘述適合現在的您的話，建議您認真且更頻繁地去思考為何如此相信文化、政治、人種或性別的問題。如果有機會和比您更持明確主張的朋友和同事討論為什麼相信這個的話，不僅意味深長，也將有利於您更明確自己的世界觀。甚至，您的姿態可能會為那些比您有“更強烈信念”的人提供一個讓他們去思考為什麼他們會有如此強烈信念的機會。無論如何，思考自我，並與他人交談，可以讓您對這些影響我們所有人的重要問題所持的信念和價值觀變得更明確。',
    'app.individualreport.variables.textG_1': '  <b>第三，關於涉及到文化、政治問題，不管是國內問題，還是國際問題，比起您的朋友和同事，對於急劇變化的時態及價值觀，您傾向於傳統的、消極的和慎重的。您在沒有充分考慮到這些變化（會給家人、職場、社會等）帶來的影響，以及伴隨導入新的社會系統而帶來的成本問題的情況下，您可能會覺得一個已被證明多年的系統正在受到質疑或摧毀。對沒有這些共同信念的人您可能會感到不安，並且可能會認為他們的主張不成熟，或不負責任。</b>再次強調，理解他人為什麼和自己有相同的想法或不同的感受至關重要。例如，有些人相信“傳統的”價值觀和生存方式，只不過是支配沒有權力、財力和權利的人的手段。這樣的信念，對這些人來說，可能基於他們自身或他們的熟人所經歷的個人成長和環境而形成的。您如果對這一觀點持有不同看法的話，您或許會把他們當成滿腹牢騷的人或單純的人而想排除他們。',
    'app.individualreport.variables.textG_2': '  相反，嘗試更多地瞭解那些與您有不同思考感受的人的背景和經歷。他們比您想像的更加開放，您滿懷誠意虛心接受的話，他可能會比您想象更認真更誠實的跟您對話。就像您有可能被他們說服一樣，他們也可能會因為您的見解的合理性而動心。最後，和這樣的「他人」相遇，有利於促進善意和相互尊重，有利於他人和我們每個人更好地理解我們為什麼會相信重視這樣的事情。',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>關於環境意識，您傾向於認為與地球有很深的親密感和連繫，認為它是唯一必須保護和保存的家園。因此，我們為了保護擁有生命的地球，比起去尋找證據更需要我們去採取更多的行動，儘管如此，或許眾多的人對沒有分享這一想法而感到懊惱。</b>思考這些問題重要的是，各式各樣的團體、文化、宗教會以他們自己認為是正當的理由為依據，這意味著我們可能對人類與自然界和地球的關係有完全不同的理解。我們之間為什麼會有這樣的不同？我們為什麼會因這些問題而動心？嘗試思考這些問題至關重要。因為我們對環境的信仰和價值觀，以及個人、組織、政府及社會體驗理解自然界，與自然界的息息相關，都會對整個世界的環保活動、政策、實踐產生重大影響。不僅訴斥您瞭解的“事實”，重要的是，您要盡可能地尊重他人的立場以更開放的姿態去參與討論。換句話說，就是有必要就您自身的信念，不僅要從理性的觀點（當然這是重要的），也要從為何自己那樣想的觀點與他人共用。這種方式，可能看起來抽象，但它包含了我們堅信的理由與重要的價值觀，這些價值觀是我們對自然的義務（以及不是義務的東西）的“核心”。實際上它更有可能鼓勵我們對環境問題的真正思考。',
    'app.individualreport.ecologicalresonance.textB': '<b>關於環境意識，您在開放地討論保護地球天然資源必要性的同時，傾向於認為自己在環境問題上基本上持“中立的”態度。因此，或許會對環境問題持強烈信念的人抱有疑問。這一差異不僅讓我們有機會瞭解我們在這方面的信念，同時也讓此類重要問題自己所持的信念（或是不足）更明確。</b>思考這些問題重要的是，各式各樣的團體、文化、宗教會以他們自己認為是正當的理由為依據，這意味著我們可能對人類與自然界和地球的關係有完全不同的理解。我們之間為什麼會有這樣的不同？我們為什麼會因這些問題而動心？嘗試思考這些問題至關重要。因為我們對環境的信仰和價值觀，以及個人、組織、政府及社會體驗理解自然界，與自然界的息息相關，都會對整個世界的環保活動、政策、實踐產生重大影響。不僅訴斥您瞭解的“事實”，重要的是，您要盡可能地尊重他人的立場以更開放的姿態去參與討論。換句話說，就是有必要就您自身的信念，不僅要從理性的觀點（當然這是重要的），也要從為何自己那樣想的觀點與他人共用。這種方式，可能看起來抽象，但它包含了我們堅信的理由與重要的價值觀，這些價值觀是我們對自然的義務（以及不是義務的東西）的“核心”。實際上它更有可能鼓勵我們對環境問題的真正思考。',
    'app.individualreport.ecologicalresonance.textC': '<b>關於環境意識，您是一個主張應與地球保持和諧與聯繫的人，並且對於擔心自然界狀態的人，您通常會持懷疑態度。如果您對關於環境的主張持懷疑態度的話，碰到保證公平性的看法，如果能更多地調查現代科學對這些極其重要和複雜的問題的揭示，那就太好了。還值得注意的是，包括基於宗教和信仰的組織，世界上各式各樣的團體，正逐漸意識到管理地球和自然界的重要性。</b>思考這些問題最重要的是，各式各樣的團體、文化、宗教會以他們認為是正當的理由為依據，這意味著我們可能對人類與自然界和地球的關係有完全不同的理解。我們之間為什麼會有這樣的不同？我們為什麼會因這些問題而動心？嘗試思考這些問題是很重要的。因為我們對環境的信仰和世界觀，以及個人、組織、政府及社會體驗理解自然界，與自然界相關的樣態，都會對世界中環境保護活動、政策、實踐產生很大的影響。不僅訴斥您瞭解的“事實”，重要的是，您要盡可能地尊重他人的立場以更開放的姿態去參與討論。換句話說，就是有必要就您自身的信念，不僅要從理性的觀點（當然這是重要的），也要從為何自己那樣想的觀點與他人共用。這種方式，可能看起來抽象，但它包含了我們堅信的理由與重要的價值觀，這些價值觀是我們對自然的義務（以及不是義務的東西）的“核心”。實際上它更有可能鼓勵我們對環境問題的真正思考。',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': '我們最後的“從大視野來看”課題是，最廣泛的意義上的對“全球問題的參與”的您的傾向。<b>整體而言，您傾向於瞭解與自己不同的文化、社會和傳統，並大力支持在尊重它們的基礎上所建立的關係。這一世界觀表明，您傾向於盡可能接納跨文化、全球性的機會和經歷。</b>關於這一點，無論您持何種姿態，世界都會逐漸連繫在一起，考慮到這一事實至關重要。例如，即使某一國的事情（經濟、政治、環境等）和其他國家的大多數人沒有直接關係，也有可能會影響到其他國家的狀況。甚至，隨著技術的發展，您可置身於事故發生的現場，甚至可影響它的發展。這樣，對於他人（不同的文化、宗教等）我們所持的信仰和價值觀，會影響我們如何經歷、面對、應對自己和他人，並且與如何理解、應對與人類息息相關的地球的諸問題與機會有必然的連繫。',
    'app.individualreport.globalengagement.textB': '我們最後的“從大視野來看”課題是，最廣泛的意義上的對“全球問題的參與”的您的傾向。<b>整體而言，您傾向於瞭解與自己不同的文化、社會和傳統，並能感受到在尊重它們的基礎上所建立的關係的必要性。這一世界觀表明，您對相關的跨文化、全球性的機會和經歷感興趣。</b>關於這一點，無論您持何種姿態，世界都會逐漸連繫在一起，考慮到這一事實至關重要。例如，即使某一國的事情（經濟、政治、環境等）和其他國家的大多數人沒有直接關係，也有可能會影響到其他國家的狀況。甚至，隨著技術的發展，您可置身於事故發生的現場，甚至可影響它的發展。這樣，對於他人（不同的文化、宗教等）我們所持的信仰和價值觀，會影響我們如何經歷、面對、應對自己和他人，並且與如何理解、應對與人類息息相關的地球的諸問題與機會有必然的連繫。',
    'app.individualreport.globalengagement.textC': '我們最後的“從大視野來看”課題是，最廣泛的意義上的對”全球問題的參與”的您的傾向。<b>整體而言，您會抱有這樣的疑問，我們有時（或非常頻繁地）對不同的文化、社會和傳統過於關心、寬容或讓步了。</b>關於這一點，無論您持何種姿態，世界都會逐漸連繫在一起，考慮到這一事實至關重要。例如，即使某一國的事情（經濟、政治、環境等）和其他國家的大多數人沒有直接關係，也有可能會影響到其他國家的狀況。甚至，隨著技術的發展，您可置身於事故發生的現場，甚至可影響它的發展。這樣，對於他人（不同的文化、宗教等）我們所持的信仰和價值觀，會影響我們如何經歷、面對、應對自己和他人，並且與如何理解、應對與人類息息相關的地球的諸問題與機會有必然的連繫。',
}

const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': '低',
    'app.individualreport.negativelifeevents.moderate': '中',
    'app.individualreport.negativelifeevents.high': '高',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': '十分滿足',
    'app.individualreport.needsclosure.met_at_times': '有時滿足，但不總是',
    'app.individualreport.needsclosure.not_met_well': '不滿足',
}

export const individualReportPageZHCN: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle, ...individualReportVariables,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};