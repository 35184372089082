import 'antd/dist/reset.css';

import { Button, Radio } from 'antd';
import React from 'react';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';

type Props = FilterDropdownProps & {
    confirmButtonText: string;
    resetButtonText: string;
}

export const TableFilterWithRadioButton = ({
    filters, setSelectedKeys, selectedKeys, confirm, clearFilters, confirmButtonText, resetButtonText
}: Props) => {
    return (
        <div style={styles.container}>
            <Radio.Group
                value={selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : 0}
                onChange={e => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
            >
                {filters?.map((item: ColumnFilterItem) => (
                    <Radio
                        key={item.value + (item.text?.toString() ?? '')}
                        style={styles.radio}
                        value={item.value}
                    >
                        {item.text}
                    </Radio>
                ))}
            </Radio.Group>
            <div style={styles.buttonsContainer}>
                <Button
                    type='primary'
                    size='small'
                    onClick={() => confirm()}
                    style={{ ...styles.button, ...styles.rightSpace8 }}
                >
                    {confirmButtonText}
                </Button>
                <Button onClick={clearFilters} size='small' style={styles.button}>
                    {resetButtonText}
                </Button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        flexDirection: 'column',
        padding: '8px',
    } as React.CSSProperties,
    buttonsContainer: {
        marginTop: '8px'
    } as React.CSSProperties,
    button: {
        verticalAlign: 'baseline',
        width: '100px',
    } as React.CSSProperties,
    radio: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    } as React.CSSProperties,
    rightSpace8: {
        marginRight: '8px',
    } as React.CSSProperties,
}
