import { getContext, resetContext } from 'kea'; // <-- add this
import { localStoragePlugin } from 'kea-localstorage';
import sagaPlugin, { keaSaga } from 'kea-saga';
import Reactotron from 'reactotron-react-js';
import { applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { appKeaPath, appReducer } from './appLogic';
import {
    comparativeByGroupReportPath, comparativeByGroupReportReducer
} from './comparativeByGroupReportLogic';
import {
    comparativeByIndividualReportPath, comparativeByIndividualReportReducer
} from './comparativeByIndividualReportLogic';
import { counterKeaPath, counterReducer } from './counter';
import { demographicQuestionsPath, demographicQuestionsReducer } from './demographicQuestionsLogic';
import { discursiveQuestionsPath, discursiveQuestionsReducer } from './discursiveQuestionsLogic';
import { externalKeaPath, externalReducer } from './externalLogic';
import { financialReportKeaPath, financialReportReducer } from './financialReportLogic';
import { formKeaPath, formReducer } from './formsLogic';
import { groupReportPath, groupReportReducer } from './groupReportLogic';
import { individualReportPath, individualReportReducer } from './individualReportLogic';
import { listOrganizationKeaPath, listOrganizationReducer } from './listOrganizationLogic';
import { listReportKeaPath, listReportReducer } from './listReportLogic';
import { longitudinalReportPath, longitudinalReportReducer } from './longitudinalReportLogic';
import { programKeaPath, programReducer } from './programLogic';
import { reportKeaPath, reportReducer } from './reportLogic';
import { scaleQuestionsPath, scaleQuestionsReducer } from './scaleQuestionsLogic';
import { usersKeaPath, usersReducer } from './usersLogic';
import { organizationKeaPath, organizationReducer } from './organizationLogic';
import { virtualAccountKeaPath, virtualAccountReducer } from './virtualAccountLogic';
import { comparativeIndividualReportPath, comparativeIndividualReportReducer } from './comparativeIndividualReportLogic';

export default () => {
    const reducers = combineReducers({
        app: appReducer,
        counter: counterReducer,
        demographicQuestions: demographicQuestionsReducer,
        discursiveQuestions: discursiveQuestionsReducer,
        forms: formReducer,
        individualReport: individualReportReducer,
        listOrganization: listOrganizationReducer,
        program: programReducer,
        report: reportReducer,
        scaleQuestionsQuestions: scaleQuestionsReducer,
        users: usersReducer,
        groupReport: groupReportReducer,
        listReport: listReportReducer,
        longitudinalReport: longitudinalReportReducer,
        comparativeByGroupReport: comparativeByGroupReportReducer,
        comparativeByIndividualReport: comparativeByIndividualReportReducer,
        external: externalReducer,
        financialReport: financialReportReducer,
        organizations: organizationReducer,
        virtualAccount: virtualAccountReducer,
        comparativeIndividualReport: comparativeIndividualReportReducer
    })
    const sagaMiddleware = createSagaMiddleware({
        sagaMonitor: Reactotron.createSagaMonitor!()
    });
    const middleware = applyMiddleware(sagaMiddleware);

    resetContext({
        // Create a redux store when resetting the context (with options).
        createStore: {
            paths: [
                appKeaPath,
                counterKeaPath,
                demographicQuestionsPath,
                discursiveQuestionsPath,
                formKeaPath,
                individualReportPath,
                listOrganizationKeaPath,
                programKeaPath,
                reportKeaPath,
                scaleQuestionsPath,
                usersKeaPath,
                groupReportPath,
                listReportKeaPath,
                longitudinalReportPath,
                comparativeByGroupReportPath,
                comparativeByIndividualReportPath,
                externalKeaPath,
                financialReportKeaPath,
                organizationKeaPath,
                virtualAccountKeaPath,
                comparativeIndividualReportPath
            ],
            reducers: reducers,
            // gets passed to compose(middleware, ...enhancers)(createStore)
            enhancers: compose(middleware, Reactotron.createEnhancer!())
        },
        // Plugins
        plugins: [
            sagaPlugin({ useLegacyUnboundActions: false }),
            localStoragePlugin,
        ],
    });

    sagaMiddleware.run(keaSaga);

    const store = getContext().store;
    Reactotron.setReduxStore!(store)

    return store;
};
