import React from 'react';
import { GraphBar } from '../../../components/GraphBar'
import { GetReportItemColor } from '../../../utils/reportHelper';
import { ScaleGroup } from '../../../services/reportService';

type Props = {
    hasHR: boolean;
    negativeLifeEvents: ScaleProps[];
    needsClosure: ScaleProps[];
    needsFulfillment: ScaleProps[];
    identityDiffusion: ScaleProps[];
    basicOpenness: ScaleProps[];
    selfCertitude: ScaleProps[];
    basicDeterminism: ScaleProps[];
    socioemotionalConvergence: ScaleProps[];
    physicalResonance: ScaleProps[];
    emotionalAttunument: ScaleProps[];
    selfAwereness: ScaleProps[];
    meaningQuest: ScaleProps[];
    religiousTraditionalism: ScaleProps[];
    genderTraditionalism: ScaleProps[];
    socioCulturalOpenness: ScaleProps[];
    ecologicalResonance: ScaleProps[];
    globalResonance: ScaleProps[];
}

type ScaleProps = {
    title: string;
    value: number;
    hasSpace?: boolean;
}

export const ScaleGraphBarList = (props: Props): JSX.Element => {
    return (
        <React.Fragment>
            {props.negativeLifeEvents.map((item, index) =>
                item.value > 0 && <GraphBar key={`negativeLifeEvents-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.NegativeLifeEvents)} />
            )}
            {props.hasHR && <hr />}
            {props.needsClosure.map((item, index) =>
                item.value > 0 && <GraphBar key={`needsClosure-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.NeedsClosure)} />
            )}
            {props.hasHR && <hr />}
            {props.needsFulfillment.map((item, index) =>
                item.value > 0 && <GraphBar key={`needsFulfillment-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.NeedsFulfillment)} />
            )}
            {props.hasHR && <hr />}
            {props.identityDiffusion.map((item, index) =>
                item.value > 0 && <GraphBar key={`identityDiffusion-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.IdentityDiffusion)} />
            )}
            {props.hasHR && <hr />}
            {props.basicOpenness.map((item, index) =>
                item.value > 0 && <GraphBar key={`basicOpenness-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.BasicOpenness)} />
            )}
            {props.hasHR && <hr />}
            {props.selfCertitude.map((item, index) =>
                item.value > 0 && <GraphBar key={`selfCertitude-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.SelfCertitude)} />
            )}
            {props.hasHR && <hr />}
            {props.basicDeterminism.map((item, index) =>
                item.value > 0 && <GraphBar key={`basicDeterminism-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.BasicDeterminism)} />
            )}
            {props.hasHR && <hr />}
            {props.socioemotionalConvergence.map((item, index) =>
                item.value > 0 && <GraphBar key={`socioemotionalConvergence-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.SocioemotionalConvergence)} />
            )}
            {props.hasHR && <hr />}
            {props.physicalResonance.map((item, index) =>
                item.value > 0 && <GraphBar key={`physicalResonance-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.PhysicalResonance)} />
            )}
            {props.hasHR && <hr />}
            {props.emotionalAttunument.map((item, index) =>
                item.value > 0 && <GraphBar key={`emotionalAttunument-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.EmotionalAttunement)} />
            )}
            {props.hasHR && <hr />}
            {props.selfAwereness.map((item, index) =>
                item.value > 0 && <GraphBar key={`selfAwereness-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.SelfAwereness)} />
            )}
            {props.hasHR && <hr />}
            {props.meaningQuest.map((item, index) =>
                item.value > 0 && <GraphBar key={`meaningQuest-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.MeaningQuest)} />
            )}
            {props.hasHR && <hr />}
            {props.religiousTraditionalism.map((item, index) =>
                item.value > 0 && <GraphBar key={`religiousTraditionalism-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.ReligiousTraditionalism)} />
            )}
            {props.hasHR && <hr />}
            {props.genderTraditionalism.map((item, index) =>
                item.value > 0 && <GraphBar key={`genderTraditionalism-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.GenderTraditionalism)} />
            )}
            {props.hasHR && <hr />}
            {props.socioCulturalOpenness.map((item, index) =>
                item.value > 0 && <GraphBar key={`socioCulturalOpenness-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.SocioculturalOpenness)} />
            )}
            {props.hasHR && <hr />}
            {props.ecologicalResonance.map((item, index) =>
                item.value > 0 && <GraphBar key={`ecologicalResonance-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.EcologicalResonance)} />
            )}
            {props.hasHR && <hr />}
            {props.globalResonance.map((item, index) =>
                item.value > 0 && <GraphBar key={`globalResonance-${index}`} hasSpace={item.hasSpace} title={item.title} value={item.value} personalizedBarColor={GetReportItemColor(ScaleGroup.GlobalResonance)} />
            )}
        </React.Fragment>
    )
}