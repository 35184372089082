import React from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';

import { DiscursiveQuestionsPageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    labelIdMessage: keyof (DiscursiveQuestionsPageMessages);
    onChange: (answerValue: string) => void;
};

export const FormTextArea = ({ labelIdMessage, onChange }: Props): JSX.Element => {
    return (
        <Form.Group className='mb-3' controlId={labelIdMessage}>
            <Form.Label> {GetFormattedMessage(labelIdMessage)} </Form.Label>
            <InputGroup>
                <InputGroup.Text><FaPencilAlt /></InputGroup.Text>
                <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    rows={3}
                    onChange={(e) => onChange(e.currentTarget.value)}
                />
            </InputGroup>
        </Form.Group>
    );
}
