import React from 'react';
import { SelectItem } from '../../../../components/FormDropdown';
import { OrganizationDetails } from '../../../../services/organizationService';
import { FilterProgram } from './FilterProgram';

type Props = {
    searchData: { organizationId?: number, programQuery: string, subProgramQuery: string };
    organizations: OrganizationDetails[];
    onSearchHandleClick(organizationId: number, programQuery: string, subProgramQuery: string): void;
}

export const FilterProgramContainer = ({ searchData, organizations, onSearchHandleClick }: Props): JSX.Element => {
    const organizationSelectOptions: Array<SelectItem> =
        organizations.map((item: OrganizationDetails) => ({ value: item.id, label: item.name }));
    return (
        <FilterProgram
            searchData={searchData}
            organizations={organizationSelectOptions}
            searchHandleClick={onSearchHandleClick}
        />
    );
}
