import React, { useEffect } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { ValidateExternalToken } from "../../utils/validateHelper";
import { AppActions, appLogic } from "../../redux/appLogic";
import { useActions } from 'kea';
import { DASHBOARD_PAGE, LOGIN_PAGE } from "../../router/pages";

type Props = RouteComponentProps;

export const LoadingMyBevi = withRouter((props: Props): JSX.Element => {
    const { token } = useParams<{ token: string }>();
    
    const { setToken }: AppActions = useActions(appLogic);

    useEffect(() => {
        if (!ValidateExternalToken(token)) {
            setToken(token);
            props.history.push(DASHBOARD_PAGE);
        } else {
            props.history.push(LOGIN_PAGE);
        }
        // eslint-disable-next-line
    }, [token]);

    return <></>;
})