import { VirtualAccountMessages } from '../types';

export const virtualAccountDE: VirtualAccountMessages = {
  'app.virtual_account.title': 'Virtuelles Konto nach Institution',
  'app.virtual_account.add_interactions': 'Interaktionen hinzufügen',
  'app.virtual_account.add_interactions_to_organization': 'Fügen Sie Interaktionen für die Organisation hinzu:',
  'app.virtual_account.interactions_placeholder': "Geben Sie die Anzahl der Iterationen ein",
  'app.virtual_account.successful_message': 'Das virtuelle Konto wurde erfolgreich aktualisiert.',
  'app.virtual_account.day': 'Tag',
  'app.virtual_account.interaction_credit': 'Interaktions-Credits',
  'app.virtual_account.interaction_debit': 'Interaktionsbelastungen',
  'app.virtual_account.balance': 'Gleichgewicht',
  'app.virtual_account.total_balance_month': 'Der Gesamtsaldo für den Monat beträgt ',
  'app.virtual_account.interactions_available': 'interaktionen verfügbar'
}