import React from 'react';
import { Image } from 'react-bootstrap';
import { colors } from '../config/colors';
import { isMaintenence, maintenanceEndDate, maintenanceStartDate } from '../config/constants';
import { PageMessages } from '../locales/types';
import { GetDate } from '../utils/dateHelper';
import { GetFormattedMessage } from '../utils/htmlHelper';

type Props = {
    title: keyof (PageMessages),
    subTitle?: keyof (PageMessages),
    body?: Array<keyof (PageMessages)>,
    image: string;
}

export const NoInfo = ({ title, subTitle, body, image }: Props): JSX.Element => {
    return (
        <div style={styles.container}>
            <div style={styles.image}>
                <Image src={image} width={250} height={250} />
            </div>
            <div style={styles.textContainer}>
                <h1>
                    {GetFormattedMessage(title)}
                </h1>
                {subTitle &&
                    <h3 style={styles.subTitle}>{GetFormattedMessage(subTitle)}</h3>
                }
                {body && !isMaintenence &&
                    body.map((item, index) => <p key={index}>{GetFormattedMessage(item)}</p>)
                }
                {isMaintenence && maintenanceStartDate && maintenanceEndDate &&
                    GetFormattedMessage('app.internaltemplate.system.maintenance_time').replace("{0}", GetDate(maintenanceStartDate)).replace("{1}", GetDate(maintenanceEndDate))
                }
            </div>

        </div>
    );
}

const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%'
    } as React.CSSProperties,
    subTitle: {
        width: 600,
        textAlign: 'left',
    } as React.CSSProperties,
    textContainer: {
        marginLeft: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
    } as React.CSSProperties,
    text: {
        color: colors.black,
    } as React.CSSProperties,
    image: {
        marginLeft: 20
    } as React.CSSProperties,
}
