import React from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    onSaveInstitutionModalOpen: () => void;
};

export const InstitutionsTitle = (
    { onSaveInstitutionModalOpen
}: Props): JSX.Element => {

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {GetFormattedMessage('app.institutions.title')}
                    </h2>
                    <ButtonWithIcon
                        textId={'app.institutions.add.title'}
                        onClick={onSaveInstitutionModalOpen}
                        icon={<IoMdAddCircleOutline />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
};
