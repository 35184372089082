import { InvitationPageMessages } from '../types';

export const invitationZHTW: InvitationPageMessages = {
    'app.invitation.invite_label_template': 'You were invited to participate in the BEVI by {0}. You will be registered for program {1} and group {2}.',
    'app.invitation.email': 'Email',
    'app.invitation.password': 'Password',
    'app.invitation.confirm_password': 'Repeat password',
    'app.invitation.name': 'Name',
    'app.invitation.language': 'Language',
    'app.invitation.blank_feedback': 'This field cannot be blank!',
    'app.invitation.invalid_email_feedback': 'This email is invalid!',
    'app.invitation.password_length_feedback_template': 'This field must have at least {0} characters!',
    'app.invitation.confirm_password_not_equal_feedback': 'This field must be equals to the password!',
    'app.invitation.success_notification_header': 'Welcome to BEVI!',
    'app.invitation.success_notification_body': 'Your registered email and password will be used to log into the system in the next few times.',
    'app.invitation.error_notification_header': 'An error occurred.',
    'app.invitation.error_notification_body': 'Please check the data sent and try again.',
}
