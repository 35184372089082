import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormDropdown } from '../../../components/FormDropdown';

import { FormDetailsExtraQuestionOptions } from '../../../services/listFormsService';
import { ColSpec } from '../../../utils/formHelper';

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    questionId: number;
    title: string;
    type: number;
    extraQuestionOptions?: FormDetailsExtraQuestionOptions[],
    answerChange: (key: 'ExtraQuestions', value: { id: number, value: any }) => void;
    questionNumber: number;
    style?: React.CSSProperties;
};

export const ExtraQuestion = (
    { optionsMd, questionId, title, type, extraQuestionOptions, style, answerChange, questionNumber, ...groupProps }: Props
): JSX.Element => {
    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    {title}
                </div>
            </Form.Label>
            <Col md={optionsMd} style={styles.colDiv}>
                {type === 1 ?
                    <Form.Control as='input' onChange={(e) => answerChange('ExtraQuestions', { id: questionId, value: e.currentTarget.value })} />
                    :
                    <FormDropdown
                        defaultOptionId={'app.dropdown_select'}
                        onValueSelect={(e) => answerChange('ExtraQuestions', { id: questionId, value: e })}
                        optionItens={extraQuestionOptions != null ? extraQuestionOptions!.map(x => {
                            return { label: x.description, value: x.id }
                        }) : []}
                    />
                }
            </Col>
        </Form.Group>
    );
}

const styles = {
    colDiv: {
        minWidth: '80px',
    } as React.CSSProperties,
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px',
    } as React.CSSProperties,
};
