import React from 'react';

import { Reports } from '../../../services/reportService';
import { Step1 } from './Step1';
import { TokenInfo } from '../../../services/loginService';

type Props = {
  userInfo: TokenInfo;
  reportName?: string;
  reportType?: Reports;
  onReportNameChanged: (reportName: string) => void;
  onReportTypeChanged: (reportType?: Reports) => void;
};
export const Step1Container = (
  {
    userInfo, reportName, reportType, onReportNameChanged, onReportTypeChanged
  }: Props
): JSX.Element => {
  return (
    <Step1
      userInfo={userInfo}
      reportName={reportName}
      reportType={reportType}
      onReportNameChanged={onReportNameChanged}
      onReportTypeChanged={onReportTypeChanged}
    />
  );
}
