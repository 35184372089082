import { ConsentPageMessages } from '../types';

export const consentPageES: ConsentPageMessages = {
    'app.consent.title': 'Formulario de consentimiento',
    'app.consent.form_title': 'Formulario de consentimiento',
    'app.consent.greeting': 'Estimado participante:',
    'app.consent.introduction': 'Gracias por acceder a completar el Test sobre Creencias, Vivencias y Valores o BEVI.',
    'app.consent.general.observation': 'Este formulario de consentimiento general se basa en las observaciones derivadas de numerosos procedimientos desarrollados por un Comité Institucional de Revisión de Protocolos. Antes de completar el BEVI, es posible que se le solicite consentimiento por medio de un sistema o formulario externo aprobado por una institución u organización determinada.',
    'app.consent.explanation_1': 'Como sugiere el propio nombre del test, el BEVI está diseñado para evaluar las muchas formas en que la gente se ve a sí misma, a otros y al mundo en general. Tras completar una serie de preguntas sobre sus antecedentes personales, se le solicitará responder a una serie de ítems que cubren una amplia variedad de asuntos y temas. Podrá tener diferentes reacciones en función del ítem. Algunos de estos ítems le podrán parecer relacionados directamente con su aprendizaje, crecimiento y desarrollo, pero no todos lo están; algunos podrán parecerle claros y directos mientras que otros podrían resultarle ambiguos. Dado que el cuestionario evalúa numerosos factores complejos e interrelacionados, tanto estos ítems como las preguntas sobre sus antecedentes personales son relevantes a la hora de ayudarnos a entender procesos relacionados con el aprendizaje, crecimiento y desarrollo así como la forma en que cada persona se ve a sí misma, a otros y al mundo en general.',
    'app.consent.explanation_2': 'Aunque cada persona podrá responder de distintas formas a estos ítems, tenga en cuenta que no existen respuestas "correctas" o "incorrectas" al BEVI. Dado que los individuos solemos diferir en nuestras creencias, valores y vivencias, es habitual y esperable que una persona esté muy de acuerdo con un ítem mientras que otra distinta se muestre muy en desacuerdo con el mismo ítem. Al permitir respuestas que van desde Muy de acuerdo o De acuerdo hasta En desacuerdo o Muy en descuerdo, el BEVI reconoce de forma explícita que cada persona puede tener diferentes creencias, valores y vivencias.',
    'app.consent.participant.rights.1': 'Su participación en este estudio es voluntaria y puede retirarse del mismo en cualquier momento. Su privacidad se protegerá conforme a derecho hasta sus últimas consecuencias. Todas sus respuestas se tratarán de forma <b>estrictamente confidencial</b>. Ninguna de las respuestas que proporcione <b>se podrán relacionar en modo alguno con usted a menos que usted lo autorice expresamente por escrito.</b> No obstante, en determinados casos, puede que se le invite a participar en una experiencia en que sus respuestas se repasen directamente con usted. Si esto sucediera, sus respuestas se tratarán de manera estrictamente confidencial; su privacidad se protegerá conforme a derecho hasta sus últimas consecuencias como si se tratase de información con consideración de reservada. Solo los coordinadores del proyecto tendrán acceso a los datos en bruto. Para fines académicos, docentes, profesionales, organizativos, informativos o ilustrativos es posible que se publiquen o presenten determinadas escalas o ítems, datos de perfil de grupo o informes institucionales. No obstante, si así fuera, nunca se presentarán o publicarán datos identificativos que pudieran relacionarle a usted de forma directa con un perfil específico, escala, ítem o respuesta (i.e., cualquier dato identificativo que haya proporcionado será eliminado), a menos que usted lo autorice expresamente por escrito.',
    'app.consent.participant.rights.2': 'Dependiendo de cuándo y en qué contexto se encuentre usted haciendo el BEVI, es posible que se le dé la oportunidad de leer un informe de sus respuestas. Si así fuera, puede que se le solicite mostrar su conformidad con una serie de condiciones por separado antes de poder leer tal informe. También es posible que se le solicite participar en una sesión de discusión de un informe de grupo; este informe podrá incluir datos BEVI de un grupo más extenso del que usted habrá podido ser miembro. Como parte de proyectos de evaluación del BEVI, también es posible que se le solicite responder a otras preguntas cuantitativas (i.e., elección múltiple) o cualitativas (i.e., respuestas por escrito a preguntas) y/o proporcionar información adicional por medio de una entrevista con preguntas que contestar oralmente o por escrito. Este proceso podría requerir discusiones de seguimiento con el administrador o intérprete de un aspecto evaluativo determinado. Su participación en todos estos procesos es voluntaria y podrá decidir retirarse de los mismos en cualquier momento. Como ya se ha señalado, ninguna de las respuestas que proporcione en un informe de grupo o de cualquier otra naturaleza podrá relacionarse con usted. Finalmente, podría proponérsele participar en una sesión de feedback tras completar el BEVI o tras participar en cualquier proceso evaluativo tanto cuantitativo como cualitativo bajo los mismos términos ya señalados.',
    'app.consent.understand.agree': 'Si ha entendido y está de acuerdo con la información y pautas anteriormente indicadas y accede voluntariamente a completar el BEVI y participar en una o más experiencias en que se utilice el BEVI (i.e., tal y como se describe con anterioridad), por favor haga click en el botón \'Acepto\'.',
    'app.consent.have.questions': 'Si tiene alguna pregunta, no dude en contactar con nosotros a través de <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>',
    'app.consent.thanks': 'Gracias por su participación.',
    'app.consent.giving.consent': 'Prestación de consentimiento',
    'app.consent.read.and.agree': 'He leído la información relativa al BEVI y este formulario de consentimiento y entiendo lo que se me requiere como participante. Acepto ser incluido como participante por voluntad propia. He leído la descripción de este proyecto y mis derechos como participante. Al hacer click en el botón Acepto que hay a continuación, y completar y enviar esta encuesta confidencial en línea, estoy dando mi consentimiento para ser incluido como participante.',
    'app.consent.agree': 'Acepto',
    'app.consent.disagree': 'En desacuerdo',
    'app.custom_agreement.modal_title': 'Informar de acuerdo de revisión',
    'app.custom_agreement.modal_explanation_text': 'Por favor, edita el texto del acuerdo personalizado antes de enviarlo.',
    'app.custom_agreement.default_custom_agreement': 'Estimado participante BEVI:<br/><br/>Ya has dado tu consentimiento para hacer el BEVI, pero antes de poder generar tu informe BEVI te pedimos que muestres tu conformidad con 1) participar en una o más sesiones donde discutiremos tu informe individual contigo y 2) autorizar a <strong>[here we need to be able to fill in one or more names of individual people]</strong> a revisar tu informe individual (i. e. tu narrativa, puntuación) y discutir el sentido de tu informe BEVI y sus implicaciones contigo.<br/>Obviamente, como se indica en el formulario de consentimientos BEVI, tus resultados particulares serán absolutamente confidenciales. Si tienes preguntas, o necesitas más información, por favor, ponte en contacto con tu administrador BEVI.<br/><br/>Si entiendes y estás de acuerdo a los dos puntos anteriores, por favor, haz click en el botón "De acuerdo" a continuación. ¡Gracias por tu interés en el BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>Ya dio su consentimiento para realizar el BEVI, pero antes de que podamos trabajar con usted en uno o más de sus informes BEVI (por ejemplo, con fines de capacitación, consultoría, asesoramiento, etc.), le preguntamos si está de acuerdo con los siguientes puntos. :</h5>
    <br />

    <p>
    1. Entiendo que existen varios tipos de informes BEVI en los que alguien certificado por BEVI puede discutir mis resultados conmigo: 1) Informe individual, 2) Informe de cambio individual, 3) Informe de socio y 4) Informe de cambio de socio. También entiendo que debo dar permiso a cualquier otra persona para revisar y discutir uno o más de mis informes conmigo.
    </p>
    <p>
    2. Al hacer clic en el botón Aceptar a continuación, autorizo a la siguiente persona o personas certificadas por BEVI, <b>[#USERS#]</b>, a revisar y discutir uno o más de mis informes conmigo.
    </p>
    <p>
    3.	También entiendo y acepto que:
        <p style="padding-left: 20px;">
        a) Autorizo al siguiente socio o socios, <b>[#PARTNERS#]</b>, a revisar y discutir mi informe/resultados y su informe/resultados, conmigo y con la persona o personas certificadas por BEVI. que he autorizado anteriormente;
        </p>
        <p style="padding-left: 20px;">
        b) No compartiré ni discutiré ningún Informe de socio o Informe de cambio de socio, en su totalidad o en parte, o de cualquier forma (por ejemplo, a través de correo electrónico, publicación, distribución, exhibición, copia, etc.), con nadie que no sea mi socio ( s) y las personas certificadas por BEVI que se enumeran anteriormente; cualquier excepción a esta condición será aceptada por escrito por mí y por todas las personas enumeradas aquí: socio o socios y la persona o personas certificadas por BEVI;
        </p>
        <p style="padding-left: 20px;">
        c) el socio o los socios que he autorizado anteriormente pueden eliminar su(s) Informe(s) de socio o Informe(s) de cambio de socio en cualquier momento cambiando el perfil de permiso en su panel de BEVI, lo que eliminará el(los) informe(s) de su BEVI tablero y el mío.
        </p>
    </p>
    <p>
    4. Además de las condiciones anteriores, todas las demás disposiciones enumeradas en el Formulario de consentimiento de BEVI seguirán vigentes (por ejemplo, la naturaleza voluntaria de mi participación, etc.). Si tiene alguna pregunta o necesita más información, comuníquese con la persona certificada por BEVI o las personas que trabajan con usted.
    </p>
    <p>
    5. Si está de acuerdo con todos los puntos anteriores, haga clic en "Acepto" a continuación. Si no está de acuerdo con todos los puntos anteriores, haga clic en "No estoy de acuerdo" a continuación.
    </p>
    <center>¡Gracias por su interés en BEVI!</center>
    `,
}



