import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { SelectItem } from '../../components/FormDropdown';
import { PageMessages } from '../../locales/types';
import { listOrganizationLogic, ListOrganizationProps } from '../../redux/listOrganizationLogic';
import { ProgramActions, programLogic, ProgramProps } from '../../redux/programLogic';
import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import {
    CREATE_PROGRAM, CREATE_SUBPROGRAM, EDIT_SUBPROGRAM, PROGRAMS_PAGE
} from '../../router/pages';
import {
    SearchOrganizationDetails, SearchProgramsDetails
} from '../../services/organizationService';
import { SendProgram, UserFormDetailsSubProgram, UserSubProgramPayload } from '../../services/programService';
import { ProgramModel } from '../../services/reportService';
import { OrganizationUser } from '../../services/usersService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { CreateProgram } from './CreateProgram';

type Props = RouteComponentProps;

type UploadedFile = {
    name: string;
    email: string;
    isActive: boolean;
}

type Error = {
    error: string;
    row: number;
    column: number;
    value?: any;
    type?: any;
};

type ParsedObjectsResult = {
    rows: UploadedFile[];
    errors: Error[];
}

export const CreateProgramContainer = withRouter((props: Props): JSX.Element => {
    const {
        selectedProgram,
        selectedSubprogram,
        searchOrganizationResults,
    }: ListOrganizationProps = useValues(listOrganizationLogic);

    const {
        getOrganizationUsersOnlyParticipants,
        saveProgram,
        clearLastSubProgramUsers,
    }: UsersActions = useActions(usersLogic);

    const {
        organizationUsers,
        loadingOrganizationUsers,
        organizationError,
        lastSubProgramUsers,
        errorSaveProgram,
        loadingSaveProgram,
    }: UsersProps = useValues(usersLogic);

    const {
        clearSubProgramDetails,
        loadSubProgramDetails,
        getSubProgram,
        getUserSubProgram,
        clearUserSubProgram,
        clearSubProgram
    }: ProgramActions = useActions(programLogic);

    const {
        loading: subProgramLoading,
        error: subProgramError,
        subProgramDetails,
        subProgram,
        loadingSubProgram,
        userSubProgram,
        loadingUserSubProgram
    }: ProgramProps = useValues(programLogic);

    const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const [showModalSubPrograns, setShowModalSubPrograns] = useState<boolean>(false);
    const [selectSubProgram, setSelectSubProgram] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (userSubProgram != null) {
            let newSelectUsers = [...selectedUsers];
            const usersIds = newSelectUsers.map(x => x.id);
            userSubProgram.users.forEach((x: UserSubProgramPayload) => {
                if (!usersIds.includes(x.id)) {
                    newSelectUsers.push(x);
                }
            })
            setSelectedUsers(newSelectUsers);
            clearUserSubProgram();
        }
    }, [userSubProgram, selectedUsers, clearUserSubProgram])

    // Effect validate the selected program, subprogram and organization.
    useEffect(() => {
        const redirectPath = !searchOrganizationResults ||
            (props.location.pathname === CREATE_SUBPROGRAM && !selectedProgram) ||
            (props.location.pathname === EDIT_SUBPROGRAM && (!selectedProgram || !selectedSubprogram));

        if (redirectPath) props.history.replace(PROGRAMS_PAGE);
    }, [selectedProgram, selectedSubprogram, searchOrganizationResults, props.history, props.location]);

    const organizationName = searchOrganizationResults?.name;
    const isSubProgram = props.location.pathname !== CREATE_PROGRAM;

    const [program, setProgram] = useState<SendProgram>(GetDefaultProgram(searchOrganizationResults, selectedProgram));

    // Reset on unmount for the next mount.
    useEffect(() => {
        return () => {
            clearSubProgramDetails();
            clearLastSubProgramUsers();
            clearSubProgram();
        }
        // eslint-disable-next-line
    }, []);

    // Effect to catch all users belonging to the selected organization (similar to componentdidmount).
    useEffect(() => {
        if (getOrganizationUsersOnlyParticipants && searchOrganizationResults) {
            getOrganizationUsersOnlyParticipants(searchOrganizationResults.id);
        }
    }, [getOrganizationUsersOnlyParticipants, searchOrganizationResults]);

    // Effect to fetch selected subprogram (similar to componentdidmount).
    useEffect(() => {
        if (props.location.pathname === EDIT_SUBPROGRAM && selectedSubprogram) {
            loadSubProgramDetails && loadSubProgramDetails(selectedSubprogram.id);
        }
    }, [props.location, clearSubProgramDetails, loadSubProgramDetails, selectedSubprogram]);

    // Effect to fetch selected subprogram (similar to componentdidmount).
    useEffect(() => {
        if (subProgramDetails) {
            setProgram(p => ({
                ...p,
                subProgramId: subProgramDetails.id,
                subProgramName: subProgramDetails.description,
                year: subProgramDetails.year,
                dueDate: subProgramDetails.dueDateOnUtc,
                emailMessage: subProgramDetails.emailMessage,
            }));

            const userIds = subProgramDetails?.usersAndFormsDetails?.map(
                (u: UserFormDetailsSubProgram) => (
                    { id: u.userId, login: u.login, name: u.name, isActive: u.isUserActive }
                ));
            userIds && setSelectedUsers(userIds);
        } else {
            setProgram(GetDefaultProgram(searchOrganizationResults, selectedProgram));
            setSelectedUsers([]);
        }
        // eslint-disable-next-line
    }, [subProgramDetails]);

    // Effect to define userIds for the program when the selected users are changed.
    useEffect(() => {
        const selectedUserIds = selectedUsers.map((user: OrganizationUser) => user.id);
        setProgram((p: SendProgram) => ({ ...p, userIds: selectedUserIds }));
    }, [selectedUsers, setProgram]);

    // Effect to react to the response of the "UseUsersFromLastSubProgram" button request.
    useEffect(() => {
        if (lastSubProgramUsers) {
            const lastSubProgramUsersDiff: OrganizationUser[] = lastSubProgramUsers
                .filter((user: OrganizationUser) => !selectedUsers.find(lastUser => lastUser.id === user.id));
            if (lastSubProgramUsersDiff.length > 0) {
                setSelectedUsers(s => [...s, ...lastSubProgramUsersDiff])
            }
        }
    }, [lastSubProgramUsers, selectedUsers]);

    // Effect to react to SaveProgram response.
    useEffect(() => {
        if (loadingSaveProgram === false && sending === true) {
            setShowConfirmation(true)
        }
    }, [loadingSaveProgram, sending]);

    const availableUsers: Array<SelectItem> = [...organizationUsers]
        .filter((user: OrganizationUser) => !selectedUsers?.find(selectedUser => selectedUser.id === user.id))
        .map((user: OrganizationUser) => ({ value: user.id, label: user.name + ` [${user.login}]` }));


    // handle functions.
    const handleUserSelectChange = (userId: number): void => {
        if (!selectedUsers.map(x => x.id).includes(userId)) {
            const user = organizationUsers.find((x: OrganizationUser) => x.id === userId);
            if (user != null)
                setSelectedUsers([...selectedUsers, user]);
        }
    }

    const handleUserRemoveClick = (userId: number): void => {
        const newSelectedUsers = selectedUsers.filter((user: OrganizationUser) => user.id !== userId)
        setSelectedUsers(newSelectedUsers);
    }

    const handleClearSelectedUsers = (): void => {
        setSelectedUsers([]);
    }

    const handleReuseLastSubProgramUsers = (): void => {
        if (program?.programId != null) {
            getSubProgram(program.programId);
            setShowModalSubPrograns(true);
        }
    }

    const handleProgramUpdate = (newValue: Partial<SendProgram>): void => {
        setProgram({ ...program, ...newValue });
    }

    const handleSaveButtonClick = (): void => {
        // TODO(rodrigo.santos): Make sure that all be filled.
        saveProgram(program);
        setSending(true);
    }

    const handleConfirmationOkClick = (): void => {
        if (errorSaveProgram) {
            setShowConfirmation(false);
        } else {
            props.history.goBack();
        }
    }

    const prepareConfirmationBody = (): Array<keyof (PageMessages)> => {
        if (props.location.pathname === EDIT_SUBPROGRAM) {
            return errorSaveProgram ?
                ['app.edit_sub_program.confirmation_body_fail']
                :
                ['app.edit_sub_program.confirmation_body_success'];
        }

        const message: keyof (PageMessages) = errorSaveProgram ?
            (isSubProgram ?
                'app.create_program.confirmation_body_fail_subprogram'
                :
                'app.create_program.confirmation_body_fail_program'
            )
            :
            (isSubProgram ?
                'app.create_program.confirmation_body_success_subprogram'
                :
                'app.create_program.confirmation_body_success_program'
            );

        return [message]
    }

    const prepareConfirmationTitle = (): keyof (PageMessages) => {
        if (props.location.pathname === EDIT_SUBPROGRAM) {
            return errorSaveProgram ?
                'app.edit_sub_program.confirmation_title_fail'
                :
                'app.edit_sub_program.confirmation_title_success';
        }

        return errorSaveProgram ?
            'app.create_program.confirmation_title_fail'
            :
            'app.create_program.confirmation_title_success';
    }

    // Upload
    const excelNameColumn = GetFormattedMessage('app.create_program.excel.name_column');
    const excelLoginColumn = GetFormattedMessage('app.create_program.excel.login_column');
    const excelActiveColumn = GetFormattedMessage('app.create_program.excel.active_column');

    const schema = {
        [excelNameColumn]: {
            prop: 'name',
            type: String,
            required: true,
        },
        [excelLoginColumn]: {
            prop: 'email',
            type: String,
            required: true,
        },
        [excelActiveColumn]: {
            prop: 'isActive',
            type: Boolean,
            required: true,
        },
    }

    const [rejectedFilesError, setRejectedFilesError] = useState<string[]>([]);
    const [parsedFilesError, setParsedFilesError] = useState<string[]>([]);
    const [parsedFilesUnknowError, setParsedFilesUnknowError] = useState<string[]>([]);

    const uploadRejectedFilesErrorBodyPrefix = GetFormattedMessage('app.create_program.upload_rejected_files_error_body_prefix');
    const uploadParsedFilesErrorBodyPrefix = GetFormattedMessage('app.create_program.upload_parsed_files_error_body_prefix');
    const uploadParsedFilesErrorBody = GetFormattedMessage('app.create_program.upload_parsed_files_error_body');
    const uploadParsedFilesUnknowErrorBodyPrefix = GetFormattedMessage('app.create_program.upload_parsed_files_unknow_error_body_prefix');

    const handleFileAdded = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length > 0) {
            const rejectedFilenames = rejectedFiles.map((file) => file.file.name)
            setRejectedFilesError([uploadRejectedFilesErrorBodyPrefix, ...rejectedFilenames]);
        }

        setUploadingFile(true);

        let parseError: string[] = []
        let parseUnknowError: string[] = []
        for (const file of acceptedFiles) {
            try {
                const parsed: ParsedObjectsResult = await readXlsxFile(file, { schema })
                const newSelectedUsers: OrganizationUser[] = [];
                const selectedUsersLogins = selectedUsers.map(x => x.login);
                parsed.rows.forEach((row: UploadedFile) => {
                    if (!selectedUsersLogins.includes(row.email)) {
                        const user: OrganizationUser = organizationUsers.find((user: OrganizationUser) => {
                            return user.name.toUpperCase() === row.name.toUpperCase() && user.login.toUpperCase() === row.email.toUpperCase();
                        });
                        if (!!user && availableUsers.find((value: SelectItem) => value.value === user.id)) {
                            newSelectedUsers.push(user)
                        }
                    }
                });
                if (parsed.errors.length > 0) {
                    parseError.push(
                        uploadParsedFilesErrorBody
                            .replace("{0}", file.name)
                            .replace("{1}", parsed.errors.length.toString())
                    );
                }
                if (newSelectedUsers.length > 0) setSelectedUsers([...selectedUsers, ...newSelectedUsers]);
            } catch (error) {
                parseUnknowError.push(file.name)
            }
        }
        if (parseError.length > 0) {
            const prefix = uploadParsedFilesErrorBodyPrefix
                .replace("{0}", excelNameColumn)
                .replace("{1}", excelLoginColumn)
                .replace("{2}", excelActiveColumn)
            setParsedFilesError([prefix, ...parseError]);
        }
        if (parseUnknowError.length > 0) {
            setParsedFilesUnknowError([uploadParsedFilesUnknowErrorBodyPrefix, ...parseUnknowError]);
        }

        setUploadingFile(false);
    };

    const { open, getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileAdded,
        noClick: true,
        noKeyboard: true,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': []
        }
    });

    const columns: ColumnProps<any>[] = [{
        dataIndex: 'description',
        title: GetFormattedMessage('app.create_program.table.column_name'),
        key: "description",
    }]

    return (
        <React.Fragment>
            <CreateProgram
                loadingUserSubProgram={loadingUserSubProgram}
                organizationName={organizationName}
                allOrganizationUsers={organizationUsers}
                program={program}
                isSubProgram={isSubProgram}
                availableUsers={availableUsers}
                selectedUsers={selectedUsers}
                onSaveButtonClicked={handleSaveButtonClick}
                onUserSelected={handleUserSelectChange}
                onUserRemoveClicked={handleUserRemoveClick}
                onClearAllUserClicked={handleClearSelectedUsers}
                onUseUsersFromLastSubProgramClicked={handleReuseLastSubProgramUsers}
                onProgramValuesUpdated={handleProgramUpdate}
                onBackButtonClicked={props.history.goBack}
                loading={loadingOrganizationUsers || subProgramLoading || loadingSaveProgram}
                uploadingUsers={uploadingFile}
                error={organizationError || subProgramError}
                openDropzone={open}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={prepareConfirmationTitle()}
                bodyFormattedListMessageId={prepareConfirmationBody()}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={handleConfirmationOkClick}
                onPrimaryButtonClicked={handleConfirmationOkClick}
            />

            <ConfirmationModal
                showModal={rejectedFilesError.length > 0}
                titleFormattedMessageId={'app.create_program.upload_files_error_title'}
                bodyFormattedText={rejectedFilesError}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={() => setRejectedFilesError([])}
                onPrimaryButtonClicked={() => setRejectedFilesError([])}
            />

            <ConfirmationModal
                showModal={parsedFilesError.length > 0}
                titleFormattedMessageId={'app.create_program.upload_files_error_title'}
                bodyFormattedText={parsedFilesError}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={() => setParsedFilesError([])}
                onPrimaryButtonClicked={() => setParsedFilesError([])}
            />

            <ConfirmationModal
                showModal={parsedFilesUnknowError.length > 0}
                titleFormattedMessageId={'app.create_program.upload_files_error_title'}
                bodyFormattedText={parsedFilesUnknowError}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={() => setParsedFilesUnknowError([])}
                onPrimaryButtonClicked={() => setParsedFilesUnknowError([])}
            />

            <ConfirmationModal
                showModal={showModalSubPrograns}
                titleFormattedMessageId={'app.create_program.table.column_get_last_users'}
                body={<Table columns={columns} scroll={{ y: 300 }}
                    dataSource={subProgram?.filter((x: ProgramModel) => x.id !== program.subProgramId).map((x: any, i: number) => {
                        x.key = i;
                        return x;
                    })}
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                            if (selectedRows.length > 0) {
                                setSelectSubProgram(selectedRows[0].id);
                            }
                        },
                    }}
                    loading={loadingSubProgram} />}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={() => {
                    setShowModalSubPrograns(false);
                    setSelectSubProgram(undefined);
                }}
                onPrimaryButtonClicked={() => {
                    setShowModalSubPrograns(false);
                    if (selectSubProgram != null) {
                        getUserSubProgram(selectSubProgram);
                        setSelectSubProgram(undefined);
                    }
                }}
            />
        </React.Fragment>
    );
});

const GetDefaultProgram = (organization: SearchOrganizationDetails, program?: SearchProgramsDetails): SendProgram => {
    return ({
        organizationId: organization?.id,
        programId: program?.id,
        programName: program?.name,
        subProgramName: '',
        year: new Date().getFullYear().toString(),
        dueDate: '',
        userIds: [],
        alertUsersByEmail: false,
        emailMessage: '',
    });
}
