import { ListOrganizationsMessages } from '../types';

export const listOrganizationsCA: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Creat el',
    'app.listorganizations.active': 'Actiu',
    'app.listorganizations.inactive': 'Inactiu',
    'app.listorganizations.new.program': 'Nou Programa o Experiència',
    'app.listorganizations.new.group': 'Crear i anomenar una nova administració del BEVI.',
    'app.listorganizations.view': 'Veure',
    'app.listorganizations.report': 'Informe',
    'app.listorganizations.details': 'Revisar i gestionar l’administració del BEVI.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Convidar nous usuaris a unir-se a aquest grup a través d’un codi QR.',
    'app.listorganizations.tooltip.sub_program_edit': 'Editar els paràmetres d’una administració del BEVI.',
    'app.listorganizations.table.beviname': 'Nom de l’administració del BEVI',
    'app.listorganizations.table.created_on': 'Creat el',
    'app.listorganizations.table.due_date': 'Data de venciment',
    'app.listorganizations.table.completed_percentage': '% Completat',
    'app.listorganizations.table.status': 'Estat',
    'app.listorganizations.table.actions': 'Accions',
    'app.listorganizations.select_organization': 'Seleccionar una organització.',
    'app.listorganizations.search': 'Cercar',
    'app.listorganizations.new_organization': 'Organització Nova',
    'app.listorganizations.input_program_placeholder': 'Escriu el nom del programa o experiència.',
    'app.listorganizations.input_subprogram_placeholder': 'Escriu el nom de l’administració del BEVI.',
    'app.listorganizations.organization_area': 'Gestió de Programes i Experiències',
    'app.listorganizations.download.data_report': 'Descarregar dades com arxiu d’EXCEL.',
    "app.listorganizations.sort_name_date": "Ordenar per nom o data",
    "app.listorganizations.sort_by": "Ordenar per",
    'app.sample.data.footer.message_line1': "<b><i>Nota.</i> El sistema d'informes BEVI permet el desenvolupament de vuit tipus d'informes diferents: 1) Informes individuals, 2) Informes de canvis individuals, 3) Informes de socis, 4) Informes de canvis de socis, 5) Informes de grups, 6) Informes de canvis de grup, 7) Dins Informes de grup, i 8) Informes entre grups. Per donar una idea de com funcionen aquests informes i del tipus d'informació a què es pot accedir, es proporcionen dos exemples de cada tipus d'informe: A i B.</b>",
    'app.sample.data.footer.message_line2': "<b>Tot i que els informes de mostra són coherents amb els informes reals del sistema BEVI, aquests exemples només es proporcionen amb finalitats il·lustratives. Com a tal, és possible que s'hagi modificat part de la informació d'aquests informes de mostra (p. ex., puntuacions de l'escala) o s'hagi eliminat (p. ex., respostes qualitatives) per tal d'assegurar que els resultats es desidentifiquen i/o es desvinculen a persones específiques.</b>",
    'app.sample.data.footer.message_line3': "<b>Si teniu cap pregunta sobre aquests informes de mostra o sobre qualsevol altre aspecte del BEVI, poseu-vos en contacte amb la persona que us ha autoritzat l'accés administratiu a BEVI o poseu-vos en contacte amb l'equip BEVI a <a href='https://thebevi.com/contact/'>https://thebevi.com/contact/.</a> </b>",
}
