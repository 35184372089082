import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    downloadQrCodeInvitation,
    ListOrganizationResponse, listOrganizations, listOrganizationWithPrograms,
    ListOrganizationWithProgramsResponse, OrganizationDetails, OrganizationWithProgramsModel,
    SearchOrganizationDetails, SearchOrganizationResponse, searchOrganizations,
    SearchProgramsDetails, SearchSubProgramsDetails,
} from '../services/organizationService';
import { ReportLanguage } from '../services/reportService';
import { GetDataDownloadResponse } from '../services/types';
import { getUser, GetUserBody, GetUserResponse } from '../services/usersService';
import { RenameProgramResponse, RenameProgramPayloadRequest, renameProgram } from '../services/programService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'getOrganizations',
    'searchOrganization',
    'selectProgram',
    'selectSubprogram',
    'loadOrganizationWithProgram',
    'setSelectedUserId',
    'setSelectedInstitutionId',
    'getUser',
    'donwloadQrCodeInvitation',
    'clearDownloadingQrCode',
    'renameProgram',
    'clearSuccessfulRenameProgram',
    'updateSearchOrganizationResults',
]);
export type ListOrganizationActions = { [K in (typeof actionsLiterals)[number]]: any };
export const listOrganizationActionsConstants: ListOrganizationActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setListOrganization',
    'setGetOrganizationError',
    'setSearchResults',
    'setSearchResultsError',
    'setListOrganizationWithProgram',
    'setListOrganizationWithProgramError',
    'setUser',
    'setUserError',
    'setSuccessfulDownload',
    'setDataDownloadError',
    'successfulRenameProgram',
    'setErroRenameProgram',
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loadingPage',
    'organizations',
    'loadingSearchOrganizations',
    'searchData',
    'searchOrganizationResults',
    'selectedProgram',
    'selectedSubprogram',
    'selectedUserId',
    'selectedInstitutionId',
    'organizationsWithPrograms',
    'user',
    'dataDownloadError',
    'loadingDataDownload',
    'hasRenamedProgram',
    'errorMessageRenameProgram',
    'loadingRenameProgram',
]);
export type ListOrganizationProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const listOrganizationPropsConstants: ListOrganizationProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ListOrganizationActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchListOrganizationRequest: () => void,
        fetchSearchOrganizationRequest: () => void,
        fetchOrganizationWithProgram: () => void,
        fetchUser: () => void,
        fetchQrCodeInvitation: () => void,
        fetchRenameProgramRequest: () => void,
        fetchUpdateSearchOrganizationResults: () => void,
    }
};

type ListOrganizationRaceResponse = {
    listOrganizationResponse?: ListOrganizationResponse;
}

type OrganizationWithProgramRaceResponse = {
    organizationWithProgram?: ListOrganizationWithProgramsResponse;
}

type SearchOrganizationRaceResponse = {
    searchOrganizationResponse?: SearchOrganizationResponse;
}

type GetUserRaceResponse = {
    getUserResponse?: GetUserResponse;
}

type RenameProgramRaceResponse = {
    renameProgramResponse?: RenameProgramResponse;
}

// Define payloads type.
type ErrorPayload = { errorKey: string; };
type ListOrganizationPayload = { organizations: OrganizationDetails[] };
type ListOrganizationWithProgramsPayload = { organizationsWithPrograms: OrganizationWithProgramsModel[] };
type GetUserPayload = { user: GetUserBody };

type SearchOrganizationPayloadRequest = { organizationId?: number, programQuery: string, subProgramQuery: string };
type SearchOrganizationRequest = { payload: SearchOrganizationPayloadRequest };
type GetUserRequest = { payload: UserIdPayloadRequest };

type InstitutionIdPayloadRequest = { institutionId: number };
type UserIdPayloadRequest = { userId: number };
type SelectProgramPayloadRequest = { program: SearchProgramsDetails };
type SelectSubprogramPayloadRequest = { subprogram: SearchSubProgramsDetails };
type SearchOrganizationPayload = { results: SearchOrganizationDetails };
type DownloadQrCodeInvitationPayload = { subProgramId: number, locale: ReportLanguage };
type DownloadQrCodeInvitationRequest = { payload: DownloadQrCodeInvitationPayload };

type RenameProgramPayload = { program: RenameProgramPayloadRequest }
type RenameProgramRequest = { payload: RenameProgramPayload };
type StatusRenameProgramPayload = { success: boolean; }

type GetDataDownloadRaceResponse = {
    getDataDownloadResponse?: GetDataDownloadResponse;
}

const OrganizationItemPropType = PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool,
    name: PropTypes.string,
});

const SearchSubProgramsDetailsPropType = PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool,
    description: PropTypes.string,
    createdOnUtc: PropTypes.string,
    dueDateOnUtc: PropTypes.string,
    completedPercentage: PropTypes.number,
});

const SearchProgramsDetailsPropType = PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    subPrograms: PropTypes.arrayOf(SearchSubProgramsDetailsPropType)
});

const SearchOrganizationDetailsPropType = PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool,
    name: PropTypes.string,
    programs: PropTypes.arrayOf(SearchProgramsDetailsPropType)
});

const emptyOrganizationList: OrganizationDetails[] = [];

const defaultSearchData: SearchOrganizationPayloadRequest = {
    organizationId: undefined,
    programQuery: '',
    subProgramQuery: '',
}

// persist forms reducer
export const listOrganizationKeaPath = 'listOrganization';

export const listOrganizationReducer = keaReducer(listOrganizationKeaPath);

export const listOrganizationLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [listOrganizationKeaPath],

    actions: () => ({
        [listOrganizationActionsConstants.getOrganizations]: true,
        [listOrganizationActionsConstants.loadOrganizationWithProgram]: true,
        [listOrganizationActionsConstants.clearDownloadingQrCode]: true,
        [listOrganizationActionsConstants.setSelectedInstitutionId]:
            (institutionId: number): InstitutionIdPayloadRequest => ({ institutionId }),
        [listOrganizationActionsConstants.setSelectedUserId]:
            (userId: number): UserIdPayloadRequest => ({ userId }),
        [listOrganizationActionsConstants.getUser]:
            (userId: number): UserIdPayloadRequest => ({ userId }),
        [listOrganizationActionsConstants.searchOrganization]:
            (organizationId: number, programQuery: string, subProgramQuery: string): SearchOrganizationPayloadRequest =>
                ({ organizationId, programQuery, subProgramQuery }),
        [listOrganizationActionsConstants.selectProgram]:
            (program: SearchProgramsDetails): SelectProgramPayloadRequest => ({ program }),
        [listOrganizationActionsConstants.selectSubprogram]:
            (subprogram: SearchSubProgramsDetails): SelectSubprogramPayloadRequest => ({ subprogram }),
        [listOrganizationActionsConstants.donwloadQrCodeInvitation]:
            (subProgramId: number, locale: ReportLanguage): DownloadQrCodeInvitationPayload => ({ subProgramId, locale }),
        [listOrganizationActionsConstants.renameProgram]: (program: RenameProgramPayloadRequest): RenameProgramPayload => ({ program }),
        [listOrganizationActionsConstants.updateSearchOrganizationResults]: (results: SearchOrganizationDetails): SearchOrganizationPayload => ({ results }),
        [listOrganizationActionsConstants.clearSuccessfulRenameProgram]: true,
        [privateActionsConstants.setUser]: (user: GetUserBody): GetUserPayload => ({ user }),
        [privateActionsConstants.setUserError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setListOrganizationWithProgram]:
            (organizationsWithPrograms: OrganizationWithProgramsModel[]): ListOrganizationWithProgramsPayload => ({ organizationsWithPrograms }),
        [privateActionsConstants.setGetOrganizationError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setListOrganization]:
            (organizations: OrganizationDetails[]): ListOrganizationPayload => ({ organizations }),
        [privateActionsConstants.setSearchResultsError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setListOrganizationWithProgramError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setSearchResults]:
            (results: SearchOrganizationDetails): SearchOrganizationPayload => ({ results }),
        [privateActionsConstants.setDataDownloadError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setSuccessfulDownload]: () => true,
        [privateActionsConstants.setErroRenameProgram]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.successfulRenameProgram]: (): StatusRenameProgramPayload => ({ success: true }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [listOrganizationPropsConstants.loadingPage]: [true, PropTypes.bool, {
            [actions.getOrganizations]: () => true,
            [actions.loadOrganizationWithProgram]: () => true,
            [actions.getUser]: () => true,
            [actions.setUser]: () => false,
            [actions.setUserError]: () => false,
            [actions.setListOrganizationWithProgram]: () => false,
            [actions.setListOrganization]: () => false,
            [actions.setGetOrganizationError]: () => false,
            [logout]: () => true,
        }],
        [listOrganizationPropsConstants.organizations]: [emptyOrganizationList, PropTypes.arrayOf(OrganizationItemPropType), {
            [actions.setListOrganization]: (_: OrganizationDetails[], payload: ListOrganizationPayload) => payload.organizations,
            [actions.setGetOrganizationError]: () => emptyOrganizationList,
            [logout]: () => emptyOrganizationList
        }],
        [listOrganizationPropsConstants.loadingSearchOrganizations]: [false, PropTypes.bool, {
            [actions.searchOrganization]: () => true,
            [actions.getOrganizations]: () => false,
            [actions.setSearchResults]: () => false,
            [actions.setListOrganization]: () => false,
            [actions.setGetOrganizationError]: () => false,
            [actions.setSearchResultsError]: () => false,
            [logout]: () => false,
        }],
        [listOrganizationPropsConstants.searchData]: [defaultSearchData, PropTypes.any, {
            [actions.searchOrganization]: (_: SearchOrganizationPayloadRequest, payload: SearchOrganizationPayloadRequest) => payload,
            [actions.setGetOrganizationError]: () => defaultSearchData,
            [actions.setSearchResultsError]: () => defaultSearchData,
            [actions.setListOrganization]: () => defaultSearchData,
            [logout]: () => defaultSearchData,
        }],
        [listOrganizationPropsConstants.searchOrganizationResults]: [null, PropTypes.objectOf(SearchOrganizationDetailsPropType), {
            [actions.setSearchResults]: (_: SearchOrganizationDetails, payload: SearchOrganizationPayload) => payload.results,
            [actions.setSearchResultsError]: () => null,
            [actions.setListOrganization]: () => null,
            [actions.setGetOrganizationError]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.selectedProgram]: [null, PropTypes.objectOf(SearchProgramsDetailsPropType), {
            [actions.selectProgram]:
                (_: SearchProgramsDetails, payload: SelectProgramPayloadRequest) => payload.program,
            [actions.setSearchResults]: () => null,
            [actions.setSearchResultsError]: () => null,
            [actions.setListOrganization]: () => null,
            [actions.setGetOrganizationError]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.selectedSubprogram]: [null, PropTypes.objectOf(SearchSubProgramsDetailsPropType), {
            [actions.selectSubprogram]:
                (_: SearchSubProgramsDetails, payload: SelectSubprogramPayloadRequest) => payload.subprogram,
            [actions.setSearchResults]: () => null,
            [actions.setSearchResultsError]: () => null,
            [actions.setListOrganization]: () => null,
            [actions.setGetOrganizationError]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.organizationsWithPrograms]: [null, PropTypes.any, {
            [actions.setListOrganizationWithProgram]:
                (_: OrganizationWithProgramsModel[], payload: ListOrganizationWithProgramsPayload) => payload.organizationsWithPrograms,
            [actions.setListOrganizationWithProgramError]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.user]: [null, PropTypes.any, {
            [actions.setUser]: (_: number, payload: GetUserPayload) => payload.user,
            [actions.setUserError]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.selectedUserId]: [null, PropTypes.number, {
            [actions.setSelectedUserId]: (_: number, payload: UserIdPayloadRequest) => payload.userId,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.selectedInstitutionId]: [null, PropTypes.number, {
            [actions.setSelectedInstitutionId]: (_: number, payload: InstitutionIdPayloadRequest) => payload.institutionId,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.dataDownloadError]: [null, PropTypes.string, {
            [actions.donwloadQrCodeInvitation]: () => null,
            [actions.setSuccessfulDownload]: () => null,
            [actions.setDataDownloadError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null
        }],
        [listOrganizationPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.donwloadQrCodeInvitation]: () => true,
            [actions.setSuccessfulDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [actions.clearDownloadingQrCode]: () => false,
            [logout]: () => true
        }],
        [listOrganizationPropsConstants.errorMessageRenameProgram]: [null, PropTypes.string, {
            [actions.setErroRenameProgram]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.clearSuccessfulRenameProgram]: () => null,
            [actions.renameProgram]: () => null,
            [actions.successfulRenameProgram]: () => null,
            [logout]: () => null,
        }],
        [listOrganizationPropsConstants.hasRenamedProgram]: [false, PropTypes.bool, {
            [actions.successfulRenameProgram]: () => true,
            [actions.clearSuccessfulRenameProgram]: () => false,
            [actions.setErroRenameProgram]: () => false,
            [logout]: () => false,
        }],
        [listOrganizationPropsConstants.loadingRenameProgram]: [false, PropTypes.bool, {
            [actions.renameProgram]: () => true,
            [actions.clearSuccessfulRenameProgram]: () => false,
            [actions.successfulRenameProgram]: () => false,
            [actions.setErroRenameProgram]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.getOrganizations]: workers.fetchListOrganizationRequest,
        [actions.searchOrganization]: workers.fetchSearchOrganizationRequest,
        [actions.loadOrganizationWithProgram]: workers.fetchOrganizationWithProgram,
        [actions.getUser]: workers.fetchUser,
        [actions.donwloadQrCodeInvitation]: workers.fetchQrCodeInvitation,
        [actions.renameProgram]: workers.fetchRenameProgramRequest,
        [actions.updateSearchOrganizationResults]: workers.fetchUpdateSearchOrganizationResults,
    }),

    workers: {
        * fetchListOrganizationRequest(): any {

            //@ts-ignore
            const { setGetOrganizationError, setListOrganization } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { listOrganizationResponse }: ListOrganizationRaceResponse = yield race({
                listOrganizationResponse: call(listOrganizations, token),
                timeout: delay(requestTimeout)
            });

            if (listOrganizationResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (listOrganizationResponse?.status === successfulCode) {
                if (listOrganizationResponse?.body) {
                    yield put(setListOrganization(listOrganizationResponse?.body));
                } else {
                    yield put(setGetOrganizationError(generalExceptionKey));
                }
            } else if (listOrganizationResponse?.keyErrorMessage) {
                yield put(setGetOrganizationError(listOrganizationResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchSearchOrganizationRequest(action: SearchOrganizationRequest): any {

            //@ts-ignore
            const { setSearchResults, setSearchResultsError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId, programQuery, subProgramQuery } = action.payload;

            if (organizationId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const { searchOrganizationResponse }: SearchOrganizationRaceResponse = yield race({
                searchOrganizationResponse: call(searchOrganizations, token, organizationId, programQuery, subProgramQuery),
                timeout: delay(requestTimeout)
            });

            if (searchOrganizationResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (searchOrganizationResponse?.status === successfulCode) {
                if (searchOrganizationResponse?.body) {
                    yield put(setSearchResults(searchOrganizationResponse?.body));
                } else {
                    yield put(setSearchResultsError(generalExceptionKey));
                }
            } else if (searchOrganizationResponse?.keyErrorMessage) {
                yield put(setSearchResultsError(searchOrganizationResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchOrganizationWithProgram(): any {

            //@ts-ignore
            const { setListOrganizationWithProgram, setListOrganizationWithProgramError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationWithProgram }: OrganizationWithProgramRaceResponse = yield race({
                organizationWithProgram: call(listOrganizationWithPrograms, token),
                timeout: delay(requestTimeout)
            });

            if (organizationWithProgram?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (organizationWithProgram?.status === successfulCode) {
                if (organizationWithProgram?.body) {
                    yield put(setListOrganizationWithProgram(organizationWithProgram?.body));
                } else {
                    yield put(setListOrganizationWithProgramError(generalExceptionKey));
                }
            } else if (organizationWithProgram?.keyErrorMessage) {
                yield put(setListOrganizationWithProgramError(organizationWithProgram.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchUser(action: GetUserRequest): any {

            //@ts-ignore
            const { setUser, setUserError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { userId } = action.payload;

            const { getUserResponse }: GetUserRaceResponse = yield race({
                getUserResponse: call(getUser, token, userId),
                timeout: delay(requestTimeout)
            });

            if (getUserResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (getUserResponse?.status === successfulCode) {
                if (getUserResponse?.body) {
                    yield put(setUser(getUserResponse?.body));
                } else {
                    yield put(setUserError(generalExceptionKey));
                }
            } else if (getUserResponse?.keyErrorMessage) {
                yield put(setUserError(getUserResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchQrCodeInvitation(action: DownloadQrCodeInvitationRequest): any {
            //@ts-ignore
            const { clearDownloadingQrCode, setSuccessfulDownload, setDataDownloadError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { subProgramId, locale } = action.payload;

            const { getDataDownloadResponse }: GetDataDownloadRaceResponse = yield race({
                getDataDownloadResponse: call(downloadQrCodeInvitation, token, subProgramId, locale),
                timeout: delay(requestTimeout)
            });

            if (getDataDownloadResponse?.status === unauthorizedCode) {
                yield put(clearDownloadingQrCode());
                yield put(notAuthorized());
            } else if (getDataDownloadResponse?.status === successfulCode) {
                yield put(setSuccessfulDownload());
            } else if (getDataDownloadResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(getDataDownloadResponse.keyErrorMessage));
            } else {
                yield put(clearDownloadingQrCode());
                yield put(serviceUnavailable());
            }
        },
        * fetchRenameProgramRequest(action: RenameProgramRequest): any {

            //@ts-ignore
            const { successfulRenameProgram, setErroRenameProgram } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { program } = action.payload;
            const { renameProgramResponse }: RenameProgramRaceResponse = yield race({
                renameProgramResponse: call(renameProgram, token, program),
                timeout: delay(requestTimeout)
            });

            if (renameProgramResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (renameProgramResponse?.status === successfulCode) {
                yield put(successfulRenameProgram(renameProgramResponse?.body));
            } else if (renameProgramResponse?.keyErrorMessage) {
                yield put(setErroRenameProgram(renameProgramResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchUpdateSearchOrganizationResults(searchOrganizationResultsData: any): any {
            //@ts-ignore
            const { setSearchResults } = this.actionCreators;

            if(searchOrganizationResultsData?.payload != null) {
                yield put(setSearchResults(searchOrganizationResultsData?.payload?.results));
            }
        },
    }
})
