import { ProgramsMessages } from '../types';

export const programsDE: ProgramsMessages = {
  'app.programs.add.title': 'Neues Programm',
  'app.programs.rename': 'Programm umbenennen',
  'app.programs.edit.title': 'Programm bearbeiten',
  'app.programs.add.successful_message': 'Programm wurde erfolgreich aktualisiert.',
  'app.programs.add.error_message': 'Etwas ist schief gelaufen. Bitte versuchen Sie es in ein paar Minuten erneut.',
  'app.programs.add.new_name': 'Neuer Name',
  'app.programs.add.button_save': 'Speichern',
  'app.programs.chat.successful_message': 'In a few minutes, all users will be able to use AI chat.',
  'app.programs.chat.add_chat_all': 'Add Chat for all Users',
  'app.programs.chat.question_add_chat_all': 'Are you sure you want to include chat for all users in this program?'
}
