import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { colors } from '../../../config/colors';
import { BackgroundStatisticItem } from './BackgroundStatisticItem';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { GroupReportBasicDescriptiveStatistics } from '../../../services/types';
import { GetDuration } from '../../../utils/reportHelper';

type Props = {
    basicDescriptiveStatistics: GroupReportBasicDescriptiveStatistics;
    selectedCountryCodes: number[];
}

function formatTime(time: any): string {
    let timeResult = '';

    if (time && time['days'] !== undefined) {
        if (parseInt(time['days']) > 0) {
            timeResult += time['days'];
            if (parseInt(time['days']) > 1) {
                timeResult += ' ' + GetFormattedMessage('app.time.days');
            } else {
                timeResult += ' ' + GetFormattedMessage('app.time.day');
            }
        }
    }

    if (time && time['hours'] !== undefined) {
        if (parseInt(time['hours']) > 0) {
            if (timeResult !== '') {
                timeResult += ` ${GetFormattedMessage('app.time.and')} `;
            }
            //@ts-ignore
            timeResult += time['hours'];
            if (parseInt(time['hours']) > 1) {
                timeResult += ' ' + GetFormattedMessage('app.time.hours');
            } else {
                timeResult += ' ' + GetFormattedMessage('app.time.hour');
            }
        }
    }

    if (time && time['minutes'] !== undefined) {
        if (parseInt(time['minutes']) > 0) {
            if (timeResult !== '') {
                timeResult += ` ${GetFormattedMessage('app.time.and')} `;
            }
            timeResult += time['minutes'];
            if (parseInt(time['minutes']) > 1) {
                timeResult += ' ' + GetFormattedMessage('app.time.minutes');
            } else {
                timeResult += ' ' + GetFormattedMessage('app.time.minute');
            }
        }
    }

    if (time && time['seconds'] !== undefined) {
        if (parseInt(time['seconds']) > 0) {
            if (timeResult !== '') {
                timeResult += ` ${GetFormattedMessage('app.time.and')} `;
            }
            //@ts-ignore
            timeResult += time['seconds'];
            if (parseInt(time['seconds']) > 1) {
                timeResult += ' ' + GetFormattedMessage('app.time.seconds');
            } else {
                timeResult += ' ' + GetFormattedMessage('app.time.second');
            }
        }
    }

    return timeResult;
}

export const BasicDescriptiveStatistics = ({ basicDescriptiveStatistics, selectedCountryCodes }: Props): JSX.Element => {
    const time = GetDuration(basicDescriptiveStatistics.completionTime[0], basicDescriptiveStatistics.completionTime[1]);
    return (
        <Row>
            <Col md={6}>
                <BackgroundStatisticItem
                    title={[
                        GetFormattedMessage('app.report.background_statistic_item.background_information')
                    ]}
                    background={basicDescriptiveStatistics.background}
                    selectedCountryCodes={selectedCountryCodes}
                />
            </Col>
            <Col md={6}>
                <Row>
                    <Col>
                        <h2 style={styles.title}>{GetFormattedMessage('app.report.background_statistic_item.domain_scores')}</h2>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.validity')}</h4>
                        <ul>
                            <li>{GetFormattedMessage('app.report.scales.consistency')}: {basicDescriptiveStatistics.validity_Consistency}</li>
                            <li>{GetFormattedMessage('app.report.scales.congruency')}: {basicDescriptiveStatistics.validity_Congruency}</li>
                            <li>{GetFormattedMessage('app.report.background_statistic_item.average_missing_items')}: {basicDescriptiveStatistics.validity_AverageMissingItems}</li>
                            <li>
                                {GetFormattedMessage('app.report.background_statistic_item.average_quantitative_completion_time')}: {formatTime(time)}
                            </li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.full_scale')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.fullScale}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.formative_variables')}</h4>
                        <ul>
                            <li>{GetFormattedMessage('app.report.scales.negative_life_events')}: {basicDescriptiveStatistics.formativeVariables_NegativeLifeEvents}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.fulfillment_of_core_needs')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.fulfillmentOfCoreNeeds}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.tolerance_of_disequilibrium')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.toleranceOfDisequilibrium}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.critical_thinking')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.criticalThinking}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.self_access')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.selfAccess}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.other_access')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.otherAccess}</li>
                        </ul>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ span: 11, offset: 1 }}>
                        <h4>{GetFormattedMessage('app.report.background_statistic_item.global_access')}</h4>
                        <ul>
                            <li>{basicDescriptiveStatistics.globalAccess}</li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const styles = {
    title: {
        textAlign: 'center',
        color: colors.darkSlateBlue,
        fontSize: '20px'
    } as React.CSSProperties,
};
