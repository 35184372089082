import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { DASHBOARD_PAGE, PROGRAMS_PAGE } from '../../router/pages';
import { IsParticipant } from '../../utils/userHelper';
import { FirstAccess } from './FirstAccess';

type Props = RouteComponentProps;

export const FirstAccessController = withRouter((props: Props): JSX.Element => {

  const { tokenInfo, token }: AppProps = useValues(appLogic);

  useEffect(() => {
    if (tokenInfo === undefined || tokenInfo.first_access !== 'True') {
      const page = IsParticipant(tokenInfo) ? DASHBOARD_PAGE : PROGRAMS_PAGE;
      props.history.push(page)
    }
  }, [tokenInfo, props.history])

  const { setPasswordFirstAccess }: AppActions = useActions(appLogic);
  const { loading, hasPasswordUpdateError }: AppProps = useValues(appLogic);

  const [error, setError] = useState<string>('');


  const handleChangePasswordSubmit = (password: string, confirmationPassword: string): void => {
    if (password === confirmationPassword) {
      setPasswordFirstAccess(token, password);
    } else {
      setError('app.login.passwords_dont_match')
    }
  }

  useEffect(() => {
    if (hasPasswordUpdateError) {
      setError('app.systemerro');
    }
  }, [hasPasswordUpdateError]);

  return (
    <FirstAccess
      changePassword={handleChangePasswordSubmit}
      loading={loading}
      errorKey={error}
    />
  );
});
