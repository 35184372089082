import React from 'react';
import { Image as Img } from 'react-bootstrap';
import logo_bevi from '../../assets/images/bevi_logo.png';
import { GraphBar } from '../../components/GraphBar';
import { colors } from '../../config/colors';
import { ScaleGroup } from '../../services/reportService';
import { GetReportItemColor } from '../../utils/reportHelper';
import { ReportProps } from './ReportTypes';


export const ComparativeReportByIndividualPage = (props: ReportProps): JSX.Element => (
    <div style={styles.divBackground} className='background'>
        <div style={styles.divCard} className='card'>
            <div style={styles.container}>
                <div style={styles.alignCenter}>
                    <Img src={logo_bevi} />
                </div>
                <hr />

                <SectionTitle message={props.report.title} />

                <SectionTitle message={props.scalesTitle.consistency} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`consistency-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.Consistency]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.Consistency)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={props.scalesTitle.congruency} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`congruency-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.Congruency]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.Congruency)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`1. ${props.scalesTitle.negative_life_events}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`negative_life_events-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.NegativeLifeEvents]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.NegativeLifeEvents)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`2. ${props.scalesTitle.needs_closure}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`needs_closure-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.NeedsClosure]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.NeedsClosure)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`3. ${props.scalesTitle.needs_fulfillment}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`needs_fulfillment-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.NeedsFulfillment]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.NeedsFulfillment)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`4. ${props.scalesTitle.identity_diffusion}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`identity_diffusion-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.IdentityDiffusion]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.IdentityDiffusion)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`5. ${props.scalesTitle.basic_openness}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`basic_openness-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.BasicOpenness]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.BasicOpenness)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`6. ${props.scalesTitle.self_certitude}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`self_certitude-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.SelfCertitude]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.SelfCertitude)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`7. ${props.scalesTitle.basic_determinism}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`basic_determinism-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.BasicDeterminism]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.BasicDeterminism)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`8. ${props.scalesTitle.socioemotional_convergence}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`socioemotional_convergence-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.SocioemotionalConvergence]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.SocioemotionalConvergence)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`9. ${props.scalesTitle.physical_resonance}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`physical_resonance-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.PhysicalResonance]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.PhysicalResonance)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`10. ${props.scalesTitle.emotional_attunement}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`emotional_attunement-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.EmotionalAttunement]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.EmotionalAttunement)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`11. ${props.scalesTitle.self_awareness}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`self_awareness-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.SelfAwereness]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.SelfAwereness)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`12. ${props.scalesTitle.meaning_quest}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`meaning_quest-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.MeaningQuest]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.MeaningQuest)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`13. ${props.scalesTitle.religious_traditionalism}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`religious_traditionalism-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.ReligiousTraditionalism]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.ReligiousTraditionalism)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`14. ${props.scalesTitle.gender_traditionalism}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`gender_traditionalism-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.GenderTraditionalism]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.GenderTraditionalism)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`15. ${props.scalesTitle.sociocultural_openness}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`sociocultural_openness-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.SocioculturalOpenness]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.SocioculturalOpenness)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`16. ${props.scalesTitle.ecological_resonance}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`ecological_resonance-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.EcologicalResonance]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.EcologicalResonance)}
                            />
                        )
                    })}
                </div>

                <SectionTitle message={`17. ${props.scalesTitle.global_resonance}`} />
                <div style={styles.texts}>
                    {props.report.individualData.map((item, index) => {
                        return (
                            <GraphBar key={`global_resonance-${item.individualAlias}-${index}`}
                                title={item.individualAlias}
                                value={item.aggregateProfile.scales[ScaleGroup.GlobalResonance]}
                                personalizedBarColor={GetReportItemColor(ScaleGroup.GlobalResonance)}
                            />
                        )
                    })}
                </div>

            </div>
        </div>
    </div>
);

type TitleProps = {
    message: string;
}

const SectionTitle = (props: TitleProps): JSX.Element => (
    <div style={styles.paddingTop10}>
        <hr />
        <div style={styles.texts}>
            <h4 style={{ ...styles.title, ...styles.textCenter }}>{props.message}</h4>
        </div>
    </div>
)

const styles = {
    container: {
        padding: 5
    } as React.CSSProperties,
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        marginBottom: '10%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center',
    } as React.CSSProperties,
    title: {
        color: colors.darkSlateBlue,
        fontSize: '32px'
    } as React.CSSProperties,
    texts: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
    } as React.CSSProperties,
    paddingTop10: {
        paddingTop: 10
    } as React.CSSProperties,
    textCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
}