import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GetFormattedMessage } from "../../../utils/htmlHelper";
import { validateEmail } from "../../../utils/validateHelper";
import { FormModel, Reports } from "../../../services/reportService";
import { Table } from "antd";
import { ColumnProps } from "antd/es/table";
import { differenceBy } from "lodash";

type Props = {
    forms?: FormModel[];
    loadingForms?: boolean;
    reportType?: Reports;
    selectForms?: FormModel[];
    errorValidForm?: string;
    groupIndex?: number;
    ignoreEmails?: string[],
    lastEmailGroup?: string,
    findForms: (email: string, index: number) => void;
    setSelectForms: (forms: FormModel[], index: number) => void;
    changeGroupName?: (value: string, index: number) => void;
}

export const Step2IndividualReport = ({ forms, loadingForms, reportType, selectForms, errorValidForm, groupIndex,
    ignoreEmails, lastEmailGroup, findForms, setSelectForms, changeGroupName }: Props) => {
    const [email, setEmail] = useState<string>();
    const [invalidEmail, setInvalidEmail] = useState<string>();
    const invalidEmailFeedbackText = GetFormattedMessage('app.invitation.invalid_email_feedback');
    const userNotFound = GetFormattedMessage('app.error.user_not_found');
    const emailInUse = GetFormattedMessage('app.create_report.email_in_use')

    useEffect(() => {
        if (errorValidForm != null) {
            setInvalidEmail(userNotFound);
        } else {
            setInvalidEmail(undefined);
        }
    }, [errorValidForm, userNotFound])

    useEffect(() => {
        setEmail(lastEmailGroup);
    }, [lastEmailGroup])

    const searchEmail = () => {
        if (!email || !validateEmail(email)) {
            setInvalidEmail(invalidEmailFeedbackText);
        } else if (ignoreEmails != null && email != null && ignoreEmails.includes(email)) {
            setInvalidEmail(emailInUse);
        } else {
            if (groupIndex != null) {
                findForms(email, groupIndex);
                if (changeGroupName) {
                    changeGroupName(email, groupIndex);
                }
            }
        }
    };

    const columns: ColumnProps<FormModel>[] = [
        {
            title: GetFormattedMessage('app.create_program.table.column_name'),
            dataIndex: 'name',
        },
        {
            title: GetFormattedMessage('app.create_program.table.column_email'),
            dataIndex: 'email',
        },
        {
            title: GetFormattedMessage('app.listforms.program'),
            dataIndex: 'program',
            render: (_: string, record: FormModel) => {
                return (
                    <div>
                        {record.program} - {record.subProgram}
                    </div>
                );
            }
        },
    ];

    const rowSelection = {
        onChange: (_: React.Key[], selectRows: FormModel[]) => {
            if (groupIndex != null) {
                setSelectForms(selectRows, groupIndex);
            }
        },
    };

    const typeRowSelection = useMemo(() => {
        if (reportType != null && [Reports.individual_report, Reports.partner_report].includes(reportType)) {
            return 'radio'
        }
        return 'checkbox';
    }, [reportType])

    return <Form style={styles.formDiv}>
        <Form.Group className='mb-3'>
            <Row>
                <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <b>Search Forms by Email</b>
                        </Form.Label>
                        <Form.Control value={email} onChange={e => {
                            setEmail(e.target.value);
                            setInvalidEmail(undefined);
                        }} isInvalid={!!invalidEmail} placeholder={GetFormattedMessage('app.search_placeholder')} />
                        <Form.Control.Feedback type='invalid'>
                            {invalidEmail}
                        </Form.Control.Feedback>
                        <br />
                        <Button onClick={searchEmail}>
                            {GetFormattedMessage('app.btn_search')}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Table
                rowSelection={{
                    type: typeRowSelection,
                    selectedRowKeys: selectForms?.map(x => x.id),
                    ...rowSelection,
                }}
                loading={loadingForms}
                columns={columns}
                dataSource={forms?.filter(x => x.email === lastEmailGroup)}
                pagination={false}
                rowKey={(record) => record.id}
            />
        </Form.Group>
    </Form>
}

const styles = {
    formDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
}
