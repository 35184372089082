import { Select } from "antd"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { FormDetails } from "../../../services/listFormsService"
import styled from "styled-components"
import { GetFormattedMessage } from "../../../utils/htmlHelper"
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Reports } from "../../../services/reportService"
import { isNil } from "lodash"

type Props = {
  open: boolean,
  onHide: () => void,
  formList: FormDetails[],
  compare: (ids: number[]) => void
}

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .anticon-close {
        position: absolute;
        right: 12px;
    }
`

const ModalComparative = ({ open, onHide, formList, compare }: Props) => {
  const [values, setValues] = useState<number[]>([]);

  useEffect(() => {
    setValues([]);
  }, [open]);

  console.log('FORM LIST', formList);

  const suffix = (
    <>
      <span>
        {values.length} / 10
      </span>
      <DownOutlined />
    </>
  );

  return <Modal show={open}>
    <Container>
      <CloseOutlined onClick={onHide} />
      <h3>{GetFormattedMessage('app.demographic.select_multiple_reports')}</h3>
      <Select suffixIcon={suffix} maxCount={10} placeholder={GetFormattedMessage('app.search_placeholder')}
        onChange={(v) => setValues(v)} mode="multiple" options={formList.filter(t => (isNil(t.type) || t.type === Reports.individual_report) && t.status === 5).map(x => ({
          label: x.programName + ' - ' + x.subProgramName,
          value: x.id
        }))} />
      <Button disabled={values.length < 2} onClick={() => values.length > 1 && compare(values)}>{GetFormattedMessage('app.demographic.compare')}</Button>
    </Container>
  </Modal>
}

export default ModalComparative;