import { LoginPageMessages } from '../types';

export const loginPageJA: LoginPageMessages = {
    'app.login.text_welcome': 'Welcome to the Beliefs, Events, and Values Inventory (BEVI)',
    'app.login.text_information': `このサイトにアクセスするには、BEVIのライセンスを受けた大学、組織、または管理者から招待されている必要があります。`,
    'app.login.text_login_requirement': `ユーザー名とパスワードを入力してください。`,
    'app.login.lbl_username': 'ユーザー名:',
    'app.login.lbl_password': 'パスワード:',
    'app.login.btn_submit': 'ログイン',
    'app.login.lbl_new_password': '新規パスワード',
    'app.login.lbl_new_password_confirmation': 'パスワードの再入力',
    'app.login.btn_submit_new_password': 'パスワードのリセット',
    'app.login.placeholder.login': 'メールアドレス',
    'app.login.placeholder.password': 'あなたのパスワード',
    'app.login.invalid_credentials': 'メールアドレス/パスワードが間違っています。再度試してみてください。または以下の「パスワード設定/再設定」をクリックして、アカウントをリセットしてください。',
    'app.login.forgot_password.invalid_credentials': 'メールアドレスが間違っています。再度試してみてください。',
    'app.login.forgot_password': 'パスワード設定/再設定',
    'app.login.forgot_password_confirmation_title': 'パスワードのリセット',
    'app.login.forgot_password_confirmation_body': '登録されたメールアドレスにメールが送信されました。メールに記載されている指示に従って、パスワードを再設定してください。',
    'app.login.btn_submit_forgot_password': 'パスワードの再設定（メールアドレスを入力しクリックしてください）',
    'app.login.passwords_dont_match': 'パスワードが一致しません。',
    'app.login.change_password.expired_confirmation_title': 'このページの情報はありません。',
    'app.login.change_password.expired_confirmation_body': 'ページへのアクセス許可が失効しました。再度アクセスをリクエストするか、BEVIに再度ログインしてください。',
    'app.login.change_password.success_confirmation_title': '再設定の完了',
    'app.login.change_password.success_confirmation_body': '新しいパスワードが設定されました。次回より、新しいパスワードでログインしてください。',
    'app.login.first_access.title': 'パスワードを変更してからご利用ください。',
}
