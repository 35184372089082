import { ConsentPageMessages } from '../types';

export const consentPageZHTW: ConsentPageMessages = {
    'app.consent.title': '同意书',
    'app.consent.greeting': '各位参加者:',
    'app.consent.introduction': ' 非常感谢您有意参加 Beliefs, Events, and Values Inventory（BEVI）的考评',
    'app.consent.general.observation': '  此知情同意书参考了多所大学伦理审查委员会（Institutional Review Board: IRB）的审查流程而制定。在参加BEVI考评前，除了此同意书以外，您还有可能会被某大学或机构要求提供其他系统或格式的同意书。对后者的流程若存疑问，请咨询所在大学或机构的BEVI管理者。',
    'app.consent.explanation_1': ' BEVI（Beliefs, Events, and Values Inventory）正如其名所示，是以考评人们是如何看待自己、他人、世界为目的而设制的。在一系列相关的背景信息问题之后，会对范围广泛的问题或话题进行提问。您的反应，有可能会因问题不同而不同。有些问题貌似与学习、成长和发展相关，但有些问题可能毫无相关。有些问题可能看起来很直截了当，但有些问题可能会显得模棱两可。该测试考评的是复杂而又互相联系的多个因素，这些所有的问题及背景信息不仅有益于我们对学习、成长以及发展过程的认识与理解，而且还会帮助我们去理解不同的人是如何看待自己、他人、世界。（还有，它以最少的设问实现了上述目标。）',
    'app.consent.explanation_2': ' 尽管不同的人可能会有不同的回答，但请注意 BEVI 的问题既没有所谓的“正确”或“错误”的回答。因为我们每个人的信仰、价值观及人生经历是不尽相同的，所以即使某个人对特定问题的回答是“非常同意”，但不同的人对相同问题的回答却有可能会是“非常不同意”。这是理所当然且可预知的。之所以会有“非常同意”、“同意”、“不同意”、“非常不同意”这样的选项，是因为BEVI考虑到每个人会有不同的信仰、价值观及人生经历，不能用“正确”或“错误”一概而论。',
    'app.consent.participant.rights.1': ' 参与本研究是遵循自愿原则的，您随时可以不参加。您的个人隐私将会受到法律的最大保护。而且，您的所有回答，会以<b>绝对机密的方式处理。对您的每项回答，除以<b>书面的方式得到您本人明确的同意以外，无论任何方式都不会牵扯到您个人。但是，在一些情况下，您可以通过您的回答直接去回顾反思您的经历。一旦这些情况发生，您的回答将以绝对机密的方式处理。您的个人隐私将会受到法律最大限度地保护，而且会将您的个人信息作为特许保密的信息慎重管理，不对外公布。只有整个课程项目的负责人才有权访问原始数据。为了研究、教育、专业培训、大学或机构的运营、信息的提供或解说，团体结果的各比例或各项目、组特征表，整个团体，大学或机构的结果将有可能会被公布或出版。但是，这种情况，只要直接牵扯到您个人的档案或资料、比例、项目或回答, 除非得到您本人明确的书面同意，否则将不会被公布或出版（例如，您填写的所有ID信息也将会被删除）。',
    'app.consent.participant.rights.2': ' 还取决于您在何时，何种状况下完成BEVI考评，您还将有机会看到以您的个人回答为您专门做成的个人数据分析结果。这种情况，您必须另外同意一些事项后才能正式阅览该个人数据分析结果。而且，您还有可能会被邀请参加团体结果的讨论。这个团体结果可能是你已参加过的，与您的个人结果相比更大的，团体的BEVI考评结果。作为使用BEVI考评项目的一环，可能会邀请您参加其他的定量研究（例如：多项选择题）或定性研究（例如：主观题），或以书面或口头问答的方式追加信息。这一情况，还会包含与BEVI管理者或研究者的事后访谈。您的参与都是遵循自愿原则的，您随时可以不参加。如上所述，你在团体结果或所有其他研究中的个人回答都不会牵扯到您个人。最后，在参加BEVI考评或所有其他定量研究、定性研究之后，您还将有机会参加我们的结果反馈交流会。',
    'app.consent.understand.agree': '  在理解并同意上述信息和方针后，若您愿意参加BEVI考评及（类似上述的）BEVI研究活动的话，请单击“同意”。',
    'app.consent.have.questions': '    若有疑问，请随时咨询<a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>',
    'app.consent.thanks': '谢谢您的参与！',
    'app.consent.giving.consent': '同意书',
    'app.consent.read.and.agree': '我阅读了BEVI的概要和此同意书，理解了其对参加者的需求。并且阅读了以上关于此研究及作为参加者的我的权利的说明。我点击以下“同意”按钮，表示愿意参加此研究。',
    'app.consent.agree': '同意',
    'app.consent.form_title': 'Consent Form',
    'app.consent.disagree': 'Disagree',
    'app.custom_agreement.modal_title': 'Report review agreement',
    'app.custom_agreement.modal_explanation_text': 'Please edit the custom agreement text before submitting it.',
    'app.custom_agreement.default_custom_agreement': 'Dear BEVI Participant:<br/><br/>You already provided consent to take the BEVI, but before we can work with you on your BEVI report, we are asking you to agree 1) that you want to participate in one or more sessions where your individual report will be discussed with you and 2) that you are authorizing <strong>[here we need to be able to fill in one or more names of individual people]</strong> to review your individual report (e.g., your narrative, scores) and discuss the  meaning or implications of your BEVI report with you.<br/>Of course, as indicated in the consent form for the BEVI, your individual results will remain completely confidential.  If you have any questions, or need further information, please contact the BEVI administrator who is working with you.<br/><br/>If you understand and agree to the two above provisions, please click the "agree" button below. Thank you for your interest in the BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>您已同意接受 BEVI，但在我們與您合作處理一份或多份 BEVI 報告（例如，出於輔導、諮詢、輔導等目的）之前，我們會詢問您是否同意以下幾點:</h5>
    <br />

    <p>
    1. 我了解有多種類型的 BEVI 報告，獲得 BEVI 認證的人員可能會與我討論我的結果：1) 個人報告、2) 個人變更報告、3) 合作夥伴報告和 4) 合作夥伴變更報告。 我還了解，我必須允許其他人與我一起審查和討論我的一份或多份報告。    
    </p>
    <p>
    2. 點擊下面的「同意」按鈕，即表示我授權以下 BEVI 認證個人<b>[#USERS#]</b>與我一起審查和討論我的一份或多份報告。
    </p>
    <p>
    3.	我也理解並同意：
        <p style="padding-left: 20px;">
        a) 我授權以下合作夥伴<b>[#PARTNERS#]</b>與我以及 BEVI 認證的個人一起審查和討論我的報告/結果以及他們的報告/結果我已在上面授權；
        </p>
        <p style="padding-left: 20px;">
        b) 我不會與我的合作夥伴以外的任何人分享或討論任何合作夥伴報告或合作夥伴變更報告的全部或部分內容，或以任何形式（例如，透過電子郵件、張貼、散佈、展示、複製等）（ s) 以及上面列出的 BEVI 認證個人； 此條件的任何例外情況均須得到本人和此處列出的所有個人（合夥人或多個合夥人以及 BEVI 認證個人或多個個人）的書面同意；
        </p>
        <p style="padding-left: 20px;">
        c) 我在上面授權的合作夥伴可以隨時透過更改其 BEVI 儀表板中的權限設定檔刪除其合作夥伴報告或合作夥伴變更報告，這將從其 BEVI 中刪除該報告儀表板和我的。
        </p>
    </p>
    <p>
    4. 除上述條件外，BEVI同意書中列出的所有其他條款將繼續有效（例如，本人參與的自願性質等）。 如果您有任何疑問或需要更多信息，請聯繫 BEVI 認證人員或與您合作的人員。
    </p>
    <p>
    5. 如果您同意以上所有內容，請點選下面的「我同意」。 如果您不同意上述所有觀點，請點擊下面的「我不同意」。
    </p>
    <center>感謝您對 BEVI 的關注！</center>
    `,
}



