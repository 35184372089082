import { ExternalPageMessages } from '../types';

export const externalEN: ExternalPageMessages = {
    'app.external.title.thankyou': 'Thank you for completing the BEVI.',
    'app.external.thankyou_static_1_1': 'The Beliefs, Events, and Values Inventory or BEVI and this BEVI report are copyrighted. ' +
        'The BEVI and BEVI reports, items, item content, or scales may not be modified, copied, disseminated, or published, in whole ' +
        'or part, without the written and express permission of Craig N. Shealy, Ph.D.',
    'app.external.thankyou_static_1_2': '',
    'app.external.thankyou_static_2': 'As with the BEVI as a whole, the structure and design of these reports are subject ' +
        'to ongoing processes of review and development. If you have suggestions or comments about any aspect of the BEVI or this ' +
        'report, please feel free to contact us directly at info@ibavi.org',
    'app.external.thankyou_static_3': 'To learn more about beliefs and values, please visit the International Beliefs ' +
        'and Values Institute (IBAVI) at www.ibavi.org and www.thebevi.com',
}
