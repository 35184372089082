import { PageMessages } from '../locales/types';
import { CustomAgreementStatus } from '../services/programService';

export function getStatusValue(status: number): keyof (PageMessages) {
    if (status === 1) return 'app.listforms.available';
    if (status === 2) return 'app.listforms.onDemographics';
    if (status === 3) return 'app.listforms.onScales';
    if (status === 4) return 'app.listforms.onSubjective';
    if (status === 5) return 'app.listforms.finished';
    if (status === 6) return 'app.listforms.cancelled';
    if (status === 7) return 'app.listforms.expired';
    if (status === 8) return 'app.listforms.archived';
    if (status === 9) return 'app.listforms.invalid';
    return 'app.listforms.all';
}

export function getInvalidStatusReason(status?: number): keyof (PageMessages) | undefined {
    if (status === undefined || status === null) return undefined;
    if (status === 2) return 'app.form.invalid_status.by_time';
    if (status === 3) return 'app.form.invalid_status.by_number_of_questions';
    if (status === 4) return 'app.form.invalid_status.by_consecutive_answers';
    if (status === 5) return 'app.form.invalid_status.by_common_answers';
    if (status === 6) return 'app.form.invalid_status.by_congruency';
    if (status === 7) return 'app.form.invalid_status.by_consistency';
    if (status === 8) return 'app.form.invalid_status.by_wrong_scale_value';
    return undefined;
}

export function getCustomAgreementStatusKey(status?: CustomAgreementStatus): keyof (PageMessages) | undefined {
    switch (status) {
        case CustomAgreementStatus.NotRequested: return 'app.form.custom_agreement.not_requested';
        case CustomAgreementStatus.Pending: return 'app.form.custom_agreement.pending';
        case CustomAgreementStatus.Accepted: return 'app.form.custom_agreement.accepted';
        case CustomAgreementStatus.Rejected: return 'app.form.custom_agreement.rejected';
        default: return undefined;
    }
}

export function isFormAvailable(status?: number): boolean {
    return status === 1 || status === 2 || status === 3 || status === 4;
}

export function shouldShowReport(status?: number, showReport?: boolean): boolean {
    return status === 5 && showReport === true;
}

type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type ColSize = true | 'auto' | NumberAttr;
type ColOrderNumber = number | '1' | '2' | '3' | '4' | '5';
type ColOrder = ColOrderNumber | 'first' | 'last';
export type ColSpec = ColSize | {
    span?: ColSize;
    offset?: NumberAttr;
    order?: ColOrder;
};
