import { InvitationPageMessages } from '../types';

export const invitationDE: InvitationPageMessages = {
    'app.invitation.invite_label_template': 'Sie wurden von {0}zur Teilnahme am BEVI eingeladen. Sie werden für Programm {1} und Gruppe {2} angemeldet.',
    'app.invitation.email': 'E-Mail',
    'app.invitation.password': 'Passwort',
    'app.invitation.confirm_password': 'Passwort wiederholen',
    'app.invitation.name': 'Name',
    'app.invitation.language': 'Sprache',
    'app.invitation.blank_feedback': 'Dies ist ein Pflichtfeld!',
    'app.invitation.invalid_email_feedback': 'Diese E-Mail-Adresse ist ungültig!',
    'app.invitation.password_length_feedback_template': 'Dieses Feld muss mindestens {0} Zeichen enthalten!',
    'app.invitation.confirm_password_not_equal_feedback': 'Dieses Feld muss mit dem Passwort übereinstimmen!',
    'app.invitation.success_notification_header': 'Willkommen beim BEVI!',
    'app.invitation.success_notification_body': 'Ihre registrierte E-Mail und Ihr Passwort werden bei den kommenden Log-ins in das System verwendet.',
    'app.invitation.error_notification_header': 'Es ist ein Fehler aufgetreten.',
    'app.invitation.error_notification_body': 'Bitte überprüfen Sie die gesendeten Daten und versuchen Sie es erneut.',
}
