import { InvitationPageMessages } from '../types';

export const invitationJA: InvitationPageMessages = {
    'app.invitation.invite_label_template': 'あなたは {0} によって BEVI へ参加するよう招待されました。あなたはプログラム「{1}」のサブプログラム「{2}」に登録されます。',
    'app.invitation.email': 'メールアドレス',
    'app.invitation.password': 'パスワード',
    'app.invitation.confirm_password': 'パスワードを再入力して下さい',
    'app.invitation.name': '姓名',
    'app.invitation.language': '言語コード',
    'app.invitation.blank_feedback': '入力必須項目です。',
    'app.invitation.invalid_email_feedback': 'このメールアドレスは無効です。',
    'app.invitation.password_length_feedback_template': '少なくとも{0}文字以上必要です。',
    'app.invitation.confirm_password_not_equal_feedback': 'パスワードが一致しません。',
    'app.invitation.success_notification_header': 'BEVIへようこそ！',
    'app.invitation.success_notification_body': '登録されたメールアドレスとパスワードは、次回以降のBEVIへのログインにも用いられます。記録をとっておいてください。パスワードは再設定可能です。',
    'app.invitation.error_notification_header': 'エラーが発生しました。',
    'app.invitation.error_notification_body': '送信されたデータを確認して再度お試しください。',
}
