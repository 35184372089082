import { ListOrganizationsMessages } from '../types';

export const listOrganizationsES: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Creado el',
    'app.listorganizations.active': 'Activo',
    'app.listorganizations.inactive': 'Inactivo',
    'app.listorganizations.new.program': 'Nuevo programa o experiencia',
    'app.listorganizations.new.group': 'Crear y nombrar una nueva administración del BEVI.',
    'app.listorganizations.view': 'Ver',
    'app.listorganizations.report': 'Informe',
    'app.listorganizations.details': 'Revisar y gestionar una administración del BEVI.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Invita a nuevos usuarios a unirse a este grupo mediante código QR.',
    'app.listorganizations.tooltip.sub_program_edit': 'Editar parámetros de una administración del BEVI.',
    'app.listorganizations.table.beviname': 'Nombre de una administración del BEVI',
    'app.listorganizations.table.created_on': 'Creado el',
    'app.listorganizations.table.due_date': 'Fecha límite de entrega',
    'app.listorganizations.table.completed_percentage': '% completado',
    'app.listorganizations.table.status': 'Estado',
    'app.listorganizations.table.actions': 'Acciones',
    'app.listorganizations.select_organization': 'Seleccionar organización.',
    'app.listorganizations.search': 'Buscar',
    'app.listorganizations.new_organization': 'Nueva Organización',
    'app.listorganizations.input_program_placeholder': 'Escribe el nombre del programa o experiencia.',
    'app.listorganizations.input_subprogram_placeholder': 'Escribe el nombre de una administración del BEVI.',
    'app.listorganizations.organization_area': 'Gestión de Programas y Experiencias',
    'app.listorganizations.download.data_report': 'Descargar datos como archivo Excel.',
    "app.listorganizations.sort_name_date": "Ordenar por nombre o fecha",
    "app.listorganizations.sort_by": "Ordenar por",
    'app.sample.data.footer.message_line1': '<b><i>Nota.</i> El sistema de informes BEVI permite el desarrollo de ocho tipos de informes diferentes: 1) Informes individuales, 2) Informes de cambios individuales, 3) Informes de socios, 4) Informes de cambios de socios, 5) Informes de grupo, 6) Informes de cambios de grupo, 7) Dentro Informes grupales, y 8) Informes entre grupos. Para dar una idea de cómo funcionan estos informes y el tipo de información a la que se puede acceder, se proporcionan dos ejemplos de cada tipo de informe (A y B).</b>',
    'app.sample.data.footer.message_line2': '<b>Aunque los informes de muestra son consistentes con los informes reales del sistema BEVI, estos ejemplos se proporcionan únicamente con fines ilustrativos. Como tal, parte de la información en estos informes de muestra puede haber sido modificada (por ejemplo, puntuaciones de escala) o eliminada (por ejemplo, respuestas cualitativas) para garantizar que los resultados no estén identificados y/o desvinculados de personas específicas.</b>',
    'app.sample.data.footer.message_line3': '<b>Si tiene alguna pregunta sobre estos informes de muestra o cualquier otro aspecto de BEVI, comuníquese con la persona que autorizó el acceso administrativo de BEVI para usted o comuníquese con el equipo de BEVI al <a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
