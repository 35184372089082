import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageMessages } from '../locales/types';
import { GetFormattedMessage } from '../utils/htmlHelper';
import { DropdownAntdStyled } from './styled/dropdownAntd';

type DropdownProps = {
    optionItens: { label: string, value: any }[];
    defaultOptionId?: keyof (PageMessages) | "";
    allowClear?: boolean;
    onChange?: (value: any) => void;
    showSearch?: boolean;
    ignoreValue?: boolean;
};

export const FormDropdownAntd = ({ optionItens, defaultOptionId, allowClear, showSearch, ignoreValue, onChange }: DropdownProps): JSX.Element => {
    const [selectValue, setSelectValue] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const clickOutside = () => setOpen(false);
        document.getElementById('root')!.addEventListener('click', function (e) {
            clickOutside();
        }, true);
        return () => {
            document.getElementById('root')!.removeEventListener('click', clickOutside, true);
        };
    }, []);

    return (
        <DropdownAntdStyled>
            <Select
                showSearch={showSearch}
                filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                className='form-dropdown-antd'
                options={optionItens}
                value={selectValue}
                inputValue={selectValue}
                searchValue={searchValue}
                placeholder={defaultOptionId && GetFormattedMessage(defaultOptionId)}
                open={open}
                allowClear={allowClear}
                onClick={() => setOpen(true)}
                onSearch={(value) => setSearchValue(value)}
                onChange={(value: any) => {
                    if (onChange) {
                        onChange(value)
                    }
                    if (!ignoreValue) {
                        setSelectValue(value);
                    }
                }} />
        </DropdownAntdStyled>
    );
}
