import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loading } from '../../components/Loading';
import { appLogic, AppProps } from '../../redux/appLogic';
import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import { LIST_USERS_PAGE } from '../../router/pages';
import { UserProgramsPage } from './UserPrograms';

type Props = RouteComponentProps;

export const UserProgramsContainer = withRouter((props: Props): JSX.Element => {
    const { clearUserProgramDetails }: UsersActions = useActions(usersLogic);
    const { userProgramDetails, loadingUserProgramDetails }: UsersProps = useValues(usersLogic);
    const { tokenInfo }: AppProps = useValues(appLogic);

    useEffect(() => {
        if (userProgramDetails === null && !loadingUserProgramDetails) {
            props.history.replace(LIST_USERS_PAGE);
        }
        // eslint-disable-next-line
    }, [userProgramDetails, loadingUserProgramDetails]);

    useEffect(() => {
        return () => {
            clearUserProgramDetails();
        };
        // eslint-disable-next-line
    }, []);

    const handleBackButtonClick = () => {
        props.history.goBack();
    };

    if (loadingUserProgramDetails || !userProgramDetails) return <Loading />


    return (
        <UserProgramsPage
            userInfo={tokenInfo}
            userPrograms={userProgramDetails}
            onBackButtonClicked={handleBackButtonClick}
        />
    )
});
