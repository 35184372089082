import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'You and Your Worldview',
    'app.individualreport.a_personal_report_from': 'A personal report from the',
    'app.individualreport.bevi_description': 'Beliefs, Events, and Values Inventory (BEVI)',
    'app.individualreport.user': '被考评者',
    'app.individualreport.date_of_test': '完成BEVI考评的日期',
    'app.individualreport.static_text_1': ' 无论您身在何处，拿起智能手机或报纸，打开电视或收音机都会立即发现一个事实。那就是，信仰和价值观在人的一生中是不可或缺的。人们从政治、宗教、教育、艺术到婚姻、家人、性别、性欲都各持己见。但是，某一信仰和价值观是“正确的”还是“错误的”(正如之后所述，BEVI不会对其进行评判），这并不重要。重要的是要去理解“思考、感情、行为、人生经历、背景如何在复杂地相互作用下造就了不同的人”。为什么理解这些会变得如此重要？那是因为信仰和价值观会影响到世上的每个人、团体、组织、政府以及社会的行动、指针与实践。Beliefs, Events, and Values Inventory（BEVI）为我们提供了一种方法去测量个人及小组的这些复杂因素。同时，它还有助于我们更好地了解“为什么我们会形成某种特定的信仰和价值观？”“为什么他人会以相同或不同的方式看世界？”',
    'app.individualreport.static_text_2': ' 此结果旨在帮助您更好地理解自己的信仰和价值观。同时，还会提出一些理由来说明您为什么会形成那样的信仰与价值观。诚然，关于这些“什么？”“为什么？”的问题，要用绝对的方式去回答是困难的。并且，有必要强调世界是在不断变化的。今天认为是“正确的”事情，在不久前可能被认为是“错误的”。同样，现在认为是“不好”的事情，可能几年后就会被认为是“好的”。因此，此结果并不是对这些相关事项的最终考评，而是反映了您现在，人生此刻的问题。每个人都是在变化、独一无二的、复杂的。无论哪种测量，都不可能测量出以前的您、现在的您或将来的您的全部，而且BEVI的目的也不在于此。这一考评的设制目的在于帮助您就人生的本质，更好地反省自己、认识他人与世界。',
    'app.individualreport.static_text_3': ' 最后再次强调：此结果关注的是您对人生、工作、人际关系的信念和价值观的基本特征及其影响，旨在加深您对自己、他人、以及更大的世界的理解。在此再次强调：BEVI不赞同通过比较某种信仰和价值观与另一种信仰和价值观，而得出“正确的”或“错误的”、“更好的”或“更坏的”。',
    'app.individualreport.static_text_4': ' 因此，请基于以上背景来阅读您的结果。在解释信仰和价值观的同时，您的个人结果将以粗体字显示。',
    'app.individualreport.static_text_5': ' 我们在成长中或人生中的经历经常会影响到我们对自己、他人以及世界的理解。例如，那些人生中有许多积极事情发生的，感到自己的核心需求（爱、关怀等）已得到充分满足的人，基本上都会信任他人，也会相信人生应该会有好事情发生吧。有时（并不是总是），那些人生中有很多消极事情发生的人（与父母或家里的冲突等），感到自己的核心需求没有得到充分满足的人，尤其是在幼年或长时间经历，或非常痛苦或留下创伤的情况下，就会感到世界不是个适合居住的地方，因此有可能会影响到其对世界的看法及与他人的相处方式（学校、职场、人际关系等）。并且，实际上，要是人生经历了非常多的负面事情，或是核心需求得不到满足时，与针对这些问题接受过培训或具有专门知识的人交谈可能会有所帮助。虽说如此，但还是会有差别，人生对每个人而言都会有一定的难度，认识到这一点至关重要。即使经历了很多困难，大多数人都能克服那样的逆境，有时会得到他人（伴侣、朋友、老师、领导、教父、辅导员、心理咨询等）各种各样的帮助，继续其在学校、职场及人生中有意义的人际关系。无论如何，当您理解不了别人的信念和价值观时，请回想起“各种各样的人生经历和背景，对我们理解自己、他人、以及更大的世界都会产生影响”。这一认识，让我们在各种各样的情况下，即使对自己或他人的想法、感受、行为产生不了共鸣，也会加深对其的理解。',
    'app.individualreport.static_text_6': ' 如果您是倾向于“怀疑”型的，那您周围的人也是（即使是那些“非常有自信”型的人）对新的或不习惯的事物不像看起来那么有自信，即使与您相比较实际上也并不知道什么是“正确”的行为，了解这些可能会对您有所帮助。如果您有充分的理由相信自己知道正发生着什么，合理的自信是非常了不起的。但是，处于未经历过的状况或环境下，一般认为采取更怀疑且更开放的态度为妙。另一方面，特别留意最感兴趣的问题、疑问、实习及过程，可能会有效。关于您自身的这些信息，对理解“您是谁？”“将成为谁？”或“想成为谁？”都会有帮助吧。',
    'app.individualreport.static_text_7': ' 同时，我们会遇见新的人或新的状况，也会随着时间的流逝在变化过程中认识到自己知识的有限，没有“正确答案”，或在下决心之前发现就这一主题需要更进一步的学习。为了能接受诸如此类的现实，努力去“了解不知道的事情”也同样重要。总之，我们人类之所以能够理解做这件事的程度，是因为它与以更深的理解、智慧和慈悲去思考、感受和行动的能力有关',
    'app.individualreport.static_text_8': ' 参照上述的观察和分析，尝试思考我们日常生活中的“欲望”和“情绪”所起的作用至关重要。我们常常会寻找“深切感受”的经历（例如，人际关系或音乐、戏剧、读书、旅行、大自然的体验等），或渴望能有“深切感受”的经历。正因为我们的情绪大部分表露了“真实的自我”，所以感受自我或将情绪表达给他人并非易事。但在构筑和谐的人际关系、满足欲望、追求事业和人生目标的基础上让他人了解“真正的自己”至关重要。因此，表露我们所有的情绪与欲望是不明智的，同时，若不能感受或表露情绪或欲望，可能会给我们自己或他人带来巨大的困难。',
    'app.individualreport.static_text_9_1': '也就是说无法感受情绪，认知欲望或不想那样做的主要原因有以下两个，它们会成为自己或他人的问题。',
    'app.individualreport.static_text_9_2': '    1）将无法获得有关我们内心真正“正在发生的”重要信息，而导致我们的决定及全体的幸福受损。',
    'app.individualreport.static_text_9_3': '    2）他人可能会觉得与我们很难相处，也可能很难了解真实的我们。',
    'app.individualreport.static_text_9_4': '庆幸的是，无论我们承认与否，我们都出生在一个充满情绪与欲望的世界，甚至在我们会说话之前，这已成为我们与他人交流的最基本方式。一生中，我们会感受到自己和他人的各种欲望与情绪，在此过程中，我们会变得更人性。',
    'app.individualreport.static_text_10_1': '最后，我们的背景与经历不仅会影响到我们如何看待自己与他人，也会影响到我们如何看待“更大的世界”或思考“世界（应）如何”。这里，我想关注与我们人类息息相关的两个全球性问题。',
    'app.individualreport.static_text_10_2': '   1）对待环境与自然界我们的姿态',
    'app.individualreport.static_text_10_3': '   2）想走向更大世界的程度或倾向',
    'app.individualreport.static_text_11': '  最后再次强调：以上的结果与陈述是关于现在的您。我们每个人都是变化的，您现在的信仰与价值观也会随着时间的推移而变化。最后，为了获得更好的机会去理解、捍卫和接受我们在生活中所做出的决定，培养去深思“我们究竟信什么？”或“什么对我们才重要”的勇气与能力是值得赞赏的。在此衷心希望：此结果所提供的信息与观点将有助或有利于您持续思考“自己是谁？”“将成为谁？”“想成为谁？”。',
    'app.individualreport.heading_text_1_1': '  Beliefs, Events, and Values Inventory (BEVI)是为了帮助大家更好地理解自己的信仰与价值观而设制的。BEVI也考察信仰和价值观是如何影响学习、个人成长、人际关系、以及团体或组织的发展，人生目标的追求。为了解决这些问题，这个结果包含了您是如何看待自己、他人、以及更大世界的信息。但BEVI并不是判断您的回答的正误或好坏的考评，也不认为那样的考评能够实现。相反，这个结果是为了促进大家能去反思我们共同面临的人生问题而设制。',
    'app.individualreport.heading_text_1_2': '  关于BEVI，若有疑问，请咨询所在学校、大学、组织、留学项目的BEVI负责人。您也可以直接咨询我们：<a href="mailto: info@thebevi.com " > info@thebevi.com</a>',
    'app.individualreport.heading_text_1_3': '  注意：在阅读以下结果之前，请务必同意以下3个条件。您可以通过在网上点击“同意”按键，或在阅读以下结果前在另外提供的同意书上签字同意。',
    'app.individualreport.heading_text_2': '  1.您({UserName})自愿完成BEVI考评，随后，想阅读您个人的结果（即您已同意阅读您个人的结果不受任何第三者、团体、组织和社团的要求）。',
    'app.individualreport.heading_text_3': '  2.我们将竭尽全力以有益且富于建设性的方式传达有关BEVI的信息。但是，您在阅读此结果后若感到苦恼的话，将同意我们与您所在单位的BEVI负责人、BEVI结果反馈机构协调员、和/或医疗、心理健康咨询机构联系。',
    'app.individualreport.heading_text_4': '  3.您可以为自己保留一份该结果的复印。但是，因为BEVI及其结果受版权保护，您同意不复制，也不向他人（以电子或其他形式）散布该结果。',
    'app.individualreport.thankyou_static_1_1': 'BEVI及BEVI的结果、每道问题及其内容、还有各项比例标准，除非得到Craig N. Shealy博士的明确书面许可，否则无权对其全部或一部分进行分发、复制、变更、应用、公开或出版。',
    'app.individualreport.thankyou_static_1_2': '以研究或教育为目的的BEVI中文版的使用权已由Craig N. Shealy博士授予西谷 元 教授（日本广岛大学）。',
    'app.individualreport.thankyou_static_2': '  与BEVI考评一样，结果的构成及设计将会不断修改完善。若您对BEVI及其结果有意见或建议，请直接联系info@thebevi.com。',
    'app.individualreport.thankyou_static_3': '  若想了解有关BEVI的更多详情，请查看International Beliefs and Values Institute（IBAVI）的主页（www.ibavi.org）及BEVI主页（thebevi.com）。',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': '  我们相信或重视的东西会有一些不同，或有时会截然不同。为什么会有这样的事情发生呢？对此问题可能会有各种各样的回答，但BEVI却是建立在我们成长的地方、文化、背景、以及在我们的成长过程中或人生中积极的、消极的经验会对我们认知世界有重大影响这一基本认识的基础上。',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': '  从BEVI的观点来看，我们学会思考和感受世界的方式（如政治和宗教）是包括（但不限于）家庭、成长、文化和背景等各种因素共同作用的结果。因此，为了理解人们的信仰和价值观，在了解人们经历的积极的或消极的人生经历的一部分的同时，也有必要了解本人回答的核心需求的满足度。<b>您回答的人生的消极经历是{NegativeLifeEvents} 。并且，您回答的幼年期和青春期的核心需求是{NeedsClosure}</b>',
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': ' 我们的背景和经历也会以其他方式来影响我们。比如，影响了我们处理与自己截然不同的人与事的能力和意愿的发展。从BEVI的立场来看，就这一点会有两种人。一种可称为“自信型”。这种类型的人，在理解人生、工作及人际关系的经历时，无论对“自己或对方是谁？”“世界如何运转？”都充满了自信。另一种可称为“怀疑型”。这种类型的人，对这样的问题几乎没有明确的回答，不仅有时会感到怀疑和后悔，在面对与以往不同或自己不熟悉的状况时，有不知如何应对的倾向。<b>从您的BEVI回答可看出您比起“怀疑型”更倾向于“自信型”。</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': ' 当然，无论是自信型还是怀疑型的方式，都潜伏着利弊。并且，根据事情和状况的不同，我们时常会同时感受到这两种方式。但是，一般来说，如果您是“自信型”，尤其是在经历您不熟悉的状况时，您未必会知道该做什么，如何思考，应感受到什么，所以认识到这一点会更好。',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': ' 　<b>您在某种意义上有着强烈的自我意识和责任感，相信“人生取决于自我”，通常厌恶“消极的想法”。</b>因为您不轻易放弃目标，这一姿态可能有利于您达成了人生中的众多目标。但这一姿态，可能会让您很难与那些没有强烈奋斗目标和积极想法的人产生共鸣。每个人都有着不同的背景和经历，对您而言或许是过度的“贫困”或“软弱”的东西，却有可能反应了他们在生活中所经历的实际的困难和障碍，记住这一点很重要。因为至少现在，即使汇聚世上所有积极的想法，现实中也存在着解决不了的困难和障碍。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': ' 　　</b>我们的背景和经历也会以其他方式来影响我们。比如，影响了我们处理与自己截然不同的人与事的能力和意愿的发展。从BEVI的立场来看，就这一点会有两种人。一种可称为“自信型”。这种类型的人，在理解人生、工作及人际关系的经历时，无论对“自己或对方是谁？”“世界如何运转？”都充满了自信。另一种可称为“怀疑型”。这种类型的人，对这样的问题几乎没有明确的回答，不仅有时会感到怀疑和后悔，在面对与以往不同或自己不熟悉的状况时，有不知如何应对的倾向。<b>从您的BEVI回答可看出您处于“怀疑型”和“自信型”之间。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': ' 　当然，无论是自信型还是怀疑型的方式，都潜伏着利弊。并且，根据事情和状况的不同，我们时常会同时感受到这两种方式。但是，一般来说，如果您是“自信型”，尤其是在经历您不熟悉的状况时，您未必会知道该做什么，如何思考，应感受到什么，所以认识到这一点会更好。',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '　 <b>您似乎会对“人生取决于自我”或“应以积极的想法度过人生”而感到困惑。</b>有时候，这种思维方式非常有效，但您却倾向于质疑对待人生和生活的积极方式。“自己的问题责任在于自己”这一想法的好处在于您会涌出解决眼前问题的力量。但这一世界观的一个缺点在于，持这种思维方式的人，可能不会总能与看起来没有能力“克服困难”的人产生共鸣。关于这一点，值得深思的一个问题是“相信（或不相信）我们对自己的生活完全负责，会有什么影响？” ',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': ' 　我们的背景和经历也会以其他方式来影响我们。比如，影响了我们处理与自己截然不同的人与事的能力和意愿的发展。从BEVI的立场来看，就这一点会有两种人。一种可称为“自信型”。这种类型的人，在理解人生、工作及人际关系的经历时，无论对“自己或对方是谁？”“世界如何运转？”都充满了自信。另一种可称为“怀疑型”。这种类型的人，对这样的问题几乎没有明确的回答，不仅有时会感到怀疑和后悔，在面对与以往不同或自己不熟悉的状况时，有不知如何应对的倾向。<b>从您的BEVI回答可看出您比起“自信型”更倾向于“怀疑型”。</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': ' 当然，无论是自信型还是怀疑型的方式，都潜伏着利弊。并且，根据事情和状况的不同，我们时常会同时感受到这两种方式。但是，一般来说，如果您是“自信型”，尤其是在经历您不熟悉的状况时，您未必会知道该做什么，如何思考，应感受到什么，所以认识到这一点会更好。',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '  <b>您似乎会对“人生取决于自我”或“应以积极的想法度过人生”而感到困惑。</b>有时候，这种思维方式非常有效，但您却倾向于质疑对待人生和生活的积极方式。“自己的问题责任在于自己”这一想法的好处在于您会涌出解决眼前问题的力量。但这一世界观的一个缺点在于，持这种思维方式的人，可能不会总能与看起来没有能力“克服困难”的人产生共鸣。您回顾一下什么时候有干劲，什么时候没有，并分别思考其原因可能会有助于您。例如，关于“命运”“人类的本质”“事情为何那样”，您最后相信什么？这些信念中的哪一个最有利（或不利）于您日常的挑战和发挥？',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': '  我们人类是一个追求生命意义的生物。即使连这一点也未注意到，去获得“事物为什么会这样”的说明，在没有充足信息的情况下，基于因果关系也能导出结论。这些说明，从我们人类为什么会这样行动，在更大的世界中为什么会发生这样的事情，到人生、活着的目的，包罗万象。有时候，并未注意到已经得出关于“事物为什么会这样”的结论，在日常生活中，这些意义形成的过程，确实是在一般的、自动的或者是无意识的情况下产生的，您甚至可能没有意识到这是不可避免的。<b>从您的BEVI回答中，可看出您已具备充分的能力去对事物细微且复杂的因果关系进行说明，并能深入、正确地思考“事物为什么会这样”。</b>',
    'app.individualreport.whywedo.lessthan69.text': ' 我们人类是一个追求生命意义的生物。即使连这一点也未注意到，去获得“事物为什么会这样”的说明，在没有充足信息的情况下，基于因果关系也能导出结论。这些说明，从我们人类为什么会这样行动，在更大的世界中为什么会发生这样的事情，到人生、活着的目的，包罗万象。有时候，并未注意到已经得出关于“事物为什么会这样”的结论，在日常生活中，这些意义形成的过程，确实是在一般的、自动的或者是无意识的情况下产生的，您甚至可能没有意识到这是不可避免的。<b>从您的BEVI回答中，可看出您对“事物为什么会这样”，能更深入思考了。</b>',
    'app.individualreport.whywedo.lessthan30.text': ' 我们人类是一个追求生命意义的生物。即使连这一点也未注意到，去获得“事物为什么会这样”的说明，在没有充足信息的情况下，基于因果关系也能导出结论。这些说明，从我们人类为什么会这样行动，在更大的世界中为什么会发生这样的事情，到人生、活着的目的，包罗万象。有时候，并未注意到已经得出关于“事物为什么会这样”的结论，在日常生活中，这些意义形成的过程，确实是在一般的、自动的或者是无意识的情况下产生的，您甚至可能没有意识到这是不可避免的。<b>从您的BEVI回答中，可看出您或许需要在开始新挑战之前，或在新挑战时或之后，更深入思考“人们为什么会这样做”或“事情为什么会这样”。</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': ' 首先, ',
    'app.individualreport.title.foundation': ' 前提: “生成变量”和“核心欲望”',
    'app.individualreport.title.tolerance_of_desequilibrium': '不均衡的宽容',
    'app.individualreport.title.making_sense': ' 理解行为的动机',
    'app.individualreport.title.access_to_yourself': ' 了解自身及自己的想法、感情、欲望',
    'app.individualreport.title.access_to_the_thoughts': ' 了解他人的想法和感情',
    'app.individualreport.title.access_to_the_larger_world': ' 了解更广阔的世界',
    'app.individualreport.title.conclusion': ' 最后',
    'app.individualreport.title.thankyou': ' 感谢参加BEVI考评。',
    'app.individualreport.title.profile': ' 个人信息',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '  <b>既情绪化又敏感，您可能已注意到，与其他人相比，有些人更看重自己的欲望和情感表达。这样的解释，似乎非常适合您。</b>请思考情绪对人类生存的重要性。把握自己情绪的同时正确解释或理解他人情绪的能力（有人称之为“情商EQ”），是从个人的人际关系到工作环境，开辟人生道路的关键。如果没有情绪，理解我们喜欢的东西和不喜欢的东西，我们自己和他人，以及我们想成为的自己应该非常困难吧。',
    'app.individualreport.accessself.textB': '　<b>既情绪化又敏感，您可能已注意到，与其他人相比，有些人更看重自己的欲望和情感表达。这样的解释，似乎非常适合您。</b>请思考情绪对人类生存的重要性。把握自己情绪的同时正确解释或理解他人情绪的能力（有人称之为“情商EQ”），是从个人的人际关系到工作环境，开辟人生道路的关键。如果没有情绪，理解我们喜欢的东西和不喜欢的东西，我们自己和他人，以及我们想成为的自己应该非常困难吧。',
    'app.individualreport.accessself.textC': ' <b>既情绪化又敏感，您可能已注意到，与其他人相比，有些人更看重自己的欲望和情感表达。这样的解释，似乎不适合您对自己或他人情绪把握的方法。他人表露情绪与弱点似乎会让您感到困惑，有时甚至会让您烦恼。如果这个适合您的话，根据我们背景和人生经历的不同，感受深层欲望和情绪会变得很容易，或相反，会变得很难。面对这一现实，可能重新考虑一下比较好。因为背景和人生经历的不同，一般而言，只有您自己才能判断出您是否能深刻感受到，或他人对您的要求是否已变少。如果您在人生，工作或人际关系上经历了这样的事，特别是这些经历是新的时候，至少在最初处理难以应付的人、状况、人际关系的时候，考虑它们的影响至关重要。</b>请思考情绪对人类生存的重要性。把握自己情绪的同时正确解释或理解他人情绪的能力（有人称之为“情商EQ”），是从个人的人际关系到工作环境，开辟人生道路的关键。如果没有情绪，理解我们喜欢的东西和不喜欢的东西，我们自己和他人，以及我们想成为的自己应该非常困难吧。',
    'app.individualreport.accessself.textI': '  <b>您备受艰难抉择或复杂情绪的煎熬，通常会尽量避免过度的“自我反省”。当您看到一个人过于关注自己的欲望和情绪时，您或许会感到困惑甚至焦躁。每个人都拥有塑造他们自己观点的不同的生活经历和背景，并且对“为什么我们是我们”这一问题花过多时间思考的人，经常能够帮助他人理解自己的真实姿态和行为的理由，记住这一点至关重要。而且，有时他们的经历、理解和控制会受艰难抉择或复杂情绪的影响。这时，最好与就此类问题接受过培训或具有这方面知识的人交谈。</b>',
    'app.individualreport.accessself.textH': ' <b>您似乎对自己的想法与情绪持开放态度，但您或许会意识到与周围人相比，您会持“中立态度”。一些人或许会在思考“我们为什么会变成这样”这一事情上花费大量的时间和精力，但也有些人会完全不考虑，反而会对思考的人感到不安。就这一点，您从各种意义上，是能够帮助持完全不同看法的两种人（例如，“非常喜欢”情绪外露的人和完全不情绪外露的人）增进相互理解的人。</b>',
    'app.individualreport.accessself.textG': '<b>您对艰难抉择或复杂情绪持非常开放的态度，通常会自我反省，或许会意识到自己的想法与情绪。有时，当看起来和他人没有同等能力时，会感到寂寞与孤独。对您这样的人来说，理解并尊重欲望及情绪的外露，去发现喜欢思考“为什么我们会这样”的“志同道合的人”至关重要。世界需要像您这样具有深度思考与感悟能力的人。</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {
    'app.individualreport.accessothers.textA': '宗教和政治、男人和女人的作用、人种和民族的问题，诸如此类的话题，要是遇到和我们持完全不同意见的人，或许会变成诱发一场非常激烈辩论的导火线，因此，我们从年轻时就会避开此类话题。但从BEVI的观点来看，这些并不属于不能说的、或不该说的话题。实际上，讨论此类话题，是实现公民社会的健全议论必不可缺的。最重要的是，要去理解为什么某一话题对我们而言会成为“诱发一场非常激烈辩论的导火线”。因此，您需要去审视自己是如何看待宗教、性别、政治和种族问题，您的这些信仰和价值观或许会潜在影响您与那些持相同或不同看法和感受的人的关系。<br /><br />' +
        '<b>首先第一，您看起来有强烈的宗教信仰或信仰心。到目前为止您或许有过这一信仰心帮您找出意义及目的的经历。有强烈的信仰心是非常了不起的事情。但同时，您也需要去理解他人、团体、宗教或文化就这些基本问题，可能会与您持迥然不同的看法。</b>有时，对于宗教问题，为什么我们相信那些，对此我们立场明确。另一方面，也有这种情况，那就是没有很好地思考我们为什么信仰这些，不管是什么，只是单纯的接受被教的东西。在这里重要的是，不是说这个信仰是正确的，其他信仰是错误的，相反，对我们所有人都会产生影响的是，我们大家相信什么（例如，没有信仰，其实是认为某一特定的信仰或信仰系统“信仰”没有重要性和现实性的一种信仰）。宗教信仰问题，会对我们自身、与他人及更大的世界的经历产生重大影响，所以，努力去理解我们对宗教自己所持的信仰和价值观的性质及起源至关重要。同样重要的是，由于我们的宗教信仰，通常源于我们的成长、文化及背景，因此理解这一点或许能加深我们理解他人为什么会与自己持有相同或不同的想法。怀着真挚的好奇心和诚意去探究信仰和价值观，不仅可以加深我们去理解为什么自己会是现在的自己，也可以帮助他人以同样的方式去理解自己。',
    'app.individualreport.accessothers.textB': '宗教和政治、男人和女人的作用、人种和民族的问题，诸如此类的话题，要是遇到和我们持完全不同意见的人，或许会变成诱发一场非常激烈辩论的导火线，因此，我们从年轻时就会避开此类话题。但从BEVI的观点来看，这些并不属于不能说的、或不该说的话题。实际上，讨论此类话题，是实现公民社会的健全议论必不可缺的。最重要的是，要去理解为什么某一话题对我们而言会成为“诱发一场非常激烈辩论的导火线”。因此，您需要去审视自己是如何看待宗教、性别、政治和种族问题，您的这些信仰和价值观或许会潜在影响您与那些持相同或不同看法和感受的人的关系。<br /><br />' +
        '<b>首先第一，您看起来有稳定的宗教信仰。对于“信仰问题”，或许您会认为自己现在的信仰完全没问题。但同时，对于自己信仰的深度和强度又可能会持有疑问。并且，或许会认为那些看起来有强烈信仰的人（例如，将自己描述为非常虔诚或非常反宗教的人）是不可思议的。</b>有时，对于宗教问题，为什么我们相信那些，对此我们立场明确。另一方面，也有这种情况，那就是没有很好地思考我们为什么信仰这些，不管是什么，只是单纯的接受被教的东西。在这里重要的是，不是说这个信仰是正确的，其他信仰是错误的，相反，对我们所有人都会产生影响的是，我们大家相信什么（例如，没有信仰，其实是认为某一特定的信仰或信仰系统“信仰”没有重要性和现实性的一种信仰）。宗教信仰问题，会对我们自身、与他人及更大的世界的经历产生重大影响，所以，努力去理解我们对宗教自己所持的信仰和价值观的性质及起源至关重要。同样重要的是，由于我们的宗教信仰，通常源于我们的成长、文化及背景，因此理解这一点或许能加深我们理解他人为什么会与自己持有相同或不同的想法。怀着真挚的好奇心和诚意去探究信仰和价值观，不仅可以加深我们去理解为什么自己会是现在的自己，也可以帮助他人以同样的方式去理解自己。',
    'app.individualreport.accessothers.textC': '宗教和政治、男人和女人的作用、人种和民族的问题，诸如此类的话题，要是遇到和我们持完全不同意见的人，或许会变成诱发一场非常激烈辩论的导火线，因此，我们从年轻时就会避开此类话题。但从BEVI的观点来看，这些并不属于不能说的、或不该说的话题。实际上，讨论此类话题，是实现公民社会的健全议论必不可缺的。最重要的是，要去理解为什么某一话题对我们而言会成为“诱发一场非常激烈辩论的导火线”。因此，您需要去审视自己是如何看待宗教、性别、政治和种族问题，您的这些信仰和价值观或许会潜在影响您与那些持相同或不同看法和感受的人的关系。<br /><br />' +
        '<b>首先第一，您看起来没有强烈的宗教信仰或信仰心，对宗教信仰和信者持怀疑的态度。您之所以会对宗教信仰持谨慎的态度，或以“信仰”为由拒绝接受此类问题的态度，是因为您认为持此类信仰和价值观的人大都是没有经过认真思考就盲目信奉的。对您而言，或许去思考这些事情会比较好：您目前的接纳方式会产生怎样的后果？或要是将某一强烈的信念（例如，完全肯定自身信仰的正当性）简单地换成其他某一强烈的信念（例如，完全否定自身信仰的正当性），那或许会导致让您无法加深对持久不变的信仰和价值观的起源及目的的理解。</b>有时，对于宗教问题，为什么我们相信那些，对此我们立场明确。另一方面，也有这种情况，那就是没有很好地思考我们为什么信仰这些，不管是什么，只是单纯的接受被教的东西。在这里重要的是，不是说这个信仰是正确的，其他信仰是错误的，相反，对我们所有人都会产生影响的是，我们大家相信什么（例如，没有信仰，其实是认为某一特定的信仰或信仰系统“信仰”没有重要性和现实性的一种信仰）。宗教信仰问题，会对我们自身、与他人及更大的世界的经历产生重大影响，所以，努力去理解我们对宗教自己所持的信仰和价值观的性质及起源至关重要。同样重要的是，由于我们的宗教信仰，通常源于我们的成长、文化及背景，因此理解这一点或许能加深我们理解他人为什么会与自己持有相同或不同的想法。怀着真挚的好奇心和诚意去探究信仰和价值观，不仅可以加深我们去理解为什么自己会是现在的自己，也可以帮助他人以同样的方式去理解自己。',
}

const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '  <b>第二，关于性别问题，您是男性还是女性？您应如何？您倾向于持有传统的或保守的信仰和价值观。而且，您或许会感到男人和女人都是“按其应有的姿态被创造出来的”，只要单纯地理解并接受这个事实，可能就会给所有的人带来好结果。我们所有人都知道男女有别。但问题就出在为什么会有差别上。</b>从您的回答可看出您倾向于性别差异主要归因于其根本的生物学因素。关于这一话题的学说非常复杂，男女间实际上或是假定的“差异”，长年被当作否定少女或女性众多权力的依据，认识到这一点至关重要。总之，“生物学上的议论”会因女人在男人的世界里不能对等生存、工作，或原本就没有女人在男人世界里生存的计划，经常会以此去否定女性权力的正当化。但固有观念就像把双刃剑，男孩和男人被认为情感及其表达的“能力低下”，这不仅剥夺了他们拥有情感的“权利”，也意味着否定了他们去体会自己和他人欲望的机会。就像这样，男人要学会“变得强大”，对他人进行攻击，或不考虑对方莽撞行动时，或许这样的“教训”对我们所有人都会产生重大影响。而且，历史赋予了女孩和女人教育孩子等的职责，这导致了她们平等参加的权利被否定。在思考这一问题时重要的是要去了解生物差异的论点是如何被用来限制女性和男性的机会和生存方式及其对我们所有人的影响。',
    'app.individualreport.gendertradition.textB': '  <b>第二，关于性别问题，您是男性还是女性？您应如何？您倾向于拥有平衡的信仰和价值观。而且，您不相信男人和女人都是单纯地“按其应有的姿态被创造出来的”。您或许会承认男女有别，但最重要的是您意识到为什么会有差别这一问题上了吗？</b>例如，男女的差别是起因于“环境”？“遗传”？还是其两者的结合？男女双方的立场都有据可寻，男女间的差异不仅归因于其根本的生物学因素，也是文化、教育方法复杂相互作用的结果。重要的是还要意识到：对男女差异的生物学解释，从教育到投票乃至控制自己的性行为，其实长期以来一直被用作剥夺女孩和妇女众多权利的依据。但固有观念就像把双刃剑。例如，男孩和男人情感及其表达能力低下，或许以此为由而被认为没有能力教育孩子。在面对这一问题，大多数学者主张男人和女人作为人，与差异相比，有更多相似的地方。因此，对于性别差异，通过阅读文献学习我们知道什么，不知道什么至关重要。',
    'app.individualreport.gendertradition.textC': '  <b>第二，关于性别问题，您是男性还是女性？您应如何？您倾向于支持更进步或更变革性的信仰和价值观。恐怕您对男人和女人都是单纯地“按其应有的姿态被创造出来的”这一观点不同意的可能性很大。您意识到男女有别，对此事实的凭证我们大家每天都能目睹。但问题就出在为什么会有差别上。</b>例如，男女的差别是起因于“环境”？“遗传”？还是其两者的结合？看了您的答案，您更倾向于认为男女间的差异是由其根本的文化或环境因素所造成的。关于这一话题的学说非常复杂，男女双方的立场都有据可寻，男女间的差异不仅归因于其根本的生物学因素，也是文化、教育方法复杂相互作用的结果。但同时重要的是还要意识到：对男女差异的生物学解释，从教育到投票乃至控制自己的性行为，其实长期以来一直被用作剥夺女孩和妇女众多权利的依据。但固有观念就像把双刃剑。例如，男孩和男人被认为情感及其表达能力低下，这不仅剥夺了他们去感受自己和他人情绪或去体验欲望的“权利”。而且，历史赋予了女孩和女人教育孩子等的职责，这导致了她们平等参加的权利被否定。在思考这一问题时重要的是要去了解假设的生物差异论是如何被用来限制女性和男性的机会和生存方式及其对我们个人乃至社会所有人的影响。大多数学者主张男人和女人作为人，与差异相比，有更多相似的地方。因此，对于性别差异，通过阅读文献学习我们知道什么，不知道什么至关重要。',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '  <b>第三，关于涉及到文化、政治问题，您倾向于认为它们是容易接受的、开放的、自由的、进步的。这样的思维方式和生存方式，意味着您会衷心期待和新的或与自己不同的想法、方式、状况、人、社会的相遇，而且能够享受这样的机会是件非常了不起的事情。您同时认为这可以给自己的生活、工作、以及社会整体带来变化，所以或许会对没有这些共同信念的人您可能会感到不安。</b>再次强调，理解他人为什么和自己有相同的想法或不同的感受至关重要。例如，不同的制度、文化、或生存方式，所有这些都可能会让我们体验到是对自己世界观的挑战，因此，其或许会成为一些人不安的因素。您如果不同意这一点的话，对这种情况感到不舒服的人，您或许会将他们作为视野狭隘或单纯的人而排除他们。',
    'app.individualreport.variables.textE_2': ' 相反，尝试去了解那些以与您有不同想法或感受的人的背景和经历。他们或许比您想象的更开放，但却为如何才能更好地理解与自己截然不同的信仰和价值观而奋斗。在与他们的相遇中，他们以及我们所有人，或许会理解我们大家为什么会相信并重视这样的事情。',
    'app.individualreport.variables.textF': '  <b>第三，关于涉及到文化、政治问题，您和被认为是进步的或保守的朋友和同事相比，可能会被说成“稳健”或“中规中矩”。您还可以从看似妥协或对立的观点和想法中找到价值，您的这一姿态对您有利。但这一姿态，可能也表现出您对这些问题没有深入思考，比较不关心或只不过还不知道针对这一点自己相信什么。</b>如果这些叙述适合现在的您的话，建议您认真且更频繁地去思考为何如此相信文化、政治、人种或性别的问题。如果有机会和比您更持明确主张的朋友和同事讨论为什么相信这个的话，不仅意味深长，也将有利于您更明确自己的世界观。甚至，您的姿态可能会为那些比您有“更强烈信念”的人提供一个让他们去思考为什么他们会有如此强烈信念的机会。无论如何，思考自我，并与他人交谈，可以让您对这些影响我们所有人的重要问题所持的信念和价值观变得更明确。',
    'app.individualreport.variables.textG_1': '  <b>第三，关于涉及到文化、政治问题，不管是国内问题，还是国际问题，比起您的朋友和同事，对于急剧变化的时态及价值观，您倾向于传统的、消极的和慎重的。您在没有充分考虑到这些变化（会给家人、职场、社会等）带来的影响，以及伴随导入新的社会系统而带来的成本问题的情况下，您可能会觉得一个已被证明多年的系统正在受到质疑或摧毁。对没有这些共同信念的人您可能会感到不安，并且可能会认为他们的主张不成熟，或不负责任。</b>再次强调，理解他人为什么和自己有相同的想法或不同的感受至关重要。例如，有些人相信“传统的”价值观和生存方式，只不过是支配没有权力、财力和权利的人的手段。这样的信念，对这些人来说，可能基于他们自身或他们的熟人所经历的个人成长和环境而形成的。您如果对这一观点持有不同看法的话，您或许会把他们当成满腹牢骚的人或单纯的人而想排除他们。',
    'app.individualreport.variables.textG_2': '  相反，尝试更多地了解那些与您有不同思考感受的人的背景和经历。他们比您想象的更加开放，您满怀诚意虚心接受的话，他可能会比您想象更认真更诚实的跟您对话。就像您有可能被他们说服一样，他们也可能会因为您的见解的合理性而动心。最后，和这样的「他人」相遇，有利于促进善意和相互尊重，有利于他人和我们每个人更好地理解我们为什么会相信重视这样的事情。',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>关于环境意识，您倾向于认为与地球有很深的亲密感和联系，认为它是唯一必须保护和保存的家园。因此，我们为了保护拥有生命的地球，比起去寻找证据更需要我们去采取更多的行动，尽管如此，或许众多的人对没有分享这一想法而感到懊恼。</b>思考这些问题重要的是，各种各样的团体、文化、宗教会以他们自己认为是正当的理由为依据，这意味着我们可能对人类与自然界和地球的关系有完全不同的理解。我们之间为什么会有这样的不同？我们为什么会因这些问题而动心？尝试思考这些问题至关重要。因为我们对环境的信仰和价值观，以及个人、组织、政府及社会体验理解自然界，与自然界的息息相关，都会对整个世界的环保活动、政策、实践产生重大影响。不仅诉斥您了解的“事实”，重要的是，您要尽可能地尊重他人的立场以更开放的姿态去参与讨论。换句话说，就是有必要就您自身的信念，不仅要从理性的观点（当然这是重要的），也要从为何自己那样想的观点与他人共享。这种方式，可能看起来抽象，但它包含了我们坚信的理由与重要的价值观，这些价值观是我们对自然的义务（以及不是义务的东西）的“核心”。实际上它更有可能鼓励我们对环境问题的真正思考。',
    'app.individualreport.ecologicalresonance.textB': '<b>关于环境意识，您在开放地讨论保护地球天然资源必要性的同时，倾向于认为自己在环境问题上基本上持“中立的”态度。因此，或许会对环境问题持强烈信念的人抱有疑问。这一差异不仅让我们有机会了解我们在这方面的信念，同时也让此类重要问题自己所持的信念（或是不足）更明确。</b>思考这些问题重要的是，各种各样的团体、文化、宗教会以他们自己认为是正当的理由为依据，这意味着我们可能对人类与自然界和地球的关系有完全不同的理解。我们之间为什么会有这样的不同？我们为什么会因这些问题而动心？尝试思考这些问题至关重要。因为我们对环境的信仰和价值观，以及个人、组织、政府及社会体验理解自然界，与自然界的息息相关，都会对整个世界的环保活动、政策、实践产生重大影响。不仅诉斥您了解的“事实”，重要的是，您要尽可能地尊重他人的立场以更开放的姿态去参与讨论。换句话说，就是有必要就您自身的信念，不仅要从理性的观点（当然这是重要的），也要从为何自己那样想的观点与他人共享。这种方式，可能看起来抽象，但它包含了我们坚信的理由与重要的价值观，这些价值观是我们对自然的义务（以及不是义务的东西）的“核心”。实际上它更有可能鼓励我们对环境问题的真正思考。',
    'app.individualreport.ecologicalresonance.textC': '<b>关于环境意识，您是一个主张应与地球保持和谐与联系的人，并且对于担心自然界状态的人，您通常会持怀疑态度。如果您对关于环境的主张持怀疑态度的话，碰到保证公平性的看法，如果能更多地调查现代科学对这些极其重要和复杂的问题的揭示，那就太好了。还值得注意的是，包括基于宗教和信仰的组织，世界上各种各样的团体，正逐渐意识到管理地球和自然界的重要性。</b>思考这些问题最重要的是，各种各样的团体、文化、宗教会以他们认为是正当的理由为依据，这意味着我们可能对人类与自然界和地球的关系有完全不同的理解。我们之间为什么会有这样的不同，我们为什么会因为这个问题而动心，尝试思考这些问题是很重要的。因为我们对环境的信仰和世界观，以及个人、组织、政府及社会体验理解自然界，与自然界相关的样态，都会对世界中环境保护活动、政策、实践产生很大的影响。不仅诉斥您了解的“事实”，重要的是，您要尽可能地尊重他人的立场以更开放的姿态去参与讨论。换句话说，就是有必要就您自身的信念，不仅要从理性的观点（当然这是重要的），也要从为何自己那样想的观点与他人共享。这种方式，可能看起来抽象，但它包含了我们坚信的理由与重要的价值观，这些价值观是我们对自然的义务（以及不是义务的东西）的“核心”。实际上它更有可能鼓励我们对环境问题的真正思考。',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': '我们最后的“从大视野来看”课题是，最广泛的意义上的对“全球问题的参与”的您的倾向。<b>整体而言，您倾向于了解与自己不同的文化、社会和传统，并大力支持在尊重它们的基础上所建立的关系。这一世界观表明，您倾向于尽可能接纳跨文化、全球性的机会和经历。</b>关于这一点，无论您持何种姿态，世界都会逐渐联系在一起，考虑到这一事实至关重要。例如，即使某一国的事情（经济、政治、环境等）和其他国家的大多数人没有直接关系，也有可能会影响到其他国家的状况。甚至，随着技术的发展，您可置身于事故发生的现场，甚至可影响它的发展。这样，对于他人（不同的文化、宗教等）我们所持的信仰和价值观，会影响我们如何经历、面对、应对自己和他人，并且与如何理解、应对与人类息息相关的地球的诸问题与机会有必然的联系。',
    'app.individualreport.globalengagement.textB': '我们最后的“从大视野来看”课题是，最广泛的意义上的对“全球问题的参与”的您的倾向。<b>整体而言，您倾向于了解与自己不同的文化、社会和传统，并能感受到在尊重它们的基础上所建立的关系的必要性。这一世界观表明，您对相关的跨文化、全球性的机会和经历感兴趣。</b>关于这一点，无论您持何种姿态，世界都会逐渐联系在一起，考虑到这一事实至关重要。例如，即使某一国的事情（经济、政治、环境等）和其他国家的大多数人没有直接关系，也有可能会影响到其他国家的状况。甚至，随着技术的发展，您可置身于事故发生的现场，甚至可影响它的发展。这样，对于他人（不同的文化、宗教等）我们所持的信仰和价值观，会影响我们如何经历、面对、应对自己和他人，并且与如何理解、应对与人类息息相关的地球的诸问题与机会有必然的联系。',
    'app.individualreport.globalengagement.textC': '我们最后的“从大视野来看”课题是，最广泛的意义上的对”全球问题的参与”的您的倾向。<b>整体而言，您会抱有这样的疑问，我们有时（或非常频繁地）对不同的文化、社会和传统过于关心、宽容或让步了。</b>关于这一点，无论您持何种姿态，世界都会逐渐联系在一起，考虑到这一事实至关重要。例如，即使某一国的事情（经济、政治、环境等）和其他国家的大多数人没有直接关系，也有可能会影响到其他国家的状况。甚至，随着技术的发展，您可置身于事故发生的现场，甚至可影响它的发展。这样，对于他人（不同的文化、宗教等）我们所持的信仰和价值观，会影响我们如何经历、面对、应对自己和他人，并且与如何理解、应对与人类息息相关的地球的诸问题与机会有必然的联系。',
}

const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': '低',
    'app.individualreport.negativelifeevents.moderate': '中',
    'app.individualreport.negativelifeevents.high': '高',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': '十分满足',
    'app.individualreport.needsclosure.met_at_times': '有时满足，但不总是',
    'app.individualreport.needsclosure.not_met_well': '不满足',
}

export const individualReportPageZHTW: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle, ...individualReportVariables,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};