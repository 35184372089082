import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { SelectItem } from '../../../../components/FormDropdown';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../../../redux/listOrganizationLogic';
import { UsersActions, usersLogic } from '../../../../redux/usersLogic';
import { OrganizationDetails } from '../../../../services/organizationService';
import { MultipleUser } from './MultipleUser';

type Props = {
    loading: boolean;
}

export const MultipleUserContainer = ({ loading }: Props): JSX.Element => {

    const { organizations }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { getOrganizations }: ListOrganizationActions = useActions(listOrganizationLogic);

    const { createUserBatch }: UsersActions = useActions(usersLogic);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': []
        }
    });

    const onClickSubmit = (organizationId?: number, sendEmail?: boolean) => {
        createUserBatch(acceptedFiles[0], organizationId, sendEmail);
    }

    useEffect(() => {
        getOrganizations();
    }, [getOrganizations]);

    const organizationSelectOptions: Array<SelectItem> =
        organizations.map((item: OrganizationDetails) => ({ value: item.id, label: item.name }));

    return (
        <MultipleUser
            organizations={organizationSelectOptions}
            loading={loading}
            acceptedFiles={acceptedFiles}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            onClearSelectedFile={() => acceptedFiles.shift()}
            onClickSubmit={onClickSubmit}
        />
    )
};
