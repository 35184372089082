import 'bootstrap/dist/css/bootstrap.min.css';
import './style/customize.css';

import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import Reactotron from 'reactotron-react-js';

import { colors } from './config/colors';
import configureStore from './redux/store';
import { Routes } from './router/router';
import { ConfigProvider } from 'antd';

const store = configureStore();

export class App extends React.Component {
    componentDidMount() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            Reactotron.connect();
        }
    }

    render(): ReactNode {
        return (
            <ConfigProvider theme={{
                token: {
                    colorTextPlaceholder: '#808080'
                }
            }}>
                <div className="app">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>BEVI - Beliefs, Events, and Values Inventory</title>
                        <style>{`body { background-color: ${colors.light}; }`}</style>
                    </Helmet>
                    <Provider store={store}>
                        <Routes />
                    </Provider>
                </div>
            </ConfigProvider>
        );
    }
}
