import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import { SaveDiscursiveAnswersResponse, saveDiscursiveQuestions } from '../services/questionsService';
import { DiscursiveQuestionAnswers } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateExternalToken, ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['sendDiscursiveAnswers', 'clearSendQuestion']);
export type DiscursiveQuestionsActions = { [K in (typeof actionsLiterals)[number]]: any };
export const discursiveActionsConstants: DiscursiveQuestionsActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'successfulSend'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'showReport', 'sendQuestion']);
export type DiscursiveQuestionsProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const discursiveQuestionsPropsConstants: DiscursiveQuestionsProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: DiscursiveQuestionsActions & PrivateActions; };

type WorkersParam = {
    workers: {
        saveDiscursiveAnswers: () => void;
    }
};

// Define payloads type.
type ErrorPayload = { error: string; };
type AnswersPayload = { answers: DiscursiveQuestionAnswers };
type SuccessfulSendPayload = { result: boolean };

type SendAnswersRequest = { payload: AnswersPayload };
type SaveDiscursiveAnswersRaceResponse = {
    saveDiscursiveAnswersResponse?: SaveDiscursiveAnswersResponse;
}

// persist app reducer
export const discursiveQuestionsPath = 'discursiveQuestions';

export const discursiveQuestionsReducer = keaReducer(discursiveQuestionsPath);

export const discursiveQuestionsLogic = kea({
     connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [discursiveQuestionsPath],

    actions: () => ({
        [discursiveActionsConstants.sendDiscursiveAnswers]:
            (answers: DiscursiveQuestionAnswers ): AnswersPayload => ({ answers }),
        [discursiveActionsConstants.clearSendQuestion]: true,
        [privateActionsConstants.successfulSend]: 
            (result: boolean): SuccessfulSendPayload => ({ result }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [discursiveQuestionsPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.sendDiscursiveAnswers]: () => true,
            [actions.setError]: () => false,
            [actions.successfulSend]: () => false,
            [logout]: () => false
        }],
        [discursiveQuestionsPropsConstants.error]: [null, PropTypes.string, {
            [actions.sendDiscursiveAnswers]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [discursiveQuestionsPropsConstants.showReport]: [null, PropTypes.bool, {
            [actions.successfulSend]: (_: boolean, payload: SuccessfulSendPayload) => payload.result,
            [logout]: () => null
        }],
        [discursiveQuestionsPropsConstants.sendQuestion]: [false, PropTypes.bool, {
            [actions.successfulSend]: () => true,
            [actions.clearSendQuestion]: () => false,
            [logout]: () => false
        }]
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.sendDiscursiveAnswers]: workers.saveDiscursiveAnswers
    }),

    workers: {
        * saveDiscursiveAnswers(action: SendAnswersRequest): any {
            const { answers } = action.payload;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }
            
            //@ts-ignore
            const { setError, successfulSend } = this.actionCreators;
            const { saveDiscursiveAnswersResponse }: SaveDiscursiveAnswersRaceResponse = yield race({
                saveDiscursiveAnswersResponse: call(saveDiscursiveQuestions, token, answers),
                timeout: delay(requestTimeout)
            });

            if (saveDiscursiveAnswersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (saveDiscursiveAnswersResponse?.status === successfulCode) {
                yield put(successfulSend(saveDiscursiveAnswersResponse?.body))
            } else if (!!saveDiscursiveAnswersResponse?.keyErrorMessage) {
                yield put(setError(saveDiscursiveAnswersResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        }
    }
});
