import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import { downloadWithinGroupReports, getComparativeByIndividualReport, GetComparativeByIndividualReportResponse, ReportLanguage } from '../services/reportService';
import { ComparativeByIndividualReportItem } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['loadComparativeByIndividualReport', 'downloadWithinGroupReport']);
export type ComparativeByIndividualReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const comparativeByIndividualReportConstants: ComparativeByIndividualReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReport', 'setSuccessfullDownload', 'setDataDownloadError'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'report', 'loadingDataDownload']);
export type ComparativeByIndividualReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const comparativeByIndividualReportPropsConstants: ComparativeByIndividualReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ComparativeByIndividualReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        fetchComparativeByIndividualReport: () => void;
        fetchDownloadWithinGroupReport: () => void;
    },
};

// Define payloads type.
type ErrorPayload = { error: string; };
type ComparativeByIndividualReportPayload = { reportId: number };
//todo
type ReportPayload = { data: ComparativeByIndividualReportItem };
type ComparativeByIndividualReportRequest = { payload: ComparativeByIndividualReportPayload };
type ComparativeReportByIndividualReportRaceResponse = {
    comparativeByIndividualReportResponse?: GetComparativeByIndividualReportResponse;
}
type DownloadWithinGroupReportRequestPayload = { language: ReportLanguage, reportId: number };
type DownloadWithinGroupReportRequestPayloadRequest = { payload: DownloadWithinGroupReportRequestPayload };

// persist app reducer
export const comparativeByIndividualReportPath = 'comparativeByIndividualReport';
export const comparativeByIndividualReportReducer = keaReducer(comparativeByIndividualReportPath);

export const comparativeByIndividualReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [comparativeByIndividualReportPath],

    actions: () => ({
        [comparativeByIndividualReportConstants.loadComparativeByIndividualReport]:
            (reportId: number): ComparativeByIndividualReportPayload => ({ reportId }),
        [privateActionsConstants.setReport]:
            (data: ComparativeByIndividualReportItem): ReportPayload => ({ data }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [comparativeByIndividualReportConstants.downloadWithinGroupReport]:
            (language: ReportLanguage, reportId: number): DownloadWithinGroupReportRequestPayload => ({ language, reportId }),
        [privateActionsConstants.setSuccessfullDownload]: () => true,
        [privateActionsConstants.setDataDownloadError]:
                (error: string): ErrorPayload => ({ error }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [comparativeByIndividualReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.loadComparativeByIndividualReport]: () => true,
            [actions.setError]: () => false,
            [actions.setReport]: () => false,
            [logout]: () => false
        }],
        [comparativeByIndividualReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.loadComparativeByIndividualReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [comparativeByIndividualReportPropsConstants.report]: [null, PropTypes.any, {
            [actions.setReport]: (_: ComparativeByIndividualReportItem, payload: ReportPayload) => payload.data,
            [actions.loadComparativeByIndividualReport]: () => null,
            [logout]: () => null
        }],
        [comparativeByIndividualReportPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.downloadWithinGroupReport]: () => true,
            [actions.setSuccessfullDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadComparativeByIndividualReport]: workers.fetchComparativeByIndividualReport,
        [actions.downloadWithinGroupReport]: workers.fetchDownloadWithinGroupReport,
    }),

    workers: {
        * fetchComparativeByIndividualReport(action: ComparativeByIndividualReportRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { reportId } = action.payload;
            //@ts-ignore
            const { setError, setReport } = this.actionCreators;
            const { comparativeByIndividualReportResponse }: ComparativeReportByIndividualReportRaceResponse = yield race({
                comparativeByIndividualReportResponse: call(getComparativeByIndividualReport, token, reportId),
                timeout: delay(requestTimeout)
            });

            if (comparativeByIndividualReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (comparativeByIndividualReportResponse?.status === successfulCode) {
                yield put(setReport(comparativeByIndividualReportResponse?.body))
            } else if (!!comparativeByIndividualReportResponse?.keyErrorMessage) {
                yield put(setError(comparativeByIndividualReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchDownloadWithinGroupReport(action: DownloadWithinGroupReportRequestPayloadRequest): any {
            //@ts-ignore
            const { setDataDownloadError, setSuccessfullDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { language, reportId } = action.payload;
            const downloadWithinGroupReportResponse = yield call(
                downloadWithinGroupReports,
                token,
                { reportId, language }
            );

            if (downloadWithinGroupReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (downloadWithinGroupReportResponse?.status === successfulCode) {
                yield put(setSuccessfullDownload());
            } else if (!!downloadWithinGroupReportResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(downloadWithinGroupReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        }
    }
});
