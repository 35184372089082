import jwt_decode from 'jwt-decode';
import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { notFoundCode, successfulCode } from '../config/constants';
import {
    changePassword, changePasswordFirstAccess, ChangePasswordResponse, FirstAccessChangePasswordResponse, forgotPassword, ForgotPasswordResponse, login,
    LogonResponse, registerThroughAnInvitation, RegisterThroughAnInvitationResponse, TokenInfo
} from '../services/loginService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';


// actions
const actionsLiterals = asLiterals([
    'login',
    'setToken',
    'changePassword',
    'forgotPassword',
    'clearNotAuthorized',
    'clearServiceUnavailable',
    'setPasswordFirstAccess',
    'registerThroughAnInvitation'
]);
export type AppActions = { [K in (typeof actionsLiterals)[number]]: any };
export const appActionsConstants: AppActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setSystemError',
    'setCredentialsError',
    'passwordUpdateSuccessful',
    'setPasswordUpdateError',
    'forgotPasswordRequestFinished',
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading',
    'token',
    'tokenInfo',
    'hasCredentialsError',
    'hasSystemError',
    'hasPasswordUpdateError',
    'notAuthorized',
    'serviceUnavailable',
]);
export type AppProps = { [K in (typeof reducersLiterals)[number]]: any };
export const appPropsConstants: AppProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: AppActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchLoginRequest: () => void;
        fetchLogoutRequest: () => void;
        fetchChangePasswordRequest: () => void;
        fetchForgotPasswordRequest: () => void;
        fetchSetFirstAccessRequest: () => void;
        fetchRegisterRequest: () => void;
    }
};

// Define payloads type.
type LoginPayload = { username: string; password: string; };
type LoginRequest = { payload: LoginPayload };
type ChangePasswordPayload = { token: string; newPassword: string; };
type ChangePasswordRequest = { payload: ChangePasswordPayload };
type ForgotPasswordPayload = { username: string; };
type ForgotPasswordRequest = { payload: ForgotPasswordPayload };
type TokenPayload = { token: string; };
type RegisterPayload = { name: string; email: string; password: string; subprogramId: number; language: number };
type RegisterRequest = { payload: RegisterPayload };

type FirstAccessChangePasswordRaceResponse = { response: FirstAccessChangePasswordResponse; }
type LogonRaceResponse = { response: LogonResponse; }
type ChangePasswordRaceResponse = { response: ChangePasswordResponse; }
type ForgotPasswordRaceResponse = { response: ForgotPasswordResponse; }
type RegisterRaceResponse = { response: RegisterThroughAnInvitationResponse; }

// persist app reducer
export const appKeaPath = 'app';

export const appReducer = keaReducer(appKeaPath);

export const appLogic = kea({
    path: () => [appKeaPath],

    actions: () => ({
        [appActionsConstants.login]:
            (username: string, password: string): LoginPayload => ({ username, password }),
        [appActionsConstants.changePassword]:
            (token: string, newPassword: string): ChangePasswordPayload => ({ token, newPassword }),
        [appActionsConstants.setPasswordFirstAccess]:
            (token: string, newPassword: string): ChangePasswordPayload => ({ token, newPassword }),
        [appActionsConstants.forgotPassword]:
            (username: string): ForgotPasswordPayload => ({ username }),
        [appActionsConstants.registerThroughAnInvitation]:
            (name: string, email: string, password: string, subprogramId: number, language: number): RegisterPayload =>
                ({ name, email, password, subprogramId, language }),
        [appActionsConstants.clearNotAuthorized]: () => true,
        [appActionsConstants.clearServiceUnavailable]: () => true,
        [appActionsConstants.setToken]: (token: string): TokenPayload => ({ token }),
        [privateActionsConstants.passwordUpdateSuccessful]: true,
        [privateActionsConstants.forgotPasswordRequestFinished]: true,
        [privateActionsConstants.setPasswordUpdateError]: true,
        [privateActionsConstants.setSystemError]: true,
        [privateActionsConstants.setCredentialsError]: true,
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [appPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.login]: () => true,
            [actions.changePassword]: () => true,
            [actions.setPasswordFirstAccess]: () => true,
            [actions.forgotPassword]: () => true,
            [actions.registerThroughAnInvitation]: () => true,
            [actions.setToken]: () => false,
            [actions.setSystemError]: () => false,
            [actions.setCredentialsError]: () => false,
            [actions.passwordUpdateSuccessful]: () => false,
            [actions.setPasswordUpdateError]: () => false,
            [actions.forgotPasswordRequestFinished]: () => false,
            [logout]: () => false
        }],
        [appPropsConstants.token]: [null, PropTypes.string, { persist: true }, {
            [actions.setToken]: (_: string, payload: TokenPayload) => payload.token,
            [logout]: () => null
        }],
        [appPropsConstants.tokenInfo]: [null, PropTypes.any, { persist: true }, {
            [actions.setToken]: (_: TokenInfo, payload: TokenPayload) => {
                if (!payload.token) return null;
                try {
                    return jwt_decode<TokenInfo>(payload.token);
                } catch (e) {
                    console.log('Cannot decode token');
                    return null;
                }
            },
            [logout]: () => null
        }],
        [appPropsConstants.hasSystemError]: [false, PropTypes.bool, {
            [actions.login]: () => false,
            [actions.forgotPassword]: () => false,
            [actions.setToken]: () => false,
            [actions.registerThroughAnInvitation]: () => false,
            [actions.setSystemError]: () => true,
            [actions.setCredentialsError]: () => false,
            [logout]: () => false
        }],
        [appPropsConstants.hasCredentialsError]: [false, PropTypes.bool, {
            [actions.login]: () => false,
            [actions.setToken]: () => false,
            [actions.setSystemError]: () => false,
            [actions.setCredentialsError]: () => true,
            [logout]: () => false
        }],
        [appPropsConstants.hasPasswordUpdateError]: [false, PropTypes.bool, {
            [actions.login]: () => false,
            [actions.passwordUpdateSuccessful]: () => false,
            [actions.setPasswordUpdateError]: () => true,
            [logout]: () => false
        }],
        [appPropsConstants.notAuthorized]: [false, PropTypes.bool, {
            [actions.clearNotAuthorized]: () => false,
            [notAuthorized]: () => true,
            [logout]: () => false,
        }],
        [appPropsConstants.serviceUnavailable]: [false, PropTypes.bool, {
            [actions.clearServiceUnavailable]: () => false,
            [serviceUnavailable]: () => true,
            [logout]: () => false,
        }]
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.login]: workers.fetchLoginRequest,
        [actions.changePassword]: workers.fetchChangePasswordRequest,
        [actions.forgotPassword]: workers.fetchForgotPasswordRequest,
        [actions.setPasswordFirstAccess]: workers.fetchSetFirstAccessRequest,
        [actions.registerThroughAnInvitation]: workers.fetchRegisterRequest,
        [logout]: workers.fetchLogoutRequest
    }),

    workers: {
        * fetchLoginRequest(action: LoginRequest): any {
            //@ts-ignore
            const { setSystemError, setToken, setCredentialsError } = this.actionCreators;
            const { username, password } = action.payload;

            if (!username || !password) {
                yield put(setCredentialsError());
            } else {

                const { response }: LogonRaceResponse = yield race({
                    response: call(login, username, password),
                    timeout: delay(requestTimeout)
                });

                if (!!response.body?.accessToken) {
                    yield put(setToken(response.body.accessToken));
                } else if (response.status === notFoundCode) {
                    yield put(setCredentialsError());
                } else {
                    // Should I set to service unavailable?
                    yield put(setSystemError());
                }
            }
        },
        * fetchChangePasswordRequest(action: ChangePasswordRequest): any {
            //@ts-ignore
            const { passwordUpdateSuccessful, setPasswordUpdateError } = this.actionCreators;
            const { token, newPassword } = action.payload;

            const { response }: ChangePasswordRaceResponse = yield race({
                response: call(changePassword, token, newPassword),
                timeout: delay(requestTimeout)
            });

            if (response.status === successfulCode) {
                yield put(passwordUpdateSuccessful());
            } else {
                yield put(setPasswordUpdateError());
            }
        },
        * fetchForgotPasswordRequest(action: ForgotPasswordRequest): any {
            //@ts-ignore
            const { forgotPasswordRequestFinished, setSystemError } = this.actionCreators;
            const { username } = action.payload;

            const { response }: ForgotPasswordRaceResponse = yield race({
                response: call(forgotPassword, username),
                timeout: delay(requestTimeout)
            });

            if (response.status === successfulCode) {
                yield put(forgotPasswordRequestFinished());
            } else {
                // Should I set to service unavailable?
                yield put(setSystemError());
            }
        },
        * fetchLogoutRequest(): any {
            //@ts-ignore
            const { setToken } = this.actionCreators;
            yield put(setToken(''))
        },
        * fetchSetFirstAccessRequest(action: ChangePasswordRequest): any {
            //@ts-ignore
            const { setPasswordUpdateError, setToken } = this.actionCreators;
            const { token, newPassword } = action.payload;

            const { response }: FirstAccessChangePasswordRaceResponse = yield race({
                response: call(changePasswordFirstAccess, token, newPassword),
                timeout: delay(requestTimeout)
            });

            if (!!response.body?.accessToken) {
                yield put(setToken(response.body.accessToken));
                // yield put(passwordUpdateSuccessful());
            } else {
                yield put(setPasswordUpdateError());
            }
        },
        * fetchRegisterRequest(action: RegisterRequest): any {
            //@ts-ignore
            const { setSystemError, setToken } = this.actionCreators;
            const { name, email, password, subprogramId, language } = action.payload;

            const { response }: RegisterRaceResponse = yield race({
                response: call(registerThroughAnInvitation, name, email, password, subprogramId, language),
                timeout: delay(requestTimeout)
            });

            if (!!response.body?.accessToken) {
                yield put(setToken(response.body.accessToken));
            } else {
                // TODO(rodrigo.santos): Handle different errors coming from the backend.
                yield put(setSystemError());
            }
        }
    }
})
