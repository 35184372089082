import React from 'react';
import { Image } from 'react-bootstrap';

import usersImage from '../../../assets/images/users.png';
import { H2 } from '../../../components/styled/h2';
import { H5 } from '../../../components/styled/h5';
import { PageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    title: keyof(PageMessages),
    subTitle: keyof(PageMessages),
}

export const NoUserFound = ({ title, subTitle }: Props):JSX.Element => {
 return (
    <div style={styles.container}>
        <div style={styles.image}>
            <Image src={usersImage} fluid />
        </div>
        <div style={styles.textContainer}>
            <H2> {GetFormattedMessage(title)} </H2>
            <H5> {GetFormattedMessage(subTitle)} </H5>
        </div>
    </div>
 );
}

const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%'
    } as React.CSSProperties,
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
    } as React.CSSProperties,
    image: {
        marginRight: 20,
        width: '30%',
    } as React.CSSProperties,
}
