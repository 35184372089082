import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SelectItem } from '../../components/FormDropdown';
import { Loading } from '../../components/Loading';
import {
    FinancialReportActions, financialReportLogic, FinancialReportProps
} from '../../redux/financialReportLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import { OrganizationDetails } from '../../services/organizationService';
import { formatDate } from '../../utils/dateHelper';
import { FilterFinancialReport } from './components/FilterFinancialReport';
import { FinancialReport } from './FinancialReport';

type Props = RouteComponentProps;

export const FinancialReportContainer = withRouter((props: Props): JSX.Element => {
    const { getOrganizations }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { organizations, loadingPage }: ListOrganizationProps = useValues(listOrganizationLogic);

    const { loadFinancialReport }: FinancialReportActions = useActions(financialReportLogic);
    const { financialReport, loading, errorKey }: FinancialReportProps = useValues(financialReportLogic);

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [organizationId] = useState<number>()

    useEffect(() => {
        const currentEndDate = new Date();
        const currentStartDate = new Date(currentEndDate.getFullYear() - 1, 0, 1); // Jan 1st from last year.

        setStartDate(currentStartDate);
        setEndDate(currentEndDate);
        if (!organizations?.length)
            getOrganizations && getOrganizations();
        // eslint-disable-next-line
    }, []);

    const onSearchClick = (organizationId: number, startDateParam: string, endDateParam: string) => {
        const startDate = new Date(startDateParam);
        const endDate = new Date(endDateParam);
        setStartDate(startDate);
        setEndDate(endDate);
        loadFinancialReport(organizationId, startDate, endDate);
    };

    if (loadingPage) return <Loading />

    const organizationSelectOptions: Array<SelectItem> =
        organizations.map((item: OrganizationDetails) => ({ value: item.id, label: item.name }));

    return (
        <React.Fragment>
            <FilterFinancialReport
                organizationOptions={organizationSelectOptions}
                organizationId={organizationId}
                startDate={formatDate(startDate)}
                endDate={formatDate(endDate)}
                searchHandleClick={onSearchClick}
            />
            {loading ?
                <Loading />
                :
                <FinancialReport summary={financialReport} errorKey={errorKey} />
            }
        </React.Fragment>
    )
});
