import React, { useCallback, useMemo } from 'react';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { Table, Tag } from 'antd';
import { colors } from '../../config/colors';
import { ColumnsType } from 'antd/es/table';
import { formatDate } from '../../utils/dateHelper';
import { EditOutlined } from '@ant-design/icons';
import { ReportPermissionResponse } from '../../services/reportService';

type Props = {
    reportPermissions: ReportPermissionResponse[],
    loadingReportPermission: boolean,
    setSelectReportPermission: (data: ReportPermissionResponse) => void
}

export const ReportPermission = (
    {
        reportPermissions,
        loadingReportPermission,
        setSelectReportPermission
    }: Props
) => {
    const Tags = useCallback((status: number) => {
        switch (status) {
            case 1:
                return <Tag>Pending</Tag>
            case 2:
                return <Tag color="green">Approved</Tag>
            case 3:
                return <Tag color="red">Rejected</Tag>
        }
    }, [])

    const columns = useMemo(() => {
        return [{
            dataIndex: ['report', 'name'],
            key: 'reportName',
            title: GetFormattedMessage('app.create_report.name')
        }, {
            dataIndex: 'description',
            key: 'reportName',
            title: GetFormattedMessage('app.permission.table_title.permission')
        }, {
            dataIndex: 'creationDate',
            key: 'creationDate',
            title: 'Date',
            render: (value) => {
                return formatDate(new Date(value));
            }
        }, {
            dataIndex: 'status',
            key: 'status',
            title: GetFormattedMessage('app.listforms.status'),
            render: (value) => {
                return Tags(value);
            }
        }, {
            dataIndex: ['requester', 'name'],
            key: 'requester',
            title: GetFormattedMessage('app.create_report.requester'),
        }, {
            dataIndex: 'actions',
            key: 'actions',
            title: GetFormattedMessage('app.listorganizations.table.actions'),
            align: 'center',
            render: (value, row) => {
                    return <>
                        <EditOutlined onClick={() => setSelectReportPermission(row)} />
                    </>;
            }
        }] as ColumnsType<any>
    }, [Tags])

    return (<>
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {GetFormattedMessage('app.internaltemplate.menu.report_permission')}
                    </h2>
                </div>
                <hr />
                <Table
                    loading={loadingReportPermission}
                    dataSource={reportPermissions}
                    rowKey={(record: any) => record.id.toString()}
                    locale={{ emptyText: GetFormattedMessage('app.empty.data') }}
                    columns={columns}
                />
            </div>
        </div>
    </>);
}


const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'left',
        paddingRight: '20px',
        paddingLeft: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
    colFlex: {
        display: 'flex',
        gap: 12
    } as React.CSSProperties,
};
