import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    ComparativeIndividualReportModel, FormModel, getComparativeIndividualReport, GetComparativeIndividualReportResponse, getFindValidIndividualForms,
    getReportPermission,
    getReportPermissionAmout,
    GetValidIndividualFormsResponse,
    PostSaveIndividualFormsResponse,
    postSaveIndividualReport,
    ReportPermissionResponse,
    SaveIndividualAndCombinedReportRequest,
    SaveIndividualReportResponse,
    updateReportPermission,
    UpdateReportPermissionResponse
} from '../services/reportService';
import { IndividualReportModel } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['loadComparativeIndividualReport', 'findValidIndividualForms', 'saveIndividualReport', 'clearReportResponse',
    'clearErrorValidForm', 'getReportPermissionAmount', 'getReportPermission', 'updateReportPermission', 'clearUpdateReportPermission']);
export type ComparativeIndividualReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const comparativeIndividualReportConstants: ComparativeIndividualReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReport', 'setErrorFindValidForm', 'setValidForm', 'setErrorSave', 'setReportId', 'setReportPermissionAmount',
    'setReportPermission', 'setReportPermissionSuccess'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'reports', 'loadingValidForm', 'errorValidForm', 'validForms',
    'saveReportResponse', 'loadingSave', 'errorSave', 'loadingReportPermissionAmount', 'loadingReportPermission',
    'loadingUpdateReportPermission', 'reportPermissionAmount', 'reportPermissions', 'reportPermissionSuccess']);
export type ComparativeIndividualReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const comparativeIndividualReportPropsConstants: ComparativeIndividualReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ComparativeIndividualReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        fetchComparativeIndividualReport: () => void;
        findValidIndividualForms: () => void;
        saveIndividualReport: () => void;
        clearReportResponse: () => void;
        clearErrorValidForm: () => void;
        getReportPermissionAmount: () => void;
        getReportPermission: () => void;
        updateReportPermission: () => void;
        clearUpdateReportPermission: () => void;
    },
};

// Define payloads type.
type ErrorPayload = { error: string; };
type ComparativeIndividualReportPayload = { body: ComparativeIndividualReportModel };
//todo
type ReportPayload = { data: IndividualReportModel };
type ComparativeIndividualReportRequest = { payload: ComparativeIndividualReportPayload };
type ComparativeReportByIndividualReportRaceResponse = {
    comparativeIndividualReportResponse?: GetComparativeIndividualReportResponse;
}

type ValidFormPayload = { email: string };
type IndividualValidFormPayload = { data: FormModel };
type ValidFormReportRequest = { payload: ValidFormPayload };
type ValidFormRaceResponse = {
    validFormResponse?: GetValidIndividualFormsResponse;
}

type ReportIdPayload = { data: number };
type SavePayload = { data: SaveIndividualAndCombinedReportRequest };
type SaveFormPayload = { data: SaveIndividualReportResponse };
type SaveRequest = { payload: SavePayload };
type SaveRaceResponse = {
    formResponse?: PostSaveIndividualFormsResponse;
}

type UpdateReportPermissionPayload = { body: UpdateReportPermissionResponse };
type UpdateReportPermissionRequest = { payload: UpdateReportPermissionPayload };

type ReportPermissionPayload = { data: ReportPermissionResponse[] };

type ReportPermissionAmountPayload = { data: number };

type ReportPermissionSuccessPayload = { data: boolean };

// persist app reducer
export const comparativeIndividualReportPath = 'comparativeIndividualReport';
export const comparativeIndividualReportReducer = keaReducer(comparativeIndividualReportPath);

export const comparativeIndividualReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [comparativeIndividualReportPath],

    actions: () => ({
        [comparativeIndividualReportConstants.loadComparativeIndividualReport]:
            (body: ComparativeIndividualReportModel): ComparativeIndividualReportPayload => ({ body }),
        [privateActionsConstants.setReport]:
            (data: IndividualReportModel): ReportPayload => ({ data }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [comparativeIndividualReportConstants.findValidIndividualForms]:
            (email: string): ValidFormPayload => ({ email }),
        [privateActionsConstants.setValidForm]:
            (data: FormModel): IndividualValidFormPayload => ({ data }),
        [privateActionsConstants.setErrorFindValidForm]:
            (error: string): ErrorPayload => ({ error }),
        [comparativeIndividualReportConstants.saveIndividualReport]:
            (data: SaveIndividualAndCombinedReportRequest): SavePayload => ({ data }),
        [privateActionsConstants.setReportId]:
            (data: SaveIndividualReportResponse): SaveFormPayload => ({ data }),
        [privateActionsConstants.setErrorSave]:
            (error: string): ErrorPayload => ({ error }),
        [comparativeIndividualReportConstants.clearReportResponse]:
            () => { },
        [comparativeIndividualReportConstants.clearErrorValidForm]:
            () => { },
        [privateActionsConstants.setReportPermission]:
            (data: ReportPermissionResponse[]): ReportPermissionPayload => ({ data }),
        [privateActionsConstants.setReportPermissionAmount]:
            (data: number): ReportPermissionAmountPayload => ({ data }),
        [privateActionsConstants.setReportPermissionSuccess]:
            (data: boolean): ReportPermissionSuccessPayload => ({ data }),
        [comparativeIndividualReportConstants.getReportPermission]:
            () => { },
        [comparativeIndividualReportConstants.getReportPermissionAmount]:
            () => { },
        [comparativeIndividualReportConstants.updateReportPermission]:
            (body: UpdateReportPermissionResponse): UpdateReportPermissionPayload => ({ body }),
        [comparativeIndividualReportConstants.clearUpdateReportPermission]:
            () => { },
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [comparativeIndividualReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.loadComparativeIndividualReport]: () => true,
            [actions.setError]: () => false,
            [actions.setReport]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.loadComparativeIndividualReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.reports]: [null, PropTypes.any, {
            [actions.setReport]: (_: IndividualReportModel, payload: ReportPayload) => payload.data,
            [actions.loadComparativeIndividualReport]: () => null,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.loadingValidForm]: [false, PropTypes.bool, {
            [actions.findValidIndividualForms]: () => true,
            [actions.setErrorFindValidForm]: () => false,
            [actions.setValidForm]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.errorValidForm]: [null, PropTypes.string, {
            [actions.findValidIndividualForms]: () => null,
            [actions.setErrorFindValidForm]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.clearErrorValidForm]: () => null,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.validForms]: [null, PropTypes.any, {
            [actions.setValidForm]: (_: IndividualReportModel, payload: ReportPayload) => payload.data,
            [actions.findValidIndividualForms]: () => null,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.loadingSave]: [false, PropTypes.bool, {
            [actions.saveIndividualReport]: () => true,
            [actions.setErrorSave]: () => false,
            [actions.setReportId]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.errorSave]: [null, PropTypes.string, {
            [actions.saveIndividualReport]: () => null,
            [actions.setErrorSave]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.saveReportResponse]: [null, PropTypes.any, {
            [actions.setReportId]: (_: any, payload: ReportIdPayload) => payload.data,
            [actions.saveIndividualReport]: () => null,
            [actions.clearReportResponse]: () => null,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.loadingReportPermission]: [false, PropTypes.bool, {
            [actions.getReportPermission]: () => true,
            [actions.setReportPermission]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.reportPermissions]: [null, PropTypes.any, {
            [actions.setReportPermission]: (_: any, payload: ReportPermissionPayload) => payload.data,
            [actions.getReportPermission]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [comparativeIndividualReportPropsConstants.loadingReportPermissionAmount]: [false, PropTypes.bool, {
            [actions.getReportPermissionAmount]: () => true,
            [actions.setReportPermissionAmount]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.reportPermissionAmount]: [0, PropTypes.number, {
            [actions.setReportPermissionAmount]: (_: any, payload: ReportPermissionAmountPayload) => payload.data,
            [actions.getReportPermission]: () => 0,
            [actions.setError]: () => 0,
            [logout]: () => 0
        }],
        [comparativeIndividualReportPropsConstants.loadingUpdateReportPermission]: [false, PropTypes.bool, {
            [actions.updateReportPermission]: () => true,
            [actions.setReportPermissionSuccess]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [comparativeIndividualReportPropsConstants.reportPermissionSuccess]: [false, PropTypes.bool, {
            [actions.setReportPermissionSuccess]: () => true,
            [actions.updateReportPermission]: () => false,
            [actions.setError]: () => false,
            [actions.clearUpdateReportPermission]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadComparativeIndividualReport]: workers.fetchComparativeIndividualReport,
        [actions.findValidIndividualForms]: workers.findValidIndividualForms,
        [actions.saveIndividualReport]: workers.saveIndividualReport,
        [actions.getReportPermission]: workers.getReportPermission,
        [actions.getReportPermissionAmount]: workers.getReportPermissionAmount,
        [actions.updateReportPermission]: workers.updateReportPermission,
    }),

    workers: {
        * fetchComparativeIndividualReport(action: ComparativeIndividualReportRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { body } = action.payload;
            //@ts-ignore
            const { setError, setReport } = this.actionCreators;
            const { comparativeIndividualReportResponse }: ComparativeReportByIndividualReportRaceResponse = yield race({
                comparativeIndividualReportResponse: call(getComparativeIndividualReport, token, body),
                timeout: delay(requestTimeout)
            });

            if (comparativeIndividualReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (comparativeIndividualReportResponse?.status === successfulCode) {
                yield put(setReport(comparativeIndividualReportResponse?.body))
            } else if (!!comparativeIndividualReportResponse?.keyErrorMessage) {
                yield put(setError(comparativeIndividualReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        *findValidIndividualForms(action: ValidFormReportRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { email } = action.payload;
            //@ts-ignore
            const { setErrorFindValidForm, setValidForm } = this.actionCreators;
            const { validFormResponse }: ValidFormRaceResponse = yield race({
                validFormResponse: call(getFindValidIndividualForms, token, email),
                timeout: delay(requestTimeout)
            });

            if (validFormResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (validFormResponse?.status === successfulCode) {
                yield put(setValidForm(validFormResponse?.body))
            } else if (!!validFormResponse?.keyErrorMessage) {
                yield put(setErrorFindValidForm(validFormResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        *saveIndividualReport(action: SaveRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { data } = action.payload;
            //@ts-ignore
            const { setErrorSave, setReportId } = this.actionCreators;
            const { formResponse }: SaveRaceResponse = yield race({
                formResponse: call(postSaveIndividualReport, token, data),
                timeout: delay(requestTimeout)
            });

            if (formResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (formResponse?.status === successfulCode) {
                yield put(setReportId(formResponse?.body))
            } else if (!!formResponse?.keyErrorMessage) {
                yield put(setErrorSave(formResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        *getReportPermission(): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const { setError, setReportPermission } = this.actionCreators;
            const { response } = yield race({
                response: call(getReportPermission, token),
                timeout: delay(requestTimeout)
            });

            if (response?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (response?.status === successfulCode) {
                yield put(setReportPermission(response?.body))
            } else if (!!response?.keyErrorMessage) {
                yield put(setError(response.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        *getReportPermissionAmount(): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const { setError, setReportPermissionAmount } = this.actionCreators;
            const { response } = yield race({
                response: call(getReportPermissionAmout, token),
                timeout: delay(requestTimeout)
            });

            if (response?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (response?.status === successfulCode) {
                yield put(setReportPermissionAmount(response?.body))
            } else if (!!response?.keyErrorMessage) {
                yield put(setError(response.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        *updateReportPermission(action: UpdateReportPermissionRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }
            const { body } = action.payload;

            //@ts-ignore
            const { setError, setReportPermissionSuccess } = this.actionCreators;
            const { response } = yield race({
                response: call(updateReportPermission, token, body),
                timeout: delay(requestTimeout)
            });

            if (response?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (response?.status === successfulCode) {
                yield put(setReportPermissionSuccess(true))
            } else if (!!response?.keyErrorMessage) {
                yield put(setError(response.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
});
