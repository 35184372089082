import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { colors } from '../../config/colors';
import { TokenInfo } from '../../services/loginService';
import { DemographicsQuestionsConfigurationModel } from '../../services/organizationService';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
  userInfo: TokenInfo;
  demographicsQuestionsData: DemographicsQuestionsConfigurationModel;
  loadingButton: boolean;
  success: boolean;
  onHideDemographicsQuestionsValuesUpdated: (newValue: Partial<DemographicsQuestionsConfigurationModel>) => void;
  onSaveButtonClicked: () => void;
  onSuccessfullSaveHideDemographicsQuestions: () => void;
}

export const HideDemographicsQuestions = ({ loadingButton, success, demographicsQuestionsData, onHideDemographicsQuestionsValuesUpdated, onSaveButtonClicked, onSuccessfullSaveHideDemographicsQuestions }: Props): JSX.Element => {

    const [age, setAge] = useState<boolean>(demographicsQuestionsData.age);
    const [gender, setGender] = useState<boolean>(demographicsQuestionsData.gender);
    const [completedYearOfEducation, setCompletedYearOfEducation] = useState<boolean>(demographicsQuestionsData.completedYearOfEducation);
    const [maritalStatus, setMaritalStatus] = useState<boolean>(demographicsQuestionsData.maritalStatus);
    const [ethnicBackground, setEthnicBackground] = useState<boolean|undefined>(demographicsQuestionsData.ethnicBackground);
    const [motherCountry, setMotherCountry] = useState<boolean|undefined>(demographicsQuestionsData.motherCountry);
    const [fatherCountry, setFatherCountry] = useState<boolean|undefined>(demographicsQuestionsData.fatherCountry);
    const [memberOfDisadvantagedOrMinorityGroup, setMemberOfDisadvantagedOrMinorityGroup] = useState<boolean|undefined>(demographicsQuestionsData.memberOfDisadvantagedOrMinorityGroup);
    const [primaryCountryOfOrigin, setPrimaryCountryOfOrigin] = useState<boolean|undefined>(demographicsQuestionsData.primaryCountryOfOrigin);
    const [timeInOtherCountries, setTimeInOtherCountry] = useState<boolean|undefined>(demographicsQuestionsData.timeInOtherCountry);
    const [visitedOrLivedCountries, setVisitedOrLivedCountries] = useState<boolean|undefined>(demographicsQuestionsData.visitedOrLivedCountries);
    const [politicalOrientation, setPoliticalOrientation] = useState<boolean|undefined>(demographicsQuestionsData.politicalOrientation);
    const [politicalCommitment, setPoliticalCommitment] = useState<boolean|undefined>(demographicsQuestionsData.politicalCommitment);
    const [motherFormalEducation, setMotherFormalEducation] = useState<boolean|undefined>(demographicsQuestionsData.motherFormalEducation);
    const [fatherFormalEducation, setFatherFormalEducation] = useState<boolean|undefined>(demographicsQuestionsData.fatherFormalEducation);
    const [primaryAreaOrWorkOrProfessionalInterest, setPrimaryAreaOrWorkOrProfessionalInterest] = useState<boolean|undefined>(demographicsQuestionsData.primaryAreaOrWorkOrProfessionalInterest);
    const [primaryLanguages, setPrimaryLanguages] = useState<boolean|undefined>(demographicsQuestionsData.primaryLanguages);
    const [totalYearsStudiedForeignLanguage, setTotalYearsStudiedForeignLanguage] = useState<boolean|undefined>(demographicsQuestionsData.totalYearsStudiedForeignLanguage);
    const [incomeLevelOfHomeCountry, setIncomeLevelOfHomeCountry] = useState<boolean|undefined>(demographicsQuestionsData.incomeLevelOfHomeCountry);
    const [academicStandingOrRank, setAcademicStandingOrRank] = useState<boolean|undefined>(demographicsQuestionsData.academicStandingOrRank);
    const [currentBeliefsOrCommitmentsRegardingReligion, setCurrentBeliefsOrCommitmentsRegardingReligion] = useState<boolean|undefined>(demographicsQuestionsData.currentBeliefsOrCommitmentsRegardingReligion);
    const [commitmentTowardFaithReligions, setCommitmentTowardFaithReligions] = useState<boolean|undefined>(demographicsQuestionsData.commitmentTowardFaithReligions);
    const [interestInInternationalOrInterculturalLearning, setInterestInInternationalOrInterculturalLearning] = useState<boolean|undefined>(demographicsQuestionsData.interestInInternationalOrInterculturalLearning);
    const [satisfactionLevelInternationalExperienceProgram, setSatisfactionLevelInternationalExperienceProgram] = useState<boolean|undefined>(demographicsQuestionsData.satisfactionLevelInternationalExperienceProgram);
    const [participatedExperiencesCapabilities, setParticipatedExperiencesCapabilities] = useState<boolean|undefined>(demographicsQuestionsData.participatedExperiencesCapabilities);
    const [plansToParticipateExperiencesCapabilities, setPlansToParticipateExperiencesCapabilities] = useState<boolean|undefined>(demographicsQuestionsData.plansToParticipateExperiencesCapabilities);
    const [participatedLearningCoursesOrProgramsCapabilities, setParticipatedLearningCoursesOrProgramsCapabilities] = useState<boolean|undefined>(demographicsQuestionsData.participatedLearningCoursesOrProgramsCapabilities );
    const [plansToParticipateLearningCoursesOrProgramsCapabilities, setplansToParticipateLearningCoursesOrProgramsCapabilities] = useState<boolean|undefined>(demographicsQuestionsData.plansToParticipateLearningCoursesOrProgramsCapabilities);

    const [alert, setAlert] = useState<boolean>(false);

    useEffect(() => {
        if(success) {
          setAlert(success)
        }
    }, [success])

    return (
        <div>
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <div style={styles.h2ProgramTitle}>
                        <h2>
                            {GetFormattedMessage('app.institutions.add.hide_demographics_questions')}
                        </h2>
                        <h6>
                            {GetFormattedMessage('app.institutions.add.hide_demographics_questions')}
                        </h6>
                    </div>
                </div>
            </div>
            {alert === true &&
                <Alert variant="primary"
                    onClose={() => {
                        setAlert(false)
                        onSuccessfullSaveHideDemographicsQuestions()
                    }}
                    dismissible>
                    <p dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.institutions.hide_demographics_questions.successful_message') }} />
                </Alert>
            }
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <Form>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsAge'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.age')}
                                checked={age}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ age: !age });
                                    setAge(!age);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsGender'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.gender')}
                                checked={gender}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ gender: !gender });
                                    setGender(!gender);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsCompletedYearOfEducation'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.education_years')}
                                checked={completedYearOfEducation}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ completedYearOfEducation: !completedYearOfEducation });
                                    setCompletedYearOfEducation(!completedYearOfEducation);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsMaritalStatus'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.marital_status')}
                                checked={maritalStatus}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ maritalStatus: !maritalStatus });
                                    setMaritalStatus(!maritalStatus);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsEthnicBackground'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.ethnic')}
                                checked={ethnicBackground}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ ethnicBackground: !ethnicBackground });
                                    setEthnicBackground(!ethnicBackground);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsMotherCountry'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.country_mother_born')}
                                checked={motherCountry}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ motherCountry: !motherCountry });
                                    setMotherCountry(!motherCountry);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsFatherCountry'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.country_father_born')}
                                checked={fatherCountry}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ fatherCountry: !fatherCountry });
                                    setFatherCountry(!fatherCountry);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsMemberOfDisadvantagedOrMinorityGroup'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.disadvantaged_or_minority_group')}
                                checked={memberOfDisadvantagedOrMinorityGroup}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ memberOfDisadvantagedOrMinorityGroup: !memberOfDisadvantagedOrMinorityGroup });
                                    setMemberOfDisadvantagedOrMinorityGroup(!memberOfDisadvantagedOrMinorityGroup);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPrimaryCountryOfOrigin'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.primary_country')}
                                checked={primaryCountryOfOrigin}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ primaryCountryOfOrigin: !primaryCountryOfOrigin });
                                    setPrimaryCountryOfOrigin(!primaryCountryOfOrigin);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsTimeInOtherCountry'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.time_in_other_countries')}
                                checked={timeInOtherCountries}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ timeInOtherCountry: !timeInOtherCountries });
                                    setTimeInOtherCountry(!timeInOtherCountries);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsVisitedOrLivedCountries'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.visit_countries')}
                                checked={visitedOrLivedCountries}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ visitedOrLivedCountries: !visitedOrLivedCountries });
                                    setVisitedOrLivedCountries(!visitedOrLivedCountries);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPoliticalOrientation'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.political_orientation')}
                                checked={politicalOrientation}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ politicalOrientation: !politicalOrientation });
                                    setPoliticalOrientation(!politicalOrientation);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPoliticalCommitment'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.political_commitment')}
                                checked={politicalCommitment}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ politicalCommitment: !politicalCommitment });
                                    setPoliticalCommitment(!politicalCommitment);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsMotherFormalEducation'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.mother_formal_education')}
                                checked={motherFormalEducation}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ motherFormalEducation: !motherFormalEducation });
                                    setMotherFormalEducation(!motherFormalEducation);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsFatherFormalEducation'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.father_formal_education')}
                                checked={fatherFormalEducation}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ fatherFormalEducation: !fatherFormalEducation });
                                    setFatherFormalEducation(!fatherFormalEducation);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPrimaryAreaOrWorkOrProfessionalInterest'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.primary_area_of_work')}
                                checked={primaryAreaOrWorkOrProfessionalInterest}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ primaryAreaOrWorkOrProfessionalInterest: !primaryAreaOrWorkOrProfessionalInterest });
                                    setPrimaryAreaOrWorkOrProfessionalInterest(!primaryAreaOrWorkOrProfessionalInterest);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPrimaryLanguages'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.primary_language')}
                                checked={primaryLanguages}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ primaryLanguages: !primaryLanguages });
                                    setPrimaryLanguages(!primaryLanguages);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsTotalYearsStudiedForeignLanguage'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.years_studied_foreign_language')}
                                checked={totalYearsStudiedForeignLanguage}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ totalYearsStudiedForeignLanguage: !totalYearsStudiedForeignLanguage });
                                    setTotalYearsStudiedForeignLanguage(!totalYearsStudiedForeignLanguage);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsIncomeLevelOfHomeCountry'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.income_level')}
                                checked={incomeLevelOfHomeCountry}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ incomeLevelOfHomeCountry: !incomeLevelOfHomeCountry });
                                    setIncomeLevelOfHomeCountry(!incomeLevelOfHomeCountry);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsAcademicStandingOrRank'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.academic_rank')}
                                checked={academicStandingOrRank}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ academicStandingOrRank: !academicStandingOrRank });
                                    setAcademicStandingOrRank(!academicStandingOrRank);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsCurrentBeliefsOrCommitmentsRegardingReligion'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.religion')}
                                checked={currentBeliefsOrCommitmentsRegardingReligion}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ currentBeliefsOrCommitmentsRegardingReligion: !currentBeliefsOrCommitmentsRegardingReligion });
                                    setCurrentBeliefsOrCommitmentsRegardingReligion(!currentBeliefsOrCommitmentsRegardingReligion);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsCommitmentTowardFaithReligions'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.religion_scale')}
                                checked={commitmentTowardFaithReligions}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ commitmentTowardFaithReligions: !commitmentTowardFaithReligions });
                                    setCommitmentTowardFaithReligions(!commitmentTowardFaithReligions);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsInterestInInternationalOrInterculturalLearning'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.personal_interest')}
                                checked={interestInInternationalOrInterculturalLearning}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ interestInInternationalOrInterculturalLearning: !interestInInternationalOrInterculturalLearning });
                                    setInterestInInternationalOrInterculturalLearning(!interestInInternationalOrInterculturalLearning);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsSatisfactionLevelInternationalExperienceProgram'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.experience_satisfaction')}
                                checked={satisfactionLevelInternationalExperienceProgram}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ satisfactionLevelInternationalExperienceProgram: !satisfactionLevelInternationalExperienceProgram });
                                    setSatisfactionLevelInternationalExperienceProgram(!satisfactionLevelInternationalExperienceProgram);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsParticipatedExperiencesCapabilities'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.participated_experiences')}
                                checked={participatedExperiencesCapabilities}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ participatedExperiencesCapabilities: !participatedExperiencesCapabilities });
                                    setParticipatedExperiencesCapabilities(!participatedExperiencesCapabilities);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPlansToParticipateExperiencesCapabilities'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.plan_participate_experiences')}
                                checked={plansToParticipateExperiencesCapabilities}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ plansToParticipateExperiencesCapabilities: !plansToParticipateExperiencesCapabilities });
                                    setPlansToParticipateExperiencesCapabilities(!plansToParticipateExperiencesCapabilities);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsParticipatedLearningCoursesOrProgramsCapabilities'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.participated_learning_courses')}
                                checked={participatedLearningCoursesOrProgramsCapabilities}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ participatedLearningCoursesOrProgramsCapabilities: !participatedLearningCoursesOrProgramsCapabilities });
                                    setParticipatedLearningCoursesOrProgramsCapabilities(!participatedLearningCoursesOrProgramsCapabilities);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formDemographicsQuestionsPlansToParticipateLearningCoursesOrProgramsCapabilities'>
                            <Form.Check
                                type='checkbox'
                                label={GetFormattedMessage('app.demographic.plan_participate_learning_courses')}
                                checked={plansToParticipateLearningCoursesOrProgramsCapabilities}
                                onChange={() => {
                                    onHideDemographicsQuestionsValuesUpdated({ plansToParticipateLearningCoursesOrProgramsCapabilities: !plansToParticipateLearningCoursesOrProgramsCapabilities });
                                    setplansToParticipateLearningCoursesOrProgramsCapabilities(!plansToParticipateLearningCoursesOrProgramsCapabilities);
                                }}
                            />
                        </Form.Group>
                    </Form>
                    <div style={styles.buttonContainer} className='button'>
                        <Button
                            style={styles.btnBack}
                            variant='primary'
                            onClick={() => onSaveButtonClicked()}
                            disabled={loadingButton}
                        >
                            {loadingButton &&
                                <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                    style={{ marginRight: 10 }}
                                />
                            }
                            {GetFormattedMessage('app.save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

};

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    btnBack: {
        width: '150px',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 32
    } as React.CSSProperties,
};