import { SelectItem } from '../../../components/FormDropdown';
import { PageMessages } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import {
    FormalEducationOptions,
    GetCountriesOptions, GetEthnicOrientationOptions, GetGenderOptions,
    GetIncomeLevelOfYourHomeCountryOptions, GetLevelOfInterestOptions,
    GetMaritialStatusOptions, GetMinorityGroupFilterOptions, GetPoliticalOrientationOptions, GetPrimaryWorkAreaOptions,
    GetReligiousOrientationOptions, GetTimeSpentInOtherCountriesOptions, GetYearOfEducationOptions, ParticipatedExperiencesOptions, ParticipatedLearningCoursesOptions, PlanParticipateExperiencesOptions, PlanParticipateLearningCoursesOptions
} from '../../../utils/demographHelper';
import { convertFormattedMessagesToSelectItem } from '../../../utils/htmlHelper';

type ConditionalOptions = number[];

type ConditionalProps = {
    labelId: keyof (PageMessages);
    questionKeyName: keyof (DemographicQuestionAnswers);
    coditional: ConditionalOptions;
};

export function isConditionApproved(options: ConditionalOptions, selected?: number): boolean {
    if (selected === undefined) return false;

    return options.find((option: number) => option === selected) === selected;
}

abstract class Question {
    abstract GetQuestionLabelId(): keyof (PageMessages);
    abstract GetConditonalProps(): ConditionalProps | null;
    abstract GetQuestionKeyName(): keyof (DemographicQuestionAnswers);
    abstract GetQuestionExplnationLabelId(): keyof (PageMessages) | null;
};

export abstract class SelectQuestion extends Question {
    abstract GetDefintions(): SelectItem[];
};

export abstract class YesOrNoSelectQuestion extends SelectQuestion {
    public static readonly kNoValue = 0;
    public static readonly kYesValue = 1;

    GetDefintions(): SelectItem[] {
        return (
            convertFormattedMessagesToSelectItem([
                { key: 'app.response_no', value: YesOrNoSelectQuestion.kNoValue },
                { key: 'app.response_yes', value: YesOrNoSelectQuestion.kYesValue },
            ])
        );
    }

    GetQuestionExplnationLabelId(): keyof (PageMessages) | null {
        return null;
    }
};

export abstract class CountrySelectQuestion extends SelectQuestion {
    GetDefintions(): SelectItem[] { return GetCountriesOptions(); }
    GetQuestionExplnationLabelId(): keyof (PageMessages) { return 'app.demographic.country_explanation_field' }
}

export abstract class NumericQuestion extends Question {
    abstract GetMaxValue(): number;
};

// Previous Question 3
export class Age extends NumericQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'Age' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.age'; }
    GetMaxValue(): number { return 125; }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 4
export class Gender extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'Gender' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.gender' }
    GetDefintions(): SelectItem[] { return GetGenderOptions(); }
    GetConditonalProps(): ConditionalProps | null {
        return {
            labelId: 'app.demographic.disadvantaged_or_minority_group_yes',
            coditional: [3],
            questionKeyName: 'GenderSelfIdentity'
        };
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 4 (other)
export class CompletedYearOfEducation extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'CompletedYearOfEducation' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.education_years'; }
    GetDefintions(): SelectItem[] { return GetYearOfEducationOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 6
export class MaritalStatus extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'MaritalStatus' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.marital_status'; }
    GetDefintions(): SelectItem[] { return GetMaritialStatusOptions() }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 7
export class EthnicBackground extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'EthnicBackground' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.ethnic'; }
    GetDefintions(): SelectItem[] { return GetEthnicOrientationOptions(); }
    GetConditonalProps(): ConditionalProps | null {
        return ({
            labelId: 'app.demographic.ethnic_other',
            coditional: [998, 999],
            questionKeyName: 'EthnicBackgroundOther',
        });
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 8
export class MinorityGroup extends YesOrNoSelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'MemberOfDisadvantagedOrMinorityGroup'
    }
    GetQuestionLabelId(): keyof (PageMessages) {
        return 'app.demographic.disadvantaged_or_minority_group';
    }
    GetDefintions(): SelectItem[] { return GetMinorityGroupFilterOptions(); }
    GetConditonalProps(): ConditionalProps | null {
        return ({
            labelId: 'app.demographic.disadvantaged_or_minority_group_yes',
            coditional: [YesOrNoSelectQuestion.kYesValue],
            questionKeyName: 'MemberOfDisadvantagedOrMinorityGroupDescription',
        })
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 9
export class PrimaryWorkArea extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'PrimaryAreaOrWorkOrProfessionalInterest'
    }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.primary_area_of_work'; }
    GetDefintions(): SelectItem[] { return GetPrimaryWorkAreaOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 10
export class PoliticalOrientation extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'PoliticalOrientation' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.political_orientation'; }
    GetDefintions(): SelectItem[] { return GetPoliticalOrientationOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

export class PoliticalCommitment extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'PoliticalCommitment'; }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.political_commitment'; }
    GetDefintions(): SelectItem[] { return GetLevelOfInterestOptions(/*withNotApplicable*/ false); }
    GetConditonalProps(): ConditionalProps | null { return null }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 11a
export class Religion extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'CurrentBeliefsOrCommitmentsRegardingReligion'
    }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.religion'; }
    GetDefintions(): SelectItem[] { return GetReligiousOrientationOptions(); }
    GetConditonalProps(): ConditionalProps | null {
        return ({
            labelId: 'app.demographic.religion_other',
            coditional: [999],
            questionKeyName: 'CurrentBeliefsOrCommitmentsRegardingReligionOther',
        });
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};


// Previous Question 11b
export class ReligionScale extends SelectQuestion {
    constructor(religionResponseValue?: number, otherValue?: string) {
        super();
        this.religionResponseValue = religionResponseValue;
        this.otherLabel = otherValue
    }

    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'CommitmentTowardFaithReligions'
    }
    GetQuestionLabelId(): keyof (PageMessages) {
        if (this.religionResponseValue === 999 && this.otherLabel === undefined) {
            return 'app.demographic.religion_scale';
        }
        return this.religionResponseValue === undefined ?
            'app.demographic.religion_scale'
            :
            'app.demographic.religion_scale_specified';
    }
    GetDefintions(): SelectItem[] { return GetLevelOfInterestOptions(/*withNotApplicable*/ false); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetResponseLabel(): string | undefined {
        if (this.religionResponseValue === undefined) return undefined;
        if (this.religionResponseValue === 999) return this.otherLabel;

        return GetReligiousOrientationOptions().find(
            (item: SelectItem) => item.value === this.religionResponseValue)?.label;
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }

    private religionResponseValue?: number;
    private otherLabel?: string;
};

// Previous Question 12
export class IncomeLevel extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'IncomeLevelOfHomeCountry' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.income_level'; }
    GetDefintions(): SelectItem[] { return GetIncomeLevelOfYourHomeCountryOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 13
export class AcademicRank extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'AcademicStandingOrRank' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.academic_rank'; }
    GetDefintions(): SelectItem[] {
        return convertFormattedMessagesToSelectItem([
            { value: 1, key: 'app.demographic.academic_rank.response_1' },
            { value: 2, key: 'app.demographic.academic_rank.response_2' },
            { value: 3, key: 'app.demographic.academic_rank.response_3' },
        ]);
    }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 14
export class PrimaryCountry extends CountrySelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'PrimaryCountryOfOrigin' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.primary_country'; }
    GetConditonalProps(): ConditionalProps | null { return null; }
}

// Previous Question 15
export class TimeInOtherCountry extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'TimeInOtherCountry' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.time_in_other_countries'; }
    GetDefintions(): SelectItem[] { return GetTimeSpentInOtherCountriesOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 16
export class VisitCountries extends NumericQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'VisitedOrLivedCountries' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.visit_countries'; }
    GetMaxValue(): number { return 200; }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 17
export class MotherBorn extends CountrySelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'MotherCountry' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.country_mother_born'; }
    GetConditonalProps(): ConditionalProps | null { return null; }
}

// Previous Question 18
export class FatherBorn extends CountrySelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'FatherCountry' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.country_father_born'; }
    GetConditonalProps(): ConditionalProps | null { return null; }
}

// Previous Question 19
export class PersonalInterest extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'InterestInInternationalOrInterculturalLearning'
    }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.personal_interest'; }
    GetDefintions(): SelectItem[] { return GetLevelOfInterestOptions(/*withNotApplicable*/false); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

export class FatherFormalEducation extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'FatherFormalEducation'
    }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.father_formal_education'; }
    GetDefintions(): SelectItem[] { return FormalEducationOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

export class MotherFormalEducation extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'MotherFormalEducation'
    }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.mother_formal_education'; }
    GetDefintions(): SelectItem[] { return FormalEducationOptions(); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 20a
export class ParticipatedExperiences extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'ParticipatedExperiencesCapabilities' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.participated_experiences'; }
    GetDefintions(): SelectItem[] { return ParticipatedExperiencesOptions() }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 20b
export class ParticipatedLearningCourses extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'ParticipatedLearningCoursesOrProgramsCapabilities'
    }
    GetQuestionLabelId(): keyof (PageMessages) {
        return 'app.demographic.participated_learning_courses';
    }
    GetDefintions(): SelectItem[] { return ParticipatedLearningCoursesOptions() }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 21a
export class PlanParticipateExperiences extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'PlansToParticipateExperiencesCapabilities'
    }
    GetQuestionLabelId(): keyof (PageMessages) {
        return 'app.demographic.plan_participate_experiences';
    }
    GetDefintions(): SelectItem[] { return PlanParticipateExperiencesOptions() }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 21b
export class PlanParticipateLearningCourses extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) {
        return 'PlansToParticipateLearningCoursesOrProgramsCapabilities'
    }
    GetQuestionLabelId(): keyof (PageMessages) {
        return 'app.demographic.plan_participate_learning_courses';
    }
    GetDefintions(): SelectItem[] { return PlanParticipateLearningCoursesOptions() }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 22
export class ExperienceSatisfaction extends SelectQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'SatisfactionLevelInternationalExperienceProgram' }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.experience_satisfaction'; }
    GetDefintions(): SelectItem[] { return GetLevelOfInterestOptions(/*withNotApplicable*/false); }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
}

// Previous Question 23a
export class LanguagesSpeak extends Question {
    GetQuestionPrimaryLanguageKeyName(): keyof (DemographicQuestionAnswers) { return 'PrimaryLanguages' }
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'FluencyInLanguages' }
    GetPrimaryLanguageLabelId(): keyof (PageMessages) { return 'app.demographic.primary_language'; }
    GetPrimaryLanguageOtherLabelId(): keyof (PageMessages) { return 'app.demographic.primary_language_other'; }
    GetQuestionLabelId(): keyof (PageMessages) { return 'app.demographic.language_level_of_fluency'; }
    GetConditonalProps(): ConditionalProps {
        return ({
            labelId: 'app.demographic.language_options.Other',
            coditional: [999],
            questionKeyName: 'FluencyInLanguagesOthers',
        });
    }
    GetOtherIdValue(): number {
        return 999;
    }

    GetFormLabels(): Array<keyof (PageMessages)> {
        return ['app.demographic.table_language', 'app.demographic.table_level_of_fluency'];
    }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};

// Previous Question 23b
export class YearsStudieForiegnLanguage extends NumericQuestion {
    GetQuestionKeyName(): keyof (DemographicQuestionAnswers) { return 'TotalYearsStudiedForeignLanguage' }
    GetQuestionLabelId(): keyof (PageMessages) {
        return 'app.demographic.years_studied_foreign_language';
    }
    GetMaxValue(): number { return 120; }
    GetConditonalProps(): ConditionalProps | null { return null; }
    GetQuestionExplnationLabelId(): keyof (PageMessages) | null { return null; }
};
