import { NoInfoMessages } from '../types';

export const noInfoEN: NoInfoMessages = {
    'app.no.info.title.not_found': 'PAGE NOT FOUND',
    'app.no.info.title.no_data': 'NO DATA FOUND',
    'app.no.info.subtitle.not_found': 'Looks like you got lost!',
    'app.no.info.subtitle.no_data': 'Make sure your search parameters are correct, and if there is an error contact info@thebevi.com.',
    'app.no.info.body.not_found': 'The page you\'re looking for doesn\'t exist or has been moved.',
    'app.no.info.body.no_data': 'Take a look into the fields that you choose and if everything is correct, contact the account manager',
    'app.no.info.title.not_authorized': 'PAGE NOT AUTHORIZED',
    'app.no.info.subtitle.not_authorized': 'Looks like you\'re trying to access a page that you don\'t have permission',
    'app.no.info.body.not_authorized': 'If you want to access it, please, contact the account manager.',
    'app.no.info.title.maintenance': 'SYSTEM UNDER MAINTENANCE',
    'app.no.info.subtitle.maintenance': 'Looks like the system is under maintenance',
    'app.no.info.body.maintenance': 'Please, wait a few minutes and try again.',
}
