import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { Col, InputNumber, Row } from 'antd';
import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';

type Props = {
    showModal: boolean;
    organizationName: string,
    onModalHide: () => void;
    addInteraction: (interactions: number) => void
}

export const AddInteractionsModal = (
    {
        showModal, onModalHide, addInteraction, organizationName
    }: Props
): JSX.Element => {
    const [iteractions, setIteractions] = useState<number>();

    return (
        <Modal show={showModal} onHide={onModalHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={GetFormattedMessage('app.virtual_account.add_interactions_to_organization') + ' ' + organizationName} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col span={24}>
                        <InputNumberStyled style={{ width: '100%', height: 38 }} placeholder={GetFormattedMessage('app.virtual_account.interactions_placeholder')}
                        onChange={(value) => setIteractions(value as number)} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonWithIcon
                    textId={'app.virtual_account.add_interactions'}
                    disabled={iteractions == null}
                    onClick={() => addInteraction(iteractions!)}
                    icon={<FaPlus />}
                />
            </Modal.Footer>
        </Modal>
    );
};


export const InputNumberStyled = styled(InputNumber)`
    .ant-input-number-input-wrap {
        height: 100%;
        input {
            height: 100%;
        }
    }
`;