import { ListOrganizationsMessages } from '../types';

export const listOrganizationsZHTW: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Created on',
    'app.listorganizations.active': 'Active',
    'app.listorganizations.inactive': 'Inactive',
    'app.listorganizations.new.program': 'New Program or Experience',
    'app.listorganizations.new.group': 'Create and name a new BEVI administration.',
    'app.listorganizations.view': 'View',
    'app.listorganizations.report': 'Report',
    'app.listorganizations.details': 'Review and manage the BEVI administration.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Invite new users to join on this group by QR Code.',
    'app.listorganizations.tooltip.sub_program_edit': 'Edit BEVI administration parameters.',
    'app.listorganizations.table.beviname': 'BEVI Administration Name',
    'app.listorganizations.table.created_on': 'Created On',
    'app.listorganizations.table.due_date': 'Due Date',
    'app.listorganizations.table.completed_percentage': 'Completed %',
    'app.listorganizations.table.status': 'Status',
    'app.listorganizations.table.actions': 'Actions',
    'app.listorganizations.select_organization': 'Select an organization.',
    'app.listorganizations.search': 'Search',
    'app.listorganizations.new_organization': 'New Organization',
    'app.listorganizations.input_program_placeholder': 'Type the name of the program or experience.',
    'app.listorganizations.input_subprogram_placeholder': 'Type the name of the BEVI administration.',
    'app.listorganizations.organization_area': 'Management of Programs and Experiences',
    'app.listorganizations.download.data_report': 'Download data as an Excel file.',
    "app.listorganizations.sort_name_date": "Sort by name or date",
    "app.listorganizations.sort_by": "Sort by",
    'app.sample.data.footer.message_line1': '<b><i>筆記。</i> BEVI 報告系統允許開發八種不同的報告類型：1) 個人報告，2) 個人變更報告，3) 合作夥伴報告，4) 合作夥伴變更報告，5) 團體報告，6) 團體變更報告，7) 內部小組報告，以及8) 小組間報告。 為了讓您了解這些報告的工作原理以及可以存取的資訊類型，提供了每種報告類型的兩個範例（A 和 B）。</b>',
    'app.sample.data.footer.message_line2': '<b>儘管範例報告與 BEVI 系統中的實際報告一致，但提供這些範例僅用於說明目的。 因此，這些樣本報告中的一些資訊可能已被修改（例如，量表分數）或刪除（例如，定性反應），以確保結果不被識別和/或與特定個人脫鉤。</b>',
    'app.sample.data.footer.message_line3': '<b>如果您對這些樣本報告或 BEVI 的任何其他方面有任何疑問，請聯絡為您授權 BEVI 管理存取權限的個人，或聯絡 BEVI 團隊：<a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
