import { useActions, useValues } from 'kea';
import React, { Fragment, useContext, useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { appLogic, AppProps } from '../../redux/appLogic';
import { ComparativeByGroupReportActions, comparativeByGroupReportLogic, ComparativeByGroupReportProps } from '../../redux/comparativeByGroupReportLogic';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ComparativeReportByGroup } from './ComparativeReportByGroup';
import { GroupReportBackgroundDomain } from '../../services/types';
import { GetEthnicOrientationByCode, GetCountryByCode, GetMaritalStatusByCode, GetReligionByCode, GetLevelOfInterestByCode } from '../../utils/demographHelper';
import { GetIncomeGroup } from '../../utils/reportHelper';
import { InsufficientDataComponent } from '../GroupReport/components/InsufficientDataComponent';
import { IntlContext } from '../../IntlProviderWrapper';
import { ReportLanguage } from '../../services/reportService';
import { DetailsText } from './ReportTypes';
import { BeingBevi } from '../../components/BeingBevi';

type Props = RouteComponentProps;

export const ComparativeReportByGroupContainer = withRouter((props: Props): JSX.Element => {
  const { loadComparativeByGroupReport, downloadBetweenGroupReport }: ComparativeByGroupReportActions = useActions(comparativeByGroupReportLogic);
  const { loading, report, loadingDataDownload }: ComparativeByGroupReportProps = useValues(comparativeByGroupReportLogic);
  const { tokenInfo }: AppProps = useValues(appLogic);
  const { getReportLanguage } = useContext(IntlContext);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadComparativeByGroupReport(id)
  }, [loadComparativeByGroupReport, id]);

  const download = () => {
    const reportLanguage = getReportLanguage && getReportLanguage();
    downloadBetweenGroupReport(reportLanguage ?? ReportLanguage.English, id)
  }

  const BuildBackgroundDomainEthnicDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.ethnicBackground;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetEthnicOrientationByCode(parseInt(item)) + ":" + lowest[parseInt(item)];
        });

        const middle = backgroundDomainContrast[i].middleBackground.ethnicBackground;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetEthnicOrientationByCode(parseInt(item)) + ":" + middle[parseInt(item)];
        });

        const highest = backgroundDomainContrast[i].highestBackground.ethnicBackground;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetEthnicOrientationByCode(parseInt(item)) + ":" + highest[parseInt(item)];
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  const BuildBackgroundDomainCountryOfOriginDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.countryOfOriginDetails;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetCountryByCode(parseInt(item)) + ":" + lowest[parseInt(item)];
        });

        const middle = backgroundDomainContrast[i].middleBackground.countryOfOriginDetails;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetCountryByCode(parseInt(item)) + ":" + middle[parseInt(item)];
        });

        const highest = backgroundDomainContrast[i].highestBackground.countryOfOriginDetails;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetCountryByCode(parseInt(item)) + ":" + highest[parseInt(item)];
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  const BuildRelationshipDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.maritalStatus;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetMaritalStatusByCode(parseInt(item)) + ":" + lowest[parseInt(item)];
        });

        const middle = backgroundDomainContrast[i].middleBackground.maritalStatus;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetMaritalStatusByCode(parseInt(item)) + ":" + middle[parseInt(item)];
        });

        const highest = backgroundDomainContrast[i].highestBackground.maritalStatus;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetMaritalStatusByCode(parseInt(item)) + ":" + highest[parseInt(item)];
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  const BuildReligionDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.religions;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetReligionByCode(parseInt(item)) !== undefined ? GetReligionByCode(parseInt(item)) + ":" + lowest[parseInt(item)] : '';
        });

        const middle = backgroundDomainContrast[i].middleBackground.religions;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetReligionByCode(parseInt(item)) !== undefined ? GetReligionByCode(parseInt(item)) + ":" + middle[parseInt(item)] : '';
        });

        const highest = backgroundDomainContrast[i].highestBackground.religions;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetReligionByCode(parseInt(item)) !== undefined ? GetReligionByCode(parseInt(item)) + ":" + highest[parseInt(item)] : '';
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  const BuildLevelOfInterestDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.interest;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + lowest[parseInt(item)];
        });

        const middle = backgroundDomainContrast[i].middleBackground.interest;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + middle[parseInt(item)];
        });

        const highest = backgroundDomainContrast[i].highestBackground.interest;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + highest[parseInt(item)];
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  const BuildSatisfactionDetails =
    (backgroundDomainContrast: GroupReportBackgroundDomain[]): DetailsText[] => {
      const arr: DetailsText[] = [];

      for (let i = 0; i < backgroundDomainContrast.length; i++) {
        const lowest = backgroundDomainContrast[i].lowestBackground.satisfaction;
        var resultLowest = Object.keys(lowest).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + lowest[parseInt(item)];
        });

        const middle = backgroundDomainContrast[i].middleBackground.satisfaction;
        var resultMiddle = Object.keys(middle).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + middle[parseInt(item)];
        });

        const highest = backgroundDomainContrast[i].highestBackground.satisfaction;
        var resultHighest = Object.keys(highest).map((item, index) => {
          return GetLevelOfInterestByCode(parseInt(item)) + ":" + highest[parseInt(item)];
        });

        arr.push({
          highest: resultHighest,
          lowest: resultLowest,
          middle: resultMiddle,
        })
      }

      return arr;
    };

  if (loading) return <Loading />

  if (!report.hasEnoughData) {
    return (
      <React.Fragment>
        <InsufficientDataComponent />
      </React.Fragment>
    )
  }

  return (
    <Fragment>
      <ComparativeReportByGroup
        download={download}
        loadingDataDownload={loadingDataDownload}
        report={report ? report : {}}
        userName={tokenInfo.name}
        scalesTitle={{
          negative_life_events: GetFormattedMessage('app.report.scales.negative_life_events'),
          needs_closure: GetFormattedMessage('app.report.scales.needs_closure'),
          needs_fulfillment: GetFormattedMessage('app.report.scales.needs_fulfillment'),
          identity_diffusion: GetFormattedMessage('app.report.scales.identity_diffusion'),
          basic_openness: GetFormattedMessage('app.report.scales.basic_openness'),
          self_certitude: GetFormattedMessage('app.report.scales.self_certitude'),
          basic_determinism: GetFormattedMessage('app.report.scales.basic_determinism'),
          socioemotional_convergence: GetFormattedMessage('app.report.scales.socioemotional_convergence'),
          physical_resonance: GetFormattedMessage('app.report.scales.physical_resonance'),
          emotional_attunement: GetFormattedMessage('app.report.scales.emotional_attunement'),
          self_awareness: GetFormattedMessage('app.report.scales.self_awareness'),
          meaning_quest: GetFormattedMessage('app.report.scales.meaning_quest'),
          religious_traditionalism: GetFormattedMessage('app.report.scales.religious_traditionalism'),
          gender_traditionalism: GetFormattedMessage('app.report.scales.gender_traditionalism'),
          sociocultural_openness: GetFormattedMessage('app.report.scales.sociocultural_openness'),
          ecological_resonance: GetFormattedMessage('app.report.scales.ecological_resonance'),
          global_resonance: GetFormattedMessage('app.report.scales.global_resonance'),
          consistency: GetFormattedMessage('app.report.scales.consistency'),
          congruency: GetFormattedMessage('app.report.scales.congruency'),
        }}
        elementsTitle={{
          details_title: GetFormattedMessage('app.report.background_statistic_item.details'),
          aggregate_by_demographics_menu_title: GetFormattedMessage('app.report.menu.aggregate_by_demographics'),
          aggregate_profile_menu_title: GetFormattedMessage('app.report.menu.aggregate_profile'),
          background_domain_contrast_menu_title: GetFormattedMessage('app.report.menu.background_domain_contrast'),
          decile_profile_menu_title: GetFormattedMessage('app.report.menu.decile_profile'),
          profile_contrast_menu_title: GetFormattedMessage('app.report.menu.profile_contrast'),
          report_introduction_menu_title: GetFormattedMessage('app.report.menu.introduction'),

          // Introduction Items
          program: GetFormattedMessage('app.report.introduction.program_name'),
          group: GetFormattedMessage('app.report.introduction.group_name'),
          created_on: GetFormattedMessage('app.report.introduction.create_date'),
          visualized_by: GetFormattedMessage('app.report.introduction.visualized_by'),
          number_of_participants: GetFormattedMessage('app.report.introduction.number_of_participants'),

          // Background Domain Contrast
          background_domain_title: GetFormattedMessage('app.report.background_domain_contrast.title'),
          lowest_full_scale_title: GetFormattedMessage('app.report.background_domain_contrast.lowest_full_scale'),
          middle_full_scale_title: GetFormattedMessage('app.report.background_domain_contrast.middle_full_scale'),
          highest_full_scale_title: GetFormattedMessage('app.report.background_domain_contrast.highest_full_scale'),
          mean: GetFormattedMessage('app.report.background_domain_contrast.mean'),
          range: GetFormattedMessage('app.report.background_domain_contrast.range'),
          background_information: GetFormattedMessage('app.report.background_domain_contrast.background_information'),
          age: GetFormattedMessage('app.report.background_statistic_item.age'),
          gender: GetFormattedMessage('app.report.background_statistic_item.gender'),
          etnich_background: GetFormattedMessage('app.report.background_statistic_item.ethnic_background'),
          country_of_origin: GetFormattedMessage('app.report.background_statistic_item.country_of_origin'),
          years_of_education: GetFormattedMessage('app.report.background_statistic_item.years_of_education'),
          income: GetFormattedMessage('app.report.background_statistic_item.income'),
          relationship_status: GetFormattedMessage('app.report.background_statistic_item.marital_status'),
          religion: GetFormattedMessage('app.report.background_statistic_item.religion'),
          interest: GetFormattedMessage('app.report.background_statistic_item.interest'),
          satisfaction: GetFormattedMessage('app.report.background_statistic_item.satisfaction'),
          age_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          age_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          age_range: GetFormattedMessage('app.report.background_statistic_item.range'),
          age_average: GetFormattedMessage('app.report.background_statistic_item.average_age'),
          gender_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          gender_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          gender_male: GetFormattedMessage('app.demographic.gender.male'),
          gender_female: GetFormattedMessage('app.demographic.gender.female'),
          gender_self_identify: GetFormattedMessage('app.demographic.gender.self_identify'),
          etnich_background_answer: GetFormattedMessage('app.report.background_statistic_item.answer'),
          etnich_background_not_answer: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          etnich_background_details: BuildBackgroundDomainEthnicDetails(report.backgroundDomainContrast),
          country_of_origin_answer: GetFormattedMessage('app.report.background_statistic_item.answer'),
          country_of_origin_not_answer: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          country_of_origin_raised: GetFormattedMessage('app.report.background_statistic_item.raised_in'),
          country_of_origin_not_raised: GetFormattedMessage('app.report.background_statistic_item.not_raised_in'),
          country_of_origin_details: BuildBackgroundDomainCountryOfOriginDetails(report.backgroundDomainContrast),
          years_of_education_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          years_of_education_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          years_of_education_range: GetFormattedMessage('app.report.background_statistic_item.range'),
          years_of_education_average: GetFormattedMessage('app.report.background_statistic_item.average'),
          income_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          income_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          income_average: GetFormattedMessage('app.report.background_statistic_item.average'),
          income_lowest: GetFormattedMessage('app.report.background_statistic_item.lowest'),
          income_middle: GetFormattedMessage('app.report.background_statistic_item.middle'),
          income_highest: GetFormattedMessage('app.report.background_statistic_item.highest'),
          income_participant: GetFormattedMessage('app.report.background_statistic_item.income_participants'),
          income_range: GetFormattedMessage('app.report.background_statistic_item.range'),
          income_group_1: GetIncomeGroup(1),
          income_group_2: GetIncomeGroup(2),
          income_group_3: GetIncomeGroup(3),
          relationship_status_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          relationship_status_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          relationship_status_details: BuildRelationshipDetails(report.backgroundDomainContrast),
          religion_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          religion_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          religion_details: BuildReligionDetails(report.backgroundDomainContrast),
          interest_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          interest_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          interest_details: BuildLevelOfInterestDetails(report.backgroundDomainContrast),
          satisfaction_answered: GetFormattedMessage('app.report.background_statistic_item.answer'),
          satisfaction_not_answered: GetFormattedMessage('app.report.background_statistic_item.not_answer'),
          satisfaction_details: BuildSatisfactionDetails(report.backgroundDomainContrast),

          // Aggregate Profile
          aggregate_profile_title: GetFormattedMessage('app.report.menu.profile_contrast'),
          lowest_optimal_profile: GetFormattedMessage('app.report.profile_contrast.lowest_optimal_profiles'),
          middle_optimal_profile: GetFormattedMessage('app.report.profile_contrast.middle_optimal_profiles'),
          highest_optimal_profile: GetFormattedMessage('app.report.profile_contrast.highest_optimal_profiles'),
          lowest: GetFormattedMessage('app.report.profile_contrast.lowest'),
          middle: GetFormattedMessage('app.report.profile_contrast.middle'),
          highest: GetFormattedMessage('app.report.profile_contrast.highest'),

          // Decile Profile
          decile: GetFormattedMessage('app.reports.decile'),

          // Countries
          countries: report.selectedCountryCodes.map((item: number) => GetCountryByCode(item)),

          // Ethnicities
          ethnicities: report.selectedEthnicityCodes.map((item: number) => GetEthnicOrientationByCode(item)),

          // Demographics Title
          country_of_origin_title: GetFormattedMessage('app.report.menu.country_of_origin'),
          gender_title: GetFormattedMessage('app.report.menu.gender'),
          education_title: GetFormattedMessage('app.report.menu.education'),
          ethnicity_title: GetFormattedMessage('app.report.menu.ethnicity'),
          income_title: GetFormattedMessage('app.report.menu.income'),
          interest_title: GetFormattedMessage('app.report.menu.interest'),
          politics_title: GetFormattedMessage('app.report.menu.politics'),
          religion_title: GetFormattedMessage('app.report.menu.religion'),
          satisfaction_title: GetFormattedMessage('app.report.menu.satisfaction'),

          // Country of Origin
          selected_country_title: GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_country'),
          selected_countries_title: GetFormattedMessage('app.report.aggregate_profile.country_of_origin.selected_countries'),
          other_country: GetFormattedMessage('app.report.aggregate_profile.country_of_origin.other'),

          // Gender
          male_title: GetFormattedMessage('app.report.aggregate_profile.gender.male'),
          female_title: GetFormattedMessage('app.report.aggregate_profile.gender.female'),
          self_identify_title: GetFormattedMessage('app.report.aggregate_profile.gender.self_identify'),

          // Education
          education_lowest_30_title: GetFormattedMessage('app.report.aggregate_profile.education.lowest_30'),
          education_highest_30_title: GetFormattedMessage('app.report.aggregate_profile.education.highest_30'),

          // Ethnicity
          selected_ethnicity_title: GetFormattedMessage('app.report.aggregate_profile.ethnicity.selected_ethnicity'),
          selected_ethnicities_title: GetFormattedMessage('app.report.aggregate_profile.ethnicity.selected_ethnicities'),
          other_ethnicity: GetFormattedMessage('app.report.aggregate_profile.ethnicity.other'),

          // Income
          income_bottom_third: GetFormattedMessage('app.report.aggregate_profile.income.bottom_third'),
          income_middle_third: GetFormattedMessage('app.report.aggregate_profile.income.middle_third'),
          income_top_third: GetFormattedMessage('app.report.aggregate_profile.income.top_third'),

          // Interest
          interest_low: GetFormattedMessage('app.report.aggregate_profile.interest.low'),
          interest_middle: GetFormattedMessage('app.report.aggregate_profile.interest.middle'),
          interest_high: GetFormattedMessage('app.report.aggregate_profile.interest.high'),

          // Politics
          politics_low: GetFormattedMessage('app.report.aggregate_profile.politics.low'),
          politics_middle: GetFormattedMessage('app.report.aggregate_profile.politics.middle'),
          politics_high: GetFormattedMessage('app.report.aggregate_profile.politics.high'),

          // Religion
          religion_low: GetFormattedMessage('app.report.aggregate_profile.religion.low'),
          religion_middle: GetFormattedMessage('app.report.aggregate_profile.religion.middle'),
          religion_high: GetFormattedMessage('app.report.aggregate_profile.religion.high'),

          // Satisfaction
          satisfaction_low: GetFormattedMessage('app.report.aggregate_profile.satisfaction.low'),
          satisfaction_middle: GetFormattedMessage('app.report.aggregate_profile.satisfaction.middle'),
          satisfaction_high: GetFormattedMessage('app.report.aggregate_profile.satisfaction.high'),

          insufficient_data_title: GetFormattedMessage('app.reports.insuficient_data'),

          experiential_reflection_items_title: GetFormattedMessage('app.report.menu.experiential_reflection_items'),
          question_1_title: GetFormattedMessage('app.report.menu.question_1'),
          question_2_title: GetFormattedMessage('app.report.menu.question_2'),
          question_3_title: GetFormattedMessage('app.report.menu.question_3'),
        }}
      />
      <BeingBevi type='ComparativeByGroupReport' id={id} />
    </Fragment>
  )
});

