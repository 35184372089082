import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    getSubProgram, getSubProgramByProgram, GetSubProgramResponse, getUsersFromSubProgram, sendCustomAgreement, SendCustomAgreementResponse,
    SubProgramDetailsModel, remindToTakeTheBEVI,
    SubProgramModel,
    UserSubProgramPayload,
    addChatProgram
} from '../services/programService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'loadSubProgramDetails', 'clearSubProgramDetails', 'sendCustomAgreement', 'getSubProgram', 'getUserSubProgram', 'clearUserSubProgram', 'clearSubProgram',
    'remindToTakeTheBEVI', 'addChatProgram', 'clearSuccessfulAddChatProgram',
]);
export type ProgramActions = { [K in (typeof actionsLiterals)[number]]: any };
export const programActionsConstants: ProgramActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setSubProgramDetails', 'setSubProgramDetailsError',
    'successfullySentCustomAgreement', 'errorSendingCustomAgreement', 'setSubProgram', 'setSubProgramError',
    'setUserSubProgram', 'setUserSubProgramError', 'sendRemindToTakeTheBEVI', 'setRemindToTakeTheBEVIError',
    'successfulAddChatProgram', 'setErroAddChatProgram'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading', 'error', 'subProgramDetails', 'loadingSubProgram', 'subProgram', 'loadingUserSubProgram', 'loadingRemindToTakeTheBEVI', 'remindToTakeTheBEVISuccess',
    'userSubProgram', 'hasAddChatProgram', 'errorMessageAddChatProgram', 'loadingAddChatProgram']);
export type ProgramProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const programPropsConstants: ProgramProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ProgramActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchSubProgramDetails: () => void,
        sendCustomAgreementSaga: () => void,
        fetchGetSubProgramByProgramRequest: () => void,
        fetchGetUserFromSubProgramRequest: () => void,
        fetchRemindToTakeTheBEVIRequest: () => void,
        sendAddChatProgramRequest: () => void
    }
};

type LoadSubProgramDetailsRaceResponse = {
    subProgramDetailsResponse?: GetSubProgramResponse;
}
type SendCustomAgreementRaceResponse = {
    sendCustomAgreementResponse?: SendCustomAgreementResponse;
}

// Define payloads type.
type LoadSubProgramDetailsPayload = { subProgramId: number };
type LoadSubProgramDetailsRequest = { payload: LoadSubProgramDetailsPayload };
type SendCustomAgreementPayload = { formId: number, customAgreementText: string };
type SendCustomAgreementRequest = { payload: SendCustomAgreementPayload };

type ErrorPayload = { errorKey: string; };

type SubProgramDetailsPayload = { subProgram: SubProgramDetailsModel };

type GetSubProgramRequest = { payload: { programId: number } };
type SubProgramPayload = { subPrograns: SubProgramModel[] };
type GetUserSubProgramRequest = { payload: { subProgramId: number } };
type UserSubProgram = { users: UserSubProgramPayload[] };
type RemindToTakeTheBEVIRequest = { payload: { subProgramId: number } };

type AddChatProgramPayload = { programId: number }
type AddChatProgramRequest = { payload: any };
type StatusAddChatProgramPayload = { success: boolean; }

const UserFormDetailsSubProgramPropType = PropTypes.shape({
    userId: PropTypes.number,
    name: PropTypes.string,
    login: PropTypes.string,
    isUserActive: PropTypes.bool,
    completedPercentage: PropTypes.number,
    formStatus: PropTypes.number,
    FormId: PropTypes.number,
    IsValidReport: PropTypes.bool,
    ValidationFormStatus: PropTypes.number,
});

const SubProgramsDetailsPropType = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    year: PropTypes.number,
    isActive: PropTypes.bool,
    createdOnUtc: PropTypes.string,
    dueDateOnUtc: PropTypes.string,
    completedPercentage: PropTypes.number,
    emailMessage: PropTypes.string,
    usersAndFormsDetails: PropTypes.arrayOf(UserFormDetailsSubProgramPropType),
});

const SubProgramsPropType = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
});

const UserSubProgramsPropType = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    login: PropTypes.string,
    isActive: PropTypes.bool,
});

// persist forms reducer
export const programKeaPath = 'program';

export const programReducer = keaReducer(programKeaPath);

export const programLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [programKeaPath],

    actions: () => ({
        [programActionsConstants.clearSubProgramDetails]: true,
        [programActionsConstants.loadSubProgramDetails]:
            (subProgramId: number): LoadSubProgramDetailsPayload => ({ subProgramId }),
        [programActionsConstants.sendCustomAgreement]:
            (formId: number, customAgreementText: string): SendCustomAgreementPayload => ({ formId, customAgreementText }),
        [privateActionsConstants.setSubProgramDetails]:
            (subProgram: SubProgramDetailsModel): SubProgramDetailsPayload => ({ subProgram }),
        [privateActionsConstants.successfullySentCustomAgreement]: true,
        [privateActionsConstants.setSubProgramDetailsError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.errorSendingCustomAgreement]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setSubProgramError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [programActionsConstants.getSubProgram]:
            (programId: number): any => ({ programId }),
        [privateActionsConstants.setSubProgram]:
            (subPrograns: SubProgramModel[]): SubProgramPayload => ({ subPrograns }),
        [privateActionsConstants.setUserSubProgramError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [programActionsConstants.getUserSubProgram]:
            (subProgramId: number): any => ({ subProgramId }),
        [privateActionsConstants.setUserSubProgram]:
            (users: UserSubProgramPayload[]): UserSubProgram => ({ users }),
        [programActionsConstants.clearUserSubProgram]: true,
        [programActionsConstants.clearSubProgram]: true,
        [programActionsConstants.remindToTakeTheBEVI]:
            (subProgramId: number): any => ({ subProgramId }),
        [privateActionsConstants.sendRemindToTakeTheBEVI]:
            (value: boolean): any => (value),
        [privateActionsConstants.setRemindToTakeTheBEVIError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
        [programActionsConstants.addChatProgram]: (programId: number): AddChatProgramPayload => ({ programId }),
        [privateActionsConstants.setErroAddChatProgram]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.successfulAddChatProgram]: (): StatusAddChatProgramPayload => ({ success: true }),
        [programActionsConstants.clearSuccessfulAddChatProgram]: true,
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [programPropsConstants.subProgramDetails]: [null, PropTypes.objectOf(SubProgramsDetailsPropType), {
            [actions.setSubProgramDetails]:
                (_: SubProgramDetailsModel, payload: SubProgramDetailsPayload) => payload.subProgram,
            [actions.loadSubProgramDetails]: () => null,
            [actions.setSubProgramDetailsError]: () => null,
            [actions.clearSubProgramDetails]: () => null,
            [logout]: () => null,
        }],
        [programPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.loadSubProgramDetails]: () => true,
            [actions.setSubProgramDetails]: () => false,
            [actions.setSubProgramDetailsError]: () => false,
            [actions.sendCustomAgreement]: () => true,
            [actions.errorSendingCustomAgreement]: () => false,
            [actions.successfullySentCustomAgreement]: () => false,
            [logout]: () => false,
        }],
        [programPropsConstants.error]: [null, PropTypes.string, {
            [actions.setSubProgramDetailsError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.loadSubProgramDetails]: () => null,
            [actions.setSubProgramDetails]: () => null,
            [actions.errorSendingCustomAgreement]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.sendCustomAgreement]: () => null,
            [actions.setSubProgramError]: () => (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.setUserSubProgramError]: () => (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null,
        }],
        [programPropsConstants.loadingSubProgram]: [false, PropTypes.bool, {
            [actions.getSubProgram]: () => true,
            [actions.setSubProgram]: () => false,
            [actions.setSubProgramError]: () => false,
        }],
        [programPropsConstants.subProgram]: [null, PropTypes.objectOf(SubProgramsPropType), {
            [actions.setSubProgram]:
                (_: SubProgramModel, payload: SubProgramPayload) => payload.subPrograns,
            [actions.clearSubProgram]: () => null
        }],
        [programPropsConstants.loadingUserSubProgram]: [false, PropTypes.bool, {
            [actions.getUserSubProgram]: () => true,
            [actions.setUserSubProgram]: () => false,
            [actions.setUserSubProgramError]: () => false,
        }],
        [programPropsConstants.userSubProgram]: [null, PropTypes.objectOf(UserSubProgramsPropType), {
            [actions.setUserSubProgram]:
                (_: UserSubProgramPayload, payload: UserSubProgram) => payload.users,
            [actions.clearUserSubProgram]: () => null,
        }],
        [programPropsConstants.loadingRemindToTakeTheBEVI]: [false, PropTypes.bool, {
            [actions.remindToTakeTheBEVI]: () => true,
            [actions.sendRemindToTakeTheBEVI]: () => false,
            [actions.setRemindToTakeTheBEVIError]: () => false,
        }],
        [programPropsConstants.remindToTakeTheBEVISuccess]: [false, PropTypes.bool, {
            [actions.sendRemindToTakeTheBEVI]: (_: any, payload: boolean) => payload,
        }],
        [programPropsConstants.errorMessageAddChatProgram]: [null, PropTypes.string, {
            [actions.setErroAddChatProgram]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.clearSuccessfulAddChatProgram]: () => null,
            [actions.addChatProgram]: () => null,
            [actions.successfulAddChatProgram]: () => null,
            [logout]: () => null,
        }],
        [programPropsConstants.hasAddChatProgram]: [false, PropTypes.bool, {
            [actions.successfulAddChatProgram]: () => true,
            [actions.clearSuccessfulAddChatProgram]: () => false,
            [actions.setErroAddChatProgram]: () => false,
            [logout]: () => false,
        }],
        [programPropsConstants.loadingAddChatProgram]: [false, PropTypes.bool, {
            [actions.addChatProgram]: () => true,
            [actions.clearSuccessfulAddChatProgram]: () => false,
            [actions.successfulAddChatProgram]: () => false,
            [actions.setErroAddChatProgram]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadSubProgramDetails]: workers.fetchSubProgramDetails,
        [actions.sendCustomAgreement]: workers.sendCustomAgreementSaga,
        [actions.getSubProgram]: workers.fetchGetSubProgramByProgramRequest,
        [actions.getUserSubProgram]: workers.fetchGetUserFromSubProgramRequest,
        [actions.remindToTakeTheBEVI]: workers.fetchRemindToTakeTheBEVIRequest,
        [actions.addChatProgram]: workers.sendAddChatProgramRequest,
    }),

    workers: {
        * fetchSubProgramDetails(action: LoadSubProgramDetailsRequest): any {

            //@ts-ignore
            const { setSubProgramDetails, setSubProgramDetailsError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { subProgramId } = action.payload;

            const { subProgramDetailsResponse }: LoadSubProgramDetailsRaceResponse = yield race({
                subProgramDetailsResponse: call(getSubProgram, token, subProgramId),
                timeout: delay(requestTimeout)
            });

            if (subProgramDetailsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (subProgramDetailsResponse?.status === successfulCode) {
                if (subProgramDetailsResponse?.body) {
                    yield put(setSubProgramDetails(subProgramDetailsResponse?.body));
                } else {
                    yield put(setSubProgramDetailsError(generalExceptionKey));
                }
            } else if (subProgramDetailsResponse?.keyErrorMessage) {
                yield put(setSubProgramDetailsError(subProgramDetailsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * sendCustomAgreementSaga(action: SendCustomAgreementRequest): any {

            //@ts-ignore
            const { successfullySentCustomAgreement, errorSendingCustomAgreement } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { formId, customAgreementText } = action.payload;

            const { sendCustomAgreementResponse }: SendCustomAgreementRaceResponse = yield race({
                sendCustomAgreementResponse: call(sendCustomAgreement, token, formId, customAgreementText),
                timeout: delay(requestTimeout)
            });

            if (sendCustomAgreementResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (sendCustomAgreementResponse?.status === successfulCode) {
                yield put(successfullySentCustomAgreement());
            } else if (sendCustomAgreementResponse?.keyErrorMessage) {
                yield put(errorSendingCustomAgreement(sendCustomAgreementResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchGetSubProgramByProgramRequest(action: GetSubProgramRequest): any {
            //@ts-ignore
            const { setSubProgram, setSubProgramError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { programId } = action.payload;

            const subProgramUsersResponse = yield call(getSubProgramByProgram, token, programId);

            if (subProgramUsersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (subProgramUsersResponse?.status === successfulCode) {
                if (subProgramUsersResponse?.body) {
                    yield put(setSubProgram(subProgramUsersResponse?.body));
                } else {
                    yield put(setSubProgramError(generalExceptionKey));
                }
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchGetUserFromSubProgramRequest(action: GetUserSubProgramRequest): any {
            //@ts-ignore
            const { setUserSubProgram, setUserSubProgramError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { subProgramId } = action.payload;

            const subProgramUsersResponse = yield call(getUsersFromSubProgram, token, subProgramId);

            if (subProgramUsersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (subProgramUsersResponse?.status === successfulCode) {
                if (subProgramUsersResponse?.body) {
                    yield put(setUserSubProgram(subProgramUsersResponse?.body));
                } else {
                    yield put(setUserSubProgramError(generalExceptionKey));
                }
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchRemindToTakeTheBEVIRequest(action: RemindToTakeTheBEVIRequest): any {
            //@ts-ignore
            const { sendRemindToTakeTheBEVI, setRemindToTakeTheBEVIError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { subProgramId } = action.payload;

            const remindToTakeTheBEVIResponse = yield call(remindToTakeTheBEVI, token, subProgramId);

            if (remindToTakeTheBEVIResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (remindToTakeTheBEVIResponse?.status === successfulCode) {
                if (remindToTakeTheBEVIResponse?.body) {
                    yield put(sendRemindToTakeTheBEVI(true));
                    yield delay(1);
                    yield put(sendRemindToTakeTheBEVI(false));
                } else {
                    yield put(setRemindToTakeTheBEVIError(generalExceptionKey));
                }
            } else {
                yield put(serviceUnavailable());
            }
        },
        * sendAddChatProgramRequest(action: AddChatProgramRequest): any {

            //@ts-ignore
            const { successfulAddChatProgram, setErroAddChatProgram } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { programId } = action.payload;
            const { addChatProgramResponse }: any = yield race({
                addChatProgramResponse: call(addChatProgram, token, programId),
                timeout: delay(requestTimeout)
            });

            if (addChatProgramResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (addChatProgramResponse?.status === successfulCode) {
                yield put(successfulAddChatProgram(addChatProgramResponse?.body));
            } else if (addChatProgramResponse?.keyErrorMessage) {
                yield put(setErroAddChatProgram(addChatProgramResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
