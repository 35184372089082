import { InvitationPageMessages } from '../types';

export const invitationES: InvitationPageMessages = {
    'app.invitation.invite_label_template': 'Has sido invitado/a a participar en el BEVI por {0}. Te apuntarán al programa {1} y subprograma {2}.',
    'app.invitation.email': 'Correo-e',
    'app.invitation.password': 'Contraseña',
    'app.invitation.confirm_password': 'Repite contraseña',
    'app.invitation.name': 'Nombre',
    'app.invitation.language': 'Lengua',
    'app.invitation.blank_feedback': 'Este campo no puede dejarse en blanco!',
    'app.invitation.invalid_email_feedback': 'Este correo-e no es válido!',
    'app.invitation.password_length_feedback_template': 'Este campo tiene que tener  por lo menos {0} caracteres!',
    'app.invitation.confirm_password_not_equal_feedback': 'Este campo tiene que ser igual a la contraseña!',
    'app.invitation.success_notification_header': 'Bienvenido/Bienvenida al BEVI!',
    'app.invitation.success_notification_body': 'Utilizarás tu correo-e y contraseña registrados para entrar en el sistema en las próximas ocasiones.',
    'app.invitation.error_notification_header': 'Un error ha ocurrido.',
    'app.invitation.error_notification_body': 'Por favor compruebe los datos enviados e inténtalo de nuevo.',
}
