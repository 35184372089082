import { useActions, useValues } from 'kea';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { formLogic, FormsActions, FormsProps } from '../../redux/formsLogic';
import {
    COMBINED_INDIVIDUAL_REPORT_PAGE,
    COMPARATIVE_INDIVIDUAL_PAGE,
    CONSENT_PAGE, DEMOGRAPHIC_QUESTIONS_PAGE, DISCURSIVE_QUESTIONS_PAGE, INDIVIDUAL_REPORT_PAGE,
    SCALE_QUESTIONS_PAGE
} from '../../router/pages';
import { FormDetails } from '../../services/listFormsService';
import { ListForms } from './ListForms';
import { ComparativeIndividualReportActions, comparativeIndividualReportLogic } from '../../redux/comparativeIndividualReportLogic';
import { ComparativeIndividualReportModel, ReportLanguage, Reports } from '../../services/reportService';
import ModalComparative from './components/ModalComparative';
import { IntlContext } from '../../IntlProviderWrapper';

type Props = RouteComponentProps;

export const ListFormsContainer = withRouter((props: Props): JSX.Element => {

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showRestartConfirmation, setShowRestartConfirmation] = useState<boolean>(false);
    const [selectedRestartFormId, setSelectedRestartFormId] = useState<number | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);

    const { formList, loading, successfullRestart }: FormsProps = useValues(formLogic);

    const {
        list, setFormId, setHideQuestions, setFormStatus, setShowReport, updateFormPercentage, restartForm, setExtraQuestions
    }: FormsActions = useActions(formLogic);

    const {
        loadComparativeIndividualReport
    }: ComparativeIndividualReportActions = useActions(comparativeIndividualReportLogic);

    const { getReportLanguage } = useContext(IntlContext);

    const loadComparative = () => {
        const forms = formList.filter((x: any) => x.showReport);
        if (forms.length === 2) {
            callDispatchLoadComparative(forms.map((x: any) => x.id));
        } else {
            setShowModal(true);
        }
    };

    const callDispatchLoadComparative = (ids: number[]) => {
        const reportLanguage = getReportLanguage && getReportLanguage();
        loadComparativeIndividualReport({
            formIds: ids,
            language: reportLanguage ?? ReportLanguage.English,
            generateArea: 'Participant'
        } as ComparativeIndividualReportModel);
        props.history.push(COMPARATIVE_INDIVIDUAL_PAGE);
    };

    useEffect(() => {
        list();
    }, [list]);

    useEffect(() => {
        if (successfullRestart === true) {
            setShowRestartConfirmation(false);
            list();
        }
    }, [successfullRestart, list]);

    const handleFormClick = (formId?: number) => {
        const forms: FormDetails[] = formList;
        const item: FormDetails | undefined = forms.find((x: FormDetails) => x.id === formId);

        setFormId(item?.id);
        setHideQuestions(item?.hideQuestions)
        setExtraQuestions(item?.extraQuestions)
        setShowReport(item?.showReport);
        setFormStatus(item?.status);
        updateFormPercentage(item?.percentageCompleted);

        if (item?.status === 1) {
            // Status: Available
            setShowConfirmation(true);
        } else {
            // Move to specific page.
            if (item?.status === 2) {
                props.history.push(DEMOGRAPHIC_QUESTIONS_PAGE);
            } else if (item?.status === 3) {
                props.history.push(SCALE_QUESTIONS_PAGE);
            } else if (item?.status === 4) {
                props.history.push(DISCURSIVE_QUESTIONS_PAGE);
            } else if (item?.status === 5 && item?.showReport) {
                props.history.push(INDIVIDUAL_REPORT_PAGE);
            }
        }
    }

    const handleReportClick = (reportId: number) => {
        props.history.push(COMBINED_INDIVIDUAL_REPORT_PAGE.replace(":id", reportId.toString()));
    }

    const handleConfirmationSuccessClick = (): void => {
        setShowConfirmation(false);
        setFormStatus(1);
        props.history.push(CONSENT_PAGE);
    }

    const handleConfirmationCloseClick = (): void => {
        setShowConfirmation(false);
    }

    const handleRestartClick = (formId?: number): void => {
        setShowRestartConfirmation(true);
        setSelectedRestartFormId(formId);
    }

    const handleCancelRestart = (): void => {
        setShowRestartConfirmation(false);
        setSelectedRestartFormId(undefined);
    }

    const handleRestartConfirmationSuccessClick = (): void => {
        restartForm(selectedRestartFormId);
    }

    return (
        <Fragment>
            <ListForms
                data={formList}
                loading={loading}
                formClick={handleFormClick}
                showReportClick={handleFormClick}
                reportClick={handleReportClick}
                restartClick={handleRestartClick}
                loadComparative={loadComparative}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={'app.listforms.confirmation_title'}
                bodyFormattedListMessageId={[
                    'app.listforms.instruction.part1',
                    'app.listforms.instruction.part2',
                    'app.listforms.instruction.part3',
                    'app.listforms.instruction.press.start'
                ]}
                primaryButtonFormattedMessageId={'app.btn_start'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleConfirmationCloseClick}
                onPrimaryButtonClicked={handleConfirmationSuccessClick}
                onSecondaryButtonClicked={handleConfirmationCloseClick}
            />

            <ConfirmationModal
                showModal={showRestartConfirmation}
                titleFormattedMessageId={'app.listforms.retart_confirmation_title'}
                bodyFormattedListMessageId={[
                    'app.listforms.restart.instruction.part1',
                ]}
                primaryButtonFormattedMessageId={'app.btn_restart'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleCancelRestart}
                onPrimaryButtonClicked={handleRestartConfirmationSuccessClick}
                onSecondaryButtonClicked={handleCancelRestart}
            />
            <ModalComparative compare={(ids) => callDispatchLoadComparative(ids)} formList={formList} open={showModal} onHide={() => setShowModal(false)} />
        </Fragment>
    )
});
