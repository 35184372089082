import React from 'react';
import { Button } from 'react-bootstrap';
import { IconType } from 'react-icons/lib/cjs';
import { colors } from '../../../config/colors';
import { PageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type VariantType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark'
    | 'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success'
    | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light';

type Props = {
    titleMessageKey: keyof (PageMessages)
    icon: IconType;
    variant: VariantType;
    onClick: () => void;
};

export const ActionButton = (
    { titleMessageKey, icon: Icon, variant, onClick }: Props
): JSX.Element => {

    return (
        <Button className="col w-100" style={styles.buttonWithText} onClick={onClick} variant={variant}>
            <Icon className="active-button-icon"  />
            <p className="active-button-text" style={styles.text}>{GetFormattedMessage(titleMessageKey)}</p>
        </Button>
    );
}

const styles = {
    buttonWithText: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as React.CSSProperties,
    text: {
        color: colors.white
    } as React.CSSProperties,
};
