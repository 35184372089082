import React from 'react';
import { Tab } from 'semantic-ui-react';
import { H2 } from '../../components/styled/h2';
import { colors } from '../../config/colors';
import { IntlContext, LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';
import { GroupReportItem } from '../../services/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { AggregateProfile } from './components/AggregateProfile';
import { BackgroundDomainContrast } from './components/BackgroundDomainContrast';
import { BasicDescriptiveStatistics } from './components/BasicDescriptiveStatistics';
import { DecileProfile } from './components/DecileProfile';
import { Introduction } from './components/Introduction';
import { ProfileContrast } from './components/ProfileContrast';
import { InsufficientDataComponent } from './components/InsufficientDataComponent';
import { AggregateByDemographics } from '../../components/report/AggregateByDemographics';
import { NarrativeReport } from './components/NarrativeReport';
import { DomainScores } from './components/DomainScores';
import { FormattedMessage } from 'react-intl';

type Props = {
    report: GroupReportItem;
    userName: string;
}

export const GroupReportPage = ({ report, userName }: Props): JSX.Element => {
    const { messages } = React.useContext(IntlContext);
    const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    const mainPanes = [
        {
            menuItem: GetFormattedMessage('app.report.menu.introduction'), render: () =>
                <Tab.Pane>
                    <Introduction
                        introduction={report.introduction}
                        visualizedBy={userName}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.basic_descriptive_statistics'), render: () =>
                <Tab.Pane>
                    <BasicDescriptiveStatistics
                        basicDescriptiveStatistics={report.basicDescriptiveStatistics}
                        selectedCountryCodes={report.selectedCountryCodes}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.aggregate_profile'), render: () =>
                <Tab.Pane>
                    <AggregateProfile

                        title={
                            //@ts-ignore
                            messages['app.report.menu.aggregate_profile'].toString()
                        }
                        aggregateProfile={report.aggregateProfile}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.decile_profile'), render: () =>
                <Tab.Pane>
                    <DecileProfile
                        decileProfile={[report.decileProfile]}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.aggregate_by_demographics'),
            render: () =>
                <Tab.Pane>
                    <AggregateByDemographics report={report} type={'group'} />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.profile_background_contrast'), render: () =>
                <Tab.Pane>
                    <BackgroundDomainContrast
                        {...report.backgroundDomainContrast}
                        selectedCountryCodes={report.selectedCountryCodes}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.profile_contrast'), render: () =>
                <Tab.Pane>
                    <ProfileContrast
                        profileContrast={[report.profileContrast]}
                    />
                </Tab.Pane>
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.experiential_reflection_items'), render: () =>
                <Tab menu={{ pointing: true }} panes={experientialReflectionItemsPanes} />
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.narrative_report'), render: () =>
                <Tab.Pane>
                    <NarrativeReport narrativeReports={[report.narrativeReport]} />
                </Tab.Pane>
        },
    ]

    if (isJapanese) {
        const title = GetFormattedMessage('app.report.background_statistic_item.domain_scores');
        mainPanes.push({
            menuItem: title, render: () =>
                <Tab.Pane>
                    <React.Fragment>
                        <DomainScores domainScores={report.backgroundStatistic} title={title} />
                    </React.Fragment>
                </Tab.Pane>
        });
    }

    const experientialReflectionItemsPanes = [
        {
            menuItem: GetFormattedMessage('app.report.menu.question_1'),
            render: () =>
                <Tab.Pane attached={false}>
                    <p><FormattedMessage id="app.discursive_questions.question1" /></p>
                    <ul>
                        {
                            report.writtenAnswers1.map((item, index) => <li key={index}>{item}</li>)
                        }
                    </ul>
                </Tab.Pane>,
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.question_2'),
            render: () =>
                <Tab.Pane attached={false}>
                    <p><FormattedMessage id="app.discursive_questions.question2" /></p>
                    <ul>
                        {
                            report.writtenAnswers2.map((item, index) => <li key={index}>{item}</li>)
                        }
                    </ul>
                </Tab.Pane>,
        },
        {
            menuItem: GetFormattedMessage('app.report.menu.question_3'),
            render: () =>
                <Tab.Pane attached={false}>
                    <p><FormattedMessage id="app.discursive_questions.question3" /></p>
                    <ul>
                        {
                            report.writtenAnswers3.map((item, index) => <li key={index}>{item}</li>)
                        }
                    </ul>
                </Tab.Pane>,
        },
    ]

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                {
                    report.hasEnoughData ?
                        <React.Fragment>
                            <H2 textAlign='center'>{report.title}</H2>
                            <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={mainPanes} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                //@ts-ignore
                                <H2 textAlign='center'>{messages['app.reports.group_report.title'].toString()}</H2>
                            }
                            <InsufficientDataComponent />
                        </React.Fragment>

                }
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    buttonSubmit: {
        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
        height: '35px',
        width: '100px',
        color: '#fff',
        backgroundColor: colors.green,
        border: 'none',
    } as React.CSSProperties
};
