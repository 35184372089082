import { Popconfirm, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { Accordion, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FaBars, FaCloudDownloadAlt, FaSearchPlus, FaEdit, FaRocketchat } from 'react-icons/fa';
import { AiOutlineQrcode } from "react-icons/ai";
import { IoMdAddCircleOutline } from 'react-icons/io';
import { SearchSubProgramsDetails } from '../../../services/organizationService';
import { GetDate } from '../../../utils/dateHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import moment from 'moment';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { HasPermissionCapabilityProgramAdmin, IsSuperAdmin } from '../../../utils/userHelper';
import { TokenInfo } from '../../../services/loginService';
import { UserPermissions } from '../../../services/usersService';

type Props = {
    organizationId: number;
    programId: number;
    programName: string;
    eventKey: string;
    loadingQrCodeDownload: boolean;
    records: SearchSubProgramsDetails[];
    onCreateSubProgramClick: () => void;
    onEditSubProgramClick: (subprogram: SearchSubProgramsDetails) => void;
    onDetailsSubProgramClick: (subprogram: SearchSubProgramsDetails) => void;
    handleClickDownloadDataReport:
    (organizationId: number, programId?: number, subProgramId?: number) => void;
    onQrCodeDetailsClick: (subProgramId: number) => void;
    showDataDownloadButton: boolean;
    onRenameProgramModalOpen: (program: any) => void;
    onChatAllClick: (programId: number) => void;
    userInfo: TokenInfo;
}

export const CardProgram = (
    {
        eventKey, programId, organizationId, onEditSubProgramClick, programName, records,
        onDetailsSubProgramClick, showDataDownloadButton, onCreateSubProgramClick,
        onQrCodeDetailsClick, handleClickDownloadDataReport, loadingQrCodeDownload, onRenameProgramModalOpen,
        onChatAllClick, userInfo
    }: Props
): JSX.Element => {

    const activeText = GetFormattedMessage('app.listorganizations.active');
    const inactiveText = GetFormattedMessage('app.listorganizations.inactive');
    const subprogramDownloadReportText = GetFormattedMessage('app.listorganizations.download.data_report');
    const subprogramDetails = GetFormattedMessage('app.listorganizations.details');
    const subprogramEditTooltip = GetFormattedMessage('app.listorganizations.tooltip.sub_program_edit');
    const subprogramQrCodeDetails = GetFormattedMessage('app.listorganizations.tooltip.invite_by_qr_code');
    const chatAllTooltip = GetFormattedMessage('app.programs.chat.add_chat_all');

    const columns: ColumnProps<SearchSubProgramsDetails>[] = [
        {
            title: GetFormattedMessage('app.listorganizations.table.beviname'),
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
            title: GetFormattedMessage('app.listorganizations.table.created_on'),
            dataIndex: 'createdOnUtc',
            key: 'createdOnUtc',
            sorter: (a, b) => moment(a.createdOnUtc).unix() - moment(b.createdOnUtc).unix(),
            render: (_: string, record: SearchSubProgramsDetails) => (
                <span key={'CreateDate' + record.id}>
                    {GetDate(record.createdOnUtc)}
                </span>
            )
        },
        {
            title: GetFormattedMessage('app.listorganizations.table.due_date'),
            dataIndex: 'dueDateOnUtc',
            key: 'dueDateOnUtc',
            sorter: (a, b) => moment(a.dueDateOnUtc).unix() - moment(b.dueDateOnUtc).unix(),
            render: (_: string, record: SearchSubProgramsDetails) => (
                <span key={'DueDate' + record.id}>
                    {GetDate(record.dueDateOnUtc)}
                </span>
            )
        },
        {
            title: GetFormattedMessage('app.listorganizations.table.completed_percentage'),
            dataIndex: 'completedPercentage',
            key: 'completedPercentage',
            render: (_: number, record: SearchSubProgramsDetails) => (
                <span key={'Percentage' + record.id}>
                    {(record.completedPercentage * 100).toFixed(2)} %
                </span>
            )
        },
        {
            title: GetFormattedMessage('app.listorganizations.table.status'),
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_: boolean, record: SearchSubProgramsDetails) => (
                <span>
                    <Tag color={(record.isActive === false) ? 'volcano' : 'green'} key={'TAG' + record.id}>
                        {record.isActive ? activeText : inactiveText}
                    </Tag>
                </span>
            ),
        },
        {
            title: GetFormattedMessage('app.listorganizations.table.actions'),
            key: 'action',
            align: 'center',
            render: (_: string, record: SearchSubProgramsDetails) => (
                <div style={styles.alignCenter} key={'BTN' + record.id}>
                    {showDataDownloadButton &&
                        <OverlayTrigger
                            key={`overlayNewProgram-${organizationId}`}
                            placement='auto'
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`subprogramDownloadReport`} key={`subprogramDownloadReport-${record.id}`}>
                                    {subprogramDownloadReportText}
                                </Tooltip>
                            }
                        >
                            <span>
                                <FaCloudDownloadAlt
                                    style={styles.iconMarginRight}
                                    size={20}
                                    onClick={() => handleClickDownloadDataReport(organizationId, programId, record.id)}
                                />
                            </span>
                        </OverlayTrigger>
                    }
                    {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canEditSubProgram) && <OverlayTrigger
                        key={`subprogramEditTooltip-${organizationId}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip id={`subprogramEditTooltip`} key={`subprogramEditTooltip-${record.id}`}>
                                {subprogramEditTooltip}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FaEdit
                                style={styles.iconMarginRight}
                                size={20}
                                onClick={() => onEditSubProgramClick(record)}
                            />
                        </span>
                    </OverlayTrigger>}
                    {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canManageSubProgram) && <OverlayTrigger
                        key={`subprogramDetails-${organizationId}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip id={`subprogramDetails`} key={`subprogramDetails-${record.id}`}>
                                {subprogramDetails}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FaSearchPlus
                                size={20}
                                onClick={() => onDetailsSubProgramClick(record)}
                            />
                        </span>
                    </OverlayTrigger>}
                    {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canSubProgramQrCodeInviteNewUsers) && <OverlayTrigger
                        key={`subprogramQrCodeDetails-${organizationId}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip id={`subprogramQrCodeDetails`} key={`subprogramQrCodeDetails-${record.id}`}>
                                {subprogramQrCodeDetails}
                            </Tooltip>
                        }
                    >
                        <span>
                            <AiOutlineQrcode
                                size={20}
                                onClick={() => onQrCodeDetailsClick(record.id)}
                            />
                        </span>
                    </OverlayTrigger>}
                </div>
            ),
        },
    ];

    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <div
                style={styles.displayInlineFlex}
                onClick={decoratedOnClick}
            >
                {children}
            </div>
        );
    }

    return (
        <Card>
            <Card.Header>
                <div className="row">
                    <div className="col-md-9" style={styles.displayInlineBlock}>
                        <CustomToggle key={programName} eventKey={eventKey}>
                            <span>
                                <FaBars />
                            </span>
                            <span>
                                {programName}
                            </span>
                        </CustomToggle>
                    </div>
                    <div className="col-md-3" style={styles.alignRight}>
                        {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canRenameProgram) && <OverlayTrigger
                            key={`overlayRenameProgram-${programId}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`renameProgram`} key={`renameProgram-${programId}`}>
                                    {GetFormattedMessage('app.programs.rename')}
                                </Tooltip>
                            }
                        >
                            <span>
                                <FaEdit
                                    style={styles.iconMarginRight}
                                    size={25}
                                    onClick={() => onRenameProgramModalOpen({ programId: programId, name: programName })}
                                />
                            </span>
                        </OverlayTrigger>}
                        {showDataDownloadButton &&
                            <OverlayTrigger
                                key={`overlayDataDownloadProgram-${programId}`}
                                placement={'auto'}
                                trigger={["hover", "hover"]}
                                overlay={<Tooltip id={`downloadReportDataOrganizationTooltip`}
                                    key={`downloadReportDataOrganizationTooltip-${programId}`}>
                                    {GetFormattedMessage('app.listorganizations.download.data_report')}
                                </Tooltip>}
                            >
                                <span>
                                    <FaCloudDownloadAlt
                                        style={styles.iconMarginRight}
                                        size={25}
                                        onClick={() => handleClickDownloadDataReport(organizationId, programId)}
                                    />
                                </span>
                            </OverlayTrigger>
                        }
                        {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canAddSubProgram) && <OverlayTrigger
                            key={`overlayNewBevirogram-${programId}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={<Tooltip id={`createNewBeviTooltip-${programId}`}>
                                {GetFormattedMessage('app.listorganizations.new.group')}
                            </Tooltip>}
                        >
                            <span>
                                <IoMdAddCircleOutline style={styles.iconMarginRight} size={25} onClick={onCreateSubProgramClick} />
                            </span>
                        </OverlayTrigger>}
                        {IsSuperAdmin(userInfo) && <OverlayTrigger
                            key={`chatAll-${programId}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`chatAll`} key={`chatAllDetails-${programId}`}>
                                    {chatAllTooltip}
                                </Tooltip>
                            }
                        >
                            <span>
                                <Popconfirm onConfirm={() => onChatAllClick(programId)}
                                    title={GetFormattedMessage('app.programs.chat.question_add_chat_all')} placement='bottom'>
                                    <FaRocketchat
                                        size={20}
                                    />
                                </Popconfirm>
                            </span>
                        </OverlayTrigger>}
                    </div>
                </div>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                    <Table
                        columns={columns}
                        dataSource={records}
                        pagination={false}
                        rowKey={'id'}
                        loading={loadingQrCodeDownload}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

const styles = {
    displayInlineBlock: {
        display: 'inline-block',
    } as React.CSSProperties,
    displayInlineFlex: {
        display: 'inline-flex',
        width: '100%',
        gap: 12
    } as React.CSSProperties,
    alignRight: {
        textAlign: 'right'
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
    iconMarginRight: {
        marginRight: '5'
    } as React.CSSProperties,
}
