import { VirtualAccountMessages } from '../types';

export const virtualAccountES: VirtualAccountMessages = {
  'app.virtual_account.title': 'Cuenta Virtual por Institución',
  'app.virtual_account.add_interactions': 'Agregar Interacciones',
  'app.virtual_account.add_interactions_to_organization': 'Agregar interacciones para la organización:',
  'app.virtual_account.interactions_placeholder': 'Escriba el número de iteraciones',
  'app.virtual_account.successful_message': 'La cuenta virtual se actualizó correctamente.',
  'app.virtual_account.day': 'Día',
  'app.virtual_account.interaction_credit': 'Créditos de interacción',
  'app.virtual_account.interaction_debit': 'Débitos de interacción',
  'app.virtual_account.balance': 'Balance',
  'app.virtual_account.total_balance_month': 'El saldo total del mes es ',
  'app.virtual_account.interactions_available': 'interacciones disponibles'
}