import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Select } from "antd";
import { SearchUsersDetails } from "../../../services/usersService";

type Props = {
    users?: SearchUsersDetails[];
    loadingUsers?: boolean;
    selectedUsers?: number[];
    setSelectedUsers?: (value: number[]) => void
}

export const Step3IndividualReport = ({ users, loadingUsers, selectedUsers, setSelectedUsers }: Props) => {

    return <Form style={styles.formDiv}>
        <Form.Group className='mb-3'>
            <Row>
                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <b>Users</b>
                        </Form.Label>
                        <br />
                        <Select
                            optionFilterProp="label"
                            loading={loadingUsers}
                            value={selectedUsers}
                            onChange={(value) => setSelectedUsers && setSelectedUsers(value)}
                            style={{ width: '100%' }} options={users?.map((x) => ({
                                label: `${x.name} (${x.login})`,
                                value: x.id
                            }))} mode="multiple" />
                    </Form.Group>
                </Col>
            </Row>
        </Form.Group>
    </Form>
}

const styles = {
    formDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
}
