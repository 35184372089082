import { ListFormPageMessages } from '../types';

export const listFormsPageZHCN: ListFormPageMessages = {
    'app.listforms.title': 'BEVI儀表板(BEVI Dashboard）',
    'app.listforms.subtitle': '在BEVI儀表板上，可參加BEVI考評、閱覽和管理你的個人結果。',
    'app.listforms.organization': '大學或機構',
    'app.listforms.participant': '被考評者',
    'app.listforms.program': '課程項目',
    'app.listforms.take_by': '截止日期',
    'app.listforms.completed_on': '回答日期',
    'app.listforms.progress': '進度',
    'app.listforms.status': '狀態',
    'app.listforms.action': '操作',
    'app.listforms.organization_tooltip': '此儀表板可參加BEVI考評、閱覽和管理其結果。',
    'app.listforms.program_tooltip': '您参加 BEVI 的报告、计划或体验的名称。',
    'app.listforms.take_by_tooltip': '請在該日期前完成BEVI考評。',
    'app.listforms.completed_on_tooltip': '完成BEVI考評的日期',
    'app.listforms.progress_tooltip': 'BEVI考評的進度（顯示“100%”時，表示“你已完成BEVI考評”）',
    'app.listforms.status_tooltip': 'BEVI考評狀態',
    'app.listforms.action_tooltip': '可參加或繼續BEVI考評或再考評，並能閱覽其個人結果。顯示的方框是現在可選擇的選項。',
    'app.listforms.all': 'All',
    'app.listforms.available': '未回答',
    'app.listforms.onDemographics': '背景信息填寫中',
    'app.listforms.onScales': '客觀選擇題回答中',
    'app.listforms.onSubjective': '主觀題回答中',
    'app.listforms.finished': '完成',
    'app.listforms.cancelled': '取消',
    'app.listforms.expired': '已過期',
    'app.listforms.archived': 'Archived',
    'app.listforms.invalid': '無效',
    'app.listforms.start': '開始',
    'app.listforms.continue': '繼續',
    'app.listforms.report': '閱覽結果',
    'app.listforms.partner_report': '查看合作伙伴报告',
    'app.listforms.partner_change_report': '查看合作伙伴变更报告',
    'app.listforms.restart': '再考評',
    'app.listforms.confirmation_title': '操作指南',
    'app.listforms.retart_confirmation_title': '再考評',
    'app.listforms.restart.instruction.part1': '請點擊以下按鈕再次進行BEVI考評。',
    'app.listforms.instruction.part1': '以下考評由背景信息及185道客觀選擇題構成。關於185道客觀選擇題，每頁將顯示20道題，請仔細閱讀每道題並從4個選項“非常同意、同意、不同意、非常不同意”中選出一個最能描述您感受的選項。',
    'app.listforms.instruction.part2': '每回答完20道題，請點擊“下一頁”繼續答題。一旦點擊“下一頁”後，將不能返回上一頁進行修改。',
    'app.listforms.instruction.part3': '在開始BEVI考評前，你必須閱讀其概要及同意書。',
    'app.listforms.instruction.press.start': '非常感謝您參加BEVI考評。若您準備就緒，請點擊“開始”按鈕。',
    'app.listforms.comparative_individual_report': '比较个人报告',
    'app.form.status_title': '目前沒有可以參加的BEVI考評。',
    'app.form.status_finished_subtitle': '已完成該BEVI考評。',
    'app.form.status_finished_body': '若對此BEVI考評有疑問，請諮詢BEVI實施負責人。',
    'app.form.status_cancelled_subtitle': '此BEVI考評已被取消。',
    'app.form.status_cancelled_body': '若對此BEVI考評有疑問，請諮詢BEVI實施負責人。',
    'app.form.status_expired_subtitle': '此BEVI考評已過期。',
    'app.form.status_expired_body': '若對此BEVI考評有疑問，請諮詢BEVI實施負責人。',
    'app.form.invalid_status.by_time': '回答時間',
    'app.form.invalid_status.by_number_of_questions': '答題數不足',
    'app.form.invalid_status.by_consecutive_answers': '連續答題',
    'app.form.invalid_status.by_common_answers': '同一形式',
    'app.form.invalid_status.by_congruency': '缺乏適宜性',
    'app.form.invalid_status.by_consistency': '缺乏一貫性',
    'app.form.invalid_status.by_wrong_scale_value': '(ZHCN)Invalid by Wrong Scale Value',
    'app.listforms.custom_agreement_status': '(ZHCN)Custom Agreement Status',
    'app.form.custom_agreement.not_requested': '(ZHCN)Not requested',
    'app.form.custom_agreement.pending': '(ZHCN)Pending',
    'app.form.custom_agreement.accepted': '(ZHCN)Accepted',
    'app.form.custom_agreement.rejected': '(ZHCN)Rejected',
    'app.form.actions.tooltip.send_custom_agreement': '(ZHCN)Send custom agreement',
    'app.form.actions.tooltip.resend_custom_agreement': '(ZHCN)Resend custom agreement',
    'app.form.actions.tooltip.show_report': '(ZHCN)Show report',
    'app.listforms.ai_interactions': 'AI互动'
}
