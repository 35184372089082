import React, { Fragment, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Button, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { Table } from 'antd';
import { IoIosCloseCircleOutline, IoMdAddCircleOutline } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { FormDropdown, SelectItem } from '../../../components/FormDropdown';

import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ExtraQuestionModel } from '../../../services/types';

type Props = {
    showModal: boolean;
    modalTitle: any;
    loadingButton: boolean;
    fetchAddExtraQuestion: (title: string, questionType: number, extraQuestionOptions: string[]) => void;
    fetchEditExtraQuestion: (title: string, newExtraQuestionOptions: string[]) => void;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    extraQuestion?: ExtraQuestionModel;
}

export const SaveExtraQuestionModal = (
    { loadingButton, showModal, modalTitle, extraQuestion, fetchAddExtraQuestion, fetchEditExtraQuestion, setShowModal }: Props
): JSX.Element => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [selectIndex, setSelectIndex] = useState<number | undefined>(undefined);
    const [options, setOptions] = useState<{ description: string }[]>([]);
    const [type, setType] = useState<SelectItem>({
        label: GetFormattedMessage('app.institutions.demographics_questions.type_1'),
        value: 1
    });
    const [typeOptions] = useState<SelectItem[]>([{
        label: GetFormattedMessage('app.institutions.demographics_questions.type_1'),
        value: 1
    }, {
        label: GetFormattedMessage('app.institutions.demographics_questions.type_2'),
        value: 2
    }])

    useEffect(() => {
        if (extraQuestion == null) {
            setTitle('');
            setType(typeOptions[0]);
            setOptions([]);
        } else {
            setTitle(extraQuestion.title);
            setType(typeOptions[extraQuestion.type - 1]);
            if (extraQuestion.extraQuestionOptions != null)
                setOptions(extraQuestion.extraQuestionOptions.map(x => {
                    return {
                        description: x.description
                    }
                }));
            else
                setOptions([]);
        }
    }, [showModal, typeOptions, extraQuestion]);

    const disabledButton = useMemo(() => {
        if (!loadingButton && title.trim() !== '' && (type.value === 1 || (type.value === 2 && options.length > 0)))
            return false;
        return true;
    }, [title, type, loadingButton, options])

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormattedMessage(modalTitle).toUpperCase()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <React.Fragment>
                    {/* {error === true &&
            <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.institutions.add.error_message') }} />
          } */}
                    <Form.Group className='mb-3' as={Col}>
                        <Form.Group className='mb-3' controlId="formField2">
                            <b><FormattedMessage id='app.institutions.demographics_questions.title' /></b>
                            <Form.Control
                                className="form-control mb-3"
                                type='text'
                                placeholder={GetFormattedMessage('app.institutions.demographics_questions.title')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                                value={title}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <b><FormattedMessage id='app.institutions.demographics_questions.type' /></b>
                                <FormDropdown
                                    value={type}
                                    isSearchable={true}
                                    defaultOptionId={'app.institutions.demographics_questions.type'}
                                    onValueSelect={(value: any) => setType(typeOptions.find(x => x.value === value)!)}
                                    optionItens={typeOptions}
                                // isDisabled={!canSelectSubPrograms || organizationOptions.length === 0}
                                />
                            </div>
                            {type.value === 2 && <div style={{ width: '100%' }}>
                                <div style={{ marginTop: 14, width: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <b><FormattedMessage id='app.institutions.demographics_questions.options' /></b>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Control
                                                className="form-control mb-3"
                                                type='text'
                                                placeholder={GetFormattedMessage('app.institutions.demographics_questions.description')}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                                                value={description}
                                            />
                                            {selectIndex == null ? <IoMdAddCircleOutline onClick={() => {
                                                if (description.trim() !== '') {
                                                    setSelectIndex(undefined);
                                                    setDescription('');
                                                    setOptions([...options, { description: description.trim() }])
                                                }
                                            }} style={{ fontSize: 28, cursor: 'pointer', marginLeft: 12, marginBottom: 14 }} />
                                                : <Fragment>
                                                    <FaEdit style={{ fontSize: 28, cursor: 'pointer', marginLeft: 12, marginBottom: 14 }} onClick={() => {
                                                        if (description.trim() !== '') {
                                                            const editOption = [...options];
                                                            editOption[selectIndex].description = description.trim();
                                                            setSelectIndex(undefined);
                                                            setDescription('');
                                                            setOptions(editOption);
                                                        }
                                                    }} />
                                                    <IoIosCloseCircleOutline style={{ fontSize: 28, cursor: 'pointer', marginLeft: 12, marginBottom: 14 }} onClick={() => {
                                                        setDescription('');
                                                        setSelectIndex(undefined);
                                                    }} />
                                                </Fragment>}
                                        </div>
                                        <Table pagination={false} columns={[{
                                            dataIndex: 'description',
                                            width: '75%',
                                            title: GetFormattedMessage('app.institutions.demographics_questions.description'),
                                        }, {
                                            dataIndex: 'description',
                                            width: '25%',
                                            title: GetFormattedMessage('app.institutions.list.action'),
                                            render: (value, row, index) => {
                                                if (extraQuestion != null && index + 1 > extraQuestion.extraQuestionOptions.length) {
                                                    return <div>
                                                        <div style={{ display: 'flex', gap: 12 }}>
                                                            <div>
                                                                <FaEdit style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setSelectIndex(index);
                                                                    setDescription(options[index].description);
                                                                }} />
                                                            </div>
                                                            <div>
                                                                <FaTrash style={{ cursor: 'pointer' }} onClick={() => {
                                                                    const deleteOptions = [...options];
                                                                    deleteOptions.splice(index, 1);
                                                                    setOptions(deleteOptions);
                                                                    setDescription('');
                                                                    setSelectIndex(undefined);
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                return <></>;
                                            }
                                        }]} dataSource={options}>
                                        </Table>
                                    </div>
                                </div>
                            </div>}
                        </Form.Group>
                    </Form.Group>
                </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ backgroundColor: colors.darkSlateBlue }}
                    variant='primary'
                    onClick={() => {
                        if (extraQuestion == null)
                            fetchAddExtraQuestion(title, type!.value!, options.map(x => x.description))
                        else {
                            const editOptions = [...options].map(x => x.description);
                            if(extraQuestion.type === 2)
                                editOptions.splice(0, extraQuestion.extraQuestionOptions.length);
                            fetchEditExtraQuestion(title, editOptions)
                        }
                    }}
                    disabled={disabledButton}
                >
                    {loadingButton &&
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            style={{ marginRight: 10 }}
                        />
                    }
                    {GetFormattedMessage('app.institutions.add.button_save')}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};