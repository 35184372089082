import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { PageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = Partial<typeof Form.Group> & { groupAs?: typeof Col, groupMd?: string } & {
    options: SelectItem[];
    value?: SelectItem[];
    isSelectedOption: boolean;
    labelKey: keyof PageMessages;
    disableDefaultText?: boolean;
    defaultOptionKey?: keyof PageMessages
    useSwitchToDisable?: boolean;
    closeMenuOnSelect?: boolean
    onFilterChange: (values: number[] | undefined, text: string[]) => void;
};

export const SelectOptionGroup = (
    {
        value, options, labelKey, onFilterChange, defaultOptionKey, isSelectedOption,
        closeMenuOnSelect, disableDefaultText, useSwitchToDisable, groupAs, groupMd, ...groupProps
    }: Props
): JSX.Element => {

    const [switchState, setSwitchState] = useState<boolean>(useSwitchToDisable === false || value !== undefined);
    const [currentValues, setCurrentValues] = useState<{ indexes: number[], texts: string[] }>();

    const defaultValue = isSelectedOption ?
        'app.create_report.filter_select_with_values'
        :
        defaultOptionKey ? defaultOptionKey : 'app.create_report.filter_select_default_option';

    return (
        <Form.Group className='mb-3' as={groupAs} md={groupMd} {...groupProps}>
            { useSwitchToDisable !== false &&
                <Form.Check
                    type='switch'
                    inline={true}
                    id={labelKey}
                    label={GetFormattedMessage(labelKey)}
                    defaultChecked={switchState}
                    readOnly={true}
                    onClick={() => {
                        if (switchState) onFilterChange(undefined, []);
                        else if (currentValues !== undefined) {
                            onFilterChange(currentValues.indexes, currentValues.texts);
                        }
                        setSwitchState(!switchState);
                    }}
                />
            }
            <FormDropdown
                id={labelKey}
                isDisabled={!switchState}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                isMulti={true}
                isClearable={true}
                isSearchable={true}
                value={value}
                closeMenuOnSelect={closeMenuOnSelect !== undefined ? closeMenuOnSelect : false}
                defaultOptionId={disableDefaultText === true ? undefined : defaultValue}
                optionItens={options}
                onMultiValueSelect={(values: number[], texts: string[]) => {
                    setCurrentValues({ indexes: values, texts });
                    onFilterChange(values.length > 0 ? values : undefined, texts);
                }}
            />
        </Form.Group>
    )
}
