import { serviceUrl } from '../config/constants';
import { BaseResponse, fetchGetDataObjectMethod, fetchGetMethod, fetchPostMethod, getResponseDataObject, getResponseObject, getResponseObjectWithoutBody } from '../rpc/api';
import { ReportLanguage } from './reportService';
import { GetDataDownloadResponse } from './types';

const listOrganizationErrorMessageKey = 'app.error.list.getorganization';
const listOrganizationWithProgramsErrorMessageKey = 'app.error.list.organization_with_programs';
const searchOrganizationErrorMessageKey = 'app.error.list.searchorganization';
const errorMessageKey = 'app.error.logon';

export type ListOrganizationBody = Array<OrganizationDetails>;

export class ListOrganizationResponse extends BaseResponse<ListOrganizationBody> { }

export type OrganizationDetails = {
  id: number;
  name: string;
  active: boolean;
}

export class GetOrganizationResponse extends BaseResponse<OrganizationDetails> { }

export class SearchOrganizationResponse extends BaseResponse<SearchOrganizationBody> { }

export type SearchOrganizationBody = SearchOrganizationDetails;
export type SearchOrganizationDetails = {
  id: number;
  active: boolean;
  name: string;
  programs: Array<SearchProgramsDetails>;
}

export type SearchProgramsDetails = {
  id: number;
  name: string;
  isActive: boolean;
  subPrograms: Array<SearchSubProgramsDetails>;
}

export type SearchSubProgramsDetails = {
  id: number;
  description: string;
  isActive: boolean;
  createdOnUtc: string;
  dueDateOnUtc: string;
  completedPercentage: number;
}

export function listOrganizations(token: string): Promise<ListOrganizationResponse> {
  const response = fetchGetMethod(`${serviceUrl}/api/user/GetOrganizations`, token);
  return getResponseObject<ListOrganizationBody>(response, listOrganizationErrorMessageKey);
}

export function searchOrganizations(
  token: string, organizationId: number, programQuery: string, subProgramQuery: string
): Promise<SearchOrganizationResponse> {

  const body = { organizationId: organizationId, programQuery: programQuery, subProgramQuery: subProgramQuery };
  const response = fetchPostMethod(
    `${serviceUrl}/api/organization/search`, token, body);
  return getResponseObject<SearchOrganizationBody>(response, searchOrganizationErrorMessageKey);
}


export type ProgramModel = {
  id: number;
  isActive?: boolean;
  name: string;
}

export type OrganizationWithProgramsModel = {
  id: number;
  name: string;
  active?: boolean;
  programs: ProgramModel[];
}
export class ListOrganizationWithProgramsResponse extends BaseResponse<OrganizationWithProgramsModel[]> { }

export function listOrganizationWithPrograms(token: string) {
  const response = fetchGetMethod(`${serviceUrl}/api/organization/GetOrganizationProgramAndSubProgram`, token);
  return getResponseObject<OrganizationWithProgramsModel[]>(response, listOrganizationWithProgramsErrorMessageKey);
}

export function downloadQrCodeInvitation(token: string, subprogramId: number, locale: ReportLanguage): Promise<GetDataDownloadResponse> {
  const response = fetchGetDataObjectMethod(
    `${serviceUrl}/api/organization/GetQRcode?subProgramId=${subprogramId}&locale=${locale}`,
    token
  );
  return getResponseDataObject(response, listOrganizationWithProgramsErrorMessageKey, 'qrcode_invitation.pdf');
}

export type SaveOrganizationBody = {}
export class SaveOrganizationResponse extends BaseResponse<SaveOrganizationBody> { }
export class AddChatOrganizationResponse extends BaseResponse<any> { }

export type SaveOrganizationPayloadRequest = {
  id?: number;
  name?: string;
};

export type SaveOrganizationResponseBody = {
  id: number;
  name: string;
};

export type AddChatOrganizationPayload = {
  organizationId: number;
}

export function saveOrganization(token: string, request: SaveOrganizationPayloadRequest): Promise<SaveOrganizationResponse> {
  const response = fetchPostMethod(`${serviceUrl}/api/organization/SaveOrganization`, token, request);
  return getResponseObjectWithoutBody(response, errorMessageKey);
}

export function addChatOrganization(token: string, request: number): Promise<AddChatOrganizationResponse> {
  const response = fetchPostMethod(`${serviceUrl}/api/organization/ChatAllOrganization/`, token, request);
  return getResponseObjectWithoutBody(response, errorMessageKey);
}

export type DemographicsQuestionsConfigurationModel = {
  organizationId: number;
  age: boolean;
  gender: boolean;
  completedYearOfEducation: boolean;
  maritalStatus: boolean;
  ethnicBackground?: boolean;
  motherCountry?: boolean;
  fatherCountry?: boolean;
  memberOfDisadvantagedOrMinorityGroup?: boolean;
  primaryCountryOfOrigin?: boolean;
  timeInOtherCountry?: boolean;
  visitedOrLivedCountries?: boolean;
  politicalOrientation?: boolean;
  politicalCommitment?: boolean;
  motherFormalEducation?: boolean;
  fatherFormalEducation?: boolean;
  primaryAreaOrWorkOrProfessionalInterest?: boolean;
  primaryLanguages?: boolean;
  totalYearsStudiedForeignLanguage?: boolean;
  incomeLevelOfHomeCountry?: boolean;
  academicStandingOrRank?: boolean;
  currentBeliefsOrCommitmentsRegardingReligion?: boolean;
  commitmentTowardFaithReligions?: boolean;
  interestInInternationalOrInterculturalLearning?: boolean;
  satisfactionLevelInternationalExperienceProgram?: boolean;
  participatedExperiencesCapabilities?: boolean;
  plansToParticipateExperiencesCapabilities?: boolean;
  participatedLearningCoursesOrProgramsCapabilities?: boolean;
  plansToParticipateLearningCoursesOrProgramsCapabilities?: boolean;
};

export class LoadDemographicsQuestionsConfigurationResponse extends BaseResponse<DemographicsQuestionsConfigurationModel> { }

export function loadDemographicsQuestionsConfiguration(token: string, organizationId: number): Promise<LoadDemographicsQuestionsConfigurationResponse> {
  const response = fetchGetMethod(`${serviceUrl}/api/organization/LoadDemographicsQuestionsConfiguration?organizationId=${organizationId}`, token);
  return getResponseObject<DemographicsQuestionsConfigurationModel>(response, errorMessageKey);
}

export type SaveDemographicsQuestionsConfigurationBody = {}
export class SaveDemographicsQuestionsConfigurationResponse extends BaseResponse<SaveDemographicsQuestionsConfigurationBody> { }

export function saveDemographicsQuestionsConfiguration(token: string, request: DemographicsQuestionsConfigurationModel): Promise<SaveDemographicsQuestionsConfigurationResponse> {
  const response = fetchPostMethod(`${serviceUrl}/api/organization/SaveDemographicsQuestionsConfiguration`, token, request);
  return getResponseObjectWithoutBody(response, errorMessageKey);
}