import { ListFormPageMessages } from '../types';

export const listFormsPageDE: ListFormPageMessages = {
    'app.listforms.title': 'Mein BEVI Dashboard',
    'app.listforms.subtitle': 'Über das BEVI-Dashboard können Sie am BEVI teilnehmen und Ihren BEVI-Berichte überprüfen und verwalten.',
    'app.listforms.organization': 'Organisation',
    'app.listforms.participant': 'Teilnehmer:in',
    'app.listforms.program': 'Programm',
    'app.listforms.take_by': 'Fälligkeitsdatum',
    'app.listforms.completed_on': 'Datum der Fertigstellung',
    'app.listforms.progress': 'Fortschritt',
    'app.listforms.status': 'Status',
    'app.listforms.custom_agreement_status': 'Status benutzerdefinierte Vereinbarung',
    'app.listforms.action': 'Start',
    'app.listforms.organization_tooltip': 'Über das BEVI-Dashboard können Sie am BEVI teilnehmen und Ihre BEVI-Berichte überprüfen und verwalten.',
    'app.listforms.program_tooltip': 'Der Name des Berichts, Programms oder der Erfahrung, für die Sie den BEVI absolvieren.',
    'app.listforms.take_by_tooltip': 'Dies ist das Datum, bis zu dem das BEVI ausgefüllt werden sollte',
    'app.listforms.completed_on_tooltip': 'Dies ist das Datum, an dem das BEVI von Ihnen ausgefüllt wurde',
    'app.listforms.progress_tooltip': 'Gibt an, zu wie viel Prozent Sie die BEVI-Administration abgeschlossen haben (100 % bedeuten das BEVI ist abgeschlossen)',
    'app.listforms.status_tooltip': 'Tooltip Statusspalte',
    'app.listforms.action_tooltip': 'Hier können Sie den BEVI ausfüllen, fertigstellen, wiederholen oder Ihren BEVI-Bericht einsehen. Das Feld, das Sie sehen, ist was Sie derzeit bearbeiten können.',
    'app.listforms.all': 'Alles',
    'app.listforms.available': 'Noch nicht gestartet',
    'app.listforms.onDemographics': 'Zur Demografie',
    'app.listforms.onScales': 'Zu BEVI Fragen',
    'app.listforms.onSubjective': 'Zu den gegebenen Antworten',
    'app.listforms.finished': 'Fertigstellen',
    'app.listforms.cancelled': 'Abgebrochen',
    'app.listforms.expired': 'Abgelaufen',
    'app.listforms.archived': 'Archiviert',
    'app.listforms.invalid': 'Ungültig',
    'app.listforms.start': 'BEVI ausfüllen',
    'app.listforms.continue': 'BEVI fertigstellen',
    'app.listforms.report': 'BEVI-Bericht überprüfen',
    'app.listforms.partner_report': 'Überprüfen Sie den Partnerbericht',
    'app.listforms.partner_change_report': 'Überprüfen Sie den Partnerwechselbericht',
    'app.listforms.restart': 'BEVI wiederholen',
    'app.listforms.confirmation_title': 'Anleitung',
    'app.listforms.retart_confirmation_title': 'BEVI neu starten',
    'app.listforms.restart.instruction.part1': 'Klicken Sie auf die Schaltfläche unten, um das BEVI neu zu starten.',
    'app.listforms.instruction.part1': 'Das folgende Tool besteht aus Hintergrundinformationen und 185 Fragen mit 20 Fragen pro Seite. ' +
        'Bitte lesen Sie jede Frage durch und wählen Sie eine der vier Optionen aus, die am besten beschreibt, wie Sie sich fühlen: ' +
        'stimme voll und ganz zu, stimme zu, stimme nicht zu, stimme überhaupt nicht zu.',
    'app.listforms.instruction.part2': 'Wenn Sie alle Fragen beantwortet haben, drücken Sie auf "Weiter", um fortzufahren. Sobald Sie auf "Weiter" gedrückt haben, können Sie nicht mehr zurückgehen und Ihre Antworten ändern.',
    'app.listforms.instruction.part3': 'Bevor Sie den BEVI ausfüllen können, müssen Sie angeben, dass Sie die Informationen über das BEVI gelesen haben und Ihr Einverständnis geben.',
    'app.listforms.instruction.press.start': 'Danke für Ihre Teilnahme. Wenn Sie bereit sind, drücken Sie bitte auf Start.',
    'app.listforms.comparative_individual_report': 'Vergelijk individueel rapport',
    'app.form.status_title': 'BEVI-Formular nicht verfügbar',
    'app.form.status_finished_subtitle': 'Sieht so aus, als wäre dieses Formular bereits beantwortet worden.',
    'app.form.status_finished_body': 'Wenn Sie Fragen zum Status des Formulars haben, wenden Sie sich bitte an die Verantwortlichen.',
    'app.form.status_cancelled_subtitle': 'Sieht so aus, als wäre dieses Formular abgebrochen worden',
    'app.form.status_cancelled_body': 'Wenn Sie Fragen zum Status des Formulars haben, wende Sie sich bitte an die Verantwortlichen.',
    'app.form.status_expired_subtitle': 'Sieht so aus, als wäre dieses Formular abgelaufen',
    'app.form.status_expired_body': 'Wenn Sie Fragen zum Status des Formulars haben, wenden Sie sich bitte an die Verantwortlichen.',
    'app.form.invalid_status.by_time': 'Ungültig aufgrund von Zeitüberschreitung',
    'app.form.invalid_status.by_number_of_questions': 'Ungültig aufgrund von Anzahl der Fragen',
    'app.form.invalid_status.by_consecutive_answers': 'Ungültig aufgrund von aufeinanderfolgenden Antworten',
    'app.form.invalid_status.by_common_answers': 'Ungültig aufgrund von gemeinsamen Antworten',
    'app.form.invalid_status.by_congruency': 'Ungültig aufgrund von Kongruenz',
    'app.form.invalid_status.by_consistency': 'Ungültig aufgrund von Übereinstimmung',
    'app.form.invalid_status.by_wrong_scale_value': 'Ungültig aufgrund von falschem Skalenwert',
    'app.form.custom_agreement.not_requested': 'Nicht angefordert',
    'app.form.custom_agreement.pending': 'Ausstehend',
    'app.form.custom_agreement.accepted': 'Akzeptiert',
    'app.form.custom_agreement.rejected': 'Abgelehnt',
    'app.form.actions.tooltip.send_custom_agreement': 'Benutzerdefinierte Vereinbarung senden',
    'app.form.actions.tooltip.resend_custom_agreement': 'Benutzerdefinierte Vereinbarung erneut senden',
    'app.form.actions.tooltip.show_report': 'Bericht anzeigen',
    'app.listforms.ai_interactions': 'KI-Interaktionen'
}
