import React from 'react';
import Select, { Props } from 'react-select';

import { IntlContext } from '../IntlProviderWrapper';
import { PageMessages } from '../locales/types';

export type SelectItem = {
    value: number | undefined;
    label: string;
};

type DropdownProps = Props<SelectItem> & {
    isMulti?: boolean;
    isInvalid?: boolean;
    defaultOptionId?: keyof (PageMessages);
    optionItens: Array<SelectItem>;
    onValueSelect?: (selectedValue: number | undefined, text: string) => void;
    onMultiValueSelect?: (selectedValue: number[], text: string[]) => void;
};

export const FormDropdown = (
    { optionItens, isInvalid, isMulti, defaultOptionId, onValueSelect, onMultiValueSelect, ...selectProps }: DropdownProps
): JSX.Element => {

    const { messages }: { messages?: PageMessages } = React.useContext(IntlContext);

    const getFormattedMessage = (id: keyof (PageMessages)): string => {
        if (messages) return messages[id].toString()
        return ''
    }

    const customStyles = {
        control: (base: Record<string, unknown>, state: any) => ({
            ...base,
            // state.isFocused can display different borderColor if you need it
            borderColor: state.isFocused
                ? '#ddd'
                : isInvalid ? 'red' : '#ddd',
            // overwrittes hover style
            '&:hover': {
                borderColor: state.isFocused
                    ? '#ddd'
                    : isInvalid ? 'red' : '#ddd'
            }
        }),
        menu: (provided: any) => ({ ...provided, zIndex: 9999 })
    }

    const defaultOption: string = (defaultOptionId && getFormattedMessage(defaultOptionId)) || '';
    return (
        <Select
            styles={customStyles}
            {...selectProps}
            isMulti={isMulti}
            isSearchable={selectProps === undefined ? false : selectProps.isSearchable}
            isClearable={selectProps === undefined ? true : selectProps.isClearable}
            options={optionItens}
            placeholder={defaultOption}
            menuIsOpen={optionItens.length === 0 ? false : undefined}
            onChange={(selectedItem) => {
                let label: string = '';
                let value: number | undefined;

                if (!isMulti) {
                    if (selectedItem !== null) {
                        //@ts-ignore
                        value = selectedItem.value;
                        //@ts-ignore
                        label = selectedItem.label;
                    }
                    //@ts-ignore
                    onValueSelect && onValueSelect(value, label);
                } else {
                    let values: number[] = [];
                    let texts: string[] = [];

                    const selectedItemsCasted = selectedItem as unknown as SelectItem[];
                    selectedItemsCasted?.forEach((item: SelectItem) => {
                        values.push(item.value || 0);
                        texts.push(item.label);
                    });
                    onMultiValueSelect && onMultiValueSelect(values, texts);
                }
            }}
        />
    );
}
