import { serviceUrl } from '../config/constants';
import {
    BaseResponse, fetchPostMethod, getResponseObject
} from '../rpc/api';

const getVirtualAccountErrorMessageKey = 'app.error.virtual_account.get_virtual_account';

export function getVirtualAccount(token: string, data: RequestVirtualAccount): Promise<BaseResponse<AiChatVirtualAccount[]>> {
    const response = fetchPostMethod<RequestVirtualAccount>(
        `${serviceUrl}/api/beingbevi/getVirtualAccount`, token, data);
    return getResponseObject<AiChatVirtualAccount[]>(response, getVirtualAccountErrorMessageKey);
}

export function addInteractions(token: string, data: RequestVirtualAccountCreate): Promise<BaseResponse<any>> {
    const response = fetchPostMethod<RequestVirtualAccountCreate>(
        `${serviceUrl}/api/beingbevi/addInteractions`, token, data);
    return getResponseObject<any>(response, getVirtualAccountErrorMessageKey);
}

export type RequestVirtualAccountCreate = {
    organizationId: number;
    interactions: number;
}

export type RequestVirtualAccount = {
    organizationId: number;
    month: number;
    year: number;
}

export interface AiChatVirtualAccount {
    balance: number;
    days: AiChatVirtualAccountDay[];
}

export interface AiChatVirtualAccountDay {
    day: number;
    debits: number;
    credits: number;
    finalBalance: number;
}
