import { InstitutionMessages } from '../types';

export const institutionCA: InstitutionMessages = {
  'app.institutions.title': 'Gestió de l’Organització',
  'app.institutions.new': 'Nou Organització',
  'app.institutions.search': 'Cercar',
  'app.institutions.list.name': 'Anomenar',
  'app.institutions.list.edit': 'Editar',
  'app.institutions.list.action': 'Acció',
  'app.institutions.add.title': 'Cercar Organització',
  'app.institutions.edit.title': 'Editar Organització',
  'app.institutions.add.successful_message': 'Organització creat amb èxit.',
  'app.institutions.add.error_message': 'Alguna cosa ha fallat. Si us plau, torna a provar després d’uns minuts.',
  'app.institutions.add.new_name': 'Nou nom',
  'app.institutions.add.button_save': 'Guardar',
  'app.institutions.add.hide_demographics_questions': 'Amaga Preguntes Demogràfiques',
  'app.institutions.add.demographics_extra_questions': 'Preguntes Addicionals de Demografia',
  'app.institutions.hide_demographics_questions.successful_message': 'Les preguntes de demografia s’han actualitzat correctament.',
  'app.institutions.demographics_questions.question': 'Pregunta',
  'app.institutions.demographics_questions.type': 'Tipus',
  'app.institutions.demographics_questions.type_0': 'Per defecte',
  'app.institutions.demographics_questions.type_1': 'Text',
  'app.institutions.demographics_questions.type_2': 'Opció única',
  'app.institutions.demographics_questions.add.title': 'Nova pregunta addicional',
  'app.institutions.demographics_questions.edit.title': 'Edita la pregunta addicional',
  'app.institutions.demographics_questions.title': 'Títol',
  'app.institutions.demographics_questions.options': 'Opcions',
  'app.institutions.demographics_questions.description': 'Descripció',
  'app.institutions.demographics_questions.delete': 'Esteu segur que suprimiu aquest element?',
  'app.institutions.chat.successful_message': "En pocs minuts, tots els usuaris podran utilitzar el xat d'IA.",
  'app.institutions.chat.add_chat_all': 'Afegeix un xat per a tots els usuaris',
  'app.institutions.chat.question_add_chat_all': "Confirmes que vols incloure el xat per a tots els usuaris d'aquesta organització?"
}