import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Loading } from '../../components/Loading';
import { QuestionPageHeader } from '../../components/QuestionPageHeader';
import { colors } from '../../config/colors';
import { appMessagesIds } from '../../locales/types';
import { FormDetailsExtraQuestion } from '../../services/listFormsService';
import { DemographicsQuestionsConfigurationModel } from '../../services/organizationService';
import { DemographicQuestionAnswers, DemographicQuestionAnswersValues } from '../../services/types';
import { CheckOptionsQuestion } from './components/CheckOptionsQuestion';
import { DropdownOptionsQuestion } from './components/DropdownOptionsQuestion';
import { EthnicQuestion } from './components/EthnicQuestion';
import { LanguagesSpeakQuestion } from './components/LanguagesSpeakQuestion';
import { NumericQuestions } from './components/NumericQuestions';
import { PrimaryWorkAreaQuestion } from './components/PrimaryWorkAreaQuestion';
import { ReligionScaleQuestion } from './components/ReligionScaleQuestion';
import { SliderOptions } from './components/SliderOptions';
import { ExtraQuestion } from './components/ExtraQuestion';
import * as Question from './library/questionDefinitions';

type Props = {
    answers: DemographicQuestionAnswers;
    extraQuestions?: FormDetailsExtraQuestion[],
    hideQuestions: DemographicsQuestionsConfigurationModel;
    onNextButtonClicked: () => void;
    onAnswerChanged: (key: keyof (DemographicQuestionAnswers), value: DemographicQuestionAnswersValues) => void;
    loading: boolean;
    error?: string;
};

export const DemographicQuestions = (
    { answers, extraQuestions, hideQuestions, loading, onNextButtonClicked, onAnswerChanged }: Props
): JSX.Element => {
    // TODO(rodrigo.santos): Separate the loading into loading and sending.
    if (loading) {
        return <Loading />
    }

    const smallSizeMd = '3';
    const mediumSizeMd = '6';
    let question = 0;

    return (
        <React.Fragment>
            <QuestionPageHeader />
            <h3 style={{ textAlign: 'center' }}>Background Information</h3>
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <Form>
                        { /* Previous Question 3 */}
                        {hideQuestions?.age === false && (<NumericQuestions
                            questionNumber={++question}
                            question={new Question.Age()}
                            optionsMd={'1'}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 4 */}
                        {hideQuestions?.gender === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.Gender()}
                            optionsMd={smallSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 4 (other) */}
                        {hideQuestions?.completedYearOfEducation === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.CompletedYearOfEducation()}
                            optionsMd={smallSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 6 */}
                        {hideQuestions?.maritalStatus === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.MaritalStatus()}
                            optionsMd={smallSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 7 */}
                        {hideQuestions?.ethnicBackground === false && (<EthnicQuestion
                            questionNumber={++question}
                            question={new Question.EthnicBackground()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 17 */}
                        {hideQuestions?.motherCountry === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.MotherBorn()}
                            optionsMd={smallSizeMd}
                            isSearchable={true}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 18 */}
                        {hideQuestions?.fatherCountry === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.FatherBorn()}
                            optionsMd={smallSizeMd}
                            isSearchable={true}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 8 */}
                        {hideQuestions?.memberOfDisadvantagedOrMinorityGroup === false && (<CheckOptionsQuestion
                            questionNumber={++question}
                            type='radio'
                            inline={true}
                            question={new Question.MinorityGroup()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 14 */}
                        {hideQuestions?.primaryCountryOfOrigin === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.PrimaryCountry()}
                            optionsMd={smallSizeMd}
                            isSearchable={true}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 15 */}
                        {hideQuestions?.timeInOtherCountry === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.TimeInOtherCountry()}
                            optionsMd={mediumSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 16 */}
                        {hideQuestions?.visitedOrLivedCountries === false && (<NumericQuestions
                            questionNumber={++question}
                            question={new Question.VisitCountries()}
                            optionsMd='1'
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 10 */}
                        {hideQuestions?.politicalOrientation === false && (<SliderOptions
                            mdLabel={3}
                            questionNumber={++question}
                            question={new Question.PoliticalOrientation()}
                            answerChange={onAnswerChanged}
                            minLabel='app.demographic.political_orientation_conservative_label'
                            maxLabel='app.demographic.political_orientation_liberal_label'
                            min={0} max={100}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {hideQuestions?.politicalCommitment === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.PoliticalCommitment()}
                            answerChange={onAnswerChanged}
                            optionsMd={mediumSizeMd}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {hideQuestions?.motherFormalEducation === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.MotherFormalEducation()}
                            answerChange={onAnswerChanged}
                            optionsMd={mediumSizeMd}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {hideQuestions?.fatherFormalEducation === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.FatherFormalEducation()}
                            answerChange={onAnswerChanged}
                            optionsMd={mediumSizeMd}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 9 */}
                        {hideQuestions?.primaryAreaOrWorkOrProfessionalInterest === false && (<PrimaryWorkAreaQuestion
                            questionNumber={++question}
                            question={new Question.PrimaryWorkArea()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 23 */}
                        {hideQuestions?.primaryLanguages === false && (<LanguagesSpeakQuestion
                            questionNumber={++question}
                            question={new Question.LanguagesSpeak()}
                            primaryLanguageAnswers={answers?.PrimaryLanguages}
                            answers={answers?.FluencyInLanguages}
                            otherAnswers={answers?.FluencyInLanguagesOthers}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 24 */}
                        {hideQuestions?.totalYearsStudiedForeignLanguage === false && (<NumericQuestions
                            questionNumber={++question}
                            question={new Question.YearsStudieForiegnLanguage()}
                            answerChange={onAnswerChanged}
                            optionsMd='1'
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 12 */}
                        {hideQuestions?.incomeLevelOfHomeCountry === false && (<SliderOptions
                            questionNumber={++question}
                            question={new Question.IncomeLevel()}
                            answerChange={onAnswerChanged}
                            minLabel='app.demographic.income_less_label'
                            maxLabel='app.demographic.income_more_label'
                            min={0} max={100}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 13 */}
                        {hideQuestions?.academicStandingOrRank === false && (<SliderOptions
                            questionNumber={++question}
                            question={new Question.AcademicRank()}
                            answerChange={onAnswerChanged}
                            minLabel='app.demographic.academic_rank_lowest_label'
                            maxLabel='app.demographic.academic_rank_highest_label'
                            min={0} max={100}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 11 */}
                        {hideQuestions?.commitmentTowardFaithReligions === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.Religion()}
                            optionsMd={mediumSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {hideQuestions?.currentBeliefsOrCommitmentsRegardingReligion === false && (<ReligionScaleQuestion
                            questionNumber={++question}
                            question={new Question.ReligionScale(
                                answers.CurrentBeliefsOrCommitmentsRegardingReligion,
                                answers.CurrentBeliefsOrCommitmentsRegardingReligionOther
                            )}
                            optionsMd={smallSizeMd}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 19 */}
                        {hideQuestions?.interestInInternationalOrInterculturalLearning === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.PersonalInterest()}
                            answerChange={onAnswerChanged}
                            optionsMd={smallSizeMd}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 22 */}
                        {hideQuestions?.satisfactionLevelInternationalExperienceProgram === false && (<DropdownOptionsQuestion
                            questionNumber={++question}
                            question={new Question.ExperienceSatisfaction()}
                            answerChange={onAnswerChanged}
                            optionsMd={smallSizeMd}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}


                        {/* Previous Question 20a */}
                        {hideQuestions?.participatedExperiencesCapabilities === false && (<CheckOptionsQuestion
                            questionNumber={++question}
                            type='checkbox'
                            question={new Question.ParticipatedExperiences()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 21a */}
                        {hideQuestions?.plansToParticipateExperiencesCapabilities === false && (<CheckOptionsQuestion
                            type='checkbox'
                            questionNumber={++question}
                            question={new Question.PlanParticipateExperiences()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 20b */}
                        {hideQuestions?.participatedLearningCoursesOrProgramsCapabilities === false && (<CheckOptionsQuestion
                            questionNumber={++question}
                            type='checkbox'
                            question={new Question.ParticipatedLearningCourses()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {/* Previous Question 21b */}
                        {hideQuestions?.plansToParticipateLearningCoursesOrProgramsCapabilities === false && (<CheckOptionsQuestion
                            questionNumber={++question}
                            type='checkbox'
                            question={new Question.PlanParticipateLearningCourses()}
                            answerChange={onAnswerChanged}
                            style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven}
                        />)}
                        {extraQuestions?.map(x => {
                            return <ExtraQuestion
                                questionNumber={++question}
                                answerChange={onAnswerChanged}
                                questionId={x.id}
                                title={x.title}
                                type={x.type}
                                extraQuestionOptions={x.extraQuestionOptions}
                                optionsMd={x.type === 1 ? 12 : 6}
                                style={question % 2 === 0 ? styles.questionRowOdd : styles.questionRowEven} />
                        })}
                    </Form>
                    <div className='button'>
                        <Button
                            disabled={loading}
                            style={styles.btnNext}
                            variant='primary'
                            onClick={onNextButtonClicked}
                        >
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                :
                                <FormattedMessage id={appMessagesIds['app.btn_next']} />
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const styles = {
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: 'transparent',
        border: '0',
    } as React.CSSProperties,
    h1BeviTitle: {
        marginTop: '0',
        width: '300px',
        marginBottom: '10px',
    } as React.CSSProperties,
    btnNext: {
        width: '150px',
        float: 'right',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    questionRowOdd: {
        padding: '16px',
        backgroundColor: 'transparent',
    } as React.CSSProperties,
    questionRowEven: {
        padding: '16px',
        backgroundColor: colors.white,
        borderRadius: '16px',
    } as React.CSSProperties,
};
