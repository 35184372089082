import { Select, Tag, UploadProps, Upload, Spin } from 'antd';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { valueColor } from '../UserProfilePermission';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { InboxOutlined } from '@ant-design/icons';
import { FaDownload } from 'react-icons/fa';
import { colors } from '../../../config/colors';

const { Dragger } = Upload;

const ReactExport: any = require('react-export-excel-xlsx-fix');

const ExcelFile = ReactExport.modules.ExcelFile;
const ExcelSheet = ReactExport.modules.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.modules.ExcelFile.ExcelColumn;

type Props = {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    addUserPermission: (permission: number[], file: File) => void;
    loading?: boolean;
};

const SelectMult = styled(Select)`
    .ant-select-selection-overflow {
        gap: 6px;
    }

    .ant-tag {
        display: flex;
        align-items: center;
    }
`;

export const PermissionModal = ({ openModal, setOpenModal, addUserPermission, loading }: Props): JSX.Element => {

    const [permissions, setPermissions] = useState<number[]>([1, 4, 8]);
    const [file, setFile] = useState<File>();

    const excelLoginColumn = GetFormattedMessage('app.create_program.excel.login_column');
    const fileTemplate = GetFormattedMessage('app.permission.download_tooltip');

    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={valueColor[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    const props: UploadProps = {
        name: 'file',
        beforeUpload(file) {
            if (['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
                setFile(file);
                return false;
            }
            return Upload.LIST_IGNORE;
        },
        maxCount: 1,
        onRemove: () => setFile(undefined),
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    };

    return (<Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
            <Modal.Title>
                <FormattedMessage id={'app.permission.add_permissions'} />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className='mb-3' as={Row}>
                    <Col md={12}>
                    <Form.Group className='mb-3'>
                            <ExcelFile
                                filename={`user_permissions`}
                                element={
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={["hover", "hover"]}
                                        overlay={<Tooltip id={`downloadTooltip`}> {fileTemplate} </Tooltip>}
                                    >
                                        <Button className=' bg-transparent'>
                                            <FaDownload color={colors.darkSlateBlue} />
                                        </Button>
                                    </OverlayTrigger>
                                }
                            >
                                <ExcelSheet
                                    data={[]}
                                    name={'user_permissions'}
                                >
                                    <ExcelColumn label={excelLoginColumn} value='login' />
                                </ExcelSheet>
                            </ExcelFile>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label><b>{GetFormattedMessage('app.permission.permissions')}</b></Form.Label>
                            <SelectMult
                                mode="multiple"
                                size='middle'
                                placeholder="Please select"
                                value={permissions}
                                style={{ width: '100%' }}
                                tagRender={tagRender}
                                optionFilterProp='label'
                                onChange={(value: any) => setPermissions(value)}
                                options={[{
                                    value: 1,
                                    label: 'INDIVIDUAL REPORT',
                                }, {
                                    value: 4,
                                    label: 'VISUALIZE - CHAT BOT',
                                }, {
                                    value: 8,
                                    label: 'CAN TALK TO CHAT BOT',
                                }, {
                                    value: 16,
                                    label: 'VISUALIZE - VIRTUAL ACCOUNT',
                                }
                                ]}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Col}>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <p>{GetFormattedMessage('app.create.user.drag.n.drop.text')}</p>
                                    <em>{GetFormattedMessage('app.create.user.dran.n.drop.available.files')}</em>
                                </p>

                            </Dragger>
                        </Form.Group>
                    </Col>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Spin spinning={loading}>
                <Button disabled={file == null || permissions == null || permissions.length === 0} variant='primary' onClick={() => addUserPermission(permissions!, file!)}>
                    <FormattedMessage id={'app.permission.add_permissions'} />
                </Button>
            </Spin>
        </Modal.Footer>
    </Modal>);
}
