import { appCA } from './ca/app';
import { consentPageCA } from './ca/consent';
import { counterPageCA } from './ca/counter';
import { createReportCA } from './ca/create_report';
import { createProgramCA } from './ca/createProgram';
import { dataDownloadCA } from './ca/dataDownload';
import { demographicQuestionsCA } from './ca/demographicQuestions';
import { discursiveQuestionsCA } from './ca/discursiveQuestions';
import { externalCA } from './ca/external';
import { individualReportPageCA } from './ca/individualReport';
import { internalTemplatePageCA } from './ca/internalTemplate';
import { listFormsPageCA } from './ca/listforms';
import { listOrganizationsCA } from './ca/listOrganizations';
import { listReportCA } from './ca/listReport';
import { loginPageCA } from './ca/login';
import { noInfoCA } from './ca/noInfo';
import { permissionCA } from './ca/permission';
import { reportsCA } from './ca/reports';
import { scaleQuestionsPageCA } from './ca/scaleQuestions';
import { timeCA } from './ca/time';
import { usersCA } from './ca/users';
import { PageMessages } from './types';
import { financialReportCA } from './ca/financialReport';
import { invitationCA } from './ca/invitation';
import { institutionCA } from './ca/institutions';
import { programsCA } from './ca/programs';
import { virtualAccountCA } from './ca/virtualAccount';

export const ca: PageMessages = {
    ...appCA,
    ...counterPageCA,
    ...loginPageCA,
    ...scaleQuestionsPageCA,
    ...consentPageCA,
    ...listFormsPageCA,
    ...demographicQuestionsCA,
    ...listOrganizationsCA,
    ...discursiveQuestionsCA,
    ...individualReportPageCA,
    ...internalTemplatePageCA,
    ...createProgramCA,
    ...usersCA,
    ...dataDownloadCA,
    ...noInfoCA,
    ...createReportCA,
    ...reportsCA,
    ...timeCA,
    ...listReportCA,
    ...permissionCA,
    ...externalCA,
    ...financialReportCA,
    ...invitationCA,
    ...institutionCA,
    ...programsCA,
    ...virtualAccountCA
}
