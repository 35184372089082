import { TimeMessages } from '../types';

export const timeES: TimeMessages = {
    'app.time.day': 'día',
    'app.time.days': 'días',
    'app.time.hour': 'hora',
    'app.time.hours': 'horas',
    'app.time.minute': 'minuto',
    'app.time.minutes': 'minutos',
    'app.time.second': 'segundo',
    'app.time.seconds': 'segundos',
    'app.time.and': 'y',
}
