import { appDE } from './de/app';
import { consentPageDE } from './de/consent';
import { counterPageDE } from './de/counter';
import { createReportDE } from './de/create_report';
import { createProgramDE } from './de/createProgram';
import { dataDownloadDE } from './de/dataDownload';
import { demographicQuestionsDE } from './de/demographicQuestions';
import { discursiveQuestionsDE } from './de/discursiveQuestions';
import { externalDE } from './de/external';
import { individualReportPageDE } from './de/individualReport';
import { internalTemplatePageDE } from './de/internalTemplate';
import { listFormsPageDE } from './de/listforms';
import { listOrganizationsDE } from './de/listOrganizations';
import { listReportDE } from './de/listReport';
import { loginPageDE } from './de/login';
import { noInfoDE } from './de/noInfo';
import { permissionDE } from './de/permission';
import { reportsDE } from './de/reports';
import { scaleQuestionsPageDE } from './de/scaleQuestions';
import { timeDE } from './de/time';
import { usersDE } from './de/users';
import { PageMessages } from './types';
import { financialReportDE } from './de/financialReport';
import { invitationDE } from './de/invitation';
import { institutionDE } from './de/institutions';
import { programsDE } from './de/programs';
import { virtualAccountDE } from './de/virtualAccount';

export const de: PageMessages = {
    ...appDE,
    ...counterPageDE,
    ...loginPageDE,
    ...scaleQuestionsPageDE,
    ...consentPageDE,
    ...listFormsPageDE,
    ...demographicQuestionsDE,
    ...listOrganizationsDE,
    ...discursiveQuestionsDE,
    ...individualReportPageDE,
    ...internalTemplatePageDE,
    ...createProgramDE,
    ...usersDE,
    ...dataDownloadDE,
    ...noInfoDE,
    ...createReportDE,
    ...reportsDE,
    ...timeDE,
    ...listReportDE,
    ...permissionDE,
    ...externalDE,
    ...financialReportDE,
    ...invitationDE,
    ...institutionDE,
    ...programsDE,
    ...virtualAccountDE
}
