import React from 'react';
import { DecileProfileTable } from '../../../components/DecileProfileTable';
import { ScaleGroup, DecileProfileItem } from '../../../services/reportService';
import { GroupReportDecileProfile } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { getScaleTitle } from '../../../utils/reportHelper';
import { isEmpty } from 'lodash';

type Props = {
    decileProfile: GroupReportDecileProfile[];
    subGroupName?: string[];
}

const buildDecile = (decileProfile: GroupReportDecileProfile[],
    scale: ScaleGroup, index: number, subGroupName?: string[]) => {
    const decileGroup: DecileProfileItem[] = [];
    const scaleTitle = getScaleTitle(scale);

    let i = 0;
    for (i = 0; i < decileProfile.length; i++) {
        // const profile = decileProfile[i];
        const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';
        let profile = decileProfile[i];
        if(!isEmpty(name)) {
            profile = decileProfile.filter(t => t.groupName === name)[0];
        }

        decileGroup.push({
            decileCode: scale,
            //@ts-ignore
            decileTitle: `${index > 0 ? index + '. ' : ''}${GetFormattedMessage(scaleTitle)} ${name && '(' + name + ')'}`,
            value1: profile.scales[scale][0],
            value2: profile.scales[scale][1],
            value3: profile.scales[scale][2],
            value4: profile.scales[scale][3],
            value5: profile.scales[scale][4],
            value6: profile.scales[scale][5],
            value7: profile.scales[scale][6],
            value8: profile.scales[scale][7],
            value9: profile.scales[scale][8],
            value10: profile.scales[scale][9],
        });
    }

    return decileGroup;
}

export const DecileProfile = (props: Props): JSX.Element => {
    const decileProfile = props.decileProfile;
    const subGroupName = props.subGroupName;
    return (
        <React.Fragment>
            <DecileProfileTable
                consistency={buildDecile(decileProfile, ScaleGroup.Consistency, 0, subGroupName)}
                congruency={buildDecile(decileProfile, ScaleGroup.Congruency, 0, subGroupName)}
                negativeLifeEvents={buildDecile(decileProfile, ScaleGroup.NegativeLifeEvents, 1, subGroupName)}
                needsClosure={buildDecile(decileProfile, ScaleGroup.NeedsClosure, 2, subGroupName)}
                needsFulfillment={buildDecile(decileProfile, ScaleGroup.NeedsFulfillment, 3, subGroupName)}
                identityDiffusion={buildDecile(decileProfile, ScaleGroup.IdentityDiffusion, 4, subGroupName)}
                basicOpenness={buildDecile(decileProfile, ScaleGroup.BasicOpenness, 5, subGroupName)}
                selfCertitude={buildDecile(decileProfile, ScaleGroup.SelfCertitude, 6, subGroupName)}
                basicDeterminism={buildDecile(decileProfile, ScaleGroup.BasicDeterminism, 7, subGroupName)}
                socioemotionalConvergence={buildDecile(decileProfile, ScaleGroup.SocioemotionalConvergence, 8, subGroupName)}
                physicalResonance={buildDecile(decileProfile, ScaleGroup.PhysicalResonance, 9, subGroupName)}
                emotionalAttunement={buildDecile(decileProfile, ScaleGroup.EmotionalAttunement, 10, subGroupName)}
                selfAwereness={buildDecile(decileProfile, ScaleGroup.SelfAwereness, 11, subGroupName)}
                meaningQuest={buildDecile(decileProfile, ScaleGroup.MeaningQuest, 12, subGroupName)}
                religiousTraditionalism={buildDecile(decileProfile, ScaleGroup.ReligiousTraditionalism, 13, subGroupName)}
                genderTraditionalism={buildDecile(decileProfile, ScaleGroup.GenderTraditionalism, 14, subGroupName)}
                socioculturalOpenness={buildDecile(decileProfile, ScaleGroup.SocioculturalOpenness, 15, subGroupName)}
                ecologicalResonance={buildDecile(decileProfile, ScaleGroup.EcologicalResonance, 16, subGroupName)}
                globalResonance={buildDecile(decileProfile, ScaleGroup.GlobalResonance, 17, subGroupName)}
            />
        </React.Fragment>
    )
}