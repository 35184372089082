import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import Column from 'antd/lib/table/Column';
import React, { useState } from 'react';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';

import { SelectItem } from '../../../components/FormDropdown';
import { ParticipantModel } from '../../../services/reportService';
import { GetCountriesOptions, GetEthnicOrientationOptions, GetMaritialStatusOptions, GetPrimaryWorkAreaOptions, GetReligiousOrientationOptionsForReports } from '../../../utils/demographHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { LineEditTextGroup } from './LineEditTextGroup';
import { SelectOptionGroup } from './SelectOptionGroup';
import { UserFormForView, UserFormsPerOrganizationForView } from './Step4Helper';
import { paginationDefaultPageSize } from '../../../config/constants';
import { SanitizeDoubleByteNumber } from '../../../utils/stringHelper';

type Props = {
    userForms: UserFormsPerOrganizationForView[];
    maxNumberOfForms?: number;
    selectedCountries?: number[];
    selectedEthnics?: number[];
    selectedRelationships?: number[];
    selectedReligions?: number[];
    selectedPrimaryAreaOfWorkCodes?: number[];
    onSelectCountriesChanged: (values: number[] | undefined) => void;
    onSelectEthnicsChanged: (values: number[] | undefined) => void;
    onSelectRelationshipsChanged: (values: number[] | undefined) => void;
    onSelectReligionsChanged: (values: number[] | undefined) => void;
    onSelectPrimaryAreaOfWorkCodesChanged: (values: number[] | undefined) => void;
    onMaxNumberFormsChanged: (value: number | undefined) => void;
    onUserFormReportChanged: (participants: ParticipantModel[], changeAlias: boolean) => void;
};

export const Step3Longitudinal = (
    {
        selectedCountries, selectedEthnics, selectedRelationships, selectedReligions, selectedPrimaryAreaOfWorkCodes,
        maxNumberOfForms, userForms,
        onUserFormReportChanged, onSelectCountriesChanged, onSelectEthnicsChanged, onSelectRelationshipsChanged, onSelectReligionsChanged,
        onSelectPrimaryAreaOfWorkCodesChanged, onMaxNumberFormsChanged
    }: Props
): JSX.Element => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const countriesOptions = GetCountriesOptions();
    const countriesValues: SelectItem[] = selectedCountries?.map((value: number) => ({
        value: value,
        label: countriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const ethnicOrientationOptions = GetEthnicOrientationOptions();
    const ethnicOrientationValues: SelectItem[] = selectedEthnics?.map((value: number) => ({
        value: value,
        label: ethnicOrientationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const relationshipOptions = GetMaritialStatusOptions();
    const relationshipValues: SelectItem[] = selectedRelationships?.map((value: number) => ({
        value: value,
        label: relationshipOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const religionsOptions = GetReligiousOrientationOptionsForReports();
    const religionsValues: SelectItem[] = selectedReligions?.map((value: number) => ({
        value: value,
        label: religionsOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const primaryAreaOfWorkCodesOptions = GetPrimaryWorkAreaOptions();
    const primaryAreaOfWorkCodesValues: SelectItem[] = selectedPrimaryAreaOfWorkCodes?.map((value: number) => ({
        value: value,
        label: primaryAreaOfWorkCodesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    return (
        <React.Fragment>
            <Table
                dataSource={userForms}
                pagination={userForms.length > paginationDefaultPageSize ? defaultPagination : false}
                key={'step3-logitudinal-user-table'}
                rowKey={(record: UserFormsPerOrganizationForView, index?: number) => record.name + index}
            >
                <Column
                    title={GetFormattedMessage('app.create_report.step3_user_exporting_column_title')}
                    key={'user-exporting-column'}
                    render={(_, record: UserFormsPerOrganizationForView) => {
                        const userKey = record.name +
                            record.users.reduce<string>((previousValue, currentValue) => {
                                return previousValue + currentValue.userName + currentValue.formId
                            }, "");
                        const exporting = record.users.reduce<boolean>((previousValue, currentValue) => {
                            return previousValue && currentValue.exporting
                        }, true);
                        return (
                            <FormCheck
                                id={userKey}
                                key={userKey}
                                type='switch'
                                checked={exporting}
                                label={''}
                                onChange={() => {
                                    const participants: ParticipantModel[] = record.users.map((element: UserFormForView) => ({
                                        id: element.formId,
                                        alias: element.alias
                                    }));
                                    onUserFormReportChanged(participants, /*changeAlias*/ false);
                                }}
                            />
                        );
                    }}
                />
                <Column
                    title={GetFormattedMessage('app.create_report.step3_user_name_column_title')}
                    key={'user-name-key'}
                    render={(_, record: UserFormsPerOrganizationForView) => record.name}
                />
            </Table>
            <br />
            <br />
            <Form as={Row}>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_countries_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_gender'
                        value={countriesValues}
                        options={countriesOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectCountriesChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedCountries && selectedCountries.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_countries_lbl')}
                            <ul>
                                {selectedCountries.map((countryValue: number) =>
                                    <li key={countryValue}>
                                        {countriesOptions.find(country => country.value === countryValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_ethnics_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_gender'
                        disableDefaultText={true}
                        value={ethnicOrientationValues}
                        options={ethnicOrientationOptions}
                        isSelectedOption={true}
                        onFilterChange={onSelectEthnicsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedEthnics && selectedEthnics.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_ethnics_lbl')}
                            <ul>
                                {selectedEthnics.map((ethnicValue: number) =>
                                    <li key={ethnicValue}>
                                        {ethnicOrientationOptions.find(e => e.value === ethnicValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_relationship_status_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_marital_status'
                        value={relationshipValues}
                        options={relationshipOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectRelationshipsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedRelationships && selectedRelationships.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_relationship_status_lbl')}
                            <ul>
                                {selectedRelationships.map((relationshipValue: number) =>
                                    <li key={relationshipValue}>
                                        {relationshipOptions.find(e => e.value === relationshipValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_religion_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_religious_orientation'
                        value={religionsValues}
                        options={religionsOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectReligionsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedReligions && selectedReligions.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_religion_lbl')}
                            <ul>
                                {selectedReligions.map((religionValue: number) =>
                                    <li key={religionValue}>
                                        {religionsOptions.find(e => e.value === religionValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_primary_area_work_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_primary_area_of_work'
                        value={primaryAreaOfWorkCodesValues}
                        options={primaryAreaOfWorkCodesOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectPrimaryAreaOfWorkCodesChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedPrimaryAreaOfWorkCodes && selectedPrimaryAreaOfWorkCodes.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_primary_area_work_lbl')}
                            <ul>
                                {selectedPrimaryAreaOfWorkCodes.map((primaryAreaOfWorkValue: number) =>
                                    <li key={primaryAreaOfWorkValue}>
                                        {primaryAreaOfWorkCodesOptions.find(e => e.value === primaryAreaOfWorkValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_number_of_forms_lbl')}
                    </Form.Label>
                    <LineEditTextGroup
                        value={maxNumberOfForms?.toString()}
                        useSwitchToDisable={false}
                        onFilterChange={(value: string | undefined) => {
                            const number = Number(SanitizeDoubleByteNumber(value));
                            onMaxNumberFormsChanged(!Number.isNaN(number) ? number : undefined);
                        }}
                    />
                </Form.Group>
            </Form>
        </React.Fragment>
    )
}
