import { TimeMessages } from '../types';

export const timeEN: TimeMessages = {
  'app.time.day': 'day',
  'app.time.days': 'days',
  'app.time.hour': 'hour',
  'app.time.hours': 'hours',
  'app.time.minute': 'minute',
  'app.time.minutes': 'minutes',
  'app.time.second': 'second',
  'app.time.seconds': 'seconds',
  'app.time.and': 'and',
}
