import { ProgramsMessages } from '../types';

export const programsZHTW: ProgramsMessages = {
  'app.programs.add.title': 'New Program',
  'app.programs.rename': 'Rename program',
  'app.programs.edit.title': 'Edit Program',
  'app.programs.add.successful_message': 'Program was successful updated.',
  'app.programs.add.error_message': 'Something went wrong, please, try again in a few minutes.',
  'app.programs.add.new_name': 'New name',
  'app.programs.add.button_save': 'Save',
  'app.programs.chat.successful_message': 'In a few minutes, all users will be able to use AI chat.',
  'app.programs.chat.add_chat_all': 'Add Chat for all Users',
  'app.programs.chat.question_add_chat_all': 'Are you sure you want to include chat for all users in this program?'
}