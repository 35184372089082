import { serviceUrl } from '../config/constants';
import {
    BaseResponse, fetchBasicPostMethod, fetchPostMethod, fetchPostMethodWithoutToken,
    getResponseObject, getResponseObjectWithoutBody
} from '../rpc/api';
import { UserType } from './usersService';

const logonErrorMessageKey = 'app.error.logon';
const forgotPasswordErrorMessageKey = 'app.error.forgot_password';
const changePasswordErrorMessageKey = 'app.error.change_password';

export type TokenInfo = {
    id: number;
    login: string;  // user email.
    name: string;
    first_access: boolean;
    user_type: UserType;
    isAnonymous: boolean;
    isExternal: string;
    formId?: string;
    exp: number;
    capabilities?: string;
};

export type LogonBody = {
    accessToken?: string;
}
export class LogonResponse extends BaseResponse<LogonBody> { }

export function login(username: string, password: string): Promise<LogonResponse> {
    const body = { login: username, password: password };
    const response = fetchBasicPostMethod<typeof body>(`${serviceUrl}/api/access/login`, username, password, body);
    return getResponseObject<LogonBody>(response, logonErrorMessageKey);
}

export type ForgotPasswordBody = {}
export class ForgotPasswordResponse extends BaseResponse<ForgotPasswordBody> { }

export function forgotPassword(username: string): Promise<ChangePasswordResponse> {
    const body = { Login: username };
    const response = fetchPostMethodWithoutToken<typeof body>(`${serviceUrl}/api/access/ForgotPassword`, body);
    return getResponseObjectWithoutBody(response, forgotPasswordErrorMessageKey);
}

export type ChangePasswordBody = {}
export class ChangePasswordResponse extends BaseResponse<ChangePasswordBody> { }

export function changePassword(token: string, newPassword: string): Promise<ChangePasswordResponse> {
    const body = { password: newPassword };
    const response = fetchPostMethod<typeof body>(`${serviceUrl}/api/user/UpdatePassword`, token, body);
    return getResponseObjectWithoutBody(response, changePasswordErrorMessageKey);
}

export type FirstAccessChangePasswordBody = {
    accessToken?: string;
}
export class FirstAccessChangePasswordResponse extends BaseResponse<FirstAccessChangePasswordBody> { }

export function changePasswordFirstAccess(token: string, newPassword: string): Promise<FirstAccessChangePasswordResponse> {
    const body = { password: newPassword };
    const response = fetchPostMethod<typeof body>(`${serviceUrl}/api/user/UpdatePasswordFirstAccess`, token, body);
    return getResponseObject<FirstAccessChangePasswordBody>(response, changePasswordErrorMessageKey);
}

export type RegisterThroughAnInvitationBody = {
    accessToken?: string;
}
export class RegisterThroughAnInvitationResponse extends BaseResponse<RegisterThroughAnInvitationBody> { }

export function registerThroughAnInvitation(
    name: string, email: string, password: string, subprogramId: number, language: number
): Promise<RegisterThroughAnInvitationResponse> {
    const body = {
        SubProgram: subprogramId,
        Name: name,
        Email: email,
        Password: password,
        Language: language
    };
    const response = fetchPostMethodWithoutToken<typeof body>(`${serviceUrl}/api/access/Invite`, body);
    return getResponseObject<RegisterThroughAnInvitationBody>(response, changePasswordErrorMessageKey);
}
