import { UsersMessages } from '../types';

const users: UsersMessages = {
    'app.users.listuser.title': 'User:innen verwalten',
    'app.users.name': 'Username',
    'app.users.login': 'E-Mail',
    'app.users.status.inactive': 'Inaktive',
    'app.users.status.active': 'Aktive',
    'app.users.status': 'Status',
    'app.create_batch_user.excel.email_column': 'E-Mail',
    'app.create_batch_user.excel.line_number_column': 'Zeilennummer',
    'app.create_batch_user.excel.user_error_column': 'Fehler',
    'app.create_batch_user.excel.download_error_details': 'Download-Details',
    'app.create_batch_user.error_already_exists': 'User existiert bereits',
    'app.create_batch_user.error_invalid_line': 'Ungültige Zeile',
    'app.create_batch_user.error_wrong_email': 'User mit falscher E-Mail-Adresse',
    'app.create_batch_user.error_missing_name': 'Username fehlt',
    'app.create_batch_user.error_details_filename': 'Fehlerdetails',
    'app.user.organization': 'Organisation',
    'app.user.select_organization': 'Organisation auswählen.',
    'app.user.select_program': 'Programm oder Erfahrung auswählen',
    'app.user.program': 'Programm',
    'app.users.listuser.action': 'Aktion',
    'app.users.listuser.reset_password': 'Passwort zurücksetzen',
    'app.users.listuser.details': 'User Management',
    'app.users.listuser.programs_action': 'Programme and Erfahrungen',
    'app.users.add_new_user': 'Neuer User',
    'app.user_programs.programName': 'Name Programm oder Erfahrung',
    'app.user_programs.subprogramName': 'Name BEVI Administration',
    'app.user_programs.status': 'Status',
    'app.user_programs.completedOn': 'Abgeschlossen am',
    'app.user_programs.invalid_reason': 'Ungültiger Grund',
    'app.listorganizations.input_username_placeholder': 'User-Name eingeben',
    'app.listorganizations.input_useremail_placeholder': 'User E-Mail-Adresse eingeben',
    'app.create.batch_user_error': 'Beim Versuch, mehrere Benutzer:innen für die Organisation anzulegen, sind ein oder mehrere Fehler aufgetreten. Für weitere Details laden Sie die Datei mit den Fehlerinformationen herunter.',
    'app.create.user.title': 'Neuen User:innen anlegen',
    'app.create.user.single.user': 'Geben Sie eine einzige E-Mail-Adresse für eine Person an, die befugt ist, das BEVI auszufüllen und/oder zu verwalten.',
    'app.create.user.multiple.users': 'Geben Sie die E-Mail-Adressen der Personen an, die befugt sind, das BEVI auszufüllen.',
    'app.create.user.choose.option.new.user': 'Wählen Sie die richtige Option aus den beiden folgenden Möglichkeiten aus.',
    'app.create.user.language_codes': 'Sprachencodes',
    'app.create.user.download.template.file': 'Lade Vorlagendatei herunter.',
    'app.create.user.download.data.example': 'Lade Beispieldatei herunter',
    'app.create.user.upload.file': 'Hochladen',
    'app.create.user.drag.n.drop.text': 'Ziehen Sie mehrere Dateien hier hinein, oder klicken Sie, um Dateien auszuwählen',
    'app.create.user.dran.n.drop.available.files': '(Nur *.xlsx und *.xls Dateien werden akzeptiert)',
    'app.create.user.remove': 'Entfernen',
    'app.create.user.selected.file': 'Ausgewählt: ',
    'app.create.user.create.multiple.user.title': 'Geben Sie die E-Mail-Adressen der Personen an, die befugt sind, das BEVI auszufüllen.',
    'app.create.user.create.single.user.title': 'User erstellen',
    'app.create.user.table.column_actions': 'Organisation entfernen',
    'app.create.user.edit.single.user.title': 'User Management',
    'app.create.user.is.anonymous': 'Ist Anonym',
    'app.create.user.user.type': 'User-Typ',
    'app.create.user.language': 'E-Mail Sprache',
    "app.create.user.capability_visualize_user": "BENUTZER VISUALISIEREN",
    "app.create.user.capability_add_user": "BENUTZER HINZUFÜGEN",
    "app.create.user.capability_reset_password_user": "PASSWORT ZURÜCKSETZEN - BENUTZER",
    "app.create.user.capability_manage_user": "BENUTZER VERWALTEN",
    "app.create.user.capability_visualize_user_programs": "BENUTZERPROGRAMME VISUALISIEREN",
    "app.create.user.capability_visualize_program_and_experience": "PROGRAMM UND ERFAHRUNG VISUALISIEREN",
    "app.create.user.capability_add_program_and_experience": "PROGRAMM UND ERFAHRUNG HINZUFÜGEN",
    "app.create.user.capability_rename_program_and_experience": "PROGRAMM UND ERFAHRUNG UMBENENNEN",
    "app.create.user.capability_add_subprogram": "UNTERPROGRAMM HINZUFÜGEN",
    "app.create.user.capability_edit_subprogram": "UNTERPROGRAMM BEARBEITEN",
    "app.create.user.capability_manage_subprogram": "UNTERPROGRAMM VERWALTEN",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "QR-CODE ZUM EINLADEN NEUER BENUTZER ANZEIGEN - UNTERPROGRAMM",
    "app.create.user.capability_visualize_report": "BERICHT VISUALISIEREN",
    "app.create.user.capability_add_report": "BERICHT HINZUFÜGEN",
    'app.user.profile.participant': 'Teilnehmer:innen',
    'app.user.profile.programAdmin': 'Programm-Administrator:in',
    'app.user.profile.institutional': 'Institutions-Administrator:in',
    'app.user.profile.accountManager': 'Account-Manager:in',
    'app.user.profile.superAdmin': 'Super-Administrator:in',
    'app.user.language.message': '(Die Sprache, in der der Nutzer E-Mails erhält)',
    'app.user.successfulMessage': 'User wurde erfolgreich erstellt',
    'app.edit.user.confirmation_title_fail': 'Oh nein! Leider konnte keine Aktualisierung durchgeführt werden',
    'app.edit.user.confirmation_body_fail': 'Der User konnte nicht aktualisiert werden. Bitte überprüfen Sie die angegebenen Daten, die Internetverbindung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, sprechen Sie mit der/dem Kundenbetreuer:in.',
    'app.edit.user.confirmation_body_fail_already_exists': 'Der User konnte nicht aktualisiert werden, weil ein anderer User die angegebene E-Mail-Adresse bereits hat.',
    'app.edit.user.confirmation_body_fail_wrong_email': 'Der User konnte nicht aktualisiert werden, weil die angegebene E-Mail ungültig ist.',
    'app.edit.user.confirmation_title_success': 'Erfolgreich aktualisiert',
    'app.edit.user.confirmation_body_success': 'Der User wurde aktualisiert und Sie können ihn jetzt in der User-Liste sehen, wohin Sie weitergeleitet werden.',
    'app.create.user.confirmation_title_fail': 'Oh nein! Leider hat das Erstellen nicht funktioniert',
    'app.create.user.confirmation_body_fail': 'Der User konnte nicht erstellt werden. Bitte überprüfen Sie die angegebenen Daten, die Internetverbindung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, sprechen Sie mit der/dem Kundenbetreuer:in.',
    'app.create.user.confirmation_title_success': 'Erfolgreich erstellt',
    'app.create.user.confirmation_body_success': 'Der User wurde erfolgreich erstellt.',
    'app.create.user.confirmation_body_fail_already_exists': 'Der User konnte nicht erstellt werden, weil ein anderer User die angegebene E-Mail-Adresse bereits hat.',
    'app.create.user.confirmation_body_fail_wrong_email': 'Der User konnte nicht erstellt werden, weil die angegebene E-Mail ungültig ist.',
    'app.reset_password.title': 'PASSWORT ZURÜCKSETZEN',
    'app.reset_password.body': 'Wenn Sie auf "PASSWORT ZURÜCKSETZEN" klicken, setzten Sie vorübergehend das Passwort für  <b>{username}</b> zurück.<br/><br/>Sobald sie sich im System angemeldet haben, können sie ihr Passwort ändern.',
    'app.reset_password.successful_message': '<b>{username}</b> wurde erfolgreich aktualisiert',
    'app.reset_password.error_message': 'Etwas ist schief gelaufen. Bitte versuchen Sie es in ein paar Minuten erneut.',
    'app.reset_password.new_temporary_password': 'Tippen Sie unten das vorübergehende Passwort ein',
    'app.reset_password.new_password': 'Neues Passwort',
    'app.reset_password.button_reset': 'Passwort zurücksetzen',
    'app.create.user.send_welcome_email': 'Willkommens-E-Mail senden',
    "app.users_type.select": "Wählen Sie einen Benutzertyp aus",
    "app.users_type.all": "Alle",
    "app.users_type.participant": "Teilnehmer",
    "app.users_type.program_admin": "Programmadministrator",
    "app.users_type.institutional": "Institutionell",
    "app.users_type.account_manager": "Kontoverwalter",
    "app.users_type.super_admin": "Superadministrator",
    "app.users_type.external_partner": "Externer Partner"
}

export const usersDE: UsersMessages =
    { ...users }
