import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { TokenInfo } from '../../../services/loginService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { HasPermissionCapabilityProgramAdmin, IsAccountManager, IsSuperAdmin } from '../../../utils/userHelper';
import { UserPermissions, UserType } from '../../../services/usersService';
import { AppProps, appLogic } from '../../../redux/appLogic';
import { useValues } from 'kea';

type Props = {
    organizations: SelectItem[];
    searchUserData: { organizationId?: number, userNameQuery: string, userEmailQuery: string, userType: number };
    searchHandleClick: (organizationId: number, userNameQuery: string, userEmailQuery: string, userType?: number) => void;
    onCreateUserHandleClick: () => void;
    userInfo: TokenInfo;
};

export const FilterUsers = (
    { organizations, searchUserData, searchHandleClick, onCreateUserHandleClick, userInfo
    }: Props): JSX.Element => {
    const [organizationId, setOrganization] = useState<number | undefined>(searchUserData.organizationId);
    const [userNameQuery, setUserNameQuery] = useState<string>(searchUserData.userNameQuery);
    const [userEmailQuery, setUserEmailQuery] = useState<string>(searchUserData.userEmailQuery);
    const [userType, setUserType] = useState<number | undefined>(searchUserData.userType);

    const { tokenInfo }: AppProps = useValues(appLogic);

    const userTypes: SelectItem[] = [{
        label: GetFormattedMessage('app.users_type.all'),
        value: 0
    }, {
        label: GetFormattedMessage('app.users_type.participant'),
        value: UserType.participant
    }, {
        label: GetFormattedMessage('app.users_type.program_admin'),
        value: UserType.programAdmin
    }, {
        label: GetFormattedMessage('app.users_type.institutional'),
        value: UserType.institutional
    }, {
        label: GetFormattedMessage('app.users_type.account_manager'),
        value: UserType.accountManager
    }, {
        label: GetFormattedMessage('app.users_type.super_admin'),
        value: UserType.superAdmin
    }, {
        label: GetFormattedMessage('app.users_type.external_partner'),
        value: UserType.externalPartner
    }];

    const disabledSearch = useMemo(() => {
        if (organizationId != null || IsSuperAdmin(userInfo) || IsAccountManager(userInfo))
            return false;
        return true;
        // eslint-disable-next-line
    }, [organizationId])

    const selectedOrganization = organizations.find((item: SelectItem) => item.value === organizationId);
    const selectedUserType = userTypes.find((item) => item.value === userType);

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {GetFormattedMessage('app.users.listuser.title')}
                    </h2>
                    {HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canAddUser) && <ButtonWithIcon
                        textId={'app.users.add_new_user'}
                        onClick={onCreateUserHandleClick}
                        icon={<IoMdAddCircleOutline />}
                    />}
                </div>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        <Col md={3}>
                            <FormDropdown
                                value={selectedOrganization}
                                isSearchable={true}
                                defaultOptionId={'app.listorganizations.select_organization'}
                                onValueSelect={(value: number | undefined) => {
                                    setOrganization(value);
                                }}
                                optionItens={organizations}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                value={userNameQuery}
                                as='input'
                                placeholder={GetFormattedMessage('app.listorganizations.input_username_placeholder')}
                                onChange={(x: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => { setUserNameQuery(x.currentTarget.value); }}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                value={userEmailQuery}
                                as='input'
                                placeholder={GetFormattedMessage('app.listorganizations.input_useremail_placeholder')}
                                onChange={(x: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => { setUserEmailQuery(x.currentTarget.value); }}
                            />
                        </Col>
                        <Col md={3}>
                            <FormDropdown
                                value={selectedUserType}
                                isSearchable={true}
                                placeholder={GetFormattedMessage('app.users_type.select')}
                                defaultOptionId={'app.users_type.all'}
                                onValueSelect={(value: number | undefined) => {
                                    setUserType(value);
                                }}
                                optionItens={userTypes}
                            />
                        </Col>
                    </Row>
                </div>

                <div style={styles.buttonSearchDiv}>
                    <ButtonWithIcon
                        disabled={disabledSearch}
                        textId={'app.btn_search'}
                        onClick={() => { searchHandleClick(Number(organizationId), userNameQuery, userEmailQuery, userType) }}
                        icon={<FaSearch />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'right',
        paddingRight: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
};
