import { TokenInfo } from "../services/loginService";
import { UserPermissions, UserType } from "../services/usersService";

export function IsParticipant(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.participant.toString();
}

export function IsProgramAdmin(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.programAdmin.toString();
}

export function IsInstitutional(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.institutional.toString();
}

export function IsAccountManager(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.accountManager.toString();
}

export function IsSuperAdmin(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.superAdmin.toString();
}

export function HasPermissionCapability(tokenInfo: TokenInfo, userPermission: UserPermissions): boolean {
    if (tokenInfo?.capabilities) {
        const capabilities = parseInt(tokenInfo.capabilities);
        return (capabilities & userPermission) !== 0;
    }
    return false;
}

export function HasPermissionCapabilityProgramAdmin(tokenInfo: TokenInfo, userPermission: UserPermissions): boolean {
    if (!IsProgramAdmin(tokenInfo)) {
        return true;
    } else if (HasPermissionCapability(tokenInfo, userPermission)) {
        return true;
    }
    return false;
}