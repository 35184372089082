import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import maintenence from '../../assets/images/maintenance.png';
import { NoInfo } from '../../components/NoInfo';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';

export const PageMaintenance = (): JSX.Element => {

    const { clearServiceUnavailable }: AppActions = useActions(appLogic);
    const { serviceUnavailable }: AppProps = useValues(appLogic);

    useEffect(() => {
        serviceUnavailable && clearServiceUnavailable();
    }, [serviceUnavailable, clearServiceUnavailable]);

    return (
        <NoInfo 
            title={'app.no.info.title.maintenance'}
            subTitle={'app.no.info.subtitle.maintenance'}
            body={[
                'app.no.info.body.maintenance'
            ]}
            image={maintenence}
        />
    );
}