import { FinancialReportMessages } from '../types';

export const financialReportJA: FinancialReportMessages = {
    'app.financialreport.title': '(JA) Financial report',
    'app.financialreport.organization_lbl': '(JA) Organization',
    'app.financialreport.start_data_lbl': '(JA) Start date',
    'app.financialreport.end_data_lbl': '(JA) End date',
    'app.financialreport.created_forms_title': '(JA) Created forms',
    'app.financialreport.finished_forms_title': '(JA) Finished forms',
    'app.financialreport.valid_forms_title': '(JA) Valid forms',
    'app.financialreport.invalid_forms_title': '(JA) Invalid forms',
    'app.financialreport.unanswered_forms_title': '(JA) Unanswered forms',
    'app.financialreport.error_header': '(JA) Error',
    'app.financialreport.error_text': '(JA) An error occurred and the report data could not be loaded, please try again.',

}
