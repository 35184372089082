import { ConsentPageMessages } from '../types';

export const consentPageZHCN: ConsentPageMessages = {
    'app.consent.title': 'Report review agreement',
    'app.consent.form_title': 'Consent Form',
    'app.consent.greeting': 'Dear Participant:',
    'app.consent.introduction': 'Thank you for your willingness to complete the Beliefs, Events, and Values Inventory or BEVI.',
    'app.consent.general.observation': 'This general consent form has been developed from the input of many different Institutional Review Board (IRB) processes. Before completing the BEVI, it is possible that you may be required to provide consent via a separate system or form that has been approved by a given institution or organization. If you have any questions about this process, please consult the BEVI administrator at your institution or organization.',
    'app.consent.explanation_1': 'As the name of this inventory suggests, the BEVI is designed to assess the many different ways in which people see themselves, others, and the larger world. After completing a series of background questions, you will be asked to respond to items covering a very wide range of issues and topics. You may have different reactions to different items. Some of these items will seem directly related to learning, growth, and development, but some may not; some items may seem straightforward whereas others may seem ambiguous. Because this measure is assessing many complex and interrelated factors, all of these items as well as the background questions are relevant to helping us understand processes that are related to learning, growth, and development as well as how different people see themselves, others, and the larger world.',
    'app.consent.explanation_2': 'Although different people may respond in different ways to these items, please know that there are no "right" or "wrong" answers on the BEVI. Since we often differ in our beliefs, values, and life experiences, it is both common and expected that one person may strongly agree with an item whereas a different person may strongly disagree with that same item. By allowing responses from Strongly Agree, Agree, Disagree, and Strongly Disagree, the BEVI explicitly recognizes that different people may have very different beliefs, values, and life experiences.',
    'app.consent.participant.rights.1': 'Your participation in this study is voluntary and you may choose to discontinue participation at any time. Your privacy will be protected to the maximum extent allowable by law. All of your answers will be treated with <b>strict confidence</b>. Individual answers you give to questions will <b>never be linked to you unless you specifically authorize such linkage in writing.</b> However, in some cases, you may be engaged in an experience where your responses are able to be reviewed directly with you. If that happens, your answers will be treated in strict confidence; your privacy would be protected to the maximum extent allowable by law, and in a manner that is consistent with privileged information. Only the project coordinators will have access to the raw data. For scholarly, teaching, professional, organizational, informational, or illustrative purposes, it is possible that individual scales or items, group profile data, or organizational reports may be presented or published. However, if so, no identifying information ever would be presented or published that could link a specific profile, scales, items, or responses directly to you (e.g., any ID you may have provided would be deleted), unless you specifically authorize such linkage in writing.',
    'app.consent.participant.rights.2': 'Depending upon when and in what context you are completing the BEVI, it is possible that you may be given the opportunity to review a report regarding your responses. If so, you may be required to agree to separate conditions before you are able to read this report. It also is possible that you may be asked to participate in a session where a group report will be discussed; this report may include BEVI data from a larger group of which you may have been a member. As part of a BEVI assessment project, it also is possible that you may be asked to complete other quantitative (e.g., multiple choice) or qualitative (e.g., written responses to questions) measures and/or to provide additional information via written or oral interview questions. This process may involve follow up discussions with the administrator or interpreter of a particular measure. Your participation in all such processes is voluntary and you may choose to discontinue participation at any time. As noted above, your individual responses in any group report or other measure will not be linked to you. Finally, you may also have the opportunity to participate in a feedback session after completion of the BEVI or other quantitative or qualitative measures as indicated above.',
    'app.consent.understand.agree': 'If you understand and agree with the above information and guidelines, and voluntarily consent to complete the BEVI and participate in one or more experiences in which the BEVI is used (e.g., as described above), please click on the \'Agree\' button.',
    'app.consent.have.questions': 'If you have any questions, please feel free to contact us at <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>',
    'app.consent.thanks': 'Thank you for your participation.',
    'app.consent.giving.consent': 'Giving of Consent',
    'app.consent.read.and.agree': 'I have read about the BEVI and this consent form and I understand what is being requested of me as a participant. I freely consent to participate. I have read the above description of this project and of my rights as a participant. By clicking on the Agree button below, and completing and submitting this confidential online survey, I am consenting to participate.',
    'app.consent.agree': 'Agree',
    'app.consent.disagree': 'Disagree',
    'app.custom_agreement.modal_title': 'Report review agreement',
    'app.custom_agreement.modal_explanation_text': 'Please edit the custom agreement text before submitting it.',
    'app.custom_agreement.default_custom_agreement': 'Dear BEVI Participant:<br/><br/>You already provided consent to take the BEVI, but before we can work with you on your BEVI report, we are asking you to agree 1) that you want to participate in one or more sessions where your individual report will be discussed with you and 2) that you are authorizing <strong>[here we need to be able to fill in one or more names of individual people]</strong> to review your individual report (e.g., your narrative, scores) and discuss the  meaning or implications of your BEVI report with you.<br/>Of course, as indicated in the consent form for the BEVI, your individual results will remain completely confidential.  If you have any questions, or need further information, please contact the BEVI administrator who is working with you.<br/><br/>If you understand and agree to the two above provisions, please click the "agree" button below. Thank you for your interest in the BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>您已同意接受 BEVI，但在我们与您合作处理一份或多份 BEVI 报告（例如，出于辅导、咨询、辅导等目的）之前，我们会询问您是否同意以下几点 :</h5>
    <br />

    <p>
    1. 我了解有多种类型的 BEVI 报告，获得 BEVI 认证的人员可能会与我讨论我的结果：1) 个人报告、2) 个人变更报告、3) 合作伙伴报告和 4) 合作伙伴变更报告。 我还了解，我必须允许其他人与我一起审查和讨论我的一份或多份报告。
    </p>
    <p>
    2. 单击下面的“同意”按钮，即表示我授权以下 BEVI 认证个人<b>[#USERS#]</b>与我一起审查和讨论我的一份或多份报告。
    </p>
    <p>
    3.	我也理解并同意：
        <p style="padding-left: 20px;">
        a) 我授权以下合作伙伴<b>[#PARTNERS#]</b>与我以及 BEVI 认证的个人一起审查和讨论我的报告/结果以及他们的报告/结果 我已在上面授权；
        </p>
        <p style="padding-left: 20px;">
        b) 我不会与我的合作伙伴以外的任何人分享或讨论任何合作伙伴报告或合作伙伴变更报告的全部或部分内容，或以任何形式（例如，通过电子邮件、张贴、分发、展示、复制等）（ s) 以及上面列出的 BEVI 认证个人； 此条件的任何例外情况均须得到本人和此处列出的所有个人（合伙人或多个合伙人以及 BEVI 认证个人或多个个人）的书面同意；
        </p>
        <p style="padding-left: 20px;">
        c) 我在上面授权的合作伙伴可以随时通过更改其 BEVI 仪表板中的权限配置文件删除其合作伙伴报告或合作伙伴变更报告，这将从其 BEVI 中删除该报告 仪表板和我的。
        </p>
    </p>
    <p>
    4. 除上述条件外，BEVI同意书中列出的所有其他条款将继续有效（例如，本人参与的自愿性质等）。 如果您有任何疑问或需要更多信息，请联系 BEVI 认证人员或与您合作的人员。
    </p>
    <p>
    5. 如果您同意以上所有内容，请点击下面的“我同意”。 如果您不同意上述所有观点，请点击下面的“我不同意”。
    </p>
    <center>感谢您对 BEVI 的关注！</center>
    `,
}



