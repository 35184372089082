import { CreateReportMessages } from '../types';

export const createReportES: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'Asignar nombre falso a todos los participantes',
    'app.create_report.btn_real_name_all': 'Asignar nombre real a todos los participantes',
    'app.create_report.btn_fake_name': 'Utilizar nombre falso',
    'app.create_report.btn_real_name': 'Utilizar nombre real',
    'app.create_report.title': 'Gestión de Informes',
    'app.create_report.summary_line1': ' El Sistema de Gestión del BEVI permite generar informes basados en todos los datos obtenidos para el grupo con el que estés trabajando. Hay cuatro tipos de informe posibles: <br />' +
        '&nbsp;&nbsp;1) Informe de Grupo (este informe está basado en un periodo de tiempo durante el que se ha administrado el BEVI); <br />' +
        '&nbsp;&nbsp;2) Informe Longitudinal (se trata de un informe completado a lo largo de un tiempo como por ejemplo T1, T2, T3, etc.)   <br />' +
        '&nbsp;&nbsp;3) Informes entre Grupos (se trata de informes que permiten comparar diferentes grupos dentro de la misma institución o entre instituciones u organizaciones). <br />' +
        '&nbsp;&nbsp;4) Informes dentro de un mismo Grupo (se trata de informes que comparan a los individuos de un grupo entre ellos por escalas BEVI, pero que lo hacen de forma anónima) ',
    'app.create_report.summary_line2': ' Estos informes se pueden generar automáticamente (i. e., utilizarán automáticamente todos los datos de tu grupo o grupos). Sin embargo, también puede refinar cada uno de los tipos de informe para que se centren en diferentes combinaciones de ítems de información personal. Por ejemplo, puede generar un informe que compare hombres con mujeres de dos países distintos. Hay literalmente cientos de combinaciones distintas que podrá seleccionar utilizando el refinamiento de búsqueda. ',
    'app.create_report.step1': 'PASO 1 - CONFIGURAR SU INFORME',
    'app.create_report.step2': 'PASOS 2 - ADICIONAR GRUPO DE RELATÓRIOS',
    'app.create_report.step3': 'PASOS 3 - REFINAR SU INFORME',
    'app.create_report.step3Individual': 'PASOS 3 - ¿QUIÉN PUEDE VER EL INFORME?',
    'app.create_report.step4': 'PASOS 4 - ADMINISTRAR PARTICIPANTES',
    'app.create_report.step3_username_column': 'Nombre de usuario',
    'app.create_report.step3_aliasname_column': 'Alias',
    'app.create_report.step3_action_column': 'Acción',
    'app.create_report.step3_no_user_title': 'No se encontraron usuarios',
    'app.create_report.step3_no_user_subtitle': 'Por favor, modifique los parámetros de los pasos anteriores y vuelva a realizar la búsqueda de usuarios.',
    'app.create_report.step3_user_name_column_title': 'Participantes',
    'app.create_report.step3_user_exporting_column_title': 'Incluidos',
    'app.create_report.requester': 'Solicitante',
    'app.create_report.name': 'Reportar nombre',
    'app.create_report.report_type': 'Tipo',
    'app.create_report.select_subprogram': 'Seleccione datos para generar el informe',
    'app.create_report.select_subprogram_warning': '(por favor, rellene los campos anteriores)',
    'app.create_report.group_report': 'Informe de grupo',
    'app.create_report.longitudinal_report': 'Informe de cambio de grupo',
    'app.create_report.comparative_by_group_report': 'Informes entre Grupos',
    'app.create_report.comparative_by_individual_report': 'Informes dentro del mismo Grupo',
    'app.create_report.select_organization': 'Seleccionar una Organización.',
    'app.create_report.select_program': 'Seleccionar un Programa o Experiencia.',
    'app.create_report.select_sub_program': 'Seleccionar Periodo de Administración del BEVI.',
    'app.create_report.organization_name': 'Organización',
    'app.create_report.program_name': 'Programa o Experiencia',
    'app.create_report.sub_program_name': 'Período de Administración del BEVI',
    'app.create_report.actions': 'Quitar',
    'app.create_report.action_remove': 'Quitar',
    'app.create_report.show_filters': 'Mostrar filtros de criterios',
    'app.create_report.hide_filters': 'Ocultar filtros de criterios',
    'app.create_report.filter_select_default_option': 'Seleccionar filtro...',
    'app.create_report.filter_select_with_values': 'Filtros',
    'app.create_report.filter_age': 'Edad',
    'app.create_report.filter_gender': 'Género',
    'app.create_report.filter_years_of_education': 'Años de Educación',
    'app.create_report.filter_primary_country_of_origin': 'País de Origen',
    'app.create_report.filter_marital_status': 'Estado civil',
    'app.create_report.filter_political_orientation': 'Orientación Política (Conservador a Progresista)',
    'app.create_report.filter_ethnic_background': 'Antecedentes Étnicos',
    'app.create_report.filter_religious_orientation': 'Orientación Religiosa',
    'app.create_report.filter_religious_commitment': 'Compromiso Religioso',
    'app.create_report.filter_academic_rank': 'Rango Académico',
    'app.create_report.filter_time_spent_in_other_countries': 'Tiempo pasado en otros países',
    'app.create_report.filter_nro_visited_countries': 'Países visitados',
    'app.create_report.filter_fluency': 'Fluidez',
    'app.create_report.filter_years_of_language_study': 'Años dedicados al Estudio de Idiomas',
    'app.create_report.filter_mother_country': 'País de la Madre',
    'app.create_report.filter_father_country': 'País del Padre',
    'app.create_report.filter_mother_education': 'Educación de la Madre',
    'app.create_report.filter_father_education': 'Educación del Padre',
    'app.create_report.filter_minority_group': 'Grupo minoritario',
    'app.create_report.filter_personal_interest': 'Interés en experiencias Internacionales / Culturales',
    'app.create_report.filter_study_abroad_satisfaction': 'Satisfacción con experiencias Internacionales / Culturales',
    'app.create_report.filter_primary_area_of_work': 'Principal Campo Laboral',
    'app.create_report.filter_area_of_occupations': 'Área de Ocupación',
    'app.create_report.filter_income_level_of_your_home_country': 'Nivel Salarial',
    'app.create_report.btn_search': 'Buscar',
    'app.create_report.btn_save': 'Generar',
    'app.create_report.placeholder.name': 'Introducir nombre de informe',
    'app.create_report.select.report_type': 'Seleccionar tipo de informe',
    'app.create_report.select_countries_options_lbl': 'Escoger como mínimo un País de Origen para comparar con todos los demás:',
    'app.create_report.selected_countries_lbl': 'Países seleccionados:',
    'app.create_report.select_ethnics_options_lbl': 'Escoger como mínimo un Grupo étnico para comparar con todos los demás:',
    'app.create_report.selected_ethnics_lbl': 'Orientación étnica seleccionada:',
    'app.create_report.select_number_of_forms_lbl': 'Escoger el máximo número de evaluaciones BEVI en la sección discursiva:',
    'app.create_report.select_relationship_status_options_lbl': 'Escoger como mínimo un Estado civil para comparar con todos los demás:',
    'app.create_report.select_religion_options_lbl': 'Escoger como mínimo un Religión para comparar con todos los demás:',
    'app.create_report.select_primary_area_work_options_lbl': 'Escoger como mínimo un Área principal de trabajo para comparar con todos los demás:',
    'app.create_report.selected_relationship_status_lbl': 'Estado de la relación seleccionada',
    'app.create_report.selected_religion_lbl': 'Orientación Religiosa Seleccionada',
    'app.create_report.selected_primary_area_work_lbl': 'Área de trabajo principal seleccionada',
    'app.create_report.individual_report': 'Informe Individual',
    'app.create_report.longitudinal_individual_report': 'Informe de cambio individual',
    'app.create_report.combined_report': 'Informe de socio',
    'app.create_report.individual': 'Individual',
    'app.create_report.partner': 'Socio',
    'app.create_report.group': 'Grupo',
    'app.create_report.select.group': 'Seleccionar el grupo',
    'app.create_report.partner_change_report': 'Informe de cambio de socio',
    'app.create_report.email_in_use': 'Correo electrónico en uso',
    'app.create_report.add_new_participant': 'Agregar nuevo participante'
}
