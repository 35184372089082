import React from "react";
import { Modal } from "react-bootstrap";

import { GetFormattedMessage } from "../utils/htmlHelper";
import { PageMessages } from "../locales/types";
import { Radio } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { RadioGroupStyled } from "./styled/radio";

export type Sort = {
  field: string;
  order: "asc" | "desc";
};

type Props = {
  showModal: boolean;
  onModalHide: () => void;
  sort?: Sort;
  setSort: (data?: Sort) => void;
  sortField: { name: keyof PageMessages; field: string }[];
};

export const SortModal = ({
  showModal,
  onModalHide,
  sortField,
  sort,
  setSort
}: Props): JSX.Element => {

  return (
    <Modal size="sm" centered show={showModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {GetFormattedMessage("app.listorganizations.sort_by").toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {sortField.map((s) => (
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 12 }}>
              <b>{GetFormattedMessage(s.name)}</b>
              <RadioGroupStyled onChange={() => {
                onModalHide();
              }} value={sort?.field + "-" + sort?.order}>
                <Radio.Button
                  onClick={() => setSort({ field: s.field, order: "asc" })}
                  value={s.field + "-asc"}
                >
                  <CaretUpOutlined />
                </Radio.Button>
                <Radio.Button
                  onClick={() => setSort({ field: s.field, order: "desc" })}
                  value={s.field + "-desc"}
                >
                  <CaretDownOutlined />
                </Radio.Button>{" "}
              </RadioGroupStyled>
            </div>
          ))}
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
};
