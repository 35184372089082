import { ReportsMessages } from '../types';

export const reportsES: ReportsMessages = {
    'app.reports.insuficient_data': 'No hay suficientes datos para generar el informe.',
    'app.reports.decile': 'Decil',
    'app.reports.group_report.title': 'Informe de grupo',
    'app.reports.longitudinal.title': 'Informe longitudinal',
    'app.reports.comparative_by_group.title': 'Informe entre grupos',
    'app.reports.comparative_by_individual.title': 'Informe dentro del grupo',
    'app.report.menu.introduction': 'Detalles de informe',
    'app.report.menu.basic_descriptive_statistics': 'Àmbito de antecedentes personales',
    'app.report.menu.aggregate_profile': 'Agregar Perfil',
    'app.report.menu.decile_profile': 'Perfil de decil',
    'app.report.menu.aggregate_by_demographics': 'Agregar por Demografía',
    'app.report.menu.background_domain_contrast': 'Contraste por ámbito de antecedentes personales',
    'app.report.menu.profile_background_contrast': 'Contraste por perfil de antecedentes personales',
    'app.report.menu.profile_contrast': 'Contraste por perfil',
    'app.report.menu.experiential_reflection_items': 'Ítems de reflexión experiencial',
    'app.report.menu.country_of_origin': 'País de origen',
    'app.report.menu.gender': 'Género',
    'app.report.menu.education': 'Educación',
    'app.report.menu.ethnicity': 'Origen Étnico',
    'app.report.menu.income': 'Salario',
    'app.report.menu.interest': 'Intereses',
    'app.report.menu.politics': 'Política',
    'app.report.menu.religion': 'Religión',
    'app.report.menu.satisfaction': 'Satisfacción',
    'app.report.menu.question_1': 'Pregunta 1',
    'app.report.menu.question_2': 'Pregunta 2',
    'app.report.menu.question_3': 'Pregunta 3',
    'app.report.menu.narrative_report': 'Reporte narrativo',
    'app.report.introduction.create_date': 'Creado el:',
    'app.report.introduction.created_by': 'Usuario:',
    'app.report.introduction.program_name': 'Programa o experiencia:',
    'app.report.introduction.group_name': 'Periodo(s) de administración del BEVI:',
    'app.report.introduction.number_of_participants': 'Número de participantes:',
    'app.report.introduction.visualized_by': 'Visto por:',
    'app.report.scales.consistency': 'Coherencia',
    'app.report.scales.congruency': 'Congruencia',
    'app.report.scales.negative_life_events': 'Vivencias negativas',
    'app.report.scales.needs_closure': 'Cierre de necesidades',
    'app.report.scales.needs_fulfillment': 'Satisfacción de necesidades',
    'app.report.scales.identity_diffusion': 'Difusión de la identidad',
    'app.report.scales.basic_openness': 'Receptividad básica',
    'app.report.scales.self_certitude': 'Seguridad en sí mismo',
    'app.report.scales.basic_determinism': 'Determinismo básico',
    'app.report.scales.socioemotional_convergence': 'Convergencia socioemocional',
    'app.report.scales.physical_resonance': 'Resonancia física',
    'app.report.scales.emotional_attunement': 'Sintonía emocional',
    'app.report.scales.self_awareness': 'Autoconciencia',
    'app.report.scales.meaning_quest': 'Búsqueda de significado',
    'app.report.scales.religious_traditionalism': 'Tradicionalismo religioso',
    'app.report.scales.gender_traditionalism': 'Tradicionalismo de género',
    'app.report.scales.sociocultural_openness': 'Receptividad sociocultural',
    'app.report.scales.ecological_resonance': 'Resonancia ecológica',
    'app.report.scales.global_resonance': 'Resonancia global',
    'app.report.aggregate_profile.high': 'Alto',
    'app.report.aggregate_profile.low': 'Bajo',
    'app.report.aggregate_profile.average': 'Normal',
    'app.report.background_statistic_item.income_participants': '{0} de {1} participantes - {2} %',
    'app.report.background_statistic_item.background_information': 'Información sobre antecedentes personales',
    'app.report.background_statistic_item.lowest': 'El más bajo',
    'app.report.background_statistic_item.middle': 'Medio',
    'app.report.background_statistic_item.highest': 'El más alto',
    'app.report.background_statistic_item.age': 'Edad',
    'app.report.background_statistic_item.average_age': 'Edad media:',
    'app.report.background_statistic_item.answer': 'Respuesta:',
    'app.report.background_statistic_item.not_answer': 'Sin respuesta:',
    'app.report.background_statistic_item.range': 'Rango:',
    'app.report.background_statistic_item.gender': 'Género',
    'app.report.background_statistic_item.ethnic_background': 'Antecedentes Étnicos',
    'app.report.background_statistic_item.country_of_origin': 'País de origen',
    'app.report.background_statistic_item.raised_in': 'Criado en',
    'app.report.background_statistic_item.not_raised_in': 'No criado en',
    'app.report.background_statistic_item.details': 'Información detallada',
    'app.report.background_statistic_item.years_of_education': 'Años de educación',
    'app.report.background_statistic_item.income': 'Salario',
    'app.report.background_statistic_item.average': 'Normal:',
    'app.report.background_statistic_item.marital_status': 'Estado civil',
    'app.report.background_statistic_item.religion': 'Religión',
    'app.report.background_statistic_item.interest': 'Intereses',
    'app.report.background_statistic_item.satisfaction': 'Satisfacción',
    'app.report.background_statistic_item.domain_scores': 'Puntuación por ámbito',
    'app.report.background_statistic_item.validity': 'Validez',
    'app.report.background_statistic_item.average_missing_items': 'Media de ítems que faltan',
    'app.report.background_statistic_item.average_quantitative_completion_time': 'Tiempo medio cuantitativo de finalización',
    'app.report.background_statistic_item.full_scale': 'Escala completa',
    'app.report.background_statistic_item.formative_variables': 'I. Variables formativas',
    'app.report.background_statistic_item.fulfillment_of_core_needs': 'II. Grado de cumplimiento de necesidades bàsicas',
    'app.report.background_statistic_item.tolerance_of_disequilibrium': 'III. Tolerancia al desequilibrio',
    'app.report.background_statistic_item.critical_thinking': 'IV. Pensamiento crítico',
    'app.report.background_statistic_item.self_access': 'V. Acceso al yo',
    'app.report.background_statistic_item.other_access': 'VI. Acceso a los demás',
    'app.report.background_statistic_item.global_access': 'VII. Acceso global',
    'app.report.decile_profile.high': 'Alto',
    'app.report.decile_profile.low': 'Bajo',
    'app.report.decile_profile.average': 'Normal',
    'app.report.background_domain_contrast.title': 'Contraste de ámbito de antecedentes personales',
    'app.report.background_domain_contrast.lowest_full_scale': 'Escala completa más baja',
    'app.report.background_domain_contrast.middle_full_scale': 'Escala completa media',
    'app.report.background_domain_contrast.highest_full_scale': 'Escala completa más alta',
    'app.report.background_domain_contrast.mean': 'Media',
    'app.report.background_domain_contrast.range': 'Rango',
    'app.report.background_domain_contrast.background_information': 'Información sobre antecedentes personales',
    'app.report.profile_contrast.lowest': 'Más bajo',
    'app.report.profile_contrast.middle': 'Medio',
    'app.report.profile_contrast.highest': 'Más alto',
    'app.report.profile_contrast.lowest_optimal_profiles': 'Perfiles óptimos más bajos',
    'app.report.profile_contrast.middle_optimal_profiles': 'Perfiles óptimos medios',
    'app.report.profile_contrast.highest_optimal_profiles': 'Perfiles óptimos más altos',
    'app.report.aggregate_profile.country_of_origin.selected_country': 'País seleccionado',
    'app.report.aggregate_profile.country_of_origin.selected_countries': 'Países seleccionados',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Étnia seleccionada',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Étnias seleccionadas',
    'app.report.aggregate_profile.country_of_origin.other': 'Otro',
    'app.report.aggregate_profile.religious_orientation.other': 'Otro',
    'app.report.aggregate_profile.parents_country.same': 'Mismo',
    'app.report.aggregate_profile.parents_country.different': 'Diferente',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Estado civil seleccionado',
    'app.report.aggregate_profile.religion.selected_religion': 'Religión seleccionada',
    'app.report.aggregate_profile.relationship_status.other': 'Otro',
    'app.report.aggregate_profile.gender.male': 'Hombre',
    'app.report.aggregate_profile.gender.female': 'Mujer',
    'app.report.aggregate_profile.gender.self_identify': 'Autocategorización',
    'app.report.aggregate_profile.education.lowest_30': '30% más bajo',
    'app.report.aggregate_profile.education.highest_30': '30% m·s alto',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'Étnia seleccionada',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Étnias seleccionadas',
    'app.report.aggregate_profile.ethnicity.other': 'Otra',
    'app.report.aggregate_profile.income.bottom_third': 'Tercio inferior',
    'app.report.aggregate_profile.income.middle_third': 'Tercio medio',
    'app.report.aggregate_profile.income.top_third': 'Tercio superior',
    'app.report.aggregate_profile.interest.low': 'Bajo',
    'app.report.aggregate_profile.interest.middle': 'Medio',
    'app.report.aggregate_profile.interest.high': 'Alto',
    'app.report.aggregate_profile.politics.low': 'Bajo',
    'app.report.aggregate_profile.politics.middle': 'Medio',
    'app.report.aggregate_profile.politics.high': 'Alto',
    'app.report.aggregate_profile.age.low': 'Bajo',
    'app.report.aggregate_profile.age.middle': 'Medio',
    'app.report.aggregate_profile.age.high': 'Alto',
    'app.report.aggregate_profile.religion.low': 'Bajo',
    'app.report.aggregate_profile.religion.middle': 'Medio',
    'app.report.aggregate_profile.religion.high': 'Alto',
    'app.report.aggregate_profile.satisfaction.low': 'Bajo',
    'app.report.aggregate_profile.satisfaction.middle': 'Medio',
    'app.report.aggregate_profile.satisfaction.high': 'Alto',
    'app.report.aggregate_profile.mother_father_education.low': 'Bajo',
    'app.report.aggregate_profile.mother_father_education.middle': 'Medio',
    'app.report.aggregate_profile.mother_father_education.high': 'Alto',
    'app.report.aggregate_profile.time_spent_other_countries.low': 'Bajo',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Medio',
    'app.report.aggregate_profile.time_spent_other_countries.high': 'Alto',
    'app.report.aggregate_profile.minority_group.minority': 'Grupo minoritario',
    'app.report.aggregate_profile.minority_group.majority': 'Grupo mayoritario',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'ámbito laboral principal seleccionado',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'ámbitos laborales principales seleccionados',
    'app.report.aggregate_profile.primary_area_of_work.other': 'Otro',
    'app.report.aggregate_profile.years_of_language_study.low': 'Bajo',
    'app.report.aggregate_profile.years_of_language_study.middle': 'Medio',
    'app.report.aggregate_profile.years_of_language_study.high': 'Alto',
    'app.report.aggregate_profile.academic_rank.low': 'Bajo',
    'app.report.aggregate_profile.academic_rank.middle': 'Medio',
    'app.report.aggregate_profile.academic_rank.high': 'Alto',
    'app.report.aggregate_profile.visited_countries.low': 'Bajo',
    'app.report.aggregate_profile.visited_countries.middle': 'Medio',
    'app.report.aggregate_profile.visited_countries.high': 'Alto',
    'app.report.aggregate_profile.fluency.low': 'No o mínimamente',
    'app.report.aggregate_profile.fluency.middle': 'Un poco o modestamente',
    'app.report.aggregate_profile.fluency.high': 'Mayormente fluido o fluido',
    'app.report.aggregate_demographic.core_demographics': 'Datos demográficos básicos',
    'app.report.aggregate_demographic.parental_background': 'Antecedentes de los padres',
    'app.report.aggregate_demographic.political_religious_inclinations': 'Inclinaciones políticas y religiosas',
    'app.report.aggregate_demographic.education_background': 'Antecedentes educacionales',
    'app.report.aggregate_demographic.international_cultural_experiences': 'Experiencias Internacionales y Culturales',
    'app.report.aggregate_demographic.age': 'Edad',
    'app.report.aggregate_demographic.gender': 'Género',
    'app.report.aggregate_demographic.relationship_status': 'Estado civil',
    'app.report.aggregate_demographic.country_origin': 'País de origen',
    'app.report.aggregate_demographic.parent_country': 'País de origen de los padres',
    'app.report.aggregate_demographic.mother_education': 'Educación de la madre',
    'app.report.aggregate_demographic.father_education': 'Educación del padre',
    'app.report.aggregate_demographic.ethnic_background': 'Origen étnico',
    'app.report.aggregate_demographic.minority_group': 'Grupo minoritario',
    'app.report.aggregate_demographic.political_orientation': 'Orientación política',
    'app.report.aggregate_demographic.religious_orientation': 'Orientación religiosa',
    'app.report.aggregate_demographic.religious_commitment': 'Compromiso religioso',
    'app.report.aggregate_demographic.income_level': 'Nivel de ingresos',
    'app.report.aggregate_demographic.years_education': 'Años de educación',
    'app.report.aggregate_demographic.primary_area_work': 'Área principal de trabajo',
    'app.report.aggregate_demographic.academic_rank': 'Rango académico',
    'app.report.aggregate_demographic.time_spent_countries': 'Tiempo pasado en otros países',
    'app.report.aggregate_demographic.visited_countries': 'Países visitados',
    'app.report.aggregate_demographic.personal_interest': 'Interés personal',
    'app.report.aggregate_demographic.study_abroad_satisfaction': 'Satisfacción con estudios en el extranjero',
    'app.report.aggregate_demographic.fluency': 'Fluidez',
    'app.report.aggregate_demographic.years_language_study': 'Años de estudio de idiomas',
    'app.report.comparative_individual_report_result_title': 'Resultados del informe de cambios individuales de BEVI',
    'app.report.comparative_individual_report_result_description': 'De una administración a la siguiente, usted se mueve entre diferentes "bandas" de cambio en el BEVI (es decir, puede estar mostrando cambios sustanciales de una administración a la siguiente). "Being Bevi" puede brindarle más información sobre los cambios que realmente se están produciendo entre las diferentes administraciones de BEVI (es decir, Being Bevi es la entidad de IA que vive dentro de Beviverse; haga clic en el círculo en la esquina inferior derecha). Si hay texto en color a continuación, normalmente significa que sus puntuaciones han cambiado sustancialmente de una administración del BEVI a la siguiente. El texto en color hace referencia a las direcciones en las que se ha producido este cambio en las diferentes administraciones de BEVI (por ejemplo, normalmente sería desde la primera vez que tomó el BEVI hasta la segunda vez, pero eso realmente depende de cuántas veces ha tomado el BEVI y qué comparaciones está haciendo). Si desea profundizar más y ver cambios específicos para usted en las 17 escalas de BEVI, comuníquese con su administrador de BEVI o con el equipo de BEVI en',
    'app.report.comparative_individual_report_result_description_no_change': 'De una administración a la siguiente, uno permanece dentro de la misma "banda" de cambio. "Being Bevi" puede brindarle más información sobre los cambios que realmente están ocurriendo entre una administración de BEVI y la siguiente (es decir, Being Bevi es la entidad de IA que vive dentro del Beviverse; haga clic en el círculo en la esquina inferior derecha). ). Si desea profundizar más y ver cambios específicos para usted en las 17 escalas de BEVI, comuníquese con su administrador de BEVI o con el equipo de BEVI en',
    'app.report.partner_report_result_title': 'Resultados del informe de socios de BEVI',
    'app.report.partner_change_report_result_title': 'Resultados del informe de cambio de socio de BEVI',
    'app.report.permission.modal_title': 'Estimado/a Participante del BEVI',
    'app.report.permission.modal_description': `
    <h5>Ya dio su consentimiento para realizar el BEVI, pero antes de que podamos trabajar con usted en su informe BEVI, le pedimos que acepte</h5>
    <br />
    <p>
    1) que desea participar en una o más sesiones en las que se discutirá con usted su informe individual y
    </p>
    <p>
    2) que usted autoriza a <b>[#USERS#]</b> a revisar su informe individual (por ejemplo, su narrativa, puntuaciones) y discutir el significado o las implicaciones de su informe BEVI con usted.
    Por supuesto, como se indica en el formulario de consentimiento de BEVI, sus resultados individuales serán completamente confidenciales. Si tiene alguna pregunta o necesita más información, comuníquese con el administrador de BEVI que está trabajando con usted.
    </p>
    <p>
    Si comprende y acepta las dos disposiciones anteriores, haga clic en el botón "Acepto" a continuación.
    </p>
    <center>¡Gracias por su interés en BEVI!</center>
    `,
    'app.report.permission.pending': 'Pendiente',
    'app.report.permission.approved': 'Aprobado',
    'app.report.permission.rejected': 'Rechazado',
    'app.report.permission.approve': 'ACEPTO',
    'app.report.permission.reject': 'NO ESTOY DE ACUERDO',
}
