import { appES } from './es/app';
import { consentPageES } from './es/consent';
import { counterPageES } from './es/counter';
import { createReportES } from './es/create_report';
import { createProgramES } from './es/createProgram';
import { dataDownloadES } from './es/dataDownload';
import { demographicQuestionsES } from './es/demographicQuestions';
import { discursiveQuestionsES } from './es/discursiveQuestions';
import { externalES } from './es/external';
import { individualReportPageES } from './es/individualReport';
import { internalTemplatePageES } from './es/internalTemplate';
import { listFormsPageES } from './es/listforms';
import { listOrganizationsES } from './es/listOrganizations';
import { listReportES } from './es/listReport';
import { loginPageES } from './es/login';
import { noInfoES } from './es/noInfo';
import { permissionES } from './es/permission';
import { reportsES } from './es/reports';
import { scaleQuestionsPageES } from './es/scaleQuestions';
import { timeES } from './es/time';
import { usersES } from './es/users';
import { PageMessages } from './types';
import { financialReportES } from './es/financialReport';
import { invitationES } from './es/invitation';
import { institutionES } from './es/institutions';
import { programsES } from './es/programs';
import { virtualAccountES } from './es/virtualAccount';

export const es: PageMessages = {
    ...appES,
    ...counterPageES,
    ...loginPageES,
    ...scaleQuestionsPageES,
    ...consentPageES,
    ...listFormsPageES,
    ...demographicQuestionsES,
    ...listOrganizationsES,
    ...discursiveQuestionsES,
    ...individualReportPageES,
    ...internalTemplatePageES,
    ...createProgramES,
    ...usersES,
    ...dataDownloadES,
    ...noInfoES,
    ...createReportES,
    ...reportsES,
    ...timeES,
    ...listReportES,
    ...permissionES,
    ...externalES,
    ...financialReportES,
    ...invitationES,
    ...institutionES,
    ...programsES,
    ...virtualAccountES
}
