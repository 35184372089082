import { ProgramsMessages } from '../types';

export const programsJA: ProgramsMessages = {
  'app.programs.add.title': '(JA) New Program',
  'app.programs.rename': '(JA) Rename program',
  'app.programs.edit.title': '(JA) Edit Program',
  'app.programs.add.successful_message': '(JA) Program was successful updated.',
  'app.programs.add.error_message': '(JA) Something went wrong, please, try again in a few minutes.',
  'app.programs.add.new_name': '(JA) New name',
  'app.programs.add.button_save': '(JA) Save',
  'app.programs.chat.successful_message': '(JA) In a few minutes, all users will be able to use AI chat.',
  'app.programs.chat.add_chat_all': '(JA) Add Chat for all Users',
  'app.programs.chat.question_add_chat_all': '(JA) Are you sure you want to include chat for all users in this program?'
}