import { InstitutionMessages } from '../types';

export const institutionJA: InstitutionMessages = {
  'app.institutions.title': '(JA) Organization Management',
  'app.institutions.new': '(JA) New Organization',
  'app.institutions.search': '(JA) Search',
  'app.institutions.list.name': '(JA) Name',
  'app.institutions.list.edit': '(JA) Edit',
  'app.institutions.list.action': '選択',
  'app.institutions.add.title': '(JA) New Organization',
  'app.institutions.edit.title': '(JA) Edit Organization',
  'app.institutions.add.successful_message': '(JA) Organization was successful updated.',
  'app.institutions.add.error_message': '(JA) Something went wrong, please, try again in a few minutes.',
  'app.institutions.add.new_name': '(JA) New name',
  'app.institutions.add.button_save': '(JA) Save',
  'app.institutions.add.hide_demographics_questions': '(JA) Hide Demographics Questions',
  'app.institutions.add.demographics_extra_questions': '(JA) Demographics Extra Questions',
  'app.institutions.hide_demographics_questions.successful_message': '(JA) Demographics questions was successful updated.',
  'app.institutions.demographics_questions.question': '(JA) Question',
  'app.institutions.demographics_questions.type': '(JA) Type',
  'app.institutions.demographics_questions.type_0': '(JA) Defecto',
  'app.institutions.demographics_questions.type_1': '(JA) Texto',
  'app.institutions.demographics_questions.type_2': '(JA) Single Option',
  'app.institutions.demographics_questions.add.title': '(JA) New Extra Question',
  'app.institutions.demographics_questions.edit.title': '(JA) Edit Extra Question',
  'app.institutions.demographics_questions.title': '(JA) Title',
  'app.institutions.demographics_questions.options': '(JA) Options',
  'app.institutions.demographics_questions.description': '(JA) Description',
  'app.institutions.demographics_questions.delete': '(JA) Are you sure to delete this item?',
  'app.institutions.chat.successful_message': '(JA) In a few minutes, all users will be able to use AI chat.',
  'app.institutions.chat.add_chat_all': '(JA) Add Chat for all Users',
  'app.institutions.chat.question_add_chat_all': '(JA) Are you sure you want to include chat for all users in this organization?'
}