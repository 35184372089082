import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';
import { ValidateToken } from '../utils/validateHelper';
import { FinancialReportBody, GetFinancialReportResponse, getFinancialReport } from '../services/reportService';

// actions
const actionsLiterals = asLiterals([
    'loadFinancialReport',
]);
export type FinancialReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const financialReportActionsConstants: FinancialReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals(['setFinancialReport','setError']);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'financialReport', 'loading', 'errorKey'
]);
export type FinancialReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const financialReportPropsConstants: FinancialReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: FinancialReportActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchFinancialReportRequest: () => void;
    }
};

// Define payloads type.
type LoadFinancialReportPayload = {
    organizationId: number;
    startDate: Date;
    endDate: Date;
};
type LoadFinancialReportPayloadRequest = { payload: LoadFinancialReportPayload };

type FinancialReportPayload = { report: FinancialReportBody };

type ErrorPayload = { errorKey: string; };

type LoadFinancialReportRaceResponse = {
    loadFinancialReportResponse?: GetFinancialReportResponse;
}

// persist forms reducer
export const financialReportKeaPath = 'financialReport';

export const financialReportReducer = keaReducer(financialReportKeaPath);

export const financialReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [financialReportKeaPath],

    actions: () => ({
        [financialReportActionsConstants.loadFinancialReport]:
            (organizationId: number, startDate: Date, endDate: Date): LoadFinancialReportPayload => ({ organizationId, startDate, endDate }),
        [privateActionsConstants.setError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setFinancialReport]: 
            (report: FinancialReportBody) : FinancialReportPayload => ({ report })
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [financialReportPropsConstants.financialReport]: [null, PropTypes.any, {
            [actions.setFinancialReport]: (_: FinancialReportBody, payload: FinancialReportPayload) => payload.report,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [financialReportPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.loadFinancialReport]: () => true,
            [actions.setError]: () => false,
            [actions.setFinancialReport]: () => false,
            [logout]: () => false
        }],
        [financialReportPropsConstants.errorKey]: [null, PropTypes.string, {
            [actions.loadFinancialReport]: () => null,
            [actions.setFinancialReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadFinancialReport]: workers.fetchFinancialReportRequest,
    }),

    workers: {
        * fetchFinancialReportRequest(action: LoadFinancialReportPayloadRequest): any {
            //@ts-ignore
            const { setError, setFinancialReport } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }
            
            const { organizationId, startDate, endDate } = action.payload;
            const { loadFinancialReportResponse }: LoadFinancialReportRaceResponse = yield race({
                loadFinancialReportResponse: call(getFinancialReport, token, organizationId, startDate, endDate),
                timeout: delay(requestTimeout)
            });

            if (loadFinancialReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (loadFinancialReportResponse?.status === successfulCode) {
                if (loadFinancialReportResponse?.body) {
                    yield put(setFinancialReport(loadFinancialReportResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (loadFinancialReportResponse?.keyErrorMessage) {
                yield put(setError(loadFinancialReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
