import React from 'react';
import { ProgressBar, Row, Col } from 'react-bootstrap';

type Props = {
    value: number;
    personalizedBarColor: string;
    title: string;
    hasSpace?: boolean;
    key?: string;
}

export const GraphBar = ({ title, value, personalizedBarColor, hasSpace, key }: Props): JSX.Element => {

    const styleProps = hasSpace && hasSpace === true ? styles.marginTopWithSpace : styles.marginTop;
    return (
        <Row style={styleProps} key={key}>
            <Col md={3}>
                <p style={styles.title}>{title}</p>
            </Col>
            <Col md={9}>
                <ProgressBar now={value} label={`${value}`} bsPrefix={personalizedBarColor} />
            </Col>
        </Row>
    )
}

const styles = {
    title: {
        textAlign: 'right',
        fontWeight: 'bold',
    } as React.CSSProperties,
    numberText: {
        fontWeight: 'bold',
        textAlign: 'left'
    } as React.CSSProperties,
    marginTop: {
        paddingTop: 5,
    } as React.CSSProperties,
    marginTopWithSpace: {
        paddingTop: 25,
    } as React.CSSProperties,
};
