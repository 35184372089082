import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import {
  addChatOrganization,
  saveOrganization, SaveOrganizationPayloadRequest, SaveOrganizationResponse
} from '../services/organizationService';

import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

const actionsLiterals = asLiterals([
  'createNewOrganization',
  'clearSuccessfulCreateNewOrganization',
  'addChatOrganization',
  'clearSuccessfulAddChatOrganization',
]);

export type OrganizationActions = { [K in (typeof actionsLiterals)[number]]: any };
export const organizationActionsConstants: OrganizationActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
  'successfulCreateNewOrganization',
  'setErroNewOrganization',
  'successfulAddChatOrganization',
  'setErroAddChatOrganization'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

const reducersLiterals = asLiterals([
  'hasCreatedNewOrganization',
  'errorMessageNewOrganization',
  'loadingCreateNewOrganization',
  'hasAddChatOrganization',
  'errorMessageAddChatOrganization',
  'loadingAddChatOrganization',
]);
export type OrganizationProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const organizationPropsConstants: OrganizationProps = { ...arrayToObject(reducersLiterals) };

type ReducersParam = { actions: OrganizationActions & PrivateActions; };
type WorkersParam = {
  workers: {
    sendSaveOrganizationRequest: () => void;
    sendAddChatOrganizationRequest: () => void;
  }
};

// Race type definitions.
type SaveOrganizationRaceResponse = {
  saveOrganizationResponse?: SaveOrganizationResponse;
}

// Define payloads type.
type ErrorPayload = { errorKey: string; };

type SaveOrganizationPayload = { organization: SaveOrganizationPayloadRequest }
type SaveOrganizationRequest = { payload: SaveOrganizationPayload };
type StatusSaveOrganizationPayload = { success: boolean; }

type AddChatOrganizationPayload = { organizationId: number }
type AddChatOrganizationRequest = { payload: any };
type StatusAddChatOrganizationPayload = { success: boolean; }

// persist forms reducer
export const organizationKeaPath = 'organization';

export const organizationReducer = keaReducer(organizationKeaPath);

export const organizationLogic = kea({
  connect: {
    props: [appLogic, [appPropsConstants.token]],
  },

  path: () => [organizationKeaPath],

  actions: () => ({
    [organizationActionsConstants.createNewOrganization]: (organization: SaveOrganizationPayloadRequest): SaveOrganizationPayload => ({ organization }),
    [privateActionsConstants.setErroNewOrganization]: (errorKey: string): ErrorPayload => ({ errorKey }),
    [privateActionsConstants.successfulCreateNewOrganization]: (): StatusSaveOrganizationPayload => ({ success: true }),
    [organizationActionsConstants.clearSuccessfulCreateNewOrganization]: true,
    [organizationActionsConstants.addChatOrganization]: (organizationId: number): AddChatOrganizationPayload => ({ organizationId }),
    [privateActionsConstants.setErroAddChatOrganization]: (errorKey: string): ErrorPayload => ({ errorKey }),
    [privateActionsConstants.successfulAddChatOrganization]: (): StatusAddChatOrganizationPayload => ({ success: true }),
    [organizationActionsConstants.clearSuccessfulAddChatOrganization]: true,
  }),

  reducers: ({ actions }: ReducersParam) => ({
    [organizationPropsConstants.errorMessageNewOrganization]: [null, PropTypes.string, {
      [actions.setErroNewOrganization]: (_: string, payload: ErrorPayload) => payload.errorKey,
      [actions.clearSuccessfulCreateNewOrganization]: () => null,
      [actions.createNewOrganization]: () => null,
      [actions.successfulCreateNewOrganization]: () => null,
      [logout]: () => null,
    }],
    [organizationPropsConstants.hasCreatedNewOrganization]: [false, PropTypes.bool, {
      [actions.successfulCreateNewOrganization]: () => true,
      [actions.clearSuccessfulCreateNewOrganization]: () => false,
      [actions.setErroNewOrganization]: () => false,
      [logout]: () => false,
    }],
    [organizationPropsConstants.loadingCreateNewOrganization]: [false, PropTypes.bool, {
      [actions.createNewOrganization]: () => true,
      [actions.clearSuccessfulCreateNewOrganization]: () => false,
      [actions.successfulCreateNewOrganization]: () => false,
      [actions.setErroNewOrganization]: () => false,
      [logout]: () => false,
    }],
    [organizationPropsConstants.errorMessageAddChatOrganization]: [null, PropTypes.string, {
      [actions.setErroAddChatOrganization]: (_: string, payload: ErrorPayload) => payload.errorKey,
      [actions.clearSuccessfulAddChatOrganization]: () => null,
      [actions.addChatOrganization]: () => null,
      [actions.successfulAddChatOrganization]: () => null,
      [logout]: () => null,
    }],
    [organizationPropsConstants.hasAddChatOrganization]: [false, PropTypes.bool, {
      [actions.successfulAddChatOrganization]: () => true,
      [actions.clearSuccessfulAddChatOrganization]: () => false,
      [actions.setErroAddChatOrganization]: () => false,
      [logout]: () => false,
    }],
    [organizationPropsConstants.loadingAddChatOrganization]: [false, PropTypes.bool, {
      [actions.addChatOrganization]: () => true,
      [actions.clearSuccessfulAddChatOrganization]: () => false,
      [actions.successfulAddChatOrganization]: () => false,
      [actions.setErroAddChatOrganization]: () => false,
      [logout]: () => false,
    }],
  }),

  takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
    [actions.createNewOrganization]: workers.sendSaveOrganizationRequest,
    [actions.addChatOrganization]: workers.sendAddChatOrganizationRequest,
  }),

  workers: {
    * sendSaveOrganizationRequest(action: SaveOrganizationRequest): any {

      //@ts-ignore
      const { successfulCreateNewOrganization, setErroNewOrganization } = this.actionCreators;
      //@ts-ignore
      const token = yield this.get(appPropsConstants.token);
      if (!ValidateToken(token)) {
        yield put(logout());
        return;
      }

      const { organization } = action.payload;
      const { saveOrganizationResponse }: SaveOrganizationRaceResponse = yield race({
        saveOrganizationResponse: call(saveOrganization, token, organization),
        timeout: delay(requestTimeout)
      });

      if (saveOrganizationResponse?.status === unauthorizedCode) {
        yield put(notAuthorized());
      } else if (saveOrganizationResponse?.status === successfulCode) {
        yield put(successfulCreateNewOrganization(saveOrganizationResponse?.body));
      } else if (saveOrganizationResponse?.keyErrorMessage) {
        yield put(setErroNewOrganization(saveOrganizationResponse.keyErrorMessage));
      } else {
        yield put(serviceUnavailable());
      }
    },
    * sendAddChatOrganizationRequest(action: AddChatOrganizationRequest): any {

      //@ts-ignore
      const { successfulAddChatOrganization, setErroAddChatOrganization } = this.actionCreators;
      //@ts-ignore
      const token = yield this.get(appPropsConstants.token);
      if (!ValidateToken(token)) {
        yield put(logout());
        return;
      }

      const { organizationId } = action.payload;
      const { addChatOrganizationResponse }: any = yield race({
        addChatOrganizationResponse: call(addChatOrganization, token, organizationId),
        timeout: delay(requestTimeout)
      });

      if (addChatOrganizationResponse?.status === unauthorizedCode) {
        yield put(notAuthorized());
      } else if (addChatOrganizationResponse?.status === successfulCode) {
        yield put(successfulAddChatOrganization(addChatOrganizationResponse?.body));
      } else if (addChatOrganizationResponse?.keyErrorMessage) {
        yield put(setErroAddChatOrganization(addChatOrganizationResponse.keyErrorMessage));
      } else {
        yield put(serviceUnavailable());
      }
    },
  }
});