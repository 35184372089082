import { PermissionMessages } from '../types';

export const permissionDE: PermissionMessages = {
  'app.permission.title': 'Berechtigung - Einzelbericht - Profil',
  'app.permission.remove_permission_to_all': 'Allen Berechtigung entziehen',
  'app.permission.give_permission_to_all': 'Allen Berechtigung erteilen',
  'app.permission.remove_permission': 'Berechtigung entziehen',
  'app.permission.give_permission': 'Berechtigung erteilen',
  'app.permission.table_title.permission': 'Berechtigung',
  'app.permission.table_title.name': 'Name',
  'app.permission.table_title.email': 'E-Mail',
  'app.permission.button.update': 'Update',
  'app.permission.message': 'User:innen wurden erfolgreich aktualisiert',
  'app.permission.add_permissions_with_excel_file': 'Fügen Sie Berechtigungen mit einer Excel-Datei hinzu',
  'app.permission.add_permissions': 'Berechtigungen hinzufügen',
  'app.permission.permissions': 'Berechtigungen',
  'app.permission.download_tooltip': 'Dateivorlage herunterladen'
}
