import { ListReportMessages } from '../types';

export const listReportDE: ListReportMessages = {
    'app.listreport.report_area': 'Verwaltung von Berichten',
    'app.listreport.new': 'Neuer Bericht',
    'app.listreport.search': 'Suche',
    'app.listreport.name': 'Name',
    'app.listreport.report_type': 'Art',
    'app.listreport.created_on': 'Erstellt am',
    'app.listreport.confirm_deletion_header': 'Löschung bestätigen',
    'app.listreport.confirm_deletion_body': 'Sind Sie sicher, dass Sie den Bericht löschen möchten? Wenn Sie fortfahren, wird dieser Bericht unwiderruflich aus unserer Datenbank entfernt.',
    'app.listorganizations.view': 'Ansehen',
    'app.listorganizations.edit': 'Bearbeiten',
    'app.listorganizations.delete': 'Löschen',
    //Without translation
    'app.listreport.new_group': 'New Report Group',
    'app.listreport.add_group': 'Add Another Group',
    'app.listreport.please_add_group_name': 'Please add group name',
    'app.listreport.report_status': 'Berichtsstatus',
    'app.listreport.report_status_pending_approval': 'Pending Participant Approval',
    'app.listreport.report_status_completed': 'Available',
}
