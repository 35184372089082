import { FinancialReportMessages } from '../types';

export const financialReportES: FinancialReportMessages = {
    'app.financialreport.title': 'Informe de uso',
    'app.financialreport.organization_lbl': 'Organización',
    'app.financialreport.start_data_lbl': 'Fecha de comienzo',
    'app.financialreport.end_data_lbl': 'Fecha de finalización',
    'app.financialreport.created_forms_title': 'Nº DE INVITADOS A HACER EL BEVI',
    'app.financialreport.finished_forms_title': 'Nº DE BEVIS COMPLETADOS',
    'app.financialreport.valid_forms_title': 'Nº DE BEVIS VÁLIDOS',
    'app.financialreport.invalid_forms_title': 'Nº DE BEVIS NO VÁLIDOS',
    'app.financialreport.unanswered_forms_title': 'Nº DE BEVIS SIN CONTESTAR',
    'app.financialreport.error_header': 'Error',
    'app.financialreport.error_text': 'Se ha producido un error y los datos del informe no se han podido cargar, por favor, inténtelo de nuevo',
}
