import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': 'Age',
    'app.demographic.gender': 'Gender',
    'app.demographic.marital_status': 'Relationship Status',
    'app.demographic.level_of_fluency': 'Please indicate your highest level of fluency in any language other than native languages (spoken in your home and/or community, and/or the language of instruction in the primary/grammar/elementary school(s) you attended)?.',
    'app.demographic.education_years': `Please indicate how many years of formal education you have completed.`,
    'app.demographic.raised_in_country_where_currently_in': 'Beginning with primary education in 1st grade (e.g., beyond preschool or kindergarten), how many years of formal education have you completed:',
    'app.demographic.ethnic': 'What is your ethnic background?',
    'app.demographic.ethnic_other': 'Please describe in the space provided:',
    'app.demographic.ethnic_asian': `Asian. A person having origins in countries or regions of the Far East, Indian subcontinent, or Southeast Asia including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam.`,
    'app.demographic.ethnic_african': 'Black or African. A person having origins in any of the Black racial groups of Africa.',
    'app.demographic.ethnic_latino': `Hispanic or Latino. A person having origins in countries or regions including, for example,  Cuba, Mexico, Puerto Rico, South or Central American, or other Spanish culture or origin, regardless of race.`,
    'app.demographic.ethnic_indigenous': `Indigenous. A person having origins in any of the original peoples of a given country or region who maintains an affiliation or attachment with an indigenous tribe or community.`,
    'app.demographic.ethnic_middle_eastern': `Middle Eastern. A person having origins in any of the countries or regions from Afghanistan to Libya, including, for example Egypt, Iran, Iraq, Israel, Jordan, Lebanon, Saudi Arabia, Sudan, Syria, Turkey, or countries of the Arabian peninsula.`,
    'app.demographic.ethnic_white': 'White or Caucasian. A person having origins in any of the White racial groups of Europe.',
    'app.demographic.completing_bevi': 'Please indicate when you are completing the BEVI.',
    'app.demographic.completing_bevi_other': 'Please describe the circumstances under which you are completing the BEVI below.',
    'app.demographic.previously_completed': 'Have you previously completed the BEVI?',
    'app.demographic.disadvantaged_or_minority_group': 'In your experience, are you a member of a marginalized or disadvantaged group?',
    'app.demographic.disadvantaged_or_minority_group_yes': 'In the space provided, please offer any additional information.',
    'app.demographic.primary_area_of_work': 'Of the options below, which represents the closest match to your field of study or your primary area of work / professional interest?',
    'app.demographic.current_area': 'In the space provided, please list your current area(s) of study or your occupation(s):',
    'app.demographic.political_orientation': 'Across the range from "Conservative/Traditional (e.g., supportive of retaining or preserving existing laws, systems, and ways of being)" to "Liberal/Progressive (e.g., supportive of changing or reforming existing laws, systems, and ways of being)", please use the slider below to indicate your political orientation',
    'app.demographic.political_commitment': 'Please indicate your level of personal commitment to your political orientation.',
    'app.demographic.political_orientation_conservative_label': 'Conservative/Traditional',
    'app.demographic.political_orientation_liberal_label': 'Liberal/Progressive',
    'app.demographic.religion': 'Which of the following options corresponds most closely to your current beliefs or commitments regarding religion or spirituality?',
    'app.demographic.religion_other': 'Please describe in the space provided:',
    'app.demographic.religion_scale': 'Please indicate your level of personal commitment towards religion or spirituality.',
    'app.demographic.religion_scale_specified': 'Please indicate your level of personal commitment to {0}',
    'app.demographic.income_level': 'Across the range from "Lowest Rank" to "Highest Rank," please use the slider below to indicate your income or access to resources compared to others in your society.',
    'app.demographic.income_less_label': 'Lowest Rank',
    'app.demographic.income_more_label': 'Highest Rank',
    'app.demographic.academic_rank_lowest_label': 'Lowest Rank',
    'app.demographic.academic_rank_highest_label': 'Highest Rank',
    'app.demographic.academic_rank': 'Relative to other students who took classes with you in your most recent school experience, please provide your best estimate of your academic standing or rank (e.g., from "Lowest Rank to "Highest Rank").',
    'app.demographic.primary_country': 'What is your primary country of origin?',
    'app.demographic.time_in_other_countries': 'Of the options below, please indicate the amount of time you have spent in countries/regions other than your country of origin.',
    'app.demographic.time_in_other_countries_other': 'Please specify',
    'app.demographic.visit_countries': 'How many countries/regions other than your country of origin have you visited or lived in?',
    'app.demographic.country_explanation_field': 'To find your country, you may either select the country from the dropdown menu or simply type in and select the language.',
    'app.demographic.country_mother_born': 'Please indicate the country/region in which your mother was born:',
    'app.demographic.country_father_born': 'Please indicate the country/region in which your father was born:',
    'app.demographic.personal_interest': 'Please indicate your level of personal interest in international, multicultural, or intercultural experiences (e.g., work, study, or service abroad; Virtual Exchange (VE) or Collaborative Online International Learning (COIL); multicultural coursework; participation on diverse teams).',
    'app.demographic.participated_experiences': 'Have you previously completed, or are you currently completing, any of the following experiences? (Click all that apply)',
    'app.demographic.participated_learning_courses': 'Have you previously completed, or are you currently completing, any of the following global and diversity learning courses/programs? (Click all that apply)',
    'app.demographic.plan_participate_experiences': 'Do you plan to complete any of the following experiences? (Click all that apply)',
    'app.demographic.plan_participate_learning_courses': 'Do you plan to complete any of the following global and diversity learning courses/programs? (Click all that apply)',
    'app.demographic.experience_satisfaction': 'If you have participated, or are currently participating, in international, multicultural, or intercultural experiences  (e.g., work, study, or service abroad; Virtual Exchange (VE) or Collaborative Online International Learning (COIL); multicultural coursework; participation on diverse teams), please indicate your overall level of satisfaction with these experiences.',
    'app.demographic.languages_speak': 'How many languages do you speak as native languages (spoken in your home and/or community, and/or the language of instruction in the primary/grammar/elementary school(s) you attended)?',
    'app.demographic.years_studied_foreign_language': 'How many total years have you studied of foreign language(s)?',
    'app.demographic.primary_language': 'What is your primary language (i.e., the first or main language that you speak)? You may select more than one language if you have more than one primary language. To find your language, you may either select the language(s) from the dropdown menu or simply type in and select the language(s).',
    'app.demographic.primary_language_other': 'Do you speak any language(s) other than your native language (i.e., your first or primary language)?',
    'app.demographic.language_level_of_fluency': 'From the options below, please select any language(s) that you speak as well as your level of fluency.',
    'app.demographic.language_level_of_fluency_add_anwser': 'Add',
    'app.demographic.table_level_of_fluency': 'Level of fluency',
    'app.demographic.table_language': 'Language',
    'app.demographic.table_action': 'Action',
    'app.demographic.remove_language_tooltip': 'Remove language',
    'app.demographic.father_formal_education': 'Please indicate the highest level of formal education obtained by your father:',
    'app.demographic.mother_formal_education': 'Please indicate the highest level of formal education obtained by your mother:',
    'app.demographic.comparative': 'Comparative Individual Report',
    'app.demographic.compare': 'Compare',
    'app.demographic.select_multiple_reports': 'Select multiple reports',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanian',
    'app.demographic.language_options.Amharic': 'Amharic',
    'app.demographic.language_options.Arabic': 'Arabic',
    'app.demographic.language_options.Armenian': 'Armenian',
    'app.demographic.language_options.Basque': 'Basque',
    'app.demographic.language_options.Bengali': 'Bengali',
    'app.demographic.language_options.Byelorussian': 'Byelorussian',
    'app.demographic.language_options.Burmese': 'Burmese',
    'app.demographic.language_options.Bulgarian': 'Bulgarian',
    'app.demographic.language_options.Catalan': 'Catalan',
    'app.demographic.language_options.Czech': 'Czech',
    'app.demographic.language_options.Chinese': 'Chinese',
    'app.demographic.language_options.Croatian': 'Croatian',
    'app.demographic.language_options.Danish': 'Danish',
    'app.demographic.language_options.Dari': 'Dari',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Dutch',
    'app.demographic.language_options.English': 'English',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonian',
    'app.demographic.language_options.Faroese': 'Faroese',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finnish',
    'app.demographic.language_options.French': 'French',
    'app.demographic.language_options.Gaelic': 'Gaelic',
    'app.demographic.language_options.Galician': 'Galician',
    'app.demographic.language_options.German': 'German',
    'app.demographic.language_options.Greek': 'Greek',
    'app.demographic.language_options.Hebrew': 'Hebrew',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Hungarian',
    'app.demographic.language_options.Icelandic': 'Icelandic',
    'app.demographic.language_options.Indonesian': 'Indonesian',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Eskimo)',
    'app.demographic.language_options.Italian': 'Italian',
    'app.demographic.language_options.Japanese': 'Japanese',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Korean',
    'app.demographic.language_options.Kurdish': 'Kurdish',
    'app.demographic.language_options.Laotian': 'Laotian',
    'app.demographic.language_options.Latvian': 'Latvian',
    'app.demographic.language_options.Lappish': 'Lappish',
    'app.demographic.language_options.Lithuanian': 'Lithuanian',
    'app.demographic.language_options.Macedonian': 'Macedonian',
    'app.demographic.language_options.Malay': 'Malay',
    'app.demographic.language_options.Maltese': 'Maltese',
    'app.demographic.language_options.Nepali': 'Nepali',
    'app.demographic.language_options.Norwegian': 'Norwegian',
    'app.demographic.language_options.Pashto': 'Pashto',
    'app.demographic.language_options.Polish': 'Polish',
    'app.demographic.language_options.Portuguese': 'Portuguese',
    'app.demographic.language_options.Romanian': 'Romanian',
    'app.demographic.language_options.Russian': 'Russian',
    'app.demographic.language_options.Scots': 'Scots',
    'app.demographic.language_options.Serbian': 'Serbian',
    'app.demographic.language_options.Slovak': 'Slovak',
    'app.demographic.language_options.Slovenian': 'Slovenian',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Spanish',
    'app.demographic.language_options.Swedish': 'Swedish',
    'app.demographic.language_options.Swahili': 'Swahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalog-Filipino',
    'app.demographic.language_options.Tajik': 'Tajik',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Thai',
    'app.demographic.language_options.Tibetan': 'Tibetan',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tongan',
    'app.demographic.language_options.Turkish': 'Turkish',
    'app.demographic.language_options.Turkmen': 'Turkmen',
    'app.demographic.language_options.Ucrainian': 'Ucrainian',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Uzbek',
    'app.demographic.language_options.Vietnamese': 'Vietnamese',
    'app.demographic.language_options.Welsh': 'Welsh',
    'app.demographic.language_options.Other': 'Other',
    'app.demographic.gender.male': 'Male',
    'app.demographic.gender.female': 'Female',
    'app.demographic.gender.self_identify': 'Prefer to self-describe',
    'app.demographic.marital.response_single': 'Single',
    'app.demographic.marital.response_married': 'Married',
    'app.demographic.marital.response_divorced': 'Divorced',
    'app.demographic.marital.response_widowed': 'Widowed',
    'app.demographic.marital.response_other': 'Other',
    'app.demographic.education_years.response_1': 'No formal education.',
    'app.demographic.education_years.response_2': '1 year',
    'app.demographic.education_years.response_3': '2 years',
    'app.demographic.education_years.response_4': '3 years',
    'app.demographic.education_years.response_5': '4 years',
    'app.demographic.education_years.response_6': '5 years',
    'app.demographic.education_years.response_7': '6 years',
    'app.demographic.education_years.response_8': '7 years',
    'app.demographic.education_years.response_9': '8 years',
    'app.demographic.education_years.response_10': '9 years',
    'app.demographic.education_years.response_11': '10 years',
    'app.demographic.education_years.response_12': '11 years',
    'app.demographic.education_years.response_13': '12 years',
    'app.demographic.education_years.response_14': '13 years',
    'app.demographic.education_years.response_15': '14 years',
    'app.demographic.education_years.response_16': '15 years',
    'app.demographic.education_years.response_17': '16 years',
    'app.demographic.education_years.response_18': '17 years',
    'app.demographic.education_years.response_19': '18 years',
    'app.demographic.education_years.response_20': '19 years',
    'app.demographic.education_years.response_21': '20 years',
    'app.demographic.education_years.response_22': '21 years',
    'app.demographic.education_years.response_23': '22 years',
    'app.demographic.education_years.response_24': '23 years',
    'app.demographic.education_years.response_25': '24 years',
    'app.demographic.education_years.response_26': '25 years',
    'app.demographic.education_years.response_27': '26 years',
    'app.demographic.education_years.response_28': '27 years',
    'app.demographic.education_years.response_29': '28 years',
    'app.demographic.education_years.response_30': '29 years',
    'app.demographic.education_years.response_31': '30 years',
    'app.demographic.education_years.response_32': 'More than 30 years',
    'app.demographic.ethnic.respose_asian': 'Asian',
    'app.demographic.ethnic.respose_african': 'Black or African',
    'app.demographic.ethnic.respose_latino': 'Hispanic or Latino',
    'app.demographic.ethnic.respose_indigenous': 'Indigenous',
    'app.demographic.ethnic.respose_middle_eastern': 'Middle Eastern',
    'app.demographic.ethnic.respose_white': 'White or Caucasian',
    'app.demographic.ethnic.respose_mixed': 'Mixed',
    'app.demographic.ethnic.respose_other': 'Other',
    'app.demographic.completing_bevi.response_before': 'Before (or at the beginning of) an international/intercultural learning experience.',
    'app.demographic.completing_bevi.response_during': 'During (or in the middle of) an international/intercultural learning experience.',
    'app.demographic.completing_bevi.response_after': 'After (or at the end of) an international/intercultural learning experience.',
    'app.demographic.completing_bevi.response_during_academic': 'During academic orientation and not one of the above options.',
    'app.demographic.completing_bevi.response_end_graduation': 'At the end of your undergraduate college carrier.',
    'app.demographic.completing_bevi.response_other': 'Other - Please Specify.',
    'app.demographic.primary_area_of_work.response_1': 'Arts, Communications, Humanities',
    'app.demographic.primary_area_of_work.response_2': 'Behavior, Culture, Society',
    'app.demographic.primary_area_of_work.response_3': 'Business',
    'app.demographic.primary_area_of_work.response_4': 'Education',
    'app.demographic.primary_area_of_work.response_5': 'Health and Human Services',
    'app.demographic.primary_area_of_work.response_6': 'Science and Technology',
    'app.demographic.primary_area_of_work.response_7': 'Other',
    'app.demographic.political_orientation.response_1': 'Conservative/traditional (e.g., supportive of retaining or preserving existing laws, systems, and ways of being)',
    'app.demographic.political_orientation.response_2': 'More conservative than liberal',
    'app.demographic.political_orientation.response_3': 'Moderate/neutral/neither conservative nor liberal',
    'app.demographic.political_orientation.response_4': 'More liberal than conservative',
    'app.demographic.political_orientation.response_5': 'Liberal/progressive (e.g., supportive of changing or reforming existing laws, systems, and ways of being)',
    'app.demographic.political_orientation.response_6': 'Other',
    'app.demographic.religion.response_1': 'African Traditional Religion',
    'app.demographic.religion.response_2': 'Agnosticism',
    'app.demographic.religion.response_3': 'Atheism',
    'app.demographic.religion.response_4': 'Bahai',
    'app.demographic.religion.response_5': 'Buddhism',
    'app.demographic.religion.response_6': 'Caodaism',
    'app.demographic.religion.response_7': 'Chinese Traditional Religion',
    'app.demographic.religion.response_8': 'Christianity',
    'app.demographic.religion.response_9': 'Hinduism',
    'app.demographic.religion.response_10': 'Indigenous',
    'app.demographic.religion.response_11': 'Islam',
    'app.demographic.religion.response_12': 'Jainism',
    'app.demographic.religion.response_13': 'Juche',
    'app.demographic.religion.response_14': 'Judaism',
    'app.demographic.religion.response_15': 'Paganism',
    'app.demographic.religion.response_16': 'Shinto',
    'app.demographic.religion.response_17': 'Sikhism',
    'app.demographic.religion.response_18': 'Spiritism',
    'app.demographic.religion.response_19': 'Spiritual But No Specific Religious Affiliation',
    'app.demographic.religion.response_20': 'Tenrikyo',
    'app.demographic.religion.response_21': 'Unitarian-Universalism',
    'app.demographic.religion.response_22': 'Zoroastrianism',
    'app.demographic.religion.response_other': 'Other',
    'app.demographic.religion.no_response': 'I prefer not to answer this question',
    'app.demographic.income_level.response_1': 'Top third',
    'app.demographic.income_level.response_2': 'Middle third',
    'app.demographic.income_level.response_3': 'Bottom third',
    'app.demographic.academic_rank.response_1': 'Top third',
    'app.demographic.academic_rank.response_2': 'Middle third',
    'app.demographic.academic_rank.response_3': 'Bottom third',
    'app.demographic.participated_experiences.response_1': 'Seminar course specifically for 1st year/freshman students',
    'app.demographic.participated_experiences.response_2': 'Member of a university-organized learning community',
    'app.demographic.participated_experiences.response_3': 'Writing intensive course',
    'app.demographic.participated_experiences.response_4': 'Research project with a faculty or professional mentor',
    'app.demographic.participated_experiences.response_5': 'University sponsored/approved service learning or community-based project',
    'app.demographic.participated_experiences.response_6': 'University sponsored/approved internship',
    'app.demographic.participated_experiences.response_7': 'Capstone/end of degree project',
    'app.demographic.participated_experiences.response_8': 'Common or shared set of required courses',
    'app.demographic.participated_experiences.response_9': 'Collaborative assignments or projects with others',
    'app.demographic.participated_experiences.response_10': 'Diversity / global learning courses or study abroad',
    'app.demographic.participated_experiences.response_11': 'Electronic portfolio (eportfolio) of your educational work or experiences',
    'app.demographic.participated_experiences.response_12': 'Online learning (e.g., virtual or web-based) that <b>includes</b> an international, multicultural, or intercultural focus.',
    'app.demographic.participated_experiences.response_13': 'Online learning (e.g., virtual or web-based) that <b>does not include</b> an international, multicultural, or intercultural focus.',
    'app.demographic.participated_learning_courses.response_1': 'Taken a course with a multicultural or global studies emphasis',
    'app.demographic.participated_learning_courses.response_2': 'Participated in extracurricular multicultural, international, cultural events on campus',
    'app.demographic.participated_learning_courses.response_3': 'Completed service learning abroad',
    'app.demographic.participated_learning_courses.response_4': 'Completed an internship abroad',
    'app.demographic.participated_learning_courses.response_5': 'Studied abroad',
    'app.demographic.participated_learning_courses.response_6': 'Lived in a living-learning community with a focus on international, multicultural, global, or language learning',
    'app.demographic.participated_learning_courses.response_7': 'Studied a language that was not my native language',
    'app.demographic.participated_learning_courses.response_8': 'Lived with a roommate with cultural tradition or background that was different from my own',
    'app.demographic.participated_learning_courses.response_9': 'Completed or are completing Virtual Exchange (VE), Collaborative Online International Learning (COIL), or another online / web-based learning experience that has an international, multicultural, or intercultural focus.',
    'app.demographic.plan_participate_experiences.response_1': 'Seminar course specifically for 1st year/freshman students',
    'app.demographic.plan_participate_experiences.response_2': 'Member of a university-organized learning community',
    'app.demographic.plan_participate_experiences.response_3': 'Writing intensive course',
    'app.demographic.plan_participate_experiences.response_4': 'Research project with a faculty or professional mentor',
    'app.demographic.plan_participate_experiences.response_5': 'University sponsored/approved service learning or community-based project',
    'app.demographic.plan_participate_experiences.response_6': 'University sponsored/approved internship',
    'app.demographic.plan_participate_experiences.response_7': 'Capstone/end of degree project',
    'app.demographic.plan_participate_experiences.response_8': 'Common or shared set of required courses',
    'app.demographic.plan_participate_experiences.response_9': 'Collaborative assignments or projects with others',
    'app.demographic.plan_participate_experiences.response_10': 'Diversity / global learning courses or study abroad',
    'app.demographic.plan_participate_experiences.response_11': 'Electronic portfolio (eportfolio) of your educational work or experiences',
    'app.demographic.plan_participate_experiences.response_12': 'Online learning (e.g., virtual or web-based) that <b>includes</b> an international, multicultural, or intercultural focus.',
    'app.demographic.plan_participate_experiences.response_13': 'Online learning (e.g., virtual or web-based) that <b>does not include</b> an international, multicultural, or intercultural focus.',
    'app.demographic.plan_participate_learning_courses.response_1': 'Take a course with a multicultural or global studies emphasis',
    'app.demographic.plan_participate_learning_courses.response_2': 'Participate in extracurricular multicultural, international, cultural events on campus.',
    'app.demographic.plan_participate_learning_courses.response_3': 'Complete service learning abroad.',
    'app.demographic.plan_participate_learning_courses.response_4': 'Complete an internship abroad',
    'app.demographic.plan_participate_learning_courses.response_5': 'Study abroad',
    'app.demographic.plan_participate_learning_courses.response_6': 'Live in a living-learning community with a focus on international, multicultural, global, or language learning',
    'app.demographic.plan_participate_learning_courses.response_7': 'Study a language that was not my native language',
    'app.demographic.plan_participate_learning_courses.response_8': 'Live with a roommate with cultural tradition or background that was different from my own',
    'app.demographic.plan_participate_learning_courses.response_9': 'Complete Virtual Exchange (VE), Collaborative Online International Learning (COIL), or another online / web-based learning experience that has an international, multicultural, or intercultural focus.',
    'app.demographic.time_in_other_country.response_not_spent': 'No significant time abroad',
    'app.demographic.time_in_other_country.response_less_2_weeks': 'At least 1 week but less than 2 weeks',
    'app.demographic.time_in_other_country.response_less_1_month': 'At least 2 weeks but less than 1 month',
    'app.demographic.time_in_other_country.response_less_3_months': 'At least 1 month but less than 3 months',
    'app.demographic.time_in_other_country.response_less_6_months': 'At least 3 months but less than 6 months',
    'app.demographic.time_in_other_country.response_less_1_year': 'At least 6 months but less than 1 year',
    'app.demographic.time_in_other_country.response_less_2_years': 'At least 1 year but less than 2 years',
    'app.demographic.time_in_other_country.response_less_5_years': 'At least 2 years but less than 5 years',
    'app.demographic.time_in_other_country.response_less_10_years': 'At least 5 years but less than 10 years',
    'app.demographic.time_in_other_country.response_10_or_more': '10 or more years',
    'app.demographic.country.response_eua': 'United States of America',
    'app.demographic.country.response_afganistan': 'Afghanistan',
    'app.demographic.country.response_albania': 'Albania',
    'app.demographic.country.response_algeria': 'Algeria',
    'app.demographic.country.response_american_samoa': 'American Samoa',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armenia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australia',
    'app.demographic.country.response_austria': 'Austria',
    'app.demographic.country.response_azerbaijan': 'Azerbaijan',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarus',
    'app.demographic.country.response_belgium': 'Belgium',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benin',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolivia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnia & Herzegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brazil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgaria',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodia',
    'app.demographic.country.response_cameroon': 'Cameroon',
    'app.demographic.country.response_canada': 'Canada',
    'app.demographic.country.response_cape_verde': 'Cape Verde',
    'app.demographic.country.response_cayman_islands': 'Cayman Islands',
    'app.demographic.country.response_central_african_republic': 'Central African Republic',
    'app.demographic.country.response_chad': 'Chad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colombia',
    'app.demographic.country.response_comoros': 'Comoros',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'Congo, Democratic Republic',
    'app.demographic.country.response_cook_islands': 'Cook Islands',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Côte d\'Ivoire ',
    'app.demographic.country.response_croatia': 'Croatia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Cyprus',
    'app.demographic.country.response_czech_republic': 'Czech Republic',
    'app.demographic.country.response_denmark': 'Denmark',
    'app.demographic.country.response_djibouti': 'Djibouti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'Dominican Republic',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Egypt',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Equatorial Guinea',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estonia',
    'app.demographic.country.response_ethiopia': 'Ethiopia',
    'app.demographic.country.response_euro_area': 'Euro Area',
    'app.demographic.country.response_falkland_islands': 'Falkland Islands',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finland',
    'app.demographic.country.response_france': 'France',
    'app.demographic.country.response_french_guiana': 'French Guiana',
    'app.demographic.country.response_french_polynesia': 'French Polynesia',
    'app.demographic.country.response_gabon': 'Gabon',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgia',
    'app.demographic.country.response_germany': 'Germany',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Greece',
    'app.demographic.country.response_grenada': 'Grenada',
    'app.demographic.country.response_guadeloupe': 'Guadeloupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haiti',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Hungary',
    'app.demographic.country.response_iceland': 'Iceland',
    'app.demographic.country.response_india': 'India',
    'app.demographic.country.response_indonesia': 'Indonesia',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Ireland',
    'app.demographic.country.response_isle_of_man': 'Isle of Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italy',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': 'Japan',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordan',
    'app.demographic.country.response_kazakhstan': 'Kazakhstan',
    'app.demographic.country.response_kenya': 'Kenya',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kyrgyzstan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Latvia',
    'app.demographic.country.response_lebanon': 'Lebanon',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libya',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Lithuania',
    'app.demographic.country.response_luxembourg': 'Luxembourg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Macedonia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malaysia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Maldives',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Mariana',
    'app.demographic.country.response_marshall_islands': 'Marshall Islands',
    'app.demographic.country.response_martinique': 'Martinique',
    'app.demographic.country.response_mauritania': 'Mauritania',
    'app.demographic.country.response_mauritius': 'Mauritius',
    'app.demographic.country.response_mexico': 'Mexico',
    'app.demographic.country.response_micronesia': 'Micronesia',
    'app.demographic.country.response_moldova': 'Moldova',
    'app.demographic.country.response_monaco': 'Monaco',
    'app.demographic.country.response_mongolia': 'Mongolia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Morocco',
    'app.demographic.country.response_mozambique': 'Mozambique',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Netherland Antilles',
    'app.demographic.country.response_netherlands': 'Netherlands',
    'app.demographic.country.response_new_caledonia': 'New Caledonia',
    'app.demographic.country.response_new_zealand': 'New Zealand',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'North Korea',
    'app.demographic.country.response_norway': 'Norway',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panama',
    'app.demographic.country.response_papua_new_guinea': 'Papua New Guinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Peru',
    'app.demographic.country.response_phillipines': 'Philippines',
    'app.demographic.country.response_poland': 'Poland',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Reunion',
    'app.demographic.country.response_romania': 'Romania',
    'app.demographic.country.response_russian_federation': 'Russian Federation',
    'app.demographic.country.response_rwanda': 'Rwanda',
    'app.demographic.country.response_st_helena': 'St Helena',
    'app.demographic.country.response_st_kitts-nevis': 'St Kitts-Nevis',
    'app.demographic.country.response_st_lucia': 'St Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'St Vincent & Grenadines',
    'app.demographic.country.response_stateless': 'Stateless',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'Sao Tome & Principe',
    'app.demographic.country.response_saudi_arabia': 'Saudi Arabia',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Serbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapore',
    'app.demographic.country.response_slovak_republic': 'Slovak Republic',
    'app.demographic.country.response_slovenia': 'Slovenia',
    'app.demographic.country.response_solomon_islands': 'Solomon Islands',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'South Africa',
    'app.demographic.country.response_south_korea': 'South Korea',
    'app.demographic.country.response_spain': 'Spain',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Suriname',
    'app.demographic.country.response_swaziland': 'Swaziland',
    'app.demographic.country.response_sweden': 'Sweden',
    'app.demographic.country.response_switzerland': 'Switzerland',
    'app.demographic.country.response_syria': 'Syria',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tajikistan',
    'app.demographic.country.response_tanzania': 'Tanzania',
    'app.demographic.country.response_thailand': 'Thailand',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor-Leste',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad & Tobago',
    'app.demographic.country.response_tunisia': 'Tunisia',
    'app.demographic.country.response_turkey': 'Turkey',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Turks & Caicos Is',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ukraine',
    'app.demographic.country.response_united_arab_erimates': 'United Arab Erimates',
    'app.demographic.country.response_united_kingdom': 'United Kingdom',
    'app.demographic.country.response_uraguay': 'Uraguay',
    'app.demographic.country.response_uzbekistan': 'Uzbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Virgin Islands',
    'app.demographic.country.response_west_bank_and_gaza': 'West Bank and Gaza',
    'app.demographic.country.response_yemen': 'Yemen',
    'app.demographic.country.response_zambia': 'Zambia',
    'app.demographic.country.response_zimbabwe': 'Zimbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak': 'I do not speak any language other than my native language (s)',
    'app.demographic.level_of_fluency.minimally_fluent': 'Minimally fluent',
    'app.demographic.level_of_fluency.somewhat_fluent': 'Somewhat fluent',
    'app.demographic.level_of_fluency.moderately_fluent': 'Moderately fluent',
    'app.demographic.level_of_fluency.mostly_fluent': 'Mostly fluent',
    'app.demographic.level_of_fluency.fluent': 'Fluent',
    'app.demographic.level_of_interest.not_applicable': 'Not applicable',
    'app.demographic.level_of_interest.extremely_low': 'Extremely low',
    'app.demographic.level_of_interest.very_low': 'Very low',
    'app.demographic.level_of_interest.low': 'Low',
    'app.demographic.level_of_interest.neutral': 'Neutral',
    'app.demographic.level_of_interest.high': 'High',
    'app.demographic.level_of_interest.very_high': 'Very high',
    'app.demographic.level_of_interest.extremely_high': 'Extremely high',
    'app.demographic_slider_default_option': 'Please move cursor to select your response.',
    'app.demographic.formal_education.some_high_school_or_less': 'Some high school or less',
    'app.demographic.formal_education.high_school_graduate': 'High school graduate',
    'app.demographic.formal_education.some_college': 'Some college',
    'app.demographic.formal_education.college_degree': 'College degree',
    'app.demographic.formal_education.some_graduate_school': 'Some graduate school',
    'app.demographic.formal_education.graduate_degree': 'Graduate degree (e.g, Master\'s degree)',
    'app.demographic.formal_education.professional_degree': 'Professional degree (e.g., Law)',
    'app.demographic.formal_education.doctoral_degree': 'Doctoral degree',
}

export const demographicQuestionsEN: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
