import { VirtualAccountMessages } from '../types';

export const virtualAccountJA: VirtualAccountMessages = {
  'app.virtual_account.title': 'Virtual Account by Institution',
  'app.virtual_account.add_interactions': 'Add Interactions',
  'app.virtual_account.add_interactions_to_organization': 'Add interactions for the organization:',
  'app.virtual_account.interactions_placeholder': 'Type number of iterations',
  'app.virtual_account.successful_message': 'Virtual Account was successful updated.',
  'app.virtual_account.day': 'Day',
  'app.virtual_account.interaction_credit': 'Interaction Credits',
  'app.virtual_account.interaction_debit': 'Interaction Debits',
  'app.virtual_account.balance': 'Balance',
  'app.virtual_account.total_balance_month': 'Total balance for the month is ',
  'app.virtual_account.interactions_available': 'interactions available'
}