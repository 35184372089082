import { ClientOptions } from 'reactotron-core-client/dist/types/client-options';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';

const configuration: ClientOptions = {
    name: 'BEVI'
};

if (process.env.REACT_NATIVE_PACKAGER_HOSTNAME) {
    configuration.host = process.env.REACT_NATIVE_PACKAGER_HOSTNAME;
}

const reactotron = Reactotron
  .configure(configuration)
  .use(reactotronRedux())
  .use(sagaPlugin({}))
  .connect()

export default reactotron
