import { Input, Modal, Tree, TreeDataNode } from "antd";
import React, { useEffect, useMemo, useState } from "react"
import { OrganizationModel } from "../../../services/reportService";
import { GetFormattedMessage } from "../../../utils/htmlHelper";
import { SelectedSubProgram } from "../CreateReportContainer";
import { FaBuffer, FaRegBuilding } from "react-icons/fa";
import { FileTextOutlined } from "@ant-design/icons"
import { Form } from "react-bootstrap";

type Props = {
    organizations?: OrganizationModel[],
    selectedOrganizations?: number[],
    onCancel: () => void,
    addMultiplesSubPrograms: (subPrograms: SelectedSubProgram[]) => void
}

export const TreeOrganization: React.FC<Props> = ({ organizations, selectedOrganizations, onCancel, addMultiplesSubPrograms }) => {
    const [searchValue, setSearchValue] = useState<string>();
    const [values, setValues] = useState<string[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [highlights, setHighlights] = useState<string[]>([]);

    useEffect(() => {
        setValues([]);
        setExpandedKeys([]);
        setSearchValue(undefined);
        setHighlights([]);
    }, [selectedOrganizations])

    useEffect(() => {
        const expanded: string[] = [];
        const highlights: string[] = [];
        if (searchValue) {
            organizations?.forEach((organization) => {
                if (organization.name.toUpperCase().includes(searchValue.toUpperCase())) {
                    expanded.push('organization-' + organization.id);
                    highlights.push('organization-' + organization.id);
                }
                if (organization.programs != null) {
                    organization.programs.filter(x => x.isActive).forEach(program => {
                        if (program.name.toUpperCase().includes(searchValue.toUpperCase())) {
                            expanded.push('organization-' + organization.id);
                            expanded.push('program-' + program.id);
                            highlights.push('program-' + program.id);
                        }
                        if (program.subPrograms != null) {
                            const subprograms = program.subPrograms.filter(x => x.isActive && x.description.toUpperCase().includes(searchValue.toUpperCase()));
                            if (subprograms.length > 0) {
                                expanded.push('organization-' + organization.id);
                                expanded.push('program-' + program.id);

                                subprograms.forEach((subprogram) => {
                                    highlights.push('organization=' + organization.id + '&program=' + program.id + '&subprogram=' + subprogram.id);
                                })
                            }
                        }
                    })
                }
            })
        }
        setHighlights(highlights);
        setExpandedKeys(expanded);
    }, [organizations, searchValue])

    const dig = useMemo(() => {
        const list: any[] = [];
        organizations?.forEach((organization, index) => {
            const organizationNodes: TreeDataNode = {
                title: organization.name,
                key: 'organization-' + organization.id,
                icon: <FaRegBuilding style={styles.icon} />,
                style: styles.title
            };

            if (organization.programs != null) {
                const programNodes: TreeDataNode[] = [];

                organization.programs.filter(x => x.isActive).forEach(program => {
                    const programNode: TreeDataNode = {
                        title: program.name,
                        key: 'program-' + program.id,
                        icon: <FaBuffer style={styles.icon} />,
                    };

                    const subProgramNodes: TreeDataNode[] = [];

                    program.subPrograms.filter(x => x.isActive).forEach(subprogram => {
                        if (!selectedOrganizations?.includes(subprogram.id))
                            subProgramNodes.push({
                                title: subprogram.description,
                                key: 'organization=' + organization.id + '&program=' + program.id + '&subprogram=' + subprogram.id,
                                icon: <FileTextOutlined />
                            });
                    });

                    if (subProgramNodes.length > 0) {
                        programNode.children = subProgramNodes;
                        programNodes.push(programNode);
                    }
                })
                if (programNodes.length > 0)
                    organizationNodes.children = programNodes;
            }
            if (organizationNodes.children)
                list.push(organizationNodes);
        })
        return list;
    }, [organizations, selectedOrganizations]);

    const treeData = dig;

    const addSubprograms = () => {
        const subPrograms: SelectedSubProgram[] = [];
        values.filter(x => x.includes('subprogram')).forEach(queryString => {
            const params = new URLSearchParams(queryString);
            const organization = params.get("organization")!;
            const program = params.get("program")!;
            const subprogram = params.get("subprogram")!;

            subPrograms.push({
                organizationId: parseInt(organization),
                programId: parseInt(program),
                subProgramId: parseInt(subprogram)
            })
        });

        addMultiplesSubPrograms(subPrograms);
    }

    return <Modal okText={GetFormattedMessage('app.demographic.language_level_of_fluency_add_anwser')}
        onCancel={onCancel} open={selectedOrganizations != null} closeIcon={false} onOk={addSubprograms}>
        <Form.Group className='mb-3'>
            <Input
                value={searchValue}
                placeholder={GetFormattedMessage('app.btn_search')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value);
                }}
            />
        </Form.Group>
        <Tree
            expandedKeys={expandedKeys}
            onExpand={(v: any) => setExpandedKeys(v)}
            showIcon
            checkable
            checkedKeys={values}
            onCheck={(v: any) => setValues(v)}
            treeData={treeData}
            height={400}
            filterTreeNode={(v) => highlights.includes(v.key)}
        />
    </Modal>;
}

const styles = {
    icon: {
        position: 'relative',
        bottom: 2,
        marginRight: 4
    } as React.CSSProperties,
    title: {
        fontSize: 14,
        fontWeight: 'bold'
    } as React.CSSProperties,
}