import React from 'react'
import { LongitudinalReportIntroduction } from '../../services/types'
import { GetDate } from '../../utils/dateHelper'
import { GetFormattedMessage } from '../../utils/htmlHelper'

type Props = {
    introduction: LongitudinalReportIntroduction;
    visualizedBy: string;
}

export const Introduction = ({ introduction, visualizedBy }: Props): JSX.Element => {
    return (
        <div>
            <div>
                <h3>{GetFormattedMessage('app.report.introduction.group_name')}</h3>
                {
                    introduction.groupStructure.map((item) => {
                        return (
                            <>
                                {item.groupName}:
                                {item.details.map((item, index) => <li key={`SubProgramGroupName-${index}`}>{item}</li>)}
                                <br />
                            </>
                        )
                    })
                }

                <h3>{GetFormattedMessage('app.report.introduction.create_date')}</h3>
                <li>{GetDate(introduction.createdDateOnUtc)}</li>
                <br />
                <h3>{GetFormattedMessage('app.report.introduction.visualized_by')}</h3>
                <li>{visualizedBy}</li>
                <br />
                <h3>{GetFormattedMessage('app.report.introduction.number_of_participants')}</h3>
                <li>{introduction.numberOfParticipants}</li>
            </div>
        </div>
    )
}