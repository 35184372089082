import React from 'react'
import { Table } from 'react-bootstrap'
import { colors } from '../config/colors'
import { DecileProfileItem, ScaleGroup } from '../services/reportService'
import { GetFormattedMessage } from '../utils/htmlHelper'

type Props = {
    consistency: DecileProfileItem[];
    congruency: DecileProfileItem[];
    negativeLifeEvents: DecileProfileItem[];
    needsClosure: DecileProfileItem[];
    needsFulfillment: DecileProfileItem[];
    identityDiffusion: DecileProfileItem[];
    basicOpenness: DecileProfileItem[];
    selfCertitude: DecileProfileItem[];
    basicDeterminism: DecileProfileItem[];
    socioemotionalConvergence: DecileProfileItem[];
    physicalResonance: DecileProfileItem[];
    emotionalAttunement: DecileProfileItem[];
    selfAwereness: DecileProfileItem[];
    meaningQuest: DecileProfileItem[];
    religiousTraditionalism: DecileProfileItem[];
    genderTraditionalism: DecileProfileItem[];
    socioculturalOpenness: DecileProfileItem[];
    ecologicalResonance: DecileProfileItem[];
    globalResonance: DecileProfileItem[];
}

export const DecileProfileTable = (props: Props): JSX.Element => {
    return (
        <Table size="sm" striped bordered>
            <thead>
                {buildHeaderFooter()}
            </thead>
            <tbody>
                {props.consistency.map((item, index) => buildRow(item, `Consistency-${index}`))}
                {props.congruency.map((item, index) => buildRow(item, `Congruency-${index}`))}
                {props.negativeLifeEvents.map((item, index) => buildRow(item, `NegativeLifeEvents-${index}`))}
                {props.needsClosure.map((item, index) => buildRow(item, `NeedsClosure-${index}`))}
                {props.needsFulfillment.map((item, index) => buildRow(item, `NeedsFulfillment-${index}`))}
                {props.identityDiffusion.map((item, index) => buildRow(item, `IdentityDiffusion-${index}`))}
                {props.basicOpenness.map((item, index) => buildRow(item, `BasicOpenness-${index}`))}
                {props.selfCertitude.map((item, index) => buildRow(item, `SelfCertitude-${index}`))}
                {props.basicDeterminism.map((item, index) => buildRow(item, `BasicDeterminism-${index}`))}
                {props.socioemotionalConvergence.map((item, index) => buildRow(item, `SocioemotionalConvergence-${index}`))}
                {props.physicalResonance.map((item, index) => buildRow(item, `PhysicalResonance-${index}`))}
                {props.emotionalAttunement.map((item, index) => buildRow(item, `EmotionalAttunement-${index}`))}
                {props.selfAwereness.map((item, index) => buildRow(item, `SelfAwereness-${index}`))}
                {props.meaningQuest.map((item, index) => buildRow(item, `MeaningQuest-${index}`))}
                {props.religiousTraditionalism.map((item, index) => buildRow(item, `ReligiousTraditionalism-${index}`))}
                {props.genderTraditionalism.map((item, index) => buildRow(item, `GenderTraditionalism-${index}`))}
                {props.socioculturalOpenness.map((item, index) => buildRow(item, `SocioculturalOpenness-${index}`))}
                {props.ecologicalResonance.map((item, index) => buildRow(item, `EcologicalResonance-${index}`))}
                {props.globalResonance.map((item, index) => buildRow(item, `GlobalResonance-${index}`))}
            </tbody>
            <tfoot>
                {buildHeaderFooter()}
            </tfoot>
        </Table>
    )
}

const styles = {
    title: {
        backgroundColor: colors.lightBlue,
        textAlign: 'center',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center',
    } as React.CSSProperties,
};

const buildHeaderFooter = (): JSX.Element => {
    return (
        <tr style={styles.title}>
            <th>{GetFormattedMessage('app.reports.decile')}</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
        </tr>
    )
}

const buildRow = (item: DecileProfileItem, key: string): JSX.Element => {
    return (
        <tr style={styles.alignCenter} key={key}>
            <td style={{ backgroundColor: buildDecileTitleBG(item.decileCode), textAlign: 'left' }}><b>{item.decileTitle}</b></td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value1) }}>{item.value1}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value2) }}>{item.value2}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value3) }}>{item.value3}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value4) }}>{item.value4}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value5) }}>{item.value5}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value6) }}>{item.value6}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value7) }}>{item.value7}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value8) }}>{item.value8}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value9) }}>{item.value9}%</td>
            <td style={{ backgroundColor: buildDecileValueBG(item.decileCode, item.value10) }}>{item.value10}%</td>
        </tr>
    )
}

const buildDecileTitleBG = (decileCode: ScaleGroup): string => {
    if (decileCode === ScaleGroup.Congruency || decileCode === ScaleGroup.Consistency) return colors.orange;
    if (decileCode % 2 === 0) return colors.gray;
    return colors.lightGray;
}

const buildDecileValueBG = (decileCode: ScaleGroup, value: number): string => {
    if (decileCode === ScaleGroup.Congruency || decileCode === ScaleGroup.Consistency)
        return colors.orange;

    if (value <= 5) return colors.decileLess5;
    if (value <= 10) return colors.decileLess10;
    if (value <= 15) return colors.decileLess15;
    if (value <= 25) return colors.decileLess25;

    return colors.decileRest;
}