import React, { ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { PageMessages } from '../locales/types';

type Props = {
    showModal: boolean;
    titleFormattedMessageId: keyof (PageMessages);
    bodyFormattedListMessageId?: Array<keyof (PageMessages)>;
    bodyFormattedText?: Array<ReactNode>;
    body?: ReactNode;
    primaryButtonFormattedMessageId?: keyof (PageMessages);
    secondaryButtonFormattedMessageId?: keyof (PageMessages);
    contentClassName?: string;
    onModalHide: () => void;
    onPrimaryButtonClicked?: () => void;
    onSecondaryButtonClicked?: () => void;
}

export const ConfirmationModal = (
    {
        showModal, titleFormattedMessageId, bodyFormattedListMessageId, bodyFormattedText, body,
        primaryButtonFormattedMessageId, secondaryButtonFormattedMessageId, contentClassName,
        onModalHide, onPrimaryButtonClicked, onSecondaryButtonClicked
    }: Props
): JSX.Element => {
    return (
        <Modal contentClassName={contentClassName} show={showModal} onHide={onModalHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={titleFormattedMessageId} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {
                        bodyFormattedListMessageId?.map((item, index) => {
                            return <p key={item + index}><FormattedMessage id={item} /></p>
                        })
                    }
                    {
                        bodyFormattedText?.map((item, index) => {
                            return <p key={'body' + index}>{item}</p>
                        })
                    }
                    {body}
                </>
            </Modal.Body>

            <Modal.Footer>
                {secondaryButtonFormattedMessageId &&
                    <Button variant='secondary' onClick={onSecondaryButtonClicked}>
                        <FormattedMessage id={secondaryButtonFormattedMessageId} />
                    </Button>
                }
                {primaryButtonFormattedMessageId &&
                    <Button variant='primary' onClick={onPrimaryButtonClicked}>
                        <FormattedMessage id={primaryButtonFormattedMessageId} />
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

