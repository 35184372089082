import React from 'react';
import { ScaleGroup } from '../../../services/reportService';
import { GroupReportAggregateByDemographics_TimeSpentInOtherCountries } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { AggregateProfileByDemographics } from './AggregateProfileByDemographics';
import { InsufficientDataComponent } from './InsufficientDataComponent';
import { getScaleTitle } from '../../../utils/reportHelper';

type Props = {
  report: GroupReportAggregateByDemographics_TimeSpentInOtherCountries[];
  subProgramName?: string[];
}

type ScaleProps = {
  title: string;
  value: number;
  hasSpace: boolean;
}

const hasEnoughData = (report: GroupReportAggregateByDemographics_TimeSpentInOtherCountries[]) => {
  let invalidReportCount = 0;
  for (let i = 0; i < report.length; i++) {
    if (!report[i].hasEnoughData) {
      invalidReportCount += 1;
    }
  }

  if (report.length === 1) {
    if (invalidReportCount === 0) return true;
    return false;
  }
  else {
    return report.length - invalidReportCount > 1;
  }
}

const buildSubTitle = (report: GroupReportAggregateByDemographics_TimeSpentInOtherCountries[],
  subProgramName?: string[]) => {

  const subTitle: string[][] = [];
  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    subTitle.push(
      [
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].lowRange})`,
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].middleRange})`,
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].highRange})`,
      ]
    )

  }
  return subTitle;
}

const buildScales = (report: GroupReportAggregateByDemographics_TimeSpentInOtherCountries[],
  scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {

  const scalePropos: ScaleProps[] = [];
  const scaleTitle = getScaleTitle(scale);

  const low = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.low');
  const middle = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.middle');
  const high = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.high');

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${low})`,
      value: reportItem.scales[scale][0],
      hasSpace: i === 0 && name !== ''
    });
  }

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${middle})`,
      value: reportItem.scales[scale][1],
      hasSpace: i === 0 && name !== ''
    });
  }

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${high})`,
      value: reportItem.scales[scale][2],
      hasSpace: i === 0 && name !== ''
    });
  }

  return scalePropos;
}

export const AggregateProfileByDemographics_TimeSpentInOtherCountries = ({ report, subProgramName }: Props) => {

  if (!hasEnoughData(report)) {
    return <InsufficientDataComponent />
  }

  const low = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.low');
  const middle = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.middle');
  const high = GetFormattedMessage('app.report.aggregate_profile.time_spent_other_countries.high');
  return (
    <AggregateProfileByDemographics
      title={[
        `${low} (N = ${report.map(item => item.lowNumberOfUsers).reduce((prev, next) => prev + next)})`,
        `${middle} (N = ${report.map(item => item.middleNumberOfUsers).reduce((prev, next) => prev + next)})`,
        `${high} (N = ${report.map(item => item.highNumberOfUsers).reduce((prev, next) => prev + next)})`
      ]}
      subTitle={buildSubTitle(report, subProgramName)}
      negativeLifeEvents={buildScales(report, ScaleGroup.NegativeLifeEvents, 1, subProgramName)}
      needsClosure={buildScales(report, ScaleGroup.NeedsClosure, 2, subProgramName)}
      needsFulfillment={buildScales(report, ScaleGroup.NeedsFulfillment, 3, subProgramName)}
      identityDiffusion={buildScales(report, ScaleGroup.IdentityDiffusion, 4, subProgramName)}
      basicOpenness={buildScales(report, ScaleGroup.BasicOpenness, 5, subProgramName)}
      selfCertitude={buildScales(report, ScaleGroup.SelfCertitude, 6, subProgramName)}
      basicDeterminism={buildScales(report, ScaleGroup.BasicDeterminism, 7, subProgramName)}
      socioemotionalConvergence={buildScales(report, ScaleGroup.SocioemotionalConvergence, 8, subProgramName)}
      physicalResonance={buildScales(report, ScaleGroup.PhysicalResonance, 9, subProgramName)}
      emotionalAttunument={buildScales(report, ScaleGroup.EmotionalAttunement, 10, subProgramName)}
      selfAwereness={buildScales(report, ScaleGroup.SelfAwereness, 11, subProgramName)}
      meaningQuest={buildScales(report, ScaleGroup.MeaningQuest, 12, subProgramName)}
      religiousTraditionalism={buildScales(report, ScaleGroup.ReligiousTraditionalism, 13, subProgramName)}
      genderTraditionalism={buildScales(report, ScaleGroup.GenderTraditionalism, 14, subProgramName)}
      socioCulturalOpenness={buildScales(report, ScaleGroup.SocioculturalOpenness, 15, subProgramName)}
      ecologicalResonance={buildScales(report, ScaleGroup.EcologicalResonance, 16, subProgramName)}
      globalResonance={buildScales(report, ScaleGroup.GlobalResonance, 17, subProgramName)}
    />
  )
}