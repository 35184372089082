import { Table, Tag } from 'antd';
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { TableFilterWithRadioButton } from '../../components/TableFilterWithRadioButton';
import { colors } from '../../config/colors';
import { paginationDefaultPageSize } from '../../config/constants';
import { TokenInfo } from '../../services/loginService';
import { UserProgramDetails, UserPrograms } from '../../services/usersService';
import { GetDate } from '../../utils/dateHelper';
import { getInvalidStatusReason, getStatusValue, isFormAvailable } from '../../utils/formHelper';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { IsAccountManager, IsSuperAdmin } from '../../utils/userHelper';

type Props = {
    userInfo: TokenInfo;
    userPrograms: UserPrograms;
    onBackButtonClicked: () => void;
}

const getReportTagColor = (status: number): string => {
    if (isFormAvailable(status)) return 'blue';
    if (status === 5) return 'green';
    if (status === 6 || status === 7) return 'volcano';
    return 'black';
}

export const UserProgramsPage = ({ userPrograms, onBackButtonClicked, userInfo }: Props) => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
    }

    const textStatus1 = GetFormattedMessage(getStatusValue(1));
    const textStatus2 = GetFormattedMessage(getStatusValue(2));
    const textStatus3 = GetFormattedMessage(getStatusValue(3));
    const textStatus4 = GetFormattedMessage(getStatusValue(4));
    const textStatus5 = GetFormattedMessage(getStatusValue(5));
    const textStatus6 = GetFormattedMessage(getStatusValue(6));
    const textStatus7 = GetFormattedMessage(getStatusValue(7));
    const textStatus8 = GetFormattedMessage(getStatusValue(8));
    const textStatus9 = GetFormattedMessage(getStatusValue(9));

    const getTitle = (status: number): string => {
        if (status === 1) return textStatus1;
        if (status === 2) return textStatus2;
        if (status === 3) return textStatus3;
        if (status === 4) return textStatus4;
        if (status === 5) return textStatus5;
        if (status === 6) return textStatus6;
        if (status === 7) return textStatus7;
        if (status === 8) return textStatus8;
        if (status === 9) return textStatus9;
        return '';
    }

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }
    let columns: ColumnType<UserProgramDetails>[] = [
        {
            title: GetFormattedMessage('app.user_programs.programName'),
            dataIndex: 'programName',
            key: 'programName',
            width: '30%',
            sorter: (a: UserProgramDetails, b: UserProgramDetails) => a.programName.localeCompare(b.programName),
            ...filterOptions,
            onFilter: (value, record: UserProgramDetails) =>
                record.programName.toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: GetFormattedMessage('app.user_programs.subprogramName'),
            dataIndex: 'groupName',
            key: 'groupName',
            width: '30%',
            sorter: (a: UserProgramDetails, b: UserProgramDetails) => a.groupName.localeCompare(b.groupName),
            ...filterOptions,
            onFilter: (value, record: UserProgramDetails) =>
                record.groupName.toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: GetFormattedMessage('app.user_programs.status'),
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            sorter: (a: UserProgramDetails, b: UserProgramDetails) => a.status - b.status,
            render: (_: number, record: UserProgramDetails) => (
                <span>
                    <Tag color={getReportTagColor(record.status)} key={'TAG' + record.formId}>
                        {getTitle(record.status)}
                    </Tag>
                </span>
            ),
            filters: [
                { text: getTitle(1), value: '1' },
                { text: getTitle(2), value: '2' },
                { text: getTitle(3), value: '3' },
                { text: getTitle(4), value: '4' },
                { text: getTitle(5), value: '5' },
                { text: getTitle(6), value: '6' },
                { text: getTitle(7), value: '7' },
                { text: getTitle(8), value: '8' },
                { text: getTitle(9), value: '9' },
            ],
            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithRadioButton
                    {...props}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                />
            ),
            onFilter: (value, record: UserProgramDetails) => value === record.status.toString(),
        },
        {
            title: GetFormattedMessage('app.user_programs.completedOn'),
            dataIndex: 'completedOn',
            key: 'completedOn',
            width: '20%',
            sorter: (a: UserProgramDetails, b: UserProgramDetails) => (
                a.completedOn?.localeCompare(b?.completedOn ?? '') ?? -1
            ),
            render: (_: string, record: UserProgramDetails) => (
                <span key={'CreateDate' + record.formId}>
                    {GetDate(record.completedOn)}
                </span>
            )
        },
    ];

    if (IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) {
        columns.push({
            title: GetFormattedMessage('app.user_programs.invalid_reason'),
            dataIndex: 'validationFormStatus',
            key: 'validationFormStatus',
            width: '20%',
            sorter: (a: UserProgramDetails, b: UserProgramDetails) => (
                a.validationFormStatus?.toString().localeCompare(b?.validationFormStatus?.toString() ?? '') ?? -1
            ),
            render: (_: string, record: UserProgramDetails) => (
                <span key={'ValidationFormStatus' + record.formId}>
                    {getInvalidStatusReason(record.validationFormStatus) !== undefined ? GetFormattedMessage(getInvalidStatusReason(record.validationFormStatus)) : ''}
                </span>
            )
        })
    }

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <h2 style={styles.h2ProgramTitle}>
                    {userPrograms.userName}
                </h2>
                <Table
                    columns={columns}
                    dataSource={userPrograms.details}
                    pagination={userPrograms.details.length > paginationDefaultPageSize ? defaultPagination : false}
                    rowKey={(record: UserProgramDetails) => record.formId.toString() ?? 'incompleted'}
                />
                <div style={styles.buttonContainer} className='button'>
                    <Button
                        style={styles.btnBack}
                        variant='primary'
                        onClick={() => onBackButtonClicked()}
                    >
                        <FormattedMessage id={'app.btn_back'} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    pageHeader: {
        border: '1px solid ' + colors.gray
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
    iconMarginRight: {
        marginRight: '5'
    } as React.CSSProperties,
    link: {
        color: colors.darkGray,
        marginLeft: 10
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 32,
    } as React.CSSProperties,
    btnBack: {
        width: '150px',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 32
    } as React.CSSProperties,
};
