import { ConsentPageMessages } from '../types';

export const consentPageEN: ConsentPageMessages = {
    'app.consent.title': 'Report review agreement',
    'app.consent.form_title': 'Consent Form',
    'app.consent.greeting': 'Dear Participant:',
    'app.consent.introduction': 'Thank you for your willingness to complete the Beliefs, Events, and Values Inventory or BEVI.',
    'app.consent.general.observation': 'This general consent form has been developed from the input of many different Institutional Review Board (IRB) processes. Before completing the BEVI, it is possible that you may be required to provide consent via a separate system or form that has been approved by a given institution or organization. If you have any questions about this process, please consult the BEVI administrator at your institution or organization.',
    'app.consent.explanation_1': 'As the name of this inventory suggests, the BEVI is designed to assess the many different ways in which people see themselves, others, and the larger world. After completing a series of background questions, you will be asked to respond to items covering a very wide range of issues and topics. You may have different reactions to different items. Some of these items will seem directly related to learning, growth, and development, but some may not; some items may seem straightforward whereas others may seem ambiguous. Because this measure is assessing many complex and interrelated factors, all of these items as well as the background questions are relevant to helping us understand processes that are related to learning, growth, and development as well as how different people see themselves, others, and the larger world.',
    'app.consent.explanation_2': 'Although different people may respond in different ways to these items, please know that there are no "right" or "wrong" answers on the BEVI. Since we often differ in our beliefs, values, and life experiences, it is both common and expected that one person may strongly agree with an item whereas a different person may strongly disagree with that same item. By allowing responses from Strongly Agree, Agree, Disagree, and Strongly Disagree, the BEVI explicitly recognizes that different people may have very different beliefs, values, and life experiences.',
    'app.consent.participant.rights.1': 'Your participation in this study is voluntary and you may choose to discontinue participation at any time. Your privacy will be protected to the maximum extent allowable by law. All of your answers will be treated with <b>strict confidence</b>. Individual answers you give to questions will <b>never be linked to you unless you specifically authorize such linkage in writing.</b> However, in some cases, you may be engaged in an experience where your responses are able to be reviewed directly with you. If that happens, your answers will be treated in strict confidence; your privacy would be protected to the maximum extent allowable by law, and in a manner that is consistent with privileged information. Only the project coordinators will have access to the raw data. For scholarly, teaching, professional, organizational, informational, or illustrative purposes, it is possible that individual scales or items, group profile data, or organizational reports may be presented or published. However, if so, no identifying information ever would be presented or published that could link a specific profile, scales, items, or responses directly to you (e.g., any ID you may have provided would be deleted), unless you specifically authorize such linkage in writing.',
    'app.consent.participant.rights.2': 'Depending upon when and in what context you are completing the BEVI, it is possible that you may be given the opportunity to review a report regarding your responses. If so, you may be required to agree to separate conditions before you are able to read this report. It also is possible that you may be asked to participate in a session where a group report will be discussed; this report may include BEVI data from a larger group of which you may have been a member. As part of a BEVI assessment project, it also is possible that you may be asked to complete other quantitative (e.g., multiple choice) or qualitative (e.g., written responses to questions) measures and/or to provide additional information via written or oral interview questions. This process may involve follow up discussions with the administrator or interpreter of a particular measure. Your participation in all such processes is voluntary and you may choose to discontinue participation at any time. As noted above, your individual responses in any group report or other measure will not be linked to you. Finally, you may also have the opportunity to participate in a feedback session after completion of the BEVI or other quantitative or qualitative measures as indicated above.',
    'app.consent.understand.agree': 'If you understand and agree with the above information and guidelines, and voluntarily consent to complete the BEVI and participate in one or more experiences in which the BEVI is used (e.g., as described above), please click on the \'Agree\' button.',
    'app.consent.have.questions': 'If you have any questions, please feel free to contact us at <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a>',
    'app.consent.thanks': 'Thank you for your participation.',
    'app.consent.giving.consent': 'Giving of Consent',
    'app.consent.read.and.agree': 'I have read about the BEVI and this consent form and I understand what is being requested of me as a participant. I freely consent to participate. I have read the above description of this project and of my rights as a participant. By clicking on the Agree button below, and completing and submitting this confidential online survey, I am consenting to participate.',
    'app.consent.agree': 'Agree',
    'app.consent.disagree': 'Disagree',
    'app.custom_agreement.modal_title': 'Report review agreement',
    'app.custom_agreement.modal_explanation_text': 'Please edit the custom agreement text before submitting it.',
    'app.custom_agreement.default_custom_agreement': 'Dear BEVI Participant:<br/><br/>You already provided consent to take the BEVI, but before we can work with you on your BEVI report, we are asking you to agree 1) that you want to participate in one or more sessions where your individual report will be discussed with you and 2) that you are authorizing <strong>[here we need to be able to fill in one or more names of individual people]</strong> to review your individual report (e.g., your narrative, scores) and discuss the  meaning or implications of your BEVI report with you.<br/>Of course, as indicated in the consent form for the BEVI, your individual results will remain completely confidential.  If you have any questions, or need further information, please contact the BEVI administrator who is working with you.<br/><br/>If you understand and agree to the two above provisions, please click the "agree" button below. Thank you for your interest in the BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>You already provided consent to take the BEVI, but before we can work with you on one or more of your BEVI reports (e.g., for purposes of coaching, consulting, counseling, etc.), we are asking whether you agree with the following points: </h5>
    <br />

    <p>
    1.	I understand that there are several types of BEVI reports where my results may be discussed with me by someone who is BEVI Certified: 1) Individual Report, 2) Individual Change Report, 3) Partner Report, and 4) Partner Change Report.  I also understand that I must provide permission for anyone else to review and discuss one or more of my reports with me.     
    </p>
    <p>
    2.	By clicking the Agree button below, I am authorizing the following BEVI Certified individual or individuals, <b>[#USERS#]</b>, to review and discuss one or more of my reports with me.     
    </p>
    <p>
    3.	I also understand and agree that: 
        <p style="padding-left: 20px;">
        a)	 I am authorizing the following partner or partners, <b>[#PARTNERS#]</b>, to review and discuss my report / results, and their report / results, with me, and with the BEVI Certified individual or individuals that I have authorized above; 
        </p>
        <p style="padding-left: 20px;">
        b)	I will not share or discuss any Partner Report or Partner Change Report in whole or in part – or in any form (e.g., through email, posting, distributing, displaying, copying, etc.) – with anyone other than my partner(s) and the BEVI Certified individual(s) who are listed above; any exceptions to this condition will be agreed to in writing by me and all of the individuals listed here – partner or partners and the BEVI Certified individual or individuals; 
        </p>
        <p style="padding-left: 20px;">
        c)	the partner or partners that I have authorized above may delete their Partner Report(s) or Partner Change Report(s) at any time via changing the permission profile in their BEVI dashboard, which will remove the report(s) from their BEVI dashboard and mine.    
        </p>
    </p>
    <p>
    4.	In addition to the above conditions, all other provisions listed in the BEVI Consent Form 
    will remain in effect (e.g., the voluntary nature of my participation, etc.).  If you have any questions, or need further information, please contact the BEVI Certified individual or individuals who are working with you. 
    </p>
    <p>
    5.	If you agree to all of the above points, please click, “I Agree,” below.  If you do not agree to all of the above points, please click, “I Do Not Agree,” below.
    </p>
    <center>Thank you for your interest in the BEVI!</center>
    `,
}



