import { ExternalPageMessages } from '../types';

export const externalDE: ExternalPageMessages = {
    'app.external.title.thankyou': 'Danke, dass Sie das BEVI ausgefüllt haben.',
    'app.external.thankyou_static_1_1': 'Das Beliefs, Events, and Values Inventory (BEVI) und dieser BEVI-Bericht sind urheberrechtlich geschützt.  ' +
        'Das BEVI und die BEVI-Berichte, Items, Item-Inhalte oder Skalen dürfen nicht, weder Ganz noch teilweise, ohne die schriftliche und ausdrückliche Genehmigung von Craig N. Shealy, Ph.D. verändert, kopiert, verbreitet oder   ' +
        'veröffentlicht werden.',
    'app.external.thankyou_static_1_2': '',
    'app.external.thankyou_static_2': 'Sowie das BEVI als Ganzes, unterliegen auch die Struktur und das Design der Berichte ' +
        'einer ständigen Überprüfung und Weiterentwickelung. Wenn Sie Vorschläge oder Kommentare zu irgendeinem Aspekt des BEVI oder dieses ' +
        'Berichts haben, können Sie uns gerne direkt kontaktieren: info@ibavi.org',
    'app.external.thankyou_static_3': 'Um mehr über Überzeugungen und Werte zu erfahren, besuchen Sie bitte das International Beliefs ' +
        'and Values Institute (IBAVI) unter www.ibavi.org und www.thebevi.com',
}
