import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { Reports, SearchFormsRequest } from '../../../services/reportService';
import {
    FormalEducationOptions,
    GetCountriesOptions, GetEthnicOrientationOptions, GetGenderOptions,
    GetIncomeLevelOfYourHomeCountryOptions, GetMaritialStatusOptions, GetMinorityGroupFilterOptions, GetPrimaryWorkAreaOptions,
    GetReligiousOrientationOptionsForReports, GetTimeSpentInOtherCountriesOptions, GetYearOfEducationOptions
} from '../../../utils/demographHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { FilterNumberRangeGroup } from './FilterNumberRangeGroup';
import { LineEditTextGroup } from './LineEditTextGroup';
import { SelectOptionGroup } from './SelectOptionGroup';
import { Step3IndividualReport } from './Step3IndividualReport';
import { SearchUsersDetails } from '../../../services/usersService';

type Props = {
    showCriteriaFilter: boolean
    filterValues: Partial<SearchFormsRequest>;
    reportType?: Reports;
    loadingUsers?: boolean;
    users?: SearchUsersDetails[];
    selectedUsers?: number[];
    onFilterChange: (values: Partial<SearchFormsRequest>) => void;
    handleFilterVisibilityToggleButton: () => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    setSelectedUsers?: (value: number[]) => void;
};

export const Step3 = (
    { showCriteriaFilter, filterValues, reportType, loadingUsers, users, selectedUsers, onFilterChange, handleFilterVisibilityToggleButton, setSelectedUsers }: Props
): JSX.Element => {

    const genderOptions = GetGenderOptions();
    const genderValues = filterValues?.gender?.map((value: number) => ({
        value: value,
        label: genderOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const maritalStatusOptions = GetMaritialStatusOptions();
    const maritialStatusValues = filterValues?.maritalStatus?.map((value: number) => ({
        value: value,
        label: maritalStatusOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const countriesOptions = GetCountriesOptions();
    const primaryCountryOfOriginValues = filterValues?.primaryCountryOfOrigin?.map((value: number) => ({
        value: value,
        label: countriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));
    const motherCountryValues = filterValues?.motherCountry?.map((value: number) => ({
        value: value,
        label: countriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));
    const fatherCountryValues = filterValues?.fatherCountry?.map((value: number) => ({
        value: value,
        label: countriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const educationOptions = FormalEducationOptions();
    const motherEducationValues = filterValues?.motherEducation?.map((value: number) => ({
        value: value,
        label: educationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const fatherEducationValues = filterValues?.fatherCountry?.map((value: number) => ({
        value: value,
        label: educationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const yearOfEducationOptions = GetYearOfEducationOptions();
    const yearOfEducationValues = filterValues?.yearsOfEducation?.map((value: number) => ({
        value: value,
        label: yearOfEducationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const ethnicOrientationOptions = GetEthnicOrientationOptions()
    const ethnicOrientationValues = filterValues?.ethnicBackground?.map((value: number) => ({
        value: value,
        label: ethnicOrientationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const minorityGroupOptions = GetMinorityGroupFilterOptions()
    const minorityGroupValues = filterValues?.minorityGroup?.map((value: number) => ({
        value: value,
        label: minorityGroupOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const religiousOrientationOptions = GetReligiousOrientationOptionsForReports()
    const religiousOrientationValues = filterValues?.religiousOrientation?.map((value: number) => ({
        value: value,
        label: religiousOrientationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const timeSpentInOtherCountriesOptions = GetTimeSpentInOtherCountriesOptions();
    const timeSpentInOtherCountriesValues = filterValues?.timeSpentInAnotherCountry?.map((value: number) => ({
        value: value,
        label: timeSpentInOtherCountriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    // const fluencyOptions = GetLevelOfFluencyOptions();
    // const fluencyValues = filterValues?.fluency?.map((value: number) => ({
    //     value: value,
    //     label: fluencyOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    // }));

    const incomeLevelOfYourHomeCountryOptions = GetIncomeLevelOfYourHomeCountryOptions();
    const incomeLevelOfYourHomeCountryValues = filterValues?.incomeLevelOfYourHomeCountry?.map((value: number) => ({
        value: value,
        label: incomeLevelOfYourHomeCountryOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    const primaryWorkAreaOptions = GetPrimaryWorkAreaOptions();
    const primaryWorkAreaValues = filterValues?.primaryAreaOfWork?.map((value: number) => ({
        value: value,
        label: primaryWorkAreaOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    }));

    if (reportType != null && [Reports.individual_report, Reports.longitudinal_individual_report, Reports.partner_report, Reports.partner_change_report].includes(reportType)) {
        return <Step3IndividualReport selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} loadingUsers={loadingUsers} users={users} />;
    }

    return (
        // @ts-ignore
        <Form style={styles.formDiv}>
            <Form.Group className='mb-3'>
                <Button
                    // @ts-ignore
                    style={styles.filterVisibilityToggleButton}
                    variant={showCriteriaFilter ? 'secondary' : 'primary'}
                    onClick={() => handleFilterVisibilityToggleButton()}
                >
                    {showCriteriaFilter ?
                        <React.Fragment>
                            <FaEyeSlash color={colors.white} style={styles.iconSpace} />
                            {GetFormattedMessage('app.create_report.hide_filters')}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <FaEye color={colors.white} style={styles.iconSpace} />
                            {GetFormattedMessage('app.create_report.show_filters')}
                        </React.Fragment>
                    }
                </Button>
            </Form.Group>
            {showCriteriaFilter &&
                <React.Fragment>
                    {/* Filter Group Core Demographics */}
                    <div className="bd-callout bd-callout-danger">
                        <h4>Core Demographics</h4>
                        <Form.Group className='mb-3' as={Row}>
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_age'
                                value={filterValues?.age?.length === 2 ?
                                    [filterValues.age[0], filterValues.age[1]]
                                    :
                                    undefined
                                }
                                min={0}
                                max={120}
                                onFilterChange={(values: { min: number, max: number } | undefined) => {
                                    onFilterChange({
                                        ...filterValues,
                                        age: values ? [values.min, values.max] : undefined
                                    })
                                }}
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_primary_country_of_origin'
                                value={primaryCountryOfOriginValues}
                                options={countriesOptions}
                                isSelectedOption={
                                    !!filterValues?.primaryCountryOfOrigin &&
                                    filterValues.primaryCountryOfOrigin.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, primaryCountryOfOrigin: values })
                                }}
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_ethnic_background'
                                value={ethnicOrientationValues}
                                options={ethnicOrientationOptions}
                                isSelectedOption={
                                    !!filterValues?.ethnicBackground &&
                                    filterValues.ethnicBackground.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, ethnicBackground: values })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_gender'
                                value={genderValues}
                                options={genderOptions}
                                isSelectedOption={
                                    !!filterValues?.gender && filterValues.gender.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, gender: values })
                                }}
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_income_level_of_your_home_country'
                                value={incomeLevelOfYourHomeCountryValues}
                                options={incomeLevelOfYourHomeCountryOptions}
                                isSelectedOption={
                                    !!filterValues?.incomeLevelOfYourHomeCountry &&
                                    filterValues.incomeLevelOfYourHomeCountry.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, incomeLevelOfYourHomeCountry: values })
                                }}
                            />
                            <SelectOptionGroup
                                groupMd={"4"}
                                groupAs={Col}
                                labelKey='app.create_report.filter_minority_group'
                                value={minorityGroupValues}
                                options={minorityGroupOptions}
                                isSelectedOption={
                                    !!filterValues?.minorityGroup &&
                                    filterValues.minorityGroup.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, minorityGroup: values })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                groupMd='4'
                                labelKey='app.create_report.filter_marital_status'
                                value={maritialStatusValues}
                                options={maritalStatusOptions}
                                isSelectedOption={
                                    !!filterValues?.maritalStatus &&
                                    filterValues.maritalStatus.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, maritalStatus: values })
                                }}
                            />
                        </Form.Group>
                    </div>

                    {/* Filter Group Parental Background */}
                    <div className="bd-callout bd-callout-pink">
                        <h4>Parental Background</h4>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_father_country'
                                value={fatherCountryValues}
                                options={countriesOptions}
                                isSelectedOption={
                                    !!filterValues?.fatherCountry &&
                                    filterValues.fatherCountry.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, fatherCountry: values })
                                }}
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_father_education'
                                value={fatherEducationValues}
                                options={educationOptions}
                                isSelectedOption={
                                    !!filterValues?.fatherEducation &&
                                    filterValues.fatherEducation.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, fatherEducation: values })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_mother_country'
                                value={motherCountryValues}
                                options={countriesOptions}
                                isSelectedOption={
                                    !!filterValues?.motherCountry &&
                                    filterValues.motherCountry.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, motherCountry: values })
                                }}
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_mother_education'
                                value={motherEducationValues}
                                options={educationOptions}
                                isSelectedOption={
                                    !!filterValues?.motherEducation &&
                                    filterValues.motherEducation.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, motherEducation: values })
                                }}
                            />
                        </Form.Group>
                    </div>

                    {/* Filter Group Education Background */}
                    <div className="bd-callout bd-callout-soft-green">
                        <h4>Education Background</h4>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_years_of_education'
                                value={yearOfEducationValues}
                                options={yearOfEducationOptions}
                                isSelectedOption={
                                    !!filterValues?.yearsOfEducation &&
                                    filterValues.yearsOfEducation.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, yearsOfEducation: values })
                                }}
                            />
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_academic_rank'
                                value={filterValues?.academicRank?.length === 2 ?
                                    [filterValues?.academicRank[0], filterValues?.academicRank[1]]
                                    :
                                    undefined
                                }
                                min={0}
                                max={100}
                                onFilterChange={(values: { min: number, max: number } | undefined) => {
                                    onFilterChange({
                                        ...filterValues,
                                        academicRank: values ? [values.min, values.max] : undefined
                                    })
                                }}
                            />
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_years_of_language_study'
                                value={filterValues?.yearsOfLanguageStudy?.length === 2 ?
                                    [filterValues?.yearsOfLanguageStudy[0], filterValues?.yearsOfLanguageStudy[1]]
                                    :
                                    undefined
                                }
                                min={0}
                                max={120}
                                onFilterChange={(values: { min: number, max: number } | undefined) =>
                                    onFilterChange({
                                        ...filterValues,
                                        yearsOfLanguageStudy: values ? [values.min, values.max] : undefined
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_primary_area_of_work'
                                value={primaryWorkAreaValues}
                                options={primaryWorkAreaOptions}
                                isSelectedOption={
                                    !!filterValues?.primaryAreaOfWork &&
                                    filterValues.primaryAreaOfWork.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, primaryAreaOfWork: values })
                                }}
                            />
                            <LineEditTextGroup
                                groupAs={Col}
                                value={filterValues?.areaOfOccupations}
                                labelKey='app.create_report.filter_area_of_occupations'
                                onFilterChange={(value: string | undefined) =>
                                    onFilterChange({ ...filterValues, areaOfOccupations: value })
                                }
                            />
                        </Form.Group>
                    </div>

                    {/* Filter Group International and Cultural Experiences */}
                    <div className="bd-callout bd-callout-green">
                        <h4>International and Cultural Experiences</h4>
                        <Form.Group className='mb-3' as={Row}>
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_nro_visited_countries'
                                value={filterValues?.nroVisitedCountry?.length === 2 ?
                                    [filterValues.nroVisitedCountry[0], filterValues.nroVisitedCountry[1]]
                                    :
                                    undefined
                                }
                                min={0}
                                max={223}
                                onFilterChange={(values: { min: number, max: number } | undefined) =>
                                    onFilterChange({
                                        ...filterValues,
                                        nroVisitedCountry: values ? [values.min, values.max] : undefined
                                    })
                                }
                            />
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_time_spent_in_other_countries'
                                value={timeSpentInOtherCountriesValues}
                                options={timeSpentInOtherCountriesOptions}
                                isSelectedOption={
                                    !!filterValues?.timeSpentInAnotherCountry &&
                                    filterValues.timeSpentInAnotherCountry.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, timeSpentInAnotherCountry: values })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_personal_interest'
                                value={filterValues?.personalInterest?.length === 2 ?
                                    [filterValues?.personalInterest[0], filterValues?.personalInterest[1]]
                                    :
                                    undefined
                                }
                                min={1}
                                max={7}
                                onFilterChange={(values: { min: number, max: number } | undefined) =>
                                    onFilterChange({
                                        ...filterValues,
                                        personalInterest: values ? [values.min, values.max] : undefined
                                    })
                                }
                            />
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_study_abroad_satisfaction'
                                value={filterValues?.studyAbroadSatisfaction?.length === 2 ?
                                    [filterValues?.studyAbroadSatisfaction[0], filterValues?.studyAbroadSatisfaction[1]]
                                    :
                                    undefined
                                }
                                min={1}
                                max={7}
                                onFilterChange={(values: { min: number, max: number } | undefined) =>
                                    onFilterChange({
                                        ...filterValues,
                                        studyAbroadSatisfaction: values ? [values.min, values.max] : undefined
                                    })
                                }
                            />
                            {/* todo(rubens.sotero): Implement on the backend */}
                            {/* <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_fluency'
                                value={fluencyValues}
                                options={fluencyOptions}
                                isSelectedOption={
                                    !!filterValues?.fluency &&
                                    filterValues.fluency.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, fluency: values })
                                }}
                            /> */}
                        </Form.Group>
                    </div>

                    {/* Filter Group Political and Religious Inclinations */}
                    <div className="bd-callout bd-callout-info">
                        <h4>Political and Religious Inclinations</h4>
                        <Form.Group className='mb-3' as={Row}>
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_political_orientation'
                                value={filterValues?.politicalOrientation?.length === 2 ?
                                    [filterValues?.politicalOrientation[0], filterValues?.politicalOrientation[1]]
                                    :
                                    undefined
                                }
                                min={0}
                                max={100}
                                onFilterChange={(values: { min: number, max: number } | undefined) => {
                                    onFilterChange({
                                        ...filterValues,
                                        politicalOrientation: values ? [values.min, values.max] : undefined
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <SelectOptionGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_religious_orientation'
                                value={religiousOrientationValues}
                                options={religiousOrientationOptions}
                                isSelectedOption={
                                    !!filterValues?.religiousOrientation &&
                                    filterValues.religiousOrientation.length > 0
                                }
                                onFilterChange={(values: number[] | undefined) => {
                                    onFilterChange({ ...filterValues, religiousOrientation: values })
                                }}
                            />
                            <FilterNumberRangeGroup
                                groupAs={Col}
                                labelKey='app.create_report.filter_religious_commitment'
                                value={filterValues?.religionCommitment?.length === 2 ?
                                    [filterValues?.religionCommitment[0], filterValues?.religionCommitment[1]]
                                    :
                                    undefined
                                }
                                min={1}
                                max={7}
                                onFilterChange={(values: { min: number, max: number } | undefined) => {
                                    onFilterChange({
                                        ...filterValues,
                                        religionCommitment: values ? [values.min, values.max] : undefined
                                    })
                                }}
                            />
                        </Form.Group>
                    </div>

                    {/* Filter Group Experiences */}
                    {/* <div className="bd-callout bd-callout-green">
                        <h4>Experiences</h4>
                    </div> */}
                </React.Fragment>
            }
        </Form>
    );
}

const styles = {
    formDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
    filterVisibilityToggleButton: {
        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
        width: '200px',
        border: 'none',
    } as React.CSSProperties,
    iconSpace: {
        marginRight: '5px',
    } as React.CSSProperties,
}
