import React from 'react';
import { ScaleGroup } from '../../../services/reportService';
import { GroupReportAggregateByDemographics_Income } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { AggregateProfileByDemographics } from './AggregateProfileByDemographics';
import { InsufficientDataComponent } from './InsufficientDataComponent';
import { getScaleTitle } from '../../../utils/reportHelper';

type Props = {
  reportIncome: GroupReportAggregateByDemographics_Income[];
  subProgramName?: string[];
}

type ScaleProps = {
  title: string;
  value: number;
  hasSpace: boolean;
}

const hasEnoughData = (report: GroupReportAggregateByDemographics_Income[]) => {
  let invalidReportCount = 0;
  for (let i = 0; i < report.length; i++) {
    if (!report[i].hasEnoughData) {
      invalidReportCount += 1;
    }
  }

  if (report.length === 1) {
    if (invalidReportCount === 0) return true;
    return false;
  }
  else {
    return report.length - invalidReportCount > 1;
  }
}

const buildSubTitle = (report: GroupReportAggregateByDemographics_Income[],
  subProgramName?: string[]) => {

  const subTitle: string[][] = [];
  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    subTitle.push(
      [
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].incomeRangeByEstimate[0]})`,
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].incomeRangeByEstimate[1]})`,
        `${subProgramName ? subProgramName[i] + ' - ' : ''}(${report[i].incomeRangeByEstimate[2]})`,
      ]
    )

  }
  return subTitle;
}

const buildScales = (report: GroupReportAggregateByDemographics_Income[],
  scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {

  const scalePropos: ScaleProps[] = [];
  const scaleTitle = getScaleTitle(scale);

  const bottomThird = GetFormattedMessage('app.report.aggregate_profile.income.bottom_third');
  const middleThird = GetFormattedMessage('app.report.aggregate_profile.income.middle_third');
  const topThird = GetFormattedMessage('app.report.aggregate_profile.income.top_third');

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${bottomThird})`,
      value: reportItem.scales[scale][0],
      hasSpace: i === 0 && name !== ''
    });
  }

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${middleThird})`,
      value: reportItem.scales[scale][1],
      hasSpace: i === 0 && name !== ''
    });
  }

  for (let i = 0; i < report.length; i++) {

    if (!report[i].hasEnoughData) continue;

    const reportItem = report[i];
    const name = subGroupName && subGroupName.length > i ? subGroupName[i] : '';

    scalePropos.push({
      //@ts-ignore
      title: `${scaleNumber}. ${GetFormattedMessage(scaleTitle)} (${name && name + ' - '}${topThird})`,
      value: reportItem.scales[scale][2],
      hasSpace: i === 0 && name !== ''
    });
  }

  return scalePropos;
}

export const AggregateProfileByDemographics_Income = ({ reportIncome, subProgramName }: Props) => {

  if (!hasEnoughData(reportIncome)) {
    return <InsufficientDataComponent />
  }

  const bottomThird = GetFormattedMessage('app.report.aggregate_profile.income.bottom_third');
  const middleThird = GetFormattedMessage('app.report.aggregate_profile.income.middle_third');
  const topThird = GetFormattedMessage('app.report.aggregate_profile.income.top_third');
  return (
    <AggregateProfileByDemographics
      title={[
        `${bottomThird} (N = ${reportIncome.map(item => item.incomeByEstimate[1]).reduce((prev, next) => prev + next)})`,
        `${middleThird} (N = ${reportIncome.map(item => item.incomeByEstimate[2]).reduce((prev, next) => prev + next)})`,
        `${topThird} (N = ${reportIncome.map(item => item.incomeByEstimate[3]).reduce((prev, next) => prev + next)})`
      ]}
      subTitle={buildSubTitle(reportIncome, subProgramName)}
      negativeLifeEvents={buildScales(reportIncome, ScaleGroup.NegativeLifeEvents, 1, subProgramName)}
      needsClosure={buildScales(reportIncome, ScaleGroup.NeedsClosure, 2, subProgramName)}
      needsFulfillment={buildScales(reportIncome, ScaleGroup.NeedsFulfillment, 3, subProgramName)}
      identityDiffusion={buildScales(reportIncome, ScaleGroup.IdentityDiffusion, 4, subProgramName)}
      basicOpenness={buildScales(reportIncome, ScaleGroup.BasicOpenness, 5, subProgramName)}
      selfCertitude={buildScales(reportIncome, ScaleGroup.SelfCertitude, 6, subProgramName)}
      basicDeterminism={buildScales(reportIncome, ScaleGroup.BasicDeterminism, 7, subProgramName)}
      socioemotionalConvergence={buildScales(reportIncome, ScaleGroup.SocioemotionalConvergence, 8, subProgramName)}
      physicalResonance={buildScales(reportIncome, ScaleGroup.PhysicalResonance, 9, subProgramName)}
      emotionalAttunument={buildScales(reportIncome, ScaleGroup.EmotionalAttunement, 10, subProgramName)}
      selfAwereness={buildScales(reportIncome, ScaleGroup.SelfAwereness, 11, subProgramName)}
      meaningQuest={buildScales(reportIncome, ScaleGroup.MeaningQuest, 12, subProgramName)}
      religiousTraditionalism={buildScales(reportIncome, ScaleGroup.ReligiousTraditionalism, 13, subProgramName)}
      genderTraditionalism={buildScales(reportIncome, ScaleGroup.GenderTraditionalism, 14, subProgramName)}
      socioCulturalOpenness={buildScales(reportIncome, ScaleGroup.SocioculturalOpenness, 15, subProgramName)}
      ecologicalResonance={buildScales(reportIncome, ScaleGroup.EcologicalResonance, 16, subProgramName)}
      globalResonance={buildScales(reportIncome, ScaleGroup.GlobalResonance, 17, subProgramName)}
    />
  )
}