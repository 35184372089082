import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'Preguntes sense resposta',
    'app.questions.confirmation':
        'Segur que vols continuar sense respondre a totes les preguntes? No podràs tornar-hi més endavant.',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': 'Molt d\'acord',
    'app.question.agree': 'D\'acord',
    'app.question.disagree': 'En desacord',
    'app.question.strongly_disagree': 'Molt en desacord',
    'app.question.q1': 'De vegades no sé quin camí he de seguir.',
    'app.question.q2': 'La gent es burlava de mi quan era més jove.',
    'app.question.q3': 'De vegades em comparo amb els altres.',
    'app.question.q4': 'És saludable que els joves experimentin sexualment.',
    'app.question.q5': 'He tingut molts conflictes amb un o més membres de la meva família.',
    'app.question.q6': 'Em faria por estar tot/a sol/a al món.',
    'app.question.q7': 'Tant de bo que la meva mare i el meu pare haguessin fet les coses d\'una altra manera.',
    'app.question.q8': 'Les dones són gairebé sempre més emocionals durant la menstruació.',
    'app.question.q9': 'Hauríem d\'intentar entendre cultures diferents de la nostra.',
    'app.question.q10': 'La meva mare és/era la meva millor amiga.',
    'app.question.q11': 'Algun dia acceptarem que els homes i les dones són simplement diferents.',
    'app.question.q12': 'El lloc d\'una dona és a casa.',
    'app.question.q13': 'Sovint em quedo embadalit/da amb la bellesa d\'aquest món.',
    'app.question.q14': 'Algunes persones tenen millor genètica.',
    'app.question.q15': 'Els prejudicis són a tot arreu i hem de superar-los.',
    'app.question.q16': 'He passat per una crisi d\'identitat dolorosa.',
    'app.question.q17': 'Ploro diverses vegades  l\'any.',
    'app.question.q18': 'Sempre puc/podia comptar amb la meva mare o el meu pare per rebre bons consells.',
    'app.question.q19': 'La part inconscient de la nostra ment pot influir en els sentiments i en el comportament.',
    'app.question.q20': 'És important estar ben informat/da sobre els fets mundials.',
    'app.question.q21': 'Un home ha de comportar-se com un home.',
    'app.question.q22': 'Déu em va fer tal com soc.',
    'app.question.q23': 'Al final, la gent tria ser feliç o infeliç.',
    'app.question.q24': 'Sento una profunda connexió espiritual amb la Terra.',
    'app.question.q25': 'Vaig tenir una infància meravellosa.',
    'app.question.q26': 'No sempre em sento bé amb qui soc.',
    'app.question.q27': 'No està bé dubtar de l\'existència de Déu.',
    'app.question.q28': 'No pots culpar  ningú més que a tu mateix/a dels teus problemes.',
    'app.question.q29': 'Les dones són més dependents emocionalment que els homes.',
    'app.question.q30': 'M\'agrada tenir converses profundes que duren hores.',
    'app.question.q31': 'Hauríem d\'augmentar el salari mínim.',
    'app.question.q32': 'Vaig tenir una relació sana i positiva amb el meu pare quan era més jove.',
    'app.question.q33': 'La SIDA podria ser un senyal de la ira de Déu.',
    'app.question.q34': 'Hi ha massa persones buscant ajudes gratuïtes de l\'Estat.',
    'app.question.q35': 'L\'educació sexual s\'ha d\'ensenyar a les nostres escoles.',
    'app.question.q36': 'Vaig veure molta indignació o agressivitat quan era més jove.',
    'app.question.q37': 'Vull trobar una millor sensació d\'equilibri a la meva vida.',
    'app.question.q38': 'Em prenc els meus sentiments molt seriosament.',
    'app.question.q39': 'Les dones poden ser extremadament competitives.',
    'app.question.q40': 'No hauríem de negar la nostra naturalesa animal.',
    'app.question.q41': 'Tinc vergonya quan penso en alguns moments del meu passat.',
    'app.question.q42': 'He tingut molts conflictes en les meves relacions.',
    'app.question.q43': 'Soc més liberal que conservador/a pel que fa a temes socials.',
    'app.question.q44': 'Penso molt en el sentit de la vida.',
    'app.question.q45': 'De vegades estic avorrit/da.',
    'app.question.q46': 'És normal que el jovent experimenti amb alcohol i drogues recreatives.',
    'app.question.q47': 'Les dones tenen molts problemes per decidir-se.',
    'app.question.q48': 'Conèixer altres cultures m\'ajuda a entendre la meva.',
    'app.question.q49': 'Només hi ha un camí per arribar al cel.',
    'app.question.q50': 'La gent sol fracassar perquè no s\'esforça prou.',
    'app.question.q51': 'He hagut de mantenir a distància els meus pares.',
    'app.question.q52': 'Els homes són com són i s’ha d’acceptar.',
    'app.question.q53': 'Els humans han evolucionat al llarg de milions d\'anys.',
    'app.question.q54': 'Una o més persones de la meva família van rebre abusos físics, sexuals o emocionals.',
    'app.question.q55': 'Tinc autèntica necessitat de carinyo i afecte.',
    'app.question.q56': 'S’haurien de destinar més diners a programes d’educació infantil i primària.',
    'app.question.q57': 'Soc una persona molt sensible.',
    'app.question.q58': 'Estic a favor de la feina que fan els sindicats.',
    'app.question.q59': 'Em preocupa el medi ambient.',
    'app.question.q60': 'Em fa mal ser rebutjat/da.',
    'app.question.q61': 'No tinc res a dir de negatiu sobre els meus pares.',
    'app.question.q62': 'M\'he preguntat qui soc i cap a on vaig.',
    'app.question.q63': 'De vegades em superen les emocions.',
    'app.question.q64': 'Les dones xafardegen molt més que els homes.',
    'app.question.q65': 'Sense religió no hi pot haver pau.',
    'app.question.q66': 'No es pot negar que els homes són més forts que les dones.',
    'app.question.q67': 'Com a societat som massa consumistes.',
    'app.question.q68': 'La meva família tenia molts problemes econòmics.',
    'app.question.q69': 'Sempre intento entendre\'m millor.',
    'app.question.q70': 'És natural que els forts sobrevisquin.',
    'app.question.q71': 'Els homes són més lògics que les dones.',
    'app.question.q72': 'Treballar sentiments dolorosos del passat ens pot ajudar.',
    'app.question.q73': 'De vegades, els homes se senten febles, igual que les dones.',
    'app.question.q74': 'Estaria malament vestir  un nen de rosa.',
    'app.question.q75': 'M\'agrada veure pel·lícules sobre relacions sentimentals.',
    'app.question.q76': 'Vaig tenir una relació sana i positiva amb la meva mare quan era més jove.',
    'app.question.q77': 'Em preocupa la salut del nostre planeta.',
    'app.question.q78': 'El meu cos és molt sensible al que sento.',
    'app.question.q79': 'He de reconèixer que soc igual que els meus pares.',
    'app.question.q80': 'Les dones són més emocionals que els homes.',
    'app.question.q81': 'Tinc fermes creences religioses.',
    'app.question.q82': 'Soc com soc perquè vaig néixer així.',
    'app.question.q83': 'Els meus pares van tenir molts conflictes en la seva relació.',
    'app.question.q84': 'M\'agrada pensar qui soc.',
    'app.question.q85': 'Les dones han fet progressos extraordinaris en les últimes dècades.',
    'app.question.q86': 'El meu pare és el meu heroi.',
    'app.question.q87': 'M\'ha costat molt decidir què he de fer amb la meva vida.',
    'app.question.q88': 'De vegades sento les coses massa intensament.',
    'app.question.q89': 'Hauríem de veure la Terra com la nostra mare.',
    'app.question.q90': 'M\'he sentit sol/a en la meva vida.',
    'app.question.q91': 'Molts professors d\'universitat són massa progressistes en les seves opinions.',
    'app.question.q92': 'L’única esperança real de curació prové d’un poder superior.',
    'app.question.q93': 'De vegades em sento vulnerable i necessitat/da d’afecte.',
    'app.question.q94': 'Hauríem de fer més per ajudar els grups minoritaris de la nostra societat.',
    'app.question.q95': 'Tothom es podria beneficiar d\'aprendre més d\'un idioma.',
    'app.question.q96': 'Tot i que esperem que ho siguin, els homes no estan realment fets per ser fidels en el matrimoni.',
    'app.question.q97': 'No entenc per què passen algunes coses.',
    'app.question.q98': 'El meu pare és/era emocionalment distant amb mi.',
    'app.question.q99': 'Hauríem d\'elegir una dona per dirigir la nostra nació.',
    'app.question.q100': 'Al final, un escull la vida que té.',
    'app.question.q101': 'El poder judicial del nostre país és massa progressista.',
    'app.question.q102': 'Vull que em prenguin seriosament.',
    'app.question.q103': 'Hauríem de fer més per ajudar els menys afortunats.',
    'app.question.q104': 'No em molesten les mostres d\'emoció.',
    'app.question.q105': 'Quan era més jove vaig patir danys emocionals.',
    'app.question.q106': 'La pornografia degrada les dones.',
    'app.question.q107': 'Em fan enfadar les injustícies del món.',
    'app.question.q108': 'Les principals propostes del govern fan més mal que bé.',
    'app.question.q109': 'Van ferir els meus sentiments en el passat.',
    'app.question.q110': 'M\'agrada llegir llibres d\'autoajuda.',
    'app.question.q111': 'La meva mare o el meu pare tenen/han tingut problemes emocionals.',
    'app.question.q112': 'Gaudeixo aprenent sobre altres cultures.',
    'app.question.q113': 'Hem de preservar les tradicions que són nostres.',
    'app.question.q114': 'Les dones no són prou ambicioses per tenir èxit.',
    'app.question.q115': 'De vegades estic enfadat/da amb altres persones.',
    'app.question.q116': 'Ningú et pot fer mal de veritat llevat que se li ho permeti.',
    'app.question.q117': 'Necessito algú en la meva vida que m’escolti com estic.',
    'app.question.q118': 'Pots superar qualsevol problema si t’esforces més.',
    'app.question.q119': 'Resar no serveix per a res.',
    'app.question.q120': 'Sovint estic/estava enmig dels problemes o conflictes dels meus pares.',
    'app.question.q121': 'Hi ha una bretxa massa gran entre els rics i els pobres del nostre país.',
    'app.question.q122': 'No entenc tot allò que sento.',
    'app.question.q123': 'La gent realment no canvia.',
    'app.question.q124': 'Mai no vaig sentir que fos prou bo/na per a la meva família.',
    'app.question.q125': 'Els homes són més competitius per naturalesa que les dones.',
    'app.question.q126': 'Hem de fer més per protegir els recursos naturals de la Terra.',
    'app.question.q127': 'He tingut greus problemes econòmics a la meva vida.',
    'app.question.q128': 'No m’agrada ser criticat/da.',
    'app.question.q129': 'La meva mare o el meu pare i jo estem/estàvem units.',
    'app.question.q130': 'Tinc problemes que he de treballar.',
    'app.question.q131': 'La pregària és més curativa que la medicina tradicional.',
    'app.question.q132': 'Vaig ser maltractat/da o no vaig rebre prou atenció quan era més jove.',
    'app.question.q133': 'S’ha de posar més empenta en la guerra contra les drogues.',
    'app.question.q134': 'Estic fermament a favor de la igualtat de drets de les dones.',
    'app.question.q135': 'El meu pare em va entendre i em va acceptar.',
    'app.question.q136': 'L’educació és la clau per a una societat més il.lustrada.',
    'app.question.q137': 'El paper d’un home és ser fort.',
    'app.question.q138': 'Hauríem de protegir la terra independentment de qui la posseeixi.',
    'app.question.q139': 'Els meus pares m’exigien massa.',
    'app.question.q140': 'Tothom ens beneficiaríem si les dones tinguessin més poder econòmic i polític.',
    'app.question.q141': 'De vegades ploro quan altres persones ploren.',
    'app.question.q142': 'Algunes dones demanen ser assetjades per la manera com vesteixen.',
    'app.question.q143': 'Si no pots dir alguna cosa agradable, no diguis res.',
    'app.question.q144': 'No es pot canviar el passat, així que no té sentit viure-hi ancorat/da.',
    'app.question.q145': 'Quan la vida et colpeja, has d’aixecar-te i seguir endavant.',
    'app.question.q146': 'Que et rebutgin sovint et fa més fort/a.',
    'app.question.q147': 'Algunes persones analitzen massa el món.',
    'app.question.q148': 'Sempre que estic malament intento tenir pensaments positius.',
    'app.question.q149': 'Sempre és millor centrar-se en les fortaleses que en les debilitats.',
    'app.question.q150': 'La millor manera de viure la vida és mantenint una actitud positiva.',
    'app.question.q151': 'Un cop fet, ja està fet; així que perdona i oblida.',
    'app.question.q152': 'Realment no es pot dir que un punt de vista sigui millor que un altre.',
    'app.question.q153': 'Crec que la gent hauria d\'estar agraïda pel que té i deixar de queixar-se.',
    'app.question.q154': 'Alguns números porten més mala sort que d’altres.',
    'app.question.q155': 'No cal dir paraulotes.',
    'app.question.q156': 'Massa gent no assumeix les seves responsabilitats.',
    'app.question.q157': 'Els vidents no poden predir el futur.',
    'app.question.q158': 'Sempre és millor intentar simplificar les coses.',
    'app.question.q159': 'És impossible que dos experts es posin d\'acord amb res.',
    'app.question.q160': 'Si segueixes les regles del joc, t\'aniran bé les coses.',
    'app.question.q161': 'Estaria bé agradar a tothom o almenys que em respectessin.',
    'app.question.q162': 'Hauríem de ser més tolerants amb diversos costums culturals.',
    'app.question.q163': 'Soc un esperit lliure.',
    'app.question.q164': 'Crec en el poder de l’astrologia.',
    'app.question.q165': 'Em fan pena les persones que viuen en la pobresa.',
    'app.question.q166': 'Valoro la lògica per damunt d’altres coses.',
    'app.question.q167': 'Estic còmode/a amb grups de persones que són molt diferents de mi.',
    'app.question.q168': 'Els pares haurien de romandre de seguir junts pel bé dels seus fills/es.',
    'app.question.q169': 'És saludable que les persones tinguin fantasies sexuals.',
    'app.question.q170': 'Les etiquetes diagnòstiques no són del tot útils.',
    'app.question.q171': 'Sempre busco el costat bo de les coses.',
    'app.question.q172': 'Éssers d’altres planetes han ajudat la nostra espècie.',
    'app.question.q173': 'La Terra té milers de milions d’anys.',
    'app.question.q174': 'La debilitat pot ser una virtut.',
    'app.question.q175': 'A ningú li agrada un/a pessimista.',
    'app.question.q176': 'Em molesten les mostres d’emocions.',
    'app.question.q177': 'Els meus pares tenien una relació molt bona mentre jo creixia.',
    'app.question.q178': 'Som massa tolerants amb les diverses pràctiques culturals.',
    'app.question.q179': 'Ja fem prou per ajudar els pobres.',
    'app.question.q180': 'La gent es preocupa massa pel medi ambient.',
    'app.question.q181': 'Els prejudicis realment ja no són un problema.',
    'app.question.q182': 'La gent canvia constantment.',
    'app.question.q183': 'Hi ha molts camins per arribar al cel.',
    'app.question.q184': 'Els homes i les dones tenen més similituds que diferències.',
    'app.question.q185': 'En realitat no triem la vida que tenim.',
};

export const scaleQuestionsPageCA: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
