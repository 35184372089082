import { appEN } from './en/app';
import { consentPageEN } from './en/consent';
import { counterPageEN } from './en/counter';
import { createReportEN } from './en/create_report';
import { createProgramEN } from './en/createProgram';
import { dataDownloadEN } from './en/dataDownload';
import { demographicQuestionsEN } from './en/demographicQuestions';
import { discursiveQuestionsEN } from './en/discursiveQuestions';
import { externalEN } from './en/external';
import { individualReportPageEN } from './en/individualReport';
import { internalTemplatePageEN } from './en/internalTemplate';
import { listFormsPageEN } from './en/listforms';
import { listOrganizationsEN } from './en/listOrganizations';
import { listReportEN } from './en/listReport';
import { loginPageEN } from './en/login';
import { noInfoEN } from './en/noInfo';
import { permissionEN } from './en/permission';
import { reportsEN } from './en/reports';
import { scaleQuestionsPageEN } from './en/scaleQuestions';
import { timeEN } from './en/time';
import { usersEN } from './en/users';
import { PageMessages } from './types';
import { financialReportEN } from './en/financialReport';
import { invitationEN } from './en/invitation';
import { institutionEN } from './en/institutions';
import { programsEN } from './en/programs';
import { virtualAccountEN } from './en/virtualAccount';

export const en: PageMessages = {
    ...appEN,
    ...counterPageEN,
    ...loginPageEN,
    ...scaleQuestionsPageEN,
    ...consentPageEN,
    ...listFormsPageEN,
    ...demographicQuestionsEN,
    ...listOrganizationsEN,
    ...discursiveQuestionsEN,
    ...individualReportPageEN,
    ...internalTemplatePageEN,
    ...createProgramEN,
    ...usersEN,
    ...dataDownloadEN,
    ...noInfoEN,
    ...createReportEN,
    ...reportsEN,
    ...timeEN,
    ...listReportEN,
    ...permissionEN,
    ...externalEN,
    ...financialReportEN,
    ...invitationEN,
    ...institutionEN,
    ...programsEN,
    ...virtualAccountEN
}
