import { CreateReportMessages } from '../types';

export const createReportDE: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'Alias für alle Teilnehmer:innen',
    'app.create_report.btn_real_name_all': 'Echter Name für alle Teilnehmer:innen',
    'app.create_report.btn_fake_name': 'Alias verwenden',
    'app.create_report.btn_real_name': 'Echten Namen verwenden',
    'app.create_report.title': 'Berichte verwalten',
    'app.create_report.summary_line1': 'Mit dem BEVI-Verwaltungsystem können Sie Berichte erstellen, die auf allen Daten Ihrer Gruppe basieren. Es gibt vier mögliche Berichtstypen: <br />' +
        '&nbsp;&nbsp;1) Gruppenbericht (Dieser Bericht bezieht sich auf einen bestimmten Zeitraum, in dem das BEVI verwaltet wird.); <br />' +
        '&nbsp;&nbsp;2) Längsschnittbericht (Dies ist ein Bericht, der über einen längeren Zeitraum hinweg erstellt wird, z. B. T1, T2, T3, usw.)   <br />' +
        '&nbsp;&nbsp;3) Berichte zwischen Gruppen (Das sind Berichte, mit denen es möglich ist, verschiedene Gruppen innerhalb und zwischen verschiedenen Einrichtungen oder Organisationen zu vergleichen). <br />' +
        '&nbsp;&nbsp;4) Berichte innerhalb einer Gruppe (Das sind Berichte, in denen Einzelpersonen innerhalb einer Gruppe über BEVI-Skalen hinweg miteinander verglichen werden, allerdings in anonymisierter Form)',
    'app.create_report.summary_line2': 'Alle diese Berichte können automatisch erstellt werden (d.h. sie verwenden automatisch alle Daten Ihrer Gruppe(n)). Sie können jedoch auch jeden dieser Berichtstypen filtern, um sich auf verschiedene Kombinationen von Hintergrundinformationen zu konzentrieren. Sie könnten zum Beispiel einen Bericht erstellen, der nur Frauen und Männer aus zwei verschiedenen Ländern vergleicht. Es gibt buchstäblich hunderte von verschiedenen Kombinationen, die Sie mit dieser Filterfunktion auswählen können. ',
    'app.create_report.step1': 'SCHRITT 1 - KONFIGURATION DES BERICHTS',
    'app.create_report.step2': 'SCHRITT 2 - FILTERN DES BERICHTS',
    'app.create_report.step3': 'SCHRITT 3 - VERWALTEN VON TEILNEHMER:INNEN',
    'app.create_report.step3Individual': 'SCHRITT 3 - WER KANN DEN BERICHT SEHEN?',
    'app.create_report.step3_username_column': 'Benutzername',
    'app.create_report.step3_aliasname_column': 'Alias',
    'app.create_report.step3_action_column': 'Start',
    'app.create_report.step3_no_user_title': 'Es konnte kein/e Benutzer:in gefunden werden',
    'app.create_report.step3_no_user_subtitle': 'Ändern Sie bitte die Parameter in den vorherigen Schritten und suchen Sie erneut nach Benutzer:innen.',
    'app.create_report.step3_user_name_column_title': 'Teilnehmer:in',
    'app.create_report.step3_user_exporting_column_title': 'Enthalten',
    'app.create_report.requester': 'Anforderer',
    'app.create_report.name': 'Berichtsname',
    'app.create_report.report_type': 'Art',
    'app.create_report.select_subprogram': 'Daten für die Erstellung des Berichts auswählen',
    'app.create_report.select_subprogram_warning': '(bitte vorherige Felder ausfüllen)',
    'app.create_report.group_report': 'Gruppenbericht',
    'app.create_report.longitudinal_report': 'Gruppenänderungsbericht',
    'app.create_report.comparative_by_group_report': 'Berichte zwischen Gruppen',
    'app.create_report.comparative_by_individual_report': 'Berichte innerhalb einer Gruppe',
    'app.create_report.select_organization': 'Organisation auswählen.',
    'app.create_report.select_program': 'Wählen Sie ein Programm oder eine Erfahrung.',
    'app.create_report.select_sub_program': 'Wählen Sie eine BEVI-Administration.',
    'app.create_report.organization_name': 'Organisation',
    'app.create_report.program_name': 'Programm oder Erfahrung',
    'app.create_report.sub_program_name': 'BEVI-Administration',
    'app.create_report.actions': 'Entfernen',
    'app.create_report.action_remove': 'Entfernen',
    'app.create_report.show_filters': 'Kriterienfilter anzeigen',
    'app.create_report.hide_filters': 'Kriterienfilter ausblenden',
    'app.create_report.filter_select_default_option': 'Zum Filtern auswählen...',
    'app.create_report.filter_select_with_values': 'Filtern',
    'app.create_report.filter_age': 'Alter',
    'app.create_report.filter_gender': 'Geschlecht',
    'app.create_report.filter_years_of_education': 'Jahre der Ausbildung',
    'app.create_report.filter_primary_country_of_origin': 'Herkunftsland',
    'app.create_report.filter_marital_status': 'Familienstand',
    'app.create_report.filter_political_orientation': 'Politische Orientierung (Konservativ bis Liberal)',
    'app.create_report.filter_ethnic_background': 'Ethnischer Hintergrund',
    'app.create_report.filter_religious_orientation': 'Religiöse Orientierung',
    'app.create_report.filter_religious_commitment': 'Glaubensbekenntnis',
    'app.create_report.filter_academic_rank': 'Akademischer Grad',
    'app.create_report.filter_time_spent_in_other_countries': 'In anderen Ländern verbrachte Zeit',
    'app.create_report.filter_nro_visited_countries': 'Besuchte Länder',
    'app.create_report.filter_fluency': 'Sprachniveau',
    'app.create_report.filter_years_of_language_study': 'Jahre des Sprachunterrichts',
    'app.create_report.filter_mother_country': 'Land der Mutter',
    'app.create_report.filter_father_country': 'Land des Vaters',
    'app.create_report.filter_mother_education': 'Ausbildung der Mutter',
    'app.create_report.filter_father_education': 'Ausbildung des Vaters',
    'app.create_report.filter_minority_group': 'Minderheit',
    'app.create_report.filter_personal_interest': 'Interesse an internationalen / kulturellen Erfahrungen',
    'app.create_report.filter_study_abroad_satisfaction': 'Zufriedenheit mit internationalen / kulturellen Erfahrungen',
    'app.create_report.filter_primary_area_of_work': 'Primärer Arbeitsbereich',
    'app.create_report.filter_area_of_occupations': 'Berufsfelder',
    'app.create_report.filter_income_level_of_your_home_country': 'Einkommensniveau',
    'app.create_report.btn_search': 'Suche',
    'app.create_report.btn_save': 'Generieren',
    'app.create_report.placeholder.name': 'Name des Berichts eingeben',
    'app.create_report.select.report_type': 'Art des Berichts auswählen',
    'app.create_report.select_countries_options_lbl': 'Wählen Sie mindestens ein Herkunftsland, um es mit allen anderen zu vergleichen:',
    'app.create_report.selected_countries_lbl': 'Ausgewählte Länder:',
    'app.create_report.select_ethnics_options_lbl': 'Wählen Sie mindestens eine Ethnie, um sie mit allen anderen zu vergleichen:',
    'app.create_report.selected_ethnics_lbl': 'Ausgewählte Ethnie:',
    'app.create_report.select_number_of_forms_lbl': 'Wählen Sie die maximale Anzahl von BEVI-Bewertungen für den diskursiven Teil:',
    //Without translation
    'app.create_report.step4': 'STEP 4 - MANAGING PARTICIPANTS',
    'app.create_report.select_relationship_status_options_lbl': 'Choose at least one Relationship Status to contrast with all others:',
    'app.create_report.selected_relationship_status_lbl': 'Selected Relationship Status',
    'app.create_report.select_religion_options_lbl': 'Choose at least one Religion to contrast with all others:',
    'app.create_report.selected_religion_lbl': 'Selected Religious Orientation',
    'app.create_report.select_primary_area_work_options_lbl': 'Choose at least one Primary Area of Work to contrast with all others:',
    'app.create_report.selected_primary_area_work_lbl': 'Selected Primary Area of Work',
    'app.create_report.individual_report': 'Einzelbericht',
    'app.create_report.longitudinal_individual_report': 'Individueller Änderungsbericht',
    'app.create_report.combined_report': 'Partnerbericht',
    'app.create_report.individual': 'Individuell',
    'app.create_report.partner': 'Partner',
    'app.create_report.group': 'Gruppe',
    'app.create_report.select.group': 'Wähle die Gruppe',
    'app.create_report.partner_change_report': 'Partnerwechselbericht',
    'app.create_report.email_in_use': 'E-Mail in Benutzung',
    'app.create_report.add_new_participant': 'Neuen Teilnehmer hinzufügen'
}
