import React from 'react';
import { TokenInfo } from '../../../services/loginService';
import {
    ParticipantModel, ReportFormsModel, Reports, UserFormModel
} from '../../../services/reportService';
import { NoUserFound } from './NoUserFound';
import { Step4 } from './Step4';
import { Step4ComparativeByIndividual } from './Step4ComparativeByIndividual';
import { UserFormsPerOrganizationForView } from './Step4Helper';
import { Step3Longitudinal } from './Step4Longitudinal';

type Props = {
    allParticipants: ReportFormsModel[];
    selectedParticipants: ParticipantModel[];
    reportType: Reports;
    userInfo: TokenInfo;
    selectedCountries?: number[];
    selectedEthnics?: number[];
    selectedRelationships?: number[];
    selectedReligions?: number[];
    selectedPrimaryAreaOfWorkCodes?: number[];
    maxNumberForms?: number;
    onSelectCountriesChanged: (values: number[] | undefined) => void;
    onSelectEthnicsChanged: (values: number[] | undefined) => void;
    onSelectRelationshipsChanged: (values: number[] | undefined) => void;
    onSelectReligionsChanged: (values: number[] | undefined) => void;
    onSelectPrimaryAreaOfWorkCodesChanged: (values: number[] | undefined) => void;
    onMaxNumberFormsChanged: (value: number | undefined) => void;
    onSelectedParticipantsChanged: (participants: ParticipantModel[]) => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};

export const Step4Container = (
    {
        userInfo, allParticipants, selectedParticipants, reportType, selectedCountries, selectedEthnics, selectedRelationships, selectedReligions,
        selectedPrimaryAreaOfWorkCodes, maxNumberForms,
        onSelectCountriesChanged, onSelectEthnicsChanged, onMaxNumberFormsChanged, onSelectRelationshipsChanged, onSelectReligionsChanged,
        onSelectPrimaryAreaOfWorkCodesChanged, onSelectedParticipantsChanged, 
    }: Props
): JSX.Element => {
    console.log('allParticipants', allParticipants)
    const userFormsView: UserFormsPerOrganizationForView[] = allParticipants?.map((participants: ReportFormsModel) => ({
        name: participants.groupName,
        users: participants.forms.map((user: UserFormModel) => {
            const foundParticipant = selectedParticipants.find((p: ParticipantModel) => p.id === user.formId);
            return ({
                ...user,
                alias: foundParticipant ? foundParticipant.alias : user.alias,
                exporting: foundParticipant ? true : false,
            })
        })
    }));

    const handleSelectedParticipantsChanged = (participants: ParticipantModel[], changeAlias: boolean): void => {
        let newParticipants: ParticipantModel[] = selectedParticipants;

        participants.forEach((participant: ParticipantModel) => {
            const index = newParticipants.findIndex((p: ParticipantModel) => p.id === participant.id);
            if (index >= 0) {
                if (changeAlias) {
                    newParticipants[index].alias = participant.alias;
                } else {
                    newParticipants.splice(index, 1);
                }
            } else {
                let foundedParticipant: UserFormModel | undefined;
                for (let groupForms of allParticipants) {
                    foundedParticipant = groupForms.forms.find((u: UserFormModel) => u.formId === participant.id);
                    if (foundedParticipant !== undefined) break;
                }

                if (foundedParticipant) {
                    newParticipants.push({
                        id: foundedParticipant.formId,
                        alias: foundedParticipant.alias
                    });
                }
            }
        });

        onSelectedParticipantsChanged([...newParticipants]);
    }

    if (!userFormsView || userFormsView.length === 0) {
        return (
            <React.Fragment>
                <br />
                <div style={styles.noUserFoundContainerDiv}>
                    <div style={styles.noUserFoundSizeDiv}>
                        <NoUserFound
                            title='app.create_report.step3_no_user_title'
                            subTitle='app.create_report.step3_no_user_subtitle'
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    if (reportType === Reports.longitudinal_report) {
        return (
            <Step3Longitudinal
                userForms={userFormsView}
                selectedCountries={selectedCountries}
                selectedEthnics={selectedEthnics}
                selectedRelationships={selectedRelationships}
                selectedReligions={selectedReligions}
                selectedPrimaryAreaOfWorkCodes={selectedPrimaryAreaOfWorkCodes}
                maxNumberOfForms={maxNumberForms}
                onUserFormReportChanged={handleSelectedParticipantsChanged}
                onSelectCountriesChanged={onSelectCountriesChanged}
                onSelectEthnicsChanged={onSelectEthnicsChanged}
                onSelectRelationshipsChanged={onSelectRelationshipsChanged}
                onSelectReligionsChanged={onSelectReligionsChanged}
                onSelectPrimaryAreaOfWorkCodesChanged={onSelectPrimaryAreaOfWorkCodesChanged}
                onMaxNumberFormsChanged={onMaxNumberFormsChanged}
            />
        );
    }

    if (reportType === Reports.comparative_by_individual) {
        return (
            <Step4ComparativeByIndividual
                userInfo={userInfo}
                userForms={userFormsView}
                onUserFormReportChanged={handleSelectedParticipantsChanged}
            />
        );
    }

    return (
        <Step4
            userForms={userFormsView}
            selectedCountries={selectedCountries}
            selectedEthnics={selectedEthnics}
            selectedRelationships={selectedRelationships}
            selectedReligions={selectedReligions}
            selectedPrimaryAreaOfWorkCodes={selectedPrimaryAreaOfWorkCodes}
            maxNumberOfForms={maxNumberForms}
            onUserFormReportChanged={handleSelectedParticipantsChanged}
            onSelectCountriesChanged={onSelectCountriesChanged}
            onSelectEthnicsChanged={onSelectEthnicsChanged}
            onSelectRelationshipsChanged={onSelectRelationshipsChanged}
            onSelectReligionsChanged={onSelectReligionsChanged}
            onSelectPrimaryAreaOfWorkCodesChanged={onSelectPrimaryAreaOfWorkCodesChanged}
            onMaxNumberFormsChanged={onMaxNumberFormsChanged}
        />
    );
}

const styles = {
    noUserFoundContainerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    } as React.CSSProperties,
    noUserFoundSizeDiv: {
        width: '70%',
    } as React.CSSProperties,
}
