import React, { useState } from 'react';
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap';

import { colors } from '../config/colors';
import { GetFormattedMessage } from '../utils/htmlHelper';

type Props = {
    showModal: boolean;
    loadingButton: boolean;
    onModalHide: () => void;
    onDataDownload:
      (studyAbroad: boolean, additionalDemographicsInfo: boolean, removeAnonymous: boolean) => void;
}

export const DataDownloadModal = (
    { loadingButton, showModal, onModalHide, onDataDownload }: Props
): JSX.Element => {
    const [studyAbroad, setStudyAbroad] = useState<boolean>(false);
    const [additionalDemographicsInfo, setAdditionalDemographicsInfo] = useState<boolean>(false);
    const [removeAnonymous, setRemoveAnonymous] = useState<boolean>(false);

    const onSubmitButtonClicked = () => {
        setTimeout(function() {
            // Wait a little bit because the useState took a few milliseconds to change the state.
            onDataDownload(studyAbroad, additionalDemographicsInfo, removeAnonymous);
        }, 1000);
    }

    return (
        <Modal show={showModal} onHide={onModalHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {GetFormattedMessage('app.listorganizations.download.data_report').toUpperCase()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <React.Fragment>
                    <Form.Group className='mb-3' as={Col}>
                        <Form.Check
                            type='checkbox'
                            key={'cb_study_abroad'}
                            label={GetFormattedMessage('app.datadownload.checkbox.study_abroad')}
                            onChange={() => setStudyAbroad(!studyAbroad)}
                            id={`cb_study_abroad`}
                        />
                        <Form.Check
                            type='checkbox'
                            key={'cb_additional_demographics_info'}
                            label={GetFormattedMessage('app.datadownload.checkbox.additional_demographics_info')}
                            onChange={() => setAdditionalDemographicsInfo(!additionalDemographicsInfo)}
                            id={`cb_additional_demographics_info`}
                        />
                        <Form.Check
                            type='checkbox'
                            key={'cb_remove_anonymous'}
                            label={GetFormattedMessage('app.datadownload.checkbox.remove_anonymous_user')}
                            onChange={() => setRemoveAnonymous(!removeAnonymous)}
                            id={`cb_remove_anonymous`}
                        />
                    </Form.Group>
                </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ backgroundColor: colors.darkSlateBlue }}
                    variant='primary'
                    onClick={onSubmitButtonClicked}
                    disabled={loadingButton}
                >
                    { loadingButton &&
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            style={{ marginRight: 10 }}
                        />
                    }
                    {GetFormattedMessage('app.download_excel')}
                </Button>
            </Modal.Footer>
      </Modal>
  );
};
