import { Alert, Select, Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';

import { SearchOutlined } from '@ant-design/icons';

import search from '../../assets/images/no_info_search.png';
import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { SelectItem } from '../../components/FormDropdown';
import { Loading } from '../../components/Loading';
import { NoInfo } from '../../components/NoInfo';
import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { paginationDefaultPageSize } from '../../config/constants';
import { OrganizationModel } from '../../services/reportService';
import { SearchUsersPermissionDetails, UserPermissionModel } from '../../services/usersService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { FilterUsers } from './components/FilterUsers';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import styled from 'styled-components';
import { PermissionModal } from './components/PermissionModal';

type Props = {
    organizations: OrganizationModel[];
    users: SearchUsersPermissionDetails[];
    loading: boolean;
    updatedUsers: boolean;
    loadFirstTime: boolean;
    updateIndividualReportUserPermissionClick: (users: UserPermissionModel[]) => void;
    clearFeedbackHandle: () => void;
    onOrganizationChange(organizationId: number | undefined): SelectItem[];
    searchHandleClick: (organizationId: number, program: number | undefined) => void;
    addUserPermission: (permission: number[], file: File) => void;
    loadingAddUserPermission?: boolean;
    hasAddUserPermission?: boolean;
}

const SelectMult = styled(Select)`
    .ant-select-selection-overflow {
        gap: 6px;
    }

    .ant-tag {
        display: flex;
        align-items: center;
    }
`;

export const valueColor: any = {
    1: 'gold',
    4: 'cyan',
    8: 'pink',
}

export const UserProfilePermission = (
    {
        organizations, updatedUsers, loading, users, onOrganizationChange,
        searchHandleClick, updateIndividualReportUserPermissionClick, clearFeedbackHandle, loadFirstTime,
        addUserPermission, loadingAddUserPermission, hasAddUserPermission
    }: Props
) => {
    const organizationOptions: SelectItem[] = organizations?.map(
        (org: OrganizationModel) => ({ value: org.id, label: org.name })
    ) ?? [];

    const [userPermission, setUserPermission] = useState<SearchUsersPermissionDetails[]>([]);
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);
    const [usersPermissionChanged, setUsersPermissionChanged] = useState<UserPermissionModel[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const changePermission = (permissions: number[], userId: number) => {
        const findUser = usersPermissionChanged.find(up => up.userId === userId);
        if (findUser) {
            setUsersPermissionChanged(upc => [...upc.filter(x => x.userId !== userId), { permissions: permissions, userId: userId }]);
        } else {
            setUsersPermissionChanged(upc => [...upc, { permissions: permissions, userId: userId }]);
        }
        clearFeedbackHandle();
    }

    const onOpenPermissionModal = () => {
        setOpenModal(true);
    }

    useEffect(() => {
        if (hasAddUserPermission) {
            setOpenModal(false);
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 10000)
        }
    }, [hasAddUserPermission])

    useEffect(() => {
        if (users) setUserPermission(users);
    }, [users]);

    const onUpdateClick = () => {
        if (usersPermissionChanged.length > 0)
            updateIndividualReportUserPermissionClick(usersPermissionChanged);
    }

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
    }

    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={valueColor[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <div>
            <FilterUsers
                organizations={organizationOptions}
                searchHandleClick={searchHandleClick}
                OnOrganizationChange={onOrganizationChange}
                onOpenPermissionModal={onOpenPermissionModal}
            />
            {showSuccess &&
                <Alert
                    message={GetFormattedMessage('app.permission.message')}
                    type='success'
                    showIcon
                />
            }
            {
                loadFirstTime === false ? null :
                    loading === true ? (
                        <Loading />
                    ) : (
                        users === undefined || users === null || users.length === 0 ? (
                            <NoInfo
                                title={'app.no.info.title.no_data'}
                                subTitle={'app.no.info.subtitle.no_data'}
                                image={search}
                            />
                        ) : (
                            <React.Fragment>
                                <div>
                                    {updatedUsers &&
                                        <Alert
                                            message={GetFormattedMessage('app.permission.message')}
                                            type='success'
                                            showIcon
                                        />
                                    }
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <Table
                                            dataSource={userPermission}
                                            pagination={userPermission.length > paginationDefaultPageSize ? defaultPagination : false}
                                            rowKey={(record: SearchUsersPermissionDetails, index?: number) => (
                                                record.userId.toString() + index
                                            )}
                                        >
                                            <Column
                                                title={GetFormattedMessage('app.permission.table_title.permission')}
                                                key={'user-exporting-column'}
                                                width={'30%'}
                                                sorter={(a: SearchUsersPermissionDetails, b: SearchUsersPermissionDetails) => (
                                                    Number(a.hasPermission) - Number(b.hasPermission)
                                                )}
                                                render={(_, record: SearchUsersPermissionDetails) => (
                                                    <SelectMult
                                                        mode="multiple"
                                                        size='middle'
                                                        placeholder="Please select"
                                                        defaultValue={record.capabilities}
                                                        style={{ width: '100%' }}
                                                        tagRender={tagRender}
                                                        optionFilterProp='label'
                                                        onChange={(value: any) => changePermission(value, record.userId)}
                                                        options={[{
                                                            value: 1,
                                                            label: 'INDIVIDUAL REPORT',
                                                        }, {
                                                            value: 4,
                                                            label: 'VISUALIZE - CHAT BOT',
                                                        }, {
                                                            value: 8,
                                                            label: 'CAN TALK TO CHAT BOT',
                                                        }, {
                                                            value: 16,
                                                            label: 'VISUALIZE - VIRTUAL ACCOUNT',
                                                        }
                                                        ]}
                                                    />
                                                )}
                                            />
                                            <Column
                                                title={GetFormattedMessage('app.permission.table_title.name')}
                                                key={'user-name-key'}
                                                width={'35%'}
                                                sorter={(a: SearchUsersPermissionDetails, b: SearchUsersPermissionDetails) => (
                                                    a.userName.localeCompare(b.userName)
                                                )}
                                                {...filterOptions}
                                                onFilter={(value, record: SearchUsersPermissionDetails) => (
                                                    record.userName.toLowerCase().includes(value.toString().toLowerCase())
                                                )}
                                                render={(_, record: SearchUsersPermissionDetails) => record.userName}
                                            />
                                            <Column
                                                title={GetFormattedMessage('app.permission.table_title.email')}
                                                key={'user-name-key'}
                                                width={'35%'}
                                                sorter={(a: SearchUsersPermissionDetails, b: SearchUsersPermissionDetails) => (
                                                    a.userEmail.localeCompare(b.userEmail)
                                                )}
                                                {...filterOptions}
                                                onFilter={(value, record: SearchUsersPermissionDetails) => (
                                                    record.userEmail.toLowerCase().includes(value.toString().toLowerCase())
                                                )}
                                                render={(_, record: SearchUsersPermissionDetails) => record.userEmail}
                                            />
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div style={styles.paddingRow}>
                                            <ButtonWithIcon
                                                textId={'app.permission.button.update'}
                                                onClick={onUpdateClick}
                                                icon={<FaSave />}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    )}
            <PermissionModal openModal={openModal} setOpenModal={setOpenModal} addUserPermission={addUserPermission} loading={loadingAddUserPermission} />
        </div>
    );
}

const styles = {
    paddingRow: {
        padding: '16px',
    } as React.CSSProperties,
}
