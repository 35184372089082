import { Col } from "antd";
import React from "react"
import { Button, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { colors } from "../../../config/colors";
import { Reports } from "../../../services/reportService";

type Props = {
    disabled: boolean;
    hasBack: boolean;
    loadingNext?: boolean;
    nextName?: string;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    nextClick?: () => void;
};

export const StepsBackNext: React.FC<Props> = (props): JSX.Element => {
    const { disabled, hasBack, loadingNext, nextName, setCurrentStep, nextClick } = props;
    
    return <Col>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 12 }}>
            {hasBack ? <Button
                style={{
                    backgroundColor: colors.darkSlateBlue,
                }}
                variant='primary'
                onClick={() => {
                    setCurrentStep(x => x - 1)
                }}
            >
                <FormattedMessage id={'app.btn_back'} />
            </Button> : <div></div>}
            <Button
                style={{
                    backgroundColor: colors.darkSlateBlue,
                }}
                variant='primary'
                onClick={() => {
                    if (nextClick == null)
                        setCurrentStep(x => x + 1)
                    else
                        nextClick();
                }}
                disabled={loadingNext === true || disabled}
            >
                {loadingNext ?
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                    :
                    <FormattedMessage id={nextName ?? 'app.btn_next'} />
                }
            </Button>
        </div>
    </Col>
}

type PropsAddGroup = {
    onClick: () => void;
    reportType?: Reports
};

export const StepsAddGroup: React.FC<PropsAddGroup> = (props): JSX.Element => {
    const { onClick } = props;
    return <Col>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 12 }}>
            <div></div>
            <Button
                style={{
                    backgroundColor: colors.darkSlateBlue,
                }}
                variant='primary'
                onClick={onClick}
            >
                <FormattedMessage id={(props.reportType != null && [Reports.partner_change_report, Reports.partner_report].includes(props.reportType)) 
                    ? 'app.create_report.add_new_participant' : 'app.listreport.add_group'} />
            </Button>
        </div>
    </Col>
}