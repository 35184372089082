import { useActions, useValues } from 'kea';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import {
    DemographicQuestionsActions, demographicQuestionsLogic, DemographicQuestionsProps
} from '../../redux/demographicQuestionsLogic';
import { formLogic, FormsActions, FormsProps } from '../../redux/formsLogic';
import {
    EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE, EXTERNAL_SCALE_QUESTIONS_PAGE, SCALE_QUESTIONS_PAGE
} from '../../router/pages';
import { DemographicQuestionAnswers, DemographicQuestionAnswersValues, ExtraQuestions } from '../../services/types';
import { DemographicQuestions } from './DemographicQuestions';

type Props = RouteComponentProps;

export const DemographicQuestionsContainer = withRouter((props: Props): JSX.Element => {
    const { sendDemographicAnswers }: DemographicQuestionsActions = useActions(demographicQuestionsLogic);

    const { loading, error }: DemographicQuestionsProps = useValues(demographicQuestionsLogic);
    const { formId, hideQuestions, extraQuestions }: FormsProps = useValues(formLogic);
    const { setFormStatus }: FormsActions = useActions(formLogic);

    const [answers, setAnswers] = useState<DemographicQuestionAnswers>({ formId: formId });

    const [sending, setSending] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const checkAnswerWithHideQuestion = (answer: any, isHide: boolean): boolean => {
        if ((answer !== undefined && isHide === false) || (answer === undefined && isHide === true))
            return true;
        return false;
    }

    const handleNextButtonClick = (): void => {
        let shouldSendDemographicAnswers = false;
        if (checkAnswerWithHideQuestion(answers?.Age, hideQuestions?.age) &&
            checkAnswerWithHideQuestion(answers?.Gender, hideQuestions?.gender) &&
            checkAnswerWithHideQuestion(answers?.CompletedYearOfEducation, hideQuestions?.completedYearOfEducation) &&
            checkAnswerWithHideQuestion(answers?.MaritalStatus, hideQuestions?.maritalStatus) &&
            checkAnswerWithHideQuestion(answers?.EthnicBackground, hideQuestions?.ethnicBackground) &&
            checkAnswerWithHideQuestion(answers?.MotherCountry, hideQuestions?.motherCountry) &&
            checkAnswerWithHideQuestion(answers?.FatherCountry, hideQuestions?.fatherCountry) &&
            checkAnswerWithHideQuestion(answers?.MemberOfDisadvantagedOrMinorityGroup, hideQuestions?.memberOfDisadvantagedOrMinorityGroup) &&
            checkAnswerWithHideQuestion(answers?.PrimaryCountryOfOrigin, hideQuestions?.primaryCountryOfOrigin) &&
            checkAnswerWithHideQuestion(answers?.TimeInOtherCountry, hideQuestions?.timeInOtherCountry) &&
            checkAnswerWithHideQuestion(answers?.VisitedOrLivedCountries, hideQuestions?.visitedOrLivedCountries) &&
            checkAnswerWithHideQuestion(answers?.PoliticalOrientation, hideQuestions?.politicalOrientation) &&
            checkAnswerWithHideQuestion(answers?.PoliticalCommitment, hideQuestions?.politicalCommitment) &&
            checkAnswerWithHideQuestion(answers?.MotherFormalEducation, hideQuestions?.motherFormalEducation) &&
            checkAnswerWithHideQuestion(answers?.FatherFormalEducation, hideQuestions?.fatherFormalEducation) &&
            checkAnswerWithHideQuestion(answers?.PrimaryAreaOrWorkOrProfessionalInterest, hideQuestions?.primaryAreaOrWorkOrProfessionalInterest) &&
            (checkAnswerWithHideQuestion(answers?.PrimaryLanguages, hideQuestions?.primaryLanguages) || checkAnswerWithHideQuestion(answers?.FluencyInLanguages, hideQuestions?.primaryLanguages) || checkAnswerWithHideQuestion(answers?.FluencyInLanguagesOthers, hideQuestions?.primaryLanguages)) &&
            checkAnswerWithHideQuestion(answers?.TotalYearsStudiedForeignLanguage, hideQuestions?.totalYearsStudiedForeignLanguage) &&
            checkAnswerWithHideQuestion(answers?.IncomeLevelOfHomeCountry, hideQuestions?.incomeLevelOfHomeCountry) &&
            checkAnswerWithHideQuestion(answers?.AcademicStandingOrRank, hideQuestions?.academicStandingOrRank) &&
            checkAnswerWithHideQuestion(answers?.CurrentBeliefsOrCommitmentsRegardingReligion, hideQuestions?.currentBeliefsOrCommitmentsRegardingReligion) &&
            checkAnswerWithHideQuestion(answers?.CommitmentTowardFaithReligions, hideQuestions?.commitmentTowardFaithReligions) &&
            checkAnswerWithHideQuestion(answers?.InterestInInternationalOrInterculturalLearning, hideQuestions?.interestInInternationalOrInterculturalLearning) &&
            checkAnswerWithHideQuestion(answers?.SatisfactionLevelInternationalExperienceProgram, hideQuestions?.satisfactionLevelInternationalExperienceProgram) &&
            checkAnswerWithHideQuestion(answers?.ParticipatedExperiencesCapabilities, hideQuestions?.participatedExperiencesCapabilities) &&
            checkAnswerWithHideQuestion(answers?.PlansToParticipateExperiencesCapabilities, hideQuestions?.plansToParticipateExperiencesCapabilities) &&
            checkAnswerWithHideQuestion(answers?.ParticipatedLearningCoursesOrProgramsCapabilities, hideQuestions?.participatedLearningCoursesOrProgramsCapabilities) &&
            checkAnswerWithHideQuestion(answers?.PlansToParticipateLearningCoursesOrProgramsCapabilities, hideQuestions?.plansToParticipateLearningCoursesOrProgramsCapabilities)) {
            // answers?.WhenYouAreCompleting !== undefined && // Not added yet.
            // answers?.RaisedInCountryWhereYouAreCurrentlyIn!== undefined &&
            shouldSendDemographicAnswers = true;
        }

        if (shouldSendDemographicAnswers) {
            sendDemographicAnswers(answers);
            setSending(true);
        } else {
            setShowConfirmation(true);
        }
    }

    useEffect(() => {
        if (sending === true && loading === false) {
            setFormStatus(3);  // On Scale.
            if (props.location.pathname === EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE) {
                props.history.push(EXTERNAL_SCALE_QUESTIONS_PAGE);
            } else {
                props.history.push(SCALE_QUESTIONS_PAGE);
            }
        }
        // eslint-disable-next-line
    }, [sending, loading, setFormStatus, props.history]);

    const handleConfirmationCloseClick = (): void => {
        setShowConfirmation(false);
    }

    const handleConfirmationOkClick = (): void => {
        sendDemographicAnswers(answers);
        setShowConfirmation(false);
        setSending(true);
    }

    const handleAnswerChanged = (
        key: keyof (DemographicQuestionAnswers), value: DemographicQuestionAnswersValues
    ): void => {
        if (key !== "ExtraQuestions") {
            setAnswers(prevState => ({ ...prevState, [key]: value } as DemographicQuestionAnswers));
        } else {
            let newExtraQuestion: ExtraQuestions[] = [];
            const thisValue = value as ExtraQuestions;
            if (answers.ExtraQuestions != null) {
                newExtraQuestion = [...answers.ExtraQuestions];
                let thisExtraQuestion = newExtraQuestion.find(x => x.id === thisValue.id);
                if (thisExtraQuestion != null) {
                    thisExtraQuestion.value = thisValue.value;
                    setAnswers(prevState => ({ ...prevState, ExtraQuestions: newExtraQuestion } as DemographicQuestionAnswers));
                } else {
                    newExtraQuestion.push(thisValue);
                    setAnswers(prevState => ({ ...prevState, ExtraQuestions: newExtraQuestion } as DemographicQuestionAnswers));
                }
            } else {
                setAnswers(prevState => ({ ...prevState, ExtraQuestions: [thisValue] } as DemographicQuestionAnswers));
            }
        }
    }

    return (
        <Fragment>
            <DemographicQuestions
                answers={answers}
                extraQuestions={extraQuestions}
                hideQuestions={hideQuestions}
                onNextButtonClicked={handleNextButtonClick}
                onAnswerChanged={handleAnswerChanged}
                loading={loading}
                error={error}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={'app.questions.confirmation_title'}
                bodyFormattedListMessageId={['app.questions.confirmation']}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleConfirmationCloseClick}
                onPrimaryButtonClicked={handleConfirmationOkClick}
                onSecondaryButtonClicked={handleConfirmationCloseClick}
            />
        </Fragment>
    );
});
