import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsJA: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': '今回BEVIを受検しようとしているプログラムに関連して、以下の3つの質問に答えてください',
    'app.discursive_questions.question1': '1. どのような出来事また状況が、あなたに最も影響を与えたでしょうか。またそれはなぜですか。',
    'app.discursive_questions.question2': '2. このような出来事または状況は、あなた自身の「自己」や「アイデンティティ」（例えば、異文化、ジェンダー、民族、宗教や政治に対する考え方）を、自分自身または他者との関係で、より明確にすることができたでしょうか。また、なぜそのようにことがおこったのですか。',
    'app.discursive_questions.question3': '3. 今回BEVIを受検しようとしているプログラムの結果、あなた個人としては何を学ぶことができるでょうか/できたでしょうか。',
    'app.discursive_questions.thank_you': 'BEVIを受検していただきありがとうございます。',
    'app.discursive_questions.please_note': '注意:',
    'app.discursive_questions.go_to_report': 'あなたが個人別レポートを参照したいと思う場合は、下のボタンをクリックしてください。また、後で自身のダッシュボードからレポートを確認することもできます。',
    'app.discursive_questions.do_not_show_report': '残念ながら、なんらかの理由（例えば、一定以上の質問に対して回答しなかった等）で、レポートを作成することできませんでした。もし宜しければBEVIを再受験することもできます。',
    'app.discursive_questions.visualize_report': 'レポートを読む',
}
