import { NoInfoMessages } from '../types';

export const noInfoDE: NoInfoMessages = {
    'app.no.info.title.not_found': 'SEITE NICHT GEFUNDEN',
    'app.no.info.title.no_data': 'KEINE DATEN GEFUNDEN',
    'app.no.info.subtitle.not_found': 'Sieht so aus, als hätten Sie sich verirrt!',
    'app.no.info.subtitle.no_data': 'Vergewissern Sie sich, dass Ihre Suchparameter korrekt sind. Falls ein Fehler auftritt, wenden Sie sich an info@thebevi.com.',
    'app.no.info.body.not_found': 'Die Seite, die Sie suchen, gibt es nicht oder sie wurde verschoben.',
    'app.no.info.body.no_data': 'Sehen Sie sich die von Ihnen gewählten Felder an und kontaktieren Sie die/den Account-Manager:in, wenn alles korrekt ist.',
    'app.no.info.title.not_authorized': 'SEITE NICHT AUTORISIERT',
    'app.no.info.subtitle.not_authorized': 'Es sieht so aus, als ob Sie versuchen, auf eine Seite zuzugreifen, für die Sie keine Berechtigung haben.',
    'app.no.info.body.not_authorized': 'Wenn Sie darauf zugreifen möchten, wenden Sie sich bitte an die/den Account-Manager:in.',
    'app.no.info.title.maintenance': 'SYSTEM WIRD GEWARTET',
    'app.no.info.subtitle.maintenance': 'Sieht aus, als ob das System gewartet wird',
    'app.no.info.body.maintenance': 'Versuchen Sie es in ein paar Minuten erneut.',
}
