import { FinancialReportMessages } from '../types';

export const financialReportEN: FinancialReportMessages = {
    'app.financialreport.title': 'Usage Report',
    'app.financialreport.organization_lbl': 'Organization',
    'app.financialreport.start_data_lbl': 'Start date',
    'app.financialreport.end_data_lbl': 'End date',
    'app.financialreport.created_forms_title': 'NUMBER INVITED TO TAKE BEVI',
    'app.financialreport.finished_forms_title': 'NUMBER COMPLETED BEVIS',
    'app.financialreport.valid_forms_title': 'NUMBER VALID BEVIS',
    'app.financialreport.invalid_forms_title': 'NUMBER INVALID BEVIS',
    'app.financialreport.unanswered_forms_title': 'NUMBER UNANSWERED BEVIS',
    'app.financialreport.error_header': 'Error',
    'app.financialreport.error_text': 'An error occurred and the report data could not be loaded, please try again.',
}
