import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { IconType } from 'react-icons/lib/cjs';
import { Link } from 'react-router-dom';

import { BeviFormattedLabel } from '../../../components/BeviFormattedLabel';
import { colors } from '../../../config/colors';
import { PageMessages } from '../../../locales/types';

type Props = {
    toPath: string;
    labelId: keyof (PageMessages);
    icon: IconType;
    onItemClicked?: () => void;
};

export const MenuItem = ({ toPath, labelId, icon: Icon, onItemClicked }: Props): JSX.Element => {
    const [hovering, setHovering] = useState<boolean>(false);

    const backgroundStyle = hovering ? { backgroundColor: colors.darkSlateBlue } : { backgroundColor: 'transparent' };

    return (
        <li className='nav-item' onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            <div className='row'>
                <Link
                    className='nav-link active'
                    to={toPath}
                    onClick={onItemClicked}
                    replace
                >
                    <InputGroup style={styles.inputGroupDiv}>
                        <div style={{ ...backgroundStyle, ...styles.houverDiv }} />
                        <InputGroup.Text style={styles.iconDiv}>
                            <Icon color={colors.darkSlateBlue} />
                        </InputGroup.Text>
                        <BeviFormattedLabel style={styles.text} messageId={labelId} />
                    </InputGroup>
                </Link>
            </div>
        </li>
    );
}

const styles = {
    houverDiv: {
        width: 3,
        height: 'auto',
    } as React.CSSProperties,
    inputGroupDiv: {
        alignItems: 'center',
    } as React.CSSProperties,
    iconDiv: {
        backgroundColor: 'transparent',
        borderWidth: 0,
    } as React.CSSProperties,
    text: {
        color: colors.black,
        textAlignVertical: 'center',
        margin: 0,
    } as React.CSSProperties,
}
