import { ListFormPageMessages } from '../types';

export const listFormsPageES: ListFormPageMessages = {
    'app.listforms.title': 'Mi panel BEVI',
    'app.listforms.subtitle': 'El panel de control BEVI te permite realizar el BEVI y revisar y gestionar tus informes BEVI.',
    'app.listforms.organization': 'Organización',
    'app.listforms.participant': 'Participante',
    'app.listforms.program': 'Programa',
    'app.listforms.take_by': 'Fecha límite de entrega',
    'app.listforms.completed_on': 'Fecha de realización',
    'app.listforms.progress': 'Progreso',
    'app.listforms.status': 'Estado',
    'app.listforms.custom_agreement_status': 'Estado del acuerdo particular',
    'app.listforms.action': 'Acción',
    'app.listforms.organization_tooltip': 'El panel de control BEVI te permite realizar el BEVI y revisar y gestionar tus informes BEVI.',
    'app.listforms.program_tooltip': 'El nombre del informe, programa o experiencia por el cual está tomando el BEVI.',
    'app.listforms.take_by_tooltip': 'Esta es la fecha límite para completar el BEVI',
    'app.listforms.completed_on_tooltip': 'Esta es la fecha en que completaste el BEVI',
    'app.listforms.progress_tooltip': 'Progreso de realización de un determinado BEVI (i. e., 100% significa que has finalizado el BEVI)',
    'app.listforms.status_tooltip': 'Información emergente de columna de estado',
    'app.listforms.action_tooltip': 'Desde aquí, puedes hacer, completar, o volver a hacer el BEVI o revisar tu informe BEVI. El cuadro que ves muestra lo que puedes hacer en este momento.',
    'app.listforms.all': 'Todos',
    'app.listforms.available': 'Sin comenzar',
    'app.listforms.onDemographics': 'Sobre Demografía',
    'app.listforms.onScales': 'Sobre las Preguntas BEVI',
    'app.listforms.onSubjective': 'Sobre las Respuestas por escrito',
    'app.listforms.finished': 'Completar',
    'app.listforms.cancelled': 'Cancelado',
    'app.listforms.expired': 'Expirado',
    'app.listforms.archived': 'Archivado',
    'app.listforms.invalid': 'No válido',
    'app.listforms.start': 'Hacer BEVI',
    'app.listforms.continue': 'Completar BEVI',
    'app.listforms.report': 'Leer informe BEVI',
    'app.listforms.partner_report': 'Revisar el informe del socio',
    'app.listforms.partner_change_report': 'Revisar el informe de cambio de socio',
    'app.listforms.restart': 'Volver a hacer BEVI',
    'app.listforms.confirmation_title': 'Instrucciones',
    'app.listforms.retart_confirmation_title': 'Reiniciar BEVI',
    'app.listforms.restart.instruction.part1': 'Haz click en el botón que aparece a continuación para reiniciar el BEVI.',
    'app.listforms.instruction.part1': 'El siguiente test consta de preguntas sobre tus antecedentes personales y de 185 preguntas presentadas a razón de 20 preguntas por página. ' +
        'Por favor, lee cada pregunta y responde seleccionando la opción que mejor refleje lo que piensas: ' +
        'Muy de acuerdo, De acuerdo, En desacuerdo, Muy en desacuerdo.',
    'app.listforms.instruction.part2': 'Cada vez que completes una página de preguntas, pulsa continuar para pasar a la siguiente; una vez hayas pulsado el botón continuar no podrás volver atrás para cambiar tus respuestas.',
    'app.listforms.instruction.part3': 'Debes indicar que has leído la información sobre el BEVI y prestar consentimiento informado antes de hacer el BEVI.',
    'app.listforms.instruction.press.start': 'Gracias por participar. Por favor, pulsa comenzar cuando estés preparado(a).',
    'app.listforms.comparative_individual_report': 'Comparar informe individual',
    'app.form.status_title': 'Formulario BEVI no disponible',
    'app.form.status_finished_subtitle': 'Parece que ya se ha respondido el formulario.',
    'app.form.status_finished_body': 'Ponte en contacto con los responsables si tienes preguntas sobre el estado del formulario.',
    'app.form.status_cancelled_subtitle': 'Parece que se ha cancelado el formulario',
    'app.form.status_cancelled_body': 'Ponte en contacto con los responsables si tienes preguntas sobre el estado del formulario.',
    'app.form.status_expired_subtitle': 'Parece que el formulario ha expirado',
    'app.form.status_expired_body': 'Ponte en contacto con los responsables si tienes preguntas sobre el estado del formulario.',
    'app.form.invalid_status.by_time': 'No válido por Tiempo',
    'app.form.invalid_status.by_number_of_questions': 'No válido por Número de Preguntas',
    'app.form.invalid_status.by_consecutive_answers': 'No válido por Respuestas Consecutivas',
    'app.form.invalid_status.by_common_answers': 'No válido por Respuestas Comunes',
    'app.form.invalid_status.by_congruency': 'No válido por Congruencia',
    'app.form.invalid_status.by_consistency': 'No válido por Coherencia',
    'app.form.invalid_status.by_wrong_scale_value': 'No válido por Valor de Escala Erróneo',
    'app.form.custom_agreement.not_requested': 'No se ha pedido',
    'app.form.custom_agreement.pending': 'Pendiente',
    'app.form.custom_agreement.accepted': 'Aceptado',
    'app.form.custom_agreement.rejected': 'Rechazado',
    'app.form.actions.tooltip.send_custom_agreement': 'Envia acuerdo particular',
    'app.form.actions.tooltip.resend_custom_agreement': 'Vuelve a enviar acuerdo particulart',
    'app.form.actions.tooltip.show_report': 'Muestra informe',
    'app.listforms.ai_interactions': 'Interacciones de IA'
}
