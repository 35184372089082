import React from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { InsufficientDataComponent } from '../GroupReport/components/InsufficientDataComponent';
import { GroupReportPage } from './GroupReportPage';
import { ReportProps } from './ReportType';
import { Button } from 'react-bootstrap';

export const GroupReport = (props: ReportProps): JSX.Element => {

    if (!props.report.hasEnoughData) {
        return (
            <React.Fragment>
                <InsufficientDataComponent />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={styles.divButtonContainer}>
                <ButtonPDF
                    loading={props.downloading}
                    onClick={props.onDownloadButtonClicked}
                />
            </div>
            <GroupReportPage {...props} />
        </React.Fragment>
    );
}

type ButtonProps = {
    onClick: () => void;
    loading: boolean;
}

const ButtonPDF = (props: ButtonProps): JSX.Element => (
    <Button style={styles.button} onClick={props.onClick}>
        <FaCloudDownloadAlt size={22} style={styles.marginRight} />
        <span>
            {props.loading
                ? GetFormattedMessage('app.loading_document')
                : GetFormattedMessage('app.download_document')
            }
        </span>
    </Button>
)

const styles = {
    divButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        paddingBottom: 10,
        marginRight: 16,
    } as React.CSSProperties,
    pdfButton: {
        backgroundColor: colors.lightGray,
        color: colors.white,
    } as React.CSSProperties,
    button: {
        display: 'flex',
        height: 'auto',
        width: 'auto',
    } as React.CSSProperties,
    marginRight: {
        marginRight: 10,
    } as React.CSSProperties,
}