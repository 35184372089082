import { useActions, useValues } from 'kea';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import { SaveUserStatus, UserErrorResponse } from '../../services/usersService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { CreateUser, UserErrorDetails } from './CreateUser';

type Props = RouteComponentProps;

export const CreateUserContainer = withRouter((props: Props): JSX.Element => {

    const {
        hasCreatedNewUser, errorMessageNewUser, loadingCreateNewUser, newUserErrorDetails
    }: UsersProps = useValues(usersLogic);
    const { clearSuccessfulCreateNewUser }: UsersActions = useActions(usersLogic);

    const errorDetails: UserErrorDetails[] | undefined = newUserErrorDetails?.map((error: UserErrorResponse) => (
        {
            lineNumber: error.lineNumber,
            email: error.email,
            userError: GetUserErrorText(error.userError)
        }
    ));

    return (
        <CreateUser
            hasCreateUser={hasCreatedNewUser}
            errorMessage={errorMessageNewUser}
            loading={loadingCreateNewUser}
            errorDetails={errorDetails}
            clearAlertMessages={clearSuccessfulCreateNewUser}
        />
    );
});

function GetUserErrorText(userError: SaveUserStatus): string {
    switch(userError) {
        case SaveUserStatus.AlreadyExists: return GetFormattedMessage('app.create_batch_user.error_already_exists');
        case SaveUserStatus.InvalidLine: return GetFormattedMessage('app.create_batch_user.error_invalid_line');
        case SaveUserStatus.WrongEmail: return GetFormattedMessage('app.create_batch_user.error_wrong_email');
        case SaveUserStatus.MissingName: return GetFormattedMessage('app.create_batch_user.error_missing_name');
        default: return '';
    }
}
