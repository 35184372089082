import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';

import moment from 'moment';

import { LocalStorage_BeviLanguage } from '../IntlProviderWrapper';

export const GetDate = (date?: string): string => {
    if (date === undefined || date === null || date === '') return ''

    moment.locale(GetLanguage());

    // Year - month - day (for japanese)

    //return moment(date).format("MMMM Do, YYYY");
    return moment(date).format("YYYY-MM-DD"); // JApanese
}

export const formatDate = (date: Date | undefined): string => {
    if (date === undefined) return '';
    var d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const GetLanguage = (): string => {
    const languageCode: string | null = localStorage.getItem(LocalStorage_BeviLanguage);

    if (languageCode === 'ja') return 'ja';
    else if (languageCode === 'zh') return 'zh-cn';
    else if (languageCode === 'es') return 'es';
    else return 'en';
}
