import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': '未回答の質問',
    'app.questions.confirmation': '回答していない質問がありますが、続けますか？ 後でこれらの質問に回答することはできません。',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': '強くそう思う',
    'app.question.agree': 'そう思う',
    'app.question.disagree': 'そうは思わない',
    'app.question.strongly_disagree': '全くそうは思わない',
    'app.question.q1': '時々自分の進むべき道がわからない。',
    'app.question.q2': '子供のころ、よく人にからかわれた。',
    'app.question.q3': '時々自分と人とを比較する。',
    'app.question.q4': '若者が性的な冒険するのは健全なことだ。',
    'app.question.q5': '私は、家族のメンバーとよく衝突していた。',
    'app.question.q6': '世の中で一人きりになってしまうのがこわい。',
    'app.question.q7': '母や父が、自分に違うようにしてくれていたらよかったのに、と思う。',
    'app.question.q8': '女性は、生理が近づくと、必ずと言っていいほど感情的になる。',
    'app.question.q9': '私たちは、自分たちと異なる文化を理解しようとすべきだ。',
    'app.question.q10': '母は自分の親友だ/だった。',
    'app.question.q11': 'いずれ人々は、男性と女性は全く異なるということを認めるだろう。',
    'app.question.q12': '女性の居場所は家庭の中にある。',
    'app.question.q13': 'この世界の美しさに、しばしば圧倒されることがある。',
    'app.question.q14': 'より優れた遺伝子を持つ人はいる。',
    'app.question.q15': '偏見はいたるところにあり、私たちはそれに打ち勝たなくてはならない。',
    'app.question.q16': '私は、深刻なアイデンティティの危機を経験したことがある。',
    'app.question.q17': '一年に何度か泣いてしまう。',
    'app.question.q18': '母や父から、良いアドバイスをいつも得ることができる/できた。',
    'app.question.q19': '人の心の無意識の部分が、私たちの感情や行動に影響することがある。',
    'app.question.q20': '世界で起こっている出来事について多くの知識を持つことは大切だ。',
    'app.question.q21': '男は男らしく振舞うべきだ。',
    'app.question.q22': '神/仏/神聖な存在が、今の自分を作り上げた。',
    'app.question.q23': '結局、幸福になるか不幸になるかを決めるのは自分だ。',
    'app.question.q24': '私は、地球との深い精神的なつながりを感じる。',
    'app.question.q25': '素晴らしい幼少時代を過ごした。',
    'app.question.q26': '自分自身の生き方に満足できないときがある。',
    'app.question.q27': '神/仏/神聖な存在を疑うのは間違いだ。',
    'app.question.q28': '自分の問題を、他人や環境のせいにするべきではない。',
    'app.question.q29': '女性は男性より、感情に依存していることが多い。',
    'app.question.q30': '私は何時間も真剣な話をするのが好きだ。',
    'app.question.q31': '最低賃金は引き上げられるべきだ。',
    'app.question.q32': '子供のころ、父と良好で健全な関係を築いていた。',
    'app.question.q33': 'エイズは天罰だと思う。',
    'app.question.q34': '無償の援助を得ようとする人が多すぎる。',
    'app.question.q35': '学校で性教育を行うべきだ。',
    'app.question.q36': '子供のころ、多くの争いと敵意を見たことがある。',
    'app.question.q37': '人生において、より優れたバランス感覚を身に着けたい。',
    'app.question.q38': '私は、自分自身の感情を、非常に真剣に受け止めている。',
    'app.question.q39': '女性は手ごわいライバルになりうる。',
    'app.question.q40': '私たちは、自分自身の動物的な側面を否定すべきではない。',
    'app.question.q41': '振り返ると恥ずかしい出来事が過去にある。',
    'app.question.q42': '私は人間関係で衝突することがよくあった。',
    'app.question.q43': '私は、社会問題については、保守的というよりはリベラルな方だ。',
    'app.question.q44': '人生の意味について、よく考える。',
    'app.question.q45': '時々退屈に感じる。',
    'app.question.q46': '若者がアルコールやハーブを試すのは、普通のことだ。',
    'app.question.q47': '女性は決断するのが非常に苦手だ。',
    'app.question.q48': '他の文化についての知識は、自分自身の文化を理解するのに役立つ。',
    'app.question.q49': '天国に通じる道は一つだ。',
    'app.question.q50': '人が失敗するのは、多くの場合十分頑張っていないからだ。',
    'app.question.q51': '私は両親と距離を置く必要があった。',
    'app.question.q52': '好む好まざるにかかわらず、男の子はいつまでも子どもだ。',
    'app.question.q53': '人類は何百万年もかけて進化してきた。',
    'app.question.q54': '家族に、身体的、性的又は感情的な虐待を受けていた者がいた。',
    'app.question.q55': '自分には温かさと愛情が特に必要だ。',
    'app.question.q56': '子どもの早期教育プログラムに、もっとお金をかけるべきだ。',
    'app.question.q57': '私は非常に感情的な人間だ。',
    'app.question.q58': '労働組合の活動を支持している。',
    'app.question.q59': '私は環境のことを心配している。',
    'app.question.q60': '拒絶されるのはつらい。',
    'app.question.q61': '両親に不満はない。',
    'app.question.q62': '自分が何者であり、どこへ行こうとしているのか確信が持てないことがある。',
    'app.question.q63': '私は、時々自分の感情を優先してしまう。 ',
    'app.question.q64': '女性の方が男性よりはるかによく噂話をする。',
    'app.question.q65': '宗教がなければ、平和は存在しない。',
    'app.question.q66': '男性の方が女性よりも強いということは否定できない。',
    'app.question.q67': '社会全体が、消費者中心に傾き過ぎていると思う。',
    'app.question.q68': '私の家族は金銭面で多くの問題を抱えていた。',
    'app.question.q69': '自分のことをよりよく理解するよう、常に努力している。',
    'app.question.q70': '強い者が生き残るのは当然だ。',
    'app.question.q71': '男性は女性よりも論理的である。',
    'app.question.q72': '過去の辛い感情と向き合うことは役に立つ。',
    'app.question.q73': '時々、男性も女性と同じように弱い気持ちになることがある。',
    'app.question.q74': '男の子にピンク色の服を着せることは間違っている。',
    'app.question.q75': '人間関係を描いた映画を見るのが好きだ。',
    'app.question.q76': '子供のころ、母とは良好で健全な関係を築いていた。',
    'app.question.q77': '地球環境の状態が心配だ。',
    'app.question.q78': '私の体は、自分の感情に敏感に反応する。',
    'app.question.q79': '自分の考え方、ふるまいは両親とうりふたつだ。',
    'app.question.q80': '女性は男性よりも感情的だ。',
    'app.question.q81': '強い宗教的信念を持っている。',
    'app.question.q82': '今の自分がこうなのは、そのように生まれついたからだ。',
    'app.question.q83': '私の両親は、夫婦関係でよく揉めていた。',
    'app.question.q84': '自分が何者なのか考えるのが好きだ。',
    'app.question.q85': '女性の地位はこの数十年で向上した。',
    'app.question.q86': '父は私にとってヒーローだ。',
    'app.question.q87': '自分の生き方を見つけるのに苦労した。',
    'app.question.q88': '時々、物事を深刻に受け止めすぎてしまう。',
    'app.question.q89': '私たちは、「母なる地球」という考え方を信じるべきだ。',
    'app.question.q90': '人生で孤独を感じたことがある。',
    'app.question.q91': '多くの教授/先生は、考え方がリベラルすぎる。',
    'app.question.q92': '真のいやしは、崇高な力によってのみもたらされる。',
    'app.question.q93': '時々、私は無力で、他の人の愛情が必要だと感じることがある。',
    'app.question.q94': '私たちの社会は、マイノリティ･グループをもっと支援すべきだ。',
    'app.question.q95': '複数の言語を学ぶのは、誰にとっても有益なことだ。',
    'app.question.q96': 'そのようにと期待しても、男性は結婚に対し誠実であるようには造られていない。',
    'app.question.q97': 'なぜ起こるのか自分には理解できない事柄がある。',
    'app.question.q98': '父とは心の隔たりがある/あった。',
    'app.question.q99': '女性がわが国の首相として選ばれるべきだ。',
    'app.question.q100': '最終的には、個人が自分の生き方を決める。',
    'app.question.q101': 'この国の司法機関は、リベラルすぎる。',
    'app.question.q102': '自分のことを真剣に受け止めてほしい。',
    'app.question.q103': '私たちは恵まれない人たちをもっと援助すべきだ。',
    'app.question.q104': '私は感情があらわになっても気にならない。',
    'app.question.q105': '成長過程で感情的に傷つけられた。',
    'app.question.q106': 'ポルノは女性をおとしめている。',
    'app.question.q107': '世の中の全ての不正に対し、怒りを覚える。',
    'app.question.q108': '大きな政府プログラムは、益よりも害の方が多い。',
    'app.question.q109': '過去に心を傷つけられたことがある。',
    'app.question.q110': '自己啓発についての本を読むのが好きだ。',
    'app.question.q111': '母/父は、情緒についての問題を抱えている/抱えていた。',
    'app.question.q112': '他の文化について学ぶのは楽しい。',
    'app.question.q113': '私たちは歴史ある伝統を守らなければならない。',
    'app.question.q114': '女性には成功するための野心が足りない。',
    'app.question.q115': '時々、他人に対し怒りを覚える。',
    'app.question.q116': '自分が強ければ、誰も本当に私を傷つけることはできない。',
    'app.question.q117': '自分の思いを聞いてくれる人が私には必要だ。',
    'app.question.q118': 'ほとんど全ての問題は、より懸命に取り組みさえすれば解決できる。',
    'app.question.q119': '神/仏/神聖な存在に祈るだけでは何も成しえない。',
    'app.question.q120': '私は、しばしば両親の問題や揉め事の中心となっている/なっていた。',
    'app.question.q121': 'この国は、貧富の格差が大きすぎる。',
    'app.question.q122': '自分の感じていることを全て理解しているわけではない。',
    'app.question.q123': '人間は実際のところ変わらない。',
    'app.question.q124': '自分のことを、家族にとって十分な存在だと感じたことはない。',
    'app.question.q125': '男性は生まれつき女性よりも競争好きだ。',
    'app.question.q126': '私たちは、地球の天然資源を守るためにもっと何かしなければならない。',
    'app.question.q127': '私は深刻な金銭問題を抱えていたことがある。',
    'app.question.q128': '私は批判されたくない。',
    'app.question.q129': '母/父と私は、非常に仲が良い/良かった。',
    'app.question.q130': '私は取り組まなければならない問題を抱えている。',
    'app.question.q131': '祈りは薬よりも治癒力がある',
    'app.question.q132': '私は子供のころ虐待/ネグレクト（育児放棄）されていた。',
    'app.question.q133': 'わが国は、犯罪に対して、より厳罰で対処すべきだ。',
    'app.question.q134': '私は女性の平等の権利を強く支持する。',
    'app.question.q135': '父は私を理解し、受け入れてくれた。',
    'app.question.q136': '教育は、より進んだ社会への鍵だ。',
    'app.question.q137': '男性の役割は、強くあることだ。',
    'app.question.q138': '政府が開発しようとしても、個人の土地は保護されるべきだ。',
    'app.question.q139': '両親は私に多くを要求しすぎていた。',
    'app.question.q140': '女性が経済や政治でより力を持つことは、すべての人の利益となる。',
    'app.question.q141': '人が泣いていると、私も一緒に泣いてしまうことがある。',
    'app.question.q142': '服装ゆえに自らセクハラを招いている女性もいる。',
    'app.question.q143': '何か好意的なことが言えないのであれば、黙っているべきだ。',
    'app.question.q144': '過去は変えられないのだから、くよくよと考えるのは無意味だ。',
    'app.question.q145': '人生に打ちのめされたら、立ち上がって進み続けるべきだ。',
    'app.question.q146': '拒絶はしばしばあなたをより強くする。',
    'app.question.q147': '世の中を分析しすぎる人もいる。',
    'app.question.q148': '嫌な気分の時は、いつも前向きに考えるようにしている。',
    'app.question.q149': '常に短所より長所を見るようにした方が良い。',
    'app.question.q150': '人生を乗り切る最善の方法は、明るい態度を持ち続けることだ。',
    'app.question.q151': 'すでに起こったことは仕方がないのだから、許して忘れるべきだ。',
    'app.question.q152': 'ある観点が他のどの観点よりも優れているとは言い切れない。',
    'app.question.q153': '人は自分の持っているものに感謝し、不平を言うのを止めるべきだと思う。',
    'app.question.q154': 'ある数字は他の数字よりも不吉である。',
    'app.question.q155': 'ののしったり口汚い言葉を使う理由はない。',
    'app.question.q156': '自分の責任を果たさない人が多すぎる。',
    'app.question.q157': '霊能者は未来を予測できる。',
    'app.question.q158': '物事は簡略化するに越したことはない。',
    'app.question.q159': '専門家二人を何かについて合意させることはできない。',
    'app.question.q160': 'ルールに従って行動すれば、上手くやっていける。',
    'app.question.q161': 'すべての人が、私のことを好きでいてくれるか、少なくとも尊重してくれたら良いのにと思う。',
    'app.question.q162': '私たちは、異なる文化慣習に対し、寛容であるべきだ。',
    'app.question.q163': '私は自由人だ（伝統的な暮らしや習慣にしばられない）。',
    'app.question.q164': '私はうらないや占星術の力を信じている。',
    'app.question.q165': '貧困の中で生きている人のことを思うと悲しい。',
    'app.question.q166': '私は明確な論理を何よりも重んじる。',
    'app.question.q167': '自分と立場の大きく異なる人たちと一緒にいても問題はない。',
    'app.question.q168': '親は、子供のために別れるべきではない。',
    'app.question.q169': '性的な空想にふけるのは健全なことだ。',
    'app.question.q170': '医学的な診断は、さほど役に立たない。',
    'app.question.q171': '私はいつも物事の明るい面を見ている。',
    'app.question.q172': '他の惑星からの生命体が人類の手助けをした。',
    'app.question.q173': '地球は何十億年の歳をかさねている。',
    'app.question.q174': '弱さは美徳となりうる。',
    'app.question.q175': '悲観論者を好きな人はいない。',
    'app.question.q176': '私は他の人が感情を表すことが好きではない。',
    'app.question.q177': '私が成長する間、両親はとても良い関係だった。',
    'app.question.q178': '私たちは異なる文化慣習に対し寛容すぎる。',
    'app.question.q179': '私たちは貧しい人々を助けるための行動を、十分とっている。',
    'app.question.q180': '人々は、環境のことについて案じすぎている。',
    'app.question.q181': '偏見はもはやそれほど問題ではない。',
    'app.question.q182': '人々は、常に変わる。',
    'app.question.q183': '天国への道はたくさんある。',
    'app.question.q184': '男性と女性は、異なっているというより、むしろ似ている。',
    'app.question.q185': '私たちの運命はほとんど定まっている。',
};

export const scaleQuestionsPageJA: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}

