import React from 'react';
import { Tab } from 'semantic-ui-react';
import { H2 } from '../../components/styled/h2';
import { colors } from '../../config/colors';
import { IntlContext, LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';
import { GroupReportBackgroundDomain, GroupReportProfileContrast, LongitudinalReportItem } from '../../services/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { BackgroundDomainContrast } from '../GroupReport/components/BackgroundDomainContrast';
import { DecileProfile } from '../GroupReport/components/DecileProfile';
import { InsufficientDataComponent } from '../GroupReport/components/InsufficientDataComponent';
import { ProfileContrast } from '../GroupReport/components/ProfileContrast';
import { Introduction } from '../../components/report/Introduction';
import { LongitudinalAggregateProfile } from '../../components/report/LongitudinalAggregateProfile';
import { AggregateByDemographics } from '../../components/report/AggregateByDemographics';
import { NarrativeReport } from '../GroupReport/components/NarrativeReport';
import { DomainScoresList } from '../GroupReport/components/DomainScoresList';
import { FormattedMessage } from 'react-intl';

type Props = {
  report: LongitudinalReportItem;
  userName: string;
}

const buildAggregateBackground =
  (subGroup: string[], backgroundDomain: GroupReportBackgroundDomain[],
    selectedCountryCodes: number[]) => {
    const experientialReflectionItemsPanes = [];
    for (let i = 0; i < subGroup.length; i++) {
      const subGroupName = subGroup[i];
      const backgroundDomainContrast = backgroundDomain.filter(t => t.groupName === subGroupName)[0];

      experientialReflectionItemsPanes.push({
        menuItem: subGroupName,
        render: () =>
          <Tab.Pane attached={false}>
            <BackgroundDomainContrast
              highestMean={backgroundDomainContrast.highestMean}
              highestRange={backgroundDomainContrast.highestRange}
              lowestMean={backgroundDomainContrast.lowestMean}
              lowestRange={backgroundDomainContrast.lowestRange}
              middleMean={backgroundDomainContrast.middleMean}
              middleRange={backgroundDomainContrast.middleRange}
              highestBackground={backgroundDomainContrast.highestBackground}
              lowestBackground={backgroundDomainContrast.lowestBackground}
              middleBackground={backgroundDomainContrast.middleBackground}
              selectedCountryCodes={selectedCountryCodes}
              subGroupName={subGroupName}
            />
          </Tab.Pane>,
      });
    }
    return experientialReflectionItemsPanes;
  }

export const LongitudinalReportPage = ({ report, userName }: Props): JSX.Element => {
  const { messages } = React.useContext(IntlContext);
  const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

  const profileContrastPanes =
    (subGroup: string[], profileBackgroundDomainContrast: GroupReportProfileContrast[]) => {
      const profileContrastPanes = [];

      profileContrastPanes.push({
        //@ts-ignore
        menuItem: messages['app.report.menu.profile_contrast'].toString(),
        render: () =>
          <Tab.Pane attached={false}>
            <ProfileContrast
              profileContrast={profileBackgroundDomainContrast}
              subGroupName={subGroup}
            />
          </Tab.Pane>,
      });

      for (let i = 0; i < subGroup.length; i++) {
        const groupName = subGroup[i];

        const profile = profileBackgroundDomainContrast.filter(t => t.groupName === groupName)[0];
        profileContrastPanes.push({
          menuItem: groupName,
          render: () =>
            <Tab.Pane attached={false}>
              <ProfileContrast
                profileContrast={[profile]}
                key={`profileContrast-${i}`}
              />
            </Tab.Pane>,
        });
      }
      return profileContrastPanes;
    }

  const mainPanes = [
    {
      menuItem: GetFormattedMessage('app.report.menu.introduction'), render: () =>
        <Tab.Pane>
          <Introduction
            introduction={report.introduction}
            visualizedBy={userName}
          />
        </Tab.Pane>
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.aggregate_profile'), render: () =>
        <Tab.Pane>
          <LongitudinalAggregateProfile
            aggregateProfile={report.aggregateProfile}
            subProgramName={report.groupNameOrder}
          />
        </Tab.Pane>
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.decile_profile'),
      render: () =>
        <Tab.Pane>
          <DecileProfile
            decileProfile={report.decileProfile}
            subGroupName={report.groupNameOrder}
          />
        </Tab.Pane>
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.aggregate_by_demographics'),
      render: () =>
        <Tab.Pane>
          <AggregateByDemographics report={report} type={'longitudinal'} />
        </Tab.Pane>
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.background_domain_contrast'),
      render: () =>
        <Tab
          menu={{ pointing: true }}
          panes={buildAggregateBackground(report.groupNameOrder,
            report.backgroundDomainContrast, report.selectedCountryCodes)}
        />
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.profile_contrast'),
      render: () =>
        <Tab
          menu={{ pointing: true }}
          panes={profileContrastPanes(report.groupNameOrder,
            report.profileBackgroundDomainContrast)}
        />
    },
    { menuItem: GetFormattedMessage('app.report.menu.experiential_reflection_items'), render: () => <Tab menu={{ pointing: true }} panes={experientialReflectionItemsPanes} /> },
    {
      menuItem: GetFormattedMessage('app.report.menu.narrative_report'), render: () =>
        <Tab.Pane>
          <NarrativeReport narrativeReports={report.narrativeReport} />
        </Tab.Pane>
    },
  ]

  if (isJapanese) {
    mainPanes.push({
      menuItem: GetFormattedMessage('app.report.background_statistic_item.domain_scores'), render: () =>
        <Tab.Pane>
          <React.Fragment>
            <DomainScoresList domainScoresList={report.backgroundStatistics} groupsName={report.groupNameOrder} />
          </React.Fragment>
        </Tab.Pane>
    });
  }

  const experientialReflectionItemsPanes = [
    {
      menuItem: GetFormattedMessage('app.report.menu.question_1'),
      render: () =>
        <Tab.Pane attached={false}>
          <p><FormattedMessage id="app.discursive_questions.question1" /></p>
          <ul>
            {
              report.writtenAnswers1.map((item, index) => <li key={index}>{item}</li>)
            }
          </ul>
        </Tab.Pane>,
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.question_2'),
      render: () =>
        <Tab.Pane attached={false}>
          <p><FormattedMessage id="app.discursive_questions.question2" /></p>
          <ul>
            {
              report.writtenAnswers2.map((item, index) => <li key={index}>{item}</li>)
            }
          </ul>
        </Tab.Pane>,
    },
    {
      menuItem: GetFormattedMessage('app.report.menu.question_3'),
      render: () =>
        <Tab.Pane attached={false}>
          <p><FormattedMessage id="app.discursive_questions.question3" /></p>
          <ul>
            {
              report.writtenAnswers3.map((item, index) => <li key={index}>{item}</li>)
            }
          </ul>
        </Tab.Pane>,
    },
  ]

  return (
    <div style={styles.divBackground} className='background'>
      <div style={styles.divCard} className='card'>
        {
          report.hasEnoughData ?
            <React.Fragment>
              <H2 textAlign='center'>{report.title}</H2>
              <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={mainPanes} />
            </React.Fragment>
            :
            <React.Fragment>
              {
                //@ts-ignore
                <H2 textAlign='center'>{messages['app.reports.longitudinal.title'].toString()}</H2>
              }
              <InsufficientDataComponent />
            </React.Fragment>

        }
      </div>
    </div>
  );
}

const styles = {
  divBackground: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  } as React.CSSProperties,
  divCard: {
    width: '100%',
    background: colors.white,
    borderRadius: '15px',
    padding: '16px',
  } as React.CSSProperties,
  buttonSubmit: {
    fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
    height: '35px',
    width: '100px',
    color: '#fff',
    backgroundColor: colors.green,
    border: 'none',
  } as React.CSSProperties
};
