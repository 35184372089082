import { Button, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React from 'react';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { MdLibraryBooks, MdRestore } from 'react-icons/md';
import { FormattedMessage, IntlContext } from 'react-intl';

import { Loading } from '../../components/Loading';
import { H2 } from '../../components/styled/h2';
import { H5 } from '../../components/styled/h5';
import { colors } from '../../config/colors';
import { FormDetails } from '../../services/listFormsService';
import { GetDate } from '../../utils/dateHelper';
import { isFormAvailable, shouldShowReport } from '../../utils/formHelper';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ActionButton } from './components/ActionButton';
import styled from 'styled-components';
import { Reports } from '../../services/reportService';
import { isNil } from 'lodash';

type Props = {
    data: FormDetails[];
    loading: boolean;
    showReportClick: (formId?: number) => void;
    formClick: (formId?: number) => void;
    restartClick: (formId?: number) => void;
    loadComparative: () => void;
    reportClick: (reportId: number) => void;
};

const ComparativeButton = styled(Button)`
    margin-top: 10px;
`

export const ListForms = ({ data, showReportClick, formClick, loading, restartClick, loadComparative, reportClick }: Props): JSX.Element => {

    const { messages } = React.useContext(IntlContext);

    if (loading) return <Loading />

    const columns: ColumnType<FormDetails>[] = [
        {
            title: () => (
                <Tooltip title={messages['app.listforms.organization_tooltip'].toString()}>
                    {messages['app.listforms.organization'].toString()}
                </Tooltip>
            ),
            dataIndex: 'organizationName',
        },
        {
            title: () => (
                <Tooltip title={messages['app.listforms.program_tooltip'].toString()}>
                    {messages['app.create_report.name'].toString()}
                </Tooltip>
            ),
            dataIndex: 'reportName'
        },
        {
            title: () => (
                <Tooltip title={messages['app.listforms.take_by_tooltip'].toString()}>
                    {messages['app.listforms.take_by'].toString()}
                </Tooltip>
            ),
            dataIndex: 'dueDate',
            render: (dueDate: string) => GetDate(dueDate),
            responsive: ['md'],
        },
        {
            title: () => (
                <Tooltip title={messages['app.listforms.completed_on_tooltip'].toString()}>
                    {messages['app.listforms.completed_on'].toString()}
                </Tooltip>
            ),
            dataIndex: 'completedOn',
            render: (completedOn: string) => GetDate(completedOn),
            responsive: ['lg'],
        },
        {
            title: () => (
                <Tooltip title={messages['app.listforms.progress_tooltip'].toString()}>
                    {messages['app.listforms.progress'].toString()}
                </Tooltip>
            ),
            dataIndex: 'percentageCompleted',
            align: 'center',
            render: (percentage: number) => <span>{percentage * 100} %</span>,
            responsive: ['md'],
        },
        {
            title: () => (
                <Tooltip title={messages['app.listforms.action_tooltip'].toString()}>
                    {messages['app.listforms.action'].toString()}
                </Tooltip>
            ),
            key: 'action',
            render: (_: any, record: FormDetails) =>
                <div style={styles.divActionButton}>
                    {isFormAvailable(record.status) &&
                        <ActionButton
                            titleMessageKey={record.status === 1 ?
                                'app.listforms.start'
                                :
                                'app.listforms.continue'
                            }
                            onClick={() => formClick(record.id)}
                            variant={record.status === 1 ? 'primary' : "info"}
                            icon={MdLibraryBooks}
                        />
                    }
                    {shouldShowReport(record.status, record.showReport) &&
                        <ActionButton
                            titleMessageKey='app.listforms.report'
                            onClick={() => showReportClick(record.id)}
                            variant={"success"}
                            icon={AiOutlineAreaChart}
                        />
                    }
                    {record.type != null &&
                        <ActionButton
                            titleMessageKey={record.type === Reports.partner_report ? 'app.listforms.partner_report' : 'app.listforms.partner_change_report'}
                            onClick={() => reportClick(record.id)}
                            variant={"warning"}
                            icon={AiOutlineAreaChart}
                        />
                    }
                    {record.canRestart &&
                        <ActionButton
                            titleMessageKey='app.listforms.restart'
                            onClick={() => restartClick(record.id)}
                            variant={"secondary"}
                            icon={MdRestore}
                        />
                    }
                </div>
        }
    ];

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.divTitle}>
                    <H2 textAlign='center'><FormattedMessage id='app.listforms.title' /></H2>
                    <H5 textAlign='center'><FormattedMessage id='app.listforms.subtitle' /></H5>
                    {data.filter(x => (isNil(x.type) || x.type === Reports.individual_report) && x.showReport).length > 1 && <ComparativeButton onClick={loadComparative} type='primary'>
                    {GetFormattedMessage('app.listforms.comparative_individual_report')}
                </ComparativeButton>}
                </div>
       
                <br />
                <Table
                    dataSource={data}
                    rowKey={(record: FormDetails) => record.id.toString()}
                    locale={{ emptyText: GetFormattedMessage('app.empty.data') }}
                    columns={columns}
                />
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        flex: 1,
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    divTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '15px',
        paddingBottom: '25px',
    } as React.CSSProperties,
    divActionButton: {
        height: '40px',
    } as React.CSSProperties,
};
