import { ConsentPageMessages } from '../types';

export const consentPageDE: ConsentPageMessages = {
    'app.consent.title': 'Vereinbarung zur Berichtsprüfung',
    'app.consent.form_title': 'Einverständniserklärung',
    'app.consent.greeting': 'Liebe Teilnehmer:in:',
    'app.consent.introduction': 'Vielen Dank für Ihre Bereitschaft, das BEVI (Beliefs, Events, and Values Inventory) auszufüllen.',
    'app.consent.general.observation': 'Dieses allgemeine Einwilligungsformular wurde unter Berücksichtigung vieler verschiedener Verfahren des Institutional Review Board (IRB) entwickelt. Vor dem Ausfüllen des BEVI kann es sein, dass Sie Ihre Einwilligung über ein separates System oder Formular, das von einer Einrichtung oder Organisation genehmigt wurde, erteilen müssen. Wenn Sie Fragen zu diesem Verfahren haben sollten, wenden Sie sich bitte an die/den BEVI-Administrator:in Ihrer Einrichtung oder Organisation.',
    'app.consent.explanation_1': 'Wie der Name dieses Inventars bereits andeutet, wurde der BEVI entwickelt, um zu bewerten, wie verschieden Menschen sich selbst, andere und die Welt im Allgemeinen sehen. Nachdem Sie eine Reihe von Hintergrundfragen beantwortet haben, werden Sie gebeten, auf Punkte zu antworten, die ein sehr breites Spektrum von Fragen und Themen abdecken. Möglicherweise werden Sie auf verschiedene Punkte unterschiedlich reagieren. Einige dieser Punkte scheinen direkt mit Lernen, Wachstum und Entwicklung zu tun zu haben, andere wiederum nicht; einige Punkte scheinen einfach zu sein, während andere zweideutig erscheinen. Da mit dieser Maßnahme viele komplexe und miteinander verknüpfte Faktoren erfasst werden, sind alle diese Punkte sowie die Hintergrundfragen relevant für das Verständnis von Prozessen, die mit Lernen, Wachstum und Entwicklung zusammenhängen, sowie dafür, wie verschiedene Menschen sich selbst, andere und die Welt im Allgemeinen sehen.',
    'app.consent.explanation_2': 'Obwohl verschiedene Personen auf diese Punkte unterschiedlich antworten können, gibt es im BEVI keine "richtigen" oder "falschen" Antworten. Da wir uns oft in unseren Überzeugungen, Werten und Lebenserfahrungen unterscheiden, ist es sowohl üblich als auch zu erwarten, dass eine Person einem Punkt stark zustimmt, während eine andere Person demselben Punkt stark widerspricht. Dadurch, dass das BEVI die Antworten "stimme voll und ganz zu", "stimme eher zu", "stimme eher nicht zu" und "stimme überhaupt nicht zu" zulässt, wird ausdrücklich erkennbar, dass verschiedene Menschen sehr unterschiedliche Überzeugungen, Werte und Lebenserfahrungen haben können.',
    'app.consent.participant.rights.1': 'Ihre Teilnahme an dieser Studie ist freiwillig und Sie können sich jederzeit dazu entscheiden, abzubrechen. Ihre Privatsphäre wird im größtmöglichen gesetzlich zulässigen Umfang geschützt. Alle Ihre Antworten werden mit <b>strenger Vertraulichkeit</b> behandelt. Einzelne Antworten, die Sie auf Fragen geben, werden <b>nie mit Ihnen in Verbindung gebracht</b>, es sei denn, Sie stimmen einer solchen Verbindung ausdrücklich schriftlich zu.</b> In einigen Fällen kann es jedoch vorkommen, dass Antworten direkt mit Ihnen verbunden werden können. In diesem Fall werden Ihre Antworten streng vertraulich behandelt; Ihre Privatsphäre wird im größtmöglichen gesetzlich zulässigen Umfang und in einer Weise geschützt, die mit dem Informationsschutz vereinbar ist. Nur die Projektkoordinator:innen werden Zugang zu den Rohdaten haben. Zu wissenschaftlichen, pädagogischen, beruflichen, organisatorischen, informativen oder illustrativen Zwecken ist es möglich, dass einzelne Skalen oder Punkte, Gruppenprofildaten oder Organisationsberichte präsentiert oder veröffentlicht werden. Wenn dies jedoch der Fall ist, werden keine identifizierenden Informationen präsentiert oder veröffentlicht, die ein bestimmtes Profil, Skalen, Punkte oder Antworten direkt mit Ihnen in Verbindung bringen könnten (z. B. würde jegliche Identitätsbescheinigung gelöscht werden), es sei denn, Sie genehmigen eine solche Verknüpfung ausdrücklich in schriftlicher Form.',
    'app.consent.participant.rights.2': 'Je nachdem, wann und in welchem Zusammenhang Sie das BEVI ausfüllen, ist es möglich, dass Sie einen Bericht über Ihre Antworten einsehen können. Wenn dies der Fall ist, müssen Sie möglicherweise gesonderten Bedingungen zustimmen, bevor Sie den Bericht lesen können.  Es ist auch möglich, dass Sie gebeten werden, an einer Sitzung teilzunehmen, in der ein Gruppenbericht besprochen wird; dieser Bericht kann BEVI-Daten aus einer größeren Gruppe enthalten, der Sie angehören haben könnten. Im Rahmen eines BEVI-Bewertungsprojekts ist es auch möglich, dass Sie gebeten werden, andere quantitative (z. B. Multiple Choice) oder qualitative (z. B. schriftliche Antworten auf Fragen) Inhalte auszufüllen und/oder zusätzliche Informationen in Form von schriftlichen oder mündlichen Interviews zu geben. Dieser Prozess kann Folgegespräche mit dem Administrator:innen oder Interpret:innen eines bestimmten Inhaltes beinhalten. Ihre Teilnahme an all diesen Verfahren ist freiwillig und Sie können sich jederzeit dafür entscheiden, abzubrechen. Wie bereits erwähnt, werden Ihre individuellen Antworten in einem Gruppenbericht oder in anderen Inhalten nicht mit Ihnen in Verbindung gebracht. Wie oben angegeben können Sie nach Abschluss des BEVI oder anderen quantitativen oder qualitativen Inhalten an einer Feedback-Sitzung teilnehmen.',
    'app.consent.understand.agree': 'Wenn Sie mit den obigen Informationen und Richtlinien einverstanden sind und zustimmmen, das BEVI auszufüllen und an einem oder mehreren der oben genannten Verfahren teilzunehmen, bei denen das BEVI verwendet wird, klickenn Sie bitte auf die Schaltfläche \'Zustimmen\'.',
    'app.consent.have.questions': 'Wenn Sie Fragen haben, können Sie uns gerne unter <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com</a> kontaktieren',
    'app.consent.thanks': 'Vielen Dank für Ihre Teilnahme.',
    'app.consent.giving.consent': 'Erteilung der Zustimmung',
    'app.consent.read.and.agree': 'Ich habe die Informationen über das BEVI und diese Einverständniserklärung gelesen und verstehe, was von mir als Teilnehmer:in verlangt wird. Ich willige freiwillig in die Teilnahme ein. Ich habe die obige Beschreibung dieses Projekts und meiner Rechte als Teilnehmer:in gelesen. Indem ich auf die Schaltfläche Zustimmen klicke und diese vertrauliche Online-Umfrage ausfülle und abschicke, erkläre ich mich mit der Teilnahme einverstanden.',
    'app.consent.agree': 'Zustimmen',
    'app.consent.disagree': 'Nicht zustimmen',
    'app.custom_agreement.modal_title': 'Vereinbarung zur Berichtsprüfung',
    'app.custom_agreement.modal_explanation_text': 'Bitte bearbeiten Sie den Text der benutzerdefinierten Vereinbarung, bevor Sie ihn abschicken.',
    'app.custom_agreement.default_custom_agreement': 'Liebe Teilnehmer:in!:<br/><br/>Sie haben zwar bereits der Teilnahme am BEVI zugestimmt, bevor wir aber mit Ihnen an ihrem BEVI-Bericht arbeiten können, würden wir Sie dennoch um Ihre Einverständnis zu folgenden Punkten bitten, nämlich 1) an einer Sitzung oder mehreren Sitzungen teilzunehmen, in denen Ihr individueller Bericht persönlich besprochen wird, und 2) dass Sie <strong>[here we need to be able to fill in one or more names of individual people]</strong> uns dazu ermächtigen Ihren individuellen Bericht (z.B. Ihren Bericht, Ihre Punkteanzahl) einzusehen und die Bedeutung oder Auswirkungen Ihres BEVI-Berichts mit Ihnen zu besprechen.<br/>Wie in der Einverständniserklärung für das BEVI angegeben, werden Ihre individuellen Ergebnisse selbstverständlich absolut vertraulich behandelt.  Wenn Sie Fragen haben oder weitere Informationen benötigen, wende Sie sich bitte an die/den BEVI-Administrator:in, die/der mit Ihnen zusammenarbeitet.<br/><br/>Wenn Sie die beiden oben genannten Bestimmungen verstanden haben und damit einverstanden sind, klicken Sie bitte unten auf "Zustimmen". Vielen Dank für Ihr Interesse am BEVI!',
    'app.custom_agreement.partner_report_agreement': `
    <h5>Sie haben der Teilnahme am BEVI bereits zugestimmt, aber bevor wir mit Ihnen an einem oder mehreren Ihrer BEVI-Berichte arbeiten können (z. B. für Coaching-, Beratungs-, Beratungszwecke usw.), fragen wir Sie, ob Sie mit den folgenden Punkten einverstanden sind :</h5>
    <br />

    <p>
    1. Mir ist bewusst, dass es mehrere Arten von BEVI-Berichten gibt, bei denen meine Ergebnisse von jemandem mit BEVI-Zertifizierung mit mir besprochen werden können: 1) Einzelbericht, 2) Einzeländerungsbericht, 3) Partnerbericht und 4) Partnerwechselbericht. Mir ist auch bewusst, dass ich jedem anderen die Erlaubnis erteilen muss, einen oder mehrere meiner Berichte zu prüfen und mit mir zu besprechen.
    </p>
    <p>
    2. Indem ich unten auf die Schaltfläche „Zustimmen“ klicke, autorisiere ich die folgende(n) BEVI-zertifizierte(n) Person(en), <b>[#USERS#]</b>, einen oder mehrere meiner Berichte zu prüfen und mit mir zu besprechen.
    </p>
    <p>
    3.	Ich verstehe und stimme auch zu, dass:
        <p style="padding-left: 20px;">
        a) Ich ermächtige den folgenden Partner oder die folgenden Partner, <b>[#PARTNERS#]</b>, meinen Bericht/Ergebnisse und deren Bericht/Ergebnisse mit mir und der oder den BEVI-zertifizierten Personen zu prüfen und zu besprechen dass ich oben autorisiert habe;
        </p>
        <p style="padding-left: 20px;">
        b) Ich werde keinen Partnerbericht oder Partneränderungsbericht ganz oder teilweise – oder in irgendeiner Form (z. B. per E-Mail, Post, Verteilung, Anzeige, Kopie usw.) – mit jemand anderem als meinem Partner teilen oder besprechen ( s) und die oben aufgeführte(n) BEVI-zertifizierte(n) Person(en); Alle Ausnahmen von dieser Bedingung werden von mir und allen hier aufgeführten Personen – dem Partner oder den Partnern und der oder den BEVI-zertifizierten Personen – schriftlich vereinbart.
        </p>
        <p style="padding-left: 20px;">
        c) Der Partner oder die Partner, die ich oben autorisiert habe, können ihre Partnerberichte oder Partneränderungsberichte jederzeit löschen, indem sie das Berechtigungsprofil in ihrem BEVI-Dashboard ändern, wodurch die Berichte aus ihrem BEVI entfernt werden Armaturenbrett und meins.
        </p>
    </p>
    <p>
    4. Zusätzlich zu den oben genannten Bedingungen bleiben alle anderen in der BEVI-Einverständniserklärung aufgeführten Bestimmungen bestehen (z. B. die Freiwilligkeit meiner Teilnahme usw.). Wenn Sie Fragen haben oder weitere Informationen benötigen, wenden Sie sich bitte an die BEVI-zertifizierte Person oder Personen, die mit Ihnen zusammenarbeiten.
    </p>
    <p>
    5. Wenn Sie allen oben genannten Punkten zustimmen, klicken Sie bitte unten auf „Ich stimme zu“. Wenn Sie nicht mit allen oben genannten Punkten einverstanden sind, klicken Sie bitte unten auf „Ich stimme nicht zu“.
    </p>
    <center>Vielen Dank für Ihr Interesse am BEVI!</center>
    `,
}



