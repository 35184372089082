import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { SelectItem } from '../../components/FormDropdown';
import { Loading } from '../../components/Loading';
import { colors } from '../../config/colors';
import { UsersMessages } from '../../locales/types';
import { appLogic, AppProps } from '../../redux/appLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import { LIST_USERS_PAGE } from '../../router/pages';
import { OrganizationWithProgramsModel, ProgramModel } from '../../services/organizationService';
import { CreateNewUserPayloadRequest, SaveUserStatus } from '../../services/usersService';
import { SingleUser } from './components/single_user/SingleUser';

type Props = RouteComponentProps;

export const EditUserContainer = withRouter((props: Props): JSX.Element => {

    const {
        organizationsWithPrograms, user, selectedUserId, loadingPage
    }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { loadOrganizationWithProgram, getUser }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { loadingCreateNewUser, errorMessageNewUser, saveUserStatus }: UsersProps = useValues(usersLogic);
    const { createNewUser }: UsersActions = useActions(usersLogic);
    const { tokenInfo }: AppProps = useValues(appLogic);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    // Effect to react to createNewUser response.
    useEffect(() => {
        if (saveUserStatus !== null) {
            setShowConfirmation(true)
        }
    }, [saveUserStatus]);

    // Effect validate the selected user id.
    useEffect(() => {
        if (selectedUserId === null) {
            props.history.replace(LIST_USERS_PAGE);
        } else {
            getUser && getUser(selectedUserId);
        }
    }, [getUser, selectedUserId, props.history]);

    useEffect(() => {
        loadOrganizationWithProgram();
    }, [loadOrganizationWithProgram]);

    const programSelectOptions: Map<number, SelectItem[]> = new Map<number, SelectItem[]>();
    const organizationSelectOptions: Array<SelectItem> =
        organizationsWithPrograms?.map(
            (org: OrganizationWithProgramsModel) => {
                const programs: SelectItem[] = [];
                org.programs.forEach((prog: ProgramModel) =>
                    programs.push({ value: prog.id, label: prog.name }))
                programSelectOptions.set(org.id, programs);
                return ({ value: org.id, label: org.name })
            }) ?? [];

    const onClickSubmit = (
        organizationIds: number[], programIds: number[], name: string, login: string,
        language: number | undefined, userType: number | undefined, anonymous: number | undefined,
        sendEmail?: boolean, permissions?: number[]
    ) => {
        createNewUser({
            id: selectedUserId,
            organizationIds,
            programIds,
            name,
            login,
            language,
            userType,
            isAnonymous: anonymous,
            permissions 
        } as CreateNewUserPayloadRequest);
    }

    const handleConfirmationOkClick = (): void => {
        if (errorMessageNewUser || saveUserStatus === SaveUserStatus.AlreadyExists || saveUserStatus === SaveUserStatus.WrongEmail) {
            setShowConfirmation(false);
        } else {
            props.history.goBack();
        }
    }

    const prepareConfirmationBody = (): Array<keyof(UsersMessages)> => {
        if (errorMessageNewUser) return ['app.edit.user.confirmation_body_fail'];

        if (saveUserStatus === SaveUserStatus.AlreadyExists) return ['app.edit.user.confirmation_body_fail_already_exists'];
        if (saveUserStatus === SaveUserStatus.WrongEmail) return ['app.edit.user.confirmation_body_fail_wrong_email'];
        return ['app.edit.user.confirmation_body_success'];

    }

    const prepareConfirmationTitle = (): keyof(UsersMessages) => {
        if (errorMessageNewUser || saveUserStatus === SaveUserStatus.AlreadyExists || saveUserStatus === SaveUserStatus.WrongEmail) {
            return 'app.edit.user.confirmation_title_fail'
        }
        return 'app.edit.user.confirmation_title_success';
    }

    if (loadingPage || user == null || organizationsWithPrograms === null) return <Loading />

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <SingleUser
                    titleKey='app.create.user.edit.single.user.title'
                    user={user}
                    userInfo={tokenInfo}
                    organizations={organizationSelectOptions}
                    programs={programSelectOptions}
                    onClickSubmit={onClickSubmit}
                    loading={loadingCreateNewUser}
                    onBackButtonClicked={props.history.goBack}
                />

                <ConfirmationModal
                    showModal={showConfirmation}
                    titleFormattedMessageId={prepareConfirmationTitle()}
                    bodyFormattedListMessageId={prepareConfirmationBody()}
                    primaryButtonFormattedMessageId={'app.btn_ok'}
                    onModalHide={handleConfirmationOkClick}
                    onPrimaryButtonClicked={handleConfirmationOkClick}
                />
            </div>
        </div>
    );
});

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
};
