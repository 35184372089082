import React from 'react'
import { GroupReportIntroduction } from '../../../services/types'
import { GetFormattedMessage } from '../../../utils/htmlHelper'
import { GetDate } from '../../../utils/dateHelper'

type Props = {
  introduction: GroupReportIntroduction;
  visualizedBy: string;
}

export const Introduction = ({ introduction, visualizedBy }: Props): JSX.Element => {
  return (
    <div>
      <div>
        <h3>{GetFormattedMessage('app.report.introduction.group_name')}</h3>
        {introduction.groupStructure[0].details.map((item, index) => <li key={`GroupName-${index}`}>{item}</li>)}
        <br />
        <h3>{GetFormattedMessage('app.report.introduction.create_date')}</h3>
        <li>{GetDate(introduction.createdDateOnUtc)}</li>
        <br />
        <h3>{GetFormattedMessage('app.report.introduction.visualized_by')}</h3>
        <li>{visualizedBy}</li>
        <br />
        <h3>{GetFormattedMessage('app.report.introduction.number_of_participants')}</h3>
        <li>{introduction.numberOfParticipants}</li>
      </div>
    </div>
  )
}