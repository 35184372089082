import React, { useState } from 'react';
import { Button, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
  showModal: boolean;
  user: any;
  loadingButton: boolean;
  success: boolean;
  error: boolean;
  onModalHide: () => void;
  onChangePassword(password: string): void;
}

export const ResetPasswordModal = (
  { loadingButton, showModal, user, success, error, onModalHide, onChangePassword }: Props
): JSX.Element => {

  const [password, setPassword] = useState<string>('');

  const onSubmitButtonClicked = () => {
    if (password !== '') {
      onChangePassword(password);
    }
  }

  return (
    <Modal show={showModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {GetFormattedMessage('app.reset_password.title').toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {success === true &&
            <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.reset_password.successful_message').replace("{username}", user?.name) }} />
          }
          {error === true &&
            <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.reset_password.error_message') }} />
          }
          <Form.Group className='mb-3' as={Col}>
            <Form.Group className='mb-3' as={Row} controlId="formField2">
              {user !== undefined &&
                <div dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.reset_password.body').replace("{username}", user.name) }} />
              }
            </Form.Group>
          </Form.Group>
          <Form.Group className='mb-3' as={Col}>
            <Form.Group className='mb-3' controlId="formField2">
              <b><FormattedMessage id='app.reset_password.new_temporary_password' /></b>
              <Form.Control
                className="form-control mb-3"
                type='password'
                placeholder={GetFormattedMessage('app.reset_password.new_password')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Form.Group>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: colors.darkSlateBlue }}
          variant='primary'
          onClick={onSubmitButtonClicked}
          disabled={loadingButton || password === ''}
        >
          {loadingButton &&
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: 10 }}
            />
          }
          {GetFormattedMessage('app.reset_password.button_reset')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};