import React, { Fragment, useEffect, useState } from 'react';
import { useValues, useActions } from 'kea';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DemographicQuestionsActions, demographicQuestionsLogic, DemographicQuestionsProps } from '../../redux/demographicQuestionsLogic';
import { DemographicExtraQuestions } from './DemographicExtraQuestions';
import { listOrganizationLogic, ListOrganizationProps } from '../../redux/listOrganizationLogic';
import { ORGANIZATION_PAGE } from '../../router/pages';
import { SaveExtraQuestionModal } from './components/ExtraQuestionsModal';
import { AddExtraQuestionModel, DeleteExtraQuestionModel, EditExtraQuestionModel, ExtraQuestionModel } from '../../services/types';

type Props = RouteComponentProps;

export const DemographicExtraQuestionsContainer = withRouter((props: Props): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [extraQuestion, setExtraQuestion] = useState<ExtraQuestionModel | undefined>(undefined);
    const { selectedInstitutionId }: ListOrganizationProps = useValues(listOrganizationLogic);

    const {
        demographicsExtraQuestions, loadingDemographicsExtraQuestions, loadingExtraQuestions,
        hasSavedExtraQuestions, hasDeletedExtraQuestions
    }: DemographicQuestionsProps = useValues(demographicQuestionsLogic);

    const {
        loadDemographicsExtraQuestions, addExtraQuestion, editExtraQuestion, deleteExtraQuestion
    }: DemographicQuestionsActions = useActions(demographicQuestionsLogic);

    useEffect(() => {
        if (selectedInstitutionId === null) {
            props.history.replace(ORGANIZATION_PAGE);
        }
    }, [selectedInstitutionId, props.history]);

    useEffect(() => {
        loadDemographicsExtraQuestions(selectedInstitutionId);
        // eslint-disable-next-line
    }, []);

    const fetchAddExtraQuestion = (title: string, questionType: number, extraQuestionOptions: string[]) => {
        const model: AddExtraQuestionModel = {
            institutionId: selectedInstitutionId,
            title,
            extraQuestionOptions,
            questionType
        }
        addExtraQuestion(model);
    }

    const fetchEditExtraQuestion = (title: string, newExtraQuestionOptions: string[]) => {
        const model: EditExtraQuestionModel = {
            id: extraQuestion!.id,
            institutionId: selectedInstitutionId,
            title,
            newExtraQuestionOptions
        }
        editExtraQuestion(model);
    }

    const fetchDeleteExtraQuestion = (id: number) => {
        const model: DeleteExtraQuestionModel = {
            id,
            institutionId: selectedInstitutionId,
        }
        deleteExtraQuestion(model);
    }


    useEffect(() => {
        if (hasSavedExtraQuestions) {
            loadDemographicsExtraQuestions(selectedInstitutionId);
            setShowModal(false);
        }
    }, [hasSavedExtraQuestions, selectedInstitutionId, loadDemographicsExtraQuestions])

    useEffect(() => {
        if (hasDeletedExtraQuestions) {
            loadDemographicsExtraQuestions(selectedInstitutionId);
        }
    }, [hasDeletedExtraQuestions, selectedInstitutionId, loadDemographicsExtraQuestions])


    return (
        <Fragment>
            <SaveExtraQuestionModal fetchAddExtraQuestion={fetchAddExtraQuestion} fetchEditExtraQuestion={fetchEditExtraQuestion} loadingButton={loadingExtraQuestions}
                showModal={showModal} setShowModal={setShowModal} extraQuestion={extraQuestion}
                modalTitle={editExtraQuestion == null ? 'app.institutions.demographics_questions.add.title' : 'app.institutions.demographics_questions.edit.title'} />
            <DemographicExtraQuestions
                setShowModal={setShowModal}
                setExtraQuestion={setExtraQuestion}
                loading={loadingDemographicsExtraQuestions}
                data={demographicsExtraQuestions}
                fetchDeleteExtraQuestion={fetchDeleteExtraQuestion}
            />
        </Fragment>
    );
});
