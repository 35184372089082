import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { OrganizationDetails } from '../../../services/organizationService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { UserPermissions } from '../../../services/usersService';
import { TokenInfo } from '../../../services/loginService';
import { HasPermissionCapabilityProgramAdmin } from '../../../utils/userHelper';


type Props = {
    searchHandleClick: (startDate: string, endDate: string, organizationId?: number) => void;
    userInfo: TokenInfo;
    startDate: string;
    endDate: string;
    selectedOrganizationId?: number;
    organizations: OrganizationDetails[];
    onCreateNewReportClick: () => void;
    onOrganizationChanged: (organizationId?: number) => void;
};

export const FilterListReport = ({
    onCreateNewReportClick, searchHandleClick, onOrganizationChanged,
    startDate, endDate, organizations, selectedOrganizationId, userInfo
}: Props): JSX.Element => {
    const [startDateState, setStartDate] = useState<string>(startDate);
    const [endDateState, setEndDate] = useState<string>(endDate);

    const organizationOptions: Array<SelectItem> =
        organizations.map((item: OrganizationDetails) => ({ value: item.id, label: item.name }));

    const selectedOrganization = organizationOptions.find((item: SelectItem) => item.value === selectedOrganizationId);

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2> {GetFormattedMessage('app.listreport.report_area')} </h2>
                    {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canAddReport) && <div style={{ display: 'flex', gap: 12, justifyContent: 'center' }}>
                        <ButtonWithIcon
                            textId={'app.listreport.new'}
                            onClick={onCreateNewReportClick}
                            icon={<IoMdAddCircleOutline />}
                        />
                    </div>}
                </div>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        <Col md={4}>
                            <FormDropdown
                                isSearchable={true}
                                defaultOptionId={'app.listorganizations.select_organization'}
                                onValueSelect={(value: number | undefined) => {
                                    onOrganizationChanged(value);
                                }}
                                value={selectedOrganization}
                                optionItens={organizationOptions}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                value={startDateState}
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setStartDate(e.target.value)
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                value={endDateState}
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEndDate(e.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={styles.buttonSearchDiv}>
                    <ButtonWithIcon
                        disabled={startDateState === undefined || startDateState === '' ||
                            endDateState === undefined || endDateState === ''}
                        textId={'app.listreport.search'}
                        onClick={() => { searchHandleClick(startDateState, endDateState, selectedOrganizationId) }}
                        icon={<FaSearch />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'right',
        paddingRight: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
};
