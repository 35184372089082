import { InstitutionMessages } from '../types';

export const institutionES: InstitutionMessages = {
  'app.institutions.title': 'Gestión de la organización',
  'app.institutions.new': 'Nueva organización',
  'app.institutions.search': 'Buscar',
  'app.institutions.list.name': 'Nombrar',
  'app.institutions.list.edit': 'Editar',
  'app.institutions.list.action': 'Acción',
  'app.institutions.add.title': 'Nueva Organización',
  'app.institutions.edit.title': 'Editar Organización',
  'app.institutions.add.successful_message': 'Organización ha sido actualizado con éxito.',
  'app.institutions.add.error_message': 'Algo ha ido mal, por favor, vuelve a intentarlo en unos minutos.',
  'app.institutions.add.new_name': 'Nuevo nombre',
  'app.institutions.add.button_save': 'Guardar',
  'app.institutions.add.hide_demographics_questions': 'Ocultar Preguntas Demográficas',
  'app.institutions.add.demographics_extra_questions': 'Preguntas adicionales sobre datos demográficos',
  'app.institutions.hide_demographics_questions.successful_message': 'Las preguntas demográficas ha sido actualizado con éxito.',
  'app.institutions.demographics_questions.question': 'Pregunta',
  'app.institutions.demographics_questions.type': 'Escribe',
  'app.institutions.demographics_questions.type_0': 'Default',
  'app.institutions.demographics_questions.type_1': 'Text',
  'app.institutions.demographics_questions.type_2': 'Opción única',
  'app.institutions.demographics_questions.add.title': 'Nueva pregunta adicional',
  'app.institutions.demographics_questions.edit.title': 'Editar pregunta adicional',
  'app.institutions.demographics_questions.title': 'Título',
  'app.institutions.demographics_questions.options': 'Opciones',
  'app.institutions.demographics_questions.description': 'Descripción',
  'app.institutions.demographics_questions.delete': '¿Estás seguro de eliminar este artículo?',
  'app.institutions.chat.successful_message': 'En unos minutos, todos los usuarios podrán utilizar el chat AI.',
  'app.institutions.chat.add_chat_all': 'Agregar chat para todos los usuarios',
  'app.institutions.chat.question_add_chat_all': '¿Está seguro de que desea incluir el chat para todos los usuarios de esta organización?'
}