import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Loading } from '../../components/Loading';
import { QuestionPageHeader } from '../../components/QuestionPageHeader';
import { colors } from '../../config/colors';
import { appMessagesIds } from '../../locales/types';
import { DiscursiveQuestionAnswers } from '../../services/types';
import { FormTextArea } from './components/FormTextArea';

type Props = {
    onAnswerChanged: (key: keyof(DiscursiveQuestionAnswers), answerValue: string) => void;
    onNextButtonClicked: () => void;
    loading: boolean;
    error?: string;
}

export const DiscursiveQuestions = (
    { loading, error, onNextButtonClicked, onAnswerChanged }: Props
): JSX.Element => {

    // TODO(rodrigo.santos): Separate the loading into loading and sending.
    if (loading) {
        return <Loading />
    }

    return (
        <React.Fragment>
            <QuestionPageHeader />
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <FormattedMessage id={'app.discursive_questions.introduction'} />
                    <br />
                    <br />
                    <FormTextArea
                        labelIdMessage='app.discursive_questions.question1'
                        onChange={(value: string) => onAnswerChanged('response1', value)}
                    />
                    <FormTextArea
                        labelIdMessage='app.discursive_questions.question2'
                        onChange={(value: string) => onAnswerChanged('response2', value)}
                    />
                    <FormTextArea
                        labelIdMessage='app.discursive_questions.question3'
                        onChange={(value: string) => onAnswerChanged('response3', value)}
                    />
                    <br />
                    <div className='button'>
                        <Button
                            style={styles.btnNext}
                            variant="primary"
                            onClick={onNextButtonClicked}
                            disabled={loading}
                        > 
                        { loading?
                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            : 
                            <FormattedMessage id={appMessagesIds['app.btn_finish']} />
                        }  
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const styles = {
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h1BeviTitle: {
        marginTop: '0',
        width: '300px',
        marginBottom: '10px',
    } as React.CSSProperties,
    btnNext: {
        width: '150px',
        float: 'right',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties
};
