import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { FormNumericInput } from '../../../components/FormNumericInput';
import { PageMessages } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import { ColSpec } from '../../../utils/formHelper';
import { NumericQuestion } from '../library/questionDefinitions';

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    question: NumericQuestion;
    answerChange: (key: keyof(DemographicQuestionAnswers), value: number | string | undefined) => void;
    defaultOptionId ?: keyof(PageMessages);
    questionNumber: number;
    style?: React.CSSProperties;
};

export const NumericQuestions = (
    { optionsMd, question, style, answerChange, defaultOptionId, questionNumber, ...groupProps }: Props
): JSX.Element => {
    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={question.GetQuestionLabelId()} />
                </div>
            </Form.Label>
            <Col md={optionsMd} style={styles.colDiv}>
                <FormNumericInput
                    maxValue={question.GetMaxValue()}
                    onValueChange={(value: number | undefined) => {
                        answerChange(question.GetQuestionKeyName(), value);
                    }}
                />
            </Col>
        </Form.Group>
    );
}

const styles = {
    colDiv: {
        minWidth: '80px',
    } as React.CSSProperties,
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px',
    } as React.CSSProperties,
};
