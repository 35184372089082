import { PermissionMessages } from '../types';

export const permissionCA: PermissionMessages = {
  'app.permission.title': 'Permís – Informe Individual - Perfil',
  'app.permission.remove_permission_to_all': 'Eliminar el permís a tot',
  'app.permission.give_permission_to_all': 'Donar permís a tot',
  'app.permission.remove_permission': 'Eliminar permís',
  'app.permission.give_permission': 'Donar permís',
  'app.permission.table_title.permission': 'Permís',
  'app.permission.table_title.name': 'Nom',
  'app.permission.table_title.email': 'Adreça electrònica',
  'app.permission.button.update': 'Actualitzar',
  'app.permission.message': 'Usuaris actualitzats amb èxit',
  'app.permission.add_permissions_with_excel_file': 'Afegiu permisos amb un fitxer excel',
  'app.permission.add_permissions': 'Afegeix permisos',
  'app.permission.permissions': 'Permisos',
  'app.permission.download_tooltip': 'Descarrega la plantilla de fitxer'
}
