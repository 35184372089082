export function asLiterals<T extends string>(arr: T[]): T[] {
    return arr;
}

export function arrayToObject(arr: Array<string>) {
    return arr.reduce((result: any, item: string) => {
        result[item] = item;
        return result;
    }, {});
}
