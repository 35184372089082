import { useValues } from 'kea';
import React from 'react';

import { formLogic, FormsProps } from '../../redux/formsLogic';
import { FormDetails } from '../../services/listFormsService';
import { QuestionPageHeader } from './QuestionPageHeader';

export const QuestionPageHeaderContainer = (): JSX.Element => {
    const { formList, formId, formPercentageCompleted }: FormsProps = useValues(formLogic);

    const formDetails: FormDetails = formList.find((form: FormDetails) => form.id === formId);

    return (
        <QuestionPageHeader formListDetails={formDetails} percentageCompleted={formPercentageCompleted}/>
    );
}
