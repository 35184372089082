import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    getOrganizationProgramAndSubProgram, GetReportModel, OrganizationModel,
    OrganizationProgramAndSubProgramResponse, ReportFormsModel, Reports, saveReport,
    SaveReportRequest, SaveReportResponseBody, SearchFormsRequest,
    searchUserForms, searchUserFormsGroup, searchUserFormsLongitudinal, SearchUserFormsResponse, getReport, GetReportResponse, SearchFormsGroupRequest, searchUserFormsGroupLongitudinal
} from '../services/reportService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'clearReportData', 'loadOrganizations', 'searchForReportForms', 'searchForReportFormsGroup', 'generateReport',
    'setSelectedReportId', 'clearSelectedReport', 'clearSelectedReportId', 'loadSelectedReport',
]);
export type ReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const reportActionsConstants: ReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setOrganizations', 'setReportForms', 'setError', 'setReportResult', 'setSelectedReport'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading', 'searching', 'generating', 'keyErrorMessage', 'organizations',
    'reportForms', 'reportResult', 'selectedReportId', 'selectedReport',
]);
export type ReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const reportPropsConstants: ReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ReportActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchOrganizations: () => void;
        fetchReportForms: () => void;
        fetchReportFormsGroup: () => void;
        sendGeneratedReport: () => void;
        fetchSelectedReport: () => void;
    }
};

// Race type definitions.
type SearchForReportFormsRaceResponse = {
    searchForReportFormsResponse?: SearchUserFormsResponse;
}
type GetOrganizationsRaceResponse = {
    getOrganizationsResponse?: OrganizationProgramAndSubProgramResponse;
}
type GetSelectedReportRaceResponse = {
    getSelectedReportResponse?: GetReportResponse;
}

// Define payloads type.
type SearchForReportFormsPayload = { request: SearchFormsRequest, reportType: Reports; };
type SearchForReportFormsRequest = { payload: SearchForReportFormsPayload };

type SearchForReportFormsGroupPayload = { request: SearchFormsGroupRequest, reportType: Reports; };
type SearchForReportFormsGroupRequest = { payload: SearchForReportFormsGroupPayload };

type SelectedReportIdPayload = { reportId: number; };
type LoadSelectedReportRequest = { payload: SelectedReportIdPayload }

type GenerateReportPayload = { report: SaveReportRequest; };
type GenerateReportRequest = { payload: GenerateReportPayload };

type GetReportModelPayload = { report: GetReportModel; }
type OrganizationPayload = { organizations: OrganizationModel[]; };
type ReportFormsPayload = { reportForms: ReportFormsModel[]; };
type ErrorPayload = { errorKey: string; };

const SubProgramModelPropType = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    active: PropTypes.bool,
});

const ProgramModelPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
    subPrograms: PropTypes.arrayOf(SubProgramModelPropType),
});

const OrganizationModelPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
    programs: PropTypes.arrayOf(ProgramModelPropType),
});

const UserFormModelPropType = PropTypes.shape({
    formId: PropTypes.number,
    userName: PropTypes.string,
});

const SaveResultModelPropType = PropTypes.shape({
    reportId: PropTypes.number,
    reportType: PropTypes.number,
});

const ReportFormsModelPropType = PropTypes.shape({
    groupName: PropTypes.string,
    forms: PropTypes.arrayOf(UserFormModelPropType),
});

// persist forms reducer
export const reportKeaPath = 'report';

export const reportReducer = keaReducer(reportKeaPath);

export const reportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [reportKeaPath],

    actions: () => ({
        [reportActionsConstants.loadOrganizations]: true,
        [reportActionsConstants.clearReportData]: true,
        [reportActionsConstants.clearSelectedReport]: true,
        [reportActionsConstants.clearSelectedReportId]: true,
        [reportActionsConstants.setSelectedReportId]:
            (reportId: number): SelectedReportIdPayload => ({ reportId }),
        [reportActionsConstants.loadSelectedReport]:
            (reportId: number): SelectedReportIdPayload => ({ reportId }),
        [reportActionsConstants.generateReport]:
            (report: SaveReportRequest): GenerateReportPayload => ({ report }),
        [reportActionsConstants.searchForReportForms]:
            (request: SearchFormsRequest, reportType: Reports): SearchForReportFormsPayload => ({ request, reportType }),
        [reportActionsConstants.searchForReportFormsGroup]:
            (request: SearchFormsGroupRequest, reportType: Reports): SearchForReportFormsGroupPayload => ({ request, reportType }),
        [privateActionsConstants.setOrganizations]:
            (organizations: OrganizationModel[]): OrganizationPayload => ({ organizations }),
        [privateActionsConstants.setReportForms]:
            (reportForms: ReportFormsModel[]): ReportFormsPayload => ({ reportForms }),
        [privateActionsConstants.setReportResult]:
            (result: SaveReportResponseBody) => ({ result }),
        [privateActionsConstants.setSelectedReport]:
            (report: GetReportModel): GetReportModelPayload => ({ report }),
        [privateActionsConstants.setError]: (errorKey: string): ErrorPayload => ({ errorKey }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [reportPropsConstants.keyErrorMessage]: [null, PropTypes.string, {
            [actions.loadOrganizations]: () => null,
            [actions.generateReport]: () => null,
            [actions.searchForReportForms]: () => null,
            [actions.searchForReportFormsGroup]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null,
        }],
        // Load organization.
        [reportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.loadOrganizations]: () => true,
            [actions.loadSelectedReport]: () => true,
            [actions.setOrganizations]: () => false,
            [actions.setReportForms]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false,
        }],
        [reportPropsConstants.organizations]: [null, PropTypes.arrayOf(OrganizationModelPropType), {
            [actions.setOrganizations]:
                (_: OrganizationModel[], payload: OrganizationPayload) => payload.organizations,
            [actions.loadOrganizations]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null,
        }],
        // Search for report forms.
        [reportPropsConstants.searching]: [false, PropTypes.bool, {
            [actions.searchForReportForms]: () => true,
            [actions.searchForReportFormsGroup]: () => true,
            [actions.setReportForms]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false,
        }],
        [reportPropsConstants.reportForms]: [null, PropTypes.arrayOf(ReportFormsModelPropType), {
            [actions.setReportForms]:
                (_: ReportFormsModel[], payload: ReportFormsPayload) => payload.reportForms,
            [actions.searchForReportForms]: () => null,
            [actions.searchForReportFormsGroup]: () => null,
            [actions.setError]: () => null,
            [actions.clearReportData]: () => null,
            [logout]: () => null,
        }],
        [reportPropsConstants.reportResult]: [null, PropTypes.arrayOf(SaveResultModelPropType), {
            [actions.setReportResult]:
                (_: SaveReportResponseBody, payload: SaveReportResponseBody) => payload,
            [actions.searchForReportForms]: () => null,
            [actions.searchForReportFormsGroup]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null,
        }],
        // Set selected report id.
        [reportPropsConstants.selectedReportId]: [null, PropTypes.number, {
            [actions.setSelectedReportId]: (_: number, payload: SelectedReportIdPayload) => payload.reportId,
            [actions.setError]: () => null,
            [actions.clearSelectedReportId]: () => null,
            [logout]: () => null,
        }],
        // Load selected report.
        [reportPropsConstants.selectedReport]: [null, PropTypes.any, {
            [actions.setSelectedReport]: (_: GetReportModel, payload: GetReportModelPayload) => payload.report,
            [actions.clearSelectedReport]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null,
        }],
        // Generate report.
        [reportPropsConstants.generating]: [false, PropTypes.bool, {
            [actions.generateReport]: () => true,
            [actions.setError]: () => false,
            [actions.clearReportData]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadOrganizations]: workers.fetchOrganizations,
        [actions.searchForReportForms]: workers.fetchReportForms,
        [actions.searchForReportFormsGroup]: workers.fetchReportFormsGroup,
        [actions.generateReport]: workers.sendGeneratedReport,
        [actions.loadSelectedReport]: workers.fetchSelectedReport,
    }),

    workers: {
        * fetchOrganizations(): any {
            //@ts-ignore
            const { setOrganizations, setError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { getOrganizationsResponse }: GetOrganizationsRaceResponse = yield race({
                getOrganizationsResponse: call(getOrganizationProgramAndSubProgram, token),
                timeout: delay(requestTimeout)
            });

            if (getOrganizationsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (getOrganizationsResponse?.status === successfulCode) {
                if (getOrganizationsResponse?.body) {
                    yield put(setOrganizations(getOrganizationsResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (getOrganizationsResponse?.keyErrorMessage) {
                yield put(setError(getOrganizationsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchReportForms(action: SearchForReportFormsRequest): any {

            //@ts-ignore
            const { setReportForms, setError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { request, reportType } = action.payload;

            const { searchForReportFormsResponse }: SearchForReportFormsRaceResponse = yield race({
                searchForReportFormsResponse: (reportType === Reports.longitudinal_report) ?
                    call(searchUserFormsLongitudinal, token, request)
                    :
                    call(searchUserForms, token, request)
                ,
                timeout: delay(requestTimeout)
            });

            if (searchForReportFormsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (searchForReportFormsResponse?.status === successfulCode) {
                if (searchForReportFormsResponse?.body) {
                    yield put(setReportForms(searchForReportFormsResponse?.body));
                    return 'success';
                } else {
                    yield put(setError(generalExceptionKey));
                    return 'error';
                }
            } else if (searchForReportFormsResponse?.keyErrorMessage) {
                yield put(setError(searchForReportFormsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchReportFormsGroup(action: SearchForReportFormsGroupRequest): any {

            //@ts-ignore
            const { setReportForms, setError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { request, reportType } = action.payload;

            const { searchForReportFormsResponse }: SearchForReportFormsRaceResponse = yield race({
                searchForReportFormsResponse: (reportType === Reports.longitudinal_report) ?
                    call(searchUserFormsGroupLongitudinal, token, request)
                    :
                    call(searchUserFormsGroup, token, request)
                ,
                timeout: delay(requestTimeout)
            });

            if (searchForReportFormsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (searchForReportFormsResponse?.status === successfulCode) {
                if (searchForReportFormsResponse?.body) {
                    yield put(setReportForms(searchForReportFormsResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (searchForReportFormsResponse?.keyErrorMessage) {
                yield put(setError(searchForReportFormsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * sendGeneratedReport(action: GenerateReportRequest): any {
            // TODO(rodrigo.santos): Implement and add the request parameters.

            //@ts-ignore
            const { setReportResult, setError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { report } = action.payload;
            const generateReportFormsResponse = yield call(saveReport, token, report);

            if (generateReportFormsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (generateReportFormsResponse?.status === successfulCode) {
                if (generateReportFormsResponse?.body) {
                    yield put(setReportResult(generateReportFormsResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (generateReportFormsResponse?.keyErrorMessage) {
                yield put(setError(generateReportFormsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchSelectedReport(action: LoadSelectedReportRequest): any {

            //@ts-ignore
            const { setSelectedReport, setError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { reportId } = action.payload;
            const { getSelectedReportResponse }: GetSelectedReportRaceResponse = yield race({
                getSelectedReportResponse: call(getReport, token, reportId),
                timeout: delay(requestTimeout)
            });

            if (getSelectedReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (getSelectedReportResponse?.status === successfulCode) {
                if (getSelectedReportResponse?.body) {
                    yield put(setSelectedReport(getSelectedReportResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (getSelectedReportResponse?.keyErrorMessage) {
                yield put(setError(getSelectedReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
});
