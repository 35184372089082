import '../../style/individualReport.css';

import React from 'react';
import { Button, Image as Img } from 'react-bootstrap';

import logo_bevi from '../../assets/images/bevi_logo.png';
import { colors } from '../../config/colors';
import { AggregateProfile } from '../GroupReport/components/AggregateProfile';
import { ReportProps } from './ReportTypes';
import { DomainScores } from '../GroupReport/components/DomainScores';
import { LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';

export const ReportView = (props: ReportProps): JSX.Element => {
    const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.container}>
                    <div style={styles.alignCenter}>
                        <Img src={logo_bevi} className='report-logo' />
                    </div>
                    <hr />
                    <div style={styles.alignCenter}>
                        <h2 style={styles.title} className='report-h2'><u>{props.header.line1}</u></h2>
                        <h3 style={styles.title} className='report-h3'>{props.header.line2}</h3>
                        <h3 style={styles.title} className='report-h3'>{props.header.line3}</h3>
                        <h4 style={styles.title} className='report-h4'>{props.header.line4}</h4>
                    </div>
                    <div className='report-texts'>
                        {props.heading.map((item, index) =>
                            item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`heading-${index}`} />
                        )}
                    </div>

                    {!props.agreed && (
                        <div className="report-texts text-right">
                            <Button
                                className="btn px-4"
                                variant="success"
                                onClick={() => props.onAgreedButtonClicked()}
                            >
                                {props.agreeButtonText}
                            </Button>
                        </div>
                    )}

                    {props.agreed && (
                        <React.Fragment>
                            <SectionTitle message={props.title.introduction} />
                            <div className='report-texts'>
                                {props.introduction.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`introduction-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.foundation} />
                            <div className='report-texts'>
                                {props.foundation.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`foundation-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.tolerance_of_desequilibrium} />
                            <div className='report-texts'>
                                {props.toleranceOfDisequilibrium.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`toleranceOfDisequilibrium-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.making_sense} />
                            <div className='report-texts'>
                                {props.whyWeDo.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`whyWeDo-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.access_to_yourself} />
                            <div className='report-texts'>
                                {props.accessSelf.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessSelf-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.access_to_the_thoughts} />
                            <div className='report-texts'>
                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: props.accessToThoughtsAndFeelingsOfOthers }} />
                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: props.genderTradition }}></div>

                                {props.variables.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`variables-${index}`} />
                                )}
                            </div>

                            <SectionTitle message={props.title.access_to_the_larger_world} />
                            <div className='report-texts'>
                                {props.accessToTheLargerWorld.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessToTheLargerWorld-${index}`} />
                                )}
                            </div>

                            {
                                (props.backgroundStatistic !== undefined && isJapanese) &&
                                <React.Fragment>
                                    <SectionTitle message={props.title.domain_scores} />
                                    <DomainScores title={props.title.domain_scores} domainScores={props.backgroundStatistic} />
                                </React.Fragment>
                            }

                            {props.hasPermissionToProfile &&
                                <React.Fragment>
                                    <SectionTitle message={props.title.profile} />
                                    <AggregateProfile title={props.title.profile} aggregateProfile={props.profile} />
                                </React.Fragment>
                            }

                            <SectionTitle message={props.title.conclusion} />
                            <div className='report-texts'>
                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: props.conclusion }}></div>
                            </div>

                            <SectionTitle message={props.title.thankYou} />
                            <div className='report-texts'>
                                {props.thankYou.map((item, index) =>
                                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`thankYou-${index}`} />
                                )}
                            </div>

                            {
                                props.demographicsSection !== undefined &&
                                <React.Fragment>
                                    <SectionTitle message={'Demographics - Accessible Only By Super Administrators Or Their Authorized Designees.'} />

                                    <div className='report-texts' style={styles.questionRowOddFirstLine}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.age.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.age.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.gender.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.gender.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.yearsOfFormalEducation.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.yearsOfFormalEducation.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.relationshipStatus.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.relationshipStatus.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.ethnicBackground.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.ethnicBackground.value }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.ethnicBackground.other }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.motherCountry.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.motherCountry.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.fatherCountry.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.fatherCountry.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.minorityGroup.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.minorityGroup.value }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.minorityGroup.other }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.primaryCountry.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.primaryCountry.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.timeSpentInAnotherCountry.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.timeSpentInAnotherCountry.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.visitedCountries.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.visitedCountries.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.politicalOrientation.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.politicalOrientation.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.levelPoliticalOrientation.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.levelPoliticalOrientation.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.motherEducation.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.motherEducation.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.fatherEducation.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.fatherEducation.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.primaryAreaOfWork.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.primaryAreaOfWork.value }}></div>
                                        {
                                            props.demographicsSection.primaryAreaOfWork.others.map((item, index) => {
                                                return <div key={`PrimaryAreaOfWork-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.primaryLanguage.title }}></div>
                                        {
                                            props.demographicsSection.primaryLanguage.others.map((item, index) => {
                                                return <div key={`PrimaryLanguage-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.otherFluentLanguages.title }}></div>
                                        {
                                            props.demographicsSection.otherFluentLanguages.others.map((item, index) => {
                                                return <div key={`OtherPrimaryLanguages-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.otherLanguages.title }}></div>
                                        {
                                            props.demographicsSection.otherLanguages.others.map((item, index) => {
                                                return <div key={`OtherLanguages-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.yearOfForeignLanguages.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.yearOfForeignLanguages.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.income.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.income.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.academicRank.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.academicRank.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.commitmentsReligion.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.commitmentsReligion.value }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.commitmentsReligion.other }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.levelCommitmentsReligion.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.levelCommitmentsReligion.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.personalInterest.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.personalInterest.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.currentlyParticipating.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.currentlyParticipating.value }}></div>
                                    </div>

                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.previouslyCompletedExperiences.title }}></div>
                                        {
                                            props.demographicsSection.previouslyCompletedExperiences.value.map((item, index) => {
                                                return <div key={`PreviouslyCompletedExperiences-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.completedExperiences.title }}></div>
                                        {
                                            props.demographicsSection.completedExperiences.value.map((item, index) => {
                                                return <div key={`CompletedExperiences-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.previouslyCompletedLearningCoursesPrograms.title }}></div>
                                        {
                                            props.demographicsSection.previouslyCompletedLearningCoursesPrograms.value.map((item, index) => {
                                                return <div key={`PreviouslyCompletedLearningCoursesPrograms-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.demographicsSection.completedLearningCoursesPrograms.title }}></div>
                                        {
                                            props.demographicsSection.completedLearningCoursesPrograms.value.map((item, index) => {
                                                return <div key={`CompletedLearningCoursesPrograms-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {
                                props.scalesStronglyAgree !== undefined &&
                                <React.Fragment>
                                    <SectionTitle message={'Question Strongly Agree - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        {
                                            props.scalesStronglyAgree && props.scalesStronglyAgree.map((item, index) => {
                                                return <div key={`ScalesStronglyAgree-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {
                                props.scalesStronglyDisagree !== undefined &&
                                <React.Fragment>
                                    <SectionTitle message={'Question Strongly Disagree - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        {
                                            props.scalesStronglyDisagree && props.scalesStronglyDisagree.map((item, index) => {
                                                return <div key={`ScalesStronglyAgree-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {
                                props.showExtraData === true &&
                                <React.Fragment>
                                    <SectionTitle message={'Discursive Questions - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                                    <div className='report-texts' style={styles.questionRowOddFirstLine}>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer1.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer1.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowEven}>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer2.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer2.value }}></div>
                                    </div>
                                    <div className='report-texts' style={styles.questionRowOdd}>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer3.title }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.discusiveAnswer3.value }}></div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

type TitleProps = {
    message: string;
}

const SectionTitle = (props: TitleProps): JSX.Element => (
    <div style={styles.paddingTop10}>
        <hr />
        <div className='report-texts'>
            <h4 style={styles.title} className='report-h4'>{props.message}</h4>
        </div>
    </div>
)

const styles = {
    container: {
        padding: 5
    } as React.CSSProperties,
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        marginBottom: '10%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center',
    } as React.CSSProperties,
    title: {
        color: colors.darkSlateBlue,
    } as React.CSSProperties,
    paddingTop10: {
        paddingTop: 10
    } as React.CSSProperties,
    questionRowOdd: {
        padding: '16px',
        backgroundColor: '#cecece50',
        borderRadius: '16px',
    } as React.CSSProperties,
    questionRowOddFirstLine: {
        padding: '16px',
        backgroundColor: '#cecece50',
        borderRadius: '16px',
        marginTop: '10px'
    } as React.CSSProperties,
    questionRowEven: {
        padding: '16px',
        backgroundColor: colors.white,
        borderRadius: '16px',
    } as React.CSSProperties,
}
