import React from 'react';
import { Button } from 'react-bootstrap';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    acceptedFiles: File[];
    getRootProps(props?: DropzoneRootProps): DropzoneRootProps;
    getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
    onClearSelectedFile: () => void;
}

export const ExcelDropzone = ({ acceptedFiles, getRootProps, getInputProps, onClearSelectedFile }: Props): JSX.Element => {

  return (
        <div style={styles.dropzone} {...getRootProps()}>
            { acceptedFiles.length === 0 ?
                <React.Fragment>
                    <input {...getInputProps()} />
                    <p>{GetFormattedMessage('app.create.user.drag.n.drop.text')}</p>
                    <em>{GetFormattedMessage('app.create.user.dran.n.drop.available.files')}</em>
                </React.Fragment>
                :
                <React.Fragment>
                    <p><b>{GetFormattedMessage('app.create.user.selected.file')}{acceptedFiles[0].name}</b></p>
                    <Button onClick={() => onClearSelectedFile()} variant='danger'>
                        {GetFormattedMessage('app.create.user.remove')}
                    </Button>
                </React.Fragment>
            }
        </div>
  );
}

const styles = {
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: colors.gray,
        borderStyle: 'dashed',
        backgroundColor: colors.lightGray,
        color: colors.strongGray,
        outline: 'none',
        transition: 'border .24s ease-in-out',
    } as React.CSSProperties,
}