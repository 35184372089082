import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { DatePicker } from 'antd';
import { SelectItem, FormDropdown } from '../../../components/FormDropdown';
import { Dayjs } from 'dayjs';
import { IsSuperAdmin } from '../../../utils/userHelper';
import { TokenInfo } from '../../../services/loginService';

type Props = {
    userInfo: TokenInfo;
    selectOrganization?: any,
    selectDate?: Dayjs | null,
    setSelectOrganizationId: (value?: number) => void,
    setSelectDate: (value: Dayjs | null) => void,
    organizations?: SelectItem[];
    loadingOrganization: boolean;
    onSearch: () => void;
    setShowModal: (value: boolean) => void;
}

export const FilterVirtualAccount = ({ organizations, setSelectOrganizationId, setSelectDate, onSearch, loadingOrganization, selectOrganization, selectDate,
    setShowModal, userInfo }: Props): JSX.Element => {
    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {GetFormattedMessage('app.virtual_account.title')}
                    </h2>
                </div>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        <Col md={4}>
                            <DatePicker style={styles.date} value={selectDate} onChange={(value) => setSelectDate(value)} picker="month" />
                        </Col>
                        <Col md={4}>
                            <FormDropdown
                                value={organizations?.find(x => x.value === selectOrganization)}
                                isLoading={loadingOrganization}
                                isSearchable={true}
                                defaultOptionId={'app.listorganizations.select_organization'}
                                onValueSelect={(value) => {
                                    setSelectOrganizationId(value)
                                }}
                                optionItens={organizations ?? []}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={styles.buttonSearchDiv}>
                    {IsSuperAdmin(userInfo) && <ButtonWithIcon
                        disabled={selectOrganization == null}
                        textId={'app.virtual_account.add_interactions'}
                        onClick={() => setShowModal(true)}
                        icon={<FaPlus />}
                    />}
                    <ButtonWithIcon
                        disabled={selectOrganization == null || selectDate == null}
                        textId={'app.listorganizations.search'}
                        onClick={() => onSearch()}
                        icon={<FaSearch />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'right',
        paddingRight: '20px',
        paddingLeft: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
    date: {
        width: '100%',
        height: '38px'
    } as React.CSSProperties,
};
