import React, { useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

type Props = FormControlProps & {
    initialValue?: number;
    value?: string;
    maxValue?: number;
    onValueChange?: (value: number | undefined) => void;
};

export const FormNumericInput = ({ value, maxValue, onValueChange, initialValue, ...formProps }: Props): JSX.Element => {
    const [internalValue, setInternalValue] = useState<string>(initialValue ? initialValue.toString() : '');

    return (
        <Form.Control
            {...formProps}
            as='input'
            value={value || internalValue}
            onChange={(e) => {
                const numberText: string = e.currentTarget.value.replace(/\D/,'')

                if (internalValue !== numberText) {
                    if (numberText.length === 0) {
                        setInternalValue(numberText);
                        onValueChange && onValueChange(undefined);
                    }
                    const currentNumber = Number(numberText);
                    if (!maxValue || currentNumber <= maxValue) {
                        setInternalValue(numberText);
                        onValueChange && onValueChange(currentNumber);
                    }
                }
            }}
        />
    );
}
