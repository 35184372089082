import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { defaultNotificationDurationInSeconds, longNotificationDurationInSeconds } from '../../config/constants';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { DASHBOARD_PAGE, LOGIN_PAGE } from '../../router/pages';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ValidateTokenInfo } from '../../utils/validateHelper';
import { Invitation } from './Invitation';

type PageProps = {
    token: string
}

type TokenParameters = {
    institution_name: string;
    program_name: string;
    sub_program_name: string;
    sub_program_id: number;
}

type Props = RouteComponentProps;

export const InvitationContainer = withRouter((props: Props): JSX.Element => {
    const { token: invitationToken } = useParams<PageProps>();

    const { registerThroughAnInvitation }: AppActions = useActions(appLogic);
    const { tokenInfo, loading, hasSystemError }: AppProps = useValues(appLogic);

    const [tokenProps, setTokenProps] = useState<TokenParameters>();

    const successNotificationHeader = GetFormattedMessage('app.invitation.success_notification_header');
    const successNotificationBody = GetFormattedMessage('app.invitation.success_notification_body');
    const errorNotificationHeader = GetFormattedMessage('app.invitation.error_notification_header');
    const errorNotificationBody = GetFormattedMessage('app.invitation.error_notification_body');

    // invitationToken decode handling effect.
    useEffect(() => {
        const decoded = DecodeToken(invitationToken);
        if (decoded === undefined) {
            props.history.replace(LOGIN_PAGE);
        } else {
            setTokenProps(decoded);
        }
    }, [invitationToken, props.history, setTokenProps])

    // Error handling effect.
    useEffect(() => {
        if (tokenProps && !loading && (hasSystemError || (!!tokenInfo && !ValidateTokenInfo(tokenInfo)))) {
            notification['error']({
                message: errorNotificationHeader,
                description: errorNotificationBody,
                duration: defaultNotificationDurationInSeconds,
            });
        }
        // eslint-disable-next-line
    }, [loading, hasSystemError, tokenInfo, tokenProps]);

    // Successful handling effect.
    useEffect(() => {
        if (!!tokenProps && !!tokenInfo && ValidateTokenInfo(tokenInfo)) {
            notification['success']({
                message: successNotificationHeader,
                description: successNotificationBody,
                duration: longNotificationDurationInSeconds,
            });
            props.history.replace(DASHBOARD_PAGE);
        }
        // eslint-disable-next-line
    }, [tokenInfo, tokenProps]);

    const handleSubmitButtonClick = (name: string, email: string, password: string, language: number) => {
        registerThroughAnInvitation(name, email, password, tokenProps?.sub_program_id, language);
    }

    return (
        <Invitation
            institutionName={tokenProps?.institution_name ?? ''}
            programName={tokenProps?.program_name ?? ''}
            subProgramName={tokenProps?.sub_program_name ?? ''}
            loading={loading}
            onSubmitButtonClicked={handleSubmitButtonClick}
        />
    );
});

function DecodeToken(token?: string): TokenParameters | undefined {
    if (token) {
        try {
            return jwt_decode<TokenParameters>(token, { header: false });
        } catch {
            console.log('Cannot decode token');
        }
    }
    return undefined;
}
