import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'Sie und Ihre Weltansicht',
    'app.individualreport.a_personal_report_from': 'Ein persönlicher Bericht vom',
    'app.individualreport.bevi_description': 'Beliefs, Events, and Values Inventory (Überzeugungen, Erfahrungen und Werte Selbstberichtsinventar), kurz BEVI',
    'app.individualreport.user': 'User',
    'app.individualreport.date_of_test': 'Datum der Fertigstellung',
    'app.individualreport.static_text_1': 'Wenn Sie irgendwo auf der Welt eine Zeitung in die Hand nehmen oder ein Radio oder einen Fernseher einschalten, ' +
        'wird Ihnen eine Tatsache sofort klar: ' +
        'Überzeugungen und Werte sind ein wesentlicher Bestandteil der menschlichen Erfahrungswelt. Die Menschen haben starke Meinungen zu Themen wie  ' +
        'Politik, Religion, Bildung und Kunst bis hin zu Ehe, Familie, Geschlecht und Sexualität.  ' +
        'Doch ob eine bestimmte Überzeugung "richtig" oder "falsch" ist, ist wohl weniger wichtig als ' +
        'das komplexe Zusammenspiel von Gedanken, Gefühlen, Verhalten, Lebensgeschichte, ' +
        'und dem Kontext, der für jeden Menschen zu einer einzigartigen Version der Realität führt. ' +
        'Ein solches Verständnis ist wichtig, weil Überzeugungen und Werte die Handlungen, ' +
        'Politik und Praxis von Einzelpersonen, Gruppen, Organisationen, Regierungen und Gesellschaften auf der ganzen Welt beeinflussen. ' +
        'Das Beliefs, Events, and Values Inventory (Überzeugungen, Erfahrungen und Werte Inventar), kurz BEVI, bietet uns die Möglichkeit, diese komplexen ' +
        'Fragen auf individueller und Gruppenebene zu erforschen, indem es jedem von uns hilft, zu verstehen, ' +
        'warum wir bestimmte Werte und Überzeugungen haben. ',
    'app.individualreport.static_text_2': 'Dieser Bericht soll Ihnen helfen, einen genaueren Blick darauf zu werfen, was Sie ' +
        'zu glauben scheinen und was Ihnen wichtig ist, aber auch, warum Sie glauben, was Sie glauben. ' +
        'Zugegeben, diese "Was"- und "Warum"-Fragen sind in jeder Hinsicht schwer zu beantworten. ' +
        'Außerdem sollte betont werden, dass sich die Welt ständig verändert. ' +
        'Was heute als "wahr" empfunden wird, wurde vor nicht allzu langer Zeit vielleicht als "falsch" angesehen. ' +
        'Ebenso kann das, was heute als "schlecht" angesehen wird, in den nächsten Jahren als "gut" angesehen werden. ' +
        'Daher ist es sehr wichtig, dass Sie die folgenden Ansichten einfach als Themen ansehen, ' +
        'über die Sie an diesem Punkt in Ihrem Leben nachdenken, und nicht als endgültige Entscheidung für sich ' +
        'Als menschliche Wesen sind wir alle veränderlich, einzigartig und komplex.  Kein Maßstab kann all das erfassen, ' +
        'was Sie gewesen sind, was Sie jetzt sind und was Sie noch werden; und das BEVI versucht auch nicht, dies zu tun. ' +
        'Stattdessen soll dieses Instrument Ihnen helfen, über sich selbst, andere und die Welt im Allgemeinen ' +
        'in einer Reihe von Bereichen nachzudenken, die für die Erfahrung des Menschseins oft zentral sind.',
    'app.individualreport.static_text_3': 'Ein letzter Punkt sollte hervorgehoben werden. Obwohl sich dieser Bericht auf das  ' +
        'Wesen und die Auswirkungen von Überzeugungen und Werten auf das eigene Leben, die Arbeit und die Beziehungen konzentriert und versucht, ' +
        'ein besseres Verständnis für sich selbst, andere und die Welt zu schaffen, nimmt das BEVI keine Stellung dazu, ob eine Gruppe von ' +
        'Überzeugungen und Werten "richtig", "falsch", "besser" oder "schlechter" ist als eine andere Gruppe von Überzeugungen und Werten.',
    'app.individualreport.static_text_4': 'Vor diesem Hintergrund sollten wir uns nun Ihren Bericht ansehen. ' +
        'Zusätzlich zu den Erläuterungen zu den Überzeugungen und Werten ' +
        'sind Ihre Antworten auf die Fragen des BEVI im Folgenden fett gedruckt.',
    'app.individualreport.static_text_5': 'Die Erfahrungen, die wir im Heranwachsendenalter und im Laufe unseres Lebens machen, beeinflussen oft, ' +
        'wie wir uns selbst, andere und die Welt im Allgemeinen wahrnehmen. Menschen, die viele positive Ereignisse in ihrem ' +
        'Leben erlebt haben und das Gefühl haben, dass ihre Grundbedürfnisse die meiste Zeit über erfüllt wurden, haben zum Beispiel oft das Gefühl, dass andere Menschen ' +
        'grundsätzlich vertrauenswürdig sind und dass man vom Leben Gutes erwarten kann. Manchmal - aber keineswegs immer - wenn ' +
        'Menschen viele negative Lebensereignisse erleben (z. B. Konflikte mit ihren Bezugspersonen oder in ihrem Zuhause) oder das Gefühl haben, ' +
        'dass ihre Grundbedürfnisse nicht ausreichend befriedigt wurden (z. B. nach Liebe oder Fürsorge), kann ihre gesamte Art, ' +
        'die Welt zu sehen und andere zu erleben (z. B. in der Schule, bei der Arbeit und in Beziehungen), beeinflusst werden. Das liegt vor allem daran, dass sie ' +
        '"die Welt" nicht immer als einen angenehmen Ort erlebt haben, vor allem, wenn diese Erfahrungen ' +
        'sehr früh oder über einen langen Zeitraum hinweg gemacht wurden oder besonders schwierig oder traumatisch waren. ' +
        'Und tatsächlich kann es für Menschen, die viele negative Lebensereignisse erlebt haben oder deren Grundbedürfnissen nicht ausreichen befriedigt wurden ' +
        'hilfreich sein, mit jemandem zu sprechen, die/der sich mit diesen Themen auskennt.  ' +
        'Aber es ist auch sehr wichtig zu erkennen, dass das Leben für uns alle mehr oder weniger herausfordernd ist,  ' +
        'und selbst wenn wir viele Schwierigkeiten erleben, sind die meisten Menschen in der Lage, mit solchen Widrigkeiten umzugehen und sinnvolle ' +
        'Beziehungen in der Schule, im Beruf und im Leben zu führen, manchmal mit erheblicher Hilfe von anderen (z. B. Partner:inen, Freunden, Lehrer:innen,  ' +
        'Arbeitgeber:innen, geistlichen Führer:innen, Mentor:innen, Berater:innen).  In jedem Fall kann es hilfreich sein, sich daran zu erinnern, ' +
        'dass unterschiedliche Lebenserfahrungen und Hintergründe Einfluss darauf haben, wie wir uns selbst, andere und die Welt sehen, ' +
        'wenn wir die Überzeugungen und Werte anderer nicht verstehen. Eine solche Erkenntnis kann uns helfen, besser zu verstehen, wenn nicht sogar nachzuempfinden, warum wir und  ' +
        'andere in verschiedenen Situationen und Kontexten denken, fühlen und tun, was wir tun.',
    'app.individualreport.static_text_6': 'Wenn Sie eher der "wissbegierige" Typ dinf, kann es hilfreich sein, daran zu denken, dass ' +
        'andere Menschen um Sie herum - selbst die "sehr selbstsicheren" Typen - in Bezug auf Neues oder Unbekanntes vielleicht nicht so sicher sind, ' +
        'wie sie zu sein scheinen, und vielleicht nicht besser wissen als Sie, was das "Richtige" ist. ' +
        'Ein vernünftiges Selbstvertrauen ist großartig, wenn Sie in einer Situation sind, in der Sie guten Grund zu der Annahme haben, dass Sie wissen, was passiert.  ' +
        'Wenn Sie sich jedoch in einer Situation oder an einem Ort befinden, den Sie noch nie zuvor gesehen haben, kann es hilfreich sein, generell eine neugierige  ' +
        'und offene Haltung einzunehmen und besonders auf die Themen, Fragen, Praktiken und Prozesse zu achten, ' +
        'die Ihnen am interessantesten erscheinen. Solche Informationen über sich selbst können Ihnen helfen, besser zu verstehen, wer Sie sind, wer Sie werden und wer Sie sein möchten. ',
    'app.individualreport.static_text_7': 'Wenn wir neuen Menschen oder Situationen begegnen und uns im Laufe der Zeit verändern, ' +
        'ist es ebenso wichtig, dass wir uns bemühen, "zu wissen, was wir nicht wissen", damit wir die Grenzen unseres eigenen Wissens einschätzen und zugeben können, ' +
        'dass wir vielleicht nicht "die richtigen Antworten" haben oder mehr über ein bestimmtes Thema lernen müssen, bevor wir uns eine Meinung bilden. ' +
        'Kurz gesagt wird das Maß, in dem wir in der Lage sind, zu verstehen, warum wir Menschen tun, was wir tun, oft mit der Fähigkeit assoziiert, ' +
        'mit mehr Verständnis, Weisheit und Mitgefühl zu denken, zu fühlen und zu handeln. ',
    'app.individualreport.static_text_8': 'Im Zusammenhang mit den oben genannten Erkenntnissen und Beobachtungen lohnt es sich, ' +
        'allgemein über die Rolle von "Bedürfnissen" und "Gefühlen" in unserem täglichen Leben nachzudenken. Ihnen wird zum Beispiel auffallen, dass wir uns oft nach Erfahrungen sehnen,  ' +
        'bei denen wir "tief fühlen" (z. B. durch Beziehungen, Musik, Theater, Lesen, Reisen, Aufenthalt in der Natur usw.).  ' +
        'Weil unsere Emotionen so viel über unser "wahres Ich" verraten, fällt es uns oft schwer zu fühlen, was wir fühlen, geschweige denn, ' +
        'diese Gefühle laut auszusprechen, denn das kann viel darüber aussagen, wer wir wirklich sind. ' +
        'Und doch ist es für den Aufbau von Beziehungen, die Befriedigung von Bedürfnissen und die Verfolgung von Arbeits- und Lebenszielen entscheidend, andere wissen zu lassen,' +
        ' "wer wir sind".  Auch wenn es nicht ratsam ist, jede Emotion oder jedes Bedürfnis zu zeigen, das wir haben, ' +
        'kann es für uns und andere zu echten Schwierigkeiten führen, wenn wir sie nicht erleben oder ausdrücken können.',
    'app.individualreport.static_text_9_1': 'Kurz gesagt, die Unfähigkeit oder der Unwille, Gefühle zu empfinden und Bedürfnisse anzuerkennen, ' +
        'kann aus mindestens zwei Gründen ein Problem für uns und andere sein: 1) Wir verlieren wichtige Informationen darüber, was wirklich in uns "vorgeht", ' +
        'was unsere Entscheidungsfindung und unser allgemeines Wohlbefinden weiter beeinträchtigen kann; und 2) andere können es schwierig finden, eine Beziehung zu uns aufzubauen oder zu wissen, wer wir wirklich sind. ' +
        'Glücklicherweise kommen wir alle, ob wir es nun wahrhaben wollen oder nicht, mit Gefühlen und Bedürfnissen auf die Welt, was vielleicht die grundlegendste ' +
        'Art und Weise ist, wie wir mit anderen Wesen kommunizieren, noch bevor wir unsere ersten Worte sprechen.  ' +
        'Im Laufe des Lebens haben wir die Chance, die ganze Bandbreite an Bedürfnissen und Gefühlen in uns selbst und in anderen kennenzulernen und dabei zutiefst menschlich zu werden.',
    'app.individualreport.static_text_9_2': '',
    'app.individualreport.static_text_9_3': '',
    'app.individualreport.static_text_9_4': '',
    'app.individualreport.static_text_10_1': 'Schließlich können unser Hintergrund und unsere Erfahrungen nicht nur beeinflussen, wie wir uns selbst und andere sehen,  ' +
        'sondern auch, wie wir die Welt als "großes Ganzes" sehen und wie sie unserer Meinung nach ist oder sein sollte. Wir werden uns hier auf zwei globale Themen konzentrieren,  ' +
        'die für uns alle von Bedeutung sind: 1) unsere Haltung gegenüber der Umwelt und der Natur und 2) das Ausmaß, in dem wir geneigt sind, ' +
        'uns global zu engagieren.',
    'app.individualreport.static_text_10_2': '',
    'app.individualreport.static_text_10_3': '',
    'app.individualreport.static_text_11': 'Am Ende dieses Berichts ist es wichtig, sich daran zu erinnern, dass die obigen Aussagen für die Situation gelten, ' +
        'in der Sie sich gerade befinden; wir alle verändern uns im Laufe unseres Lebens, und auch Ihre aktuellen Überzeugungen und Werte können sich mit der Zeit ändern. ' +
        'Letztendlich ist es gut, den Mut und die Fähigkeit zu fördern, so intensiv wie möglich darüber nachdenken zu können, was wir glauben ' +
        'und schätzen, um sicherstellen zu können, dass die Entscheidungen, die wir in unserem eigenen Leben treffen, diejenigen sind, die wir verstehen,  ' +
        'verteidigen können und annehmen wollen. Wir hoffen, dass die Informationen und Perspektiven in diesem Bericht hilfreich und relevant  ' +
        'für Ihr eigenes Leben sind, wenn Sie darüber nachdenken, wer Sie jetzt sind, wer Sie waren und wer Sie sein wollen.',
    'app.individualreport.heading_text_1_1': 'Das BEVI soll Menschen helfen, ' +
        'sich ihrer eigenen Überzeugungen und Werte bewusst zu werden. Das BEVI untersucht auch, wie Überzeugungen und Werte das Lernen, ' +
        'die persönliche Entwicklung, Beziehungen, Gruppen- oder Organisationsprozesse und das Verfolgen von Lebenszielen beeinflussen können. ' +
        'Wenn Sie sich mit diesen Fragen beschäftigen, enthält dieser Bericht Informationen darüber, wie Sie sich selbst, andere und die Welt sehen. ' +
        'Da das BEVI jedoch keine Stellung dazu bezieht, ob die von Ihnen gegebenen Antworten richtig, falsch, wahr oder unwahr sind, ' +
        'werden in diesem Bericht auch keine solchen Behauptungen aufgestellt. Stattdessen soll dieser Bericht zum Nachdenken über Fragen des Lebens anregen,  ' +
        'die oft für uns alle relevant sind. Wenn Sie Fragen zum BEVI haben, wende Sie sich bitte an die/den BEVI-Administrator:in in Ihrer Organisation oder Institution. ' +
        'Sie können uns auch direkt unter info@ibavi.org kontaktieren. ' +
        'Bevor Sie den folgenden Bericht lesen, müssen Sie den drei folgenden Bedingungen zustimmen, indem Sie entweder auf die Schaltfläche "Zustimmen" klicken oder  ' +
        'ein separates Zustimmungsformular unterschreiben, das Sie vor dem Lesen des Berichts erhalten:',
    'app.individualreport.heading_text_1_2': '',
    'app.individualreport.heading_text_1_3': '',
    'app.individualreport.heading_text_2': '1. Sie ({UserName}) haben das BEVI freiwillig ausgefüllt und anschließend diesen Folgebericht angefordert, ' +
        'den Sie freiwillig lesen (d.h. Sie stimmen zu, dass keine Person, Gruppe, Organisation oder Institution von Ihnen verlangt, diesen Bericht zu lesen).',
    'app.individualreport.heading_text_3': '2. Wir haben uns bemüht, die Informationen des BEVI so zu vermitteln, dass sie hilfreich ' +
        'und konstruktiv sind. Sollte Ihnen jedoch der Bericht beim Lesen Sorge bereiten, erklären Sie sich bereit, die/den BEVI-Administrator:in in Ihrer  ' +
        'Organisation, die/den Koordinator:in einer BEVI-Feedback-Sitzung und/oder eine/n Dienstleister:in für die physische/psychische Gesundheit zu kontaktieren.',
    'app.individualreport.heading_text_4': '3. Es steht Ihnen frei, eine Kopie dieses Berichts für sich zu behalten. ' +
        'Da das BEVI und dieser Bericht jedoch urheberrechtlich geschützt sind, dürfen Sie diesen Bericht nicht fotokopieren oder  ' +
        '(elektronisch oder auf andere Weise) an andere Personen weitergeben.',
    'app.individualreport.thankyou_static_1_1': 'Beliefs, Events, and Values Inventory (Überzeugungen, Erfahrungen und Werte Inventar), kurz BEVI und dieser BEVI-Bericht sind urheberrechtlich geschützt. ' +
        'Das BEVI und die BEVI-Berichte, Items, Item-Inhalte oder Skalen dürfen ohne die schriftliche und ausdrückliche Genehmigung von Craig N. Shealy, Ph.D., weder ganz ' +
        'noch teilweise verändert, kopiert, verbreitet oder veröffentlicht werden. ',
    'app.individualreport.thankyou_static_1_2': '',
    'app.individualreport.thankyou_static_2': 'Wie das BEVI als Ganzes werden auch die Struktur und das Design dieser Berichte  ' +
        'ständig überprüft und weiterentwickelt. Wenn Sie Vorschläge oder Kommentare zu irgendeinem Aspekt des BEVI oder dieses ' +
        'Berichts haben, können Sie sich gerne direkt an uns wenden: info@ibavi.org.',
    'app.individualreport.thankyou_static_3': 'Wenn Sie mehr über Überzeugungen und Werte erfahren möchten, besuchen Sie das International Beliefs ' +
        'and Values Institute (IBAVI) unter www.ibavi.org und www.thebevi.com.',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': 'Wir alle unterscheiden uns ein wenig und manchmal auch sehr in dem, was wir glauben und schätzen.  ' +
        'Warum ist das so?  Obwohl es viele Möglichkeiten gibt, diese Frage zu beantworten, basiert das BEVI auf der grundlegenden Erkenntnis,  ' +
        'dass die Orte, Kulturen und Kontexte, in denen wir aufwachsen, sowie die positiven und negativen Lebenserfahrungen, die wir  ' +
        'im Heranwachsendenalter und im Laufe unseres Lebens machen, einen großen Einfluss darauf haben, wie wir die Welt sehen.',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': 'Aus der Sicht des BEVI ist die Art und Weise, wie wir über die Welt denken ' +
        'und fühlen (z.B. Politik und Religion), das Ergebnis einer ganzen Reihe von Faktoren, einschließlich, aber ' +
        'keineswegs beschränkt auf die eigene Familie, Erziehung, Kultur und den Kontext. Um zu verstehen, was Menschen ' +
        'glauben und schätzen, ist es daher hilfreich, einige der positiven und negativen Lebenserfahrungen zu kennen, die Menschen gemacht haben ' +
        'und zu wissen, inwieweit ihre Grundbedürfnisse erfüllt wurden. <b>In Ihrem Fall berichten Sie,  ' +
        'dass Sie in Ihrem Leben {NegativeLifeEvents} verschiedene negative Erfahrungen gemacht haben. ' +
        'Sie berichten auch, dass sich Ihre eigenen Kernbedürfnisse in der Kindheit und Jugend {NeedsClosure} befanden.</b>'
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': 'Unser Hintergrund und unsere Erfahrungen wirken sich auch auf andere Weise auf uns aus, ' +
        'indem sie das Ausmaß prägen, in dem wir fähig und bereit sind, mit Menschen und Ereignissen umzugehen, die ganz anders sind, ' +
        'als wir es gewohnt sind. Aus Sicht des BEVI gibt es in dieser Hinsicht zwei Arten von Menschen.  Der erste Typ - nennen wir ihn ' +
        '"selbstbewusst" - ist sich seiner selbst sicher und weiß, wer andere sind, wie die Welt funktioniert und welche ' +
        'Erfahrungen sie im Leben, bei der Arbeit und in Beziehungen machen.  Der zweite Typ - nennen wir ihn "neugierig" - ist sich über solche Dinge vielleicht weniger im Klaren, ' +
        'verspürt manchmal Zweifel und Reue und ist verwirrt darüber, wie er auf Situationen reagieren kann, ' +
        'die anders oder ungewohnt sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie eher selbstbewusst als neugierig sind.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': 'Natürlich hat eine selbstbewusste oder neugierige Herangehensweise Vor- und Nachteile,' +
        'und jeder von uns kann sich von Zeit zu Zeit ein mal so und ein mal so fühlen, abhängig von den jeweiligen  ' +
        'Umständen oder Situationen.  Wenn Sie der "selbstbewusste" Typ sind, kann es hilfreich sein, sich die Situationen bewusst zu machen, ' +
        'in denen Sie nicht unbedingt wissen, was Sie tun, denken oder fühlen sollen, vor allem, wenn Sie etwas erleben, ' +
        'das anders ist, als Sie es gewohnt sind. <b>In diesem Zusammenhang scheinen Sie auch einen sehr starken ' +
        'Willen und ein hohes Verantwortungsbewusstsein zu haben, glauben, dass die Welt das ist, was Sie aus ihr machen, und lehnen negatives Denken ' +
        'generell ab.  Eine solche Haltung kann Ihnen helfen, viele Dinge in Ihrem Leben zu erreichen, da Sie sich nicht so leicht von Ihren  ' +
        'Zielen abbringen lassen. Diese Einstellung kann es Ihnen auch manchmal schwer machen, sich in andere einzufühlen, die vielleicht nicht ' +
        'so zielstrebig und optimistisch sind.  Es ist wichtig, sich daran zu erinnern, dass Menschen unterschiedliche Hintergründe und ' +
        'Erfahrungen haben und dass das, was Sie bei ihnen als übermäßige "Bedürftigkeit" oder "Schwäche" empfinden, vielleicht eher ' +
        'die wirklichen Schwierigkeiten und Hindernisse widerspiegelt, die sie in ihrem Leben erlebt haben und die sie mit allem positiven Denken der  ' +
        'Welt nicht überwinden können, zumindest noch nicht.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': '',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': 'Unser Hintergrund und unsere Erfahrungen wirken sich auch auf andere Weise auf uns aus, ' +
        'indem sie das Ausmaß prägen, in dem wir fähig und bereit sind, mit Menschen und Ereignissen umzugehen, die ganz anders sind,  ' +
        'als wir es gewohnt sind.  Aus Sicht des BEVI gibt es in dieser Hinsicht zwei Arten von Menschen.  Der erste Typ - nennen wir ihn  ' +
        '"selbstbewusst" - ist sich seiner selbst sicher und weiß, wer andere sind, wie die Welt funktioniert ' +
        'und wie sie das Leben erleben.   Der zweite Typ - nennen wir ihn "neugierig" - ist sich über diese Dinge vielleicht weniger im Klaren, ' +
        'hat manchmal Zweifel und Bedauern und ist verwirrt darüber, wie er auf Situationen reagieren soll, ' +
        'die anders oder ungewohnt sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie eher in der Mitte zwischen selbstbewusst und neugierig sind.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': 'Natürlich hat eine selbstbewusste oder neugierige Herangehensweise Vor- und Nachteile, ' +
        'und jeder von uns kann sich von Zeit zu Zeit ein mal so und ein mal so fühlen, abhängig von den jeweiligen  ' +
        'Umständen oder Situationen.  Wenn Sie der "selbstbewusste" Typ sind, kann es hilfreich sein, sich die Situationen bewusst zu machen,  ' +
        'in denen Sie nicht unbedingt wissen, was Sie tun, denken oder fühlen sollen, vor allem, wenn Sie etwas erleben, ' +
        'das anders ist, als Sie es gewohnt sind.',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '<b>In diesem Zusammenhang scheinen Sie sich zu fragen, ob das Leben das ' +
        'ist, "was Sie daraus machen", oder ob die beste Strategie zur Bewältigung von Problemen wirklich darin besteht, einfach "positiv" zu denken. ' +
        '</b> In manchen Fällen mögen diese Methoden für Sie funktionieren, aber Sie sind wahrscheinlich genauso ' +
        'geneigt, diese "positive" Herangehensweise an das Leben und die Lebensführung in Frage zu stellen. Ein Vorteil des Denkens, dass "nur ' +
        'Sie für Ihre Probleme verantwortlich sind", ist vielleicht, dass solche Menschen sich ermächtigt fühlen, jedes Problem anzugehen, mit dem sie konfrontiert sind. ' +
        'Ein Nachteil dieser Weltanschauung könnte jedoch sein, dass solche Menschen nicht immer besonders viel Mitgefühl für diejenigen empfinden, ' +
        'die scheinbar nicht die Fähigkeit haben, "jedes Hindernis zu überwinden". Eine weitere Frage, die sich in diesem Zusammenhang stellt, ist, ' +
        'welche Folgen der Glaube - oder der Unglaube - hat, dass wir "alle für die Art von Leben verantwortlich sind, das wir führen".',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': 'Unser Hintergrund und unsere Erfahrungen wirken sich auch auf andere Weise auf uns aus, ' +
        'indem sie das Ausmaß prägen, in dem wir fähig und bereit sind, mit Menschen und Ereignissen umzugehen, die ganz anders sind, ' +
        'als wir es gewohnt sind. Aus Sicht des BEVI gibt es in dieser Hinsicht zwei Arten von Menschen.  Der erste Typ - ' +
        'nennen wir ihn "selbstbewusst" - ist sich seiner selbst sicher und weiß, wer andere sind, wie die Welt ' +
        'funktioniert und wie sie das Leben erleben. Der zweite Typ - nennen wir ihn "neugierig" - ist sich über diese Dinge vielleicht weniger im Klaren, ' +
        'hat manchmal Zweifel und Bedauern und ist verwirrt darüber, wie er auf Situationen reagieren soll,' +
        'die anders oder ungewohnt sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie eher neugierig als selbstbewusst sind.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': 'Natürlich hat eine selbstbewusste oder wissbegierige Herangehensweise Vor- und Nachteile, ' +
        'und jeder von uns kann sich von Zeit zu Zeit ein mal so und ein mal so fühlen, abhängig von den jeweiligen  ' +
        'Umständen oder Situationen. Wenn Sie der "selbstbewusste" Typ sind, kann es hilfreich sein, sich die Situationen bewusst zu machen, ' +
        'in denen Sie nicht unbedingt wissen, was Sie tun, denken oder fühlen sollen, vor allem, wenn Sie etwas erleben, ' +
        'das anders ist, als Sie es gewohnt sind. <b>In diesem Zusammenhang scheinen Sie generell ' +
        'skeptisch zu sein, dass die Welt das ist, "was Sie aus ihr machen" oder dass wir "einfach positiv denken" sollten, ' +
        'um durchs Leben zu kommen.</b> Tatsächlich können Menschen, die so denken, manchmal naiv oder sogar irritierend wirken, ' +
        'vor allem, weil sie ungeduldig mit denen zu sein scheinen, die allein schon mit den alltäglichen Dingen des Lebens zu kämpfen haben. Der Vorteil dieser Denkweise, dass  ' +
        '"nur Sie für Ihre Probleme verantwortlich sind", liegt vielleicht darin, dass solche Menschen sich ermächtigt fühlen, ' +
        'jedes Problem anzugehen, mit dem sie konfrontiert werden.  Der Nachteil dieser Weltanschauung ist jedoch, dass solche Menschen nicht immer besonders viel ' +
        'Mitgefühl für diejenigen empfinden, die scheinbar nicht die Fähigkeit haben, "jedes Hindernis zu überwinden"."' +
        ' In Ihrem Fall könnte es hilfreich sein, darüber nachzudenken, wann Sie sich ermächtigt fühlen und wann nicht  ' +
        'und warum das bei Ihnen der Fall ist. Was glauben Sie zum Beispiel über "Schicksal", "die menschliche Natur" ' +
        'oder "warum die Dinge so sind, wie sie sind"? Welche dieser Überzeugungen helfen Ihnen am meisten - und welche am wenigsten -  ' +
        'bei der Bewältigung der Herausforderungen und Möglichkeiten, die sich Ihnen jeden Tag bieten?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': 'Wir Menschen sind sinnstiftende Lebewesen. ' +
        'Vielleicht sind wir uns dessen nicht einmal bewusst, aber wir alle suchen nach Erklärungen dafür, "warum die Dinge so sind, wie sie sind",  ' +
        'und ziehen kausale Schlüsse, auch wenn wir nicht immer genügend Informationen dafür haben. Diese Erklärungen reichen oft ' +
        'von der Frage, warum wir Menschen tun, was wir tun, über die Frage, warum Ereignisse in der Welt so ablaufen, wie sie ablaufen, bis hin zum allgemeinen ' +
        'Sinn des Lebens oder der Existenz. Manchmal sind wir uns vielleicht nicht einmal bewusst, dass wir Schlussfolgerungen darüber ziehen, "warum die Dinge ' +
        'so sind, wie sie sind", oder wie häufig, automatisch, unbewusst oder sogar unvermeidlich diese sinnstiftenden ' +
        'Prozesse im täglichen Leben sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie sehr gut ' +
        'differenzierte und ausgefeilte kausale Erklärungen für Phänomene finden und tiefgründig und genau darüber nachdenken können, ' +
        '"warum die Dinge so sind, wie sie sind".</b>',
    'app.individualreport.whywedo.lessthan69.text': 'Wir Menschen sind sinnstiftende Lebewesen. ' +
        'Vielleicht sind wir uns dessen nicht einmal bewusst, aber wir alle suchen nach Erklärungen dafür, "warum die Dinge so sind, wie sie sind ' +
        'und ziehen kausale Schlüsse, auch wenn wir nicht immer genügend Informationen dafür haben. Diese Erklärungen reichen oft ' +
        'von der Frage, warum wir Menschen tun, was wir tun, über die Frage, warum Ereignisse in der Welt so ablaufen, wie sie ablaufen, bis hin zum allgemeinen ' +
        'Sinn des Lebens oder der Existenz. Manchmal sind wir uns vielleicht nicht einmal bewusst, dass wir Schlussfolgerungen darüber ziehen, "warum die Dinge ' +
        'so sind, wie sie sind", oder wie häufig, automatisch, unbewusst oder sogar unvermeidlich diese sinnstiftenden ' +
        'Prozesse im täglichen Leben sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie ' +
        'immer mehr darüber nachdenken, "warum die Dinge so sind, wie sie sind". </b>',
    'app.individualreport.whywedo.lessthan30.text': 'Wir Menschen sind sinnstiftende Lebewesen. ' +
        'Vielleicht sind wir uns dessen nicht einmal bewusst, aber wir alle suchen nach Erklärungen dafür, "warum die Dinge so sind, wie sie sind ' +
        'und ziehen kausale Schlüsse, auch wenn wir nicht immer genügend Informationen dafür haben. Diese Erklärungen reichen oft ' +
        'von der Frage, warum wir Menschen tun, was wir tun, über die Frage, warum Ereignisse in der Welt so ablaufen, wie sie ablaufen, bis hin zum allgemeinen ' +
        'Sinn des Lebens oder der Existenz. Manchmal sind wir uns vielleicht nicht einmal bewusst, dass wir Schlussfolgerungen darüber ziehen, "warum die Dinge ' +
        'so sind, wie sie sind", oder wie häufig, automatisch, unbewusst oder sogar unvermeidlich diese sinnstiftenden ' +
        'Prozesse im täglichen Leben sind. <b>Aus Ihren Antworten im BEVI geht hervor, dass Sie davon profitieren könnten, ' +
        'mehr darüber zu erfahren, warum Menschen tun, was sie tun, oder warum Ereignisse in der Welt so geschehen, wie sie geschehen, und zwar vor, während ' +
        'und nach neuen oder besonders herausfordernden Erfahrungen, die Sie machen.</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': 'Einleitung',
    'app.individualreport.title.foundation': "Die Grundlage: 'Formative Variablen' und 'Kernbedürfnisse'",
    'app.individualreport.title.tolerance_of_desequilibrium': 'Toleranz des Ungleichgewichts',
    'app.individualreport.title.making_sense': 'Verstehen, warum wir tun, was wir tun',
    'app.individualreport.title.access_to_yourself': 'Zugang zu Ihnen selbst und Ihren Gedanken, Gefühlen und Bedürfnissen',
    'app.individualreport.title.access_to_the_thoughts': 'Zugang zu den Gedanken und Gefühlen anderer',
    'app.individualreport.title.access_to_the_larger_world': 'Zugang zu der Welt',
    'app.individualreport.title.conclusion': 'Fazit',
    'app.individualreport.title.thankyou': 'Danke, dass Sie das BEVI ausgefüllt haben.',
    'app.individualreport.title.profile': 'Profil',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '<b>Sie haben wahrscheinlich ' +
        'bemerkt, dass manche Menschen dazu neigen, emotionaler und sensibler zu sein und gleichzeitig ' +
        'den Ausdruck von Bedürfnissen oder Gefühlen mehr schätzen als andere. Eine solche Beschreibung scheint ' +
        'sehr gut zu Ihnen zu passen.</b> Denken Sie einen Moment darüber nach, wie zentral Gefühle ' +
        'für die menschliche Existenz sind. Die Fähigkeit zu fühlen, was Sie fühlen, und gleichzeitig die Gefühle anderer ' +
        'richtig zu interpretieren und zu verstehen - das ist es, was Sie brauchen. ' +
        'Gefühle anderer zu verstehen - was manche als "emotionale Intelligenz" bezeichnen ' +
        '- ist der Schlüssel, um sich in allen Lebensbereichen zurechtzufinden, von Ihren persönlichen Beziehungen bis hin zur ' +
        'Arbeit.  Ohne Gefühle wäre es sehr schwierig zu verstehen, was wir mögen und was nicht, ' +
        'wer wir und andere sind und wer wir sein wollen.',
    'app.individualreport.accessself.textB': '<b>Sie haben wahrscheinlich ' +
        'bemerkt, dass manche Menschen dazu neigen, emotionaler und sensibler zu sein als andere. Eine solche Beschreibung ' +
        'scheint in vielen Fällen auf Sie zuzutreffen.</b> Denken Sie einen Moment darüber nach, wie zentral Emotionen ' +
        'für die menschliche Existenz sind.  Die Fähigkeit, das zu fühlen, was Sie fühlen, und gleichzeitig die Gefühle anderer ' +
        'richtig zu interpretieren und zu verstehen. ' +
        'Die Fähigkeit, die Gefühle anderer zu deuten und zu verstehen - was manche als "emotionale Intelligenz" bezeichnen - ' +
        'ist der Schlüssel, um sich in allen Lebensbereichen zurechtzufinden, von Ihren persönlichen Beziehungen bis hin zur ' +
        'Arbeit.  Ohne Gefühle wäre es sehr schwierig zu verstehen, was wir mögen und was nicht, ' +
        'wer wir und andere sind und wer wir sein wollen.',
    'app.individualreport.accessself.textC': '<b>Sie haben wahrscheinlich bemerkt ' +
        'dass manche Menschen dazu neigen, emotionaler und sensibler zu sein und auch den Ausdruck von ' +
        'Bedürfnissen oder Gefühlen mehr Wert beimessen als andere. Eine solche Beschreibung scheint weniger beschreibend dafür zu sein, wie Sie ' +
        'mit Ihren eigenen Gefühlen und denen anderer umgehen, denn Sie können dazu neigen, verwirrt und manchmal sogar ' +
        'irritiert zu sein, wenn andere Menschen ihre Gefühle übermäßig zeigen oder verletzlich sind. ' +
        '</b> Wenn das bei Ihnen der Fall ist, könnte es hilfreich sein, noch einmal, ' +
        'über die Tatsache nachzudenken, dass wir aufgrund unseres Backgrounds und unserer Lebenserfahrungen viel eher - oder viel weniger - in der Lage sind, ' +
        'auf tiefe Bedürfnisse und Gefühle "zuzugreifen".  Nur Sie können beurteilen, ' +
        'ob Ihr Hintergrund und Ihre Erfahrungen Sie generell davon abgehalten haben, ' +
        'zu tief zu fühlen oder zu viel von anderen zu brauchen.  Wenn das Ihre Erfahrung war, ist es wichtig, ' +
        'darüber nachzudenken, welche Auswirkungen solche Prozesse auf Ihr Leben, Ihre Arbeit und Ihre ' +
        'Beziehungen zu anderen haben. Auch sollten Sie darüber nachdenken, wie Sie Menschen, Situationen und Beziehungen erleben, ' +
        'die zumindest am Anfang schwer zu bewältigen sein können, vor allem, wenn sie neu sind.  Denken Sie ' +
        'einen Moment darüber nach, wie wesentlich Gefühle für die menschliche Existenz sind.  Die Fähigkeit zu fühlen, was Sie ' +
        'fühlen und gleichzeitig die Gefühle anderer richtig zu interpretieren und zu verstehen - was manche ' +
        'als "emotionale Intelligenz" bezeichnen - ist der Schlüssel, um sich in allen Lebensbereichen zurechtzufinden, von Ihren ' +
        'persönlichen Beziehungen bis hin zur Arbeitswelt.  Ohne Gefühle wäre es sehr schwierig, ' +
        'zu verstehen, was wir mögen und was nicht, wer wir und andere sind und wer wir sein wollen.',
    'app.individualreport.accessself.textI': '<b>Vielleicht haben Sie auch manchmal mit schwierigen Gedanken und Gefühlen zu kämpfen und vermeiden übermäßige "Selbstbeobachtung"." Im Allgemeinen mag es Sie verwirren oder sogar irritieren, wenn andere sich so sehr darauf konzentrieren, "warum sie so sind, wie sie sind".</b> ' +
        'Es ist wichtig, sich daran zu erinnern, dass jeder Mensch unterschiedliche Erfahrungen und Hintergründe hat, ' +
        'die sein Selbstverständnis prägen, und dass Menschen, die viel Zeit damit verbringen, darüber nachzudenken, ' +
        '"warum wir so sind, wie wir sind", dem Rest von uns oft helfen können, besser zu verstehen, wer wir sind und ' +
        'warum wir tun, was wir tun.  Gleichzeitig ist es auch möglich, sich mit Gedanken und Gefühlen zu beschäftigen, ' +
        'die sehr schwer zu erleben, zu verstehen oder zu bewältigen sind; In solchen Fällen kann es  ' +
        'hilfreich sein, mit jemandem zu sprechen, der sich mit diesen Themen auskennt.',
    'app.individualreport.accessself.textH': '<b>Vielleicht sind Sie auch offen ' +
        'für die Welt der Gedanken und Gefühle, aber Sie stellen fest, dass Sie im Vergleich ' +
        'zu Ihren Mitmenschen ein "Mittelding" sind. Manche Menschen scheinen viel Zeit und Energie darauf zu verwenden, darüber nachzudenken, ' +
        '"warum wir so sind, wie wir sind", während andere anscheinend gar nicht so viel darüber nachdenken ' +
        'und diejenigen, die das tun, vielleicht ein wenig irritierend finden. In vielerlei Hinsicht sind Sie in einer guten Position, ' +
        'um beiden Seiten zu helfen, die Tendenzen der jeweils anderen Seite besser zu verstehen (z.B. ' +
        'diejenigen, die "viel" fühlen, und diejenigen, die solche Gefühle scheinbar ganz vermeiden).</b>',
    'app.individualreport.accessself.textG': '<b>Vielleicht sind Sie auch sehr offen ' +
        'für schwierige Gedanken und Gefühle, für Introspektive im Allgemeinen und dafür, sich Ihrer Gedanken und Gefühle ' +
        'bewusst zu sein.  Es mag Sie manchmal verwundern, wenn andere diese Fähigkeit nicht zu haben scheinen, ' +
        'was sich für Sie manchmal einsam oder isolierend anfühlen kann.  Für Menschen wie Sie ist es wichtig, ' +
        'andere "Gleichgesinnte" zu finden, die den Ausdruck von Bedürfnissen und Gefühlen verstehen und schätzen ' +
        'und gerne darüber nachdenken, "warum wir so sind, wie wir sind".  Wir brauchen Menschen wie Sie auf der  ' +
        'Welt, die die Fähigkeit haben, so tief zu denken und zu fühlen.</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {

    'app.individualreport.accessothers.textA': 'Von klein auf lernen wir oft, es zu vermeiden, ' +
        'über bestimmte Themen wie Religion und Politik, die Rolle von Mann und Frau oder Fragen der Rasse oder ' +
        'ethnischen Zugehörigkeit zu sprechen, weil diese Themen so hitzig werden können, wenn wir auf jemanden treffen,  ' +
        'der ganz anders denkt als wir.  Aus der Sicht des BEVI geht es aber nicht darum, dass wir diese Themen nicht diskutieren können oder ' +
        'sollten; für einen gesunden Diskurs in einer zivilisierten Gesellschaft ist es sogar unerlässlich' +
        ', dass wir das tun.  Das Wichtigste ist, dass wir zuerst verstehen, warum manche Themen für uns und für andere' +
        '"heiß" sind.  Deshalb erforschen wir hier, wie Sie Fragen der Religion, des Geschlechts,  ' +
        'der Politik und der Rasse sehen und welche Auswirkungen solche Überzeugungen und Werte auf Ihre  ' +
        'Beziehungen zu anderen haben können, die ähnlich - oder anders - denken und fühlen wie Sie. <br /><br />' +
        '<b>Erstens scheint es so, als hätten Sie einen starken  ' +
        'religiösen Glauben oder eine starke Überzeugung und dass Sie Ihren Glauben als einen wichtigen Teil Ihrer  ' +
        'Sinngebung und Sinnfindung sehen. Es kann wunderbar sein, ein tiefes Gefühl des Glaubens zu haben.  Es ist aber auch ' +
        'wichtig zu verstehen, dass manche Menschen, Gruppen, Religionen und Kulturen ' +
        'in diesen grundlegenden Fragen ganz anders glauben als Sie. </b>  Manchmal wissen wir ganz genau,  ' +
        'warum wir in religiösen Fragen so glauben, wie wir glauben. In anderen Fällen haben wir einfach ' +
        'akzeptiert, was uns gelehrt wurde, ohne wirklich darüber nachzudenken, warum wir glauben, ' +
        'was wir glauben. Es geht hier nicht darum, dass eine Glaubensrichtung richtig und eine andere falsch ist, ' +
        'sondern darum, dass wir alle etwas über diese grundlegenden Dinge, die uns alle betreffen,  ' +
        'glauben (d.h. auch Nicht-Glauben ist eine Überzeugung, dass ein bestimmter Glaube oder ein bestimmtes  ' +
        'Glaubenssystem keinen Sinn hat - oder nicht real ist). Weil religiöse Überzeugungen so zentral dafür sind, wie wir uns selbst, ' +
        'andere und die Welt erleben, ist es sehr wichtig, dass wir uns bemühen, das Wesen und die Ursprünge ' +
        'unserer Überzeugungen und Werte in Bezug auf Religion zu verstehen. Da unsere religiösen Überzeugungen oft  ' +
        'aus unserer Erziehung, unserer Kultur und unserem Umfeld resultieren, ist es vielleicht sogar noch wichtiger, ' +
        'dass wir besser verstehen, wie und warum andere Menschen ' +
        'ähnlich oder anders glauben als wir. Wenn wir uns dem Glauben und den Werten mit echter Neugier und echtem Interesse nähern, ' +
        'können wir mehr darüber erfahren, warum wir so sind, wie wir sind und gleichzeitig  ' +
        'anderen dabei helfen, das Gleiche zu tun.',
    'app.individualreport.accessothers.textB': 'Von klein auf lernen wir oft, es zu vermeiden, ' +
        'über bestimmte Themen wie Religion und Politik, die Rolle von Mann und Frau oder Fragen der Rasse oder ' +
        'ethnischen Zugehörigkeit zu sprechen, weil diese Themen so hitzig werden können, wenn wir auf jemanden treffen,  ' +
        'der ganz anders denkt als wir. Aus der Sicht des BEVI geht es aber nicht darum, dass wir diese Themen nicht diskutieren können oder ' +
        'sollten; für einen gesunden Diskurs in einer zivilisierten Gesellschaft ist es sogar unerlässlich ' +
        ', dass wir das tun. Das Wichtigste ist, dass wir zuerst verstehen, warum manche Themen für uns und für andere ' +
        '"heiß" sind. Deshalb erforschen wir hier, wie Sie Fragen der Religion, des Geschlechts,  ' +
        'der Politik und der Rasse sehen und welche Auswirkungen solche Überzeugungen und Werte auf Ihre  ' +
        'Beziehungen zu anderen haben können, die ähnlich - oder anders - denken und fühlen wie Sie. ' +
        '<br /><br />' +
        '<b>Erstens scheint es so, als hätten Sie eher gemäßigte ' +
        'religiöse Überzeugungen.  Es ist möglich, dass Sie mit Ihren derzeitigen Überzeugungen in Bezug auf ' +
        '"Glaubensfragen" völlig zufrieden sind. Es ist aber auch möglich, dass Sie Fragen zur Tiefe und ' +
        'Intensität Ihres eigenen religiösen Glaubens haben, welcher das auch sein mag. Vielleicht wundern Sie sich auch über andere, ' +
        'die scheinbar sehr stark gläubig oder ungläubig sind (z. B. bezeichnen Sie sich selbst als sehr stark  ' +
        'religiös oder sehr stark anti-religiös). </b> Manchmal wissen wir sehr genau, ' +
        'warum wir in religiösen Fragen so denken, wie wir denken. In anderen Fällen haben wir einfach ' +
        'akzeptiert, was uns gelehrt wurde, ohne wirklich darüber nachzudenken, warum wir glauben, ' +
        'was wir glauben. Es geht hier nicht darum, dass eine Glaubensrichtung richtig und eine andere falsch ist, ' +
        'sondern darum, dass wir alle etwas über diese grundlegenden Dinge, die uns alle betreffen,  ' +
        'glauben (d.h. auch Nicht-Glauben ist eine Überzeugung, dass ein bestimmter Glaube oder ein bestimmtes  ' +
        'Glaubenssystem keinen Sinn hat - oder nicht real ist). Weil religiöse Überzeugungen so zentral dafür sind, wie wir uns selbst, ' +
        'andere und die Welt erleben, ist es sehr wichtig, dass wir uns bemühen, das Wesen und die Ursprünge ' +
        'unserer Überzeugungen und Werte in Bezug auf Religion zu verstehen. Da unsere religiösen Überzeugungen oft  ' +
        'aus unserer Erziehung, unserer Kultur und unserem Umfeld resultieren, ist es vielleicht sogar noch wichtiger, ' +
        'dass wir besser verstehen, wie und warum andere Menschen ' +
        'ähnlich oder anders glauben als wir. Wenn wir uns dem Glauben und den Werten mit echter Neugier und echtem Interesse nähern, ' +
        'können wir mehr darüber erfahren, warum wir so sind, wie wir sind, und gleichzeitig  ' +
        'anderen dabei helfen, das Gleiche zu tun.',
    'app.individualreport.accessothers.textC': 'Von klein auf lernen wir oft, es zu vermeiden, ' +
        'über bestimmte Themen wie Religion und Politik, die Rolle von Mann und Frau oder Fragen der Rasse oder ' +
        'ethnischen Zugehörigkeit zu sprechen, weil diese Themen so hitzig werden können, wenn wir auf jemanden treffen,  ' +
        'der ganz anders denkt als wir.  Aus der Sicht des BEVI geht es aber nicht darum, dass wir diese Themen nicht diskutieren können oder ' +
        'sollten; für einen gesunden Diskurs in einer zivilisierten Gesellschaft ist es sogar unerlässlich ' +
        ', dass wir das tun. Das Wichtigste ist, dass wir zuerst verstehen, warum manche Themen für uns und für andere' +
        '"heiß" sind. Deshalb erforschen wir hier, wie Sie Fragen der Religion, des Geschlechts,  ' +
        'der Politik und der Rasse sehen und welche Auswirkungen solche Überzeugungen und Werte auf Ihre  ' +
        'Beziehungen zu anderen haben können, die ähnlich - oder anders - denken und fühlen wie Sie. <br /><br />' +
        '<b>Erstens scheinen Sie keinen starken religiösen ' +
        'Glauben oder Überzeugungen zu haben und sind vielleicht sogar skeptisch gegenüber religiösen ' +
        'Systemen oder religiösen Gläubigen. Wenn Sie sorgfältig über religiöse Überzeugungen nachdenken oder sie nicht ' +
        'allein aus "Glauben" akzeptieren, kann das zu einem kritischen Nachdenken über Überzeugungen und Werte führen, ' +
        'die allzu oft ohne ausreichende Prüfung akzeptiert werden. Unabhängig davon, wie dieser Prozess für Sie  ' +
        'ausgeht, kann es hilfreich sein, über die Möglichkeit nachzudenken, dass eine feste Überzeugung ' +
        '(z.B. der tiefe Glaube an die Gültigkeit des eigenen Glaubens) einfach durch eine andere ' +
        'feste Überzeugung (z.B. der tiefe Glaube an die Gültigkeit des eigenen Nicht-Glaubens) ' +
        'ersetzt werden kann, ohne dass wir dadurch mehr über den Ursprung und den Zweck von ' +
        'Glauben und Werten für uns alle erfahren.</b> Manchmal wissen wir sehr genau, ' +
        'warum wir in religiösen Fragen so denken, wie wir denken. In anderen Fällen haben wir einfach ' +
        'akzeptiert, was uns gelehrt wurde, ohne wirklich darüber nachzudenken, warum wir glauben, ' +
        'was wir glauben. Es geht hier nicht darum, dass eine Glaubensrichtung richtig und eine andere falsch ist, ' +
        'sondern darum, dass wir alle etwas über diese grundlegenden Dinge, die uns alle betreffen,  ' +
        'glauben (d.h. auch Nicht-Glauben ist eine Überzeugung, dass ein bestimmter Glaube oder ein bestimmtes  ' +
        'Glaubenssystem keinen Sinn hat - oder nicht real ist). Weil religiöse Überzeugungen so zentral dafür sind, wie wir uns selbst, ' +
        'andere und die Welt erleben, ist es sehr wichtig, dass wir uns bemühen, das Wesen und die Ursprünge ' +
        'unserer Überzeugungen und Werte in Bezug auf Religion zu verstehen. Da unsere religiösen Überzeugungen oft  ' +
        'aus unserer Erziehung, unserer Kultur und unserem Umfeld resultieren, ist es vielleicht sogar noch wichtiger, ' +
        'dass wir besser verstehen, wie und warum andere Menschen ' +
        'ähnlich oder anders glauben als wir. Wenn wir uns dem Glauben und den Werten mit echter Neugier und Fürsorge nähern, ' +
        'können wir mehr darüber erfahren, warum wir so sind, wie wir sind, und gleichzeitig  ' +
        'anderen dabei helfen, das Gleiche zu tun.',
}

const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '<b>Zweitens neigen Sie in Bezug auf das Geschlecht ' +
        'zu eher traditionellen oder konventionellen Überzeugungen und Werten darüber, wer Männer und ' +
        'Frauen sind und wie sie sein sollten. Sie sind vielleicht auch der Meinung, dass Männer und Frauen' +
        ' "einfach so sind" und dass es uns allen besser gehen würde, wenn wir diese Tatsache einfach  ' +
        'verstehen und akzeptieren würden. Wir alle wissen, dass es Unterschiede zwischen Männern und Frauen gibt. Aber die Frage ' +
        'ist doch, warum es überhaupt Unterschiede gibt?  Aus Ihren Antworten geht hervor, dass Sie glauben, ' +
        'dass die Unterschiede zwischen den Geschlechtern größtenteils auf biologische Faktoren zurückzuführen sind.</b> Obwohl die Wissenschaft ' +
        'zu diesem Thema sehr komplex ist, ist es wichtig zu wissen, dass tatsächliche oder angenommene "Unterschiede" zwischen ' +
        'Männern und Frauen im Laufe der Jahre dazu benutzt wurden, Mädchen und Frauen viele Rechte zu verweigern; Kurz ' +
        'gesagt, "biologische Argumente" wurden oft benutzt, um die Verweigerung von Rechten für Frauen ' +
        'zu rechtfertigen, weil sie nicht dazu "bestimmt" sind, als Gleichgestellte in der Welt der Männer zu leben, zu arbeiten oder zu sein.  Stereotype ' +
        'können jedoch in beide Richtungen wirken, da Jungen und Männer als "weniger fähig" angesehen werden,  ' +
        'für sich selbst zu sorgen oder Gefühle zu empfinden. Das bedeutet, dass ihnen nicht nur das "Recht" verweigert wird, Gefühle zu haben und Bedürfnisse ' +
        'bei sich selbst und anderen zu empfinden. Wenn Männer lernen, dass sie "hart sein müssen, und sich dann anderen gegenüber aggressiv oder gefühllos verhalten,   ' +
        'können sich solche "Lektionen" auf uns alle auswirken. ' +
        'Männern kann auch die Gleichberechtigung verweigert werden, wenn es zum Beispiel um die Kindererziehung geht oder um andere Rollen, ' +
        'die traditionell Mädchen und Frauen zugewiesen wurden. Wenn wir über solche Themen nachdenken, ist es ' +
        'wichtig zu verstehen, wie Argumente, die auf vermeintlichen biologischen Unterschieden beruhen, dazu benutzt ' +
        'werden können, die Möglichkeiten und Lebensweisen von Frauen und Männern einzuschränken, was Konsequenzen für uns alle hat.',
    'app.individualreport.gendertradition.textB': '<b>Zweitens neigen Sie in Bezug auf das Geschlecht  ' +
        'zu ausgewogenen Überzeugungen und Wertvorstellungen darüber, wer Männer und Frauen sind und wie sie ' +
        'sein sollten. Sie sind nicht davon überzeugt, dass Männer und Frauen einfach "so gemacht sind, wie sie sein sollten".  Sie ' +
        'erkennen zwar an, dass es Unterschiede zwischen Männern und Frauen gibt, aber die wichtigste ' +
        'Frage ist, warum es diese Unterschiede überhaupt gibt.</b> Sind diese Unterschiede zum Beispiel  ' +
        'auf die "Umwelt" oder die "Genetik" oder eine Kombination aus beidem zurückzuführen? ' +
        'Es gibt Belege für beide Seiten, die darauf hindeuten, dass die Unterschiede zwischen Männern und Frauen auf ' +
        'ein komplexes Zusammenspiel von kulturellen und erzieherischen Prozessen sowie auf grundliegende biologische Unterschiede zurückzuführen ' +
        'sind.  Es ist wichtig zu erkennen, dass biologische Erklärungen für diese Unterschiede ' +
        'im Laufe der Jahre dazu benutzt wurden, Mädchen und Frauen viele Rechte vorzuenthalten, von der Bildung über das ' +
        'Wahlrecht bis hin zur Kontrolle über ihre eigene Sexualität. Stereotype können jedoch in beide Richtungen wirken. Zum Beispiel ' +
        'können Männer als weniger fähig angesehen werden, Gefühle zu nähren oder auszudrücken, und sind daher weniger geeignet  ' +
        'für die Kindererziehung. Wenn wir über solche Fragen nachdenken, ist es wichtig, mehr darüber zu lesen und zu lernen, ' +
        'was wir über die Unterschiede zwischen den Geschlechtern wissen - und was nicht, vor allem, weil viele Wissenschaftlerinnen und Wissenschaftler ' +
        'behaupten, dass Frauen und Männer sich als menschliche Wesen mehr ähneln als unterscheiden.',
    'app.individualreport.gendertradition.textC': '<b>Zweitens haben Sie in Bezug auf das Geschlecht ' +
        'eher fortschrittliche oder liberale Überzeugungen und Werte in Bezug darauf, wer Männer und Frauen ' +
        'sind und wie sie sein sollten; Sie sind wahrscheinlich nicht der Meinung, dass Männer und Frauen einfach "so  ' +
        'gemacht sind". Sie erkennen an, dass es Unterschiede zwischen Männern und Frauen gibt. Wir alle wissen und ' +
        'sehen die Beweise für diese Tatsache täglich. Aber die Frage ist, warum es diese Unterschiede  ' +
        'überhaupt gibt. Sind diese Unterschiede zum Beispiel auf die "Umwelt" oder die "Genetik" oder' +
        'eine Kombination aus beidem zurückzuführen?  Aus Ihren Antworten geht hervor, dass Sie der Meinung sind, dass die Unterschiede ' +
        'zwischen den Geschlechtern wahrscheinlich eher auf kulturelle oder umweltbedingte Faktoren zurückzuführen sind.</b> ' +
        'Obwohl die Wissenschaft zu diesem Thema sehr komplex ist, gibt es auf beiden Seiten Hinweise darauf, ' +
        ' dass die Unterschiede zwischen Männern und Frauen auf ein komplexes Zusammenspiel von kulturellen ' +
        ' und erzieherischen Prozessen sowie biologischen Unterschieden zurückzuführen sind.  Gleichzeitig  ' +
        'wurden diese Unterschiede aber auch dazu benutzt, um Mädchen und Frauen im Laufe der Jahre viele ' +
        'Rechte zu verweigern, von der Bildung über das Wahlrecht bis hin zur Kontrolle über die eigene  ' +
        'Sexualität, und oft wurden "biologische Argumente" benutzt,um die Verweigerung solcher Rechte für Frauen zu rechtfertigen. ' +
        'Stereotype können jedoch in beide Richtungen wirken, da Jungen und Männer als "weniger fähig" ' +
        'angesehen werden, für sich selbst zu sorgen oder Emotionen zu empfinden. Das bedeutet, dass ihnen  ' +
        'nicht nur das "Recht" verweigert wird, Gefühle zu haben und Bedürfnisse zu empfinden - bei sich selbstund bei anderen -,  ' +
        'sondern dass ihnen auch die Gleichberechtigung verweigert wird, wenn es z. B. um die Kindererziehung oder andere Rollen geht, die historisch gesehen Mädchen und Frauen zugewiesen wurden. ' +
        'Wenn wir über solche Themen nachdenken, ist es wichtig zu verstehen, dass Argumente, die auf  ' +
        'vermeintlichen biologischen Unterschieden beruhen, dazu benutzt wurden, die Möglichkeiten und Lebensweisen  ' +
        'von Frauen und Männern einzuschränken, was für uns alle Konsequenzen hat, sowohl auf individueller als auch auf gesellschaftlicher Ebene. ' +
        'Daher ist es wichtig, mehr darüber zu lesen und zu erfahren, was wir über Geschlechterunterschiede wissen ' +
        'und was nicht, denn viele Wissenschaftler:innen sind der Meinung, dass Frauen und Männer   ' +
        'sich als menschliche Wesen mehr ähneln als unterscheiden.',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '<b>Drittens werden Sie in kulturellen und ' +
        'politischen Fragen wahrscheinlich eher als akzeptierend, offen, liberal und fortschrittlich beschrieben. ' +
        'Diese Art zu denken und zu sein kann wunderbar sein, denn Sie erwarten wahrscheinlich sehnsüchtig ' +
        'die Begegnung mit Ideen, Ansätzen, Umständen, Menschen oder Gesellschaften, die neu oder anders sind ' +
        'und empfinden dabei ein Gefühl der Begeisterung.  Möglicherweise möchten Sie auch Veränderungen in Ihrem Leben, ' +
        'bei Ihrer Arbeit und in der Gesellschaft insgesamt vorantreiben und fühlen sich von anderen, die nicht dieselben Überzeugungen teilen,  ' +
        'irritiert.</b> Auch hier ist es wichtig zu verstehen, warum andere vielleicht nicht so denken ' +
        'oder fühlen wie Sie. Zum Beispiel können andere Systeme, Kulturen oder Lebensweisen für manche Menschen ' +
        'eine Quelle der Angst sein, da die Überzeugungen und Werte dieser Systeme, Kulturen oder  ' +
        'Lebensweisen als Herausforderung für ihre Weltanschauung empfunden werden können.  Wenn Sie in dieser ' +
        'Hinsicht anderer Meinung sind, könnte es verlockend sein, Menschen, die sich in solchen Situationen ' +
        'unwohl fühlen, als engstirnig oder einfältig abzutun. Versuchen Sie stattdessen, mehr über die Hintergründe und ' +
        'Lebenserfahrungen der Menschen zu erfahren, die so anders denken und fühlen als Sie. Vielleicht sind  ' +
        ' sie offener, als es den Anschein hat, aber sie kämpfen damit, herauszufinden, wie sie mit Überzeugungen ' +
        ' und Werten umgehen sollen, die anders sind als alles, was sie gewohnt waren. In einer solchen Begegnung, ' +
        'ist es vielleicht möglich, besser zu verstehen, warum sie - und wir alle - überhaupt an das glauben ' +
        ' und schätzen, was wir tun.  ',
    'app.individualreport.variables.textE_2': '',
    'app.individualreport.variables.textF': '<b>Drittens werden Sie in kulturellen und ' +
        'politischen Fragen wahrscheinlich als eher "gemäßigter" als einige Ihrer ' +
        'Altersgenossen, die vielleicht ein wenig - oder sogar viel - liberaler oder konservativer sind als Sie, beschrieben. ' +
        'Diese Einstellung hat Vorteile, da Sie vielleicht zu Kompromissen neigen oder den Wert von Perspektiven  ' +
        'oder Ideen erkennen, die gegensätzlich zu sein scheinen. Andererseits kann eine solche Einstellung auch  ' +
        'bedeuten, dass Sie sich mit solchen Themen noch nicht eingehend beschäftigt haben, es Ihnen relativ ' +
        'egal ist oder Sie einfach noch nicht wissen, was Sie in dieser Hinsicht glauben.</b> In dem Maße, in dem ' +
        'diese Sichtweise zu Ihnen passt, kann es hilfreich sein, gründlich und häufig darüber nachzudenken, ' +
        'warum Sie das glauben, was Sie in Bezug auf Kultur, Politik, Rasse oder Geschlecht glauben. Es kann ' +
        'nicht nur interessant sein, mit Freund:innen und Kolleg:innen, die was das angeht scheinbar noch strenger sind, ' +
        'darüber zu sprechen, warum sie glauben, was sie glauben, sondern es kann Ihnen auch helfen, die Grundlage ' +
        'für Ihre eigene Weltanschauung besser zu verstehen. Außerdem könnten Sie feststellen, dass Ihre eigene Haltung für diejenigen, ' +
        'die "überzeugter" sind als Sie, eine interessante Gelegenheit darstellt, über ihre eigenen Gründe ' +
        'für ihre Überzeugungen nachzudenken. So oder so werden solche Begegnungen mit Ihnen selbst und anderen  ' +
        'wahrscheinlich klarstellen, was Sie zu diesen wichtigen Themen, die uns alle betreffen, glauben und schätzen.',
    'app.individualreport.variables.textG_1': '<b>Drittens werden Sie in kulturellen und ' +
        'politischen Fragen wahrscheinlich eher als traditioneller, vorsichtiger oder zurückhaltender als Ihre ' +
        'Kolleg:innen, was die Notwendigkeit oder den Wert eines schnellen Wandels auf lokaler oder globaler Ebene angeht beschrieben. Sie ' +
        'haben vielleicht das Gefühl, dass bewährte Arbeitsweisen in Frage gestellt oder sogar untergraben werden ' +
        'ohne dass die Folgen solcher Veränderungen (z. B. für die Familie, den Arbeitsplatz, die Gesellschaft), ' +
        'oder die finanziellen Kosten entsprechender Sozialprogramme ausreichend berücksichtigt werden. Es ist möglich, ' +
        'dass Sie sich von anderen irritiert fühlen, die nicht dieselben Überzeugungen teilen und die in ihren  ' +
        'Bemühungen, sich für Sie einzusetzen, naiv oder unverantwortlich erscheinen.</b> Auch hier ist es wichtig' +
        'zu verstehen, warum andere vielleicht nicht so denken oder fühlen wie Sie. Andere glauben zum Beispiel, ' +
        'dass "traditionelle" Glaubens- und Lebensweisen nur dazu dienen, diejenigen zu kontrollieren, die weniger ' +
        'Macht, Ressourcen oder Rechte haben. Solche Überzeugungen können für sie eine Grundlage in ihrer eigenen ' +
        'Lebensgeschichte oder in Umständen haben, die sie oder andere, die sie kennen, erlebt haben. Wenn Sie in dieser  ' +
        'Hinsicht anders denken, kann es verlockend sein, solche Menschen als Jammerlappen oder Simulant:innen abzutun.  ' +
        'Versuchen Sie stattdessen, mehr über die Hintergründe und Lebenserfahrungen der Menschen zu erfahren, die so ' +
        'anders denken und fühlen als Sie. Vielleicht sind sie offener für Ihre Überzeugungen, als Sie denken ' +
        'und eher bereit, sich auf eine tiefgehende und ehrliche Diskussion mit Ihnen einzulassen - wenn sie  ' +
        'respektvoll und in gutem Glauben geführt wird - als Sie vielleicht denken. Vielleicht lassen sie sich sogar ' +
        'von der Weisheit Ihrer Sichtweise überzeugen, so wie Sie von der Relevanz ihrer Sichtweise überzeugt werden können. Letztlich ' +
        'können solche Begegnungen mit "dem Anderen" den guten Willen und den gegenseitigen Respekt fördern und ' +
        'uns allen helfen, besser zu verstehen, warum sie und wir alle überhaupt an das glauben und schätzen,' +
        ' was wir tun. ',
    'app.individualreport.variables.textG_2': '',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>Was Ihre Einstellung zur ' +
        'Umwelt angeht, scheinen Sie eine tiefe Verbundenheit mit der Erde zu haben und betrachten unseren ' +
        'Planeten als unser einziges Zuhause, das geschützt und erhalten werden muss. Deshalb ist es vielleicht ' +
        'frustrierend für Sie, dass so viele andere diese Überzeugung nicht teilen, vor allem, wenn die Beweise ' +
        'so eindeutig sind, dass wir uns besser um die Natur kümmern müssen.</b> ' +
        'Wenn Sie über diese Themen nachdenken, sollten Sie bedenken, dass verschiedene Gruppen, Kulturen und  ' +
        'Religionen die Beziehung zwischen Menschen, Natur und Erde aus verschiedenen Gründen, ' +
        'die ihnen völlig legitim erscheinen, unterschiedlich sehen. Es ist wichtig, sich zu ' +
        'vergegenwärtigen, worauf diese Unterschiede beruhen und warum wir uns mit diesen Themen auf die eine oder andere  ' +
        'Weise auseinandersetzen. Das liegt daran, dass unsere ökologischen Überzeugungen und Werte - und die Art und ' +
        'Weise, wie Einzelpersonen, Organisationen, Regierungen und Gesellschaften die Natur erleben, verstehen' +
        'und mit ihr umgehen - tiefgreifende Auswirkungen auf Umweltmaßnahmen, -politik und -praktiken auf der ganzen Welt  ' +
        'haben.  Versuchen Sie, soweit es Ihnen möglich ist, andere in ein respektvolles und offenes Gespräch über diese Themen zu ' +
        'verwickeln und berufen Sie sich nicht nur auf "die Fakten", wie Sie sie verstehen. Mit anderen Worten, teilen Sie ' +
        'eigenen Überzeugungen mit, und zwar nicht nur vom intellektuellen Standpunkt aus - was natürlich sehr wichtig ist -, ' +
        'sondern auch im Hinblick darauf, warum Sie so denken, wie Sie denken. Eine solche Herangehensweise fördert eher ' +
        'ein echtes Nachdenken über Umweltthemen, die zwar abstrakt erscheinen, aber in Wirklichkeit sehr wertvoll sind, ' +
        'da sie den Kern dessen treffen, warum wir glauben, dass wir hier sind und welche Verpflichtungen wir gegenüber ' +
        'der Natur haben - oder auch nicht.',
    'app.individualreport.ecologicalresonance.textB': '<b>Was Ihre Einstellung zur ' +
        'Umwelt angeht, scheinen Sie offen für Argumente zu sein, die den Schutz der natürlichen Ressourcen der Erde ' +
        'betreffen und würden sich selbst wahrscheinlich als "gemäßigt" in Bezug auf Umweltfragen im Allgemeinen ' +
        'bezeichnen. Daher wundern Sie sich vielleicht über Menschen, die in diesen Fragen starke Überzeugungen ' +
        ' vertreten.</b> Solche Unterschiede bieten Ihnen die Möglichkeit, mehr über Ihre eigenen Überzeugungen ' +
        'in dieser Hinsicht zu erfahren und vielleicht die Grundlage für Ihre Überzeugungen (oder deren Fehlen) ' +
        'in diesen wichtigen Fragen zu klären. Wenn Sie über diese Themen nachdenken, sollten Sie bedenken, dass verschiedene ' +
        'Gruppen, Kulturen und Religionen die Beziehung zwischen Menschen, Natur und Erde aus verschiedenen Gründen, die ' +
        'ihnen völlig legitim erscheinen, unterschiedlich sehen. Es kann auch hilfreich sein, sich zu vergegenwärtigen, ' +
        'worauf diese Unterschiede beruhen und warum wir diese Themen auf die eine oder andere Weise so tief empfinden. Denn ' +
        'unsere ökologischen Überzeugungen und Werte - und die Art und Weise, wie Einzelpersonen, Organisationen, Regierungen ' +
        'und Gesellschaften die Natur erleben, verstehen und mit ihr umgehen - haben tiefgreifende Auswirkungen auf ' +
        'Umweltmaßnahmen, -politik und -praktiken auf der ganzen Welt. Versuchen Sie, soweit es Ihnen möglich ist, andere in ' +
        'ein respektvolles und offenes Gespräch über diese Themen zu verwickeln und berufen Sie sich nicht nur auf "die Fakten", ' +
        'wie Sie sie verstehen. Mit anderen Worten, teilen Sie eigenen Überzeugungen mit, und zwar nicht nur vom ' +
        'intellektuellen Standpunkt aus - was natürlich sehr wichtig ist -, sondern auch im Hinblick darauf, warum Sie so  ' +
        'denken, wie Sie denken. Eine solche Herangehensweise fördert eher ein echtes Nachdenken über Umweltthemen, ' +
        'die zwar abstrakt erscheinen, aber in Wirklichkeit sehr wertvoll sind, da sie den Kern dessen treffen, warum wir ' +
        'glauben, dass wir hier sind und welche Verpflichtungen wir gegenüber der Natur haben - oder auch nicht.',
    'app.individualreport.ecologicalresonance.textC': '<b>Was Ihre Einstellung zur ' +
        'Umwelt angeht, scheinen Sie generell skeptisch gegenüber denjenigen zu sein, die behaupten eine tiefe Verbundenheit' +
        'mit der Erde zu haben und auch gegenüber denjenigen, die sich Sorgen um den Zustand unsere Natur machen.  ' +
        'Wenn Sie dazu neigen, Umweltbehauptungen skeptisch gegenüberzustehen, ist es vielleicht eine gute Idee,  ' +
        'sich mit Perspektiven auseinanderzusetzen, die sich um Unparteilichkeit bemühen, und zu sehen, was die Wissenschaft ' +
        'uns heute über diese enorm wichtigen - wenn auch hochkomplexen - Themen sagt. Es ist auch erwähnenswert, dass  ' +
        'viele verschiedene Gruppen auf der ganzen Welt, einschließlich religiöser oder glaubensbasierter Organisationen, ' +
        'zunehmend die Bedeutung der Verantwortung für die Erde und die Natur erkennen.</b> ' +
        'Wenn Sie über diese Themen nachdenken, sollten Sie bedenken, dass verschiedene Gruppen, Kulturen ' +
        'und Religionen die Beziehung zwischen Menschen, Natur und Erde aus verschiedenen Gründen, die ' +
        'ihnen völlig legitim erscheinen, unterschiedlich sehen. Es ist wichtig, sich zu ' +
        'vergegenwärtigen, worauf diese Unterschiede beruhen und warum wir uns mit diesen Themen auf die eine oder andere  ' +
        'Weise auseinandersetzen. Das liegt daran, dass unsere ökologischen Überzeugungen und Werte - und die Art und ' +
        'Weise, wie Einzelpersonen, Organisationen, Regierungen und Gesellschaften die Natur erleben, verstehen' +
        'und mit ihr umgehen - tiefgreifende Auswirkungen auf Umweltmaßnahmen, -politik und -praktiken auf der ganzen Welt  ' +
        'haben. Versuche, soweit es dir möglich ist, andere in ein respektvolles und offenes Gespräch über diese Themen zu ' +
        'verwickeln, und berufen Sie sich nicht nur auf "die Fakten", wie Sie sie verstehen. Mit anderen Worten, teilen Sie ' +
        'eigenen Überzeugungen mit, und zwar nicht nur vom intellektuellen Standpunkt aus - was natürlich sehr wichtig ist -, ' +
        'sondern auch im Hinblick darauf, warum Sie so denken, wie Sie denken. Eine solche Herangehensweise fördert eher ' +
        'ein echtes Nachdenken über Umweltthemen, die zwar abstrakt erscheinen, aber in Wirklichkeit sehr wertvoll sind, ' +
        'da sie den Kern dessen treffen, warum wir glauben, dass wir hier sind und welche Verpflichtungen wir gegenüber ' +
        'der Natur haben - oder auch nicht.',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': 'Unser letztes "großes Thema" ist Ihre Tendenz zu ' +
        'globalem Engagement oder Resonanz im weitesten Sinne. <b>Im Großen und Ganzen, ' +
        'scheinen Sie die Notwendigkeit eines Verständnisses für und eines respektvollen Umgangs mit Kulturen, Gesellschaften ' +
        'und Traditionen, die sich von unseren eigenen unterscheiden, stark zu befürworten. Eine solche Weltanschauung ' +
        'lässt vermuten, dass Sie interkulturelle und globale Chancen und Erfahrungen nutzen werden, wann immer es möglich ' +
        ' ist.</b> Wie auch immer Sie zu diesem Thema stehen, es ist wichtig, sich zu vergegenwärtigen, dass die Welt ' +
        'immer stärker miteinander verbunden ist. So können sich beispielsweise Ereignisse in einem Land (z.B. ' +
        'wirtschaftliche, politische oder ökologische) auf die Situation in einem anderen Land auswirken, auch wenn die  ' +
        'große Mehrheit der Menschen in beiden Ländern kaum direkten Kontakt miteinander hat. Außerdem sind wir dank ' +
        'der Technologie in der Lage, das Weltgeschehen direkt zu erleben und zu beeinflussen, während es passiert.  ' +
        'So sind unsere Überzeugungen und Werte in Bezug auf andere (z. B. andere Kulturen, Religionen)  ' +
        'unweigerlich damit verbunden, wie wir uns selbst und andere erleben und miteinander umgehen und wie wir   ' +
        'die verschiedenen Herausforderungen und Chancen rund um den Globus verstehen und darauf reagieren. Mit ' +
        'entsprechenden Auswirkungen für uns alle.',
    'app.individualreport.globalengagement.textB': 'Unser letztes "großes Thema" ist Ihre Tendenz zu ' +
        'globalem Engagement oder Resonanz im weitesten Sinne. <b>Im Großen und Ganzen, ' +
        'scheinen Sie die Notwendigkeit eines Verständnisses für und eines respektvollen Umgangs mit Kulturen, Gesellschaften ' +
        'und Traditionen zu schätzen, die sich von unseren eigenen unterscheiden. Eine solche Weltanschauung lässt ' +
        'vermuten, dass Sie auch neugierig auf interkulturelle und globale Chancen reagieren, wenn sie sich bieten.</b>' +
        '</b> Wie auch immer Sie zu diesem Thema stehen, es ist wichtig, sich zu vergegenwärtigen, dass die Welt ' +
        'immer stärker miteinander verbunden ist. So können sich beispielsweise Ereignisse in einem Land (z. B.' +
        'wirtschaftliche, politische oder ökologische) auf die Situation in einem anderen Land auswirken, auch wenn die  ' +
        'große Mehrheit der Menschen in beiden Ländern kaum direkten Kontakt miteinander hat. Außerdem sind wir dank ' +
        'der Technologie in der Lage, das Weltgeschehen direkt zu erleben und zu beeinflussen, während es passiert.  ' +
        'So sind unsere Überzeugungen und Werte in Bezug auf andere (z. B. andere Kulturen, Religionen)  ' +
        'unweigerlich damit verbunden, wie wir uns selbst und andere erleben und miteinander umgehen und wie wir   ' +
        'die verschiedenen Herausforderungen und Chancen rund um den Globus verstehen und darauf reagieren. Mit ' +
        'entsprechenden Auswirkungen für uns alle.',
    'app.individualreport.globalengagement.textC': 'Unser letztes "großes Thema" ist Ihre Tendenz zu ' +
        'globalem Engagement oder Resonanz im weitesten Sinne. <b>Im Großen und Ganzen ' +
        'scheinen Sie sich zu fragen, ob wir manchmal (oder sogar häufig) zu weit gehen, wenn wir versuchen, Kulturen, ' +
        'Gesellschaften und Traditionen, die sich von unseren eigenen unterscheiden, zu tolerieren oder zu beschwichtigen.</b> ' +
        'Wie auch immer Sie zu diesem Thema stehen, es ist wichtig, sich zu vergegenwärtigen, dass die Welt ' +
        'immer stärker miteinander verbunden ist. So können sich beispielsweise Ereignisse in einem Land (z. B.' +
        'wirtschaftliche, politische oder ökologische) auf die Situation in einem anderen Land auswirken, auch wenn die  ' +
        'große Mehrheit der Menschen in beiden Ländern kaum direkten Kontakt miteinander hat. Außerdem sind wir dank ' +
        'der Technologie in der Lage, das Weltgeschehen direkt zu erleben und zu beeinflussen, während es passiert.  ' +
        'So sind unsere Überzeugungen und Werte in Bezug auf andere (z. B. andere Kulturen, Religionen)  ' +
        'unweigerlich damit verbunden, wie wir uns selbst und andere erleben und miteinander umgehen und wie wir   ' +
        'die verschiedenen Herausforderungen und Chancen rund um den Globus verstehen und darauf reagieren. Mit ' +
        'entsprechenden Auswirkungen für uns alle.',
}

const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': 'niedrig',
    'app.individualreport.negativelifeevents.moderate': 'mittel',
    'app.individualreport.negativelifeevents.high': 'hoch',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': 'gut erfüllt',
    'app.individualreport.needsclosure.met_at_times': 'gelegentlich erfüllt, aber nicht immer',
    'app.individualreport.needsclosure.not_met_well': 'nicht gut erfüllt',
}

export const individualReportPageDE: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle, ...individualReportVariables,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};
