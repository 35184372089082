import React from 'react';
import search from '../../assets/images/no_info_search.png';
import { NoInfo } from '../../components/NoInfo';

export const PageNotFound = (): JSX.Element => {
    return (
        <NoInfo
            title={'app.no.info.title.not_found'}
            subTitle={'app.no.info.subtitle.not_found'}
            body={[
                'app.no.info.body.not_found'
            ]}
            image={search}
        />
    );
}