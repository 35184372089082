import { notification } from "antd";
import { useActions, useValues } from "kea";
import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";

import search from "../../assets/images/no_info_search.png";
import { DataDownloadModal } from "../../components/DataDownloadModal";
import { Loading } from "../../components/Loading";
import { NoInfo } from "../../components/NoInfo";
import { defaultNotificationDurationInSeconds } from "../../config/constants";
import {
  IntlContext,
  LocalStorage_BeviLanguage,
} from "../../IntlProviderWrapper";
import { appLogic, AppProps } from "../../redux/appLogic";
import {
  IndividualReportActions,
  individualReportLogic,
  IndividualReportProps,
} from "../../redux/individualReportLogic";
import {
  ListOrganizationActions,
  listOrganizationLogic,
  ListOrganizationProps,
} from "../../redux/listOrganizationLogic";
import {
  CREATE_PROGRAM,
  CREATE_SUBPROGRAM,
  EDIT_SUBPROGRAM,
  SUBPROGRAM_DETAILS,
} from "../../router/pages";
import {
  SearchProgramsDetails,
  SearchSubProgramsDetails,
} from "../../services/organizationService";
import { RenameProgramPayloadRequest } from "../../services/programService";
import {
  DataDownloadParams,
  ReportLanguage,
} from "../../services/reportService";
import { GetFormattedMessage } from "../../utils/htmlHelper";
import { IsSuperAdmin } from "../../utils/userHelper";
import { FilterProgram } from "./components/Filter/";
import { RenameProgramModal } from "./components/RenameProgramModal";
import { ListOrganization } from "./ListOrganization";
import { Sleep } from "../../utils/sleeptHelper";
import { Sort, SortModal } from "../../components/SortModal";
import { orderBy } from "lodash";
import { ProgramActions, ProgramProps, programLogic } from "../../redux/programLogic";

type Props = RouteComponentProps;
type Downloadstate = {
  organizationId: number;
  programId?: number;
  subProgramId?: number;
};

export const ListOrganizationContainer = withRouter(
  (props: Props): JSX.Element => {
    const { getReportLanguage } = useContext(IntlContext);

    const [selectedProgram, setSelectedProgram] = useState<any>(undefined);
    const [savedProgram, setSavedProgram] = useState<any>(undefined);
    const [loadingRenamePrograms, setLoadingRenamePrograms] =
      useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showSortModal, setShowSortModal] = useState<boolean>(false);
    const [sort, setSort] = useState<Sort>();
    const [programsSort, setProgramsSort] = useState<any[]>([]);
    const [downloadState, setDownloadInfo] = useState<Downloadstate>({
      organizationId: 0,
    });
    const [chatSuccess, setChatSuccess] = useState<boolean>(false);

    const {
      getOrganizations,
      selectProgram,
      selectSubprogram,
      searchOrganization,
      donwloadQrCodeInvitation,
      clearDownloadingQrCode,
      renameProgram,
      clearSuccessfulRenameProgram,
      updateSearchOrganizationResults,
    }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { getDataDownload }: IndividualReportActions = useActions(
      individualReportLogic
    );
    const {
      organizations,
      loadingPage,
      loadingSearchOrganizations,
      searchOrganizationResults,
      searchData,
      loadingDataDownload: loadingQrCodeDownload,
      dataDownloadError: qrCodeDownloadError,
      hasRenamedProgram,
      errorMessageRenameProgram,
    }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { loadingDataDownload }: IndividualReportProps = useValues(
      individualReportLogic
    );

    const { hasAddChatProgram, errorMessageAddChatProgram }: ProgramProps = useValues(programLogic);
    const { addChatProgram, clearSuccessfulAddChatProgram }: ProgramActions = useActions(programLogic);

    const { tokenInfo }: AppProps = useValues(appLogic);

    const qrCodeErrorNotificationHeader = GetFormattedMessage(
      "app.invitation.error_notification_header"
    );
    const qrCodeErrorNotificationBody =
      GetFormattedMessage(qrCodeDownloadError);

    useEffect(() => {
      if (searchOrganizationResults?.programs) {
        if (sort) {
          setProgramsSort(
            orderBy(
              searchOrganizationResults.programs, sort.field, sort.order
            )
          );
        } else {
          setProgramsSort(searchOrganizationResults.programs);
        }
      }
    }, [sort, searchOrganizationResults?.programs]);

    useEffect(() => {
      if (hasAddChatProgram === true)
        setChatSuccess(hasAddChatProgram);
    }, [hasAddChatProgram, errorMessageAddChatProgram]);

    useEffect(() => {
      async function clearSuccessAfterDelay() {
        await Sleep(10000);
        setChatSuccess(false);
      }

      if (chatSuccess === true) {
        clearSuccessAfterDelay();
      }
    }, [chatSuccess])

    useEffect(() => {
      if (!organizations?.length) getOrganizations && getOrganizations();
    }, [getOrganizations, organizations]);

    useEffect(() => {
      if (
        searchData.organizationId !== undefined &&
        !searchOrganizationResults?.length
      ) {
        searchOrganization(
          searchData.organizationId,
          searchData.programQuery,
          searchData.subProgramQuery
        );
      }
      // unmount
      return () => {
        clearDownloadingQrCode();
      };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!loadingDataDownload) {
        setShowModal(false);
      }
    }, [loadingDataDownload]);

    useEffect(() => {
      if (!!qrCodeDownloadError) {
        notification["error"]({
          message: qrCodeErrorNotificationHeader,
          description: qrCodeErrorNotificationBody,
          duration: defaultNotificationDurationInSeconds,
        });
      }
      // eslint-disable-next-line
    }, [qrCodeDownloadError]);

    useEffect(() => {
      if (hasRenamedProgram === true || errorMessageRenameProgram === true) {
        setLoadingRenamePrograms(false);
      }
      if (hasRenamedProgram === true) setSuccess(hasRenamedProgram);
    }, [hasRenamedProgram, errorMessageRenameProgram]);

    useEffect(() => {
      async function clearSuccessAfterDelay() {
        await Sleep(10000);
        setSuccess(false);
      }

      if (success === true) {
        clearSuccessAfterDelay();
      }
    }, [success]);

    if (loadingPage) {
      return <Loading />;
    }

    const handleCreateProgramClick = (): void => {
      props.history.push(CREATE_PROGRAM);
    };

    const handleCreateSubProgramClick = (
      program: SearchProgramsDetails
    ): void => {
      selectProgram(program);
      props.history.push(CREATE_SUBPROGRAM);
    };

    const handleSubprogramEditClick = (
      program: SearchProgramsDetails,
      subprogram: SearchSubProgramsDetails
    ): void => {
      selectProgram(program);
      selectSubprogram(subprogram);
      props.history.push(EDIT_SUBPROGRAM);
    };

    const handleSubProgramDetailsClick = (
      program: SearchProgramsDetails,
      subprogram: SearchSubProgramsDetails
    ): void => {
      selectProgram(program);
      selectSubprogram(subprogram);
      props.history.push(SUBPROGRAM_DETAILS);
    };

    const handleClickDownloadDataReport = (
      organizationId: number,
      programId?: number,
      subProgramId?: number
    ): void => {
      setDownloadInfo({
        organizationId,
        programId,
        subProgramId,
      });
      setShowModal(true);
    };

    const handleClickSort = (): void => {
      setShowSortModal(true);
    };

    const onCancelButtonClicked = () => {
      setShowModal(false);
    };

    const onSubmitButtonClicked = (
      studyAbroad: boolean,
      additionalDemographicsInfo: boolean,
      removeAnonymous: boolean
    ) => {
      const body: DataDownloadParams = {
        organizationId: downloadState.organizationId,
        programId: downloadState.programId,
        subProgramId: downloadState.subProgramId,
        removeAnonymous: removeAnonymous,
        additionalDemoInfo: additionalDemographicsInfo,
        studyAbroad: studyAbroad,
        language: localStorage.getItem(LocalStorage_BeviLanguage) ?? "en",
      };
      getDataDownload(body);
    };

    const onSearchHandleClick = (
      organizationId: number,
      programQuery: string,
      subProgramQuery: string
    ) => {
      searchOrganization(organizationId, programQuery, subProgramQuery);
    };

    const handleQrCodeDetailsClick = (subProgramId: number) => {
      const reportLanguage = getReportLanguage && getReportLanguage();
      donwloadQrCodeInvitation(
        subProgramId,
        reportLanguage ?? ReportLanguage.English
      );
    };

    const hasNoElement = () => {
      return (
        searchOrganizationResults === undefined ||
        searchOrganizationResults == null
      );
    };

    const onRenameProgramModalOpen = (program?: any) => {
      let data: RenameProgramPayloadRequest = { programId: 0, name: "" };
      if (
        program &&
        program.programId &&
        program.programId > 0 &&
        program.name &&
        program.name.length > 0
      ) {
        data.programId = program.programId;
        data.name = program.name;
      }
      setSelectedProgram(data);
    };

    const onRenameProgramModalHide = () => {
      setSelectedProgram(undefined);
    };

    const onRenameProgramHandleClick = (
      program: RenameProgramPayloadRequest
    ) => {
      setLoadingRenamePrograms(true);
      setSavedProgram(program);
      renameProgram(program);
    };

    const onSuccessfullRenameProgramModalHide = async () => {
      if (savedProgram != null) {
        let searchOrganizationResultsData = searchOrganizationResults;
        const updatedProgramList = searchOrganizationResults.programs.map(
          (program: any) => {
            if (program.id === savedProgram.programId) {
              return {
                ...program,
                name: savedProgram.name,
              };
            }
            return program;
          }
        );
        setSavedProgram(undefined);
        searchOrganizationResultsData.programs = updatedProgramList;
        updateSearchOrganizationResults(searchOrganizationResultsData);
      }
      setSelectedProgram(undefined);
      clearSuccessfulRenameProgram();
    };

    const onChatAllClick = (programId: number) => {
      clearSuccessfulAddChatProgram();
      addChatProgram({ id: programId });
    }

    return (
      <React.Fragment>
        <FilterProgram
          searchData={searchData}
          organizations={organizations}
          onSearchHandleClick={onSearchHandleClick}
        />
        {success && (
          <Alert
            variant="primary"
            onClose={() => setSuccess(false)}
            dismissible
          >
            <p
              dangerouslySetInnerHTML={{
                __html: GetFormattedMessage(
                  "app.programs.add.successful_message"
                ),
              }}
            />
          </Alert>
        )}
        {chatSuccess &&
          <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.programs.chat.successful_message') }} />
        }
        {searchData?.organizationId ===
          undefined ? null : loadingSearchOrganizations ? (
            <Loading />
          ) : hasNoElement() ? (
            <NoInfo
              title={"app.no.info.title.no_data"}
              subTitle={"app.no.info.subtitle.no_data"}
              image={search}
            />
          ) : (
          <React.Fragment>
            <ListOrganization
              organizationId={searchOrganizationResults.id}
              organizationName={searchOrganizationResults.name}
              loadingQrCodeDownload={loadingQrCodeDownload}
              programs={programsSort}
              onCreateProgramClick={handleCreateProgramClick}
              onCreateSubProgramClick={handleCreateSubProgramClick}
              onSubProgramEditClick={handleSubprogramEditClick}
              onDetailsSubProgramClick={handleSubProgramDetailsClick}
              handleClickDownloadDataReport={handleClickDownloadDataReport}
              showDataDownloadButton={IsSuperAdmin(tokenInfo)}
              onQrCodeDetailsClick={handleQrCodeDetailsClick}
              onRenameProgramModalOpen={onRenameProgramModalOpen}
              handleClickSort={handleClickSort}
              onChatAllClick={onChatAllClick}
              userInfo={tokenInfo}
            />
            <DataDownloadModal
              showModal={showModal}
              loadingButton={loadingDataDownload}
              onModalHide={onCancelButtonClicked}
              onDataDownload={onSubmitButtonClicked}
            />
            <RenameProgramModal
              program={selectedProgram}
              loadingButton={loadingRenamePrograms}
              showModal={selectedProgram !== undefined}
              onModalHide={onRenameProgramModalHide}
              onRenameProgramHandleClick={onRenameProgramHandleClick}
              onSuccessfullRenameProgramModalHide={
                onSuccessfullRenameProgramModalHide
              }
              success={hasRenamedProgram === true}
              error={errorMessageRenameProgram === true}
            />
          </React.Fragment>
        )}
        <SortModal
          showModal={showSortModal}
          onModalHide={() => setShowSortModal(false)}
          sort={sort}
          setSort={setSort}
          sortField={[
            {
              field: "name",
              name: "app.create_program.table.column_name",
            },
            {
              field: "createdOnUtc",
              name: "app.listorganizations.created.on",
            },
          ]}
        />
      </React.Fragment>
    );
  }
);
