import { appZHCN } from './zh_CN/app';
import { consentPageZHCN } from './zh_CN/consent';
import { counterPageZHCN } from './zh_CN/counter';
import { createReportZHCN } from './zh_CN/create_report';
import { createProgramZHCN } from './zh_CN/createProgram';
import { dataDownloadZHCN } from './zh_CN/dataDownload';
import { demographicQuestionsZHCN } from './zh_CN/demographicQuestions';
import { discursiveQuestionsZHCN } from './zh_CN/discursiveQuestions';
import { externalZHCN } from './zh_CN/external';
import { individualReportPageZHCN } from './zh_CN/individualReport';
import { internalTemplatePageZHCN } from './zh_CN/internalTemplate';
import { listFormsPageZHCN } from './zh_CN/listforms';
import { listOrganizationsZHCN } from './zh_CN/listOrganizations';
import { listReportZHCN } from './zh_CN/listReport';
import { loginPageZHCN } from './zh_CN/login';
import { noInfoZHCN } from './zh_CN/noInfo';
import { permissionZHCN } from './zh_CN/permission';
import { reportsZHCN } from './zh_CN/reports';
import { scaleQuestionsPageZHCN } from './zh_CN/scaleQuestions';
import { timeZHCN } from './zh_CN/time';
import { usersZHCN } from './zh_CN/users';
import { PageMessages } from './types';
import { financialReportZHCN } from './zh_CN/financialReport';
import { invitationZHCN } from './zh_CN/invitation';
import { institutionZHCN } from './zh_CN/institutions';
import { programsZHCN } from './zh_CN/programs';
import { virtualAccountZHCN } from './zh_CN/virtualAccount';

export const zhCN: PageMessages = {
  ...appZHCN,
  ...counterPageZHCN,
  ...loginPageZHCN,
  ...scaleQuestionsPageZHCN,
  ...consentPageZHCN,
  ...listFormsPageZHCN,
  ...demographicQuestionsZHCN,
  ...listOrganizationsZHCN,
  ...discursiveQuestionsZHCN,
  ...individualReportPageZHCN,
  ...internalTemplatePageZHCN,
  ...createProgramZHCN,
  ...usersZHCN,
  ...dataDownloadZHCN,
  ...noInfoZHCN,
  ...createReportZHCN,
  ...reportsZHCN,
  ...timeZHCN,
  ...listReportZHCN,
  ...permissionZHCN,
  ...externalZHCN,
  ...financialReportZHCN,
  ...invitationZHCN,
  ...institutionZHCN,
  ...programsZHCN,
  ...virtualAccountZHCN
}
