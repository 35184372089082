import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ScaleGraphBarList } from './ScaleGraphBarList';
import { GroupReportProfileContrast } from '../../../services/types';
import { ScaleGroup } from '../../../services/reportService';
import { IntlContext } from '../../../IntlProviderWrapper';
import { getScaleTitle } from '../../../utils/reportHelper';
import { isNil } from 'lodash';

type Props = {
    profileContrast: GroupReportProfileContrast[];
    subGroupName?: string[];
}

type ScaleProps = {
    title: string;
    value: number;
    hasSpace: boolean;
}

type TitleProps = {
    title: string | undefined;
    subTitle: string[];
}

type ProfileItem = {
    title: TitleProps[];
    hasHR: boolean;
    negativeLifeEvents: ScaleProps[];
    needsClosure: ScaleProps[];
    needsFulfillment: ScaleProps[];
    identityDiffusion: ScaleProps[];
    basicOpenness: ScaleProps[];
    selfCertitude: ScaleProps[];
    basicDeterminism: ScaleProps[];
    socioemotionalConvergence: ScaleProps[];
    physicalResonance: ScaleProps[];
    emotionalAttunument: ScaleProps[];
    selfAwereness: ScaleProps[];
    meaningQuest: ScaleProps[];
    religiousTraditionalism: ScaleProps[];
    genderTraditionalism: ScaleProps[];
    socioCulturalOpenness: ScaleProps[];
    ecologicalResonance: ScaleProps[];
    globalResonance: ScaleProps[];
}

export const ProfileContrast = (props: Props): JSX.Element => {
    console.log('ENTROU AQU')
    const { messages } = React.useContext(IntlContext);

    const buildTitle = (profileContrast: GroupReportProfileContrast[], subGroupName?: string[]) => {
        const title: TitleProps[] = [];

        let subTitle: string[] = [];
        let i = 0;
        if(isNil(subGroupName)) {
            for (i = 0; i < profileContrast.length; i++) {
                const profile = profileContrast[i];
                const name = '';
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.lowest_optimal_profiles'].toString() + `<br /> ( ${name && name + ' - '}N = ${profile.lowNumberOfUsers})`);
            }
        } else {
            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.lowest_optimal_profiles'].toString() + `<br /> ( ${groupName && groupName + ' - '}N = ${profile.lowNumberOfUsers})`);
            }
        }

        title.push({
            //@ts-ignore
            title: messages['app.report.profile_contrast.lowest'].toString(),
            //@ts-ignore
            subTitle: subTitle
        })

        subTitle = [];
        if(isNil(subGroupName)) {
            for (i = 0; i < profileContrast.length; i++) {
                const profile = profileContrast[i];
                const name = '';
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.middle_optimal_profiles'].toString() + `<br /> ( ${name && name + ' - '}N = ${profile.middleNroOfUsers})`);
            }
        } else {
            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.middle_optimal_profiles'].toString() + `<br /> ( ${groupName && groupName + ' - '}N = ${profile.middleNroOfUsers})`);
            }
        }

        title.push({
            //@ts-ignore
            title: messages['app.report.profile_contrast.middle'].toString(),
            //@ts-ignore
            subTitle: subTitle,
        })

        subTitle = [];
        if(isNil(subGroupName)) {
            for (i = 0; i < profileContrast.length; i++) {
                const profile = profileContrast[i];
                const name = '';
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.highest_optimal_profiles'].toString() + `<br /> ( ${name && name + ' - '}N = ${profile.highNumberOfUsers})`);
            }
        } else {
            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];
                //@ts-ignore
                subTitle.push(messages['app.report.profile_contrast.highest_optimal_profiles'].toString() + `<br /> ( ${groupName && groupName + ' - '}N = ${profile.highNumberOfUsers})`);
            }
        }

        title.push({
            //@ts-ignore
            title: messages['app.report.profile_contrast.highest'].toString(),
            //@ts-ignore
            subTitle: subTitle,
        })

        return title;
    }

    const buildScales = (profileContrast: GroupReportProfileContrast[], scale: ScaleGroup, scaleNumber: number, subGroupName?: string[]) => {
        const scalePropos: ScaleProps[] = [];
        const scaleTitle = getScaleTitle(scale);

        if(!isNil(subGroupName)) {
            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${groupName && groupName + ' - '}${messages['app.report.profile_contrast.lowest'].toString()})`,
                    value: profile.scales[scale][0],
                    hasSpace: index === 0 && groupName !== ''
                });
            }

            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${groupName && groupName + ' - '}${messages['app.report.profile_contrast.middle'].toString()})`,
                    value: profile.scales[scale][1],
                    hasSpace: index === 0 && groupName !== ''
                });
            }

            for (let index = 0; index < subGroupName.length; index++) {
                const groupName = subGroupName[index];
                const profile = profileContrast.filter(t => t.groupName === groupName)[0];

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${groupName && groupName + ' - '}${messages['app.report.profile_contrast.highest'].toString()})`,
                    value: profile.scales[scale][2],
                    hasSpace: index === 0 && groupName !== ''
                });
            }
           
        } else {
            for (let i = 0; i < profileContrast.length; i++) {
                const name = '';
                const profile = profileContrast[i];

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${name && name + ' - '}${messages['app.report.profile_contrast.lowest'].toString()})`,
                    value: profile.scales[scale][0],
                    hasSpace: i === 0 && name !== ''
                });
            }

            for (let i = 0; i < profileContrast.length; i++) {
                const profile = profileContrast[i];
                const name = '';

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${name && name + ' - '}${messages['app.report.profile_contrast.middle'].toString()})`,
                    value: profile.scales[scale][1],
                    hasSpace: i === 0 && name !== ''
                });
            }

            for (let i = 0; i < profileContrast.length; i++) {
                const profile = profileContrast[i];
                const name = '';

                scalePropos.push({
                    //@ts-ignore
                    title: `${scaleNumber}. ${messages[scaleTitle].toString()} (${name && name + ' - '}${messages['app.report.profile_contrast.highest'].toString()})`,
                    value: profile.scales[scale][2],
                    hasSpace: i === 0 && name !== ''
                });
            }
        }

        return scalePropos;
    }

    return (
        <React.Fragment>
            <ProfileContrastDetails
                hasHR={true}
                title={buildTitle(props.profileContrast, props.subGroupName)}
                negativeLifeEvents={buildScales(props.profileContrast, ScaleGroup.NegativeLifeEvents, 1, props.subGroupName)}
                needsClosure={buildScales(props.profileContrast, ScaleGroup.NeedsClosure, 2, props.subGroupName)}
                needsFulfillment={buildScales(props.profileContrast, ScaleGroup.NeedsFulfillment, 3, props.subGroupName)}
                identityDiffusion={buildScales(props.profileContrast, ScaleGroup.IdentityDiffusion, 4, props.subGroupName)}
                basicOpenness={buildScales(props.profileContrast, ScaleGroup.BasicOpenness, 5, props.subGroupName)}
                selfCertitude={buildScales(props.profileContrast, ScaleGroup.SelfCertitude, 6, props.subGroupName)}
                basicDeterminism={buildScales(props.profileContrast, ScaleGroup.BasicDeterminism, 7, props.subGroupName)}
                socioemotionalConvergence={buildScales(props.profileContrast, ScaleGroup.SocioemotionalConvergence, 8, props.subGroupName)}
                physicalResonance={buildScales(props.profileContrast, ScaleGroup.PhysicalResonance, 9, props.subGroupName)}
                emotionalAttunument={buildScales(props.profileContrast, ScaleGroup.EmotionalAttunement, 10, props.subGroupName)}
                selfAwereness={buildScales(props.profileContrast, ScaleGroup.SelfAwereness, 11, props.subGroupName)}
                meaningQuest={buildScales(props.profileContrast, ScaleGroup.MeaningQuest, 12, props.subGroupName)}
                religiousTraditionalism={buildScales(props.profileContrast, ScaleGroup.ReligiousTraditionalism, 13, props.subGroupName)}
                genderTraditionalism={buildScales(props.profileContrast, ScaleGroup.GenderTraditionalism, 14, props.subGroupName)}
                socioCulturalOpenness={buildScales(props.profileContrast, ScaleGroup.SocioculturalOpenness, 15, props.subGroupName)}
                ecologicalResonance={buildScales(props.profileContrast, ScaleGroup.EcologicalResonance, 16, props.subGroupName)}
                globalResonance={buildScales(props.profileContrast, ScaleGroup.GlobalResonance, 17, props.subGroupName)}
            />
        </React.Fragment>
    )
}

const styles = {
    marginTop10: {
        marginTop: 10,
    } as React.CSSProperties,
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
    } as React.CSSProperties,
};

const ProfileContrastDetails = (props: ProfileItem): JSX.Element => {
    return (
        <React.Fragment>
            <Row style={styles.marginTop10}>
                {props.title.map((item, index) =>
                    <Col style={styles.title} key={`Title-${index}`}>
                        <h3>{item.title}</h3> <br />
                        {item.subTitle.map((sub, index) =>
                            <div dangerouslySetInnerHTML={{ __html: `<h5 key={'SubTitle-${index}'}>${sub}</h5>` }} key={index}></div>
                        )}
                    </Col>
                )}
            </Row>
            <hr />
            <hr />
            <ScaleGraphBarList
                {...props}
                hasHR={true}
            />
        </React.Fragment>
    )
}