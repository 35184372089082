import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

import { colors } from '../config/colors';
import { GetFormattedMessage } from '../utils/htmlHelper';

export const Loading = (): JSX.Element => {

    return (
        <div style={styles.divBackground}>
            <h5 style={styles.textLoading}> {GetFormattedMessage('app.loading')} </h5>
            <SyncLoader color={colors.darkSlateBlue} size={5} margin={3} />
        </div>
    );
}

const styles = {
    divBackground: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    } as React.CSSProperties,
    textLoading: {
        color: colors.darkSlateBlue
    } as React.CSSProperties,
};
