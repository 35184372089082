import Table from 'antd/lib/table';
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { FaTimes } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { SelectItem } from '../../components/FormDropdown';
import { Loading } from '../../components/Loading';
import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { colors } from '../../config/colors';
import { paginationDefaultPageSize } from '../../config/constants';
import { createProgramMessageIds } from '../../locales/types';
import { SendProgram } from '../../services/programService';
import { OrganizationUser } from '../../services/usersService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { AssociateUsersHeader } from './components/AssociateUsersHeader';

type Props = {
    organizationName: string;
    program: SendProgram;
    isSubProgram: boolean;
    availableUsers: SelectItem[];
    selectedUsers: OrganizationUser[];
    allOrganizationUsers: OrganizationUser[];
    loading: boolean;
    uploadingUsers: boolean;
    error?: string;
    loadingUserSubProgram?: boolean;
    onProgramValuesUpdated: (newValue: Partial<SendProgram>) => void;
    onUserSelected: (userId: number) => void;
    onSaveButtonClicked: (alertUsers: boolean) => void;
    onBackButtonClicked: () => void;
    onUserRemoveClicked: (userId: number) => void;
    onClearAllUserClicked: () => void;
    onUseUsersFromLastSubProgramClicked: () => void;
    openDropzone(): void;
    getRootProps(props?: DropzoneRootProps): DropzoneRootProps;
    getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
};

// TODO(rodrigo.santos): use the error property.
export const CreateProgram = (
    {
        organizationName, program, availableUsers, selectedUsers, uploadingUsers, loading, allOrganizationUsers, loadingUserSubProgram,
        onUserSelected, onSaveButtonClicked, onUserRemoveClicked, onClearAllUserClicked, isSubProgram,
        onUseUsersFromLastSubProgramClicked, onProgramValuesUpdated, onBackButtonClicked,
        openDropzone, getRootProps, getInputProps,
    }: Props
): JSX.Element => {
    const [alertUsers, setAlertUsers] = useState<boolean>(false);
    const [forceDropdownUpdate, setForceDropdownUpdate] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    if (loading) return <Loading />

    const dueDate = program.dueDate ? (new Date(program.dueDate)).toISOString().slice(0, 10) : "";
    const actionRemoveTooltip = GetFormattedMessage('app.create_program.tooltip.action_remove');

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder')
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search')
    const searchResetButtonText = GetFormattedMessage('app.btn_reset')
    const tableColumns: ColumnType<OrganizationUser>[] = [
        {
            title: GetFormattedMessage('app.create_program.table.column_name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a: OrganizationUser, b: OrganizationUser) => a.name.localeCompare(b.name),
            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithInput
                    inputPlaceholder={searchInputPlaceholder}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                    {...props}
                />
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            onFilter: (value, record: OrganizationUser) =>
                record.name.toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: GetFormattedMessage('app.create_program.table.column_email'),
            dataIndex: 'login',
            key: 'login',
            sorter: (a: OrganizationUser, b: OrganizationUser) => a.login.localeCompare(b.login),
            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithInput
                    inputPlaceholder={searchInputPlaceholder}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                    {...props}
                />
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            onFilter: (value, record: OrganizationUser) =>
                record.login.toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: GetFormattedMessage('app.create_program.table.column_actions'),
            key: 'action',
            align: 'center',
            render: (_: string, record: OrganizationUser) => (
                <OverlayTrigger
                    key={`trigger-${record.login}-${record.id}`}
                    placement={'right'}
                    trigger={["hover", "hover"]}
                    overlay={
                        <Tooltip id={`${record.name}-${record.id}`}>
                            {`${actionRemoveTooltip} ${record.name}`}
                        </Tooltip>
                    }
                >
                    <Button
                        key={`btn-${record.login}-${record.id}`}
                        // @ts-ignore
                        style={styles.associateUserButtonAction}
                        className=' bg-transparent shadow-none'
                        onClick={() => {
                            setForceDropdownUpdate(forceDropdownUpdate + 1);
                            onUserRemoveClicked(record.id);
                        }}
                    >
                        <FaTimes color='red' />
                    </Button>
                </OverlayTrigger>
            ),
        }
    ];

    // TODO(rodrigo.santos): Validate the form when trying to submit.
    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <Form>
                    <Form.Group className='mb-3' controlId='formOrganization'>
                        <Form.Label>{GetFormattedMessage('app.create_program.lbl_organization_name')}</Form.Label>
                        <Form.Control type='text' value={organizationName} disabled />
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='formProgram'>
                        <Form.Label>{GetFormattedMessage('app.create_program.lbl_program_name')}</Form.Label>
                        <Form.Control
                            type='text'
                            value={program.programName}
                            disabled={isSubProgram}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onProgramValuesUpdated({ programName: e.target.value });
                            }}
                        />
                    </Form.Group>

                    <Form.Group className='mb-3' as={Row} controlId='subProgramLine'>
                        <Form.Group className='mb-3' as={Col} sm='6' controlId='formSubProgram'>
                            <Form.Label>{GetFormattedMessage('app.create_program.lbl_sub_program_name')}</Form.Label>
                            <Form.Control
                                type='text'
                                value={program.subProgramName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onProgramValuesUpdated({ subProgramName: e.target.value });
                                }}
                                required
                            />
                        </Form.Group>

                        {/* <Form.Group className='mb-3' as={Col} sm='2' controlId='formYear'>
                            <Form.Label>{GetFormattedMessage('app.create_program.lbl_year')}</Form.Label>
                            <FormNumericInput
                                value={program.year}
                                maxValue={9999}
                                onValueChange={(value: number | undefined) => {
                                    onProgramValuesUpdated({ year: value?.toString() });
                                }}
                            />
                        </Form.Group> */}

                        <Form.Group className='mb-3' as={Col} sm='6' controlId='formDueDate'>
                            <Form.Label>{GetFormattedMessage('app.create_program.lbl_due_date')}</Form.Label>
                            <Form.Control
                                value={dueDate}
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onProgramValuesUpdated({ dueDate: e.target.value });
                                }}
                            />
                        </Form.Group>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='formAssociateUsers'>
                        <section style={styles.associateUserContainer}>
                            <AssociateUsersHeader
                                program={program}
                                availableUsers={availableUsers}
                                selectedUsers={selectedUsers}
                                organizationName={organizationName}
                                allOrganizationUsers={allOrganizationUsers}
                                uploadingUsers={uploadingUsers}
                                onUserSelected={onUserSelected}
                                onClearAllUserClicked={onClearAllUserClicked}
                                onUseUsersFromLastSubProgramClicked={onUseUsersFromLastSubProgramClicked}
                                dropdownKey={`dropdown-${forceDropdownUpdate}`}
                                openDropzone={openDropzone}
                                getInputProps={getInputProps}
                                getRootProps={getRootProps}
                            />
                            {!uploadingUsers && selectedUsers.length > 0 &&
                                <React.Fragment>
                                    <Form.Group className='mb-3' controlId='formTable'>
                                        <Table
                                            columns={tableColumns}
                                            dataSource={selectedUsers}
                                            loading={loadingUserSubProgram}
                                            pagination={selectedUsers.length > paginationDefaultPageSize ? defaultPagination : false}
                                            rowKey={(record: OrganizationUser) => `row-${record.login}-${record.id}`}
                                        />
                                    </Form.Group>
                                </React.Fragment>
                            }
                        </section>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formAlertUser'>
                        <Form.Check
                            type='checkbox'
                            label={GetFormattedMessage('app.create_program.lbl_alert_all_users')}
                            onChange={() => {
                                onProgramValuesUpdated({ alertUsersByEmail: !alertUsers });
                                setAlertUsers(!alertUsers);
                            }}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="formEmailMessage">
                        <Form.Label>{GetFormattedMessage('app.create_program.lbl_email_message')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            disabled={!alertUsers}
                            value={program.emailMessage ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onProgramValuesUpdated({ emailMessage: e.target.value });
                            }}
                        />
                    </Form.Group>
                </Form>

                <div style={{ flexDirection: 'row' }} className='button'>
                    <Button
                        // @ts-ignore
                        style={styles.btnBack}
                        variant='primary'
                        onClick={() => onBackButtonClicked()}
                    >
                        <FormattedMessage id={'app.btn_back'} />
                    </Button>
                    <Button
                        disabled={
                            loading || uploadingUsers || !dueDate ||
                            !program.year || !program.programName || !program.subProgramName
                        }
                        // @ts-ignore
                        style={styles.btnSave}
                        variant='primary'
                        onClick={() => onSaveButtonClicked(alertUsers)}
                    >
                        {loading
                            ? <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            : (alertUsers
                                ? <FormattedMessage id={createProgramMessageIds['app.create_program.btn_submit_and_send_email']} />
                                : <FormattedMessage id={createProgramMessageIds['app.create_program.btn_submit']} />
                            )
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '900px',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    btnSave: {
        width: '150px',
        float: 'right',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    btnBack: {
        width: '150px',
        float: 'left',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    associateUserContainer: {
        padding: '20px 60px',
    } as React.CSSProperties,
    associateUserButtonAction: {
        borderWidth: 0,
    } as React.CSSProperties,
};
