import { useValues, useActions } from 'kea';
import React, { Fragment, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HideDemographicsQuestions } from './HideDemographicsQuestions';
import { ORGANIZATION_PAGE } from '../../router/pages';
import { appLogic, AppProps } from '../../redux/appLogic';
import { Loading } from '../../components/Loading';
import {
    demographicQuestionsLogic, DemographicQuestionsActions, DemographicQuestionsProps
} from '../../redux/demographicQuestionsLogic';
import {
    listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import { Sleep } from '../../utils/sleeptHelper';
import { DemographicsQuestionsConfigurationModel } from '../../services/organizationService';

type Props = RouteComponentProps;

export const HideDemographicsQuestionsContainer = withRouter((props: Props): JSX.Element => {

    const { tokenInfo }: AppProps = useValues(appLogic);
    const { selectedInstitutionId }: ListOrganizationProps = useValues(listOrganizationLogic);
    const {
        demographicsQuestionsConfiguration, loadingDemographicsQuestionsConfiguration, hasSavedDemographicsQuestionsConfiguration, errorMessageSaveDemographicsQuestionsConfiguration
    }: DemographicQuestionsProps = useValues(demographicQuestionsLogic);
    const { loadDemographicsQuestionsConfiguration, saveDemographicsQuestionsConfiguration, clearSuccessfulSaveDemographicsQuestionsConfiguration }: DemographicQuestionsActions = useActions(demographicQuestionsLogic);

    const [loadingSaveDemographicsQuestionsConfiguration, setLoadingSaveDemographicsQuestionsConfiguration] = useState<boolean>(false);
    const [demographicsQuestions, setDemographicsQuestions] = useState<any>(undefined);
    const [success, setSuccess] = useState<boolean>(false);

    // Load Demographics Questions Configuration Effects
    useEffect(() => {
        if (selectedInstitutionId === null) {
            props.history.replace(ORGANIZATION_PAGE);
        }
    }, [selectedInstitutionId, props.history]);

    useEffect(() => {
        loadDemographicsQuestionsConfiguration(selectedInstitutionId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (demographicsQuestionsConfiguration !== undefined && demographicsQuestionsConfiguration !== null)
            setDemographicsQuestions(demographicsQuestionsConfiguration);
    }, [demographicsQuestionsConfiguration]);

    // Save Demographics Questions Configuration Effects
    useEffect(() => {
        if ((hasSavedDemographicsQuestionsConfiguration === true) || errorMessageSaveDemographicsQuestionsConfiguration === true) {
            setLoadingSaveDemographicsQuestionsConfiguration(false);
        }
        if (hasSavedDemographicsQuestionsConfiguration === true)
            setSuccess(hasSavedDemographicsQuestionsConfiguration);
    }, [hasSavedDemographicsQuestionsConfiguration, errorMessageSaveDemographicsQuestionsConfiguration]);

    const onSuccessfullSaveHideDemographicsQuestions = () => {
        clearSuccessfulSaveDemographicsQuestionsConfiguration();
        loadDemographicsQuestionsConfiguration(selectedInstitutionId)
    }

    useEffect(() => {
        async function clearSuccessAfterDelay() {
            await Sleep(5000);
            setSuccess(false);
            onSuccessfullSaveHideDemographicsQuestions()
        }

        if (success === true) {
            window.scrollTo(0, 0);
            clearSuccessAfterDelay();
        }
        // eslint-disable-next-line
    }, [success]);

    const handleDemographicsQuestionsUpdate = (newValue: Partial<DemographicsQuestionsConfigurationModel>): void => {
        setDemographicsQuestions({ ...demographicsQuestions, ...newValue });
    }

    const handleSaveButtonClick = () => {
        setLoadingSaveDemographicsQuestionsConfiguration(true);
        saveDemographicsQuestionsConfiguration(demographicsQuestions);
    };

    if (loadingDemographicsQuestionsConfiguration) return <Loading />

    return (
        demographicsQuestions !== undefined
            ? (
                <Fragment>
                    <HideDemographicsQuestions
                        userInfo={tokenInfo}
                        demographicsQuestionsData={demographicsQuestions}
                        loadingButton={loadingSaveDemographicsQuestionsConfiguration}
                        onHideDemographicsQuestionsValuesUpdated={handleDemographicsQuestionsUpdate}
                        onSaveButtonClicked={handleSaveButtonClick}
                        onSuccessfullSaveHideDemographicsQuestions={onSuccessfullSaveHideDemographicsQuestions}
                        success={success}
                    />
                </Fragment>
            ) : (
                <Loading />
            )
    );
});