import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { Reports, ReportsGroup } from '../../../services/reportService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { IsProgramAdmin } from '../../../utils/userHelper';
import { TokenInfo } from '../../../services/loginService';

export type SelectedSubProgramsData = {
  organizationName: string;
  programName: string;
  subProgramId: number;
  subProgramName: string;
};

type Props = {
  userInfo: TokenInfo;
  reportName?: string;
  reportType?: Reports;
  onReportNameChanged: (reportName: string) => void;
  onReportTypeChanged: (reportType?: Reports) => void;
};

export const Step1 = (
  {
    userInfo, reportName, reportType,
    onReportNameChanged, onReportTypeChanged
  }: Props
): JSX.Element => {
  const [group, setGroup] = useState<ReportsGroup>();

  const reportTypeOptions = useMemo(() => {
    return GetReportTypeOptions(group);
  }, [group]);
  const reportGroupOptions = GetReportGroupOptions(userInfo);
  const reportTypeSelectItem = GetReportTypeSelectItem(reportTypeOptions, reportType);

  return (
    // @ts-ignore
    <Form style={styles.formDiv}>
      <Form.Group className='mb-3' as={Row}>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Label><b>{GetFormattedMessage('app.create_report.name')}</b></Form.Label>
            <Form.Control
              style={{ height: 38 }}
              value={reportName ?? ""}
              placeholder={GetFormattedMessage('app.create_report.placeholder.name')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onReportNameChanged(e.target.value)
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Label>
              <b>{GetFormattedMessage('app.create_report.group')}</b>
            </Form.Label>
            <FormDropdown
              isSearchable={true}
              defaultOptionId={'app.create_report.select.group'}
              onValueSelect={(value: any) => {
                setGroup(value);
                onReportTypeChanged(undefined);
              }}
              optionItens={reportGroupOptions}
            />
          </Form.Group>
        </Col>
        <Col md={{ span: 4 }}>
          <Form.Group className='mb-3'>
            <Form.Label>
              <b>{GetFormattedMessage('app.create_report.report_type')}</b>
            </Form.Label>
            <FormDropdown
              value={reportTypeSelectItem ?? null}
              isSearchable={true}
              isDisabled={reportTypeOptions.length === 0}
              defaultOptionId={'app.create_report.select.report_type'}
              onValueSelect={(value: number | undefined) => {
                onReportTypeChanged(value);
              }}
              optionItens={reportTypeOptions}
            />
          </Form.Group>
        </Col>
      </Form.Group>
    </Form>
  )
}

function GetReportTypeOptions(group?: ReportsGroup): SelectItem[] {
  if (ReportsGroup.individual === group) {
    return ([
      {
        value: Reports.individual_report,
        label: GetFormattedMessage('app.create_report.individual_report'),
      },
      {
        value: Reports.longitudinal_individual_report,
        label: GetFormattedMessage('app.create_report.longitudinal_individual_report'),
      },
    ]);
  } else if (ReportsGroup.group === group) {
    return ([
      {
        value: Reports.group_report,
        label: GetFormattedMessage('app.create_report.group_report'),
      },
      {
        value: Reports.longitudinal_report,
        label: GetFormattedMessage('app.create_report.longitudinal_report'),
      },
      {
        value: Reports.comparative_by_group_report,
        label: GetFormattedMessage('app.create_report.comparative_by_group_report'),
      },
      {
        value: Reports.comparative_by_individual,
        label: GetFormattedMessage('app.create_report.comparative_by_individual_report'),
      },
    ]);
  } else if (ReportsGroup.partner === group) {
    return ([
      {
        value: Reports.partner_report,
        label: GetFormattedMessage('app.create_report.combined_report'),
      },
      {
        value: Reports.partner_change_report,
        label: GetFormattedMessage('app.create_report.partner_change_report'),
      },
    ]);
  }
  return ([]);
}

function GetReportGroupOptions(userInfo: TokenInfo): SelectItem[] {
  if (IsProgramAdmin(userInfo)) {
    return ([
      {
        value: ReportsGroup.group,
        label: GetFormattedMessage('app.create_report.group'),
      }
    ])
  };

  return ([
    {
      value: ReportsGroup.individual,
      label: GetFormattedMessage('app.create_report.individual'),
    },
    {
      value: ReportsGroup.partner,
      label: GetFormattedMessage('app.create_report.partner'),
    },
    {
      value: ReportsGroup.group,
      label: GetFormattedMessage('app.create_report.group'),
    },
  ]);
}

function GetReportTypeSelectItem(options: SelectItem[], reportType?: Reports): SelectItem | undefined {
  if (reportType === undefined) return undefined;
  return options.find((item: SelectItem) => item.value === reportType);
}

const styles = {
  formDiv: {
    paddingTop: '15px',
  } as React.CSSProperties,
  alignCenter: {
    textAlign: 'center'
  } as React.CSSProperties,
  btnNext: {
    float: 'right',
    backgroundColor: colors.darkSlateBlue,
  } as React.CSSProperties,
}
