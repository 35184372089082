import { useActions, useValues } from 'kea';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loading } from '../../components/Loading';
import {
    IndividualReportActions, individualReportLogic, IndividualReportProps
} from '../../redux/individualReportLogic';
import { GetDate } from '../../utils/dateHelper';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import {
    GetAccessLargeWorld, GetAccessSelf, GetAccessToThoughtsAndFeelingsOfOthers, GetGenderTradition,
    GetNeedsClosure, GetNegativeLifeEvents, GetToleranceOfDisequilibruim, GetVariables,
    GetWhyWeDoText
} from '../../utils/reportHelper';
import { IndividualReport } from './IndividualReport';
import { DemographicsSection, HeaderReportProps, TitleReportProps } from './ReportTypes';
import {
    FormalEducationOptionsByCode, GetCountryByCode, GetEthnicOrientationByCode, GetGenderByCode, GetLanguageOptionsByCode,
    GetLevelOfFluencyOptionsByCode, GetLevelOfInterestByCode, GetMaritalStatusByCode, GetParticipatedExperiencesOptionsByCode,
    GetParticipatedLearningCoursesOptionsByCode, GetPlanParticipateExperiencesOptionsByCode,
    GetPlanParticipateLearningCoursesOptionsByCode, GetPrimaryAreaOfWorkByCode, GetReligionByCode,
    GetTimeSpentInOtherCountriesOptionsByCode, GetYearOfEducationOptionsByCode
} from '../../utils/demographHelper';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ReportLanguage } from '../../services/reportService';
import { IntlContext } from '../../IntlProviderWrapper';
import { formLogic, FormsProps } from '../../redux/formsLogic';
import { BeingBevi } from '../../components/BeingBevi';

type Props = RouteComponentProps;

enum QuestionType {
    StronglyAgree,
    StronglyDisagree,
}

enum DiscusiveQuestionType {
    Response1,
    Response2,
    Response3,
}

export const IndividualReportContainer = withRouter((props: Props): JSX.Element => {
    const [agreed, setAgreed] = useState<boolean>(false);
    const [showCustomAgreementModal, setShowCustomAgreementModal] = useState<boolean>(false);
    const { formId }: FormsProps = useValues(formLogic);
    const { getReportLanguage } = useContext(IntlContext);
  
    const {
        getIndividualReport,
        setIndividualReportAgreement,
        setCustomAgreementConsentResponse,
        downloadIndividualReport,
    }: IndividualReportActions = useActions(individualReportLogic);
    const { loading, error, individualReport, loadingDataDownload, dataDownloadError }: IndividualReportProps = useValues(individualReportLogic);

    useEffect(() => {
        getIndividualReport && getIndividualReport();
    }, [getIndividualReport]);

    useEffect(() => {
        if (!!individualReport?.customAgreement) {
            setShowCustomAgreementModal(true);
        }
    }, [individualReport, setShowCustomAgreementModal]);

    const handleAgreedButtonClick = () => {
        setIndividualReportAgreement();
        setAgreed(true);
    }

    const customAgreementModalResponse = (accepted: boolean) => {
        setCustomAgreementConsentResponse(accepted);
        setShowCustomAgreementModal(false);
    }

    // TODO(rubens | rodrigo): Change to the error component.
    if (loading) {
        return <Loading />
    }

    const buildDiscursiveQuestions = (type: DiscusiveQuestionType) => {
        if (type === DiscusiveQuestionType.Response1) {
            let answer = individualReport.discusiveAnswer1 ?? 'Did not answer';
            return {
                title: `<b>Question - ${GetFormattedMessage('app.discursive_questions.question1')}</b>`,
                value: `Answer - ${answer}`
            }
        }
        else if (type === DiscusiveQuestionType.Response2) {
            let answer = individualReport.discusiveAnswer2 ?? 'Did not answer';
            return {
                title: `<b>Question - ${GetFormattedMessage('app.discursive_questions.question2')}</b>`,
                value: `Answer - ${answer}`
            }
        }
        else {
            let answer = individualReport.discusiveAnswer2 ?? 'Did not answer';
            return {
                title: `<b>Question - ${GetFormattedMessage('app.discursive_questions.question3')}</b>`,
                value: `Answer - ${answer}`
            }
        }
    }

    const buildScaleQuestions = (type: QuestionType) => {
        let questions = [];
        if (type === QuestionType.StronglyAgree) {
            if (individualReport.scalesStronglyAgree === undefined || individualReport.scalesStronglyAgree === null ||
                individualReport.scalesStronglyAgree.questions === undefined || individualReport.scalesStronglyAgree.questions === null) {
                return undefined;
            }
            questions = individualReport.scalesStronglyAgree.questions;
        } else {
            if (individualReport.scalesStronglyDisagree === undefined || individualReport.scalesStronglyDisagree === null ||
                individualReport.scalesStronglyDisagree.questions === undefined || individualReport.scalesStronglyDisagree.questions === null) {
                return undefined;
            }
            questions = individualReport.scalesStronglyDisagree.questions;
        }

        questions.sort((a: any, b: any) => parseFloat(a.order) - parseFloat(b.order));
        return questions.map((item: any) => `${item.order} - ${GetFormattedMessage(item.localeKey)}`);
    }

    const buildDemographicsSection = () => {
        if (individualReport.demographicsQuestions === undefined || individualReport.demographicsQuestions === null) return undefined;

        const result: DemographicsSection = {
            age: {
                title: `<b>Question - ${GetFormattedMessage('app.demographic.age')}</b>`,
                value: `Answer - ${individualReport.demographicsQuestions.age !== undefined && individualReport.demographicsQuestions.age !== null ? individualReport.demographicsQuestions.age : 'Did not answer'}`
            },
            gender: {
                title: `<b>Question - ${GetFormattedMessage('app.demographic.gender')}</b>`,
                value: `Answer - ${individualReport.demographicsQuestions.gender !== undefined && individualReport.demographicsQuestions.gender !== null ? GetGenderByCode(individualReport.demographicsQuestions.gender) : 'Did not answer'}`
            },
            yearsOfFormalEducation: {
                title: `<b>Question - ${GetFormattedMessage('app.demographic.education_years')}</b>`,
                value: `Answer - ${individualReport.demographicsQuestions.completedYearOfEducation !== undefined && individualReport.demographicsQuestions.completedYearOfEducation !== null ? GetYearOfEducationOptionsByCode(individualReport.demographicsQuestions.completedYearOfEducation) : 'Did not answer'}`
            },
            relationshipStatus: {
                title: `<b>Question - ${GetFormattedMessage('app.demographic.marital_status')}</b>`,
                value: `Answer - ${individualReport.demographicsQuestions.maritalStatus !== undefined && individualReport.demographicsQuestions.maritalStatus !== null ? GetMaritalStatusByCode(individualReport.demographicsQuestions.maritalStatus) : 'Did not answer'}`
            },
            ethnicBackground: {
                title: `<b>Question - ${GetFormattedMessage('app.demographic.ethnic')}</b>`,
                value: `Answer - ${individualReport.demographicsQuestions.ethnicBackground !== undefined && individualReport.demographicsQuestions.ethnicBackground !== null ? GetEthnicOrientationByCode(individualReport.demographicsQuestions.ethnicBackground) : 'Did not answer'}`,
                other: `Answer - (Other) - ${individualReport.demographicsQuestions.ethnicBackgroundOther !== undefined && individualReport.demographicsQuestions.ethnicBackgroundOther !== null ? individualReport.demographicsQuestions.ethnicBackgroundOther : 'Did not answer'}`
            },
            motherCountry: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.country_mother_born')
                    }</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.motherCountry !== undefined && individualReport.demographicsQuestions.motherCountry !== null ? GetCountryByCode(individualReport.demographicsQuestions.motherCountry) : 'Did not answer'} `
            },
            fatherCountry: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.country_father_born')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.fatherCountry !== undefined && individualReport.demographicsQuestions.fatherCountry !== null ? GetCountryByCode(individualReport.demographicsQuestions.fatherCountry) : 'Did not answer'} `
            },
            minorityGroup: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.disadvantaged_or_minority_group')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup !== undefined && individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup !== null ? (individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroup === 1 ? GetFormattedMessage('app.response_yes') : GetFormattedMessage('app.response_no')) : 'Did not answer'} `,
                other: `Answer - (Other) - ${individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription !== undefined && individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription !== null ? individualReport.demographicsQuestions.memberOfDisadvantagedOrMinorityGroupDescription : 'Did not answer'} `
            },
            primaryCountry: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.primary_country')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.primaryCountryOfOrigin !== undefined && individualReport.demographicsQuestions.primaryCountryOfOrigin !== null ? GetCountryByCode(individualReport.demographicsQuestions.primaryCountryOfOrigin) : 'Did not answer'} `
            },
            timeSpentInAnotherCountry: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.time_in_other_countries')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.timeInOtherCountry !== undefined && individualReport.demographicsQuestions.timeInOtherCountry !== null ? GetTimeSpentInOtherCountriesOptionsByCode(individualReport.demographicsQuestions.timeInOtherCountry) : 'Did not answer'} `
            },
            visitedCountries: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.visit_countries')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.visitedOrLivedCountries !== undefined && individualReport.demographicsQuestions.visitedOrLivedCountries !== null ? individualReport.demographicsQuestions.visitedOrLivedCountries : 'Did not answer'} `
            },
            politicalOrientation: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.political_orientation')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.politicalOrientation !== undefined && individualReport.demographicsQuestions.politicalOrientation !== null ? individualReport.demographicsQuestions.politicalOrientation : 'Did not answer'} `
            },
            levelPoliticalOrientation: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.political_commitment')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.politicalCommitment !== undefined && individualReport.demographicsQuestions.politicalCommitment !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.politicalCommitment) : 'Did not answer'} `
            },
            motherEducation: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.mother_formal_education')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.motherFormalEducation !== undefined && individualReport.demographicsQuestions.motherFormalEducation !== null ? FormalEducationOptionsByCode(individualReport.demographicsQuestions.motherFormalEducation) : 'Did not answer'} `
            },
            fatherEducation: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.father_formal_education')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.fatherFormalEducation !== undefined && individualReport.demographicsQuestions.fatherFormalEducation !== null ? FormalEducationOptionsByCode(individualReport.demographicsQuestions.fatherFormalEducation) : 'Did not answer'} `
            },
            primaryAreaOfWork: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.primary_area_of_work')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest !== undefined && individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest !== null ? GetPrimaryAreaOfWorkByCode(individualReport.demographicsQuestions.primaryAreaOrWorkOrProfessionalInterest) : 'Did not answer'} `,
                others: [
                    `Descripive Item #1 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation1 : 'Did not answer'}`,
                    `Descripive Item #2 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation2 : 'Did not answer'}`,
                    `Descripive Item #3 - ${individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 !== undefined && individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 !== null ? individualReport.demographicsQuestions.currentAreaOfStudyOrOccupation3 : 'Did not answer'}`,
                ]
            },
            primaryLanguage: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.primary_language')}</b> `,
                others: individualReport.demographicsQuestions.primaryLanguages !== null && individualReport.demographicsQuestions.primaryLanguages.map((item: any) => {
                    return `Language: ${GetLanguageOptionsByCode(item.language)} `
                }),
                value: '',
            },
            otherFluentLanguages: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.language_level_of_fluency')}</b> `,
                value: '',
                others: individualReport.demographicsQuestions.fluencyInLanguages !== null && individualReport.demographicsQuestions.fluencyInLanguages.map((item: any) => {
                    return `Language: ${GetLanguageOptionsByCode(item.language)} - Fluency: ${GetLevelOfFluencyOptionsByCode(item.fluency)} `
                })
            },
            otherLanguages: {
                title: `<b> Question - (Other Option) - ${GetFormattedMessage('app.demographic.language_level_of_fluency')}</b>`,
                value: '',
                others: individualReport.demographicsQuestions.fluencyInLanguagesOthers !== null && individualReport.demographicsQuestions.fluencyInLanguagesOthers.map((item: any) => {
                    return `Language: ${item.language} - Fluency: ${GetLevelOfFluencyOptionsByCode(item.fluency)} `
                })
            },
            yearOfForeignLanguages: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.years_studied_foreign_language')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage !== undefined && individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage !== null ? individualReport.demographicsQuestions.totalYearsStudiedForeignLanguage : 'Did not answer'} `
            },
            income: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.income_level')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.incomeLevelOfHomeCountry !== undefined && individualReport.demographicsQuestions.incomeLevelOfHomeCountry !== null ? individualReport.demographicsQuestions.incomeLevelOfHomeCountry : 'Did not answer'} `
            },
            academicRank: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.academic_rank')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.academicStandingOrRank !== undefined && individualReport.demographicsQuestions.academicStandingOrRank !== null ? individualReport.demographicsQuestions.academicStandingOrRank : 'Did not answer'} `
            },
            commitmentsReligion: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.religion')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion !== undefined && individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion !== null ? GetReligionByCode(individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligion) : 'Did not answer'} `,
                other: `Answer - (Other) - ${individualReport.demographicsQuestions.currentBeliefsOrCommitmentsRegardingReligionOther ?? 'Did not answer'} `
            },
            levelCommitmentsReligion: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.religion_scale')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.commitmentTowardFaithReligions !== undefined && individualReport.demographicsQuestions.commitmentTowardFaithReligions !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.commitmentTowardFaithReligions) : 'Did not answer'} `,
            },
            personalInterest: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.personal_interest')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning !== undefined && individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.interestInInternationalOrInterculturalLearning) : 'Did not answer'} `,
            },
            currentlyParticipating: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.experience_satisfaction')}</b> `,
                value: `Answer - ${individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram !== undefined && individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram !== null ? GetLevelOfInterestByCode(individualReport.demographicsQuestions.satisfactionLevelInternationalExperienceProgram) : 'Did not answer'} `,
            },
            previouslyCompletedExperiences: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.participated_experiences')}</b> `,
                value: individualReport.demographicsQuestions.participatedExperiencesCapabilities !== undefined &&
                    individualReport.demographicsQuestions.participatedExperiencesCapabilities !== null ?
                    GetParticipatedExperiencesOptionsByCode(individualReport.demographicsQuestions.participatedExperiencesCapabilities) : ['Did not answer'],
            },
            completedExperiences: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.plan_participate_experiences')}</b> `,
                value: individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities !== undefined &&
                    individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities !== null ?
                    GetParticipatedLearningCoursesOptionsByCode(individualReport.demographicsQuestions.plansToParticipateExperiencesCapabilities) : ['Did not answer'],
            },
            previouslyCompletedLearningCoursesPrograms: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.participated_learning_courses')}</b> `,
                value: individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities !== undefined &&
                    individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities !== null ?
                    GetPlanParticipateExperiencesOptionsByCode(individualReport.demographicsQuestions.participatedLearningCoursesOrProgramsCapabilities) : ['Did not answer'],
            },
            completedLearningCoursesPrograms: {
                title: `<b> Question - ${GetFormattedMessage('app.demographic.plan_participate_learning_courses')}</b> `,
                value: individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities !== undefined &&
                    individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities !== null ?
                    GetPlanParticipateLearningCoursesOptionsByCode(individualReport.demographicsQuestions.plansToParticipateLearningCoursesOrProgramsCapabilities) : ['Did not answer'],
            }
        }

        return result;
    }

    const handleDownloadButtonClick = () => {
        const reportLanguage = getReportLanguage && getReportLanguage();
        downloadIndividualReport(reportLanguage ?? ReportLanguage.English);
    }

    return (
        <React.Fragment>
            {error || dataDownloadError ?
                <p>Error</p>
                :
                <IndividualReport
                    downloading={loadingDataDownload}
                    onDownloadButtonClicked={handleDownloadButtonClick}
                    header={{
                        line1: GetFormattedMessage('app.individualreport.you_and_your_worldview'),
                        line2: GetFormattedMessage('app.individualreport.a_personal_report_from'),
                        line3: GetFormattedMessage('app.individualreport.bevi_description'),
                        line4: GetFormattedMessage('app.individualreport.user') + ': ' + individualReport.userName + ' | ' +
                            GetFormattedMessage('app.individualreport.date_of_test') + ': ' + GetDate(individualReport.formStartDateOnUtc),
                    } as HeaderReportProps}
                    title={{
                        introduction: GetFormattedMessage('app.individualreport.title.introduction'),
                        foundation: GetFormattedMessage('app.individualreport.title.foundation'),
                        tolerance_of_desequilibrium: GetFormattedMessage('app.individualreport.title.tolerance_of_desequilibrium'),
                        making_sense: GetFormattedMessage('app.individualreport.title.making_sense'),
                        access_to_yourself: GetFormattedMessage('app.individualreport.title.access_to_yourself'),
                        access_to_the_thoughts: GetFormattedMessage('app.individualreport.title.access_to_the_thoughts'),
                        access_to_the_larger_world: GetFormattedMessage('app.individualreport.title.access_to_the_larger_world'),
                        conclusion: GetFormattedMessage('app.individualreport.title.conclusion'),
                        thankYou: GetFormattedMessage('app.individualreport.title.thankyou'),
                        profile: GetFormattedMessage('app.individualreport.title.profile'),
                        domain_scores: GetFormattedMessage('app.report.background_statistic_item.domain_scores'),
                        scalesTitle: {
                            negative_life_events: GetFormattedMessage('app.report.scales.negative_life_events'),
                            needs_closure: GetFormattedMessage('app.report.scales.needs_closure'),
                            needs_fulfillment: GetFormattedMessage('app.report.scales.needs_fulfillment'),
                            identity_diffusion: GetFormattedMessage('app.report.scales.identity_diffusion'),
                            basic_openness: GetFormattedMessage('app.report.scales.basic_openness'),
                            self_certitude: GetFormattedMessage('app.report.scales.self_certitude'),
                            basic_determinism: GetFormattedMessage('app.report.scales.basic_determinism'),
                            socioemotional_convergence: GetFormattedMessage('app.report.scales.socioemotional_convergence'),
                            physical_resonance: GetFormattedMessage('app.report.scales.physical_resonance'),
                            emotional_attunement: GetFormattedMessage('app.report.scales.emotional_attunement'),
                            self_awareness: GetFormattedMessage('app.report.scales.self_awareness'),
                            meaning_quest: GetFormattedMessage('app.report.scales.meaning_quest'),
                            religious_traditionalism: GetFormattedMessage('app.report.scales.religious_traditionalism'),
                            gender_traditionalism: GetFormattedMessage('app.report.scales.gender_traditionalism'),
                            sociocultural_openness: GetFormattedMessage('app.report.scales.sociocultural_openness'),
                            ecological_resonance: GetFormattedMessage('app.report.scales.ecological_resonance'),
                            global_resonance: GetFormattedMessage('app.report.scales.global_resonance'),
                            consistency: GetFormattedMessage('app.report.scales.consistency'),
                            congruency: GetFormattedMessage('app.report.scales.congruency'),
                        }
                    } as TitleReportProps}
                    heading={[
                        GetFormattedMessage('app.individualreport.heading_text_1_1'),
                        GetFormattedMessage('app.individualreport.heading_text_1_2'),
                        GetFormattedMessage('app.individualreport.heading_text_1_3'),
                        GetFormattedMessage('app.individualreport.heading_text_2').replace('{UserName}', individualReport.userName),
                        GetFormattedMessage('app.individualreport.heading_text_3'),
                        GetFormattedMessage('app.individualreport.heading_text_4'),
                    ]}
                    introduction={[
                        GetFormattedMessage('app.individualreport.static_text_1'),
                        GetFormattedMessage('app.individualreport.static_text_2'),
                        GetFormattedMessage('app.individualreport.static_text_3'),
                        GetFormattedMessage('app.individualreport.static_text_4'),
                    ]}
                    foundation={[
                        GetFormattedMessage('app.individualreport.demographics.text'),
                        GetFormattedMessage('app.individualreport.formativecore.text')
                            .replace('{NegativeLifeEvents}',
                                GetNegativeLifeEvents(individualReport.negativeLifeEventsIndicator))
                            .replace('{NeedsClosure}', GetNeedsClosure(individualReport.needsClosureIndicator)),
                        GetFormattedMessage('app.individualreport.static_text_5'),
                    ]}
                    toleranceOfDisequilibrium={GetToleranceOfDisequilibruim(individualReport.toleranceDisequilibriumIndicator)}
                    whyWeDo={GetWhyWeDoText(individualReport.whyWeDoIndicator)}
                    accessSelf={GetAccessSelf(individualReport.accessSelfIndicator, individualReport.selfAwarenessIndicator)}
                    accessToThoughtsAndFeelingsOfOthers={GetAccessToThoughtsAndFeelingsOfOthers(individualReport.religiousTraditionalimsIndicator)}
                    genderTradition={GetGenderTradition(individualReport.genderTraditionIndicator)}
                    variables={GetVariables(individualReport.socioCulturalIndicator)}
                    accessToTheLargerWorld={
                        GetAccessLargeWorld(individualReport.ecologicalResonanceIndicator,
                            individualReport.globalEngagementIndicador)}
                    hasPermissionToProfile={individualReport.hasPermissionToViewProfile}
                    profile={individualReport.aggregateProfile}
                    backgroundStatistic={individualReport.backgroundStatistic}
                    conclusion={GetFormattedMessage('app.individualreport.static_text_11')}
                    thankYou={[
                        GetFormattedMessage('app.individualreport.thankyou_static_1_1'),
                        GetFormattedMessage('app.individualreport.thankyou_static_1_2'),
                        GetFormattedMessage('app.individualreport.thankyou_static_2'),
                        GetFormattedMessage('app.individualreport.thankyou_static_3'),
                    ]}
                    agreeButtonText={GetFormattedMessage('app.consent.agree')}
                    agreed={agreed}
                    onAgreedButtonClicked={handleAgreedButtonClick}
                    demographicsSection={buildDemographicsSection()}
                    scalesStronglyAgree={buildScaleQuestions(QuestionType.StronglyAgree)}
                    scalesStronglyDisagree={buildScaleQuestions(QuestionType.StronglyDisagree)}
                    discusiveAnswer1={buildDiscursiveQuestions(DiscusiveQuestionType.Response1)}
                    discusiveAnswer2={buildDiscursiveQuestions(DiscusiveQuestionType.Response2)}
                    discusiveAnswer3={buildDiscursiveQuestions(DiscusiveQuestionType.Response3)}
                    showExtraData={individualReport.showExtraData}
                />
            }
            <Modal show={showCustomAgreementModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id={'app.consent.title'} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: individualReport?.customAgreement }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => customAgreementModalResponse(false)}>
                        <FormattedMessage id={'app.consent.disagree'} />
                    </Button>
                    <Button variant='success' onClick={() => customAgreementModalResponse(true)}>
                        <FormattedMessage id={'app.consent.agree'} />
                    </Button>
                </Modal.Footer>
            </Modal>
            {formId && <BeingBevi type='IndividualReport' id={formId.toString()} />}
        </React.Fragment>
    )
});
