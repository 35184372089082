import { notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ProgressBar, Row, Spinner, Toast } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import RichTextEditor from 'react-rte';

import { FormNumericInput } from '../../components/FormNumericInput';
import { Loading } from '../../components/Loading';
import { colors } from '../../config/colors';
import { defaultNotificationDurationInSeconds } from '../../config/constants';
import { PageMessages } from '../../locales/types';
import { TokenInfo } from '../../services/loginService';
import { SearchProgramsDetails } from '../../services/organizationService';
import { SubProgramDetailsModel } from '../../services/programService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { UserListForms } from './components/UserListForms';
import { UserAiInteractionsInput } from './components/UserAiInteractionsInput';
import { IsAccountManager, IsSuperAdmin } from '../../utils/userHelper';

type Props = {
  organizationName: string;
  program: SearchProgramsDetails;
  subProgram: SubProgramDetailsModel;
  loading: boolean;
  error?: string;
  userInfo: TokenInfo;
  loadingRemindToTakeTheBEVI: boolean,
  remindToTakeTheBEVISuccess: boolean,
  onBackButtonClicked: () => void;
  showReportClick: (formId: number) => void;
  sendCustomAgreement: (formId: number, customAgreementText: string) => void;
  handleRemindToTakeTheBEVI: () => void,
  loadSubProgramDetails: (subProgramId: number) => void
};

// TODO(rodrigo.santos): use the error property.
export const SubProgramDetails = (
  {
    organizationName, program, subProgram, loading, error, userInfo, loadingRemindToTakeTheBEVI, remindToTakeTheBEVISuccess,
    onBackButtonClicked, showReportClick, sendCustomAgreement, handleRemindToTakeTheBEVI,
    loadSubProgramDetails
  }: Props
): JSX.Element => {
  const defaultMessage = GetFormattedMessage('app.custom_agreement.default_custom_agreement');
  const errorMessage = GetFormattedMessage(error as (keyof (PageMessages)));
  const [customAgreementFormId, setCustomAgreementFormId] = useState<number | undefined>();
  const [showErrorToast, setShowErrorToast] = useState<boolean>(!!error);
  const [editorState, setEditorState] = useState(RichTextEditor.createValueFromString(defaultMessage, 'html'));
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (remindToTakeTheBEVISuccess)
      setShowModal(true);
  }, [remindToTakeTheBEVISuccess])

  if (loading) return <Loading />
  const dueDate = subProgram ? moment(subProgram.dueDateOnUtc)?.format("MMM Do, YYYY") : "";
  // const totalUsers = subProgram.usersAndFormsDetails.length;

  const totalUsers = subProgram.usersAndFormsDetails.length > 0 ? subProgram.usersAndFormsDetails
    .map((user): number => Number(user.formStatus !== 6 && user.formStatus !== 7 && user.formStatus !== 8 && user.formStatus !== 9))
    .reduce((acc, currValue) => acc + currValue) : 0;

  const totalUsersCompletedForm = subProgram.usersAndFormsDetails.length > 0 ? subProgram.usersAndFormsDetails
    .map((user): number => Number(user.formStatus === 5))
    .reduce((acc, currValue) => acc + currValue) : 0;
  const usersCurrentProgress = totalUsers !== 0 ? ((totalUsersCompletedForm / totalUsers) * 100) : 0;

  const clearCustomAgreementData = () => {
    setCustomAgreementFormId(undefined);
    setEditorState(RichTextEditor.createValueFromString(defaultMessage, 'html'));
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  const onSendCustomAgreementButtonClick = () => {
    if (customAgreementFormId !== undefined) {
      sendCustomAgreement(customAgreementFormId, editorState.toString('html'));
    }
    clearCustomAgreementData();
  }

  if (!!error) {
    notification['error']({
      message: GetFormattedMessage('app.notification.error_title'),
      description: !!errorMessage ? errorMessage : 'An unknown error has occurred.',
      duration: defaultNotificationDurationInSeconds,
    });
  }

  return (
    <div style={styles.divBackground} className='background'>
      <div style={styles.divCard} className='card'>
        {!!error &&
          <Row>
            <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={3000} autohide>
              <Toast.Header className='bg-red'>
                Error: {error}
              </Toast.Header>
            </Toast>
          </Row>
        }
        <Form>
          <Form.Group className='mb-3' controlId='formOrganization'>
            <Form.Label>{GetFormattedMessage('app.create_program.lbl_organization_name')}</Form.Label>
            <Form.Control type='text' value={organizationName} disabled />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formProgram'>
            <Form.Label>{GetFormattedMessage('app.create_program.lbl_program_name')}</Form.Label>
            <Form.Control
              type='text'
              value={program.name}
              disabled
            />
          </Form.Group>

          <Form.Group className='mb-3' as={Row} controlId='subProgramLine'>
            <Form.Group className='mb-3' as={Col} sm='7' controlId='formSubProgram'>
              <Form.Label>{GetFormattedMessage('app.create_program.lbl_sub_program_name')}</Form.Label>
              <Form.Control
                type='text'
                value={subProgram?.description}
                disabled
              />
            </Form.Group>

            <Form.Group className='mb-3' as={Col} sm='2' controlId='formYear'>
              <Form.Label>{GetFormattedMessage('app.create_program.lbl_year')}</Form.Label>
              <FormNumericInput
                value={subProgram?.year.toString()}
                maxValue={9999}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} sm='3' controlId='formDueDate'>
              <Form.Label>{GetFormattedMessage('app.create_program.lbl_due_date')}</Form.Label>
              <Form.Control
                value={dueDate}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} sm='12' controlId='remindAlert'>
              <Button style={styles.alertBtn} variant='warning' className={loadingRemindToTakeTheBEVI ? 'disabled' : ''} onClick={() => handleRemindToTakeTheBEVI()}>
                {loadingRemindToTakeTheBEVI && <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
                <FormattedMessage id={'app.details_sub_program.remind_alert'} />
              </Button>
            </Form.Group>
          </Form.Group>
          {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) && (
            <Form.Group className='mb-3' controlId='usersProgress'>
              <Form.Label>{GetFormattedMessage('app.listforms.ai_interactions')}</Form.Label>
              <UserAiInteractionsInput afterSuccess={() => loadSubProgramDetails(subProgram.id)} records={subProgram.usersAndFormsDetails ?? []} />
            </Form.Group>)
          }

          <Form.Group className='mb-3' controlId='usersProgress'>
            <Form.Label>{GetFormattedMessage('app.details_sub_program.progress')}</Form.Label>
            <ProgressBar now={usersCurrentProgress} label={`${usersCurrentProgress.toFixed(2)} %`} />
          </Form.Group>
          <br />
          <Form.Group className='mb-3' controlId='formUser'>
            <Form.Label>
              <h4> {GetFormattedMessage('app.details_sub_program.participant_details')} </h4>
            </Form.Label>
            <UserListForms
              users={subProgram.usersAndFormsDetails}
              showReportClick={showReportClick}
              sendCustomAgreementButtonClick={(formId) => setCustomAgreementFormId(formId)}
              userInfo={userInfo}
            />
          </Form.Group>
        </Form>

        <Modal show={!!customAgreementFormId} backdrop="static">
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage id={'app.custom_agreement.modal_title'} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id={'app.custom_agreement.modal_explanation_text'} />
            <RichTextEditor
              value={editorState}
              onChange={setEditorState}
              // @ts-ignore
              toolbarConfig={toolbarConfig}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={clearCustomAgreementData}>
              <FormattedMessage id={'app.btn_back'} />
            </Button>
            <Button
              variant='success'
              disabled={editorState.toString('html') === defaultMessage}
              onClick={onSendCustomAgreementButtonClick}
            >
              <FormattedMessage id={'app.form.actions.tooltip.send_custom_agreement'} />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static">
          <Modal.Header closeButton>
            <FormattedMessage id={'app.details_sub_program.remind_alert_text'} />
          </Modal.Header>
        </Modal>

        <br />
        <div className='button'>
          <Button
            disabled={loading}
            style={styles.btnSave}
            variant='primary'
            onClick={() => onBackButtonClicked()}
          >
            {loading ?
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              :
              <FormattedMessage id={'app.btn_back'} />
            }
          </Button>
        </div>
      </div>
    </div >
  );
}

const styles = {
  divBackground: {
    justifyContent: 'center',
    display: 'flex',
  } as React.CSSProperties,
  divCard: {
    width: '100%',
    background: colors.white,
    borderRadius: '15px',
    padding: '16px',
  } as React.CSSProperties,
  btnSave: {
    width: '150px',
    float: 'right',
    backgroundColor: colors.darkSlateBlue,
  } as React.CSSProperties,
  associateUserContainer: {
    padding: '20px 60px',
  } as React.CSSProperties,
  associateUserButtonAction: {
    borderWidth: 0,
  } as React.CSSProperties,
  alertBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  } as React.CSSProperties
};
