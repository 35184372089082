import { PermissionMessages } from '../types';

export const permissionZHCN: PermissionMessages = {
  'app.permission.title': 'Permission - Individual Report - Profile',
  'app.permission.remove_permission_to_all': 'Remove permission to all',
  'app.permission.give_permission_to_all': 'Give permission to all',
  'app.permission.remove_permission': 'Remove permission',
  'app.permission.give_permission': 'Give permission',
  'app.permission.table_title.permission': 'Permission',
  'app.permission.table_title.name': 'Name',
  'app.permission.table_title.email': 'Email',
  'app.permission.button.update': 'Update',
  'app.permission.message': 'Users successfully updated',
  'app.permission.add_permissions_with_excel_file': 'Add permissions with excel file',
  'app.permission.add_permissions': 'Add permissions',
  'app.permission.permissions': 'Permissions',
  'app.permission.download_tooltip': 'Download file template'
}
