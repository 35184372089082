import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    organizations: SelectItem[];
    searchHandleClick: (organizationId: number, program: number | undefined) => void;
    OnOrganizationChange(organizationId: number | undefined): SelectItem[];
    onOpenPermissionModal: () => void;
};

export const FilterUsers = ({ organizations, searchHandleClick, OnOrganizationChange, onOpenPermissionModal }: Props): JSX.Element => {

    const [organizationId, setOrganization] = useState<number | undefined>(undefined);
    const [programId, setProgramId] = useState<number | undefined>(undefined);
    const [programs, setPrograms] = useState<SelectItem[]>([]);

    const organizationChangeHandle = (organizationId: number | undefined) => {
        setOrganization(organizationId);
        const programs = OnOrganizationChange(organizationId);
        setPrograms(programs);
        setProgramId(undefined);
    }

    const GetProgram = (programId: number | undefined): SelectItem => {
        const programIndex = programs.findIndex((t: SelectItem) => t.value === programId);
        if (programIndex < 0) return { value: undefined, label: GetFormattedMessage('app.create_report.select_program') };
        return { value: programs[programIndex].value, label: programs[programIndex].label };
    }

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {GetFormattedMessage('app.permission.title')}
                    </h2>
                </div>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        <Col md={4}>
                            <FormDropdown
                                isSearchable={true}
                                defaultOptionId={'app.listorganizations.select_organization'}
                                onValueSelect={(value: number | undefined) => {
                                    organizationChangeHandle(value);
                                }}
                                optionItens={organizations}
                            />
                        </Col>
                        <Col md={4}>
                            <FormDropdown
                                isSearchable={true}
                                defaultOptionId={'app.create_report.select_program'}
                                onValueSelect={(value: number | undefined, text: string) => {
                                    if (value !== undefined) {
                                        setProgramId(value);
                                    } else {
                                        setProgramId(undefined);
                                    }
                                }}
                                value={GetProgram(programId)}

                                optionItens={programs}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={styles.buttonSearchDiv}>
                    <Row>
                        <Col style={styles.colFlex}>
                            <ButtonWithIcon
                                disabled={organizationId === undefined}
                                textId={'app.listorganizations.search'}
                                onClick={() => { searchHandleClick(Number(organizationId), programId) }}
                                icon={<FaSearch />}

                            />
                            <ButtonWithIcon
                                textId={'app.permission.add_permissions_with_excel_file'}
                                onClick={() => { onOpenPermissionModal() }}
                                icon={<FaPlus />}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'left',
        paddingRight: '20px',
        paddingLeft: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
    colFlex: {
        display: 'flex',
        gap: 12
    } as React.CSSProperties,
};
