import { ListReportMessages } from '../types';

export const listReportCA: ListReportMessages = {
    'app.listreport.report_area': 'Gestió d’informes',
    'app.listreport.new': 'Nou informe',
    'app.listreport.new_group': "Nou grup d'informes",
    'app.listreport.add_group': 'Afegeix un altre grup',
    'app.listreport.please_add_group_name': 'Si us plau, afegiu el nom del grup',
    'app.listreport.search': 'Cercar',
    'app.listreport.name': 'Anomenar',
    'app.listreport.report_type': 'Escriure',
    'app.listreport.created_on': 'Creat el',
    'app.listreport.confirm_deletion_header': 'Confirmar eliminar',
    'app.listreport.confirm_deletion_body': 'Estàs segur que vols seguir amb el procés d’eliminació d’informes? Si segueixes endavant, aquest informe s’eliminarà permanentment de les nostres bases de dades.',
    'app.listorganizations.view': 'Veure',
    'app.listorganizations.edit': 'Editar',
    'app.listorganizations.delete': 'Eliminar',
    'app.listreport.report_status': "Estat de l'informe",
    'app.listreport.report_status_pending_approval': 'Pending Participant Approval',
    'app.listreport.report_status_completed': 'Available',
}
