import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { FormDropdown } from '../../../components/FormDropdown';
import { demographicMessageIds } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import { EthnicBackground, isConditionApproved } from '../library/questionDefinitions';

type Props = {
    question: EthnicBackground;
    answerChange: (key: keyof(DemographicQuestionAnswers), value: number | string | undefined) => void;
    questionNumber: number;
    style?: React.CSSProperties;
};

export const EthnicQuestion = ({ question, answerChange, questionNumber, style }: Props): JSX.Element => {

    const [selectedValue, setSelectedValue] = useState<number>();

    const conditionalProps = question.GetConditonalProps();

    const [hasConditionalApproved, setHasConditionalApproved] = useState<boolean>(false);
    useEffect(() => {
        if (conditionalProps) {
            const approved = isConditionApproved(conditionalProps.coditional, selectedValue);
            if (hasConditionalApproved === true && approved === false) {
                answerChange(conditionalProps!!.questionKeyName, undefined);
            }
            setHasConditionalApproved(approved);
        }
    }, [answerChange, conditionalProps, hasConditionalApproved, selectedValue]);

    return (
        <Form.Group className='mb-3' controlId="demographic.ethnic" style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={question.GetQuestionLabelId()} />
                </div>
                <ul>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_asian']} /> </li>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_african']} /> </li>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_latino']} /> </li>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_indigenous']} /> </li>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_middle_eastern']} /> </li>
                    <li> <FormattedMessage id={demographicMessageIds['app.demographic.ethnic_white']} /> </li>
                </ul>
            </Form.Label>
            <Col md='6'>
                <FormDropdown
                    defaultOptionId={'app.dropdown_select'}
                    onValueSelect={(value: number | undefined, _: string) => {
                        setSelectedValue(value);
                        answerChange(question.GetQuestionKeyName(), value);
                    }}
                    optionItens={question.GetDefintions()}
                />
            { hasConditionalApproved &&
                <React.Fragment>
                    <p />
                    <Form.Label>
                        <FormattedMessage id={conditionalProps!!.labelId} />
                    </Form.Label>
                    <Form.Control
                        as='input'
                        onChange={(e) => {
                            answerChange(conditionalProps!!.questionKeyName, e.currentTarget.value);
                        }}
                    />
                </React.Fragment>
            }
            </Col>
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
