import { useActions, useValues } from 'kea';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { formLogic, FormsActions, FormsProps } from '../../redux/formsLogic';
import {
    ScaleQuestionsActions, scaleQuestionsLogic, ScaleQuestionsProps
} from '../../redux/scaleQuestionsLogic';
import { DISCURSIVE_QUESTIONS_PAGE, EXTERNAL_SCALE_QUESTIONS_PAGE, EXTERNAL_DISCURSIVE_QUESTIONS_PAGE } from '../../router/pages';
import { ScaleQuestionAnswer, ScaleQuestionAnswers } from '../../services/types';
import { ScaleQuestions } from './ScaleQuestions';

type AnswerDictionary = {
    [ScaleQuestionDefinitionId: number]: ScaleQuestionAnswer;
}

type Props = RouteComponentProps;

export const ScaleQuestionsContainer = withRouter((props: Props): JSX.Element => {
    const { loadScaleQuestions, sendAnswers }: ScaleQuestionsActions = useActions(scaleQuestionsLogic);
    const {
        loading, error, currentQuestions, isLastPage, finishQuestions
    }: ScaleQuestionsProps = useValues(scaleQuestionsLogic);
    const { setFormStatus }: FormsActions = useActions(formLogic);

    const { formId }: FormsProps = useValues(formLogic);

    useEffect(() => {
        loadScaleQuestions(formId);
    }, [loadScaleQuestions, formId]);

    useEffect(() => {
        if (finishQuestions) {
            setFormStatus(4);  // On Discursive.
            if (props.location.pathname === EXTERNAL_SCALE_QUESTIONS_PAGE) {
                props.history.push(EXTERNAL_DISCURSIVE_QUESTIONS_PAGE);
            } else {
                props.history.push(DISCURSIVE_QUESTIONS_PAGE);
            }
        }
        // eslint-disable-next-line
    }, [finishQuestions, setFormStatus, props.history]);

    const [answers, setAnswers] = useState<AnswerDictionary>({});
    const handleNewAnswer = (id: number, answerValue: number): void => {
        answers[id] = { scaleQuestionDefinitionId: id, answer: answerValue };
        setAnswers(answers);
    }

    const sendPageAnswers = (): void => {
        const formattedAnswers: ScaleQuestionAnswers = {
            formId: formId,
            isFinished: isLastPage,
            scaleAnswers: Object.values(answers)
        };
        setAnswers({});
        sendAnswers(formattedAnswers);
    }

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const sendAllPageAnswers = (): void => {
        sendPageAnswers();
        window.scrollTo(0, 0);
    } 

    const handleNextButtonClick = (): void => {
        if (Object.keys(answers).length === currentQuestions.length) {
            sendAllPageAnswers();
        } else {
            setShowConfirmation(true);
        }
    }

    const handleConfirmationCloseClick = (): void => {
        setShowConfirmation(false);
    }

    const handleConfirmationOkClick = (): void => {
        sendAllPageAnswers();
        setShowConfirmation(false);
    }

    return (
        <Fragment>
            <ScaleQuestions
                questions={currentQuestions}
                onAnswer={handleNewAnswer}
                onNextButtonClicked={handleNextButtonClick}
                loading={loading || !currentQuestions}
                error={error}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={'app.questions.confirmation_title'}
                bodyFormattedListMessageId={['app.questions.confirmation']}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleConfirmationCloseClick}
                onPrimaryButtonClicked={handleConfirmationOkClick}
                onSecondaryButtonClicked={handleConfirmationCloseClick}
            />
        </Fragment>
    );
});
