import { LoginPageMessages } from '../types';

export const loginPageES: LoginPageMessages = {
    'app.login.text_welcome': 'Bienvenido(a) al Test de Creencias, Vivencias y Valores (BEVI)',
    'app.login.text_information': 'Para acceder a este sitio, debes contar con una invitación de una institución, organización o administrador con licencia BEVI',
    'app.login.text_login_requirement': 'Por favor, introduce el nombre de usuario y contraseña requeridos (que te habrán proporcionado).',
    'app.login.lbl_username': 'Nombre de usuario:',
    'app.login.lbl_password': 'Contraseña:',
    'app.login.btn_submit': 'Ingresar',
    'app.login.lbl_new_password': 'Nueva contraseña',
    'app.login.lbl_new_password_confirmation': 'Repita la contraseña',
    'app.login.btn_submit_new_password': 'Restablecer contraseña',
    'app.login.placeholder.login': 'correo-e@ejemplo.com',
    'app.login.placeholder.password': 'tu contraseña',
    'app.login.invalid_credentials': '¡Vaya! Los datos proporcionados no coinciden con nuestros registros. Por favor, vuelve a intentarlo o restablece tu cuenta haciendo click en He olvidado mi contraseña a continuación.',
    'app.login.forgot_password.invalid_credentials': 'El correo-e no es válido. Por favor, vuelve a intentarlo.',
    'app.login.forgot_password': '¿Has olvidado la contraseña?',
    'app.login.forgot_password_confirmation_title': 'Restablecimiento de contraseña',
    'app.login.forgot_password_confirmation_body': 'Se ha enviado un correo a la dirección de correo proporcionada. Sigue las instrucciones del correo para restablecer tu contraseña.',
    'app.login.btn_submit_forgot_password': 'Recuperar contraseña',
    'app.login.passwords_dont_match': 'Las contraseñas no coinciden.',
    'app.login.change_password.expired_confirmation_title': 'Página expirada',
    'app.login.change_password.expired_confirmation_body': 'Tu permiso para acceder a la página ha expirado. Por favor, solicita acceso de nuevo o ingresa en la página para acceder al BEVI.',
    'app.login.change_password.success_confirmation_title': 'Contraseña actualizada',
    'app.login.change_password.success_confirmation_body': 'Tu contraseña se ha modificado con éxito. Utiliza tu nueva contraseña para ingresar en la página de inicio, donde serás redirigido.',
    'app.login.first_access.title': 'Por favor, cambia su contraseña antes de utilizar el sistema.',
}
