import { CreateProgramMessages } from '../types';

export const createProgramEN: CreateProgramMessages = {
    'app.create_program.btn_submit': 'Save',
    'app.create_program.btn_submit_and_send_email': 'Save/Send Email',
    'app.create_program.btn_unselect_all_users': 'Remove all',
    'app.create_program.select_individual_manually': 'Select Individuals Manually',
    'app.create_program.table.column_name': 'Name',
    'app.create_program.table.column_email': 'Email',
    'app.create_program.table.column_actions': 'Remove Individuals',
    'app.create_program.table.column_get_last_users': 'Individuals from previous administration.',
    'app.create_program.tooltip.action_remove': 'Remove',
    'app.create_program.tooltip.btn_get_last_users': 'Import individuals from previous administration.',
    'app.create_program.tooltip.btn_download_users': 'Download / manage who will take the BEVI.',
    'app.create_program.tooltip.btn_download_users_empty': 'Download / manage who will take the BEVI(Empty Template).',
    'app.create_program.excel.name_column': 'Name',
    'app.create_program.excel.login_column': 'Email',
    'app.create_program.excel.active_column': 'Active',
    'app.create_program.confirmation_title_success': 'Successfully created',
    'app.create_program.confirmation_body_success_program': 'The program or experience has now been created.  You may review or manage it by clicking "ok" below.',
    'app.create_program.confirmation_body_success_subprogram': 'You have created and named a BEVI administration.  You may review or manage it by clicking "ok" below.',
    'app.create_program.confirmation_title_fail': 'Oh no! Unfortunately, we couldn\'t create it',
    'app.create_program.confirmation_body_fail_program': 'The program couldn’t be created. Please, review the data provided, the internet connection and try again. If the problem persists, talk to the account manager.',
    'app.create_program.confirmation_body_fail_subprogram': 'The Sub Program couldn’t be created. Please, review the data provided, the internet connection and try again. If the problem persists, talk to the account manager.',
    'app.edit_sub_program.confirmation_title_success': 'Successfully updated',
    'app.edit_sub_program.confirmation_body_success': 'The group was updated and you can now visualize it in the organization program listing, where you are being redirected.',
    'app.edit_sub_program.confirmation_title_fail': 'Oh no! Unfortunately, we couldn\'t update it',
    'app.edit_sub_program.confirmation_body_fail': 'The Sub Program couldn’t be updated. Please, review the data provided, the internet connection and try again. If the problem persists, talk to the account manager.',
    'app.create_program.lbl_associate_users': 'Select individuals to take the BEVI.',
    'app.create_program.lbl_organization_name': 'Name of Organization',
    'app.create_program.lbl_program_name': 'Name of Program or Experience',
    'app.create_program.lbl_sub_program_name': 'Name of BEVI Administration',
    'app.create_program.lbl_year': 'Year',
    'app.create_program.lbl_due_date': 'Final Date for BEVI Administration',
    'app.create_program.lbl_alert_all_users': 'Alert all individuals by email',
    'app.details_sub_program.progress': 'Group Progress',
    'app.details_sub_program.participant_details': 'Participant Details',
    'app.details_sub_program.remind_alert': 'Remind Alert',
    'app.details_sub_program.remind_alert_text': 'A reminder alert was scheduled and it will be sent in a few minutes.',
    'app.create_program.lbl_email_message': 'Type a message below that will be sent to the email addresses of all individual participants.',
    'app.create_program.tooltip.btn_upload_users': 'Upload who will take the BEVI.',
    'app.create_program.upload_files_error_title': 'Upload File Error ',
    'app.create_program.upload_rejected_files_error_body_prefix': 'The following files were rejected because they are not in the expected format:',
    'app.create_program.upload_parsed_files_error_body_prefix': 'The file is expected to have columns {0}, {1} and {2}. The following files were not read correctly:',
    'app.create_program.upload_parsed_files_error_body': '{0} has {1} errors.',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'Error trying to analyze the following files:',
    'app.create_program.select_individuals_title': 'Select individuals to take the BEVI through one of three methods:',
    'app.create_program.import_individuals_from_previous_administration': '1.  Import individuals from previous administration.',
    'app.create_program.download_manage_who_will_take_the_bevi': '2.  Download / manage who will take the BEVI',
    'app.create_program.upload_who_will_take_the_bevi': ', and then upload who will take the BEVI',
    'app.create_program.add_individuals_manually': '3.  Add individuals manually / one by one clicking on the dropdown below.',
}
