import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import permissionDenied from '../../assets/images/permissionDenied.png';
import { NoInfo } from '../../components/NoInfo';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';

export const PageNoAuthorized = (): JSX.Element => {

    const { clearNotAuthorized }: AppActions = useActions(appLogic);
    const { notAuthorized }: AppProps = useValues(appLogic);

    useEffect(() => {
        notAuthorized && clearNotAuthorized();
    }, [notAuthorized, clearNotAuthorized]);

    return (
        <NoInfo 
            title={'app.no.info.title.not_authorized'}
            subTitle={'app.no.info.subtitle.not_authorized'}
            body={[
                'app.no.info.body.not_authorized'
            ]}
            image={permissionDenied}
        />
    );
}