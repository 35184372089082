import jwt_decode from 'jwt-decode';

import { TokenInfo } from '../services/loginService';

export function validateEmail(email: string): boolean {
    const emailRegExp = new RegExp(
        [
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+',
            '(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}',
            '\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)',
            '+[a-zA-Z]{2,}))$',
        ].join('')
    );

    return !!email && !!email.match(emailRegExp);
}

export function ValidateExternalToken(token?: string): boolean | undefined {
    let tokenInfo: TokenInfo | undefined;
    if (token) {
        try {
            tokenInfo = jwt_decode<TokenInfo>(token, { header: false });
        } catch {
            console.log('Cannot decode token');
            return undefined;
        }
    }
    return ValidateExternalTokenInfo(tokenInfo);
}

export function ValidateExternalTokenInfo(tokenInfo?: TokenInfo): boolean {
    if (tokenInfo != null && !!tokenInfo && validateExpirationTime(tokenInfo)) {
        return tokenInfo?.isExternal?.toLowerCase() === "true" && tokenInfo?.formId !== undefined;
    }
    return false;
}

export function ValidateToken(token?: string): boolean | undefined {
    let tokenInfo: TokenInfo | undefined;
    if (token) {
        try {
            tokenInfo = jwt_decode(token);
        } catch {
            console.log('Cannot decode token');
            return undefined;
        }
    }
    return ValidateTokenInfo(tokenInfo);
}

export function ValidateTokenInfo(tokenInfo?: TokenInfo): boolean {
    //@ts-ignore
    if (tokenInfo !== null &&
        !!tokenInfo && validateExpirationTime(tokenInfo)) {
        return tokenInfo?.isExternal?.toLowerCase() === "false";
    }
    return false;
}

function validateExpirationTime(tokenInfo: TokenInfo): boolean {
    if (!tokenInfo?.exp) return false;

    const utcNow = new Date();
    const tokenExpirationTime: number = new Date(0).setUTCSeconds(tokenInfo.exp);
    return tokenExpirationTime > utcNow.getTime();
}
