import { Slider } from 'antd';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { PageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type ValueProps = {
    min: number;
    max: number;
};

type Props = Partial<typeof Form.Group> & { groupAs?: typeof Col, groupMd?: string } & {
    value?: [number, number];
    min: number;
    max: number;
    labelKey: keyof PageMessages;
    onFilterChange: (values: ValueProps | undefined) => void;
};

export const FilterNumberRangeGroup = (
    { value, min, max, labelKey, onFilterChange, groupAs, groupMd, ...groupProps }: Props
): JSX.Element => {

    const [switchState, setSwitchState] = useState<boolean>(value !== undefined);
    const [currentValue, setCurrentValue] = useState<ValueProps>(
        { min: min, max: max }
    );

    const marks = {
        [currentValue.min]: currentValue.min.toString(),
        [currentValue.max]: currentValue.max.toString(),
    };

    return (
        <Form.Group className='mb-3' as={groupAs} md={groupMd} {...groupProps}>
            <Form.Check
                type='switch'
                inline={true}
                id={labelKey}
                label={GetFormattedMessage(labelKey)}
                defaultChecked={switchState}
                readOnly={true}
                onChange={() => {
                    if (switchState) onFilterChange(undefined);
                    else onFilterChange(currentValue);

                    setSwitchState(!switchState);
                }}
            />
            <Slider
                id={labelKey}
                disabled={!switchState}
                marks={marks}
                range={true}
                value={value ?? [min, max]}
                min={min}
                max={max}
                onChange={(values) => {
                    if (typeof values !== 'number') {
                        setCurrentValue({ min: values[0], max: values[1] })
                        onFilterChange({ min: values[0], max: values[1] });
                    }
                }}
            />
        </Form.Group>
    )
}
