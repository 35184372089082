import { notification } from 'antd';
import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ConfirmationModal } from '../../components/ConfirmationModal';

import { Loading } from '../../components/Loading';
import { defaultNotificationDurationInSeconds } from '../../config/constants';
import { appLogic, AppProps } from '../../redux/appLogic';
import { ListOrganizationActions, listOrganizationLogic, ListOrganizationProps } from '../../redux/listOrganizationLogic';
import { ListReportActions, listReportLogic, ListReportProps } from '../../redux/listReportLogic';
import { ReportActions, reportLogic } from '../../redux/reportLogic';
import { CREATE_REPORT_PAGE, EDIT_REPORT_PAGE } from '../../router/pages';
import { formatDate } from '../../utils/dateHelper';
import { FilterListReport } from './components/FilterListReport';
import { ListReport } from './ListReport';

type Props = RouteComponentProps;

export const ListReportContainer = withRouter((props: Props): JSX.Element => {

    const { tokenInfo }: AppProps = useValues(appLogic);
    const { getReports, deleteReport }: ListReportActions = useActions(listReportLogic);
    const { reports, loading, deleting, deleteError }: ListReportProps = useValues(listReportLogic);

    const { getOrganizations }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { organizations, loadingPage }: ListOrganizationProps = useValues(listOrganizationLogic);

    const {
        setSelectedReportId, clearReportData, clearSelectedReport
    }: ReportActions = useActions(reportLogic);

    const [endDate, setEndDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(new Date(endDate.getFullYear() - 1, 0, 1)); // Jan 1st from last year.
    const [organizationId, setOrganizationId] = useState<number | undefined>();
    const [requestingReportDeletion, setRequestingReportDeletion] = useState<boolean>(false);
    const [confirmDeletionForReportId, setConfirmDeletionForReportId] = useState<number>();

    const { messages } = React.useContext(IntlContext);

    useEffect(() => {
        if (!organizations?.length) {
            getOrganizations && getOrganizations();
        } else if(organizations?.length === 1) {
            setOrganizationId(organizations[0].id);
        }
    }, [getOrganizations, organizations]);

    useEffect(() => {
        if (requestingReportDeletion && !deleting) {
            setRequestingReportDeletion(false);
            setConfirmDeletionForReportId(undefined);
            if (!!deleteError) {
                const errorMessage = messages[deleteError].toString();
                notification['error']({
                    message: messages['app.notification.error_title'].toString(),
                    description: !!errorMessage ? errorMessage : 'An unknown error has occurred.',
                    duration: defaultNotificationDurationInSeconds,
                });
            }
        }
        // eslint-disable-next-line
    }, [requestingReportDeletion, deleting, deleteError]);

    const onSearchClick = (startDateParam: string, endDateParam: string, organizationId?: number) => {
        setStartDate(new Date(startDateParam));
        setEndDate(new Date(endDateParam));
        getReports(new Date(startDateParam), new Date(endDateParam), organizationId);
    };

    const onCreateNewReportClick = () => {
        clearReportData();
        clearSelectedReport();
        props.history.push(CREATE_REPORT_PAGE);
    };

    const onEditReportClick = (reportId: number) => {
        clearSelectedReport();
        setSelectedReportId(reportId);
        props.history.push(EDIT_REPORT_PAGE);
    };

    const onDeleteReportClick = (reportId: number) => {
        setConfirmDeletionForReportId(reportId);
    }

    const handleDeletionModalExit = () => {
        setConfirmDeletionForReportId(undefined);
    }

    const handleDeletionModalConfirmation = () => {
        if (!!confirmDeletionForReportId) {
            deleteReport(confirmDeletionForReportId);
        }
        setRequestingReportDeletion(true);
    }

    if (loadingPage || deleting) {
        return <Loading />
    }
    
    return (
        <React.Fragment>
            <FilterListReport
                onCreateNewReportClick={onCreateNewReportClick}
                userInfo={tokenInfo}
                startDate={formatDate(startDate)}
                endDate={formatDate(endDate)}
                organizations={organizations}
                selectedOrganizationId={organizationId}
                onOrganizationChanged={(newOrganizationId?: number) => setOrganizationId(newOrganizationId)}
                searchHandleClick={onSearchClick}
            />
            {loading ?
                <Loading />
                :
                <ListReport
                    userInfo={tokenInfo}
                    report={reports}
                    onEditReportClicked={onEditReportClick}
                    onDeleteReportClicked={onDeleteReportClick}
                />
            }
            <ConfirmationModal
                showModal={!!confirmDeletionForReportId}
                titleFormattedMessageId={'app.listreport.confirm_deletion_header'}
                bodyFormattedListMessageId={['app.listreport.confirm_deletion_body']}
                primaryButtonFormattedMessageId={'app.btn_confirm'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleDeletionModalExit}
                onPrimaryButtonClicked={handleDeletionModalConfirmation}
                onSecondaryButtonClicked={handleDeletionModalExit}
            />
        </React.Fragment>
    )
});

