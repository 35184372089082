import 'antd/dist/reset.css';
// import 'antd/lib/page-header/style/css';

import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

import search from '../../assets/images/no_info_search.png';
import { NoInfo } from '../../components/NoInfo';
import { colors } from '../../config/colors';
import {
    SearchProgramsDetails, SearchSubProgramsDetails
} from '../../services/organizationService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { CardProgram } from './components/CardProgram';
import { SwapOutlined } from '@ant-design/icons';
import { TokenInfo } from '../../services/loginService';
import { HasPermissionCapabilityProgramAdmin } from '../../utils/userHelper';
import { UserPermissions } from '../../services/usersService';

type Props = {
    organizationId: number;
    organizationName: string;
    loadingQrCodeDownload: boolean;
    programs: SearchProgramsDetails[];
    onCreateProgramClick: () => void;
    onCreateSubProgramClick: (program: SearchProgramsDetails) => void;
    onSubProgramEditClick: (program: SearchProgramsDetails, subprogram: SearchSubProgramsDetails) => void;
    onDetailsSubProgramClick: (program: SearchProgramsDetails, subprogram: SearchSubProgramsDetails) => void;
    handleClickDownloadDataReport:
    (organizationId: number, programId?: number, subProgramId?: number) => void;
    onQrCodeDetailsClick: (subProgramId: number) => void;
    showDataDownloadButton: boolean;
    onRenameProgramModalOpen: (program: any) => void;
    handleClickSort: () => void;
    onChatAllClick: (programId: number) => void;
    userInfo: TokenInfo;
}

export const ListOrganization = (
    {
        programs, organizationName, organizationId, onCreateProgramClick, onSubProgramEditClick,
        onDetailsSubProgramClick, onCreateSubProgramClick, handleClickDownloadDataReport,
        onQrCodeDetailsClick, showDataDownloadButton, loadingQrCodeDownload, onRenameProgramModalOpen,
        handleClickSort, onChatAllClick, userInfo
    }: Props
): JSX.Element => {

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <PageHeader
                    key={organizationName}
                    style={styles.pageHeader}
                    onBack={() => null}
                    title={organizationName}
                    backIcon={null}
                    extra={[<OverlayTrigger
                        key={`overlayDataSort-${organizationId}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip
                                id={`dataSortTooltip`}
                                key={`dataSortTooltip-${organizationId}`}
                            >
                                {GetFormattedMessage('app.listorganizations.sort_name_date')}
                            </Tooltip>
                        }
                    >
                        <span>
                            <SwapOutlined
                                onClick={() => handleClickSort()}
                                style={styles.sortIcon}
                            />
                        </span>
                    </OverlayTrigger>,
                    showDataDownloadButton &&
                    <OverlayTrigger
                        key={`overlayDataDownload-${organizationId}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip
                                id={`downloadReportDataOrganizationTooltip`}
                                key={`downloadReportDataOrganizationTooltip-${organizationId}`}
                            >
                                {GetFormattedMessage('app.listorganizations.download.data_report')}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FaCloudDownloadAlt
                                onClick={() => handleClickDownloadDataReport(organizationId, undefined, undefined)}
                                size={30}
                            />
                        </span>
                    </OverlayTrigger>,
                    <>
                        {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canAddProgramExperience) && <OverlayTrigger
                            key={`overlayNewProgram-${organizationId}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`createNewProgramTooltip`} key={`createNewProgramTooltip-${organizationId}`}>
                                    {GetFormattedMessage('app.listorganizations.new.program')}
                                </Tooltip>
                            }
                        >
                            <span>
                                <IoMdAddCircleOutline size={30} onClick={() => onCreateProgramClick()} />
                            </span>
                        </OverlayTrigger>}
                    </>,
                    ]}
                />
                <Accordion>
                    {!programs?.length
                        ? (
                            <NoInfo
                                title={'app.no.info.title.no_data'}
                                subTitle={'app.no.info.subtitle.no_data'}
                                image={search}
                            />
                        ) : (
                            programs?.map((item, index) =>
                                <CardProgram
                                    key={index + '-' + item.name}
                                    eventKey={index.toString()}
                                    programName={item.name}
                                    programId={item.id}
                                    organizationId={organizationId}
                                    records={item.subPrograms}
                                    loadingQrCodeDownload={loadingQrCodeDownload}
                                    onEditSubProgramClick={subProgram => onSubProgramEditClick(item, subProgram)}
                                    onDetailsSubProgramClick={subProgram => onDetailsSubProgramClick(item, subProgram)}
                                    onCreateSubProgramClick={() => onCreateSubProgramClick(item)}
                                    onQrCodeDetailsClick={onQrCodeDetailsClick}
                                    handleClickDownloadDataReport={handleClickDownloadDataReport}
                                    showDataDownloadButton={showDataDownloadButton}
                                    onRenameProgramModalOpen={onRenameProgramModalOpen}
                                    onChatAllClick={onChatAllClick}
                                    userInfo={userInfo}
                                />
                            )
                        )}
                </Accordion>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    pageHeader: {
        border: '1px solid ' + colors.gray
    } as React.CSSProperties,
    sortIcon: {
        fontSize: 30,
        rotate: '90deg',
        transform: 'scaleX(-1)',
        cursor: 'initial'
    }
};
