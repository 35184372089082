import { ListFormPageMessages } from '../types';

export const listFormsPageCA: ListFormPageMessages = {
    'app.listforms.title': 'El meu tauler de control BEVI',
    'app.listforms.subtitle': 'El sistema de tauler de control BEVI et permet fer el BEVI i revisar i gestionar els teus informes BEVI.',
    'app.listforms.organization': 'Organització',
    'app.listforms.participant': 'Participant',
    'app.listforms.program': 'Programa',
    'app.listforms.take_by': 'Data de Venciment',
    'app.listforms.completed_on': 'Data de Finalització',
    'app.listforms.progress': 'Progrés',
    'app.listforms.status': 'Estat',
    'app.listforms.custom_agreement_status': '(TODO) Custom Agreement Status',
    'app.listforms.action': 'Acció',
    'app.listforms.organization_tooltip': 'El sistema de tauler de control BEVI et permet fer el BEVI i revisar i gestionar els teus informes BEVI.',
    'app.listforms.program_tooltip': "El nom de l'informe, el programa o l'experiència per al qual esteu fent el BEVI.",
    'app.listforms.take_by_tooltip': 'Aquesta és la data en què s’ha de completar el BEVI.',
    'app.listforms.completed_on_tooltip': 'Aquesta és la data en què tu has completat el BEVI.',
    'app.listforms.progress_tooltip': 'El grau en què has completat una administració del BEVI (i.e., 100% vol dir que has completat el BEVI)',
    'app.listforms.status_tooltip': 'Finestreta emergent de la columna d’estat',
    'app.listforms.action_tooltip': 'Aquí, pots fer, completar o refer el BEVI o veure el teu informe BEVI. La capseta t’indica què pots fer en aquests moments.',
    'app.listforms.all': 'Tot',
    'app.listforms.available': 'Encara no s’ha començat',
    'app.listforms.onDemographics': 'Sobre qüestiones demogràfiques',
    'app.listforms.onScales': 'Sobre qüestions del BEVI',
    'app.listforms.onSubjective': 'Sobre respostes escrites',
    'app.listforms.finished': 'Completat',
    'app.listforms.cancelled': 'Cancel·lat',
    'app.listforms.expired': 'Caducat',
    'app.listforms.archived': 'Arxivat',
    'app.listforms.invalid': 'No vàlid',
    'app.listforms.start': 'Fer el BEVI',
    'app.listforms.continue': 'Completar el BEVI',
    'app.listforms.report': 'Veure l’informe BEVI',
    'app.listforms.partner_report': "Reviseu l'informe del soci",
    'app.listforms.partner_change_report': "Reviseu l'informe de canvi de soci",
    'app.listforms.restart': 'Fer el BEVI de nou',
    'app.listforms.confirmation_title': 'Instruccions',
    'app.listforms.retart_confirmation_title': 'Tornar a començar el BEVI',
    'app.listforms.restart.instruction.part1': 'Fer clic al botó següent per començar el BEVI de nou.',
    'app.listforms.instruction.part1': 'L’instrument a continuació consisteix en ítems d’informació addicional i 185 qüestions, amb 20 qüestions per pàgina. ' +
        'Si us plau, llegeix cada qüestió i indica la teva resposta, escollint una de les quatre opcions que millor descriu com et sents: ' +
        'molt d’acord, d’acord, en desacord, molt en desacord.',
    'app.listforms.instruction.part2': 'Una vegada que hagis contestat a cada grup de qüestions, prem continuar per seguir endavant; una vegada que hagis donat al botó de continuar no pots tornar enrere i canviar respostes anteriors.',
    'app.listforms.instruction.part3': 'Has d’indicar que has llegit la informació sobre el BEVI i donar el teu consentiment informat abans que puguis completar el BEVI.',
    'app.listforms.instruction.press.start': 'Gràcies per participar. Si us plau prem començar si ja estàs preparat/da.',
    'app.listforms.comparative_individual_report': 'Comparar informe individual',
    'app.form.status_title': 'Formulari BEVI no disponible',
    'app.form.status_finished_subtitle': 'Sembla que ja s’ha contestat al formulari.',
    'app.form.status_finished_body': 'Posa’t en contacte amb els responsables si tens qualsevol qüestió sobre l’estat del formulari.',
    'app.form.status_cancelled_subtitle': 'Sembla que s’ha cancel·lat el formulari.',
    'app.form.status_cancelled_body': ' Posa’t en contacte amb els responsables si tens qualsevol qüestió sobre l’estat del formulari.',
    'app.form.status_expired_subtitle': 'Sembla que el formulari hagi caducat',
    'app.form.status_expired_body': ' Posa’t en contacte amb els responsables si tens qualsevol qüestió sobre l’estat del formulari.',
    'app.form.invalid_status.by_time': 'No vàlid per Temps',
    'app.form.invalid_status.by_number_of_questions': 'No vàlid pel Nombre de Qüestions',
    'app.form.invalid_status.by_consecutive_answers': 'No vàlid per Respostes Consecutives',
    'app.form.invalid_status.by_common_answers': 'No vàlid per Respostes en Comú',
    'app.form.invalid_status.by_congruency': 'No vàlid per Congruència',
    'app.form.invalid_status.by_consistency': 'No vàlid per Coherència',
    'app.form.invalid_status.by_wrong_scale_value': 'No vàlid per Valor d’Escala Erroni',
    'app.form.custom_agreement.not_requested': '(TODO) Not requested',
    'app.form.custom_agreement.pending': '(TODO) Pending',
    'app.form.custom_agreement.accepted': '(TODO) Accepted',
    'app.form.custom_agreement.rejected': '(TODO) Rejected',
    'app.form.actions.tooltip.send_custom_agreement': '(TODO) Send custom agreement',
    'app.form.actions.tooltip.resend_custom_agreement': '(TODO) Resend custom agreement',
    'app.form.actions.tooltip.show_report': '(TODO) Show report',
    'app.listforms.ai_interactions': "Interaccions d'AI"
}
