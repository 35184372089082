import { serviceUrl } from '../config/constants';
import {
    BaseResponse,
    fetchGetMethod,
    fetchPostDataObjectMethod,
    fetchPostMethod,
    getResponseDataObject,
    getResponseObject,
    getResponseObjectWithoutBody
} from '../rpc/api';
import {
    ComparativeByGroupReportItem,
    ComparativeByIndividualReportItem,
    GetDataDownloadResponse,
    GroupReportItem,
    IndividualReport,
    IndividualReportModel,
    LongitudinalReportItem
} from './types';

const errorMessageKey = 'app.error.logon';
const errorUserNotFoundMessageKey = 'app.error.user_not_found';

export class GetIndividualReportResponse extends BaseResponse<IndividualReport> { }

export enum Reports {
    all = 0,
    group_report = 1,
    longitudinal_report = 2,
    comparative_by_group_report = 3,
    comparative_by_individual = 4,
    individual_report = 5,
    longitudinal_individual_report = 6,
    partner_report = 7,
    partner_change_report = 8,
}

export enum ReportsGroup {
    individual = 1,
    partner = 2,
    group = 3,
}

export type DataDownloadParams = {
    organizationId: number;
    programId?: number;
    subProgramId?: number;
    language?: string;
    removeAnonymous: boolean;
    studyAbroad: boolean;
    additionalDemoInfo: boolean;
}

export interface ComparativeIndividualReportModel {
    formIds: number[];
    generateArea: string;
    reportId: number | null;
    language: ReportLanguage;
}

export interface SaveIndividualAndCombinedReportRequest {
    Id?: number;
    Name: string;
    ReportType: Reports;
    SelectedForms: number[];
}

export interface SaveIndividualReportResponse {
    reportId: number;
    reportType: number;
}

export interface UpdateReportPermissionResponse {
    status: number;
    reportPermissionId: number;
}

export interface ReportPermissionResponse {
    id: number;
    report: any;
    reportId: number;
    reportType: number;
    requesterId: number;
    requester: any;
    approverId: number;
    status: ReportPermissionStatus;
    creationDate: Date;
    updateDate?: Date;
    visualizers?: string[];
    approvers?: string[];
}

export enum ReportPermissionStatus {
    Pending = 1,
    Approved = 2,
    Rejected = 3,
}

export enum ScaleGroup {
    All = 0,
    NegativeLifeEvents = 1,
    NeedsClosure = 2,
    NeedsFulfillment = 3,
    IdentityDiffusion = 4,
    BasicOpenness = 5,
    SelfCertitude = 6,
    BasicDeterminism = 7,
    SocioemotionalConvergence = 8,
    PhysicalResonance = 9,
    EmotionalAttunement = 10,
    SelfAwereness = 11,
    MeaningQuest = 12,
    ReligiousTraditionalism = 13,
    GenderTraditionalism = 14,
    SocioculturalOpenness = 15,
    EcologicalResonance = 16,
    GlobalResonance = 17,
    Consistency = 999,
    Congruency = 1000,
}

export type DecileProfileItem = {
    decileTitle: string;
    decileCode: ScaleGroup;
    value1: number;
    value2: number;
    value3: number;
    value4: number;
    value5: number;
    value6: number;
    value7: number;
    value8: number;
    value9: number;
    value10: number;
}

export function loadIndividualReport(token: string, formId: number): Promise<GetIndividualReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/individualReport?formId=${formId}`, token);
    return getResponseObject<IndividualReport>(response, errorMessageKey);
}

export function sendIndividualReportAgreement(token: string, formId: number): Promise<BaseResponse<{}>> {
    const body = { individualReportId: formId };
    const response = fetchPostMethod(`${serviceUrl}/api/report/SetIndividualReportAgreement`, token, body);
    return getResponseObjectWithoutBody(response, errorMessageKey);
}

export function signCustomConsent(
    token: string, formId: number, accepted: boolean
): Promise<BaseResponse<{}>> {
    const body = {
        formId: formId,
        accepted: accepted,
    };
    const response = fetchPostMethod(`${serviceUrl}/api/report/SignCustomConsent`, token, body);
    return getResponseObjectWithoutBody(response, errorMessageKey);
}

export function downloadDataReport(token: string, body: DataDownloadParams): Promise<GetDataDownloadResponse> {
    const response = fetchPostDataObjectMethod(`${serviceUrl}/api/report/DataDownload`, token, body);
    return getResponseDataObject(response, errorMessageKey, 'data_download.xlsx');
}

export class GetGroupReportResponse extends BaseResponse<GroupReportItem> { }

export function getGroupReport(token: string, reportId: number): Promise<GetGroupReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/GroupReport?reportId=${reportId}`, token);
    return getResponseObject<GroupReportItem>(response, errorMessageKey);
}

export class GetLongitudinalReportResponse extends BaseResponse<LongitudinalReportItem> { }

export function getLongitudinalReport(token: string, reportId: number): Promise<GetLongitudinalReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/LongitudinalReport?reportId=${reportId}`, token);
    return getResponseObject<LongitudinalReportItem>(response, errorMessageKey);
}

export class GetComparativeByGroupReportResponse extends BaseResponse<ComparativeByGroupReportItem> { }

export function getComparativeByGroupReport(token: string, reportId: number): Promise<GetComparativeByGroupReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/ComparativeByGroupReport?reportId=${reportId}`, token);
    return getResponseObject<ComparativeByGroupReportItem>(response, errorMessageKey);
}

export class GetComparativeByIndividualReportResponse extends BaseResponse<ComparativeByIndividualReportItem> { }
export class GetComparativeIndividualReportResponse extends BaseResponse<IndividualReportModel> { }
export class GetValidIndividualFormsResponse extends BaseResponse<FormModel> { }
export class PostSaveIndividualFormsResponse extends BaseResponse<number> { }
export class GetReportPermissionAmoutReponse extends BaseResponse<number> { }
export class GetReportPermissionReponse extends BaseResponse<ReportPermissionResponse> { }

export function getComparativeByIndividualReport(token: string, reportId: number): Promise<GetComparativeByIndividualReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/ComparativeByIndividualReport?reportId=${reportId}`, token);
    return getResponseObject<ComparativeByIndividualReportItem>(response, errorMessageKey);
}

export function getComparativeIndividualReport(token: string, body: ComparativeIndividualReportModel): Promise<GetComparativeIndividualReportResponse> {
    const response = fetchPostDataObjectMethod(`${serviceUrl}/api/report/ComparativeIndividualReport`, token, body);
    return getResponseObject<IndividualReportModel>(response, errorMessageKey);
}

export function getFindValidIndividualForms(token: string, email: string): Promise<GetValidIndividualFormsResponse> {
    const response = fetchPostDataObjectMethod(`${serviceUrl}/api/report/FindValidIndividualForms`, token, { email });
    return getResponseObject<FormModel>(response, errorUserNotFoundMessageKey);
}

export function postSaveIndividualReport(token: string, data: SaveIndividualAndCombinedReportRequest): Promise<PostSaveIndividualFormsResponse> {
    const response = fetchPostDataObjectMethod(`${serviceUrl}/api/report/SaveIndividualAndCombinedReport`, token, data);
    return getResponseObject<number>(response, errorMessageKey);
}

export function getReportPermission(token: string): Promise<GetReportPermissionReponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/ReportPermission`, token);
    return getResponseObject<ReportPermissionResponse>(response, errorMessageKey);
}

export function getReportPermissionAmout(token: string): Promise<GetReportPermissionAmoutReponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/ReportPermissionAmount`, token);
    return getResponseObject<number>(response, errorMessageKey);
}

export function updateReportPermission(token: string, data: UpdateReportPermissionResponse): Promise<any> {
    const response = fetchPostDataObjectMethod(`${serviceUrl}/api/report/UpdateReportPermission`, token, data);
    return getResponseObject<any>(response, errorMessageKey);
}

export type SubProgramModel = {
    id: number;
    isActive?: boolean;
    description: string;
}

export type ProgramModel = {
    id: number;
    isActive?: boolean;
    name: string;
    subPrograms: SubProgramModel[]
}

export type OrganizationModel = {
    id: number;
    name: string;
    active?: boolean;
    programs: ProgramModel[];
}

export class OrganizationProgramAndSubProgramResponse extends BaseResponse<OrganizationModel[]> { }

export function getOrganizationProgramAndSubProgram(
    token: string
): Promise<OrganizationProgramAndSubProgramResponse> {

    const response = fetchGetMethod(`${serviceUrl}/api/organization/GetOrganizationProgramAndSubProgram`, token);
    return getResponseObject<OrganizationModel[]>(response, errorMessageKey);
}

export type FormModel = {
    id: number;
    email: string;
    name: string;
    program: string;
    subProgram: string;
}

export type UserFormModel = {
    formId: number;
    userName: string;
    alias: string;
    fakeAlias: string;
}

export type ReportFormsModel = {
    groupName: string;
    forms: UserFormModel[];
}

export type SearchFormsRequest = {
    subProgramsIds: number[];
    age: number[];
    gender: number[];
    yearsOfEducation: number[];
    primaryCountryOfOrigin: number[];
    politicalOrientation: number[];
    maritalStatus: number[];
    ethnicBackground: number[];
    minorityGroup: number[];
    religiousOrientation: number[];
    religionCommitment: number[];
    academicRank: number[];
    nroVisitedCountry: number[];
    timeSpentInAnotherCountry: number[];
    personalInterest: number[];
    motherCountry: number[];
    fatherCountry: number[];
    motherEducation: number[];
    fatherEducation: number[];
    studyAbroadSatisfaction: number[];
    yearsOfLanguageStudy: number[];
    primaryAreaOfWork: number[];
    incomeLevelOfYourHomeCountry: number[];
    areaOfOccupations: string;
    fluency: number[];
}


export type SearchFormsGroupRequest = {
    groups: {
        groupName: string;
        subProgramsIds: number[];
    }[];
    age: number[];
    gender: number[];
    yearsOfEducation: number[];
    primaryCountryOfOrigin: number[];
    politicalOrientation: number[];
    maritalStatus: number[];
    ethnicBackground: number[];
    minorityGroup: number[];
    religiousOrientation: number[];
    religionCommitment: number[];
    academicRank: number[];
    nroVisitedCountry: number[];
    timeSpentInAnotherCountry: number[];
    personalInterest: number[];
    motherCountry: number[];
    fatherCountry: number[];
    motherEducation: number[];
    fatherEducation: number[];
    studyAbroadSatisfaction: number[];
    yearsOfLanguageStudy: number[];
    primaryAreaOfWork: number[];
    incomeLevelOfYourHomeCountry: number[];
    areaOfOccupations: string;
    fluency: number[];
}

export class SearchUserFormsResponse extends BaseResponse<ReportFormsModel[]> { }

export function searchUserFormsLongitudinal(token: string, request: SearchFormsRequest): Promise<SearchUserFormsResponse> {

    const response = fetchPostMethod(`${serviceUrl}/api/report/SearchFormsLongitudinal`, token, request);
    return getResponseObject<ReportFormsModel[]>(response, errorMessageKey);
}

export function searchUserForms(token: string, request: SearchFormsRequest): Promise<SearchUserFormsResponse> {

    const response = fetchPostMethod(`${serviceUrl}/api/report/SearchForms`, token, request);
    return getResponseObject<ReportFormsModel[]>(response, errorMessageKey);
}

export function searchUserFormsGroup(token: string, request: SearchFormsGroupRequest): Promise<SearchUserFormsResponse> {

    const response = fetchPostMethod(`${serviceUrl}/api/report/SearchFormsByGroup`, token, request);
    return getResponseObject<ReportFormsModel[]>(response, errorMessageKey);
}


export function searchUserFormsGroupLongitudinal(token: string, request: SearchFormsGroupRequest): Promise<SearchUserFormsResponse> {

    const response = fetchPostMethod(`${serviceUrl}/api/report/SearchFormsLongitudinalByGroup`, token, request);
    return getResponseObject<ReportFormsModel[]>(response, errorMessageKey);
}

export type ParticipantModel = {
    id: number;
    alias: string;
    groupName?: string;
}

export type SaveReportRequest = {
    id?: number;
    name: string;
    reportType: Reports;
    subPrograms: number[];
    participants: ParticipantModel[];
    searchFilters: Partial<SearchFormsRequest>;
    selectedCountries: number[];
    selectedEthnics: number[];
    selectedReligions: number[];
    selectedRelationship: number[];
    selectedPrimaryAreasOfWork: number[];
    maxNumberForms: number;
    groupOrder: string[];
}

export type SaveReportResponseBody = {
    reportId: number;
    reportType: Reports;
};

export class SaveReportResponse extends BaseResponse<SaveReportResponseBody> { }

export function saveReport(token: string, request: SaveReportRequest): Promise<SaveReportResponse> {
    const response = fetchPostMethod(`${serviceUrl}/api/report/SaveReport`, token, request);
    return getResponseObject<SaveReportResponseBody>(response, errorMessageKey);
}

export class ListReportResponse extends BaseResponse<ListReportModel[]> { }

export enum ReportStatus {
    All = 0,
    Completed = 1,
    PendingParticipantApproval = 2,
}

export type ListReportModel = {
    id: number;
    createdTimeOnUtc: string;
    name: string;
    reportType: number;
    canDelete: boolean;
    reportStatus: ReportStatus;
}

export type ListReportRequest = {
    startDate: Date;
    endDate: Date;
    organizationId?: number;
    isSampleDataReport?: boolean;
}

export function listReports(token: string, request: ListReportRequest): Promise<ListReportResponse> {
    const response = fetchPostMethod(`${serviceUrl}/api/report/GetReports`, token, request);
    return getResponseObject<ListReportModel[]>(response, errorMessageKey);
}

export type DeleteReportRequest = {
    reportId: number;
};

export class DeleteReportResponse extends BaseResponse<{}> { }

export function deleteReport(token: string, request: DeleteReportRequest): Promise<DeleteReportResponse> {
    const response = fetchPostMethod(`${serviceUrl}/api/report/DeleteReport`, token, request);
    return getResponseObjectWithoutBody(response, errorMessageKey);
}

export type GetReportModel = {
    id: number;
    maxNumberForms: number;
    name: string;
    participants: ReportFormsModel[];
    reportType: Reports;
    selectedCountries: number[];
    selectedEthnics: number[];
    selectedReligions: number[];
    selectedRelationships: number[];
    selectedPrimaryAreaOfWorkCodes: number[];
    subPrograms: number[];
    searchFilters?: SearchFormsRequest;
}

export class GetReportResponse extends BaseResponse<GetReportModel> { }

export function getReport(token: string, reportId: number): Promise<GetReportResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/report/GetReport?reportId=${reportId}`, token);
    return getResponseObject<GetReportModel>(response, errorMessageKey);
}

export type GetFinancialReportRequest = {
    organizationId: number;
    startDate: Date;
    endDate: Date;
}

export type FinancialReportBody = {
    total: number;
    done: number;
    valid: number;
    invalid: number;
    notFinish: number;
}

export class GetFinancialReportResponse extends BaseResponse<FinancialReportBody> { }

export function getFinancialReport(
    token: string, organizationId: number, startDate: Date, endDate: Date
): Promise<GetFinancialReportResponse> {
    const request: GetFinancialReportRequest = { organizationId, startDate, endDate }
    const response = fetchPostMethod(`${serviceUrl}/api/report/FinancialReport`, token, request);
    return getResponseObject<FinancialReportBody>(response, errorMessageKey);
}

export enum ReportLanguage {
    All = 0,
    English = 1,
    Japanese = 2,
    Spanish = 4,
    Catalan = 5,
    ChineseSimplified = 6,
    ChineseTradicional = 7,
    German = 8,
}

export type DownloadIndivudualReportParams = {
    formId: number;
    language: ReportLanguage;
}

export class DownloadReportResponse extends BaseResponse<GetDataDownloadResponse> { }

export function downloadIndividualReport(
    token: string, params: DownloadIndivudualReportParams
): Promise<DownloadReportResponse> {
    const body = { formId: params.formId, language: params.language };
    const response = fetchPostDataObjectMethod(
        `${serviceUrl}/api/report/DownloadIndividualReport`,
        token,
        body
    );
    return getResponseDataObject(response, errorMessageKey, 'individual_report.pdf');
}

export type DownloadReportParams = {
    reportId: number;
    language: ReportLanguage;
}

export function downloadGroupReport(
    token: string, params: DownloadReportParams
): Promise<DownloadReportResponse> {
    const body = { reportId: params.reportId, language: params.language };
    const response = fetchPostDataObjectMethod(
        `${serviceUrl}/api/report/DownloadGroupReport`,
        token,
        body
    );
    return getResponseDataObject(response, errorMessageKey, 'group_report.pdf');
}

export function downloadWithinGroupReports(
    token: string, params: DownloadReportParams
): Promise<DownloadReportResponse> {
    const body = { reportId: params.reportId, language: params.language };
    const response = fetchPostDataObjectMethod(
        `${serviceUrl}/api/report/DownloadWithinGroupReport`,
        token,
        body
    );
    return getResponseDataObject(response, errorMessageKey, 'within-group-report.pdf');
}

export function downloadBetweenGroupReports(
    token: string, params: DownloadReportParams
): Promise<DownloadReportResponse> {
    const body = { reportId: params.reportId, language: params.language };
    const response = fetchPostDataObjectMethod(
        `${serviceUrl}/api/report/DownloadBetweenGroupReport`,
        token,
        body
    );
    return getResponseDataObject(response, errorMessageKey, 'between-group-report.pdf');
}

export function downloadLongitudinalReports(
    token: string, params: DownloadReportParams
): Promise<DownloadReportResponse> {
    const body = { reportId: params.reportId, language: params.language };
    const response = fetchPostDataObjectMethod(
        `${serviceUrl}/api/report/DownloadLongitudinalReport`,
        token,
        body
    );
    return getResponseDataObject(response, errorMessageKey, 'longitudinal-report.pdf');
}