import { useActions, useValues } from 'kea';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import search from '../../assets/images/no_info_search.png';
import { NoInfo } from '../../components/NoInfo';
import { appLogic, AppProps } from '../../redux/appLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import {
    OrganizationActions, organizationLogic, OrganizationProps
} from '../../redux/organizationLogic';
import { SaveOrganizationPayloadRequest } from '../../services/organizationService';
import { Sleep } from '../../utils/sleeptHelper';
import { HIDE_DEMOGRAPHICS_QUESTIONS, ORGANIZATION_DEMOGRAPHICS_EXTRA_QUESTIONS_PAGE } from '../../router/pages';
import { CreateInstitutionModal } from './components/CreateInstitutionModal';
import { InstitutionsTitle } from './components/InstitutionsTitle';
import { ListInstitutions } from './ListInstitutions';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { DemographicQuestionsActions, demographicQuestionsLogic } from '../../redux/demographicQuestionsLogic';

type Props = RouteComponentProps;

export const ListInstitutionsContainer = withRouter((props: Props): JSX.Element => {

    const { tokenInfo }: AppProps = useValues(appLogic);

    const { organizations, loadingPage }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { getOrganizations, setSelectedInstitutionId }: ListOrganizationActions = useActions(listOrganizationLogic);

    const { hasCreatedNewOrganization, errorMessageNewOrganization, hasAddChatOrganization, errorMessageAddChatOrganization }: OrganizationProps = useValues(organizationLogic);
    const { createNewOrganization, clearSuccessfulCreateNewOrganization, addChatOrganization, clearSuccessfulAddChatOrganization }: OrganizationActions = useActions(organizationLogic);

    const { clearDemographicsExtraQuestions }: DemographicQuestionsActions = useActions(demographicQuestionsLogic);

    const [selectedInstitution, setSelectedInstitution] = useState<any>(undefined);
    const [loadingSaveInstitutions, setLoadingSaveInstitutions] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [chatSuccess, setChatSuccess] = useState<boolean>(false);

    useEffect(() => {
        getOrganizations();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ((hasCreatedNewOrganization === true) || errorMessageNewOrganization === true) {
            setLoadingSaveInstitutions(false);
        }
        if (hasCreatedNewOrganization === true)
            setSuccess(hasCreatedNewOrganization);
    }, [hasCreatedNewOrganization, errorMessageNewOrganization]);

    useEffect(() => {
        async function clearSuccessAfterDelay() {
            await Sleep(10000);
            setSuccess(false);
        }

        if (success === true) {
            clearSuccessAfterDelay();
            clearSuccessfulAddChatOrganization();
        }
    }, [clearSuccessfulAddChatOrganization, success])

    useEffect(() => {
        if (hasAddChatOrganization === true)
            setChatSuccess(hasAddChatOrganization);
    }, [hasAddChatOrganization, errorMessageAddChatOrganization]);

    useEffect(() => {
        async function clearSuccessAfterDelay() {
            await Sleep(10000);
            setChatSuccess(false);
        }

        if (chatSuccess === true) {
            clearSuccessAfterDelay();
        }
    }, [chatSuccess])

    const onSuccessfullSaveInstitutionModalHide = async () => {
        setSelectedInstitution(undefined);
        clearSuccessfulCreateNewOrganization();
        getOrganizations();
    }

    const onModalHide = () => {
        setSelectedInstitution(undefined);
    }

    const onSaveInstitutionHandleClick = (organization: SaveOrganizationPayloadRequest) => {
        setLoadingSaveInstitutions(true);
        createNewOrganization(organization);
    }

    const onHideDemographicsQuestionsHandleClick = (institutionId: number) => {
        clearSuccessfulCreateNewOrganization();
        setSelectedInstitutionId(institutionId);
        props.history.replace(HIDE_DEMOGRAPHICS_QUESTIONS);
    }

    const onDemographicsExtraQuestionsHandleClick = (institutionId: number) => {
        clearDemographicsExtraQuestions();
        setSelectedInstitutionId(institutionId);
        props.history.replace(ORGANIZATION_DEMOGRAPHICS_EXTRA_QUESTIONS_PAGE);
    }

    const onChatAllClick = (institutionId: number) => {
        addChatOrganization({ id: institutionId });
    }

    const onSaveInstitutionModalOpen = (organization?: any) => {
        let data: SaveOrganizationPayloadRequest = { id: 0, name: '' };
        if (organization && organization.id && organization.id > 0 && organization.name && organization.name.length > 0) {
            data.id = organization.id;
            data.name = organization.name;
        }
        setSelectedInstitution(data);
    }

    return (
        <Fragment>
            <InstitutionsTitle
                onSaveInstitutionModalOpen={onSaveInstitutionModalOpen}
            />
            {success &&
                <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.institutions.add.successful_message') }} />
            }
            {chatSuccess &&
                <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.institutions.chat.successful_message') }} />
            }
            {(organizations === undefined || organizations == null || organizations.length === 0) && !loadingPage
                ? (
                    <NoInfo
                        title={'app.no.info.title.no_data'}
                        subTitle={'app.no.info.subtitle.no_data'}
                        image={search}
                    />
                ) : (
                    <>
                        <ListInstitutions
                            userInfo={tokenInfo}
                            data={organizations}
                            loading={loadingPage}
                            onEditClick={onSaveInstitutionModalOpen}
                            onHideDemographicsQuestionsClicked={onHideDemographicsQuestionsHandleClick}
                            onDemographicsExtraQuestionsHandleClick={onDemographicsExtraQuestionsHandleClick}
                            onChatAllClick={onChatAllClick}
                        />
                        <CreateInstitutionModal
                            organization={selectedInstitution}
                            loadingButton={loadingSaveInstitutions}
                            showModal={selectedInstitution !== undefined}
                            onModalHide={onModalHide}
                            onSaveInstitutionHandleClick={onSaveInstitutionHandleClick}
                            onSuccessfullSaveInstitutionModalHide={onSuccessfullSaveInstitutionModalHide}
                            success={hasCreatedNewOrganization === true}
                            error={errorMessageNewOrganization === true}
                        />
                    </>
                )
            }
        </Fragment>
    )
});
