import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': 'Edat',
    'app.demographic.gender': 'Gènere',
    'app.demographic.marital_status': 'Estat civil',
    'app.demographic.level_of_fluency': 'Si us plau, indica el nivell més alt de fluïdesa en qualsevol idioma que no sigui nadiu (que es parli a casa,  a la teva comunitat o idioma vehicular en escoles de primària i secundària on has anat)?.',
    'app.demographic.education_years': 'Si us plau indica quants anys d’educació formal has completat.',
    'app.demographic.raised_in_country_where_currently_in': 'A partir del primer curs d’educació primària (per exemple, més enllà d’educació infantil o preescolar), quants anys has completat d’educació formal:',
    'app.demographic.ethnic': 'Quin origen ètnic tens?',
    'app.demographic.ethnic_other': 'Si us plau, escriu a l’espai següent:',
    'app.demographic.ethnic_asian': 'Asiàtic/a: persona que té orígens en països o regions de l’Àsia Oriental, el subcontinent indi o el sud-est asiàtic, inclosos, per exemple, Cambodja, la Xina, Índia, Japó, Corea, Malàisia, Pakistan, les Illes Filipines, Tailàndia i Vietnam.',
    'app.demographic.ethnic_african': 'Negre/a o africà/na: persona que té origen en qualsevol dels grups racials negres d’Àfrica.',
    'app.demographic.ethnic_latino': 'Hispà/ana o llatinoamericà/ana: persona que té orígens en països o regions que inclouen, per exemple, Cuba, Mèxic, Puerto Rico, Amèrica del Sud o Central o qualsevol altra cultura o origen espanyol, independentment de la raça.',
    'app.demographic.ethnic_indigenous': 'Indígena: persona que té origen en qualsevol dels pobles originals d’un determinat país o regió que manté una afiliació o vinculació amb una tribu o comunitat indígena.',
    'app.demographic.ethnic_middle_eastern': 'De l’Orient Mitjà: persona que té origen en qualsevol dels països o regions des de l’Afganistan fins a Líbia, inclosos, per exemple, Egipte, Iran, Iraq, Israel, Jordània, Líban, Aràbia Saudita, Sudan, Síria, Turquia o països de la península Aràbiga.',
    'app.demographic.ethnic_white': 'Blanc/a o caucàsic/a: persona que té origen en qualsevol dels grups racials blancs d’Europa.',
    'app.demographic.completing_bevi': 'Si us plau, indica quan contestes el BEVI.',
    'app.demographic.completing_bevi_other': 'Si us plau, descriu a continuació  les circumstàncies en les quals contestes el BEVI.',
    'app.demographic.previously_completed': 'Has contestat el BEVI abans?',
    'app.demographic.disadvantaged_or_minority_group': 'Segons la teva experiència, ets membre d’un grup marginat o desafavorit?',
    'app.demographic.disadvantaged_or_minority_group_yes': ' Si us plau, proporciona informació addicional a l’espai següent.',
    'app.demographic.primary_area_of_work': 'De les opcions següents, quina representa o s’acosta més a l’àrea de coneixement dels teus estudis o la teva àrea de treball principal / interès professional?',
    'app.demographic.current_area': 'A l’espai següent, indica les teves àrees d’estudi o ocupacions professionals actuals:',
    'app.demographic.political_orientation': 'En el rang des de “Conservador/tradicionalista (per exemple, partidari de conservar i preservar les lleis, sistemes i maneres de viure)” fins a “Liberal/progressista (per exemple, donant suport al canvi o reforma de lleis, sistemes i maneres de viure)”, si us plau, indica la teva orientació política utilitzant el control lliscant següent.',
    'app.demographic.political_commitment': 'Si us plau, indica el teu nivell de compromís personal amb l’orientació política que tens.',
    'app.demographic.political_orientation_conservative_label': 'Conservador/tradicionalista',
    'app.demographic.political_orientation_liberal_label': 'Liberal/progressista',
    'app.demographic.religion': 'Quina de les opcions següents correspon més a les teves creences o sentit de compromís sobre religió o espiritualitat?',
    'app.demographic.religion_other': 'Si us plau, descriu a l’espai següent:',
    'app.demographic.religion_scale': 'Si us plau, indica el teu nivell de compromís personal amb la religió o l’espiritualitat.',
    'app.demographic.religion_scale_specified': 'Si us plau, indica el teu nivell de compromís personal amb {0}',
    'app.demographic.income_level': 'Utilitza el control lliscant següent per indicar els teus ingressos o accés a recursos en comparació amb altres de la teva societat que van des de “Rang més baix” a “Rang més alt”.',
    'app.demographic.income_less_label': 'Rang més baix',
    'app.demographic.income_more_label': 'Rang més alt',
    'app.demographic.academic_rank_lowest_label': 'Rang més baix',
    'app.demographic.academic_rank_highest_label': 'Rang més baix',
    'app.demographic.academic_rank': 'En relació amb altres estudiants que han estat a classe amb tu en la teva experiència educativa més recent, assenyala la millor estimació del teu nivell acadèmic o del teu rang (per exemple, des de "Rang més baix" a "Rang més alt").',
    'app.demographic.primary_country': 'On has nascut?',
    'app.demographic.time_in_other_countries': 'De les opcions següents, indica la quantitat de temps que has passat en països o regions diferents del teu país d’origen.',
    'app.demographic.time_in_other_countries_other': 'Si us plau, especifica-ho',
    'app.demographic.visit_countries': ' Quants països o regions a banda del teu país d’origen has visitat o hi has viscut?',
    'app.demographic.country_explanation_field': 'Per trobar el teu país, pots seleccionar-lo al menú desplegable o simplement escriure i seleccionar l’idioma.',
    'app.demographic.country_mother_born': 'Si us plau, indica on ha nascut la teva mare (país o regió):',
    'app.demographic.country_father_born': 'Si us plau, indica on ha nascut teu pare (país o regió):',
    'app.demographic.personal_interest': 'Si us plau, indica el teu nivell d’interès personal en experiències internacionals, multiculturals o interculturals (per exemple, feina, estudis o serveis a l’estranger; intercanvi virtual (VE) o aprenentatge virtual col·laboratiu internacional (COIL); treballs acadèmics multiculturals; participació en equips de diversitat).',
    'app.demographic.participated_experiences': 'Has completat anteriorment o estàs duent a terme ara alguna de les experiències següents? (Fes clic a tot el que correspongui)',
    'app.demographic.participated_learning_courses': 'Has completat anteriorment o estàs duent a terme ara algun dels següents cursos/programes d’aprenentatge global i de diversitat? (Fes clic a tot el que correspongui)',
    'app.demographic.plan_participate_experiences': 'Tens previst completar alguna de les experiències següents? (Fes clic a tot el que correspongui)',
    'app.demographic.plan_participate_learning_courses': 'Tens previst cursar algun dels següents cursos/programes d’aprenentatge global i de diversitat? (Fes clic a tot el que correspongui)',
    'app.demographic.experience_satisfaction': 'Si has participat o estàs participant en experiències internacionals, multiculturals o interculturals (per exemple, feina, estudis o serveis a l’estranger; intercanvi virtual (VE) o aprenentatge virtual col·laboratiu internacional (COIL); treballs acadèmics multiculturals; participació en equips de diversitat), si us plau, indica el teu nivell general de satisfacció.',
    'app.demographic.languages_speak': 'Quants idiomes parles de manera nativa (parlats a casa o a la comunitat i/o idioma vehicular en escoles o instituts on has estudiat)?',
    'app.demographic.years_studied_foreign_language': 'Quants anys de llengües estrangeres has estudiat?',
    'app.demographic.primary_language': 'Quin és el teu idioma principal (és a dir, el primer o el principal idioma que parles)? Pots seleccionar més d’un idioma si en tens més d’un. Per trobar-lo , pots seleccionar els idiomes del menú desplegable o simplement escriure i seleccionar-los.',
    'app.demographic.primary_language_other': 'Parles altres idiomes que no siguin la teva llengua materna? (és a dir, el teu primer o el teu idioma principal)?',
    'app.demographic.language_level_of_fluency': 'Entre les opcions següents, selecciona qualsevol idioma o idiomes que parles, així com el nivell de fluïdesa.',
    'app.demographic.language_level_of_fluency_add_anwser': 'Afegeix',
    'app.demographic.table_level_of_fluency': 'Nivell de fluïdesa',
    'app.demographic.table_language': 'Llengua',
    'app.demographic.table_action': 'Acció',
    'app.demographic.remove_language_tooltip': 'Eliminar llengua',
    'app.demographic.father_formal_education': 'Si us plau, indica el nivell més alt d’educació formal que té el teu pare:',
    'app.demographic.mother_formal_education': ' Si us plau, indica el nivell més alt d’educació formal que té la teva mare:',
    'app.demographic.comparative': 'Informe Individual Comparatiu',
    'app.demographic.compare': 'Compara',
    'app.demographic.select_multiple_reports': 'Seleccioneu diversos informes',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanès',
    'app.demographic.language_options.Amharic': 'Amhàric',
    'app.demographic.language_options.Arabic': 'Àrab',
    'app.demographic.language_options.Armenian': 'Armeni',
    'app.demographic.language_options.Basque': 'Basc',
    'app.demographic.language_options.Bengali': 'Bengalí',
    'app.demographic.language_options.Byelorussian': 'Bielorús',
    'app.demographic.language_options.Burmese': 'Birmà',
    'app.demographic.language_options.Bulgarian': 'Búlgar',
    'app.demographic.language_options.Catalan': 'Català',
    'app.demographic.language_options.Czech': 'Txec',
    'app.demographic.language_options.Chinese': 'Xinès',
    'app.demographic.language_options.Croatian': 'Croat',
    'app.demographic.language_options.Danish': 'Danès',
    'app.demographic.language_options.Dari': 'Dari (persa afgà)',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Holandès',
    'app.demographic.language_options.English': 'Anglès',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonià',
    'app.demographic.language_options.Faroese': 'Feroès',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finès',
    'app.demographic.language_options.French': 'Francès',
    'app.demographic.language_options.Gaelic': 'Gaèlic',
    'app.demographic.language_options.Galician': 'Gallec',
    'app.demographic.language_options.German': 'Alemany',
    'app.demographic.language_options.Greek': 'Grec',
    'app.demographic.language_options.Hebrew': 'Hebreu',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Hongarès',
    'app.demographic.language_options.Icelandic': 'Islandès',
    'app.demographic.language_options.Indonesian': 'Indonesi',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuit (esquimal)',
    'app.demographic.language_options.Italian': 'Italià',
    'app.demographic.language_options.Japanese': 'Japonès',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Coreà',
    'app.demographic.language_options.Kurdish': 'Kurd',
    'app.demographic.language_options.Laotian': 'Laosià',
    'app.demographic.language_options.Latvian': 'Letó',
    'app.demographic.language_options.Lappish': 'Sami',
    'app.demographic.language_options.Lithuanian': 'Lituà',
    'app.demographic.language_options.Macedonian': 'Macedoni',
    'app.demographic.language_options.Malay': 'Malai',
    'app.demographic.language_options.Maltese': 'Maltès',
    'app.demographic.language_options.Nepali': 'Nepalès',
    'app.demographic.language_options.Norwegian': 'Noruec',
    'app.demographic.language_options.Pashto': 'Paixtu',
    'app.demographic.language_options.Polish': 'Polonès',
    'app.demographic.language_options.Portuguese': 'Portuguès',
    'app.demographic.language_options.Romanian': 'Romanès',
    'app.demographic.language_options.Russian': 'Rus',
    'app.demographic.language_options.Scots': 'Escocès',
    'app.demographic.language_options.Serbian': 'Serbi',
    'app.demographic.language_options.Slovak': 'Eslovac',
    'app.demographic.language_options.Slovenian': 'Eslovè',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Espanyol',
    'app.demographic.language_options.Swedish': 'Suec',
    'app.demographic.language_options.Swahili': 'Suahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagal-filipí',
    'app.demographic.language_options.Tajik': 'Tadjik',
    'app.demographic.language_options.Tamil': 'Tàmil',
    'app.demographic.language_options.Thai': 'Tailandès',
    'app.demographic.language_options.Tibetan': 'Tibetà',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tonga',
    'app.demographic.language_options.Turkish': 'Turc',
    'app.demographic.language_options.Turkmen': 'Turcman',
    'app.demographic.language_options.Ucrainian': 'Ucraïnès',
    'app.demographic.language_options.Urdu': 'Urdú',
    'app.demographic.language_options.Uzbek': 'Uzbek',
    'app.demographic.language_options.Vietnamese': 'Vietnamita',
    'app.demographic.language_options.Welsh': 'Gal·lès ',
    'app.demographic.language_options.Other': 'Un altra',
    'app.demographic.gender.male': 'Home',
    'app.demographic.gender.female': 'Dona',
    'app.demographic.gender.self_identify': 'Prefereixo autocategorització',
    'app.demographic.marital.response_single': 'Solter/a',
    'app.demographic.marital.response_married': 'Casat/da',
    'app.demographic.marital.response_divorced': 'Divorciat/da',
    'app.demographic.marital.response_widowed': 'Vidu/a',
    'app.demographic.marital.response_other': 'Un altre',
    'app.demographic.education_years.response_1': 'Sense educació formal.',
    'app.demographic.education_years.response_2': '1 any',
    'app.demographic.education_years.response_3': '2 anys',
    'app.demographic.education_years.response_4': '3 anys',
    'app.demographic.education_years.response_5': '4 anys',
    'app.demographic.education_years.response_6': '5 anys',
    'app.demographic.education_years.response_7': '6 anys',
    'app.demographic.education_years.response_8': '7 anys',
    'app.demographic.education_years.response_9': '8 anys',
    'app.demographic.education_years.response_10': '9 anys',
    'app.demographic.education_years.response_11': '10 anys',
    'app.demographic.education_years.response_12': '11 anys',
    'app.demographic.education_years.response_13': '12 anys',
    'app.demographic.education_years.response_14': '13 anys',
    'app.demographic.education_years.response_15': '14 anys',
    'app.demographic.education_years.response_16': '15 anys',
    'app.demographic.education_years.response_17': '16 anys',
    'app.demographic.education_years.response_18': '17 anys',
    'app.demographic.education_years.response_19': '18 anys',
    'app.demographic.education_years.response_20': '19 anys',
    'app.demographic.education_years.response_21': '20 anys',
    'app.demographic.education_years.response_22': '21 anys',
    'app.demographic.education_years.response_23': '22 anys',
    'app.demographic.education_years.response_24': '23 anys',
    'app.demographic.education_years.response_25': '24 anys',
    'app.demographic.education_years.response_26': '25 anys',
    'app.demographic.education_years.response_27': '26 anys',
    'app.demographic.education_years.response_28': '27 anys',
    'app.demographic.education_years.response_29': '28 anys',
    'app.demographic.education_years.response_30': '29 anys',
    'app.demographic.education_years.response_31': '30 anys',
    'app.demographic.education_years.response_32': 'Més de 30 anys',
    'app.demographic.ethnic.respose_asian': 'Asiàtic/a',
    'app.demographic.ethnic.respose_african': 'Negre o africà/ana',
    'app.demographic.ethnic.respose_latino': 'Hispà/ana o llatinoamericà/ana',
    'app.demographic.ethnic.respose_indigenous': 'Indígena',
    'app.demographic.ethnic.respose_middle_eastern': 'De l’Orient Mitjà ',
    'app.demographic.ethnic.respose_white': 'Blanc/a o caucàsic/a',
    'app.demographic.ethnic.respose_mixed': 'De raça mixta',
    'app.demographic.ethnic.respose_other': 'Una altra',
    'app.demographic.completing_bevi.response_before': 'Abans (o al començament) d’una experiència d’aprenentatge internacional/intercultural.',
    'app.demographic.completing_bevi.response_during': 'Durant (o enmig) d’una experiència d’aprenentatge internacional/intercultural ',
    'app.demographic.completing_bevi.response_after': 'Després (o al final) d’una experiència d’aprenentatge internacional/intercultural.',
    'app.demographic.completing_bevi.response_during_academic': 'Durant l’orientació acadèmica i no en una de les opcions anteriors.',
    'app.demographic.completing_bevi.response_end_graduation': 'Al final de la teva carrera universitària.',
    'app.demographic.completing_bevi.response_other': 'Un altre – Si us plau especifica’l.',
    'app.demographic.primary_area_of_work.response_1': 'Arts, comunicació, humanitats',
    'app.demographic.primary_area_of_work.response_2': 'Antropologia, comportament humà, cultura, societat',
    'app.demographic.primary_area_of_work.response_3': 'Empresarials, administració d’empresa.',
    'app.demographic.primary_area_of_work.response_4': 'Educació',
    'app.demographic.primary_area_of_work.response_5': 'Salut i serveis socials ',
    'app.demographic.primary_area_of_work.response_6': 'Ciència i tecnologia',
    'app.demographic.primary_area_of_work.response_7': 'Un altre',
    'app.demographic.political_orientation.response_1': 'Conservador/tradicionalista (per exemple, partidari de conservar i preservar les lleis, sistemes i maneres de viure)',
    'app.demographic.political_orientation.response_2': 'Més conservador/a que liberal',
    'app.demographic.political_orientation.response_3': 'Moderat/da/neutral / ni conservador/a ni liberal',
    'app.demographic.political_orientation.response_4': 'Més liberal que conservador/a',
    'app.demographic.political_orientation.response_5': 'Liberal/progressista (per exemple, que dona suport al canvi o reforma de lleis, sistemes i maneres de viure)',
    'app.demographic.political_orientation.response_6': 'Altres',
    'app.demographic.religion.response_1': 'Religió tradicional africana',
    'app.demographic.religion.response_2': 'Agnosticisme',
    'app.demographic.religion.response_3': 'Ateisme',
    'app.demographic.religion.response_4': 'Bahá’í',
    'app.demographic.religion.response_5': 'Budisme',
    'app.demographic.religion.response_6': 'Caodaisme',
    'app.demographic.religion.response_7': 'Religió tradicional xinesa',
    'app.demographic.religion.response_8': 'Cristianisme',
    'app.demographic.religion.response_9': 'Hinduisme',
    'app.demographic.religion.response_10': 'Indígenes',
    'app.demographic.religion.response_11': 'Islam',
    'app.demographic.religion.response_12': 'Jainisme',
    'app.demographic.religion.response_13': 'Juche',
    'app.demographic.religion.response_14': 'Judaisme',
    'app.demographic.religion.response_15': 'Paganisme',
    'app.demographic.religion.response_16': 'Xintoisme',
    'app.demographic.religion.response_17': 'Sikhisme',
    'app.demographic.religion.response_18': 'Espiritisme',
    'app.demographic.religion.response_19': 'Espiritual però cap afiliació religiosa específica',
    'app.demographic.religion.response_20': 'Tenrikyo',
    'app.demographic.religion.response_21': 'Unitarisme universalista',
    'app.demographic.religion.response_22': 'Zoroastrisme',
    'app.demographic.religion.response_other': 'Una altra',
    'app.demographic.religion.no_response': 'Prefereixo no respondre a aquesta pregunta.',
    'app.demographic.income_level.response_1': 'Terç més alt',
    'app.demographic.income_level.response_2': 'Terç mitjà',
    'app.demographic.income_level.response_3': 'Terç més baix',
    'app.demographic.academic_rank.response_1': 'Terç més alt',
    'app.demographic.academic_rank.response_2': 'Terç mitjà',
    'app.demographic.academic_rank.response_3': 'Terç més baix',
    'app.demographic.participated_experiences.response_1': 'Seminaris específics per a estudiants de primer any',
    'app.demographic.participated_experiences.response_2': 'Membre d’una comunitat d’aprenentatge organitzada per la universitat',
    'app.demographic.participated_experiences.response_3': 'Curs intensiu d’escriptura',
    'app.demographic.participated_experiences.response_4': 'Projecte d’investigació amb un professor o mentor professional',
    'app.demographic.participated_experiences.response_5': 'Aprenentatge servei o projecte basat en la comunitat patrocinat o reconegut per la universitat',
    'app.demographic.participated_experiences.response_6': 'Pràctiques patrocinades o reconegudes per la universitat',
    'app.demographic.participated_experiences.response_7': 'Treball de fi de grau / projecte de final de carrera',
    'app.demographic.participated_experiences.response_8': 'Conjunt comú o compartit de cursos obligatoris',
    'app.demographic.participated_experiences.response_9': 'Treballs o projectes col·laboratius amb altres',
    'app.demographic.participated_experiences.response_10': 'Cursos de diversitat/aprenentatge global o estudis a l’estranger',
    'app.demographic.participated_experiences.response_11': 'Portafolis digital dels teus treballs o experiències educatives',
    'app.demographic.participated_experiences.response_12': 'Aprenentatge en línia (per exemple, virtual o a través d’internet) que <b>inclou</b> un enfocament internacional, multicultural o intercultural.',
    'app.demographic.participated_experiences.response_13': 'Aprenentatge en línia (per exemple, virtual o a través d’internet) que <b>no inclou</b> un enfocament internacional, multicultural o intercultural.',
    'app.demographic.participated_learning_courses.response_1': 'He fet un curs dedicat a estudis multiculturals o globals',
    'app.demographic.participated_learning_courses.response_2': 'He participat en esdeveniments culturals, internacionals o multiculturals extracurriculars al campus universitari',
    'app.demographic.participated_learning_courses.response_3': 'He fet un programa aprenentatge servei a l’estranger',
    'app.demographic.participated_learning_courses.response_4': 'He fet unes pràctiques a l’estranger',
    'app.demographic.participated_learning_courses.response_5': 'He estudiat a l’estranger',
    'app.demographic.participated_learning_courses.response_6': 'He viscut en una comunitat d’aprenentatge amb un enfocament internacional, multicultural, global o d’aprenentatge d’idiomes',
    'app.demographic.participated_learning_courses.response_7': 'He estudiat una llengua que no és la meva llengua materna/nativa',
    'app.demographic.participated_learning_courses.response_8': 'He viscut amb un/a company/a de pis amb una cultura i tradicions diferents de les meves',
    'app.demographic.participated_learning_courses.response_9': 'He fet o estic fent un intercanvi virtual (VE) o aprenentatge virtual col·laboratiu internacional (COIL); o una altra experiència d’aprenentatge virtual o a través d’internet amb un enfocament internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_experiences.response_1': 'Seminaris específics per a estudiants de primer any',
    'app.demographic.plan_participate_experiences.response_2': 'Membre d’una comunitat d’aprenentatge organitzada per la universitat',
    'app.demographic.plan_participate_experiences.response_3': 'Curs intensiu d’escriptura',
    'app.demographic.plan_participate_experiences.response_4': 'Projecte d’investigació amb un professor o mentor professional',
    'app.demographic.plan_participate_experiences.response_5': 'Aprenentatge servei o projecte basat en la comunitat patrocinat o reconegut per la universitat',
    'app.demographic.plan_participate_experiences.response_6': 'Pràctiques patrocinades o reconegudes per la universitat',
    'app.demographic.plan_participate_experiences.response_7': 'Treball de fi de grau / projecte de final de carrera',
    'app.demographic.plan_participate_experiences.response_8': 'Conjunt comú o compartit de cursos obligatoris',
    'app.demographic.plan_participate_experiences.response_9': 'Treballs o projectes col·laboratius amb altres',
    'app.demographic.plan_participate_experiences.response_10': 'Cursos de diversitat / aprenentatge global o estudis a l’estranger',
    'app.demographic.plan_participate_experiences.response_11': 'Portafolis digital dels teus treballs o experiències educatives',
    'app.demographic.plan_participate_experiences.response_12': 'Aprenentatge en línia (per exemple, virtual o a través d’internet) que <b>inclou</b> un enfocament internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_experiences.response_13': 'Aprenentatge en línia (per exemple, virtual o a través d’internet) que <b>no inclou</b> un enfocament internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_learning_courses.response_1': 'Fer un curs dedicat a estudis multiculturals o globals ',
    'app.demographic.plan_participate_learning_courses.response_2': 'Participar en esdeveniments culturals, internacionals o multiculturals extracurriculars al campus universitari.',
    'app.demographic.plan_participate_learning_courses.response_3': 'Completar el programa d’aprenentatge servei a l’estranger.',
    'app.demographic.plan_participate_learning_courses.response_4': 'Fer pràctiques a l’estranger',
    'app.demographic.plan_participate_learning_courses.response_5': 'Estudiar a l’estranger',
    'app.demographic.plan_participate_learning_courses.response_6': 'Viure en una comunitat d’aprenentatge amb un enfocament internacional, multicultural, global o d’aprenentatge d’idiomes ',
    'app.demographic.plan_participate_learning_courses.response_7': 'Estudiar una llengua que no és la meva llengua materna/nativa',
    'app.demographic.plan_participate_learning_courses.response_8': 'Viure amb un/a company/a de pis amb una cultura i tradicions diferents de les meves',
    'app.demographic.plan_participate_learning_courses.response_9': 'Completar un intercanvi virtual (VE) o aprenentatge virtual col·laboratiu internacional (COIL); o un altra experiència d’aprenentatge virtual o a través d’internet amb un enfocament internacional, multicultural o intercultural.',
    'app.demographic.time_in_other_country.response_not_spent': 'No he passat gaire temps a l’estranger',
    'app.demographic.time_in_other_country.response_less_2_weeks': 'Almenys 1 setmana però menys de 2',
    'app.demographic.time_in_other_country.response_less_1_month': 'Almenys 2 setmanes però menys d’1 mes',
    'app.demographic.time_in_other_country.response_less_3_months': 'Almenys 1 mes però menys de 3',
    'app.demographic.time_in_other_country.response_less_6_months': 'Almenys 3 mesos però menys de 6',
    'app.demographic.time_in_other_country.response_less_1_year': 'Almenys 6 mesos però menys d’1 any',
    'app.demographic.time_in_other_country.response_less_2_years': 'Almenys 1 any però menys de 2',
    'app.demographic.time_in_other_country.response_less_5_years': 'Almenys 2 anys però menys de 5',
    'app.demographic.time_in_other_country.response_less_10_years': 'Almenys 5 anys però menys de 10',
    'app.demographic.time_in_other_country.response_10_or_more': '10 anys o més',
    'app.demographic.country.response_eua': 'Estats Units',
    'app.demographic.country.response_afganistan': 'Afganistan',
    'app.demographic.country.response_albania': 'Albània',
    'app.demographic.country.response_algeria': 'Algèria',
    'app.demographic.country.response_american_samoa': 'Samoa Nord-americana',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua i Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armènia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Austràlia',
    'app.demographic.country.response_austria': 'Àustria',
    'app.demographic.country.response_azerbaijan': 'Azerbaidjan',
    'app.demographic.country.response_bahamas': 'Bahames',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Bielorússia',
    'app.demographic.country.response_belgium': 'Bèlgica',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benín',
    'app.demographic.country.response_bermuda': 'Bermudes',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolívia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bòsnia i Hercegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brasil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgària',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodja',
    'app.demographic.country.response_cameroon': 'Camerun',
    'app.demographic.country.response_canada': 'Canadà',
    'app.demographic.country.response_cape_verde': 'Cap Verd',
    'app.demographic.country.response_cayman_islands': 'Illes Caiman',
    'app.demographic.country.response_central_african_republic': 'República Centreafricana',
    'app.demographic.country.response_chad': 'Txad',
    'app.demographic.country.response_chile': 'Xile',
    'app.demographic.country.response_china': 'Xina',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colòmbia',
    'app.demographic.country.response_comoros': 'Comores',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'República Democràtica del Congo',
    'app.demographic.country.response_cook_islands': 'Illes Cook',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Costa d\'Ivori',
    'app.demographic.country.response_croatia': 'Croàcia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Xipre',
    'app.demographic.country.response_czech_republic': 'República Txeca',
    'app.demographic.country.response_denmark': 'Dinamarca',
    'app.demographic.country.response_djibouti': 'Djibouti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'República Dominicana',
    'app.demographic.country.response_ecuador': 'Equador',
    'app.demographic.country.response_egypt': 'Egipte',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': ' Guinea Equatorial',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estònia',
    'app.demographic.country.response_ethiopia': 'Etiòpia',
    'app.demographic.country.response_euro_area': 'Zona euro',
    'app.demographic.country.response_falkland_islands': 'Illes Malvines',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finlàndia',
    'app.demographic.country.response_france': 'França',
    'app.demographic.country.response_french_guiana': 'Guaiana Francesa',
    'app.demographic.country.response_french_polynesia': 'Polinèsia Francesa',
    'app.demographic.country.response_gabon': 'Gabon',
    'app.demographic.country.response_gambia': 'Gàmbia',
    'app.demographic.country.response_georgia': 'Geòrgia',
    'app.demographic.country.response_germany': 'Alemanya',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Grècia',
    'app.demographic.country.response_grenada': 'Granada',
    'app.demographic.country.response_guadeloupe': 'Guadalupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haití',
    'app.demographic.country.response_honduras': 'Hondures',
    'app.demographic.country.response_hungary': 'Hongria',
    'app.demographic.country.response_iceland': 'Islàndia',
    'app.demographic.country.response_india': 'Índia',
    'app.demographic.country.response_indonesia': 'Indonèsia',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Irlanda',
    'app.demographic.country.response_isle_of_man': 'Illa de Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Itàlia',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': 'Japó',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordània',
    'app.demographic.country.response_kazakhstan': 'Kazakhstan',
    'app.demographic.country.response_kenya': 'Kenya',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kirguizistan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Letònia',
    'app.demographic.country.response_lebanon': 'Líban',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Libèria',
    'app.demographic.country.response_libya': 'Líbia',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Lituània',
    'app.demographic.country.response_luxembourg': 'Luxemburg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Macedònia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malàisia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Maldives',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Illes Mariannes',
    'app.demographic.country.response_marshall_islands': 'Illes Marshall',
    'app.demographic.country.response_martinique': 'Martinica',
    'app.demographic.country.response_mauritania': 'Mauritània',
    'app.demographic.country.response_mauritius': 'Maurici',
    'app.demographic.country.response_mexico': 'Mèxic',
    'app.demographic.country.response_micronesia': 'Micronèsia',
    'app.demographic.country.response_moldova': 'Moldàvia',
    'app.demographic.country.response_monaco': 'Mònaco',
    'app.demographic.country.response_mongolia': 'Mongòlia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Marroc',
    'app.demographic.country.response_mozambique': 'Moçambic',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namíbia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Antilles Neerlandeses',
    'app.demographic.country.response_netherlands': 'Països Baixos',
    'app.demographic.country.response_new_caledonia': 'Nova Caledònia',
    'app.demographic.country.response_new_zealand': 'Nova Zelanda',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Níger',
    'app.demographic.country.response_nigeria': 'Nigèria',
    'app.demographic.country.response_north_korea': 'Corea del Nord',
    'app.demographic.country.response_norway': 'Noruega',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panamà',
    'app.demographic.country.response_papua_new_guinea': 'Papua Nova Guinea',
    'app.demographic.country.response_paraguay': 'Paraguai',
    'app.demographic.country.response_peru': 'Perú',
    'app.demographic.country.response_phillipines': 'Filipines',
    'app.demographic.country.response_poland': 'Polònia',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Illa de la Reunió',
    'app.demographic.country.response_romania': 'Romania',
    'app.demographic.country.response_russian_federation': 'Federació Rússia',
    'app.demographic.country.response_rwanda': 'Ruanda',
    'app.demographic.country.response_st_helena': 'Santa Helena',
    'app.demographic.country.response_st_kitts-nevis': 'Saint Kitts i Nevis',
    'app.demographic.country.response_st_lucia': 'Saint Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'Saint Vincent i les Grenadines',
    'app.demographic.country.response_stateless': 'Sense estat',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'São Tomé i Príncipe',
    'app.demographic.country.response_saudi_arabia': 'Aràbia Saudita',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Sèrbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapur',
    'app.demographic.country.response_slovak_republic': 'República Eslovaca',
    'app.demographic.country.response_slovenia': 'Eslovènia',
    'app.demographic.country.response_solomon_islands': 'Illes Salomó',
    'app.demographic.country.response_somalia': 'Somàlia',
    'app.demographic.country.response_south_africa': 'Sud-àfrica',
    'app.demographic.country.response_south_korea': 'Corea del Sud',
    'app.demographic.country.response_spain': 'Espanya',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Surinam',
    'app.demographic.country.response_swaziland': 'Eswatini',
    'app.demographic.country.response_sweden': 'Suècia',
    'app.demographic.country.response_switzerland': 'Suïssa',
    'app.demographic.country.response_syria': 'Síria',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tadjikistan',
    'app.demographic.country.response_tanzania': 'Tanzània',
    'app.demographic.country.response_thailand': 'Tailàndia',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor Oriental',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinitat i Tobago',
    'app.demographic.country.response_tunisia': 'Tunísia',
    'app.demographic.country.response_turkey': 'Turquia',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Illes Turks i Caicos',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ucraïna',
    'app.demographic.country.response_united_arab_erimates': 'Emirats Àrabs Units',
    'app.demographic.country.response_united_kingdom': 'Regne Unit',
    'app.demographic.country.response_uraguay': 'Uruguai',
    'app.demographic.country.response_uzbekistan': 'Uzbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Veneçuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Illes Verges',
    'app.demographic.country.response_west_bank_and_gaza': 'Cisjordània i la franja de Gaza',
    'app.demographic.country.response_yemen': 'Iemen',
    'app.demographic.country.response_zambia': 'Zàmbia',
    'app.demographic.country.response_zimbabwe': 'Zimbàbue',
    'app.demographic.level_of_fluency.i_do_not_speak': 'No parlo cap altre idioma que no sigui el/s meu/s idioma/es nadiu/s',
    'app.demographic.level_of_fluency.minimally_fluent': 'Mínimament fluid',
    'app.demographic.level_of_fluency.somewhat_fluent': 'Poc fluid',
    'app.demographic.level_of_fluency.moderately_fluent': 'Moderadament fluid',
    'app.demographic.level_of_fluency.mostly_fluent': 'Bastant fluid',
    'app.demographic.level_of_fluency.fluent': 'Fluid',
    'app.demographic.level_of_interest.not_applicable': 'No aplicable',
    'app.demographic.level_of_interest.extremely_low': 'Extremadament baix',
    'app.demographic.level_of_interest.very_low': 'Molt baix',
    'app.demographic.level_of_interest.low': 'Baix',
    'app.demographic.level_of_interest.neutral': 'Neutral',
    'app.demographic.level_of_interest.high': 'Alt',
    'app.demographic.level_of_interest.very_high': 'Molt alt',
    'app.demographic.level_of_interest.extremely_high': 'Extremadament alt',
    'app.demographic_slider_default_option': 'Si us plau, mou el cursor per seleccionar la resposta.',
    'app.demographic.formal_education.some_high_school_or_less': 'Part de la secundària o menys',
    'app.demographic.formal_education.high_school_graduate': 'Estudis secundaris, o grau mitjà de formació professional',
    'app.demographic.formal_education.some_college': 'Grau superior de formació professional, o diversos cursos a la universitat sense acabar amb un títol',
    'app.demographic.formal_education.college_degree': 'Grau universitari',
    'app.demographic.formal_education.some_graduate_school': 'Cursos de postgrau sense acabar amb un títol',
    'app.demographic.formal_education.graduate_degree': ' Màster',
    'app.demographic.formal_education.professional_degree': 'Màster professionalitzador (per exemple, Dret, Educació)',
    'app.demographic.formal_education.doctoral_degree': 'Doctorat',
}

export const demographicQuestionsCA: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }


