import { NoInfoMessages } from '../types';

export const noInfoZHCN: NoInfoMessages = {
    'app.no.info.title.not_found': 'PAGE NOT FOUND',
    'app.no.info.title.no_data': 'NO DATA',
    'app.no.info.subtitle.not_found': '您查找的網頁不存在。',
    'app.no.info.subtitle.no_data': '未搜索到符合條件的數據。',
    'app.no.info.body.not_found': '您要查找的頁面不存在或已被刪除。',
    'app.no.info.body.no_data': '請檢查搜索條件。若仍未搜索到任何數據，請聯繫BEVI管理者。',
    'app.no.info.title.not_authorized': 'PAGE NOT AUTHORIZED',
    'app.no.info.subtitle.not_authorized': '您無權查看此數據。',
    'app.no.info.body.not_authorized': '請諮詢BEVI管理者。',
    'app.no.info.title.maintenance': '維護中',
    'app.no.info.subtitle.maintenance': '系統維護中，暫停服務。',
    'app.no.info.body.maintenance': '請稍後重新連接或稍等片刻。',
}
