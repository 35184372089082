import { useActions, useValues } from 'kea';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import {
    DiscursiveQuestionsActions, discursiveQuestionsLogic, DiscursiveQuestionsProps
} from '../../redux/discursiveQuestionsLogic';
import { formLogic, FormsActions, FormsProps } from '../../redux/formsLogic';
import { DASHBOARD_PAGE, EXTERNAL_DISCURSIVE_QUESTIONS_PAGE, EXTERNAL_INDIVIDUAL_REPORT_PAGE, EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE, INDIVIDUAL_REPORT_PAGE } from '../../router/pages';
import { DiscursiveQuestionAnswers } from '../../services/types';
import { DiscursiveQuestions } from './DiscursiveQuestions';

type Props = RouteComponentProps;

export const DiscursiveQuestionsContainer = withRouter((props: Props): JSX.Element => {
    const { sendDiscursiveAnswers, clearSendQuestion }: DiscursiveQuestionsActions = useActions(discursiveQuestionsLogic);

    const { loading, error, showReport, sendQuestion }: DiscursiveQuestionsProps = useValues(discursiveQuestionsLogic);
    const { setFormStatus, setShowReport }: FormsActions = useActions(formLogic);
    const { formId }: FormsProps = useValues(formLogic);

    const [ answers, setAnswers ] = useState<DiscursiveQuestionAnswers>({ formId: formId });
    const handleAnswerChange = (key: keyof(DiscursiveQuestionAnswers), value?: string) => {
        setAnswers({ ...answers, [key]: value });
    }

    const handleNextButtonClick = (): void => {
        sendDiscursiveAnswers(answers);
    }

    const handleConfirmationCloseClick = (): void => {
        clearSendQuestion();
        const route = props.location.pathname === EXTERNAL_DISCURSIVE_QUESTIONS_PAGE ?
            EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE : DASHBOARD_PAGE;
        props.history.push(route);
    }

    const handleConfirmationOkClick = (): void => {
        setShowReport(showReport);
        clearSendQuestion();

        let route = '';
        if (showReport) {
            setFormStatus(5);
            route = props.location.pathname === EXTERNAL_DISCURSIVE_QUESTIONS_PAGE ?
                EXTERNAL_INDIVIDUAL_REPORT_PAGE : INDIVIDUAL_REPORT_PAGE;
        } else {
            route = props.location.pathname === EXTERNAL_DISCURSIVE_QUESTIONS_PAGE ?
                EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE : DASHBOARD_PAGE;
        }
        props.history.push(route);
    }

    return (
        <React.Fragment>
            <DiscursiveQuestions
                onAnswerChanged={handleAnswerChange}
                onNextButtonClicked={handleNextButtonClick}
                loading={loading}
                error={error}
            />
            
            <ConfirmationModal
                showModal={sendQuestion}
                titleFormattedMessageId={ showReport ? 
                    'app.discursive_questions.thank_you' : 'app.discursive_questions.please_note'}
                bodyFormattedListMessageId={showReport ?
                    ['app.discursive_questions.go_to_report'] : ['app.discursive_questions.do_not_show_report']}
                primaryButtonFormattedMessageId={showReport ? 'app.discursive_questions.visualize_report' :'app.btn_ok'}
                secondaryButtonFormattedMessageId={'app.btn_cancel'}
                onModalHide={handleConfirmationCloseClick}
                onPrimaryButtonClicked={handleConfirmationOkClick}
                onSecondaryButtonClicked={handleConfirmationCloseClick}
            />
        </React.Fragment>
    );
});
