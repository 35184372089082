import React, { useEffect, useState } from 'react';
import { Card, Empty, Timeline } from 'antd';
import styled from 'styled-components';
import { AiChatVirtualAccount, AiChatVirtualAccountDay } from '../../services/virtualAccountService';
import { Loading } from '../../components/Loading';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { DollarOutlined } from '@ant-design/icons';

type Props = {
    loading?: boolean,
    data?: AiChatVirtualAccount
}

export const VirtualAccount = (
    {
        loading, data
    }: Props
) => {
    const [showTimeline, setShowTimeline] = useState(false);

    useEffect(() => {
        if (loading) {
            setShowTimeline(true);
        }
    }, [loading])

    if (loading) {
        return <Loading />
    }

    const verifyTimelineColor = (day: AiChatVirtualAccountDay, index: number) => {
        if (data?.days) {
            if (index > 0) {
                if (data.days[index - 1].finalBalance > day.finalBalance) {
                    return 'red';
                } else if (data.days[index - 1].finalBalance === day.finalBalance) {
                    return 'grey';
                }
            }
        }

        return 'green';
    }

    if (!showTimeline) {
        return <></>;
    }

    if (data == null) {
        return <Empty description={GetFormattedMessage('app.empty.data')} />;
    }

    if (data != null) {
        return <>
            <Balance>
                <BalanceCard>
                    <p>
                        {GetFormattedMessage('app.virtual_account.total_balance_month')}
                        <b>{data!.balance}</b>&nbsp;
                        {GetFormattedMessage('app.virtual_account.interactions_available')}
                    </p>
                </BalanceCard>
            </Balance>
            <TimelineDiv>
                <Timeline
                    mode="alternate"
                    items={
                        [
                            {
                                color: 'green',
                                dot: <DollarOutlined style={{ fontSize: 24 }} />,
                                children: (<></>)
                            },
                            ...data!.days.map((item, index) => ({
                                color: verifyTimelineColor(item, index),
                                children: (<>
                                    <p>
                                        <b>{GetFormattedMessage('app.virtual_account.day')} {item.day}</b>
                                    </p>
                                    <TimelineItems>
                                        <TimelineCard>
                                            <div className='item'>{GetFormattedMessage('app.virtual_account.interaction_credit')}: {item.credits}</div>
                                            <div className='item'>{GetFormattedMessage('app.virtual_account.interaction_debit')}: {item.debits}</div>
                                            <div className='item final'>{GetFormattedMessage('app.virtual_account.balance')}: {item.finalBalance}</div>
                                        </TimelineCard>
                                    </TimelineItems>
                                </>)
                            }))
                        ]
                    }
                />
            </TimelineDiv>
        </>
    } else {
        return <></>;
    }
}

export const Balance = styled.div`
    display: flex;
    justify-content: center;
`

export const BalanceCard = styled(Card)`
  width: max-content;
  color: #5e5e5e;
  font-weight: 500;
`;

export const TimelineDiv = styled.div`
  display: flex;
  justify-content: center;

  .ant-timeline {
    width: 100%;
  }
`;

export const TimelineCard = styled(Card)`
  width: max-content;

  .item {
    font-weight: 500;
    color: #8f8e8e;
  }

  .final {
    padding-top: 12px;
    color: #5e5e5e;
  }
`;

export const TimelineItems = styled.div`
  .ant-timeline-item-right & {
    display: flex;
    justify-content: flex-end;
  }
`;