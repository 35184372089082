import { Alert } from 'antd';
import React, { useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { PageMessages } from '../../locales/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
    requestPassword: (username: string) => void;
    loading: boolean;
    errorKey?: keyof(PageMessages);
};

export const ForgotPassword = ({ requestPassword, loading, errorKey }: Props): JSX.Element => {
    const [username, setUsername] = useState<string>('');

    return (
        <React.Fragment>
            { errorKey &&
                <div style={styles.errorDiv}>
                    <Alert message={<FormattedMessage id={errorKey} />} type='error' showIcon />
                </div>
            }

            <Form.Group className='mb-3' as={Row} controlId='formField1'>
                <FormattedMessage id='app.login.lbl_username' />
                <Form.Control
                    className="form-control mb-3"
                    type='email'
                    placeholder={GetFormattedMessage('app.login.placeholder.login')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                />
            </Form.Group>
            <div className="d-flex justify-content-end mb-4">
                <Button className="btn px-4 shadow-lg btn-primary ml-auto rounded-pill"
                    disabled={loading}
                    style={styles.button}
                    type='submit'
                    onClick={() => { requestPassword(username)} }
                >
                    { loading?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        :
                        <FormattedMessage id='app.login.btn_submit_forgot_password' />
                    }
                </Button>
            </div>
        </React.Fragment>
    );
}

const styles = {
    button: {
        width: 200,
    } as React.CSSProperties,
    errorDiv: {
        width: 400, 
        paddingBottom: 20
    } as React.CSSProperties,
}

