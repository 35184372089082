import { Table, Tag } from 'antd';
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaSearchPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';

import { TableFilterWithInput } from '../../components/TableFilterWithInput';
import { TableFilterWithRadioButton } from '../../components/TableFilterWithRadioButton';
import { colors } from '../../config/colors';
import { paginationDefaultPageSize } from '../../config/constants';
import {
    COMBINED_INDIVIDUAL_REPORT_PAGE,
    COMPARATIVE_BY_GROUP_REPORT_PAGE,
    COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE,
    COMPARATIVE_INDIVIDUAL_REPORT_PAGE,
    GROUP_REPORT_PAGE,
    LONGITUDINAL_COMPARATIVE_INDIVIDUAL_REPORT_PAGE,
    LONGITUDINAL_REPORT_PAGE
} from '../../router/pages';
import { TokenInfo } from '../../services/loginService';
import { ListReportModel, ReportStatus } from '../../services/reportService';
import { GetDate } from '../../utils/dateHelper';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { IsAccountManager, IsInstitutional, IsSuperAdmin } from '../../utils/userHelper';

type Props = {
    userInfo: TokenInfo;
    report: ListReportModel[];
    onEditReportClicked: (reportId: number) => void;
    onDeleteReportClicked: (reportId: number) => void;
}

const buildReportUrl = (reportId: number, reportType: number): string => {
    if (reportType === 1) return GROUP_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 2) return LONGITUDINAL_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 3) return COMPARATIVE_BY_GROUP_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 4) return COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 5) return COMPARATIVE_INDIVIDUAL_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 6) return LONGITUDINAL_COMPARATIVE_INDIVIDUAL_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 7) return COMBINED_INDIVIDUAL_REPORT_PAGE.replace(':id', reportId.toString());
    if (reportType === 8) return COMBINED_INDIVIDUAL_REPORT_PAGE.replace(':id', reportId.toString());
    return GROUP_REPORT_PAGE.replace(':id', reportId.toString());// TODO(rubens.sotero): change to another page.
}

const getReportTagColor = (reportType: number): string => {
    if (reportType === 1) return 'volcano';
    if (reportType === 2) return 'green';
    if (reportType === 3) return 'blue';
    if (reportType === 4) return 'purple';
    if (reportType === 5) return 'red';
    if (reportType === 6) return 'cadetblue';
    if (reportType === 7) return 'pink';
    if (reportType === 8) return 'rebeccapurple';
    return 'black';
}

const getReportStatusTagColor = (reportStatus: ReportStatus): string => {
    if (reportStatus === ReportStatus.Completed) return 'green';
    if (reportStatus === ReportStatus.PendingParticipantApproval) return 'gray';
    return 'black';
}

export const ListReport = ({ userInfo, report, onEditReportClicked, onDeleteReportClicked }: Props) => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);
    
    // const editMessage = GetFormattedMessage('app.listorganizations.edit');
    const viewMessage = GetFormattedMessage('app.listorganizations.view');
    const deleteMessage = GetFormattedMessage('app.listorganizations.delete');
    const groupReportTitle = GetFormattedMessage('app.create_report.group_report');
    const longitudinalReportTile = GetFormattedMessage('app.create_report.longitudinal_report');
    const comparativeByGroupReportTile = GetFormattedMessage('app.create_report.comparative_by_group_report');
    const comparativeByIndividualReportTile = GetFormattedMessage('app.reports.comparative_by_individual.title');
    const individualReportTile = GetFormattedMessage('app.create_report.individual_report');
    const longitudinalIndividualReportTile = GetFormattedMessage('app.create_report.longitudinal_individual_report');
    const combinedIndividualReportTile = GetFormattedMessage('app.create_report.combined_report');
    const partnerChangeReportTile = GetFormattedMessage('app.create_report.partner_change_report');

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder');
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search');
    const searchResetButtonText = GetFormattedMessage('app.btn_reset');
    const filterOptions = {
        filterDropdown: (props: FilterDropdownProps) => (
            <TableFilterWithInput
                inputPlaceholder={searchInputPlaceholder}
                confirmButtonText={searchConfirmButtonText}
                resetButtonText={searchResetButtonText}
                {...props}
            />
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
    }

    const getTitle = (reportType: number): string => {
        if (reportType === 1) return groupReportTitle;
        if (reportType === 2) return longitudinalReportTile;
        if (reportType === 3) return comparativeByGroupReportTile;
        if (reportType === 4) return comparativeByIndividualReportTile;
        if (reportType === 5) return individualReportTile;
        if (reportType === 6) return longitudinalIndividualReportTile;
        if (reportType === 7) return combinedIndividualReportTile;
        if (reportType === 8) return partnerChangeReportTile;
        return '';
    }

    const getReportStatusTitle = (reportStatus: ReportStatus): string => {
        if (reportStatus === ReportStatus.Completed) return GetFormattedMessage('app.listreport.report_status_completed');
        if (reportStatus === ReportStatus.PendingParticipantApproval) return GetFormattedMessage('app.listreport.report_status_pending_approval');
        return '';
    }

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const columns: ColumnType<ListReportModel>[] = [
        {
            title: GetFormattedMessage('app.listreport.name'),
            dataIndex: 'name',
            key: 'name',
            width: '35%',
            sorter: (a: ListReportModel, b: ListReportModel) => a.name.localeCompare(b.name),
            ...filterOptions,
            onFilter: (value, record: ListReportModel) =>
                record.name.toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: GetFormattedMessage('app.listreport.created_on'),
            dataIndex: 'createdTimeOnUtc',
            key: 'CreatedTimeOnUtc',
            width: '20%',
            sorter: (a: ListReportModel, b: ListReportModel) => (
                a.createdTimeOnUtc.localeCompare(b.createdTimeOnUtc)
            ),
            render: (_: string, record: ListReportModel) => (
                <span key={'CreateDate' + record.id}>
                    {GetDate(record.createdTimeOnUtc)}
                </span>
            )
        },
        {
            title: GetFormattedMessage('app.listreport.report_type'),
            dataIndex: 'reportType',
            key: 'reportType',
            width: '20%',
            sorter: (a: ListReportModel, b: ListReportModel) => a.reportType - b.reportType,
            render: (_: number, record: ListReportModel) => (
                <span>
                    <Tag color={getReportTagColor(record.reportType)} key={'TAG Report Type' + record.id}>
                        {getTitle(record.reportType)}
                    </Tag>
                </span>
            ),
            filters: [
                { text: getTitle(1), value: '1' },
                { text: getTitle(2), value: '2' },
                { text: getTitle(3), value: '3' },
                { text: getTitle(4), value: '4' },
                { text: getTitle(5), value: '5' },
                { text: getTitle(6), value: '6' },
                { text: getTitle(7), value: '7' },
            ],
            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithRadioButton
                    {...props}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                />
            ),
            onFilter: (value, record: ListReportModel) => value === record.reportType.toString(),
        },

        {
            title: GetFormattedMessage('app.listreport.report_status'),
            dataIndex: 'reportStatus',
            key: 'reportStatus',
            width: '15%',
            sorter: (a: ListReportModel, b: ListReportModel) => a.reportStatus - b.reportStatus,
            render: (_: number, record: ListReportModel) => (
                <span>
                    <Tag color={getReportStatusTagColor(record.reportStatus)} key={'TAG Report Status' + record.id}>
                        {getReportStatusTitle(record.reportStatus)}
                    </Tag>
                </span>
            ),
            filters: [
                { text: getReportStatusTitle(1), value: '1' },
                { text: getReportStatusTitle(2), value: '2' },
            ],

            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithRadioButton
                    {...props}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                />
            ),
            onFilter: (value, record: ListReportModel) => value === record.reportStatus.toString(),
        },

        {
            title: GetFormattedMessage('app.listorganizations.table.actions'),
            key: 'action',
            align: 'center',
            width: '15%',
            render: (_: string, record: ListReportModel) => (
                <div style={styles.alignCenter} key={'BTN' + record.id}>
                    {/* TODO: DISABLE THE EDIT FUNCTION UNTIL IT'S DONE. */}
                    {/* <OverlayTrigger
                        key={`listReportEdit-${record.id}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip id={`subprogramEditTooltip`} key={`listReportEditTooltip-${record.id}`}>
                                {editMessage}
                            </Tooltip>
                        }
                    >
                        <FaEdit
                            style={styles.actionIcon}
                            size={20}
                            onClick={() => onEditReportClicked(record.id)}
                        />
                    </OverlayTrigger> */}
                    {record.reportStatus === ReportStatus.Completed && <OverlayTrigger
                        key={`listReportDetails-${record.id}`}
                        placement={'auto'}
                        trigger={["hover", "hover"]}
                        overlay={
                            <Tooltip id={`subprogramDetails`} key={`listReportDetailsTooltip-${record.id}`}>
                                {viewMessage}
                            </Tooltip>
                        }
                    >
                        <Link
                            to={buildReportUrl(record.id, record.reportType)}
                            target='_black'
                            style={{ ...styles.link, ...styles.actionIcon }}
                            key={Math.ceil(Math.random() * 100)}
                        >
                            <FaSearchPlus size={20} />
                        </Link>
                    </OverlayTrigger>}
                    {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo) || IsInstitutional(userInfo)) && record.canDelete && (
                        <OverlayTrigger
                            key={`listReportRemove-${record.id}`}
                            placement={'auto'}
                            trigger={["hover", "hover"]}
                            overlay={
                                <Tooltip id={`subprogramDetails`} key={`listReportRemoveTooltip-${record.id}`}>
                                    {deleteMessage}
                                </Tooltip>
                            }
                        >
                            <AiOutlineDelete
                                style={styles.actionIcon}
                                color="red"
                                size={22}
                                onClick={() => onDeleteReportClicked(record.id)}
                            />
                        </OverlayTrigger>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <Table
                    columns={columns}
                    dataSource={report}
                    pagination={report.length > paginationDefaultPageSize ? defaultPagination : false}
                    rowKey={(record: ListReportModel) => record.id.toString()}
                />
            </div>
        </div>
    )
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    pageHeader: {
        border: '1px solid ' + colors.gray
    } as React.CSSProperties,
    alignCenter: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-around',
        textAlign: 'center',
    } as React.CSSProperties,
    actionIcon: {
        cursor: 'pointer',
        marginRight: '5'
    } as React.CSSProperties,
    link: {
        color: colors.darkGray,
    } as React.CSSProperties,
};
