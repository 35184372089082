import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    SaveDemographicAnswersResponse, saveDemographicQuestions, loadDemographicsExtraQuestions, addExtraQuestions, editExtraQuestions, deleteExtraQuestions
} from '../services/questionsService';
import { AddExtraQuestionModel, DeleteExtraQuestionModel, DemographicQuestionAnswers, EditExtraQuestionModel, ExtraQuestionModel } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { formLogic, formsActionsConstants } from './formsLogic';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';
import { ValidateExternalToken, ValidateToken } from '../utils/validateHelper';
import {
    loadDemographicsQuestionsConfiguration, saveDemographicsQuestionsConfiguration, DemographicsQuestionsConfigurationModel,
    SaveDemographicsQuestionsConfigurationResponse
} from '../services/organizationService';

// actions
const actionsLiterals = asLiterals([
    'sendDemographicAnswers',
    'loadDemographicsExtraQuestions',
    'loadDemographicsQuestionsConfiguration',
    'clearDemographicsQuestionsConfiguration',
    'clearDemographicsExtraQuestions',
    'saveDemographicsQuestionsConfiguration',
    'clearSuccessfulSaveDemographicsQuestionsConfiguration',
    'saveLoadingExtraQuestion',
    'addExtraQuestion',
    'editExtraQuestion',
    'deleteExtraQuestion',
    'clearExtraQuestions'
]);

export type DemographicQuestionsActions = { [K in (typeof actionsLiterals)[number]]: any };
export const demographicActionsConstants: DemographicQuestionsActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError',
    'successfulSend',
    'setDemographicsExtraQuestions',
    'setDemographicsExtraQuestionsError',
    'setDemographicsQuestionsConfiguration',
    'setDemographicsQuestionsConfigurationError',
    'successfulSaveDemographicsQuestionsConfiguration',
    'successfulSaveExtraQuestions',
    'successfulDeleteExtraQuestions',
    'setErrorSaveDemographicsQuestionsConfiguration',
    'setErrorExtraQuestions'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading',
    'error',
    'demographicsQuestionsConfiguration',
    'demographicsExtraQuestions',
    'loadingDemographicsExtraQuestions',
    'loadingDemographicsQuestionsConfiguration',
    'errorDemographicsQuestionsConfiguration',
    'errorDemographicsExtraQuestions',
    'hasSavedDemographicsQuestionsConfiguration',
    'errorMessageSaveDemographicsQuestionsConfiguration',
    'loadingSaveDemographicsQuestionsConfiguration',
    'loadingExtraQuestions',
    'errorSaveExtraQuestions',
    'errorDeleteExtraQuestions',
    'hasSavedExtraQuestions',
    'hasDeletedExtraQuestions',
]);

export type DemographicQuestionsProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const demographicQuestionsPropsConstants: DemographicQuestionsProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: DemographicQuestionsActions & PrivateActions; };

type WorkersParam = {
    workers: {
        saveDemographicAnswers: () => void,
        fetchDemographicsQuestionsConfigurationRequest: () => void,
        saveDemographicsQuestionsConfiguration: () => void,
        fetchDemographicsExtraQuestionsRequest: () => void,
        addExtraQuestionRequest: () => void,
        editExtraQuestionRequest: () => void,
        deleteExtraQuestionRequest: () => void,
    }
};

// Hide questions type definitions.
type SendAnswersRequest = { payload: AnswersPayload };
type SaveDemographicAnswersRaceResponse = {
    saveAnswersResponse?: SaveDemographicAnswersResponse;
}

type SaveDemographicsQuestionsConfigurationRaceResponse = {
    saveDemographicsQuestionsConfigurationResponse?: SaveDemographicsQuestionsConfigurationResponse
}

type AddExtraQuestionsPayload = { question: AddExtraQuestionModel };
type AddExtraQuestionsRequest = { payload: AddExtraQuestionsPayload };
type EditExtraQuestionsPayload = { question: EditExtraQuestionModel };
type EditExtraQuestionsRequest = { payload: EditExtraQuestionsPayload };
type DeleteExtraQuestionsPayload = { question: DeleteExtraQuestionModel };
type DeleteExtraQuestionsRequest = { payload: DeleteExtraQuestionsPayload };
type DeleteExtraQuestionsSuccessPayload = { success: boolean };
type SaveExtraQuestionsSuccessPayload = { success: boolean };


// Define payloads type.
type ErrorPayload = { error: string; };
type AnswersPayload = { answers: DemographicQuestionAnswers };

type LoadDemographicsQuestionsConfigurationPayloadRequest = { organizationId: number };
type LoadDemographicsQuestionsConfigurationRequest = { payload: LoadDemographicsQuestionsConfigurationPayloadRequest };
type DemographicsQuestionsConfigurationPayloadRequest = { demographicsQuestionsConfiguration: DemographicsQuestionsConfigurationModel };

type SaveDemographicsQuestionsConfigurationPayload = { demographicsQuestionsConfiguration: DemographicsQuestionsConfigurationModel };
type SaveDemographicsQuestionsConfigurationRequest = { payload: SaveDemographicsQuestionsConfigurationPayload };
type StatusSaveDemographicsQuestionsConfigurationPayload = { success: boolean };

type LoadDemographicsExtraQuestionsPayloadRequest = { organizationId: number };
type LoadDemographicsExtraQuestionsRequest = { payload: LoadDemographicsExtraQuestionsPayloadRequest };
type DemographicsExtraQuestionsPayloadRequest = { demographicsExtraQuestions: ExtraQuestionModel[] };

// persist app reducer
export const demographicQuestionsPath = 'demographicQuestions';

export const demographicQuestionsReducer = keaReducer(demographicQuestionsPath);

export const demographicQuestionsLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
        actions: [formLogic, [formsActionsConstants.updateFormPercentage]],
    },

    path: () => [demographicQuestionsPath],

    actions: () => ({
        [demographicActionsConstants.sendDemographicAnswers]:
            (answers: DemographicQuestionAnswers): AnswersPayload => ({ answers }),
        [privateActionsConstants.successfulSend]: true,
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),

        // Get Demographics Questions Configuration
        [demographicActionsConstants.loadDemographicsQuestionsConfiguration]:
            (organizationId: number): LoadDemographicsQuestionsConfigurationPayloadRequest => ({ organizationId }),
        [demographicActionsConstants.clearDemographicsQuestionsConfiguration]: true,
        [privateActionsConstants.setDemographicsQuestionsConfiguration]:
            (demographicsQuestionsConfiguration: DemographicsQuestionsConfigurationModel): DemographicsQuestionsConfigurationPayloadRequest => ({ demographicsQuestionsConfiguration }),
        [privateActionsConstants.setDemographicsQuestionsConfigurationError]:
            (error: string): ErrorPayload => ({ error }),

        // Save Demographics Questions Configuration
        [demographicActionsConstants.saveDemographicsQuestionsConfiguration]: (demographicsQuestionsConfiguration: DemographicsQuestionsConfigurationModel): SaveDemographicsQuestionsConfigurationPayload => ({ demographicsQuestionsConfiguration }),
        [privateActionsConstants.setErrorSaveDemographicsQuestionsConfiguration]: (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.successfulSaveDemographicsQuestionsConfiguration]: (): StatusSaveDemographicsQuestionsConfigurationPayload => ({ success: true }),
        [demographicActionsConstants.clearSuccessfulSaveDemographicsQuestionsConfiguration]: true,

        // Get Demographics Extra Questions
        [demographicActionsConstants.loadDemographicsExtraQuestions]:
            (organizationId: number): LoadDemographicsExtraQuestionsPayloadRequest => ({ organizationId }),
        [demographicActionsConstants.clearDemographicsExtraQuestions]: true,
        [privateActionsConstants.setDemographicsExtraQuestions]:
            (demographicsExtraQuestions: ExtraQuestionModel[]): DemographicsExtraQuestionsPayloadRequest => ({ demographicsExtraQuestions }),
        [privateActionsConstants.setDemographicsExtraQuestionsError]:
            (error: string): ErrorPayload => ({ error }),

        // Extra Question
        [demographicActionsConstants.editExtraQuestion]: (question: EditExtraQuestionModel): EditExtraQuestionsPayload => ({ question }),
        [demographicActionsConstants.addExtraQuestion]: (question: AddExtraQuestionModel): AddExtraQuestionsPayload => ({ question }),
        [demographicActionsConstants.deleteExtraQuestion]: (question: DeleteExtraQuestionModel): DeleteExtraQuestionsPayload => ({ question }),
        [demographicActionsConstants.clearExtraQuestions]: true,
        [privateActionsConstants.setErrorExtraQuestions]: (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.successfulSaveExtraQuestions]: (): SaveExtraQuestionsSuccessPayload => ({ success: true }),
        [privateActionsConstants.successfulDeleteExtraQuestions]: (): DeleteExtraQuestionsSuccessPayload => ({ success: true }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [demographicQuestionsPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.sendDemographicAnswers]: () => true,
            [actions.setError]: () => false,
            [actions.successfulSend]: () => false,
            [logout]: () => false
        }],
        [demographicQuestionsPropsConstants.error]: [null, PropTypes.string, {
            [actions.sendDemographicAnswers]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],

        // Demographics Questions Configuration
        [demographicQuestionsPropsConstants.loadingDemographicsQuestionsConfiguration]: [false, PropTypes.bool, {
            [actions.loadDemographicsQuestionsConfiguration]: () => true,
            [actions.setDemographicsQuestionsConfiguration]: () => false,
            [actions.setDemographicsQuestionsConfigurationError]: () => false,
            [actions.clearDemographicsQuestionsConfiguration]: () => false,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.errorDemographicsQuestionsConfiguration]: [null, PropTypes.string, {
            [actions.setDemographicsQuestionsConfigurationError]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.loadDemographicsQuestionsConfiguration]: () => null,
            [actions.clearDemographicsQuestionsConfiguration]: () => null,
            [logout]: () => null,
        }],
        [demographicQuestionsPropsConstants.demographicsQuestionsConfiguration]: [null, PropTypes.any, {
            [actions.setDemographicsQuestionsConfiguration]: (_: string, payload: DemographicsQuestionsConfigurationPayloadRequest) => payload.demographicsQuestionsConfiguration,
            [actions.clearDemographicsQuestionsConfiguration]: () => null,
            [logout]: () => null,
        }],

        // Demographics Extra Questions
        [demographicQuestionsPropsConstants.loadingDemographicsExtraQuestions]: [false, PropTypes.bool, {
            [actions.loadDemographicsExtraQuestions]: () => true,
            [actions.setDemographicsExtraQuestions]: () => false,
            [actions.setDemographicsExtraQuestionsError]: () => false,
            [actions.clearDemographicsExtraQuestions]: () => false,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.errorDemographicsExtraQuestions]: [null, PropTypes.string, {
            [actions.setDemographicsExtraQuestionsError]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.loadDemographicsExtraQuestions]: () => null,
            [actions.clearDemographicsExtraQuestions]: () => null,
            [logout]: () => null,
        }],
        [demographicQuestionsPropsConstants.demographicsExtraQuestions]: [null, PropTypes.any, {
            [actions.setDemographicsExtraQuestions]: (_: string, payload: DemographicsExtraQuestionsPayloadRequest) => payload.demographicsExtraQuestions,
            [actions.clearDemographicsExtraQuestions]: () => null,
            [logout]: () => null,
        }],

        // Save Demographics Questions Configuration
        [demographicQuestionsPropsConstants.errorMessageSaveDemographicsQuestionsConfiguration]: [null, PropTypes.string, {
            [actions.setErrorSaveDemographicsQuestionsConfiguration]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.clearSuccessfulSaveDemographicsQuestionsConfiguration]: () => null,
            [actions.saveDemographicsQuestionsConfiguration]: () => null,
            [actions.successfulSaveDemographicsQuestionsConfiguration]: () => null,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.hasSavedDemographicsQuestionsConfiguration]: [false, PropTypes.bool, {
            [actions.successfulSaveDemographicsQuestionsConfiguration]: () => true,
            [actions.clearSuccessfulSaveDemographicsQuestionsConfiguration]: () => false,
            [actions.setErrorSaveDemographicsQuestionsConfiguration]: () => false,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.loadingSaveDemographicsQuestionsConfiguration]: [false, PropTypes.bool, {
            [actions.saveDemographicsQuestionsConfiguration]: () => true,
            [actions.clearSuccessfulSaveDemographicsQuestionsConfiguration]: () => false,
            [actions.successfulSaveDemographicsQuestionsConfiguration]: () => false,
            [actions.setErrorSaveDemographicsQuestionsConfiguration]: () => false,
            [logout]: () => false,
        }],

        // Extra Questions
        [demographicQuestionsPropsConstants.errorSaveExtraQuestions]: [null, PropTypes.string, {
            [actions.setErrorExtraQuestions]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.clearExtraQuestions]: () => null,
            [actions.addExtraQuestion]: () => null,
            [actions.editExtraQuestion]: () => null,
            [actions.successfulSaveExtraQuestions]: () => null,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.errorDeleteExtraQuestions]: [null, PropTypes.string, {
            [actions.setErrorExtraQuestions]: (_: string, payload: ErrorPayload) => payload.error,
            [actions.clearExtraQuestions]: () => null,
            [actions.deleteExtraQuestion]: () => null,
            [actions.successfulDeleteExtraQuestions]: () => null,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.hasSavedExtraQuestions]: [false, PropTypes.bool, {
            [actions.successfulSaveExtraQuestions]: () => true,
            [actions.clearExtraQuestions]: () => false,
            [actions.setErrorExtraQuestions]: () => false,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.hasDeletedExtraQuestions]: [false, PropTypes.bool, {
            [actions.successfulDeleteExtraQuestions]: () => true,
            [actions.clearExtraQuestions]: () => false,
            [actions.setErrorExtraQuestions]: () => false,
            [logout]: () => false,
        }],
        [demographicQuestionsPropsConstants.loadingExtraQuestions]: [false, PropTypes.bool, {
            [actions.addExtraQuestion]: () => true,
            [actions.editExtraQuestion]: () => true,
            [actions.clearExtraQuestions]: () => false,
            [actions.successfulSaveExtraQuestions]: () => false,
            [actions.setErrorExtraQuestions]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.sendDemographicAnswers]: workers.saveDemographicAnswers,
        [actions.loadDemographicsQuestionsConfiguration]: workers.fetchDemographicsQuestionsConfigurationRequest,
        [actions.saveDemographicsQuestionsConfiguration]: workers.saveDemographicsQuestionsConfiguration,
        [actions.loadDemographicsExtraQuestions]: workers.fetchDemographicsExtraQuestionsRequest,
        [actions.addExtraQuestion]: workers.addExtraQuestionRequest,
        [actions.editExtraQuestion]: workers.editExtraQuestionRequest,
        [actions.deleteExtraQuestion]: workers.deleteExtraQuestionRequest,
    }),

    workers: {
        * saveDemographicAnswers(action: SendAnswersRequest): any {
            const { answers } = action.payload;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const { setError, successfulSend, updateFormPercentage } = this.actionCreators;

            const { saveAnswersResponse }: SaveDemographicAnswersRaceResponse = yield race({
                saveAnswersResponse: call(saveDemographicQuestions, token, answers),
                timeout: delay(requestTimeout)
            });

            if (saveAnswersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (saveAnswersResponse?.status === successfulCode) {
                if (saveAnswersResponse?.body) {
                    yield put(updateFormPercentage(saveAnswersResponse.body));
                }
                yield put(successfulSend())
            } else if (saveAnswersResponse?.keyErrorMessage) {
                yield put(setError(saveAnswersResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * saveDemographicsQuestionsConfiguration(action: SaveDemographicsQuestionsConfigurationRequest): any {
            //@ts-ignore
            const { successfulSaveDemographicsQuestionsConfiguration, setErrorSaveDemographicsQuestionsConfiguration } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { demographicsQuestionsConfiguration } = action.payload;
            const { saveDemographicsQuestionsConfigurationResponse }: SaveDemographicsQuestionsConfigurationRaceResponse = yield race({
                saveDemographicsQuestionsConfigurationResponse: call(saveDemographicsQuestionsConfiguration, token, demographicsQuestionsConfiguration),
                timeout: delay(requestTimeout)
            });

            if (saveDemographicsQuestionsConfigurationResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (saveDemographicsQuestionsConfigurationResponse?.status === successfulCode) {
                yield put(successfulSaveDemographicsQuestionsConfiguration(saveDemographicsQuestionsConfigurationResponse?.body));
            } else if (saveDemographicsQuestionsConfigurationResponse?.keyErrorMessage) {
                yield put(setErrorSaveDemographicsQuestionsConfiguration(saveDemographicsQuestionsConfigurationResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchDemographicsQuestionsConfigurationRequest(action: LoadDemographicsQuestionsConfigurationRequest): any {

            //@ts-ignore
            const { setDemographicsQuestionsConfiguration, setDemographicsQuestionsConfigurationError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId } = action.payload;

            if (organizationId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const demographicsQuestionsConfigurationResponse = yield call(loadDemographicsQuestionsConfiguration, token, organizationId);

            if (demographicsQuestionsConfigurationResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (demographicsQuestionsConfigurationResponse?.status === successfulCode) {
                if (demographicsQuestionsConfigurationResponse?.body) {
                    yield put(setDemographicsQuestionsConfiguration(demographicsQuestionsConfigurationResponse?.body?.hideQuestions));
                } else {
                    yield put(setDemographicsQuestionsConfigurationError(generalExceptionKey));
                }
            } else if (demographicsQuestionsConfigurationResponse?.keyErrorMessage) {
                yield put(setDemographicsQuestionsConfigurationError(demographicsQuestionsConfigurationResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchDemographicsExtraQuestionsRequest(action: LoadDemographicsExtraQuestionsRequest): any {

            //@ts-ignore
            const { setDemographicsExtraQuestions, setDemographicsExtraQuestionsError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId } = action.payload;

            if (organizationId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const demographicsExtraQuestionsResponse = yield call(loadDemographicsExtraQuestions, token, organizationId);

            if (demographicsExtraQuestionsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (demographicsExtraQuestionsResponse?.status === successfulCode) {
                if (demographicsExtraQuestionsResponse?.body) {
                    yield put(setDemographicsExtraQuestions(demographicsExtraQuestionsResponse?.body));
                } else {
                    yield put(setDemographicsExtraQuestionsError(generalExceptionKey));
                }
            } else if (demographicsExtraQuestionsResponse?.keyErrorMessage) {
                yield put(setDemographicsExtraQuestionsError(demographicsExtraQuestionsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * addExtraQuestionRequest(action: AddExtraQuestionsRequest): any {

            //@ts-ignore
            const { successfulSaveExtraQuestions, setErrorExtraQuestions, clearExtraQuestions } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            const { question } = action.payload;

            if (question === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const extraQuestionResponse = yield call(addExtraQuestions, token, question);

            if (extraQuestionResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (extraQuestionResponse?.status === successfulCode) {
                yield put(successfulSaveExtraQuestions());
                yield delay(1);
                yield put(clearExtraQuestions());
            } else if (extraQuestionResponse?.keyErrorMessage) {
                yield put(setErrorExtraQuestions(extraQuestionResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * editExtraQuestionRequest(action: EditExtraQuestionsRequest): any {

            //@ts-ignore
            const { successfulSaveExtraQuestions, setErrorExtraQuestions, clearExtraQuestions } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            const { question } = action.payload;

            if (question === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const extraQuestionResponse = yield call(editExtraQuestions, token, question);

            if (extraQuestionResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (extraQuestionResponse?.status === successfulCode) {
                yield put(successfulSaveExtraQuestions());
                yield delay(1);
                yield put(clearExtraQuestions());
            } else if (extraQuestionResponse?.keyErrorMessage) {
                yield put(setErrorExtraQuestions(extraQuestionResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * deleteExtraQuestionRequest(action: DeleteExtraQuestionsRequest): any {

            //@ts-ignore
            const { successfulDeleteExtraQuestions, setErrorExtraQuestions, clearExtraQuestions } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }

            const { question } = action.payload;

            if (question === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const extraQuestionResponse = yield call(deleteExtraQuestions, token, question);

            if (extraQuestionResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (extraQuestionResponse?.status === successfulCode) {
                yield put(successfulDeleteExtraQuestions());
                yield delay(1);
                yield put(clearExtraQuestions());
            } else if (extraQuestionResponse?.keyErrorMessage) {
                yield put(setErrorExtraQuestions(extraQuestionResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
});
