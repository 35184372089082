import { RadarControllerChartOptions } from 'chart.js';
import React from 'react';
import { Radar } from 'react-chartjs-2';
import { BackgroundStatistic } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';


type Props = {
  title?: string;
  domainScores: BackgroundStatistic;
}

enum DomainScoreGroup {
	FulfillmentOfCoreNeeds = 0,
	ToleranceOfDisequilibrium = 1,
	CriticalThinking = 2,
	SelfAccess = 3,
	OtherAccess = 4,
	GlobalAccess = 5,
}

type DomainScoresSummary = {
    label: string;
    group: DomainScoreGroup;
}

const options: RadarControllerChartOptions = {
    showLine: true,
    spanGaps: false,
};

export const DomainScores = ({ domainScores, title }: Props): JSX.Element => {
	const domainScoresSummaries: DomainScoresSummary[] = [
        { label: GetFormattedMessage('app.report.background_statistic_item.fulfillment_of_core_needs'), group: DomainScoreGroup.FulfillmentOfCoreNeeds },
        { label: GetFormattedMessage('app.report.background_statistic_item.tolerance_of_disequilibrium'), group: DomainScoreGroup.ToleranceOfDisequilibrium },
		{ label: GetFormattedMessage('app.report.background_statistic_item.critical_thinking'), group: DomainScoreGroup.CriticalThinking },
		{ label: GetFormattedMessage('app.report.background_statistic_item.self_access'), group: DomainScoreGroup.SelfAccess },
		{ label: GetFormattedMessage('app.report.background_statistic_item.other_access'), group: DomainScoreGroup.OtherAccess },
		{ label: GetFormattedMessage('app.report.background_statistic_item.global_access'), group: DomainScoreGroup.GlobalAccess },
    ];

	const data = {
        labels: domainScoresSummaries.map(summary => summary.label),
        datasets: [
            {
                label: title,
                data: [
					domainScores.fulfillmentOfCoreNeeds,
					domainScores.toleranceOfDisequilibrium,
					domainScores.criticalThinking,
					domainScores.selfAccess,
					domainScores.otherAccess,
					domainScores.globalAccess,
				],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
        options: {
            scale: {
                min: 0,
                max: 100,
            },
        },    
    };

	return (
		<React.Fragment>
			<div className="profile-chart-container pt-4">
                <div className="profile-radar-chart-container">
                    <Radar data={data} options={options} redraw={false} />
                </div>
            </div>
		</React.Fragment>
	)
}
