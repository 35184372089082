import { ProgramsMessages } from '../types';

export const programsES: ProgramsMessages = {
  'app.programs.add.title': 'Nuevo Programa',
  'app.programs.rename': 'Renombrar program',
  'app.programs.edit.title': 'Editar Programa',
  'app.programs.add.successful_message': 'Programa ha sido actualizado con éxito.',
  'app.programs.add.error_message': 'Algo ha ido mal, por favor, vuelve a intentarlo en unos minutos.',
  'app.programs.add.new_name': 'Nuevo nombre',
  'app.programs.add.button_save': 'Guardar',
  'app.programs.chat.successful_message': 'En unos minutos, todos los usuarios podrán utilizar el chat AI.',
  'app.programs.chat.add_chat_all': 'Agregar chat para todos los usuarios',
  'app.programs.chat.question_add_chat_all': '¿Estás seguro de que quieres incluir chat para todos los usuarios en este programa?'
}