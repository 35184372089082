import { VirtualAccountMessages } from '../types';

export const virtualAccountCA: VirtualAccountMessages = {
  'app.virtual_account.title': 'Compte virtual per institució',
  'app.virtual_account.add_interactions': 'Afegir Interaccions',
  'app.virtual_account.add_interactions_to_organization': "Afegiu interaccions per a l'organització:",
  'app.virtual_account.interactions_placeholder': "Tipus de nombre d'iteracions",
  'app.virtual_account.successful_message': "El compte virtual s'ha actualitzat correctament.",
  'app.virtual_account.day': 'Dia',
  'app.virtual_account.interaction_credit': "Crèdits d'interacció",
  'app.virtual_account.interaction_debit': "Dèbits d'interacció",
  'app.virtual_account.balance': 'Balanç',
  'app.virtual_account.total_balance_month': 'El saldo total del mes és ',
  'app.virtual_account.interactions_available': 'interaccions disponibles'
}