import { Table } from 'antd';
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Accordion, Card, Col, Form, FormCheck, Row, useAccordionButton } from 'react-bootstrap';

import { SearchOutlined } from '@ant-design/icons';

import { SelectItem } from '../../../components/FormDropdown';
import { TableFilterWithInput } from '../../../components/TableFilterWithInput';
import { paginationDefaultPageSize } from '../../../config/constants';
import { ParticipantModel } from '../../../services/reportService';
import { GetCountriesOptions, GetEthnicOrientationOptions, GetMaritialStatusOptions, GetPrimaryWorkAreaOptions, GetReligiousOrientationOptionsForReports } from '../../../utils/demographHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { SanitizeDoubleByteNumber } from '../../../utils/stringHelper';
import { LineEditTextGroup } from './LineEditTextGroup';
import { SelectOptionGroup } from './SelectOptionGroup';
import { UserFormForView, UserFormsPerOrganizationForView } from './Step4Helper';

type Props = {
    userForms: UserFormsPerOrganizationForView[];
    maxNumberOfForms?: number;
    selectedCountries?: number[];
    selectedEthnics?: number[];
    selectedRelationships?: number[];
    selectedReligions?: number[];
    selectedPrimaryAreaOfWorkCodes?: number[];
    onSelectCountriesChanged: (values: number[] | undefined) => void;
    onSelectEthnicsChanged: (values: number[] | undefined) => void;
    onSelectRelationshipsChanged: (values: number[] | undefined) => void;
    onSelectReligionsChanged: (values: number[] | undefined) => void;
    onSelectPrimaryAreaOfWorkCodesChanged: (values: number[] | undefined) => void;
    onMaxNumberFormsChanged: (value: number | undefined) => void;
    onUserFormReportChanged: (participants: ParticipantModel[], changeAlias: boolean) => void;
};

export const Step4 = (
    {
        selectedCountries, selectedEthnics, selectedRelationships, selectedReligions, selectedPrimaryAreaOfWorkCodes, maxNumberOfForms, userForms,
        onUserFormReportChanged, onSelectCountriesChanged, onSelectEthnicsChanged, onSelectRelationshipsChanged, onSelectReligionsChanged,
        onSelectPrimaryAreaOfWorkCodesChanged, onMaxNumberFormsChanged
    }: Props
): JSX.Element => {
    const [pageSize, setPageSize] = useState<number>(paginationDefaultPageSize);

    const defaultActiveKey = userForms?.length > 0 ? `${userForms[0].name}-0` : '';
    const countriesOptions = GetCountriesOptions();
    const countriesValues: SelectItem[] = selectedCountries?.map((value: number) => ({
        value: value,
        label: countriesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const ethnicOrientationOptions = GetEthnicOrientationOptions();
    const ethnicOrientationValues: SelectItem[] = selectedEthnics?.map((value: number) => ({
        value: value,
        label: ethnicOrientationOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const relationshipOptions = GetMaritialStatusOptions();
    const relationshipValues: SelectItem[] = selectedRelationships?.map((value: number) => ({
        value: value,
        label: relationshipOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const religionsOptions = GetReligiousOrientationOptionsForReports();
    const religionsValues: SelectItem[] = selectedReligions?.map((value: number) => ({
        value: value,
        label: religionsOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const primaryAreaOfWorkCodesOptions = GetPrimaryWorkAreaOptions();
    const primaryAreaOfWorkCodesValues: SelectItem[] = selectedPrimaryAreaOfWorkCodes?.map((value: number) => ({
        value: value,
        label: primaryAreaOfWorkCodesOptions.find((item: SelectItem) => item.value === value)?.label ?? ""
    })) ?? [];

    const defaultPagination: TablePaginationConfig = {
        position: ['topCenter', 'bottomCenter'],
        pageSize: pageSize,
        onChange: (_: number, newPageSize?: number) => {
            if (newPageSize !== undefined && pageSize !== newPageSize) setPageSize(newPageSize);
        }
    }

    const searchInputPlaceholder = GetFormattedMessage('app.search_placeholder')
    const searchConfirmButtonText = GetFormattedMessage('app.btn_search')
    const searchResetButtonText = GetFormattedMessage('app.btn_reset')
    const columns: ColumnType<UserFormForView>[] = [
        {
            title: GetFormattedMessage('app.create_report.step3_user_exporting_column_title'),
            key: 'user-exporting-column',
            width: '20%',
            align: 'center',
            sorter: (a: UserFormForView, b: UserFormForView) => Number(a.exporting) - Number(b.exporting),
            render: (_: string, record: UserFormForView) => {
                const userKey = record.userName + record.formId + record.fakeAlias;
                return (
                    <FormCheck
                        id={userKey}
                        key={userKey}
                        type='switch'
                        checked={record.exporting}
                        label={''}
                        onChange={() => {
                            const participants: ParticipantModel[] = [
                                { id: record.formId, alias: record.alias }
                            ];
                            onUserFormReportChanged(participants, /*changeAlias*/ false);
                        }}
                    />
                );
            },
        },
        {
            title: GetFormattedMessage('app.create_report.step3_user_name_column_title'),
            dataIndex: 'userName',
            key: 'user-name-key',
            width: '80%',
            sorter: (a: UserFormForView, b: UserFormForView) => a.userName.localeCompare(b.userName),
            filterDropdown: (props: FilterDropdownProps) => (
                <TableFilterWithInput
                    inputPlaceholder={searchInputPlaceholder}
                    confirmButtonText={searchConfirmButtonText}
                    resetButtonText={searchResetButtonText}
                    {...props}
                />
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            onFilter: (value, record: UserFormForView) =>
                record.userName.toLowerCase().includes(value.toString().toLowerCase()),
        },
    ]

    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <div onClick={decoratedOnClick} style={styles.accordionToogle}>
                {children}
            </div>
        );
    }

    return (
        <React.Fragment>
            <Accordion
                // @ts-ignore
                style={styles.accordionDiv}
                defaultActiveKey={defaultActiveKey}
            >
                {userForms.map((org: UserFormsPerOrganizationForView, index: number) => {
                    const eventKeyOrganization = `${org.name}-${index.toString()}`;
                    return (
                        <Card style={{ borderBottom: 1 }} key={eventKeyOrganization}>
                            <CustomToggle as={Card.Header} eventKey={eventKeyOrganization}>
                                {org.name}
                            </CustomToggle>
                            <Accordion.Collapse eventKey={eventKeyOrganization}>
                                <Card.Body id={org.name}>
                                    <Table
                                        key={'step3-user-table'}
                                        dataSource={org.users}
                                        columns={columns}
                                        pagination={org.users.length > paginationDefaultPageSize ? defaultPagination : false}
                                        rowKey={(record: UserFormForView, index?: number) => `${record.fakeAlias} ${index}`}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
            <br />
            <br />
            <h1>Step 4 - Aggregate Profile By:</h1>
            <br />
            <br />
            <Form as={Row}>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_countries_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_gender'
                        value={countriesValues}
                        options={countriesOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectCountriesChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedCountries && selectedCountries.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_countries_lbl')}
                            <ul>
                                {selectedCountries.map((countryValue: number) =>
                                    <li key={countryValue}>
                                        {countriesOptions.find(country => country.value === countryValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_ethnics_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_gender'
                        disableDefaultText={true}
                        value={ethnicOrientationValues}
                        options={ethnicOrientationOptions}
                        isSelectedOption={true}
                        onFilterChange={onSelectEthnicsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedEthnics && selectedEthnics.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_ethnics_lbl')}
                            <ul>
                                {selectedEthnics.map((ethnicValue: number) =>
                                    <li key={ethnicValue}>
                                        {ethnicOrientationOptions.find(e => e.value === ethnicValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_relationship_status_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_marital_status'
                        value={relationshipValues}
                        options={relationshipOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectRelationshipsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedRelationships && selectedRelationships.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_relationship_status_lbl')}
                            <ul>
                                {selectedRelationships.map((relationshipValue: number) =>
                                    <li key={relationshipValue}>
                                        {relationshipOptions.find(e => e.value === relationshipValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_religion_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_religious_orientation'
                        value={religionsValues}
                        options={religionsOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectReligionsChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedReligions && selectedReligions.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_religion_lbl')}
                            <ul>
                                {selectedReligions.map((religionValue: number) =>
                                    <li key={religionValue}>
                                        {religionsOptions.find(e => e.value === religionValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_primary_area_work_options_lbl')}
                    </Form.Label>
                    <SelectOptionGroup
                        labelKey='app.create_report.filter_primary_area_of_work'
                        value={primaryAreaOfWorkCodesValues}
                        options={primaryAreaOfWorkCodesOptions}
                        isSelectedOption={true}
                        disableDefaultText={true}
                        onFilterChange={onSelectPrimaryAreaOfWorkCodesChanged}
                        useSwitchToDisable={false}
                        closeMenuOnSelect={true}
                    />
                    {selectedPrimaryAreaOfWorkCodes && selectedPrimaryAreaOfWorkCodes.length > 0 &&
                        <Form.Label>
                            {GetFormattedMessage('app.create_report.selected_primary_area_work_lbl')}
                            <ul>
                                {selectedPrimaryAreaOfWorkCodes.map((primaryAreaOfWorkValue: number) =>
                                    <li key={primaryAreaOfWorkValue}>
                                        {primaryAreaOfWorkCodesOptions.find(e => e.value === primaryAreaOfWorkValue)?.label}
                                    </li>
                                )}
                            </ul>
                        </Form.Label>
                    }
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" as={Col}>
                    <Form.Label>
                        {GetFormattedMessage('app.create_report.select_number_of_forms_lbl')}
                    </Form.Label>
                    <LineEditTextGroup
                        value={maxNumberOfForms?.toString()}
                        useSwitchToDisable={false}
                        onFilterChange={(value: string | undefined) => {
                            const number = Number(SanitizeDoubleByteNumber(value));
                            onMaxNumberFormsChanged(!Number.isNaN(number) ? number : undefined);
                        }}
                    />
                </Form.Group>
            </Form>
        </React.Fragment>
    )
}

const styles = {
    accordionDiv: {
        paddingTop: '15px',
    } as React.CSSProperties,
    accordionToogle: {
        padding: '0.75rem 1.25rem',
        backgroundColor: 'rgba(0,0,0,.03)',
        borderBottom: '1px solid rgba(0,0,0,.125)'
    } as React.CSSProperties,
}
