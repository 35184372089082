import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': 'Edad',
    'app.demographic.gender': 'Género',
    'app.demographic.marital_status': 'Estado civil',
    'app.demographic.level_of_fluency': 'Por favor, indica tu nivel máximo de fluidez en cualquier lengua excepto tus lenguas nativas (las que hablas en tu casa y/o comunidad, y/o la lengua vehicular en la(s) escuela(s) de primaria/educación básica a la(s) que acudías).',
    'app.demographic.education_years': 'Por favor, indica cuántos años de educación reglada has completado.',
    'app.demographic.raised_in_country_where_currently_in': 'Empezando por la educación primaria en 1º de primaria/EGB (i. e., después de preescolar o guardería), ¿cuántos años de educación reglada has completado?:',
    'app.demographic.ethnic': '¿Cuál es tu origen étnico?',
    'app.demographic.ethnic_other': 'Por favor, descríbelo en el espacio proporcionado:',
    'app.demographic.ethnic_asian': 'Asiático. Persona cuyos orígenes se remontan a países o regiones del Lejano Oriente, Subcontinente Indio, o Sudeste Asiático incluidos, por ejemplo, Camboya, China, India, Japón, Corea, Malasia, Pakistán, Islas Filipinas, Tailandia, y Vietnam.',
    'app.demographic.ethnic_african': 'Negro o Africano. Persona cuyos orígenes se remontan a cualquiera de los grupos raciales negros de África',
    'app.demographic.ethnic_latino': 'Hispano o Latino. Persona cuyos orígenes se remontan a países o regiones como Cuba, México, Puerto Rico, Sudamérica o Centroamérica, u otra cultura u origen hispano, con independencia de la raza.',
    'app.demographic.ethnic_indigenous': 'Indígena. Persona cuyos orígenes se remontan a cualquiera de los pueblos originales de un país determinado o región, que mantiene una filiación o apego con una tribu o comunidad indígena.',
    'app.demographic.ethnic_middle_eastern': 'Próximoriental. Persona cuyos orígenes se remontan a cualquiera de los países o regiones desde Afganistán a Libia, incluyendo, por ejemplo, Egipto, Irán, Israel, Jordania, Líbano, Arabia Saudí, Sudán, Siria, Turquía, o países de la península Arábiga.',
    'app.demographic.ethnic_white': 'Blanco o Caucásico. Persona cuyos orígenes se remontan a cualquiera de los grupos raciales blancos de Europa.',
    'app.demographic.completing_bevi': 'Por favor, indica cuándo estás haciendo el BEVI.',
    'app.demographic.completing_bevi_other': 'Por favor, describe las circunstancias en las que estás haciendo el BEVI a continuación.',
    'app.demographic.previously_completed': '¿Ya habías hecho el BEVI anteriormente?',
    'app.demographic.disadvantaged_or_minority_group': 'Desde tu punto de vista, ¿formas parte de un grupo marginal o desfavorecido?',
    'app.demographic.disadvantaged_or_minority_group_yes': 'En el espacio proporcionado, por favor, escribe cualquier cosa que quieras añadir.',
    'app.demographic.primary_area_of_work': 'De las opciones que se ofrecen a continuación, ¿cuál se corresponde mejor con tu campo de estudio o tu principal área laboral / de interés profesional?',
    'app.demographic.current_area': 'En el espacio proporcionado, por favor, enumera tu(s) principal(es) campo(s) de estudio u ocupación(es):',
    'app.demographic.political_orientation': 'En una escala que va de lo "Conservador/Tradicional(i. e., a favor de conservar o preservar las leyes, sistemas y formas de ser existentes)" a lo "Progresista (i. e., a favor de cambiar o reformar las leyes, sistemas y formas de ser existentes)", por favor, utiliza el deslizador que encontrarás a continuación para indicar tu orientación política',
    'app.demographic.political_commitment': 'Por favor, indica tu nivel de compromiso con tu orientación política.',
    'app.demographic.political_orientation_conservative_label': 'Conservador/Tradicional',
    'app.demographic.political_orientation_liberal_label': 'Progresista',
    'app.demographic.religion': 'Cuál de las siguientes opciones se corresponde en mayor medida con tus creencias o compromisos en cuanto a religión o espiritualidad en la actualidad?',
    'app.demographic.religion_other': 'Por favor, descríbelo en el espacio proporcionado:',
    'app.demographic.religion_scale': 'Por favor, indica tu nivel de compromiso personal con la religión o espiritualidad.',
    'app.demographic.religion_scale_specified': 'Por favor, indica tu nivel de compromiso personal con {0}',
    'app.demographic.income_level': 'En una escala comprendida entre "Rango más bajo" y "Rango más alto," por favor, utiliza el deslizador para indicar tu rango salarial o acceso a recursos comparado con otras personas de la sociedad en que vives.',
    'app.demographic.income_less_label': 'Rango más bajo',
    'app.demographic.income_more_label': 'Rango más alto',
    'app.demographic.academic_rank_lowest_label': 'Rango más bajo',
    'app.demographic.academic_rank_highest_label': 'Rango más alto',
    'app.demographic.academic_rank': 'En relación con otros estudiantes que fueran a clase contigo durante tu experiencia académica más reciente, por favor, proporciona una estimación lo más adecuada posible de tu posición académica o rango (i. e., de "Rango más bajo a "Rango más alto").',
    'app.demographic.primary_country': '¿Cuál es tu principal país de origen?',
    'app.demographic.time_in_other_countries': 'Con las opciones que se ofrecen a continuación, por favor, indica el tiempo que has pasado en países/regiones distintas a tu país de origen.',
    'app.demographic.time_in_other_countries_other': 'Por favor, especifica',
    'app.demographic.visit_countries': '¿Cuántos países/regiones distintos a tu país de origen has visitado o en cuántos has vivido?',
    'app.demographic.country_explanation_field': 'Para indicar tu país, puedes seleccionarlo del menú desplegable o simplemente escribir el nombre del país y seleccionarlo.',
    'app.demographic.country_mother_born': 'Por favor, indica el país/región de nacimiento de tu madre:',
    'app.demographic.country_father_born': 'Por favor, indica el país/región de nacimiento de tu padre:',
    'app.demographic.personal_interest': 'Por favor, indica tu nivel de interés personal en experiencias internacionales, multiculturales o interculturales (i. e., trabajo, estudio, o servicios en el extranjero; Intercambio Virtual o Aprendizaje Internacional Colaborativo en Línea; tareas multiculturales en el aula; participación en equipos diversos)',
    'app.demographic.participated_experiences': '¿Ya habías participado anteriormente o estás participando en la actualidad en alguna de las siguientes experiencias? (marca todas aquellas que sean relevantes)',
    'app.demographic.participated_learning_courses': '¿Ya habías participado anteriormente o estás participando en la actualidad en alguno de los siguientes cursos/programas de aprendizaje global y diversidad? (marca todas aquellas que sean relevantes)',
    'app.demographic.plan_participate_experiences': 'Tienes pensado participar en alguna de las siguientes experiencias? (marca todas aquellas que sean relevantes)',
    'app.demographic.plan_participate_learning_courses': '¿Tienes pensado participar en alguno de los siguientes cursos/programas de aprendizaje global y diversidad? (marca todas aquellas que sean relevantes)',
    'app.demographic.experience_satisfaction': 'Si has participado, o participas en la actualidad en experiencias internacionales, multiculturales o interculturales (i. e., trabajo, estudio, o servicios en el extranjero; Intercambio Virtual o Aprendizaje Internacional Colaborativo en Línea; tareas multiculturales en el aula; participación en equipos diversos), por favor, indica tu nivel de satisfacción personal con estas experiencias.',
    'app.demographic.languages_speak': '¿Cuántas lenguas hablas de forma nativa (habladas en tu casa y/o comunidad, y/o la lengua vehicular en la(s) escuela(s) de primaria/educación básica a la(s) que acudías)?',
    'app.demographic.years_studied_foreign_language': 'Cuántos años de idiomas has estudiado en total?',
    'app.demographic.primary_language': '¿Cuál es tu lengua materna (i. e., la primera lengua o lengua principal que hablas)? Puedes seleccionar más de una lengua si tienes más de una lengua materna. Para indicar tu(s) lengua(s), puedes seleccionarla(s) del menú desplegable o simplemente escribir el nombre de la(s) lengua(s) y seleccionarla(s).',
    'app.demographic.primary_language_other': '¿Hablas otra lengua distinta a tu lengua nativa (i. e. tu primera lengua o lengua principal)?',
    'app.demographic.language_level_of_fluency': 'De las opciones que se ofrecen a continuación, por favor, selecciona la(s) lengua(s) que hables así como tu nivel de fluidez en ella(s).',
    'app.demographic.language_level_of_fluency_add_anwser': 'Añadir',
    'app.demographic.table_level_of_fluency': 'Nivel de fluidez',
    'app.demographic.table_language': 'Lengua',
    'app.demographic.table_action': 'Acción',
    'app.demographic.remove_language_tooltip': 'Quitar lengua',
    'app.demographic.father_formal_education': 'Por favor, indica el nivel más alto de educación reglada obtenido por tu padre:',
    'app.demographic.mother_formal_education': ' Por favor, indica el nivel más alto de educación reglada obtenido por tu madre:',
    'app.demographic.comparative': 'Informe individual comparativo',
    'app.demographic.compare': 'Comparar',
    'app.demographic.select_multiple_reports': 'Seleccionar varios informes',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanés',
    'app.demographic.language_options.Amharic': 'Amhárico',
    'app.demographic.language_options.Arabic': 'Árabe',
    'app.demographic.language_options.Armenian': 'Armenio',
    'app.demographic.language_options.Basque': 'Vasco',
    'app.demographic.language_options.Bengali': 'Bengalí',
    'app.demographic.language_options.Byelorussian': 'Bieloruso',
    'app.demographic.language_options.Burmese': 'Birmano',
    'app.demographic.language_options.Bulgarian': 'Búlgaro',
    'app.demographic.language_options.Catalan': 'Catalán',
    'app.demographic.language_options.Czech': 'Checo',
    'app.demographic.language_options.Chinese': 'Chino',
    'app.demographic.language_options.Croatian': 'Croata',
    'app.demographic.language_options.Danish': 'Danés',
    'app.demographic.language_options.Dari': 'Darí',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Neerlandés',
    'app.demographic.language_options.English': 'Inglés',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonio',
    'app.demographic.language_options.Faroese': 'Feroés',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finlandés',
    'app.demographic.language_options.French': 'Francés',
    'app.demographic.language_options.Gaelic': 'Gaélico',
    'app.demographic.language_options.Galician': 'Gallego',
    'app.demographic.language_options.German': 'Alemán',
    'app.demographic.language_options.Greek': 'Griego',
    'app.demographic.language_options.Hebrew': 'Hebreo',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Húngaro',
    'app.demographic.language_options.Icelandic': 'Islandés',
    'app.demographic.language_options.Indonesian': 'Indonesio',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Esquimal)',
    'app.demographic.language_options.Italian': 'Italiano',
    'app.demographic.language_options.Japanese': 'Japonés',
    'app.demographic.language_options.Khmer': 'Jemer',
    'app.demographic.language_options.Korean': 'Coreano',
    'app.demographic.language_options.Kurdish': 'Kurdo',
    'app.demographic.language_options.Laotian': 'Laosiano',
    'app.demographic.language_options.Latvian': 'Letón',
    'app.demographic.language_options.Lappish': 'Lapón',
    'app.demographic.language_options.Lithuanian': 'Lituano',
    'app.demographic.language_options.Macedonian': 'Macedonio',
    'app.demographic.language_options.Malay': 'Malayo',
    'app.demographic.language_options.Maltese': 'Maltés',
    'app.demographic.language_options.Nepali': 'Nepalí',
    'app.demographic.language_options.Norwegian': 'Noruego',
    'app.demographic.language_options.Pashto': 'Pastún',
    'app.demographic.language_options.Polish': 'Polaco',
    'app.demographic.language_options.Portuguese': 'Portugués',
    'app.demographic.language_options.Romanian': 'Rumano',
    'app.demographic.language_options.Russian': 'Ruso',
    'app.demographic.language_options.Scots': 'Escocés',
    'app.demographic.language_options.Serbian': 'Serbio',
    'app.demographic.language_options.Slovak': 'Eslovaco',
    'app.demographic.language_options.Slovenian': 'Esloveno',
    'app.demographic.language_options.Somali': 'Somalí',
    'app.demographic.language_options.Spanish': 'Español',
    'app.demographic.language_options.Swedish': 'Sueco',
    'app.demographic.language_options.Swahili': 'Suajili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalo-Filipino',
    'app.demographic.language_options.Tajik': 'Tayiko',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Tailandés',
    'app.demographic.language_options.Tibetan': 'Tibetano',
    'app.demographic.language_options.Tigrinya': 'Tigriña',
    'app.demographic.language_options.Tongan': 'Tongano',
    'app.demographic.language_options.Turkish': 'Turco',
    'app.demographic.language_options.Turkmen': 'Turcomano',
    'app.demographic.language_options.Ucrainian': 'Ucraniano',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Uzbeko',
    'app.demographic.language_options.Vietnamese': 'Vietnamita',
    'app.demographic.language_options.Welsh': 'Galés',
    'app.demographic.language_options.Other': 'Otro',
    'app.demographic.gender.male': 'Hombre',
    'app.demographic.gender.female': 'Mujer',
    'app.demographic.gender.self_identify': 'Prefiero autocategorizarme',
    'app.demographic.marital.response_single': 'Soltero/a',
    'app.demographic.marital.response_married': 'Casado/a',
    'app.demographic.marital.response_divorced': 'Divorciado/a',
    'app.demographic.marital.response_widowed': 'Viudo/a',
    'app.demographic.marital.response_other': 'Otro',
    'app.demographic.education_years.response_1': 'Sin educación reglada.',
    'app.demographic.education_years.response_2': '1 año',
    'app.demographic.education_years.response_3': '2 años',
    'app.demographic.education_years.response_4': '3 años',
    'app.demographic.education_years.response_5': '4 años',
    'app.demographic.education_years.response_6': '5 años',
    'app.demographic.education_years.response_7': '6 años',
    'app.demographic.education_years.response_8': '7 años',
    'app.demographic.education_years.response_9': '8 años',
    'app.demographic.education_years.response_10': '9 años',
    'app.demographic.education_years.response_11': '10 años',
    'app.demographic.education_years.response_12': '11 años',
    'app.demographic.education_years.response_13': '12 años',
    'app.demographic.education_years.response_14': '13 años',
    'app.demographic.education_years.response_15': '14 años',
    'app.demographic.education_years.response_16': '15 años',
    'app.demographic.education_years.response_17': '16 años',
    'app.demographic.education_years.response_18': '17 años',
    'app.demographic.education_years.response_19': '18 años',
    'app.demographic.education_years.response_20': '19 años',
    'app.demographic.education_years.response_21': '20 años',
    'app.demographic.education_years.response_22': '21 años',
    'app.demographic.education_years.response_23': '22 años',
    'app.demographic.education_years.response_24': '23 años',
    'app.demographic.education_years.response_25': '24 años',
    'app.demographic.education_years.response_26': '25 años',
    'app.demographic.education_years.response_27': '26 años',
    'app.demographic.education_years.response_28': '27 años',
    'app.demographic.education_years.response_29': '28 años',
    'app.demographic.education_years.response_30': '29 años',
    'app.demographic.education_years.response_31': '30 años',
    'app.demographic.education_years.response_32': 'Más de 30 años',
    'app.demographic.ethnic.respose_asian': 'Asiático',
    'app.demographic.ethnic.respose_african': 'Negro o Africano',
    'app.demographic.ethnic.respose_latino': 'Hispano o Latino',
    'app.demographic.ethnic.respose_indigenous': 'Indígena',
    'app.demographic.ethnic.respose_middle_eastern': 'Próximo oriental',
    'app.demographic.ethnic.respose_white': 'Blanco o Caucásico',
    'app.demographic.ethnic.respose_mixed': 'Mestizo',
    'app.demographic.ethnic.respose_other': 'Otro',
    'app.demographic.completing_bevi.response_before': 'Antes (o al comienzo) de una experiencia de aprendizaje internacional/intercultural.',
    'app.demographic.completing_bevi.response_during': 'Durante (o inmerso(a) en) una experiencia de aprendizaje internacional/intercultural.',
    'app.demographic.completing_bevi.response_after': 'Después de (o finalizando) una experiencia de aprendizaje internacional/intercultural.',
    'app.demographic.completing_bevi.response_during_academic': 'Durante mi periodo de orientación académica [NO EXISTE EN ESPAÑA] y ninguna de las opciones anteriores.',
    'app.demographic.completing_bevi.response_end_graduation': 'Acabando un Grado.',
    'app.demographic.completing_bevi.response_other': 'Otro – Por favor, especifica.',
    'app.demographic.primary_area_of_work.response_1': 'Artes, Comunicación, Humanidades',
    'app.demographic.primary_area_of_work.response_2': 'Ciencias conductuales, Cultura, Sociedad',
    'app.demographic.primary_area_of_work.response_3': 'Economía',
    'app.demographic.primary_area_of_work.response_4': 'Educación',
    'app.demographic.primary_area_of_work.response_5': 'Sanidad y Servicios Sociales',
    'app.demographic.primary_area_of_work.response_6': 'Ciencia y Tecnología',
    'app.demographic.primary_area_of_work.response_7': 'Otro',
    'app.demographic.political_orientation.response_1': 'Conservador/Tradicional(i. e., a favor de conservar o preservar las leyes, sistemas y formas de ser existentes)',
    'app.demographic.political_orientation.response_2': 'Más conservador que progresista',
    'app.demographic.political_orientation.response_3': 'Moderado/neutral/ni conservador ni progresista',
    'app.demographic.political_orientation.response_4': 'Más progresista que conservador',
    'app.demographic.political_orientation.response_5': 'Progresista (i. e., a favor de cambiar o reformar las leyes, sistemas y formas de ser existentes)',
    'app.demographic.political_orientation.response_6': 'Otro',
    'app.demographic.religion.response_1': 'Religión Africana Tradicional',
    'app.demographic.religion.response_2': 'Agnosticismo',
    'app.demographic.religion.response_3': 'Ateísmo',
    'app.demographic.religion.response_4': 'Bahaísmo',
    'app.demographic.religion.response_5': 'Budismo',
    'app.demographic.religion.response_6': 'Caodaísmo',
    'app.demographic.religion.response_7': 'Religión Tradicional China',
    'app.demographic.religion.response_8': 'Cristianismo',
    'app.demographic.religion.response_9': 'Hinduismo',
    'app.demographic.religion.response_10': 'Indígena',
    'app.demographic.religion.response_11': 'Islam',
    'app.demographic.religion.response_12': 'Jainismo',
    'app.demographic.religion.response_13': 'Juche',
    'app.demographic.religion.response_14': 'Judaísmo',
    'app.demographic.religion.response_15': 'Paganismo',
    'app.demographic.religion.response_16': 'Shinto',
    'app.demographic.religion.response_17': 'Sijismo',
    'app.demographic.religion.response_18': 'Espiritismo',
    'app.demographic.religion.response_19': 'Espiritual pero sin Filiación Religiosa Específica',
    'app.demographic.religion.response_20': 'Tenrikyo',
    'app.demographic.religion.response_21': 'Universalismo-Unitario',
    'app.demographic.religion.response_22': 'Zoroastrismo',
    'app.demographic.religion.response_other': 'Otra',
    'app.demographic.religion.no_response': 'Prefiero no responder a esta pregunta',
    'app.demographic.income_level.response_1': 'Tercio superior',
    'app.demographic.income_level.response_2': 'Tercio medio',
    'app.demographic.income_level.response_3': 'Tercio inferior',
    'app.demographic.academic_rank.response_1': 'Tercio superior',
    'app.demographic.academic_rank.response_2': 'Tercio medio',
    'app.demographic.academic_rank.response_3': 'Tercio inferior',
    'app.demographic.participated_experiences.response_1': 'Seminario específico para estudiantes de primer año',
    'app.demographic.participated_experiences.response_2': 'Miembro de una comunidad de aprendizaje organizada a nivel universitario',
    'app.demographic.participated_experiences.response_3': 'Curso de escritura intensivo',
    'app.demographic.participated_experiences.response_4': 'Proyecto de investigación con un mentor profesional o académico',
    'app.demographic.participated_experiences.response_5': 'Proyecto de aprendizaje-servicio financiado/aprobado por una universidad o basado en la comunidad',
    'app.demographic.participated_experiences.response_6': 'Prácticas profesionales en el extranjero financiadas/aprobadas por una universidad',
    'app.demographic.participated_experiences.response_7': 'Proyecto final/Trabajo de Fin de Grado',
    'app.demographic.participated_experiences.response_8': 'Conjunto de cursos obligatorios comunes o compartidos',
    'app.demographic.participated_experiences.response_9': 'Tareas colaborativas o proyectos en grupo',
    'app.demographic.participated_experiences.response_10': 'Cursos de aprendizaje global/diversidad o estudios en el extranjero',
    'app.demographic.participated_experiences.response_11': 'Portfolio electrónico (portfolio-e) de tu trabajo o experiencias educativas',
    'app.demographic.participated_experiences.response_12': 'Aprendizaje en línea (i. e., virtual o basado en web) que <b>incluya</b> un enfoque internacional, multicultural o intercultural.',
    'app.demographic.participated_experiences.response_13': 'Aprendizaje en línea (i. e., virtual o basado en web) que <b>no incluya</b> un enfoque internacional, multicultural o intercultural.',
    'app.demographic.participated_learning_courses.response_1': 'Matriculado un curso con énfasis en estudios multiculturales o globales',
    'app.demographic.participated_learning_courses.response_2': 'Participado en eventos culturales extracurriculares, multiculturales  e internacionales dentro del campus.',
    'app.demographic.participated_learning_courses.response_3': 'Finalizado experiencia de aprendizaje-servicio en el extranjero',
    'app.demographic.participated_learning_courses.response_4': 'Finalizado prácticas profesionales en el extranjero',
    'app.demographic.participated_learning_courses.response_5': 'Estudiado en el extranjero',
    'app.demographic.participated_learning_courses.response_6': 'Vivido en una comunidad de aprendizaje en vivo con un enfoque internacional, multicultural, global, o de aprendizaje de idiomas',
    'app.demographic.participated_learning_courses.response_7': 'Estudiado un idioma que no fuera mi lengua nativa',
    'app.demographic.participated_learning_courses.response_8': 'Vivido con un compañero de una tradición cultural u origen distinto del mío.',
    'app.demographic.participated_learning_courses.response_9': 'Realizado o en proceso de finalizar un Intercambio Virtual, Aprendizaje Internacional Colaborativo en Línea, u otra experiencia de aprendizaje en línea/basada en web que tenga un enfoque internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_experiences.response_1': 'Seminario específico para estudiantes de primer año',
    'app.demographic.plan_participate_experiences.response_2': 'Miembro de una comunidad de aprendizaje organizada a nivel universitario',
    'app.demographic.plan_participate_experiences.response_3': 'Curso de escritura intensivo',
    'app.demographic.plan_participate_experiences.response_4': 'Proyecto de investigación con un mentor profesional o académico',
    'app.demographic.plan_participate_experiences.response_5': 'Proyecto de aprendizaje-servicio financiado/aprobado por una universidad o basado en la comunidad',
    'app.demographic.plan_participate_experiences.response_6': 'Prácticas profesionales en el extranjero financiadas/aprobadas por una universidad',
    'app.demographic.plan_participate_experiences.response_7': 'Proyecto final/Trabajo de Fin de Grado',
    'app.demographic.plan_participate_experiences.response_8': 'Conjunto de cursos obligatorios comunes o compartidos',
    'app.demographic.plan_participate_experiences.response_9': 'Tareas colaborativas o proyectos en grupo',
    'app.demographic.plan_participate_experiences.response_10': 'Cursos de aprendizaje global/diversidad o estudios en el extranjero',
    'app.demographic.plan_participate_experiences.response_11': 'Portfolio electrónico (portfolio-e) de tu trabajo o experiencias educativas',
    'app.demographic.plan_participate_experiences.response_12': 'Aprendizaje en línea (i. e., virtual o basado en web) que <b>incluya</b> un enfoque internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_experiences.response_13': ' Aprendizaje en línea (i. e., virtual o basado en web) que <b>no incluya</b> un enfoque internacional, multicultural o intercultural.',
    'app.demographic.plan_participate_learning_courses.response_1': 'Matricular un curso con énfasis en estudios multiculturales o globales',
    'app.demographic.plan_participate_learning_courses.response_2': 'Participar en eventos culturales extracurriculares, multiculturales  e internacionales dentro del campus.',
    'app.demographic.plan_participate_learning_courses.response_3': 'Finalizar experiencia de aprendizaje-servicio en el extranjero',
    'app.demographic.plan_participate_learning_courses.response_4': 'Finalizar prácticas profesionales en el extranjero',
    'app.demographic.plan_participate_learning_courses.response_5': 'Estudiar en el extranjero',
    'app.demographic.plan_participate_learning_courses.response_6': 'Vivir en una comunidad de aprendizaje en vivo con un enfoque internacional, multicultural, global, o de aprendizaje de idiomas',
    'app.demographic.plan_participate_learning_courses.response_7': 'Estudiar un idioma que no fuera mi lengua nativa',
    'app.demographic.plan_participate_learning_courses.response_8': 'Vivir con un compañero de una tradición cultural u origen distinto del mío.',
    'app.demographic.plan_participate_learning_courses.response_9': 'Realizar un Intercambio Virtual, Aprendizaje Internacional Colaborativo en Línea, u otra experiencia de aprendizaje en línea/basada en web que tenga un enfoque internacional, multicultural o intercultural.',
    'app.demographic.time_in_other_country.response_not_spent': 'Tiempo de estancia en el extranjero no significativo',
    'app.demographic.time_in_other_country.response_less_2_weeks': 'Al menos 1 semana pero menos de 2 semanas',
    'app.demographic.time_in_other_country.response_less_1_month': 'Al menos 2 semanas pero menos de 1 mes',
    'app.demographic.time_in_other_country.response_less_3_months': 'Al menos 1 mes pero menos de 3 meses',
    'app.demographic.time_in_other_country.response_less_6_months': 'Al menos 3 meses pero menos de 6 meses',
    'app.demographic.time_in_other_country.response_less_1_year': 'Al menos 6 meses pero menos de 1 año',
    'app.demographic.time_in_other_country.response_less_2_years': 'Al menos 1 año pero menos de 2 años',
    'app.demographic.time_in_other_country.response_less_5_years': 'Al menos 2 años pero menos de 5 años',
    'app.demographic.time_in_other_country.response_less_10_years': 'Al menos 5 años pero menos de 10 años',
    'app.demographic.time_in_other_country.response_10_or_more': '10 o más años',
    'app.demographic.country.response_eua': 'Estados Unidos de América',
    'app.demographic.country.response_afganistan': 'Afganistán',
    'app.demographic.country.response_albania': 'Albania',
    'app.demographic.country.response_algeria': 'Algeria',
    'app.demographic.country.response_american_samoa': 'Samoa Americana',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguila',
    'app.demographic.country.response_antigua_barbuda': 'Antigua y Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armenia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australia',
    'app.demographic.country.response_austria': 'Austria',
    'app.demographic.country.response_azerbaijan': 'Azerbaiyán',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Baréin',
    'app.demographic.country.response_bangladesh': 'Bangladés',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarús',
    'app.demographic.country.response_belgium': 'Bélgica',
    'app.demographic.country.response_belize': 'Belice',
    'app.demographic.country.response_benin': 'Benín',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bután',
    'app.demographic.country.response_bolivia': 'Bolivia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnia y Herzegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brasil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgaria',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodia',
    'app.demographic.country.response_cameroon': 'Camerún',
    'app.demographic.country.response_canada': 'Canadá',
    'app.demographic.country.response_cape_verde': 'Cabo Verde',
    'app.demographic.country.response_cayman_islands': 'Islas Caimán',
    'app.demographic.country.response_central_african_republic': 'República Centroafricana',
    'app.demographic.country.response_chad': 'Chad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colombia',
    'app.demographic.country.response_comoros': 'Comoras',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'Congo, República Democrática',
    'app.demographic.country.response_cook_islands': 'Islas Cook',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Costa de Marfil',
    'app.demographic.country.response_croatia': 'Croacia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Chipre',
    'app.demographic.country.response_czech_republic': 'República Checa',
    'app.demographic.country.response_denmark': 'Dinamarca',
    'app.demographic.country.response_djibouti': 'Yibuti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'República Dominicana',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Egipto',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Guinea Ecuatorial',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estonia',
    'app.demographic.country.response_ethiopia': 'Etiopía',
    'app.demographic.country.response_euro_area': 'Zona Euro',
    'app.demographic.country.response_falkland_islands': 'Islas Malvinas',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finlandia',
    'app.demographic.country.response_france': 'Francia',
    'app.demographic.country.response_french_guiana': 'Guayana Francesa',
    'app.demographic.country.response_french_polynesia': 'Polinesia Francesa',
    'app.demographic.country.response_gabon': 'Gabón',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgia',
    'app.demographic.country.response_germany': 'Alemania',
    'app.demographic.country.response_ghana': 'Gana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Grecia',
    'app.demographic.country.response_grenada': 'Granada',
    'app.demographic.country.response_guadeloupe': 'Guadalupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea-Bisáu',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haití',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Hungría',
    'app.demographic.country.response_iceland': 'Islandia',
    'app.demographic.country.response_india': 'India',
    'app.demographic.country.response_indonesia': 'Indonesia',
    'app.demographic.country.response_iran': 'Irán',
    'app.demographic.country.response_iraq': 'Irak',
    'app.demographic.country.response_ireland': 'Irlanda',
    'app.demographic.country.response_isle_of_man': 'Isla de Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italia',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': 'Japón',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordania',
    'app.demographic.country.response_kazakhstan': 'Kazajistán',
    'app.demographic.country.response_kenya': 'Kenia',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kirguistán',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Latvia',
    'app.demographic.country.response_lebanon': 'Líbano',
    'app.demographic.country.response_lesotho': 'Lesoto',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libia',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Liuania',
    'app.demographic.country.response_luxembourg': 'Luxemburgo',
    'app.demographic.country.response_macau': 'Macao',
    'app.demographic.country.response_macedonia': 'Macedonia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malasia',
    'app.demographic.country.response_malawi': 'Malaui',
    'app.demographic.country.response_maldives': 'Maldivas',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Islas Marianas',
    'app.demographic.country.response_marshall_islands': 'Islas Marshall',
    'app.demographic.country.response_martinique': 'Martinica',
    'app.demographic.country.response_mauritania': 'Mauritania',
    'app.demographic.country.response_mauritius': 'Mauricio',
    'app.demographic.country.response_mexico': 'México',
    'app.demographic.country.response_micronesia': 'Micronesia',
    'app.demographic.country.response_moldova': 'Moldavia',
    'app.demographic.country.response_monaco': 'Mónaco',
    'app.demographic.country.response_mongolia': 'Mongolia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Marruecos',
    'app.demographic.country.response_mozambique': 'Mozambique',
    'app.demographic.country.response_myanmar': 'Birmania',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Antillas Holandesas',
    'app.demographic.country.response_netherlands': 'Países Bajos',
    'app.demographic.country.response_new_caledonia': 'Nueva Caledonia',
    'app.demographic.country.response_new_zealand': 'Nueva Zelanda',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'Corea del Norte',
    'app.demographic.country.response_norway': 'Noruega',
    'app.demographic.country.response_oman': 'Omán',
    'app.demographic.country.response_pakistan': 'Pakistán',
    'app.demographic.country.response_palau': 'Palaos',
    'app.demographic.country.response_panama': 'Panamá',
    'app.demographic.country.response_papua_new_guinea': 'Papua Nueva Guinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Perú',
    'app.demographic.country.response_phillipines': 'Filipinas',
    'app.demographic.country.response_poland': 'Polonia',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Catar',
    'app.demographic.country.response_reunion': 'Reunión',
    'app.demographic.country.response_romania': 'Rumania',
    'app.demographic.country.response_russian_federation': 'Federación Rusa',
    'app.demographic.country.response_rwanda': 'Ruanda',
    'app.demographic.country.response_st_helena': 'Santa Helena',
    'app.demographic.country.response_st_kitts-nevis': 'San Cristóbal y Nieves',
    'app.demographic.country.response_st_lucia': 'Santa Lucía',
    'app.demographic.country.response_st_vincent_grenadines': 'San Vincente y las Granadinas',
    'app.demographic.country.response_stateless': 'Apátrida',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'Santo Tomé y Príncipe',
    'app.demographic.country.response_saudi_arabia': 'Arabia Saudí',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Serbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leona',
    'app.demographic.country.response_singapore': 'Singapur',
    'app.demographic.country.response_slovak_republic': 'Eslovaquia',
    'app.demographic.country.response_slovenia': 'Eslovenia',
    'app.demographic.country.response_solomon_islands': 'Islas Salomón',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'Suráfrica',
    'app.demographic.country.response_south_korea': 'Corea del Sur',
    'app.demographic.country.response_spain': 'España',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudán',
    'app.demographic.country.response_suriname': 'Surinam',
    'app.demographic.country.response_swaziland': 'Suazilandia',
    'app.demographic.country.response_sweden': 'Suecia',
    'app.demographic.country.response_switzerland': 'Suiza',
    'app.demographic.country.response_syria': 'Siria',
    'app.demographic.country.response_taiwan': 'Taiwán',
    'app.demographic.country.response_tajikistan': 'Tayikistán',
    'app.demographic.country.response_tanzania': 'Tanzania',
    'app.demographic.country.response_thailand': 'Tailandia',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor Oriental',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad y Tobago',
    'app.demographic.country.response_tunisia': 'Túnez',
    'app.demographic.country.response_turkey': 'Turquía',
    'app.demographic.country.response_turkmenistan': 'Turkmenistán',
    'app.demographic.country.response_turks_caicos_is': 'Islas Turcas y Caicos',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ucrania',
    'app.demographic.country.response_united_arab_erimates': 'Emiratos Árabes',
    'app.demographic.country.response_united_kingdom': 'Reino Unido',
    'app.demographic.country.response_uraguay': 'Uruguay',
    'app.demographic.country.response_uzbekistan': 'Uzbekistán',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Islas Vírgenes',
    'app.demographic.country.response_west_bank_and_gaza': 'Franja de Gaza',
    'app.demographic.country.response_yemen': 'Yemen',
    'app.demographic.country.response_zambia': 'Zambia',
    'app.demographic.country.response_zimbabwe': 'Zimbabue',
    'app.demographic.level_of_fluency.i_do_not_speak': 'No hablo más lenguas que mi(s) lengua(s) nativa(s)',
    'app.demographic.level_of_fluency.minimally_fluent': 'Minimamente fluido',
    'app.demographic.level_of_fluency.somewhat_fluent': 'Algo fluido',
    'app.demographic.level_of_fluency.moderately_fluent': 'Moderadamente fluido',
    'app.demographic.level_of_fluency.mostly_fluent': 'Mayormente fluido',
    'app.demographic.level_of_fluency.fluent': 'Fluido',
    'app.demographic.level_of_interest.not_applicable': 'No aplica',
    'app.demographic.level_of_interest.extremely_low': 'Extremadamente bajo',
    'app.demographic.level_of_interest.very_low': 'Muy bajo',
    'app.demographic.level_of_interest.low': 'Bajo',
    'app.demographic.level_of_interest.neutral': 'Neutral',
    'app.demographic.level_of_interest.high': 'Alto',
    'app.demographic.level_of_interest.very_high': 'Muy alto',
    'app.demographic.level_of_interest.extremely_high': 'Extremadamente alto',
    'app.demographic_slider_default_option': 'Por favor, mueve el cursor para seleccionar tu respuesta.',
    'app.demographic.formal_education.some_high_school_or_less': 'Parte de la Educación Secundaria o menos',
    'app.demographic.formal_education.high_school_graduate': 'Educación secundaria o grado medio de Formación Profesional',
    'app.demographic.formal_education.some_college': 'Grado superior de Formación Profesional o varios cursos en la universidad sin llegar a la obtención del título',
    'app.demographic.formal_education.college_degree': 'Grado universitario',
    'app.demographic.formal_education.some_graduate_school': 'Estudios de postgrado sin llegar a la obtención del título',
    'app.demographic.formal_education.graduate_degree': 'Máster',
    'app.demographic.formal_education.professional_degree': 'Máster profesionalizador (i. e., Derecho, Máster de profesorado)',
    'app.demographic.formal_education.doctoral_degree': 'Doctorado',
}

export const demographicQuestionsES: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
