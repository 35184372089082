import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { Loading } from '../../components/Loading';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { ExternalActions, externalLogic, ExternalProps } from '../../redux/externalLogic';
import { formLogic, FormsActions } from '../../redux/formsLogic';
import {
    EXTERNAL_CONSENT_PAGE, EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE, EXTERNAL_DISCURSIVE_QUESTIONS_PAGE,
    EXTERNAL_SCALE_QUESTIONS_PAGE, NOT_AVAILABLE_FORM
} from '../../router/pages';
import { ValidateExternalToken } from '../../utils/validateHelper';

type Props = RouteComponentProps<{externalToken: string}> & { };

export const ExternalBeviContainer = withRouter((props: Props): JSX.Element => {

    const { setToken }: AppActions = useActions(appLogic);
    const { tokenInfo }: AppProps = useValues(appLogic);
    const { getExternalFormStatus }: ExternalActions = useActions(externalLogic);
    const { externalFormStatus }: ExternalProps = useValues(externalLogic);
    const { setFormId, setFormStatus }: FormsActions = useActions(formLogic);

    const { externalToken } = useParams<{externalToken: string}>();

    useEffect(() => {
        if (ValidateExternalToken(externalToken)) {
            setToken(externalToken)
            getExternalFormStatus(externalToken);
        } else {
            props.history.replace(NOT_AVAILABLE_FORM);
        }
        // eslint-disable-next-line
    }, [externalToken, getExternalFormStatus, setToken]);

    useEffect(() => {
        if (tokenInfo?.formId && externalFormStatus?.status) {
             setFormId(Number(tokenInfo?.formId));
             setFormStatus(externalFormStatus?.status);
             switch (externalFormStatus?.status) {
                case 1: {
                    props.history.replace(EXTERNAL_CONSENT_PAGE);
                    break;
                }
                case 2: {
                    props.history.replace(EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE);
                    break;
                }
                case 3: {
                    props.history.replace(EXTERNAL_SCALE_QUESTIONS_PAGE);
                    break;
                }
                case 4: {
                    props.history.replace(EXTERNAL_DISCURSIVE_QUESTIONS_PAGE);
                    break;
                }
                default: {
                    props.history.replace({
                        pathname: NOT_AVAILABLE_FORM,
                        state: { status: externalFormStatus?.status }
                    });
                }
            }
        }
    }, [props.history, tokenInfo, externalFormStatus, setFormId, setFormStatus]);

    return (
        <div style={styles.divBackground} className='background'>
            <Loading />
        </div>
    );
});

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
};

