import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': '年齡',
    'app.demographic.gender': '性別',
    'app.demographic.marital_status': '婚姻狀況',
    'app.demographic.level_of_fluency': '＝＝＝Not in the Demographics请输入您除母语以外的语言的流利程度。这里的母语指的是您在自己家或社区、或上过的小学使用的语言。',
    'app.demographic.education_years': '選擇您到現在為止已完成正規教育的年數。',
    'app.demographic.raised_in_country_where_currently_in': '您是否主要是在您目前居住、工作、或學習的國家長大？',
    'app.demographic.ethnic': '民族背景。',
    'app.demographic.ethnic_other': '如果可以的話，請將您的民族背景具體填寫在下欄。',
    'app.demographic.ethnic_asian': '亞洲人：來自中國或港澳、台灣、韓國、日本、菲律賓、泰國、越南、柬埔寨、馬來西亞、印度、巴基斯坦等，遠東、東南亞或印度次大陸的國家或地區 ',
    'app.demographic.ethnic_african': '黑人或非洲人：來自非洲黑人種族之一',
    'app.demographic.ethnic_latino': '西班牙後裔或拉丁美洲人後裔：祖籍古巴、墨西哥、波多黎各、南非、中非、以及其他受西班牙、葡萄牙文化影響的國家或地區',
    'app.demographic.ethnic_indigenous': '原住民或土著：來自任何一個國家或地區的原住民或土著，或維持著與原住民或社區的關係或歸屬 ',
    'app.demographic.ethnic_middle_eastern': '中東：來自埃及、伊朗、伊拉克、以色列、約旦、黎巴嫩、沙烏地阿拉伯、蘇丹、敘利亞、土耳其、包括阿拉伯半島的國家、或從阿富汗到利比亞的國家或地區',
    'app.demographic.ethnic_white': '白人：來自歐洲的任何一個種族群體',
    'app.demographic.completing_bevi': '＝＝＝Not in the Demographics输入在哪个阶段想接受BEVI。Please indicate when you are completing the BEVI.',
    'app.demographic.completing_bevi_other': '＝＝＝Not in the Demographics输入在哪种情况下想接受BEVI。Please describe the circumstances under which you are completing the BEVI below.',
    'app.demographic.previously_completed': '＝＝＝Not in the Demographics以前是否接触过BEVIHave you previously completed the BEVI?',
    'app.demographic.disadvantaged_or_minority_group': '您認為您目前身處弱勢群體或少數群體嗎？',
    'app.demographic.disadvantaged_or_minority_group_yes': '如果可以的話，請將其詳情填寫在下欄。',
    'app.demographic.primary_area_of_work': '請從下列選單中選出最接近您的專業、職業的領域，或職業興趣的一項。',
    'app.demographic.current_area': '請在下欄填寫您現在的本科學院，專業或職業。',
    'app.demographic.political_orientation': '從“守舊的或傳統的（例如，贊成維持現有的法律、制度、安逸現狀）”到“變革的或開明的（例如，贊成變更、改革現有的法律、制度、努力改變現狀）”，請用以下滑桿標示您現在的政治傾向。',
    'app.demographic.political_orientation_conservative_label': '守舊的或傳統的',
    'app.demographic.political_orientation_liberal_label': '變革的或開明的',
    'app.demographic.religion': '請從下列選單中選出最接近您現在信仰的宗教或神靈的一項。',
    'app.demographic.religion_other': '如果可以的話，請將其詳情填寫在下欄。',
    'app.demographic.religion_scale': '您現在參與宗教信仰或神靈活動的程度。',
    'app.demographic.religion_scale_specified': '您對{0}的參與程度。',
    'app.demographic.income_level': '從“最低”到“最高”，請用以下滑桿標示您或家人的相對收入，或相對資產（在您的國家或地區，與他人相比）。',
    'app.demographic.income_less_label': '最低',
    'app.demographic.income_more_label': '最高',
    'app.demographic.academic_rank_lowest_label': '最低',
    'app.demographic.academic_rank_highest_label': '最高',
    'app.demographic.academic_rank': '和您最近上的大學或學校的同班同學相比，從“最低”到“最高”，請用以下滑桿標示出您學習狀態或成績排名最好時的位置。',
    'app.demographic.primary_country': '您的出生國。請輸入其英文單字的開頭搜索。',
    'app.demographic.time_in_other_countries': '您在出生國以外的國家或地區度過的時間。',
    'app.demographic.time_in_other_countries_other': '請指定',
    'app.demographic.visit_countries': '除了您的出生國，您去過或在多少個國家或地區居住過？(請輸入半形數字)? ',
    'app.demographic.country_mother_born': '您母親的出生國。請輸入其英文單字的開頭。',
    'app.demographic.country_father_born': '您父親的出生國。請輸入其英文單字的開頭。',
    'app.demographic.personal_interest': '您對國際性，多元文化，或跨文化體驗的<b>個人關心程度</b>（例如：海外留學、海外就職；網路等的虛擬交流（VE：Virtual Exchange）、網上國際協作學習（COIL：Collaborative Online International Learning）；關於多元文化的課程項目；參加跨國團隊等）',
    'app.demographic.participated_experiences': '您是否參加過，或現在正在參加以下課程項目？（請選擇所有符合的選項）',
    'app.demographic.participated_learning_courses': '您是否參加過，或現在正在參加以下與跨文化，或多樣化相關的課程項目？（請選擇所有符合的選項）',
    'app.demographic.plan_participate_experiences': '您是否打算參加以下課程項目？（請選擇所有符合的選項）',
    'app.demographic.plan_participate_learning_courses': '您是否打算參加以下與跨文化，或多樣性相關的課程項目？（請選擇所有符合的選項）',
    'app.demographic.experience_satisfaction': '您對參加過，或現在正在參加的國際性，多元文化，或跨文化體驗的<b>整體滿意度</b>（例如：海外留學、海外就職；網路等的虛擬交流（VE：Virtual Exchange）、網上國際協作學習（COIL：Collaborative Online International Learning）；關於多元文化的課程項目；參加跨國團隊等）',
    'app.demographic.languages_speak': '您使用幾種語言作為母語？您會說嗎？這裡的母語指的是您在家或社區、或是上過的小學使用的語言。',
    'app.demographic.years_studied_foreign_language': '目前為止，您學了幾年外語(請輸入半形數字)。',
    'app.demographic.primary_language': '您的母語（第一語言、主要語言）是什麼語言？若您有多種母語，可選擇多種語言。請從下列選單選擇語言或輸入其英文單字的開頭。',
    'app.demographic.primary_language_other': '除了自己的母語以外，您有過使用其他語言的經歷嗎（例如，課程作業，會話，閱讀等）? ',
    'app.demographic.language_level_of_fluency': '除了自己的母語以外，若您在會話，閱讀或寫作上使用其他語言，請在下方選擇您使用的非母語的語言及其流利程度。選擇語言時，請輸入其英文單字的開頭。',
    'app.demographic.language_level_of_fluency_add_anwser': '添加',
    'app.demographic.table_level_of_fluency': '流利程度',
    'app.demographic.table_language': '語言',
    'app.demographic.table_action': '操作',
    'app.demographic.remove_language_tooltip': '刪除這一欄',
    'app.demographic.mother_formal_education': '您母親的最高學歷。',
    'app.demographic.father_formal_education': '您父親的最高學歷。。',
    'app.demographic.political_commitment': '您個人對政治的參與程度',
    'app.demographic.comparative': '个人比较报告',
    'app.demographic.compare': 'Compare',
    'app.demographic.select_multiple_reports': '选择多个报告',
    'app.demographic.country_explanation_field': 'To find your country, you may either select the country from the dropdown menu or simply type in and select the language.',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanian',
    'app.demographic.language_options.Amharic': 'Amharic',
    'app.demographic.language_options.Arabic': 'Arabic',
    'app.demographic.language_options.Armenian': 'Armenian',
    'app.demographic.language_options.Basque': 'Basque',
    'app.demographic.language_options.Bengali': 'Bengali',
    'app.demographic.language_options.Byelorussian': 'Byelorussian',
    'app.demographic.language_options.Burmese': 'Burmese',
    'app.demographic.language_options.Bulgarian': 'Bulgarian',
    'app.demographic.language_options.Catalan': 'Catalan',
    'app.demographic.language_options.Czech': 'Czech',
    'app.demographic.language_options.Chinese': 'Chinese',
    'app.demographic.language_options.Croatian': 'Croatian',
    'app.demographic.language_options.Danish': 'Danish',
    'app.demographic.language_options.Dari': 'Dari',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Dutch',
    'app.demographic.language_options.English': 'English',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonian',
    'app.demographic.language_options.Faroese': 'Faroese',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finnish',
    'app.demographic.language_options.French': 'French',
    'app.demographic.language_options.Gaelic': 'Gaelic',
    'app.demographic.language_options.Galician': 'Galician',
    'app.demographic.language_options.German': 'German',
    'app.demographic.language_options.Greek': 'Greek',
    'app.demographic.language_options.Hebrew': 'Hebrew',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Hungarian',
    'app.demographic.language_options.Icelandic': 'Icelandic',
    'app.demographic.language_options.Indonesian': 'Indonesian',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Eskimo)',
    'app.demographic.language_options.Italian': 'Italian',
    'app.demographic.language_options.Japanese': 'Japanese',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Korean',
    'app.demographic.language_options.Kurdish': 'Kurdish',
    'app.demographic.language_options.Laotian': 'Laotian',
    'app.demographic.language_options.Latvian': 'Latvian',
    'app.demographic.language_options.Lappish': 'Lappish',
    'app.demographic.language_options.Lithuanian': 'Lithuanian',
    'app.demographic.language_options.Macedonian': 'Macedonian',
    'app.demographic.language_options.Malay': 'Malay',
    'app.demographic.language_options.Maltese': 'Maltese',
    'app.demographic.language_options.Nepali': 'Nepali',
    'app.demographic.language_options.Norwegian': 'Norwegian',
    'app.demographic.language_options.Pashto': 'Pashto',
    'app.demographic.language_options.Polish': 'Polish',
    'app.demographic.language_options.Portuguese': 'Portuguese',
    'app.demographic.language_options.Romanian': 'Romanian',
    'app.demographic.language_options.Russian': 'Russian',
    'app.demographic.language_options.Scots': 'Scots',
    'app.demographic.language_options.Serbian': 'Serbian',
    'app.demographic.language_options.Slovak': 'Slovak',
    'app.demographic.language_options.Slovenian': 'Slovenian',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Spanish',
    'app.demographic.language_options.Swedish': 'Swedish',
    'app.demographic.language_options.Swahili': 'Swahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalog-Filipino',
    'app.demographic.language_options.Tajik': 'Tajik',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Thai',
    'app.demographic.language_options.Tibetan': 'Tibetan',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tongan',
    'app.demographic.language_options.Turkish': 'Turkish',
    'app.demographic.language_options.Turkmen': 'Turkmen',
    'app.demographic.language_options.Ucrainian': 'Ucrainian',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Uzbek',
    'app.demographic.language_options.Vietnamese': 'Vietnamese',
    'app.demographic.language_options.Welsh': 'Welsh',
    'app.demographic.language_options.Other': 'Other',
    'app.demographic.gender.male': '男性',
    'app.demographic.gender.female': '女性',
    'app.demographic.gender.self_identify': '自己敘述',
    'app.demographic.marital.response_single': '單身',
    'app.demographic.marital.response_married': '已婚',
    'app.demographic.marital.response_divorced': '離婚',
    'app.demographic.marital.response_widowed': '喪偶',
    'app.demographic.marital.response_other': '其他',
    'app.demographic.education_years.response_1': '沒有受過正規教育',
    'app.demographic.education_years.response_2': '1 年',
    'app.demographic.education_years.response_3': '2 年',
    'app.demographic.education_years.response_4': '3 年',
    'app.demographic.education_years.response_5': '4 年',
    'app.demographic.education_years.response_6': '5 年',
    'app.demographic.education_years.response_7': '6 年',
    'app.demographic.education_years.response_8': '7 年',
    'app.demographic.education_years.response_9': '8 年',
    'app.demographic.education_years.response_10': '9 年',
    'app.demographic.education_years.response_11': '10 年',
    'app.demographic.education_years.response_12': '11 年',
    'app.demographic.education_years.response_13': '12 年',
    'app.demographic.education_years.response_14': '13 年',
    'app.demographic.education_years.response_15': '14 年',
    'app.demographic.education_years.response_16': '15 年',
    'app.demographic.education_years.response_17': '16 年',
    'app.demographic.education_years.response_18': '17 年',
    'app.demographic.education_years.response_19': '18 年',
    'app.demographic.education_years.response_20': '19 年',
    'app.demographic.education_years.response_21': '20 年',
    'app.demographic.education_years.response_22': '21 年',
    'app.demographic.education_years.response_23': '22 年',
    'app.demographic.education_years.response_24': '23 年',
    'app.demographic.education_years.response_25': '24 年',
    'app.demographic.education_years.response_26': '25 年',
    'app.demographic.education_years.response_27': '26 年',
    'app.demographic.education_years.response_28': '27 年',
    'app.demographic.education_years.response_29': '28 年',
    'app.demographic.education_years.response_30': '29 年',
    'app.demographic.education_years.response_31': '30 年',
    'app.demographic.education_years.response_32': '31年以上',
    'app.demographic.ethnic.respose_asian': '亞洲人',
    'app.demographic.ethnic.respose_african': '黑人或非洲人',
    'app.demographic.ethnic.respose_latino': '西班牙後裔或拉丁美洲人後裔',
    'app.demographic.ethnic.respose_indigenous': '原住民或土著',
    'app.demographic.ethnic.respose_middle_eastern': '中東',
    'app.demographic.ethnic.respose_white': '白人',
    'app.demographic.ethnic.respose_mixed': '混血',
    'app.demographic.ethnic.respose_other': '其他',
    'app.demographic.completing_bevi.response_before': '國際性或跨文化體驗學習開始前',
    'app.demographic.completing_bevi.response_during': '國際性或跨文化體驗學習中',
    'app.demographic.completing_bevi.response_after': '國際性或跨文化體驗學習後',
    'app.demographic.completing_bevi.response_during_academic': '還處課程項目的說明階段，或上述選項以外的情況',
    'app.demographic.completing_bevi.response_end_graduation': '大學本科畢業時',
    'app.demographic.completing_bevi.response_other': '其他：請具體填寫',
    'app.demographic.primary_area_of_work.response_1': '藝術、傳媒、文學',
    'app.demographic.primary_area_of_work.response_2': '心理、社會、法律',
    'app.demographic.primary_area_of_work.response_3': '商學或商科',
    'app.demographic.primary_area_of_work.response_4': '教育',
    'app.demographic.primary_area_of_work.response_5': '醫療衛生、公共管理或公共服務',
    'app.demographic.primary_area_of_work.response_6': '物理、化學、工學、資訊',
    'app.demographic.primary_area_of_work.response_7': '其他',
    'app.demographic.political_orientation.response_1': '守舊的或傳統的（例如，贊成維持現有的法律、制度、安逸現狀）',
    'app.demographic.political_orientation.response_2': '更守舊',
    'app.demographic.political_orientation.response_3': '中立：既不守舊也不開明',
    'app.demographic.political_orientation.response_4': '更開明',
    'app.demographic.political_orientation.response_5': '變革的或開明的（例如，贊成變更、改革現有的法律、制度、努力改變現狀）',
    'app.demographic.political_orientation.response_6': '其他',
    'app.demographic.religion.response_1': '起源於非洲的傳統宗教',
    'app.demographic.religion.response_2': '不可知論',
    'app.demographic.religion.response_3': '無神論',
    'app.demographic.religion.response_4': '巴哈伊教',
    'app.demographic.religion.response_5': '佛教',
    'app.demographic.religion.response_6': '高臺教',
    'app.demographic.religion.response_7': '起源於中國的傳統宗教',
    'app.demographic.religion.response_8': '基督教',
    'app.demographic.religion.response_9': '印度教',
    'app.demographic.religion.response_10': '起源於原住民的傳統宗教',
    'app.demographic.religion.response_11': '伊斯蘭教',
    'app.demographic.religion.response_12': '耆那教',
    'app.demographic.religion.response_13': '朝鮮的主體思想（金日成主義）',
    'app.demographic.religion.response_14': '猶太教',
    'app.demographic.religion.response_15': '自然崇拜、多神教',
    'app.demographic.religion.response_16': '神道教',
    'app.demographic.religion.response_17': '錫克教',
    'app.demographic.religion.response_18': '唯心教、唯靈論、招魂術',
    'app.demographic.religion.response_19': '有信仰但沒有教派',
    'app.demographic.religion.response_20': '天理教',
    'app.demographic.religion.response_21': '（一神）普救派',
    'app.demographic.religion.response_22': '瑣羅亞斯德教、祆教、火祆教、拜火教',
    'app.demographic.religion.response_other': '其他',
    'app.demographic.religion.no_response': '不想回答此問題',
    'app.demographic.income_level.response_1': '上層1/3',
    'app.demographic.income_level.response_2': '中層1/3',
    'app.demographic.income_level.response_3': '下層1/3',
    'app.demographic.academic_rank.response_1': '上層1/3',
    'app.demographic.academic_rank.response_2': '中層1/3',
    'app.demographic.academic_rank.response_3': '下層1/3',
    'app.demographic.participated_experiences.response_1': '1年級或新生研討會',
    'app.demographic.participated_experiences.response_2': '大學或學校舉辦的課外學習活動',
    'app.demographic.participated_experiences.response_3': '以報告或論文寫作為中心的課程',
    'app.demographic.participated_experiences.response_4': '教師或專家指導下的研究項目',
    'app.demographic.participated_experiences.response_5': '由大學或學校舉辦或認可的以社會貢獻為目的的體驗性學習（服務性學習）或以社區為對象的項目',
    'app.demographic.participated_experiences.response_6': '大學或學校舉辦或認可的實習',
    'app.demographic.participated_experiences.response_7': '頂石（Capstone） 項目或畢業項目',
    'app.demographic.participated_experiences.response_8': '必修科目',
    'app.demographic.participated_experiences.response_9': '與其他學生協作學習的課程或項目',
    'app.demographic.participated_experiences.response_10': '與多元文化、國際化教育相關的課程或留學',
    'app.demographic.participated_experiences.response_11': '有關您學習成果或經歷的電子學習歷程',
    'app.demographic.participated_experiences.response_12': '線上(同步或非同步)教育中，<b>包含</b>國際性、多元文化、跨文化的內容',
    'app.demographic.participated_experiences.response_13': '線上(同步或非同步)教育中，<b>不包含</b>國際性、多元文化、跨文化<b>的內容</b>',
    'app.demographic.participated_learning_courses.response_1': '以多元化或國際化問題為中心的課程',
    'app.demographic.participated_learning_courses.response_2': '專注於校園內多元文化或國際化方面的課外活動',
    'app.demographic.participated_learning_courses.response_3': '海外的以社會貢獻為目的的體驗性學習（服務性學習）',
    'app.demographic.participated_learning_courses.response_4': '海外實習',
    'app.demographic.participated_learning_courses.response_5': '海外留學',
    'app.demographic.participated_learning_courses.response_6': '居住在重視國際化、多元文化、全球化或是以語言學習為中心的教育型學生宿舍或社區',
    'app.demographic.participated_learning_courses.response_7': '學習母語以外的語言',
    'app.demographic.participated_learning_courses.response_8': '與來自國外或具有不同文化、民族背景的人或家人一起生活',
    'app.demographic.participated_learning_courses.response_9': '網路等的虛擬交流（VE：Virtual Exchange）或網上國際協作學習（COIL：Collaborative Online International Learning）、或重視國際化、多元文化或跨文化的其他同步或非同步的網路學習體驗',
    'app.demographic.plan_participate_experiences.response_1': '1年級或新生研討會',
    'app.demographic.plan_participate_experiences.response_2': '大學或學校舉辦的課外學習活動',
    'app.demographic.plan_participate_experiences.response_3': '以報告或論文寫作為中心的課程',
    'app.demographic.plan_participate_experiences.response_4': '教師或專家指導下的研究項目',
    'app.demographic.plan_participate_experiences.response_5': '由大學或學校舉辦或認可的以社會貢獻為目的的體驗性學習（服務性學習）或以社區為對象的項目',
    'app.demographic.plan_participate_experiences.response_6': '大學或學校舉辦或認可的實習',
    'app.demographic.plan_participate_experiences.response_7': '頂石（Capstone） 項目或畢業項目',
    'app.demographic.plan_participate_experiences.response_8': '必修科目',
    'app.demographic.plan_participate_experiences.response_9': '與其他學生協作學習的課程或項目',
    'app.demographic.plan_participate_experiences.response_10': '與多元文化、國際化教育相關的課程或留學',
    'app.demographic.plan_participate_experiences.response_11': '有關您學習成果或經歷的電子學習歷程',
    'app.demographic.plan_participate_experiences.response_12': '線上(同步或非同步)教育中，<b>包含</b>國際性、多元文化、跨文化的內容',
    'app.demographic.plan_participate_experiences.response_13': '線上(同步或非同步)教育中，<b>不包含</b>國際性、多元文化、跨文化<b>的內容</b>',
    'app.demographic.plan_participate_learning_courses.response_1': '以多元化或國際化問題為中心的課程',
    'app.demographic.plan_participate_learning_courses.response_2': '專注於校園內多元文化或國際化方面的課外活動',
    'app.demographic.plan_participate_learning_courses.response_3': '海外的以社會貢獻為目的的體驗性學習（服務性學習）',
    'app.demographic.plan_participate_learning_courses.response_4': '海外實習',
    'app.demographic.plan_participate_learning_courses.response_5': '海外留學',
    'app.demographic.plan_participate_learning_courses.response_6': '居住在重視國際性、多元文化、全球化或是語言學習的教育學生宿舍或是社區',
    'app.demographic.plan_participate_learning_courses.response_7': '學習母語以外的語言',
    'app.demographic.plan_participate_learning_courses.response_8': '與來自國外或具有不同文化、民族背景的人或家人一起生活',
    'app.demographic.plan_participate_learning_courses.response_9': '網路等的虛擬交流（VE：Virtual Exchange）或網上國際協作學習（COIL：Collaborative Online International Learning）、或重視國際化、多元文化或跨文化的其他同步或非同步的網路學習體驗',
    'app.demographic.time_in_other_country.response_not_spent': '未滿1週',
    'app.demographic.time_in_other_country.response_less_2_weeks': '1週～未滿2週',
    'app.demographic.time_in_other_country.response_less_1_month': '2週～未滿1個月',
    'app.demographic.time_in_other_country.response_less_3_months': '1個月～未滿3個月',
    'app.demographic.time_in_other_country.response_less_6_months': '3個月～未滿6個月',
    'app.demographic.time_in_other_country.response_less_1_year': '6個月～未滿1年',
    'app.demographic.time_in_other_country.response_less_2_years': '1年～未滿2年',
    'app.demographic.time_in_other_country.response_less_5_years': '2年～未滿5年',
    'app.demographic.time_in_other_country.response_less_10_years': '5年～未滿10年',
    'app.demographic.time_in_other_country.response_10_or_more': '10年或10年以上',
    'app.demographic.country.response_eua': 'United States of America',
    'app.demographic.country.response_afganistan': 'Afghanistan',
    'app.demographic.country.response_albania': 'Albania',
    'app.demographic.country.response_algeria': 'Algeria',
    'app.demographic.country.response_american_samoa': 'American Samoa',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armenia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australia',
    'app.demographic.country.response_austria': 'Austria',
    'app.demographic.country.response_azerbaijan': 'Azerbaijan',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarus',
    'app.demographic.country.response_belgium': 'Belgium',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benin',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolivia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnia & Herzegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brazil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgaria',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodia',
    'app.demographic.country.response_cameroon': 'Cameroon',
    'app.demographic.country.response_canada': 'Canada',
    'app.demographic.country.response_cape_verde': 'Cape Verde',
    'app.demographic.country.response_cayman_islands': 'Cayman Islands',
    'app.demographic.country.response_central_african_republic': 'Central African Republic',
    'app.demographic.country.response_chad': 'Chad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colombia',
    'app.demographic.country.response_comoros': 'Comoros',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'Congo, Democratic Republic',
    'app.demographic.country.response_cook_islands': 'Cook Islands',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Cote DIvoire',
    'app.demographic.country.response_croatia': 'Croatia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Cyprus',
    'app.demographic.country.response_czech_republic': 'Czech Republic',
    'app.demographic.country.response_denmark': 'Denmark',
    'app.demographic.country.response_djibouti': 'Djibouti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'Dominican Republic',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Egypt',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Equatorial Guinea',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estonia',
    'app.demographic.country.response_ethiopia': 'Ethiopia',
    'app.demographic.country.response_euro_area': 'Euro Area',
    'app.demographic.country.response_falkland_islands': 'Falkland Islands',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finland',
    'app.demographic.country.response_france': 'France',
    'app.demographic.country.response_french_guiana': 'French Guiana',
    'app.demographic.country.response_french_polynesia': 'French Polynesia',
    'app.demographic.country.response_gabon': 'Gabon',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgia',
    'app.demographic.country.response_germany': 'Germany',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Greece',
    'app.demographic.country.response_grenada': 'Grenada',
    'app.demographic.country.response_guadeloupe': 'Guadeloupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haiti',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Hungary',
    'app.demographic.country.response_iceland': 'Iceland',
    'app.demographic.country.response_india': 'India',
    'app.demographic.country.response_indonesia': 'Indonesia',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Ireland',
    'app.demographic.country.response_isle_of_man': 'Isle of Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italy',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': 'Japan',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordan',
    'app.demographic.country.response_kazakhstan': 'Kazakhstan',
    'app.demographic.country.response_kenya': 'Kenya',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kyrgyzstan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Latvia',
    'app.demographic.country.response_lebanon': 'Lebanon',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libya',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Lithuania',
    'app.demographic.country.response_luxembourg': 'Luxembourg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Macedonia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malaysia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Maldives',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Mariana',
    'app.demographic.country.response_marshall_islands': 'Marshall Islands',
    'app.demographic.country.response_martinique': 'Martinique',
    'app.demographic.country.response_mauritania': 'Mauritania',
    'app.demographic.country.response_mauritius': 'Mauritius',
    'app.demographic.country.response_mexico': 'Mexico',
    'app.demographic.country.response_micronesia': 'Micronesia',
    'app.demographic.country.response_moldova': 'Moldova',
    'app.demographic.country.response_monaco': 'Monaco',
    'app.demographic.country.response_mongolia': 'Mongolia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Morocco',
    'app.demographic.country.response_mozambique': 'Mozambique',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Netherland Antilles',
    'app.demographic.country.response_netherlands': 'Netherlands',
    'app.demographic.country.response_new_caledonia': 'New Caledonia',
    'app.demographic.country.response_new_zealand': 'New Zealand',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'North Korea',
    'app.demographic.country.response_norway': 'Norway',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panama',
    'app.demographic.country.response_papua_new_guinea': 'Papua New Guinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Peru',
    'app.demographic.country.response_phillipines': 'Philippines',
    'app.demographic.country.response_poland': 'Poland',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Reunion',
    'app.demographic.country.response_romania': 'Romania',
    'app.demographic.country.response_russian_federation': 'Russian Federation',
    'app.demographic.country.response_rwanda': 'Rwanda',
    'app.demographic.country.response_st_helena': 'St Helena',
    'app.demographic.country.response_st_kitts-nevis': 'St Kitts-Nevis',
    'app.demographic.country.response_st_lucia': 'St Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'St Vincent & Grenadines',
    'app.demographic.country.response_stateless': 'Stateless',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'Sao Tome & Principe',
    'app.demographic.country.response_saudi_arabia': 'Saudi Arabia',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Serbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapore',
    'app.demographic.country.response_slovak_republic': 'Slovak Republic',
    'app.demographic.country.response_slovenia': 'Slovenia',
    'app.demographic.country.response_solomon_islands': 'Solomon Islands',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'South Africa',
    'app.demographic.country.response_south_korea': 'South Korea',
    'app.demographic.country.response_spain': 'Spain',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Suriname',
    'app.demographic.country.response_swaziland': 'Swaziland',
    'app.demographic.country.response_sweden': 'Sweden',
    'app.demographic.country.response_switzerland': 'Switzerland',
    'app.demographic.country.response_syria': 'Syria',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tajikistan',
    'app.demographic.country.response_tanzania': 'Tanzania',
    'app.demographic.country.response_thailand': 'Thailand',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor-Leste',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad & Tobago',
    'app.demographic.country.response_tunisia': 'Tunisia',
    'app.demographic.country.response_turkey': 'Turkey',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Turks & Caicos Is',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ukraine',
    'app.demographic.country.response_united_arab_erimates': 'United Arab Erimates',
    'app.demographic.country.response_united_kingdom': 'United Kingdom',
    'app.demographic.country.response_uraguay': 'Uraguay',
    'app.demographic.country.response_uzbekistan': 'Uzbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Virgin Islands',
    'app.demographic.country.response_west_bank_and_gaza': 'West Bank and Gaza',
    'app.demographic.country.response_yemen': 'Yemen',
    'app.demographic.country.response_zambia': 'Zambia',
    'app.demographic.country.response_zimbabwe': 'Zimbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak': '除了自己的母語，我在會話，閱讀或寫作上不使用其他語言。',
    'app.demographic.level_of_fluency.minimally_fluent': '會說最低限度',
    'app.demographic.level_of_fluency.somewhat_fluent': '會說一些',
    'app.demographic.level_of_fluency.moderately_fluent': '中等水平',
    'app.demographic.level_of_fluency.mostly_fluent': '大部分流利',
    'app.demographic.level_of_fluency.fluent': '流利',
    'app.demographic.level_of_interest.not_applicable': '沒有合適選項',
    'app.demographic.level_of_interest.extremely_low': '極其低',
    'app.demographic.level_of_interest.very_low': '很低',
    'app.demographic.level_of_interest.low': '低',
    'app.demographic.level_of_interest.neutral': '不低也不高',
    'app.demographic.level_of_interest.high': '高',
    'app.demographic.level_of_interest.very_high': '很高',
    'app.demographic.level_of_interest.extremely_high': '極其高',
    'app.demographic_slider_default_option': '請滑動游標回答',
    'app.demographic.formal_education.some_high_school_or_less': '中學畢業或高中輟學',
    'app.demographic.formal_education.high_school_graduate': '高中畢業',
    'app.demographic.formal_education.some_college': '大學輟學',
    'app.demographic.formal_education.college_degree': '大學畢業或學士學位 ',
    'app.demographic.formal_education.some_graduate_school': '研究生輟學',
    'app.demographic.formal_education.graduate_degree': '研究生畢業或碩士學位',
    'app.demographic.formal_education.professional_degree': '專業碩士學位（例如：律師）',
    'app.demographic.formal_education.doctoral_degree': '博士學位',
}

export const demographicQuestionsZHCN: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
