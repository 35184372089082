import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': '年龄',
    'app.demographic.gender': '性別',
    'app.demographic.marital_status': '婚姻状况',
    'app.demographic.level_of_fluency': '＝＝＝Not in the Demographics请输入您除母语以外的语言的流利程度。这里的母语指的是您在自己家或社区、或上过的小学使用的语言。',
    'app.demographic.education_years': '选择您到现在为止已完成正规教育的年数。',
    'app.demographic.raised_in_country_where_currently_in': '您是否主要是在您目前居住、工作、或学习的国家长大？',
    'app.demographic.ethnic': '民族背景。',
    'app.demographic.ethnic_other': '如果可以的话，请将您的民族背景具体填写在下栏。',
    'app.demographic.ethnic_asian': '亚洲人：来自中国或港澳、台湾、韩国、日本、菲律宾、泰国、越南、柬埔寨、马来西亚、印度、巴基斯坦等，远东、东南亚或印度次大陆的国家或地区 ',
    'app.demographic.ethnic_african': '黑人或非洲人：来自非洲黑人种族之一',
    'app.demographic.ethnic_latino': '西班牙后裔或拉丁美洲人后裔：祖籍古巴、墨西哥、波多黎各、南非、中非、以及其他受西班牙、葡萄牙文化影响的国家或地区',
    'app.demographic.ethnic_indigenous': '原住民或土著：来自任何一个国家或地区的原住民或土著，或维持着与原住民或社区的关系或归属 ',
    'app.demographic.ethnic_middle_eastern': '中东：来自埃及、伊朗、伊拉克、以色列、约旦、黎巴嫩、沙特阿拉伯、苏丹、叙利亚、土耳其、包括阿拉伯半岛的国家、或从阿富汗到利比亚的国家或地区',
    'app.demographic.ethnic_white': '白人：来自欧洲的任何一个种族群体',
    'app.demographic.completing_bevi': '＝＝＝Not in the Demographics输入在哪个阶段想接受BEVI。Please indicate when you are completing the BEVI.',
    'app.demographic.completing_bevi_other': '＝＝＝Not in the Demographics输入在哪种情况下想接受BEVI。Please describe the circumstances under which you are completing the BEVI below.',
    'app.demographic.previously_completed': '＝＝＝Not in the Demographics以前是否接触过BEVIHave you previously completed the BEVI?',
    'app.demographic.disadvantaged_or_minority_group': '您认为您目前身处弱势群体或少数群体吗？',
    'app.demographic.disadvantaged_or_minority_group_yes': '如果可以的话，请将其详情填写在下栏。',
    'app.demographic.primary_area_of_work': '请从下拉菜单中选出最接近您的专业、职业的领域，或职业兴趣的一项。',
    'app.demographic.current_area': '请在下栏填写您现在的本科学院，专业或职业。',
    'app.demographic.political_orientation': '从“守旧的或传统的（例如，赞成维持现有的法律、制度、安逸现状）”到“变革的或开明的（例如，赞成变更、改革现有的法律、制度、努力改变现状）”，请用以下滑杆标示您现在的政治倾向。',
    'app.demographic.political_orientation_conservative_label': '守旧的或传统的',
    'app.demographic.political_orientation_liberal_label': '变革的或开明的',
    'app.demographic.religion': '请从下拉菜单中选出最接近您现在信仰的宗教或神灵的一项。',
    'app.demographic.religion_other': '如果可以的话，请将其详情填写在下栏。',
    'app.demographic.religion_scale': '您现在参与宗教信仰或神灵活动的程度。',
    'app.demographic.religion_scale_specified': '您对{0}的参与程度。',
    'app.demographic.income_level': '从“最低”到“最高”，请用以下滑杆标示您或家人的相对收入，或相对资产（在您的国家或地区，与他人相比）。',
    'app.demographic.income_less_label': '最低',
    'app.demographic.income_more_label': '最高',
    'app.demographic.academic_rank_lowest_label': '最低',
    'app.demographic.academic_rank_highest_label': '最高',
    'app.demographic.academic_rank': '和您最近上的大学或学校的同班同学相比，从“最低”到“最高”，请用以下滑杆标示出您学习状态或成绩排名最好时的位置。',
    'app.demographic.primary_country': '您的出生国。请输入其英文单词的前部分搜索。',
    'app.demographic.time_in_other_countries': '您在出生国以外的国家或地区度过的时间。',
    'app.demographic.time_in_other_countries_other': '请指定',
    'app.demographic.visit_countries': '除了您的出生国，您去过或在多少个国家或地区居住过？(请输入半角数字)? ',
    'app.demographic.country_mother_born': '您母亲的出身国。请输入其英文单词的前部分。',
    'app.demographic.country_father_born': '您父亲的出身国。请输入其英文单词的前部分。',
    'app.demographic.personal_interest': '您对国际性，多元文化，或跨文化体验的<b>个人关心程度</b>（例如：海外留学、海外就职；网络等的虚拟交流（VE：Virtual Exchange）、网上国际协作学习（COIL：Collaborative Online International Learning）；关于多元文化的课程项目；参加跨国团队等）',
    'app.demographic.participated_experiences': '您是否参加过，或现在正在参加以下课程项目？（请选择所有符合的选项）',
    'app.demographic.participated_learning_courses': '您是否参加过，或现在正在参加以下与跨文化，或多样化相关的课程项目？（请选择所有符合的选项）',
    'app.demographic.plan_participate_experiences': '您是否打算参加以下课程项目？（请选择所有符合的选项）',
    'app.demographic.plan_participate_learning_courses': '您是否打算参加以下与跨文化，或多样性相关的课程项目？（请选择所有符合的选项）',
    'app.demographic.experience_satisfaction': '您对参加过，或现在正在参加的国际性，多元文化，或跨文化体验的<b>整体满意度</b>（例如：海外留学、海外就职；网络等的虚拟交流（VE：Virtual Exchange）、网上国际协作学习（COIL：Collaborative Online International Learning）；关于多元文化的课程项目；参加跨国团队等）',
    'app.demographic.languages_speak': '您使用几种语言作为母语？您会说吗？这里的母语指的是您在家或社区、或是上过的小学使用的语言。',
    'app.demographic.years_studied_foreign_language': '目前为止，您学了几年外语(请输入半角数字)。',
    'app.demographic.primary_language': '您的母语（第一语言、主要语言）是什么语言？若您有多种母语，可选择多种语言。请从下拉菜单选择语言或输入其英文单词的前部分。',
    'app.demographic.primary_language_other': '除了自己的母语以外,您有过使用其他语言的经历吗（例如，课程作业，会话，阅读等）? ',
    'app.demographic.language_level_of_fluency': '除了自己的母语以外，若您在会话，阅读或写作上使用其他语言，请在下方选择您使用的非母语的语言及其流利程度。选择语言时，请输入其英文单词的前部分。',
    'app.demographic.language_level_of_fluency_add_anwser': '添加',
    'app.demographic.table_level_of_fluency': '流利程度',
    'app.demographic.table_language': '语言',
    'app.demographic.table_action': '操作',
    'app.demographic.remove_language_tooltip': '删除这一栏',
    'app.demographic.mother_formal_education': '您母亲的最高学历。',
    'app.demographic.father_formal_education': '您父亲的最高学历。。',
    'app.demographic.political_commitment': '您个人对政治的参与程度',
    'app.demographic.comparative': '個人比較報告',
    'app.demographic.compare': 'Compare',
    'app.demographic.select_multiple_reports': '選擇多份報告',
    'app.demographic.country_explanation_field': 'To find your country, you may either select the country from the dropdown menu or simply type in and select the language.',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'Afrikaans',
    'app.demographic.language_options.Albanian': 'Albanian',
    'app.demographic.language_options.Amharic': 'Amharic',
    'app.demographic.language_options.Arabic': 'Arabic',
    'app.demographic.language_options.Armenian': 'Armenian',
    'app.demographic.language_options.Basque': 'Basque',
    'app.demographic.language_options.Bengali': 'Bengali',
    'app.demographic.language_options.Byelorussian': 'Byelorussian',
    'app.demographic.language_options.Burmese': 'Burmese',
    'app.demographic.language_options.Bulgarian': 'Bulgarian',
    'app.demographic.language_options.Catalan': 'Catalan',
    'app.demographic.language_options.Czech': 'Czech',
    'app.demographic.language_options.Chinese': 'Chinese',
    'app.demographic.language_options.Croatian': 'Croatian',
    'app.demographic.language_options.Danish': 'Danish',
    'app.demographic.language_options.Dari': 'Dari',
    'app.demographic.language_options.Dzongkha': 'Dzongkha',
    'app.demographic.language_options.Dutch': 'Dutch',
    'app.demographic.language_options.English': 'English',
    'app.demographic.language_options.Esperanto': 'Esperanto',
    'app.demographic.language_options.Estonian': 'Estonian',
    'app.demographic.language_options.Faroese': 'Faroese',
    'app.demographic.language_options.Farsi': 'Farsi',
    'app.demographic.language_options.Finnish': 'Finnish',
    'app.demographic.language_options.French': 'French',
    'app.demographic.language_options.Gaelic': 'Gaelic',
    'app.demographic.language_options.Galician': 'Galician',
    'app.demographic.language_options.German': 'German',
    'app.demographic.language_options.Greek': 'Greek',
    'app.demographic.language_options.Hebrew': 'Hebrew',
    'app.demographic.language_options.Hindi': 'Hindi',
    'app.demographic.language_options.Hungarian': 'Hungarian',
    'app.demographic.language_options.Icelandic': 'Icelandic',
    'app.demographic.language_options.Indonesian': 'Indonesian',
    'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Eskimo)',
    'app.demographic.language_options.Italian': 'Italian',
    'app.demographic.language_options.Japanese': 'Japanese',
    'app.demographic.language_options.Khmer': 'Khmer',
    'app.demographic.language_options.Korean': 'Korean',
    'app.demographic.language_options.Kurdish': 'Kurdish',
    'app.demographic.language_options.Laotian': 'Laotian',
    'app.demographic.language_options.Latvian': 'Latvian',
    'app.demographic.language_options.Lappish': 'Lappish',
    'app.demographic.language_options.Lithuanian': 'Lithuanian',
    'app.demographic.language_options.Macedonian': 'Macedonian',
    'app.demographic.language_options.Malay': 'Malay',
    'app.demographic.language_options.Maltese': 'Maltese',
    'app.demographic.language_options.Nepali': 'Nepali',
    'app.demographic.language_options.Norwegian': 'Norwegian',
    'app.demographic.language_options.Pashto': 'Pashto',
    'app.demographic.language_options.Polish': 'Polish',
    'app.demographic.language_options.Portuguese': 'Portuguese',
    'app.demographic.language_options.Romanian': 'Romanian',
    'app.demographic.language_options.Russian': 'Russian',
    'app.demographic.language_options.Scots': 'Scots',
    'app.demographic.language_options.Serbian': 'Serbian',
    'app.demographic.language_options.Slovak': 'Slovak',
    'app.demographic.language_options.Slovenian': 'Slovenian',
    'app.demographic.language_options.Somali': 'Somali',
    'app.demographic.language_options.Spanish': 'Spanish',
    'app.demographic.language_options.Swedish': 'Swedish',
    'app.demographic.language_options.Swahili': 'Swahili',
    'app.demographic.language_options.Tagalog-Filipino': 'Tagalog-Filipino',
    'app.demographic.language_options.Tajik': 'Tajik',
    'app.demographic.language_options.Tamil': 'Tamil',
    'app.demographic.language_options.Thai': 'Thai',
    'app.demographic.language_options.Tibetan': 'Tibetan',
    'app.demographic.language_options.Tigrinya': 'Tigrinya',
    'app.demographic.language_options.Tongan': 'Tongan',
    'app.demographic.language_options.Turkish': 'Turkish',
    'app.demographic.language_options.Turkmen': 'Turkmen',
    'app.demographic.language_options.Ucrainian': 'Ucrainian',
    'app.demographic.language_options.Urdu': 'Urdu',
    'app.demographic.language_options.Uzbek': 'Uzbek',
    'app.demographic.language_options.Vietnamese': 'Vietnamese',
    'app.demographic.language_options.Welsh': 'Welsh',
    'app.demographic.language_options.Other': 'Other',
    'app.demographic.gender.male': '男性',
    'app.demographic.gender.female': '女性',
    'app.demographic.gender.self_identify': '自己叙述',
    'app.demographic.marital.response_single': '单身',
    'app.demographic.marital.response_married': '已婚',
    'app.demographic.marital.response_divorced': '离婚',
    'app.demographic.marital.response_widowed': '丧偶',
    'app.demographic.marital.response_other': '其他',
    'app.demographic.education_years.response_1': '没有受过正规教育',
    'app.demographic.education_years.response_2': '1 年',
    'app.demographic.education_years.response_3': '2 年',
    'app.demographic.education_years.response_4': '3 年',
    'app.demographic.education_years.response_5': '4 年',
    'app.demographic.education_years.response_6': '5 年',
    'app.demographic.education_years.response_7': '6 年',
    'app.demographic.education_years.response_8': '7 年',
    'app.demographic.education_years.response_9': '8 年',
    'app.demographic.education_years.response_10': '9 年',
    'app.demographic.education_years.response_11': '10 年',
    'app.demographic.education_years.response_12': '11 年',
    'app.demographic.education_years.response_13': '12 年',
    'app.demographic.education_years.response_14': '13 年',
    'app.demographic.education_years.response_15': '14 年',
    'app.demographic.education_years.response_16': '15 年',
    'app.demographic.education_years.response_17': '16 年',
    'app.demographic.education_years.response_18': '17 年',
    'app.demographic.education_years.response_19': '18 年',
    'app.demographic.education_years.response_20': '19 年',
    'app.demographic.education_years.response_21': '20 年',
    'app.demographic.education_years.response_22': '21 年',
    'app.demographic.education_years.response_23': '22 年',
    'app.demographic.education_years.response_24': '23 年',
    'app.demographic.education_years.response_25': '24 年',
    'app.demographic.education_years.response_26': '25 年',
    'app.demographic.education_years.response_27': '26 年',
    'app.demographic.education_years.response_28': '27 年',
    'app.demographic.education_years.response_29': '28 年',
    'app.demographic.education_years.response_30': '29 年',
    'app.demographic.education_years.response_31': '30 年',
    'app.demographic.education_years.response_32': '31年以上',
    'app.demographic.ethnic.respose_asian': '亚洲人',
    'app.demographic.ethnic.respose_african': '黑人或非洲人',
    'app.demographic.ethnic.respose_latino': '西班牙后裔或拉丁美洲人后裔',
    'app.demographic.ethnic.respose_indigenous': '原住民或土著',
    'app.demographic.ethnic.respose_middle_eastern': '中东',
    'app.demographic.ethnic.respose_white': '白人',
    'app.demographic.ethnic.respose_mixed': '混血',
    'app.demographic.ethnic.respose_other': '其他',
    'app.demographic.completing_bevi.response_before': '国际性或跨文化体验学习开始前',
    'app.demographic.completing_bevi.response_during': '国际性或跨文化体验学习中',
    'app.demographic.completing_bevi.response_after': '国际性或跨文化体验学习后',
    'app.demographic.completing_bevi.response_during_academic': '还处课程项目的说明阶段，或上述选项以外的情况',
    'app.demographic.completing_bevi.response_end_graduation': '大学本科毕业时',
    'app.demographic.completing_bevi.response_other': '其他：请具体填写',
    'app.demographic.primary_area_of_work.response_1': '艺术、传媒、文学',
    'app.demographic.primary_area_of_work.response_2': '心理、社会、法律',
    'app.demographic.primary_area_of_work.response_3': '商学或商科',
    'app.demographic.primary_area_of_work.response_4': '教育',
    'app.demographic.primary_area_of_work.response_5': '医疗卫生、公共管理或公共服务',
    'app.demographic.primary_area_of_work.response_6': '物理、化学、工学、信息',
    'app.demographic.primary_area_of_work.response_7': '其他',
    'app.demographic.political_orientation.response_1': '守旧的或传统的（例如，赞成维持现有的法律、制度、安逸现状）',
    'app.demographic.political_orientation.response_2': '更守旧',
    'app.demographic.political_orientation.response_3': '中立：既不守旧也不开明',
    'app.demographic.political_orientation.response_4': '更开明',
    'app.demographic.political_orientation.response_5': '变革的或开明的（例如，赞成变更、改革现有的法律、制度、努力改变现状）',
    'app.demographic.political_orientation.response_6': '其他',
    'app.demographic.religion.response_1': '起源于非洲的传统宗教',
    'app.demographic.religion.response_2': '不可知论',
    'app.demographic.religion.response_3': '无神论',
    'app.demographic.religion.response_4': '巴哈伊教',
    'app.demographic.religion.response_5': '佛教',
    'app.demographic.religion.response_6': '高台教',
    'app.demographic.religion.response_7': '起源于中国的传统宗教',
    'app.demographic.religion.response_8': '基督教',
    'app.demographic.religion.response_9': '印度教',
    'app.demographic.religion.response_10': '起源于原住民的传统宗教',
    'app.demographic.religion.response_11': '伊斯兰教',
    'app.demographic.religion.response_12': '耆那教',
    'app.demographic.religion.response_13': '朝鲜的主体思想（金日成主义）',
    'app.demographic.religion.response_14': '犹太教',
    'app.demographic.religion.response_15': '自然崇拜、多神教',
    'app.demographic.religion.response_16': '神道教',
    'app.demographic.religion.response_17': '锡克教',
    'app.demographic.religion.response_18': '唯心教、唯灵论、招魂术',
    'app.demographic.religion.response_19': '有信仰但没有教派',
    'app.demographic.religion.response_20': '天理教',
    'app.demographic.religion.response_21': '（一神）普救派',
    'app.demographic.religion.response_22': '琐罗亚斯德教、祆教、火祆教、拜火教',
    'app.demographic.religion.response_other': '其他',
    'app.demographic.religion.no_response': '不想回答此问题',
    'app.demographic.income_level.response_1': '上层1/3',
    'app.demographic.income_level.response_2': '中层1/3',
    'app.demographic.income_level.response_3': '下层1/3',
    'app.demographic.academic_rank.response_1': '上层1/3',
    'app.demographic.academic_rank.response_2': '中层1/3',
    'app.demographic.academic_rank.response_3': '下层1/3',
    'app.demographic.participated_experiences.response_1': '1年级或新生研讨会',
    'app.demographic.participated_experiences.response_2': '大学或学校举办的课外学习活动',
    'app.demographic.participated_experiences.response_3': '以报告或论文写作为中心的课程',
    'app.demographic.participated_experiences.response_4': '教师或专家指导下的研究项目',
    'app.demographic.participated_experiences.response_5': '由大学或学校举办或认可的以社会贡献为目的的体验性学习（服务性学习）或以社区为对象的项目',
    'app.demographic.participated_experiences.response_6': '大学或学校举办或认可的实习',
    'app.demographic.participated_experiences.response_7': '顶石（Capstone） 项目或毕业项目',
    'app.demographic.participated_experiences.response_8': '必修科目',
    'app.demographic.participated_experiences.response_9': '与其他学生协作学习的课程或项目',
    'app.demographic.participated_experiences.response_10': '与多元文化、国际化教育相关的课程或留学',
    'app.demographic.participated_experiences.response_11': '有关您学习成果或经历的电子档案袋',
    'app.demographic.participated_experiences.response_12': '在线(同步或异步)教育中，<b>包含</b>国际性、多元文化、跨文化的内容',
    'app.demographic.participated_experiences.response_13': '在线(同步或异步)教育中，<b>不包含</b>国际性、多元文化、跨文化<b>的内容</b>',
    'app.demographic.participated_learning_courses.response_1': '以多元化或国际化问题为中心的课程',
    'app.demographic.participated_learning_courses.response_2': '专注于校园内多元文化或国际化方面的课外活动',
    'app.demographic.participated_learning_courses.response_3': '海外的以社会贡献为目的的体验性学习（服务性学习）',
    'app.demographic.participated_learning_courses.response_4': '海外实习',
    'app.demographic.participated_learning_courses.response_5': '海外留学',
    'app.demographic.participated_learning_courses.response_6': '居住在重视国际化、多元文化、全球化或是以语言学习为中心的教育型学生宿舍或社区',
    'app.demographic.participated_learning_courses.response_7': '学习母语以外的语言',
    'app.demographic.participated_learning_courses.response_8': '与来自国外或具有不同文化、民族背景的人或家人一起生活',
    'app.demographic.participated_learning_courses.response_9': '网络等的虚拟交流（VE：Virtual Exchange）或网上国际协作学习（COIL：Collaborative Online International Learning）、或重视国际化、多元文化或跨文化的其他同步或异步的网络学习体验',
    'app.demographic.plan_participate_experiences.response_1': '1年级或新生研讨会',
    'app.demographic.plan_participate_experiences.response_2': '大学或学校举办的课外学习活动',
    'app.demographic.plan_participate_experiences.response_3': '以报告或论文写作为中心的课程',
    'app.demographic.plan_participate_experiences.response_4': '教师或专家指导下的研究项目',
    'app.demographic.plan_participate_experiences.response_5': '由大学或学校举办或认可的以社会贡献为目的的体验性学习（服务性学习）或以社区为对象的项目',
    'app.demographic.plan_participate_experiences.response_6': '大学或学校举办或认可的实习',
    'app.demographic.plan_participate_experiences.response_7': '顶石（Capstone） 项目或毕业项目',
    'app.demographic.plan_participate_experiences.response_8': '必修科目',
    'app.demographic.plan_participate_experiences.response_9': '与其他学生协作学习的课程或项目',
    'app.demographic.plan_participate_experiences.response_10': '与多元文化、国际化教育相关的课程或留学',
    'app.demographic.plan_participate_experiences.response_11': '有关您学习成果或经历的电子档案袋',
    'app.demographic.plan_participate_experiences.response_12': '在线(同步或异步)教育中，<b>包含</b>国际性、多元文化、跨文化的内容',
    'app.demographic.plan_participate_experiences.response_13': '在线(同步或异步)教育中，<b>不包含</b>国际性、多元文化、跨文化<b>的内容</b>',
    'app.demographic.plan_participate_learning_courses.response_1': '以多元化或国际化问题为中心的课程',
    'app.demographic.plan_participate_learning_courses.response_2': '专注于校园内多元文化或国际化方面的课外活动',
    'app.demographic.plan_participate_learning_courses.response_3': '海外的以社会贡献为目的的体验性学习（服务性学习）',
    'app.demographic.plan_participate_learning_courses.response_4': '海外实习',
    'app.demographic.plan_participate_learning_courses.response_5': '海外留学',
    'app.demographic.plan_participate_learning_courses.response_6': '居住在重视国际性、多元文化、全球化或是语言学习的教育学生宿舍或是社区',
    'app.demographic.plan_participate_learning_courses.response_7': '学习母语以外的语言',
    'app.demographic.plan_participate_learning_courses.response_8': '与来自国外或具有不同文化、民族背景的人或家人一起生活',
    'app.demographic.plan_participate_learning_courses.response_9': '网络等的虚拟交流（VE：Virtual Exchange）或网上国际协作学习（COIL：Collaborative Online International Learning）、或重视国际化、多元文化或跨文化的其他同步或异步的网络学习体验',
    'app.demographic.time_in_other_country.response_not_spent': '未满1周',
    'app.demographic.time_in_other_country.response_less_2_weeks': '1周～未满2周',
    'app.demographic.time_in_other_country.response_less_1_month': '2周～未满1个月',
    'app.demographic.time_in_other_country.response_less_3_months': '1个月～未满3个月',
    'app.demographic.time_in_other_country.response_less_6_months': '3个月～未满6个月',
    'app.demographic.time_in_other_country.response_less_1_year': '6个月～未满1年',
    'app.demographic.time_in_other_country.response_less_2_years': '1年～未满2年',
    'app.demographic.time_in_other_country.response_less_5_years': '2年～未满5年',
    'app.demographic.time_in_other_country.response_less_10_years': '5年～未满10年',
    'app.demographic.time_in_other_country.response_10_or_more': '10年或10年以上',
    'app.demographic.country.response_eua': 'United States of America',
    'app.demographic.country.response_afganistan': 'Afghanistan',
    'app.demographic.country.response_albania': 'Albania',
    'app.demographic.country.response_algeria': 'Algeria',
    'app.demographic.country.response_american_samoa': 'American Samoa',
    'app.demographic.country.response_andorra': 'Andorra',
    'app.demographic.country.response_angola': 'Angola',
    'app.demographic.country.response_anguilla': 'Anguilla',
    'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
    'app.demographic.country.response_argentia': 'Argentina',
    'app.demographic.country.response_armenia': 'Armenia',
    'app.demographic.country.response_aruba': 'Aruba',
    'app.demographic.country.response_australia': 'Australia',
    'app.demographic.country.response_austria': 'Austria',
    'app.demographic.country.response_azerbaijan': 'Azerbaijan',
    'app.demographic.country.response_bahamas': 'Bahamas',
    'app.demographic.country.response_bahrain': 'Bahrain',
    'app.demographic.country.response_bangladesh': 'Bangladesh',
    'app.demographic.country.response_barbados': 'Barbados',
    'app.demographic.country.response_belarus': 'Belarus',
    'app.demographic.country.response_belgium': 'Belgium',
    'app.demographic.country.response_belize': 'Belize',
    'app.demographic.country.response_benin': 'Benin',
    'app.demographic.country.response_bermuda': 'Bermuda',
    'app.demographic.country.response_bhutan': 'Bhutan',
    'app.demographic.country.response_bolivia': 'Bolivia',
    'app.demographic.country.response_bosnia_herzegovina': 'Bosnia & Herzegovina',
    'app.demographic.country.response_botswana': 'Botswana',
    'app.demographic.country.response_brazil': 'Brazil',
    'app.demographic.country.response_brunei': 'Brunei',
    'app.demographic.country.response_bulgaria': 'Bulgaria',
    'app.demographic.country.response_burkina_faso': 'Burkina Faso',
    'app.demographic.country.response_burundi': 'Burundi',
    'app.demographic.country.response_cambodia': 'Cambodia',
    'app.demographic.country.response_cameroon': 'Cameroon',
    'app.demographic.country.response_canada': 'Canada',
    'app.demographic.country.response_cape_verde': 'Cape Verde',
    'app.demographic.country.response_cayman_islands': 'Cayman Islands',
    'app.demographic.country.response_central_african_republic': 'Central African Republic',
    'app.demographic.country.response_chad': 'Chad',
    'app.demographic.country.response_chile': 'Chile',
    'app.demographic.country.response_china': 'China',
    'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
    'app.demographic.country.response_colombia': 'Colombia',
    'app.demographic.country.response_comoros': 'Comoros',
    'app.demographic.country.response_congo': 'Congo',
    'app.demographic.country.response_congo,_democratic_republic': 'Congo, Democratic Republic',
    'app.demographic.country.response_cook_islands': 'Cook Islands',
    'app.demographic.country.response_costa_rica': 'Costa Rica',
    'app.demographic.country.response_cote_divoire': 'Cote DIvoire',
    'app.demographic.country.response_croatia': 'Croatia',
    'app.demographic.country.response_cuba': 'Cuba',
    'app.demographic.country.response_cyprus': 'Cyprus',
    'app.demographic.country.response_czech_republic': 'Czech Republic',
    'app.demographic.country.response_denmark': 'Denmark',
    'app.demographic.country.response_djibouti': 'Djibouti',
    'app.demographic.country.response_dominica': 'Dominica',
    'app.demographic.country.response_dominican_republic': 'Dominican Republic',
    'app.demographic.country.response_ecuador': 'Ecuador',
    'app.demographic.country.response_egypt': 'Egypt',
    'app.demographic.country.response_el_salvador': 'El Salvador',
    'app.demographic.country.response_equatorial_guinea': 'Equatorial Guinea',
    'app.demographic.country.response_eritrea': 'Eritrea',
    'app.demographic.country.response_estonia': 'Estonia',
    'app.demographic.country.response_ethiopia': 'Ethiopia',
    'app.demographic.country.response_euro_area': 'Euro Area',
    'app.demographic.country.response_falkland_islands': 'Falkland Islands',
    'app.demographic.country.response_fiji': 'Fiji',
    'app.demographic.country.response_finland': 'Finland',
    'app.demographic.country.response_france': 'France',
    'app.demographic.country.response_french_guiana': 'French Guiana',
    'app.demographic.country.response_french_polynesia': 'French Polynesia',
    'app.demographic.country.response_gabon': 'Gabon',
    'app.demographic.country.response_gambia': 'Gambia',
    'app.demographic.country.response_georgia': 'Georgia',
    'app.demographic.country.response_germany': 'Germany',
    'app.demographic.country.response_ghana': 'Ghana',
    'app.demographic.country.response_gibraltar': 'Gibraltar',
    'app.demographic.country.response_greece': 'Greece',
    'app.demographic.country.response_grenada': 'Grenada',
    'app.demographic.country.response_guadeloupe': 'Guadeloupe',
    'app.demographic.country.response_guam': 'Guam',
    'app.demographic.country.response_guatemala': 'Guatemala',
    'app.demographic.country.response_guernsey': 'Guernsey',
    'app.demographic.country.response_guinea': 'Guinea',
    'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
    'app.demographic.country.response_guyana': 'Guyana',
    'app.demographic.country.response_haiti': 'Haiti',
    'app.demographic.country.response_honduras': 'Honduras',
    'app.demographic.country.response_hungary': 'Hungary',
    'app.demographic.country.response_iceland': 'Iceland',
    'app.demographic.country.response_india': 'India',
    'app.demographic.country.response_indonesia': 'Indonesia',
    'app.demographic.country.response_iran': 'Iran',
    'app.demographic.country.response_iraq': 'Iraq',
    'app.demographic.country.response_ireland': 'Ireland',
    'app.demographic.country.response_isle_of_man': 'Isle of Man',
    'app.demographic.country.response_israel': 'Israel',
    'app.demographic.country.response_italy': 'Italy',
    'app.demographic.country.response_jamaica': 'Jamaica',
    'app.demographic.country.response_japan': 'Japan',
    'app.demographic.country.response_jersey': 'Jersey',
    'app.demographic.country.response_jordan': 'Jordan',
    'app.demographic.country.response_kazakhstan': 'Kazakhstan',
    'app.demographic.country.response_kenya': 'Kenya',
    'app.demographic.country.response_kiribati': 'Kiribati',
    'app.demographic.country.response_kosovo': 'Kosovo',
    'app.demographic.country.response_kuwait': 'Kuwait',
    'app.demographic.country.response_kyrgyzstan': 'Kyrgyzstan',
    'app.demographic.country.response_laos': 'Laos',
    'app.demographic.country.response_latvia': 'Latvia',
    'app.demographic.country.response_lebanon': 'Lebanon',
    'app.demographic.country.response_lesotho': 'Lesotho',
    'app.demographic.country.response_liberia': 'Liberia',
    'app.demographic.country.response_libya': 'Libya',
    'app.demographic.country.response_liechtenstein': 'Liechtenstein',
    'app.demographic.country.response_lithuania': 'Lithuania',
    'app.demographic.country.response_luxembourg': 'Luxembourg',
    'app.demographic.country.response_macau': 'Macau',
    'app.demographic.country.response_macedonia': 'Macedonia',
    'app.demographic.country.response_madagascar': 'Madagascar',
    'app.demographic.country.response_malaysia': 'Malaysia',
    'app.demographic.country.response_malawi': 'Malawi',
    'app.demographic.country.response_maldives': 'Maldives',
    'app.demographic.country.response_mali': 'Mali',
    'app.demographic.country.response_malta': 'Malta',
    'app.demographic.country.response_mariana': 'Mariana',
    'app.demographic.country.response_marshall_islands': 'Marshall Islands',
    'app.demographic.country.response_martinique': 'Martinique',
    'app.demographic.country.response_mauritania': 'Mauritania',
    'app.demographic.country.response_mauritius': 'Mauritius',
    'app.demographic.country.response_mexico': 'Mexico',
    'app.demographic.country.response_micronesia': 'Micronesia',
    'app.demographic.country.response_moldova': 'Moldova',
    'app.demographic.country.response_monaco': 'Monaco',
    'app.demographic.country.response_mongolia': 'Mongolia',
    'app.demographic.country.response_montserrat': 'Montserrat',
    'app.demographic.country.response_montenegro': 'Montenegro',
    'app.demographic.country.response_morocco': 'Morocco',
    'app.demographic.country.response_mozambique': 'Mozambique',
    'app.demographic.country.response_myanmar': 'Myanmar',
    'app.demographic.country.response_namibia': 'Namibia',
    'app.demographic.country.response_nepal': 'Nepal',
    'app.demographic.country.response_netherland_antilles': 'Netherland Antilles',
    'app.demographic.country.response_netherlands': 'Netherlands',
    'app.demographic.country.response_new_caledonia': 'New Caledonia',
    'app.demographic.country.response_new_zealand': 'New Zealand',
    'app.demographic.country.response_nicaragua': 'Nicaragua',
    'app.demographic.country.response_niger': 'Niger',
    'app.demographic.country.response_nigeria': 'Nigeria',
    'app.demographic.country.response_north_korea': 'North Korea',
    'app.demographic.country.response_norway': 'Norway',
    'app.demographic.country.response_oman': 'Oman',
    'app.demographic.country.response_pakistan': 'Pakistan',
    'app.demographic.country.response_palau': 'Palau',
    'app.demographic.country.response_panama': 'Panama',
    'app.demographic.country.response_papua_new_guinea': 'Papua New Guinea',
    'app.demographic.country.response_paraguay': 'Paraguay',
    'app.demographic.country.response_peru': 'Peru',
    'app.demographic.country.response_phillipines': 'Philippines',
    'app.demographic.country.response_poland': 'Poland',
    'app.demographic.country.response_portugal': 'Portugal',
    'app.demographic.country.response_puerto_rico': 'Puerto Rico',
    'app.demographic.country.response_qatar': 'Qatar',
    'app.demographic.country.response_reunion': 'Reunion',
    'app.demographic.country.response_romania': 'Romania',
    'app.demographic.country.response_russian_federation': 'Russian Federation',
    'app.demographic.country.response_rwanda': 'Rwanda',
    'app.demographic.country.response_st_helena': 'St Helena',
    'app.demographic.country.response_st_kitts-nevis': 'St Kitts-Nevis',
    'app.demographic.country.response_st_lucia': 'St Lucia',
    'app.demographic.country.response_st_vincent_grenadines': 'St Vincent & Grenadines',
    'app.demographic.country.response_stateless': 'Stateless',
    'app.demographic.country.response_samoa': 'Samoa',
    'app.demographic.country.response_san_marino': 'San Marino',
    'app.demographic.country.response_sao_tome_principe': 'Sao Tome & Principe',
    'app.demographic.country.response_saudi_arabia': 'Saudi Arabia',
    'app.demographic.country.response_senegal': 'Senegal',
    'app.demographic.country.response_seychelles': 'Seychelles',
    'app.demographic.country.response_serbia': 'Serbia',
    'app.demographic.country.response_sierra_leone': 'Sierra Leone',
    'app.demographic.country.response_singapore': 'Singapore',
    'app.demographic.country.response_slovak_republic': 'Slovak Republic',
    'app.demographic.country.response_slovenia': 'Slovenia',
    'app.demographic.country.response_solomon_islands': 'Solomon Islands',
    'app.demographic.country.response_somalia': 'Somalia',
    'app.demographic.country.response_south_africa': 'South Africa',
    'app.demographic.country.response_south_korea': 'South Korea',
    'app.demographic.country.response_spain': 'Spain',
    'app.demographic.country.response_sri_lanka': 'Sri Lanka',
    'app.demographic.country.response_sudan': 'Sudan',
    'app.demographic.country.response_suriname': 'Suriname',
    'app.demographic.country.response_swaziland': 'Swaziland',
    'app.demographic.country.response_sweden': 'Sweden',
    'app.demographic.country.response_switzerland': 'Switzerland',
    'app.demographic.country.response_syria': 'Syria',
    'app.demographic.country.response_taiwan': 'Taiwan',
    'app.demographic.country.response_tajikistan': 'Tajikistan',
    'app.demographic.country.response_tanzania': 'Tanzania',
    'app.demographic.country.response_thailand': 'Thailand',
    'app.demographic.country.response_togo': 'Togo',
    'app.demographic.country.response_timor_leste': 'Timor-Leste',
    'app.demographic.country.response_tonga': 'Tonga',
    'app.demographic.country.response_trinidad_tobago': 'Trinidad & Tobago',
    'app.demographic.country.response_tunisia': 'Tunisia',
    'app.demographic.country.response_turkey': 'Turkey',
    'app.demographic.country.response_turkmenistan': 'Turkmenistan',
    'app.demographic.country.response_turks_caicos_is': 'Turks & Caicos Is',
    'app.demographic.country.response_tuvalu': 'Tuvalu',
    'app.demographic.country.response_uganda': 'Uganda',
    'app.demographic.country.response_ukraine': 'Ukraine',
    'app.demographic.country.response_united_arab_erimates': 'United Arab Erimates',
    'app.demographic.country.response_united_kingdom': 'United Kingdom',
    'app.demographic.country.response_uraguay': 'Uraguay',
    'app.demographic.country.response_uzbekistan': 'Uzbekistan',
    'app.demographic.country.response_vanuatu': 'Vanuatu',
    'app.demographic.country.response_venezuela': 'Venezuela',
    'app.demographic.country.response_vietnam': 'Vietnam',
    'app.demographic.country.response_virgin_islands': 'Virgin Islands',
    'app.demographic.country.response_west_bank_and_gaza': 'West Bank and Gaza',
    'app.demographic.country.response_yemen': 'Yemen',
    'app.demographic.country.response_zambia': 'Zambia',
    'app.demographic.country.response_zimbabwe': 'Zimbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak': '除了自己的母语，我在会话，阅读或写作上不使用其他语言。',
    'app.demographic.level_of_fluency.minimally_fluent': '会说最低限度',
    'app.demographic.level_of_fluency.somewhat_fluent': '会说一些',
    'app.demographic.level_of_fluency.moderately_fluent': '中等水平',
    'app.demographic.level_of_fluency.mostly_fluent': '大部分流利',
    'app.demographic.level_of_fluency.fluent': '流利',
    'app.demographic.level_of_interest.not_applicable': '没有合适选项',
    'app.demographic.level_of_interest.extremely_low': '极其低',
    'app.demographic.level_of_interest.very_low': '很低',
    'app.demographic.level_of_interest.low': '低',
    'app.demographic.level_of_interest.neutral': '不低也不高',
    'app.demographic.level_of_interest.high': '高',
    'app.demographic.level_of_interest.very_high': '很高',
    'app.demographic.level_of_interest.extremely_high': '极其高',
    'app.demographic_slider_default_option': '请滑动光标回答',
    'app.demographic.formal_education.some_high_school_or_less': '中学毕业或高中辍学',
    'app.demographic.formal_education.high_school_graduate': '高中毕业',
    'app.demographic.formal_education.some_college': '大学辍学',
    'app.demographic.formal_education.college_degree': '大学毕业或学士学位 ',
    'app.demographic.formal_education.some_graduate_school': '研究生辍学',
    'app.demographic.formal_education.graduate_degree': '研究生毕业或硕士学位',
    'app.demographic.formal_education.professional_degree': '专业硕士学位（例如：律师）',
    'app.demographic.formal_education.doctoral_degree': '博士学位',
}

export const demographicQuestionsZHTW: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
