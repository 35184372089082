import {
    IndividualReportAccessOthersMessages, IndividualReportAccessSelfMessages,
    IndividualReportDefaultMessages, IndividualReportDemographicsMessages,
    IndividualReportEcologicalResonanceMessages, IndividualReportFormativeCoreMessages,
    IndividualReportGenderTraditionMessages, IndividualReportGlobalEngagementMessages,
    IndividualReportMessages, IndividualReportNeedsClosureMessages,
    IndividualReportNegativeEventsMessages, IndividualReportTitleMessages,
    IndividualReportToleranceDisequilibriumMessages, IndividualReportVariablesMessages,
    IndividualReportWhyWeDoMessages
} from '../types';

const individualReportDefault: IndividualReportDefaultMessages = {
    'app.individualreport.you_and_your_worldview': 'Tú y tú forma de ver el mundo',
    'app.individualreport.a_personal_report_from': 'Informe personal del',
    'app.individualreport.bevi_description': 'Test de Creencias, Vivencias y Valores (BEVI))',
    'app.individualreport.user': 'Usuario',
    'app.individualreport.date_of_test': 'Fecha de finalización',
    'app.individualreport.static_text_1': 'Cuando lees un periódico o enciendes la radio o televisión ' +
        'en cualquier lugar del mundo, hay una cosa que queda clara de inmediato: ' +
        'las creencias y los valores son consustanciales a la experiencia humana. La gente tiene opiniones muy marcadas sobre temas ' +
        'que van de la política, la religión y las artes al matrimonio, la familia, el género y la sexualidad.  ' +
        'Sin embargo, posiblemente lo importante no sea tanto si determinada creencia está "bien" o "mal" ' +
        'sino más bien comprender de qué forma las complejas interacciones entre los pensamientos, sentimientos, conducta, historia vital ' +
        'y contexto configuran una única versión de la realidad para cada ser humano. ' +
        'Esta comprensión es importante porque las creencias y valores  influyen en las acciones, ' +
        'políticas y prácticas de los individuos, grupos, organizaciones, gobiernos y sociedades de todo el mundo. ' +
        'El Test de Creencias, Vivencias y Valores o BEVI nos proporciona una herramienta para explorar estas complejas' +
        'cuestiones tanto a nivel individual como colectivo, ayudándonos a entender por qué témenos ciertas creencias y valores, ' +
        'además de analizar cómo pueden otras personas ver el mundo de formas similares y distintas. ',
    'app.individualreport.static_text_2': 'Este informe está diseñado para acercarte a las cosas ' +
        'que aparentemente crees y valoras, al tiempo que aventura posibilidades de por qué piensas como piensas. ' +
        'Cierto es que estos "qués" y "porqués" no son fáciles de responder en términos absolutos. ' +
        'Además, no hemos de olvidar que el mundo está en constante proceso de cambio. ' +
        'Lo que hoy se percibe como "verdadero" puede haberse considerado "falso" hasta hace no mucho. ' +
        'En la misma línea, lo que hoy se ve como "malo" podría llegar a considerarse "bueno" en unos años. ' +
        'Por tanto, es muy importante que te tomes este informe simplemente como algo ' +
        'sobre lo que reflexionar en este momento de tu vida más que como una conclusión final en torno a estas cuestiones en lo que a ti concierne. ' +
        'Como seres humanos, somos cambiantes, únicos y complejos. No existe herramienta capaz de capturar todo ' +
        'lo que has sido, eres y serás; ni tampoco es ese el cometido del BEVI. ' +
        'Este instrumento se ha diseñado más bien para ayudarte a reflexionar sobre ti mismo, sobre los demás y sobre el mundo en general ' +
        'en torno a una serie de áreas básicas que suelen ser consustanciales a la experiencia humana.',
    'app.individualreport.static_text_3': 'Hemos de hacer hincapié en una última cuestión. Aunque el informe se centra en la ' +
        'naturaleza e implicaciones de creencias y valores en tu vida, trabajo y relaciones, en aras de mejorar ' +
        'tu propio autoconocimiento, así como tu conocimiento de los demás y del mundo en general, el BEVI no se posiciona en cuanto a si un determinado conjunto de ' +
        'creencias y valores está "bien", "mal", o es "mejor" o "peor" que otro conjunto de creencias y valores determinado.',
    'app.individualreport.static_text_4': 'De modo que, con todo lo dicho en mente, procedamos a revisar tu informe. ' +
        'Además de toda la información explicativa sobre creencias y valores, ' +
        'tus particulares respuestas al BEVI están resaltadas en negrita.',
    'app.individualreport.static_text_5': 'Las experiencias que tenemos al crecer y a lo largo de la vida afectan a la forma ' +
        'en que nos entendemos a nosotros mismos, a los demás y al mundo en general. Por ejemplo, la gente que tiene muchas vivencias positivas durante ' +
        'su vida, y que considera que sus necesidades básicas se vieron mayormente cubiertas, tiende a considerar a sus congéneres fundamentalmente ' +
        'dignos de confianza y a esperar cosas buenas de la vida. A veces, si bien no siempre, cuando ' +
        'una persona experimenta muchas vivencias negativas (i. e., conflictos con sus cuidadores o en su hogar), o no ' +
        'les parece que sus necesidades básicas se hayan visto suficientemente cubiertas (i. e., en términos afectivos o materiales), su forma de ver ' +
        'el mundo y su experiencia con los demás (i. e., en el colegio, trabajo y relaciones) puede verse afectada; ' +
        'no siempre experimentan "el mundo" como un lugar agradable, especialmente si tales experiencias se producen ' +
        'a edad muy temprana, durante periodos de tiempo prolongados, o si han sido especialmente complicadas o traumáticas. ' +
        'De hecho, a la gente que ha tenido muchas vivencias negativas, o ha visto descuidadas sus necesidades básicas, ' +
        'puede resultarle útil hablar con alguien con formación o conocimientos sobre este tipo de cuestiones. ' +
        'Dicho esto, es muy importante reconocer que, en mayor o menor grado, la vida es un desafío para todo el mundo, ' +
        'e incluso cuando se sufren muchas adversidades, la mayoría de las personas son capaces de lidiar con ellas y continuar manteniendo relaciones  ' +
        'sanas en el colegio, el trabajo y la vida, a veces con mucha ayuda por parte de otra personas (i. e., parejas, amigos, profesores, ' +
        'jefes, líderes espirituales, mentores, consejeros). En cualquier caso, cuando las creencias y valores de otros no nos parecen razonables, ' +
        'puede ser útil recordar que nuestras experiencias en la vida y nuestras circunstancias personales influyen en cómo nos vemos a nosotros mismos, ' +
        'a otras personas y al mundo en general. Ser conscientes de ello puede ayudarnos a comprender un poco más, e incluso empatizar, con las razones ' +
        'por las que tanto nosotros como otras personas pensamos como pensamos, sentimos como sentimos y actuamos como actuamos en diferentes situaciones y contextos.',
    'app.individualreport.static_text_6': 'Si tiendes a ser del tipo "inquisitivo", te puede ser útil recordar que ' +
        'las personas que te rodean, incluso las que muestran "más confianza", podrían no ser tan seguras como aparentan frente ' +
        'a lo nuevo o desconocido, y puede que de hecho no estén más capacitadas que tú para saber qué sería "lo correcto" en dichas circunstancias. ' +
        'La confianza en su justa medida es fabulosa cuando te encuentras en una situación con la que tienes familiaridad. ' +
        'Sin embargo, cuando estás en una situación o lugar en el que nunca habías estado, puede ser útil adoptar una actitud más ' +
        'inquisitiva y abierta en general, poniendo especial atención en el tipo de cuestiones, interrogantes, prácticas y procesos que ' +
        'te resultan más interesantes. Tal información sobre tu persona puede ayudarte a entender mejor quién eres, en quién te estás convirtiendo, y quién te gustaría ser. ',
    'app.individualreport.static_text_7': 'Al mismo tiempo, a medida que nos encontramos con personas o situaciones nuevas y cambiamos con el paso del tiempo, ' +
        'es igualmente importante que aspiremos a conseguir "saber lo que desconocemos" para así tener una idea aproximada de los límites de nuestro propio conocimiento, ' +
        'y admitir que quizá no tengamos "la respuesta correcta" o que necesitemos saber más de un tema en particular antes de formarnos una idea definitiva al respecto. ' +
        'Resumiendo, el grado en que somos capaces de entender por qué los humanos actúan como actúan está relacionado con la capacidad de pensar, ' +
        'sentir y actuar con mayor comprensión, sabiduría y compasión.',
    'app.individualreport.static_text_8': 'En la misma línea de los hallazgos y observaciones mencionados, merece la pena reflexionar de forma más general sobre ' +
        'la importancia de las "necesidades" y "sentimientos" en nuestra vida diaria. Habrás notado, por ejemplo, que solemos perseguir e incluso anhelamos ' +
        'experiencias emocionalmente "profundas" (i. e., a través de las relaciones, música, teatro, lectura, viajes, naturaleza, etc.) ' +
        'Como nuestras emociones dicen tanto de nuestro "verdadero yo", sentir lo que sentimos es a menudo complicado, y no digamos ' +
        'expresarlo en voz alta, dado que al hacerlo podríamos estar revelando muchas cosas sobre quiénes somos en realidad. ' +
        'Y con todo, darle a conocer a otras personas "quiénes somos" es crucial para construir relaciones, satisfacer necesidades y llegar a realizarse en el trabajo ' +
        'y en la vida.  En consecuencia, aunque sería conveniente no compartir cada emoción o necesidad que tenemos, ser incapaz de ' +
        'experimentarlas o expresarlas puede acarrearnos auténticos problemas tanto a nosotros mismos como a los demás.',
    'app.individualreport.static_text_9_1': 'En definitiva, la incapacidad o rechazo a sentir emociones y reconocer necesidades puede ser un obstáculo ' +
        'para nosotros mismos y para otras personas por al menos dos razones: 1) nos perdemos información importante sobre lo que "sucede" dentro de nosotros, lo que además puede ' +
        'afectar negativamente a nuestra capacidad de tomar decisiones y nuestro bienestar general; y 2) puede que a otras personas les resulte difícil relacionarse con nosotros o llegar a conocernos realmente. ' +
        'Afortunadamente, seamos o no capaces de reconocerlo, todos venimos al mundo programados para sentir y necesitar, lo que es quizá la ' +
        'forma más básica en que nos comunicamos con otros seres, incluso antes de decir nuestras primeras palabras. ' +
        'En la vida, tenemos ocasión de experimentar la gama completa de necesidades y sentimientos en nosotros mismos y en los demás, lo que a su vez nos hace más profundamente humanos.',
    'app.individualreport.static_text_9_2': '',
    'app.individualreport.static_text_9_3': '',
    'app.individualreport.static_text_9_4': '',
    'app.individualreport.static_text_10_1': 'Por último, nuestro contexto personal y experiencias no solo pueden influir en cómo nos vemos a nosotros mismos y a otras personas, ' +
        'sino también en cómo vemos el "mundo en general" y cómo creemos que es o debería ser. Aquí, nos vamos a centrar finalmente en dos temas globales ' +
        'que son relevantes para todos nosotros: 1) nuestra postura hacia el medio ambiente y el mundo natural; y 2) nuestro grado de ' +
        ' compromiso global.',
    'app.individualreport.static_text_10_2': '',
    'app.individualreport.static_text_10_3': '',
    'app.individualreport.static_text_11': 'Para concluir este informe, es importante recordar que las afirmaciones que has leído se refieren ' +
        'al punto en que estás ahora; todos cambiamos durante el curso de nuestras vidas y las creencias y valores que tienes hoy podrían cambiar también con el tiempo. ' +
        'En el análisis final, es bueno que desarrollemos el valor y capacidad de reflexionar con toda la profundidad posible sobre lo que creemos ' +
        'y valoramos para asegurarnos al máximo que las decisiones que tomamos en nuestra vida son algo que entendemos, ' +
        'podemos justificar y con lo que estaremos satisfechos. Esperamos que la información y perspectiva que te aporte este informe te sea útil ' +
        'en la vida y continúes reflexionando sobre quién eres ahora, en quién te estás convirtiendo y quién quieres ser.',
    'app.individualreport.heading_text_1_1': 'El test sobre creencias, vivencias y valores (BEVI) está diseñado para ayudar a la gente a tomar ' +
        'mayor conciencia de sus propias creencias y valores. El BEVI también analiza cómo las creencias y valores influyen en el aprendizaje, ' +
        ' el crecimiento personal, las relaciones, los procesos grupales u organizativos y la consecución de las metas. ' +
        'Al abordar estas cuestiones, este informe proporciona información sobre cómo te ves a ti mismo, a los demás y al mundo en general. ' +
        'Sin embargo, el BEVI no se posiciona en modo alguno sobre si las respuestas proporcionadas están bien, mal, o son mejores o peores, ' +
        'ni tampoco en este informe se hacen tales afirmaciones. Más bien, el informe se ha diseñado para promover la reflexión sobre cuestiones de la vida ' +
        'que suelen ser relevantes para todo el mundo. Si tienes alguna pregunta sobre el BEVI, por favor, ponte en contacto con el administrador BEVI de tu organización o institución. ' +
        'También puedes ponerte en contacto con nosotros directamente a través de info@ibavi.org. ' +
        'Antes de leer este informe, debes mostrar tu conformidad con las siguientes tres condiciones haciendo click en el botón Acepto o firmando un ' +
        'formulario de conformidad por separado que se te proporcionará antes de leer el informe:',
    'app.individualreport.heading_text_1_2': '',
    'app.individualreport.heading_text_1_3': '',
    'app.individualreport.heading_text_2': '1. Tú, ({UserName}), has completado voluntariamente el BEVI y solicitado este informe de seguimiento, ' +
        'que vas a leer voluntariamente (i. e., estás de acuerdo en que ninguna persona, grupo, organización o entidad te ha pedido que leas el informe).',
    'app.individualreport.heading_text_3': '2. No se ha escatimado en esfuerzos para comunicar la información del BEVI de forma que resulte útil ' +
        'y constructiva. Sin embargo, si llegaras a experimentar angustia durante la lectura del informe, estás de acuerdo en ponerte en contacto con el administrador del BEVI ' +
        'de tu organización, el coordinador de la sesión de feedback sobre el BEVI y/o con un centro sanitario o psiquiátrico.',
    'app.individualreport.heading_text_4': '3. Puedes quedarte con una copia de este informe. ' +
        'Sin embargo, dado que el BEVI y este informe están sujetos a propiedad intelectual, accedes a no fotocopiarlo o distribuirlo ' +
        '(electrónicamente o por cualquier otro medio).',
    'app.individualreport.thankyou_static_1_1': 'El Test sobre Creencias, Vivencias y Valores o BEVI está sujeto a propiedad intelectual. ' +
        'Queda prohibida la modificación, copia, distribución o publicación, en todo o en parte, del BEVI, los informes BEVI, los ítems y su contenido, ' +
        'o escalas, sin autorización expresa por escrito del Dr. Craig N. Shealy.',
    'app.individualreport.thankyou_static_1_2': '',
    'app.individualreport.thankyou_static_2': 'Al igual que el test BEVI íntegramente, la estructura y diseño de estos informes están sujetos ' +
        'a un proceso constante de revisión y desarrollo. Si tienes alguna sugerencia o comentario sobre cualquier aspecto del BEVI o de este ' +
        'informe, no dudes en contactar con nosotros directamente a través de info@ibavi.org.',
    'app.individualreport.thankyou_static_3': 'Para más información sobre creencias y valores puede visitar el Instituto Internacional de Creencias ' +
        ' y Valores (IBAVI) a través de www.ibavi.org y www.thebevi.com.',
}

const individualReportDemographics: IndividualReportDemographicsMessages = {
    'app.individualreport.demographics.text': 'Todos diferimos, a veces notablemente, en lo que creemos y valoramos. ' +
        'Pero ¿por qué? Aunque hay muchas formas de responder a esta pregunta, el BEVI se ha diseñado bajo la premisa básica ' +
        'de que los lugares, culturas y contextos en que nos criamos, y las vivencias positivas y negativas que ' +
        'tenemos mientras crecemos y a lo largo de toda nuestra vida, tienen un gran impacto en cómo vemos el mundo.',
}

const individualReportFormativeCore: IndividualReportFormativeCoreMessages = {
    'app.individualreport.formativecore.text': 'En el BEVI se parte de la idea de que adoptamos maneras de pensar y ' +
        'sentir con respecto al mundo (i. e., política y religión) como consecuencia de toda una serie de factores, incluidos ' +
        'la propia familia, la educación, la cultura y el contexto, si bien no son ni mucho menos los únicos. Por eso para entender lo que la gente ' +
        'cree y valora, es útil conocer algunas de las experiencias positivas y negativas que han vivido ' +
        'así como el grado en que consideran que sus necesidades básicas se han visto cubiertas <b>En tu caso, has ' +
        'manifestado haber tenido {NegativeLifeEvents} una serie de experiencias negativas en la vida. ' +
        'También manifiestas que tus necesidades básicas se han {NeedsClosure} durante tu infancia y adolescencia.</b>'
}

const individualReportToleranceDisequilibrium: IndividualReportToleranceDisequilibriumMessages = {
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text': 'Nuestro contexto personal y experiencias también nos afectan de otras maneras, ' +
        'configurando el grado en que estamos dispuestos a lidiar con gente y situaciones muy distintas de aquello a lo que estamos ' +
        'acostumbrados. De acuerdo con el BEVI, existen dos tipos de personas en este sentido. El primer tipo, ' +
        ' llamémosle "seguro", puede mostrar confianza en sí mismo y seguridad sobre quiénes son los demás, cómo funciona el mundo ' +
        'y sobre sus experiencias vitales, laborales y relaciones. El segundo tipo, llamémosle "inquisitivo", puede tener menos claras estas ' +
        'cuestiones, tener dudas y arrepentirse a veces, así como no tener claro cómo reaccionarían frente a situaciones ' +
        'atípicas o poco habituales. <b>Por tus respuestas en el BEVI, tiendes a ser más "seguro" que "inquisitivo"</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1': 'Obviamente, uno y otro enfoque, seguro o inquisitivo,' +
        'tienen sus ventajas e inconvenientes, y todo del mundo se puede sentir de ambas formas de vez en cuando en función ' +
        'de las circunstancias o situación. Sin embargo, en general, si eres del tipo "seguro", te puede venir bien ser más consciente ' +
        'de los momentos en que no necesariamente sabes qué hacer, cómo pensar o qué sentir, sobre todo cuando te enfrentas a ' +
        'algo distinto a aquello a lo que estás acostumbrado. <b>En relación con esto, pareces tener un gran voluntad y un ' +
        'gran sentido de la responsabilidad, creer que el mundo es "cómo tú quieres que sea" y rechazas los "pensamientos negativos" ' +
        'en general. Esta postura ante la vida puede ayudarte a conseguir muchas cosas, ya que no es fácil apartarte ' +
        'de tus metas. Esta misma actitud también puede hacerte difícil a veces empatizar con otras personas que puedan no tener ' +
        'tu voluntad u optimismo. De nuevo puede venirte bien tener en cuenta que las personas tenemos distintas experiencias y contextos ' +
        'personales, y que lo que a ti pueda parecerte "necesidad" o "debilidad" excesivas, podría ser en cambio un reflejo de los ' +
        'obstáculos y dificultades con que una persona se ha encontrado en la vida, a los cuales es incapaz de sobreponerse ni con todos los ' +
        'pensamientos positivos del mundo, al menos hasta ese momento.</b>',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2': '',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text': 'Nuestro contexto personal y experiencias también nos afectan de otras maneras, ' +
        'configurando el grado en que estamos dispuestos a lidiar con gente y situaciones muy distintas de aquello a lo que estamos ' +
        'acostumbrados. De acuerdo con el BEVI, existen dos tipos de personas en este sentido. El primer tipo, ' +
        'llamémosle "seguro", puede mostrar confianza en sí mismo y seguridad sobre quiénes son los demás, cómo funciona el mundo ' +
        'y sobre sus experiencias vitales, laborales y relaciones. El segundo tipo, llamémosle "inquisitivo", puede tener menos claras estas ' +
        'cuestiones, tener dudas y arrepentirse a veces, así como no tener claro cómo reaccionarían frente a situaciones ' +
        'atípicas o poco habituales. <b>Por tus respuestas en el BEVI, tiendes a ser entre seguro e inquisitivo</b>.',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2': ' Obviamente, uno y otro enfoque, seguro o inquisitivo, ' +
        'tienen sus ventajas e inconvenientes, y todo del mundo se puede sentir de ambas formas de vez en cuando en función ' +
        'de las circunstancias o situación. Sin embargo, en general, si eres del tipo "seguro", te puede venir bien ser más consciente ' +
        'de los momentos en que no necesariamente sabes qué hacer, cómo pensar o qué sentir, sobre todo cuando te enfrentas a ' +
        'algo distinto a aquello a lo que estás acostumbrado. ',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3': '<b>En relación con esto, parece que dudas de si la vida ' +
        'es "como uno quiere que sea" y si la mejor estrategia para lidiar con los problemas simplemente es "tener ' +
        'pensamientos positivos."</b> En algunos casos, estos métodos pueden funcionarte, pero no por ello dejas de ' +
        'cuestionar este enfoque "positivo" a la vida y vivir. Quizá una de las ventajas de pensar que "el único ' +
        'responsable de sus problemas es uno mismo" es que hace que la persona se sienta empoderada para abordar cualquier problema que se le plantee. ' +
        'Sin embargo, una de las desventajas de esta postura puede ser que la persona que así piensa no siempre se sienta inclinada a empatizar  ' +
        'con quienes no parecen capaces de "superar cualquier obstáculo." Entre otras preguntas que merece la pena plantear a este ' +
        'respecto está, ¿cuáles son las consecuencias de creer, o no creer, que "somos responsables del tipo de vida que tenemos"?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text': 'Nuestro contexto personal y experiencias también nos afectan de otras maneras, ' +
        'configurando el grado en que estamos dispuestos a lidiar con gente y situaciones muy distintas de aquello a lo que estamos ' +
        'acostumbrados. De acuerdo con el BEVI, existen dos tipos de personas en este sentido. El primer tipo, ' +
        'llamémosle "seguro", puede mostrar confianza en sí mismo y seguridad sobre quiénes son los demás, cómo funciona el mundo ' +
        'y sobre sus experiencias vitales, laborales y relaciones. El segundo tipo, llamémosle "inquisitivo", puede tener menos claras estas ' +
        'cuestiones, tener dudas y arrepentirse a veces, así como no tener claro cómo reaccionarían frente a situaciones ' +
        'atípicas o poco habituales. <b>Por tus respuestas al BEVI, tiendes a ser más inquisitivo que seguro.</b>',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1': 'Obviamente, uno y otro enfoque, seguro o inquisitivo, ' +
        'tienen sus ventajas e inconvenientes, y todo el mundo se puede sentir de ambas formas de vez en cuando en función ' +
        'de las circunstancias o situación. Sin embargo, en general, si eres del tipo "seguro", te puede venir bien ser más consciente ' +
        'de los momentos en que no necesariamente sabes qué hacer, cómo pensar o qué sentir, sobre todo cuando te enfrentas a ' +
        'algo distinto a aquello a lo que estás acostumbrado. <b>En relación con esto, pareces ser algo escéptico en general ' +
        'hacia la idea de que el mundo "es como uno quiere que sea" y si deberíamos "limitarnos a tener pensamientos positivos" para ' +
        'salir adelante en la vida.</b> De hecho, la gente que piensa así puede resultar ingenua e incluso irritante a veces, ' +
        'principalmente porque se pueden mostrar impacientes con aquellos a los que ya les cuesta terminar cada día. Quizá una de las ventajas ' +
        'de pensar que "el único responsable de sus problemas es uno mismo" es que hace que la persona se sienta empoderada ' +
        'para abordar cualquier problema que se le plantee. Sin embargo, una de las desventajas de esta postura puede ser que la persona que así piensa no siempre sienta ' +
        'particular empatía hacia quienes no parecen ser capaces de "superar cualquier obstáculo."' +
        ' En tu caso, puede venirte bien reflexionar sobre los momentos en que te sientes y no te sientes empoderado/a ' +
        'y por qué es así. Por ejemplo, ¿qué opinión te has formado sobre el "destino," la "naturaleza humana,"? ' +
        'o ¿"por qué las cosas son como son"? ¿Cuáles de estas creencias son más (y menos) útiles para ti ' +
        'a la hora de abordar los desafíos y posibilidades que se te presentan cada día?',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2': '',
}

const individualReportWhyWedo: IndividualReportWhyWeDoMessages = {
    'app.individualreport.whywedo.greaterthan69.text': 'Los humanos le buscamos significado a las cosas constantemente. ' +
        'Puede que ni siquiera seamos conscientes de ello, pero todos le buscamos una explicación a "por qué las cosas son como son" ' +
        'y sacamos conclusiones causales aunque no siempre contemos con la información necesaria para poder hacerlo. Esta búsqueda de sentido suele ' +
        'variar enormemente, desde la razón por la que los humanos actuamos como actuamos, hasta el propósito último de nuestra existencia,' +
        'pasando por el motivo de que las cosas sucedan como suceden en general. A veces, puede que ni seamos conscientes de estar sacando ' +
        'conclusiones sobre "por qué las cosas son como son" o de lo habituales, automáticos, inconscientes o incluso inevitables ' +
        ' que son estos procesos de búsqueda de significado en la vida diaria. <b>Por tus respuestas en el BEVI, pareces tener una capacidad muy ' +
        'desarrollada de elaborar explicaciones causales matizadas y sofisticadas para los fenómenos, y de reflexionar con profundidad ' +
        'y acierto sobre "por qué las cosas son como son."</b>',
    'app.individualreport.whywedo.lessthan69.text': ' Los humanos le buscamos significado a las cosas constantemente. ' +
        'Puede que ni siquiera seamos conscientes de ello, pero todos le buscamos una explicación a "por qué las cosas son como son" ' +
        'y sacamos conclusiones causales aunque no siempre contemos con la información necesaria para poder hacerlo. Esta búsqueda de sentido suele ' +
        'variar enormemente, desde la razón por la que los humanos actuamos como actuamos, hasta el propósito último de nuestra existencia,' +
        'pasando por el motivo de que las cosas sucedan como suceden en general. A veces, puede que ni seamos conscientes de estar sacando ' +
        'conclusiones sobre "por qué las cosas son como son" o de lo habituales, automáticos, inconscientes o incluso inevitables ' +
        ' que son estos procesos de búsqueda de significado en la vida diaria. <b>Por tus respuestas en el BEVI, parece que ' +
        'cada vez te planteas más "por qué las cosas son como son."</b>',
    'app.individualreport.whywedo.lessthan30.text': 'Los humanos le buscamos significado a las cosas constantemente. ' +
        'Puede que ni siquiera seamos conscientes de que ello, pero todos le buscamos explicación a "por qué las cosas son como son" ' +
        'y sacamos conclusiones causales aunque no siempre contemos con la información necesaria para poder hacerlo. Esta búsqueda de sentido suele ' +
        'variar enormemente, desde la razón por la que los humanos actuamos como actuamos, hasta el propósito último de nuestra existencia,' +
        'pasando por el motivo de que las cosas sucedan como suceden en general. A veces, puede que ni seamos conscientes de estar sacando ' +
        'conclusiones sobre "por qué las cosas son como son" o de lo habituales, automáticos, inconscientes o incluso inevitables ' +
        ' que son estos procesos de búsqueda de significado en la vida diaria. <b>Por tus respuestas en el BEVI, parece que te podría venir bien ' +
        'explorar más las razones de por qué la gente actúa como actúa o por qué las cosas suceden como suceden en el mundo, antes, durante ' +
        'y después de cualquier experiencia novedosa o especialmente difícil con que te puedas encontrar.</b>',
}

const individualReportTitle: IndividualReportTitleMessages = {
    'app.individualreport.title.introduction': 'Introduction',
    'app.individualreport.title.foundation': 'Los cimientos: "Variables formativas" y "Necesidades básicas"',
    'app.individualreport.title.tolerance_of_desequilibrium': 'Tolerancia al desequilibrio',
    'app.individualreport.title.making_sense': 'Búsqueda de sentido a por qué actuamos como actuamos',
    'app.individualreport.title.access_to_yourself': 'Acceso a ti mismo y tus pensamientos, sentimientos y necesidades',
    'app.individualreport.title.access_to_the_thoughts': 'Acceso a los pensamientos y sentimientos de los demás',
    'app.individualreport.title.access_to_the_larger_world': 'Acceso al mundo en general',
    'app.individualreport.title.conclusion': 'Conclusiones',
    'app.individualreport.title.thankyou': 'Gracias por hacer el BEVI.',
    'app.individualreport.title.profile': 'Perfil',
}

const individualReportAccessSelf: IndividualReportAccessSelfMessages = {
    'app.individualreport.accessself.textA': '<b>Probablemente hayas ' +
        'notado que determinadas personas tienden a ser más emocionales y sensibles y que le dan ' +
        'mucho más valor a la expresión de necesidades y sentimientos que otras. Esta descripción parece ' +
        'encajar bien contigo.</b> Párate a pensar un momento en lo centrales que son las emociones en la ' +
        'existencia humana. La capacidad de sentir lo que sientes e interpretar y entender ' +
        'con precisión los sentimientos de los demás (lo que algunos han denominado "inteligencia emocional"), ' +
        'es clave para tener éxito en todas las facetas de la vida, desde tus relaciones personales hasta el mundo ' +
        'laboral. Sin emociones, sería muy difícil entender lo que nos gusta o no nos gusta, ' +
        'quiénes somos y quiénes son los demás, y quiénes queremos ser.',
    'app.individualreport.accessself.textB': '<b> Probablemente hayas ' +
        'notado que determinadas personas tienden a ser más emocionales y sensibles que otras. Esta descripción' +
        'parece encajar contigo en muchas circunstancias.</b> Párate a pensar un momento en lo centrales que son las emociones en la ' +
        'existencia humana. La capacidad de sentir lo que sientes e interpretar y entender ' +
        'con precisión los sentimientos de los demás (lo que algunos han denominado "inteligencia emocional"), ' +
        'es clave para tener éxito en todas las facetas de la vida, desde tus relaciones personales hasta el mundo ' +
        'laboral. Sin emociones, sería muy difícil entender lo que nos gusta o no nos gusta, ' +
        'quiénes somos y quiénes son los demás, y quiénes queremos ser.',
    'app.individualreport.accessself.textC': '<b>Probablemente hayas ' +
        'notado que determinadas personas tienden a ser más emocionales y sensibles y que le dan ' +
        'mucho más valor a la expresión de necesidades y sentimientos que otras. Esta descripción no parece encajar con la manera' +
        'en que tú abordas tus propios sentimientos y los de los demás, en el sentido de que te suele desconcertar y hasta ' +
        'molestar a veces lo que tú ves como muestras excesivas de emoción o vulnerabilidad en ' +
        'otras personas. </b> Si así es, podría venirte bien volver a reflexionar ' +
        'sobre el hecho de que nuestros contextos personales y vivencias nos pueden hacer mucho más proclives (o mucho menos) ' +
        'a "conectar" con nuestros sentimientos y necesidades más básicos. Solo tú puedes juzgar ' +
        'si tu contexto personal y experiencias no favorecieron en general ' +
        'que vivieras las cosas con excesiva intensidad o que necesitases demasiado de los demás. Si esa es tu experiencia, es importante que ' +
        'reflexiones sobre el posible impacto de tales procesos en tu vida, en el trabajo, en tus ' +
        'relaciones con los demás, y en cómo vives tu interacción con la gente, las situaciones y las relaciones, ' +
        'que pueden resultarte difíciles de manejar, al menos de primeras, especialmente cuando son nuevas. Párate ' +
        'a pensar un momento en lo centrales que son las emociones en la existencia humana. La capacidad de sentir lo que ' +
        'sientes e interpretar y entender con precisión los sentimientos de los demás (lo que algunos han ' +
        'denominado "inteligencia emocional") es clave para tener éxito en todas las facetas de la vida, desde tus ' +
        'relaciones personales hasta el mundo laboral. Sin emociones, sería muy difícil ' +
        'entender lo que nos gusta o no nos gusta, quiénes somos y quiénes son los demás, y quiénes queremos ser.',
    'app.individualreport.accessself.textI': '<b>A veces también puede costarte lidiar con pensamientos y sentimientos complicados y tiendes a evitar la "introspección" excesiva. En general, puede sorprenderte e incluso irritarte que los demás se centren tanto en "por qué son como son."</b> ' +
        'De nuevo, es importante que recuerdes que todo el mundo tiene distintas experiencias y contextos personales, ' +
        'que moldean la forma en que se ven a sí mismos, y que la gente que se pasa mucho tiempo reflexionando sobre ' +
        '"por qué son como son" suele poder ayudar a los demás a entender mejor quiénes son y ' +
        'por qué actúan como actúan. Al mismo tiempo, también es posible que nos preocupen determinados pensamientos ' +
        'y sentimientos que resulta difícil experimentar, entender o manejar; en tales casos, puede ' +
        'venirte bien hablar con alguien con estudios o conocimientos sobre este tipo de cuestiones.',
    'app.individualreport.accessself.textH': '<b>Puede que también estés abierto ' +
        'al mundo de la reflexión y las emociones, pero te parezca que te encuentras en un "término medio" comparado ' +
        'con quienes te rodean. Te parecerá que algunos dedican mucho tiempo y energías ' +
        'a pensar en "por qué son como son" mientras que otros parecen no pensar nada en estos ' +
        'términos, y hasta te molesten un poco los que no lo hacen. En muchos sentidos, estás en el lugar adecuado ' +
        'para ayudar a ambos bandos a entender mejor las tendencias del otro en este sentido ' +
        '(i. e., los que parecen tomarse las cosas "muy a pecho" frente a los que parecen evitar a toda costa estas emociones).</b>',
    'app.individualreport.accessself.textG': '<b>Puede que también estés muy abierto ' +
        'a las emociones y reflexiones complicadas, a ser introspectivo en general, y consciente de cómo ' +
        'te sientes y piensas. A veces te puede resultar sorprendente que otras personas no tengan esta misma capacidad, ' +
        'lo que puede hacerte sentir solo/a o aislado/a en ocasiones. Para la gente como tú, es importante ' +
        'buscar otras "almas gemelas" que entiendan y valoren la importancia de manifestar necesidades y sentimientos, ' +
        'y a quienes les guste pensar "por qué somos como somos." No cabe duda de que el mundo necesita gente como tú, ' +
        ' con la capacidad de reflexionar y sentir de forma tan profunda.</b>',
}

const individualReportAccessOthers: IndividualReportAccessOthersMessages = {
    'app.individualreport.accessothers.textA': 'Desde edad temprana, solemos aprender a evitar conversaciones sobre ' +
        'determinados temas como la política, el rol de hombres y mujeres, o cuestiones de raza o ' +
        'etnicidad porque se trata de asuntos que pueden generar mucha polémica cuando nos encontramos con alguien cuyas creencias ' +
        'son muy distintas a las nuestras. Pero desde el punto de vista del BEVI, no es una cuestión de que no podamos o ' +
        'no debamos discutir estos temas; de hecho, hacerlo es esencial para elaborar discursos constructivos en cualquier ' +
        ' sociedad civil. Lo más importante es entender en primer lugar por qué algunos temas nos resultan "delicados" ' +
        'a nosotros y a otras personas. Aquí, pues, exploramos la que parece ser tu forma de ver temas como la religión, el género, ' +
        'la política y la raza, así como las implicaciones potenciales de tus creencias y valores de cara a ' +
        'tus relaciones con personas con opiniones y emociones similares (o distintas) a las tuyas. <br /><br />' +
        '<b>Para empezar, parece que tiendes a tener creencias o convicciones religiosas ' +
        'marcadas, y tu fe parece tener un papel esencial en tu manera de darle sentido' +
        'y propósito a tu vida.  Tener un sentido de la fe muy desarrollado puede ser maravilloso. También es ' +
        'importante entender que algunos individuos, grupos, religiones y culturas pueden tener creencias ' +
        'distintas a las tuyas sobre estas cuestiones fundamentales. Algunas personas tienen muy claro ' +
        'por qué creen lo que creen en materia religiosa. Otras se han limitado ' +
        'a aceptar lo que se les enseñó, fuese lo que fuese, sin pararse realmente a reflexionar sobre por qué creen ' +
        'lo que creen. La cuestión aquí no es que un conjunto de creencias sea correcto y otro incorrecto, ' +
        'sino más bien que todos acabamos por creer algo respecto a estos asuntos fundamentales que nos atañen ' +
        'a todos (i. e., incluso no tener creencias es tener la creencia de que no tiene sentido -o no es realista- tener una  ' +
        'creencia o sistema de creencias particular). Dado que las creencias religiosas tienen tanto peso en cómo nos experimentamos ' +
        'a nosotros mismos, a los demás y al mundo en general, es importante que nos esforcemos por entender la ' +
        'naturaleza y orígenes de nuestras propias creencias y valores religiosos. Y lo que es más, teniendo en cuenta que ' +
        'nuestras creencias religiosas suelen ser producto de nuestra infancia, cultura y contexto particular,  ' +
        'quizá por ello podamos llegar a entender mejor por qué otras personas tienen ' +
        'creencias similares o distintas a las nuestras. Si abordamos el tema de las creencias y valores ' +
        'con curiosidad e interés genuinos, puede que lleguemos a entender mejor por qué somos como somos, además ' +
        'de ayudar a los demás a hacerlo también.',
    'app.individualreport.accessothers.textB': 'Desde edad temprana, solemos aprender a evitar conversaciones sobre ' +
        'determinados temas como la política, el rol de hombres y mujeres, o cuestiones de raza o ' +
        'etnicidad porque se trata de asuntos que pueden generar mucha polémica cuando nos encontramos con alguien cuyas creencias ' +
        'son muy distintas a las nuestras. Pero desde el punto de vista del BEVI, no es una cuestión de que no podamos o ' +
        'no debamos discutir estos temas; de hecho, hacerlo es esencial para elaborar discursos constructivos en cualquier ' +
        ' sociedad civil. Lo más importante es entender en primer lugar por qué algunos temas nos resultan "delicados" ' +
        'a nosotros y a otras personas. Aquí, pues, exploramos la que parece ser tu forma de ver temas como la religión, el género, ' +
        'la política y la raza, así como las implicaciones potenciales de tus creencias y valores de cara a ' +
        'tus relaciones con personas con opiniones y emociones similares (o distintas) a las tuyas. <br /><br />' +
        '<br /><br />' +
        '<b>Para empezar, parece que tiendes a tener creencias ' +
        'religiosas moderadas. Es posible que actualmente no tengas ningún problema con tus creencias en ' +
        '"asuntos de fe." Sin embargo, también es posible que tengas dudas sobre la profundidad e ' +
        'intensidad de tus propias creencias religiosas, sean estas las que sean. Puede que también te hagas preguntas sobre otras personas' +
        'con creencias aparentemente muy marcadas en un sentido o en otro (i. e., se describen a sí mismos como muy ' +
        'religiosos o muy antirreligiosos).</b> Algunas personas tienen muy claro ' +
        'por qué creen lo que creen en materia religiosa. Otras se han limitado ' +
        'a aceptar lo que se les enseñó, fuese lo que fuese, sin pararse realmente a reflexionar sobre por qué creen ' +
        'lo que creen. La cuestión aquí no es que un conjunto de creencias sea correcto y otro incorrecto, ' +
        'sino más bien que todos acabamos por creer algo respecto a estos asuntos fundamentales que nos atañen ' +
        'a todos (i. e., incluso no tener creencias es tener la creencia de que no tiene sentido -o no es realista- tener una  ' +
        'creencia o sistema de creencias particular). Dado que las creencias religiosas tienen tanto peso en cómo nos experimentamos ' +
        'a nosotros mismos, a los demás y al mundo en general, es importante que nos esforcemos por entender la ' +
        'naturaleza y orígenes de nuestras propias creencias y valores religiosos. Y lo que es más, teniendo en cuenta que ' +
        'nuestras creencias religiosas suelen ser producto de nuestra infancia, cultura y contexto particular,  ' +
        'quizá por ello podamos llegar a entender mejor por qué otras personas tienen ' +
        'creencias similares o distintas a las nuestras. Si abordamos el tema de las creencias y valores ' +
        'con curiosidad e interés genuinos, puede que lleguemos a entender mejor por qué somos como somos, además ' +
        'de ayudar a los demás a hacerlo también.', 'app.individualreport.accessothers.textC': 'Desde edad temprana, solemos aprender a evitar conversaciones sobre ' +
            'determinados temas como la política, el rol de hombres y mujeres, o cuestiones de raza o ' +
            'etnicidad porque se trata de asuntos que pueden generar mucha polémica cuando nos encontramos con alguien cuyas creencias ' +
            'son muy distintas a las nuestras. Pero desde el punto de vista del BEVI, no es una cuestión de que no podamos o ' +
            'no debamos discutir estos temas; de hecho, hacerlo es esencial para elaborar discursos constructivos en cualquier ' +
            ' sociedad civil. Lo más importante es entender en primer lugar por qué algunos temas nos resultan "delicados" ' +
            'a nosotros y a otras personas. Aquí, pues, exploramos la que parece ser tu forma de ver temas como la religión, el género, ' +
            'la política y la raza, así como las implicaciones potenciales de tus creencias y valores de cara a ' +
            'tus relaciones con personas con opiniones y emociones similares (o distintas) a las tuyas. <br /><br />' +
            'from you. <br /><br />' +
            '<b>Para empezar, no parece que tiendas a ' +
            'tener creencias o convicciones religiosas marcadas, e incluso puede que seas escéptico en lo relativo a sistemas religiosos ' +
            'o creencias religiosas. Reflexionar detenidamente sobre el tema o no aceptar ' +
            'tales creencias sobre la única base de la "fe", es posiblemente consecuencia de aplicar el pensamiento crítico a creencias y valores que ' +
            'con demasiada frecuencia se aceptan sin cuestionarse lo suficiente. Independientemente del resultado de este proceso ' +
            'en tu caso, te puede venir bien reflexionar sobre el hecho de que un determinado conjunto de creencias muy arraigadas ' +
            '(i. e., la convicción de una persona sobre la validez de sus propias creencias) puede sustituirse por otro' +
            'conjunto de creencias igualmente arraigadas (i. e., la convicción de una persona sobre la validez de rechazar determinadas creencias) ' +
            'sin que ello suponga una mejora de nuestra comprensión sobre los orígenes y utilidad de las creencias ' +
            'y valores para todos nosotros.</b> Algunas personas tienen muy claro por qué creen lo que creen ' +
            'en materia religiosa. Otras se han limitado a aceptar lo que se les enseñó, ' +
            'fuese lo que fuese, sin pararse realmente a reflexionar sobre por qué creen lo que creen. La cuestión aquí ' +
            'no es que un conjunto de creencias sea correcto y otro incorrecto, sino más bien que todos acabamos ' +
            'por tener creencias respecto a estos asuntos fundamentales que nos atañen a todos (i. e., incluso no tener creencias es' +
            'tener la creencia de que no tiene sentido -o no es realista- tener una creencia o sistema de creencias particular). ' +
            'Dado que las creencias religiosas tienen tanto peso en cómo experimentamos el mundo en general, a los demás ' +
            'y a nosotros mismos, es importante que nos esforcemos por entender la naturaleza y orígenes de ' +
            'nuestras propias creencias y valores religiosos. Y lo que es más, si no olvidamos que nuestras creencias ' +
            'religiosas suelen ser producto de nuestra infancia, cultura y contexto particular, quizá ' +
            'podamos llegar a entender mejor por qué otras personas tienen creencias similares o ' +
            'distintas a las nuestras. Si abordamos el tema de las creencias y valores con curiosidad e interés ' +
            'genuinos, puede que lleguemos a entender mejor por qué somos como somos, además de ayudar ' +
            'a los demás a hacerlo también. ',
}

const individualReportGenderTradition: IndividualReportGenderTraditionMessages = {
    'app.individualreport.gendertradition.textA': '<b>En segundo lugar, en términos de género, ' +
        'tiendes a tener creencias y valores más tradicionales o convencionales en torno a lo que los hombres y ' +
        'las mujeres son y cómo deberían ser; es posible que también creas que hombres y mujeres están "hechos ' +
        'para ser de una manera determinada," y que a todos nos iría mejor si entendiéramos y aceptásemos ese ' +
        'hecho. Todo el mundo está de acuerdo en que hay diferencias entre hombres y mujeres. Pero la cuestión ' +
        'es, ¿por qué existen esas diferencias para empezar? De tus respuestas se deduce que tiendes a creer ' +
        'que las diferencias de género se deben mayormente a factores biológicos subyacentes.</b> Aunque el debate académico sobre ' +
        'el tema es muy complejo, es importante saber que estas "diferencias" entre hombres y mujeres, ' +
        'ya sean ficticias o reales, se han utilizado como forma de negarle a mujeres de todas las edades derechos durante años: en ' +
        'resumen, "los argumentos biológicos" se han utilizado a menudo para justificar la privación de derechos a las mujeres ' +
        'porque no están "echas" para vivir, trabajar o estar en el mundo de los hombres como iguales. Los estereotipos ' +
        'son un arma de doble filo, sin embargo, dado que los hombres pueden considerarse "menos capaces" para ' +
        'criar a los hijos o expresar emociones, lo que significa que no solo les puede ser negado el "derecho" a tener sentimientos y experimentar ' +
        'necesidades, tanto propias como ajenas, sino que tales "conclusiones" pueden además afectarnos a todos si a los hombres se les enseña ' +
        'a "ser duros," y con ello acaban actuando de forma agresiva o desconsiderada hacia otras personas. ' +
        'A los hombres también les puede ser negada la igualdad en cuanto a la crianza de los hijos, por ejemplo, o respecto a otros roles ' +
        'que históricamente se hayan venido asignando a las mujeres. Cuando se reflexiona sobre tales cuestiones, es ' +
        'importante entender cómo los argumentos basados en supuestas diferencias biológicas pueden ' +
        'utilizarse para limitar oportunidades y formas de ser a hombres y mujeres, con consecuencias para ' +
        'todos nosotros.',
    'app.individualreport.gendertradition.textB': '<b>En segundo lugar, en términos de género, ' +
        'tiendes a tener creencias y valores equilibrados en cuanto a lo que hombres y mujeres son, y cómo deberían ' +
        'ser; no te convence pensar que los hombres y mujeres estén meramente "hechos para ser de una determinada manera." Puede que ' +
        'aceptes que existen diferencias entre hombres y mujeres, pero reconoces que la cuestión ' +
        'más importante es, ¿por qué existen esas diferencias para empezar?</b> Es decir, ' +
        '¿son estas diferencias producto del "ambiente" o la "genética" o una combinación de ambos? ' +
        'Hay pruebas en ambos sentidos, lo que sugiere que las diferencias entre hombres y mujeres se deben ' +
        'a la compleja interacción entre procesos culturales y educativos así como a diferencias biológicas ' +
        'subyacentes. Es importante reconocer que las explicaciones biológicas a estas diferencias ' +
        'se han utilizado como forma de negarle a mujeres de todas las edades derechos durante años, desde la educación al ' +
        'derecho a voto o el control de su propia sexualidad. Sin embargo, los estereotipos son un arma de doble filo. Por ' +
        'ejemplo, los hombres pueden considerarse "menos capaces" para criar a los hijos o expresar emociones, y por tanto menos capaces' +
        'de criar a los hijos. Cuando se reflexiona sobre tales cuestiones, es importante leer y enterarse mejor ' +
        'de lo que realmente sabemos (y lo que no sabemos también) sobre las diferencias de género, máxime cuando muchos expertos ' +
        'defienden que, como seres humanos, mujeres y hombres presentan más parecidos que diferencias.',
    'app.individualreport.gendertradition.textC': '<b>En segundo lugar, en términos de género, ' +
        'tiendes a tener creencias y valores más progresistas en cuanto a lo que hombres y mujeres ' +
        'son y cómo deberían ser; probablemente rechazas la idea de que hombres y mujeres están "hechos para ser ' +
        'de cierta manera." Reconoces que existen diferencias entre hombres y mujeres; todos lo sabemos y ' +
        'constatamos cada día. Pero la pregunta es, ¿por qué existen tales diferencias ' +
        'para empezar? Es decir, ¿son estas diferencias producto del "ambiente" ' +
        'o la "genética" o una combinación de ambos? Por tus respuestas, tiendes a creer que las diferencias ' +
        'de género se deben más probablemente a factores ambientales y culturales subyacentes.</b> ' +
        'Aunque el debate académico sobre el tema es muy complejo, existen pruebas en ambos ' +
        'sentidos, lo que sugiere que las diferencias entre hombres y mujeres se deben a la compleja interacción ' +
        'entre procesos culturales y educativos así como a diferencias biológicas. Al mismo tiempo, ' +
        'no obstante, estas diferencias se han utilizado como forma de negarle a mujeres de todas las edades muchos ' +
        'derechos durante años, desde la educación al voto o el control de su propia sexualidad, y ' +
        'los "argumentos biológicos" se han utilizado a menudo para justificar la privación de derechos a las mujeres. ' +
        'Los estereotipos son un arma de doble filo, sin embargo, dado que los hombres pueden considerarse "menos capaces" ' +
        'para criar a los hijos o expresar emociones, lo que significa que no solo les puede ser negado el "derecho" a tener sentimientos ' +
        'y experimentar necesidades, tanto propias como ajenas, sino que también les puede ser negada la igualdad en cuanto a ' +
        'la crianza de los hijos, por ejemplo, o respecto a otros roles que históricamente se hayan venido asignando a las mujeres. ' +
        'Cuando se reflexiona sobre tales cuestiones, es importante entender cómo los argumentos ' +
        'basados en supuestas diferencias biológicas se han utilizado para limitar oportunidades y formas ' +
        'de ser a hombres y mujeres, con consecuencias para todos nosotros, a nivel individual o social. ' +
        'Por tanto, es importante leer y enterarse mejor de lo que realmente sabemos (y lo que no sabemos también) ' +
        'sobre las diferencias de géneros, máxime cuando muchos expertos creen que, como seres humanos, mujeres y hombres ' +
        'presentan más parecidos que diferencias.',
}

const individualReportVariables: IndividualReportVariablesMessages = {
    'app.individualreport.variables.textE_1': '<b>En tercer lugar, en términos culturales y ' +
        'políticos, se te podría describir como abierto y progresista. ' +
        'Pensar y ser de esta manera puede estar muy bien, y probablemente seas un entusiasta ' +
        'de las ideas, puntos de vista, circunstancias y gente nuevas o diferentes, ' +
        'y que experimentes cierta sensación de euforia cuando lo haces.  Puede que también busques promover el cambio en tu vida, ' +
        'tu trabajo y en la sociedad en general, y que te irrite la gente que no comparte tus ' +
        'convicciones.</b> De nuevo, es importante entender por qué otras personas no piensan o se sienten ' +
        'igual que tú. Por ejemplo, sistemas, culturas o formas de ser diferentes a la propia pueden ser ' +
        'fuente de ansiedad para algunas personas porque las creencias y valores imperantes en estos sistemas, culturas o ' +
        'formas de ser puedan percibirse como una amenaza a su modo de vida. Si no coincides en esto, ' +
        'es fácil que acabes por tachar a estos individuos de simplistas  ' +
        'o intransigentes. En su lugar, interésate por los contextos personales y experiencias' +
        'de los individuos que piensan y sienten de forma distinta a la tuya. Puede que sean más ' +
        'más abiertos de lo que parece, pero les cueste entender creencias y ' +
        'valores ajenos a la que es su costumbre. Si se hace el esfuerzo, ' +
        'puede que sea posible entender por qué tanto ellos, como todos nosotros, creemos y valoramos ' +
        'las cosas de la manera que lo hacemos. ',
    'app.individualreport.variables.textE_2': '',
    'app.individualreport.variables.textF': '<b>En tercer lugar, en términos culturales y ' +
        'políticos, se te podría describir como más "moderado" o "equidistante" que ' +
        'tus congéneres, quienes podrían parecerte algo más progresistas o conservadores que tú, cuando no mucho más. ' +
        'Esta postura tiene ventajas, ya que tiendes al entendimiento o a apreciar ' +
        'el valor a perspectivas o ideas poco habituales. Por otra parte, quizá esta postura ' +
        'signifique que aún no has analizado estos asuntos detenidamente, te producen cierta indiferencia, o simplemente ' +
        'no tienes clara tu opinión al respecto.</b> En función de cómo encaje esta perspectiva ' +
        'con tu yo actual, podría venirte bien acostumbrarte a reflexionar con detenimiento' +
        'sobre el porqué de tus creencias en materia cultural, política, racial o de género. Establecer ' +
        'puentes de diálogo con amigos y colegas cuyas razones para creer lo que creen son más estridentes' +

        'que las tuyas puede ser no solo interesante, sino servirte para entender mejor los fundamentos  ' +
        'de tus propias creencias. Además, puede que descubras que tu propia postura le ha servido ' +
        'para reflexionar a quienes muestran mayor "convencimiento" que tú sobre las razones ' +
        'por las que sus convicciones son tan marcadas. En cualquiera de los dos casos, tales introspecciones y acercamientos' +
        'pueden servirte para definir tus creencias y valores sobre estos asuntos cruciales que nos afectan a todos.',
    'app.individualreport.variables.textG_1': '<b>En tercer lugar, en términos políticos y culturales ' +
        'se te podría describir como más tradicional, prudente o cauto que tus congéneres en cuanto ' +
        'a tu visión sobre la necesidad o el valor de introducir cambios drásticos, ya sea local o globalmente. Es posible ' +
        'que te parezca que la introducción escalonada de cambios como forma de trabajo se está cuestionando e incluso desautorizando, ' +
        'sin la debida consideración por las consecuencias de enfoques opuestos (e.g., para las familias, el trabajo, ' +
        'la sociedad), o por los costes económicos de las correspondientes políticas sociales. Es posible que te irriten ' +
        'las personas que no comparten tus mismas convicciones, dándote la impresión de ser ingenuos o ' +
        'irresponsables en su defensa de tales políticas.</b> De nuevo, es importante entender por qué razón otras personas ' +
        'pueden no pensar o sentirse como tú. Por ejemplo, quizá haya personas que opinan que ' +
        'las creencias y formas de ser "tradicionales" no son más que maneras de controlar a quienes ostentan menos poder, ' +
        'recursos o derechos. Tales creencias pueden, en su caso, tener origen en su propia historia vital o ' +
        'en circunstancias que ellos u otras personas que conocen han vivido. Si no coincides en esto ' +
        ',es fácil que acabes por tachar a estos individuos de quejicas o simplistas.  ' +
        'En su lugar, interésate por los contextos personales y experiencias de los individuos que piensan ' +
        'y sienten de manera tan distinta a la tuya. Puede que estén más abiertos a tus creencias de lo que esperas, ' +
        'y más dispuestos a mantener un debate honesto y profundo (siempre que se produzca de manera respetuosa ' +
        'y bien intencionada) de lo que puedas imaginar. Hasta puede que les convenza la sensatez ' +
        'de tu postura, igual que a ti puede convencerte la pertinencia de la suya. En última instancia, tales ' +
        'acercamientos al "otro" pueden fomentar la buena voluntad y el respeto mútuos, al tiempo que nos sirven ' +
        'para entender mejor por qué tanto ellos como todos nosotros creemos y valoramos las cosas de la forma ' +
        'en que lo hacemos.',
    'app.individualreport.variables.textG_2': '',
}

const individualEcologicalResonance: IndividualReportEcologicalResonanceMessages = {
    'app.individualreport.ecologicalresonance.textA': '<b>En términos de actitud ' +
        'hacia el medio ambiente, pareces muy afín a la idea de conectar con el planeta, y tiendes a considerarlo ' +
        'nuestro único hogar, al que debemos proteger y preservar. Por tanto, te puede resultar frustrante ' +
        'que tan poca gente parezca compartir tus convicciones, máxime cuando hay pruebas ' +
        'tan evidentes de que debemos cuidar mejor de lo que lo hacemos a los seres vivos.</b> ' +
        'Cuando se reflexiona sobre estas cuestiones, no se ha de olvidar que distintos grupos, culturas y ' +
        'religiones diferirán en su forma de ver la relación entre el ser humano, la naturaleza y la Tierra ' +
        'de distintas formas que consideran absolutamente legítimas. Es importante, en todo caso, tener en cuenta ' +
        'los fundamentos de tales diferencias y de nuestra tendencia a tomarnos tan a pecho ' +
        'estos asuntos. Esto se debe a que nuestras creencias y valores sobre ecologismo, y la forma en que ' +
        'individuos, organizaciones, gobiernos y sociedades viven, entienden y se identifican con la naturaleza y ' +
        'el mundo naturales, tienen profundas implicaciones para la acción, las políticas y las prácticas medioambientales ' +
        'en todo el globo. En la medida que puedas, intenta entablar un diálogo abierto y respetuoso con los demás ' +
        'sobre estos asuntos sin remitirte únicamente a "los hechos" como tú los entiendes. En otras ' +
        'palabras, comparte tus convicciones, no solo desde un punto de vista intelectual (que por supuesto es ' +
        'fundamental) sino también en términos de por qué te sientes como te sientes. Es más probable que este enfoque fomente ' +
        'una reflexión sincera sobre cuestiones medioambientales, que pudieran parecer abstractas, pero que en realidad tienen ' +
        'una intensa carga moral, ya que su razón de ser "nace" de nuestra postura sobre por qué estamos en el planeta ' +
        'y cuál es nuestra obligación, si consideramos que la hay, hacia el mundo natural.',
    'app.individualreport.ecologicalresonance.textB': '<b>En términos de actitud hacia el ' +
        'medio ambiente, parecer estar abierto a los argumentos a favor de la necesidad de proteger los recursos naturales ' +
        'del planeta y probablemente te describirías como "moderado" en cuanto a cuestiones medioambientales ' +
        'en general. Por tanto, puede que te llame la atención la intensidad de las creencias, en uno u ' +
        'otro sentido, que la gente tiene sobre este asunto.</b> Estas diferencias de opinión te pueden servir para conocer ' +
        'mejor tus propias creencias en este ámbito, y para definir con claridad los fundamentos de tus convicciones, ' +
        'o falta de ellas, sobre estas cuestiones cruciales. Cuando se reflexiona sobre estos asuntos, no se ha de ' +
        'olvidar que distintos grupos, culturas y religiones diferirán en su forma de ver la relación entre el ser humano,' +
        'la naturaleza y la Tierra de distintas formas que consideran absolutamente ' +
        'legítimas. También puede ser útil, de uno u otro modo, tener en cuenta el porqué de nuestras diferencias de opinión ' +
        'y nuestra tendencia a tomarnos tan a pecho estos asuntos. Esto se debe a que ' +
        'nuestras creencias y valores sobre ecologismo, y la forma en que individuos, organizaciones, gobiernos y sociedades ' +
        'viven, entienden y se identifican con el mundo natural, tienen profundas implicaciones para la acción, ' +
        'las políticas y las prácticas medioambientales en todo el globo. En la medida que puedas, intenta entablar un diálogo ' +
        'abierto y respetuoso con los demás sobre estos asuntos sin remitirte únicamente a "los hechos" como ' +
        'tú los entiendes. En otras palabras, comparte tus convicciones, no solo desde un punto de vista intelectual ' +
        '(que por supuesto es fundamental) sino también en términos de por qué te sientes como te sientes.' +
        'Es más probable que este enfoque fomente una reflexión sincera sobre cuestiones medioambientales, que pudieran parecer ' +
        'abstractas, pero que en realidad tienen una intensa carga moral, ya que su razón de ser "nace" de nuestra postura sobre ' +
        'por qué estamos en el planeta y cuál es nuestra obligación, si consideramos que la hay, hacia el mundo natural.',
    'app.individualreport.ecologicalresonance.textC': '<b>En términos de actitud ' +
        'hacia el medio ambiente, pareces escéptico en general tanto hacia la gente que afirma tener mucha afinidad con ' +
        'la idea de conectar con el planeta como hacia quienes expresan su preocupación por el estado del mundo ' +
        'natural. Si tiendes a ser escéptico hacia las consignas ecologistas, puede venirte bien ' +
        'encontrar posturas que se aspiren a la imparcialidad, y comprobar lo que la ciencia nos dice actualmente ' +
        'sobre estas cuestiones de gran importancia y no menos complejidad. Merece la pena recordar también' +
        'que existen numerosos grupos por todo el mundo, incluidas organizaciones religiosas o basadas en un credo, ' +
        'que admiten cada vez más la importancia de velar por el planeta y el mundo natural.</b> ' +
        'Cuando se reflexiona sobre estas cuestiones, no se ha de olvidar que distintos grupos, culturas y ' +
        'religiones diferirán en su forma de ver la relación entre el ser humano, la naturaleza y la Tierra ' +
        'de distintas formas que consideran absolutamente legítimas. Es importante, en todo caso, tener en cuenta ' +
        'los fundamentos de tales diferencias y de nuestra tendencia a tomarnos tan a pecho ' +
        'estos asuntos. Esto se debe a que nuestras creencias y valores sobre ecologismo, y la forma en que ' +
        'individuos, organizaciones, gobiernos y sociedades viven, entienden y se identifican con el mundo ' +
        'natural, tienen profundas implicaciones para la acción, las políticas y las prácticas medioambientales ' +
        'en todo el globo. En la medida que puedas, intenta entablar un diálogo abierto y respetuoso con los demás ' +
        'sobre estos asuntos sin remitirte únicamente a "los hechos" como tú los entiendes. En otras ' +
        'palabras, comparte tus convicciones, no solo desde un punto de vista intelectual (que por supuesto es ' +
        'fundamental) sino también en términos de por qué te sientes como te sientes. Es más probable que este enfoque fomente ' +
        'una reflexión sincera sobre cuestiones medioambientales, que pudieran parecer abstractas, pero que en realidad tienen ' +
        'una intensa carga moral, ya que su razón de ser "nace" de nuestra postura sobre por qué estamos en el planeta ' +
        'y cuál es nuestra obligación, si consideramos que la hay, hacia el mundo natural.',
}

const individualGlobalEngagement: IndividualReportGlobalEngagementMessages = {
    'app.individualreport.globalengagement.textA': 'El último gran asunto a considerar es tu postura ' +
        'en cuanto a la globalidad o resonancia en su más amplio sentido. <b>En general, ' +
        'pareces apoyar enérgicamente la necesidad de mantener relaciones de mutuo entendimiento y respeto hacia, ' +
        'culturas, sociedades y tradiciones distintas a la nuestra. De tu postura se deduce ' +
        'que tienes tendencia a aprovechar oportunidades y experiencias interculturales y globales siempre que la ocasión ' +
        'se te presenta.</b> Sea cual sea tu postura en este asunto, es importante reflexionar sobre el hecho ' +
        'de que el mundo está cada vez más interconectado. Por ejemplo, lo que pasa en una país (i. e., ' +
        'a nivel económico, político, medioambiental) puede tener consecuencias en otro aunque la gran ' +
        'mayoría de habitantes en ambos países no hayan tenido mucho contacto directo entre ellos. Además, ' +
        'por medio de la tecnología, somos capaces de participar de e influir en sucesos globales en el instante en que suceden.  ' +
        'Por tanto, nuestras creencias y valores sobre otras personas (i. e., diferentes culturas, religiones) están ligados ' +
        'irremediablemente a cómo lidiamos y nos vemos a nosotros mismos y a otras personas, además de cómo ' +
        'entendemos y respondemos a desafíos y oportunidades diversas por todo el mundo, con las ' +
        'correspondientes implicaciones para todos nosotros.',
    'app.individualreport.globalengagement.textB': 'El último gran asunto a considerar es tu postura ' +
        'en cuanto a la globalidad o resonancia en su más amplio sentido. <b>En general, ' +
        'pareces apreciar la necesidad de mantener relaciones de mutuo entendimiento y respeto hacia culturas, ' +
        'sociedades y tradiciones distintas a la nuestra. De tu postura se deduce que ' +
        'también sentirías curiosidad por oportunidades y experiencias interculturales y globales si se te presentase la oportunidad.</b>' +
        'Sea cual sea tu postura en este asunto, es importante reflexionar sobre el hecho ' +
        'de que el mundo está cada vez más interconectado. Por ejemplo, lo que pasa en una país (i. e., ' +
        'a nivel económico, político, medioambiental) puede tener consecuencias en otro aunque la gran ' +
        'mayoría de habitantes en ambos países no hayan tenido mucho contacto directo entre ellos. Además, ' +
        'por medio de la tecnología, somos capaces de participar de e influir en sucesos globales en el instante en que suceden.  ' +
        'Por tanto, nuestras creencias y valores sobre otras personas (i. e., diferentes culturas, religiones) están ligados ' +
        'irremediablemente a cómo lidiamos y nos vemos a nosotros mismos y a otras personas, además de cómo ' +
        'entendemos y respondemos a desafíos y oportunidades diversas por todo el mundo, con las ' +
        'correspondientes implicaciones para todos nosotros.',
    'app.individualreport.globalengagement.textC': 'El último gran asunto a considerar es tu postura ' +
        'en cuanto a la globalidad o resonancia en su más amplio sentido. <b>En general, ' +
        'pareces cuestionarte si a veces (e incluso a menudo) vamos demasiado lejos en nuestros intentos de ' +
        'tolerar, apaciguar e involucrarnos con culturas, sociedades y tradiciones distintas a la nuestra.</b> ' +
        'Sea cual sea tu postura en este asunto, es importante reflexionar sobre el hecho ' +
        'de que el mundo está cada vez más interconectado. Por ejemplo, lo que pasa en una país (i. e., ' +
        'a nivel económico, político, medioambiental) puede tener consecuencias en otro aunque la gran ' +
        'mayoría de habitantes en ambos países no hayan tenido mucho contacto directo entre ellos. Además, ' +
        'por medio de la tecnología, somos capaces de participar de e influir en sucesos globales en el instante en que suceden.  ' +
        'Por tanto, nuestras creencias y valores sobre otras personas (i. e., diferentes culturas, religiones) están ligados ' +
        'irremediablemente a cómo lidiamos y nos vemos a nosotros mismos y a otras personas, además de cómo ' +
        'entendemos y respondemos a desafíos y oportunidades diversas por todo el mundo, con las ' +
        'correspondientes implicaciones para todos nosotros.',
}

const individualReportNegativeLifeEvents: IndividualReportNegativeEventsMessages = {
    'app.individualreport.negativelifeevents.low': 'bajo',
    'app.individualreport.negativelifeevents.moderate': 'moderado',
    'app.individualreport.negativelifeevents.high': 'alto',
}

const individualReportNeedsClosure: IndividualReportNeedsClosureMessages = {
    'app.individualreport.needsclosure.met_well': 'completamente satisfechas',
    'app.individualreport.needsclosure.met_at_times': 'satisfechas en ocasiones pero no sistemáticamente',
    'app.individualreport.needsclosure.not_met_well': 'no satisfechas del todo',
}

export const individualReportPageES: IndividualReportMessages = {
    ...individualReportDefault, ...individualReportDemographics, ...individualReportFormativeCore,
    ...individualReportToleranceDisequilibrium, ...individualReportWhyWedo,
    ...individualReportTitle,
    ...individualReportAccessSelf, ...individualReportAccessOthers,
    ...individualReportGenderTradition, ...individualReportVariables,
    ...individualEcologicalResonance, ...individualGlobalEngagement,
    ...individualReportNegativeLifeEvents, ...individualReportNeedsClosure
};
