import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    FormDetails, InitializeOrRestartFormResponse, initializeForms, listForms, ListFormsResponse, restartForm, FormDetailsExtraQuestion
} from '../services/listFormsService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';
import { ValidateExternalToken, ValidateToken } from '../utils/validateHelper';
import { DemographicsQuestionsConfigurationModel } from '../services/organizationService';

// actions
const actionsLiterals = asLiterals([
    'list','initializeForm','setFormStatus','setFormId', 'setHideQuestions', 'clearSelectedForm', 'setShowReport', 'updateFormPercentage',
    'restartForm', 'setExtraQuestions'
]);
export type FormsActions = { [K in (typeof actionsLiterals)[number]]: any };
export const formsActionsConstants: FormsActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals(['setListForms','setError', 'setRestartFormSuccessful']);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'loading', 'formId', 'hideQuestions', 'formStatus', 'keyErrorMessage', 'formList', 'showReport', 'formPercentageCompleted',
    'loadingRestart', 'successfullRestart', 'extraQuestions'
]);
export type FormsProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const formsPropsConstants: FormsProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: FormsActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchListFormRequest: () => void;
        fetchInitializeFormRequest: () => void;
        fetchRestartFormRequest: () => void;
    }
};

type InitializeOrRestartFormRaceResponse = {
    initializeOrRestartFormResponse?: InitializeOrRestartFormResponse;
}

// Define payloads type.
type FormIdPayload = { formId: number; };
type HideQuestionsPayload = { hideQuestions: DemographicsQuestionsConfigurationModel; };
type ShowReportPayload = { showReport: boolean };
type FormStatusPayload = { status: number; };
type FormExtraQuestionsPayload = { extraQuestions: FormDetailsExtraQuestion[] };
type ErrorPayload = { errorKey: string; };
type UpdateFormPErcentagePayload = { percentage: number; };

type ListFormsPayload = { forms: FormDetails[] };
type RestartFormPayloadRequest = { payload: FormIdPayload };

const HideQuestionsPropType = PropTypes.shape({
    organizationId: PropTypes.number,
    age: PropTypes.bool,
    gender: PropTypes.bool,
    completedYearOfEducation: PropTypes.bool,
    maritalStatus: PropTypes.bool,
    ethnicBackground: PropTypes.bool,
    motherCountry: PropTypes.bool,
    fatherCountry: PropTypes.bool,
    memberOfDisadvantagedOrMinorityGroup: PropTypes.bool,
    primaryCountryOfOrigin: PropTypes.bool,
    timeInOtherCountry: PropTypes.bool,
    visitedOrLivedCountries: PropTypes.bool,
    politicalOrientation: PropTypes.bool,
    politicalCommitment: PropTypes.bool,
    motherFormalEducation: PropTypes.bool,
    fatherFormalEducation: PropTypes.bool,
    primaryAreaOrWorkOrProfessionalInterest: PropTypes.bool,
    primaryLanguages: PropTypes.bool,
    totalYearsStudiedForeignLanguage: PropTypes.bool,
    incomeLevelOfHomeCountry: PropTypes.bool,
    academicStandingOrRank: PropTypes.bool,
    currentBeliefsOrCommitmentsRegardingReligion: PropTypes.bool,
    commitmentTowardFaithReligions: PropTypes.bool,
    interestInInternationalOrInterculturalLearning: PropTypes.bool,
    satisfactionLevelInternationalExperienceProgram: PropTypes.bool,
    participatedExperiencesCapabilities: PropTypes.bool,
    plansToParticipateExperiencesCapabilities: PropTypes.bool,
    participatedLearningCoursesOrProgramsCapabilities: PropTypes.bool,
    plansToParticipateLearningCoursesOrProgramsCapabilities: PropTypes.bool,
});

type ListFormsRaceResponse = {
    listFormsResponse?: ListFormsResponse;
}

const FormItemPropType = PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.number,
    percentageCompleted: PropTypes.number,
    organizationName: PropTypes.string,
    programName: PropTypes.string,
    subProgramName: PropTypes.string,
    availableDate: PropTypes.string,
    currentScalePage: PropTypes.number,
    showReport: PropTypes.bool,
});

const emptyFormList: FormDetails[] = [];

// persist forms reducer
export const formKeaPath = 'forms';

export const formReducer = keaReducer(formKeaPath);

export const formLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [formKeaPath],

    actions: () => ({
        [formsActionsConstants.list]: true,
        [formsActionsConstants.initializeForm]: true,
        [formsActionsConstants.restartForm]: (formId: number): FormIdPayload => ({ formId }),
        [formsActionsConstants.clearSelectedForm]: true,
        [formsActionsConstants.setFormStatus]: (status: number): FormStatusPayload => ({ status }),
        [formsActionsConstants.setFormId]: (formId: number): FormIdPayload => ({ formId }),
        [formsActionsConstants.setHideQuestions]: (hideQuestions: DemographicsQuestionsConfigurationModel): HideQuestionsPayload => ({ hideQuestions }),
        [formsActionsConstants.setExtraQuestions]: (extraQuestions: FormDetailsExtraQuestion[]): FormExtraQuestionsPayload => ({ extraQuestions }),
        [formsActionsConstants.setShowReport]: (showReport: boolean): ShowReportPayload => ({ showReport }),
        [formsActionsConstants.updateFormPercentage]: (percentage: number): UpdateFormPErcentagePayload => ({ percentage }),
        [privateActionsConstants.setRestartFormSuccessful]: true,
        [privateActionsConstants.setError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setListForms]: 
            (forms: FormDetails[]) : ListFormsPayload => ({ forms })
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [formsPropsConstants.formList]: [emptyFormList, PropTypes.arrayOf(FormItemPropType), {
            [actions.setListForms]: (_: FormDetails[], payload: ListFormsPayload) => payload.forms,
            [actions.setError]: () => emptyFormList,
            [logout]: () => emptyFormList
        }],
        [formsPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.list]: () => true,
            [actions.setListForms]: () => false,
            [actions.setFormId]: () => false,
            [actions.setHideQuestions]: () => false,
            [actions.setExtraQuestions]: () => false,
            [actions.setFormStatus]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [formsPropsConstants.formStatus]: [null, PropTypes.number, {
            [actions.setFormStatus]: (_: number, payload: FormStatusPayload) => payload.status,
            [actions.clearSelectedForm]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [formsPropsConstants.formId]: [null, PropTypes.number, {
            [actions.setFormId]: (_: number, payload: FormIdPayload) => payload.formId,
            [actions.clearSelectedForm]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [formsPropsConstants.hideQuestions]: [null, PropTypes.objectOf(HideQuestionsPropType), {
            [actions.setHideQuestions]: (_: DemographicsQuestionsConfigurationModel, payload: HideQuestionsPayload) => payload.hideQuestions,
            [actions.clearSelectedForm]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [formsPropsConstants.extraQuestions]: [null, PropTypes.objectOf(HideQuestionsPropType), {
            [actions.setExtraQuestions]: (_: FormDetailsExtraQuestion, payload: FormExtraQuestionsPayload) => payload.extraQuestions,
            [actions.clearSelectedForm]: () => null,
            [actions.setError]: () => null,
            [logout]: () => null
        }],
        [formsPropsConstants.keyErrorMessage]: [null, PropTypes.string, {
            [actions.list]: () => null,
            [actions.setFormId]: () => null,
            [actions.setHideQuestions]: () => null,
            [actions.setExtraQuestions]: () => null,
            [actions.setFormStatus]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null
        }],
        [formsPropsConstants.showReport]: [false, PropTypes.bool,{
            [actions.setShowReport]: (_: boolean, payload: ShowReportPayload) => payload.showReport,
            [actions.clearSelectedForm]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [formsPropsConstants.formPercentageCompleted]: [0, PropTypes.number, {
            [actions.updateFormPercentage]: (_: number, payload: UpdateFormPErcentagePayload) => payload.percentage,
            [logout]: () => 0
        }],
        [formsPropsConstants.loadingRestart]: [false, PropTypes.bool, {
            [actions.restartForm]: () => true,
            [actions.setRestartFormSuccessful]: () => false,
            [actions.setError]: () => false,
            [logout]: () => false
        }],
        [formsPropsConstants.successfullRestart]: [false, PropTypes.bool, {
            [actions.restartForm]: () => false,
            [actions.setRestartFormSuccessful]: () => true,
            [actions.setError]: () => false,
            [actions.list]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.list]: workers.fetchListFormRequest,
        [actions.initializeForm]: workers.fetchInitializeFormRequest,
        [actions.restartForm]: workers.fetchRestartFormRequest,
    }),

    workers: {
        * fetchListFormRequest(): any {

            //@ts-ignore
            const { setError, setListForms } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { listFormsResponse }: ListFormsRaceResponse = yield race({
                listFormsResponse: call(listForms, token),
                timeout: delay(requestTimeout)
            });

            if (listFormsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (listFormsResponse?.status === successfulCode) {
                if (listFormsResponse?.body) {
                    yield put(setListForms(listFormsResponse?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (listFormsResponse?.keyErrorMessage) {
                yield put(setError(listFormsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchInitializeFormRequest(): any {
            //@ts-ignore
            const { setError, setFormId, updateFormPercentage } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token) && !ValidateExternalToken(token)) {
                yield put(logout());
                return;
            }
            
            //@ts-ignore
            const formId  = yield this.get(formsPropsConstants.formId);

            const { initializeOrRestartFormResponse: initializeForm }: InitializeOrRestartFormRaceResponse = yield race({
                initializeOrRestartFormResponse: call(initializeForms, token, formId),
                timeout: delay(requestTimeout)
            });

            if (initializeForm?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (initializeForm?.status === successfulCode) {
                if (initializeForm?.body) {
                    // We will use the body (result property) to something?
                    yield put(setFormId(formId));
                    yield put(updateFormPercentage(initializeForm?.body));
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (initializeForm?.keyErrorMessage) {
                yield put(setError(initializeForm.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchRestartFormRequest(action: RestartFormPayloadRequest): any {
            //@ts-ignore
            const { setError, setRestartFormSuccessful } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }
            
            const { formId } = action.payload;
            const { initializeOrRestartFormResponse: restartFormResponse }: InitializeOrRestartFormRaceResponse = yield race({
                initializeOrRestartFormResponse: call(restartForm, token, formId),
                timeout: delay(requestTimeout)
            });

            if (restartFormResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (restartFormResponse?.status === successfulCode) {
                if (restartFormResponse?.body) {
                    yield put(setRestartFormSuccessful());
                } else {
                    yield put(setError(generalExceptionKey));
                }
            } else if (restartFormResponse?.keyErrorMessage) {
                yield put(setError(restartFormResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
