import { PermissionMessages } from '../types';

export const permissionJA: PermissionMessages = {
  'app.permission.title': '(JA) Permission - Individual Report - Profile',
  'app.permission.remove_permission_to_all': '(JA) Remove permission to all',
  'app.permission.give_permission_to_all': '(JA) Give permission to all',
  'app.permission.remove_permission': '(JA) Remove permission',
  'app.permission.give_permission': '(JA) Give permission',
  'app.permission.table_title.permission': '許可',
  'app.permission.table_title.name': '(JA) Name',
  'app.permission.table_title.email': '(JA) Email',
  'app.permission.button.update': '(JA) Update',
  'app.permission.message': '(JA) Users successfully updated',
  'app.permission.add_permissions_with_excel_file': '(JA) Add permissions with excel file',
  'app.permission.add_permissions': '(JA) Add permissions',
  'app.permission.permissions': '(JA) Permissions',
  'app.permission.download_tooltip': '(JA) Download file template'
}
