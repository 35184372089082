import React, { FormEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { FormDropdown, SelectItem } from '../../components/FormDropdown';
import { Loading } from '../../components/Loading';
import { GetFormattedMessage, getLanguages } from '../../utils/htmlHelper';
import { validateEmail } from '../../utils/validateHelper';

type Props = {
    institutionName: string;
    programName: string;
    subProgramName: string;
    loading: boolean;
    onSubmitButtonClicked: (name: string, email: string, password: string, language: number) => void;
}

type FormProps = {
    name?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    language?: number
}

type FormErrorProps = { [K in (keyof FormProps)[number]]?: string };

export const Invitation = (props: Props): JSX.Element => {
    const inviteLabelTemplate = GetFormattedMessage('app.invitation.invite_label_template');
    const submitButtonText = GetFormattedMessage('app.btn_submit');
    const blankFeedbackText = GetFormattedMessage('app.invitation.blank_feedback');
    const invalidEmailFeedbackText = GetFormattedMessage('app.invitation.invalid_email_feedback');
    const passwordLengthFeedbackTemplate = GetFormattedMessage('app.invitation.password_length_feedback_template');
    const passwordsNotEqualFeedbackText = GetFormattedMessage('app.invitation.confirm_password_not_equal_feedback');

    const [form, setForm] = useState<FormProps>({});
    const [errors, setErrors] = useState<FormErrorProps>({});

    const findFormErrors = () => {
        const { name, email, password, confirmPassword, language } = form;
        const newErrors: FormErrorProps = {}
        if (!name || name === '') newErrors.name = blankFeedbackText;
        if (!email || !validateEmail(email)) newErrors.email = invalidEmailFeedbackText;
        if (!password || password.length < 4) newErrors.password = passwordLengthFeedbackTemplate.replace("{0}", "4");
        if (confirmPassword !== password) newErrors.confirmPassword = passwordsNotEqualFeedbackText;
        if (!confirmPassword) newErrors.confirmPassword = blankFeedbackText;
        if (!language) newErrors.language = blankFeedbackText;
        return newErrors
    };

    const handleLanguageChange = (language?: number) => {
        setErrors({ ...errors, language: !!language ? undefined : blankFeedbackText });
        setForm({ ...form, language: language });
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newErrors = findFormErrors();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.onSubmitButtonClicked(
                form.name ?? '',
                form.email ?? '',
                form.password ?? '',
                form.language ?? 0
            );
        }
    }

    const languageOptions = getLanguages();

    return (
        <React.Fragment>
            <div>
                <text>
                    {inviteLabelTemplate
                        .replace("{0}", props.institutionName)
                        .replace("{1}", props.programName)
                        .replace("{2}", props.subProgramName)
                    }
                </text>
                <Form noValidate={true} onSubmit={handleSubmit} className="mt-3">
                    <Form.Group className='mb-3' style={styles.group}>
                        <Form.Label>
                            <small>{GetFormattedMessage('app.invitation.name')}</small>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={form.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setForm({ ...form, name: e.currentTarget?.value })
                            }}
                            isInvalid={!!errors.name}
                            disabled={props.loading}
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.name }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' style={styles.group}>
                        <Form.Label>
                            <small>{GetFormattedMessage('app.invitation.language')}</small>
                        </Form.Label>
                        <FormDropdown
                            value={languageOptions.filter((item: SelectItem) => item.value === form.language)}
                            defaultOptionId={'app.dropdown_select'}
                            onValueSelect={(value: number | undefined, _: string) => handleLanguageChange(value)}
                            optionItens={languageOptions}
                            isInvalid={!!errors.language}
                            isDisabled={props.loading}
                        />
                        {!!errors.language && (
                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                {errors.language}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className='mb-3' style={styles.group}>
                        <Form.Label>
                            <small>{GetFormattedMessage('app.invitation.email')}</small>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={form.email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setForm({ ...form, email: e.target?.value })
                            }}
                            isInvalid={!!errors.email}
                            disabled={props.loading}
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.email }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' as={Row}>
                        <Form.Group className='mb-3' as={Col} style={styles.group}>
                            <Form.Label>
                                <small>{GetFormattedMessage('app.invitation.password')}</small>
                            </Form.Label>
                            <Form.Control
                                required
                                type="password"
                                value={form.password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setForm({ ...form, password: e.target?.value })
                                }}
                                isInvalid={!!errors.password}
                                disabled={props.loading}
                            />
                            <Form.Control.Feedback type='invalid'>
                                { errors.password }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' as={Col} style={styles.group}>
                            <Form.Label>
                                <small>{GetFormattedMessage('app.invitation.confirm_password')}</small>
                            </Form.Label>
                            <Form.Control
                                required
                                type="password"
                                value={form.confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setForm({ ...form, confirmPassword: e.target?.value })
                                }}
                                isInvalid={!!errors.confirmPassword}
                                disabled={props.loading}
                            />
                            <Form.Control.Feedback type='invalid'>
                                { errors.confirmPassword }
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Col} md={{ offset: 6, span: 6 }} className="pr-0 mb-3">
                        <Button
                            className="mt-2 py-2 w-100"
                            type="submit"
                            disabled={props.loading}
                        >
                            {!props.loading ? submitButtonText : <Loading />}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </React.Fragment>
    );
}

const styles = {
    group: {
        marginBottom: '5px'
    } as React.CSSProperties,
}
