import React from 'react';
import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { ExtraQuestionModel } from '../../services/types';
import { Popconfirm, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { IntlContext } from 'react-intl';
import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { FaEdit, FaTrash } from 'react-icons/fa';

type Props = {
    loading: boolean,
    data: ExtraQuestionModel[],
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setExtraQuestion: React.Dispatch<React.SetStateAction<ExtraQuestionModel | undefined>>,
    fetchDeleteExtraQuestion: (id: number) => void
};

export const DemographicExtraQuestions = (
    { loading, data, setShowModal, setExtraQuestion, fetchDeleteExtraQuestion }: Props
): JSX.Element => {
    const { messages } = React.useContext(IntlContext);

    return (
        <React.Fragment>
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <div style={styles.h2ProgramTitle}>
                        <h2>
                            {GetFormattedMessage('app.institutions.add.demographics_extra_questions')}
                        </h2>
                        <ButtonWithIcon
                            textId={'app.institutions.demographics_questions.add.title'}
                            onClick={() => setShowModal(true)}
                            icon={<IoMdAddCircleOutline />}
                        />
                    </div>
                </div>
            </div>
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <Table
                        dataSource={data}
                        rowKey={(record: ExtraQuestionModel) => record.id.toString()}
                        locale={{ emptyText: messages['app.empty.data'].toString() }}
                        loading={loading}
                    >
                        <Column
                            title={messages['app.institutions.demographics_questions.question'].toString()}
                            dataIndex='title'
                            key='title'
                            width='70%'
                        />
                        <Column
                            title={messages['app.institutions.demographics_questions.type'].toString()}
                            dataIndex='type'
                            key='type'
                            width='20%'
                            render={(type) => {
                                return messages[`app.institutions.demographics_questions.type_${type}`].toString()
                            }}
                        />
                        <Column
                            title={messages['app.institutions.list.action'].toString()}
                            key='action'
                            width='10%'
                            render={(value, row: any, index) => {
                                return <div>
                                    <div style={{ display: 'flex', gap: 12 }}>
                                        <div>
                                            <FaEdit style={{ cursor: 'pointer' }} onClick={() => {
                                                setExtraQuestion(row);
                                                setShowModal(true);
                                            }} />
                                        </div>
                                        <div>
                                            <Popconfirm onConfirm={() => fetchDeleteExtraQuestion(row.id)} title={GetFormattedMessage('app.institutions.demographics_questions.delete')} placement='bottom'>
                                                <FaTrash style={{ cursor: 'pointer' }} />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </div>
                            }}
                        />
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
}