import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': '未回答的问题',
    'app.questions.confirmation': '有未回答的问题，是否要继续？之后将不能回答那（些）问题。 ',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': '非常同意',
    'app.question.agree': '同意',
    'app.question.disagree': '不同意',
    'app.question.strongly_disagree': '非常不同意',
    'app.question.q1': '有时会对前进的道路感到迷茫。',
    'app.question.q2': '小时候经常被人取笑。',
    'app.question.q3': '有时会拿自己和别人比较。',
    'app.question.q4': '年轻人的性冒险是健康的。',
    'app.question.q5': '我经常和家人起冲突。',
    'app.question.q6': '我害怕一个人孤独的活着。',
    'app.question.q7': '要是父母能以不同的方式对我就好了。',
    'app.question.q8': '可以说女性越接近生理期就越情绪化。',
    'app.question.q9': '我们应该理解与自己不同的文化。',
    'app.question.q10': '母亲是或曾是我最好的朋友。',
    'app.question.q11': '我们最终会承认：男人与女人，终究不一样。',
    'app.question.q12': '女人的归属是家庭。',
    'app.question.q13': '我常被这个世界的美丽所征服。',
    'app.question.q14': '有些人会有更好的基因。',
    'app.question.q15': '偏见无处不在，我们必须克服它。',
    'app.question.q16': '我曾经历过痛苦的身份危机。',
    'app.question.q17': '一年我会哭几次。',
    'app.question.q18': '我总是从我的父母那里得到或可以得到很好的建议。',
    'app.question.q19': '人精神中的无意识部分会影响其情绪与行为。',
    'app.question.q20': '充分了解时事，这至关重要。',
    'app.question.q21': '男人就要有男人样。',
    'app.question.q22': '上帝，神灵或菩萨创造了现在的我。',
    'app.question.q23': '最终决定幸福还是不幸的是你自己。',
    'app.question.q24': '我感到在精神上与地球有着密切的联系。',
    'app.question.q25': '我度过了美好的幼年时代。',
    'app.question.q26': '有时会对自己不满。',
    'app.question.q27': '怀疑上帝，神灵或菩萨的存在是错误的。',
    'app.question.q28': '自己的问题,除了自己不能责备他人。',
    'app.question.q29': '女人比男人更感性。',
    'app.question.q30': '我喜欢深聊好几小时。',
    'app.question.q31': '我们应该提高最低工资。',
    'app.question.q32': '小时候，和父亲建立了良好和健康的关系。',
    'app.question.q33': '艾滋病可能是上天的惩罚。',
    'app.question.q34': '太多人试图获得免费分发。',
    'app.question.q35': '学校应该进行性教育。',
    'app.question.q36': '小时候，我目睹过太多的愤怒，争执或攻击。',
    'app.question.q37': '我想在人生中寻找更好的平衡感。',
    'app.question.q38': '我非常认真对待自己的感受。',
    'app.question.q39': '女人可能会变成难对付的竞争对手。',
    'app.question.q40': '我们不该否认自己的动物本性。',
    'app.question.q41': '回首过去，我有过一些令人尴尬的往事。',
    'app.question.q42': '因人际关系，我有过很多的冲突。',
    'app.question.q43': '对待社会问题，比起守旧或传统，我是一个更崇尚变革或开明的人。',
    'app.question.q44': '我会经常思考人生的意义。',
    'app.question.q45': '我有时会觉得无聊。',
    'app.question.q46': '年轻人尝试酒精和大麻是正常的。',
    'app.question.q47': '女人非常不擅长做决断。',
    'app.question.q48': '了解其他文化，有利于更好的理解自己的文化。',
    'app.question.q49': '通往天国或净土的路只有一条。',
    'app.question.q50': '人失败的大多原因在于其努力不够。',
    'app.question.q51': '我需要与父母保持距离。',
    'app.question.q52': '无论喜欢与否，男人永远是孩子。',
    'app.question.q53': '人类已经进化了数百万年。',
    'app.question.q54': '家人中，有人受到身体、性或情感上的虐待。',
    'app.question.q55': '我特别需要温暖和爱。',
    'app.question.q56': '我们应该在孩子的早期教育上投入更多的钱。',
    'app.question.q57': '我是非常感性的人。',
    'app.question.q58': '我支持工会活动。',
    'app.question.q59': '我担忧环境。',
    'app.question.q60': '被拒斥是痛苦的。',
    'app.question.q61': '我对父母没有不满。',
    'app.question.q62': '我有时会不确定自己是谁，将要去哪。',
    'app.question.q63': '我有时会优先考虑自己的情绪。 ',
    'app.question.q64': '女人比男人更爱说三道四。',
    'app.question.q65': '没有宗教就没有和平。',
    'app.question.q66': '不能否认男人比女人强。',
    'app.question.q67': '社会过于以消费者为中心。',
    'app.question.q68': '我的家人在金钱方面存在很多问题。',
    'app.question.q69': '为了更好地理解自己，我总在努力。',
    'app.question.q70': '强者必胜。',
    'app.question.q71': '男人比女人更理智。',
    'app.question.q72': '面对过去的痛苦是有用的。',
    'app.question.q73': '有时，男人也会和女人一样表现出软弱的一面。',
    'app.question.q74': '让男孩穿粉色衣服是错的。',
    'app.question.q75': '我喜欢看描述人际关系的电影。',
    'app.question.q76': '小时候，和母亲建立了良好关系。',
    'app.question.q77': '我担心地球的环境状态。',
    'app.question.q78': '我的身体对自己的情绪反应敏感。',
    'app.question.q79': '我的一举一动和父母一模一样。',
    'app.question.q80': '女人比男人更感情用事。',
    'app.question.q81': '我有强烈的宗教信仰。',
    'app.question.q82': '现在的我之所以会这样，是因为我生来就这样。',
    'app.question.q83': '我的父母常为夫妻关系争吵。',
    'app.question.q84': '我喜欢思考自己是谁。',
    'app.question.q85': '女性的地位这几十年一直在上升。',
    'app.question.q86': '父亲是我的英雄。',
    'app.question.q87': '我在寻找自己的生活方式上吃了很多苦。',
    'app.question.q88': '有时我会把事情想得太严重。',
    'app.question.q89': '我们应该相信“地球母亲”的理念。',
    'app.question.q90': '人生有时会感到很孤独。',
    'app.question.q91': '大多数的教授或老师，想法过于自由。',
    'app.question.q92': '真正的救赎只会来自崇高的力量。',
    'app.question.q93': '我有时会感到很无助和很脆弱，需要得到别人的关爱。',
    'app.question.q94': '我们的社会应该更帮助少数群体。',
    'app.question.q95': '学习多种语言对谁都有益。',
    'app.question.q96': '尽管我们期望他们那样，但男人并不是为了在婚姻中保持忠诚而造就的。',
    'app.question.q97': '有些事情连自己都不知道为什么会发生。',
    'app.question.q98': '与父亲有，或曾有过心理隔阂。',
    'app.question.q99': '我们应该选女性当我国首相。',
    'app.question.q100': '最终，我们会选择自己喜欢的方式去生活。',
    'app.question.q101': '这个国家的司法机构过于自由。',
    'app.question.q102': '希望认真对待自己的事情。',
    'app.question.q103': '我们应该做更多的事情来帮助弱势群体。',
    'app.question.q104': '我不在乎我的情绪外露。',
    'app.question.q105': '成长过程中感情曾被伤害过。',
    'app.question.q106': '色情正在降低女性身份。',
    'app.question.q107': '为世界的不公而感到愤怒。',
    'app.question.q108': '政府的大项目，几乎都是弊大于利。',
    'app.question.q109': '以前，情感受挫。',
    'app.question.q110': '喜欢读自我启发类书。',
    'app.question.q111': '我妈妈和/或我爸爸有，或有过情绪或心理问题。',
    'app.question.q112': '了解其他文化很有趣。',
    'app.question.q113': '我们应该保护拥有悠久历史的传统。',
    'app.question.q114': '女人没有足够的野心获得成功。',
    'app.question.q115': '有时我会生别人的气。',
    'app.question.q116': '除非你允许他们伤害你，否则没有人能真正伤害到你。',
    'app.question.q117': '我需要有人来倾听我的感受。',
    'app.question.q118': '只要你努力，几乎所有的问题都可以解决。',
    'app.question.q119': '光是祈祷，不会成功。',
    'app.question.q120': '我曾是，或正是父母闹矛盾或争吵的原因。',
    'app.question.q121': '这个国家贫富差距非常大。',
    'app.question.q122': '我不完全理解自己的感受。',
    'app.question.q123': '人不会真正改变。',
    'app.question.q124': '我的家人未曾对我满意过。',
    'app.question.q125': '男人天生就比女人好斗。',
    'app.question.q126': '我们需要做更多去保护地球的自然资源。',
    'app.question.q127': '我曾经碰到过金钱上的困难。',
    'app.question.q128': '我不喜欢被批判。',
    'app.question.q129': '我与母亲或我与父亲，现在或过去关系很好。',
    'app.question.q130': '我有必须解决的问题。',
    'app.question.q131': '祈祷比膏药更灵效。',
    'app.question.q132': '我小时候受到过虐待或遗弃。',
    'app.question.q133': '我们应更严厉打击毒品犯罪。',
    'app.question.q134': '我强烈支持女性权利平等。',
    'app.question.q135': '父亲理解我，接受我。',
    'app.question.q136': '教育是通更先进社会的枢纽。',
    'app.question.q137': '男儿当自强。',
    'app.question.q138': '不管土地谁用，我们都应保护。',
    'app.question.q139': '父母对我要求过多。',
    'app.question.q140': '女性在经济和政治上地位的提高，将有助于社会发展。',
    'app.question.q141': '有时，别人哭，我也会跟着哭。',
    'app.question.q142': '有些女性会被性骚扰是因为她们的着装。',
    'app.question.q143': '如果你不能说好话，就应该保持沉默。',
    'app.question.q144': '过去无法改变，所以再想也无意义。',
    'app.question.q145': '当你快被生活压倒时，就应勇敢地爬起来坚强地活下去。',
    'app.question.q146': '拒绝往往让你更强大。',
    'app.question.q147': '有些人过多分析这世界。',
    'app.question.q148': '当我心情不好时，总会试着从积极角度去思考。',
    'app.question.q149': '最好总是看到优点而不是缺点。',
    'app.question.q150': '度过艰难一生的最好办法是保持一种乐观的态度。',
    'app.question.q151': '过去的事情已过去，就该原谅和忘记。',
    'app.question.q152': '不能说一种观点优于其他任何一个观点。',
    'app.question.q153': '我认为人们应该心怀感激其所拥有的并停止抱怨。',
    'app.question.q154': '有些数字比其他数字更不祥。',
    'app.question.q155': '没有理由去咒骂、谩骂。',
    'app.question.q156': '太多人没有尽责。',
    'app.question.q157': '通灵者可以预测未来。',
    'app.question.q158': '凡事简化最好。',
    'app.question.q159': '无论何事，都难以让两位专家达成一致。',
    'app.question.q160': '只要按规矩办事，就能做好。',
    'app.question.q161': '要是所有人能喜欢我，至少能尊重我就好了。',
    'app.question.q162': '对待不同的文化习俗，我们应该更宽容。',
    'app.question.q163': '我是个崇尚精神自由的人。',
    'app.question.q164': '我相信占卜和占星术的力量。',
    'app.question.q165': '想到在贫困中挣扎的人就感到悲伤。',
    'app.question.q166': '我认为逻辑清晰比什么都重要。',
    'app.question.q167': '身边都是与自己截然不同的人，我感到舒适轻松。',
    'app.question.q168': '父母为了孩子不应分开。',
    'app.question.q169': '性幻想是健康的。',
    'app.question.q170': '医疗诊断并不是总有用。',
    'app.question.q171': '我总是看到事情好的一面。',
    'app.question.q172': '外星人帮助了人类进化。',
    'app.question.q173': '地球有几十亿岁了。',
    'app.question.q174': '弱点也可以变成美德。',
    'app.question.q175': '没人会喜欢悲观主义者。',
    'app.question.q176': '我不喜欢自己或别人情绪外露。',
    'app.question.q177': '在我成长过程中，父母的关系非常好。',
    'app.question.q178': '我们过于宽容不同的文化习俗。',
    'app.question.q179': '我们尽力做好扶贫工作。',
    'app.question.q180': '人们过于担忧环境。',
    'app.question.q181': '偏见已经不成问题。',
    'app.question.q182': '人总在变化。',
    'app.question.q183': '通往天堂的路有很多条。',
    'app.question.q184': '男人和女人与其说不同，还不如说相似。',
    'app.question.q185': '人的命运都是注定的。',
};

export const scaleQuestionsPageZHTW: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
