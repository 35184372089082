import { CreateProgramMessages } from '../types';

export const createProgramCA: CreateProgramMessages = {
    'app.create_program.btn_submit': 'Desar',
    'app.create_program.btn_submit_and_send_email': 'Desa/envia correu electrònic',
    'app.create_program.btn_unselect_all_users': 'Esborrar-ho tot',
    'app.create_program.select_individual_manually': 'Seleccionar les persones manualment',
    'app.create_program.table.column_name': 'Nom',
    'app.create_program.table.column_email': 'Correu electrònic',
    'app.create_program.table.column_actions': 'Eliminar Persones Individuals',
    'app.create_program.table.column_get_last_users': 'Persones de l’administració anterior.',
    'app.create_program.tooltip.action_remove': 'Eliminar',
    'app.create_program.tooltip.btn_get_last_users': 'Importar persones de l’administració anterior.',
    'app.create_program.tooltip.btn_download_users': 'Descarregar / gestionar qui farà el BEVI.',
    'app.create_program.tooltip.btn_download_users_empty': 'Descarregar / gestionar qui farà el BEVI(Plantilla buida).',
    'app.create_program.excel.name_column': 'Nom',
    'app.create_program.excel.login_column': 'Correu electrònic',
    'app.create_program.excel.active_column': 'Actiu',
    'app.create_program.confirmation_title_success': 'S’ha creat correctament',
    'app.create_program.confirmation_body_success_program': 'S’ha creat el programa o l’experiència. Pots revisar-lo o gestionar-lo fent clic a "D’acord" a continuació.',
    'app.create_program.confirmation_body_success_subprogram': 'Has creat i anomenat una administració BEVI. Pots revisar-la o gestionar-la fent clic a "D’acord" a continuació.',
    'app.create_program.confirmation_title_fail': 'Llàstima! Malauradament, no s’ha pogut crear.',
    'app.create_program.confirmation_body_fail_program': 'El programa no s’ha pogut crear. Si us plau, revisa les dades proporcionades, la connexió d’internet i intenta-ho de nou. Si el problema persisteix, parla amb el gestor del compte.',
    'app.create_program.confirmation_body_fail_subprogram': 'No s’ha pogut crear el subprograma. Si us plau, revisa les dades proporcionades, la connexió d’internet i intenta-ho de nou. Si el problema persisteix, parla amb el gestor del compte.',
    'app.edit_sub_program.confirmation_title_success': 'S’ha actualitzat correctament.',
    'app.edit_sub_program.confirmation_body_success': 'El grup s’ha actualitzat i ara el pots visualitzar a la llista de programes per organització, on ara us redirigeix.',
    'app.edit_sub_program.confirmation_title_fail': 'Llàstima! Malauradament, no s’ha pogut actualitzar',
    'app.edit_sub_program.confirmation_body_fail': 'El subprograma no s’ha pogut actualitzar. Si us plau, revisa les dades proporcionades, la connexió d’internet i intenta-ho de nou. Si el problema persisteix, parla amb el gestor del compte.',
    'app.create_program.lbl_associate_users': 'Selecciona als individus per completar el BEVI.',
    'app.create_program.lbl_organization_name': 'Nom de l’organització',
    'app.create_program.lbl_program_name': 'Nom del programa o la experiència',
    'app.create_program.lbl_sub_program_name': 'Nom de l’administració BEVI',
    'app.create_program.lbl_year': 'Any',
    'app.create_program.lbl_due_date': 'Data final per l’administració BEVI',
    'app.create_program.lbl_alert_all_users': 'Alertar a totes les persones per correu',
    'app.details_sub_program.progress': 'Progrés grupal',
    'app.details_sub_program.participant_details': 'Informació del/la participant',
    'app.details_sub_program.remind_alert': 'Remind Alert',
    'app.details_sub_program.remind_alert_text': 'A reminder alert was scheduled and it will be sent in a few minutes.',
    'app.create_program.lbl_email_message': 'Escriu un missatge a continuació que s’enviarà a les adreces de correu electrònic de tots els participants.',
    'app.create_program.tooltip.btn_upload_users': 'Carrega qui farà el  BEVI.',
    'app.create_program.upload_files_error_title': 'Error de càrrega del fitxer',
    'app.create_program.upload_rejected_files_error_body_prefix': 'S’han rebutjat els fitxers següents perquè no tenen el format esperat:',
    'app.create_program.upload_parsed_files_error_body_prefix': 'El fitxer ha de tenir les columnes {0}, {1} and {2}. No s’ha llegit els següents fitxers correctament:',
    'app.create_program.upload_parsed_files_error_body': '{0} té {1} errors.',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'Error en intentar analitzar els fitxers següents:',
    'app.create_program.select_individuals_title': 'Selecciona els individus que han de completar el BEVI mitjançant un dels tres mètodes:',
    'app.create_program.import_individuals_from_previous_administration': '1.  Importa persones d’una administració anterior.',
    'app.create_program.download_manage_who_will_take_the_bevi': '2.  Descarregar / gestionar qui farà el BEVI',
    'app.create_program.upload_who_will_take_the_bevi': ', i després carregar qui farà el BEVI',
    'app.create_program.add_individuals_manually': '3.  Afegir individus manualment / un per un, fent clic al menú desplegable següent.',
}
