import { ListOrganizationsMessages } from '../types';

export const listOrganizationsEN: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Created on',
    'app.listorganizations.active': 'Active',
    'app.listorganizations.inactive': 'Inactive',
    'app.listorganizations.new.program': 'New Program or Experience',
    'app.listorganizations.new.group': 'Create and name a new BEVI administration.',
    'app.listorganizations.view': 'View',
    'app.listorganizations.report': 'Report',
    'app.listorganizations.details': 'Review and manage the BEVI administration.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Invite new users to join on this group by QR Code.',
    'app.listorganizations.tooltip.sub_program_edit': 'Edit BEVI administration parameters.',
    'app.listorganizations.table.beviname': 'BEVI Administration Name',
    'app.listorganizations.table.created_on': 'Created On',
    'app.listorganizations.table.due_date': 'Due Date',
    'app.listorganizations.table.completed_percentage': 'Completed %',
    'app.listorganizations.table.status': 'Status',
    'app.listorganizations.table.actions': 'Actions',
    'app.listorganizations.select_organization': 'Select an organization.',
    'app.listorganizations.search': 'Search',
    'app.listorganizations.new_organization': 'New Organization',
    'app.listorganizations.input_program_placeholder': 'Type the name of the program or experience.',
    'app.listorganizations.input_subprogram_placeholder': 'Type the name of the BEVI administration.',
    'app.listorganizations.organization_area': 'Management of Programs and Experiences',
    'app.listorganizations.download.data_report': 'Download data as an Excel file.',
    'app.listorganizations.sort_name_date': 'Sort by name or date',
    'app.listorganizations.sort_by': 'Sort by',
    'app.sample.data.footer.message_line1': '<b><i>Note.</i> The BEVI reporting system allows for the development of eight different report types: 1) Individual Reports, 2) Individual Change Reports, 3) Partner Reports, 4) Partner Change Reports, 5) Group Reports, 6) Group Change Reports, 7) Within Group Reports, and 8) Between Group Reports.  To give a sense of how these reports work, and the type of information that may be accessed, two examples of each report type -- A and B -- are provided.</b>',
    'app.sample.data.footer.message_line2': '<b>Although sample reports are consistent with actual reports in the BEVI system, these examples are provided for illustrative purposes only.  As such, some information in these sample reports may have been modified (e.g., scale scores) or removed (e.g., qualitative responses) in order to ensure that results are de-identified and/or de-linked to specific individuals.</b>',
    'app.sample.data.footer.message_line3': '<b>If you have any questions about these sample reports, or any other aspect of the BEVI, please either contact the individual who authorized BEVI administrative access for you or reach out to the BEVI Team at <a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
