import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsDE: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': 'Bitte beantworten Sie die folgenden drei Fragen in Bezug auf die Erfahrung, für die Sie das BEVI ausfüllen.',
    'app.discursive_questions.question1': '1. Welches Ereignis oder welcher Aspekt Ihrer Erfahrung hatte den größten Einfluss auf Sie und warum?',
    'app.discursive_questions.question2': '2. Gab es einen Aspekt Ihres eigenen "Selbst" oder Ihrer "Identität" (z. B. Geschlecht, Ethnie, sexuelle Orientierung, religiöser oder politischer Hintergrund usw.), der Ihnen oder anderen aufgrund dieser Erfahrung besonders deutlich oder wichtig wurde?',
    'app.discursive_questions.question3': '3. Was haben Sie gelernt oder wie haben Sie sich durch diese Erfahrung geändert?',
    'app.discursive_questions.thank_you': 'Danke, dass Sie das BEVI ausgefüllt haben!',
    'app.discursive_questions.please_note': 'Bitte beachte:',
    'app.discursive_questions.go_to_report': 'Wenn Sie Ihren Bericht jetzt sehen möchten, klicken Sie bitte unten auf die Schaltfläche "Bericht lesen". Sie können Ihren Bericht auch später in Ihrem Dashboard einsehen.',
    'app.discursive_questions.do_not_show_report': 'Leider können wir aus verschiedenen Gründen (z.B. fehlende Antworten bei zu vielen Fragen) Ihren Bericht nicht erstellen. Wenn Sie möchten, können Sie versuchen, das BEVI erneut zu machen.',
    'app.discursive_questions.visualize_report': 'Bericht lesen',
}
