import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race, select } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    getLastSubProgramUsers, saveProgram, SaveProgramResponse, SendProgram
} from '../services/programService';
import {
    createNewUserBatch, CreateNewUserBatchPayloadRequest,
    CreateNewUserPayloadRequest, CreateNewUserResponse, getOrganizationUsersOnlyParticipants,
    loadUserProgramDetails,
    OrganizationsUsersResponse, OrganizationUser, ResetUserPasswordType, resetUsersPassword, saveUser, SaveUserStatus, searchUsers,
    SearchUsersDetails, searchUsersPermission, SearchUsersPermissionDetails,
    SearchUsersPermissionResponse, updateUsersPermission,
    UpdateUsersPermissionResponse, UserErrorResponse, UserPermissionModel, UserPrograms,
    addUserPermissionExcelFile,
    UserAddPermissionErrorResponse,
    AddUserPermissionExcelFilePayloadRequest,
} from '../services/usersService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { generalExceptionKey, longRequestTimeout, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals([
    'createUserBatch',
    'getLastSubProgramUsers',
    'getOrganizationUsersOnlyParticipants',
    'searchUsers',
    'createNewUser',
    'clearSuccessfulCreateNewUser',
    'saveProgram',
    'searchUserPermission',
    'saveUserPermission',
    'clearLastSubProgramUsers',
    'loadUserProgramDetails',
    'clearUserProgramDetails',
    'resetUserResetPassword',
    'clearUserResetPassword',
    'addUserPermissionExcelFile',
    'clearSuccessfulAddUserPermission',
]);

export type UsersActions = { [K in (typeof actionsLiterals)[number]]: any };
export const usersActionsConstants: UsersActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setSearchUsers',
    'setSearchUsersError',
    'setOrganizationUsers',
    'setOrganizationUsersError',
    'setLastSubProgramUsers',
    'successfulCreateNewUser',
    'setErroNewUser',
    'setNewUserErrorDetails',
    'successfulSaveProgram',
    'setSaveProgramError',
    'setSearchUserPermission',
    'setSaveUsersPermissionStatus',
    'setUserProgramDetails',
    'setUserProgramDetailsError',
    'setResetPasswordResults',
    'setResetPasswordError',
    'successfulAddUserPermissionExcelFile',
    'setAddUserPermissionExcelFileErrorDetails',
    'setErroAddUserPermission',
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals([
    'users',
    'loadingSearchUsers',
    'searchUserData',
    'loadingOrganizationUsers',
    'organizationUsers',
    'organizationError',
    'lastSubProgramUsers',
    'hasCreatedNewUser',
    'errorMessageNewUser',
    'newUserErrorDetails',
    'loadingCreateNewUser',
    'loadingSaveProgram',
    'errorSaveProgram',
    'usersPermission',
    'loadingSearchUsersPermission',
    'saveUsersPermissionStatus',
    'saveUserStatus',
    'loadingSaveUsersPermissionStatus',
    'userProgramDetails',
    'loadingUserProgramDetails',
    'errorUserProgramDetails',
    'resetPasswordSuccessful',
    'errorResetPassword',
    'loadingSubProgram',
    'userAddPermissionError',
    'loadingAddUserPermission',
    'hasAddUserPermission'
]);
export type UsersProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const usersPropsConstants: UsersProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: UsersActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchSearchUsersRequest: () => void,
        fetchOrganizationUsersOnlyParticipants: () => void,
        fetchLastSubProgramUsersRequest: () => void,
        fetchCreateNewUserRequest: () => void,
        fetchSaveProgramRequest: () => void,
        fetchCreteNewUserBatchRequest: () => void,
        fetchSearchUserPermissionRequest: () => void,
        fetchUpdateUserPermissionRequest: () => void,
        fetchUserProgramDetailsRequest: () => void,
        fetchResetUsersRequest: () => void,
        fetchAddUserPermissionExcelFileRequest: () => void
    }
};

type OrganizationUsersOnlyParticipantsRaceResponse = {
    organizationUsersOnlyParticipantsResponse?: OrganizationsUsersResponse;
}

type CreateNewUserRaceResponse = {
    createNewUserResponse?: CreateNewUserResponse;
}

type SaveProgramRaceResponse = {
    saveProgramResponse?: SaveProgramResponse;
}

type SearchUsersPermissionRaceResponse = {
    searchUsersPermissionResponse?: SearchUsersPermissionResponse;
}

type UpdateUsersPermissionRaceResponse = {
    UpdateUsersPermissionRaceResponse?: UpdateUsersPermissionResponse;
}

// Define payloads type.
type ErrorPayload = { errorKey: string; };
type ErrorDetailsPayload = { details: UserErrorResponse[]; };
type SaveUserPayload = { status?: SaveUserStatus; };
type CreateUserPayload = { user: CreateNewUserPayloadRequest }
type SaveUserPermissionPayload = { status: boolean; };

type SearchUsersPayloadRequest = { organizationId?: number, userNameQuery: string, userEmailQuery: string, userType?: number };
type SearchUsersRequest = { payload: SearchUsersPayloadRequest };

type SearchUsersPermissionPayloadRequest = { organizationId: number, programId?: number };
type SearchUsersPermissionRequest = { payload: SearchUsersPermissionPayloadRequest };

type SaveUsersPermissionPayloadRequest = { userPermission: UserPermissionModel[] };
type SaveUsersPermissionRequest = { payload: SaveUsersPermissionPayloadRequest };

type LoadUserProgramDetailsPayloadRequest = { userId: number };
type LoadUserProgramDetailsRequest = { payload: LoadUserProgramDetailsPayloadRequest };
type UserProgramsPayloadRequest = { programs: UserPrograms };

type ResetUsersPasswordPayloadRequest = { passwords: ResetUserPasswordType[] };
type ResetUsersPasswordDetailsRequest = { payload: ResetUsersPasswordPayloadRequest };
type ResetUsersPasswordPayloadDetailsRequest = { result: boolean };

type OrganizationUsersPayloadRequest = { organizationId: number };
type OrganizationUsersRequest = { payload: SearchUsersPayloadRequest };

type GetLastSubProgramUsersPayloadRequest = {
    programId: number, programName: string, organizationId: number, subProgramId: number
};
type GetLastSubProgramUserRequest = { payload: GetLastSubProgramUsersPayloadRequest };
type CreateNewUserRequest = { payload: CreateUserPayload };
type CreateNewUserBatchRequest = { payload: CreateNewUserBatchPayloadRequest };

type SaveProgramPayloadRequest = { program: SendProgram; }
type SaveProgramPayload = { payload: SaveProgramPayloadRequest; }

type SearchUsersPayload = { results: SearchUsersDetails[] };
type OrganizationUsersPayload = { users: OrganizationUser[] };
type LastSubProgramUsersPayload = { users: OrganizationUser[] };
type SearchUsersPermissionPayload = { users: SearchUsersPermissionDetails[] };

type ErrorDetailsAddUserPermissionPayload = { details: UserAddPermissionErrorResponse[]; };
type AddUserPermissionExcelFileRequest = { payload: AddUserPermissionPayload };
type AddUserPermissionPayload = { data: AddUserPermissionExcelFilePayloadRequest }

const SearchUserItemPropType = PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
});

const SearchUsersPermissionItemPropType = PropTypes.shape({
    userId: PropTypes.number,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    hasPermission: PropTypes.bool,
});

const OrganizationUserItemPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
});

const emptySearchUsersList: SearchUsersDetails[] = [];
const emptyOrganizationUsersList: OrganizationUser[] = [];
const emptySearchUsersPermissionList: SearchUsersPermissionDetails[] = [];

const defaultSearchUsersData: SearchUsersPayloadRequest = {
    organizationId: undefined,
    userNameQuery: '',
    userEmailQuery: '',
    userType: 0
};

// persist forms reducer
export const usersKeaPath = 'users';

export const usersReducer = keaReducer(usersKeaPath);

export const usersLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [usersKeaPath],

    actions: () => ({
        // Search users actions.
        [usersActionsConstants.searchUsers]:
            (organizationId: number, userNameQuery: string, userEmailQuery: string, userType?: number): SearchUsersPayloadRequest =>
                ({ organizationId, userNameQuery, userEmailQuery, userType }),
        [privateActionsConstants.setSearchUsersError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setSearchUsers]:
            (results: SearchUsersDetails[]): SearchUsersPayload => ({ results }),

        // Create users actions.
        [usersActionsConstants.createUserBatch]:
            (file: File, organizationId: number, sendEmail: boolean): CreateNewUserBatchPayloadRequest =>
                ({ file, organizationId, sendEmail }),
        [usersActionsConstants.clearSuccessfulCreateNewUser]: true,
        [usersActionsConstants.clearLastSubProgramUsers]: true,

        // Organization users actions.
        [usersActionsConstants.getOrganizationUsersOnlyParticipants]:
            (organizationId: number): OrganizationUsersPayloadRequest => ({ organizationId }),
        [privateActionsConstants.setOrganizationUsersError]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setOrganizationUsers]:
            (users: OrganizationUser[]): OrganizationUsersPayload => ({ users }),

        // Last sub program users actions.
        [usersActionsConstants.getLastSubProgramUsers]:
            (programId: number, programName: string, organizationId: number, subProgramId: number):
                GetLastSubProgramUsersPayloadRequest => ({ programId, programName, organizationId, subProgramId }),
        [privateActionsConstants.setLastSubProgramUsers]:
            (users: OrganizationUser[]): LastSubProgramUsersPayload => ({ users }),

        // Create user actions.
        [usersActionsConstants.createNewUser]: (user: CreateNewUserPayloadRequest): CreateUserPayload => ({ user }),
        [privateActionsConstants.setErroNewUser]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setNewUserErrorDetails]: (details: UserErrorResponse[]): ErrorDetailsPayload => ({ details }),
        [privateActionsConstants.successfulCreateNewUser]: (status?: SaveUserStatus): SaveUserPayload => ({ status }),

        // Save Program Actions:
        [usersActionsConstants.saveProgram]: (program: SendProgram): SaveProgramPayloadRequest => ({ program }),
        [privateActionsConstants.successfulSaveProgram]: true,
        [privateActionsConstants.setSaveProgramError]: (errorKey: string): ErrorPayload => ({ errorKey }),

        // Search Users Permission Actions:
        [usersActionsConstants.searchUserPermission]:
            (organizationId: number, programId?: number): SearchUsersPermissionPayloadRequest =>
                ({ organizationId, programId }),
        [privateActionsConstants.setSearchUserPermission]: (users: SearchUsersPermissionDetails[]) => ({ users }),

        // Save Users Permission
        [usersActionsConstants.saveUserPermission]:
            (userPermission: UserPermissionModel[]): SaveUsersPermissionPayloadRequest =>
                ({ userPermission }),
        [privateActionsConstants.setSaveUsersPermissionStatus]: (status: boolean) => ({ status }),

        // User Program Details
        [usersActionsConstants.loadUserProgramDetails]:
            (userId: number): LoadUserProgramDetailsPayloadRequest => ({ userId }),
        [usersActionsConstants.clearUserProgramDetails]: true,
        [privateActionsConstants.setUserProgramDetails]:
            (programs: UserPrograms): UserProgramsPayloadRequest => ({ programs }),
        [privateActionsConstants.setUserProgramDetailsError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),

        // Reset Users Password
        [usersActionsConstants.resetUserResetPassword]:
            (passwords: ResetUserPasswordType[]): ResetUsersPasswordPayloadRequest => ({ passwords }),
        [usersActionsConstants.clearUserResetPassword]: true,
        [privateActionsConstants.setResetPasswordResults]:
            (result: boolean): ResetUsersPasswordPayloadDetailsRequest => ({ result }),
        [privateActionsConstants.setResetPasswordError]:
            (result: boolean): ResetUsersPasswordPayloadDetailsRequest => ({ result }),

        // Add User Permissions Excel File
        [usersActionsConstants.addUserPermissionExcelFile]: (data: AddUserPermissionExcelFilePayloadRequest): AddUserPermissionPayload => ({ data }),
        [usersActionsConstants.clearSuccessfulAddUserPermission]: true,
        [privateActionsConstants.setErroAddUserPermission]: (errorKey: string): ErrorPayload => ({ errorKey }),
        [privateActionsConstants.setAddUserPermissionExcelFileErrorDetails]: (details: UserAddPermissionErrorResponse[]): ErrorDetailsAddUserPermissionPayload => ({ details }),
        [privateActionsConstants.successfulAddUserPermissionExcelFile]: true,
    }),

    reducers: ({ actions }: ReducersParam) => ({
        // Search users reducers.
        [usersPropsConstants.loadingSearchUsers]: [false, PropTypes.bool, {
            [actions.searchUsers]: () => true,
            [actions.setSearchUsers]: () => false,
            [actions.setSearchUsersError]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.users]: [emptySearchUsersList, PropTypes.arrayOf(SearchUserItemPropType), {
            [actions.searchUsers]: () => emptySearchUsersList,
            [actions.setSearchUsers]: (_: SearchUsersDetails[], payload: SearchUsersPayload) => payload.results,
            [actions.setSearchUsersError]: () => emptySearchUsersList,
            [actions.clearSuccessfulCreateNewUser]: () => emptySearchUsersList,
            [logout]: () => emptySearchUsersList,
        }],
        [usersPropsConstants.searchUserData]: [defaultSearchUsersData, PropTypes.any, {
            [actions.searchUsers]: (_: SearchUsersPayloadRequest, payload: SearchUsersPayloadRequest) => payload,
            [actions.setSearchUsersError]: () => defaultSearchUsersData,
            [logout]: () => defaultSearchUsersData,
        }],

        // Organization users reducers.
        [usersPropsConstants.loadingOrganizationUsers]: [false, PropTypes.bool, {
            [actions.getOrganizationUsersOnlyParticipants]: () => true,
            [actions.setOrganizationUsers]: () => false,
            [actions.setOrganizationUsersError]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.organizationUsers]: [emptyOrganizationUsersList, PropTypes.arrayOf(OrganizationUserItemPropType), {
            [actions.getOrganizationUsersOnlyParticipants]: () => emptyOrganizationUsersList,
            [actions.setOrganizationUsers]: (_: OrganizationUser[], payload: OrganizationUsersPayload) => payload.users,
            [actions.setOrganizationUsersError]: () => emptyOrganizationUsersList,
            [logout]: () => emptyOrganizationUsersList,
        }],
        [usersPropsConstants.organizationError]: ['', PropTypes.string, {
            [actions.getOrganizationUsersOnlyParticipants]: () => '',
            [actions.setOrganizationUsers]: () => '',
            [actions.setOrganizationUsersError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => '',
        }],

        // Sub program last users reducers.
        [usersPropsConstants.lastSubProgramUsers]: [emptyOrganizationUsersList, PropTypes.arrayOf(OrganizationUserItemPropType), {
            [actions.getLastSubProgramUsers]: () => emptyOrganizationUsersList,
            [actions.setLastSubProgramUsers]: (_: OrganizationUser[], payload: LastSubProgramUsersPayload) => payload.users,
            [actions.clearLastSubProgramUsers]: () => emptyOrganizationUsersList,
            [actions.setOrganizationUsers]: () => emptyOrganizationUsersList,
            [logout]: () => emptyOrganizationUsersList,
        }],

        // Create new user reducers.
        [usersPropsConstants.errorMessageNewUser]: [null, PropTypes.string, {
            [actions.setErroNewUser]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.clearSuccessfulCreateNewUser]: () => null,
            [actions.createNewUser]: () => null,
            [actions.createUserBatch]: () => null,
            [actions.successfulCreateNewUser]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.newUserErrorDetails]: [null, PropTypes.any, {
            [actions.setNewUserErrorDetails]: (_: string, payload: ErrorDetailsPayload) => payload.details,
            [actions.clearSuccessfulCreateNewUser]: () => null,
            [actions.createNewUser]: () => null,
            [actions.createUserBatch]: () => null,
            [actions.successfulCreateNewUser]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.hasCreatedNewUser]: [false, PropTypes.bool, {
            [actions.successfulCreateNewUser]: () => true,
            [actions.clearSuccessfulCreateNewUser]: () => false,
            [actions.setErroNewUser]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.loadingCreateNewUser]: [false, PropTypes.bool, {
            [actions.createNewUser]: () => true,
            [actions.createUserBatch]: () => true,
            [actions.clearSuccessfulCreateNewUser]: () => false,
            [actions.successfulCreateNewUser]: () => false,
            [actions.setErroNewUser]: () => false,
            [logout]: () => false,
        }],

        // Save program reducers.
        [usersPropsConstants.loadingSaveProgram]: [false, PropTypes.bool, {
            [actions.saveProgram]: () => true,
            [actions.setSaveProgramError]: () => false,
            [actions.successfulSaveProgram]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.errorSaveProgram]: [null, PropTypes.string, {
            [actions.setSaveProgramError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.saveProgram]: () => null,
            [actions.successfulSaveProgram]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.saveUserStatus]: [null, PropTypes.number, {
            [actions.successfulCreateNewUser]: (_: number, payload: SaveUserPayload) => payload.status,
            [actions.createNewUser]: () => null,
            [actions.createUserBatch]: () => null,
            [actions.clearSuccessfulCreateNewUser]: () => null,
            [logout]: () => null,
        }],

        // Search users permission reducers.
        [usersPropsConstants.loadingSearchUsersPermission]: [false, PropTypes.bool, {
            [actions.searchUserPermission]: () => true,
            [actions.setSearchUserPermission]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.usersPermission]: [emptySearchUsersPermissionList, PropTypes.arrayOf(SearchUsersPermissionItemPropType), {
            [actions.setSearchUserPermission]: (_: SearchUsersPermissionDetails[], payload: SearchUsersPermissionPayload) => payload.users,
            [logout]: () => null,
        }],

        // Save users permission reducers.
        [usersPropsConstants.loadingSaveUsersPermissionStatus]: [false, PropTypes.bool, {
            [actions.saveUserPermission]: () => true,
            [actions.setSaveUsersPermissionStatus]: () => false,
            [actions.searchUserPermission]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.saveUsersPermissionStatus]: [false, PropTypes.bool, {
            [actions.setSaveUsersPermissionStatus]: (_: boolean, payload: SaveUserPermissionPayload) => payload.status,
            [actions.saveUserPermission]: () => false,
            [logout]: () => null,
        }],

        // User Program Details
        [usersPropsConstants.loadingUserProgramDetails]: [false, PropTypes.bool, {
            [actions.loadUserProgramDetails]: () => true,
            [actions.setUserProgramDetails]: () => false,
            [actions.setUserProgramDetailsError]: () => false,
            [actions.clearUserProgramDetails]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.errorUserProgramDetails]: [null, PropTypes.string, {
            [actions.setUserProgramDetailsError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.loadUserProgramDetails]: () => null,
            [actions.clearUserProgramDetails]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.userProgramDetails]: [null, PropTypes.any, {
            [actions.setUserProgramDetails]: (_: UserPrograms, payload: UserProgramsPayloadRequest) => payload.programs,
            [actions.clearUserProgramDetails]: () => null,
            [logout]: () => null,
        }],

        // Reset User Passwords
        [usersPropsConstants.resetPasswordSuccessful]: [false, PropTypes.bool, {
            [actions.setResetPasswordResults]: (_: boolean, payload: ResetUsersPasswordPayloadDetailsRequest) => payload.result,
            [actions.clearUserResetPassword]: () => false,
            [actions.resetUserResetPassword]: () => false,
            [actions.setResetPasswordError]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.errorResetPassword]: [false, PropTypes.bool, {
            [actions.setResetPasswordError]: (_: boolean, payload: ResetUsersPasswordPayloadDetailsRequest) => payload.result,
            [actions.clearUserResetPassword]: () => false,
            [actions.resetUserResetPassword]: () => false,
            [actions.setResetPasswordResults]: () => false,
            [logout]: () => false,
        }],

        // Add User Permission Excel File
        [usersPropsConstants.errorMessageNewUser]: [null, PropTypes.string, {
            [actions.setErroAddUserPermission]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [actions.clearSuccessfulAddUserPermission]: () => null,
            [actions.addUserPermissionExcelFile]: () => null,
            [actions.successfulAddUserPermissionExcelFile]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.userAddPermissionError]: [null, PropTypes.any, {
            [actions.setAddUserPermissionExcelFileErrorDetails]: (_: string, payload: ErrorDetailsPayload) => payload.details,
            [actions.clearSuccessfulAddUserPermission]: () => null,
            [actions.addUserPermissionExcelFile]: () => null,
            [actions.successfulAddUserPermissionExcelFile]: () => null,
            [logout]: () => null,
        }],
        [usersPropsConstants.hasAddUserPermission]: [false, PropTypes.bool, {
            [actions.successfulAddUserPermissionExcelFile]: () => true,
            [actions.addUserPermissionExcelFile]: () => false,
            [actions.clearSuccessfulAddUserPermission]: () => false,
            [actions.setErroAddUserPermission]: () => false,
            [logout]: () => false,
        }],
        [usersPropsConstants.loadingAddUserPermission]: [false, PropTypes.bool, {
            [actions.addUserPermissionExcelFile]: () => true,
            [actions.clearSuccessfulAddUserPermission]: () => false,
            [actions.successfulAddUserPermissionExcelFile]: () => false,
            [actions.setErroAddUserPermission]: () => false,
            [logout]: () => false,
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.searchUsers]: workers.fetchSearchUsersRequest,
        [actions.getOrganizationUsersOnlyParticipants]: workers.fetchOrganizationUsersOnlyParticipants,
        [actions.getLastSubProgramUsers]: workers.fetchLastSubProgramUsersRequest,
        [actions.createNewUser]: workers.fetchCreateNewUserRequest,
        [actions.saveProgram]: workers.fetchSaveProgramRequest,
        [actions.createUserBatch]: workers.fetchCreteNewUserBatchRequest,
        [actions.searchUserPermission]: workers.fetchSearchUserPermissionRequest,
        [actions.saveUserPermission]: workers.fetchUpdateUserPermissionRequest,
        [actions.loadUserProgramDetails]: workers.fetchUserProgramDetailsRequest,
        [actions.resetUserResetPassword]: workers.fetchResetUsersRequest,
        [actions.addUserPermissionExcelFile]: workers.fetchAddUserPermissionExcelFileRequest
    }),

    workers: {
        * fetchSearchUsersRequest(action: SearchUsersRequest): any {

            //@ts-ignore
            const { setSearchUsers, setSearchUsersError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId, userNameQuery, userEmailQuery, userType } = action.payload;

            if (organizationId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const searchUsersResponse = yield call(searchUsers, token, organizationId, userNameQuery, userEmailQuery, userType);

            if (searchUsersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (searchUsersResponse?.status === successfulCode) {
                if (searchUsersResponse?.body) {
                    yield put(setSearchUsers(searchUsersResponse?.body));
                } else {
                    yield put(setSearchUsersError(generalExceptionKey));
                }
            } else if (searchUsersResponse?.keyErrorMessage) {
                yield put(setSearchUsersError(searchUsersResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchOrganizationUsersOnlyParticipants(action: OrganizationUsersRequest): any {

            //@ts-ignore
            const { setOrganizationUsers, setOrganizationUsersError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId } = action.payload;
            if (organizationId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const { organizationUsersOnlyParticipantsResponse }: OrganizationUsersOnlyParticipantsRaceResponse = yield race({
                organizationUsersOnlyParticipantsResponse: call(getOrganizationUsersOnlyParticipants, token, organizationId),
                timeout: delay(requestTimeout)
            });

            if (organizationUsersOnlyParticipantsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (organizationUsersOnlyParticipantsResponse?.status === successfulCode) {
                if (organizationUsersOnlyParticipantsResponse?.body) {
                    yield put(setOrganizationUsers(organizationUsersOnlyParticipantsResponse?.body));
                } else {
                    yield put(setOrganizationUsersError(generalExceptionKey));
                }
            } else if (organizationUsersOnlyParticipantsResponse?.keyErrorMessage) {
                yield put(setOrganizationUsersError(organizationUsersOnlyParticipantsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchLastSubProgramUsersRequest(action: GetLastSubProgramUserRequest): any {

            //@ts-ignore
            const { setLastSubProgramUsers, setOrganizationUsersError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { programId, programName, organizationId, subProgramId } = action.payload;

            const lastSubProgramUsersResponse = yield call(getLastSubProgramUsers, token,
                { programId, programName, organizationId, subProgramId });

            if (lastSubProgramUsersResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (lastSubProgramUsersResponse?.status === successfulCode) {
                if (lastSubProgramUsersResponse?.body) {
                    yield put(setLastSubProgramUsers(lastSubProgramUsersResponse?.body.users));
                } else {
                    yield put(setOrganizationUsersError(generalExceptionKey));
                }
            } else if (lastSubProgramUsersResponse?.keyErrorMessage) {
                yield put(setOrganizationUsersError(lastSubProgramUsersResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchCreateNewUserRequest(action: CreateNewUserRequest): any {

            //@ts-ignore
            const { successfulCreateNewUser, setErroNewUser } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { user } = action.payload;

            const { createNewUserResponse }: CreateNewUserRaceResponse = yield race({
                createNewUserResponse: call(saveUser, token, user),
                timeout: delay(requestTimeout)
            });

            if (createNewUserResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (createNewUserResponse?.status === successfulCode) {
                yield put(successfulCreateNewUser(createNewUserResponse.body));
            } else if (createNewUserResponse?.keyErrorMessage) {
                yield put(setErroNewUser(createNewUserResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchSaveProgramRequest(action: SaveProgramPayload): any {

            //@ts-ignore
            const { successfulSaveProgram, setSaveProgramError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { program } = action.payload;

            const { saveProgramResponse }: SaveProgramRaceResponse = yield race({
                saveProgramResponse: call(saveProgram, token, program),
                timeout: delay(longRequestTimeout)
            });

            if (saveProgramResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (saveProgramResponse?.status === successfulCode) {
                yield put(successfulSaveProgram());
            } else if (saveProgramResponse?.keyErrorMessage) {
                yield put(setSaveProgramError(saveProgramResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchCreteNewUserBatchRequest(action: CreateNewUserBatchRequest): any {

            //@ts-ignore
            const { successfulCreateNewUser, setErroNewUser, setNewUserErrorDetails } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId, sendEmail, file } = action.payload;
            const createNewUserBatchResponse = yield call(createNewUserBatch, token, file, organizationId, sendEmail);

            if (createNewUserBatchResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (createNewUserBatchResponse?.status === successfulCode) {
                if (createNewUserBatchResponse?.body && createNewUserBatchResponse?.body?.length > 0) {
                    yield put(setErroNewUser('app.create.batch_user_error'));
                    yield put(setNewUserErrorDetails(createNewUserBatchResponse.body));
                } else {
                    yield put(successfulCreateNewUser({ status: successfulCode }));
                }
            } else if (createNewUserBatchResponse?.keyErrorMessage) {
                yield put(setErroNewUser(createNewUserBatchResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchSearchUserPermissionRequest(action: SearchUsersPermissionRequest): any {

            //@ts-ignore
            const { setSearchUserPermission } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { organizationId, programId } = action.payload;

            const { searchUsersPermissionResponse }: SearchUsersPermissionRaceResponse = yield race({
                searchUsersPermissionResponse: call(searchUsersPermission, token, organizationId, programId),
                timeout: delay(requestTimeout)
            });

            if (searchUsersPermissionResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (searchUsersPermissionResponse?.status === successfulCode) {
                yield put(setSearchUserPermission(searchUsersPermissionResponse?.body));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchUpdateUserPermissionRequest(action: SaveUsersPermissionRequest): any {
            //@ts-ignore
            const { setSaveUsersPermissionStatus, setSearchUserPermission } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { userPermission } = action.payload;

            const { UpdateUsersPermissionRaceResponse }: UpdateUsersPermissionRaceResponse = yield race({
                UpdateUsersPermissionRaceResponse: call(updateUsersPermission, token, userPermission),
                timeout: delay(requestTimeout)
            });

            if (UpdateUsersPermissionRaceResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (UpdateUsersPermissionRaceResponse?.status === successfulCode) {
                yield put(setSaveUsersPermissionStatus(true));
                const usersPermission: SearchUsersPermissionDetails[] = [...yield select((state: any) => state.users.usersPermission)];
                userPermission.forEach(item => {
                    const user = usersPermission.find(up => up.userId === item.userId);
                    if (user) {
                        user.capabilities = item.permissions;
                    }
                });
                yield put(setSearchUserPermission(usersPermission));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchUserProgramDetailsRequest(action: LoadUserProgramDetailsRequest): any {

            //@ts-ignore
            const { setUserProgramDetails, setUserProgramDetailsError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { userId } = action.payload;

            if (userId === undefined) {
                yield put(serviceUnavailable());
                return;
            }

            const userProgramDetailsResponse = yield call(loadUserProgramDetails, token, userId);

            if (userProgramDetailsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (userProgramDetailsResponse?.status === successfulCode) {
                if (userProgramDetailsResponse?.body) {
                    yield put(setUserProgramDetails(userProgramDetailsResponse?.body));
                } else {
                    yield put(setUserProgramDetailsError(generalExceptionKey));
                }
            } else if (userProgramDetailsResponse?.keyErrorMessage) {
                yield put(setUserProgramDetailsError(userProgramDetailsResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchResetUsersRequest(action: ResetUsersPasswordDetailsRequest): any {
            //@ts-ignore
            const { setResetPasswordResults, setResetPasswordError } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { passwords } = action.payload;
            const userProgramDetailsResponse = yield call(resetUsersPassword, token, passwords);

            if (userProgramDetailsResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (userProgramDetailsResponse?.status === successfulCode) {
                yield put(setResetPasswordResults(true));
            } else if (userProgramDetailsResponse?.keyErrorMessage) {
                yield put(setResetPasswordError(true));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchAddUserPermissionExcelFileRequest(action: AddUserPermissionExcelFileRequest): any {
            //@ts-ignore
            const { successfulAddUserPermissionExcelFile, setErroAddUserPermission, setAddUserPermissionExcelFileErrorDetails } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { data } = action.payload;
            
            const addUserPermissionExcelFileResponse = yield call(addUserPermissionExcelFile, token, data.permissions, data.file);

            if (addUserPermissionExcelFileResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (addUserPermissionExcelFileResponse?.status === successfulCode) {
                if (addUserPermissionExcelFileResponse?.body && addUserPermissionExcelFileResponse?.body?.length > 0) {
                    yield put(setErroAddUserPermission('app.create.batch_user_error'));
                    yield put(setAddUserPermissionExcelFileErrorDetails(addUserPermissionExcelFileResponse.body));
                } else {
                    yield put(successfulAddUserPermissionExcelFile({ status: successfulCode }));
                }
            } else if (addUserPermissionExcelFileResponse?.keyErrorMessage) {
                yield put(setErroAddUserPermission(addUserPermissionExcelFileResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
