import { Divider } from 'antd';
import React, { Fragment, ReactNode } from 'react';
import { Image } from 'react-bootstrap';
import { LanguageSwitch } from '../../../components/LanguageSwitch';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import logo_bevi from '../../../assets/images/bevi_logo.png';
import logoJapanese1 from '../../../assets/images/logo-japanese-1.png';
import sokaLogoJapanese from '../../../assets/images/soka-logo.jpg';
import { NavLink } from 'react-router-dom';
import { LOGIN_PAGE } from '../../../router/pages';

type Props = {
    component: ReactNode;
    isJapanese: boolean;
}

export const NoInfoTemplate = ({ component, isJapanese }: Props): JSX.Element => {

    return (
        <>
            <header className='header shadow-md'>
                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <NavLink to={LOGIN_PAGE}><Image src={logo_bevi} className='header__logo' /></NavLink>
                        {
                            isJapanese &&
                            <Fragment>
                                <Divider type={'vertical'} />
                                <Image src={logoJapanese1} className='header__logo' />
                                <Divider type={'vertical'} />
                                <Image src={sokaLogoJapanese} className='header__logo' />
                            </Fragment>
                        }
                    </div>
                    <div className='dropdown language-selector ml-auto'>
                        <LanguageSwitch />
                    </div>
                </div>
                <hr className='hr--gradient' />
            </header>
            <main>
                <div className='container-fluid row min-vh-80' >
                    {component}
                </div>
            </main>
            <hr />
            <div style={styles.beviCopyrightDiv} className='pb-3'>
                {GetFormattedMessage('app.bevi_copyright_text_1')}
                {GetFormattedMessage('app.bevi_copyright_text_2')}
            </div>
        </>
    );
}

const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    } as React.CSSProperties,
    beviCopyrightDiv: {
        textAlign: 'center',
    } as React.CSSProperties,
}
