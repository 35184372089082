import { ListFormPageMessages } from '../types';

export const listFormsPageEN: ListFormPageMessages = {
    'app.listforms.title': 'My BEVI Dashboard',
    'app.listforms.subtitle': 'The BEVI dashboard system allows you to take the BEVI and review and manage your BEVI reports.',
    'app.listforms.organization': 'Organization',
    'app.listforms.participant': 'Participant',
    'app.listforms.program': 'Program',
    'app.listforms.take_by': 'Due Date',
    'app.listforms.completed_on': 'Completion Date',
    'app.listforms.progress': 'Progress',
    'app.listforms.status': 'Status',
    'app.listforms.custom_agreement_status': 'Custom Agreement Status',
    'app.listforms.action': 'Action',
    'app.listforms.organization_tooltip': 'The BEVI dashboard system allows you to take the BEVI and review and manage your BEVI reports.',
    'app.listforms.program_tooltip': 'The name of the program or experience for which you are taking the BEVI.',
    'app.listforms.take_by_tooltip': 'This is the date by which the BEVI should be completed',
    'app.listforms.completed_on_tooltip': 'This is the date on which the BEVI was completed by you',
    'app.listforms.progress_tooltip': 'The degree to which you have completed a BEVI administration (i.e., 100% means you have finished the BEVI)',
    'app.listforms.status_tooltip': 'Status column tooltip',
    'app.listforms.action_tooltip': 'Here, you can take, complete, or retake the BEVI or review your BEVI report. The box you see is what you currently can do.',
    'app.listforms.all': 'All',
    'app.listforms.available': 'Not started yet',
    'app.listforms.onDemographics': 'On Demographics',
    'app.listforms.onScales': 'On BEVI Questions',
    'app.listforms.onSubjective': 'On Written Responses',
    'app.listforms.finished': 'Complete',
    'app.listforms.cancelled': 'Cancelled',
    'app.listforms.expired': 'Expired',
    'app.listforms.archived': 'Archived',
    'app.listforms.invalid': 'Invalid',
    'app.listforms.start': 'Take the BEVI',
    'app.listforms.continue': 'Complete the BEVI',
    'app.listforms.report': 'Review BEVI Report',
    'app.listforms.partner_report': 'Review Partner Report',
    'app.listforms.partner_change_report': 'Review Partner Change Report',
    'app.listforms.restart': 'Retake the BEVI',
    'app.listforms.confirmation_title': 'Instructions',
    'app.listforms.retart_confirmation_title': 'Restart BEVI',
    'app.listforms.restart.instruction.part1': 'Click in the button below to restart the BEVI.',
    'app.listforms.instruction.part1': 'The following instrument consists of background information items and 185 questions with 20 questions per page. ' +
        'Please read each question and indicate your response by selecting one of the four options that best describes how you feel: ' +
        'strongly agree, agree, disagree, strongly disagree.',
    'app.listforms.instruction.part2': 'Once you have answered each set of questions, press continue to go on; once the continue button has been pressed you may not go back and change previous answers.',
    'app.listforms.instruction.part3': 'You must indicate that you have read about the BEVI and provide informed consent before you may complete the BEVI.',
    'app.listforms.instruction.press.start': 'Thank you for participating. Please press start when you are ready.',
    'app.listforms.comparative_individual_report': 'Compare individual report',
    'app.form.status_title': 'Unavailable BEVI form',
    'app.form.status_finished_subtitle': 'It looks like the form has already been answered.',
    'app.form.status_finished_body': 'Contact those responsible if you have any questions regarding the form status.',
    'app.form.status_cancelled_subtitle': 'It looks like the form was canceled',
    'app.form.status_cancelled_body': 'Contact those responsible if you have any questions regarding the form status.',
    'app.form.status_expired_subtitle': 'Looks like form has expired',
    'app.form.status_expired_body': 'Contact those responsible if you have any questions regarding the form status.',
    'app.form.invalid_status.by_time': 'Invalid by Time',
    'app.form.invalid_status.by_number_of_questions': 'Invalid by Number of Questions',
    'app.form.invalid_status.by_consecutive_answers': 'Invalid by Consecutive Answers',
    'app.form.invalid_status.by_common_answers': 'Invalid by Common Answers',
    'app.form.invalid_status.by_congruency': 'Invalid by Congruency',
    'app.form.invalid_status.by_consistency': 'Invalid by Consistency',
    'app.form.invalid_status.by_wrong_scale_value': 'Invalid by Wrong Scale Value',
    'app.form.custom_agreement.not_requested': 'Not requested',
    'app.form.custom_agreement.pending': 'Pending',
    'app.form.custom_agreement.accepted': 'Accepted',
    'app.form.custom_agreement.rejected': 'Rejected',
    'app.form.actions.tooltip.send_custom_agreement': 'Send custom agreement',
    'app.form.actions.tooltip.resend_custom_agreement': 'Resend custom agreement',
    'app.form.actions.tooltip.show_report': 'Show report',
    'app.listforms.ai_interactions': 'Ai Interactions'
}
