import 'antd/dist/reset.css';

import { Button, Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React from 'react';

type Props = FilterDropdownProps & {
    inputPlaceholder: string;
    confirmButtonText: string;
    resetButtonText: string;
}

export const TableFilterWithInput = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters, inputPlaceholder, confirmButtonText, resetButtonText
}: Props) => {
    return (
        <div style={styles.container}>
            <Input
                placeholder={inputPlaceholder}
                value={selectedKeys && selectedKeys.length > 0 ? selectedKeys[0].toString() : ''}
                onChange={e => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={styles.searchTextInput}
            />
            <Button
                type='primary'
                size='small'
                onClick={() => confirm()}
                style={{ ...styles.button, ...styles.rightSpace8 }}
            >
                {confirmButtonText}
            </Button>
            <Button onClick={clearFilters} size='small' style={styles.button}>
                {resetButtonText}
            </Button>
        </div>
    );
}

const styles = {
    container: {
        padding: '8px',
    } as React.CSSProperties,
    button: {
        verticalAlign: 'baseline',
        width: '100px',
    } as React.CSSProperties,
    searchTextInput: {
        width: '208px',
        marginBottom: '8px',
        display: 'block',
    } as React.CSSProperties,
    rightSpace8: {
        marginRight: '8px',
    } as React.CSSProperties,
}
