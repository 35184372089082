import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ScaleGraphBarList } from './ScaleGraphBarList';

type Props = {
    title: string[];
    subTitle?: string[][];
    negativeLifeEvents: ScaleProps[];
    needsClosure: ScaleProps[];
    needsFulfillment: ScaleProps[];
    identityDiffusion: ScaleProps[];
    basicOpenness: ScaleProps[];
    selfCertitude: ScaleProps[];
    basicDeterminism: ScaleProps[];
    socioemotionalConvergence: ScaleProps[];
    physicalResonance: ScaleProps[];
    emotionalAttunument: ScaleProps[];
    selfAwereness: ScaleProps[];
    meaningQuest: ScaleProps[];
    religiousTraditionalism: ScaleProps[];
    genderTraditionalism: ScaleProps[];
    socioCulturalOpenness: ScaleProps[];
    ecologicalResonance: ScaleProps[];
    globalResonance: ScaleProps[];
}

type ScaleProps = {
    title: string;
    value: number;
}

export const AggregateProfileByDemographics = (props: Props): JSX.Element => {
    return (
        <React.Fragment>
            <Row style={styles.marginTop10}>
                {props.title.map((item, index) =>
                    <Col style={styles.title} key={`Title-${index}`}>
                        <h4>{item}</h4>
                    </Col>
                )}
            </Row>
            {props.subTitle && props.subTitle.map((item, index) => (
                <Row style={styles.marginTop10}>
                    {item.map((item2, index) =>
                        <Col style={styles.title} key={`SubTitle-${index}`}>
                            <h5>{item2}</h5>
                        </Col>
                    )}
                </Row>
            ))
            }
            <hr />
            <hr />
            <ScaleGraphBarList
                {...props}
                hasHR={true}
            />
        </React.Fragment>
    )
}

const styles = {
    marginTop10: {
        marginTop: 10,
    } as React.CSSProperties,
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
    } as React.CSSProperties,
};
