import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../components/FormDropdown';
import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    organizationOptions: SelectItem[];
    organizationId?: number
    startDate: string;
    endDate: string;
    searchHandleClick: (organizationId: number, startDate: string, endDate: string) => void;
};

export const FilterFinancialReport = (
    { organizationOptions, organizationId, startDate, endDate, searchHandleClick }: Props
): JSX.Element => {
    const [startDateState, setStartDate] = useState<string>(startDate);
    const [endDateState, setEndDate] = useState<string>(endDate);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(
        organizationId !== undefined
            ? organizationId
            : organizationOptions && organizationOptions.length === 1 ? organizationOptions[0].value : undefined
    );

    const selectedOrganization = organizationOptions.find((item: SelectItem) => item.value === organizationId);
    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <h2 style={styles.h2ProgramTitle}>
                    {GetFormattedMessage('app.financialreport.title')}
                </h2>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        <Col md={4}>
                            <FormDropdown
                                isSearchable={true}
                                defaultOptionId={'app.listorganizations.select_organization'}
                                onValueSelect={(value: number | undefined) => {
                                    setSelectedOrganizationId(value);
                                }}
                                value={selectedOrganization}
                                optionItens={organizationOptions}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                value={startDateState}
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setStartDate(e.target.value)
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                value={endDateState}
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setEndDate(e.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={styles.buttonSearchDiv}>
                    <ButtonWithIcon
                        disabled={
                            selectedOrganizationId === undefined ||
                            startDateState === undefined || startDateState === '' ||
                            endDateState === undefined || endDateState === ''
                        }
                        textId={'app.listreport.search'}
                        onClick={() => {
                            if (selectedOrganizationId) searchHandleClick(selectedOrganizationId, startDateState, endDateState)
                        }}
                        icon={<FaSearch />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'right',
        paddingRight: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
};
