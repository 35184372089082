import { ListOrganizationsMessages } from '../types';

export const listOrganizationsDE: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'Erstellt am',
    'app.listorganizations.active': 'Aktiv',
    'app.listorganizations.inactive': 'Inaktiv',
    'app.listorganizations.new.program': 'Neues Programm oder Erfahrung',
    'app.listorganizations.new.group': 'Erstellen Sie eine neue BEVI-Administration und benennen Sie sie.',
    'app.listorganizations.view': 'Ansehen',
    'app.listorganizations.report': 'Bericht',
    'app.listorganizations.details': 'Überprüfe und verwalte die BEVI-Administration.',
    'app.listorganizations.tooltip.invite_by_qr_code': 'Laden Sie neue User:innen per QR-Code ein, dieser Gruppe beizutreten.',
    'app.listorganizations.tooltip.sub_program_edit': 'Bearbeiten Sie die BEVI-Administrationsparameter.',
    'app.listorganizations.table.beviname': 'Name BEVI-Administration',
    'app.listorganizations.table.created_on': 'Erstellt am',
    'app.listorganizations.table.due_date': 'Fälligkeitsdatum',
    'app.listorganizations.table.completed_percentage': '% abgeschlossen',
    'app.listorganizations.table.status': 'Status',
    'app.listorganizations.table.actions': 'Aktionen',
    'app.listorganizations.select_organization': 'Organisation auswählen.',
    'app.listorganizations.search': 'Suchen',
    'app.listorganizations.new_organization': 'Neue Organisation',
    'app.listorganizations.input_program_placeholder': 'Geben Sie den Namen des Programms oder der Erfahrung ein.',
    'app.listorganizations.input_subprogram_placeholder': 'Geben Sie den Namen der BEVI-Administration ein.',
    'app.listorganizations.organization_area': 'Verwaltung von Programmen und Erfahrungen',
    'app.listorganizations.download.data_report': 'Laden Sie die Daten als Excel-Datei herunter.',
    //Without translation
    'app.listorganizations.sort_name_date': 'Sort by name or date',
    'app.listorganizations.sort_by': 'Sort by',
    'app.sample.data.footer.message_line1': '<b><i>Notiz.</i> Das BEVI-Berichtssystem ermöglicht die Entwicklung von acht verschiedenen Berichtstypen: 1) Einzelberichte, 2) Einzeländerungsberichte, 3) Partnerberichte, 4) Partneränderungsberichte, 5) Gruppenberichte, 6) Gruppenänderungsberichte, 7) Innerhalb Gruppenberichte und 8) Zwischen Gruppenberichten. Um einen Eindruck davon zu vermitteln, wie diese Berichte funktionieren und auf welche Art von Informationen zugegriffen werden kann, werden zwei Beispiele für jeden Berichtstyp – A und B – bereitgestellt.</b>',
    'app.sample.data.footer.message_line2': '<b>Obwohl Beispielberichte mit tatsächlichen Berichten im BEVI-System übereinstimmen, dienen diese Beispiele nur zur Veranschaulichung. Daher wurden möglicherweise einige Informationen in diesen Beispielberichten geändert (z. B. Skalenwerte) oder entfernt (z. B. qualitative Antworten), um sicherzustellen, dass die Ergebnisse nicht identifiziert und/oder nicht mit bestimmten Personen verknüpft werden.</b>',
    'app.sample.data.footer.message_line3': '<b>Wenn Sie Fragen zu diesen Beispielberichten oder zu anderen Aspekten des BEVI haben, wenden Sie sich bitte entweder an die Person, die den BEVI-Administratorzugriff für Sie autorisiert hat, oder wenden Sie sich an das BEVI-Team unter <a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
