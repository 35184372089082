import { serviceUrl } from '../config/constants';
import { BaseResponse, fetchGetMethod, fetchPostMethod, getResponseObject } from '../rpc/api';
import { DemographicsQuestionsConfigurationModel } from './organizationService';
import { Reports } from './reportService';

const listFormsErrorMessageKey = 'app.error.list.forms';
const initializeFormErrorMessageKey = 'app.error.initialize.form';
const restartFormErrorMessageKey = 'app.error.restart.form';
const externalFormStatusErrorMessageKey = 'app.error.external.form.status';

export type ListFormsBody = Array<FormDetails>;

export class ListFormsResponse extends BaseResponse<ListFormsBody> { }

export type InitializeOrRestartFormBody = {
  result?: boolean;
}

export class InitializeOrRestartFormResponse extends BaseResponse<InitializeOrRestartFormBody> { }

export type FormDetails = {
  id: number;
  status: number;
  percentageCompleted: number;
  organizationName: string;
  programName: string;
  hideQuestions: DemographicsQuestionsConfigurationModel;
  subProgramName: string;
  availableDate: Date;
  dueDate: Date;
  completedOn?: Date;
  currentScalePage: number;
  showReport: boolean;
  canRestart: boolean;
  extraQuestions?: FormDetailsExtraQuestion[],
  type?: Reports
}

export type FormDetailsExtraQuestion = {
  extraQuestionOptions?: FormDetailsExtraQuestionOptions[],
  id: number,
  title: string,
  type: number
}

export type FormDetailsExtraQuestionOptions = {
  description: string,
  id: number
}

export function listForms(token: string): Promise<ListFormsResponse> {
  const response = fetchGetMethod(`${serviceUrl}/api/form/ListForms`, token);
  return getResponseObject<ListFormsBody>(response, listFormsErrorMessageKey);
}

export function initializeForms(token: string, formId: number): Promise<InitializeOrRestartFormResponse> {
  const body = { FormId: formId };
  const response = fetchPostMethod(`${serviceUrl}/api/form/InitializeForm`, token, body);
  return getResponseObject<InitializeOrRestartFormBody>(response, initializeFormErrorMessageKey);
}

export function restartForm(token: string, formId: number): Promise<InitializeOrRestartFormResponse> {
  const body = { FormId: formId };
  const response = fetchPostMethod(`${serviceUrl}/api/form/RestartForm`, token, body);
  return getResponseObject<InitializeOrRestartFormBody>(response, restartFormErrorMessageKey);
}

export type ExternalFormStatus = {
    status: number;
    canRestart: boolean;
    isValid: boolean;
};
export class ExternalFormStatusResponse extends BaseResponse<ExternalFormStatus> { }
export function externalFormStatus(token: string): Promise<ExternalFormStatusResponse> {
  const response = fetchGetMethod(`${serviceUrl}/api/form/ExternalFormStatus`, token);
  return getResponseObject<ExternalFormStatus>(response, externalFormStatusErrorMessageKey);
}
