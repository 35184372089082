import { TimeMessages } from '../types';

export const timeJA: TimeMessages = {
  'app.time.day': '日',
  'app.time.days': '日間',
  'app.time.hour': '時',
  'app.time.hours': '時間',
  'app.time.minute': '分',
  'app.time.minutes': '分',
  'app.time.second': '秒',
  'app.time.seconds': '秒',
  'app.time.and': '',
}

