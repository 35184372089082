import { FinancialReportMessages } from '../types';

export const financialReportDE: FinancialReportMessages = {
    'app.financialreport.title': 'Verwendungsbericht',
    'app.financialreport.organization_lbl': 'Organisation',
    'app.financialreport.start_data_lbl': 'Startdatum',
    'app.financialreport.end_data_lbl': 'Enddatum',
    'app.financialreport.created_forms_title': 'Nr. EINLADUNGEN ZUM BEVI',
    'app.financialreport.finished_forms_title': 'Nr. ABGESCHLOSSENE BEVIS',
    'app.financialreport.valid_forms_title': 'Nr. GÜLTIGE BEVIS',
    'app.financialreport.invalid_forms_title': 'Nr. UNGÜLTIGE BEVIS',
    'app.financialreport.unanswered_forms_title': 'Nr. UNBEANTWORTETE BEVIS',
    'app.financialreport.error_header': 'Fehler',
    'app.financialreport.error_text': 'Es ist ein Fehler aufgetreten und die Berichtsdaten konnten nicht geladen werden, bitte versuchen Sie es erneut.',
}
