import { Button, InputNumber, notification, Space } from "antd"
import React, { useEffect, useState } from "react"
import { useProgramStore } from "../../../redux/program.store"
import { GetFormattedMessage } from "../../../utils/htmlHelper"
import { appLogic, AppProps } from "../../../redux/appLogic"
import { useValues } from 'kea';

type Props = {
    record?: any,
    records?: any[],
    afterSuccess?: () => void
}

export const UserAiInteractionsInput = ({
    record, records, afterSuccess
}: Props): JSX.Element => {
    const [value, setValue] = useState<number>();
    const { aiInteractionsLoading, changeAiInteractions, aiInteractionsSuccessful, aiInteractionsError, callAfterSuccess } = useProgramStore();
    const { token }: AppProps = useValues(appLogic);
    const successMessage = GetFormattedMessage('app.save_with_success');
    const errorMessage = GetFormattedMessage('app.institutions.add.error_message');

    useEffect(() => {
        if (aiInteractionsSuccessful) {
            notification.success({ message: successMessage, key: 'saveAiInteractions' });
        }
    }, [aiInteractionsSuccessful, successMessage])

    useEffect(() => {
        if (callAfterSuccess && afterSuccess) {
            afterSuccess();
        }
    }, [callAfterSuccess, afterSuccess])

    useEffect(() => {
        if (aiInteractionsError) {
            notification.error({ message: errorMessage, key: 'errorAiInteractions' });
        }
    }, [errorMessage, aiInteractionsError])

    useEffect(() => {
        if (record != null) {
            setValue(record.aiInteractions ?? 0);
        }
    }, [record])

    return <Space.Compact style={{ width: '100%' }}>
        <InputNumber value={value} onChange={(value) => setValue(value ?? 0)} />
        <Button disabled={value == null} onClick={() => {
            changeAiInteractions(records == null ? [record?.formId] : records.map(x => x?.formId), value ?? 0, token)
        }} loading={aiInteractionsLoading.includes(record?.formId) || (records != null && aiInteractionsLoading.length > 0)}
            type="primary">{GetFormattedMessage('app.save')}</Button>
    </Space.Compact>
}