import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { FormDropdown } from '../../../components/FormDropdown';
import { LocalStorage_BeviLanguage } from '../../../IntlProviderWrapper';
import { PageMessages } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import { ColSpec } from '../../../utils/formHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { isConditionApproved, SelectQuestion } from '../library/questionDefinitions';

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    question: SelectQuestion;
    answerChange: (key: keyof (DemographicQuestionAnswers), value: number | string | undefined) => void;
    defaultOptionId?: keyof (PageMessages);
    questionNumber: number;
    isSearchable?: boolean;
    isClearable?: boolean;
    style?: React.CSSProperties;
};

export const DropdownOptionsQuestion = (
    {
        question, answerChange, defaultOptionId, questionNumber, isSearchable, isClearable,
        style, optionsMd, ...groupProps
    }: Props
): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState<number>();

    const conditionalProps = question.GetConditonalProps();
    const isJapanese = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    const [hasConditionalApproved, setHasConditionalApproved] = useState<boolean>(false);
    useEffect(() => {
        if (conditionalProps) {
            const approved = isConditionApproved(conditionalProps.coditional, selectedValue);
            if (hasConditionalApproved === true && approved === false) {
                answerChange(conditionalProps!!.questionKeyName, undefined);
            }
            setHasConditionalApproved(approved);
        }
    }, [answerChange, conditionalProps, hasConditionalApproved, selectedValue]);

    const questionExplanation = question.GetQuestionExplnationLabelId();
    const questionLabel = GetFormattedMessage(question.GetQuestionLabelId());

    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <div dangerouslySetInnerHTML={{ __html: questionLabel }}></div>
                </div>
                {questionExplanation && questionExplanation !== undefined && !isJapanese &&
                    <div style={styles.divQuestionWithNumber}>
                        <FormattedMessage id={questionExplanation} />
                    </div>
                }
            </Form.Label>
            <br />
            <Col md={optionsMd}>
                <FormDropdown
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    defaultOptionId={defaultOptionId ? defaultOptionId : 'app.dropdown_select'}
                    optionItens={question.GetDefintions()}
                    onValueSelect={(value: number | undefined, _: string) => {
                        answerChange(question.GetQuestionKeyName(), value);
                        setSelectedValue(value)
                    }}
                />
            </Col>
            { hasConditionalApproved &&
                <React.Fragment>
                    <p />
                    <Form.Label column={true}>
                        <FormattedMessage id={conditionalProps!!.labelId} />
                    </Form.Label>
                    <Col md={optionsMd}>
                        <Form.Control
                            as='input'
                            onChange={(e) => {
                                answerChange(conditionalProps!!.questionKeyName, e.currentTarget.value);
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
