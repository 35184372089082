import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { PageMessages } from '../../../locales/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = Partial<typeof Form.Group> & { groupAs?: typeof Col, groupMd?: string } & {
    value?: string | undefined;
    labelKey?: keyof PageMessages;
    useSwitchToDisable?: boolean;
    onFilterChange?: (value: string | undefined) => void;
    type?: "text" | "number" | "email" | "password" | "date" | "time" | "file";
    onBlur?: (value: string | undefined) => void;
};

export const LineEditTextGroup = (
    { value, labelKey, onFilterChange, onBlur, useSwitchToDisable, groupAs, groupMd, type, ...groupProps }: Props
): JSX.Element => {

    const [currentValue, setCurrentValue] = useState<string>(value || "");
    const [switchState, setSwitchState] = useState<boolean>(
        useSwitchToDisable === false || (value !== undefined && value?.length > 0));

    return (
        <Form.Group className='mb-3' as={groupAs} md={groupMd} {...groupProps}>
            { useSwitchToDisable !== false &&
                <Form.Check
                    type='switch'
                    inline={true}
                    id={labelKey}
                    label={labelKey && GetFormattedMessage(labelKey)}
                    defaultChecked={switchState}
                    readOnly={true}
                    onClick={() => {
                        if (switchState) onFilterChange && onFilterChange(undefined);
                        else if (currentValue !== undefined) onFilterChange && onFilterChange(currentValue);

                        setSwitchState(!switchState);
                    }}
                />
            }
            <Form.Control
                as='input'
                disabled={!switchState}
                value={currentValue}
                type={type}
                onChange={(e) => {
                    const text: string = e.currentTarget.value;
                    setCurrentValue(text);
                    onFilterChange && onFilterChange(text);
                }}
                onBlur={() => {
                    onBlur && onBlur(currentValue);
                }}
            />
        </Form.Group>
    )
}
