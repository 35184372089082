import { InternalTemplateMessages } from '../types';

export const internalTemplatePageES: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'Bienvenido(a)',
    'app.internaltemplate.menu.dashboard': 'Panel',
    'app.internaltemplate.menu.users': 'Usuarios',
    'app.internaltemplate.menu.organization': 'Programas y Experiencias',
    'app.internaltemplate.menu.institutions': 'Organizaciones',
    'app.internaltemplate.menu.report': 'Informes',
    'app.internaltemplate.menu.sample_report': 'Informe de muestra',
    'app.internaltemplate.menu.permission_profile': 'Perfil de permisos',
    'app.internaltemplate.menu.financial_report': 'Informe de uso',
    'app.internaltemplate.menu.logout': 'Cerrar sesión',
    'app.internaltemplate.menu.virtualAccount': 'Cuenta virtual',
    'app.internaltemplate.system.maintenance_time': 'El sistema estará en mantenimiento desde {0} al {1}.',
    'app.internaltemplate.menu.report_permission': 'Permiso de informe'
}
