import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { SelectItem } from '../../../components/FormDropdown';
import { DemographicQuestionAnswers } from '../../../services/types';
import { ColSpec } from '../../../utils/formHelper';
import { isConditionApproved, SelectQuestion } from '../library/questionDefinitions';

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    question: SelectQuestion
    type: 'checkbox' | 'radio' | 'switch';
    answerChange: (key: keyof (DemographicQuestionAnswers), value: number | string | undefined) => void;
    inline?: boolean;
    questionNumber: number;
    style?: React.CSSProperties;
};

export const CheckOptionsQuestion = (
    { question, answerChange, inline, type, questionNumber, style, optionsMd, ...groupProps }: Props
): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState<number>();

    const questionLabelId = question.GetQuestionLabelId();
    const conditionalProps = question.GetConditonalProps();

    const [hasConditionalApproved, setHasConditionalApproved] = useState<boolean>(false);
    useEffect(() => {
        if (conditionalProps) {
            const approved = isConditionApproved(conditionalProps.coditional, selectedValue);
            if (hasConditionalApproved === true && approved === false) {
                answerChange(conditionalProps!!.questionKeyName, undefined);
            }
            setHasConditionalApproved(approved);
        }
    }, [answerChange, conditionalProps, hasConditionalApproved, selectedValue]);

    const OnQuestionAnswerChange = (value: number): void => {
        let newValue = value;
        if (type === 'checkbox' && selectedValue !== undefined) {
            newValue ^= selectedValue;
        }

        answerChange(question.GetQuestionKeyName(), newValue);
        setSelectedValue(newValue);
    }

    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={questionLabelId} />
                </div>
            </Form.Label>
            <Col md={optionsMd}>
                {question.GetDefintions().map((checkOption: SelectItem, index: number) => {
                    return (
                        <div key={`${questionLabelId}_${index}`} style={styles.divQuestionWithNumber}>
                            <Form.Check
                                inline={inline}
                                type={type}
                                label={''}
                                name={questionLabelId}
                                id={`${questionLabelId}_${index}`}
                                onClick={() => OnQuestionAnswerChange(checkOption.value ?? -1)}
                            />
                            <label dangerouslySetInnerHTML={{__html: checkOption.label}}></label>
                        </div>
                    )
                })}
            </Col>
            {hasConditionalApproved &&
                <React.Fragment>
                    <p />
                    <Form.Label>
                        <FormattedMessage id={conditionalProps!!.labelId} />
                    </Form.Label>
                    <Form.Control
                        as='input'
                        onChange={(e) => {
                            answerChange(conditionalProps!!.questionKeyName, e.currentTarget.value);
                        }}
                    />
                </React.Fragment>
            }
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
