import { InstitutionMessages } from '../types';

export const institutionZHCN: InstitutionMessages = {
  'app.institutions.title': 'Organization Management',
  'app.institutions.new': 'New Organization',
  'app.institutions.search': 'Search',
  'app.institutions.list.name': 'Name',
  'app.institutions.list.edit': 'Edit',
  'app.institutions.list.action': 'Actions',
  'app.institutions.add.title': 'New Organization',
  'app.institutions.edit.title': 'Edit Organization',
  'app.institutions.add.successful_message': 'Organization was successful updated.',
  'app.institutions.add.error_message': 'Something went wrong, please, try again in a few minutes.',
  'app.institutions.add.new_name': 'New name',
  'app.institutions.add.button_save': 'Save',
  'app.institutions.add.hide_demographics_questions': 'Hide Demographics Questions',
  'app.institutions.add.demographics_extra_questions': 'Demographics Extra Questions',
  'app.institutions.hide_demographics_questions.successful_message': 'Demographics questions was successful updated.',
  'app.institutions.demographics_questions.question': 'Question',
  'app.institutions.demographics_questions.type': 'Type',
  'app.institutions.demographics_questions.type_0': 'Default',
  'app.institutions.demographics_questions.type_1': 'Text',
  'app.institutions.demographics_questions.type_2': 'Single Option',
  'app.institutions.demographics_questions.add.title': 'New Extra Question',
  'app.institutions.demographics_questions.edit.title': 'Edit Extra Question',
  'app.institutions.demographics_questions.title': 'Title',
  'app.institutions.demographics_questions.options': 'Options',
  'app.institutions.demographics_questions.description': 'Description',
  'app.institutions.demographics_questions.delete': 'Are you sure to delete this item?',
  'app.institutions.chat.successful_message': 'In a few minutes, all users will be able to use AI chat.',
  'app.institutions.chat.add_chat_all': 'Add Chat for all Users',
  'app.institutions.chat.question_add_chat_all': 'Are you sure you want to include chat for all users in this organization?'
}