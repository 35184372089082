import { FinancialReportMessages } from '../types';

export const financialReportCA: FinancialReportMessages = {
    'app.financialreport.title': 'Informe d’ús',
    'app.financialreport.organization_lbl': 'Organització',
    'app.financialreport.start_data_lbl': 'Data d’inici',
    'app.financialreport.end_data_lbl': 'Data de finalització',
    'app.financialreport.created_forms_title': 'Nº DE CONVIDATS A COMPLETAR EL BEVI',
    'app.financialreport.finished_forms_title': 'Nº DE BEVIS COMPLETATS',
    'app.financialreport.valid_forms_title': 'Nº DE BEVIS VÀLIDS',
    'app.financialreport.invalid_forms_title': 'Nº DE BEVIS NO VÀLIDS',
    'app.financialreport.unanswered_forms_title': 'Nº DE BEVIS SENSE CONTESTAR',
    'app.financialreport.error_header': 'Error',
    'app.financialreport.error_text': 'S’ha produït un error i no s’han pogut carregar les dades de l’informe. Torna-ho a provar.',
}
