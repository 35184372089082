import { ListFormPageMessages } from '../types';

export const listFormsPageZHTW: ListFormPageMessages = {
    'app.listforms.title': 'BEVI仪表板(BEVI Dashboard）',
    'app.listforms.subtitle': '在BEVI仪表板上，可参加BEVI考评、阅览和管理你的个人结果。',
    'app.listforms.organization': '大学或机构',
    'app.listforms.participant': '被考评者',
    'app.listforms.program': '课程项目',
    'app.listforms.take_by': '截止日期',
    'app.listforms.completed_on': '回答日期',
    'app.listforms.progress': '进度',
    'app.listforms.status': '状态',
    'app.listforms.action': '操作',
    'app.listforms.organization_tooltip': '此仪表板可参加BEVI考评、阅览和管理其结果。',
    'app.listforms.program_tooltip': '您參加 BEVI 的報告、計劃或體驗的名稱。',
    'app.listforms.take_by_tooltip': '请在该日期前完成BEVI考评。',
    'app.listforms.completed_on_tooltip': '完成BEVI考评的日期',
    'app.listforms.progress_tooltip': 'BEVI考评的进度（显示“100%”时，表示“你已完成BEVI考评”）',
    'app.listforms.status_tooltip': 'BEVI考评状态',
    'app.listforms.action_tooltip': '可参加或继续BEVI考评或再考评，并能阅览其个人结果。显示的方框是现在可选择的选项。',
    'app.listforms.all': 'All',
    'app.listforms.available': '未回答',
    'app.listforms.onDemographics': '背景信息填写中',
    'app.listforms.onScales': '客观选择题回答中',
    'app.listforms.onSubjective': '主观题回答中',
    'app.listforms.finished': '完成',
    'app.listforms.cancelled': '取消',
    'app.listforms.expired': '已过期',
    'app.listforms.archived': 'Archived',
    'app.listforms.invalid': '无效',
    'app.listforms.start': '开始',
    'app.listforms.continue': '继续',
    'app.listforms.report': '阅览结果',
    'app.listforms.partner_report': '查看合作夥伴報告',
    'app.listforms.partner_change_report': '查看合作夥伴變更報告',
    'app.listforms.restart': '再考评',
    'app.listforms.confirmation_title': '操作指南',
    'app.listforms.retart_confirmation_title': '再考评',
    'app.listforms.restart.instruction.part1': '请点击以下按钮再次进行BEVI考评。',
    'app.listforms.instruction.part1': '以下考评由背景信息及185道客观选择题构成。关于185道客观选择题，每页将显示20道题，请仔细阅读每道题并从4个选项“非常同意、同意、不同意、非常不同意”中选出一个最能描述您感受的选项。',
    'app.listforms.instruction.part2': '每回答完20道题，请点击“下一页”继续答题。一旦点击“下一页”后，将不能返回上一页进行修改。',
    'app.listforms.instruction.part3': '在开始BEVI考评前，你必须阅读其概要及同意书。',
    'app.listforms.instruction.press.start': '非常感谢您参加BEVI考评。若您准备就绪，请点击“开始”按钮。',
    'app.listforms.comparative_individual_report': '比較個人報告',
    'app.form.status_title': '目前没有可以参加的BEVI考评。',
    'app.form.status_finished_subtitle': '已完成该BEVI考评。',
    'app.form.status_finished_body': '若对此BEVI考评有疑问，请咨询BEVI实施负责人。',
    'app.form.status_cancelled_subtitle': '此BEVI考评已被取消。',
    'app.form.status_cancelled_body': '若对此BEVI考评有疑问，请咨询BEVI实施负责人。',
    'app.form.status_expired_subtitle': '此BEVI考评已过期。',
    'app.form.status_expired_body': '若对此BEVI考评有疑问，请咨询BEVI实施负责人。',
    'app.form.invalid_status.by_time': '回答时间',
    'app.form.invalid_status.by_number_of_questions': '答题数不足',
    'app.form.invalid_status.by_consecutive_answers': '连续答题',
    'app.form.invalid_status.by_common_answers': '同一形式',
    'app.form.invalid_status.by_congruency': '缺乏适宜性',
    'app.form.invalid_status.by_consistency': '缺乏一貫性',
    'app.form.invalid_status.by_wrong_scale_value': 'Invalid by Wrong Scale Value',
    'app.listforms.custom_agreement_status': 'Custom Agreement Status',
    'app.form.custom_agreement.not_requested': 'Not requested',
    'app.form.custom_agreement.pending': 'Pending',
    'app.form.custom_agreement.accepted': 'Accepted',
    'app.form.custom_agreement.rejected': 'Rejected',
    'app.form.actions.tooltip.send_custom_agreement': 'Send custom agreement',
    'app.form.actions.tooltip.resend_custom_agreement': 'Resend custom agreement',
    'app.form.actions.tooltip.show_report': 'Show report',
    'app.listforms.ai_interactions': 'AI互動'
}
