import { CreateReportMessages } from '../types';

export const createReportCA: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'Nom fals per a tots els participants',
    'app.create_report.btn_real_name_all': 'Nom real per a tots els participants',
    'app.create_report.btn_fake_name': 'Utilitzar un nom fals',
    'app.create_report.btn_real_name': 'Utilitzar el nom real',
    'app.create_report.title': 'Gestió d’informes',
    'app.create_report.summary_line1': 'El sistema de gestió BEVI et permet generar informes basats en totes les dades del grup amb el qual estàs treballant. Hi ha quatre tipus d’informes possibles: <br />' +
        '&nbsp;&nbsp;1) Informe de grup (aquest informe es basa en un període de temps durant el qual s’administra el BEVI); <br />' +
        '&nbsp;&nbsp;2) Informe longitudinal (aquest informe es completa al llarg del temps, com ara T1, T2, T3, etc.)   <br />' +
        '&nbsp;&nbsp;3) Informe entre grups (aquest informe permet comparar diferents grups dins de i entre institucions o organitzacions). <br />' +
        '&nbsp;&nbsp;4) Informes dins del mateix grup (aquests informes comparen individus d’un grup a través d’escales BEVI, però ho fan de manera anònima) ',
    'app.create_report.summary_line2': 'Tots aquests informes es poden generar automàticament (és a dir, utilitzaran automàticament totes les dades del teu grup o grups). També pots afinar cadascun d’aquests informes per centrar-vos en diferents combinacions d’ítems d’informació personal. Per exemple, podeu generar un informe que només compari dones i homes de dos països diferents. Hi ha literalment centenars de combinacions que pots seleccionar mitjançant aquesta funcionalitat d’afinament. ',
    'app.create_report.step1': 'PAS 1 – CONFIGURANT EL TEU INFORME',
    'app.create_report.step2': "pas 2 - AFEGIR GRUP D'INFORME",
    'app.create_report.step3': 'PAS 3 – AFINANT EL TEU INFORME',
    'app.create_report.step3Individual': 'PAS 3 - QUI POT VEURE EL INFORME?',
    'app.create_report.step4': 'PAS 4 – GESTIONANT PARTICIPANTS',
    'app.create_report.step3_username_column': 'Nom d’usuari',
    'app.create_report.step3_aliasname_column': 'Nom d’àlies',
    'app.create_report.step3_action_column': 'Acció',
    'app.create_report.step3_no_user_title': 'No s’ha trobat cap usuari',
    'app.create_report.step3_no_user_subtitle': 'Si us plau canvia els paràmetres dels passos anteriors i torna a cercar usuaris.',
    'app.create_report.step3_user_name_column_title': 'Participants',
    'app.create_report.step3_user_exporting_column_title': 'Inclòs/oses',
    'app.create_report.requester': 'Sol·licitant',
    'app.create_report.name': "Nom de l'informe",
    'app.create_report.report_type': 'Tipus',
    'app.create_report.select_subprogram': 'Seleccioneu dades per generar l’informe',
    'app.create_report.select_subprogram_warning': '(si us plau omple els camps anteriors)',
    'app.create_report.group_report': 'Informe de grup',
    'app.create_report.longitudinal_report': 'Informe de canvis de grup',
    'app.create_report.comparative_by_group_report': 'Informes entre grups',
    'app.create_report.comparative_by_individual_report': 'Informes dins del mateix grup',
    'app.create_report.select_organization': 'Selecciona una organització.',
    'app.create_report.select_program': 'Selecciona un programa o experiència.',
    'app.create_report.select_sub_program': 'Selecciona una administració BEVI.',
    'app.create_report.organization_name': 'Organització',
    'app.create_report.program_name': 'Programa o experiència',
    'app.create_report.sub_program_name': 'Administració BEVI',
    'app.create_report.actions': 'Elimina',
    'app.create_report.action_remove': 'Eliminar',
    'app.create_report.show_filters': 'Mostrar criteris de filtre',
    'app.create_report.hide_filters': 'Amagar criteris de filtre',
    'app.create_report.filter_select_default_option': 'Seleccionar per filtrar...',
    'app.create_report.filter_select_with_values': 'Filtrant',
    'app.create_report.filter_age': 'Edat',
    'app.create_report.filter_gender': 'Gènere',
    'app.create_report.filter_years_of_education': 'Anys d’estudis',
    'app.create_report.filter_primary_country_of_origin': 'País d’origen',
    'app.create_report.filter_marital_status': 'Estat civil',
    'app.create_report.filter_political_orientation': 'Orientació política (conservador a liberal)',
    'app.create_report.filter_ethnic_background': 'Origen ètnic',
    'app.create_report.filter_religious_orientation': 'Orientació Religiosa?',
    'app.create_report.filter_religious_commitment': 'Compromís Religiós?',
    'app.create_report.filter_academic_rank': 'Rang',
    'app.create_report.filter_time_spent_in_other_countries': 'Temps passat a altres països',
    'app.create_report.filter_nro_visited_countries': 'Països visitats',
    'app.create_report.filter_fluency': 'Fluïdesa',
    'app.create_report.filter_years_of_language_study': 'Anys d’Estudi d’Idiomes',
    'app.create_report.filter_mother_country': 'País d’origen de la mare',
    'app.create_report.filter_father_country': 'País d’origen del pare',
    'app.create_report.filter_mother_education': 'Educació de la mare',
    'app.create_report.filter_father_education': 'Educació del pare',
    'app.create_report.filter_minority_group': 'Grup minoritari',
    'app.create_report.filter_personal_interest': 'Interès en experiències internacionals / culturals',
    'app.create_report.filter_study_abroad_satisfaction': 'Satisfacció amb experiències internacionals / culturals',
    'app.create_report.filter_primary_area_of_work': 'Àrea principal de treball',
    'app.create_report.filter_area_of_occupations': 'Camp professional',
    'app.create_report.filter_income_level_of_your_home_country': 'Nivell d’ingressos',
    'app.create_report.btn_search': 'Cercar',
    'app.create_report.btn_save': 'Generar',
    'app.create_report.placeholder.name': 'Escriu el nom de l’informe',
    'app.create_report.select.report_type': 'Selecciona el tipus d’informe',
    'app.create_report.select_countries_options_lbl': 'Tria almenys un país d’origen per contrastar amb tots els altres:',
    'app.create_report.selected_countries_lbl': 'Països seleccionats:',
    'app.create_report.select_ethnics_options_lbl': 'Tria almenys un grup ètnic per contrastar amb tots els altres:',
    'app.create_report.selected_ethnics_lbl': 'Orientació ètnica seleccionada:',
    'app.create_report.select_number_of_forms_lbl': 'Tria el nombre màxim d’informes BEVI a l’apartat discursiu:',
    'app.create_report.select_relationship_status_options_lbl': 'Trieu almenys un estat de relació per contrastar amb tots els altres:',
    'app.create_report.select_religion_options_lbl': 'Trieu almenys una religió per contrastar amb totes les altres:',
    'app.create_report.select_primary_area_work_options_lbl': 'Trieu almenys una àrea de treball principal per contrastar amb totes les altres:',
    'app.create_report.selected_relationship_status_lbl': 'Estat de relació seleccionat',
    'app.create_report.selected_religion_lbl': 'Orientació religiosa seleccionada',
    'app.create_report.selected_primary_area_work_lbl': 'Àrea de treball principal seleccionada',
    'app.create_report.individual_report': 'Informe Individual',
    'app.create_report.longitudinal_individual_report': 'Informe de canvis individuals',
    'app.create_report.combined_report': 'Informe de socis',
    'app.create_report.individual': 'Individual',
    'app.create_report.partner': 'Socis',
    'app.create_report.group': 'Grup',
    'app.create_report.select.group': 'Selecciona el grup',
    'app.create_report.partner_change_report': 'Informe de canvi de soci',
    'app.create_report.email_in_use': 'Correu electrònic en ús',
    'app.create_report.add_new_participant': 'Afegir nou participant'
}
