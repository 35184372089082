import { InstitutionMessages } from '../types';

export const institutionDE: InstitutionMessages = {
  'app.institutions.title': 'Organisationsmanagement',
  'app.institutions.new': 'Neue Organisation',
  'app.institutions.search': 'Suche',
  'app.institutions.list.name': 'Name',
  'app.institutions.list.edit': 'Bearbeiten',
  'app.institutions.list.action': 'Aktionen',
  'app.institutions.add.title': 'Neue Organisation',
  'app.institutions.edit.title': 'Organisation bearbeiten',
  'app.institutions.add.successful_message': 'Organisation wurde erfolgreich aktualisiert.',
  'app.institutions.add.error_message': 'Etwas ist schief gelaufen. Bitte versuchen Sie es in ein paar Minuten erneut.',
  'app.institutions.add.new_name': 'Neuer Name',
  'app.institutions.add.button_save': 'Speichern',
  'app.institutions.add.hide_demographics_questions': 'Demografische Fragen ausblenden',
  'app.institutions.hide_demographics_questions.successful_message': 'Die demografischen Fragen wurden erfolgreich aktualisiert.',
  //Without translation
  'app.institutions.add.demographics_extra_questions': 'Demographics Extra Questions',
  'app.institutions.demographics_questions.question': 'Question',
  'app.institutions.demographics_questions.type': 'Type',
  'app.institutions.demographics_questions.type_0': 'Default',
  'app.institutions.demographics_questions.type_1': 'Text',
  'app.institutions.demographics_questions.type_2': 'Single Option',
  'app.institutions.demographics_questions.add.title': 'New Extra Question',
  'app.institutions.demographics_questions.edit.title': 'Edit Extra Question',
  'app.institutions.demographics_questions.title': 'Title',
  'app.institutions.demographics_questions.options': 'Options',
  'app.institutions.demographics_questions.description': 'Description',
  'app.institutions.demographics_questions.delete': 'Are you sure to delete this item?',
  'app.institutions.chat.successful_message': 'In a few minutes, all users will be able to use AI chat.',
  'app.institutions.chat.add_chat_all': 'Add Chat for all Users',
  'app.institutions.chat.question_add_chat_all': 'Are you sure you want to include chat for all users in this organization?'
}
