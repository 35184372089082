import { GetFormattedMessage } from './htmlHelper';
import { ScaleGroup } from '../services/reportService';

enum Indicator {
    LessThan30 = 1,
    LessThan69 = 2,
    GreaterThan69 = 3,
}

export const GetToleranceOfDisequilibruim = (indicator: number): string[] => {
    if (indicator === Indicator.GreaterThan69) {
        return [
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.greaterthan69.text'),
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1'),
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2'),
        ];
    } else if (indicator === Indicator.LessThan69) {
        return [
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan69.text'),
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan69.text2'),
            GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan69.text3'),
        ];
    }

    return [
        GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan30.text'),
        GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan30.text2_1'),
        GetFormattedMessage('app.individualreport.tolerancedisequilibrium.lessthan30.text2_2'),
    ];
}

export const GetWhyWeDoText = (indicator: number): string[] => {
    if (indicator === Indicator.LessThan30) {
        return [
            GetFormattedMessage('app.individualreport.whywedo.lessthan30.text'),
            GetFormattedMessage('app.individualreport.static_text_7'),
        ];
    } else if (indicator === Indicator.LessThan69) {
        return [
            GetFormattedMessage('app.individualreport.whywedo.lessthan69.text'),
            GetFormattedMessage('app.individualreport.static_text_7'),
        ];
    }
    return [
        GetFormattedMessage('app.individualreport.whywedo.greaterthan69.text'),
        GetFormattedMessage('app.individualreport.static_text_7'),
    ];
}

export const GetAccessSelf = (accessSelfIndicator: number, selfAwarenessIndicator: number): string[] => {

    let result: string[] = [];
    if (accessSelfIndicator === Indicator.LessThan30) {
        result.push(GetFormattedMessage('app.individualreport.accessself.textC'));
    } else if (accessSelfIndicator === 2) {
        result.push(GetFormattedMessage('app.individualreport.accessself.textB'));
    } else {
        result.push(GetFormattedMessage('app.individualreport.accessself.textA'));
    }

    if (selfAwarenessIndicator === Indicator.LessThan30) {
        result.push(GetFormattedMessage('app.individualreport.accessself.textI'));
    } else if (selfAwarenessIndicator === 2) {
        result.push(GetFormattedMessage('app.individualreport.accessself.textH'));
    } else {
        result.push(GetFormattedMessage('app.individualreport.accessself.textG'));
    }

    result.push(GetFormattedMessage('app.individualreport.static_text_8'));
    result.push(GetFormattedMessage('app.individualreport.static_text_9_1'));
    result.push(GetFormattedMessage('app.individualreport.static_text_9_2'));
    result.push(GetFormattedMessage('app.individualreport.static_text_9_3'));
    result.push(GetFormattedMessage('app.individualreport.static_text_9_4'));
    return result;
}

export const GetAccessToThoughtsAndFeelingsOfOthers = (indicator: number): string => {
    if (indicator === Indicator.LessThan30) {
        return GetFormattedMessage('app.individualreport.accessothers.textC');
    } else if (indicator === Indicator.LessThan69) {
        return GetFormattedMessage('app.individualreport.accessothers.textB');
    } else {
        return GetFormattedMessage('app.individualreport.accessothers.textA');
    }
}

export const GetGenderTradition = (indicator: number): string => {
    if (indicator === Indicator.LessThan30) {
        return GetFormattedMessage('app.individualreport.gendertradition.textC');
    } else if (indicator === Indicator.LessThan69) {
        return GetFormattedMessage('app.individualreport.gendertradition.textB');
    } else {
        return GetFormattedMessage('app.individualreport.gendertradition.textA');
    }
}

export const GetVariables = (indicator: number): string[] => {
    if (indicator === Indicator.LessThan30) {
        return [GetFormattedMessage('app.individualreport.variables.textG_1'), GetFormattedMessage('app.individualreport.variables.textG_2')];
    } else if (indicator === Indicator.LessThan69) {
        return [GetFormattedMessage('app.individualreport.variables.textF')];
    } else {
        return [GetFormattedMessage('app.individualreport.variables.textE_1'), GetFormattedMessage('app.individualreport.variables.textE_2')];
    }
}

export const GetAccessLargeWorld = (ecologicalResonanceIndicator: number, globalEngagementIndicator: number): string[] => {

    let result: string[] = [];
    result.push(GetFormattedMessage('app.individualreport.static_text_10_1'));
    result.push(GetFormattedMessage('app.individualreport.static_text_10_2'));
    result.push(GetFormattedMessage('app.individualreport.static_text_10_3'));

    if (ecologicalResonanceIndicator === Indicator.LessThan30) {
        result.push(GetFormattedMessage('app.individualreport.ecologicalresonance.textC'));
    } else if (ecologicalResonanceIndicator === Indicator.LessThan69) {
        result.push(GetFormattedMessage('app.individualreport.ecologicalresonance.textB'));
    } else {
        result.push(GetFormattedMessage('app.individualreport.ecologicalresonance.textA'));
    }

    if (globalEngagementIndicator === Indicator.LessThan30) {
        result.push(GetFormattedMessage('app.individualreport.globalengagement.textC'));
    } else if (globalEngagementIndicator === Indicator.LessThan69) {
        result.push(GetFormattedMessage('app.individualreport.globalengagement.textB'));
    } else {
        result.push(GetFormattedMessage('app.individualreport.globalengagement.textA'));
    }

    return result;
}

export const GetNegativeLifeEvents = (negativeLifeEventsIndicator: number): string => {
    if (negativeLifeEventsIndicator === Indicator.LessThan30) {
        return GetFormattedMessage('app.individualreport.negativelifeevents.low');
    } else if (negativeLifeEventsIndicator === Indicator.LessThan69) {
        return GetFormattedMessage('app.individualreport.negativelifeevents.moderate');
    } else {
        return GetFormattedMessage('app.individualreport.negativelifeevents.high');
    }
}

export const GetNeedsClosure = (needsClosureIndicator: number): string => {
    if (needsClosureIndicator === Indicator.LessThan30) {
        return GetFormattedMessage('app.individualreport.needsclosure.met_well');
    } else if (needsClosureIndicator === Indicator.LessThan69) {
        return GetFormattedMessage('app.individualreport.needsclosure.met_at_times');
    } else {
        return GetFormattedMessage('app.individualreport.needsclosure.not_met_well');
    }
}

export const GetReportItemColor = (scaleGroup: ScaleGroup): string => {

    if (scaleGroup === ScaleGroup.Consistency || scaleGroup === ScaleGroup.Congruency)
        return 'personalized-bar-orange';

    if (scaleGroup === ScaleGroup.NegativeLifeEvents) return 'personalized-bar-gray';

    if (scaleGroup === ScaleGroup.NeedsClosure || scaleGroup === ScaleGroup.NeedsFulfillment ||
        scaleGroup === ScaleGroup.IdentityDiffusion) return 'personalized-bar-blue';

    if (scaleGroup === ScaleGroup.BasicOpenness || scaleGroup === ScaleGroup.SelfCertitude)
        return 'personalized-bar-pink';

    if (scaleGroup === ScaleGroup.BasicDeterminism || scaleGroup === ScaleGroup.SocioemotionalConvergence)
        return 'personalized-bar-green';

    if (scaleGroup === ScaleGroup.PhysicalResonance || scaleGroup === ScaleGroup.EmotionalAttunement ||
        scaleGroup === ScaleGroup.SelfAwereness || scaleGroup === ScaleGroup.MeaningQuest)
        return 'personalized-bar-soft-blue';

    if (scaleGroup === ScaleGroup.ReligiousTraditionalism || scaleGroup === ScaleGroup.GenderTraditionalism ||
        scaleGroup === ScaleGroup.SocioculturalOpenness) return 'personalized-bar-soft-pink';

    if (scaleGroup === ScaleGroup.EcologicalResonance || scaleGroup === ScaleGroup.GlobalResonance)
        return 'personalized-bar-soft-green';

    return '';
}

export const GetIncomeGroup = (group: number): string => {
    if (group === 1) return GetFormattedMessage('app.demographic.income_level.response_3');
    if (group === 2) return GetFormattedMessage('app.demographic.income_level.response_2');
    return GetFormattedMessage('app.demographic.income_level.response_1');
}

export const GetDuration = (t0: string, t1: string) => {
    const endDate = new Date(t1);
    const startDate = new Date(t0);

    //@ts-ignore
    let d = (endDate - startDate);
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
    let seconds = Math.floor(d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
    //@ts-ignore
    /* eslint-disable @typescript-eslint/no-unused-vars */
    let milliseconds = Math.floor(d - weekdays * 7 * 24 * 60 * 60 * 1000 - days * 24 * 60 * 60 * 1000 - hours * 60 * 60 * 1000 - minutes * 60 * 1000 - seconds * 1000);
    let t: any = {};
    //@ts-ignore
    /* eslint no-eval: 0 */
    ['weekdays', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'].forEach(q => { if (eval(q) > 0) { t[q] = eval(q); } });

    console.log('GetDuration', t);

    return t;
}

export const getScaleTitle = (scale: ScaleGroup) => {
    if (scale === ScaleGroup.NegativeLifeEvents) return 'app.report.scales.negative_life_events';
    if (scale === ScaleGroup.NeedsClosure) return 'app.report.scales.needs_closure';
    if (scale === ScaleGroup.NeedsFulfillment) return 'app.report.scales.needs_fulfillment';
    if (scale === ScaleGroup.IdentityDiffusion) return 'app.report.scales.identity_diffusion';
    if (scale === ScaleGroup.BasicOpenness) return 'app.report.scales.basic_openness';
    if (scale === ScaleGroup.SelfCertitude) return 'app.report.scales.self_certitude';
    if (scale === ScaleGroup.BasicDeterminism) return 'app.report.scales.basic_determinism';
    if (scale === ScaleGroup.SocioemotionalConvergence) return 'app.report.scales.socioemotional_convergence';
    if (scale === ScaleGroup.PhysicalResonance) return 'app.report.scales.physical_resonance';
    if (scale === ScaleGroup.EmotionalAttunement) return 'app.report.scales.emotional_attunement';
    if (scale === ScaleGroup.SelfAwereness) return 'app.report.scales.self_awareness';
    if (scale === ScaleGroup.MeaningQuest) return 'app.report.scales.meaning_quest';
    if (scale === ScaleGroup.ReligiousTraditionalism) return 'app.report.scales.religious_traditionalism';
    if (scale === ScaleGroup.GenderTraditionalism) return 'app.report.scales.gender_traditionalism';
    if (scale === ScaleGroup.SocioculturalOpenness) return 'app.report.scales.sociocultural_openness';
    if (scale === ScaleGroup.EcologicalResonance) return 'app.report.scales.ecological_resonance';
    if (scale === ScaleGroup.GlobalResonance) return 'app.report.scales.global_resonance';
    if (scale === ScaleGroup.Consistency) return 'app.report.scales.consistency';
    if (scale === ScaleGroup.Congruency) return 'app.report.scales.congruency';
    return '';
}
