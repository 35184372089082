import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';
import { successfulCode, unauthorizedCode } from '../config/constants';
import { downloadLongitudinalReports, getLongitudinalReport, GetLongitudinalReportResponse, ReportLanguage } from '../services/reportService';
import { LongitudinalReportItem } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['loadLongitudinalReport', 'downloadLongitudinalReport']);
export type LongitudinalReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const longitudinalReportConstants: LongitudinalReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReport', 'setSuccessfullDownload', 'setDataDownloadError'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'report', 'loadingDataDownload']);
export type LongitudinalReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const longitudinalReportPropsConstants: LongitudinalReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: LongitudinalReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        fetchLongitudinalReport: () => void;
        fetchDownloadLongitudinalReport: () => void;
    },
};

// Define payloads type.
type ErrorPayload = { error: string; };
type LongitudinalReportPayload = { reportId: number };

type ReportPayload = { data: LongitudinalReportItem };
type LongitudinalReportRequest = { payload: LongitudinalReportPayload };
type LongitudinalReportRaceResponse = {
    longitudinalReportResponse?: GetLongitudinalReportResponse;
}

type DownloadLongitudinalReportRequestPayload = { language: ReportLanguage, reportId: number };
type DownloadLongitudinalReportRequestPayloadRequest = { payload: DownloadLongitudinalReportRequestPayload };

// persist app reducer
export const longitudinalReportPath = 'longitudinalReport';
export const longitudinalReportReducer = keaReducer(longitudinalReportPath);

export const longitudinalReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [longitudinalReportPath],

    actions: () => ({
        [longitudinalReportConstants.loadLongitudinalReport]:
            (reportId: number): LongitudinalReportPayload => ({ reportId }),
        [privateActionsConstants.setReport]:
            (data: LongitudinalReportItem): ReportPayload => ({ data }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [longitudinalReportConstants.downloadLongitudinalReport]:
            (language: ReportLanguage, reportId: number): DownloadLongitudinalReportRequestPayload => ({ language, reportId }),
        [privateActionsConstants.setSuccessfullDownload]: () => true,
        [privateActionsConstants.setDataDownloadError]:
            (error: string): ErrorPayload => ({ error }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [longitudinalReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.loadLongitudinalReport]: () => true,
            [actions.setError]: () => false,
            [actions.setReport]: () => false,
            [logout]: () => false
        }],
        [longitudinalReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.loadLongitudinalReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [longitudinalReportPropsConstants.report]: [null, PropTypes.any, {
            [actions.setReport]: (_: LongitudinalReportItem, payload: ReportPayload) => payload.data,
            [actions.loadLongitudinalReport]: () => null,
            [logout]: () => null
        }],
        [longitudinalReportPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.downloadLongitudinalReport]: () => true,
            [actions.setSuccessfullDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.loadLongitudinalReport]: workers.fetchLongitudinalReport,
        [actions.downloadLongitudinalReport]: workers.fetchDownloadLongitudinalReport,
    }),

    workers: {
        * fetchLongitudinalReport(action: LongitudinalReportRequest): any {
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { reportId } = action.payload;
            //@ts-ignore
            const { setError, setReport } = this.actionCreators;
            const { longitudinalReportResponse }: LongitudinalReportRaceResponse = yield race({
                longitudinalReportResponse: call(getLongitudinalReport, token, reportId),
                timeout: delay(requestTimeout)
            });

            if (longitudinalReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (longitudinalReportResponse?.status === successfulCode) {
                yield put(setReport(longitudinalReportResponse?.body))
            } else if (!!longitudinalReportResponse?.keyErrorMessage) {
                yield put(setError(longitudinalReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
        * fetchDownloadLongitudinalReport(action: DownloadLongitudinalReportRequestPayloadRequest): any {
            //@ts-ignore
            const { setDataDownloadError, setSuccessfullDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { language, reportId } = action.payload;
            const downloadWithinGroupReportResponse = yield call(
                downloadLongitudinalReports,
                token,
                { reportId, language }
            );

            if (downloadWithinGroupReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (downloadWithinGroupReportResponse?.status === successfulCode) {
                yield put(setSuccessfullDownload());
            } else if (!!downloadWithinGroupReportResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(downloadWithinGroupReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        }
    }
});
