import { serviceUrl } from '../config/constants';
import {
    BaseResponse, fetchGetMethod, fetchPostMethod, getResponseObject, getResponseObjectWithoutBody
} from '../rpc/api';

const getLastSubProgramUserssErrorMessageKey = 'app.error.users_service.get_last_subprogram_users';
const getSubProgramErrorMessageKey = 'app.error.users_service.get_subprogram';
const getUserSubProgramErrorMessageKey = 'app.error.users_service.get_user_subprogram';
const saveProgramErrorMessageKey = 'app.error.users_service.save_program';

export type User = {
    id: number;
    name: string;
    email: string;
};

export type LastSubProgramUsersBody = {
    users: User[];
}
export class GetLastSubProgramUsersResponse extends BaseResponse<LastSubProgramUsersBody> { }

export function getLastSubProgramUsers(token: string, model: GetUserFromPreviousGroupModel): Promise<GetLastSubProgramUsersResponse> {
    const response = fetchPostMethod<GetUserFromPreviousGroupModel>(
        `${serviceUrl}/api/program/GetUsersFromPreviousSubProgram`, token, model);
    return getResponseObject<LastSubProgramUsersBody>(response, getLastSubProgramUserssErrorMessageKey);
}

export type GetUserFromPreviousGroupModel = {
    programId?: number;
    subProgramId?: number;
    programName: string;
    organizationId: number;
}

export type SendProgram = {
    organizationId: number;
    programId?: number;
    programName?: string;
    subProgramId?: number;
    subProgramName: string;
    year: string;
    dueDate: string;
    userIds: number[];
    alertUsersByEmail: boolean;
    emailMessage: string;
};

export type SaveProgramBody = {};
export class SaveProgramResponse extends BaseResponse<SaveProgramBody> { }

export function saveProgram(token: string, program: SendProgram): Promise<SaveProgramResponse> {

    const response = fetchPostMethod<SendProgram>(
        `${serviceUrl}/api/program/SaveProgram`, token, program);
    return getResponseObjectWithoutBody(response, saveProgramErrorMessageKey);
}

export enum CustomAgreementStatus {
    NotRequested = 0,
    Pending = 1,
    Accepted = 2,
    Rejected = 3,
}

export type UserFormDetailsSubProgram = {
    userId: number;
    name: string;
    login: string;
    isUserActive: boolean;
    completedPercentage: number;
    formStatus: number;
    formId: number;
    isValidReport: boolean;
    validationFormStatus: number;
    customAgreement: string;
    customAgreementStatus?: CustomAgreementStatus;
};

export type SubProgramDetailsModel = {
    id: number;
    description: string;
    year: number;
    isActive: boolean;
    createdOnUtc: string;
    dueDateOnUtc: string;
    emailMessage: string;
    completedPercentage: number;
    usersAndFormsDetails: UserFormDetailsSubProgram[];
};

export type SubProgramModel = {
    id: number;
    description: string;
};

type GetSubProgramBody = SubProgramDetailsModel;
export class GetSubProgramResponse extends BaseResponse<GetSubProgramBody> { }
export function getSubProgram(token: string, subProgramId: number): Promise<GetSubProgramResponse> {

    const response = fetchGetMethod(
        `${serviceUrl}/api/program/GetSubProgram?subProgramId=${subProgramId}`, token);
    return getResponseObject<GetSubProgramBody>(response, saveProgramErrorMessageKey);
}

type CustomAgreementBody = {
    formId: number;
    customAgreementText: string;
}

export class SendCustomAgreementResponse extends BaseResponse<{}> { }
export function sendCustomAgreement(
    token: string, formId: number, customAgreementText: string
): Promise<SendCustomAgreementResponse> {
    const body: CustomAgreementBody = {
        formId: formId,
        customAgreementText: customAgreementText,
    };
    const response = fetchPostMethod<CustomAgreementBody>(
        `${serviceUrl}/api/program/CustomAgreement`, token, body);
    return getResponseObjectWithoutBody(response, saveProgramErrorMessageKey);
}

export type RenameProgramBody = {}
export class RenameProgramResponse extends BaseResponse<RenameProgramBody> { }

export type RenameProgramPayloadRequest = {
    programId?: number;
    name?: string;
};

export type RenameProgramResponseBody = {
    programId: number;
    name: string;
};

export function renameProgram(token: string, request: RenameProgramPayloadRequest): Promise<RenameProgramResponse> {
    const response = fetchPostMethod(`${serviceUrl}/api/program/RenameProgram`, token, request);
    return getResponseObjectWithoutBody(response, saveProgramErrorMessageKey);
}

export type SubProgramPayload = {
    id: number;
    description: string;
};

export class SubProgramPayloadResponse extends BaseResponse<SubProgramPayload> { }

export function getSubProgramByProgram(token: string, programId: number): Promise<SubProgramPayloadResponse> {
    const response = fetchGetMethod(
        `${serviceUrl}/api/program/GetSubProgramByProgram?programId=${programId}`, token);
    return getResponseObject(response, getSubProgramErrorMessageKey);
}

export type UserSubProgramPayload = {
    id: number;
    name: string;
    login: string;
    isActive: boolean;
};

export class AddChatProgramResponse extends BaseResponse<any> { }

export type AddChatProgramPayload = {
    organizationId: number;
}

export class UserSubProgramPayloadResponse extends BaseResponse<UserSubProgramPayload> { }

export function getUsersFromSubProgram(token: string, subProgramId: number): Promise<UserSubProgramPayloadResponse> {
    const response = fetchGetMethod(
        `${serviceUrl}/api/program/GetUsersFromSubProgram?subProgramId=${subProgramId}`, token);
    return getResponseObject(response, getUserSubProgramErrorMessageKey);
}

export function remindToTakeTheBEVI(token: string, subProgramId: number): Promise<SubProgramPayloadResponse> {
    const response = fetchGetMethod(
        `${serviceUrl}/api/form/RemindToTakeTheBEVI?subProgramId=${subProgramId}`, token);
    return getResponseObject(response, getSubProgramErrorMessageKey);
}

export function addChatProgram(token: string, request: number): Promise<AddChatProgramResponse> {
    const response = fetchPostMethod(`${serviceUrl}/api/program/ChatAllProgram/`, token, request);
    return getResponseObjectWithoutBody(response, getSubProgramErrorMessageKey);
}