import '../../style/scale-questions.css';

import React from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';

import { Loading } from '../../components/Loading';
import { QuestionPageHeader } from '../../components/QuestionPageHeader';
import { colors } from '../../config/colors';
import { appMessagesIds } from '../../locales/types';
import { ScaleQuestion } from '../../services/types';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { Question } from './components/Question';

type Props = {
    questions: ScaleQuestion[];
    onAnswer: (id: number, answerValue: number) => void;
    onNextButtonClicked: () => void;
    loading: boolean;
    error?: string;
}

export const ScaleQuestions = (
    { questions, loading, onNextButtonClicked, onAnswer }: Props
): JSX.Element => {

    // TODO(rodrigo.santos): Separate the loading into loading and sending.
    if (loading) {
        return <Loading />
    }

    return (
        <React.Fragment>
            <QuestionPageHeader />
            <div style={styles.divBackground} className='background'>
                <div style={styles.divCard} className='card'>
                    <p />
                    <Form>
                        { !isMobile &&
                            <Row>
                                <Form.Label column={true} className='question-header-row' />
                                <Form.Label column={true} xs='2' md='1' className='question-header-row'>
                                    { GetFormattedMessage('app.question.strongly_agree') }
                                </Form.Label>
                                <Form.Label column={true} xs='2' md='1' className='question-header-row'>
                                    { GetFormattedMessage('app.question.agree') }
                                </Form.Label>
                                <Form.Label column={true} xs='2' md='1' className='question-header-row'>
                                    { GetFormattedMessage('app.question.disagree') }
                                </Form.Label>
                                <Form.Label column={true} xs='2' md='1' className='question-header-row'>
                                    { GetFormattedMessage('app.question.strongly_disagree') }
                                </Form.Label>
                            </Row>
                        }
                        { questions.map((question: ScaleQuestion, index: number) => {
                            const rowStyle = {
                                ...styles.questionRow,
                                ...(index % 2 === 0 ? styles.questionRowEven : styles.questionRowOdd)
                            };
                            return (
                                <Question
                                    key={question.localeKey}
                                    question={question}
                                    rowStyle={rowStyle}
                                    onAnswer={(answerValue: number) => onAnswer(question.id, answerValue)}
                                />
                            );
                        })}
                    </Form>
                    <br />
                    <div className='button'>
                        <Button
                            disabled={loading}
                            style={styles.btnNext}
                            variant='primary'
                            onClick={onNextButtonClicked}
                        >
                            { loading?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : 
                                <FormattedMessage id={appMessagesIds['app.btn_next']} />
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const styles = {
    divBackground: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        backgroundColor: 'transparent',
        border: 0,
    } as React.CSSProperties,
    btnNext: {
        width: '150px',
        float: 'right',
        backgroundColor: colors.darkSlateBlue,
    } as React.CSSProperties,
    questionRow: {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '75px',
    } as React.CSSProperties,
    questionRowOdd: {
        backgroundColor: 'transparent',
    } as React.CSSProperties,
    questionRowEven: {
        backgroundColor: colors.white,
        borderRadius: '16px',
    } as React.CSSProperties,
};
