import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormCheck, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import { FormDropdown, SelectItem } from '../../../../components/FormDropdown';
import { H2 } from '../../../../components/styled/h2';
import { colors } from '../../../../config/colors';
import { PageMessages } from '../../../../locales/types';
import { TokenInfo } from '../../../../services/loginService';
import {
    GetUserBody, UserOrganization, UserProgram, UserType
} from '../../../../services/usersService';
import { GetFormattedMessage, getLanguages, getUserProfile } from '../../../../utils/htmlHelper';
import styled from 'styled-components';
import { Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { valueColor } from '../../CreateUser';
import { intersection } from 'lodash';

type Props = {
    titleKey?: keyof (PageMessages)
    user?: GetUserBody;
    userInfo: TokenInfo;
    organizations: SelectItem[];
    programs: Map<number /*organizationId*/, SelectItem[]>;
    onClickSubmit: (
        organizationIds: number[], programIds: number[], name: string, login: string,
        language: number | undefined, userType: number | undefined, anonymous: number,
        sendEmail: boolean, permissions?: number[]
    ) => void;
    loading: boolean;
    onBackButtonClicked: () => void;
}

const SelectMult = styled(Select)`
    .ant-select-selection-overflow {
        gap: 6px;
    }

    .ant-tag {
        display: flex;
        align-items: center;
    }
`;

const PERMISSIONS = [32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144];

export const SingleUser = ({ titleKey, user, userInfo, programs, organizations, loading, onClickSubmit, onBackButtonClicked }: Props): JSX.Element => {
    const [organizationId, setOrganizationId] = useState<number[]>(
        user?.organizations?.map((org: UserOrganization) => org.organizationId) ?? []
    );
    const [sendEmail, setSendEmail] = useState<boolean>(true);
    const [programId, setProgramId] = useState<number[]>(
        user?.programs?.map((prog: UserProgram) => prog.programId) ?? []
    );
    const [name, setName] = useState<string>(user?.name ?? '');
    const [login, setLogin] = useState<string>(user?.login ?? '');
    const [language, setLanguage] = useState<number | undefined>(user?.language);
    const [userType, setUserType] = useState<number | undefined>(user?.userType);
    const [permissions, setPermissions] = useState<number[]>();
    const [anonymous] = useState<number>(0);

    useEffect(() => {
        if (user != null) {
            setPermissions(intersection(PERMISSIONS, user?.permissions));
        } else {
            setPermissions(PERMISSIONS);
        }
    }, [user])

    let availableProgramIds: SelectItem[] = [];
    organizationId.forEach((orgId: number) => {
        const options = programs.get(orgId);
        if (options) availableProgramIds = [...availableProgramIds, ...options];
    });

    const actionRemoveTooltip = GetFormattedMessage('app.create_program.tooltip.action_remove');

    const organizationColumnAction: ColumnProps<SelectItem> = ({
        title: GetFormattedMessage('app.create.user.table.column_actions'),
        key: 'action',
        align: 'center',
        render: (_: string, record: SelectItem) => (
            <OverlayTrigger
                key={`trigger-${record.value}-${record.label}`}
                placement={'right'}
                trigger={["hover", "hover"]}
                overlay={
                    <Tooltip id={`${record.value}-${record.label}`}>
                        {`${actionRemoveTooltip} ${record.label}`}
                    </Tooltip>
                }
            >
                <Button
                    key={`btn-${record.value}-${record.label}`}
                    // @ts-ignore
                    style={styles.associateUserButtonAction}
                    className=' bg-transparent shadow-none'
                    onClick={() => {
                        setOrganizationId(organizationId.filter((orgId: number) => orgId !== record.value));
                    }}
                >
                    <FaTimes color='red' />
                </Button>
            </OverlayTrigger>
        ),
    });
    const programColumnAction: ColumnProps<SelectItem> = ({
        title: GetFormattedMessage('app.create_program.table.column_actions'),
        key: 'action',
        align: 'center',
        render: (_: string, record: SelectItem) => (
            <OverlayTrigger
                key={`trigger-${record.value}-${record.label}`}
                placement={'right'}
                trigger={["hover", "hover"]}
                overlay={
                    <Tooltip id={`${record.value}-${record.label}`}>
                        {`${actionRemoveTooltip} ${record.label}`}
                    </Tooltip>
                }
            >
                <Button
                    key={`btn-${record.value}-${record.label}`}
                    // @ts-ignore
                    style={styles.associateUserButtonAction}
                    className=' bg-transparent shadow-none'
                    onClick={() => {
                        setProgramId(programId.filter((progId: number) => progId !== record.value));
                    }}
                >
                    <FaTimes color='red' />
                </Button>
            </OverlayTrigger>
        ),
    });
    const tableColumns: ColumnProps<SelectItem>[] = [
        {
            title: GetFormattedMessage('app.create_program.table.column_name'),
            dataIndex: 'label',
            key: 'label'
        },
    ]

    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={valueColor[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    const userProfileOptions = getUserProfile().filter((item: SelectItem) => item.value && item.value < userInfo.user_type)
    const languageOptions = getLanguages();
    return (
        <div style={styles.container}>
            <div style={styles.textCenter}>
                <H2 textAlign='center'>
                    {GetFormattedMessage(titleKey !== undefined ?
                        titleKey
                        :
                        'app.create.user.create.single.user.title'
                    )}
                </H2>
            </div>
            <hr />
            <br />
            <Row>
                <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.users.name')}</b></p></div>
                        </Form.Label>
                        <Form.Control
                            value={name}
                            as='input'
                            onChange={(e) => { setName(e.currentTarget.value); }}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.users.login')}</b></p></div>
                        </Form.Label>
                        <Form.Control
                            value={login}
                            as='input'
                            onChange={(e) => { setLogin(e.currentTarget.value); }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.create.user.user.type')}</b></p></div>
                        </Form.Label>
                        <FormDropdown
                            value={
                                userProfileOptions.filter((item: SelectItem) => item.value && item.value === userType)
                            }
                            defaultOptionId={'app.dropdown_select'}
                            onValueSelect={(value: number | undefined, _: string) => {
                                setUserType(value);
                                if (programId.length > 0) setProgramId([]);
                            }}
                            optionItens={userProfileOptions}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div>
                                <p><b>{GetFormattedMessage('app.create.user.language')}</b>
                                    <small style={styles.smallText}>{GetFormattedMessage('app.user.language.message')}</small></p>
                            </div>
                        </Form.Label>
                        <FormDropdown
                            value={languageOptions.filter((item: SelectItem) => item.value === language)}
                            defaultOptionId={'app.dropdown_select'}
                            onValueSelect={(value: number | undefined, _: string) => {
                                setLanguage(value);
                            }}
                            optionItens={languageOptions}
                        />
                    </Form.Group>
                </Col>
                {userType === UserType.programAdmin &&
                    <Col md={{ span: 12 }}>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.permission.permissions')}</b></p></div>
                        </Form.Label>
                        <Form.Group className='mb-3'>
                            <SelectMult
                                allowClear
                                mode="multiple"
                                size='middle'
                                placeholder="Please select"
                                value={permissions}
                                style={{ width: '100%' }}
                                tagRender={tagRender}
                                optionFilterProp='label'
                                onChange={(value: any) => setPermissions(value)}
                                options={[{
                                    value: 32,
                                    label: GetFormattedMessage('app.create.user.capability_visualize_user'),
                                }, {
                                    value: 64,
                                    label: GetFormattedMessage('app.create.user.capability_add_user'),
                                }, {
                                    value: 128,
                                    label: GetFormattedMessage('app.create.user.capability_reset_password_user'),
                                }, {
                                    value: 256,
                                    label: GetFormattedMessage('app.create.user.capability_manage_user'),
                                }, {
                                    value: 512,
                                    label: GetFormattedMessage('app.create.user.capability_visualize_user_programs'),
                                }, {
                                    value: 1024,
                                    label: GetFormattedMessage('app.create.user.capability_visualize_program_and_experience'),
                                }, {
                                    value: 2048,
                                    label: GetFormattedMessage('app.create.user.capability_add_program_and_experience'),
                                }, {
                                    value: 4096,
                                    label: GetFormattedMessage('app.create.user.capability_rename_program_and_experience'),
                                }, {
                                    value: 8192,
                                    label: GetFormattedMessage('app.create.user.capability_add_subprogram'),
                                }, {
                                    value: 16384,
                                    label: GetFormattedMessage('app.create.user.capability_edit_subprogram'),
                                }, {
                                    value: 32768,
                                    label: GetFormattedMessage('app.create.user.capability_manage_subprogram'),
                                }, {
                                    value: 65536,
                                    label: GetFormattedMessage('app.create.user.capability_show_qr_code_invite_new_users_subprogram'),
                                }, {
                                    value: 131072,
                                    label: GetFormattedMessage('app.create.user.capability_visualize_report'),
                                }, {
                                    value: 262144,
                                    label: GetFormattedMessage('app.create.user.capability_add_report'),
                                }]}
                            />
                        </Form.Group>
                    </Col>
                }
            </Row>
            <Row>
                <Col md={{ span: 6 }}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <div><p><b>{GetFormattedMessage('app.user.organization')}</b></p></div>
                        </Form.Label>
                        <FormDropdown
                            value={[]}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={false}
                            defaultOptionId={'app.user.select_organization'}
                            onMultiValueSelect={(value: number[]) => {
                                setOrganizationId((organizationId: number[]) => [...organizationId, ...value]);
                            }}
                            optionItens={organizations.filter((item: SelectItem) => !organizationId.find((id: number) => id === item.value))}
                        />
                        {organizationId.length > 0 &&
                            <React.Fragment>
                                <br />
                                <Table
                                    columns={[...tableColumns, organizationColumnAction]}
                                    dataSource={organizations.filter((item: SelectItem) => organizationId.find((id: number) => id === item.value))}
                                    pagination={false}
                                    rowKey={(record: SelectItem) => `row-${record.value}-${record.label}`}
                                />
                            </React.Fragment>
                        }
                    </Form.Group>
                </Col>
                <Col md={{ span: 6 }}>
                    <Form.Group className='mb-3' controlId='sendEmail'>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <div><p><b>{GetFormattedMessage('app.create.user.send_welcome_email')}</b></p></div>
                            </Form.Label>
                            <FormCheck
                                type='switch'
                                checked={sendEmail}
                                label={''}
                                onChange={(event) => {
                                    setSendEmail(event.target.checked);
                                }}
                            />
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
            {userType === UserType.programAdmin && organizationId.length > 0 &&
                <Form.Group className='mb-3' controlId='programGroup'>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <div><p><b>{GetFormattedMessage('app.user.program')}</b></p></div>
                            </Form.Label>
                            <FormDropdown
                                value={[]}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={false}
                                defaultOptionId={'app.user.select_program'}
                                onMultiValueSelect={(value: number[]) => {
                                    setProgramId((programId: number[]) => [...programId, ...value]);
                                }}
                                optionItens={availableProgramIds.filter((item: SelectItem) => !programId.find((id: number) => id === item.value))}
                            />
                            {programId.length > 0 &&
                                <React.Fragment>
                                    <br />
                                    <Table
                                        columns={[...tableColumns, programColumnAction]}
                                        dataSource={availableProgramIds.filter((item: SelectItem) => programId.find((id: number) => id === item.value))}
                                        pagination={false}
                                        rowKey={(record: SelectItem) => `row-${record.value}-${record.label}`}
                                    />
                                </React.Fragment>
                            }
                        </Form.Group>
                    </Col>
                </Form.Group>
            }
            <br />
            <Row>
                <Col md={{ span: 3 }}>
                    <Form.Group className='mb-3'>
                        <Button
                            variant='primary'
                            // @ts-ignore
                            style={styles.button}
                            onClick={onBackButtonClicked}
                        >
                            {GetFormattedMessage('app.btn_back')}
                        </Button>
                    </Form.Group>
                </Col>
                <Col md={{ span: 3, offset: 6 }}>
                    <Form.Group className='mb-3'>
                        <Button
                            variant='primary'
                            // @ts-ignore
                            style={styles.button}
                            disabled={
                                organizationId.length <= 0 || name === '' || login === '' ||
                                language === undefined || userType === undefined || anonymous === undefined ||
                                loading
                            }
                            onClick={() => onClickSubmit(organizationId, programId, name, login, language, userType, anonymous, sendEmail, permissions)}
                        >
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                :
                                GetFormattedMessage('app.save')
                            }
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

const styles = {
    button: {
        width: '100%',
        marginTop: 10
    } as React.CSSProperties,
    smallText: {
        color: colors.red,
        marginLeft: 5,
    } as React.CSSProperties,
    container: {
        marginTop: 40
    } as React.CSSProperties,
    textCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
    associateUserButtonAction: {
        borderWidth: 0,
    } as React.CSSProperties,
}
