import { NoInfoMessages } from '../types';

export const noInfoJA: NoInfoMessages = {
    'app.no.info.title.not_found': 'PAGE NOT FOUND',
    'app.no.info.title.no_data': 'NO DATA',
    'app.no.info.subtitle.not_found': 'お探しのページは見つかりませんでした。',
    'app.no.info.subtitle.no_data': '検索条件に一致する項目はありません。',
    'app.no.info.body.not_found': 'お探しのページは存在しないか、移動されています。',
    'app.no.info.body.no_data': '検索条件をチェックして下さい。それでもデータが検出されない場合は、BEVI管理者に問い合わせてください。',
    'app.no.info.title.not_authorized': 'PAGE NOT AUTHORIZED',
    'app.no.info.subtitle.not_authorized': 'このドキュメントを表示するための権限がありません。',
    'app.no.info.body.not_authorized': 'BEVI管理者に問い合わせてください。',
    'app.no.info.title.maintenance': 'メンテナンス中',
    'app.no.info.subtitle.maintenance': 'メンテナンスのためサービスを一時停止しています。',
    'app.no.info.body.maintenance': '後で接続し直すか、今しばらくお待ちください。',
}
