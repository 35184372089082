import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'Preguntas sin responder',
    'app.questions.confirmation':
        '¿Estás seguro de que quieres continuar sin responder a todas las preguntas? No podrás regresar a estas preguntas más tarde.',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': 'Muy de acuerdo',
    'app.question.agree': 'De acuerdo',
    'app.question.disagree': 'En desacuerdo',
    'app.question.strongly_disagree': 'Muy en desacuerdo',
    'app.question.q1': 'A veces no tengo claro qué camino seguir.',
    'app.question.q2': 'La gente se burlaba de mí cuando era pequeño.',
    'app.question.q3': 'A veces me comparo con los demás.',
    'app.question.q4': 'Es sano que los jóvenes experimenten con el sexo.',
    'app.question.q5': 'He tenido muchos conflictos con uno o más miembros de mi familia.',
    'app.question.q6': 'Me asustaría encontrarme solo/a en el mundo.',
    'app.question.q7': 'Ojalá mi madre y/o mi padre hubieran hecho las cosas de otra forma.',
    'app.question.q8': 'Las mujeres casi siempre están más sensibles cuando están con la regla.',
    'app.question.q9': 'Deberíamos tratar de entender las culturas que son distintas a la nuestra.',
    'app.question.q10': 'Mi madre es/era mi mejor amiga.',
    'app.question.q11': 'Algún día entenderemos que hombres y mujeres son sencillamente distintos.',
    'app.question.q12': 'La mujer tiene que estar en casa.',
    'app.question.q13': 'A menudo me siento abrumado/a ante la belleza del mundo.',
    'app.question.q14': 'Hay personas que tienen mejor genética.',
    'app.question.q15': 'Los prejuicios abundan y debemos superarlos.',
    'app.question.q16': 'He sufrido una crisis de identidad dolorosa.',
    'app.question.q17': 'Lloro varias veces al año.',
    'app.question.q18': 'Siempre puedo/podía contar con mi madre o mi padre cuando me hacia falta consejo.',
    'app.question.q19': 'La parte inconsciente de nuestra mente puede influir en nuestros sentimientos y comportamiento.',
    'app.question.q20': 'Es importante estar al tanto de lo que pasa en el mundo.',
    'app.question.q21': 'Un hombre se ha de comportar como un hombre.',
    'app.question.q22': 'Dios me hizo como soy.',
    'app.question.q23': 'Al final ser feliz o infeliz es una decisión tuya.',
    'app.question.q24': 'Siento que tengo una conexión espiritual muy profunda con la Tierra.',
    'app.question.q25': 'Mi infancia fue maravillosa.',
    'app.question.q26': 'No siempre me siento bien con quien soy.',
    'app.question.q27': 'No se debe dudar la existencia de Dios.',
    'app.question.q28': 'No puedes culpar de tus problemas a nadie más que a ti mismo.',
    'app.question.q29': 'Las mujeres son más dependientes emocionalmente que los hombres.',
    'app.question.q30': 'Me gusta tener conversaciones profundas durante horas y horas.',
    'app.question.q31': 'Se debería subir el sueldo mínimo.',
    'app.question.q32': 'De niño/a tuve una relación sana y positiva con mi padre.',
    'app.question.q33': 'El SIDA bien puede ser un castigo divino.',
    'app.question.q34': 'Sobran las personas que buscan ayudas gratis del estado.',
    'app.question.q35': 'Debería impartirse educación sexual en nuestras escuelas.',
    'app.question.q36': 'Me crié rodeado de rabia y hostilidad.',
    'app.question.q37': 'Quiero encontrar un mejor sentido de equilibrio en mi vida.',
    'app.question.q38': 'Me tomo mis sentimientos muy en serio.',
    'app.question.q39': 'Las mujeres pueden ser extremadamente competitivas.',
    'app.question.q40': 'No deberíamos negar nuestra naturaleza animal.',
    'app.question.q41': 'Hay cosas de mi pasado que me da vergüenza pensar.',
    'app.question.q42': 'Mis relaciones han sido muy conflictivas.',
    'app.question.q43': 'Soy más bien progresista que conservador en cuestiones sociales.',
    'app.question.q44': 'Pienso mucho en el sentido de la vida.',
    'app.question.q45': 'A veces me aburro.',
    'app.question.q46': 'Es normal que los jóvenes experimenten con el alcohol y las drogas recreativas.',
    'app.question.q47': 'A las mujeres les cuesta mucho decidirse.',
    'app.question.q48': 'Conocer otras culturas me ayuda a entender la mía propia.',
    'app.question.q49': 'Solo hay un camino al cielo.',
    'app.question.q50': 'La gente suele fracasar porque no ponen todo de su parte.',
    'app.question.q51': 'He tenido que marcar distancias con mis padres.',
    'app.question.q52': 'Los hombres son como son y hay que aceptarlo.',
    'app.question.q53': 'Los seres humanos han evolucionado a lo largo de millones de años.',
    'app.question.q54': 'Una o más personas de mi familia sufrieron abuso físico, sexual o emocional.',
    'app.question.q55': 'Tengo auténtica necesidad de cariño y afecto.',
    'app.question.q56': 'Debería destinarse más dinero a programas de educación infantil y primaria.',
    'app.question.q57': 'Soy una persona muy sensible.',
    'app.question.q58': 'Estoy a favor de la labor de los sindicatos.',
    'app.question.q59': 'Me preocupa el medioambiente.',
    'app.question.q60': 'Duele que te rechacen.',
    'app.question.q61': 'No tengo nada negativo que decir de mis padres.',
    'app.question.q62': 'Me he preguntado quien soy y a dónde voy.',
    'app.question.q63': 'Mis emociones a veces me superan.',
    'app.question.q64': 'Las mujeres son mucho más cotillas que los hombres.',
    'app.question.q65': 'Sin religión no puede haber paz.',
    'app.question.q66': 'No se puede negar que los hombres son más fuertes que las mujeres.',
    'app.question.q67': 'Como sociedad, estamos demasiado orientados al consumo.',
    'app.question.q68': 'Mi familia tuvo muchos problemas de dinero.',
    'app.question.q69': 'Me paso la vida intentando entenderme mejor.',
    'app.question.q70': 'Es natural que los fuertes sobreviven.',
    'app.question.q71': 'Los hombres usan más la lógica que las mujeres.',
    'app.question.q72': 'Lidiar con los sentimientos dolorosos del pasado es útil.',
    'app.question.q73': 'A veces los hombres se sienten débiles como las mujeres.',
    'app.question.q74': 'Los niños no deben ir de rosa.',
    'app.question.q75': 'Me gusta ver películas sobre relaciones sentimentales.',
    'app.question.q76': 'De niño/a tuve una relación sana y positiva con mi madre.',
    'app.question.q77': 'Me preocupa la salud de nuestro planeta.',
    'app.question.q78': 'Mi cuerpo es muy sensible a lo que siento.',
    'app.question.q79': 'Tengo que admitir que soy igual que mis padres.',
    'app.question.q80': 'Las mujeres son más sensibles que los hombres.',
    'app.question.q81': 'Tengo creencias religiosas muy arraigadas.',
    'app.question.q82': 'Soy como soy porque nací así.',
    'app.question.q83': 'Mis padres tuvieron una relación muy conflictiva.',
    'app.question.q84': 'Me gusta pensar en quién soy.',
    'app.question.q85': 'Las mujeres han hecho avances extraordinarios en las últimas décadas.',
    'app.question.q86': 'Mi padre es mi héroe.',
    'app.question.q87': 'Me ha costado mucho decidir qué hacer con mi vida.',
    'app.question.q88': 'A veces me tomo las cosas demasiado a pecho.',
    'app.question.q89': 'Deberíamos considerar a la tierra una madre.',
    'app.question.q90': 'Me he sentido solo/a en la vida.',
    'app.question.q91': 'Muchos profesores universitarios tienen opiniones demasiado progresistas.',
    'app.question.q92': 'La única esperanza de curación proviene de un poder superior.',
    'app.question.q93': 'A veces me siento necesitado/a y vulnerable.',
    'app.question.q94': 'Hay que hacer más para ayudar a los grupos minoritarios en nuestra sociedad.',
    'app.question.q95': 'A todo el mundo le vendría bien aprender más de un idioma.',
    'app.question.q96': 'Aunque así se espere, los hombres no están hechos para ser fieles en el matrimonio.',
    'app.question.q97': 'No entiendo cómo pueden pasar algunas cosas.',
    'app.question.q98': 'Mi padre me es/era emocionalmente distante.',
    'app.question.q99': 'Deberíamos elegir a una mujer para gobernar la nación.',
    'app.question.q100': 'Al final, uno elige la vida que tiene.',
    'app.question.q101': 'El poder judicial de nuestro país es demasiado progresista.',
    'app.question.q102': 'Quiero que me tomen en serio.',
    'app.question.q103': 'Hay que hacer más para ayudar a los más desfavorecidos.',
    'app.question.q104': 'No me molestan las muestras de emoción.',
    'app.question.q105': 'De niño/a, sufrí daños emocionales.',
    'app.question.q106': 'La pornografía degrada a la mujer.',
    'app.question.q107': 'Me enfado al ver lo injusto que es el mundo.',
    'app.question.q108': 'Los principales planes del gobierno hacen más mal que bien.',
    'app.question.q109': 'Han herido mis sentimientos en el pasado.',
    'app.question.q110': 'Me gusta leer libros de autoayuda.',
    'app.question.q111': 'Mi madre y/o mi padre tienen/han tenido problemas emocionales.',
    'app.question.q112': 'Me gusta aprender cosas de otras culturas.',
    'app.question.q113': 'Debemos preservar nuestras firmes tradiciones.',
    'app.question.q114': 'A las mujeres les falta ambición para triunfar.',
    'app.question.q115': 'A veces me enfado con los demás.',
    'app.question.q116': 'Nadie puede hacerte daño de verdad a menos que se lo permitas.',
    'app.question.q117': 'Necesito tener a alguien que escuche cómo me siento.',
    'app.question.q118': 'Puedes superar casi cualquier problema con solo poner más de tu parte.',
    'app.question.q119': 'Rezar no sirve para nada.',
    'app.question.q120': 'Suelo/solía ser motivo de conflicto o problemas entre mis padres.',
    'app.question.q121': 'La brecha entre ricos y pobres en este país es demasiado grande.',
    'app.question.q122': 'No entiendo todo lo que siento.',
    'app.question.q123': 'En realidad la gente no cambia.',
    'app.question.q124': 'Nunca me ha parecido que haya estado a la altura de lo que mi familia esperaba.',
    'app.question.q125': 'Los hombres son más competitivos por naturaleza que las mujeres.',
    'app.question.q126': 'Hay que hacer más por proteger los recursos naturales del planeta.',
    'app.question.q127': 'He tenido serios problemas de dinero durante mi vida.',
    'app.question.q128': 'No me gusta que me critiquen.',
    'app.question.q129': 'Mi madre o mi padre y yo estamos/estábamos unidos.',
    'app.question.q130': 'Tengo problemas que necesito trabajar.',
    'app.question.q131': 'Rezar tiene más poder curativo que la medicina tradicional.',
    'app.question.q132': 'De niño/a, fui víctima de maltrato/negligencia.',
    'app.question.q133': 'Hay que poner más empeño en la lucha contra las drogas.',
    'app.question.q134': 'Apoyo firmemente la igualdad de derechos para las mujeres.',
    'app.question.q135': 'Mi padre me entendió y me aceptó.',
    'app.question.q136': 'La educación es la clave de una sociedad más ilustrada.',
    'app.question.q137': 'El papel de un hombre es ser fuerte.',
    'app.question.q138': 'Hay que proteger la tierra sin importar a quién pertenezca.',
    'app.question.q139': 'Mis padres me exigían demasiado.',
    'app.question.q140': 'A todos nos beneficiaría que las mujeres tuvieran más poder económico y político.',
    'app.question.q141': 'A veces lloro cuando otras personas lloran.',
    'app.question.q142': 'Algunas mujeres van pidiendo que las acosen por cómo van vestidas.',
    'app.question.q143': 'Si no vas a decir nada agradable no digas nada.',
    'app.question.q144': 'No se puede cambiar el pasado, así que no tiene sentido vivir anclado en él.',
    'app.question.q145': 'Cuando la vida te tumbe, levántate y sigue adelante.',
    'app.question.q146': 'El rechazo a menudo te hace más fuerte.',
    'app.question.q147': 'Hay gente que analiza el mundo demasiado.',
    'app.question.q148': 'Siempre que me siento mal intento tener pensamientos positivos.',
    'app.question.q149': 'Siempre es mejor centrarse en las fortalezas que en las debilidades.',
    'app.question.q150': 'La mejor manera de sobrellevar la vida es con una actitud alegre.',
    'app.question.q151': 'Lo hecho, hecho está, así que perdona y olvida.',
    'app.question.q152': 'No se puede decir realmente que una opinión sea mejor que otras.',
    'app.question.q153': 'Creo que la gente debería estar agradecida por lo que tiene y dejar de quejarse.',
    'app.question.q154': 'Algunos números son menos agraciados que otros.',
    'app.question.q155': 'Decir palabrotas no tiene justificación.',
    'app.question.q156': 'Hay demasiada gente que no asume sus responsabilidades.',
    'app.question.q157': 'Los videntes pueden predecir el futuro.',
    'app.question.q158': 'Siempre es mejor intentar simplificar las cosas.',
    'app.question.q159': 'Es imposible poner de acuerdo en nada a dos expertos.',
    'app.question.q160': 'Si sigues las reglas te irá bien.',
    'app.question.q161': 'Estaría bien si cayera bien a todo el mundo o que al menos me respetaran.',
    'app.question.q162': 'Deberíamos ser más tolerantes con costumbres culturales distintas a las nuestras.',
    'app.question.q163': 'Soy un espíritu libre.',
    'app.question.q164': 'Creo en el poder de la astrología.',
    'app.question.q165': 'Me entristece que haya gente viviendo en la pobreza.',
    'app.question.q166': 'Para mí la pura lógica prevalece sobre casi cualquier otra cosa.',
    'app.question.q167': 'Me siento cómodo en compañía de grupos de gente muy distintos a mí.',
    'app.question.q168': 'Por el bien de sus hijos los padres no deberían separarse.',
    'app.question.q169': 'Es sano tener fantasías sexuales.',
    'app.question.q170': 'Las etiquetas diagnósticas no son tan útiles.',
    'app.question.q171': 'Siempre le busco el lado positivo a las cosas.',
    'app.question.q172': 'Nuestra especie ha recibido ayuda de seres de otros planetas.',
    'app.question.q173': 'La Tierra tiene miles de millones de años.',
    'app.question.q174': 'La debilidad puede ser una virtud.',
    'app.question.q175': 'A nadie le gustan los pesimistas.',
    'app.question.q176': 'Me molestan las muestras de emoción.',
    'app.question.q177': 'De niño/a, mis padres tenían muy buena relación.',
    'app.question.q178': 'Somos demasiado tolerantes con las costumbres culturales ajenas.',
    'app.question.q179': 'Ya se hace bastante para ayudar a los pobres.',
    'app.question.q180': 'La gente se preocupa demasiado por el medio ambiente.',
    'app.question.q181': 'Los prejuicios realmente ya no son un problema.',
    'app.question.q182': 'La gente cambia constantemente.',
    'app.question.q183': 'Hay muchos caminos al cielo.',
    'app.question.q184': 'Los hombres y las mujeres tienen más parecidos que diferencias.',
    'app.question.q185': 'En realidad uno no escoge la vida que tiene.',
};

export const scaleQuestionsPageES: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
