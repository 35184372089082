import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': '未回答的問題',
    'app.questions.confirmation': '有未回答的問題，是否要繼續？之後將不能回答那（些）問題。 ',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': '非常同意',
    'app.question.agree': '同意',
    'app.question.disagree': '不同意',
    'app.question.strongly_disagree': '非常不同意',
    'app.question.q1': '有時會對前進的道路感到迷茫。',
    'app.question.q2': '小時候經常被人取笑。',
    'app.question.q3': '有時會拿自己和別人比較。',
    'app.question.q4': '年輕人的性冒險是健康的。',
    'app.question.q5': '我經常和家人起衝突。',
    'app.question.q6': '我害怕一個人孤獨的活著。',
    'app.question.q7': '要是父母能以不同的方式對我就好了。',
    'app.question.q8': '可以說女性越接近生理期就越情緒化。',
    'app.question.q9': '我們應該理解與自己不同的文化。',
    'app.question.q10': '母親是或曾是我最好的朋友。',
    'app.question.q11': '我們最終會承認：男人與女人，終究不一樣。',
    'app.question.q12': '女人的歸屬是家庭。',
    'app.question.q13': '我常被這個世界的美麗所征服。',
    'app.question.q14': '有些人會有更好的基因。',
    'app.question.q15': '偏見無處不在，我們必須克服它。',
    'app.question.q16': '我曾經歷過痛苦的身份危機。',
    'app.question.q17': '一年我會哭幾次。',
    'app.question.q18': '我總是從我的父母那裡得到或可以得到很好的建議。',
    'app.question.q19': '人精神中的無意識部分會影響其情緒與行為。',
    'app.question.q20': '充分瞭解時事，這至關重要。',
    'app.question.q21': '男人就要有男人樣。',
    'app.question.q22': '上帝，神靈或菩薩創造了現在的我。',
    'app.question.q23': '最終決定幸福還是不幸的是你自己。',
    'app.question.q24': '我感到在精神上與地球有著密切的聯繫。',
    'app.question.q25': '我度過了美好的幼年時代。',
    'app.question.q26': '有時會對自己不滿。',
    'app.question.q27': '懷疑上帝，神靈或菩薩的存在是錯誤的。',
    'app.question.q28': '自己的問題,除了自己不能責備他人。',
    'app.question.q29': '女人比男人更感性。',
    'app.question.q30': '我喜歡深聊好幾小時。',
    'app.question.q31': '我們應該提高最低工資。',
    'app.question.q32': '小時候，和父親建立了良好和健康的關係。',
    'app.question.q33': '愛滋病可能是上天的懲罰。',
    'app.question.q34': '太多人試圖獲得免費分發。',
    'app.question.q35': '學校應該進行性教育。',
    'app.question.q36': '小時候，我目睹過太多的憤怒，爭執或攻擊。',
    'app.question.q37': '我想在人生中尋找更好的平衡感。',
    'app.question.q38': '我非常認真對待自己的感受。',
    'app.question.q39': '女人可能會變成難對付的競爭對手。',
    'app.question.q40': '我們不該否認自己的動物本性。',
    'app.question.q41': '回首過去，我有過一些令人尷尬的往事。',
    'app.question.q42': '因人際關係，我有過很多的衝突。',
    'app.question.q43': '對待社會問題，比起守舊或傳統，我是一個更崇尚變革或開明的人。',
    'app.question.q44': '我會經常思考人生的意義。',
    'app.question.q45': '我有時會覺得無聊。',
    'app.question.q46': '年輕人嘗試酒精和大麻是正常的。',
    'app.question.q47': '女人非常不擅長做決斷。',
    'app.question.q48': '瞭解其他文化，有利於更好的理解自己的文化。',
    'app.question.q49': '通往天國或凈土的路只有一條。',
    'app.question.q50': '人失敗的大多原因在於其努力不夠。',
    'app.question.q51': '我需要與父母保持距離。',
    'app.question.q52': '無論喜歡與否，男人永遠是孩子。',
    'app.question.q53': '人類已經進化了數百萬年。',
    'app.question.q54': '家人中，有人受到身體、性或情感上的虐待。',
    'app.question.q55': '我特別需要溫暖和愛。',
    'app.question.q56': '我們應該在孩子的早期教育上投入更多的錢。',
    'app.question.q57': '我是非常感性的人。',
    'app.question.q58': '我支持工會活動。',
    'app.question.q59': '我擔憂環境。',
    'app.question.q60': '被拒絕、排斥是痛苦的。',
    'app.question.q61': '我對父母沒有不滿。',
    'app.question.q62': '我有時會不確定自己是誰，將要去哪。',
    'app.question.q63': '我有時會優先考慮自己的情緒。 ',
    'app.question.q64': '女人比男人更愛說三道四。',
    'app.question.q65': '沒有宗教就沒有和平。',
    'app.question.q66': '不能否認男人比女人強。',
    'app.question.q67': '社會過於以消費者為中心。',
    'app.question.q68': '我的家人在金錢方面存在很多問題。',
    'app.question.q69': '為了更好地理解自己，我總在努力。',
    'app.question.q70': '強者必勝。',
    'app.question.q71': '男人比女人更理智。',
    'app.question.q72': '面對過去的痛苦是有用的。',
    'app.question.q73': '有時，男人也會和女人一樣表現出軟弱的一面。',
    'app.question.q74': '讓男孩穿粉色衣服是錯的。',
    'app.question.q75': '我喜歡看描述人際關係的電影。',
    'app.question.q76': '小時候，和母親建立了良好關係。',
    'app.question.q77': '我擔心地球的環境狀態。',
    'app.question.q78': '我的身體對自己的情緒反應敏感。',
    'app.question.q79': '我的一舉一動和父母一模一樣。',
    'app.question.q80': '女人比男人更感情用事。',
    'app.question.q81': '我有強烈的宗教信仰。',
    'app.question.q82': '現在的我之所以會這樣，是因為我生來就這樣。',
    'app.question.q83': '我的父母常為夫妻關係爭吵。',
    'app.question.q84': '我喜歡思考自己是誰。',
    'app.question.q85': '女性的地位這幾十年一直在上升。',
    'app.question.q86': '父親是我的英雄。',
    'app.question.q87': '我在尋找自己的生活方式上吃了很多苦。',
    'app.question.q88': '有時我會把事情想得太嚴重。',
    'app.question.q89': '我們應該相信“地球母親”的理念。',
    'app.question.q90': '人生有時會感到很孤獨。',
    'app.question.q91': '大多數的教授或老師，想法過於自由。',
    'app.question.q92': '真正的救贖只會來自崇高的力量。',
    'app.question.q93': '我有時會感到很無助和很脆弱，需要得到別人的關愛。',
    'app.question.q94': '我們的社會應該更幫助少數群體。',
    'app.question.q95': '學習多種語言對誰都有益。',
    'app.question.q96': '儘管我們期望他們那樣，但男人並不是為了在婚姻中保持忠誠而造就的。',
    'app.question.q97': '有些事情連自己都不知道為什麼會發生。',
    'app.question.q98': '與父親有，或曾有過心理隔閡。',
    'app.question.q99': '我們應該選女性當我國首相。',
    'app.question.q100': '最終，我們會選擇自己喜歡的方式去生活。',
    'app.question.q101': '這個國家的司法機構過於自由。',
    'app.question.q102': '希望認真對待自己的事情。',
    'app.question.q103': '我們應該做更多的事情來幫助弱勢群體。',
    'app.question.q104': '我不在乎我的情緒外露。',
    'app.question.q105': '成長過程中感情曾被傷害過。',
    'app.question.q106': '色情正在降低女性身份。',
    'app.question.q107': '為世界的不公而感到憤怒。',
    'app.question.q108': '政府的大項目，幾乎都是弊大於利。',
    'app.question.q109': '以前，情感受挫。',
    'app.question.q110': '喜歡讀自我啟發類書。',
    'app.question.q111': '我媽媽和/或我爸爸有，或有過情緒或心理問題。',
    'app.question.q112': '瞭解其他文化很有趣。',
    'app.question.q113': '我們應該保護擁有悠久歷史的傳統。',
    'app.question.q114': '女人沒有足夠的野心獲得成功。',
    'app.question.q115': '有時我會生別人的氣。',
    'app.question.q116': '除非你允許他們傷害你，否則沒有人能真正傷害到你。',
    'app.question.q117': '我需要有人來傾聽我的感受。',
    'app.question.q118': '只要你努力，幾乎所有的問題都可以解決。',
    'app.question.q119': '光是祈禱，不會成功。',
    'app.question.q120': '我曾是，或正是父母鬧矛盾或爭吵的原因。',
    'app.question.q121': '這個國家貧富差距非常大。',
    'app.question.q122': '我不完全理解自己的感受。',
    'app.question.q123': '人不會真正改變。',
    'app.question.q124': '我的家人未曾對我滿意過。',
    'app.question.q125': '男人天生就比女人好鬥。',
    'app.question.q126': '我們需要做更多去保護地球的自然資源。',
    'app.question.q127': '我曾經碰到過金錢上的困難。',
    'app.question.q128': '我不喜歡被批判。',
    'app.question.q129': '我與母親或我與父親，現在或過去關係很好。',
    'app.question.q130': '我有必須解決的問題。',
    'app.question.q131': '祈禱比膏藥更靈效。',
    'app.question.q132': '我小時候受到過虐待或遺棄。',
    'app.question.q133': '我們應更嚴厲打擊毒品犯罪。',
    'app.question.q134': '我強烈支持女性權利平等。',
    'app.question.q135': '父親理解我，接受我。',
    'app.question.q136': '教育是通更先進社會的樞紐。',
    'app.question.q137': '男兒當自強。',
    'app.question.q138': '不管土地誰用，我們都應保護。',
    'app.question.q139': '父母對我要求過多。',
    'app.question.q140': '女性在經濟和政治上地位的提高，將有助於社會發展。',
    'app.question.q141': '有時，別人哭，我也會跟著哭。',
    'app.question.q142': '有些女性會被性騷擾是因為她們的著裝。',
    'app.question.q143': '如果你不能說好話，就應該保持沉默。',
    'app.question.q144': '過去無法改變，所以再想也無意義。',
    'app.question.q145': '當你快被生活壓倒時，就應勇敢地爬起來堅強地活下去。',
    'app.question.q146': '拒絕往往讓你更強大。',
    'app.question.q147': '有些人過多分析這世界。',
    'app.question.q148': '當我心情不好時，總會試著從積極角度去思考。',
    'app.question.q149': '最好總是看到優點而不是缺點。',
    'app.question.q150': '度過艱難一生的最好辦法是保持一種樂觀的態度。',
    'app.question.q151': '過去的事情已過去，就該原諒和忘記。',
    'app.question.q152': '不能說一種觀點優於其他任何一個觀點。',
    'app.question.q153': '我認為人們應該心懷感激其所擁有的並停止抱怨。',
    'app.question.q154': '有些數字比其他數字更不祥。',
    'app.question.q155': '沒有理由去咒罵、謾罵。',
    'app.question.q156': '太多人沒有盡責。',
    'app.question.q157': '通靈者可以預測未來。',
    'app.question.q158': '凡事簡化最好。',
    'app.question.q159': '無論何事，都難以讓兩位專家達成一致。',
    'app.question.q160': '只要按規矩辦事，就能做好。',
    'app.question.q161': '要是所有人能喜歡我，至少能尊重我就好了。',
    'app.question.q162': '對待不同的文化習俗，我們應該更寬容。',
    'app.question.q163': '我是個崇尚精神自由的人。',
    'app.question.q164': '我相信占卜和占星術的力量。',
    'app.question.q165': '想到在貧困中掙扎的人就感到悲傷。',
    'app.question.q166': '我認為邏輯清晰比什麼都重要。',
    'app.question.q167': '身邊都是與自己截然不同的人，我感到舒適輕鬆。',
    'app.question.q168': '父母為了孩子不應分開。',
    'app.question.q169': '性幻想是健康的。',
    'app.question.q170': '醫療診斷並不是總有用。',
    'app.question.q171': '我總是看到事情好的一面。',
    'app.question.q172': '外星人幫助了人類進化。',
    'app.question.q173': '地球有幾十億歲了。',
    'app.question.q174': '弱點也可以變成美德。',
    'app.question.q175': '沒人會喜歡悲觀主義者。',
    'app.question.q176': '我不喜歡自己或別人情緒外露。',
    'app.question.q177': '在我成長過程中，父母的關係非常好。',
    'app.question.q178': '我們過於寬容不同的文化習俗。',
    'app.question.q179': '我們盡力做好扶貧工作。',
    'app.question.q180': '人們過於擔憂環境。',
    'app.question.q181': '偏見已經不成問題。',
    'app.question.q182': '人總在變化。',
    'app.question.q183': '通往天堂的路有很多條。',
    'app.question.q184': '男人和女人與其說不同，還不如說相似。',
    'app.question.q185': '人的命運都是注定的。',
};

export const scaleQuestionsPageZHCN: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
