import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { colors } from '../../../config/colors';
import { BackgroundStatisticItem } from './BackgroundStatisticItem';
import { BackgroundItem } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
    highestMean: number;
    highestRange: string;
    lowestMean: number;
    lowestRange: string;
    middleMean: number;
    middleRange: string;
    highestBackground: BackgroundItem;
    lowestBackground: BackgroundItem;
    middleBackground: BackgroundItem;
    selectedCountryCodes: number[];
    subGroupName?: string;
}

export const BackgroundDomainContrast = (props: Props): JSX.Element => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 style={styles.title}>{GetFormattedMessage('app.report.background_domain_contrast.title')}</h2>
                    {props.subGroupName &&
                        <React.Fragment>
                            <h2 style={styles.title}>{props.subGroupName}</h2>
                        </React.Fragment>
                    }
                    <hr />
                </Col>
            </Row>
            <Row style={styles.paddingTop25}>
                <Col md={4}>
                    <BackgroundStatisticItem
                        title={[
                            GetFormattedMessage('app.report.background_domain_contrast.lowest_full_scale'),
                            GetFormattedMessage('app.report.background_domain_contrast.mean') +
                            " = " + props.lowestMean + " | " +
                            GetFormattedMessage('app.report.background_domain_contrast.range') +
                            " = " + props.lowestRange,
                            GetFormattedMessage('app.report.background_domain_contrast.background_information')
                        ]}
                        background={props.lowestBackground}
                        selectedCountryCodes={props.selectedCountryCodes}
                    />
                </Col>
                <Col md={4}>
                    <BackgroundStatisticItem
                        title={[
                            GetFormattedMessage('app.report.background_domain_contrast.middle_full_scale'),
                            GetFormattedMessage('app.report.background_domain_contrast.mean') +
                            " = " + props.middleMean + " | " +
                            GetFormattedMessage('app.report.background_domain_contrast.range') +
                            " = " + props.middleRange,
                            GetFormattedMessage('app.report.background_domain_contrast.background_information')
                        ]}
                        background={props.middleBackground}
                        selectedCountryCodes={props.selectedCountryCodes}
                    />
                </Col>
                <Col md={4}>
                    <BackgroundStatisticItem
                        title={[
                            GetFormattedMessage('app.report.background_domain_contrast.highest_full_scale'),
                            GetFormattedMessage('app.report.background_domain_contrast.mean') +
                            " = " + props.highestMean + " | " +
                            GetFormattedMessage('app.report.background_domain_contrast.range') +
                            " = " + props.highestRange,
                            GetFormattedMessage('app.report.background_domain_contrast.background_information')
                        ]}
                        background={props.highestBackground}
                        selectedCountryCodes={props.selectedCountryCodes}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

const styles = {
    title: {
        textAlign: 'center',
        color: colors.darkSlateBlue
    } as React.CSSProperties,
    paddingTop25: {
        paddingTop: '25px'
    } as React.CSSProperties,
};
