import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { DASHBOARD_PAGE, FORGOT_PASSWORD_PAGE, LIST_USERS_PAGE, PROGRAMS_PAGE, REPORT_PAGE, SAMPLE_DATA_REPORT_PAGE } from '../../router/pages';
import { ValidateTokenInfo } from '../../utils/validateHelper';
import { Login } from './Login';
import { IsParticipant, IsProgramAdmin, HasPermissionCapabilityProgramAdmin } from '../../utils/userHelper';
import { UserPermissions } from '../../services/usersService';

type Props = RouteComponentProps;

export const LoginContainer = withRouter((props: Props): JSX.Element => {
    const { login }: AppActions = useActions(appLogic);
    const { loading, tokenInfo, hasCredentialsError, hasSystemError }: AppProps = useValues(appLogic);

    let errorKey = '';
    if (hasCredentialsError) {
        errorKey = 'app.login.invalid_credentials';
    } else if (hasSystemError) {
        errorKey = 'app.systemerro';
    }

    useEffect(() => {
        if (ValidateTokenInfo(tokenInfo)) {
            let page = IsParticipant(tokenInfo) ? DASHBOARD_PAGE : LIST_USERS_PAGE;
            if(IsProgramAdmin(tokenInfo)) {
                if(HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeUser)) {
                    page = LIST_USERS_PAGE;
                } else if (HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeProgramExperience)) {
                    page = PROGRAMS_PAGE;
                } else if (HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeReport)) {
                    page = REPORT_PAGE;
                } else {
                    page = SAMPLE_DATA_REPORT_PAGE;
                }
            }
            props.history.push(page)
        }
    }, [tokenInfo, props.history]);

    return (
        <Login
            login={login}
            forgotPasswordClicked={() => props.history.push(FORGOT_PASSWORD_PAGE)}
            loading={loading}
            errorKey={errorKey}
        />
    )
});
