import { useActions, useValues } from 'kea';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { listOrganizationLogic, ListOrganizationProps } from '../../redux/listOrganizationLogic';
import { ProgramActions, programLogic, ProgramProps } from '../../redux/programLogic';
import { PROGRAMS_PAGE, INDIVIDUAL_REPORT_PAGE } from '../../router/pages';
import { SubProgramDetails } from './SubProgramDetails';
import { FormsActions, formLogic } from '../../redux/formsLogic';
import { appLogic, AppProps } from '../../redux/appLogic';

type Props = RouteComponentProps;

export const SubProgramDetailsContainer = withRouter((props: Props): JSX.Element => {
    const {
        selectedProgram,
        selectedSubprogram,
        searchOrganizationResults,
    }: ListOrganizationProps = useValues(listOrganizationLogic);

    const {
        clearSubProgramDetails,
        loadSubProgramDetails,
        sendCustomAgreement,
        remindToTakeTheBEVI
    }: ProgramActions = useActions(programLogic);

    const {
        loading: subProgramLoading,
        error: subProgramError,
        subProgramDetails,
        loadingRemindToTakeTheBEVI: loadingTemp,
        remindToTakeTheBEVISuccess
    }: ProgramProps = useValues(programLogic);

    const {
        setFormId, setShowReport, setFormStatus
    }: FormsActions = useActions(formLogic);

    const { tokenInfo }: AppProps = useValues(appLogic);

    // Reset on unmount for the next mount.
    useEffect(() => {
        return () => {
            clearSubProgramDetails();
        }
        // eslint-disable-next-line
    }, []);

    // Effect to fetch selected subprogram (similar to componentdidmount).
    useEffect(() => {
        if (selectedSubprogram && selectedSubprogram.id !== subProgramDetails?.id) {
            loadSubProgramDetails && loadSubProgramDetails(selectedSubprogram.id);
        }
    }, [loadSubProgramDetails, selectedSubprogram, subProgramDetails]);

    // Effect validate the selected program, subprogram and organization.
    useEffect(() => {
        if (!selectedProgram || !selectedSubprogram || !searchOrganizationResults) {
            props.history.replace(PROGRAMS_PAGE);
        }
    }, [selectedProgram, selectedSubprogram, searchOrganizationResults, props.history]);

    const organizationName = searchOrganizationResults?.name;

    const handleRemindToTakeTheBEVI = () => {
        remindToTakeTheBEVI(subProgramDetails.id);
    }

    const showReportClick = (formId: number) => {
        setFormId(formId);
        setFormStatus(5);
        setShowReport(true);

        props.history.push(INDIVIDUAL_REPORT_PAGE);
    }

    const handleSendCustomAgreementButtonClick = (formId: number, customAgreementText: string) => {
        sendCustomAgreement(formId, customAgreementText);
        clearSubProgramDetails();
    }

    return (
        <SubProgramDetails
            organizationName={organizationName}
            program={selectedProgram}
            subProgram={subProgramDetails}
            loading={subProgramLoading || subProgramDetails == null}
            error={subProgramError}
            onBackButtonClicked={props.history.goBack}
            showReportClick={showReportClick}
            userInfo={tokenInfo}
            sendCustomAgreement={handleSendCustomAgreementButtonClick}
            loadingRemindToTakeTheBEVI={loadingTemp}
            remindToTakeTheBEVISuccess={remindToTakeTheBEVISuccess}
            handleRemindToTakeTheBEVI={handleRemindToTakeTheBEVI}
            loadSubProgramDetails={loadSubProgramDetails}
        />
    );
});
