import { ExternalPageMessages } from '../types';

export const externalCA: ExternalPageMessages = {
    'app.external.title.thankyou': 'Gràcies per completar el BEVI.',
    'app.external.thankyou_static_1_1': 'L’Inventari de creences, esdeveniments i valors  (BEVI) i aquest informe tenen drets d’autor. ' +
        'El BEVI, els informes, els ítems o les escales de BEVI no es poden modificar, copiar, difondre ni publicar totalment ni ' +
        ' parcialment sense el permís escrit i exprés de Craig N. Shealy, Ph.D.',
    'app.external.thankyou_static_1_2': '',
    'app.external.thankyou_static_2': 'Igual que amb el BEVI en general, l’estructura i el disseny d’aquests informes estan subjectes' +
        'a processos de revisió i desenvolupament constants. Si tens suggeriments o comentaris sobre algun aspecte del BEVI o aquest' +
        'informe, no dubtis a contactar-nos directament a info@ibavi.org',
    'app.external.thankyou_static_3': 'Per obtenir més informació sobre les creences i els valors, visita el International Beliefs ' +
        'and Values Institute (IBAVI) a www.ibavi.org i www.thebevi.com',
}
