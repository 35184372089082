import { useActions, useValues } from 'kea';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import search from '../../assets/images/no_info_search.png';
import { Loading } from '../../components/Loading';
import { NoInfo } from '../../components/NoInfo';
import { appLogic, AppProps } from '../../redux/appLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import { CREATE_USER_PAGE, EDIT_USER_PAGE, USER_PROGRAMS_PAGE } from '../../router/pages';
import { ResetUserPasswordType } from '../../services/usersService';
import { FilterUsers } from './components';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import { ListUsers } from './ListUsers';

type Props = RouteComponentProps;

export const ListUsersContainer = withRouter((props: Props): JSX.Element => {

    const { tokenInfo }: AppProps = useValues(appLogic);

    const { organizations, loadingPage }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { getOrganizations, setSelectedUserId }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { clearSuccessfulCreateNewUser, searchUsers, loadUserProgramDetails, resetUserResetPassword, clearUserResetPassword }: UsersActions = useActions(usersLogic);
    const { users, loadingSearchUsers, searchUserData, resetPasswordSuccessful, errorResetPassword }: UsersProps = useValues(usersLogic);

    const [selectedUser, setSelectedUser] = useState<any>(undefined);
    const [loadingResetUserPassword, setLoadingResetUserPassword] = useState<boolean>(false);

    useEffect(() => {
        if (searchUserData.organizationId !== undefined && !users?.length) {
            searchUsers(searchUserData.organizationId, searchUserData.userNameQuery, searchUserData.userEmailQuery);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (resetPasswordSuccessful === true || errorResetPassword === true) {
            setLoadingResetUserPassword(false);
        }
    }, [resetPasswordSuccessful, errorResetPassword]);

    useEffect(() => {
        if (!organizations?.length) getOrganizations();
        // eslint-disable-next-line
    }, [getOrganizations]);

    const onCreateNewUserHandleClick = () => {
        clearSuccessfulCreateNewUser();
        props.history.push(CREATE_USER_PAGE);
    }

    const onEditUserHandleClick = (userId: number) => {
        clearSuccessfulCreateNewUser();
        setSelectedUserId(userId);
        props.history.push(EDIT_USER_PAGE);
    }

    const onUserProgramsHandleClick = (userId: number) => {
        loadUserProgramDetails(userId);
        props.history.push(USER_PROGRAMS_PAGE);
    }

    if (loadingPage) {
        return <Loading />
    }

    const onResetPasswordModalHide = () => {
        setSelectedUser(undefined);
        clearUserResetPassword();
    }

    const onResetPasswordClick = (password: string) => {
        setLoadingResetUserPassword(true);
        const passwords: ResetUserPasswordType[] = [];
        passwords.push({
            NewPassword: password,
            UserId: selectedUser.id
        })
        resetUserResetPassword(passwords);
    }

    return (
        <Fragment>
            <FilterUsers
                userInfo={tokenInfo}
                organizations={organizations}
                onCreateNewUserHandleClick={onCreateNewUserHandleClick}
            />
            {searchUserData?.organizationId === undefined
                ? null
                : (users === undefined || users == null || users.length === 0) && !loadingSearchUsers
                    ? (
                        <NoInfo
                            title={'app.no.info.title.no_data'}
                            subTitle={'app.no.info.subtitle.no_data'}
                            image={search}
                        />
                    ) : (
                        <>
                            <ListUsers
                                userInfo={tokenInfo}
                                data={users}
                                loading={loadingSearchUsers}
                                onUserProgramClicked={onUserProgramsHandleClick}
                                onEditUserClicked={onEditUserHandleClick}
                                onResetClick={setSelectedUser}
                            />
                            <ResetPasswordModal
                                user={selectedUser}
                                loadingButton={loadingResetUserPassword}
                                showModal={selectedUser !== undefined}
                                onModalHide={onResetPasswordModalHide}
                                onChangePassword={onResetPasswordClick}
                                success={resetPasswordSuccessful === true}
                                error={errorResetPassword === true}
                            />
                        </>
                    )
            }
        </Fragment>
    )
});
