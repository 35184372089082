import { useCallback, useState } from "react";
import { ListReportModel, listReports } from "../services/reportService";
import { successfulCode } from "../config/constants";
import { orderBy } from "lodash";

const useSampleData = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<ListReportModel[] | undefined>();
    const [error, setError] = useState<string>();

    const getSampleData = useCallback((token: string) => {
        setLoading(true);
        setError(undefined);
        listReports(token, {
            organizationId: 0,
            endDate: new Date(),
            startDate: new Date(),
            isSampleDataReport: true
        }).then(response => {
            if (response.status === successfulCode) {
                setList(orderBy(response.body, ['name', 'id'],['asc']));
            } else {
                setError(response.keyErrorMessage);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return { getSampleData, loading, list, error };
}

export default useSampleData;