import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { BeviFormattedLabel } from '../../components/BeviFormattedLabel';
import { H2 } from '../../components/styled/h2';
import { colors } from '../../config/colors';

type Props = {
    loading: boolean;
    onHandleClick: () => void;
};

export const Consent = ({ loading, onHandleClick }: Props): JSX.Element => {

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <H2 textAlign='center'><FormattedMessage id='app.consent.form_title' /></H2>
                <BeviFormattedLabel messageId='app.consent.greeting' />
                <BeviFormattedLabel messageId='app.consent.introduction' />
                <BeviFormattedLabel messageId='app.consent.general.observation' />
                <BeviFormattedLabel messageId='app.consent.explanation_1' />
                <BeviFormattedLabel messageId='app.consent.explanation_2' />
                <BeviFormattedLabel messageId='app.consent.participant.rights.1' isHtml={true} />
                <BeviFormattedLabel messageId='app.consent.participant.rights.2' />
                <BeviFormattedLabel messageId='app.consent.understand.agree' />
                <BeviFormattedLabel messageId='app.consent.have.questions' isHtml={true} />
                <BeviFormattedLabel messageId='app.consent.thanks' />

                <Button
                    style={{
                        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
                        height: '35px',
                        width: '100px',
                        color: '#fff',
                        backgroundColor: colors.green,
                        border: 'none',
                    }}
                    onClick={() => onHandleClick()}
                >
                    {loading ?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        :
                        <FormattedMessage id='app.consent.agree' />
                    }
                </Button>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '900px',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    buttonSubmit: {
        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
        height: '35px',
        width: '100px',
        color: '#fff',
        backgroundColor: colors.green,
        border: 'none',
    } as React.CSSProperties
};
