import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, delay, put, race } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import {
    ExternalFormStatus, externalFormStatus, ExternalFormStatusResponse
} from '../services/listFormsService';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateExternalToken } from '../utils/validateHelper';
import { generalExceptionKey, requestTimeout } from './constants';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['getExternalFormStatus']);
export type ExternalActions = { [K in (typeof actionsLiterals)[number]]: any };
export const externalActionsConstants: ExternalActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setExternalFormStatus',
    'setExternalFormStatusError',
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'keyErrorMessage', 'externalFormStatus']);
export type ExternalProps = { [K in (typeof reducersLiterals)[number]]: any };
export const externalPropsConstants: ExternalProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: ExternalActions & PrivateActions; };
type WorkersParam = {
    workers: {
        fetchExternalFormStatus: () => void;
    }
};

// Define payloads type.
type TokenPayload = { externalToken: string; };
type ErrorPayload = { errorKey: string; };
type ExternalFormStatusPayload = { status: ExternalFormStatus; };

type ExternalFormStatusPayloadRequest = { payload: TokenPayload };
type ExternalFormStatusRaceResponse = { response: ExternalFormStatusResponse; }

// persist external reducer
export const externalKeaPath = 'external';

export const externalReducer = keaReducer(externalKeaPath);

export const externalLogic = kea({
    path: () => [externalKeaPath],

    actions: () => ({
        [externalActionsConstants.getExternalFormStatus]:
            (externalToken: string): TokenPayload => ({ externalToken }),
        [privateActionsConstants.setExternalFormStatus]:
            (status: ExternalFormStatus): ExternalFormStatusPayload => ({ status }),
        [privateActionsConstants.setExternalFormStatusError]:
            (errorKey: string): ErrorPayload => ({ errorKey }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [externalPropsConstants.loading]: [false, PropTypes.bool, {
            [actions.getExternalFormStatus]: () => true,
            [actions.setExternalFormStatus]: () => false,
            [actions.setExternalFormStatusError]: () => false,
            [logout]: () => false
        }],
        [externalPropsConstants.externalFormStatus]: [null, PropTypes.any, {
            [actions.getExternalFormStatus]: () => null,
            [actions.setExternalFormStatus]: (_: any, payload: ExternalFormStatusPayload) => payload.status,
            [logout]: () => null
        }],
        [externalPropsConstants.keyErrorMessage]: [null, PropTypes.string, {
            [actions.getExternalFormStatus]: () => null,
            [actions.setExternalFormStatus]: () => null,
            [actions.setExternalFormStatusError]: (_: string, payload: ErrorPayload) => payload.errorKey,
            [logout]: () => null
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.getExternalFormStatus]: workers.fetchExternalFormStatus,
    }),

    workers: {
        * fetchExternalFormStatus(action: ExternalFormStatusPayloadRequest): any {
            //@ts-ignore
            const { setExternalFormStatus, setExternalFormStatusError } = this.actionCreators;
            const { externalToken } = action.payload;

            if (!ValidateExternalToken(externalToken)) {
                yield put(notAuthorized());
                yield put(logout());
                return;
            }

            const { response }: ExternalFormStatusRaceResponse = yield race({
                response: call(externalFormStatus, externalToken),
                timeout: delay(requestTimeout)
            });

            if (response?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (response?.status === successfulCode) {
                if (response?.body) {
                    yield put(setExternalFormStatus(response.body));
                } else {
                    yield put(setExternalFormStatusError(generalExceptionKey));
                }
            } else if (response?.keyErrorMessage) {
                yield put(setExternalFormStatusError(response.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },
    }
})
