import { Button, Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';
import { IconType } from 'react-icons';

import { PageMessages } from '../locales/types';
import { GetFormattedMessage } from '../utils/htmlHelper';

export interface ButtonWithIconProps {
  textId?: keyof(PageMessages);
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
  icon: ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const ButtonWithIcon = ({ textId, onClick, icon, disabled }: ButtonWithIconProps) => {

  return (
    <Row className="flex-column-reverse">
        <Col>
            <Button style={styles.button} onClick={onClick} disabled={disabled}>
                <div style={styles.icon}>{icon}</div>
                {textId && GetFormattedMessage(textId)}
            </Button>
        </Col>
    </Row>
  );
};

export type BootstrapButtonWithIconProps = ButtonProps &{
  textId?: keyof(PageMessages);
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  icon: IconType | JSX.Element | JSX.IntrinsicElements;
  style?: React.CSSProperties;
  textColor?: string;
}
export const BootstrapButtonWithIcon = (
    { textId, textColor, icon, onClick, style, ...buttonProps }: BootstrapButtonWithIconProps
) => {
    const textStyle: React.CSSProperties = textColor ?
        {...styles.text, color: textColor} : styles.text;
    return (
        <BootstrapButton
            style={style}
            onClick={onClick}
            {...buttonProps}
        >
            <>
            {icon}
            {textId &&
                <p style={textStyle}> {GetFormattedMessage(textId)} </p>
            }
            </>
        </BootstrapButton>
    );
};

const styles = {
    button: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as React.CSSProperties,
    icon: {
        marginRight: 5
    } as React.CSSProperties,
    text: {
        marginLeft: '15px',
    } as React.CSSProperties,
};
