import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReportPermission } from './ReportPermission';
import { ComparativeIndividualReportActions, ComparativeIndividualReportProps, comparativeIndividualReportLogic } from '../../redux/comparativeIndividualReportLogic';
import { useValues, useActions } from 'kea';
import { ReportPermissionResponse, ReportPermissionStatus, Reports } from '../../services/reportService';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = RouteComponentProps;

export const ReportPermissionContainer = withRouter((props: Props): JSX.Element => {
  const [selectReportPermission, setSelectReportPermission] = useState<ReportPermissionResponse>();

  const { reportPermissions, loadingReportPermission, loadingUpdateReportPermission, reportPermissionSuccess }: ComparativeIndividualReportProps = useValues(comparativeIndividualReportLogic);

  const {
    getReportPermission, updateReportPermission, clearUpdateReportPermission
  }: ComparativeIndividualReportActions = useActions(comparativeIndividualReportLogic);

  useEffect(() => {
    getReportPermission();
  }, [getReportPermission])

  useEffect(() => {
    if (reportPermissionSuccess) {
      getReportPermission();
      clearUpdateReportPermission();
    }
  }, [clearUpdateReportPermission, getReportPermission, reportPermissionSuccess])

  const handleUpdateReportPermission = (status: ReportPermissionStatus) => {
    updateReportPermission({ reportPermissionId: selectReportPermission?.id, status });
    setSelectReportPermission(undefined);
  }

  const extractName = useCallback((fullName: string): string => {
    const parts = fullName.trim().split(' ');
    if (parts.length === 1) {
      return parts[0];
    } else {
      return parts.shift()! + ' ' + parts.join(' ');
    }
  }, []);

  const message = useMemo(() => {
    console.log('REPORT TYPE',selectReportPermission?.report?.reportType)
    if ([Reports.partner_change_report, Reports.partner_report].includes(selectReportPermission?.report?.reportType)) {
      const approvers = selectReportPermission?.approvers?.map(x => extractName(x)).join(', ') ?? '';
      const users = selectReportPermission?.visualizers?.map(x => extractName(x)).join(', ') ?? '';
      return GetFormattedMessage('app.custom_agreement.partner_report_agreement').replace('#PARTNERS#', approvers).replace('#USERS#', users);
    }
    else if ([Reports.individual_report, Reports.longitudinal_individual_report].includes(selectReportPermission?.report?.reportType)) {
      const users = selectReportPermission?.visualizers?.map(x => extractName(x)).join(', ') ?? '';
      return GetFormattedMessage('app.report.permission.modal_description').replaceAll('#USERS#', users);
    }

    return '';
  }, [selectReportPermission, extractName])

  return (<>
    <ReportPermission
      reportPermissions={reportPermissions}
      loadingReportPermission={loadingReportPermission || loadingUpdateReportPermission}
      setSelectReportPermission={setSelectReportPermission} />
    <ConfirmationModal
      onModalHide={() => setSelectReportPermission(undefined)}
      showModal={selectReportPermission != null}
      titleFormattedMessageId='app.report.permission.modal_title'
      contentClassName='w800'
      bodyFormattedText={[<div dangerouslySetInnerHTML={{ __html: message }} />]}
      primaryButtonFormattedMessageId={selectReportPermission?.status !== ReportPermissionStatus.Approved ? 'app.report.permission.approve' : undefined}
      secondaryButtonFormattedMessageId={selectReportPermission?.status !== ReportPermissionStatus.Rejected ? 'app.report.permission.reject' : undefined}
      onPrimaryButtonClicked={() => handleUpdateReportPermission(ReportPermissionStatus.Approved)}
      onSecondaryButtonClicked={() => handleUpdateReportPermission(ReportPermissionStatus.Rejected)}
    />
  </>);
});
