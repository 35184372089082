import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { colors } from '../../../config/colors';
import { SaveOrganizationPayloadRequest } from '../../../services/organizationService';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
  showModal: boolean;
  organization: any;
  loadingButton: boolean;
  success: boolean;
  error: boolean;
  onModalHide: () => void;
  onSaveInstitutionHandleClick(organization: SaveOrganizationPayloadRequest): void;
  onSuccessfullSaveInstitutionModalHide: () => void;
}

export const CreateInstitutionModal = (
  { loadingButton, showModal, organization, success, error, onModalHide, onSaveInstitutionHandleClick, onSuccessfullSaveInstitutionModalHide }: Props
): JSX.Element => {

  useEffect(() => {
    if (organization && organization.id && organization.id > 0 && organization.name) {
      setName(organization.name)
    } else {
      setName('')
    }
  }, [organization])

  useEffect(() => {
    if(success) {
      setName('')
      onSuccessfullSaveInstitutionModalHide()
    }
    // eslint-disable-next-line
  }, [success])

  const [name, setName] = useState<string>('');

  const onSubmitButtonClicked = () => {
    let data: SaveOrganizationPayloadRequest = { id: 0, name: '' };
    if (organization && organization.id && organization.id > 0) {
      data.id = organization.id
      
    }
    if(name !== '') {
      data.name = name;
      onSaveInstitutionHandleClick(data);
    }
  }

  return (
    <Modal show={showModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          { (organization && organization.id && organization.id > 0) ? GetFormattedMessage('app.institutions.edit.title').toUpperCase() : GetFormattedMessage('app.institutions.add.title').toUpperCase() }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {error === true &&
            <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.institutions.add.error_message') }} />
          }
          <Form.Group className='mb-3 px-2' as={Col}>
            <Form.Group className='mb-3' controlId="formField2">
              <b><FormattedMessage id='app.institutions.add.new_name' /></b>
              <Form.Control
                className="form-control mb-3"
                type='text'
                placeholder={GetFormattedMessage('app.institutions.add.new_name')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>
          </Form.Group>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: colors.darkSlateBlue }}
          variant='primary'
          onClick={onSubmitButtonClicked}
          disabled={loadingButton || name === ''}
        >
          {loadingButton &&
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: 10 }}
            />
          }
          {GetFormattedMessage('app.institutions.add.button_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};