import { appZHTW } from './zh_TW/app';
import { consentPageZHTW } from './zh_TW/consent';
import { counterPageZHTW } from './zh_TW/counter';
import { createReportZHTW } from './zh_TW/create_report';
import { createProgramZHTW } from './zh_TW/createProgram';
import { dataDownloadZHTW } from './zh_TW/dataDownload';
import { demographicQuestionsZHTW } from './zh_TW/demographicQuestions';
import { discursiveQuestionsZHTW } from './zh_TW/discursiveQuestions';
import { externalZHTW } from './zh_TW/external';
import { individualReportPageZHTW } from './zh_TW/individualReport';
import { internalTemplatePageZHTW } from './zh_TW/internalTemplate';
import { listFormsPageZHTW } from './zh_TW/listforms';
import { listOrganizationsZHTW } from './zh_TW/listOrganizations';
import { listReportZHTW } from './zh_TW/listReport';
import { loginPageZHTW } from './zh_TW/login';
import { noInfoZHTW } from './zh_TW/noInfo';
import { permissionZHTW } from './zh_TW/permission';
import { reportsZHTW } from './zh_TW/reports';
import { scaleQuestionsPageZHTW } from './zh_TW/scaleQuestions';
import { timeZHTW } from './zh_TW/time';
import { usersZHTW } from './zh_TW/users';
import { PageMessages } from './types';
import { financialReportZHTW } from './zh_TW/financialReport';
import { invitationZHTW } from './zh_TW/invitation';
import { institutionZHTW } from './zh_TW/institutions';
import { programsZHTW } from './zh_TW/programs';
import { virtualAccountZHTW } from './zh_TW/virtualAccount';

export const zhTW: PageMessages = {
  ...appZHTW,
  ...counterPageZHTW,
  ...loginPageZHTW,
  ...scaleQuestionsPageZHTW,
  ...consentPageZHTW,
  ...listFormsPageZHTW,
  ...demographicQuestionsZHTW,
  ...listOrganizationsZHTW,
  ...discursiveQuestionsZHTW,
  ...individualReportPageZHTW,
  ...internalTemplatePageZHTW,
  ...createProgramZHTW,
  ...usersZHTW,
  ...dataDownloadZHTW,
  ...noInfoZHTW,
  ...createReportZHTW,
  ...reportsZHTW,
  ...timeZHTW,
  ...listReportZHTW,
  ...permissionZHTW,
  ...externalZHTW,
  ...financialReportZHTW,
  ...invitationZHTW,
  ...institutionZHTW,
  ...programsZHTW,
  ...virtualAccountZHTW
}
