import { serviceUrl } from '../config/constants';
import { BaseResponse, fetchGetMethod, fetchPostMethod, getResponseObject } from '../rpc/api';
import {
    AddExtraQuestionModel,
    DeleteExtraQuestionModel,
    DemographicQuestionAnswers, DiscursiveQuestionAnswers, EditExtraQuestionModel, ExtraQuestionModel, ScaleQuestion, ScaleQuestionAnswers
} from './types';

const errorMessageKey = 'app.error.logon';

// Demographic questions services.
export type SaveQuestionsBody = number;

export class SaveDemographicAnswersResponse extends BaseResponse<SaveQuestionsBody> { }

export function saveDemographicQuestions(
    token: string, answers: DemographicQuestionAnswers
): Promise<SaveDemographicAnswersResponse> {
    const response = fetchPostMethod<DemographicQuestionAnswers>(
        `${serviceUrl}/api/question/SaveDemographicAnswers`, token, answers);
    return getResponseObject<SaveQuestionsBody>(response, errorMessageKey);
}

// Scale questions services.
export type LoadScaleQuestionBody = {
    questions?: Array<Array<ScaleQuestion>>;
}

export class LoadScaleQuestionsResponse extends BaseResponse<LoadScaleQuestionBody> { }
export class SaveScaleAnswersResponse extends BaseResponse<SaveQuestionsBody> { }

export function loadScaleQuestions(token: string, formId: number): Promise<LoadScaleQuestionsResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/question/getScaleQuestions?formId=${formId}`, token);
    return getResponseObject<LoadScaleQuestionBody>(response, errorMessageKey);
}

export function saveScaleQuestions(token: string, answers: ScaleQuestionAnswers): Promise<SaveScaleAnswersResponse> {
    const response = fetchPostMethod<ScaleQuestionAnswers>(
        `${serviceUrl}/api/question/SaveScaleAnswers`, token, answers);
    return getResponseObject<SaveQuestionsBody>(response, errorMessageKey);
}

// Discursive questions services.
export type SaveDiscursiveBody = boolean;

export class SaveDiscursiveAnswersResponse extends BaseResponse<SaveDiscursiveBody> { }

export function saveDiscursiveQuestions(
    token: string, answers: DiscursiveQuestionAnswers
): Promise<SaveDiscursiveAnswersResponse> {
    const response = fetchPostMethod<DiscursiveQuestionAnswers>(
        `${serviceUrl}/api/question/SaveDiscursiveQuestions`, token, answers);
    return getResponseObject<SaveDiscursiveBody>(response, errorMessageKey);
}

// Discursive questions services.
export type LoadDemographicsExtraQuestionBody = {
    extraQuestions?: ExtraQuestionModel[];
}

export class LoadDemographicsExtraQuestionResponse extends BaseResponse<LoadDemographicsExtraQuestionBody> { }

export function loadDemographicsExtraQuestions(token: string, organizationId: number): Promise<LoadDemographicsExtraQuestionResponse> {
    const response = fetchGetMethod(`${serviceUrl}/api/question/GetExtraQuestions?institutionId=${organizationId}`, token);
    return getResponseObject<LoadDemographicsExtraQuestionBody>(response, errorMessageKey);
}

export class AddExtraQuestionResponse extends BaseResponse<SaveQuestionsBody> { }
export class EditExtraQuestionResponse extends BaseResponse<SaveQuestionsBody> { }
export class DeleteExtraQuestionResponse extends BaseResponse<SaveQuestionsBody> { }

export function addExtraQuestions(
    token: string, model: AddExtraQuestionModel
): Promise<AddExtraQuestionResponse> {
    const response = fetchPostMethod<AddExtraQuestionModel>(
        `${serviceUrl}/api/question/AddExtraQuestion`, token, model);
    return getResponseObject<SaveQuestionsBody>(response, errorMessageKey);
}

export function editExtraQuestions(
    token: string, model: EditExtraQuestionModel
): Promise<EditExtraQuestionResponse> {
    const response = fetchPostMethod<EditExtraQuestionModel>(
        `${serviceUrl}/api/question/EditExtraQuestion`, token, model);
    return getResponseObject<SaveQuestionsBody>(response, errorMessageKey);
}

export function deleteExtraQuestions(
    token: string, model: DeleteExtraQuestionModel
): Promise<DeleteExtraQuestionResponse> {
    const response = fetchPostMethod<DeleteExtraQuestionModel>(
        `${serviceUrl}/api/question/DeleteExtraQuestion`, token, model);
    return getResponseObject<SaveQuestionsBody>(response, errorMessageKey);
}