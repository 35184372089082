import { FinancialReportMessages } from '../types';

export const financialReportZHCN: FinancialReportMessages = {
    'app.financialreport.title': 'Usage Report',
    'app.financialreport.organization_lbl': 'Organization',
    'app.financialreport.start_data_lbl': 'Start date',
    'app.financialreport.end_data_lbl': 'End date',
    'app.financialreport.created_forms_title': 'Nº INVITED TO TAKE BEVI',
    'app.financialreport.finished_forms_title': 'Nº COMPLETED BEVIS',
    'app.financialreport.valid_forms_title': 'Nº VALID BEVIS',
    'app.financialreport.invalid_forms_title': 'Nº INVALID BEVIS',
    'app.financialreport.unanswered_forms_title': 'Nº UNANSWERED BEVIS',
    'app.financialreport.error_header': 'Error',
    'app.financialreport.error_text': 'An error occurred and the report data could not be loaded, please try again.',
}
