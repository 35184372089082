import './PrimaryWorkAreaQuestion.css';

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { FormDropdown } from '../../../components/FormDropdown';
import { demographicMessageIds } from '../../../locales/types';
import { DemographicQuestionAnswers } from '../../../services/types';
import { PrimaryWorkArea } from '../library/questionDefinitions';

type Props = {
    question: PrimaryWorkArea;
    answerChange: (key: keyof(DemographicQuestionAnswers), value: number | string | undefined) => void;
    questionNumber: number;
    style?: React.CSSProperties;
};

export const PrimaryWorkAreaQuestion = ({ question, answerChange, questionNumber, style }: Props): JSX.Element => {

    return (
        <Form.Group className='mb-3' controlId='demographic.primary_work_area' style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    <FormattedMessage id={question.GetQuestionLabelId()} />
                </div>
            </Form.Label>
            <Col md='6'>
                <FormDropdown
                    defaultOptionId={'app.dropdown_select'}
                    onValueSelect={(value: number | undefined) => {
                        answerChange(question.GetQuestionKeyName(), value);
                    }}
                    optionItens={question.GetDefintions()}
                />
            </Col>
            <br />
            <Col>
                <Form.Label>
                    <FormattedMessage id={demographicMessageIds['app.demographic.current_area']} />
                </Form.Label>
                <Row>
                    <Col className='question-text-field' md={4}>
                        <Form.Control
                            as='input'
                            onChange={(e) => {
                                answerChange('CurrentAreaOfStudyOrOccupation1', e.currentTarget.value);
                            }}
                        />
                    </Col>
                    <Col className='question-text-field' md={4}>
                        <Form.Control
                            as='input'
                            onChange={(e) => {
                                answerChange('CurrentAreaOfStudyOrOccupation2', e.currentTarget.value);
                            }}
                        />
                    </Col>
                    <Col className='question-text-field' md={4}>
                        <Form.Control
                            as='input'
                            onChange={(e) => {
                                answerChange('CurrentAreaOfStudyOrOccupation3', e.currentTarget.value);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
