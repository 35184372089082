import '../style/login.css';

import React, { ReactNode } from 'react';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import logo_bevi from '../assets/images/bevi_logo.png';
import logoJapanese1 from '../assets/images/logo-japanese-1.png';
import sokaLogoJapanese from '../assets/images/soka-logo.jpg';
import { LanguageSwitch } from './LanguageSwitch';

type Props = {
    bodyComponent: ReactNode;
    isJapanese: boolean;
};

export const HomeScreenTemplate = ({ bodyComponent, isJapanese }: Props): JSX.Element => {

    return (
        <div id="login" className="row">
            <div className="login__logo__upper">
                <Image src={logo_bevi} className="login__logo__upper" />
            </div>
            <div className="dropdown language-selector">
                <LanguageSwitch />
            </div>

            <div className="login__col-left col-md-6 col-12">
                <div>
                    <hr className="hr--gradient" />
                    <div className="p-5">
                        <h1 className="text-primary mb-3"><FormattedMessage id='app.login.text_welcome' /> </h1>
                        <p><FormattedMessage id='app.login.text_information' /></p>
                        <p><FormattedMessage id='app.login.text_login_requirement' /> </p>
                        { isJapanese &&
                            <footer className="mt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Image src={logoJapanese1} />
                                        <Image width={90} src={sokaLogoJapanese} />
                                    </div>
                            </footer>
                        }
                    </div>
                </div>
            </div>

            <div className="login__col-right col-md-6 col-12">
                <div className="login__logo">
                    <Image src={logo_bevi} height={100} />
                </div>

                <div className="form-signin card shadow-lg rounded-lg border-0 overflow-hidden">
                    <div className="card-body p-5">
                        {bodyComponent}
                    </div>
                    <hr className="hr--gradient" />
                </div>
            </div>
         </div>
    );
}
