import { LoginPageMessages } from '../types';

export const loginPageCA: LoginPageMessages = {
    'app.login.text_welcome': 'Et donem la benvinguda a l’Inventari de creences, esdeveniments i valors (BEVI)',
    'app.login.text_information': `Per accedir a aquesta pàgina, t’hi ha d’haver convidat una institució, organització o administrador/a amb llicència BEVI.`,
    'app.login.text_login_requirement': `Si us plau, posa-hi el nom d’usuari i la contrasenya (que has rebut).`,
    'app.login.lbl_username': 'Nom d’usuari:',
    'app.login.lbl_password': 'Contrasenya:',
    'app.login.btn_submit': 'Iniciar',
    'app.login.lbl_new_password': 'Nova contrasenya',
    'app.login.lbl_new_password_confirmation': 'Torna a escriure la contrasenya',
    'app.login.btn_submit_new_password': 'Restablir contrasenya',
    'app.login.placeholder.login': 'email@exemple.com',
    'app.login.placeholder.password': 'La teva contrasenya',
    'app.login.invalid_credentials': 'Llàstima! Les credencials proporcionades no coincideixen amb els nostres registres. Si us plau, torna-ho a provar o restableix el compte fent clic a contrasenya oblidada a continuació.',
    'app.login.forgot_password.invalid_credentials': 'L’adreça electrònica no és vàlida. Torna-ho a provar.',
    'app.login.forgot_password': 'Has oblidat la contrasenya?',
    'app.login.forgot_password_confirmation_title': 'Contrasenya restablerta',
    'app.login.forgot_password_confirmation_body': 'S’ha enviat un missatge a la teva adreça electrònica. Segueix les instruccions del missatge per restablir la contrasenya.',
    'app.login.btn_submit_forgot_password': 'Recuperar contrasenya',
    'app.login.passwords_dont_match': 'Les contrasenyes no coincideixen.',
    'app.login.change_password.expired_confirmation_title': 'Pàgina caducada',
    'app.login.change_password.expired_confirmation_body': 'El permís per accedir a la pàgina ha caducat. Si us plau, torna a demanar-lo o inicia sessió per accedir la BEVI.',
    'app.login.change_password.success_confirmation_title': 'Contrasenya actualitzada',
    'app.login.change_password.success_confirmation_body': 'S’ha canviat la contrasenya amb èxit. Utilitza la nova per iniciar sessió i seràs redirigit.',
    'app.login.first_access.title': 'Si us plau, canvia la contrasenya abans d’utilitzar el sistema.',
}
