import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { colors } from '../../config/colors';
import { PageMessages } from '../../locales/types';
import { FinancialReportBody } from '../../services/reportService';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
    summary: FinancialReportBody | null;
    errorKey: keyof (PageMessages) | null;
}

export const FinancialReport = ({ summary, errorKey }: Props) => {
    const createdFormsTitle = GetFormattedMessage('app.financialreport.created_forms_title');
    const finishedFormTitle = GetFormattedMessage('app.financialreport.finished_forms_title');
    const validFormsTitle = GetFormattedMessage('app.financialreport.valid_forms_title');
    const invalidFormsTitle = GetFormattedMessage('app.financialreport.invalid_forms_title');
    const unansweredFormsTitle = GetFormattedMessage('app.financialreport.unanswered_forms_title');

    if (summary === null && errorKey === null) return null;

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                {summary &&
                    <Row style={styles.justifyCenter}>
                        <RenderCard header={createdFormsTitle} title={summary.total} />
                        <RenderCard header={finishedFormTitle} title={summary.done} />
                        <RenderCard header={validFormsTitle} title={summary.valid} />
                        <RenderCard header={invalidFormsTitle} title={summary.invalid} />
                        <RenderCard header={unansweredFormsTitle} title={summary.notFinish} />
                    </Row>
                }
                {errorKey &&
                    <RenderCard
                        header={GetFormattedMessage('app.financialreport.error_header')}
                        title={GetFormattedMessage(errorKey)}
                        text={GetFormattedMessage('app.financialreport.error_text')}
                        error
                    />
                }
            </div>
        </div>
    )
}

type CardProps = {
    header: string;
    title: string | number;
    text?: string;
    error?: boolean;
}
const RenderCard = ({ header, title, error, text }: CardProps) => {
    return (
        <Card
            key={`${header}-${title}`}
            bg={!error ? 'light' : undefined}
            text={'dark'}
            border={error ? "danger" : undefined}
            style={styles.cardContainer}
            className="mx-3 my-3"
        >
            <Card.Header>{header}</Card.Header>
            <Card.Body className='text-center'>
                <Card.Title>{title}</Card.Title>
                {text &&
                    <Card.Text>{text}</Card.Text>
                }
            </Card.Body>
        </Card>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    justifyCenter: {
        justifyContent: 'center'
    } as React.CSSProperties,
    cardContainer: {
        minWidth: '200px',
        width: 'initial',
        padding: 0
    } as React.CSSProperties,
};
