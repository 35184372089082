import { Button, Collapse } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { AggregateProfileByDemographics_AcademicRank as AcademicRank } from '../../pages/GroupReport/components/AggregateProfileByDemographics_AcademicRank';
import { AggregateProfileByDemographics_Age as Age } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Age';
import { AggregateProfileByDemographics_CountryOfOrigin as CountryOfOrigin } from '../../pages/GroupReport/components/AggregateProfileByDemographics_CountryOfOrigin';
import { AggregateProfileByDemographics_Education as Education } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Education';
import { AggregateProfileByDemographics_Ethnicity as Ethnicity } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Ethnicity';
import { AggregateProfileByDemographics_FatherMotherEducation as FatherMotherEducation } from '../../pages/GroupReport/components/AggregateProfileByDemographics_FatherMotherEducation';
import { AggregateProfileByDemographics_Fluency as Fluency } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Fluency';
import { AggregateProfileByDemographics_Gender as Gender } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Gender';
import { AggregateProfileByDemographics_Income as Income } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Income';
import { AggregateProfileByDemographics_Interest as Interest } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Interest';
import { AggregateProfileByDemographics_MinorityGroup as MinorityGroup } from '../../pages/GroupReport/components/AggregateProfileByDemographics_MinorityGroup';
import { AggregateProfileByDemographics_ParentsCountry as ParentsCountry } from '../../pages/GroupReport/components/AggregateProfileByDemographics_ParentsCountry';
import { AggregateProfileByDemographics_Politics as Politics } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Politics';
import { AggregateProfileByDemographics_PrimaryAreaOfWork as PrimaryAreaOfWork } from '../../pages/GroupReport/components/AggregateProfileByDemographics_PrimaryAreaOfWork';
import { AggregateProfileByDemographics_RelationshipStatus as RelationshipStatus } from '../../pages/GroupReport/components/AggregateProfileByDemographics_RelationshipStatus';
import { AggregateProfileByDemographics_Religion as Religion } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Religion';
import { AggregateProfileByDemographics_ReligionOrientation as ReligionOrientation } from '../../pages/GroupReport/components/AggregateProfileByDemographics_ReligionOrientation';
import { AggregateProfileByDemographics_Satisfaction as Satisfaction } from '../../pages/GroupReport/components/AggregateProfileByDemographics_Satisfaction';
import { AggregateProfileByDemographics_TimeSpentInOtherCountries as TimeSpentInOtherCountries } from '../../pages/GroupReport/components/AggregateProfileByDemographics_TimeSpentInOtherCountries';
import { AggregateProfileByDemographics_VisitedCountries as VisitedCountries } from '../../pages/GroupReport/components/AggregateProfileByDemographics_VisitedCountries';
import { AggregateProfileByDemographics_YearsOfLanguageStudy as YearsOfLanguageStudy } from '../../pages/GroupReport/components/AggregateProfileByDemographics_YearsOfLanguageStudy';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { GroupReportAggregateByDemographics_AcademicRank, GroupReportAggregateByDemographics_Age, GroupReportAggregateByDemographics_CountryOfOrigin, GroupReportAggregateByDemographics_Education, GroupReportAggregateByDemographics_Ethnicity, GroupReportAggregateByDemographics_Fluency, GroupReportAggregateByDemographics_Gender, GroupReportAggregateByDemographics_Income, GroupReportAggregateByDemographics_Interest, GroupReportAggregateByDemographics_MinorityGroup, GroupReportAggregateByDemographics_MotherFatherEducation, GroupReportAggregateByDemographics_ParentsCountry, GroupReportAggregateByDemographics_Politics, GroupReportAggregateByDemographics_PrimaryAreaOfWork, GroupReportAggregateByDemographics_RelationshipStatus, GroupReportAggregateByDemographics_Religion, GroupReportAggregateByDemographics_ReligionOrientation, GroupReportAggregateByDemographics_Satisfaction, GroupReportAggregateByDemographics_TimeSpentInOtherCountries, GroupReportAggregateByDemographics_VisitedCountries, GroupReportAggregateByDemographics_YearsOfLanguageStudy } from '../../services/types';

interface DemographicsItem {
  id: number;
  name: string;
  value: any;
  group: string;
}

interface AggregateByDemographicsProps {
  report: any;
  type: string;
}

export const AggregateByDemographics = ({ report, type }: AggregateByDemographicsProps) => {

  const titles = type === 'group' ? undefined : report.groupNameOrder;
  const demographicsAge: GroupReportAggregateByDemographics_Age[] = [];
  const demographicsGender: GroupReportAggregateByDemographics_Gender[] = [];
  const demographicsRelationshipStatus: GroupReportAggregateByDemographics_RelationshipStatus[] = [];
  const demographicsCountryOfOrigin: GroupReportAggregateByDemographics_CountryOfOrigin[] = [];
  const demographicsParentsCountry: GroupReportAggregateByDemographics_ParentsCountry[] = [];
  const demographicsMotherEducation: GroupReportAggregateByDemographics_MotherFatherEducation[] = [];
  const demographicsFatherEducation: GroupReportAggregateByDemographics_MotherFatherEducation[] = [];
  const demographicsEthnicity: GroupReportAggregateByDemographics_Ethnicity[] = [];
  const demographicsMinorityGroup: GroupReportAggregateByDemographics_MinorityGroup[] = [];
  const demographicsPolitics: GroupReportAggregateByDemographics_Politics[] = [];
  const demographicsReligionOrientation: GroupReportAggregateByDemographics_ReligionOrientation[] = [];
  const demographicsReligion: GroupReportAggregateByDemographics_Religion[] = [];
  const demographicsIncome: GroupReportAggregateByDemographics_Income[] = [];
  const demographicsEducation: GroupReportAggregateByDemographics_Education[] = [];
  const demographicsPrimaryAreaOfWork: GroupReportAggregateByDemographics_PrimaryAreaOfWork[] = [];
  const demographicsAcademicRank: GroupReportAggregateByDemographics_AcademicRank[] = [];
  const demographicsTimeSpentInOtherCountries: GroupReportAggregateByDemographics_TimeSpentInOtherCountries[] = [];
  const demographicsVisitedCountries: GroupReportAggregateByDemographics_VisitedCountries[] = [];
  const demographicsInterest: GroupReportAggregateByDemographics_Interest[] = [];
  const demographicsSatisfation: GroupReportAggregateByDemographics_Satisfaction[] = [];
  const demographicsFluency: GroupReportAggregateByDemographics_Fluency[] = [];
  const demographicsYearsOfLanguageStudy: GroupReportAggregateByDemographics_YearsOfLanguageStudy[] = [];

  if(type !== 'group') {
    for (let index = 0; index < report.groupNameOrder.length; index++) {
      const groupName = report.groupNameOrder[index];
      const demographics = report.demographics.filter((t: any) => t.groupName == groupName);
      demographicsAge.push(...demographics.map((t: any) => t.age));
      demographicsGender.push(...demographics.map((t: any) => t.gender));
      demographicsRelationshipStatus.push(...demographics.map((t: any) => t.relationshipStatus));
      demographicsCountryOfOrigin.push(...demographics.map((t: any) => t.countryOfOrigin));
      demographicsParentsCountry.push(...demographics.map((t: any) => t.parentsCountry));
      demographicsMotherEducation.push(...demographics.map((t: any) => t.motherEducation));
      demographicsFatherEducation.push(...demographics.map((t: any) => t.fatherEducation));
      demographicsEthnicity.push(...demographics.map((t: any) => t.ethnicity));
      demographicsMinorityGroup.push(...demographics.map((t: any) => t.minorityGroup));
      demographicsPolitics.push(...demographics.map((t: any) => t.politics));
      demographicsReligionOrientation.push(...demographics.map((t: any) => t.religionOrientation));
      demographicsReligion.push(...demographics.map((t: any) => t.religion));
      demographicsIncome.push(...demographics.map((t: any) => t.income));
      demographicsEducation.push(...demographics.map((t: any) => t.education));
      demographicsPrimaryAreaOfWork.push(...demographics.map((t: any) => t.primaryAreaOfWork));
      demographicsAcademicRank.push(...demographics.map((t: any) => t.academicRank));
      demographicsTimeSpentInOtherCountries.push(...demographics.map((t: any) => t.timeSpentInOtherCountries));
      demographicsVisitedCountries.push(...demographics.map((t: any) => t.visitedCountries));
      demographicsInterest.push(...demographics.map((t: any) => t.interest));
      demographicsSatisfation.push(...demographics.map((t: any) => t.satisfaction));
      demographicsFluency.push(...demographics.map((t: any) => t.fluency));
      demographicsYearsOfLanguageStudy.push(...demographics.map((t: any) => t.yearsOfLanguageStudy));
      
    }

  }

  const demographicsItemYourself: DemographicsItem[] = [
    {
      id: 1,
      name: GetFormattedMessage('app.report.aggregate_demographic.age'), 
      value:
        <Age
          report={type === 'group' ? [report.demographics.age] : demographicsAge}
          groupName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    {
      id: 2,
      name: GetFormattedMessage('app.report.aggregate_demographic.gender'), value:
        <Gender
          reportGender={type === 'group' ? [report.demographics.gender] : demographicsGender}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    {
      id: 3,
      name: GetFormattedMessage('app.report.aggregate_demographic.relationship_status'), value:
        <RelationshipStatus
          subProgramName={titles}
          report={type === 'group' ? [report.demographics.relationshipStatus] : demographicsRelationshipStatus}
          selectedRelationshipStatus={report.selectedRelationshipStatusCodes}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    {
      id: 4,
      name: GetFormattedMessage('app.report.aggregate_demographic.country_origin'), value: <CountryOfOrigin
        selectedCountries={report.selectedCountryCodes}
        reportCountryOfOrigin={type === 'group' ? [report.demographics.countryOfOrigin] : demographicsCountryOfOrigin}
        subProgramName={titles}
      />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    {
      id: 5,
      name: GetFormattedMessage('app.report.aggregate_demographic.parent_country'), value:
        <ParentsCountry
          report={type === 'group' ? [report.demographics.parentsCountry] : demographicsParentsCountry}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.parental_background')
    },
    {
      id: 6,
      name: GetFormattedMessage('app.report.aggregate_demographic.mother_education'), value:
        <FatherMotherEducation
          report={type === 'group' ? [report.demographics.motherEducation] : demographicsMotherEducation}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.parental_background')
    },
    {
      id: 7,
      name: GetFormattedMessage('app.report.aggregate_demographic.father_education'), value:
        <FatherMotherEducation
          report={type === 'group' ? [report.demographics.fatherEducation] : demographicsFatherEducation}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.parental_background')
    },
    {
      id: 8,
      name: GetFormattedMessage('app.report.aggregate_demographic.ethnic_background'), value:
        <Ethnicity
          reportEthnicity={type === 'group' ? [report.demographics.ethnicity] : demographicsEthnicity}
          selectedEthnicity={report.selectedEthnicityCodes}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    {
      id: 9,
      name: GetFormattedMessage('app.report.aggregate_demographic.minority_group'), value:
        <MinorityGroup
          report={type === 'group' ? [report.demographics.minorityGroup] : demographicsMinorityGroup}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },

    // Yourself: Belief and Valuse
    {
      id: 10,
      name: GetFormattedMessage('app.report.aggregate_demographic.political_orientation'), value:
        <Politics
          reportPolitics={type === 'group' ? [report.demographics.politics] : demographicsPolitics}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.political_religious_inclinations')
    },
    {
      id: 11,
      name: GetFormattedMessage('app.report.aggregate_demographic.religious_orientation'), value:
        <ReligionOrientation
          report={type === 'group' ? [report.demographics.religionOrientation] : demographicsReligionOrientation}
          subProgramName={titles}
          selectedReligions={report.selectedReligionsCodes}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.political_religious_inclinations')
    },
    {
      id: 12,
      name: GetFormattedMessage('app.report.aggregate_demographic.religious_commitment'), value:
        <Religion
          reportReligion={type === 'group' ? [report.demographics.religion] : demographicsReligion}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.political_religious_inclinations')
    },
    {
      id: 13,
      name: GetFormattedMessage('app.report.aggregate_demographic.income_level'), value:
        <Income
          reportIncome={type === 'group' ? [report.demographics.income] : demographicsIncome}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.core_demographics')
    },
    // Education
    {
      id: 14,
      name: GetFormattedMessage('app.report.aggregate_demographic.years_education'), value:
        <Education
          reportEducation={type === 'group' ? [report.demographics.education] : demographicsEducation}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.education_background')
    },
    {
      id: 15,
      name: GetFormattedMessage('app.report.aggregate_demographic.primary_area_work'), value:
        <PrimaryAreaOfWork
          report={type === 'group' ? [report.demographics.primaryAreaOfWork] : demographicsPrimaryAreaOfWork}
          subProgramName={titles}
          selectedPrimaryAreaOfWork={report.selectedPrimaryAreaOfWorkCodes}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.education_background')
    },
    {
      id: 16,
      name: GetFormattedMessage('app.report.aggregate_demographic.academic_rank'), value:
        <AcademicRank
          report={type === 'group' ? [report.demographics.academicRank] : demographicsAcademicRank}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.education_background')
    },
    // Larger World
    {
      id: 17,
      name: GetFormattedMessage('app.report.aggregate_demographic.time_spent_countries'), value:
        <TimeSpentInOtherCountries
          report={type === 'group' ? [report.demographics.timeSpentInOtherCountries] : demographicsTimeSpentInOtherCountries}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')
    },
    {
      id: 18,
      name: GetFormattedMessage('app.report.aggregate_demographic.visited_countries'), value:
        <VisitedCountries
          report={type === 'group' ? [report.demographics.visitedCountries] : demographicsVisitedCountries}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')
    },
    {
      id: 19,
      name: GetFormattedMessage('app.report.aggregate_demographic.personal_interest'), value:
        <Interest
          reportInterest={type === 'group' ? [report.demographics.interest] : demographicsInterest}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')
    },
    {
      id: 20,
      name: GetFormattedMessage('app.report.aggregate_demographic.study_abroad_satisfaction'), value:
        <Satisfaction
          reportSatisfaction={type === 'group' ? [report.demographics.satisfaction] : demographicsSatisfation}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')
    },
    {
      id: 21,
      name: GetFormattedMessage('app.report.aggregate_demographic.fluency'), value:
        <Fluency
          report={type === 'group' ? [report.demographics.fluency] : demographicsFluency}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')
    },
    {
      id: 22,
      name: GetFormattedMessage('app.report.aggregate_demographic.years_language_study'), value:
        <YearsOfLanguageStudy
          report={type === 'group' ? [report.demographics.yearsOfLanguageStudy] : demographicsYearsOfLanguageStudy}
          subProgramName={titles}
        />,
      group: GetFormattedMessage('app.report.aggregate_demographic.education_background')
    },
  ];
  const [demographicsItemId, setDemographicsItemId] = useState(1);
  const [selectDemographicsItemGroup, setSelectDemographicsItemGroup] = useState(GetFormattedMessage('app.report.aggregate_demographic.core_demographics'));
  const [selectDemographicsItem, setSelectDemographicsItem] = useState(GetFormattedMessage('app.report.aggregate_demographic.core_demographics') + ' - ' + GetFormattedMessage('app.report.background_statistic_item.age'));
  const [demographicsSectionItems] = useState<DemographicsItem[]>(demographicsItemYourself);

  return (
    <div>
      <Collapse onChange={(value) => {
        setSelectDemographicsItemGroup(_.last(value as string[])!);
      }} activeKey={selectDemographicsItemGroup}>
        {
          _.map(_.groupBy(demographicsSectionItems, x => x.group), (group, key) => {
            let buttons: React.ReactNode[] = [];

            _.forEach(group, (item) => {
              buttons.push(<Button
                type={demographicsItemId === item.id ? 'primary' : 'default'}
                size='small'
                onClick={() => {
                  setSelectDemographicsItem(item.group + ' - ' + item.name);
                  setDemographicsItemId(item.id);
                }}
                style={{ ...styles.button, ...styles.rightSpace8 }}>
                {item.name}
              </Button>);
            })

            switch (key) {
              case GetFormattedMessage('app.report.aggregate_demographic.core_demographics'):
                return <Collapse.Panel header={GetFormattedMessage('app.report.aggregate_demographic.core_demographics')} key={GetFormattedMessage('app.report.aggregate_demographic.core_demographics')} className="bd-callout bd-callout-nospace bd-callout-danger">
                  {buttons}
                </Collapse.Panel>
              case GetFormattedMessage('app.report.aggregate_demographic.parental_background'):
                return <Collapse.Panel header={GetFormattedMessage('app.report.aggregate_demographic.parental_background')} key="Parental Background" className="bd-callout bd-callout-nospace bd-callout-pink">
                  {buttons}
                </Collapse.Panel>
              case GetFormattedMessage('app.report.aggregate_demographic.education_background'):
                return <Collapse.Panel header={GetFormattedMessage('app.report.aggregate_demographic.education_background')} key="Education Background" className="bd-callout bd-callout-nospace bd-callout-soft-green">
                  {buttons}
                </Collapse.Panel>
              case GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences'):
                return <Collapse.Panel header={GetFormattedMessage('app.report.aggregate_demographic.international_cultural_experiences')} key="International and Cultural Experiences" className="bd-callout bd-callout-nospace bd-callout-green">
                  {buttons}
                </Collapse.Panel>
              case GetFormattedMessage('app.report.aggregate_demographic.political_religious_inclinations'):
                return <Collapse.Panel header={GetFormattedMessage('app.report.aggregate_demographic.political_religious_inclinations')} key="Political and Religious Inclinations" className="bd-callout bd-callout-nospace bd-callout-info">
                  {buttons}
                </Collapse.Panel>
              default:
                return buttons;
            }
          })
        }
      </Collapse>
      <h5 style={{ margin: '8px 0' }}>{selectDemographicsItem}</h5>
      <hr />
      {
        demographicsSectionItems.find(x => x.id === demographicsItemId)?.value
      }
    </div>
  )
}

const styles = {
  container: {
    flexDirection: 'column',
    padding: '8px',
  } as React.CSSProperties,
  buttonsContainer: {
    marginTop: '8px'
  } as React.CSSProperties,
  button: {
    verticalAlign: 'baseline',
  } as React.CSSProperties,
  radio: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  } as React.CSSProperties,
  rightSpace8: {
    marginRight: '8px',
    marginTop: '8px'
  } as React.CSSProperties,

}
