import { Divider } from 'antd';
import React, { Fragment, ReactNode } from 'react';
import { Image } from 'react-bootstrap';

import logo_bevi from '../../assets/images/bevi_logo.png';
import logoJapanese1 from '../../assets/images/logo-japanese-1.png';
import sokaLogoJapanese from '../../assets/images/soka-logo.jpg';
import { BeviFormattedLabel } from '../../components/BeviFormattedLabel';
import { LanguageSwitch } from '../../components/LanguageSwitch';

type Props = {
    component: ReactNode;
    isJapanese: boolean;
};

export const ExternalTemplate = ({ isJapanese, component }: Props): JSX.Element => {
    return (
        <Fragment>
            <header className='header shadow-md'>
                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <Image src={logo_bevi} className='header__logo' />
                        {isJapanese &&
                            <Fragment>
                                <Divider type={'vertical'} />
                                <Image src={logoJapanese1} className='header__logo' />
                                <Divider type={'vertical'} />
                                <Image src={sokaLogoJapanese} className='header__logo' />
                            </Fragment>
                        }
                    </div>
                    <div className='dropdown language-selector ml-auto'>
                        <LanguageSwitch />
                    </div>
                </div>
                <hr className='hr--gradient' />
            </header>
            <main>
                <div className='container-fluid'>
                    <main role='main' className='p-5;' style={styles.mainContainer}>
                        {component}
                    </main>
                </div>
            </main>
            <hr />
            <div style={styles.beviCopyrightDiv} className='pb-3'>
                <BeviFormattedLabel messageId={'app.bevi_copyright_text_1'} />
                <BeviFormattedLabel messageId={'app.bevi_copyright_text_2'} />
            </div>
        </Fragment>
    );
}

const styles = {
    beviCopyrightDiv: {
        textAlign: 'center',
    } as React.CSSProperties,
    mainContainer: {
        minHeight: '250px',
    } as React.CSSProperties,
}
