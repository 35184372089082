import { useActions, useValues } from 'kea';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';
import { appLogic, AppProps } from '../../redux/appLogic';
import { formLogic, FormsActions } from '../../redux/formsLogic';
import { logout } from '../../redux/globalActions';
import { InternalTemplate } from './InternalTemplate';

type Props = {
  component: ReactNode;
}

export const InternalTemplateContainer = ({ component }: Props): JSX.Element => {
    const { tokenInfo }: AppProps = useValues(appLogic);

    const { clearSelectedForm }: FormsActions = useActions(formLogic);
    const handleDashboardClick = () => {
        clearSelectedForm();
    }

    const dispatch = useDispatch();
    const handleLogoutClick = () => {
        dispatch(logout())
    }

    const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    return (
        <InternalTemplate
            userInfo={tokenInfo}
            component={component}
            isJapanese={isJapanese}
            onDashboardClicked={handleDashboardClick}
            onLogoutClicked={handleLogoutClick}
        />
    );
}
