import { AppMessages } from '../types';

export const appES: AppMessages = {
    'app.unknown_error': 'Ocurrió un error inesperado, por favor, contacte con la persona responsable.',
    'app.btn_start': 'Empezar',
    'app.btn_restart': 'Volver a empezar',
    'app.btn_cancel': 'Cancelar',
    'app.btn_next': 'Siguiente',
    'app.btn_ok': 'De acuerdo',
    'app.btn_confirm' : 'Confirmar',
    'app.btn_finish': 'Terminar',
    'app.btn_back': 'Volver',
    'app.dropdown_select': 'Seleccionar...',
    'app.loading': 'Cargando',
    'app.response_yes': 'Sí',
    'app.response_no': 'No',
    'app.bevi_copyright_text_1': 'El Test sobre Creencias, Vivencias y Valores o BEVI está sujeto a propiedad intelectual. Queda prohibida la modificación, copia, distribución o publicación, en todo o en parte, del BEVI, los informes BEVI, los ítems y su contenido, o escalas, sin autorización expresa por escrito del Dr. Craig N. Shealy.',
    'app.bevi_copyright_text_2': '',
    'app.empty.data': 'No existen datos',
    'app.systemerro': '¡Vaya! Estamos experimentando problemas de estabilidad, vuelva a intentarlo en unos minutos.',
    'app.loading_document': 'Cargando documento...',
    'app.download_document': 'Descargar PDF',
    'app.download_excel': 'Descargar XLSX',
    'app.btn_search': 'Buscar',
    'app.english': 'Inglés',
    'app.catalan': 'Catalana',
    'app.spanish': 'Español',
    'app.japanese': 'Japonés',
    'app.chinese-t': 'Chino tradicional',
    'app.chinese-s': 'Chino simplificado',
    'app.true': 'Verdadero',
    'app.false': 'Falso',
    'app.save': 'Guardar',
    'app.btn_reset': 'Resetear',
    'app.search_placeholder': 'Introducir texto de búsqueda',
    'app.notification.error_title': 'Error',
    'app.btn_submit': 'Enviar',
    'app.error.user_not_found': 'Usuario no encontrado',
    'app.save_with_success': 'Guardar con éxito'
}
