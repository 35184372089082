import { kea, keaReducer } from 'kea';
import PropTypes from 'prop-types';
import { call, put } from 'redux-saga/effects';

import { successfulCode, unauthorizedCode } from '../config/constants';
import { downloadGroupReport, getGroupReport, ReportLanguage } from '../services/reportService';
import { GroupReportItem } from '../services/types';
import { arrayToObject, asLiterals } from '../utils/helpers';
import { ValidateToken } from '../utils/validateHelper';
import { appLogic, appPropsConstants } from './appLogic';
import { logout, notAuthorized, serviceUnavailable } from './globalActions';

// actions
const actionsLiterals = asLiterals(['getGroupReport', 'downloadGroupReport']);
export type GroupReportActions = { [K in (typeof actionsLiterals)[number]]: any };
export const groupReportConstants: GroupReportActions = { ...arrayToObject(actionsLiterals) };

const privateActionsLiterals = asLiterals([
    'setError', 'setReport', 'setSuccessfulDownload', 'setDataDownloadError'
]);
type PrivateActions = { [K in (typeof privateActionsLiterals)[number]]: any };
const privateActionsConstants: PrivateActions = { ...arrayToObject(privateActionsLiterals) };

// props
const reducersLiterals = asLiterals(['loading', 'error', 'report', 'loadingDataDownload']);
export type GroupReportProps = { [K in (typeof reducersLiterals)[number]]?: any };
export const groupReportPropsConstants: GroupReportProps = { ...arrayToObject(reducersLiterals) };

// type reducers parameter
type ReducersParam = { actions: GroupReportActions & PrivateActions; };

type WorkersParam = {
    workers: {
        getGroupReport: () => void;
        fetchDownloadGroupReport: () => void;
    }
};

// Define payloads type.
type ErrorPayload = { error: string; };
type GroupReportPayload = { reportId: number };

type ReportPayload = { data: GroupReportItem };
type GroupReportRequest = { payload: GroupReportPayload };

type DownloadGroupReportRequestPayload = { language: ReportLanguage, reportId: number };
type DownloadGroupReportRequestPayloadRequest = { payload: DownloadGroupReportRequestPayload };

// persist app reducer
export const groupReportPath = 'groupReport';

export const groupReportReducer = keaReducer(groupReportPath);

export const groupReportLogic = kea({
    connect: {
        props: [appLogic, [appPropsConstants.token]],
    },

    path: () => [groupReportPath],

    actions: () => ({
        [groupReportConstants.getGroupReport]:
            (reportId: number): GroupReportPayload => ({ reportId }),
        [groupReportConstants.downloadGroupReport]:
            (language: ReportLanguage, reportId: number): DownloadGroupReportRequestPayload => ({ language, reportId }),
        [privateActionsConstants.setReport]:
            (data: GroupReportItem): ReportPayload => ({ data }),
        [privateActionsConstants.setError]:
            (error: string): ErrorPayload => ({ error }),
        [privateActionsConstants.setSuccessfulDownload]: () => true,
        [privateActionsConstants.setDataDownloadError]:
            (error: string): ErrorPayload => ({ error }),
    }),

    reducers: ({ actions }: ReducersParam) => ({
        [groupReportPropsConstants.loading]: [true, PropTypes.bool, {
            [actions.getGroupReport]: () => true,
            [actions.setError]: () => false,
            [actions.setReport]: () => false,
            [logout]: () => false
        }],
        [groupReportPropsConstants.error]: [null, PropTypes.string, {
            [actions.getGroupReport]: () => null,
            [actions.setError]: (_: string, payload: ErrorPayload) => payload.error,
            [logout]: () => null
        }],
        [groupReportPropsConstants.report]: [null, PropTypes.any, {
            [actions.setReport]: (_: GroupReportItem, payload: GroupReportItem) => payload,
            [actions.getGroupReport]: () => null,
            [logout]: () => null
        }],
        [groupReportPropsConstants.loadingDataDownload]: [false, PropTypes.bool, {
            [actions.downloadGroupReport]: () => true,
            [actions.setSuccessfulDownload]: () => false,
            [actions.setDataDownloadError]: () => false,
            [logout]: () => false
        }],
    }),

    takeLatest: ({ actions, workers }: ReducersParam & WorkersParam) => ({
        [actions.getGroupReport]: workers.getGroupReport,
        [actions.downloadGroupReport]: workers.fetchDownloadGroupReport,
    }),

    workers: {
        * getGroupReport(action: GroupReportRequest): any {
            const { reportId } = action.payload;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            //@ts-ignore
            const { setError, setReport } = this.actionCreators;
            const getGroupReportResponse = yield call(getGroupReport, token, reportId);

            if (getGroupReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (getGroupReportResponse?.status === successfulCode) {
                yield put(setReport(getGroupReportResponse?.body))
            } else if (!!getGroupReportResponse?.keyErrorMessage) {
                yield put(setError(getGroupReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        },

        * fetchDownloadGroupReport(action: DownloadGroupReportRequestPayloadRequest): any {
            //@ts-ignore
            const { setDataDownloadError, setSuccessfulDownload } = this.actionCreators;
            //@ts-ignore
            const token = yield this.get(appPropsConstants.token);
            if (!ValidateToken(token)) {
                yield put(logout());
                return;
            }

            const { language, reportId } = action.payload;
            const downloadIndividualReportResponse = yield call(
                downloadGroupReport,
                token,
                { reportId, language }
            );

            if (downloadIndividualReportResponse?.status === unauthorizedCode) {
                yield put(notAuthorized());
            } else if (downloadIndividualReportResponse?.status === successfulCode) {
                yield put(setSuccessfulDownload());
            } else if (!!downloadIndividualReportResponse?.keyErrorMessage) {
                yield put(setDataDownloadError(downloadIndividualReportResponse.keyErrorMessage));
            } else {
                yield put(serviceUnavailable());
            }
        }
    }
});
