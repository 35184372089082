import { CreateProgramMessages } from '../types';

export const createProgramDE: CreateProgramMessages = {
    'app.create_program.btn_submit': 'Speichern',
    'app.create_program.btn_submit_and_send_email': 'E-Mail speichern/senden',
    'app.create_program.btn_unselect_all_users': 'Alles entfernen',
    'app.create_program.select_individual_manually': 'Personen manuell auswählen',
    'app.create_program.table.column_name': 'Name',
    'app.create_program.table.column_email': 'E-Mail',
    'app.create_program.table.column_actions': 'Personen entfernen',
    'app.create_program.tooltip.action_remove': 'Entfernen',
    'app.create_program.tooltip.btn_get_last_users': 'Personen aus der vorherigen Verwaltung importieren.',
    'app.create_program.tooltip.btn_download_users': 'Downloaden / Verwalten, wer das BEVI macht.',
    'app.create_program.tooltip.btn_download_users_empty': 'Downloaden / Verwalten, wer das BEVI macht(Leere Vorlage).',
    'app.create_program.excel.name_column': 'Name',
    'app.create_program.excel.login_column': 'E-Mail',
    'app.create_program.excel.active_column': 'Aktiv',
    'app.create_program.confirmation_title_success': 'Erfolgreich erstellt',
    'app.create_program.confirmation_body_success_program': 'Das Programm oder die Erfahrung wurde nun erstellt. Zum Überprüfen oder Verwalten klicke unten auf "ok".',
    'app.create_program.confirmation_body_success_subprogram': 'BEVI-Administration wurde erstellt und benannt. Zum Überprüfen oder Verwalten klicke unten auf "ok" .',
    'app.create_program.confirmation_title_fail': 'Ups! Leider konnten wir es nicht erstellen',
    'app.create_program.confirmation_body_fail_program': 'Das Programm konnte nicht erstellt werden. Bitte überprüfen Sie die angegebenen Daten und die Internetverbindung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihre/n Betreuer:in.',
    'app.create_program.confirmation_body_fail_subprogram': 'Das Unterprogramm konnte nicht erstellt werden. Überprüfen Sie die angegebenen Daten und die Internetverbindung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihre/n Betreuer:in.',
    'app.edit_sub_program.confirmation_title_success': 'Erfolgreich aktualisiert',
    'app.edit_sub_program.confirmation_body_success': 'Die Gruppe wurde aktualisiert und kann nun in der Liste der Programme der Organisation eingesehen werden, wohin Sie weitergeleitet werden.',
    'app.edit_sub_program.confirmation_title_fail': 'Ups! Leider ist die Aktualisierung fehlgeschlagen.',
    'app.edit_sub_program.confirmation_body_fail': 'Das Unterprogramm konnte nicht aktualisiert werden. Bitte überprüfen Sie die angegebenen Daten und die Internetverbindung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihre/n Betreuer:in.',
    'app.create_program.lbl_associate_users': 'Personen auswählen, die am BEVI teilnehmen sollen.',
    'app.create_program.lbl_organization_name': 'Name der Organisation',
    'app.create_program.lbl_program_name': 'Name des Programms oder der Erfahrung',
    'app.create_program.lbl_sub_program_name': 'Name der BEVI-Administration',
    'app.create_program.lbl_year': 'Jahr',
    'app.create_program.lbl_due_date': 'Endtermin für BEVI-Administration',
    'app.create_program.lbl_alert_all_users': 'Alle Personen per E-Mail benachrichtigen',
    'app.details_sub_program.progress': 'Gruppenfortschritt',
    'app.details_sub_program.participant_details': 'Teilnehmer:innendetails',
    'app.create_program.lbl_email_message': 'Geben Sie unten eine Nachricht ein, die an die E-Mail-Adressen aller Teilnehmer:innen gesendet werden soll.',
    'app.create_program.tooltip.btn_upload_users': 'Hochladen, wer an BEVI teilnehmen soll.',
    'app.create_program.upload_files_error_title': 'Fehler beim Hochladen der Datei',
    'app.create_program.upload_rejected_files_error_body_prefix': 'Folgende Dateien wurden abgelehnt, da sie nicht das erforderliche Format haben:',
    'app.create_program.upload_parsed_files_error_body_prefix': 'Die Datei sollte die Spalten {0}, {1} und {2} enthält. Folgende Dateien wurden nicht korrekt erkannt:',
    'app.create_program.upload_parsed_files_error_body': '{0} hat {1} Fehler.',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'Fehler beim Versuch, folgende Dateien zu analysieren:',
    'app.create_program.select_individuals_title': 'Wähle Personen aus, die am BEVI wie folgt teilnehmen sollen:',
    'app.create_program.import_individuals_from_previous_administration': '1.  Importieren von Personen aus einer früheren Verwaltung, oder',
    'app.create_program.download_manage_who_will_take_the_bevi': '2.  Downloaden / Verwalten der Personen, die am BEVI teilnehmen werden',
    'app.create_program.upload_who_will_take_the_bevi': ', und dann laden Sie hoch, wer am BEVI teilnehmen soll, oder',
    'app.create_program.add_individuals_manually': '3.  Personen manuell / einzeln aus dem Dropdown-Menü hinzufügen.',
    //Without translation
    'app.create_program.table.column_get_last_users': 'Individuals from previous administration.',
    'app.details_sub_program.remind_alert': 'Remind Alert',
    'app.details_sub_program.remind_alert_text': 'A reminder alert was scheduled and it will be sent in a few minutes.',
}
