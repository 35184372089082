import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { SelectItem } from '../../../../components/FormDropdown';
import { UsersMessages } from '../../../../locales/types';
import { appLogic, AppProps } from '../../../../redux/appLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../../../redux/listOrganizationLogic';
import { UsersActions, usersLogic, UsersProps } from '../../../../redux/usersLogic';
import {
    OrganizationWithProgramsModel, ProgramModel
} from '../../../../services/organizationService';
import { CreateNewUserPayloadRequest, SaveUserStatus } from '../../../../services/usersService';
import { SingleUser } from './SingleUser';

type Props = RouteComponentProps & {
    loading: boolean;
};

export const SingleUserContainer = withRouter(({ history, loading }: Props): JSX.Element => {

    const { organizationsWithPrograms }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { loadOrganizationWithProgram }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { loadingCreateNewUser, errorMessageNewUser, saveUserStatus }: UsersProps = useValues(usersLogic);
    const { createNewUser }: UsersActions = useActions(usersLogic);
    const { tokenInfo }: AppProps = useValues(appLogic);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    // Effect to react to createNewUser response.
    useEffect(() => {
        if (saveUserStatus !== null) {
            setShowConfirmation(true)
        }
    }, [saveUserStatus]);

    useEffect(() => {
        loadOrganizationWithProgram();
    }, [loadOrganizationWithProgram]);

    const programSelectOptions: Map<number, SelectItem[]> = new Map<number, SelectItem[]>();
    const organizationSelectOptions: Array<SelectItem> =
        organizationsWithPrograms?.map(
            (org: OrganizationWithProgramsModel) => {
                const programs: SelectItem[] = [];
                org.programs.forEach((prog: ProgramModel) =>
                    programs.push({ value: prog.id, label: prog.name }))
                programSelectOptions.set(org.id, programs);
                return ({ value: org.id, label: org.name })
            }) ?? [];

    const onClickSubmit = (
        organizationIds: number[], programIds: number[], name: string, login: string,
        language: number | undefined, userType: number | undefined, anonymous: number | undefined,
        sendEmail: boolean, permissions?: number[]
    ) => {
        createNewUser({
            organizationIds,
            programIds,
            name,
            login,
            language,
            userType,
            isAnonymous: anonymous,
            sendEmail,
            permissions
        } as CreateNewUserPayloadRequest);
    }

    const handleConfirmationOkClick = (): void => {
        if (errorMessageNewUser || saveUserStatus === SaveUserStatus.AlreadyExists || saveUserStatus === SaveUserStatus.WrongEmail) {
            setShowConfirmation(false);
        } else {
            history.goBack();
        }
    }

    const prepareConfirmationBody = (): Array<keyof(UsersMessages)> => {
        if (errorMessageNewUser) return ['app.create.user.confirmation_body_fail'];

        if (saveUserStatus === SaveUserStatus.AlreadyExists) return ['app.create.user.confirmation_body_fail_already_exists'];
        if (saveUserStatus === SaveUserStatus.WrongEmail) return ['app.create.user.confirmation_body_fail_wrong_email'];
        return ['app.create.user.confirmation_body_success'];
    }

    const prepareConfirmationTitle = (): keyof(UsersMessages) => {
        if (errorMessageNewUser || saveUserStatus === SaveUserStatus.AlreadyExists || saveUserStatus === SaveUserStatus.WrongEmail) {
            return 'app.create.user.confirmation_title_fail'
        }
        return 'app.create.user.confirmation_title_success';
    }

    return (
        <React.Fragment>
            <SingleUser
                userInfo={tokenInfo}
                organizations={organizationSelectOptions}
                programs={programSelectOptions}
                onClickSubmit={onClickSubmit}
                loading={loading || loadingCreateNewUser}
                onBackButtonClicked={history.goBack}
            />
            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={prepareConfirmationTitle()}
                bodyFormattedListMessageId={prepareConfirmationBody()}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={handleConfirmationOkClick}
                onPrimaryButtonClicked={handleConfirmationOkClick}
            />
        </React.Fragment>
    );
});
