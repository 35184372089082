import { ExternalPageMessages } from '../types';

export const externalES: ExternalPageMessages = {
    'app.external.title.thankyou': 'Gracias por completar el BEVI.',
    'app.external.thankyou_static_1_1': 'El Test de Creencias, Vivencias y Valores o BEVI y este informe BEVI están sujetos a propiedad intelectual. ' +
        'Queda prohibida la modificación, copia, distribución o publicación, en todo o en parte, del BEVI, los informes BEVI, los ítems y su contenido, o escalas, ' +
        'sin autorización expresa por escrito del Dr. Craig N. Shealy.',
    'app.external.thankyou_static_1_2': '',
    'app.external.thankyou_static_2': 'Al igual que el test BEVI íntegramente, la estructura y diseño de estos informes están sujetos ' +
        'a un proceso constante de revisión y desarrollo. Si tiene alguna sugerencia o comentario sobre cualquier aspecto del BEVI o de este ' +
        'informe, no dude en contactar con nosotros directamente a través de info@ibavi.org',
    'app.external.thankyou_static_3': 'Para más información sobre creencias y valores puede visitar el Instituto Internacional de Creencias ' +
        'y Valores (IBAVI) a través de www.ibavi.org y www.thebevi.com',
}
