import { create } from 'zustand';
import { serviceUrl, successfulCode } from '../config/constants';
import { fetchPostMethod } from '../rpc/api';

interface State {
    changeAiInteractions: (formIds: number[], aiInteractions: number, token: string) => void;
    aiInteractionsLoading: number[];
    aiInteractionsSuccessful: boolean;
    callAfterSuccess: boolean;
    aiInteractionsError: boolean;
}

export const useProgramStore = create<State>((set) => ({
    aiInteractionsLoading: [],
    aiInteractionsSuccessful: false,
    callAfterSuccess: false,
    aiInteractionsError: false,
    changeAiInteractions: async (formIds, aiInteractions, token) => {
        set((state) => ({ aiInteractionsLoading: [...state.aiInteractionsLoading, ...formIds] }));
        try {
            const body = { formIds, aiInteractions };
            const response = await fetchPostMethod(`${serviceUrl}/api/program/updateAiInteractions`, token, body);

            set((state) => ({ aiInteractionsLoading: [...state.aiInteractionsLoading.filter(x => !formIds.includes(x))] }));
            if (response.status === successfulCode) {
                if(formIds.length > 1) {
                    set({ callAfterSuccess: true });   
                    setTimeout(() => {
                        set({ callAfterSuccess: false });
                    }, 1); 
                }
                set({ aiInteractionsSuccessful: true });
                setTimeout(() => {
                    set({ aiInteractionsSuccessful: false });
                }, 1);
            } else {
                set({ aiInteractionsError: true });
                setTimeout(() => {
                    set({ aiInteractionsError: false });
                }, 1);
            }
        } catch (error: any) {
            set((state) => ({ error: 'app.institutions.add.error_message', aiInteractionsLoading: state.aiInteractionsLoading.filter(x => !formIds.includes(x)) }));
            setTimeout(() => {
                set({ aiInteractionsError: false });
            }, 1);
        }
    }
}))