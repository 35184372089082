import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import { ButtonWithIcon } from '../../../../components/ButtonWithIcon';
import { FormDropdown, SelectItem } from '../../../../components/FormDropdown';
import { colors } from '../../../../config/colors';
import { GetFormattedMessage } from '../../../../utils/htmlHelper';

type Props = {
    searchData: { organizationId?: number, programQuery: string, subProgramQuery: string };
    organizations: SelectItem[];
    searchHandleClick: (organizationId: number, programQuery: string, subProgramQuery: string) => void;
};

export const FilterProgram = ({ searchData, organizations, searchHandleClick }: Props): JSX.Element => {

    const [organizationId, setOrganization] = useState<number | undefined>(
        searchData.organizationId !== undefined
            ? searchData.organizationId
            : organizations && organizations.length === 1 ? organizations[0].value : undefined
    );
    const [programQuery, setProgramQuery] = useState<string>(searchData.programQuery);
    const [subProgramQuery, setSubProgramQuery] = useState<string>(searchData.subProgramQuery);

    const selectedOrganization = organizations.find((item: SelectItem) => item.value === organizationId);

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <div style={styles.h2ProgramTitle}>
                    <h2>
                        {organizations && organizations.length === 1 ?
                            organizations[0].label :
                            GetFormattedMessage('app.listorganizations.organization_area')}
                    </h2>
                </div>
                <hr />
                <div style={styles.divSearchItems}>
                    <Row>
                        {
                            organizations && organizations.length !== 1 &&
                            <Col md={4}>
                                <FormDropdown
                                    isSearchable={true}
                                    defaultOptionId={'app.listorganizations.select_organization'}
                                    onValueSelect={(value: number | undefined) => {
                                        setOrganization(value);
                                    }}
                                    value={selectedOrganization}
                                    optionItens={organizations}
                                />
                            </Col>
                        }

                        <Col md={organizations && organizations.length !== 1 ? 4 : 6}>
                            <Form.Control
                                value={programQuery}
                                as='input'
                                placeholder={GetFormattedMessage('app.listorganizations.input_program_placeholder')}
                                onChange={(x: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => { setProgramQuery(x.currentTarget.value); }}
                            />
                        </Col>
                        <Col md={organizations && organizations.length !== 1 ? 4 : 6}>
                            <Form.Control
                                value={subProgramQuery}
                                as='input'
                                placeholder={GetFormattedMessage('app.listorganizations.input_subprogram_placeholder')}
                                onChange={(x: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => { setSubProgramQuery(x.currentTarget.value); }}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={styles.buttonSearchDiv}>
                    <ButtonWithIcon
                        disabled={organizationId === undefined}
                        textId={'app.listorganizations.search'}
                        onClick={() => { searchHandleClick(Number(organizationId), programQuery, subProgramQuery) }}
                        icon={<FaSearch />}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '20px',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    h2ProgramTitle: {
        textAlign: 'center',
    } as React.CSSProperties,
    buttonSearchDiv: {
        marginTop: '10px',
        textAlign: 'right',
        paddingRight: '20px',
        width: '100%'
    } as React.CSSProperties,
    divSearchItems: {
        flex: 1,
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingRight: '20px',
    } as React.CSSProperties,
};
