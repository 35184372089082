import '../../style/individualReport.css';

import React from 'react';
import { Image as Img } from 'react-bootstrap';

import logo_bevi from '../../assets/images/bevi_logo.png';
import { colors } from '../../config/colors';
import { AggregateProfile } from '../GroupReport/components/AggregateProfile';
import { ReportProps } from './ReportTypes';
import { DomainScores } from '../GroupReport/components/DomainScores';
import { LocalStorage_BeviLanguage } from '../../IntlProviderWrapper';
import { LongitudinalAggregateProfile } from '../../components/report/LongitudinalAggregateProfile';
import { isEmpty } from 'lodash';

export const ReportView = (props: ReportProps): JSX.Element => {
  const isJapanese: boolean = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";
  return (
    <div style={styles.divBackground} className='background'>
      <div style={styles.divCard} className='card'>
        <div style={styles.container}>
          <div style={styles.alignCenter}>
            <Img src={logo_bevi} className='report-logo' />
            {props.numberOfReports !== 1 && props.hasAnyModification &&
              <>
                <div style={{ textAlign: 'center' }}>
                  <ReportTitle message={props.title.reportResultTitle} />
                  <br />
                </div>
                <div className='report-texts'>
                  <h4 style={styles.title} className='mb-4'>{props.title.reportResultDescription} <a href='https://thebevi.com/contact/'>https://thebevi.com/contact/</a>.</h4>
                </div>
              </>
            }
          </div>

          <React.Fragment>
            {!props.hasAnyModification && props.numberOfReports !== 1 ? <>
              <div style={{ textAlign: 'center' }}>
                <ReportTitle message={props.title.reportResultTitle} />
                <br />
              </div>
              <div className='report-texts'>
                <h4 style={styles.title} className='mb-4'>{props.title.reportResultDescriptionNoChange} <a href='https://thebevi.com/contact/'>https://thebevi.com/contact/</a>.</h4>
              </div>
            </> : <>
              <SectionTitle message={props.title.introduction} />
              <div className='report-texts'>
                {props.introduction.map((item, index) =>
                  item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`introduction-${index}`} />
                )}
              </div>
            </>
            }
            {props.foundation.length > 0 &&
              <>
                <SectionTitle message={props.title.foundation} />
                <div className='report-texts'>
                  {props.foundation.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`foundation-${index}`} />
                  )}
                </div>
              </>
            }

            {props.toleranceOfDisequilibrium.length > 0 &&
              <>
                <SectionTitle message={props.title.tolerance_of_desequilibrium} />
                <div className='report-texts'>
                  {props.toleranceOfDisequilibrium.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`toleranceOfDisequilibrium-${index}`} />
                  )}
                </div>
              </>
            }

            {props.whyWeDo.length > 0 &&
              <>
                <SectionTitle message={props.title.making_sense} />
                <div className='report-texts'>
                  {props.whyWeDo.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`whyWeDo-${index}`} />
                  )}
                </div>
              </>
            }

            {props.accessSelf.length > 0 &&
              <>
                <SectionTitle message={props.title.access_to_yourself} />
                <div className='report-texts'>
                  {props.accessSelf.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessSelf-${index}`} />
                  )}
                </div>
              </>
            }

            {props.accessToTheThoughts.length > 0 &&
              <>
                <SectionTitle message={props.title.access_to_the_thoughts} />
                <div className='report-texts'>
                  {props.accessToTheThoughts.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessSelf-${index}`} />
                  )}
                </div>
              </>
            }

            {props.accessToTheLargerWorld.length > 0 &&
              <>
                <SectionTitle message={props.title.access_to_the_larger_world} />
                <div className='report-texts'>
                  {props.accessToTheLargerWorld.map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessToTheLargerWorld-${index}`} />
                  )}
                </div>
              </>
            }

            {
              (props.backgroundStatistics !== undefined && isJapanese) &&
              props.backgroundStatistics.map((backgroundStatistic) =>
                <React.Fragment>
                  {backgroundStatistic.reportTitle != null && <div className="mb-4" dangerouslySetInnerHTML={{ __html: backgroundStatistic.reportTitle }} />}
                  <SectionTitle message={props.title.domain_scores} />
                  <DomainScores title={props.title.domain_scores} domainScores={backgroundStatistic.value} />
                </React.Fragment>)
            }

            {props.profiles.length > 0 && <React.Fragment>
              <SectionTitle message={props.title.profile} />
              {props.profiles.length === 1 ? <AggregateProfile title={props.title.profile} aggregateProfile={props.profiles[0].value} /> :
                <LongitudinalAggregateProfile subProgramName={props.profiles.map(x => x.reportTitle ?? '')}
                  aggregateProfile={props.profiles.map(x => x.value)} />}
            </React.Fragment>}

            <SectionTitle message={props.title.conclusion} />
            <div className='report-texts'>
              <div className="mb-4" dangerouslySetInnerHTML={{ __html: props.conclusion }}></div>
            </div>

            <SectionTitle message={props.title.thankYou} />
            <div className='report-texts'>
              {props.thankYou.map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`thankYou-${index}`} />
              )}
            </div>
            {
              props.demographicsSections !== undefined &&
              props.demographicsSections.map((demographicsSection) => {
                return <React.Fragment>
                  <SectionTitle message={'Demographics - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                  <h2>{demographicsSection.reportTitle}</h2>
                  <div className='report-texts' style={styles.questionRowOddFirstLine}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.age.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.age.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.gender.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.gender.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.yearsOfFormalEducation.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.yearsOfFormalEducation.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.relationshipStatus.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.relationshipStatus.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.ethnicBackground.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.ethnicBackground.value }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.ethnicBackground.other }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.motherCountry.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.motherCountry.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.fatherCountry.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.fatherCountry.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.minorityGroup.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.minorityGroup.value }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.minorityGroup.other }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.primaryCountry.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.primaryCountry.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.timeSpentInAnotherCountry.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.timeSpentInAnotherCountry.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.visitedCountries.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.visitedCountries.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.politicalOrientation.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.politicalOrientation.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.levelPoliticalOrientation.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.levelPoliticalOrientation.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.motherEducation.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.motherEducation.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.fatherEducation.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.fatherEducation.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.primaryAreaOfWork.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.primaryAreaOfWork.value }}></div>
                    {
                      demographicsSection.value.primaryAreaOfWork.others.map((item, index) => {
                        return <div key={`PrimaryAreaOfWork-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.primaryLanguage.title }}></div>
                    {
                      demographicsSection.value.primaryLanguage.others.map((item, index) => {
                        return <div key={`PrimaryLanguage-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.otherFluentLanguages.title }}></div>
                    {
                      demographicsSection.value.otherFluentLanguages.others.map((item, index) => {
                        return <div key={`OtherPrimaryLanguages-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.otherLanguages.title }}></div>
                    {
                      demographicsSection.value.otherLanguages.others.map((item, index) => {
                        return <div key={`OtherLanguages-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.yearOfForeignLanguages.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.yearOfForeignLanguages.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.income.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.income.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.academicRank.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.academicRank.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.commitmentsReligion.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.commitmentsReligion.value }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.commitmentsReligion.other }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.levelCommitmentsReligion.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.levelCommitmentsReligion.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.personalInterest.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.personalInterest.value }}></div>
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.currentlyParticipating.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.currentlyParticipating.value }}></div>
                  </div>

                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.previouslyCompletedExperiences.title }}></div>
                    {
                      demographicsSection.value.previouslyCompletedExperiences.value.map((item, index) => {
                        return <div key={`PreviouslyCompletedExperiences-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.completedExperiences.title }}></div>
                    {
                      demographicsSection.value.completedExperiences.value.map((item, index) => {
                        return <div key={`CompletedExperiences-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowOdd}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.previouslyCompletedLearningCoursesPrograms.title }}></div>
                    {
                      demographicsSection.value.previouslyCompletedLearningCoursesPrograms.value.map((item, index) => {
                        return <div key={`PreviouslyCompletedLearningCoursesPrograms-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                  <div className='report-texts' style={styles.questionRowEven}>
                    <div dangerouslySetInnerHTML={{ __html: demographicsSection.value.completedLearningCoursesPrograms.title }}></div>
                    {
                      demographicsSection.value.completedLearningCoursesPrograms.value.map((item, index) => {
                        return <div key={`CompletedLearningCoursesPrograms-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                      })
                    }
                  </div>
                </React.Fragment>
              })
            }
            {
              !isEmpty(props.scalesStronglyAgree) &&
              <React.Fragment>
                <SectionTitle message={'Question Strongly Agree - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                {props.scalesStronglyAgree && props.scalesStronglyAgree.map((scalesStronglyAgree, index) => {
                  return <>
                    <h2>{scalesStronglyAgree.reportTitle}</h2>
                    <div className='report-texts' style={styles.questionRowEven}>
                      {
                        scalesStronglyAgree.value?.map((item, index) => {
                          return <div key={`ScalesStronglyAgree-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                        })
                      }
                    </div>
                  </>;
                })}

              </React.Fragment>
            }
            {
              !isEmpty(props.scalesStronglyDisagree) &&
              <React.Fragment>
                <SectionTitle message={'Question Strongly Disagree - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                {props.scalesStronglyDisagree && props.scalesStronglyDisagree.map((scalesStronglyDisagree, index) => {
                  return <>
                    <h2>{scalesStronglyDisagree.reportTitle}</h2>
                    <div className='report-texts' style={styles.questionRowEven}>
                      {
                        scalesStronglyDisagree.value?.map((item, index) => {
                          return <div key={`ScalesStronglyAgree-${index}`} dangerouslySetInnerHTML={{ __html: item }}></div>
                        })
                      }
                    </div>
                  </>;
                })}
              </React.Fragment>
            }
            {
              <React.Fragment>
                {
                  props.discusiveAnswers.map((discusiveAnswers) => <>
                    <SectionTitle message={'Discursive Questions - Accessible Only By Super Administrators Or Their Authorized Designees.'} />
                    <h2>{discusiveAnswers.reportTitle}</h2>
                    {
                      discusiveAnswers.value.map((answer, i) =>
                        <div className='report-texts' style={i % 2 === 0 ? styles.questionRowEven : styles.questionRowOddFirstLine}>
                          <div dangerouslySetInnerHTML={{ __html: answer.title }}></div>
                          <div dangerouslySetInnerHTML={{ __html: answer.value }}></div>
                        </div>)
                    }
                  </>)
                }
              </React.Fragment>
            }
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

type TitleProps = {
  message: string;
}

const SectionTitle = (props: TitleProps): JSX.Element => (
  <div style={styles.paddingTop10}>
    <hr />
    <div className='report-texts'>
      <h4 style={styles.title} className='report-h4'>{props.message}</h4>
    </div>
  </div>
)

const ReportTitle = (props: TitleProps): JSX.Element => (
  <div style={styles.beviResultTitle}>
    <hr />
    <div className='report-texts'>
      <h4 style={styles.titleBeviResults} className='report-h1'>{props.message}</h4>
    </div>
  </div>
)

const styles = {
  container: {
    padding: 5
  } as React.CSSProperties,
  divBackground: {
    justifyContent: 'center',
    display: 'flex',
  } as React.CSSProperties,
  divCard: {
    width: '100%',
    marginBottom: '10%',
    background: colors.white,
    borderRadius: '15px',
    padding: '16px',
  } as React.CSSProperties,
  alignCenter: {
    textAlign: 'center',
  } as React.CSSProperties,
  title: {
    color: colors.darkSlateBlue,
    textAlign: 'start',
  } as React.CSSProperties,
  titleBeviResults: {
    color: colors.darkSlateBlue,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    fontSize: '30px'
  } as React.CSSProperties,
  paddingTop10: {
    paddingTop: 10
  } as React.CSSProperties,
  beviResultTitle: {
    paddingTop: 10
  } as React.CSSProperties,
  questionRowOdd: {
    padding: '16px',
    backgroundColor: '#cecece50',
    borderRadius: '16px',
  } as React.CSSProperties,
  questionRowOddFirstLine: {
    padding: '16px',
    backgroundColor: '#cecece50',
    borderRadius: '16px',
    marginTop: '10px'
  } as React.CSSProperties,
  questionRowEven: {
    padding: '16px',
    backgroundColor: colors.white,
    borderRadius: '16px',
  } as React.CSSProperties,
}
