import React from 'react';
import { Col, Form } from 'react-bootstrap';

import { FormDropdown } from '../../../components/FormDropdown';
import { DemographicQuestionAnswers } from '../../../services/types';
import { ColSpec } from '../../../utils/formHelper';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { ReligionScale } from '../library/questionDefinitions';

type Props = Partial<typeof Form.Group> & { optionsMd?: ColSpec } & {
    question: ReligionScale;
    answerChange: (key: keyof(DemographicQuestionAnswers), value: number | string | undefined) => void;
    questionNumber: number;
    style: React.CSSProperties;
};

export const ReligionScaleQuestion = (
    { question, answerChange, questionNumber, style, optionsMd, ...groupProps }: Props
): JSX.Element => {
    const responseLabel = question.GetResponseLabel();
    const label = responseLabel === undefined ?
        GetFormattedMessage(question.GetQuestionLabelId())
        :
        GetFormattedMessage(question.GetQuestionLabelId()).replace('{0}', responseLabel);
    return (
        <Form.Group className='mb-3' {...groupProps} style={style}>
            <Form.Label>
                <div style={styles.divQuestionWithNumber}>
                    <p style={styles.numericLabel}>{questionNumber}. </p>
                    { label }
                </div>
            </Form.Label>
            <br />
            <Col md={optionsMd}>
                <FormDropdown
                    defaultOptionId={'app.dropdown_select'}
                    optionItens={question.GetDefintions()}
                    onValueSelect={(value: number | undefined, _: string) => {
                        answerChange(question.GetQuestionKeyName(), value);
                    }}
                />
            </Col>
        </Form.Group>
    );
}

const styles = {
    divQuestionWithNumber: {
        display: 'flex',
    } as React.CSSProperties,
    numericLabel: {
        paddingRight: '5px'
    } as React.CSSProperties,
};
