import { Steps } from 'antd';
import React, { Fragment, ReactNode } from 'react';
import { H2 } from '../../components/styled/h2';
import { H5 } from '../../components/styled/h5';
import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
    isIndividualReport: boolean,
    currentStep: number,
    children: {
        Step1: ReactNode;
        Step2?: ReactNode;
        Step3?: ReactNode;
        Step4?: ReactNode;
    },
    shouldEnableGenerateButton: boolean;
    generating: boolean;
    searching: boolean;
    onSearchClicked: () => void;
    onGenerateReportButtonClick: () => void;
};

const { Step } = Steps;

export const CreateReport = (
    { currentStep, children, isIndividualReport, shouldEnableGenerateButton, generating, searching, onSearchClicked, onGenerateReportButtonClick }: Props
): JSX.Element => {

    const { Step1, Step2, Step3, Step4 } = children;

    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <H2 textAlign='center' style={styles.title}>
                    <div dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.create_report.title') }} />
                </H2>

                <Steps style={{ paddingBottom: 20 }} current={currentStep}>
                    <Step key={1} />
                    <Step key={2} />

                    <Step key={3} />
                    {!isIndividualReport && <>
                        <Step key={4} />
                    </>}
                </Steps>
                {currentStep === 0 && <Fragment>
                    <H5>{GetFormattedMessage('app.create_report.step1')}</H5>
                    {Step1}
                </Fragment>}
                {currentStep === 1 && <Fragment>
                    <H5>{GetFormattedMessage('app.create_report.step2')}</H5>
                    {Step2}
                </Fragment>}
                {currentStep === 2 && <Fragment>
                    <hr style={styles.hrSpace} />
                    <H5>{GetFormattedMessage(!isIndividualReport ? 'app.create_report.step3' : 'app.create_report.step3Individual')}</H5>
                    {Step3}
                </Fragment>}
                {!isIndividualReport && <>
                    {currentStep === 3 && <React.Fragment>
                        <hr style={styles.hrSpace} />
                        <H5>{GetFormattedMessage('app.create_report.step4')}</H5>
                        {Step4}
                    </React.Fragment>}
                </>}
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    title: {
        paddingTop: '15px',
        paddingBottom: '20px',
    } as React.CSSProperties,
    hrSpace: {
        marginTop: '50px',
    } as React.CSSProperties,
    buttonDiv: {
        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
        height: '35px',
        width: '120px',
        color: '#fff',
        float: 'right',
        border: 'none',
    } as React.CSSProperties,
};
