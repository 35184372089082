import '../../style/login.css';

import { Alert, Input } from 'antd';
import React, { useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
    login: (username: string, password: string) => void;
    forgotPasswordClicked: () => void;
    loading: boolean;
    errorKey: string;
};

export const Login = ({ login, forgotPasswordClicked, loading, errorKey }: Props): JSX.Element => {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [forgotPasswordMouseIn, setForgotPasswordMouseIn] = useState<boolean>(false);

    return (
        <React.Fragment>
            { errorKey &&
                <div style={styles.errorDiv}>
                    <Alert message={<FormattedMessage id={errorKey} />} type='error' showIcon />
                </div>
            }

            <Form.Group className='mb-3' as={Row} controlId='formField1'>
                <FormattedMessage id='app.login.lbl_username' />
                <Form.Control
                    type='email'
                    placeholder={GetFormattedMessage('app.login.placeholder.login')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                />
            </Form.Group>
            <Form.Group className='mb-3' as={Row} controlId="formField2">
                <FormattedMessage id='app.login.lbl_password' />
                <Input
                    type='password'
                    className="form-control mb-3"
                    placeholder={GetFormattedMessage('app.login.placeholder.password')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    onPressEnter={() => { login(username, password) }}
                />
            </Form.Group>
            <div className="d-flex justify-content-end mb-4">
                <Button className="btn px-4 shadow-lg btn-primary ml-auto rounded-pill"
                    disabled={loading}
                    style={styles.button}
                    type='submit'
                    onClick={() => { login(username, password) }}
                >
                    {loading ?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        :
                        <FormattedMessage id='app.login.btn_submit' />
                    }
                </Button>
            </div>
            <div style={styles.divForgotPassword}>
                <label
                    style={forgotPasswordMouseIn ? styles.textForgotPasswordMouseIn : styles.textForgotPassword}
                    onClick={forgotPasswordClicked}
                    onMouseEnter={() => setForgotPasswordMouseIn(true)}
                    onMouseLeave={() => setForgotPasswordMouseIn(false)}
                >
                    {GetFormattedMessage('app.login.forgot_password')}
                </label>
            </div>
        </React.Fragment>
    );
}

const styles = {
    button: {
        width: 200,
    } as React.CSSProperties,
    divForgotPassword: {
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
    } as React.CSSProperties,
    textForgotPassword: {
        color: colors.orange,
        fontStyle: 'italic',
        textDecorationLine: 'underline',
    } as React.CSSProperties,
    textForgotPasswordMouseIn: {
        color: colors.orange,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecorationLine: 'underline',
    } as React.CSSProperties,
    errorDiv: {
        flex: 1,
        paddingBottom: 20,
    } as React.CSSProperties,
}
