import { useActions, useValues } from 'kea';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ConfirmationModal } from '../../components/ConfirmationModal';
import { PageMessages } from '../../locales/types';
import { AppActions, appLogic, AppProps } from '../../redux/appLogic';
import { LOGIN_PAGE } from '../../router/pages';
import { validateEmail } from '../../utils/validateHelper';
import { ForgotPassword } from './ForgotPassword';

type Props = RouteComponentProps;

export const ForgotPasswordContainer = withRouter((props: Props): JSX.Element => {

    const { forgotPassword }: AppActions = useActions(appLogic);
    const { loading, hasSystemError }: AppProps = useValues(appLogic);

    const [error, setError] = useState<keyof (PageMessages)>();
    const [sending, setSending] = useState<boolean>(false);
    const handleRequestPasswordClick = (username: string): void => {
        if (validateEmail(username)) {
            setSending(true);
            forgotPassword(username);
        } else {
            setError('app.login.forgot_password.invalid_credentials');
        }
    }

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const handleModalConfirmationExit = (): void => {
        setShowConfirmation(false);
        props.history.replace(LOGIN_PAGE);
    }

    useEffect(() => {
        if (sending === true && loading === false && hasSystemError === false) {
            setShowConfirmation(true);
        }
    }, [loading, sending, hasSystemError, props.history]);

    useEffect(() => {
        if (hasSystemError === true) {
            setError('app.systemerro');
        }
    }, [hasSystemError]);

    return (
        <React.Fragment>
            <ForgotPassword
                requestPassword={handleRequestPasswordClick}
                loading={loading}
                errorKey={error}
            />

            <ConfirmationModal
                showModal={showConfirmation}
                titleFormattedMessageId={'app.login.forgot_password_confirmation_title'}
                bodyFormattedListMessageId={['app.login.forgot_password_confirmation_body']}
                primaryButtonFormattedMessageId={'app.btn_ok'}
                onModalHide={handleModalConfirmationExit}
                onPrimaryButtonClicked={handleModalConfirmationExit}
            />
        </React.Fragment>
    );
});
