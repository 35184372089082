import { ListReportMessages } from '../types';

export const listReportJA: ListReportMessages = {
    'app.listreport.report_area': 'レポート管理',
    'app.listreport.new': '新規レポートの作成',
    'app.listreport.new_group': '(JA) New Report Group',
    'app.listreport.add_group': '(JA) Add Another Group',
    'app.listreport.please_add_group_name': '(JA) Add Another Group',
    'app.listreport.search': '検索',
    'app.listreport.name': 'レポート名',
    'app.listreport.report_type': 'レポートの種類',
    'app.listreport.created_on': '作成日',
    'app.listreport.confirm_deletion_header': 'Confirm deletion',
    'app.listreport.confirm_deletion_body': 'Are you sure you want to proceed with the report deletion process. If you proceed, this report will be removed from our database permanently.',
    'app.listorganizations.view': 'View',
    'app.listorganizations.edit': 'Edit',
    'app.listorganizations.delete': 'Delete',
    'app.listreport.report_status': 'レポートステータス',
    'app.listreport.report_status_pending_approval': 'Pending Participant Approval',
    'app.listreport.report_status_completed': 'Available',
}
