import { PermissionMessages } from '../types';

export const permissionES: PermissionMessages = {
    'app.permission.title': 'Permiso – Informe individual - Perfil',
    'app.permission.remove_permission_to_all': 'Retirar permiso a todos',
    'app.permission.give_permission_to_all': 'Conceder permiso a todos',
    'app.permission.remove_permission': 'Retirar permiso',
    'app.permission.give_permission': 'Conceder permiso',
    'app.permission.table_title.permission': 'Permiso',
    'app.permission.table_title.name': 'Nombre',
    'app.permission.table_title.email': 'Correo-e',
    'app.permission.button.update': 'Actualizar',
    'app.permission.message': 'Usuarios actualizados con éxito',
    'app.permission.add_permissions_with_excel_file': 'Agregar permisos con archivo excel',
    'app.permission.add_permissions': 'Agregar permisos',
    'app.permission.permissions': 'Permisos',
    'app.permission.download_tooltip': 'Descargar plantilla de archivo'
}
