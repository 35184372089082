import React from 'react';
import { Tab } from 'semantic-ui-react';
import { colors } from '../../../config/colors';
import { NarrativeReportModel } from '../../../services/types';
import { GetFormattedMessage } from '../../../utils/htmlHelper';
import { GetAccessLargeWorld, GetAccessSelf, GetAccessToThoughtsAndFeelingsOfOthers, GetGenderTradition, GetNeedsClosure, GetNegativeLifeEvents, GetToleranceOfDisequilibruim, GetVariables, GetWhyWeDoText } from '../../../utils/reportHelper';

type Props = {
    narrativeReports: NarrativeReportModel[]
}

export const NarrativeReport = (props: Props): JSX.Element => {
    const { narrativeReports } = props;
    console.log('narrativeReports', narrativeReports)
    return (
        <React.Fragment>
            <Tab menu={{ pointing: true }} panes={narrativeReports.map((narrativeReport, narrativeIndex) => {
                return {
                    menuItem: narrativeReport.groupTitle,
                    render: () => <NarrativeReportTab key={narrativeIndex} narrativeReport={narrativeReport} />
                }
            })} />
        </React.Fragment>
    )
}

type TabProps = {
    narrativeReport: NarrativeReportModel
}

export const NarrativeReportTab = (props: TabProps): JSX.Element => {
    const { narrativeReport } = props;
    
    return <React.Fragment>
        <SectionTitle noHr message={GetFormattedMessage('app.individualreport.title.introduction')} />
        <div className='report-texts'>
            {[
                GetFormattedMessage('app.individualreport.static_text_1'),
                GetFormattedMessage('app.individualreport.static_text_2'),
                GetFormattedMessage('app.individualreport.static_text_3'),
                GetFormattedMessage('app.individualreport.static_text_4'),
            ].map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`introduction-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.foundation')} />
        <div className='report-texts'>
            {[
                GetFormattedMessage('app.individualreport.demographics.text'),
                GetFormattedMessage('app.individualreport.formativecore.text')
                    .replace('{NegativeLifeEvents}',
                        GetNegativeLifeEvents(narrativeReport.negativeLifeEventsIndicator))
                    .replace('{NeedsClosure}', GetNeedsClosure(narrativeReport.needsClosureIndicator)),
                GetFormattedMessage('app.individualreport.static_text_5'),
            ].map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`foundation-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.tolerance_of_desequilibrium')} />
        <div className='report-texts'>
            {GetToleranceOfDisequilibruim(narrativeReport.toleranceDisequilibriumIndicator).map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`toleranceOfDisequilibrium-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.making_sense')} />
        <div className='report-texts'>
            {GetWhyWeDoText(narrativeReport.whyWeDoIndicator).map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`whyWeDo-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.access_to_yourself')} />
        <div className='report-texts'>
            {GetAccessSelf(narrativeReport.accessSelfIndicator, narrativeReport.selfAwarenessIndicator).map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessSelf-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.access_to_the_thoughts')} />
        <div className='report-texts'>
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: GetAccessToThoughtsAndFeelingsOfOthers(narrativeReport.religiousTraditionalimsIndicator) }} />
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: GetGenderTradition(narrativeReport.genderTraditionIndicator) }}></div>

            {GetVariables(narrativeReport.socioCulturalIndicator).map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`variables-${index}`} />
            )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.access_to_the_larger_world')} />
        <div className='report-texts'>
            {GetAccessLargeWorld(narrativeReport.ecologicalResonanceIndicator,
                narrativeReport.globalEngagementIndicador).map((item, index) =>
                    item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`accessToTheLargerWorld-${index}`} />
                )}
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.conclusion')} />
        <div className='report-texts'>
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.individualreport.static_text_11') }}></div>
        </div>

        <SectionTitle message={GetFormattedMessage('app.individualreport.title.thankyou')} />
        <div className='report-texts'>
            {[
                GetFormattedMessage('app.individualreport.thankyou_static_1_1'),
                GetFormattedMessage('app.individualreport.thankyou_static_1_2'),
                GetFormattedMessage('app.individualreport.thankyou_static_2'),
                GetFormattedMessage('app.individualreport.thankyou_static_3'),
            ].map((item, index) =>
                item !== '' && <div className="mb-4" dangerouslySetInnerHTML={{ __html: item }} key={`thankYou-${index}`} />
            )}
        </div>
    </React.Fragment>
}

type TitleProps = {
    message: string;
    noHr?: boolean;
}

const SectionTitle = (props: TitleProps): JSX.Element => (
    <div style={styles.paddingTop10}>
        {!props.noHr && <hr />}
        <div className='report-texts'>
            <h4 style={styles.title} className='report-h4'>{props.message}</h4>
        </div>
    </div>
)


const styles = {
    container: {
        padding: 5
    } as React.CSSProperties,
    divBackground: {
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        marginBottom: '10%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center',
    } as React.CSSProperties,
    title: {
        color: colors.darkSlateBlue,
    } as React.CSSProperties,
    mainTitle: {
        color: colors.darkSlateBlue,
        display: 'flex',
        justifyContent: 'center',
        margin: 0
    } as React.CSSProperties,
    paddingTop10: {
        paddingTop: 10
    } as React.CSSProperties,
    questionRowOdd: {
        padding: '16px',
        backgroundColor: '#cecece50',
        borderRadius: '16px',
    } as React.CSSProperties,
    questionRowOddFirstLine: {
        padding: '16px',
        backgroundColor: '#cecece50',
        borderRadius: '16px',
        marginTop: '10px'
    } as React.CSSProperties,
    questionRowEven: {
        padding: '16px',
        backgroundColor: colors.white,
        borderRadius: '16px',
    } as React.CSSProperties,
}
