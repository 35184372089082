import { CreateReportMessages } from '../types';

export const createReportEN: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'Fake name to all participants',
    'app.create_report.btn_real_name_all': 'Real name to all participants',
    'app.create_report.btn_fake_name': 'Use fake name',
    'app.create_report.btn_real_name': 'Use real name',
    'app.create_report.title': 'Report Management',
    'app.create_report.summary_line1': ' The BEVI Management System allows you to generate reports that are based upon all of the data from the group with which you are working.  There are four possible report types: <br />' +
        '&nbsp;&nbsp;1) Group Report (this report is based upon one period of time during which the BEVI is administered); <br />' +
        '&nbsp;&nbsp;2) Longitudinal Report (this is a report that is completed over time such as T1, T2, T3, etc.)   <br />' +
        '&nbsp;&nbsp;3) Between Group Reports (these are reports that allow you to compare different groups within and across institutions or organizations). <br />' +
        '&nbsp;&nbsp;4) Within Group Reports (these are reports that compare individuals within a group with each other across BEVI scales, but do so anonymously) ',
    'app.create_report.summary_line2': ' All of these reports may be generated automatically (i.e., they will automatically use all of the data in your group or groups).   However, you also are able to refine each of these report types to focus on different combinations of Background Information items. For example, you could generate a report that only compared females and males from two different countries. There are literally hundreds of different combinations you could select using this refinement functionality. ',
    'app.create_report.step1': 'STEP 1 - CONFIGURING YOUR REPORT',
    'app.create_report.step2': 'STEP 2 - ADD REPORT GROUP',
    'app.create_report.step3': 'STEP 3 - REFINING YOUR REPORT',
    'app.create_report.step3Individual': 'STEP 3 - WHO CAN SEE THE REPORT?',
    'app.create_report.step4': 'STEP 4 - MANAGING PARTICIPANTS',
    'app.create_report.step3_username_column': 'User name',
    'app.create_report.step3_aliasname_column': 'Alias name',
    'app.create_report.step3_action_column': 'Action',
    'app.create_report.step3_no_user_title': 'No users found',
    'app.create_report.step3_no_user_subtitle': 'Please change the parameters of the previous steps and try to search for users again.',
    'app.create_report.step3_user_name_column_title': 'Participants',
    'app.create_report.step3_user_exporting_column_title': 'Included',
    'app.create_report.requester': 'Requester',
    'app.create_report.name': 'Report Name',
    'app.create_report.report_type': 'Type',
    'app.create_report.select_subprogram': 'Select data to generate the report',
    'app.create_report.select_subprogram_warning': '(please fill the previous fields)',
    'app.create_report.group_report': 'Group Report',
    'app.create_report.longitudinal_report': 'Group Change Report',
    'app.create_report.comparative_by_group_report': 'Between Group Report',
    'app.create_report.comparative_by_individual_report': 'Within Group Report',
    'app.create_report.select_organization': 'Select an organization.',
    'app.create_report.select_program': 'Select a program or experience.',
    'app.create_report.select_sub_program': 'Select a BEVI administration.',
    'app.create_report.organization_name': 'Organization',
    'app.create_report.program_name': 'Program or Experiece',
    'app.create_report.sub_program_name': 'BEVI Administration',
    'app.create_report.actions': 'Remove',
    'app.create_report.action_remove': 'Remove',
    'app.create_report.show_filters': 'Show criteria filters',
    'app.create_report.hide_filters': 'Hide criteria filters',
    'app.create_report.filter_select_default_option': 'Select to filter...',
    'app.create_report.filter_select_with_values': 'Filtering',
    'app.create_report.filter_age': 'Age',
    'app.create_report.filter_gender': 'Gender',
    'app.create_report.filter_years_of_education': 'Years of Education',
    'app.create_report.filter_primary_country_of_origin': 'Country of Origin',
    'app.create_report.filter_marital_status': 'Relationship Status',
    'app.create_report.filter_political_orientation': 'Political Orientation (Conservative to Liberal)',
    'app.create_report.filter_ethnic_background': 'Ethnic Background',
    'app.create_report.filter_religious_orientation': 'Religious Orientation',
    'app.create_report.filter_religious_commitment': 'Religious Commitment',
    'app.create_report.filter_academic_rank': 'Academic Rank',
    'app.create_report.filter_time_spent_in_other_countries': 'Time spent in other countries',
    'app.create_report.filter_nro_visited_countries': 'Visited Countries',
    'app.create_report.filter_fluency': 'Fluency',
    'app.create_report.filter_years_of_language_study': 'Years of Language Study',
    'app.create_report.filter_mother_country': 'Mother\'s Country',
    'app.create_report.filter_father_country': 'Father\'s Country',
    'app.create_report.filter_mother_education': 'Mother\'s Education',
    'app.create_report.filter_father_education': 'Father\'s Education',
    'app.create_report.filter_minority_group': 'Minority Group',
    'app.create_report.filter_personal_interest': 'Interest in International / Cultural Experiences',
    'app.create_report.filter_study_abroad_satisfaction': 'Satisfaction with International / Cultural Experiences',
    'app.create_report.filter_primary_area_of_work': 'Primary Area of Work',
    'app.create_report.filter_area_of_occupations': 'Area of Occupations',
    'app.create_report.filter_income_level_of_your_home_country': 'Income Level',
    'app.create_report.btn_search': 'Search',
    'app.create_report.btn_save': 'Generate',
    'app.create_report.placeholder.name': 'Type the report name',
    'app.create_report.select.report_type': 'Select the report type',
    'app.create_report.select_countries_options_lbl': 'Choose at least one Country of Origin to contrast with all others:',
    'app.create_report.selected_countries_lbl': 'Selected countries:',
    'app.create_report.select_ethnics_options_lbl': 'Choose at least one Ethnicity to contrast with all others:',
    'app.create_report.selected_ethnics_lbl': 'Selected ethnics orientation:',
    'app.create_report.select_number_of_forms_lbl': 'Choose the maximum number of responses to experiential reflection items to display in the report:',
    'app.create_report.select_relationship_status_options_lbl': 'Choose at least one Relationship Status to contrast with all others:',
    'app.create_report.select_religion_options_lbl': 'Choose at least one Religion to contrast with all others:',
    'app.create_report.select_primary_area_work_options_lbl': 'Choose at least one Primary Area of Work to contrast with all others:',
    'app.create_report.selected_relationship_status_lbl': 'Selected Relationship Status',
    'app.create_report.selected_religion_lbl': 'Selected Religious Orientation',
    'app.create_report.selected_primary_area_work_lbl': 'Selected Primary Area of Work',
    'app.create_report.individual_report': 'Individual Report',
    'app.create_report.longitudinal_individual_report': 'Individual Change Report',
    'app.create_report.combined_report': 'Partner Report',
    'app.create_report.individual': 'Individual',
    'app.create_report.partner': 'Partner',
    'app.create_report.group': 'Group',
    'app.create_report.select.group': 'Select the group',
    'app.create_report.partner_change_report': 'Partner Change Report',
    'app.create_report.email_in_use': 'Email in use',
    'app.create_report.add_new_participant': 'Add new participant'
}
