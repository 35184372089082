import { SelectItem } from '../components/FormDropdown';
import { LocalStorage_BeviLanguage } from '../IntlProviderWrapper';
import { convertFormattedMessagesToSelectItem, FormattedMessageWithIds } from './htmlHelper';

export function GetGenderOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { key: 'app.demographic.gender.male', value: 1 },
        { key: 'app.demographic.gender.female', value: 2 },
        { key: 'app.demographic.gender.self_identify', value: 3 },
    ]);
}

export function GetEthnicOrientationOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.ethnic.respose_asian' },
        { value: 2, key: 'app.demographic.ethnic.respose_african' },
        { value: 3, key: 'app.demographic.ethnic.respose_latino' },
        { value: 4, key: 'app.demographic.ethnic.respose_indigenous' },
        { value: 5, key: 'app.demographic.ethnic.respose_middle_eastern' },
        { value: 6, key: 'app.demographic.ethnic.respose_white' },
        { value: 998, key: 'app.demographic.ethnic.respose_mixed' },
        { value: 999, key: 'app.demographic.ethnic.respose_other' },
    ]);
}

export function GetMinorityGroupFilterOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 0, key: 'app.response_no' },
        { value: 1, key: 'app.response_yes' },
    ]);
}

export function GetReligiousOrientationOptions(): SelectItem[] {
    const languageCode: string | null = localStorage.getItem(LocalStorage_BeviLanguage);

    const other = new Array<FormattedMessageWithIds>();
    other.push({ value: 999, key: 'app.demographic.religion.response_other' });
    if (languageCode === 'ja') other.push({ value: 1000, key: 'app.demographic.religion.no_response' });

    const tenrikyoAnswer = new Array<FormattedMessageWithIds>();
    if (languageCode !== 'ja') tenrikyoAnswer.push({ value: 20, key: 'app.demographic.religion.response_20' });

    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.religion.response_1' },
        { value: 2, key: 'app.demographic.religion.response_2' },
        { value: 3, key: 'app.demographic.religion.response_3' },
        { value: 4, key: 'app.demographic.religion.response_4' },
        { value: 5, key: 'app.demographic.religion.response_5' },
        { value: 6, key: 'app.demographic.religion.response_6' },
        { value: 7, key: 'app.demographic.religion.response_7' },
        { value: 8, key: 'app.demographic.religion.response_8' },
        { value: 9, key: 'app.demographic.religion.response_9' },
        { value: 10, key: 'app.demographic.religion.response_10' },
        { value: 11, key: 'app.demographic.religion.response_11' },
        { value: 12, key: 'app.demographic.religion.response_12' },
        { value: 13, key: 'app.demographic.religion.response_13' },
        { value: 14, key: 'app.demographic.religion.response_14' },
        { value: 15, key: 'app.demographic.religion.response_15' },
        { value: 16, key: 'app.demographic.religion.response_16' },
        { value: 17, key: 'app.demographic.religion.response_17' },
        { value: 18, key: 'app.demographic.religion.response_18' },
        { value: 19, key: 'app.demographic.religion.response_19' },
        ...tenrikyoAnswer,
        { value: 21, key: 'app.demographic.religion.response_21' },
        { value: 22, key: 'app.demographic.religion.response_22' },
        ...other
    ]);
}

export function GetReligiousOrientationOptionsForReports(): SelectItem[] {
    const other = new Array<FormattedMessageWithIds>();
    other.push({ value: 999, key: 'app.demographic.religion.response_other' });
    other.push({ value: 1000, key: 'app.demographic.religion.no_response' });

    const tenrikyoAnswer = new Array<FormattedMessageWithIds>();
    tenrikyoAnswer.push({ value: 20, key: 'app.demographic.religion.response_20' });

    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.religion.response_1' },
        { value: 2, key: 'app.demographic.religion.response_2' },
        { value: 3, key: 'app.demographic.religion.response_3' },
        { value: 4, key: 'app.demographic.religion.response_4' },
        { value: 5, key: 'app.demographic.religion.response_5' },
        { value: 6, key: 'app.demographic.religion.response_6' },
        { value: 7, key: 'app.demographic.religion.response_7' },
        { value: 8, key: 'app.demographic.religion.response_8' },
        { value: 9, key: 'app.demographic.religion.response_9' },
        { value: 10, key: 'app.demographic.religion.response_10' },
        { value: 11, key: 'app.demographic.religion.response_11' },
        { value: 12, key: 'app.demographic.religion.response_12' },
        { value: 13, key: 'app.demographic.religion.response_13' },
        { value: 14, key: 'app.demographic.religion.response_14' },
        { value: 15, key: 'app.demographic.religion.response_15' },
        { value: 16, key: 'app.demographic.religion.response_16' },
        { value: 17, key: 'app.demographic.religion.response_17' },
        { value: 18, key: 'app.demographic.religion.response_18' },
        { value: 19, key: 'app.demographic.religion.response_19' },
        ...tenrikyoAnswer,
        { value: 21, key: 'app.demographic.religion.response_21' },
        { value: 22, key: 'app.demographic.religion.response_22' },
        ...other
    ]);
}

export function GetPoliticalOrientationOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.political_orientation.response_1' },
        { value: 2, key: 'app.demographic.political_orientation.response_2' },
        { value: 3, key: 'app.demographic.political_orientation.response_3' },
        { value: 4, key: 'app.demographic.political_orientation.response_4' },
        { value: 5, key: 'app.demographic.political_orientation.response_5' },
        { value: 999, key: 'app.demographic.political_orientation.response_6' },
    ]);
}

export function GetMaritialStatusOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.marital.response_single' },
        { value: 2, key: 'app.demographic.marital.response_married' },
        { value: 3, key: 'app.demographic.marital.response_divorced' },
        { value: 4, key: 'app.demographic.marital.response_widowed' },
        { value: 999, key: 'app.demographic.marital.response_other' },
    ]);
}

export function GetIncomeLevelOfYourHomeCountryOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.income_level.response_1' },
        { value: 2, key: 'app.demographic.income_level.response_2' },
        { value: 3, key: 'app.demographic.income_level.response_3' },
    ]);
}

export function GetPrimaryWorkAreaOptions(): SelectItem[] {
    return (
        convertFormattedMessagesToSelectItem([
            { key: 'app.demographic.primary_area_of_work.response_1', value: 1 },
            { key: 'app.demographic.primary_area_of_work.response_2', value: 2 },
            { key: 'app.demographic.primary_area_of_work.response_3', value: 3 },
            { key: 'app.demographic.primary_area_of_work.response_4', value: 4 },
            { key: 'app.demographic.primary_area_of_work.response_5', value: 5 },
            { key: 'app.demographic.primary_area_of_work.response_6', value: 6 },
            { key: 'app.demographic.primary_area_of_work.response_7', value: 7 },
        ])
    );
}

export function GetTimeSpentInOtherCountriesOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 0, key: 'app.demographic.time_in_other_country.response_not_spent' },
        { value: 1, key: 'app.demographic.time_in_other_country.response_less_2_weeks' },
        { value: 2, key: 'app.demographic.time_in_other_country.response_less_1_month' },
        { value: 3, key: 'app.demographic.time_in_other_country.response_less_3_months' },
        { value: 4, key: 'app.demographic.time_in_other_country.response_less_6_months' },
        { value: 5, key: 'app.demographic.time_in_other_country.response_less_1_year' },
        { value: 6, key: 'app.demographic.time_in_other_country.response_less_2_years' },
        { value: 7, key: 'app.demographic.time_in_other_country.response_less_5_years' },
        { value: 8, key: 'app.demographic.time_in_other_country.response_less_10_years' },
        { value: 9, key: 'app.demographic.time_in_other_country.response_10_or_more' },
    ]);
}

export function GetYearOfEducationOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 0, key: 'app.demographic.education_years.response_1' },
        { value: 1, key: 'app.demographic.education_years.response_2' },
        { value: 2, key: 'app.demographic.education_years.response_3' },
        { value: 3, key: 'app.demographic.education_years.response_4' },
        { value: 4, key: 'app.demographic.education_years.response_5' },
        { value: 5, key: 'app.demographic.education_years.response_6' },
        { value: 6, key: 'app.demographic.education_years.response_7' },
        { value: 7, key: 'app.demographic.education_years.response_8' },
        { value: 8, key: 'app.demographic.education_years.response_9' },
        { value: 9, key: 'app.demographic.education_years.response_10' },
        { value: 10, key: 'app.demographic.education_years.response_11' },
        { value: 11, key: 'app.demographic.education_years.response_12' },
        { value: 12, key: 'app.demographic.education_years.response_13' },
        { value: 13, key: 'app.demographic.education_years.response_14' },
        { value: 14, key: 'app.demographic.education_years.response_15' },
        { value: 15, key: 'app.demographic.education_years.response_16' },
        { value: 16, key: 'app.demographic.education_years.response_17' },
        { value: 17, key: 'app.demographic.education_years.response_18' },
        { value: 18, key: 'app.demographic.education_years.response_19' },
        { value: 19, key: 'app.demographic.education_years.response_20' },
        { value: 20, key: 'app.demographic.education_years.response_21' },
        { value: 21, key: 'app.demographic.education_years.response_22' },
        { value: 22, key: 'app.demographic.education_years.response_23' },
        { value: 23, key: 'app.demographic.education_years.response_24' },
        { value: 24, key: 'app.demographic.education_years.response_25' },
        { value: 25, key: 'app.demographic.education_years.response_26' },
        { value: 26, key: 'app.demographic.education_years.response_27' },
        { value: 27, key: 'app.demographic.education_years.response_28' },
        { value: 28, key: 'app.demographic.education_years.response_29' },
        { value: 29, key: 'app.demographic.education_years.response_30' },
        { value: 30, key: 'app.demographic.education_years.response_31' },
        { value: 31, key: 'app.demographic.education_years.response_32' },
    ]);
}

export function GetCountriesOptions(): SelectItem[] {
    const countriesOptions = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.country.response_eua' },
        { value: 2, key: 'app.demographic.country.response_afganistan' },
        { value: 3, key: 'app.demographic.country.response_albania' },
        { value: 4, key: 'app.demographic.country.response_algeria' },
        { value: 5, key: 'app.demographic.country.response_american_samoa' },
        { value: 6, key: 'app.demographic.country.response_andorra' },
        { value: 7, key: 'app.demographic.country.response_angola' },
        { value: 8, key: 'app.demographic.country.response_anguilla' },
        { value: 9, key: 'app.demographic.country.response_antigua_barbuda' },
        { value: 10, key: 'app.demographic.country.response_argentia' },
        { value: 11, key: 'app.demographic.country.response_armenia' },
        { value: 12, key: 'app.demographic.country.response_aruba' },
        { value: 13, key: 'app.demographic.country.response_australia' },
        { value: 14, key: 'app.demographic.country.response_austria' },
        { value: 15, key: 'app.demographic.country.response_azerbaijan' },
        { value: 16, key: 'app.demographic.country.response_bahamas' },
        { value: 17, key: 'app.demographic.country.response_bahrain' },
        { value: 18, key: 'app.demographic.country.response_bangladesh' },
        { value: 19, key: 'app.demographic.country.response_barbados' },
        { value: 20, key: 'app.demographic.country.response_belarus' },
        { value: 21, key: 'app.demographic.country.response_belgium' },
        { value: 22, key: 'app.demographic.country.response_belize' },
        { value: 23, key: 'app.demographic.country.response_benin' },
        { value: 24, key: 'app.demographic.country.response_bermuda' },
        { value: 25, key: 'app.demographic.country.response_bhutan' },
        { value: 26, key: 'app.demographic.country.response_bolivia' },
        { value: 27, key: 'app.demographic.country.response_bosnia_herzegovina' },
        { value: 28, key: 'app.demographic.country.response_botswana' },
        { value: 29, key: 'app.demographic.country.response_brazil' },
        { value: 30, key: 'app.demographic.country.response_brunei' },
        { value: 31, key: 'app.demographic.country.response_bulgaria' },
        { value: 32, key: 'app.demographic.country.response_burkina_faso' },
        { value: 33, key: 'app.demographic.country.response_burundi' },
        { value: 34, key: 'app.demographic.country.response_cambodia' },
        { value: 35, key: 'app.demographic.country.response_cameroon' },
        { value: 36, key: 'app.demographic.country.response_canada' },
        { value: 37, key: 'app.demographic.country.response_cape_verde' },
        { value: 38, key: 'app.demographic.country.response_cayman_islands' },
        { value: 39, key: 'app.demographic.country.response_central_african_republic' },
        { value: 40, key: 'app.demographic.country.response_chad' },
        { value: 41, key: 'app.demographic.country.response_chile' },
        { value: 42, key: 'app.demographic.country.response_china' },
        { value: 43, key: 'app.demographic.country.response_china_hong_kong_sar' },
        { value: 44, key: 'app.demographic.country.response_colombia' },
        { value: 45, key: 'app.demographic.country.response_comoros' },
        { value: 46, key: 'app.demographic.country.response_congo' },
        { value: 47, key: 'app.demographic.country.response_congo,_democratic_republic' },
        { value: 48, key: 'app.demographic.country.response_cook_islands' },
        { value: 49, key: 'app.demographic.country.response_costa_rica' },
        { value: 50, key: 'app.demographic.country.response_cote_divoire' },
        { value: 51, key: 'app.demographic.country.response_croatia' },
        { value: 52, key: 'app.demographic.country.response_cuba' },
        { value: 53, key: 'app.demographic.country.response_cyprus' },
        { value: 54, key: 'app.demographic.country.response_czech_republic' },
        { value: 55, key: 'app.demographic.country.response_denmark' },
        { value: 56, key: 'app.demographic.country.response_djibouti' },
        { value: 57, key: 'app.demographic.country.response_dominica' },
        { value: 58, key: 'app.demographic.country.response_dominican_republic' },
        { value: 59, key: 'app.demographic.country.response_ecuador' },
        { value: 60, key: 'app.demographic.country.response_egypt' },
        { value: 61, key: 'app.demographic.country.response_el_salvador' },
        { value: 62, key: 'app.demographic.country.response_equatorial_guinea' },
        { value: 63, key: 'app.demographic.country.response_eritrea' },
        { value: 64, key: 'app.demographic.country.response_estonia' },
        { value: 65, key: 'app.demographic.country.response_ethiopia' },
        { value: 67, key: 'app.demographic.country.response_falkland_islands' },
        { value: 68, key: 'app.demographic.country.response_fiji' },
        { value: 69, key: 'app.demographic.country.response_finland' },
        { value: 70, key: 'app.demographic.country.response_france' },
        { value: 71, key: 'app.demographic.country.response_french_guiana' },
        { value: 72, key: 'app.demographic.country.response_french_polynesia' },
        { value: 73, key: 'app.demographic.country.response_gabon' },
        { value: 74, key: 'app.demographic.country.response_gambia' },
        { value: 75, key: 'app.demographic.country.response_georgia' },
        { value: 76, key: 'app.demographic.country.response_germany' },
        { value: 77, key: 'app.demographic.country.response_ghana' },
        { value: 78, key: 'app.demographic.country.response_gibraltar' },
        { value: 79, key: 'app.demographic.country.response_greece' },
        { value: 80, key: 'app.demographic.country.response_grenada' },
        { value: 81, key: 'app.demographic.country.response_guadeloupe' },
        { value: 82, key: 'app.demographic.country.response_guam' },
        { value: 83, key: 'app.demographic.country.response_guatemala' },
        { value: 84, key: 'app.demographic.country.response_guernsey' },
        { value: 85, key: 'app.demographic.country.response_guinea' },
        { value: 86, key: 'app.demographic.country.response_guinea_bissau' },
        { value: 87, key: 'app.demographic.country.response_guyana' },
        { value: 88, key: 'app.demographic.country.response_haiti' },
        { value: 89, key: 'app.demographic.country.response_honduras' },
        { value: 90, key: 'app.demographic.country.response_hungary' },
        { value: 91, key: 'app.demographic.country.response_iceland' },
        { value: 92, key: 'app.demographic.country.response_india' },
        { value: 93, key: 'app.demographic.country.response_indonesia' },
        { value: 94, key: 'app.demographic.country.response_iran' },
        { value: 95, key: 'app.demographic.country.response_iraq' },
        { value: 96, key: 'app.demographic.country.response_ireland' },
        { value: 97, key: 'app.demographic.country.response_isle_of_man' },
        { value: 98, key: 'app.demographic.country.response_israel' },
        { value: 99, key: 'app.demographic.country.response_italy' },
        { value: 100, key: 'app.demographic.country.response_jamaica' },
        { value: 101, key: 'app.demographic.country.response_japan' },
        { value: 102, key: 'app.demographic.country.response_jersey' },
        { value: 103, key: 'app.demographic.country.response_jordan' },
        { value: 104, key: 'app.demographic.country.response_kazakhstan' },
        { value: 105, key: 'app.demographic.country.response_kenya' },
        { value: 106, key: 'app.demographic.country.response_kiribati' },
        { value: 107, key: 'app.demographic.country.response_kosovo' },
        { value: 108, key: 'app.demographic.country.response_kuwait' },
        { value: 109, key: 'app.demographic.country.response_kyrgyzstan' },
        { value: 110, key: 'app.demographic.country.response_laos' },
        { value: 111, key: 'app.demographic.country.response_latvia' },
        { value: 112, key: 'app.demographic.country.response_lebanon' },
        { value: 113, key: 'app.demographic.country.response_lesotho' },
        { value: 114, key: 'app.demographic.country.response_liberia' },
        { value: 115, key: 'app.demographic.country.response_libya' },
        { value: 116, key: 'app.demographic.country.response_liechtenstein' },
        { value: 117, key: 'app.demographic.country.response_lithuania' },
        { value: 118, key: 'app.demographic.country.response_luxembourg' },
        { value: 119, key: 'app.demographic.country.response_macau' },
        { value: 120, key: 'app.demographic.country.response_macedonia' },
        { value: 121, key: 'app.demographic.country.response_madagascar' },
        { value: 122, key: 'app.demographic.country.response_malaysia' },
        { value: 123, key: 'app.demographic.country.response_malawi' },
        { value: 124, key: 'app.demographic.country.response_maldives' },
        { value: 125, key: 'app.demographic.country.response_mali' },
        { value: 126, key: 'app.demographic.country.response_malta' },
        { value: 127, key: 'app.demographic.country.response_mariana' },
        { value: 128, key: 'app.demographic.country.response_marshall_islands' },
        { value: 129, key: 'app.demographic.country.response_martinique' },
        { value: 130, key: 'app.demographic.country.response_mauritania' },
        { value: 131, key: 'app.demographic.country.response_mauritius' },
        { value: 132, key: 'app.demographic.country.response_mexico' },
        { value: 133, key: 'app.demographic.country.response_micronesia' },
        { value: 134, key: 'app.demographic.country.response_moldova' },
        { value: 135, key: 'app.demographic.country.response_monaco' },
        { value: 136, key: 'app.demographic.country.response_mongolia' },
        { value: 137, key: 'app.demographic.country.response_montserrat' },
        { value: 138, key: 'app.demographic.country.response_montenegro' },
        { value: 139, key: 'app.demographic.country.response_morocco' },
        { value: 140, key: 'app.demographic.country.response_mozambique' },
        { value: 141, key: 'app.demographic.country.response_myanmar' },
        { value: 142, key: 'app.demographic.country.response_namibia' },
        { value: 143, key: 'app.demographic.country.response_nepal' },
        { value: 144, key: 'app.demographic.country.response_netherland_antilles' },
        { value: 145, key: 'app.demographic.country.response_netherlands' },
        { value: 146, key: 'app.demographic.country.response_new_caledonia' },
        { value: 147, key: 'app.demographic.country.response_new_zealand' },
        { value: 148, key: 'app.demographic.country.response_nicaragua' },
        { value: 149, key: 'app.demographic.country.response_niger' },
        { value: 150, key: 'app.demographic.country.response_nigeria' },
        { value: 151, key: 'app.demographic.country.response_north_korea' },
        { value: 152, key: 'app.demographic.country.response_norway' },
        { value: 153, key: 'app.demographic.country.response_oman' },
        { value: 154, key: 'app.demographic.country.response_pakistan' },
        { value: 155, key: 'app.demographic.country.response_palau' },
        { value: 156, key: 'app.demographic.country.response_panama' },
        { value: 157, key: 'app.demographic.country.response_papua_new_guinea' },
        { value: 158, key: 'app.demographic.country.response_paraguay' },
        { value: 159, key: 'app.demographic.country.response_peru' },
        { value: 160, key: 'app.demographic.country.response_phillipines' },
        { value: 161, key: 'app.demographic.country.response_poland' },
        { value: 162, key: 'app.demographic.country.response_portugal' },
        { value: 163, key: 'app.demographic.country.response_puerto_rico' },
        { value: 164, key: 'app.demographic.country.response_qatar' },
        { value: 165, key: 'app.demographic.country.response_reunion' },
        { value: 166, key: 'app.demographic.country.response_romania' },
        { value: 167, key: 'app.demographic.country.response_russian_federation' },
        { value: 168, key: 'app.demographic.country.response_rwanda' },
        { value: 169, key: 'app.demographic.country.response_st_helena' },
        { value: 170, key: 'app.demographic.country.response_st_kitts-nevis' },
        { value: 171, key: 'app.demographic.country.response_st_lucia' },
        { value: 172, key: 'app.demographic.country.response_st_vincent_grenadines' },
        { value: 173, key: 'app.demographic.country.response_stateless' },
        { value: 174, key: 'app.demographic.country.response_samoa' },
        { value: 175, key: 'app.demographic.country.response_san_marino' },
        { value: 176, key: 'app.demographic.country.response_sao_tome_principe' },
        { value: 177, key: 'app.demographic.country.response_saudi_arabia' },
        { value: 178, key: 'app.demographic.country.response_senegal' },
        { value: 179, key: 'app.demographic.country.response_seychelles' },
        { value: 180, key: 'app.demographic.country.response_serbia' },
        { value: 181, key: 'app.demographic.country.response_sierra_leone' },
        { value: 182, key: 'app.demographic.country.response_singapore' },
        { value: 183, key: 'app.demographic.country.response_slovak_republic' },
        { value: 184, key: 'app.demographic.country.response_slovenia' },
        { value: 185, key: 'app.demographic.country.response_solomon_islands' },
        { value: 186, key: 'app.demographic.country.response_somalia' },
        { value: 187, key: 'app.demographic.country.response_south_africa' },
        { value: 188, key: 'app.demographic.country.response_south_korea' },
        { value: 189, key: 'app.demographic.country.response_spain' },
        { value: 190, key: 'app.demographic.country.response_sri_lanka' },
        { value: 191, key: 'app.demographic.country.response_sudan' },
        { value: 192, key: 'app.demographic.country.response_suriname' },
        { value: 193, key: 'app.demographic.country.response_swaziland' },
        { value: 194, key: 'app.demographic.country.response_sweden' },
        { value: 195, key: 'app.demographic.country.response_switzerland' },
        { value: 196, key: 'app.demographic.country.response_syria' },
        { value: 197, key: 'app.demographic.country.response_taiwan' },
        { value: 198, key: 'app.demographic.country.response_tajikistan' },
        { value: 199, key: 'app.demographic.country.response_tanzania' },
        { value: 200, key: 'app.demographic.country.response_thailand' },
        { value: 201, key: 'app.demographic.country.response_togo' },
        { value: 202, key: 'app.demographic.country.response_timor_leste' },
        { value: 203, key: 'app.demographic.country.response_tonga' },
        { value: 204, key: 'app.demographic.country.response_trinidad_tobago' },
        { value: 205, key: 'app.demographic.country.response_tunisia' },
        { value: 206, key: 'app.demographic.country.response_turkey' },
        { value: 207, key: 'app.demographic.country.response_turkmenistan' },
        { value: 208, key: 'app.demographic.country.response_turks_caicos_is' },
        { value: 209, key: 'app.demographic.country.response_tuvalu' },
        { value: 210, key: 'app.demographic.country.response_uganda' },
        { value: 211, key: 'app.demographic.country.response_ukraine' },
        { value: 212, key: 'app.demographic.country.response_united_arab_erimates' },
        { value: 213, key: 'app.demographic.country.response_united_kingdom' },
        { value: 214, key: 'app.demographic.country.response_uraguay' },
        { value: 215, key: 'app.demographic.country.response_uzbekistan' },
        { value: 216, key: 'app.demographic.country.response_vanuatu' },
        { value: 217, key: 'app.demographic.country.response_venezuela' },
        { value: 218, key: 'app.demographic.country.response_vietnam' },
        { value: 219, key: 'app.demographic.country.response_virgin_islands' },
        { value: 220, key: 'app.demographic.country.response_west_bank_and_gaza' },
        { value: 221, key: 'app.demographic.country.response_yemen' },
        { value: 222, key: 'app.demographic.country.response_zambia' },
        { value: 223, key: 'app.demographic.country.response_zimbabwe' },
    ]);
    const languageCode: string | null = localStorage.getItem(LocalStorage_BeviLanguage);

    let firstLanguageOptionValue: number;
    if (languageCode === 'ja') firstLanguageOptionValue = 101;
    else if (languageCode === 'zh') firstLanguageOptionValue = 42;

    return countriesOptions.sort((a: SelectItem, b: SelectItem) => {
        if (a.value === firstLanguageOptionValue) return -1;
        if (b.value === firstLanguageOptionValue) return 1;
        return a.label.localeCompare(b.label);
    });
}

export function GetLanguageOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.language_options.Afrikaans' },
        { value: 2, key: 'app.demographic.language_options.Albanian' },
        { value: 3, key: 'app.demographic.language_options.Amharic' },
        { value: 4, key: 'app.demographic.language_options.Arabic' },
        { value: 5, key: 'app.demographic.language_options.Armenian' },
        { value: 6, key: 'app.demographic.language_options.Basque' },
        { value: 7, key: 'app.demographic.language_options.Bengali' },
        { value: 8, key: 'app.demographic.language_options.Byelorussian' },
        { value: 9, key: 'app.demographic.language_options.Burmese' },
        { value: 10, key: 'app.demographic.language_options.Bulgarian' },
        { value: 11, key: 'app.demographic.language_options.Catalan' },
        { value: 12, key: 'app.demographic.language_options.Czech' },
        { value: 13, key: 'app.demographic.language_options.Chinese' },
        { value: 14, key: 'app.demographic.language_options.Croatian' },
        { value: 15, key: 'app.demographic.language_options.Danish' },
        { value: 16, key: 'app.demographic.language_options.Dari' },
        { value: 17, key: 'app.demographic.language_options.Dzongkha' },
        { value: 18, key: 'app.demographic.language_options.Dutch' },
        { value: 19, key: 'app.demographic.language_options.English' },
        { value: 20, key: 'app.demographic.language_options.Esperanto' },
        { value: 21, key: 'app.demographic.language_options.Estonian' },
        { value: 22, key: 'app.demographic.language_options.Faroese' },
        { value: 23, key: 'app.demographic.language_options.Farsi' },
        { value: 24, key: 'app.demographic.language_options.Finnish' },
        { value: 25, key: 'app.demographic.language_options.French' },
        { value: 26, key: 'app.demographic.language_options.Gaelic' },
        { value: 27, key: 'app.demographic.language_options.Galician' },
        { value: 28, key: 'app.demographic.language_options.German' },
        { value: 29, key: 'app.demographic.language_options.Greek' },
        { value: 30, key: 'app.demographic.language_options.Hebrew' },
        { value: 31, key: 'app.demographic.language_options.Hindi' },
        { value: 32, key: 'app.demographic.language_options.Hungarian' },
        { value: 33, key: 'app.demographic.language_options.Icelandic' },
        { value: 34, key: 'app.demographic.language_options.Indonesian' },
        { value: 35, key: 'app.demographic.language_options.Inuktitut_Eskimo' },
        { value: 36, key: 'app.demographic.language_options.Italian' },
        { value: 37, key: 'app.demographic.language_options.Japanese' },
        { value: 38, key: 'app.demographic.language_options.Khmer' },
        { value: 39, key: 'app.demographic.language_options.Korean' },
        { value: 40, key: 'app.demographic.language_options.Kurdish' },
        { value: 41, key: 'app.demographic.language_options.Laotian' },
        { value: 42, key: 'app.demographic.language_options.Latvian' },
        { value: 43, key: 'app.demographic.language_options.Lappish' },
        { value: 44, key: 'app.demographic.language_options.Lithuanian' },
        { value: 45, key: 'app.demographic.language_options.Macedonian' },
        { value: 46, key: 'app.demographic.language_options.Malay' },
        { value: 47, key: 'app.demographic.language_options.Maltese' },
        { value: 48, key: 'app.demographic.language_options.Nepali' },
        { value: 49, key: 'app.demographic.language_options.Norwegian' },
        { value: 50, key: 'app.demographic.language_options.Pashto' },
        { value: 51, key: 'app.demographic.language_options.Polish' },
        { value: 52, key: 'app.demographic.language_options.Portuguese' },
        { value: 53, key: 'app.demographic.language_options.Romanian' },
        { value: 54, key: 'app.demographic.language_options.Russian' },
        { value: 55, key: 'app.demographic.language_options.Scots' },
        { value: 56, key: 'app.demographic.language_options.Serbian' },
        { value: 57, key: 'app.demographic.language_options.Slovak' },
        { value: 58, key: 'app.demographic.language_options.Slovenian' },
        { value: 59, key: 'app.demographic.language_options.Somali' },
        { value: 60, key: 'app.demographic.language_options.Spanish' },
        { value: 61, key: 'app.demographic.language_options.Swedish' },
        { value: 62, key: 'app.demographic.language_options.Swahili' },
        { value: 63, key: 'app.demographic.language_options.Tagalog-Filipino' },
        { value: 64, key: 'app.demographic.language_options.Tajik' },
        { value: 65, key: 'app.demographic.language_options.Tamil' },
        { value: 66, key: 'app.demographic.language_options.Thai' },
        { value: 67, key: 'app.demographic.language_options.Tibetan' },
        { value: 68, key: 'app.demographic.language_options.Tigrinya' },
        { value: 69, key: 'app.demographic.language_options.Tongan' },
        { value: 70, key: 'app.demographic.language_options.Turkish' },
        { value: 71, key: 'app.demographic.language_options.Turkmen' },
        { value: 72, key: 'app.demographic.language_options.Ucrainian' },
        { value: 73, key: 'app.demographic.language_options.Urdu' },
        { value: 74, key: 'app.demographic.language_options.Uzbek' },
        { value: 75, key: 'app.demographic.language_options.Vietnamese' },
        { value: 76, key: 'app.demographic.language_options.Welsh' },
        { value: 999, key: 'app.demographic.language_options.Other' },
    ]);
}

export function GetLevelOfFluencyOptions(): SelectItem[] {
    return convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.level_of_fluency.i_do_not_speak' },
        { value: 2, key: 'app.demographic.level_of_fluency.minimally_fluent' },
        { value: 3, key: 'app.demographic.level_of_fluency.somewhat_fluent' },
        { value: 4, key: 'app.demographic.level_of_fluency.moderately_fluent' },
        { value: 5, key: 'app.demographic.level_of_fluency.mostly_fluent' },
        { value: 6, key: 'app.demographic.level_of_fluency.fluent' },
    ]);
}

export function GetLevelOfInterestOptions(withNotApplicable: boolean): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 0, key: 'app.demographic.level_of_interest.not_applicable' },
        { value: 1, key: 'app.demographic.level_of_interest.extremely_low' },
        { value: 2, key: 'app.demographic.level_of_interest.very_low' },
        { value: 3, key: 'app.demographic.level_of_interest.low' },
        { value: 4, key: 'app.demographic.level_of_interest.neutral' },
        { value: 5, key: 'app.demographic.level_of_interest.high' },
        { value: 6, key: 'app.demographic.level_of_interest.very_high' },
        { value: 7, key: 'app.demographic.level_of_interest.extremely_high' },
    ]);
    if (!withNotApplicable) return items.filter((item: SelectItem) => item.value !== 0);
    return items;
}

export function FormalEducationOptions(): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.formal_education.some_high_school_or_less' },
        { value: 2, key: 'app.demographic.formal_education.high_school_graduate' },
        { value: 3, key: 'app.demographic.formal_education.some_college' },
        { value: 4, key: 'app.demographic.formal_education.college_degree' },
        { value: 5, key: 'app.demographic.formal_education.some_graduate_school' },
        { value: 6, key: 'app.demographic.formal_education.graduate_degree' },
        { value: 7, key: 'app.demographic.formal_education.professional_degree' },
        { value: 8, key: 'app.demographic.formal_education.doctoral_degree' },
    ]);
    return items;
}

export function ParticipatedExperiencesOptions(): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.participated_experiences.response_1' },
        { value: 128, key: 'app.demographic.participated_experiences.response_8' },
        { value: 2, key: 'app.demographic.participated_experiences.response_2' },
        { value: 4, key: 'app.demographic.participated_experiences.response_3' },
        { value: 256, key: 'app.demographic.participated_experiences.response_9' },
        { value: 8, key: 'app.demographic.participated_experiences.response_4' },
        { value: 512, key: 'app.demographic.participated_experiences.response_10' },
        { value: 1024, key: 'app.demographic.participated_experiences.response_11' },
        { value: 16, key: 'app.demographic.participated_experiences.response_5' }, // Completed a university sponsored/approved service learning project
        { value: 32, key: 'app.demographic.participated_experiences.response_6' },
        { value: 64, key: 'app.demographic.participated_experiences.response_7' },
        { value: 2048, key: 'app.demographic.participated_experiences.response_12' },
        { value: 4096, key: 'app.demographic.participated_experiences.response_13' },
    ]);
    return items;
}

export function ParticipatedLearningCoursesOptions(): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.participated_learning_courses.response_1' },
        { value: 2, key: 'app.demographic.participated_learning_courses.response_2' },
        { value: 4, key: 'app.demographic.participated_learning_courses.response_3' },
        { value: 8, key: 'app.demographic.participated_learning_courses.response_4' },
        { value: 16, key: 'app.demographic.participated_learning_courses.response_5' },
        { value: 32, key: 'app.demographic.participated_learning_courses.response_6' },
        { value: 64, key: 'app.demographic.participated_learning_courses.response_7' },
        { value: 128, key: 'app.demographic.participated_learning_courses.response_8' },
        { value: 256, key: 'app.demographic.participated_learning_courses.response_9' },
    ]);
    return items;
}

export function PlanParticipateExperiencesOptions(): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.plan_participate_experiences.response_1' },
        { value: 128, key: 'app.demographic.plan_participate_experiences.response_8' },
        { value: 2, key: 'app.demographic.plan_participate_experiences.response_2' },
        { value: 4, key: 'app.demographic.plan_participate_experiences.response_3' },
        { value: 256, key: 'app.demographic.plan_participate_experiences.response_9' },
        { value: 8, key: 'app.demographic.plan_participate_experiences.response_4' },
        { value: 512, key: 'app.demographic.plan_participate_experiences.response_10' },
        { value: 1024, key: 'app.demographic.plan_participate_experiences.response_11' },
        { value: 16, key: 'app.demographic.plan_participate_experiences.response_5' },
        { value: 32, key: 'app.demographic.plan_participate_experiences.response_6' },
        { value: 64, key: 'app.demographic.plan_participate_experiences.response_7' },
        { value: 2048, key: 'app.demographic.plan_participate_experiences.response_12' },
        { value: 4096, key: 'app.demographic.plan_participate_experiences.response_13' },
    ]);
    return items;
}

export function PlanParticipateLearningCoursesOptions(): SelectItem[] {
    const items = convertFormattedMessagesToSelectItem([
        { value: 1, key: 'app.demographic.plan_participate_learning_courses.response_1' },
        { value: 2, key: 'app.demographic.plan_participate_learning_courses.response_2' },
        { value: 4, key: 'app.demographic.plan_participate_learning_courses.response_3' },
        { value: 8, key: 'app.demographic.plan_participate_learning_courses.response_4' },
        { value: 16, key: 'app.demographic.plan_participate_learning_courses.response_5' },
        { value: 32, key: 'app.demographic.plan_participate_learning_courses.response_6' },
        { value: 64, key: 'app.demographic.plan_participate_learning_courses.response_7' },
        { value: 128, key: 'app.demographic.plan_participate_learning_courses.response_8' },
        { value: 256, key: 'app.demographic.plan_participate_learning_courses.response_9' },
    ]);
    return items;
}

export function GetEthnicOrientationByCode(code: number): string | undefined {
    const ethnicOrientationList = GetEthnicOrientationOptions();
    return ethnicOrientationList.find((item) => item.value === code)?.label;
}

export function GetCountryByCode(code: number): string | undefined {
    const countries = GetCountriesOptions();
    return countries.find((item) => item.value === code)?.label;
}

export function GetGenderByCode(code: number): string | undefined {
    const genders = GetGenderOptions();
    return genders.find((item) => item.value === code)?.label;
}

export function GetMaritalStatusByCode(code: number): string | undefined {
    const maritalStatus = GetMaritialStatusOptions();
    return maritalStatus.find((item) => item.value === code)?.label;
}

export function GetReligionByCode(code: number): string | undefined {
    const religions = GetReligiousOrientationOptionsForReports();
    return religions.find((item) => item.value === code)?.label;
}

export function GetLevelOfInterestByCode(code: number): string | undefined {
    const interests = GetLevelOfInterestOptions(/*WithNotApplicable*/true);
    return interests.find((item) => item.value === code)?.label;
}

export function GetPrimaryAreaOfWorkByCode(code: number): string | undefined {
    const primeryAreaOfWork = GetPrimaryWorkAreaOptions();
    return primeryAreaOfWork.find((item) => item.value === code)?.label;
}

export function GetYearOfEducationOptionsByCode(code: number): string | undefined {
    const yearsOfEducation = GetYearOfEducationOptions();
    return yearsOfEducation.find((item) => item.value === code)?.label;
}

export function GetTimeSpentInOtherCountriesOptionsByCode(code: number): string | undefined {
    const options = GetTimeSpentInOtherCountriesOptions();
    return options.find((item) => item.value === code)?.label;
}

export function FormalEducationOptionsByCode(code: number): string | undefined {
    const options = FormalEducationOptions();
    return options.find((item) => item.value === code)?.label;
}

export function GetLanguageOptionsByCode(code: number): string | undefined {
    const options = GetLanguageOptions();
    return options.find((item) => item.value === code)?.label;
}

export function GetLevelOfFluencyOptionsByCode(code: number): string | undefined {
    const options = GetLevelOfFluencyOptions();
    return options.find((item) => item.value === code)?.label;
}

export function GetParticipatedExperiencesOptionsByCode(code: number): string[] {
    const options = ParticipatedExperiencesOptions();
    return options.filter(item => item.value && (item.value & code)).map((item) => item.label);
}

export function GetParticipatedLearningCoursesOptionsByCode(code: number): string[] {
    const options = ParticipatedLearningCoursesOptions();
    return options.filter(item => item.value && (item.value & code)).map((item) => item.label);
}

export function GetPlanParticipateExperiencesOptionsByCode(code: number): string[] {
    const options = PlanParticipateExperiencesOptions();
    return options.filter(item => item.value && (item.value & code)).map((item) => item.label);
}

export function GetPlanParticipateLearningCoursesOptionsByCode(code: number): string[] {
    const options = PlanParticipateLearningCoursesOptions();
    return options.filter(item => item.value && (item.value & code)).map((item) => item.label);
}
