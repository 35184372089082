import { AppMessages } from '../types';

export const appZHTW: AppMessages = {
    'app.btn_start': '开始',
    'app.btn_restart': '重新开始',
    'app.btn_cancel': '取消',
    'app.btn_next': '下一页',
    'app.btn_ok': 'OK',
    'app.btn_finish': '完成',
    'app.btn_back': '返回',
    'app.dropdown_select': '请选择……',
    'app.loading': 'loading',
    'app.response_yes': '是',
    'app.response_no': '不是',
    'app.bevi_copyright_text_1': 'BEVI（the Beliefs, Events, and Values Inventory）是受著作权法保护的测试。BEVI及BEVI的结果、每道问题及其内容、还有各项比例标准，除非得到Craig N. Shealy博士的明确书面许可，否则无权对其全部或一部分进行分发、复制、变更、应用、公开或出版。',
    'app.bevi_copyright_text_2': '以研究或教育为目的的BEVI中文版的使用权已由Craig N. Shealy博士授予西谷 元 教授（日本广岛大学）。',
    'app.empty.data': '没有数据。',
    'app.systemerro': '系统网络不稳定。请稍后重新连接或稍等片刻。',
    'app.loading_document': '下载文档',
    'app.download_document': '下载PDF',
    'app.download_excel': '下载XLSX',
    'app.btn_search': '搜索',
    'app.english': '英文',
    'app.spanish': '西班牙文',
    'app.japanese': '日文',
    'app.chinese-t': '中文（繁体）',
    'app.chinese-s': '中文（简体）',
    'app.true': '真',
    'app.false': '假',
    'app.save': '保存',
    'app.unknown_error': '发生不明故障，请联系BEVI管理负责人。',
    'app.btn_reset': '重设',
    'app.search_placeholder': '请输入搜索条件',
    'app.btn_confirm': 'Confirm',
    'app.catalan': 'Catalan',
    'app.btn_submit': 'Submit',
    'app.notification.error_title': 'Error',
    'app.error.user_not_found': 'User not found',
    'app.save_with_success': '保存成功'
}
